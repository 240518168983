import produce from "immer";
import { WealthCreditCardActionType } from "../actions/wealth-credit-card";

/* ------------- Initial State ------------- */
const initialState = {
    subProductKey: false,
    productKey: null,
    isApplied: false,
    creditCardStatus: null,
    suggestLimitationForm: null,
    personalForm: null,
    addressForm: null,
    maritalStatusForm: null,
    financeForm: null,
    paymentMethod: null,
    subProductList: null,
};

/* ------------- Reducer ------------- */
const wealthCreditCardReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case WealthCreditCardActionType.SetProductKey:
                draft.productKey = action.payload;
                break;
            case WealthCreditCardActionType.SetSubProductKey:
                draft.subProductKey = action.payload;
                break;
            case WealthCreditCardActionType.SetIsAppliedCreditCard:
                draft.isApplied = action.payload;
                break;
            case WealthCreditCardActionType.SetCreditCardStatus:
                draft.creditCardStatus = action.payload;
                break;
            case WealthCreditCardActionType.SetSuggestLimitationForm:
                draft.suggestLimitationForm = action.payload;
                break;
            case WealthCreditCardActionType.SetPersonalForm:
                draft.personalForm = action.payload;
                break;
            case WealthCreditCardActionType.SetAddressForm:
                draft.addressForm = action.payload;
                break;
            case WealthCreditCardActionType.SetMaritalForm:
                draft.maritalStatusForm = action.payload;
                break;
            case WealthCreditCardActionType.SetFinanceForm:
                draft.financeForm = action.payload;
                break;
            case WealthCreditCardActionType.SetPaymentMethod:
                draft.paymentMethod = action.payload;
                break;
            case WealthCreditCardActionType.SetSubProductList:
                draft.subProductList = action.payload;
                break;
            case WealthCreditCardActionType.Reset: {
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            }
            default:
                break;
        }
    });

export default wealthCreditCardReducer;
