import { useEffect, useMemo, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import format from "date-fns/format";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
import { RECEIPT_TYPE, TransactionGroupType } from "@config/constants";
import accountAction from "@redux/actions/account";
import userAction from "@redux/actions/user";
import TransactionListLoading from "@components/TransactionList/TransactionListLoading";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { useHistory } from "react-router-dom";
import useAccounts from "@helpers/useAccounts";
import LspTypography from "@components/LspTypography";
import warningImg from "@assets/images/warning.png";
import CardActivation from "@containers/CardManagement/CardActivation";
import { CARD_TYPES } from "@containers/CardManagement/constant";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    creditLimit: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey[500],
        fontWeight: 600,
    },
    physicalCardBox: {
        // backgroundColor: theme.palette.magicalGrey[100],
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[2],
        position: "relative",
        padding: theme.spacing(3, 2),
        marginTop: theme.spacing(2),
    },
    physicalCardBoxImg: {
        position: "absolute",
        width: 28,
        height: 28,
        left: theme.spacing(2),
        top: -theme.spacing(1.5),
    },
}));

const TransactionHistory = ({
    getTransactionList,
    transactionListFetching,
    transactionList,
    creditLimit,
    txnDateIndex,
    xidIndex,
    hasMore,
    serverTime,
    serverTimeFetching,
    resetServerTime,
    updateTransactionList,
    allowChangePhysicalStatus,
    enableImmediatelyUseCCW4,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation("translation", "creditCard");
    const { formatNumber } = useNumber();

    const { creditCard } = useAccounts();

    const firstLoaded = useRef(false);

    const onMakePaymentClick = useCallback(() => {
        history.push("payment");
    }, [history]);

    const formattedCreditLimit = useMemo(() => {
        if (creditLimit === null) {
            return "";
        }
        const label = t("creditCard:credit_limit");
        return label.replace("%@", formatNumber(creditLimit));
    }, [t, creditLimit, formatNumber]);

    const getTransactionHistory = useCallback(
        ({ pagingData } = {}) => {
            if (!creditCard || !serverTime) {
                return;
            }

            const payload = {
                accountNo: creditCard.no,
                accountType: creditCard.accountType,
                fromDate: "01/01/2015",
                toDate: format(serverTime, "dd/MM/yyyy"),
            };
            if (pagingData?.txnDateIndex && pagingData.xidIndex) {
                payload.txnDateIndex = pagingData.txnDateIndex;
                payload.xidIndex = pagingData.xidIndex;
            }
            getTransactionList(payload);
        },
        [serverTime, creditCard, getTransactionList]
    );

    const updateList = useCallback(
        (data) => {
            updateTransactionList(data);
        },
        [updateTransactionList]
    );

    const showReceipt = useCallback(
        (txn, dispDate) => {
            const refNo = txn.refNo || "";
            const options = {
                hiddenCurrency: true,
                hiddenCurrencyKey: ["VND"],
            };
            LspReceiptController.dialog(
                refNo,
                (data) => {
                    if (data?.isNoteUpdate) {
                        updateList({
                            type: RECEIPT_TYPE.CREDIT_CARD,
                            dispDate,
                            refNo,
                            list: transactionList,
                            data: {
                                note: data?.note || "",
                            },
                        });
                    }
                },
                null,
                options
            );
        },
        [transactionList, updateList]
    );

    useEffect(() => {
        return () => {
            resetServerTime();
        };
    }, [resetServerTime]);

    useEffect(() => {
        if (!serverTimeFetching && !firstLoaded.current) {
            firstLoaded.current = true;
            getTransactionHistory();
        }
    }, [serverTimeFetching, getTransactionHistory]);

    const loadMore = () => {
        if (
            !hasMore ||
            transactionListFetching ||
            serverTimeFetching ||
            !firstLoaded.current
        ) {
            return;
        }
        getTransactionHistory({
            pagingData: {
                txnDateIndex,
                xidIndex,
            },
        });
    };

    const infiniteRef = useInfiniteScroll({
        loading: transactionListFetching,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const isEmptyList =
        !hasMore && transactionList?.length === 0 && !transactionListFetching;

    const onCloseActivateCard = () => {
        GlobalDialogController.hide();
    };

    const isAllowPhysicalActivateBtn = useMemo(() => {
        return enableImmediatelyUseCCW4 && allowChangePhysicalStatus;
    }, [allowChangePhysicalStatus, enableImmediatelyUseCCW4]);

    const onOpenActivateCard = () => {
        GlobalDialogController.show({
            component: () => (
                <CardActivation
                    cardType={CARD_TYPES.VISA_CARD}
                    onDone={getTransactionHistory}
                    onClose={onCloseActivateCard}
                    type="dialog"
                />
            ),
        });
    };

    return (
        <Content>
            <AccountSummaryBox
                leftItemName={t("ah_lb_current_balance")}
                rightItemName={t("creditCard:available_credit")}
                rightItemColor="blue"
                leftItemValue={formatNumber(creditCard?.availableAmount)}
                rightItemValue={formatNumber(creditCard?.workingBalance)}
            />

            <Box mt={2} display="flex" flexDirection="row">
                <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    className={classes.creditLimit}
                >
                    {formattedCreditLimit}
                </Box>
                <LspButton variant="secondary" onClick={onMakePaymentClick}>
                    {t("mastercard_menu_label_make_payment")}
                </LspButton>
            </Box>

            {isAllowPhysicalActivateBtn && (
                <Box>
                    <Box className={classes.physicalCardBox}>
                        <img
                            src={warningImg}
                            alt="warning"
                            className={classes.physicalCardBoxImg}
                        />
                        <LspTypography variant="body3" color="grey">
                            {t(
                                "wealth:mc_v2_activate_online_feature_successful_note"
                            )}
                        </LspTypography>
                    </Box>

                    <LspButton fullWidth mt={2} onClick={onOpenActivateCard}>
                        {t("wealth:mc_v2_activate_offline_feature_CTA")}
                    </LspButton>
                </Box>
            )}

            {isEmptyList ? (
                <Box paddingY={8} textAlign="center">
                    <LspTypography variant="body1" color="grey">
                        {t("spend_dont_have_transactions_yet")}
                    </LspTypography>
                </Box>
            ) : (
                <>
                    <div ref={infiniteRef}>
                        {transactionList?.map((txnGroup) => {
                            const key = `${txnGroup.dispDate}-${txnGroup.item.length}`;
                            return (
                                <TransactionGroup
                                    key={key}
                                    header={txnGroup.dispDate}
                                    items={txnGroup.item}
                                    onItemClick={(data) =>
                                        showReceipt(data, txnGroup.dispDate)
                                    }
                                    type={TransactionGroupType.CreditCard}
                                />
                            );
                        })}
                    </div>
                    {hasMore && <TransactionListLoading />}
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    allowChangePhysicalStatus:
        state.account.creditCard.allowChangePhysicalStatus,
    creditLimit: state.account.creditCard.creditLimit,
    transactionList: state.account.creditCard.transactionList,
    transactionListFetching: state.account.creditCard.fetching,
    txnDateIndex: state.account.creditCard.txnDateIndex,
    xidIndex: state.account.creditCard.xidIndex,
    hasMore: state.account.creditCard.hasMore,
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
    enableImmediatelyUseCCW4:
        state.user.featureState?.data?.enableImmediatelyUseCCW4,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(accountAction.getCreditCardTransactionListRequest(payload)),
    resetServerTime: () => dispatch(userAction.resetServerTime()),
    updateTransactionList: (payload) =>
        dispatch(accountAction.updateTransactionList(payload)),
});

export default connect(mapState, mapDispatch)(TransactionHistory);
