import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import querystring from "query-string";
import {
    Box,
    CircularProgress,
    InputAdornment,
    Paper,
    Step,
    StepLabel,
    Stepper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
    FetchStatus,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
    TRANSFER_DESTINATION_TYPES,
} from "@config/constants";
import DoubleButtons from "@components/DoubleButtons";
import moveMoneyService from "@services/move-money";
import useNumber from "@helpers/useNumber";
import moveMoneyAction from "@redux/actions/move-money";
import LspTextField from "@components/LspTextField";
import LspReceipt from "@components/LspReceipt";
import useAccounts from "@helpers/useAccounts";
import useCapitalize from "@helpers/useCapitalize";
import useDescription from "@helpers/useDescription";
import LspSuggestAmount from "@components/LspSuggestAmount";
import useTransactionValidation from "../useTransactionValidation";
import TransferConfirmation from "./TransferConfirmation";

const InternalTransfer = ({
    transferToAccountNumber,
    transferToDebitCard,
    transferring,
    transferStatus,
    transferInfo,
    resetTransfer,
    errorCodeAmount,
    fullName,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const {
        validateAmount,
        getErrorByResponseCode,
    } = useTransactionValidation();
    const { spendAccount } = useAccounts();
    const { capitalizeSentence } = useCapitalize();
    const { getStandardDescription } = useDescription();
    const queryParams = querystring.parse(location.search);

    const defaultDescription = useMemo(() => {
        return t("mm_description_default").replace(
            "%@",
            capitalizeSentence(fullName)
        );
    }, [fullName, t, capitalizeSentence]);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            resetTransfer();
        };
    }, [resetTransfer]);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (transferStatus === FetchStatus.Success) {
            setActiveStep(2);
        }
    }, [transferStatus]);

    const steps = useMemo(
        () => [
            {
                id: 0,
                label: t("payee_bank_title"),
            },
            {
                id: 1,
                label: t("payee_transfer_review_title"),
            },
            {
                id: 2,
                label: t("transaction_btn_receipt"),
            },
        ],
        [t]
    );

    const [isAutofillProcessed, setIsAutofillProcessed] = useState(false);

    const [targetInfo, setTargetInfo] = useState("");
    const [amount, setAmount] = useState({});
    const [description, setDescription] = useState("");

    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);
    const [validatingAmount, setValidatingAmount] = useState(false);

    const [errors, setErrors] = useState({
        targetInfo: null,
        amount: null,
        description: null,
    });

    const [destination, setDestination] = useState(null);
    const [standardDescription, setStandardDescription] = useState(null);

    const destinationDescription = useMemo(() => {
        if (destination) {
            return `${destination.bank.accountName} | ${destination.bank.bankShortName}`;
        }
        return " ";
    }, [destination]);

    const back = useCallback(() => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const next = useCallback(() => {
        if (activeStep === steps.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, steps]);

    const cancel = useCallback(() => {
        history.push(`/spend-account/${SpendAccountNavigationKey.MoveMoney}`);
    }, [history]);

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value: +value });
        },
        [errors.amount]
    );

    const getTargetAccount = useCallback(
        async (target) => {
            if (!spendAccount || !target) {
                return;
            }

            setFetchingTargetAccount(true);

            const payload = {
                targetInfo: target,
                bankAccount: spendAccount.no,
            };

            const response = await moveMoneyService.getInternalAccountDetail(
                payload
            );

            if (!unmounted.current) {
                setFetchingTargetAccount(false);
                if (response.ok) {
                    const { code, data } = response.data;
                    switch (code) {
                        case RESPONSE_CODE.SUCCESS:
                            if (data.bank.accountNumber === spendAccount.no) {
                                setDestination(null);
                                setErrors((prev) => ({
                                    ...prev,
                                    targetInfo: t(
                                        "payee_card_number_invalid_payee_is_payer"
                                    ),
                                }));
                            } else {
                                setDestination({
                                    ...data,
                                    defaultDescription: t(defaultDescription),
                                });
                                setErrors((prev) => ({
                                    ...prev,
                                    targetInfo: null,
                                }));
                            }
                            break;
                        case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                            setDestination(null);
                            setErrors((prev) => ({
                                ...prev,
                                targetInfo: t(
                                    "spend_lb_invalid_timo_destination"
                                ),
                            }));
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        [t, spendAccount, defaultDescription]
    );

    const onNextHandler = useCallback(async () => {
        const desc = await getStandardDescription({
            defaultDescription,
            description,
        });

        setStandardDescription(desc?.standardDescription);
        if (description === "") {
            setDescription(desc?.description);
        }
        next();
    }, [next, description, defaultDescription, getStandardDescription]);

    const validateFormData = useCallback(() => {
        // eslint-disable-next-line prefer-const
        let formErrors = { ...errors };

        if (!targetInfo) {
            formErrors.targetInfo = t("msg_we_need_this");
        }
        if (!amount?.value) {
            formErrors.amount = t("msg_we_need_this");
        }

        setErrors(formErrors);

        const isInValid = Object.values(formErrors)?.every(
            (item) => item === null
        );

        return isInValid;
    }, [t, targetInfo, amount, errors]);

    const submitForm = useCallback(
        async (event) => {
            event.preventDefault();

            const isValid = validateFormData();
            if (!spendAccount || fetchingTargetAccount || !isValid) {
                return;
            }

            validateAmount({
                amount: amount.value,
                transferType: "member",
                beforeRequest: () => setValidatingAmount(true),
                isCancelled: unmounted.current,
                afterResponse: () => setValidatingAmount(false),
                handleBalanceLessThanMinAmount: (minAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: `${t("spend_msg_for_least_amount").replace(
                            "%@",
                            formatNumber(minAmount)
                        )}`,
                    })),
                handleAmountOutOfRange: (minAmount, maxAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: `${t("payee_card_amount_from")} ${formatNumber(
                            minAmount
                        )} ${t("moveMoney_label_amount_and")} ${formatNumber(
                            maxAmount
                        )}`,
                    })),
                handleAmountOverDailyLimit: () =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_daily_limit"),
                    })),
                handleAmountValid: onNextHandler,
                canUseOverdraft: true,
            });
        },
        [
            validateFormData,
            validateAmount,
            spendAccount,
            fetchingTargetAccount,
            t,
            amount,
            formatNumber,
            onNextHandler,
        ]
    );
    const onCompleteSendAgainAction = useCallback(() => {
        setActiveStep(0);
    }, []);

    const receiptOptions = useMemo(() => {
        return {
            enableSendAgainButton: true,
            onCompleteSendAgainHandler: onCompleteSendAgainAction,
        };
    }, [onCompleteSendAgainAction]);

    const confirmTransfer = useCallback(
        (saveAsPayee) => {
            const notification = "sms";

            const source = {
                accountNumber: spendAccount.no,
            };

            const target = {
                amount: +amount.value,
                bankName: destination.bank.bankName,
                desType: destination.type,
                description: standardDescription,
                originalFullDescription: description,
                destinationType: TRANSFER_DESTINATION_TYPES.ACCOUNT,
            };

            if (saveAsPayee) {
                target.saveAsPayee = saveAsPayee;
            }

            // Hide code for sbs-1372: tracking data.
            // Reason: Make inconsistency with mobile metric
            // User transfer from timo cell will be transfered by account api

            // switch (target.desType) {
            //     case "cardnumber":
            //         target.cardName = destination.card.cardName;
            //         target.cardNumber = destination.card.cardNumber;
            //         target.destinationType = TRANSFER_DESTINATION_TYPES.DEBIT;

            //         transferToDebitCard({
            //             notification,
            //             source,
            //             target,
            //         });
            //         break;
            //     case "accountnumber":
            //     default:

            target.accountName = destination.bank.accountName;
            target.accountNumber = destination.bank.accountNumber;

            if (queryParams.autofill === "1") {
                const destinationStr = localStorage.getItem(
                    "transferDestination"
                );
                if (destinationStr) {
                    const autofillDestination = JSON.parse(destinationStr);
                    if (destination?.bank?.bankId) {
                        target.bankId = destination?.bank?.bankId;
                    }
                    if (autofillDestination?.payeeId) {
                        target.payeeId = autofillDestination?.payeeId;
                    }
                    if (autofillDestination?.accountId) {
                        target.desTypeId = autofillDestination?.accountId?.toString();
                    }
                }
            }
            transferToAccountNumber({
                notification,
                source,
                target,
            });
            //         break;
            // }
        },
        [
            spendAccount,
            destination,
            description,
            amount,
            transferToAccountNumber,
            // transferToDebitCard,
            standardDescription,
            queryParams,
        ]
    );

    useEffect(() => {
        if (fetchingTargetAccount || isAutofillProcessed) {
            return;
        }
        if (queryParams.autofill === "1") {
            const destinationStr = localStorage.getItem("transferDestination");
            if (destinationStr) {
                const autofillDestination = JSON.parse(destinationStr);
                setIsAutofillProcessed(true);
                setTargetInfo(autofillDestination.accountNumber);
                getTargetAccount(autofillDestination.accountNumber);

                // use for send again button from receipt
                if (
                    autofillDestination?.filledDescription &&
                    autofillDestination?.filledDescription !== ""
                ) {
                    setDescription(autofillDestination?.filledDescription);
                }
                if (
                    autofillDestination?.amount &&
                    autofillDestination?.amount !== ""
                ) {
                    setAmount(autofillDestination?.amount);
                }
            }
        }
    }, [
        location.search,
        getTargetAccount,
        fetchingTargetAccount,
        isAutofillProcessed,
        queryParams.autofill,
    ]);

    useEffect(() => {
        if (errorCodeAmount) {
            const error = getErrorByResponseCode(errorCodeAmount);
            setErrors((prev) => ({
                ...prev,
                amount: error,
            }));
            setActiveStep(0); // back to input info amount
        }
    }, [errorCodeAmount, getErrorByResponseCode]);

    return (
        <Paper component={Box} overflow="hidden">
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((s) => (
                    <Step key={s.id}>
                        <StepLabel>{s.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 0 && (
                <form onSubmit={submitForm}>
                    <Box p={3} paddingTop={1}>
                        <LspTextField
                            label={t(
                                "spend_move_money_hint_username_member_detail"
                            )}
                            error={!!errors.targetInfo}
                            helperText={
                                errors.targetInfo || destinationDescription
                            }
                            onBlur={() => getTargetAccount(targetInfo)}
                            onChange={(e) => {
                                setTargetInfo(e.target.value);
                            }}
                            value={targetInfo}
                            InputProps={{
                                endAdornment: fetchingTargetAccount ? (
                                    <InputAdornment position="end">
                                        <CircularProgress size={16} />
                                    </InputAdornment>
                                ) : null,
                                type: "text",
                            }}
                        />
                        <LspSuggestAmount
                            amount={amount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onAmountChange}
                            label={t("payee_card_amount_label")}
                            error={!!errors.amount}
                            helperText={errors.amount || " "}
                            customInput={LspTextField}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            numberValue={amount.value}
                            onChange={onAmountChange}
                            inputProps={{
                                id: "amountbox",
                            }}
                        />

                        <LspTextField
                            label={t("payee_card_description_label")}
                            helperText=" "
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            value={description}
                            multiline
                            inputProps={{
                                maxLength: 254,
                                id: "descbox",
                            }}
                        />
                        <Box marginTop={1.5}>
                            <DoubleButtons
                                progressing={validatingAmount}
                                primaryButton={{ label: t("lb_next") }}
                                secondaryButton={{
                                    label: t("lb_cancel"),
                                    onClick: cancel,
                                }}
                            />
                        </Box>
                    </Box>
                </form>
            )}
            {activeStep === 1 && (
                <TransferConfirmation
                    onBack={back}
                    onConfirm={confirmTransfer}
                    destination={destination}
                    amount={amount.value}
                    description={description}
                    progressing={transferring}
                    disableSavePayee={queryParams.autofill === "1"}
                />
            )}
            {activeStep === 2 && (
                <LspReceipt
                    onClose={cancel}
                    rawReceipt={transferInfo}
                    options={receiptOptions}
                />
            )}
        </Paper>
    );
};

const mapState = (state) => ({
    transferring: state.moveMoney.fetching,
    transferStatus: state.moveMoney.status,
    transferInfo: state.moveMoney.info,
    errorCodeAmount: state.moveMoney.errorCodeAmount,
    fullName: state.user.info?.fullName,
});

const mapDispatch = (dispatch) => ({
    transferToAccountNumber: (payload) =>
        dispatch(moveMoneyAction.transferToAccountNumberRequest(payload)),
    transferToDebitCard: (payload) =>
        dispatch(moveMoneyAction.transferToDebitCardRequest(payload)),
    resetTransfer: () => dispatch(moveMoneyAction.reset()),
});

export default connect(mapState, mapDispatch)(InternalTransfer);
