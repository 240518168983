import ComingSoon from "@components/ComingSoon";
import { CreditCardNavigationKey } from "@config/constants";
import usePathname from "@helpers/usePathname";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Content from "@components/Content";
import InstallmentDetail from "./InstallmentDetail";
import InstallmentList from "./InstallmentList";
import InstallmentAvailable from "./InstallmentAvailable";
import InstallmentConversion from "./InstallmentConversion";

const parentName = "credit-card/installment";

const Installment = ({ systemParams }) => {
    const { generateChildPathname } = usePathname();
    const { t } = useTranslation();

    const isEnableInstallment = useMemo(
        () => systemParams.IsEnableInstallment === "true",
        [systemParams]
    );

    const contentRoutes = useMemo(
        () => [
            {
                path: CreditCardNavigationKey.InstallmentList,
                component: InstallmentList,
                default: true,
            },
            {
                path: generateChildPathname(
                    "",
                    CreditCardNavigationKey.InstallmentDetail,
                    "txnId"
                ),
                component: InstallmentDetail,
            },
            {
                path: generateChildPathname(
                    "",
                    CreditCardNavigationKey.InstallConversion,
                    "txnId"
                ),
                component: InstallmentConversion,
            },
            {
                path: CreditCardNavigationKey.InstallmentAvailable,
                component: InstallmentAvailable,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    return (
        <>
            {isEnableInstallment && (
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            )}
            {!isEnableInstallment && (
                <Content>
                    <ComingSoon content={t("creditCard:ins_coming_soon_msg")} />
                </Content>
            )}
        </>
    );
};

const stateProps = (state) => ({
    systemParams: state.systemParams.info.sysparams["1.0"],
});

export default memo(connect(stateProps)(Installment));
