import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import DoubleButtons from "@components/DoubleButtons";
import LspAvatar from "@components/LspAvatar";

export const TransferConfirmationDialogType = {
    DestinationExistInPayee:
        "TransferConfirmationDialogType/DestinationExistInPayee",
    DuplicatedPayeeName: "TransferConfirmationDialogType/DuplicatedPayeeName",
};

const useStyles = makeStyles(() => ({
    payeeName: {
        fontWeight: 600,
    },
}));

const TransferConfirmationDialog = ({
    open,
    payeeAvatar,
    payeeName,
    progressing,
    onConfirm,
    onCancel,
    type,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const title = useMemo(() => {
        if (type === TransferConfirmationDialogType.DestinationExistInPayee) {
            return t("payee_title_destination_added_in_list");
        }
        return t("payee_title_exist_not_have_destination");
    }, [type, t]);

    const desc = useMemo(() => {
        if (type === TransferConfirmationDialogType.DestinationExistInPayee) {
            return t("payee_content_destination_added_in_list");
        }
        return t("payee_content_exist_not_have_destination");
    }, [type, t]);

    const submitButtonLabel = useMemo(() => {
        if (type === TransferConfirmationDialogType.DestinationExistInPayee) {
            return t("lb_next");
        }
        return t("payee_btn_add_destination");
    }, [type, t]);

    return (
        <LspDialog open={open} maxWidth="sm" scroll="body">
            <LspDialogTitle>
                <Icon
                    className="font-icon icon-toomuchtimo"
                    style={{ overflow: "visible" }}
                />
                {title}
            </LspDialogTitle>
            <LspDialogContent>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    paddingBottom={2}
                >
                    <LspAvatar avatar={payeeAvatar} />
                    <Box
                        component={Typography}
                        paddingX={1}
                        className={classes.payeeName}
                    >
                        {payeeName}
                    </Box>
                </Box>
                <Box>{desc}</Box>
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    progressing={progressing}
                    primaryButton={{
                        label: submitButtonLabel,
                        onClick: onConfirm,
                    }}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: onCancel,
                    }}
                />
            </LspDialogActions>
        </LspDialog>
    );
};

export default memo(TransferConfirmationDialog);
