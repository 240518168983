import Content from "@components/Content";
import PageHeaderBack from "@components/PageHeaderBack";
import { Icon, makeStyles } from "@material-ui/core";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    iconHeader: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(1),
    },
}));

const NewBillHeader = ({ step, providerName, stepList, stepBack }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const title = useMemo(() => {
        switch (step) {
            case stepList.PROVIDER_LIST:
                return {
                    label: t("bill_title_bill_make_transfer"),
                    isOnBack: false,
                };
            case stepList.PROVIDER_CHILD_LIST:
                return {
                    label: t("bill_title_bill_make_transfer"),
                    isOnBack: true,
                };

            case stepList.INPUT_CUSTOMER_ID:
                return {
                    label: `${t("bill_lb_title_1")} ${providerName} ${t(
                        "bill_lb_title_2"
                    )}`,
                    isOnBack: true,
                };
            case stepList.BILL_DETAIL:
                return {
                    label: t("bill_title_unpaid_bill"),
                    isOnBack: true,
                };
            case stepList.BILL_SUN_LIFE_FORM:
                return {
                    label: t("wealth_life_insurance_payment_title"),
                    isOnBack: true,
                };
            default:
                return null;
        }
    }, [step, t, providerName, stepList]);

    return (
        <>
            {title && (
                <Content size="sm">
                    <PageHeaderBack onBack={title?.isOnBack ? stepBack : null}>
                        <Icon
                            className={`font-icon icon-people2 ${classes.iconHeader}`}
                        />
                        {title?.label}
                    </PageHeaderBack>
                </Content>
            )}
        </>
    );
};

export default memo(NewBillHeader);
