import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    about: {
        background: theme.palette.neutral.grey4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4),
    },
    video: {
        marginTop: theme.spacing(4),
        width: "100%",
        maxWidth: 550,
    },
}));

const CommunityAbout = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <Box className={classes.about}>
            <LspTypography variant="title1">
                {t("community_intro")}
            </LspTypography>
            <Box className={classes.video}>
                <iframe
                    width="100%"
                    height="315"
                    src={t("community_intro_video")}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            </Box>
        </Box>
    );
};

export default CommunityAbout;
