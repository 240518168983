import { useTranslation } from "react-i18next";
import {
    VINA_WEALTH_TXT_TRANSACTION_TYPES,
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_TYPES,
} from "@containers/Wealth/wealthConstants";
import { memo, useEffect } from "react";
import { Icon, Tooltip } from "@material-ui/core";
import vinaWealthAction from "@redux/actions/vina-wealth";
import { connect } from "react-redux";
import VinaWealthTransactionItem from "./VinaWealthTransactionItem";
import useSharedClasses from "./vinaWealthSharedClasses";

const VinaWealthPendingTransaction = ({
    getTransactionList,
    pendingTransactions,
    fetchingPending,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    useEffect(() => {
        const payload = {
            productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
            type: WEALTH_VINA_TYPES.TRANSACTION,
            lastId: -1,
            transactionType: [VINA_WEALTH_TXT_TRANSACTION_TYPES.PENDING],
        };
        getTransactionList(payload);
    }, [getTransactionList]);

    return (
        <>
            {!fetchingPending && (
                <div className={sharedClasses.statusLine}>
                    <span>{t("wealth:vc3_status_pending")}</span>
                    <span>
                        <Tooltip
                            arrow
                            title={t("wealth:vc3_transaction_pending_info")}
                        >
                            <Icon className="font-icon icon-vcinfo" />
                        </Tooltip>
                    </span>
                </div>
            )}
            {!fetchingPending &&
                pendingTransactions &&
                pendingTransactions?.map((trans) => (
                    <VinaWealthTransactionItem key={trans.id} item={trans} />
                ))}
        </>
    );
};

const mapState = (state) => ({
    pendingTransactions: state.vinaWealth.pendingTransaction.list,
    fetchingPending: state.vinaWealth.pendingTransaction.fetching,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(vinaWealthAction.getPendingTransaction(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(VinaWealthPendingTransaction)
);
