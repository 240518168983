import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BANK_INFO, REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import paymentRequestService from "@services/payment-request";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import LspButton from "@components/LspButton";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import LspAvatar from "@components/LspAvatar";
import LspTextField from "@components/LspTextField";
import { Controller, useForm } from "react-hook-form";
import useAccounts from "@helpers/useAccounts";
import moveMoneyService from "@services/move-money";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import { CloseIcon } from "@components/LspIcon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import Content from "@components/Content";
import ViewPayeeExist from "../ViewPayeeExist";
import { PAYMENT_REQUEST_ITEM_TYPES } from "../paymentRequest.constant";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            color: theme.palette.primary.main,
            padding: theme.spacing(4),
            // padding: theme.spacing(4, 13),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
        },
        wrapperDuplicateName: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            color: theme.palette.primary.main,
        },
        header: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            paddingBottom: theme.spacing(4),
            "& > div": {
                textTransform: "uppercase",
            },
        },
        iconForm: {
            fontSize: theme.typography.pxToRem(36),
        },
        actionBtn: {
            width: "100%",
            position: "absolute",
            padding: theme.spacing(2, 2),
            "& > svg": {
                cursor: "pointer",
            },
        },
    };
});
const PaymentCreate = ({ getPaymentList, userInfo, systemParams }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [description, setDescription] = useState(""); // get value description
    const [payeeList, setPayeeList] = useState([]); // list payee exist
    const [addNewPayee, setAddNewPayee] = useState(false); // flag for hide/show
    const [editPayeeName, setEditPayeeName] = useState(false); // flag for hide/show
    const [duplicateName, setDuplicateName] = useState(false); // flag for hide/show
    const [duplicateNameData, setDuplicateNameData] = useState(null); // data when dauplicate name payee
    const [typeDuplicateName, setTypeDuplicateName] = useState("");
    const [destinationInfo, setDestinationInfo] = useState({
        message: "",
        data: null,
    });
    const [fetchingData, setFetchingData] = useState(false);

    // error control
    const [errPayeeInfo, setErrPayeeInfo] = useState("");
    const [errPayeeName, setErrPayeeName] = useState("");
    const [errAmount, setErrAmount] = useState("");
    const unmounted = useRef(false);

    const { spendAccount } = useAccounts();
    const {
        register,
        handleSubmit,
        errors,
        control,
        formState: { isValid, isSubmitting },
        setValue,
        reset,
    } = useForm({
        mode: "onChange",
    });
    const { thousandSeparator, decimalSeparator, formatNumber } = useNumber();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const getListPayee = async (payeeId) => {
        if (unmounted.current) return;
        const { data } = await paymentRequestService.getPayeeList();
        switch (data.code) {
            case RESPONSE_CODE.CREATED:
                const payeeListRes = data?.data;

                if (!payeeListRes) return;

                const listPayee = [];
                for (let i = 0; i < payeeListRes.length; i++) {
                    let payeeItem = null;
                    if (payeeListRes[i].payeeType === "Payee") {
                        payeeItem = {
                            name: payeeListRes[i].nickName
                                ? payeeListRes[i].nickName
                                : payeeListRes[i].fullName,
                            avatar: payeeListRes[i].avatar
                                ? payeeListRes[i].avatar
                                : null,
                            payeeId: payeeListRes[i].payeeId,
                            accountNumber: payeeListRes[i].accountNumber,
                            cardNumber: payeeListRes[i].cardNumber,
                        };
                        listPayee.push(payeeItem);
                    }
                }
                setPayeeList(listPayee);
                // case duplicate name : set default receiver when success
                if (typeof payeeId === "number") {
                    const payee = listPayee.find(
                        (elm) => elm.payeeId === payeeId
                    );
                    setValue("receiver", payee, {
                        shouldValidate: true,
                        shouldDirty: true,
                    });
                } else if (typeof payeeId === "boolean") {
                    setValue("receiver", listPayee[0], {
                        shouldValidate: true,
                        shouldDirty: true,
                    });
                }
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const checkDestinationOfDefaultBank = async (payeeName) => {
        if (
            userInfo.userEmail === payeeName ||
            spendAccount?.no === payeeName
        ) {
            setErrPayeeInfo(t("payee_card_number_invalid_payee_is_payer"));
            return;
        }
        setFetchingData(true);
        // check card number
        const dataToPost = {
            targetInfo: payeeName,
            bankAccount: spendAccount?.no,
            checkType: "timo",
        };
        const { data } = await paymentRequestService.checkCardNumber(
            dataToPost
        );
        setFetchingData(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setEditPayeeName(true);
                setValue("nickName", data.data.bank.accountName, {
                    shouldValidate: true,
                    shouldDirty: true,
                });
                setDestinationInfo({
                    data: data.data,
                    message: `${data.data.bank.accountName} | ${data.data.bank.bankName}`,
                });

                break;
            case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                setErrPayeeInfo(t("spend_lb_invalid_timo_destination"));
                break;
            default:
                break;
        }
    };
    const addPayeeInfo = async (infoPayeeAccount, type) => {
        const { data } = await paymentRequestService.addNewPayeeInfoAccount(
            infoPayeeAccount,
            type
        );
        setFetchingData(false);
        switch (data.code) {
            case RESPONSE_CODE.CREATED:
                const newPayeeName = infoPayeeAccount.accounts
                    ? infoPayeeAccount.accounts[0].nickName
                    : infoPayeeAccount.cards[0].nickName;

                AlertController.show(
                    t("payment_add_payee_successfully").replace(
                        "%@",
                        newPayeeName
                    ),
                    AlertType.Success
                );

                setAddNewPayee(false);
                setEditPayeeName(false);
                getListPayee(true);
                setDestinationInfo({
                    data: null,
                    message: null,
                });
                break;

            default:
                break;
        }
    };
    const savePayee = async (nickName) => {
        const dataToPost = {
            nickName: nickName || destinationInfo.data.bank.accountName,
            fullName: destinationInfo.data.bank.accountName,
            avatar: "",
            company: "",
            phoneNos: [],
            emails: destinationInfo.email,
        };
        const { data } = await moveMoneyService.addPayee(dataToPost);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                let infoPayeeAccount = {};
                if (destinationInfo.data.type === "cardnumber") {
                    infoPayeeAccount = {
                        cards: [
                            {
                                payeeId: data.data.payeeId,
                                description: "",
                                nameOnCard: destinationInfo.data.card.cardName,
                                cardNumber:
                                    destinationInfo.data.card.cardNumber,
                                nickName:
                                    nickName ||
                                    destinationInfo.data.bank.accountName,
                            },
                        ],
                    };
                    addPayeeInfo(infoPayeeAccount, "cardnumber");
                } else if (destinationInfo.data.type === "bankaccount") {
                    infoPayeeAccount = {
                        accounts: [
                            {
                                payeeId: data.data.payeeId,
                                description: "",
                                bankId: BANK_INFO.DEFAULT_BANK_1_ID.toString(),
                                provinceId: "",
                                branchId: "",
                                branchName: "",
                                accountName:
                                    destinationInfo.data.bank.accountName,
                                accountNumber:
                                    destinationInfo.data.bank.accountNumber,
                                nickName:
                                    nickName ||
                                    destinationInfo.data.bank.accountName,
                            },
                        ],
                    };

                    addPayeeInfo(infoPayeeAccount, "bankaccount");
                }
                break;

            default:
                break;
        }
    };

    const checkPayee = async (nickName) => {
        setFetchingData(true);
        const dataToPost = {
            accountNumber: destinationInfo.data.bank.accountNumber,
            payeeName: nickName,
        };

        const { data } = await moveMoneyService.checkPayeeDestinationExistence(
            dataToPost
        );

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                savePayee(nickName);
                break;
            case RESPONSE_CODE.DESTINATION_NOT_EXISTING: // 4100
                setErrPayeeInfo(t("spend_lb_invalid_timo_destination"));
                setFetchingData(false);
                break;

            case RESPONSE_CODE.DESTINATION_EXISTING_IN_CURRENT_PAYEE: // 4101
                setTypeDuplicateName("code_destination_added_in_list");
                setDuplicateNameData(data.data);
                setDuplicateName(true);
                setFetchingData(false);
                break;
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO: // 4102
                setErrPayeeName(
                    t("payee_msg_exist_payee_have_destination_other")
                );
                setFetchingData(false);
                break;
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST: // 4103
                setTypeDuplicateName("code_payee_exist_not_have_destination");
                setDuplicateNameData(data.data);
                setDuplicateName(true);
                setFetchingData(false);
                break;
            default:
                setFetchingData(false);
                break;
        }
    };

    const sendRequest = async (value) => {
        // case when check destination success
        if (value.payeeInfo && value.nickName) {
            checkPayee(value.nickName);
            return;
        } // case when check destination
        if (value.payeeInfo) {
            checkDestinationOfDefaultBank(value.payeeInfo);
            return;
        }
        const amount = value.amount.replace(/[.,]/g, "");
        const dataToPost = {
            amount,
            reason: description ? description.trim() : null,
            receiveList: [
                {
                    payeeId: value.receiver.payeeId,
                    accountNumber: value.receiver.accountNumber
                        ? value.receiver.accountNumber
                        : value.receiver.cardNumber,
                },
            ],
        };

        const { data } = await paymentRequestService.createPaymentRequest(
            dataToPost
        );
        switch (data.code) {
            case RESPONSE_CODE.CREATED:
                GlobalDialogController.hide();
                AlertController.show(
                    `${t("pr_lb_create_request_success")?.replace(
                        "%@",
                        value?.receiver?.name
                    )}`,
                    AlertType.Success
                );
                const outstandingPayload = {
                    type: PAYMENT_REQUEST_ITEM_TYPES.OPEN,
                    lastId: "-1",
                    modifyAt: "",
                };
                getPaymentList(outstandingPayload);
                break;
            case RESPONSE_CODE.MAX_LIMIT_TRANSFER_PER_DAY:
                setErrAmount(t("ms_lb_transfer_daily_limit"));
                break;
            case RESPONSE_CODE.MIN_LIMITED:
                const err = `${t("payee_card_amount_from")} ${formatNumber(
                    systemParams.MinAmountLimitPerTransfer
                )} ${t("moveMoney_label_amount_and")} ${formatNumber(
                    systemParams.MaxAmountLimitPerTransfer
                )}`;
                setErrAmount(err);
                break;

            default:
                break;
        }
    };

    const resetState = () => {
        setDuplicateName(false);
        setEditPayeeName(false);
        setDestinationInfo({
            data: null,
            message: null,
        });
    };

    const onClose = () => {
        GlobalDialogController.hide();
    };

    const onBack = () => {
        setAddNewPayee(false);
        setEditPayeeName(false);
        setDestinationInfo({
            data: null,
            message: null,
        });
    };

    useEffect(() => {
        getListPayee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    return (
        <Content size="sm" boxProps={{ style: { position: "relative" } }}>
            <Box className={classes.actionBtn}>
                {addNewPayee && !duplicateName && (
                    <ArrowBackIcon onClick={onBack} />
                )}
                <CloseIcon onClick={onClose} style={{ float: "right" }} />
            </Box>
            <div
                className={clsx({
                    [classes.wrapper]: !duplicateName,
                    [classes.wrapperDuplicateName]: duplicateName,
                })}
            >
                {duplicateName && (
                    <ViewPayeeExist
                        resetState={resetState}
                        getListPayee={getListPayee}
                        duplicateNameData={duplicateNameData}
                        typeDuplicateName={typeDuplicateName}
                        closeNewPayee={() => setAddNewPayee(false)}
                        closeDuplicateName={() => {
                            setDuplicateName(false);
                            reset(
                                {
                                    payeeInfo:
                                        destinationInfo.data.bank.accountNumber,
                                    nickName: duplicateNameData.nickName,
                                },
                                {
                                    keepErrors: true,
                                    keepDirty: true,
                                    keepIsSubmitted: false,
                                    keepTouched: false,
                                    keepIsValid: false,
                                    keepSubmitCount: false,
                                }
                            );
                            setErrPayeeName(
                                t("payee_msg_already_existed").replace(
                                    "%@",
                                    destinationInfo.data.bank.accountName
                                )
                            );
                        }}
                        addPayeeInfo={addPayeeInfo}
                        destinationInfo={destinationInfo}
                    />
                )}
                {!duplicateName && (
                    <>
                        <Box pb={2} className={classes.header}>
                            <div className={classes.iconForm}>
                                <i
                                    className={`font-icon ${
                                        addNewPayee
                                            ? "icon-Logo-Payee"
                                            : "icon-requestpayment"
                                    } right-title-icon`}
                                />
                            </div>
                            <div>
                                {addNewPayee
                                    ? t("payment_title_add_new_payee")
                                    : t(
                                          "payment_request_initialization_header"
                                      )}
                            </div>
                        </Box>
                        <form
                            onSubmit={handleSubmit(sendRequest)}
                            style={{
                                width: "100%",
                            }}
                        >
                            {addNewPayee && (
                                <>
                                    <LspTextField
                                        fullWidth
                                        label={t(
                                            "payment_lb_input_add_new_payee"
                                        )}
                                        placeholder={t(
                                            "payment_placeholder_add_new_payee"
                                        )}
                                        name="payeeInfo"
                                        inputProps={{
                                            maxLength: 35,
                                            ref: register({
                                                required: t("msg_we_need_this"),
                                            }),
                                        }}
                                        error={
                                            !!errors.payeeInfo || !!errPayeeInfo
                                        }
                                        helperText={
                                            errors.payeeInfo?.message ||
                                            errPayeeInfo ||
                                            destinationInfo.message
                                        }
                                        onChange={(e) => {
                                            setValue(
                                                "payeeInfo",
                                                replaceSpecialCharacter({
                                                    pattern:
                                                        REGEX_PATTERN.MEMBER_DETAIL,
                                                    text: e.target.value,
                                                })
                                            );
                                            setEditPayeeName(false);
                                            setErrPayeeInfo("");
                                            setDestinationInfo({
                                                data: null,
                                                message: null,
                                            });
                                            setErrPayeeName("");
                                        }}
                                    />
                                    {editPayeeName && (
                                        <>
                                            <LspTextField
                                                fullWidth
                                                label={t("payee_name_label")}
                                                name="nickName"
                                                inputProps={{
                                                    maxLength: 35,
                                                    ref: register({
                                                        required: t(
                                                            "msg_we_need_this"
                                                        ),
                                                    }),
                                                }}
                                                onChange={(e) => {
                                                    setValue(
                                                        "nickName",
                                                        replaceSpecialCharacter(
                                                            {
                                                                pattern:
                                                                    REGEX_PATTERN.REFERRAL_CODE,
                                                                text:
                                                                    e.target
                                                                        .value,
                                                            }
                                                        )
                                                    );
                                                    setErrPayeeName("");
                                                }}
                                                error={!!errPayeeName}
                                                helperText={errPayeeName}
                                            />
                                            <LspButton
                                                disabled={!isValid}
                                                type="submit"
                                                fullWidth
                                                progressing={fetchingData}
                                            >
                                                {t("payment_lb_save_payee")}
                                            </LspButton>
                                        </>
                                    )}
                                    {!editPayeeName && (
                                        <LspButton
                                            disabled={!isValid}
                                            type="submit"
                                            fullWidth
                                            progressing={fetchingData}
                                        >
                                            {t("payment_lb_check_payee")}
                                        </LspButton>
                                    )}
                                </>
                            )}
                            {!addNewPayee && (
                                <>
                                    <Controller
                                        render={({ onChange, ...props }) => (
                                            <Autocomplete
                                                filterOptions={(
                                                    options,
                                                    params
                                                ) => {
                                                    const filtered = filter(
                                                        options,
                                                        params
                                                    );
                                                    filtered.unshift({
                                                        name: t(
                                                            "payment_lb_add_new_payee"
                                                        ),
                                                    });

                                                    return filtered;
                                                }}
                                                options={payeeList}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                renderOption={(option) => {
                                                    return (
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            datatestid="requestpayee"
                                                        >
                                                            <Box mr={1}>
                                                                <LspAvatar
                                                                    avatar={
                                                                        option.avatar
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box>
                                                                {option.name}
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <LspTextField
                                                        {...params}
                                                        label={t(
                                                            "payment_request_receiver_placeholder"
                                                        )}
                                                        variant="outlined"
                                                    />
                                                )}
                                                defaultValue={payeeList[3]}
                                                onChange={(e, data) => {
                                                    if (
                                                        data?.name ===
                                                        t(
                                                            "payment_lb_add_new_payee"
                                                        )
                                                    ) {
                                                        setAddNewPayee(true);
                                                        setValue(
                                                            "payeeInfor",
                                                            ""
                                                        );
                                                    }
                                                    return onChange(data);
                                                }}
                                                {...props}
                                            />
                                        )}
                                        onChange={([, data]) => data}
                                        name="receiver"
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                    />

                                    <Controller
                                        as={
                                            <NumberFormat
                                                customInput={LspTextField}
                                                autoComplete="off"
                                                thousandSeparator={
                                                    thousandSeparator
                                                }
                                                decimalSeparator={
                                                    decimalSeparator
                                                }
                                                label={t(
                                                    "payment_request_amount_placeholder"
                                                )}
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                allowedDecimalSeparators={false}
                                                value={null}
                                                onValueChange={() =>
                                                    setErrAmount("")
                                                }
                                            />
                                        }
                                        error={!!errAmount}
                                        helperText={errAmount}
                                        name="amount"
                                        variant="outlined"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={null}
                                    />

                                    <LspTextField
                                        label={t(
                                            "payment_request_reason_placeholder"
                                        )}
                                        value={description}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        multiline
                                    />

                                    <LspButton
                                        disabled={!isValid}
                                        type="submit"
                                        fullWidth
                                        progressing={isSubmitting}
                                    >
                                        {t(
                                            "payment_request_initialization_btn_send_request"
                                        )}
                                    </LspButton>
                                </>
                            )}
                        </form>
                    </>
                )}
            </div>
        </Content>
    );
};

const stateProps = (state) => ({
    userInfo: state.user.info,
    systemParams: state.systemParams.info.conf,
});

export default connect(stateProps, null)(PaymentCreate);
