/* ------------- Types and Action Definition ------------- */
export const BANK_ACTIONS = Object.freeze({
    GetBankList: "BANK_ACTIONS/GetBankList",
    GetBankListSuccess: "BANK_ACTIONS/GetBankListSuccess",
    GetBankListErrors: "BANK_ACTIONS/GetBankListErrors",

    GetProvinceList: "BANK_ACTIONS/GetProvinceList",
    GetProvinceListSuccess: "BANK_ACTIONS/GetProvinceListSuccess",
    GetProvinceListErrors: "BANK_ACTIONS/GetProvinceListErrors",

    GetBranchList: "BANK_ACTIONS/GetBranchList",
    GetBranchListSuccess: "BANK_ACTIONS/GetBranchListSuccess",
    GetBranchListErrors: "BANK_ACTIONS/GetBranchListErrors",
});

// bank list
const getBankList = (payload) => {
    return {
        type: BANK_ACTIONS.GetBankList,
        payload,
    };
};

const getBankListSuccess = (payload) => {
    return {
        type: BANK_ACTIONS.GetBankListSuccess,
        payload,
    };
};

const getBankListErrors = (payload) => {
    return {
        type: BANK_ACTIONS.GetBankListErrors,
        payload,
    };
};

// get province

const getProvinceList = (payload) => {
    return {
        type: BANK_ACTIONS.GetProvinceList,
        payload,
    };
};

const getProvinceListSuccess = (payload) => {
    return {
        type: BANK_ACTIONS.GetProvinceListSuccess,
        payload,
    };
};

const getProvinceListErrors = (payload) => {
    return {
        type: BANK_ACTIONS.GetProvinceListErrors,
        payload,
    };
};

// branch list

const getBranchList = (payload) => {
    return {
        type: BANK_ACTIONS.GetBranchList,
        payload,
    };
};

const getBranchListSuccess = (payload) => {
    return {
        type: BANK_ACTIONS.GetBranchListSuccess,
        payload,
    };
};

const getBranchListErrors = (payload) => {
    return {
        type: BANK_ACTIONS.GetBranchListErrors,
        payload,
    };
};

const bank = {
    getBankList,
    getBankListSuccess,
    getBankListErrors,

    getProvinceList,
    getProvinceListSuccess,
    getProvinceListErrors,

    getBranchList,
    getBranchListSuccess,
    getBranchListErrors,
};

export default bank;
