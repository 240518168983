/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    ButtonBase,
    Icon,
    makeStyles,
    Box,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { useState, memo, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import clsx from "clsx";
import wealthService from "@services/wealth";
import { useHistory } from "react-router-dom";
import useNumber from "@helpers/useNumber";
import Interweave from "interweave";
import { INSTANT_COVERAGE_STEPS } from "@containers/Wealth/wealthConstants";

const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        display: "block",
        width: "100%",
        textAlign: "right",
        cursor: "pointer",
    },

    buttonItem: {
        width: "100%",
        padding: theme.spacing(2.5, 4.25),
        flexDirection: "column",
        textAlign: "left",
        background: theme.palette.background.header,
        marginBottom: theme.spacing(1.5),
        boxShadow: theme.shadows[3],
        "&.selected": {
            borderTop: `4px solid ${theme.palette.blue.main}`,
            width: "104%",
            marginLeft: theme.spacing(-1.5),
            background: theme.palette.background.paper,
            " & > div > p": {
                color: theme.palette.blue.main,
            },
        },
    },

    infoPackage: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
}));

const RightPage = ({
    rightPackage,
    setProkeySelected,
    prokeySelected,
    stepForward,
    setPackageDetail,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { formatNumber } = useNumber();
    const theme = useTheme();

    const unmounted = useRef(false);
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const selectPackage = async () => {
        setLoading(true);
        const { data } = await wealthService.getSubProducts(prokeySelected);
        if (unmounted.current) return;
        setLoading(false);

        if (data.data) {
            setPackageDetail(data.data);
            if (isSmDown) {
                stepForward(INSTANT_COVERAGE_STEPS.MOBILE_INTRO);
                return;
            }
            stepForward(INSTANT_COVERAGE_STEPS.FORM_INFO);
        }
    };

    return (
        <Content size="xs">
            <div>
                <Icon
                    onClick={() => history.push("products")}
                    className={`font-icon icon-close ${classes.closeIcon}`}
                />
                <div className={classes.boxTitle}>
                    <Icon
                        className={`font-icon icon-selectproduct ${classes.icon}`}
                    />
                    <LspTypography variant="heading1" color="primary">
                        {t("wealth:SI00097")}
                    </LspTypography>
                </div>
                <div>
                    {rightPackage.map((value, key) => (
                        <ButtonBase
                            className={clsx(classes.buttonItem, {
                                selected: value.prokey === prokeySelected,
                            })}
                            key={key}
                            onClick={() => setProkeySelected(value.prokey)}
                        >
                            <Box mb={1.25} width="100%">
                                <LspTypography variant="body1" color="primary">
                                    {value.title.toUpperCase()}
                                </LspTypography>
                            </Box>
                            <div className={classes.infoPackage}>
                                <div>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        component="i"
                                    >
                                        {value.txtCoverage}
                                    </LspTypography>
                                    <LspTypography
                                        variant="subheading1"
                                        color="primary"
                                    >
                                        <Interweave
                                            content={value.valCoverage}
                                        />
                                    </LspTypography>
                                </div>
                                <div>
                                    <LspTypography
                                        variant="body2"
                                        color="black"
                                        component="i"
                                    >
                                        {value.txtYouPay}
                                    </LspTypography>

                                    <LspTypography
                                        variant="subheading2"
                                        color="black"
                                    >
                                        {formatNumber(value.valYouPay)}
                                    </LspTypography>
                                </div>
                            </div>
                        </ButtonBase>
                    ))}
                </div>
                <Box mt={3}>
                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={selectPackage}
                        progressing={loading}
                        disabled={!prokeySelected}
                    >
                        {t("lb_next")}
                    </LspButton>
                </Box>
            </div>
        </Content>
    );
};

export default memo(RightPage);
