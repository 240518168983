import { useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { CreditCardNavigationKey } from "@config/constants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import { makeStyles } from "@material-ui/core";
import useAccounts from "@helpers/useAccounts";
import usePathname from "@helpers/usePathname";
import TransactionHistory from "./TransactionHistory";
import Installment from "./Installment";
import Payment from "./Payment";
import Discount from "./Discount";
import Statement from "./Statement";

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: 22,
    },
}));

const CreditCard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const classes = useStyles();

    const { checkSelectedMenu } = usePathname();

    const { allowAccessCredit } = useAccounts();

    const contentRoutes = useMemo(
        () => [
            {
                path: CreditCardNavigationKey.TransactionList,
                component: TransactionHistory,
                default: true,
            },
            {
                path: CreditCardNavigationKey.Installment,
                component: Installment,
            },
            {
                path: CreditCardNavigationKey.Statements,
                component: Statement,
            },

            {
                path: CreditCardNavigationKey.Discount,
                component: Discount,
            },
            {
                path: CreditCardNavigationKey.Payment,
                component: Payment,
            },
        ],
        []
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    const navbarItems = useMemo(
        () => [
            {
                title: "lb_transactions",
                icon: "transaction-v3",
                path: CreditCardNavigationKey.TransactionList,
                selected: checkSelectedMenu(location.pathname, [
                    CreditCardNavigationKey.TransactionList,
                    CreditCardNavigationKey.Payment,
                ]),
                iconClasses: classes.icon,
            },
            {
                title: "mc_menu_label_installment",
                icon: "installment-v3",
                path: CreditCardNavigationKey.Installment,
                selected: checkSelectedMenu(
                    location.pathname,
                    CreditCardNavigationKey.Installment
                ),
            },
            {
                title: "mastercard_statement",
                icon: "statement-v3",
                path: CreditCardNavigationKey.Statements,
                selected: checkSelectedMenu(
                    location.pathname,
                    CreditCardNavigationKey.Statements
                ),
            },
            {
                title: "discount_lb",
                icon: "discount-v3",
                path: CreditCardNavigationKey.Discount,
                selected: checkSelectedMenu(
                    location.pathname,
                    CreditCardNavigationKey.Discount
                ),
            },
        ],
        [location, classes, checkSelectedMenu]
    );

    return (
        <>
            <Banner title={t("timo_mastercard")} navbarItems={navbarItems} />
            <ContentWrapper>
                <Switch>
                    {allowAccessCredit && (
                        <>
                            {contentRoutes.map((r) => {
                                return (
                                    <Route
                                        key={r.path}
                                        path={r.path}
                                        component={r.component}
                                    />
                                );
                            })}
                        </>
                    )}

                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect
                                    to={
                                        allowAccessCredit
                                            ? defaultRoutes.path
                                            : "/home"
                                    }
                                />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};

export default CreditCard;
