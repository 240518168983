import { call, put } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import bookingService from "@services/booking";
import bookingActions from "@redux/actions/booking";
import { RESPONSE_CODE } from "@config/constants";
import { BOOKING_SERVICES, BOOKING_TYPES } from "@containers/Booking/constant";

export function* getBookingList(action) {
    const { payload } = action;

    let response;
    switch (payload.bookingType) {
        case BOOKING_TYPES.CREDIT_CARD:
            response = yield call(bookingService.getBookingList, payload);
            break;
        case BOOKING_TYPES.KYC:
            response = yield call(bookingService.getJoinBookingList, payload);
            break;
        case BOOKING_TYPES.REPLACE_CHIP_CARD:
            response = yield call(bookingService.getBookingListV3, payload);
            break;
        default:
            break;
    }

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(bookingActions.getBookingListSuccess(data.availability));
            break;
        default:
            yield put(bookingActions.getBookingListError());
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
}

export function* getBookingDetail(action) {
    const { payload } = action;

    console.log("payload", payload);

    let response;
    switch (payload?.serviceName) {
        case BOOKING_SERVICES.EKYC_BOOKING:
            response = yield call(bookingService.getBookingDetailEkyc, {
                ekycId: payload?.id,
            });
            break;
        case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
            response = yield call(
                bookingService.getBookingDetailReplaceChipCard,
                {
                    replaceCardId: payload?.id,
                    bookingType: payload?.serviceName,
                }
            );
            break;
        default:
            break;
    }

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(bookingActions.getBookingDetailSuccess(data));
            break;
        default:
            yield put(bookingActions.getBookingDetailError());
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
}
