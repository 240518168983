/* ------------- Types Definition ------------- */
export const WealthActionType = Object.freeze({
    GetProductListRequest: "WealthActionType/GetProductListRequest",
    GetProductListSuccess: "WealthActionType/GetProductListSuccess",
    GetProductListError: "WealthActionType/GetProductListError",
});

/* ------------- Actions Definition ------------- */

const getProductListRequest = () => {
    return {
        type: WealthActionType.GetProductListRequest,
    };
};
const getProductListSuccess = (payload) => {
    return {
        type: WealthActionType.GetProductListSuccess,
        payload,
    };
};
const getProductListError = () => {
    return {
        type: WealthActionType.GetProductListError,
    };
};

const wealthAction = {
    getProductListRequest,
    getProductListSuccess,
    getProductListError,
};

export default wealthAction;
