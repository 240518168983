import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import tommyScared from "@assets/images/tommy_scared_status.png";
import tommyNoHope from "@assets/images/no_hope_with_status_error.png";
import tommyCry from "@assets/images/tommy_cry_status.png";
import tommySuccess from "@assets/images/tommy_success_status.png";
import LspTranslation from "@components/LspTranslation";

const closeDialog = () => GlobalDialogController.hide();

const openDialog = (content) =>
    GlobalDialogController.show({
        component: () => (
            <ConditionNotificationDialog content={content} centerImg />
        ),
    });

export const inValidNickname = () => {
    const contents = {
        banner: tommyScared,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_title" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_blacklist_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_btn_ok" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const existNickname = () => {
    const contents = {
        banner: tommyScared,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_title" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_existing_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_btn_ok" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const blackListNickname = () => {
    const contents = {
        banner: tommyScared,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_title" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_blacklist_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_btn_ok" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const maxDailyLimit = () => {
    const contents = {
        banner: tommyNoHope,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_exceed_limit_title_web" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_exceed_limit_desc_web" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_btn_ok" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const notEnoughMoney = () => {
    const contents = {
        banner: tommyNoHope,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_no_enough_money_title_web" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_no_enough_money_desc_web" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_btn_close" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const maxLimitEKyc = () => {
    const contents = {
        banner: tommyNoHope,
        title: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_exceed_limit_title_web" />
        ),
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_exceed_limit_desc_web" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_popup_reject_btn_ok" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const createNicknameFail = () => {
    const contents = {
        banner: tommyCry,
        title: <LspTranslation i18nKey="IBFT:IBFT_nickname_failed_title" />,
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_failed_general_error_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_btn_close" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const errorCreateNickname = () => {
    const contents = {
        banner: tommyCry,
        title: <LspTranslation i18nKey="IBFT:IBFT_nickname_failed_title" />,
        description: (
            <LspTranslation
                i18nKey="IBFT:IBFT_nickname_failed_insert_nickname_desc"
                replacingKeys={["hangout:callcenter_number"]}
                hyperlink
            />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_btn_close" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};

export const createAliasSuccess = () => {
    const contents = {
        banner: tommySuccess,
        title: <LspTranslation i18nKey="IBFT:IBFT_nickname_success_title" />,
        description: (
            <LspTranslation i18nKey="IBFT:IBFT_nickname_success_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="IBFT:IBFT_nickname_btn_close" />
                ),
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};
