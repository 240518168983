/* eslint-disable react/no-array-index-key */
import { Paper } from "@material-ui/core";
import { memo, useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import LspTextField from "@components/LspTextField";
import { nationalities } from "@i18n/resources/nationalities.json";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import { KeyboardDatePicker } from "@material-ui/pickers";
import format from "date-fns/format";
import parse from "date-fns/parse";
import subYears from "date-fns/subYears";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import { idPlaceIssuedList } from "@i18n/resources/placeofissued_list.json";
import { Autocomplete } from "@material-ui/lab";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import usePhoneNumber from "@helpers/usePhoneNumber";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

import { WEALTH_CREDIT_CARD_STEPS } from "../wealthConstants";

const PERSONAL_FORM = {
    YEARS_EXPIRE_CARDID: 15,
    YEARS_EXPIRE_PASSPORT: 10,
    NEW_FORMAT_CARD_ID_LENGTH: 12,
    MIN_INCOME: 4500000,
    DOC_TYPE_PASSPORT: "PV",
    DOC_TYPE_CARD_ID: "IC",
};

const WealthCreditCardPersonalForm = ({
    onNext,
    onBack,
    user,
    setPersonalForm,
    personalForm,
    serverTime,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const {
        characterValidation,
        replaceSpecialCharacter,
    } = useInputSpecialCharacter();

    const { isPhoneNumberValid } = usePhoneNumber();

    const [errors, setErrors] = useState({
        documentId: null,
        email: null,
        phoneNumber: null,
        placeOfIssue: null,
        dateOfIssue: null,
    });

    // ------ Start: Init params ------

    const [phoneNumber, setPhoneNumber] = useState(
        personalForm?.phoneNumber || user?.phoneNumber
    );
    const [email, setEmail] = useState(personalForm?.email || user?.userEmail);

    const [documentId, setDocumentId] = useState(() => {
        if (personalForm?.documentId) {
            return personalForm?.documentId;
        }

        if (user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT) {
            return user?.passport.replace(".", "");
        }

        return user?.legalId.replace(".", "") || "";
    });

    const [dateOfIssue, setDateOfIssue] = useState(() => {
        if (personalForm?.dateOfIssue) {
            return parse(personalForm?.dateOfIssue, "dd/MM/yyyy", new Date());
        }
        // const dateString = "2006-10-04";
        const dateString =
            user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT
                ? user?.passportIssueDate
                : user?.docIssueDate;

        return parse(dateString, "yyyy-MM-dd", new Date()) || "";
    });

    const [placeOfIssue, setPlaceOfIssue] = useState(() => {
        if (personalForm?.placeOfIssue) {
            return personalForm?.placeOfIssue;
        }

        if (user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT) {
            return {
                key: "",
                value: user?.passportIssuePlace,
            };
        }

        return { value: user?.docIssuePlace } || {};
    });
    const nationality = useMemo(() => {
        const item = nationalities?.filter((i) => i.key === user?.nationality);
        return item[0]?.value ?? "";
    }, [user?.nationality]);

    // ------ End: Init params ------

    // ------ Start: On change handler function ------

    const onChangeDocumentId = (e) => {
        const { value } = e.target;
        setDocumentId(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                documentId: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                documentId: "",
            }));
        }
    };

    const onChangePhoneNumber = (e) => {
        const { value } = e.target;
        setPhoneNumber(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            })
        );

        if (!value) {
            setErrors((prev) => ({
                ...prev,
                phoneNumber: t("wealth:mc_v3_error_we_need"),
            }));
        } else if (!isPhoneNumberValid(value)) {
            setErrors((prev) => ({
                ...prev,
                phoneNumber: t("wealth:mc_v3_error_phone_msg"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                phoneNumber: "",
            }));
        }
    };

    const onChangeEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                email: t("wealth:mc_v3_error_we_need"),
            }));
        } else if (!characterValidation(value, REGEX_PATTERN.EMAIL)) {
            setErrors((prev) => ({
                ...prev,
                email: t("wealth:mc_v3_error_email_msg"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                email: "",
            }));
        }
    };

    const onChangePlaceOfIssue = ({ value }) => {
        setPlaceOfIssue(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                placeOfIssue: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                placeOfIssue: "",
            }));
        }
    };
    const onChangeDateOfIssue = (d) => {
        if (!d) {
            setDateOfIssue(null);
            setErrors((prev) => ({
                ...prev,
                dateOfIssue: t("wealth:mc_v3_error_we_need"),
            }));
            return;
        }

        setErrors((prev) => ({
            ...prev,
            dateOfIssue: "",
        }));
        setErrors((prev) => ({
            ...prev,
            documentId: "",
        }));
        setDateOfIssue(new Date(d));
    };

    // ------ End: On change handler function ------

    // ------ Start: On submit form handler function ------

    const hasExpiredDateOfIssue = useCallback(() => {
        const limitYear =
            user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT
                ? PERSONAL_FORM.YEARS_EXPIRE_PASSPORT
                : PERSONAL_FORM.YEARS_EXPIRE_CARDID;

        // const parsedCurrentDate = parse("29/07/2021", "dd/MM/yyyy", new Date());
        const parsedCurrentDate = serverTime
            ? parse(serverTime, "dd/MM/yyyy", new Date())
            : new Date();
        const minDate = subYears(parsedCurrentDate, limitYear);

        return differenceInCalendarDays(dateOfIssue, minDate) < 1;
    }, [dateOfIssue, serverTime, user?.docType]);

    const hasChangePersonalInfo = useCallback(() => {
        const originalDocId =
            user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT
                ? user?.passport.replace(".", "")
                : user?.legalId.replace(".", "");

        const dateString =
            user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT
                ? user?.passportIssueDate
                : user?.docIssueDate;

        const originalDateOfIssue =
            parse(dateString, "yyyy-MM-dd", new Date()) || "";

        const originalPlaceOfIssue =
            user?.docType === PERSONAL_FORM.DOC_TYPE_PASSPORT
                ? user?.passportIssuePlace
                : user?.docIssuePlace;

        return (
            documentId !== originalDocId ||
            differenceInCalendarDays(dateOfIssue, originalDateOfIssue) !== 0 ||
            placeOfIssue?.value !== originalPlaceOfIssue ||
            user?.userEmail !== email ||
            user?.phoneNumber !== phoneNumber
        );
    }, [user, dateOfIssue, placeOfIssue, phoneNumber, email, documentId]);

    const changeInfoConfirmation = () => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                header: t("wealth:mc_v3_info_changed_title"),
                content: t("wealth:mc_v3_edit_info"),
                contact: t("wealth:mc_v3_edit_info2").replace(
                    "%@",
                    t("wealth:mc_v3_timocare_no")
                ),
                button: t("lb_ok"),
            },
            onClose: () => {
                onNext(WEALTH_CREDIT_CARD_STEPS.ADDRESS_FORM);
            },
        });
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        if (hasExpiredDateOfIssue()) {
            setErrors((prev) => ({
                ...prev,
                dateOfIssue: t("wealth:mc_v3_error_doi_expired"),
            }));

            setErrors((prev) => ({
                ...prev,
                documentId: t("wealth:mc_v3_error_doi_expired"),
            }));
            return;
        }

        const payload = {
            documentId,
            dateOfIssue: format(dateOfIssue, "dd/MM/yyyy"),
            placeOfIssue,
            phoneNumber,
            email,
            isChangeInfo: false,
        };

        if (hasChangePersonalInfo()) {
            changeInfoConfirmation();
            payload.isChangeInfo = true;
        } else {
            onNext(WEALTH_CREDIT_CARD_STEPS.ADDRESS_FORM);
        }

        setPersonalForm(payload);
    };

    const onBackHandler = () => {
        setPersonalForm(null);
        onBack();
    };

    // ------ End: On submit form handler function ------

    return (
        <form onSubmit={onSubmitForm}>
            <PageHeaderBack onBack={onBackHandler}>
                {t("wealth:mc_v3_per_inf_title")}
            </PageHeaderBack>
            <Paper>
                <div className={sharedClasses.formHeader}>
                    <LspTypography variant="heading3" color="grey">
                        {t("wealth:mc_v3_per_inf_title")}
                    </LspTypography>
                </div>
                <div className={sharedClasses.boxFormWrapper}>
                    <LspTextField
                        name="fullName"
                        value={user?.fullName}
                        label={t("wealth:mc_v3_personal_info_name_lb")}
                        disabled
                    />
                    <LspTextField
                        name="nationality"
                        value={nationality}
                        label={t("wealth:mc_v3_personal_info_nationality_lb")}
                        disabled
                    />
                    <LspTextField
                        autoFocus
                        error={!!errors?.documentId}
                        name="documentId"
                        value={documentId}
                        label={t("wealth:mc_v3_personal_info_id")}
                        helperText={errors?.documentId || ""}
                        inputProps={{
                            maxLength: 20,
                        }}
                        onChange={onChangeDocumentId}
                    />

                    <KeyboardDatePicker
                        autoOk
                        disableFuture
                        openTo="year"
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        variant="inline"
                        onChange={onChangeDateOfIssue}
                        disableToolbar
                        value={dateOfIssue}
                        TextFieldComponent={(params) => {
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("wealth:mc_v3_spouse_doi")}
                                    error={!!errors?.dateOfIssue}
                                    helperText={errors?.dateOfIssue || " "}
                                />
                            );
                        }}
                    />

                    <Autocomplete
                        options={idPlaceIssuedList}
                        getOptionLabel={(option) => option?.value || ""}
                        renderOption={(option) => <>{option.value}</>}
                        value={placeOfIssue}
                        onChange={(_, value) => onChangePlaceOfIssue({ value })}
                        renderInput={(params) => {
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("wealth:mc_v3_personal_info_poi")}
                                    error={!!errors?.placeOfIssue}
                                    helperText={errors?.placeOfIssue || " "}
                                />
                            );
                        }}
                    />

                    <LspTextField
                        error={!!errors?.phoneNumber}
                        name="phoneNumber"
                        label={t("wealth:mc_v3_personal_info_phone")}
                        value={phoneNumber}
                        helperText={errors?.phoneNumber || ""}
                        onChange={onChangePhoneNumber}
                    />
                    <LspTextField
                        error={!!errors?.email}
                        name="email"
                        value={email}
                        label={t("wealth:mc_v3_personal_info_email")}
                        helperText={errors?.email || " "}
                        onChange={onChangeEmail}
                    />
                </div>
            </Paper>
            <LspButton
                className={sharedClasses.button}
                fullWidth
                type="submit"
                disabled={
                    !!errors?.documentId ||
                    !!errors?.email ||
                    !!errors?.phoneNumber ||
                    !!errors?.placeOfIssue ||
                    !!errors?.dateOfIssue
                }
            >
                {t("wealth:mc_v3_btn_next")}
            </LspButton>
        </form>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    personalForm: state.wealthCreditCard.personalForm,
    serverTime: state.user.serverTime.value,
});

const mapDispatch = (dispatch) => ({
    setPersonalForm: (payload) =>
        dispatch(wealthCreditCardAction.setPersonalForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardPersonalForm)
);
