import api from "../config/api";
import { PayeeDestinationType } from "../config/constants";

const getPayee = async ({ payeeId, payeeType }) => {
    return await api.get("user/payee/detail", { payeeId, payeeType });
};

const savePayee = async ({ destinationType, ...payload }) => {
    return await api.post(`/user/payee/${destinationType}/add`, {
        ...payload,
        type:
            destinationType === PayeeDestinationType.BankAccount
                ? PayeeDestinationType.BankAccount.toLowerCase()
                : "cardnumber",
    });
};

const updatePayee = async (payload) => {
    return await api.post(`/user/payee/update`, payload);
};

const deleteDestination = async (payload) => {
    return await api.post("/user/payee/destination/delete", payload);
};

const deletePayee = async (payload) => {
    return await api.post("/user/payee/delete", payload);
};

const payeeService = {
    getPayee,
    savePayee,
    updatePayee,
    deleteDestination,
    deletePayee,
};

export default payeeService;
