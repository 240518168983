import { Box, CircularProgress, Hidden } from "@material-ui/core";
import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import wealthService from "@services/wealth";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import {
    MANAGE_INSTANT_STEPS,
    WEALTH_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import { GENDER_LIST, RESPONSE_CODE } from "@config/constants";
import LspCongratulation from "@components/LspCongratulation";
import { useHistory } from "react-router-dom";
import useStepNavigation from "@helpers/useStepNavigation";
import { parseInt } from "lodash";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LeftPage from "./LeftPage";
import RightPage from "./RightPage";

const LifeInsuranceManageInstant = () => {
    const { t } = useTranslation();
    const classes = useClassesShared();
    const [loading, setLoading] = useState(false);

    const { step, stepForward } = useStepNavigation({
        initialStep: "",
    });
    const [dataManageIns, setDataManageIns] = useState(null);
    const history = useHistory();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const leftContentTitle = useMemo(() => {
        const leftContentManage = {};
        if (dataManageIns) {
            leftContentManage.prokey = dataManageIns.product.productKey;
            leftContentManage.txtHeader = t("wealth:SI00065");
            leftContentManage.txtYouPayHeader = t("wealth:SI00106");
            leftContentManage.valYouPayHeader = dataManageIns.product.amount;
            leftContentManage.coverageDuration =
                dataManageIns.product.coverageDuration;
            leftContentManage.productName = t(
                `wealth:SI00058_${dataManageIns.product.productKey}`
            );

            leftContentManage.beneficiary = {};
            leftContentManage.beneficiary.name = dataManageIns.beneficiary.name;
            const content = t(`wealth:SI00039`, {
                returnObjects: true,
                defaultValue: "",
            });

            content.forEach((value) => {
                if (
                    value[0].toLowerCase() ===
                    dataManageIns.beneficiary.relationship.toLowerCase()
                ) {
                    // eslint-disable-next-line prefer-destructuring
                    leftContentManage.beneficiary.relationship = value[1];
                }
            });

            leftContentManage.beneficiary.dateOfBirth =
                dataManageIns.beneficiary.dateOfBirth;
            if (!dataManageIns.beneficiary.legalId) {
                leftContentManage.beneficiary.legalId = t("wealth:SI00090");
            } else {
                leftContentManage.beneficiary.legalId =
                    dataManageIns.beneficiary.legalId;
            }
            leftContentManage.beneficiary.gender =
                dataManageIns.beneficiary.gender === GENDER_LIST[0].cfgKey ||
                dataManageIns.beneficiary.gender ===
                    parseInt(GENDER_LIST[0].cfgKey)
                    ? t(GENDER_LIST[0].cfgValue)
                    : t(GENDER_LIST[1].cfgValue);
        }
        return leftContentManage;
    }, [dataManageIns, t]);

    const leftDetailPackage = useMemo(() => {
        const subContent = {};
        if (dataManageIns) {
            subContent.panelHeader = t("wealth:SI00066");
            subContent.panelBody = t(
                `wealth:SI00014_${dataManageIns.product.productKey}`,
                {
                    returnObjects: true,
                    defaultValue: "",
                }
            );
            subContent.panelFooter = t("wealth:SI00010", {
                returnObjects: true,
                defaultValue: "",
            });
        }

        return [subContent];
    }, [dataManageIns, t]);

    const leftDetailPackage2 = useMemo(() => {
        const subContent = {};
        const contentArr = t(`wealth:SI00055`, {
            returnObjects: true,
            defaultValue: "",
        });
        // eslint-disable-next-line prefer-destructuring
        subContent.panelHeader = contentArr[0];
        subContent.panelBody = contentArr;
        subContent.panelFooter = t("wealth:SI00056", {
            returnObjects: true,
            defaultValue: "",
        });
        return [subContent];
    }, [t]);

    const getContentManageLeft = useCallback(async () => {
        setLoading(true);
        const { data } = await wealthService.checkWealthProductStatus(
            WEALTH_PRODUCT_KEYS.LIFE_INSURANCE_INSTANT_COVERAGE
        );
        if (unmounted.current) {
            return;
        }
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setDataManageIns(data.data);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, []);

    useEffect(() => {
        getContentManageLeft();
    }, [getContentManageLeft]);

    return (
        <>
            {loading && (
                <Box textAlign="center" paddingTop={3}>
                    <CircularProgress size={18} />
                </Box>
            )}

            {!loading && (
                <div className={classes.wrapper}>
                    {/* For small devices: hide left side column & document will be display when users click apply on form */}
                    <Hidden smDown>
                        <div className={classes.leftSide}>
                            <LeftPage
                                leftContentTitle={leftContentTitle}
                                leftDetailPackage={leftDetailPackage}
                                leftDetailPackage2={leftDetailPackage2}
                            />
                        </div>
                    </Hidden>
                    {/* For small devices:  users click apply > show document > next > submit form */}
                    <div className={classes.rightSide}>
                        {!step.current && (
                            <RightPage
                                step={step}
                                stepForward={stepForward}
                                leftContentTitle={leftContentTitle}
                                leftDetailPackage={leftDetailPackage}
                                leftDetailPackage2={leftDetailPackage2}
                            />
                        )}
                        {step.current ===
                            MANAGE_INSTANT_STEPS.CONGRATULATION && (
                            <LspCongratulation
                                icon="like"
                                title={t("wealth:SI00072")}
                                contents={`${t("wealth:SI00033")}</br></br>${t(
                                    "wealth:SI00034"
                                )}`}
                                button={{
                                    label: t("lb_done"),
                                    onClick: () => {
                                        history.push(`products`);
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default LifeInsuranceManageInstant;
