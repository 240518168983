import {
    Box,
    Button,
    Grid,
    Icon,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import useInfiniteScroll from "react-infinite-scroll-hook";
import lixiActions from "../../redux/actions/lixi";
import {
    LIXI_ACTIONS,
    PAY_ANYONE_STATUSES,
    PAY_ANYONE_TYPES,
} from "./constant";
import backgroundImg from "../../assets/images/lixi_btn.png";
import backgroundImgHover from "../../assets/images/lixi_btn_hover.png";
import LixiListLoading from "./LixiListLoading";
import useNumber from "../../helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
    },
    sendBtn: {
        margin: "auto",
        width: "100%",
        maxWidth: theme.spacing(50),
        background: `url(${backgroundImg}) no-repeat top right; background-size: 100% 100%`,
        color: theme.palette.primary.main,
        "&:hover": {
            background: `url(${backgroundImgHover}) no-repeat top right; background-size: 100% 100%`,
            color: "white",
        },
    },
    list: {
        width: "100%",
        // fontSize: theme.typography.pxToRem(12),
        // [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        // },
    },
    headerIcon: {
        display: "inline-block",
        width: "10px",
        height: "10px",
        background: theme.palette.gold.main,
        borderRadius: "50%",
        marginRight: theme.spacing(1),
    },
    headerList: {
        width: "100%",
        background: "#f1f1f1",
        padding: theme.spacing(1, 2),
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "16px auto",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: theme.typography.pxToRem(16),
    },
    itemWrapper: {
        color: "white",
        borderRadius: "12px",
        padding: theme.spacing(1.5),
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
            // fontSize: theme.typography.pxToRem(14),
        },
    },
    image: {
        width: "100%",
        height: "100%",
        margin: theme.spacing(3, "auto", 2),
        borderRadius: "12px",
    },
    amount: {
        // fontSize: theme.typography.pxToRem(16),
        // [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(20),
        // },
    },
    name: {
        textAlign: "center",
        // [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        // },
    },
    emptyList: {
        marginTop: theme.spacing(2),
    },
}));

const LixiList = ({
    sendBtnClick,
    unreadList,
    readList,
    listLoading,
    type,
    getReadList,
    getUnreadList,
    hasMore,
    typeList,
    resetState,
    lastId,
    hasEmptyList,
    dashboardInfo,
    onClickLixiItem,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation("translation", "lixi");
    const { formatNumber } = useNumber();
    const [firstLoaded, setFirstLoaded] = useState(false);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    const callGetLixiList = useCallback(() => {
        let status = typeList || "";

        if (status === "") {
            switch (type) {
                case LIXI_ACTIONS.RECEIVE:
                    status = PAY_ANYONE_STATUSES.UNREAD;
                    break;
                case LIXI_ACTIONS.SEND:
                    status = "";
                    break;
                default:
                    break;
            }
        }

        const dataToPost = {
            paoType: PAY_ANYONE_TYPES.LIXI,
            action: type,
            status,
            lastId,
        };

        if (status === PAY_ANYONE_STATUSES.UNREAD) {
            getUnreadList(dataToPost);
        } else {
            getReadList(dataToPost);
        }
    }, [type, typeList, getReadList, getUnreadList, lastId]);

    // load list in the first time
    useEffect(() => {
        if (lastId === -1 && !firstLoaded) {
            callGetLixiList(type);
            setFirstLoaded(true);
        }
    }, [lastId, callGetLixiList, firstLoaded, type]);

    const getThemeList = useCallback(() => {
        const arrayThemes = t("lixi:LIXI_available_theme_keys", {
            returnObjects: true,
        });
        const themeList = [];
        for (let i = 0; i < arrayThemes.length; i++) {
            const themeObj = t(`lixi:${arrayThemes[i]}`, {
                returnObjects: true,
            });
            if (themeObj) {
                themeList.push(themeObj);
            }
        }

        return themeList;
    }, [t]);

    const getThemeDetail = useCallback(
        (themeId) => {
            const themeList = getThemeList();
            return themeList.find((item) => {
                return item.id === themeId;
            });
        },
        [getThemeList]
    );

    const loadMore = () => {
        if (listLoading || !firstLoaded || !hasMore) {
            return;
        }
        callGetLixiList(type, typeList);
    };

    const infiniteRef = useInfiniteScroll({
        loading: listLoading,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const getLixiColor = (themeId) => {
        const themeDetail = getThemeDetail(themeId);
        return themeDetail.color;
    };

    const getLixiImage = (themeId) => {
        const themeDetail = getThemeDetail(themeId);
        return themeDetail.link;
    };

    const isReceiveTab = () => {
        return type === LIXI_ACTIONS.RECEIVE;
    };

    return (
        <div className={classes.container}>
            {!dashboardInfo?.isDisable && (
                <Button
                    className={classes.sendBtn}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={sendBtnClick}
                    startIcon={
                        <Icon className="font-icon icon-Lixi---Sent-a-lixi" />
                    }
                >
                    {t("lixi:lb_send_lixi")}
                </Button>
            )}

            <Box className={classes.wrapper} p={0}>
                {hasEmptyList && !listLoading && (
                    <div className={classes.emptyList}>
                        {type === LIXI_ACTIONS.RECEIVE
                            ? t("lixi:empty_lixi_received")
                            : t("lixi:empty_lixi_sent")}
                    </div>
                )}

                <div className={classes.list} ref={infiniteRef}>
                    {unreadList.length > 0 && (
                        <Box>
                            <div className={classes.headerList}>
                                <div className={classes.headerIcon} />
                                <div>{t("lixi:new_lixi")}</div>
                            </div>
                            <div className={classes.bodyList}>
                                <Grid container spacing={isSmall ? 2 : 4}>
                                    {unreadList.map((item) => {
                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={3}
                                                key={item.giftId}
                                                onClick={() =>
                                                    onClickLixiItem(item.giftId)
                                                }
                                            >
                                                <div
                                                    style={{
                                                        background: getLixiColor(
                                                            item.themeId
                                                        ),
                                                    }}
                                                    className={
                                                        classes.itemWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.amount
                                                        }
                                                    >
                                                        {formatNumber(
                                                            item.amount
                                                        )}
                                                    </div>
                                                    <div>
                                                        <img
                                                            className={
                                                                classes.image
                                                            }
                                                            src={getLixiImage(
                                                                item.themeId
                                                            )}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        {isReceiveTab()
                                                            ? t(
                                                                  "lixi:lixi_from"
                                                              )
                                                            : t("lixi:lixi_to")}
                                                    </div>
                                                    <div
                                                        className={classes.name}
                                                    >
                                                        {item.title}
                                                    </div>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </Box>
                    )}

                    <Box>
                        {readList?.map((list, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index}>
                                    <div className={classes.headerList}>
                                        <div>{list.date}</div>
                                    </div>
                                    <div className={classes.bodyList}>
                                        <Grid
                                            container
                                            spacing={isSmall ? 2 : 4}
                                        >
                                            {list.list?.map((item) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        sm={4}
                                                        md={3}
                                                        key={item.giftId}
                                                        onClick={() =>
                                                            onClickLixiItem(
                                                                item.giftId
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                background: getLixiColor(
                                                                    item.themeId
                                                                ),
                                                            }}
                                                            className={
                                                                classes.itemWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.amount
                                                                }
                                                            >
                                                                {formatNumber(
                                                                    item.amount
                                                                )}
                                                            </div>
                                                            <div>
                                                                <img
                                                                    className={
                                                                        classes.image
                                                                    }
                                                                    src={getLixiImage(
                                                                        item.themeId
                                                                    )}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.label
                                                                }
                                                            >
                                                                {isReceiveTab()
                                                                    ? t(
                                                                          "lixi:lixi_from"
                                                                      )
                                                                    : t(
                                                                          "lixi:lixi_to"
                                                                      )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.name
                                                                }
                                                            >
                                                                {item.title}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </div>
                                </div>
                            );
                        })}
                    </Box>
                </div>
                {listLoading && <LixiListLoading />}
            </Box>
        </div>
    );
};

const stateProps = (state) => ({
    unreadList: state.lixi.unreadList,
    listLoading: state.lixi.listLoading,
    readList: state.lixi.readList,
    lastId: state.lixi.lastId,
    hasMore: state.lixi.hasMore,
    typeList: state.lixi.typeList,
    hasEmptyList: state.lixi.hasEmptyList,
    dashboardInfo: state.lixi.dashboardInfo,
});

const dispatchProps = (dispatch) => ({
    getUnreadList: (payload) =>
        dispatch(lixiActions.getUnreadLixiList(payload)),
    getReadList: (payload) => dispatch(lixiActions.getReadLixiList(payload)),
    resetState: () => dispatch(lixiActions.reset()),
});

export default connect(stateProps, dispatchProps)(LixiList);
