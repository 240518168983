import { initial, isObject } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    RESPONSE_CODE,
    VALIDATION_ERROR_TYPES,
    PRODUCT_TYPES,
    FetchStatus,
    SpendAccountNavigationKey,
} from "@config/constants";
import moveMoneyAction from "@redux/actions/move-money";
import useTransactionValidation from "@containers/SpendAccount/useTransactionValidation";
import usePhoneValidation from "@helpers/usePhoneValidation";
import moveMoneyService from "@services/move-money";

import Content from "@components/Content";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import PaymentDetail from "@containers/SpendAccount/PendingPayment/PaymentDetail";
import ExistingAccount from "src/containers/SpendAccount/MoveMoney/PayAnyone/ExistingAccount";
import PhoneNumber from "src/containers/SpendAccount/MoveMoney/PayAnyone/PhoneNumber";
import Preview from "src/containers/SpendAccount/MoveMoney/PayAnyone/Preview";
import TransferInfo from "src/containers/SpendAccount/MoveMoney/PayAnyone/TransferInfo";
import useAccounts from "@helpers/useAccounts";

const PayAnyone = ({
    reset,
    transferByPhone,
    password,
    transferStatus,
    transferDetail,
    submitting,
    externalData,
}) => {
    const steps = useMemo(() => {
        return [
            "INPUT_PHONE_NUMBER",
            "EXISTING_ACCOUNT",
            "TRANSFER_INFO",
            "PREVIEW",
            "DETAIL",
        ];
    }, []);
    const { t } = useTranslation("translation", "payAnyone");
    const history = useHistory();
    const { spendAccount } = useAccounts();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const { validateAmount, getError } = useTransactionValidation();
    const { phoneValidation } = usePhoneValidation();

    const [prevSteps, setPrevSteps] = useState([steps[0]]);
    const [currentStep, setCurrentStep] = useState(steps[0]);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorPhoneNumber, setErrorPhoneNumber] = useState("");

    const [existingAccountLoading, setExistingAccountLoading] = useState(false);
    const [existingAccount, setExistingAccount] = useState({});

    const transferOptions = useMemo(() => {
        return [
            {
                id: "TRANSFER_BY_ACCOUNT",
                label: t("payAnyone:timo_user_found_content2"),
            },
            {
                id: "TRANSFER_BY_PHONE_NUMBER",
                label: t("payAnyone:timo_user_found_content3"),
            },
        ];
    }, [t]);
    const [transferOption, setTransferOption] = useState("TRANSFER_BY_ACCOUNT");

    const [amount, setAmount] = useState("");
    const [validatingAmount, setValidatingAmount] = useState(false);
    const [description, setDescription] = useState("");

    const initErrors = useMemo(() => {
        return {
            amount: "",
            description: "",
        };
    }, []);
    const [errors, setErrors] = useState(initErrors);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const nextStep = useCallback(
        (step) => {
            if (step) {
                setCurrentStep(step);
                setPrevSteps((prev) => [...prev, step]);
            } else {
                let newStep = "";
                switch (currentStep) {
                    case steps[0]:
                    case steps[1]:
                        // eslint-disable-next-line prefer-destructuring
                        newStep = steps[2];
                        break;
                    case steps[2]:
                        // eslint-disable-next-line prefer-destructuring
                        newStep = steps[3];
                        break;
                    case steps[3]:
                        // eslint-disable-next-line prefer-destructuring
                        newStep = steps[4];
                        break;
                    default:
                        return;
                }
                setCurrentStep(newStep);
                setPrevSteps((prev) => [...prev, newStep]);
            }
        },
        [currentStep, steps]
    );

    const backPrevious = () => {
        if (prevSteps.length > 0) {
            setCurrentStep(prevSteps[prevSteps.length - 2]);
            setPrevSteps(initial(prevSteps));
        }
    };

    // Phone number handler
    const onChangePhoneNumber = (value) => {
        setPhoneNumber(value);
        setErrorPhoneNumber(phoneValidation(value));
    };

    // EXISTING_ACCOUNT
    const checkExistingAccount = async () => {
        setExistingAccountLoading(true);
        const payload = {
            targetInfo: phoneNumber,
            bankAccount: spendAccount.no,
        };
        const response = await moveMoneyService.getInternalAccountDetail(
            payload
        );
        if (!unmounted.current) {
            const { code, data } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    const accountNumber = data?.bank?.accountNumber
                        ? data?.bank?.accountNumber
                        : data?.card?.cardNumber;
                    const info = {
                        bankName: data?.bank?.bankName
                            ? data?.bank?.bankName
                            : data?.card?.bankName,
                        accountNumber,
                        accountName: data?.bank?.accountName
                            ? data?.bank?.accountName
                            : data?.card?.cardName,
                    };
                    setExistingAccount(info);
                    nextStep(steps[1]);
                    break;
                case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                    nextStep();
                    break;
                default:
                    GlobalDialogController.showError({
                        errorCode: code,
                    });
                    break;
            }

            setExistingAccountLoading(false);
        }
    };

    const phoneNumberValidation = (event) => {
        event.preventDefault();
        const errorPhone = phoneValidation(phoneNumber, true);
        if (errorPhone.errorType === VALIDATION_ERROR_TYPES.NO_SUPPORT) {
            setErrorPhoneNumber({
                ...errorPhone,
                errorMsg: t("payAnyone:mm_phone_number_vietnam"),
            });
        } else {
            setErrorPhoneNumber(errorPhone);
        }

        if (errorPhone.valid) {
            checkExistingAccount();
        }
    };

    const confirmExistingInfo = useCallback(
        (event) => {
            event.preventDefault();

            // 0920000146
            if (transferOption === "TRANSFER_BY_PHONE_NUMBER") {
                nextStep();
            } else {
                // direct to move money using member account
            }
        },
        [transferOption, nextStep]
    );

    // TRANSFER_INFO
    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value });
        },
        [errors.amount]
    );

    const confirmTransferInfo = useCallback(
        (event) => {
            event.preventDefault();
            if (!amount?.value) {
                setErrors((prev) => ({
                    ...prev,
                    amount: t("msg_we_need_this"),
                }));
                return;
            }
            validateAmount({
                amount: amount.value,
                beforeRequest: () => setValidatingAmount(true),
                isCancelled: unmounted.current,
                afterResponse: () => setValidatingAmount(false),
                handleAmountInvalid: (resError) => {
                    const error = getError(resError);
                    setErrors((prev) => ({ ...prev, amount: error }));
                },
                handleAmountValid: nextStep,
                transferType: "pa",
            });
        },
        [validateAmount, amount, getError, t, nextStep]
    );

    // PREVIEW SCREEN
    const submit = async () => {
        const payload = {
            amount: amount.value,
            secretCode: password || "",
            paoType: PRODUCT_TYPES.PAY_ANYONE,
            phoneNumber,
            desc: description || t("payAnyone:pao_description"),
        };

        transferByPhone(payload);
    };

    const previewBackHandler = () => {
        if (isObject(externalData)) {
            history.push(
                `/spend-account/${SpendAccountNavigationKey.PendingPayment}/${SpendAccountNavigationKey.PaymentDetail}/${externalData.paId}`
            );
        } else {
            backPrevious();
        }
    };

    const previewCancelHandler = () => {
        history.push(`/spend-account/${SpendAccountNavigationKey.MoveMoney}`);
    };

    // Handler for resend click on payment detail
    useEffect(() => {
        // Open Preview screen
        if (isObject(externalData)) {
            setPhoneNumber(externalData.phoneNumber);
            setAmount({ value: externalData.amount });
            setDescription(externalData.description);
            setCurrentStep(steps[3]);
        } else {
            // Open start screen of flow: input phone number
            setCurrentStep(steps[0]);
        }
    }, [externalData, steps]);

    // Handler for transfer status successfully
    useEffect(() => {
        // Open transfer info
        if (transferStatus === FetchStatus.Success && transferDetail) {
            nextStep();
        }
    }, [transferStatus, nextStep, transferDetail]);

    return (
        <Content size="md">
            {currentStep === "INPUT_PHONE_NUMBER" && (
                <PhoneNumber
                    next={phoneNumberValidation}
                    onChange={onChangePhoneNumber}
                    phoneNumber={phoneNumber}
                    error={errorPhoneNumber}
                    loading={existingAccountLoading}
                />
            )}
            {currentStep === "EXISTING_ACCOUNT" && (
                <ExistingAccount
                    next={confirmExistingInfo}
                    back={backPrevious}
                    existingAccount={existingAccount}
                    transferOption={transferOption}
                    updateTransferOption={(value) => setTransferOption(value)}
                    transferOptions={transferOptions}
                />
            )}
            {currentStep === "TRANSFER_INFO" && (
                <TransferInfo
                    next={confirmTransferInfo}
                    back={backPrevious}
                    phoneNumber={phoneNumber}
                    amount={amount}
                    description={description}
                    onAmountChange={onAmountChange}
                    onChangeDescription={(value) => setDescription(value)}
                    errors={errors}
                    loading={validatingAmount}
                />
            )}
            {currentStep === "PREVIEW" && (
                <Preview
                    description={description}
                    phoneNumber={phoneNumber}
                    amount={amount}
                    next={submit}
                    back={previewBackHandler}
                    cancel={previewCancelHandler}
                    submitting={submitting}
                />
            )}
            {currentStep === "DETAIL" && (
                <PaymentDetail txnId={transferDetail?.paId} isCreate />
            )}
        </Content>
    );
};

const stateProps = (state) => ({
    password: state.moveMoney.passwordPA,
    submitting: state.moveMoney.fetching,
    transferStatus: state.moveMoney.status,
    transferDetail: state.moveMoney.info,
    externalData: state.moveMoney.externalData,
});

const dispatchProps = (dispatch) => ({
    reset: () => dispatch(moveMoneyAction.resetPayAnyoneData()),
    transferByPhone: (payload) =>
        dispatch(moveMoneyAction.transferByPhone(payload)),
});
export default connect(stateProps, dispatchProps)(PayAnyone);
