/* eslint-disable react/no-array-index-key */
import { Box, makeStyles } from "@material-ui/core";
import TransactionItem from "./TransactionItem";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        header: {
            fontWeight: "600",
            fontSize: theme.typography.pxToRem(14),
            backgroundColor: isDark
                ? theme.palette.grey[700]
                : theme.palette.neutral.grey3,
            color: isDark
                ? theme.palette.primary.contrastText
                : theme.palette.text.primary,
        },
    };
});

const TransactionGroup = ({
    header,
    items = [],
    onItemClick,
    type,
    enableAddPayee = false,
    showRemainBalance = false,
    hiddenRemainBalanceOption = false,
}) => {
    const classes = useStyles();

    return (
        <Box marginY={2}>
            <Box px={2} py={1} className={classes.header}>
                {header}
            </Box>
            <Box paddingY={1.5}>
                {items.map((txn, idx) => (
                    /*
                        API will return duplicate records (with the same ID), 
                        add index to the key to prevent error on browsers
                    */
                    <TransactionItem
                        onClick={onItemClick ? () => onItemClick(txn) : null}
                        key={`${txn.bankXID}-${idx}`}
                        {...txn}
                        type={type}
                        enableAddPayee={enableAddPayee}
                        showRemainBalance={showRemainBalance}
                        hiddenRemainBalanceOption={hiddenRemainBalanceOption}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default TransactionGroup;
