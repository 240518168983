import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import billActions from "@redux/actions/bill";
import { isEmpty } from "lodash";
import { useEffect, memo, useMemo } from "react";

import { v4 as uuidv4 } from "uuid";

import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { METRIC_NAME, METRIC_SCREEN } from "@config/constants";
import metricActions from "@redux/actions/metric";
import NotFoundBill from "../NotFoundBill";
import SplitBillFooter from "../SplitBillFooter";
import SplitBillNav from "../SplitBillNav";
import PaymentContent from "./PaymentContent";
import PaymentHeader from "./PaymentHeader";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100vw",
        margin: "auto",
        overflow: "auto",
        height: "100%",
        border: `solid 1px ${theme.palette.magicalGrey[200]}`,
    },
    wrapperDesktop: {
        width: "100vw",
        backgroundColor: theme.palette.grey[200],
        maxWidth: 432,
        margin: "auto",
    },
    splitBillComponent: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        minHeight: "100%",
    },
    splitBillComponentHeader: {},
    splitBillComponentBody: {
        padding: theme.spacing(2),
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    splitBillComponentFooter: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.neutral.white,
    },
    splitBillComponentBodyHeader: {},
    splitBillComponentBodyContent: {
        backgroundColor: theme.palette.neutral.white,
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
    },
    splitBillComponentCancel: {
        "& $splitBillComponent": {
            backgroundColor: "unset",
        },
        "& $splitBillComponentFooter": {
            backgroundColor: "unset",
        },
    },
}));

const SplitBillPayment = ({
    payment,
    getSplitBillPayment,
    fetching,
    metricConfig,
    metricRequest,
    metricSession,
}) => {
    const { verifyCode } = useParams();
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    useEffect(() => {
        if (isEmpty(payment)) {
            getSplitBillPayment({ id: verifyCode });
        }

        if (!isEmpty(payment)) {
            const sessionId =
                query.get("appSessionId") ||
                metricConfig.appSessionId ||
                uuidv4();
            const screenSessionId = uuidv4();

            metricSession({ appSessionId: sessionId, screenSessionId });

            const time = new Date().getTime();
            const metricData = {
                data: {
                    detail_data: {
                        app_session_id: sessionId || "",
                        screen_session_id: screenSessionId || "",
                        previous_event_id:
                            query.get("previousEventId") ||
                            metricConfig.previous_event_id,
                    },
                    error_code: "",
                    error_message: "",
                    event_id: uuidv4() || "",
                    flow_name: "",
                    metric_location: "WEB",
                    previous_event_name:
                        query.get("previousScreenName") ||
                        metricConfig.previous_event_name,
                    previous_screen_name:
                        query.get("previousMetricName") ||
                        metricConfig.previous_screen_name,
                    screen_name: METRIC_SCREEN.SPLIT_BILL_PAYMENT,
                    metric_name: METRIC_NAME.START,
                    time_stamp_number: time || "",
                },
                force: true,
            };
            metricRequest(metricData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment, getSplitBillPayment, verifyCode, metricRequest]);

    const fetchingLayer = useMemo(
        () => (
            <Box>
                <div className={classes.splitBillComponentBodyHeader}>
                    <PaymentHeader />
                </div>
                <div className={classes.splitBillComponentBodyContent}>
                    <PaymentContent />
                </div>
            </Box>
        ),
        [
            classes.splitBillComponentBodyContent,
            classes.splitBillComponentBodyHeader,
        ]
    );

    return (
        <>
            <div
                className={`${classes.wrapper} ${
                    isEmpty(payment) && !fetching
                        ? classes.splitBillComponentCancel
                        : ""
                } ${!isMobile ? classes.wrapperDesktop : ""}`}
            >
                <div className={classes.splitBillComponent}>
                    <div className={classes.splitBillComponentHeader}>
                        <SplitBillNav />
                    </div>

                    <div className={classes.splitBillComponentBody}>
                        {fetching && fetchingLayer}
                        {isEmpty(payment) && !fetching && <NotFoundBill />}
                        {!isEmpty(payment) && (
                            <Box>
                                <div
                                    className={
                                        classes.splitBillComponentBodyHeader
                                    }
                                >
                                    <PaymentHeader />
                                </div>
                                <div
                                    className={
                                        classes.splitBillComponentBodyContent
                                    }
                                >
                                    <PaymentContent />
                                </div>
                            </Box>
                        )}
                    </div>

                    <div className={classes.splitBillComponentFooter}>
                        <SplitBillFooter
                            activeTermsConditions={!isEmpty(payment)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const stateProps = (state) => ({
    payment: state.bill.splitBillPayment.payment,
    fetching: state.bill.splitBillPayment.fetching,
    metricConfig: state.metrics?.metricConfig,
});

const dispatchProps = (dispatch) => ({
    getSplitBillPayment: (payload) =>
        dispatch(billActions.getSplitBillPayment(payload)),
    metricRequest: (payload) =>
        dispatch(metricActions.metricRequestUpdate(payload)),
    metricSession: (payload) => dispatch(metricActions.metricSession(payload)),
});
export default memo(connect(stateProps, dispatchProps)(SplitBillPayment));
