import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { MoveMoneyActionType } from "../actions/move-money";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    refNo: null,
    info: null,
    existingPayee: null,
    errorCodeAmount: null,
    passwordPA: null,
    passwordPAOption: false,
    disabledPasswordOption: false,
    externalData: null,
};

/* ------------- Reducer ------------- */
const moveMoneyReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case MoveMoneyActionType.TransferToDebitCardRequest:
            case MoveMoneyActionType.TransferToAccountNumberRequest:
            case MoveMoneyActionType.TransferToCreditCardRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                draft.errorCodeAmount = null;
                break;
            case MoveMoneyActionType.TransferSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.errorCodeAmount = null;
                draft.refNo = null;
                draft.info = { ...action.payload };
                break;
            case MoveMoneyActionType.TransferError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case MoveMoneyActionType.TransferErrorWithAmount:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                draft.errorCodeAmount = action.payload;
                break;
            case MoveMoneyActionType.TransferWithOTP:
                const { refNo } = action.payload;
                draft.refNo = refNo;
                draft.fetching = false;
                break;
            case MoveMoneyActionType.RemoveExistingPayee:
                draft.existingPayee = null;
                break;
            case MoveMoneyActionType.GetExistingPayeeSuccess:
                draft.fetching = false;
                draft.existingPayee = action.payload;
                break;
            case MoveMoneyActionType.SubmitOTP:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case MoveMoneyActionType.SubmitOTPError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case MoveMoneyActionType.UpdatePasswordPayAnyone:
                draft.passwordPA = action.payload;
                break;
            case MoveMoneyActionType.UpdatePasswordOptionPayAnyone:
                if (action.payload === false) {
                    draft.passwordPA = initialState.passwordPA;
                }
                draft.passwordPAOption = action.payload;
                break;
            case MoveMoneyActionType.UpdateDisabledPasswordPayAnyone:
                draft.disabledPasswordOption = action.payload;
                break;
            case MoveMoneyActionType.ResetPayAnyoneData:
                if (action.payload === "CANCEL_OTP") {
                    draft.status = initialState.status;
                    draft.errorCodeAmount = initialState.errorCodeAmount;
                    draft.fetching = initialState.fetching;
                } else {
                    draft.disabledPasswordOption =
                        initialState.disabledPasswordOption;
                    draft.passwordPA = initialState.passwordPA;
                    draft.passwordPAOption = initialState.passwordPAOption;
                    draft.externalData = initialState.externalData;
                    draft.info = initialState.info;
                }
                break;
            case MoveMoneyActionType.TransferByPhone:
                draft.status = FetchStatus.Pending;
                draft.errorCodeAmount = null;
                draft.fetching = true;
                break;
            case MoveMoneyActionType.CommitOTPTransferByPhone:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case MoveMoneyActionType.SetExternalData:
                draft.externalData = action.payload;
                break;
            /* ------------- Reset to initial state ------------- */
            case MoveMoneyActionType.Reset: {
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            }
            default:
                break;
        }
    });

export default moveMoneyReducer;
