import { Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import cardManagementService from "@services/card-management";
import { RESPONSE_CODE } from "@config/constants";
import CardManagementActions from "@redux/actions/card-management";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import clsx from "clsx";
import Toggle from "@components/Toggle";
import { CARD_STATUSES, CARD_TYPES } from "./constant";

const useStyles = makeStyles((theme) => ({
    optionWrapper: {
        padding: theme.spacing(4, 3, 4, 3),
        color: theme.palette.grey[700],
    },
    disabled: {
        cursor: "not-allowed",
        pointerEvents: "none",
        opacity: 0.5,
    },
}));

const CardInstallmentUpdate = ({ card, getCardList }) => {
    const classes = useStyles();
    const [isShowOption, setIsShowOption] = useState(false);
    const [optionIsDisabled, setOptionIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState(card.ccPostNotify);

    const { t } = useTranslation("creditCard");

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const checkPermission = useCallback(() => {
        const { cardStatus, cardType } = card;
        if (
            cardType !== CARD_TYPES.DEBIT_CARD &&
            cardType !== CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD &&
            cardType !== CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
        ) {
            switch (cardStatus) {
                case CARD_STATUSES.ACTIVE:
                    setIsShowOption(true);
                    break;
                case CARD_STATUSES.BLOCKED:
                    setIsShowOption(true);
                    setOptionIsDisabled(true);
                    break;
                default:
                    break;
            }
        }
    }, [card]);

    const updateInstallmentNotification = async (value) => {
        setLoading(true);
        const dataToPost = {
            ccPostNotify: value,
        };
        const response = await cardManagementService.updateInstallmentNotification(
            dataToPost
        );

        if (unmounted.current) {
            return;
        }
        const { code } = response.data;

        const successMsg = value
            ? t("creditCard:ins_noti_setting_on_successful")
            : t("creditCard:ins_noti_setting_off_successful");

        const errorMsg = card.ccPostNotify
            ? t("creditCard:ins_noti_setting_on_unsuccessful")
            : t("creditCard:ins_noti_setting_off_unsuccessful");

        if (response.ok) {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    getCardList();
                    AlertController.show(t(successMsg));
                    break;
                default:
                    AlertController.show(t(errorMsg), AlertType.Error);
                    break;
            }
            setLoading(false);
        } else {
            AlertController.show(t(errorMsg), AlertType.Error);
            setLoading(false);
        }
    };

    const onToggleOption = (value) => {
        setOption(value);
        updateInstallmentNotification(value);
    };

    useEffect(() => {
        checkPermission();
    }, [checkPermission]);

    return (
        <>
            {isShowOption && (
                <div
                    className={clsx(classes.optionWrapper, {
                        [classes.disabled]: optionIsDisabled,
                    })}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            {t("creditCard:mc_ins_update_toggle")}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                            <Toggle
                                status={option}
                                onChangeHandler={onToggleOption}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

const dispatchProps = (dispatch) => ({
    getCardList: () => dispatch(CardManagementActions.getCardList()),
});

export default connect(null, dispatchProps)(CardInstallmentUpdate);
