import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import {
    CreditCardNavigationKey,
    TransactionGroupType,
} from "@config/constants";
import useNumber from "@helpers/useNumber";
import usePathname from "@helpers/usePathname";
import { Box, Icon, makeStyles } from "@material-ui/core";
import accountAction from "@redux/actions/account";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InstallmentIntroduce from "./InstallmentIntroduce";

const useStyles = makeStyles((theme) => ({
    availableBar: {
        background: theme.palette.blue.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(1),
        margin: theme.spacing(4, 0),
    },
    availableBarIcon: {
        display: "flex",
        "& > span": {
            marginRight: theme.spacing(1),
        },
    },
}));

const InstallmentList = ({
    getInstallmentData,
    monthlyPayment,
    currentInstallment,
    completedInstallment,
    installmentConversableCount,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        getInstallmentData();
    }, [getInstallmentData]);

    const totalInstallment = useMemo(
        () => currentInstallment?.totalInstallment,
        [currentInstallment]
    );

    const onItemClick = useCallback(
        (txn) => {
            const txnId = txn.txnId || "";
            const path = CreditCardNavigationKey.InstallmentDetail;
            history.push(`/${path}/${txnId}`);
        },
        [history]
    );

    const onViewAvailable = useCallback(() => {
        history.push(CreditCardNavigationKey.InstallmentAvailable);
    }, [history]);

    const isShowIntroScreen = useMemo(() => {
        return (
            currentInstallment?.items.length === 0 &&
            completedInstallment?.items.length === 0 &&
            installmentConversableCount <= 0
        );
    }, [currentInstallment, completedInstallment, installmentConversableCount]);

    const isEmptyList = useMemo(() => {
        return (
            currentInstallment?.items.length === 0 &&
            completedInstallment?.items.length === 0
        );
    }, [currentInstallment, completedInstallment]);

    return (
        <>
            {isShowIntroScreen ? (
                <Content size="xl">
                    <InstallmentIntroduce />
                </Content>
            ) : (
                <Content>
                    <AccountSummaryBox
                        leftItemName={t("mc_lb_total_installment")}
                        rightItemName={t("mc_lb_monthly_payment")}
                        rightItemColor="blue"
                        leftItemValue={formatNumber(totalInstallment)}
                        rightItemValue={formatNumber(monthlyPayment)}
                    />

                    {installmentConversableCount > 0 && (
                        <div className={classes.availableBar}>
                            <div>
                                <LspTypography
                                    variant="body1"
                                    color="white"
                                    component="span"
                                    className={classes.availableBarIcon}
                                >
                                    <Icon className="font-icon icon-convertable-installment" />

                                    {t("creditCard:available_for_installment")}
                                </LspTypography>
                            </div>
                            <div>
                                <LspTypography
                                    variant="hyperlink1"
                                    color="white"
                                    onClick={onViewAvailable}
                                >
                                    {t("outstanding_tasks_commod_view")}
                                </LspTypography>
                            </div>
                        </div>
                    )}

                    {isEmptyList && (
                        <LspTypography textCenter>
                            {t("creditCard:no_current_installment")}
                        </LspTypography>
                    )}

                    {Array.isArray(currentInstallment?.items) &&
                        currentInstallment.items.length > 0 && (
                            <TransactionGroup
                                header={t("mc_lb_title_active_installment")}
                                items={currentInstallment.items}
                                type={TransactionGroupType.Installment}
                                onItemClick={onItemClick}
                            />
                        )}
                    {Array.isArray(completedInstallment?.items) &&
                        completedInstallment.items.length > 0 && (
                            <TransactionGroup
                                header={t("mc_lb_title_completed_installment")}
                                items={completedInstallment.items}
                                type={TransactionGroupType.Installment}
                                onItemClick={onItemClick}
                            />
                        )}
                </Content>
            )}
        </>
    );
};

const mapState = (state) => ({
    installmentConversableCount:
        state.account.installment.installmentConversableCount,
    monthlyPayment: state.account.installment.monthlyPayment,
    currentInstallment: state.account.installment.current,
    completedInstallment: state.account.installment.completed,
});

const mapDispatch = (dispatch) => ({
    getInstallmentData: () =>
        dispatch(accountAction.getInstallmentDataRequest()),
});

export default connect(mapState, mapDispatch)(InstallmentList);
