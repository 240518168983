import { ThemeName } from "@config/constants";

export const base = {
    darkPurple: "#3c2a5b",
    purple: "#563D82",
    lightPurple: "#77639b",
    lightPurple1: "#EEEBF2",
    darkGold: "#b26719",
    gold: "#FF9425",
    lightGold: "#ffa950",
    darkBlue: "#1b5179",
    blue: "#2774AE",
    lightBlue: "#528fbe",
    darkOrange: "#a42300",
    orange: "#EB3300",
    lightOrange: "#ef5b33",
    green: "#30CD9A",
    white: "#FFFFFF",
    red: "#E65365",
    contrastTextDarkMode: "rgba(0,0,0,0.87)",
    grey: {
        100: "#F4F5F6",
        200: "#E4E6E8",
        300: "#ADB4BC",
        400: "#747D87",
        500: "#4f4f5a",
        600: "#F8F9F9",
        700: "#f4f4f4",
        800: "#F8F9F9",
        900: "#333",
    },
    black: {
        100: "#101820",
        200: "#1E2021",
        300: "#181A1B",
    },
};

export const THEME_PATTERNS = {
    [ThemeName.Purple]: {
        light: base.lightPurple,
        main: base.purple,
        dark: base.darkPurple,
        contrastTextLightMode: base.white,
        contrastTextDarkMode: base.contrastTextDarkMode,
    },
    [ThemeName.Gold]: {
        light: base.lightGold,
        main: base.gold,
        dark: base.darkGold,
        contrastTextLightMode: base.white,
        contrastTextDarkMode: base.contrastTextDarkMode,
    },
    [ThemeName.Blue]: {
        light: base.lightBlue,
        main: base.blue,
        dark: base.darkBlue,
        contrastTextLightMode: base.white,
        contrastTextDarkMode: base.contrastTextDarkMode,
    },
    [ThemeName.Orange]: {
        light: base.lightOrange,
        main: base.orange,
        dark: base.darkOrange,
        contrastTextLightMode: base.white,
        contrastTextDarkMode: base.contrastTextDarkMode,
    },
};

export const neutral = {
    black: base.black,
    blackPattern: base.blackPattern,
    grey: base.grey[500],
    grey1: base.grey[400],
    grey2: base.grey[300],
    grey3: base.grey[200],
    grey4: base.grey[100],
    grey5: base.grey[800],
    grey6: base.grey[900],
    white: base.white,
};

export const status = {
    success: base.green,
    error: base.red,
    rejected: base.orange,
    pending: base.gold,
    active: base.blue,
    closed: base.grey[100],
};
