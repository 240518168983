import { memo } from "react";

import { useTranslation } from "react-i18next";

import LspCongratulation from "@components/LspCongratulation";
import { WEALTH_CREDIT_CARD_STEPS } from "@containers/Wealth/wealthConstants";
import useSharedClasses from "@containers/Wealth/wealthSharedClasses";

const WealthCreditCardFailed = ({ onNext }) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();

    return (
        <LspCongratulation
            noIcon
            title={t("wealth:mc_v3_nolonger_mc_title")}
            contents={t("wealth:mc_v3_nolonger_msg")}
            contentWithAction={{
                label: t("wealth:mc_v3_link_about_mc"),
                onClick: () => {
                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                },
                className: [sharedClasses.aboutButton],
            }}
        />
    );
};

export default memo(WealthCreditCardFailed);
