import { RESPONSE_CODE } from "@config/constants";
import bank from "@redux/actions/bank";
import translateService from "@services/translate";
import { call, put } from "redux-saga/effects";

export function* getBankList() {
    const response = yield call(translateService.getPrivateContent, "bankList");

    const code = response?.data?.code;
    // handle for remain update password - BE return 2007, 2008, 2009
    if (!!code && code !== RESPONSE_CODE.SUCCESS) {
        yield put(bank.getBankListErrors());
        return;
    }

    const { data, status } = response;

    switch (status) {
        case RESPONSE_CODE.SUCCESS:
            yield put(bank.getBankListSuccess(data.bankList || []));
            break;
        default:
            yield put(bank.getBankListErrors());
            break;
    }
}

export function* getProvinceList() {
    const response = yield call(
        translateService.getPrivateContent,
        "provinceList"
    );

    const code = response?.data?.code;
    // handle for remain update password - BE return 2007, 2008, 2009
    if (!!code && code !== RESPONSE_CODE.SUCCESS) {
        yield put(bank.getProvinceListErrors());
        return;
    }

    const { data, status } = response;
    switch (status) {
        case RESPONSE_CODE.SUCCESS:
            yield put(bank.getProvinceListSuccess(data.provinceList || []));
            break;
        default:
            yield put(bank.getProvinceListErrors());
            break;
    }
}

export function* getBranchList() {
    const response = yield call(
        translateService.getPrivateContent,
        "branchList"
    );

    const code = response?.data?.code;
    // handle for remain update password - BE return 2007, 2008, 2009
    if (!!code && code !== RESPONSE_CODE.SUCCESS) {
        yield put(bank.getBranchListErrors());
        return;
    }

    const { data, status } = response;
    switch (status) {
        case RESPONSE_CODE.SUCCESS:
            yield put(bank.getBranchListSuccess(data.branchList || []));
            break;
        default:
            yield put(bank.getBranchListErrors());
            break;
    }
}
