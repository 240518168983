/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import { useTranslation } from "react-i18next";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { memo, useMemo } from "react";
import { connect } from "react-redux";
import LspCongratulation from "@components/LspCongratulation";
import { format } from "date-fns";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
    contentItem: {
        display: "flex",
        " & > div": {
            width: "40%",
            textAlign: "left",
            fontSize: theme.typography.pxToRem(13),
        },
        " & > span": {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    contentDetail: {
        padding: theme.spacing(2, 3.75),
        backgroundColor: theme.palette.grey[200],
    },
    contentTitle: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "700",
        textTransform: "uppercase",
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
}));

const VinaWealthResult = ({
    onNext,
    infoReceipt,
    productDetail,
    congratulationFailed,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const arrayContent = useMemo(() => {
        let expectDate = "";
        if (infoReceipt?.priceDate) {
            expectDate = format(infoReceipt?.priceDate, "dd/MM/yyyy");
        }
        const content = [
            {
                label: t("wealth:vinawealth2_acc_no"),
                info: infoReceipt?.accountNumber,
            },
            {
                label: t("wealth:vinawealth2_acc_type"),
                info: t(
                    `wealth:vinawealth2_investment_type_${productDetail.productKey}`
                ),
            },
            {
                label: t("wealth:vc3_acc_fund"),
                info: t(`wealth:vc3_fund_overview_${productDetail.productKey}`),
            },

            {
                label: t("wealth:vc3_acc_trade_date"),
                info: expectDate,
            },
        ];
        return content;
    }, [t, infoReceipt, productDetail]);

    const viewReceiptHandler = () => {
        let refNo = infoReceipt?.timoXID;
        if (infoReceipt?.txnId) {
            refNo = infoReceipt?.txnId;
        }

        if (refNo === "") return;

        LspReceiptController.dialog(refNo, () => {
            GlobalDialogController.hide();
        });
    };

    return (
        <Content size="xs">
            {!congratulationFailed && (
                <LspCongratulation
                    component
                    icon="like"
                    title={t("wealth:vinawealth2_confirm_title")}
                    contents={
                        productDetail.FEIsContributeAccount
                            ? t("wealth:vinawealth2_fund_topup_body")
                            : t("wealth:vinawealth2_confirm_body", {
                                  returnObjects: true,
                              })
                    }
                    otherContent={t(
                        "wealth:vinawealth2_confirm_contact"
                    ).replace("%@", t("wealth:vinawealth2_hotline_phone"))}
                    button={{
                        label: t("lb_done"),
                        onClick: () => onNext(VINA_WEALTH_STEPS.DASHBOARD),
                    }}
                    contentWithAction={{
                        label: t("wealth:vinawealth2_view_receipt"),
                        onClick: viewReceiptHandler,
                    }}
                >
                    {!productDetail.FEIsContributeAccount && (
                        <div className={classes.contentDetail}>
                            <div className={classes.contentTitle}>
                                {t("wealth:vinawealth2_acc_box")}
                            </div>
                            {arrayContent?.map(
                                (c, index) =>
                                    c.info && (
                                        <div
                                            className={classes.contentItem}
                                            key={index}
                                        >
                                            <div>{c.label}</div>
                                            <span>{c.info}</span>
                                        </div>
                                    )
                            )}
                        </div>
                    )}
                </LspCongratulation>
            )}
            {congratulationFailed && (
                <LspCongratulation
                    icon="problem"
                    title={t("wealth:vc3_final_case3_title")}
                    contents={t("wealth:vc3_final_case3_msg")}
                    contentWithBox={{
                        content: t("wealth:vc3_final_case3_error_msg"),
                        isWarning: true,
                    }}
                    button={{
                        label: t("lb_done"),
                        onClick: () => onNext(VINA_WEALTH_STEPS.DASHBOARD),
                    }}
                    otherContent={t(
                        "wealth:vinawealth2_confirm_contact"
                    ).replace("%@", t("wealth:vinawealth2_hotline_phone"))}
                    type="primary"
                />
            )}
        </Content>
    );
};

const mapState = (state) => ({
    infoReceipt: state.vinaWealth.infoReceipt,
    productDetail: state.vinaWealth.productDetail,
    congratulationFailed: state.vinaWealth.congratulationFailed,
});

export default memo(connect(mapState)(VinaWealthResult));
