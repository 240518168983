import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspCardList from "@components/LspCardList";

import PageHeaderBack from "@components/PageHeaderBack";
import { APP_CONTENT } from "@config/constants";
import { Box } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import Interweave from "interweave";
import { useRef, useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const CreateBookingLocationSelection = ({
    onCancel,
    onNext,
    onBack,
    setSelectedHangout,
    hangoutProvince,
}) => {
    const { t } = useTranslation();
    const unmounted = useRef(false);

    const title = useMemo(() => {
        return t("ekyc:location_in_web").replace(
            "%@",
            hangoutProvince?.provinceName
        );
    }, [t, hangoutProvince]);

    const hangoutList = useMemo(() => {
        const hangouts = hangoutProvince?.locations?.map((item) => ({
            ...item,
            icon: item?.type === "TIMO" ? APP_CONTENT.LOGO_FONT_ICON : "bank",
            title: item?.name,
            description: item?.address,
        }));

        return hangouts || [];
    }, [hangoutProvince]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onSelectLocation = useCallback(
        (location) => {
            setSelectedHangout(location);
            onNext(location.id);
        },
        [onNext, setSelectedHangout]
    );

    return (
        <Content size="sm">
            <PageHeaderBack onBack={onBack}>
                <Interweave content={title} containerTagName="div" />
            </PageHeaderBack>
            <LspCardList
                list={hangoutList}
                onClickItem={onSelectLocation}
                variant="primary"
            />
            <Box mt={3}>
                <LspButton fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    serviceName: state.booking.serviceName,
    hangoutProvince: state.booking.hangoutProvince,
});

const mapDispatch = (dispatch) => ({
    setSelectedHangout: (payload) =>
        dispatch(bookingActions.setSelectedHangout(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingLocationSelection);
