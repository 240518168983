import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    boxLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
    },
    info: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ClaimMoneyLoading = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.box}>
                <div className={classes.boxLine}>
                    <Skeleton width="48%" />
                    <Skeleton width="48%" />
                    <Skeleton width="48%" />
                    <Skeleton width="48%" />
                </div>
                <div className={classes.boxLine}>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" />
                </div>
            </div>
            <div className={classes.info}>
                <Skeleton width="100%" />
                <Skeleton width="70%" />
            </div>
            <div className={classes.methods}>
                <Skeleton width="37%" />
                <Skeleton width="85%" />
                <Skeleton width="89%" />
                <Skeleton width="93%" />
            </div>
        </div>
    );
};

export default ClaimMoneyLoading;
