import produce from "immer";
import { cloneDeep } from "lodash";
import { FetchStatus } from "../../config/constants";
import { AccountActionType } from "../actions/account";
import mergeTransactionList from "../../helpers/mergeTransactionList";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    list: null,
    accountStatus: null,
    accountStatusFetching: false,
    accountStatusFetchStatus: null,
    spendAccount: {
        fetching: false,
        status: null,
        transactionList: null,
        hasMore: true,
        txnDateIndex: null,
        xidIndex: null,
        hiddenBalance: null,
    },
    termDeposit: {
        totalActiveTermDepositAmount: null,
        totalRedeemedTermDepositAmount: null,
    },
    creditCard: {
        fetching: false,
        status: null,
        creditLimit: null,
        transactionList: null,
        hasMore: true,
        txnDateIndex: null,
        xidIndex: null,
        allowChangePhysicalStatus: false,
    },
    installment: {
        fetching: false,
        status: null,
        installmentConversableCount: 0,
    },
    payAnyoneDashboard: {
        fetching: false,
        totalAmountPending: 0,
        totalAmountReceive: 0,
        totalAmountSend: 0,
        totalBalance: 0,
        totalNumberPending: 0,
        totalNumberUnread: 0,
    },
    payAnyoneList: {
        fetching: false,
        status: null,
        items: [],
    },
    paymentDetail: {
        fetching: false,
        status: null,
        action: null,
    },
    accountLimit: {
        fetching: false,
        status: null,
        data: null,
    },
    modifyAccountLimit: {
        fetching: false,
        status: null,
        data: null,
    },
};

/* ------------- Reducer ------------- */
const accountReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case AccountActionType.Reset:
                draft.fetching = false;
                draft.status = null;
                draft.list = null;
                draft.accountStatus = null;
                draft.accountStatusFetching = false;
                draft.accountStatusFetchStatus = null;
                draft.spendAccount = {
                    transactionList: null,
                    hasMore: true,
                    fetching: false,
                    status: null,
                    txnDateIndex: null,
                    xidIndex: null,
                };
                break;
            /* ------------- Get account status ------------- */
            case AccountActionType.GetAccountStatusRequest:
                draft.accountStatusFetching = true;
                draft.accountStatusFetchStatus = FetchStatus.Pending;
                break;
            case AccountActionType.GetAccountStatusSuccess:
                draft.accountStatusFetching = false;
                draft.accountStatusFetchStatus = FetchStatus.Success;
                draft.accountStatus = action.payload;
                break;
            case AccountActionType.GetAccountStatusError:
                draft.accountStatusFetching = false;
                draft.accountStatusFetchStatus = FetchStatus.Error;
                break;
            /* ------------- Get account list ------------- */
            case AccountActionType.GetAccountListRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AccountActionType.GetAccountListSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.list = action.payload;
                break;
            case AccountActionType.GetAccountListError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            /* ------------- Spend account transaction list ------------- */
            case AccountActionType.GetSpendAccountTransactionListRequest: {
                draft.spendAccount.fetching = true;
                draft.spendAccount.status = FetchStatus.Pending;

                const { txnDateIndex, xidIndex } = action.payload;

                if (!txnDateIndex && !xidIndex) {
                    draft.spendAccount.transactionList = null;
                    draft.spendAccount.txnDateIndex = null;
                    draft.spendAccount.xidIndex = null;
                    draft.spendAccount.hasMore = true;
                }
                break;
            }
            case AccountActionType.GetSpendAccountTransactionListSuccess: {
                draft.spendAccount.fetching = false;
                draft.spendAccount.status = FetchStatus.Success;
                draft.spendAccount.txnDateIndex = action.payload.txnDateIndex;
                draft.spendAccount.xidIndex = action.payload.xidIndex;

                draft.spendAccount.transactionList = mergeTransactionList(
                    draft.spendAccount.transactionList,
                    action.payload.items
                );
                if (
                    !Array.isArray(action.payload.items) ||
                    action.payload.items.length === 0
                ) {
                    draft.spendAccount.hasMore = false;
                }
                break;
            }
            case AccountActionType.GetSpendAccountTransactionListError:
                draft.spendAccount.fetching = false;
                draft.spendAccount.status = FetchStatus.Error;
                draft.spendAccount.hasMore = false;
                break;

            /* ------------- Spend credit card transaction list ------------- */
            case AccountActionType.GetCreditCardTransactionListRequest: {
                draft.creditCard.fetching = true;
                draft.creditCard.status = FetchStatus.Pending;

                const { txnDateIndex, xidIndex } = action.payload;

                if (!txnDateIndex && !xidIndex) {
                    draft.creditCard.transactionList = null;
                    draft.creditCard.txnDateIndex = null;
                    draft.creditCard.xidIndex = null;
                    draft.creditCard.hasMore = true;
                }
                break;
            }
            case AccountActionType.GetCreditCardTransactionListSuccess: {
                draft.creditCard.fetching = false;
                draft.creditCard.status = FetchStatus.Success;
                draft.creditCard.creditLimit = action.payload.creditLimit;
                draft.creditCard.txnDateIndex = action.payload.txnDateIndex;
                draft.creditCard.xidIndex = action.payload.xidIndex;
                draft.creditCard.allowChangePhysicalStatus =
                    action.payload?.isActivePhysicalStatus || false;

                draft.creditCard.transactionList = mergeTransactionList(
                    draft.creditCard.transactionList,
                    action.payload.items
                );
                if (
                    !Array.isArray(action.payload.items) ||
                    action.payload.items.length === 0
                ) {
                    draft.creditCard.hasMore = false;
                }
                break;
            }
            case AccountActionType.GetCreditCardTransactionListError:
                draft.creditCard.fetching = false;
                draft.creditCard.status = FetchStatus.Error;
                draft.creditCard.hasMore = false;
                break;
            case AccountActionType.SetTotalActiveTermDepositAmount:
                draft.termDeposit.totalActiveTermDepositAmount = action.payload;
                break;
            case AccountActionType.SetTotalRedeemedTermDepositAmount:
                draft.termDeposit.totalRedeemedTermDepositAmount =
                    action.payload;
                break;

            /* ------------- Installment data ------------- */
            case AccountActionType.GetInstallmentDataRequest:
                draft.installment.fetching = true;
                draft.installment.status = FetchStatus.Pending;
                break;
            case AccountActionType.GetInstallmentDataSuccess:
                draft.installment.fetching = false;
                draft.installment.status = FetchStatus.Success;
                draft.installment.current = action.payload.current;
                draft.installment.completed = action.payload.completed;
                draft.installment.monthlyPayment =
                    action.payload.monthlyPayment;
                draft.installment.totalPayment = action.payload.totalPayment;
                draft.installment.installmentConversableCount =
                    action.payload.installment;
                break;
            case AccountActionType.GetInstallmentDataError:
                draft.installment.fetching = false;
                draft.installment.status = FetchStatus.Error;
                break;

            case AccountActionType.GetPayAnyoneDashboardRequest:
                draft.payAnyoneDashboard.fetching = true;
                break;
            case AccountActionType.GetPayAnyoneDashboardSuccess:
                const payAnyoneDashboard = action.payload.find(
                    (p) => p.paoType === "PA"
                );
                draft.payAnyoneDashboard.fetching = false;
                draft.payAnyoneDashboard.totalAmountPending =
                    payAnyoneDashboard.totalAmountPending;
                draft.payAnyoneDashboard.totalAmountReceive =
                    payAnyoneDashboard.totalAmountReceive;
                draft.payAnyoneDashboard.totalAmountSend =
                    payAnyoneDashboard.totalAmountSend;
                draft.payAnyoneDashboard.totalBalance =
                    payAnyoneDashboard.totalBalance;
                draft.payAnyoneDashboard.totalNumberPending =
                    payAnyoneDashboard.totalNumberPending;
                draft.payAnyoneDashboard.totalNumberUnread =
                    payAnyoneDashboard.totalNumberUnread;
                break;
            case AccountActionType.GetPayAnyoneDashboardError:
                draft.payAnyoneDashboard.fetching = false;
                break;

            case AccountActionType.GetPayAnyoneListRequest:
                draft.payAnyoneList.fetching = true;
                draft.payAnyoneList.status = FetchStatus.Pending;
                break;
            case AccountActionType.GetPayAnyoneListSuccess:
                draft.payAnyoneList.fetching = false;
                draft.payAnyoneList.status = FetchStatus.Success;
                draft.payAnyoneList.items = action.payload.list;
                break;
            case AccountActionType.GetPayAnyoneListError:
                draft.payAnyoneList.fetching = false;
                draft.payAnyoneList.status = FetchStatus.Error;
                break;
            case AccountActionType.UpdatePaymentStatusRequest:
                draft.paymentDetail.fetching = true;
                draft.paymentDetail.status = FetchStatus.Pending;
                draft.paymentDetail.action = action.payload.status;
                break;
            case AccountActionType.UpdatePaymentStatusSuccess:
                draft.paymentDetail.fetching = false;
                draft.paymentDetail.status = FetchStatus.Success;
                break;
            case AccountActionType.UpdatePaymentStatusError:
                draft.paymentDetail.fetching = false;
                draft.paymentDetail.status = FetchStatus.Error;
                break;
            case AccountActionType.ResetPaymentDetailData:
                draft.paymentDetail.fetching =
                    initialState.paymentDetail.fetching;
                draft.paymentDetail.status = initialState.paymentDetail.status;
                draft.paymentDetail.action = initialState.paymentDetail.action;
                break;

            case AccountActionType.UpdateTransactionList:
                const { type, dispDate, refNo, list, data } = action.payload;
                const newList = cloneDeep(list);
                const dispDateList = (list || []).find(
                    (item) => item.dispDate === dispDate
                );

                const dispDateListIndex = (list || []).findIndex(
                    (item) => item.dispDate === dispDate
                );

                if (dispDateList != null) {
                    let receiptItem = (dispDateList.item || []).find(
                        (item) => item.refNo === refNo
                    );

                    const receiptItemIndex = (
                        dispDateList.item || []
                    ).findIndex((item) => item.refNo === refNo);

                    if (receiptItem != null) {
                        receiptItem = {
                            ...receiptItem,
                            ...data,
                        };
                    }

                    newList[dispDateListIndex].item[
                        receiptItemIndex
                    ] = receiptItem;

                    draft[type].transactionList = newList;
                }
                break;
            case AccountActionType.UpdateHideBalanceOption:
                const value =
                    action?.payload !== undefined
                        ? action?.payload
                        : draft.spendAccount.hiddenBalance !== true;
                draft.spendAccount.hiddenBalance = value;
                break;

            /* ------------- Account Limit ------------- */
            case AccountActionType.GetAccountLimitRequest:
                draft.accountLimit.status = true;
                draft.accountLimit.fetching = FetchStatus.Pending;
                break;
            case AccountActionType.GetAccountLimitSuccess:
                draft.accountLimit.status = false;
                draft.accountLimit.fetching = FetchStatus.Success;
                draft.accountLimit.data = action.payload;
                break;
            case AccountActionType.GetAccountLimitError:
                draft.accountLimit.status = false;
                draft.accountLimit.fetching = FetchStatus.Error;
                break;

            /* -------------Modify Account Limit ------------- */
            case AccountActionType.ModifyAccountLimitRequest:
                draft.modifyAccountLimit.status = true;
                draft.modifyAccountLimit.fetching = FetchStatus.Pending;
                break;
            case AccountActionType.ModifyAccountLimitSuccess:
                draft.modifyAccountLimit.status = false;
                draft.modifyAccountLimit.fetching = FetchStatus.Success;
                draft.modifyAccountLimit.data = action.payload;
                break;
            case AccountActionType.ModifyAccountLimitError:
                draft.modifyAccountLimit.status = false;
                draft.modifyAccountLimit.fetching = FetchStatus.Error;
                break;

            case AccountActionType.ResetAccountLimit:
                draft.modifyAccountLimit.data = null;
                draft.modifyAccountLimit.fetching = null;
                draft.modifyAccountLimit.status = null;
                break;
            default:
                break;
        }
    });

export default accountReducer;
