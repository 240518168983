/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import LspAvatar from "@components/LspAvatar";
import iconDefault from "@assets/images/icon_default.png";
import useNumber from "@helpers/useNumber";
import pendingTaskService from "@services/pending-task";
import { RESPONSE_CODE, GENERAL_CONTENT } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AlertController from "@helpers/controllers/AlertController";
import { isFunction } from "lodash";
import PaymentReceive from "@containers/PaymentRequest/PaymentReceive";
import paymentRequestService from "@services/payment-request";
import { UAParser } from "ua-parser-js";
import PendingTaskChipCardInfo from "@containers/PendingTask/PendingTaskChipCardInfo";
import {
    BOOKING_SERVICES,
    BOOKING_SERVICE_ID,
} from "@containers/Booking/constant";
import Interweave from "interweave";
import PendingTaskCardActivation from "./PendingTaskCardActivation";

const TASK_TYPES = {
    ACTIVATE_CARD_V2: "ACTIVATE_CARD_V2",
    ACTIVATE_CARD: "ACTIVATE_CARD",
    PAYMENT_REQUEST: "PAYMENT_REQUEST",
    CC_PREAPPROVE: "CC_PREAPPROVE",
};

const HIDE_TASK_LIST = [
    // "REISSUED_CARD_PROCESSING",
    // "REISSUED_CARD_ON_THE_WAY",
    // "REISSUED_CARD_MISSED_DELIVERY",
];

const PendingTaskItem = ({
    item,
    className,
    user,
    refreshList,
    openNewBooking,
    onViewBooking,
    onChangeBooking,
}) => {
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    const { os } = UAParser();

    const { t } = useTranslation();
    const unmounted = useRef(false);
    const [task, setTask] = useState(null);
    const [primaryLoadingButton, setPrimaryLoadingButton] = useState(false);
    const [secondaryLoadingButton, setSecondaryLoadingButton] = useState(false);

    const isEKYCBasic = useMemo(
        () => user?.currentOnboardingType === "eKYCBasic",
        [user]
    );
    const { formatNumber, parseNumber } = useNumber();

    const isShowItem = (type, status) => {
        let currentItem = `${type}_${status}`;
        for (let i = 0; i < HIDE_TASK_LIST.length; i++) {
            if (currentItem === HIDE_TASK_LIST[i]) {
                return false;
            }
        }
        return true;
    };

    // ====== START DELETE PAYMENT REQUEST TASK =====

    const deletePaymentTask = useCallback(async () => {
        setSecondaryLoadingButton(true);
        GlobalDialogController.hide();

        const response = await pendingTaskService.deletePendingTask({
            taskId: task?.taskId,
        });
        setSecondaryLoadingButton(false);

        if (unmounted.current) return;

        const { code } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                AlertController.show(t("cm_lb_notice_delete_task"));
                if (refreshList && isFunction(refreshList)) {
                    refreshList();
                }
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [task?.taskId, t, refreshList]);

    const deletePaymentTaskConfirmation = useCallback(() => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                header: "loan_vpbank_button_confirm",
                translatedContent: `${t("cm_lb_content_delete_task")}`,
                doubleButton: [
                    {
                        label: t("lb_yes"),
                        onClick: () => deletePaymentTask(),
                    },
                    {
                        label: t("lb_cancel"),
                        onClick: () => GlobalDialogController.hide(),
                    },
                ],
            },
        });
    }, [t, deletePaymentTask]);

    // ====== END DELETE PAYMENT REQUEST TASK =====

    // ====== START ACTIVATE DEBIT CARD TASK =====

    const onDoneActivateCard = useCallback(() => {
        if (refreshList && isFunction(refreshList)) {
            refreshList();
        }
        GlobalDialogController.hide();
    }, [refreshList]);

    const activateCard = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <PendingTaskCardActivation
                    onDone={onDoneActivateCard}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    }, [onDoneActivateCard]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    // ====== END ACTIVATE DEBIT CARD TASK =====

    const openAppStore = useCallback(() => {
        if (os?.name?.indexOf("iOs") > -1) {
            window.open(GENERAL_CONTENT.APP_STORE, "_blank");
        } else {
            window.open(GENERAL_CONTENT.CH_PLAY, "_blank");
        }
    }, [os.name]);

    const moreInfoChipCard = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <PendingTaskChipCardInfo
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    }, []);

    const getContent = useCallback(
        (type, status) => {
            const keyTitle = status
                ? `${type}_${status}_title`
                : `${type}_0_title`;
            const keyDesc = status
                ? `${type}_${status}_description`
                : `${type}_0_description`;
            const keyMsg = status
                ? `${type}_${status}_message`
                : `${type}_0_message`;
            const keyAvatar = status
                ? `${type}_${status}_avatar`
                : `${type}_0_avatar`;
            const leftBtnLabel = status
                ? `${type}_${status}_left_label`
                : `${type}_0_left_label`;
            const rightBtnLabel = status
                ? `${type}_${status}_right_label`
                : `${type}_0_right_label`;
            const leftBtnDeepLink = status
                ? `${type}_${status}_left_deeplink`
                : `${type}_0_left_deeplink`;
            const rightBtnDeepLink = status
                ? `${type}_${status}_right_deeplink`
                : `${type}_0_right_deeplink`;

            return {
                title: t(`pendingTask:${keyTitle}`) || "",
                desc: t(`pendingTask:${keyDesc}`) || "",
                msg: t(`pendingTask:${keyMsg}`) || "",
                avatar: t(`pendingTask:${keyAvatar}`),
                leftBtnLabel: t(`pendingTask:${leftBtnLabel}`) || "",
                rightBtnLabel: t(`pendingTask:${rightBtnLabel}`) || "",
                leftBtnDeepLink: t(`pendingTask:${leftBtnDeepLink}`) || "",
                rightBtnDeepLink: t(`pendingTask:${rightBtnDeepLink}`) || "",
            };
        },
        [t]
    );

    const getServiceName = (serviceType) => {
        switch (serviceType) {
            case BOOKING_SERVICE_ID.EKYC_BOOKING:
                return BOOKING_SERVICES.EKYC_BOOKING;
            case BOOKING_SERVICE_ID.REPLACE_CHIP_CARD:
            default:
                return BOOKING_SERVICES.REPLACE_CHIP_BOOKING;
        }
    };

    const mapDeepLink = useCallback(
        (deepLink, data) => {
            if (deepLink) {
                const deepLinkArr = deepLink.split("/");
                const deepLinkType = deepLinkArr[3];
                const deepLinkParam = deepLinkArr[4].split("=")[1];
                let serviceName;

                switch (deepLinkType) {
                    case "PendingTask.Booking":
                        serviceName = getServiceName(deepLinkParam);
                        openNewBooking(
                            data?.[deepLinkParam] || "",
                            serviceName
                        );
                        break;
                    case "PendingTask.ViewBooking":
                        serviceName = getServiceName(deepLinkParam);
                        // onViewBooking(data?.ekycId, serviceName);
                        onViewBooking(data?.[deepLinkParam], serviceName);
                        break;
                    case "PendingTask.Debit.Active":
                        activateCard(data);
                        break;
                    case "PendingTask.ChangeBooking":
                        serviceName = getServiceName(deepLinkParam);
                        // onChangeBooking(data?.ekycId, serviceName);
                        onChangeBooking(data?.[deepLinkParam], serviceName);
                        break;
                    case "PendingTask.Migration.Delete":
                        // migrationCancel();
                        break;
                    case "PendingTask.Migration.ImportData":
                        // migrationConfirm();
                        break;
                    case "PendingTask.Replacement": // learn more
                        moreInfoChipCard();
                        break;
                    case "forceSetupIOTP":
                    case "PendingTask.OpenApp":
                        openAppStore();
                        break;
                    default:
                        break;
                }
            } else {
                console.log("Invalid deeplink, please check again!!!!");
            }
        },
        [
            activateCard,
            openAppStore,
            moreInfoChipCard,
            openNewBooking,
            onChangeBooking,
            onViewBooking,
        ]
    );

    const showPaymentDetail = useCallback(async () => {
        setPrimaryLoadingButton(true);
        const params = {
            id: item.serviceId,
        };
        const { data } = await paymentRequestService.getPaymentDetail(
            item.isSent,
            params
        );

        if (unmounted.current) return;

        setPrimaryLoadingButton(false);

        switch (data.code) {
            case RESPONSE_CODE.CREATED:
                GlobalDialogController.show({
                    component: () => (
                        <PaymentReceive
                            paymentSendInfo={data.data}
                            isPendingTask
                            onCompleteHandler={refreshList}
                        />
                    ),
                });
                break;
            case RESPONSE_CODE.PAYMENT_UNAVAILABLE:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("cm_popup_payment_request_tittle"),
                        content: "cm_popup_payment_request_content",
                        button: t("lb_ok"),
                    },
                });
                break;

            default:
                break;
        }
    }, [item.serviceId, item.isSent, t, refreshList]);

    const handleFeatureNotSupportWarning = useCallback(() => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Warning",
                header: "oops",
                content: "msg_feature_not_available_on_web",
                button: "lb_ok",
            },
        });
    }, []);

    const mapPendingTaskItem = useCallback(
        (taskItem) => {
            if (!taskItem) return;

            const parsedData = JSON.parse(taskItem?.data);
            const status = parsedData?.status ?? "";

            // taskItem.type = "REISSUED_CARD";
            // const status = "PROCESSING";

            // const type =
            //     taskItem?.type === "FORCE_SETUP_IOTP"
            //         ? `${taskItem?.type}_web`
            //         : taskItem?.type;

            let type = taskItem?.type?.toUpperCase();
            if (
                type === "FORCE_SETUP_IOTP" ||
                (type === "REPLACE_CHIP_CARD" && status === "NEW")
            ) {
                type = `${taskItem?.type}_web`;
            }

            let content = getContent(type, status);
            let actions = [];
            switch (taskItem?.type?.toUpperCase()) {
                case TASK_TYPES.ACTIVATE_CARD:
                case TASK_TYPES.ACTIVATE_CARD_V2:
                    if (content.leftBtnLabel && isEKYCBasic) {
                        actions.push({
                            name: content.leftBtnLabel,
                            type: "secondary",
                            onClick: () => {
                                mapDeepLink(
                                    content.leftBtnDeepLink,
                                    parsedData
                                );
                            },
                            isDisabled: content.leftBtnDeepLink === "",
                        });
                    }

                    if (content.rightBtnLabel) {
                        actions.push({
                            name: content?.rightBtnLabel,
                            type: "primary",
                            onClick: () => {
                                mapDeepLink(
                                    content.rightBtnDeepLink,
                                    parsedData
                                );
                            },
                            isDisabled: content.rightBtnDeepLink === "",
                        });
                    }
                    break;
                case TASK_TYPES.PAYMENT_REQUEST:
                    content.title = t("cm_lb_title_payment_request");
                    content.desc = `${t("payee_transfer_success_from")} ${
                        parsedData.requestFrom
                    }`;
                    content.msg = parsedData.description;

                    // const paymentId = parsedData.paymentRequestId;
                    actions = [
                        {
                            name: t("payment_lb_btn_delete"),
                            type: "secondary",
                            onClick: () => deletePaymentTaskConfirmation(),
                            id: "delrequest",
                        },
                        {
                            name: t("payment_lb_btn_pay_now"),
                            type: "primary",
                            onClick: () => showPaymentDetail(),
                            id: "payrequest",
                        },
                    ];
                    break;
                case TASK_TYPES.CC_PREAPPROVE:
                    const data = JSON.parse(taskItem?.data);
                    const amount = formatNumber(
                        parseNumber(data?.amount) || 0,
                        false,
                        true
                    );
                    content.title = `${t(
                        `pendingTask:CC_PREAPPROVE_${taskItem.status}_title`
                    )}`.replace("%@", amount);
                    content.desc = t(
                        `pendingTask:CC_PREAPPROVE_${taskItem.status}_description`
                    );
                    content.msg = t(
                        `pendingTask:CC_PREAPPROVE_${taskItem.status}_message`
                    );

                    content.avatar = t(
                        `pendingTask:CC_PREAPPROVE_${taskItem.status}_avatar`
                    );

                    actions = [
                        {
                            name: t(
                                `pendingTask:CC_PREAPPROVE_${taskItem.status}_left_label`
                            ),
                            type: "primary",
                            onClick: () => handleFeatureNotSupportWarning(),
                            id: "preCCTD",
                        },
                    ];
                    break;
                default:
                    if (content.leftBtnLabel) {
                        actions.push({
                            name: content.leftBtnLabel,
                            type: "secondary",
                            onClick: () => {
                                mapDeepLink(
                                    content.leftBtnDeepLink,
                                    parsedData
                                );
                            },
                            isDisabled: content.leftBtnDeepLink === "",
                        });
                    }

                    if (content.rightBtnLabel) {
                        actions.push({
                            name: content.rightBtnLabel,
                            type: "primary",
                            onClick: () => {
                                mapDeepLink(
                                    content.rightBtnDeepLink,
                                    parsedData
                                );
                            },
                            isDisabled: content.rightBtnDeepLink === "",
                        });
                    }
                    break;
            }

            setTask({
                ...taskItem,
                data: parsedData,
                type,
                title: content?.title,
                subtitle: content?.desc,
                description: content?.msg,
                img: content?.avatar,
                actions,
                isShow: isShowItem(taskItem?.type, status),
            });
        },
        [
            getContent,
            isEKYCBasic,
            t,
            formatNumber,
            parseNumber,
            mapDeepLink,
            deletePaymentTaskConfirmation,
            showPaymentDetail,
            handleFeatureNotSupportWarning,
        ]
    );

    useEffect(() => {
        if (!item) return;
        mapPendingTaskItem(item);
    }, [item, mapPendingTaskItem]);

    return (
        <>
            {task?.isShow && (
                <Box className={className.item}>
                    <div className={className.heading}>
                        <div className={className.avatar}>
                            <LspAvatar
                                avatar={task?.data?.avatar || task?.img}
                                hasIcon={
                                    task?.type === TASK_TYPES.PAYMENT_REQUEST
                                }
                                size={48}
                                defaultAvatarInput={
                                    task?.type !== TASK_TYPES.PAYMENT_REQUEST
                                        ? iconDefault
                                        : null
                                }
                            />
                        </div>
                        <div>
                            <LspTypography variant="heading1">
                                {task?.title}
                            </LspTypography>
                            <LspTypography
                                variant="body2"
                                color="grey"
                                datatestid="request-from"
                            >
                                {task?.subtitle}
                            </LspTypography>
                        </div>
                    </div>
                    <div className={className.description}>
                        <LspTypography
                            variant="title2"
                            datatestid="request-amount"
                        >
                            {task?.data?.requestAmount &&
                                `${formatNumber(
                                    task?.data?.requestAmount
                                )} VND`}
                        </LspTypography>
                        <LspTypography variant="body2">
                            <Interweave content={task?.description} />
                        </LspTypography>
                    </div>
                    <div className={className.actions}>
                        {task?.actions?.map((button, index) => {
                            return (
                                <LspButton
                                    className={
                                        task?.actions?.length > 1
                                            ? className.multipleButton
                                            : ""
                                    }
                                    variant={button?.type}
                                    onClick={button?.onClick}
                                    key={index}
                                    fullWidth={!isSmUp}
                                    progressing={
                                        (button?.type === "primary" &&
                                            primaryLoadingButton) ||
                                        (button?.type === "secondary" &&
                                            secondaryLoadingButton)
                                    }
                                    buttonProps={{
                                        datatestid: button.id,
                                    }}
                                >
                                    {button?.name}
                                </LspButton>
                            );
                        })}
                    </div>
                </Box>
            )}
        </>
    );
};

export default memo(PendingTaskItem);
