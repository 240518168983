import useAccounts from "@helpers/useAccounts";
import usePathname from "@helpers/usePathname";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Banner from "../../components/Banner";
import { TermDepositNavigationKey } from "../../config/constants";
import useNumber from "../../helpers/useNumber";

const parentName = "term-deposit";

const TermDepositBanner = ({
    totalActiveTermDepositAmount,
    totalRedeemedTermDepositAmount,
}) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { generateChildPathname } = usePathname();

    const { spendAccount } = useAccounts();

    const activeListPath = useMemo(
        () =>
            generateChildPathname(parentName, TermDepositNavigationKey.Active),
        [generateChildPathname]
    );
    const historyListPath = useMemo(
        () =>
            generateChildPathname(parentName, TermDepositNavigationKey.History),
        [generateChildPathname]
    );
    const openTermDepositPath = useMemo(
        () =>
            generateChildPathname(
                parentName,
                TermDepositNavigationKey.OpenTermDeposit
            ),
        [generateChildPathname]
    );

    const navbarItems = useMemo(
        () => [
            {
                title: "td_active_tab",
                icon: "list",
                path: activeListPath,
                selected:
                    location.pathname.includes(activeListPath) ||
                    location.pathname.includes(openTermDepositPath),
            },
            {
                title: "hm_tab_history",
                icon: "history1",
                path: historyListPath,
                selected: location.pathname.includes(historyListPath),
            },
        ],
        [location, activeListPath, historyListPath, openTermDepositPath]
    );

    const bannerSubtitle = useMemo(() => {
        if (location.pathname.includes(historyListPath)) {
            const amount =
                totalRedeemedTermDepositAmount !== null ? (
                    <b>{formatNumber(totalRedeemedTermDepositAmount)}</b>
                ) : (
                    "-"
                );
            return (
                <>
                    {t("td_lb_header_total_redeem")} {amount}
                </>
            );
        }
        if (location.pathname.includes(openTermDepositPath)) {
            const amount = <b>{formatNumber(spendAccount.workingBalance)}</b>;
            return (
                <>
                    {t("available_to_spend")} {amount}
                </>
            );
        }
        const amount =
            totalActiveTermDepositAmount !== null ? (
                <b>{formatNumber(totalActiveTermDepositAmount)}</b>
            ) : (
                "-"
            );
        return (
            <>
                {t("termDeposit_total_term_deposit_balance")} {amount}
            </>
        );
    }, [
        location,
        historyListPath,
        openTermDepositPath,
        t,
        formatNumber,
        totalActiveTermDepositAmount,
        totalRedeemedTermDepositAmount,
        spendAccount,
    ]);

    return (
        <Banner
            title={t("hm_btn_lb_term_deposit")}
            subtitle={bannerSubtitle}
            navbarItems={navbarItems}
        />
    );
};

const mapState = (state) => ({
    totalActiveTermDepositAmount:
        state.account.termDeposit.totalActiveTermDepositAmount,
    totalRedeemedTermDepositAmount:
        state.account.termDeposit.totalRedeemedTermDepositAmount,
});

export default connect(mapState)(TermDepositBanner);
