import { memo, useRef, useEffect, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import SideMenu from "./SideMenu";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    scrollToTop: {
        background: theme.palette.primary.main,
        padding: theme.spacing(2),
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(10),
        color: theme.palette.neutral.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        transform: "rotate(90deg)",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            right: theme.spacing(1),
            bottom: theme.spacing(1),
        },
        "&:hover": {
            background: theme.palette.primary.main,
        },
    },
    "@global": {
        "*::-webkit-scrollbar": {
            width: theme.spacing(0.5),
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.1)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.neutral.grey2,
        },
    },
}));

const Layout = ({ children }) => {
    const classes = useStyles();
    const positionRef = useRef();
    const [showScrollTop, setShowScrollTop] = useState(false);

    const scrollToTopHandler = () => {
        positionRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };

    const onScrollHandler = () => {
        const { top } = positionRef.current.getBoundingClientRect();
        if (top < -500) {
            setShowScrollTop(true);
        } else {
            setShowScrollTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScrollHandler);

        return () => {
            window.removeEventListener("scroll", onScrollHandler);
        };
    }, []);

    return (
        <div ref={positionRef}>
            <TopBar />
            <SideMenu />
            <div className={classes.toolbar} />
            {children}
            {showScrollTop && (
                <IconButton
                    onClick={scrollToTopHandler}
                    className={`font-icon icon-longarrowleft ${classes.scrollToTop}`}
                />
            )}
        </div>
    );
};

export default memo(Layout);
