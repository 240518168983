import { makeStyles } from "@material-ui/core";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspButton from "@components/LspButton";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import billServices from "@services/bill";
import BillHistoryItem from "@containers/SpendAccount/BillPayment/BillHistory/BillHistoryItem";
import BillHistoryItemLoading from "@containers/SpendAccount/BillPayment/BillHistory/BillHistoryItemLoading";

const useStyles = makeStyles((theme) => ({
    histories: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[0],
    },
    historiesHeader: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: "center",
        color: theme.palette.background.grey,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),

        [theme.breakpoints.up("md")]: {
            marginBottom: theme.spacing(4),
        },
    },
    historiesBody: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    historiesFooter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    historiesTitle: {
        background: theme.palette.magicalGrey[200],
    },
    historiesColumn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
        flexDirection: "column",
        "& div": {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            "& > div": {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(0.5),
            },
            "& > div:nth-child(1)": {
                width: "12%",
            },
            "& > div:nth-child(2)": {
                width: "21%",
            },
            "& > div:nth-child(3)": {
                width: "35%",
            },
            "& > div:nth-child(4)": {
                width: "15%",
            },
        },
    },
    historiesInfo: {
        borderBottom: `1px solid ${theme.palette.magicalGrey[200]}`,
    },
    loadMore: {
        textAlign: "center",
        margin: theme.spacing(3),
        textDecoration: "underline",
        cursor: "pointer",
    },
    emptyMessage: {
        textAlign: "center",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));
const numberItemOfPage = 5;

const BillHistory = ({ selectedBill, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const unmounted = useRef(false);
    const [fetching, setFetching] = useState(false);
    const [histories, setHistories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const loadedPage = useRef(1);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getBillHistory = useCallback(async () => {
        const payload = {
            providerCode: selectedBill?.providerCode,
            serviceCode: selectedBill?.serviceCode,
            billCustomerId: selectedBill?.customerId,
            page: loadedPage.current,
            numberPerPage: numberItemOfPage,
        };
        setFetching(true);
        const response = await billServices.getBillHistory(payload);

        if (unmounted.current) return;

        const { code, data } = response.data;

        setFetching(false);

        if (code === RESPONSE_CODE.SUCCESS) {
            setTotalPages(data?.totalPages);
            setHistories((previousList) => [...previousList, ...data?.list]);
            return;
        }

        GlobalDialogController.showError({ errorCode: code });
    }, [selectedBill]);

    useEffect(() => {
        const payload = {
            providerCode: selectedBill?.providerCode,
            serviceCode: selectedBill?.serviceCode,
            billCustomerId: selectedBill?.customerId,
            page: loadedPage.current,
            numberPerPage: numberItemOfPage,
        };
        getBillHistory(payload);
    }, [getBillHistory, selectedBill]);

    const loadMoreHistory = () => {
        loadedPage.current += 1;
        getBillHistory();
    };

    return (
        <Content size="md">
            <div className={classes.histories}>
                <div className={`${classes.historiesHeader} `}>
                    {`${t("bill_title_payment_history")} ${
                        selectedBill?.providerName
                    }`}
                </div>
                <div className={classes.historiesBody}>
                    <div
                        className={`${classes.historiesColumn} ${classes.historiesTitle}`}
                    >
                        <div>{t("bill_history_lb_date_paid")}</div>
                        <div>{t("bill_amount")}</div>
                        <div>{t("bill_lb_bill_number")}</div>
                        <div>{t("bill_lb_bill_period")}</div>
                    </div>

                    {!fetching && histories.length <= 0 && (
                        <LspTypography
                            variant="body1"
                            color="grey"
                            className={classes.emptyMessage}
                        >
                            {t("mm_bill_history_no_transferred_to_destination")}
                        </LspTypography>
                    )}
                    {histories?.length > 0 &&
                        histories?.map((item) => {
                            return (
                                <BillHistoryItem
                                    item={item}
                                    classes={classes}
                                    key={item?.Billid}
                                />
                            );
                        })}

                    {!fetching && loadedPage.current < totalPages && (
                        <div
                            className={classes.loadMore}
                            onClick={loadMoreHistory}
                        >
                            {t("payee_lb_payment_history_showmore")}
                        </div>
                    )}

                    {fetching && <BillHistoryItemLoading classes={classes} />}
                </div>
                <div className={classes.historiesFooter}>
                    <LspButton fullWidth onClick={onClose}>
                        {t("lb_close")}
                    </LspButton>
                </div>
            </div>
        </Content>
    );
};

export default memo(BillHistory);
