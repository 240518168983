import LspTypography from "@components/LspTypography";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const ReceiptLoading = ({ classes }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={classes.receiptInfoBox}>
                <div className={classes.receiptInfoFrom}>
                    <div className={classes.receiptFromControl}>
                        <div className={classes.receiptFromControlLeft}>
                            <LspTypography
                                component="div"
                                variant="subheading2"
                                color="grey"
                            >
                                {t("txnapptype:receipt_from")}
                            </LspTypography>
                        </div>
                        <div className={classes.receiptFromControlRight}>
                            <Box>
                                <Skeleton
                                    variant="text"
                                    width={250}
                                    component="div"
                                />
                                <Skeleton variant="text" width={250} />
                                <Skeleton variant="text" width={250} />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.receiptInfoBox}>
                <div className={classes.receiptInfoTo}>
                    <div className={classes.receiptFromControl}>
                        <div className={classes.receiptFromControlLeft}>
                            <LspTypography
                                component="div"
                                variant="subheading2"
                                color="grey"
                            >
                                {t("txnapptype:receipt_to")}
                            </LspTypography>
                        </div>
                        <div className={classes.receiptFromControlRight}>
                            <Box>
                                <Skeleton
                                    variant="text"
                                    width={250}
                                    component="div"
                                />
                                <Skeleton variant="text" width={250} />
                                <Skeleton variant="text" width={250} />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.receiptInfoBox}>
                <div className={classes.receiptInfoNotes}>
                    <div className={classes.receiptFromControl}>
                        <div className={classes.receiptFromControlLeft}>
                            <LspTypography
                                component="div"
                                variant="subheading2"
                                color="grey"
                            >
                                {t("txnapptype:receipt_des")}
                            </LspTypography>
                        </div>
                        <div className={classes.receiptFromControlRight}>
                            <Skeleton
                                variant="text"
                                width={250}
                                component="div"
                            />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={250} />
                            <Skeleton variant="text" width={250} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReceiptLoading;
