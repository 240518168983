import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { EditIcon } from "@components/LspIcon";
import LspTextField from "@components/LspTextField";
import {
    Box,
    IconButton,
    InputAdornment,
    Paper,
    makeStyles,
} from "@material-ui/core";
import lightIcon from "@assets/images/light.png";
import LspTypography from "@components/LspTypography";
import LspChecker from "@components/LspChecker";
import { useState, useMemo, useEffect } from "react";
import PageHeaderBack from "@components/PageHeaderBack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isFunction } from "lodash";
import { connect } from "react-redux";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0),
    },
    action: {
        padding: theme.spacing(2, 0),
    },
    validationInfo: {
        margin: `${theme.spacing(2)}px 0`,
    },
    validationHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    validationInfoIcon: {
        marginRight: theme.spacing(1),
        width: 27,
        height: 27,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    validationContent: {
        marginLeft: 35,
    },
}));
const CreateNickname = ({
    onSubmit,
    aliasCheckFetching,
    nickname,
    aliasFree,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const { formatNumber } = useNumber();

    const [nicknameInput, setNicknameInput] = useState("");
    const [errors, setErrors] = useState({});

    const nicknameReg = t("IBFT:NickNameRegEx", { returnObjects: true });

    useEffect(() => {
        if (nickname) {
            setNicknameInput(nickname);
        }
    }, [nickname, nicknameReg]);

    const onValidateChange = (data, type) => {
        setErrors((prev) => ({
            ...prev,
            [type]: data.result,
        }));
    };

    const isValidated = useMemo(
        () =>
            Object.values(errors).every(
                (item) => item && nicknameInput.length > 0
            ),
        [errors, nicknameInput.length]
    );

    const onBack = () => {
        history.goBack();
    };

    const onSubmitHandler = () => {
        if (isFunction(onSubmit)) {
            onSubmit({
                nickname: nicknameInput,
            });
        }
    };

    return (
        <Content size="sm">
            <Paper>
                <Box p={3}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmitHandler();
                        }}
                    >
                        <div className={classes.header}>
                            <PageHeaderBack onBack={onBack}>
                                <LspTypography
                                    variant="heading1"
                                    color="primary"
                                >
                                    {t("IBFT:IBFT_nickname_create_header")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    color="grey"
                                    inherit
                                >
                                    {`${t(
                                        "IBFT:IBFT_nickname_intro_fee"
                                    )} ${formatNumber(
                                        aliasFree?.feeAmount
                                    )} ${t(
                                        "IBFT:IBFT_nickname_intro_fee_other"
                                    )}`}
                                </LspTypography>
                            </PageHeaderBack>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.container}>
                                <LspTextField
                                    autoFocus
                                    disableHelperText
                                    type="text"
                                    label={t("IBFT:IBFT_nickname_create_field")}
                                    value={nicknameInput}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setNicknameInput(value || "");
                                    }}
                                    disabled={aliasCheckFetching}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div className={classes.validationInfo}>
                                    <div className={classes.validationHeader}>
                                        <div
                                            className={
                                                classes.validationInfoIcon
                                            }
                                        >
                                            <img
                                                src={lightIcon}
                                                alt="Tip Icon"
                                            />
                                        </div>
                                        <LspTypography
                                            variant="body2"
                                            color="grey"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_create_hint"
                                            )}
                                        </LspTypography>
                                    </div>
                                    <div className={classes.validationContent}>
                                        {nicknameReg.map((item) => (
                                            <LspChecker
                                                key={item.key}
                                                label={t(`IBFT:${item?.key}`)}
                                                regExp={item?.regex}
                                                input={nicknameInput}
                                                onDataChange={(data) =>
                                                    onValidateChange(
                                                        data,
                                                        item?.key
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <LspButton
                                fullWidth
                                type="submit"
                                disabled={!isValidated || aliasCheckFetching}
                            >
                                {t("IBFT:IBFT_nickname_create_btn_next")}
                            </LspButton>
                        </div>
                    </form>
                </Box>
            </Paper>
        </Content>
    );
};

const mapState = (state) => ({
    aliasFree: state.user.aliasFree?.data,
    aliasCheckFetching: state.user.aliasCheck?.fetching,
});

export default connect(mapState)(CreateNickname);
