import { Button, Icon, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import userAction from "../../redux/actions/user";
import PageHeader from "../../components/PageHeader";
import SearchButton from "./SearchButton";
import Payee from "./Payee";
import PayeeLoading from "./PayeeLoading";

const useStyles = makeStyles((theme) => ({
    payeeListWrapper: {
        width: "100%",
        maxWidth: "720px",
        margin: "auto",
    },
    payee: {
        display: "flex",
        padding: theme.spacing(2, 3),
        boxShadow: "0px 2px 8px 2px rgba(0, 0, 0, 0.1)",
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        cursor: "pointer",
        alignItems: "center",
    },
    avatar: {
        marginRight: theme.spacing(2),
        position: "relative",
        padding: "1px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50%",
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    avatarSkeleton: {
        marginRight: theme.spacing(2),
    },
    icon: {
        position: "absolute",
        right: 0,
        bottom: 0,
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.primary.main,
    },
    detail: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: theme.typography.pxToRem(16),
    },
    date: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.grey[500],
        fontStyle: "italic",
    },
    buttonActions: {
        marginBottom: theme.spacing(4),
    },
    invisible: {
        display: "none",
    },
}));

const MemberList = ({ payeeList, getPayeeList, onClickPayee, loading }) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [filteredPayeeList, setFilteredPayeeList] = useState(payeeList);

    // init function
    useEffect(() => {
        getPayeeList();
    }, [getPayeeList]);

    const addPayee = () => {
        console.log("add payee....");
    };

    const filterHandler = useCallback(
        (searchInput) => {
            if (!payeeList) {
                return;
            }
            if (!searchInput) {
                setFilteredPayeeList(payeeList);
                return;
            }
            setFilteredPayeeList(
                payeeList.map((payee) => ({
                    ...payee,
                    invisible: !(
                        payee.fullName
                            ?.toLowerCase()
                            .includes(searchInput.toLowerCase()) ||
                        payee.nickName
                            ?.toLowerCase()
                            .includes(searchInput.toLowerCase())
                    ),
                }))
            );
        },
        [payeeList]
    );

    return (
        <div className={classes.payeeListWrapper}>
            <div>
                <PageHeader>{t("lixi:send_lixi_title")}</PageHeader>
            </div>
            <div className={classes.buttonActions}>
                <SearchButton filter={filterHandler}>
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={
                            <Icon className="font-icon icon-Lixi---timo-to-timo" />
                        }
                        onClick={addPayee}
                    >
                        {t("lixi:send_timo_member")}
                    </Button>
                </SearchButton>
            </div>
            <div className={classes.payeeList}>
                {loading && <PayeeLoading className={classes} />}
                {!loading &&
                    filteredPayeeList?.map((payee) => {
                        return (
                            <Payee
                                className={classes}
                                payee={payee}
                                onClickPayee={() => onClickPayee(payee)}
                                key={payee.payeeId}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

const stateProps = (state) => ({
    payeeList: state.user.payee.list,
    loading: state.user.payee.fetching,
});

const dispatchProps = (dispatch) => ({
    getPayeeList: () => dispatch(userAction.getPayeeListRequest()),
});

export default connect(stateProps, dispatchProps)(MemberList);
