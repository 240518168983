import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * @param {object} props
 * @param {string} props.address
 * @param {string} props.ward
 * @param {string} props.district
 * @param {string} props.province
 */

const useAddress = () => {
    const { t } = useTranslation();

    const joinAddress = useCallback(
        (addressObject) => {
            let fullAddress = addressObject?.address || "";

            if (addressObject?.ward) {
                fullAddress = `${fullAddress}, ${t("lb_ward")} ${
                    addressObject?.ward
                }`;
            }

            if (addressObject?.district) {
                fullAddress = `${fullAddress}, ${t("lb_district")} ${
                    addressObject?.district
                }`;
            }

            if (addressObject?.province) {
                fullAddress = `${fullAddress}, ${addressObject?.province}`;
            }

            return fullAddress;
        },
        [t]
    );

    return { joinAddress };
};

useAddress.propTypes = {
    address: PropTypes.string,
    ward: PropTypes.string,
    district: PropTypes.string,
    province: PropTypes.string,
};

useAddress.defaultProps = {
    address: "",
    ward: "",
    district: "",
    province: "",
};

export default useAddress;
