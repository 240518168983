import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import {
    BOOKING_ACTIONS,
    BOOKING_SERVICES,
    BOOKING_TYPES,
    DELIVERY_METHODS,
} from "@containers/Booking/constant";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { format, parse } from "date-fns";
import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        fontSize: 16,
    },
    body: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
}));

const CreateBookingDeliveryConfirmation = ({
    onBack,
    onCancel,
    onSubmit,
    method,
    selectedAddress,
    selectedTime,
    serviceName,
    submitting,
    type,
    selectedDate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const submitClick = useCallback(() => {
        // let fromTime = selectedTime?.fromTime;
        // let toTime = selectedTime?.toTime;

        let dataToPost = {};

        switch (serviceName) {
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                dataToPost = {
                    bookingType: BOOKING_TYPES.REPLACE_CHIP_CARD,
                    deliveryMethod: method,
                    deliveryAddress: selectedAddress.fullAddress,
                    deliveryProvince: selectedAddress.province.cfgKey,
                    deliveryDistrict: selectedAddress.district.cfgKey,
                    deliveryWard: selectedAddress.ward.cfgKey,
                    deliveryShortAddress: selectedAddress.address,
                    deliveryAddressType: selectedAddress.id,
                };
                onSubmit(dataToPost);
                break;
            case BOOKING_SERVICES.EKYC_BOOKING:
            default:
                if (type === BOOKING_ACTIONS.CHANGE_BOOKING) {
                    dataToPost = {
                        deliveryMethod: method,
                    };
                } else {
                    // new booking
                    dataToPost = {
                        deliveryMethod: method,
                        deliveryAddress: selectedAddress.fullAddress,
                        deliveryProvince: selectedAddress.province.cfgKey,
                        deliveryDistrict: selectedAddress.district.cfgKey,
                        deliveryWard: selectedAddress.ward.cfgKey,
                        deliveryShortAddress: selectedAddress.address,
                        deliveryAddressType: selectedAddress.id,
                    };
                }

                if (selectedDate?.day) {
                    const parsedTime = parse(
                        selectedDate?.day,
                        "yyyy-MM-dd",
                        new Date()
                    );
                    dataToPost.bookTime = format(
                        parsedTime,
                        "dd/MM/yyyy HH:mm:ss"
                    );
                }

                if (selectedTime?.periodId) {
                    dataToPost.periodTime = selectedTime?.periodId;
                }
                onSubmit(dataToPost);
                break;
        }
    }, [
        selectedAddress,
        serviceName,
        onSubmit,
        method,
        selectedTime,
        selectedDate,
        type,
    ]);

    const buttons = useMemo(() => {
        return [
            {
                label: t("loan_vpbank_button_confirm"),
                onClick: submitClick,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ];
    }, [t, onCancel, submitClick]);

    const content = useMemo(() => {
        let time = "";
        if (method === DELIVERY_METHODS.MOTORBIKE) {
            const parsedTime =
                parse(selectedDate?.day, "yyyy-MM-dd", new Date()) || "";
            time = `${selectedTime?.name} ${format(parsedTime, "dd/MM/yyyy")}`;
        }

        return {
            title: t("booking_delivery_confirmation"),
            address: {
                label: t("lb_to"),
                name: t("booking_delivery_new_address"),
                value: selectedAddress?.fullAddress,
            },
            time: {
                label: t("lb_time"),
                value: time,
                note: t("master:card_delivery_address"),
            },
        };
    }, [t, selectedAddress, selectedDate, selectedTime, method]);

    return (
        <Content size="sm">
            <PageHeaderBack onBack={onBack}>{content?.title}</PageHeaderBack>
            <Paper className={classes.body}>
                <Box>
                    <div className={classes.label} htmlFor="address">
                        {content?.address?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.address?.name}
                    </LspTypography>
                    <LspTypography variant="body1">
                        {content?.address?.value}
                    </LspTypography>
                </Box>

                <Box mt={2}>
                    {content?.time?.value !== "" && (
                        <>
                            <div className={classes.label} htmlFor="address">
                                {content?.time?.label}
                            </div>
                            <LspTypography variant="heading1" color="primary">
                                {content?.time?.value}
                            </LspTypography>
                        </>
                    )}

                    <Box mt={2}>
                        <LspTypography variant="body1">
                            {content?.time?.note}
                        </LspTypography>
                    </Box>
                </Box>
            </Paper>
            <Box mt={3}>
                <DoubleButtons
                    primaryButton={buttons[0]}
                    secondaryButton={buttons[1]}
                    progressing={submitting}
                />
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    method: state.booking.method,
    selectedAddress: state.booking.address,
    selectedTime: state.booking.selectedTime,
    selectedDate: state.booking.selectedDate,
    serviceName: state.booking.serviceName,
    type: state.booking.type,
});
export default connect(mapState)(CreateBookingDeliveryConfirmation);
