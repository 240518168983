import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Interweave from "interweave";
import lixiActions from "@redux/actions/lixi";
import Thumbnail from "./Thumbnail";
import ThumbnailLoading from "./ThumbnailLoading";

const useStyles = makeStyles((theme) => ({
    themeList: {
        background: theme.palette.magicalGrey[100],
        height: theme.typography.pxToRem(310),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        overflowY: "scroll",
        overflowX: "visible",
        [theme.breakpoints.up("xs")]: {
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            height: theme.typography.pxToRem(424),
        },
    },
    description: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: "center",
        margin: theme.spacing(2),
    },
}));

const LixiTheme = ({ setSubmitInfo, theme }) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [activeThemeItem, setActiveThemeItem] = useState("");
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [themeLoading, setThemeLoading] = useState(true);

    const themeList = useMemo(() => {
        const arrayThemes = t("lixi:LIXI_available_theme_keys", {
            returnObjects: true,
        });
        const list = [];
        for (let i = 0; i < arrayThemes.length; i++) {
            const th = t(`lixi:${arrayThemes[i]}`, { returnObjects: true });
            if (th) {
                list.push(th);
            }
        }

        setThemeLoading(false);

        return list;
    }, [t]);

    const onSelectTheme = useCallback(
        (selectedTheme) => {
            setIsFirstTime(false);
            setSubmitInfo({ key: "theme", value: selectedTheme });
            setActiveThemeItem(selectedTheme.id);
        },
        [setSubmitInfo]
    );

    useEffect(() => {
        if (theme && isFirstTime) {
            onSelectTheme(theme);
        }
    }, [theme, onSelectTheme, isFirstTime]);

    return (
        <div>
            {themeLoading && <ThumbnailLoading className={classes.themeList} />}
            {!themeLoading && themeList && (
                <div className={classes.themeList}>
                    {themeList?.map((th) => {
                        return (
                            <Fragment key={th.id}>
                                {!th.group && (
                                    <Thumbnail
                                        theme={th}
                                        onSelect={onSelectTheme}
                                        activeItem={activeThemeItem}
                                    />
                                )}
                            </Fragment>
                        );
                    })}

                    <Interweave
                        className={classes.description}
                        tagName="p"
                        content={t("lixi:operation_smile_theme")}
                    />

                    {themeList?.map((th) => {
                        return (
                            <Fragment key={th.id}>
                                {th.group && th.group === "smile" && (
                                    <Thumbnail
                                        key={th.id}
                                        theme={th}
                                        onSelect={onSelectTheme}
                                        activeItem={activeThemeItem}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const stateProps = (state) => ({
    theme: state.lixi.theme,
});

const dispatchProps = (dispatch) => ({
    setSubmitInfo: (payload) => dispatch(lixiActions.setSubmitInfo(payload)),
});

export default connect(stateProps, dispatchProps)(LixiTheme);
