import api from "@config/api";

const getSetting = async () => {
    return await api.get("/user/setting/get", null);
};

const updateSetting = async (payload) => {
    return await api.post("/user/setting/update", payload);
};

const getSettingNotifySMS = async () => {
    return await api.get("/user/setting/notification/sms/specs", null);
};
const securityService = {
    getSetting,
    updateSetting,
    getSettingNotifySMS,
};

export default securityService;
