import Content from "@components/Content";
import { Grid, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    fetchingInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& span": {
            width: "32%",
        },
        marginBottom: theme.spacing(4),
    },
    wrapper: {
        background: theme.palette.background.default,
        padding: `${theme.spacing(3)}px !important`,
    },
}));

const CardFetching = () => {
    const classes = useStyles();

    return (
        <Content>
            <div className={classes.buttons}>
                <Skeleton variant="rect" height={46} />
                <Skeleton variant="rect" height={46} />
                <Skeleton variant="rect" height={46} />
            </div>
            <Grid container spacing={1} justify="center" alignItems="center">
                <Grid item xs={12} sm={9} className={classes.wrapper}>
                    <div className={classes.fetchingInfo}>
                        <div>
                            <Skeleton variant="text" width={150} />
                            <Skeleton variant="text" width={180} />
                            <br />
                            <Skeleton variant="text" width={130} />
                            <Skeleton variant="text" width={170} />
                            <br />
                            <Skeleton variant="text" width={120} />
                            <Skeleton variant="text" width={165} />
                        </div>
                        <div>
                            <Skeleton variant="rect" width={134} height={214} />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={9} className={classes.wrapper}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </Grid>
            </Grid>
        </Content>
    );
};

export default CardFetching;
