export const BOOKING_SERVICES = {
    EKYC_BOOKING: "EKYC_BOOKING",
    REPLACE_CHIP_BOOKING: "REPLACE_CHIP_BOOKING",
};

export const BOOKING_ACTIONS = {
    NEW_BOOKING: "NEW_BOOKING",
    VIEW_BOOKING: "VIEW_BOOKING",
    CHANGE_BOOKING: "CHANGE_BOOKING",
};

export const BOOKING_TYPES = {
    CREDIT_CARD: "CC",
    KYC: "KYC",
    SERVICE_REQUEST: "SERVICE_REQUEST",
    REPLACE_CHIP_CARD: "REPLACE_CHIP_CARD",
};
export const DELIVERY_METHODS = {
    HANGOUT: "Hangout",
    MAIL: "Mail",
    MOTORBIKE: "Motobike",
    DELIVERY: "Delivery",
};
export const DELIVERY_ADDRESS = {
    PERMANENT_ADDRESS: "Permanent",
    CURRENT_ADDRESS: "Current",
    OTHER_ADDRESS: "Other",
    PRIVATE: "Private",
    HANGOUT: "Hangout",
};

export const BOOKING_STEPS = {
    PICKUP_METHOD: 0,
    HANGOUT_PROVINCE_SELECTION: 1,
    HANGOUT_LOCATION_SELECTION: 2,
    HANGOUT_TIME_SELECTION: 3,
    HANGOUT_CONFIRMATION: 4,
    SUCCESS_VIEW: 5,
    DELIVERY_ADDRESS_SELECTION: 6,
    DELIVERY_OTHER_ADDRESS_INPUT: 7,
    DELIVERY_CONFIRMATION: 8,
    DELIVERY_METHOD_SELECTION: 9,
    DELIVERY_BOOKING_TIME: 10,
};

export const BOOKING_SERVICE_ID = {
    EKYC_BOOKING: "ekycId",
    REPLACE_CHIP_CARD: "replacementId",
};

export const BOOKING_PERIOD = {
    MORNING: "MORNING",
    AFTERNOON: "AFTERNOON",
    EVENING: "EVENING",
};
