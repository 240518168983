/* eslint-disable react/no-array-index-key */
import { memo, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import LspTextField from "@components/LspTextField";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import LspAddress from "@components/LspAddress";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Paper,
} from "@material-ui/core";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";
import { WEALTH_CREDIT_CARD_STEPS } from "../wealthConstants";

const HOUSING_SITUATION_KEYS = {
    COLLECTIVE_OWNERSHIP: 1,
    OWN_HOUSE: 2,
    OWN_HOUSE_WITH_LOAN: 3,
    RELATIVES_HOUSE: 4,
    RENT_HOUSE: 5,
};

const WealthCreditCardAddressForm = ({
    onNext,
    onBack,
    user,
    setAddressForm,
    addressForm,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const [errors, setErrors] = useState({
        livingMonths: null,
        housingSituation: null,
    });

    const [permanentErrors, setPermanentErrors] = useState({
        address: null,
        province: null,
        district: null,
        ward: null,
    });

    const [currentErrors, setCurrentErrors] = useState({
        address: null,
        province: null,
        district: null,
        ward: null,
    });

    const HOUSING_SITUATION_LIST = useMemo(
        () => [
            {
                cfgKey: HOUSING_SITUATION_KEYS.OWN_HOUSE,
                cfgValue: t("wealth:mc_v3_fin_house_opt3"),
            },
            {
                cfgKey: HOUSING_SITUATION_KEYS.RELATIVES_HOUSE,
                cfgValue: t("wealth:mc_v3_fin_house_opt4"),
            },
            {
                cfgKey: HOUSING_SITUATION_KEYS.RENT_HOUSE,
                cfgValue: t("wealth:mc_v3_fin_house_opt5"),
            },
        ],
        [t]
    );

    // ------ Start: Init params ------

    const [permanentAddress, setPermanentAddress] = useState(
        addressForm?.permanentAddress
            ? addressForm?.permanentAddress
            : user?.address.permanentAddress
    );

    const [selectedPermanentProvince, setSelectedPermanentProvince] = useState(
        () => {
            if (addressForm?.selectedPermanentProvince) {
                return {
                    cfgKey: addressForm?.selectedPermanentProvince?.cfgKey,
                    cfgValue: addressForm?.selectedPermanentProvince?.cfgValue,
                };
            }
            if (user?.address) {
                return {
                    cfgKey: user?.address?.permanentCityId,
                    cfgValue: user?.address?.permanentCityName,
                };
            }

            return {
                cfgKey: "",
                cfgValue: "",
            };
        }
    );

    const [selectedPermanentDistrict, setSelectedPermanentDistrict] = useState(
        () => {
            if (addressForm?.selectedPermanentDistrict) {
                return {
                    cfgKey: addressForm?.selectedPermanentDistrict?.cfgKey,
                    cfgValue: addressForm?.selectedPermanentDistrict?.cfgValue,
                };
            }
            if (user?.address) {
                return {
                    cfgKey: user?.address?.permanentDistId,
                    cfgValue: user?.address?.permanentDistName,
                };
            }

            return {
                cfgKey: "",
                cfgValue: "",
            };
        }
    );
    const [selectedPermanentWard, setSelectedPermanentWard] = useState(() => {
        if (addressForm?.selectedPermanentWard) {
            return {
                cfgKey: addressForm?.selectedPermanentWard?.cfgKey,
                cfgValue: addressForm?.selectedPermanentWard?.cfgValue,
            };
        }
        if (user?.address) {
            return {
                cfgKey: user?.address?.permanentWardId,
                cfgValue: user?.address?.permanentWardName,
            };
        }

        return {
            cfgKey: "",
            cfgValue: "",
        };
    });

    const [currentAddress, setCurrentAddress] = useState(
        addressForm?.addressName
            ? addressForm?.addressName
            : user?.address.addressName
    );

    const [selectedCurrentProvince, setSelectedCurrentProvince] = useState(
        () => {
            if (addressForm?.selectedCurrentProvince) {
                return {
                    cfgKey: addressForm?.selectedCurrentProvince?.cfgKey,
                    cfgValue: addressForm?.selectedCurrentProvince?.cfgValue,
                };
            }
            if (user?.address) {
                return {
                    cfgKey: user?.address?.cityId,
                    cfgValue: user?.address?.cityName,
                };
            }

            return {
                cfgKey: "",
                cfgValue: "",
            };
        }
    );

    const [selectedCurrentDistrict, setSelectedCurrentDistrict] = useState(
        () => {
            if (addressForm?.selectedCurrentDistrict) {
                return {
                    cfgKey: addressForm?.selectedCurrentDistrict?.cfgKey,
                    cfgValue: addressForm?.selectedCurrentDistrict?.cfgValue,
                };
            }
            if (user?.address) {
                return {
                    cfgKey: user?.address?.distId,
                    cfgValue: user?.address?.distName,
                };
            }

            return {
                cfgKey: "",
                cfgValue: "",
            };
        }
    );
    const [selectedCurrentWard, setSelectedCurrentWard] = useState(() => {
        if (addressForm?.selectedCurrentWard) {
            return {
                cfgKey: addressForm?.selectedCurrentWard?.cfgKey,
                cfgValue: addressForm?.selectedCurrentWard?.cfgValue,
            };
        }
        if (user?.address) {
            return {
                cfgKey: user?.address?.wardId,
                cfgValue: user?.address?.wardName,
            };
        }

        return {
            cfgKey: "",
            cfgValue: "",
        };
    });

    const [livingMonths, setLivingMonths] = useState(
        addressForm?.livingMonths ? addressForm?.livingMonths : ""
    );

    const [housingSituation, setHousingSituation] = useState(
        addressForm?.housingSituation ? addressForm?.housingSituation : null
    );

    // ------ End: Init params ------

    // ------ Start: On change handler function ------

    // Permanent Address
    const onChangePermanentProvince = (province) => {
        if (province?.cfgKey === "") {
            setPermanentErrors((prev) => ({
                ...prev,
                province: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setPermanentErrors((prev) => ({
                ...prev,
                province: "",
            }));
        }

        setSelectedPermanentProvince(province);
    };
    const onChangePermanentDistrict = (district) => {
        if (district?.cfgKey === "") {
            setPermanentErrors((prev) => ({
                ...prev,
                district: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setPermanentErrors((prev) => ({
                ...prev,
                district: "",
            }));
        }
        setSelectedPermanentDistrict(district);
    };
    const onChangePermanentWard = (ward) => {
        if (ward?.cfgKey === "") {
            setPermanentErrors((prev) => ({
                ...prev,
                ward: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setPermanentErrors((prev) => ({
                ...prev,
                ward: "",
            }));
        }
        setSelectedPermanentWard(ward);
    };

    const onChangePermanentAddress = (e) => {
        setPermanentErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setPermanentAddress(address);
        if (!address) {
            setPermanentErrors((prev) => ({
                ...prev,
                address: t("wealth:mc_v3_error_we_need"),
            }));
        }
    };

    // Current Address
    const onChangeCurrentProvince = (province) => {
        if (province?.cfgKey === "") {
            setCurrentErrors((prev) => ({
                ...prev,
                province: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCurrentErrors((prev) => ({
                ...prev,
                province: "",
            }));
        }

        setSelectedCurrentProvince(province);
    };
    const onChangeCurrentDistrict = (district) => {
        if (district?.cfgKey === "") {
            setCurrentErrors((prev) => ({
                ...prev,
                district: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCurrentErrors((prev) => ({
                ...prev,
                district: "",
            }));
        }
        setSelectedCurrentDistrict(district);
    };
    const onChangeCurrentWard = (ward) => {
        if (ward?.cfgKey === "") {
            setCurrentErrors((prev) => ({
                ...prev,
                ward: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCurrentErrors((prev) => ({
                ...prev,
                ward: "",
            }));
        }
        setSelectedCurrentWard(ward);
    };

    const onChangeCurrentAddress = (e) => {
        setCurrentErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setCurrentAddress(address);
        if (!address) {
            setCurrentErrors((prev) => ({
                ...prev,
                address: t("wealth:mc_v3_error_we_need"),
            }));
        }
    };

    const onChangeLivingMonths = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER,
            text: e.target.value,
        });
        setLivingMonths(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                livingMonths: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                livingMonths: "",
            }));
        }
    };

    const onChangeHousingSituation = (e) => {
        const { value } = e.target;
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                housingSituation: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                housingSituation: "",
            }));
        }
        setHousingSituation(value);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();

        setAddressForm({
            permanentAddress,
            selectedPermanentProvince,
            selectedPermanentDistrict,
            selectedPermanentWard,
            addressName: currentAddress,
            selectedCurrentProvince,
            selectedCurrentDistrict,
            selectedCurrentWard,
            livingMonths,
            housingSituation,
        });

        onNext(WEALTH_CREDIT_CARD_STEPS.MARITAL_STATUS_FORM);
    };

    const onBackHandler = () => {
        setAddressForm(null);
        onBack();
    };

    // ------ End: On submit form handler function ------

    return (
        <form onSubmit={onSubmitForm}>
            <PageHeaderBack onBack={onBackHandler}>
                {t("wealth:mc_v3_per_inf_title")}
            </PageHeaderBack>
            <Paper>
                <div className={sharedClasses.formHeader}>
                    <LspTypography variant="heading3" color="grey">
                        {t("wealth:mc_v3_current_housing")}
                    </LspTypography>
                </div>

                <div className={sharedClasses.boxFormWrapper}>
                    <LspTypography
                        variant="body3"
                        color="grey"
                        className={sharedClasses.subtitle}
                    >
                        {t("wealth:mc_v3_permanent_address")}
                    </LspTypography>
                    <LspAddress
                        selectedProvince={selectedPermanentProvince}
                        onChangeProvince={onChangePermanentProvince}
                        selectedDistrict={selectedPermanentDistrict}
                        onChangeDistrict={onChangePermanentDistrict}
                        selectedWard={selectedPermanentWard}
                        onChangeWard={onChangePermanentWard}
                        errors={permanentErrors}
                        fullWidth
                    />
                    <LspTextField
                        name="permanentAddress"
                        error={!!permanentErrors?.address}
                        label={t("bill_lb_address")}
                        helperText={permanentErrors?.address || " "}
                        inputProps={{
                            maxLength: 35,
                        }}
                        onChange={onChangePermanentAddress}
                        value={permanentAddress}
                    />

                    <LspTypography
                        variant="body3"
                        color="grey"
                        className={sharedClasses.subtitle}
                    >
                        {t("wealth:mc_v3_current_address_title")}
                    </LspTypography>

                    <LspAddress
                        selectedProvince={selectedCurrentProvince}
                        onChangeProvince={onChangeCurrentProvince}
                        selectedDistrict={selectedCurrentDistrict}
                        onChangeDistrict={onChangeCurrentDistrict}
                        selectedWard={selectedCurrentWard}
                        onChangeWard={onChangeCurrentWard}
                        errors={currentErrors}
                        fullWidth
                    />
                    <LspTextField
                        name="addressName"
                        error={!!currentErrors?.address}
                        label={t("bill_lb_address")}
                        helperText={currentErrors?.address || " "}
                        inputProps={{
                            maxLength: 35,
                        }}
                        onChange={onChangeCurrentAddress}
                        value={currentAddress}
                    />
                    <div className={sharedClasses.monthWrapper}>
                        <LspTextField
                            autoFocus
                            name="livingMonths"
                            error={!!errors?.livingMonths}
                            label={t("wealth:mc_v3_info_living_time_title")}
                            helperText={errors?.livingMonths || " "}
                            inputProps={{
                                maxLength: 5,
                            }}
                            onChange={onChangeLivingMonths}
                            value={livingMonths}
                        />
                        {livingMonths && (
                            <span className={sharedClasses.monthLabel}>
                                {t("wealth:mc_v3_working_months_lb")}
                            </span>
                        )}
                    </div>

                    <LspTypography
                        variant="body3"
                        color="grey"
                        className={sharedClasses.subtitle}
                    >
                        {t("wealth:mc_v3_fin_house_title")}
                    </LspTypography>

                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="housingSituation"
                            name="housingSituation"
                        >
                            {HOUSING_SITUATION_LIST?.map((item) => {
                                return (
                                    <FormControlLabel
                                        key={item.cfgKey}
                                        value={item.cfgKey}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={
                                                    housingSituation ===
                                                    item?.cfgKey.toString()
                                                }
                                                onChange={
                                                    onChangeHousingSituation
                                                }
                                            />
                                        }
                                        label={item.cfgValue}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>
            </Paper>
            <LspButton
                fullWidth
                type="submit"
                className={sharedClasses.button}
                disabled={
                    !housingSituation ||
                    !livingMonths ||
                    !currentAddress ||
                    !selectedCurrentProvince ||
                    !selectedCurrentDistrict ||
                    !selectedCurrentWard ||
                    !permanentAddress ||
                    !selectedPermanentProvince ||
                    !selectedPermanentDistrict ||
                    !selectedPermanentWard
                }
            >
                {t("wealth:mc_v3_btn_next")}
            </LspButton>
        </form>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    addressForm: state.wealthCreditCard.addressForm,
});

const mapDispatch = (dispatch) => ({
    setAddressForm: (payload) =>
        dispatch(wealthCreditCardAction.setAddressForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardAddressForm)
);
