import { makeStyles, Icon } from "@material-ui/core";
import tomiLooking from "@assets/images/tomi_looking.png";
import { LspDialog } from "@components/LspDialog";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import LspChecker from "@components/LspChecker";
import LspButton from "@components/LspButton";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 4),
        background: theme.palette.background.paper,
    },
    header: {
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    headerImg: {
        width: 160,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    headerTitle: {
        marginBottom: theme.spacing(2),
    },
    content: {
        background: theme.palette.magicalGrey[800],
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(5),
    },
}));
const OverdraftPreviewDialog = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const successNote = useMemo(() => {
        return (
            t("overdraft:overdraft_result_success_notes", {
                returnObjects: true,
            }) || []
        );
    }, [t]);

    const handleClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };
    return (
        <>
            <LspDialog open>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <div className={classes.headerImg}>
                            <img src={tomiLooking} alt="Tomi Looking" />
                        </div>
                        <LspTypography
                            variant="title2"
                            textCenter
                            className={classes.headerTitle}
                        >
                            {t("overdraft:overdraft_result_success_note_title")}
                        </LspTypography>
                        <LspTypography variant="body1" color="grey" textCenter>
                            {t(
                                "overdraft:overdraft_result_success_description"
                            )}
                        </LspTypography>
                    </div>
                    <div className={classes.content}>
                        {successNote.map((item) => (
                            <LspChecker
                                label={
                                    <LspTypography
                                        color="black"
                                        variant="body2"
                                    >
                                        {item}
                                    </LspTypography>
                                }
                                emptyIcon={<Icon fontSize="small">✨</Icon>}
                            />
                        ))}
                    </div>
                    <LspButton fullWidth onClick={handleClose}>
                        {t("overdraft:overdraft_result_success_cta")}
                    </LspButton>
                </div>
            </LspDialog>
        </>
    );
};

export default OverdraftPreviewDialog;
