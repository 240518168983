/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Box, ButtonBase, Icon, makeStyles } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { useMemo, useRef, useState, useEffect } from "react";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { DatePicker } from "@material-ui/pickers";
import LspTextField from "@components/LspTextField";
import { connect } from "react-redux";
import addDays from "date-fns/addDays";
import { addMonths, format } from "date-fns";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import InfoTooltip from "@components/InfoTooltip";
import userService from "@services/user";
import { REGULATION_TYPE, RESPONSE_CODE } from "@config/constants";
import Loading from "@components/Loading";
import Interweave from "interweave";
import useForeignerDialog from "@helpers/useForeignerDialog";
import FooterControlActions from "@components/FooterControlActions";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(3, 2),
        },
    },
    typeList: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        border: `1px solid ${theme.palette.neutral.grey3}`,
        "& > button": {
            width: "23%",
            textAlign: "center",
            margin: theme.spacing(1, 0),
            position: "relative",
            display: "block",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        marginBottom: theme.spacing(2),
    },
    iconWrapper: {
        position: "absolute",
        width: 25,
        height: 25,
        borderRadius: "50%",
        backgroundColor: theme.palette.success.main,
        border: `1px solid ${theme.palette.primary.success}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        color: theme.palette.neutral.white,
        right: "15px",
        "& > span": {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(40),
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.status.success,
        },
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 3, 3, 3),

        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    formGroup: {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            width: "45%",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                "&:last-child": {
                    marginTop: theme.spacing(2),
                },
            },
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    tooltip: {
        "& svg": {
            fontSize: "1rem",
        },
    },
    wrapperTooltip: {
        // display: "inline-block",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    maybeLater: {
        marginTop: theme.spacing(4),
        textAlign: "center",
        "& > .desc": {
            fontStyle: "italic",
        },
    },
    beatIcon: {
        color: theme.palette.status.success,
        fontSize: "1rem",
        marginRight: theme.spacing(0.5),
    },

    tooltipIcon: {
        color: theme.palette.grey[400],
        cursor: "pointer",
        backgroundColor: "transparent",
        display: "inline-block",
        lineHeight: 0,
        fontSize: "1rem",
        marginLeft: theme.spacing(0.5),
        "& svg": {
            fontSize: "1rem",
        },
    },
    recurringType: {
        position: "absolute",
        right: theme.spacing(1.5),
        top: theme.spacing(2.75),
    },
}));

const GoalSaveReview = ({
    stepBack,
    serverTime,
    infoGoalSave,
    getListGoalSave,
    setAddNewSuccess,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedType, setSelectedType] = useState(null);
    const currentDate = useMemo(() => {
        if (serverTime.value) {
            const dateFormatted = addDays(new Date(serverTime.value), 1);
            return dateFormatted;
        }
    }, [serverTime]);
    const [startDate, setStartDate] = useState(currentDate);
    const [amount, setAmount] = useState(null);
    const [amountErr, setAmountErr] = useState("");
    const [suggestAmount, setSuggestAmount] = useState(null);
    const [fetchingSuggest, setFetchingSuggest] = useState(false);
    const [loading, setLoading] = useState(false);
    const { thousandSeparator, decimalSeparator } = useNumber();
    const { formatNumber } = useNumber();
    const { dialog } = useForeignerDialog();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const iconList = useMemo(() => {
        const listIcon = [
            {
                id: 1,
                icon: "vehicle",
                title: t("gs_btn_daily"),
                recurringType: "daily",
            },
            {
                id: 2,
                icon: "phone2",
                title: t("gs_btn_weekly"),
                recurringType: "weekly",
            },
            {
                id: 3,
                icon: "wallet",
                title: t("gs_btn_monthly"),
                recurringType: "monthly",
            },
            {
                id: 4,
                icon: "travel1",
                title: t("gs_btn_maybe_later_web"),
                recurringType: " ",
            },
        ];
        return listIcon;
    }, [t]);

    const selectedInfo = useMemo(() => {
        const elm = iconList.find((item) => item.id === selectedType);
        return elm;
    }, [iconList, selectedType]);

    const maxRangeDate = useMemo(() => {
        if (currentDate) {
            const dateFormatted = addMonths(new Date(currentDate), 2);
            return dateFormatted;
        }
    }, [currentDate]);

    const toolTipContent = useMemo(() => {
        let content = t("gs_hint_review_suggest");
        if (amount < suggestAmount) {
            content = content.replace(
                "%@",
                formatNumber(suggestAmount - amount)
            );
        } else {
            content = content.replace("%@", 0);
        }

        return content;
    }, [suggestAmount, amount, t, formatNumber]);

    const maxDate = useMemo(() => {
        if (infoGoalSave.targetDate > maxRangeDate) {
            return maxRangeDate;
        }
        return infoGoalSave.targetDate;
    }, [infoGoalSave, maxRangeDate]);

    const getSuggest = async (item, startDateChange) => {
        if (item.id !== selectedType || startDateChange) {
            setSelectedType(item.id);
            if (item.id === 4) {
                return;
            }
            setFetchingSuggest(true);
            const dataToPost = {
                endDate: format(infoGoalSave.targetDate, "dd/MM/yyyy"),
                recurringType: item.recurringType,
                startDate: startDateChange
                    ? format(startDateChange, "dd/MM/yyyy")
                    : format(startDate, "dd/MM/yyyy"),
                targetMoney: infoGoalSave.goalAmount,
            };
            const { data } = await userService.getGoalSaveSuggest(dataToPost);
            if (unmounted.current) return;
            setFetchingSuggest(false);

            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    setAmount(data.data.suggestAmount);
                    setSuggestAmount(data.data.suggestAmount);
                    break;
                case RESPONSE_CODE.NOT_ACCEPTABLE:
                    setSelectedType(null);
                    break;
                case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                    dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: data.code });
                    break;
            }
        }
    };

    const onToDateChange = (d) => {
        if (!d) {
            setStartDate(" ");
            return;
        }
        setStartDate(new Date(d));
        const selectedItem = iconList.find((item) => item.id === selectedType);
        getSuggest(selectedItem, new Date(d));
    };

    const createGoal = async () => {
        const dataToPost = {
            goalAmount: infoGoalSave.goalAmount,
            goalCategory: infoGoalSave.goalCategory,
            planName: infoGoalSave.planName,
            recurring: 1,
            recurringAmount: amount,
            recurringType: selectedInfo.recurringType,
            startDate: format(startDate, "dd/MM/yyyy"),
            targetDate: format(infoGoalSave.targetDate, "dd/MM/yyyy"),
        };
        if (selectedType === 4) {
            dataToPost.recurring = 0;
            dataToPost.recurringType = "";
            dataToPost.recurringAmount = 0;
        } else if (amount < 1000) {
            setAmountErr(t("gs_ms_recur_amount_too_small"));
            return;
        }
        setLoading(true);
        const { data } = await userService.addNewGoalSave(dataToPost);
        if (unmounted.current) return;
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.CREATED:
            case RESPONSE_CODE.SUCCESS:
                GlobalDialogController.hide();
                getListGoalSave(true);
                setAddNewSuccess(true);
                break;
            case RESPONSE_CODE.FAST_CASH_REACH_LIMIT:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("fast_cash_title_error_limit_reached"),
                        boldContent: t(
                            "fast_cash_sub_title_error_limit_reached"
                        ),
                        content: t("fast_cash_msg_error_limit_reached"),
                        button: t("fast_cash_button_error_limit_reached"),
                    },
                });
                break;
            case RESPONSE_CODE.FAST_CASH_NOT_ALLOW:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("fast_cash_title_error_transfer_not_allowed"),
                        content: t("fast_cash_msg_error_transfer_not_allowed"),
                        highlight: t(
                            "fast_cash_more_information_error_transfer_not_allowed"
                        ),
                        button: t("lb_ok"),
                    },
                });
                break;

            case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const buttons = [
        {
            onClick: stepBack,
            label: t("lb_back"),
        },

        {
            onClick: createGoal,
            label: t("gs_label_create_goal_save"),
            type: "submit",
        },

        {
            onClick: () => GlobalDialogController.hide(),
            label: t("lb_cancel"),
        },
    ];

    return (
        <div>
            <div className={classes.body}>
                <div className={classes.typeList}>
                    {iconList.map((item, key) => (
                        <ButtonBase
                            key={key}
                            onClick={() => getSuggest(item)}
                            datatestid={`recurringType${item?.recurringType?.trim()}`}
                        >
                            {selectedType === item.id && (
                                <div className={`${classes.iconWrapper}`}>
                                    <Icon className={`font-icon icon-ok `} />
                                </div>
                            )}

                            <Icon
                                className={`font-icon icon-${item.icon} ${classes.icon}`}
                            />
                            <LspTypography
                                variant="body2"
                                color="black"
                                component="div"
                            >
                                {item.title}
                            </LspTypography>
                        </ButtonBase>
                    ))}
                </div>
                {selectedType === 4 && (
                    <div className={classes.maybeLater}>
                        <LspTypography variant="body2" color="black">
                            {t("gs_msg_review_may_be_1")}
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            color="black"
                            className="desc"
                        >
                            {t("gs_msg_review_may_be_2")}
                        </LspTypography>
                    </div>
                )}
                {selectedType &&
                    selectedType !== 4 &&
                    (fetchingSuggest ? (
                        <Box my={4}>
                            <Loading />
                        </Box>
                    ) : (
                        <div className={classes.formGroup}>
                            <div>
                                <Box marginBottom={1}>
                                    <InfoTooltip
                                        color="black"
                                        title={t(
                                            "gs_hint_review_start_automatic_date"
                                        )}
                                        icon="QuestionMarkIcon"
                                        className={classes.tooltip}
                                    >
                                        <LspTypography
                                            variant="tiny"
                                            color="grey"
                                            component="span"
                                            className={classes.wrapperTooltip}
                                        >
                                            {t("gs_start_automatic")}
                                        </LspTypography>
                                    </InfoTooltip>
                                </Box>

                                <DatePicker
                                    TextFieldComponent={LspTextField}
                                    disableHelperText
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    invalidDateMessage=" "
                                    maxDateMessage=" "
                                    value={startDate}
                                    onChange={onToDateChange}
                                    maxDate={maxDate}
                                    minDate={currentDate}
                                    inputProps={{
                                        datatestid: "goalStartDate",
                                    }}
                                />
                            </div>
                            <div>
                                <Box display="flex" marginBottom={1}>
                                    <Icon
                                        className={`font-icon icon-beat ${classes.beatIcon}`}
                                    />
                                    <InfoTooltip
                                        color="black"
                                        title={
                                            <Interweave
                                                content={toolTipContent}
                                            />
                                        }
                                        icon="QuestionMarkIcon"
                                        className={classes.tooltip}
                                    >
                                        <LspTypography
                                            variant="tiny"
                                            color="grey"
                                            component="span"
                                            className={classes.wrapperTooltip}
                                        >
                                            {t("gs_timo_suggest_contribution")}
                                        </LspTypography>
                                    </InfoTooltip>
                                </Box>

                                <Box position="relative">
                                    <NumberFormat
                                        customInput={LspTextField}
                                        autoComplete="off"
                                        thousandSeparator={thousandSeparator}
                                        decimalSeparator={decimalSeparator}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        allowedDecimalSeparators={false}
                                        value={amount}
                                        inputProps={{
                                            maxLength: 19,
                                            type: "text",
                                            datatestid:
                                                "goalSuggestContribution",
                                        }}
                                        onValueChange={(e) => {
                                            setAmountErr("");
                                            setAmount(e.floatValue);
                                        }}
                                        defaultValue={null}
                                        helperText={amountErr}
                                        error={!!amountErr}
                                        // label={t(
                                        //     `gs_btn_${selectedInfo.recurringType}`
                                        // )}
                                    />
                                    <LspTypography
                                        color="grey"
                                        className={classes.recurringType}
                                        variant="body2"
                                    >
                                        {t(
                                            `gs_btn_${selectedInfo.recurringType}`
                                        )}
                                    </LspTypography>
                                </Box>
                            </div>
                        </div>
                    ))}
            </div>

            <div className={classes.footer}>
                <FooterControlActions
                    leftButton={buttons[0]}
                    centerButton={buttons[1]}
                    rightButton={buttons[2]}
                    disabled={!selectedType || (selectedType !== 4 && !amount)}
                    progressing={loading}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime,
});

export default connect(mapState, null)(GoalSaveReview);
