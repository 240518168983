import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    billBreak: {
        position: "relative",
    },
    billBreakLine: {
        opacity: "0.18",
        border: `2px dashed ${theme.palette.magicalGrey[300]}`,
    },
    billBreakCircle: {
        width: 18,
        height: 18,
        position: "absolute",
        borderRadius: "50%",
        backgroundColor: theme.palette.background.default,
    },
    billBreakCircleRight: {
        bottom: -6,
        right: -10,
    },
    billBreakCircleLeft: {
        left: -10,
        bottom: -6,
    },
}));
const BillBreakLine = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.billBreak}>
                <div className={classes.billBreakLine} />
                <div
                    className={`${classes.billBreakCircle} ${classes.billBreakCircleRight}`}
                />
                <div
                    className={`${classes.billBreakCircle} ${classes.billBreakCircleLeft}`}
                />
            </div>
        </>
    );
};
export default BillBreakLine;
