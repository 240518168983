import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ClickAwayListener,
    Grid,
    Icon,
    makeStyles,
    Popper,
} from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import AlertController from "@helpers/controllers/AlertController";
import { connect } from "react-redux";
import { ALIAS_TYPE, BANK_TRANSFER_TYPE, BankDetails } from "@config/constants";
import Content from "@components/Content";
import LspCard from "@components/LspCard";
import { isEmpty } from "lodash";
import {
    CashIcon,
    EarthIcon,
    EWalletIcon,
    FastTransferIcon,
} from "@components/LspIcon";
import LspButton from "@components/LspButton";

import NicknameLine from "./NicknameLine";

const useStyles = makeStyles((theme) => ({
    eWalletInfo: {
        padding: theme.spacing(0.75, 2),
    },
    napasTransferInfo: {
        margin: `${theme.spacing(1.5)}px 0`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    napasTransferInfoIcon: {
        marginRight: theme.spacing(1),
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.purple.main,
    },
    napasTransferInfoText: {
        color: theme.palette.grey[500],
        textAlign: "left",
    },
    receiveEWallet: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    receiveEWalletText: {
        flex: 1,
        color: theme.palette.grey[600],
        textAlign: "left",
    },
    customLine: {
        padding: `${theme.spacing(0.75)}px 0 !important`,
    },
    questionTooltip: {
        cursor: "pointer",
        color: theme.palette.magicalGrey[400],
        fontSize: theme.typography.pxToRem(16),
    },
    questionTooltipContainer: {
        backgroundColor: theme.palette.black.main,
        color: theme.palette.neutral.white,
        maxWidth: 300,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        textAlign: "left",
        position: "relative",
    },
    questionTooltipAction: {
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        marginRight: theme.spacing(0.5),
        padding: theme.spacing(0.5),
        fontSize: theme.typography.pxToRem(16),
    },
}));

const BankInformationBox = ({
    type,
    classes,
    bankInfo,
    featureState,
    user,
    fetching,
}) => {
    const { t } = useTranslation();

    const classesLocal = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [phoneNumberAlias, setPhoneNumberAlias] = useState(null);
    const [nicknameAlias, setNicknameAlias] = useState(null);
    const open = Boolean(anchorEl);
    const mktHref = t("ekyc:sh_bk_must_read_link")
        ? t("ekyc:sh_bk_must_read_link")
        : "";

    const enableIBFTGW = useMemo(() => {
        return featureState?.enableIBFTGW;
    }, [featureState?.enableIBFTGW]);

    const isActivePhoneNumber = useMemo(() => {
        if (!isEmpty(bankInfo?.aliasList)) {
            const phoneNumber = bankInfo?.aliasList.find(
                (aliasItem) => aliasItem.aliasType === ALIAS_TYPE.PHONE_NUMBER
            );
            if (phoneNumber) {
                setPhoneNumberAlias(phoneNumber);
                return phoneNumber.status;
            }
            return false;
        }
    }, [bankInfo]);

    const isActiveNickname = useMemo(() => {
        if (!isEmpty(bankInfo?.aliasList)) {
            const nickname = bankInfo?.aliasList.find(
                (aliasItem) => aliasItem.aliasType === ALIAS_TYPE.ALIAS_NICKNAME
            );

            if (nickname) {
                setNicknameAlias(nickname);
                return nickname.status;
            }
            return false;
        }
    }, [bankInfo]);

    const copyHandler = (copyType) => {
        switch (copyType) {
            case BANK_TRANSFER_TYPE.FAST_TRANSFER:
                // translate
                const isEnableNickname = isActiveNickname
                    ? `${t("IBFT:IBFT_bankdetails_fast_nickname")}: ${
                          nicknameAlias?.alias
                      }\n`
                    : "";
                const isEnablePhoneNumber = isActivePhoneNumber
                    ? `${t("IBFT:IBFT_bankdetails_fast_acc_no")}: ${
                          phoneNumberAlias?.alias
                      } ${t("lb_or")} ${bankInfo?.accountNumber}\n`
                    : `${t("IBFT:IBFT_bankdetails_fast_acc_no")}: ${
                          bankInfo?.accountNumber
                      }\n`;
                const fastTransferContent = `${t(
                    "IBFT:IBFT_bankdetails_fast_title"
                )}\n\n${isEnableNickname}${isEnablePhoneNumber}${t(
                    "IBFT:IBFT_bankdetails_fast_acc_name"
                )}: ${bankInfo?.fullName}\n${t(
                    "IBFT:IBFT_bankdetails_fast_bank_name"
                )}: ${
                    bankInfo?.bankNameDomestic ||
                    `${bankInfo?.bankShortName} - ${bankInfo?.bankName}`
                }`;

                navigator.clipboard.writeText(fastTransferContent);
                break;

            case BANK_TRANSFER_TYPE.INTERNAL:
                // translate
                const transferType =
                    !featureState?.enableIBFTGW &&
                    !featureState?.enableAliasPhase2
                        ? t("IBFT:IBFT_bankdetails_domestic_title")
                        : t("IBFT:IBFT_bankdetails_citad_title");
                const internalContent = `${transferType}\n\n${t(
                    "IBFT:IBFT_bankdetails_citad_acc_no"
                )}: ${bankInfo?.accountNumber}\n${t(
                    "IBFT:IBFT_bankdetails_citad_acc_name"
                )}: ${bankInfo?.fullName}\n${t(
                    "IBFT:IBFT_bankdetails_citad_bank_name"
                )}: ${`${bankInfo?.bankShortName} - ${bankInfo?.bankName}`} \n${t(
                    "IBFT:IBFT_bankdetails_citad_branch"
                )}: ${bankInfo?.bankBranch}\n${t(
                    "IBFT:IBFT_bankdetails_citad_city"
                )}: ${bankInfo?.bankCity}`;

                navigator.clipboard.writeText(internalContent);
                break;
            case BANK_TRANSFER_TYPE.OVERSEA:
                const oversea = `${t(
                    "IBFT:IBFT_bankdetails_oversea_title"
                )}\n\n${t("IBFT:IBFT_bankdetails_oversea_acc_no")}: ${
                    bankInfo?.accountNumber
                }\n${t("IBFT:IBFT_bankdetails_oversea_acc_name")}: ${
                    bankInfo?.fullName
                }\n${t("IBFT:IBFT_bankdetails_oversea_bank_name")}: ${
                    bankInfo?.overseaBankName
                }\n${t("IBFT:IBFT_bankdetails_oversea_address")} ${
                    bankInfo?.bankAddress
                }\n${t("IBFT:IBFT_bankdetails_oversea_swift")}: ${
                    bankInfo?.swiftCode
                }\n\n${mktHref}`;
                navigator.clipboard.writeText(oversea);
                break;
            case BANK_TRANSFER_TYPE.EWALLET:
                // translate
                const eWallet = `${t(
                    "IBFT:IBFT_bankdetails_wallet_title"
                )}\n\n${t("IBFT:IBFT_bankdetails_wallet_acc_no")}: ${
                    bankInfo?.accountNumber
                }\n${t("IBFT:IBFT_bankdetails_wallet_acc_name")}: ${
                    bankInfo?.fullName
                }\n${t("IBFT:IBFT_bankdetails_wallet_bank_name")}: ${
                    bankInfo?.bankShortName
                } - ${bankInfo?.bankName}\n`;

                navigator.clipboard.writeText(eWallet);
                break;
            default:
                break;
        }

        AlertController.show(t("member:timostarclub_msg_copy_successful"));
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAnyWay = () => {
        setAnchorEl(null);
    };

    const handleViewDetail = (link) => {
        window.open(t(link), "_blank");
    };

    const handleCopyAccountNumber = () => {
        navigator.clipboard.writeText(
            isActivePhoneNumber
                ? `${phoneNumberAlias?.alias} ${t("lb_or")} ${
                      bankInfo?.accountNumber
                  }`
                : bankInfo?.accountNumber
        );
    };

    return (
        <>
            <Content>
                {type === BANK_TRANSFER_TYPE.FAST_TRANSFER && (
                    <LspCard
                        headerIcon={<FastTransferIcon vb={24} />}
                        enableCollapse
                        noBreakLine
                        defaultTitleColor
                        defaultOpenCollapse
                        title={t("IBFT:IBFT_bankdetails_fast_title")}
                    >
                        <div className={classes.boxInformationContainer}>
                            <div className={classes.boxInformationContent}>
                                {/* ---------Nickname---------- */}
                                <NicknameLine
                                    bankInfo={bankInfo}
                                    classes={classes}
                                />
                                {/* ---------account number---------- */}
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_acc_no")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    {isActivePhoneNumber && (
                                        <>
                                            <LspTypography
                                                variant="heading2"
                                                component="span"
                                            >
                                                {phoneNumberAlias?.alias}{" "}
                                                <LspTypography
                                                    variant="subheading3"
                                                    component="span"
                                                    color="grey"
                                                >
                                                    {t("lb_or")}
                                                </LspTypography>{" "}
                                                {bankInfo?.accountNumber}
                                            </LspTypography>
                                        </>
                                    )}
                                    {!isActivePhoneNumber && (
                                        <LspTypography
                                            variant="heading2"
                                            component="span"
                                        >
                                            {bankInfo?.accountNumber}
                                        </LspTypography>
                                    )}
                                    <LspButton
                                        size="small"
                                        variant="secondary"
                                        onClick={() => {
                                            handleCopyAccountNumber();
                                            AlertController.show(
                                                t(
                                                    "member:timostarclub_msg_copy_successful"
                                                )
                                            );
                                        }}
                                    >
                                        {t(
                                            "IBFT:IBFT_bankdetails_fast_btn_copy"
                                        )}
                                    </LspButton>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_acc_name")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.fullName}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_bank_name")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.bankNameDomestic}&nbsp;
                                        {enableIBFTGW && !user?.isNewUserIBFT && (
                                            <>
                                                <span
                                                    onClick={handleClick}
                                                    aria-describedby="questionTooltip"
                                                    className={
                                                        classesLocal.questionTooltip
                                                    }
                                                >
                                                    <span className="icon-help" />
                                                </span>

                                                <Popper
                                                    id="questionTooltip"
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                >
                                                    <ClickAwayListener
                                                        onClickAway={
                                                            handleClickAnyWay
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classesLocal.questionTooltipContainer
                                                            }
                                                        >
                                                            <div
                                                                onClick={
                                                                    handleClickAnyWay
                                                                }
                                                                className={
                                                                    classesLocal.questionTooltipAction
                                                                }
                                                            >
                                                                <i className="icon-close" />
                                                            </div>
                                                            <LspTypography
                                                                variant="body2"
                                                                color="white"
                                                                component="div"
                                                            >
                                                                {t(
                                                                    "IBFT:IBFT_bankdetails_notice"
                                                                ).replace(
                                                                    "%@",
                                                                    ""
                                                                )}
                                                            </LspTypography>
                                                            <LspTypography
                                                                variant="hyperlink1"
                                                                color="success"
                                                                component="a"
                                                                onClick={() =>
                                                                    handleViewDetail(
                                                                        t(
                                                                            "IBFT:IBFT_bankdetails_notice_hyperlink"
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {t(
                                                                    "IBFT:IBFT_bankdetails_notice_details"
                                                                )}
                                                            </LspTypography>
                                                        </div>
                                                    </ClickAwayListener>
                                                </Popper>
                                            </>
                                        )}
                                    </LspTypography>
                                </div>
                                <div className={classes.breakLine} />
                                <div className={classes.napasTransferInfo}>
                                    <div
                                        className={
                                            classes.napasTransferInfoIcon
                                        }
                                    >
                                        <span className="icon-info-v2" />
                                    </div>
                                    <LspTypography
                                        className={
                                            classes.napasTransferInfoText
                                        }
                                        variant="body3"
                                    >
                                        {t("IBFT:IBFT_bankdetails_fast_hint")}
                                    </LspTypography>
                                </div>
                            </div>
                            <div className={classes.boxInformationAction}>
                                <div
                                    className={classes.boxInformationActionIcon}
                                >
                                    <span className="icon-copy" />
                                </div>

                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                    component="a"
                                    onClick={() =>
                                        copyHandler(
                                            BANK_TRANSFER_TYPE.FAST_TRANSFER
                                        )
                                    }
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_btn_copy")}
                                </LspTypography>
                            </div>
                        </div>
                    </LspCard>
                )}
                {type === BANK_TRANSFER_TYPE.EWALLET && (
                    <LspCard
                        headerIcon={<EWalletIcon vb={24} />}
                        enableCollapse
                        noBreakLine
                        defaultTitleColor
                        title={t("IBFT:IBFT_bankdetails_wallet_title")}
                    >
                        <div className={classes.boxInformationContainer}>
                            <div className={classes.boxInformationContent}>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_wallet_acc_no")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.accountNumber}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_wallet_acc_name")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.fullName}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t(
                                        "IBFT:IBFT_bankdetails_wallet_bank_name"
                                    )}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.bankShortName} -{" "}
                                        {bankInfo?.bankName}
                                    </LspTypography>
                                </div>
                            </div>
                            <div className={classes.boxInformationAction}>
                                <div
                                    className={classes.boxInformationActionIcon}
                                >
                                    <span className="icon-copy" />
                                </div>

                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                    component="a"
                                    onClick={() =>
                                        copyHandler(BANK_TRANSFER_TYPE.EWALLET)
                                    }
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_btn_copy")}
                                </LspTypography>
                            </div>
                        </div>
                    </LspCard>
                )}
                {type === BANK_TRANSFER_TYPE.INTERNAL && (
                    <LspCard
                        headerIcon={<CashIcon vb={24} />}
                        enableCollapse
                        noBreakLine
                        defaultTitleColor
                        defaultOpenCollapse={!featureState?.enableIBFTGW}
                        title={
                            !featureState?.enableIBFTGW &&
                            !featureState?.enableAliasPhase2
                                ? t("IBFT:IBFT_bankdetails_domestic_title")
                                : t("IBFT:IBFT_bankdetails_citad_title")
                        }
                    >
                        <div className={classes.boxInformationContainer}>
                            <div className={classes.boxInformationContent}>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_citad_acc_no")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.accountNumber}
                                    </LspTypography>{" "}
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_citad_acc_name")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.fullName}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_citad_bank_name")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.bankShortName} -{" "}
                                        {bankInfo?.bankName}
                                    </LspTypography>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <LspTypography
                                            className={
                                                classes.boxInformationTitle
                                            }
                                            variant="body2"
                                        >
                                            {t(
                                                "IBFT:IBFT_bankdetails_citad_branch"
                                            )}
                                        </LspTypography>
                                        <div
                                            className={
                                                classes.boxInformationText
                                            }
                                        >
                                            <LspTypography
                                                variant="heading2"
                                                component="span"
                                            >
                                                {bankInfo?.bankBranch}
                                            </LspTypography>{" "}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LspTypography
                                            className={
                                                classes.boxInformationTitle
                                            }
                                            variant="body2"
                                        >
                                            {t(
                                                "IBFT:IBFT_bankdetails_citad_city"
                                            )}
                                        </LspTypography>
                                        <div
                                            className={
                                                classes.boxInformationText
                                            }
                                        >
                                            <LspTypography
                                                variant="heading2"
                                                component="span"
                                            >
                                                {bankInfo?.bankCity}
                                            </LspTypography>{" "}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={classes.boxInformationAction}>
                                <div
                                    className={classes.boxInformationActionIcon}
                                >
                                    <span className="icon-copy" />
                                </div>

                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                    component="a"
                                    onClick={() =>
                                        copyHandler(BANK_TRANSFER_TYPE.INTERNAL)
                                    }
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_btn_copy")}
                                </LspTypography>
                            </div>
                        </div>
                    </LspCard>
                )}
                {type === BANK_TRANSFER_TYPE.OVERSEA && (
                    <LspCard
                        headerIcon={<EarthIcon vb={24} />}
                        enableCollapse
                        noBreakLine
                        defaultTitleColor
                        title={t("IBFT:IBFT_bankdetails_oversea_title")}
                        defaultOpenCollapse={!featureState?.enableIBFTGW}
                    >
                        <div className={classes.boxInformationContainer}>
                            <div className={classes.boxInformationContent}>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_oversea_acc_no")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.accountNumber}
                                    </LspTypography>{" "}
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t(
                                        "IBFT:IBFT_bankdetails_oversea_acc_name"
                                    )}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.fullName}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t(
                                        "IBFT:IBFT_bankdetails_oversea_bank_name"
                                    )}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.overseaBankName}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_oversea_address")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.bankAddress}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.boxInformationTitle}
                                    variant="body2"
                                >
                                    {t("IBFT:IBFT_bankdetails_oversea_swift")}
                                </LspTypography>
                                <div className={classes.boxInformationText}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        {bankInfo?.swiftCode}
                                    </LspTypography>
                                </div>
                                <div className={classes.breakLine} />
                                <div className={classes.napasTransferInfo}>
                                    <div
                                        className={
                                            classes.napasTransferInfoIcon
                                        }
                                    >
                                        <span className="icon-info-v2" />
                                    </div>
                                    <LspTypography
                                        className={
                                            classes.napasTransferInfoText
                                        }
                                        variant="body3"
                                    >
                                        {t(
                                            "IBFT:IBFT_bankdetails_oversea_hint"
                                        ).replace("%@", "")}
                                        <LspTypography
                                            variant="hyperlink1"
                                            component="a"
                                            color="success"
                                            onClick={() =>
                                                handleViewDetail(
                                                    t(
                                                        "IBFT:IBFT_bankdetails_oversea_hint_hyperlink"
                                                    )
                                                )
                                            }
                                        >
                                            {t(
                                                "IBFT:IBFT_bankdetails_oversea_prefer"
                                            )}
                                        </LspTypography>
                                    </LspTypography>
                                </div>
                            </div>

                            <div className={classes.boxInformationAction}>
                                <div
                                    className={classes.boxInformationActionIcon}
                                >
                                    <span className="icon-copy" />
                                </div>

                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                    component="a"
                                    onClick={() =>
                                        copyHandler(BANK_TRANSFER_TYPE.OVERSEA)
                                    }
                                >
                                    {t("IBFT:IBFT_bankdetails_fast_btn_copy")}
                                </LspTypography>
                            </div>
                        </div>
                    </LspCard>
                )}

                {fetching && (
                    <>
                        <LspCard enableCollapse noBreakLine fetching />
                    </>
                )}
            </Content>
        </>
    );
};

const stateProps = (state) => ({
    featureState: state.user.featureState.data,
    user: state.user.info || {},
});

const dispatchProps = () => ({});

export default memo(connect(stateProps, dispatchProps)(BankInformationBox));
