import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import { Box, Paper, makeStyles } from "@material-ui/core";
import termDepositService from "@services/term-deposit";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LspCard from "@components/LspCard";
import useTermDeposit from "./useTermDeposit";

const useStyles = makeStyles((theme) => ({
    headerCell: {
        color: theme.palette.white.contrastText,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.background.header,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    bodyCell: {},
}));

const RedemptionPrediction = ({ amount, termDetail }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { monthToText } = useTermDeposit();
    const [prediction, setPrediction] = useState();

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getPrediction = useCallback(async () => {
        if (!amount || !termDetail?.termId) {
            return;
        }
        const response = await termDepositService.getRedemptionPrediction({
            amount,
            termId: termDetail.termId,
        });
        if (unmounted.current) {
            return;
        }
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setPrediction(data);
                break;
            default:
                break;
        }
    }, [amount, termDetail]);

    useEffect(() => {
        getPrediction();
    }, [getPrediction]);

    if (!prediction) {
        return <></>;
    }

    return (
        <LspCard
            title={
                <LspTypography variant="subheading3">
                    Chi tiết tài khoản tiết kiệm
                </LspTypography>
            }
            color="grey"
        >
            <Box pt={1} pb={1}>
                <Box flex={1} display="flex" flexDirection="row">
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("termDeposit_lb_term")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {monthToText(termDetail.month)}
                        </LspTypography>
                    </Box>
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("termDeposit_lb_rate")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {formatNumber(prediction.interestRate)}%
                        </LspTypography>
                    </Box>
                </Box>
                <Box flex={1} display="flex" flexDirection="row">
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("termDeposit_lb_deposit_date")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {prediction.depositDate}
                        </LspTypography>
                    </Box>
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("termDeposit_lb_redeem_date")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {prediction.redeemDate}
                        </LspTypography>
                    </Box>
                </Box>
                <Box flex={1} display="flex" flexDirection="row">
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("td_lb_deposit")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {formatNumber(amount)}
                        </LspTypography>
                    </Box>{" "}
                    <Box p={1} flex={1}>
                        <LspTypography variant="subheading4">
                            {t("td_lb_balance_redeem_date")}
                        </LspTypography>
                        <LspTypography variant="subheading2" color="primary">
                            {formatNumber(prediction.finalBalance)}
                        </LspTypography>
                    </Box>
                </Box>
            </Box>
        </LspCard>
    );
};

export default memo(RedemptionPrediction);
