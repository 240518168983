/* eslint-disable jsx-a11y/label-has-associated-control */
import useNumber from "@helpers/useNumber";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    line: {
        maxWidth: theme.typography.pxToRem(400),
        borderBottom: `1px solid ${theme.palette.magicalGrey[200]}`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        color: theme.palette.grey.A400,
        textAlign: "center",
        "& label": {
            color: theme.palette.primary.main,
        },
        "&:last-child": {
            borderBottom: "none",
        },
        margin: "auto",
    },
    amount: {
        fontSize: theme.typography.pxToRem(24),
    },
    wrapper: {
        width: "100%",
    },
}));

const PreviewTransfer = ({ info }) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const { t } = useTranslation();

    return (
        <Box component={Paper} p={3} className={classes.wrapper}>
            <div className={classes.line}>
                <label>{t("lb_amount_web")}</label>
                <div className={classes.amount}>
                    {formatNumber(info?.amount)}
                </div>
            </div>
            <div className={classes.line}>
                <label>{t("lb_to")}</label>
                <div>{info?.to}</div>
            </div>
            <div className={classes.line}>
                <label>{t("payee_card_description_label")}</label>
                <div>{info?.description}</div>
            </div>
        </Box>
    );
};

export default PreviewTransfer;
