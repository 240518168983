import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    item: {
        height: 92,
        background: theme.palette.background.default,
        borderRadius: theme.shape.radiusLevels[1],
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
        boxShadow: theme.shadows[1],
    },
}));

const WealthProductListLoading = () => {
    const classes = useStyles();

    return (
        <div>
            {[...new Array(5)]?.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classes.item} key={index}>
                    <Skeleton width={157} />
                    <Skeleton width={305} />
                </div>
            ))}
        </div>
    );
};

export default memo(WealthProductListLoading);
