import { CircularProgress, makeStyles } from "@material-ui/core";
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";

import { RESPONSE_CODE } from "@config/constants";

import tommyNoHope from "@assets/images/no_hope_with_status.png";
import LspButton from "@components/LspButton";
import lspReceiptService from "@services/lsp-receipt";
import { CloseCircleIcon } from "@components/LspIcon";
import clsx from "clsx";
import contentService from "@services/content";
import axios from "axios";
import LspTypography from "../LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            padding: theme.spacing(4),
            position: "relative",
        },
        content: {
            marginBottom: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
        },
        action: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        actionItem: {
            height: 28,
            marginRight: theme.spacing(9),
            cursor: "pointer",
            display: "flex",
            color: theme.palette.neutral.white,
            alignItems: "center",

            "&:last-child": {
                marginRight: 0,
            },
        },
        containerDelete: {
            display: "flex",
            justifyContent: "center",
        },
        contentDelete: {
            maxWidth: 320,
            backgroundColor: theme.palette.neutral.white,
            borderRadius: theme.shape.borderRadius,
            textAlign: "center",
            padding: theme.spacing(4, 2, 2, 2),
        },
        deleteImg: {
            marginBottom: theme.spacing(3),
        },
        title: {
            marginBottom: theme.spacing(1),
        },
        desc: {
            marginBottom: theme.spacing(4),
        },
        imgPreview: {
            maxHeight: 500,
            "& img": {
                width: "100%",
                height: "100%",
                borderRadius: theme.shape.borderRadius,
                objectFit: "contain",
            },
        },
        closeAction: {
            color: theme.palette.neutral.white,
            cursor: "pointer",
            position: "absolute",
            right: 0,
            top: 0,
        },
    };
});

const VIEW_MODE = {
    VIEW: 0,
    DELETE: 1,
    CONFIRM: 2,
};

const LspViewAttachment = ({
    url,
    onClose,
    fileType,
    onDelete,
    fileManageId,
    refNo,
    onChangeAttachment,
    extension,
    urlDownLoad,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [viewMode, setViewMode] = useState(VIEW_MODE.VIEW);

    const handleOnClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const handleShowAttachment = () => {
        switch (fileType) {
            case "application/pdf":
                return (
                    <div className={classes.previewPdf}>
                        <embed
                            title="test"
                            src={url}
                            width="100%"
                            height={600}
                            type="application/pdf"
                            allowFullScreen
                        />
                        <div className={classes.previewPdfOverflow} />
                    </div>
                );

            default:
                return (
                    <div className={classes.imgPreview}>
                        <img src={url} alt="Img Preview" />
                    </div>
                );
        }
    };

    const handleDeleteAttachment = () => {
        setViewMode(VIEW_MODE.DELETE);
    };

    const handleChangeAttachment = () => {
        if (isFunction(onChangeAttachment)) {
            onChangeAttachment({
                fileManageId: `${fileManageId}`,
                refNo,
            });
        }
    };

    const handleConfirmDelete = async () => {
        if (isFunction(onDelete)) {
            setLoading(true);
            const response = await lspReceiptService.deleteAttachment({
                fileManageId: `${fileManageId}`,
                refNo,
            });

            setLoading(false);
            const { code } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    onDelete({ fileManageId });
                    break;

                default:
                    break;
            }
        }
    };

    const handleCancelDelete = () => {
        setViewMode(VIEW_MODE.VIEW);
    };

    return (
        <>
            {viewMode === VIEW_MODE.VIEW && (
                <div className={classes.container}>
                    <div
                        className={classes.closeAction}
                        onClick={() => handleOnClose()}
                    >
                        <CloseCircleIcon vb={16} />
                    </div>
                    <div className={classes.content}>
                        {handleShowAttachment()}
                    </div>

                    <div className={classes.action}>
                        <div className={classes.actionItem}>
                            <LspTypography
                                variant="hyperlink1"
                                color="white"
                                onClick={() => handleDeleteAttachment()}
                                component="div"
                                disabled={loading}
                            >
                                {t(
                                    "txnapptype:receipt_attach_file_view_delete"
                                )}
                            </LspTypography>
                            {loading && (
                                <CircularProgress
                                    size={18}
                                    color="white"
                                    style={{ marginLeft: 8 }}
                                />
                            )}
                        </div>
                        <LspTypography
                            className={classes.actionItem}
                            color="white"
                            variant="hyperlink1"
                            href={urlDownLoad}
                            component="a"
                            download
                        >
                            {t("txnapptype:receipt_attach_file_view_download")}
                        </LspTypography>

                        <LspTypography
                            className={classes.actionItem}
                            color="white"
                            variant="hyperlink1"
                            onClick={() => handleChangeAttachment()}
                        >
                            {t("txnapptype:receipt_attach_file_view_change")}
                        </LspTypography>
                    </div>
                </div>
            )}
            {viewMode === VIEW_MODE.DELETE && (
                <>
                    <div className={classes.containerDelete}>
                        <div className={classes.contentDelete}>
                            <div className={classes.deleteImg}>
                                <img src={tommyNoHope} alt="Tomi" />
                            </div>

                            <LspTypography
                                variant="heading1"
                                className={classes.title}
                            >
                                {t(
                                    "txnapptype:receipt_attach_file_delete_title"
                                )}
                            </LspTypography>
                            <LspTypography
                                variant="body2"
                                color="grey"
                                className={classes.desc}
                            >
                                {t(
                                    "txnapptype:receipt_attach_file_delete_desc"
                                )}
                            </LspTypography>
                            <div className={classes.action}>
                                <LspButton
                                    fullWidth
                                    variant="secondary"
                                    mr={1}
                                    onClick={() => handleCancelDelete()}
                                >
                                    {t(
                                        "txnapptype:receipt_attach_file_delete_btn_cancel"
                                    )}
                                </LspButton>
                                <LspButton
                                    fullWidth
                                    onClick={() => handleConfirmDelete()}
                                    progressing={loading}
                                >
                                    {t(
                                        "txnapptype:receipt_attach_file_delete_btn_confirm"
                                    )}
                                </LspButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default memo(LspViewAttachment);
