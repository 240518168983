/* eslint-disable react/no-array-index-key */
import { Box, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import clsx from "clsx";
import { Doughnut } from "react-chartjs-2";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    currentBox: {
        padding: theme.spacing(4),
        "& > div": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: theme.spacing(2),
            " & > p > span": {
                display: "block",
            },
        },
    },
    statusLabel: {
        background: theme.palette.blue.main,
        textAlign: "center",
        padding: theme.spacing(1, 0),
        borderRadius: theme.spacing(1, 1, 0, 0),
        "&.disabled": {
            backgroundColor: theme.palette.error.main,
        },
    },
    warningTxt: {
        padding: theme.spacing(2),
        textAlign: "center",
    },
    line: {
        height: "1px",
        background: theme.palette.grey[200],
        width: "92%",
        margin: "0 auto",
    },
    chart: {
        maxWidth: 300,
    },
    chartTxt: {
        marginLeft: theme.spacing(6),
        flex: 1,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
        },
        " & > div": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(1, 0),
            "&:nth-child(1)": {
                "& $chartPercent": {
                    color: theme.palette.purple.main,
                },
            },
            "&:nth-child(2)": {
                "& $chartPercent": {
                    color: theme.palette.blue.main,
                },
            },
            "&:nth-child(3)": {
                "& $chartPercent": {
                    color: theme.palette.orange.main,
                },
            },
            "&:nth-child(4)": {
                "& $chartPercent": {
                    color: theme.palette.gold.main,
                },
            },
        },
    },

    chartBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: theme.spacing(2.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },

    chartPercent: {},
}));

const ChartDetail = ({ currentData }) => {
    const classes = useStyles();
    const { t } = useTranslation("creditCard");
    const { formatNumber } = useNumber();
    const theme = useTheme();

    return (
        <>
            {currentData?.chartData?.length > 0 && (
                <Paper component={Box} p={4} mt={2} mb={2}>
                    <LspTypography color="primary" variant="subheading1">
                        {t("creditCard:stm_expenses_title")}
                    </LspTypography>
                    <div className={classes.chartBox}>
                        <div className={classes.chart}>
                            <Doughnut
                                data={{
                                    labels: currentData?.chartLabels,
                                    datasets: [
                                        {
                                            backgroundColor: [
                                                theme.palette.purple.main,
                                                theme.palette.blue.main,
                                                theme.palette.orange.main,
                                                theme.palette.gold.main,
                                            ],
                                            data: currentData?.chartData,
                                        },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        legend: false,
                                    },
                                }}
                            />
                        </div>

                        <div className={classes.chartTxt}>
                            {currentData?.chartData?.map((value, key) => (
                                <div key={key}>
                                    <LspTypography color="grey" variant="body2">
                                        {currentData?.chartLabels[key]}
                                    </LspTypography>
                                    <LspTypography
                                        color="grey"
                                        variant="title2"
                                        className={classes.chartPercent}
                                    >
                                        {`${value}%`}
                                    </LspTypography>
                                </div>
                            ))}
                        </div>
                    </div>
                </Paper>
            )}

            <Box mt={3} mb={2}>
                {currentData?.statusLabel && (
                    <LspTypography
                        color="white"
                        variant="subheading2"
                        className={clsx(classes.statusLabel, {
                            disabled: currentData?.status === "OVERDUE",
                        })}
                    >
                        {currentData?.statusLabel}
                    </LspTypography>
                )}

                <Paper>
                    {currentData?.status === "OVERDUE" && (
                        <>
                            <LspTypography
                                color="error"
                                variant="body1"
                                className={classes.warningTxt}
                            >
                                {t("creditCard:stm_overdue_note")}
                            </LspTypography>

                            <div className={classes.line} />
                        </>
                    )}
                    <Box className={classes.currentBox}>
                        <LspTypography color="primary" variant="subheading1">
                            {t("creditCard:stm_sum_title")}
                        </LspTypography>
                        <div>
                            <LspTypography variant="subheading2" color="black">
                                {t("creditCard:stm_opening_balance")}
                            </LspTypography>

                            <LspTypography variant="subheading1" color="black">
                                {formatNumber(currentData?.openingBalance)}
                            </LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="subheading2" color="black">
                                {t("creditCard:stm_txn")}
                            </LspTypography>

                            <LspTypography variant="subheading1" color="black">
                                {formatNumber(currentData?.transactions)}
                            </LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="subheading2" color="black">
                                {t("creditCard:stm_repayment")}
                            </LspTypography>

                            <LspTypography variant="subheading1" color="black">
                                {formatNumber(currentData?.repayment)}
                            </LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="subheading2" color="black">
                                {t("creditCard:stm_closing_balance")}
                                <LspTypography
                                    variant="body3"
                                    color="grey"
                                    component="span"
                                >
                                    {currentData?.closeDateLabel}
                                </LspTypography>
                            </LspTypography>

                            <LspTypography variant="subheading1" color="black">
                                {formatNumber(currentData?.closingBalance)}
                            </LspTypography>
                        </div>
                    </Box>
                </Paper>
            </Box>
        </>
    );
};

export default ChartDetail;
