/* eslint-disable react/no-array-index-key */
import { VINA_WEALTH_HISTORY_TRANSACTION_TYPES } from "@containers/Wealth/wealthConstants";
import { memo } from "react";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import { Icon, Paper } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import VinaWealthTransactionBox from "./VinaWealthTransactionBox";
import VinaWealthPendingTransaction from "./VinaWealthPendingTransaction";
import useSharedClasses from "./vinaWealthSharedClasses";
import VinaWealthCompleteTransaction from "./VinaWealthCompleteTransaction";

const VinaWealthTransaction = ({
    products,
    type,
    onMoreTransaction,
    onBack,
    isEmptyTransaction,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();

    return (
        <>
            {type === VINA_WEALTH_HISTORY_TRANSACTION_TYPES.ACCOUNT_INFO && (
                <VinaWealthTransactionBox
                    products={products}
                    onMoreTransaction={onMoreTransaction}
                />
            )}

            {type ===
                VINA_WEALTH_HISTORY_TRANSACTION_TYPES.FULL_TRANSACTION_LIST && (
                <>
                    <>
                        <PageHeaderBack onBack={onBack} />
                        <Paper
                            className={sharedClasses.transactionBox}
                            style={{ marginBottom: 16 }}
                        >
                            <div className={sharedClasses.headerLine}>
                                <div className={sharedClasses.title}>
                                    <Icon
                                        className={`font-icon icon-vctransaction ${sharedClasses.icon}`}
                                    />
                                    <LspTypography
                                        variant="heading3"
                                        color="primary"
                                    >
                                        {t("wealth:vc3_manage_tab2")}
                                    </LspTypography>
                                </div>
                            </div>
                            <div className={sharedClasses.body}>
                                {!isEmptyTransaction && (
                                    <>
                                        <VinaWealthPendingTransaction />
                                        <VinaWealthCompleteTransaction />
                                    </>
                                )}
                                {isEmptyTransaction && (
                                    <LspTypography variant="body2">
                                        {t("wealth:vc3_transaction_empty")}
                                    </LspTypography>
                                )}
                            </div>
                        </Paper>
                    </>
                </>
            )}
        </>
    );
};

const mapState = (state) => ({
    isForeigner: state.user.info?.isForeigner,
    isEmptyTransaction: state.vinaWealth.isEmptyTransaction,
});

export default memo(connect(mapState)(VinaWealthTransaction));
