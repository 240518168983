import { Skeleton } from "@material-ui/lab";

const PayeeLoading = ({ className }) => {
    return (
        <>
            <div className={className.payee}>
                <div className={className.avatarSkeleton}>
                    <Skeleton variant="circle" width={48} height={48} />
                </div>
                <div className={className.detail}>
                    <Skeleton variant="text" width={150} />
                    <div className={className.date}>
                        <Skeleton variant="text" width={200} />
                    </div>
                </div>
            </div>
            <div className={className.payee}>
                <div className={className.avatarSkeleton}>
                    <Skeleton variant="circle" width={48} height={48} />
                </div>
                <div className={className.detail}>
                    <Skeleton variant="text" width={150} />
                    <div className={className.date}>
                        <Skeleton variant="text" width={200} />
                    </div>
                </div>
            </div>
            <div className={className.payee}>
                <div className={className.avatarSkeleton}>
                    <Skeleton variant="circle" width={48} height={48} />
                </div>
                <div className={className.detail}>
                    <Skeleton variant="text" width={150} />
                    <div className={className.date}>
                        <Skeleton variant="text" width={200} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayeeLoading;
