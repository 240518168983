/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer } from "redux-persist";
import rootSaga from "@redux/sagas";

import authReducer, { authInitialState } from "@redux/reducers/auth";
import otpDialogReducer from "@redux/reducers/otp-dialog";
import systemParamsReducer from "@redux/reducers/system-params";
import accountReducer from "@redux/reducers/account";
import layoutReducer from "@redux/reducers/layout";
import userReducer from "@redux/reducers/user";
import globalDialogReducer from "@redux/reducers/global-dialog";
import quickCodeReducer, {
    quickCodeInitialize,
} from "@redux/reducers/quick-code";
import moveMoneyReducer from "@redux/reducers/move-money";
import cardManagementReducer from "@redux/reducers/card-management";
import lspReceiptReducer from "@redux/reducers/lsp-receipt";
import lixiReducer from "@redux/reducers/lixi";
import topUpReducer from "@redux/reducers/top-up";
import appReducer from "@redux/reducers/app";
import billReducer from "@redux/reducers/bill";
import notificationReducer from "@redux/reducers/notification";
import wealthReducer from "@redux/reducers/wealth";
import forgotPasswordReducer from "@redux/reducers/forgot-password";
import paymentRequestReducer from "@redux/reducers/payment-request";
import vinaWealthReducer from "@redux/reducers/vina-wealth";
import wealthCreditCardReducer from "@redux/reducers/wealth-credit-card";
import bookingReducer from "@redux/reducers/booking";
import wealthInsuranceReducer from "@redux/reducers/wealth-insurance";
import requestDocumentReducer from "@redux/reducers/request-document";
import metricReducer, { metricInitialState } from "@redux/reducers/metric";
import ternReducer from "@redux/reducers/term-deposit";
import bankReducer from "@redux/reducers/bank";
import overdraftReducer from "@redux/reducers/overdraft";
import {
    appPersistConfig,
    authPersistConfig,
    metricsConfig,
    quickCodePersistConfig,
    notificationPersistConfig,
} from "./redux-persist";
import { AppActionType } from "./constants";

/* ------------- Redux Configuration ------------- */
const configureStore = (rootReducer, saga) => {
    const middleware = [];
    const enhancers = [];

    /* ------------- Saga Middleware ------------- */
    const sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);

    /* ------------- Assemble Middleware ------------- */
    enhancers.push(applyMiddleware(...middleware));
    const composeEnhancers =
        (typeof window !== "undefined" &&
            !window.isProduction &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    const store = createStore(rootReducer, composeEnhancers(...enhancers));

    /* ------------- Kick Off Root Saga ------------- */
    const sagasManager = sagaMiddleware.run(saga);

    return {
        store,
        sagasManager,
        sagaMiddleware,
    };
};

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    quickCodeInfo: persistReducer(quickCodePersistConfig, quickCodeReducer),
    metrics: persistReducer(metricsConfig, metricReducer),
    otpDialog: otpDialogReducer,
    systemParams: systemParamsReducer,
    account: accountReducer,
    layout: layoutReducer,
    user: userReducer,
    notification: persistReducer(
        notificationPersistConfig,
        notificationReducer
    ),
    globalDialog: globalDialogReducer,
    moveMoney: moveMoneyReducer,
    cardInfo: cardManagementReducer,
    receipt: lspReceiptReducer,
    lixi: lixiReducer,
    topUp: topUpReducer,
    bill: billReducer,
    wealth: wealthReducer,
    forgotPassword: forgotPasswordReducer,
    paymentRequest: paymentRequestReducer,
    vinaWealth: vinaWealthReducer,
    wealthCreditCard: wealthCreditCardReducer,
    booking: bookingReducer,
    insurance: wealthInsuranceReducer,
    requestDocument: requestDocumentReducer,
    termDeposit: ternReducer,
    bank: bankReducer,
    overdraft: overdraftReducer,
});

const parentReducer = (state, action) => {
    if (action.type === AppActionType.Stop) {
        // reset every reducer except listed ones after app stopped
        const { app, auth, globalDialog, quickCodeInfo, metrics } = state;
        state = {
            app: {
                _persist: app._persist, // keep this value to work with redux persist
            },
            auth: {
                ...authInitialState,
                _persist: auth._persist, // keep this value to work with redux persist
            },
            quickCodeInfo: {
                ...quickCodeInitialize,
                _persist: quickCodeInfo._persist, // keep this value to work with redux persist
            },
            metrics: {
                ...metricInitialState,
                _persist: metrics._persist,
            },
            globalDialog,
        };
    }

    return reducers(state, action);
};

const reduxStore = () => {
    const { store } = configureStore(parentReducer, rootSaga);
    return store;
};

export default reduxStore;
