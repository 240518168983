import { RESPONSE_CODE } from "@config/constants";
import metricActions from "@redux/actions/metric";
import metricService from "@services/metrics";
import { call, put } from "redux-saga/effects";

export function* initMetricSaga() {
    const response = yield call(metricService.initMetrics);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(metricActions.initMetricSuccess(data));
            break;
        default:
            yield put(metricActions.initMetricError());
            break;
    }
}

export function* metricRequestSaga(action) {
    const { payload } = action;
    const response = yield call(metricService.joinMetric, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(metricActions.metricRequestSuccess(data));
            break;
        default:
            yield put(metricActions.metricRequestError());
            break;
    }
}
