/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { Hidden, Icon, makeStyles, MenuItem } from "@material-ui/core";
import { useMemo, useCallback, useState, memo } from "react";

import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import LspButton from "@components/LspButton";
import { isFunction } from "lodash";
import useWealth from "../useWealth";
import {
    WEALTH_PRODUCT_KEYS,
    WEALTH_LOANS_PURPOSE_LIST,
} from "../wealthConstants";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
        marginBottom: theme.spacing(4),
    },
    form: {
        marginTop: theme.spacing(3),
        width: "100%",
    },
    formLine: {
        display: "flex",
    },
    inputIcon: {
        margin: theme.spacing(2, 2, 1, 1),
        color: theme.palette.primary.main,
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    dropdown: {
        width: "100%",
    },
    button: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(6),
        },
    },
}));

const MAX_AMOUNT_LOANS = 9999999999;
const WEALTH_LOANS_PERIOD = {
    MIN: 1,
    MAX: 360,
};

const WealthLoansForm = ({ formInfo, onSubmit, loading }) => {
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const { t } = useTranslation();
    const classes = useStyles();
    const { getWealthIcon } = useWealth();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const icon = getWealthIcon(WEALTH_PRODUCT_KEYS.LOANS);
    const [errors, setErrors] = useState({
        purpose: null,
        amount: null,
        period: null,
    });
    const [purpose, setPurpose] = useState(formInfo?.purpose || "");
    const [amount, setAmount] = useState(formInfo?.amount || "");
    const [period, setPeriod] = useState(formInfo?.period || "");

    const hintAmount = useMemo(() => {
        if (!purpose) return "";

        const purposeInfo = WEALTH_LOANS_PURPOSE_LIST?.filter(
            (item) => item.id === purpose
        );

        return t("loan_vpbank_amount_msg").replace(
            "%@",
            formatNumber(purposeInfo[0]?.minAmount || "")
        );
    }, [t, purpose, formatNumber]);

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value: +value });
        },
        [errors.amount]
    );

    const onChangePurpose = (e) => {
        const { value } = e.target;
        setPurpose(e.target.value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                purpose: t("msg_we_need_this"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                purpose: null,
            }));
        }
    };

    const onChangePeriod = (e) => {
        const { value } = e.target;

        setPeriod(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.NUMBER,
                text: value,
            })
        );
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                period: t("msg_we_need_this"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                period: null,
            }));
        }
    };

    const amountValidation = (amountIn, purposeInfo) => {
        if (!amountIn) {
            setErrors((prev) => ({
                ...prev,
                amount: t("msg_we_need_this"),
            }));
            return false;
        }
        if (amountIn < purposeInfo[0]?.minAmount) {
            setErrors((prev) => ({
                ...prev,
                amount: t("loan_vpbank_amount_required_min").replace(
                    "%@",
                    formatNumber(purposeInfo[0]?.minAmount)
                ),
            }));
            return false;
        }
        if (amountIn > MAX_AMOUNT_LOANS) {
            setErrors((prev) => ({
                ...prev,
                amount: t("loan_msg_maximum_loan_amount").replace(
                    "%@",
                    formatNumber(MAX_AMOUNT_LOANS)
                ),
            }));
            return false;
        }
        return true;
    };

    const periodValidation = (periodIn) => {
        if (!periodIn) {
            setErrors((prev) => ({
                ...prev,
                period: t("msg_we_need_this"),
            }));
            return false;
        }
        if (
            periodIn < WEALTH_LOANS_PERIOD.MIN ||
            periodIn > WEALTH_LOANS_PERIOD.MAX
        ) {
            setErrors((prev) => ({
                ...prev,
                period: t("loan_vpbank_period_required"),
            }));
            return false;
        }
        return true;
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (!purpose) {
            setErrors((prev) => ({
                ...prev,
                purpose: t("msg_we_need_this"),
            }));
        }

        const purposeInfo = WEALTH_LOANS_PURPOSE_LIST?.filter(
            (item) => item.id === purpose
        );

        const amountValid = amountValidation(amount?.value, purposeInfo);
        const periodValid = periodValidation(period);

        if (!purpose || !amountValid || !periodValid) return;

        if (onSubmit && isFunction(onSubmit)) {
            onSubmit({
                purpose: purposeInfo[0],
                amount,
                period,
            });
        }
    };

    return (
        <Content size="xs">
            <div className={classes.formWrapper}>
                <Icon className={`font-icon icon-${icon} ${classes.icon}`} />
                <LspTypography variant="heading1" color="primary">
                    {t("wealth:VL00004")}
                </LspTypography>
                <form className={classes.form} onSubmit={onSubmitHandler}>
                    <div className={classes.formLine}>
                        <Icon
                            className={`font-icon icon-questionmark ${classes.inputIcon}`}
                        />
                        <LspTextField
                            autoFocus
                            select
                            value={purpose}
                            label={t("wealth:VL00005")}
                            onChange={onChangePurpose}
                            className={classes.dropdown}
                            error={!!errors?.purpose}
                            helperText={errors?.purpose || ""}
                        >
                            {WEALTH_LOANS_PURPOSE_LIST?.map((item) => {
                                return (
                                    <MenuItem
                                        value={item?.id}
                                        key={`purpose-${item?.id}`}
                                    >
                                        {t(item?.name)}
                                    </MenuItem>
                                );
                            })}
                        </LspTextField>
                    </div>
                    <div className={classes.formLine}>
                        <Icon
                            className={`font-icon icon-money ${classes.inputIcon}`}
                        />
                        <NumberFormat
                            className={classes.input}
                            value={amount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onAmountChange}
                            customInput={LspTextField}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            label={t("wealth:VL00006")}
                            error={!!errors.amount}
                            helperText={errors.amount || hintAmount || ""}
                        />
                    </div>
                    <div className={classes.formLine}>
                        <Icon
                            className={`font-icon icon-calendar ${classes.inputIcon}`}
                        />
                        <LspTextField
                            error={!!errors?.period}
                            name="period"
                            value={period}
                            label={t("wealth:VL00007")}
                            helperText={errors?.period || ""}
                            inputProps={{
                                maxLength: 5,
                            }}
                            onChange={onChangePeriod}
                        />
                    </div>
                    <div className={classes.button}>
                        {/* For normal devices: show applyBtn > click apply > submit form */}
                        <Hidden smDown>
                            <LspButton
                                fullWidth
                                type="submit"
                                progressing={loading}
                            >
                                {t("loan_btn_apply")}
                            </LspButton>
                        </Hidden>
                        {/* For small devices: show nextBtn > click next > loans document > click apply > submit form */}
                        <Hidden mdUp>
                            <LspButton fullWidth type="submit">
                                {t("lb_next")}
                            </LspButton>
                        </Hidden>
                    </div>
                </form>
            </div>
        </Content>
    );
};

export default memo(WealthLoansForm);
