import { FetchStatus } from "@config/constants";
import { BANK_ACTIONS } from "@redux/actions/bank";
import produce from "immer";
/* ------------- Initial State ------------- */
const initialState = {
    bankList: {
        status: null,
        fetching: false,
        data: [],
    },
    provinceList: {
        status: null,
        fetching: false,
        data: [],
    },
    branchList: {
        status: null,
        fetching: false,
        data: [],
    },
};

/* ------------- Reducer ------------- */
const bankReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case BANK_ACTIONS.GetBankList:
                draft.bankList.fetching = true;
                draft.bankList.status = FetchStatus.Pending;
                break;
            case BANK_ACTIONS.GetBankListSuccess:
                draft.bankList.status = FetchStatus.Success;
                draft.bankList.fetching = false;
                draft.bankList.data = action.payload;
                break;
            case BANK_ACTIONS.GetBankListErrors:
                draft.bankList.status = FetchStatus.Error;
                draft.bankList.fetching = false;
                break;

            case BANK_ACTIONS.GetProvinceList:
                draft.provinceList.fetching = true;
                draft.provinceList.status = FetchStatus.Pending;
                break;
            case BANK_ACTIONS.GetProvinceListSuccess:
                draft.provinceList.fetching = false;
                draft.provinceList.status = FetchStatus.Success;
                draft.provinceList.data = action.payload;
                break;
            case BANK_ACTIONS.GetProvinceListErrors:
                draft.provinceList.fetching = false;
                draft.provinceList.status = FetchStatus.Error;
                break;

            case BANK_ACTIONS.GetBranchList:
                draft.branchList.fetching = true;
                draft.branchList.status = FetchStatus.Pending;
                break;
            case BANK_ACTIONS.GetBranchListSuccess:
                draft.branchList.fetching = false;
                draft.branchList.status = FetchStatus.Success;
                draft.branchList.data = action.payload;
                break;
            case BANK_ACTIONS.GetBranchListErrors:
                draft.branchList.fetching = false;
                draft.branchList.status = FetchStatus.Error;
                break;

            default:
                break;
        }
    });

export default bankReducer;
