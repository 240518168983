import Banner from "@components/Banner";
import Content from "@components/Content";
import ContentWrapper from "@components/ContentWrapper";
import { Box, makeStyles, Paper } from "@material-ui/core";
import notificationAction from "@redux/actions/notification";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useNotifyNavigation from "@helpers/useNotifyNavigation";
import groupNotificationByDate from "@helpers/groupNotificationByDate";
import notificationService from "@services/notification";
import NotificationListGroup from "./NotificationListGroup";
import NotificationListFetching from "./NotificationListFetching";
import NotificationListActions from "./NotificationListActions";
import { NOTIFICATION_CHECKBOX_TYPES } from "./constant";

const useStyles = makeStyles((theme) => ({
    checkBox: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

const NotificationCenter = ({
    getNotificationList,
    notificationListFetching,
    notificationList,
    notificationListHasMore,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { direction } = useNotifyNavigation();
    const [selectedAll, setSelectedAll] = useState(
        NOTIFICATION_CHECKBOX_TYPES.NO
    );

    const [notifyListVirtual, setNotifyListVirtual] = useState([]);
    const unmounted = useRef(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        getNotificationList();
    }, [getNotificationList]);

    const notifyList = useMemo(() => {
        if (unmounted.current) {
            return;
        }
        setDeleting(false);
        return groupNotificationByDate(notificationList);
    }, [notificationList]);

    useEffect(() => {
        if (unmounted.current || !notifyList || notifyList?.length === 0) {
            return;
        }
        if (firstLoad) {
            // Load notify list in first time --> set default false for selected checkbox
            const mapList = notifyList?.map((group) => {
                const list = group.list.map((item) => ({
                    id: item.iD,
                    checked: false,
                }));

                return {
                    id: group.id,
                    list,
                    checkedType: NOTIFICATION_CHECKBOX_TYPES.NO,
                };
            });
            setNotifyListVirtual(mapList);
        } else {
            // Load more --> get list again --> need map selected Item checkbox to new list
            setNotifyListVirtual((prev) => {
                const currentGroupLength = prev?.length;
                // eslint-disable-next-line prefer-const
                let finalList = [];
                for (let i = 0; i < notifyList.length; i++) {
                    const group = notifyList[i];
                    if (i <= currentGroupLength - 1) {
                        // copy value
                        finalList.push(prev[i]);
                    } else {
                        // add new item
                        const list = group.list.map((item) => ({
                            id: item.iD,
                            checked: false,
                        }));
                        finalList.push({
                            id: group.id,
                            list,
                            checkedType: NOTIFICATION_CHECKBOX_TYPES.NO,
                        });
                    }
                }
                return finalList;
            });
        }
    }, [notifyList, firstLoad]);

    const refreshNotificationList = () => {
        setFirstLoad(true);
        setNotifyListVirtual([]);
        getNotificationList();
    };

    const loadMore = useCallback(() => {
        if (notificationListFetching) {
            return;
        }
        setFirstLoad(false);
        getNotificationList({ loadMore: true });
    }, [notificationListFetching, getNotificationList]);

    const infiniteRef = useInfiniteScroll({
        loading: notificationListFetching,
        hasNextPage: notificationListHasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const updateNotifyListVirtual = (group) => {
        setNotifyListVirtual((prev) => {
            const index = prev.findIndex((item) => item.id === group.id);
            const selectedItem = group.list.filter((item) => item.checked);
            let checkedType = NOTIFICATION_CHECKBOX_TYPES.APART;

            if (selectedItem.length === 0) {
                checkedType = NOTIFICATION_CHECKBOX_TYPES.NO;
            } else if (selectedItem.length === group.list.length) {
                checkedType = NOTIFICATION_CHECKBOX_TYPES.ALL;
            }

            prev[index] = { ...group, checkedType };
            const finalList = [...prev];
            const selectedTypeAll = finalList.filter(
                (item) => item.checkedType === NOTIFICATION_CHECKBOX_TYPES.ALL
            );
            const selectedTypeNo = finalList.filter(
                (item) => item.checkedType === NOTIFICATION_CHECKBOX_TYPES.NO
            );

            if (selectedTypeAll?.length === finalList?.length) {
                setSelectedAll(NOTIFICATION_CHECKBOX_TYPES.ALL);
            } else if (selectedTypeNo?.length === finalList?.length) {
                setSelectedAll(NOTIFICATION_CHECKBOX_TYPES.NO);
            } else {
                setSelectedAll(NOTIFICATION_CHECKBOX_TYPES.APART);
            }

            return finalList;
        });
    };

    const toggleAll = useCallback(() => {
        let checked = NOTIFICATION_CHECKBOX_TYPES.NO;
        setSelectedAll((prev) => {
            checked = prev;
            if (prev === NOTIFICATION_CHECKBOX_TYPES.NO) {
                return NOTIFICATION_CHECKBOX_TYPES.ALL;
            }

            return NOTIFICATION_CHECKBOX_TYPES.NO;
        });

        const groupCheckedType =
            checked === NOTIFICATION_CHECKBOX_TYPES.NO
                ? NOTIFICATION_CHECKBOX_TYPES.ALL
                : NOTIFICATION_CHECKBOX_TYPES.NO;

        const checkedItem = groupCheckedType !== NOTIFICATION_CHECKBOX_TYPES.NO;

        const finalList = notifyListVirtual?.map((group) => {
            const tmpGroup = group?.list?.map((item) => ({
                ...item,
                checked: checkedItem,
            }));

            return { ...group, list: tmpGroup, checkedType: groupCheckedType };
        });

        setNotifyListVirtual(finalList);
    }, [notifyListVirtual]);

    const toggleGroup = (group) => {
        const itemValue = group.checkedType === NOTIFICATION_CHECKBOX_TYPES.NO;
        const tmpList = group.list.map((item) => ({
            id: item.id,
            checked: itemValue,
        }));

        const tmpGroup = {
            id: group.id,
            list: tmpList,
            checkedType:
                group.checkedType === NOTIFICATION_CHECKBOX_TYPES.NO
                    ? NOTIFICATION_CHECKBOX_TYPES.ALL
                    : NOTIFICATION_CHECKBOX_TYPES.NO,
        };
        updateNotifyListVirtual(tmpGroup);
    };

    const toggleItem = useCallback(
        (value, notificationId, groupId) => {
            const group = notifyListVirtual.find((item) => item.id === groupId);
            const itemIndex = group.list.findIndex(
                (item) => item.id === notificationId
            );

            const item = group.list[itemIndex];
            group.list[itemIndex] = { ...item, checked: value };
            updateNotifyListVirtual(group);
        },
        [notifyListVirtual]
    );

    const isShowDeleteButton = useMemo(() => {
        if (selectedAll && selectedAll !== NOTIFICATION_CHECKBOX_TYPES.NO) {
            return true;
        }

        const selectedGroup = notifyListVirtual.filter(
            (group) =>
                group.checkedType &&
                group.checkedType !== NOTIFICATION_CHECKBOX_TYPES.NO
        );
        if (selectedGroup?.length > 0) {
            return true;
        }

        for (let i = 0; i < notifyListVirtual.length; i++) {
            const group = notifyListVirtual[i];
            if (
                group.checkedType &&
                group.checkedType !== NOTIFICATION_CHECKBOX_TYPES.NO
            ) {
                return true;
            }

            for (let j = 0; j < group.list.length; j++) {
                const item = group.list[j];
                if (item.checked) {
                    return true;
                }
            }
        }

        return false;
    }, [notifyListVirtual, selectedAll]);

    const removeSelectedItems = useCallback(() => {
        setDeleting(true);
        const selectedIds = [];

        for (let i = 0; i < notifyListVirtual.length; i++) {
            const group = notifyListVirtual[i];
            if (group.checkedType !== NOTIFICATION_CHECKBOX_TYPES.NO) {
                for (let j = 0; j < group.list.length; j++) {
                    const item = group.list[j];
                    if (item.checked) {
                        selectedIds.push(item.id);
                    }
                }
            }
        }

        const payload = {
            action: "D",
            id: selectedIds.join(",").toString(),
        };
        notificationService.updateNotificationStatus(payload).then(() => {
            setFirstLoad(true);
            setSelectedAll(NOTIFICATION_CHECKBOX_TYPES.NO);
            getNotificationList();
        });
    }, [notifyListVirtual, getNotificationList]);

    return (
        <>
            <Banner title={t("notification_label_notification_center")} />
            <ContentWrapper>
                <Content>
                    <div ref={infiniteRef}>
                        <Box component={Paper} overflow="hidden">
                            <NotificationListActions
                                toggleAll={toggleAll}
                                selectedAll={selectedAll}
                                removeSelectedItems={removeSelectedItems}
                                isShowDeleteButton={isShowDeleteButton}
                                notificationListFetching={
                                    notificationListFetching
                                }
                                checkboxStyle={classes.checkBox}
                                refresh={refreshNotificationList}
                            />

                            {((firstLoad && notificationListFetching) ||
                                deleting) && <NotificationListFetching />}

                            {!deleting &&
                                notifyList &&
                                notifyListVirtual &&
                                notifyList?.map((group, index) => {
                                    const virtualGroup =
                                        notifyListVirtual[index] || {};

                                    return (
                                        <NotificationListGroup
                                            selectGroup={toggleGroup}
                                            selectItem={toggleItem}
                                            direction={direction}
                                            group={group}
                                            key={group.id}
                                            className={classes.checkBox}
                                            checkBoxTypes={
                                                NOTIFICATION_CHECKBOX_TYPES
                                            }
                                            virtualGroup={virtualGroup}
                                        />
                                    );
                                })}

                            {!firstLoad &&
                                !deleting &&
                                notificationListFetching && (
                                    <NotificationListFetching type="loadMore" />
                                )}
                        </Box>
                    </div>
                </Content>
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    notificationListFetching: state.notification.fetching,
    notificationList: state.notification.list,
    notificationListHasMore: state.notification.hasMore,
});

const mapDispatch = (dispatch) => ({
    getNotificationList: (payload) =>
        dispatch(notificationAction.getNotificationListRequest(payload)),
});

export default connect(mapState, mapDispatch)(NotificationCenter);
