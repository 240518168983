import { memo, useCallback, useEffect, useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { NotificationActiveIcon } from "@components/LspIcon";
import { useHistory } from "react-router-dom";
import pendingTaskService from "@services/pending-task";
import { RESPONSE_CODE } from "@config/constants";
import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import NotificationBar from "./NotificationBar";

const useStyles = makeStyles((theme) => ({
    action: {
        color: theme.palette.neutral.white,
    },
}));

const PendingTaskBar = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [content, setContent] = useState("");
    const unmounted = useRef(false);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const goToPendingDashboard = () => {
        history.push("/pending-task");
    };

    const getTotalPendingTask = useCallback(async () => {
        const response = await pendingTaskService.getTotalPendingTasks();

        if (unmounted.current) return;

        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const totalTask = data.numberOfTasks;
                if (!totalTask) {
                    setContent("");
                    return;
                }
                if (totalTask && totalTask > 1) {
                    setContent(
                        t("outstanding_tasks_commod_many").replace(
                            "%@",
                            totalTask
                        )
                    );
                    return;
                }
                if (totalTask && totalTask === 1) {
                    setContent(t("outstanding_tasks_commod"));
                    return;
                }
                setContent("");
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [t]);

    useEffect(() => {
        getTotalPendingTask();
    }, [getTotalPendingTask]);

    return !content ? null : (
        <NotificationBar
            color="gold"
            IconComponent={NotificationActiveIcon}
            message={content}
            buttonComponent={
                <IconButton
                    size="small"
                    className={classes.action}
                    onClick={goToPendingDashboard}
                    datatestid="pendingtask"
                >
                    <NavigateNextIcon />
                </IconButton>
            }
        />
    );
};

export default memo(PendingTaskBar);
