import { memo } from "react";
import tommyIcon from "@assets/images/tommy_finding.png";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import ConditionNotificationDialog from "./NotificationPopover/ConditionNotificationDialog";

const UsingOverdraftWarningDialog = ({ onClose }) => {
    const { t } = useTranslation();

    const contents = {
        banner: tommyIcon,
        title: t("overdraft:overdraft_threshold_notice_title"),
        description: t("overdraft:overdraft_threshold_notice_description"),
        buttons: [
            {
                variant: "primary",
                label: t("overdraft:overdraft_threshold_notice_cta"),
                onClick: () => {
                    if (onClose && isFunction(onClose)) {
                        onClose();
                    }
                },
            },
        ],
    };

    return <ConditionNotificationDialog content={contents} centerImg />;
};

export default memo(UsingOverdraftWarningDialog);
