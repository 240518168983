import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useStepNavigation from "@helpers/useStepNavigation";
import bookingActions from "@redux/actions/booking";
import bookingService from "@services/booking";
import userService from "@services/user";
import { add, format } from "date-fns";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import CreateBookingDeliveryMethod from "@containers/Booking/CreateBooking/CreateBookingDelivery/CreateBookingDeliveryMethod";
import CreateBookingDeliveryTimeSelection from "@containers/Booking/CreateBooking/CreateBookingDelivery/CreateBookingDeliveryTimeSelection";
import {
    BOOKING_ACTIONS,
    BOOKING_SERVICES,
    BOOKING_STEPS,
    BOOKING_TYPES,
    DELIVERY_METHODS,
} from "../constant";
import CreateBookingConfirmation from "./CreateBookingHangout/CreateBookingConfirmation";
import CreateBookingLocationSelection from "./CreateBookingHangout/CreateBookingLocationSelection";

import CreateBookingPickupMethod from "./CreateBookingPickupMethod";
import CreateBookingProvinceSelection from "./CreateBookingHangout/CreateBookingProvinceSelection";
import CreateBookingSuccess from "./CreateBookingSuccess";
import CreateBookingTimeSelection from "./CreateBookingHangout/CreateBookingTimeSelection";
import CreateBookingAddressSelection from "./CreateBookingDelivery/CreateBookingAddressSelection";
import CreateBookingOtherAddress from "./CreateBookingDelivery/CreateBookingOtherAddress";
import CreateBookingDeliveryConfirmation from "./CreateBookingDelivery/CreateBookingDeliveryConfirmation";

const CreateBooking = ({
    type,
    getBookingList,
    today,
    serviceName,
    id,
    setBookingMethod,
    detail,
    isDeliveryAvailable,
    method,
}) => {
    const { t } = useTranslation();

    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: "",
    });

    const unmounted = useRef(false);
    const firstLoaded = useRef(false);

    const [submitting, setSubmitting] = useState(false);

    const availableMethod = useMemo(() => {
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                return {
                    isHangout: true,
                    isMail: true,
                    isMotorbike: true,
                    isDelivery: false,
                };

            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                return {
                    isHangout: true,
                    isMail: true,
                    isMotorbike: true,
                    isDelivery: isDeliveryAvailable,
                };
            default:
                return {};
        }
    }, [serviceName, isDeliveryAvailable]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const initPage = useCallback(() => {
        firstLoaded.current = true;

        switch (serviceName) {
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                // NEW BOOKING
                stepForward(BOOKING_STEPS.PICKUP_METHOD);
                break;
            case BOOKING_SERVICES.EKYC_BOOKING:
            default:
                if (type === BOOKING_ACTIONS.CHANGE_BOOKING) {
                    setBookingMethod(DELIVERY_METHODS.HANGOUT);
                    stepForward(BOOKING_STEPS.HANGOUT_PROVINCE_SELECTION);
                    return;
                }

                // NEW BOOKING
                stepForward(BOOKING_STEPS.PICKUP_METHOD);
                break;
        }
    }, [serviceName, setBookingMethod, stepForward, type]);

    useEffect(() => {
        if (!firstLoaded.current) {
            initPage();
        }
    }, [initPage]);

    const getScheduleList = (locationId) => {
        stepForward(BOOKING_STEPS.HANGOUT_TIME_SELECTION);

        const nextSevenDay = add(today, { days: 7 });
        // eslint-disable-next-line prefer-const
        let payload = {
            from: format(today, "yyyy-MM-dd"),
            to: format(nextSevenDay, "yyy-MM-dd"),
            locationId,
        };

        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                payload.bookingType = BOOKING_TYPES.KYC;
                break;
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                payload.bookingType = BOOKING_TYPES.REPLACE_CHIP_CARD;
                break;
            default:
                break;
        }

        getBookingList(payload);
    };

    // ======= Submit new booking ========= //

    const submitBookingSuccess = async (isRefreshProfile) => {
        if (isRefreshProfile) {
            await userService.getProfile();
        }

        // success for hangout booking
        // success for delivery booking

        stepForward(BOOKING_STEPS.SUCCESS_VIEW);
    };

    const submitBookingErrorHandler = (code) => {
        setSubmitting(false);
        // onCancel();

        let content = "";
        let header = "gs_contribute_error_title";
        switch (code) {
            case RESPONSE_CODE.RETRY_EXCEEDED: // 5004
                content = "join_schedule_msg_booking_retry_exceeded";
                break;
            case RESPONSE_CODE.NON_BOOKABLE_TIME_SCHEDULE: // 5007
                header = "join_schedule_title_warning_full_booked";
                content = "join_schedule_content_warning_full_booked";
                break;
            case RESPONSE_CODE.TOKEN_EXPIRED: // 9101
                content = "join_schedule_msg_token_expired";
                break;
            default:
                break;
        }
        if (content !== "") {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "problem",
                    button: "lb_btn_ok_got_it",
                    header,
                    content,
                },
            });

            return;
        }

        GlobalDialogController.showError({ errorCode: code });
    };

    const ekycBookingHandler = async (dataToPost) => {
        const { deliveryMethod, ...payload } = dataToPost;

        const response = await bookingService.submitEkycBooking(payload);

        if (unmounted.current) return;

        const { code } = response?.data;

        if (code !== RESPONSE_CODE.SUCCESS) {
            submitBookingErrorHandler(code);
            return;
        }

        const reqPayload = {
            ekycId: id,
            deliveryMethod,
        };
        const { data } = await bookingService.submitPickupEkyc(reqPayload);

        if (data?.code === RESPONSE_CODE.SUCCESS) {
            submitBookingSuccess(true);
            return;
        }

        submitBookingErrorHandler(data.code);
    };

    const submitDeliveryKYCBooking = async (dataToPost) => {
        const { data } = await bookingService.submitEkycDeliveryBooking({
            ...dataToPost,
            ekycId: id,
        });

        if (data?.code === RESPONSE_CODE.SUCCESS) {
            submitBookingSuccess(true);
            return;
        }

        submitBookingErrorHandler(data.code);
    };

    const submitBookingReplaceChipCard = async (dataToPost) => {
        const { data } = await bookingService.bookingReplaceChipCard(
            dataToPost
        );

        if (data?.code === RESPONSE_CODE.SUCCESS) {
            submitBookingSuccess(true);
            return;
        }

        // mobile handler 6001  is iotp > flow break, not using on web anymore
        // if (data?.code === RESPONSE_CODE.OTP_REQUIRED) {

        // }

        submitBookingErrorHandler(data.code);
    };

    const submitForNewBooking = (dataToPost) => {
        setSubmitting(true);
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                if (method === DELIVERY_METHODS.HANGOUT) {
                    ekycBookingHandler(dataToPost);
                } else {
                    submitDeliveryKYCBooking(dataToPost);
                }
                break;
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                submitBookingReplaceChipCard(dataToPost);
                break;
            default:
                break;
        }
    };

    // ======= Submit update booking ========= //

    const updateEkycBookingHandler = async (dataToPost) => {
        const response = await bookingService.updateEkycBooking({
            ...dataToPost,
            bookingId: detail?.bookingId,
        });

        if (unmounted.current) return;
        setSubmitting(false);

        const { code } = response?.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            submitBookingSuccess(true);
            return;
        }

        submitBookingErrorHandler(code);
    };

    const updateBookingReplaceChipCard = async (dataToPost) => {
        const { data } = await bookingService.updateBookingReplaceChipCard(
            dataToPost
        );

        if (data?.code === RESPONSE_CODE.SUCCESS) {
            submitBookingSuccess(true);
            return;
        }

        // mobile handler 6001  is iotp > flow break, not using on web anymore
        // if (data?.code === RESPONSE_CODE.OTP_REQUIRED) {

        // }

        submitBookingErrorHandler(data.code);
    };

    const submitForUpdateBooking = (dataToPost) => {
        setSubmitting(true);
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                updateEkycBookingHandler(dataToPost);
                break;
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                updateBookingReplaceChipCard(dataToPost);
                break;
            default:
                break;
        }
    };

    // ======= Submit booking handler ========= //

    const submitBooking = async (dataToPost) => {
        if (type === BOOKING_ACTIONS.NEW_BOOKING) {
            submitForNewBooking(dataToPost);
        } else if (type === BOOKING_ACTIONS.CHANGE_BOOKING) {
            submitForUpdateBooking(dataToPost);
        }
    };

    const onNextHandlerForAddressStep = (nextStep) => {
        if (nextStep === BOOKING_STEPS.DELIVERY_BOOKING_TIME) {
            const hangouts = t("hangout:hangouts", { returnObjects: true });
            getScheduleList(hangouts[0]?.id);
        }
        stepForward(nextStep);
    };

    return (
        <div>
            {step.current === BOOKING_STEPS.PICKUP_METHOD && (
                <CreateBookingPickupMethod
                    onNext={stepForward}
                    availableMethod={availableMethod}
                />
            )}

            {/* Booking by hangout */}
            {step.current === BOOKING_STEPS.HANGOUT_PROVINCE_SELECTION && (
                <CreateBookingProvinceSelection
                    onBack={stepBack}
                    onNext={stepForward}
                />
            )}
            {step.current === BOOKING_STEPS.HANGOUT_LOCATION_SELECTION && (
                <CreateBookingLocationSelection
                    onBack={stepBack}
                    onNext={getScheduleList}
                />
            )}
            {step.current === BOOKING_STEPS.HANGOUT_TIME_SELECTION && (
                <CreateBookingTimeSelection
                    onBack={stepBack}
                    onNext={stepForward}
                />
            )}

            {step.current === BOOKING_STEPS.HANGOUT_CONFIRMATION && (
                <CreateBookingConfirmation
                    onBack={stepBack}
                    onSubmit={submitBooking}
                    submitting={submitting}
                />
            )}

            {/* Booking by delivery mail */}

            {step.current === BOOKING_STEPS.DELIVERY_METHOD_SELECTION && (
                <CreateBookingDeliveryMethod
                    onNext={stepForward}
                    onBack={stepBack}
                    availableMethod={availableMethod}
                />
            )}

            {step.current === BOOKING_STEPS.DELIVERY_ADDRESS_SELECTION && (
                <CreateBookingAddressSelection
                    onNext={onNextHandlerForAddressStep}
                    onBack={stepBack}
                />
            )}

            {step.current === BOOKING_STEPS.DELIVERY_OTHER_ADDRESS_INPUT && (
                <CreateBookingOtherAddress
                    onNext={onNextHandlerForAddressStep}
                    onBack={stepBack}
                />
            )}
            {step.current === BOOKING_STEPS.DELIVERY_BOOKING_TIME && (
                <CreateBookingDeliveryTimeSelection
                    onNext={stepForward}
                    onBack={stepBack}
                />
            )}

            {step.current === BOOKING_STEPS.DELIVERY_CONFIRMATION && (
                <CreateBookingDeliveryConfirmation
                    onBack={stepBack}
                    onSubmit={submitBooking}
                    submitting={submitting}
                />
            )}

            {step.current === BOOKING_STEPS.SUCCESS_VIEW && (
                <CreateBookingSuccess />
            )}
        </div>
    );
};

const mapState = (state) => ({
    serviceName: state.booking.serviceName,
    detail: state.booking.detail,
    type: state.booking.type,
    method: state.booking.method,
    today: state.user.serverTime.value || new Date(),
    onCancel: state.booking.onCancel,
    isDeliveryAvailable:
        // eslint-disable-next-line
    state.systemParams?.info?.sysparams["1.0"]?.AllowBookingDelivery ==
            "true" || false,
});

const mapDispatch = (dispatch) => ({
    setBookingMethod: (payload) =>
        dispatch(bookingActions.setBookingMethod(payload)),
    getBookingList: (payload) =>
        dispatch(bookingActions.getBookingList(payload)),
});
export default connect(mapState, mapDispatch)(CreateBooking);
