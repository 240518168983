import produce from "immer";
import {
    AppActionType,
    LOCAL_STORAGE_STATUS,
    ThemeName,
} from "@config/constants";

/* ------------- Initial State ------------- */
const initialState = {
    status: AppActionType.Stop,
    triggered: false,
    paletteType: "light",
    themeName: ThemeName.Purple,
    isDarkMode: false,
    agreeTermCondition: false,
};

/* ------------- Reducer ------------- */
const appReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case AppActionType.Start:
                draft.status = AppActionType.Start;
                draft.triggered = true;

                if (!draft.themeName) {
                    draft.themeName = initialState.themeName;
                }
                if (!draft.isDarkMode) {
                    draft.isDarkMode = initialState.isDarkMode;
                }
                break;
            case AppActionType.Stop:
                draft.status = AppActionType.Stop;
                draft.triggered = false;
                draft.themeName = localStorage.getItem("themeName")
                    ? localStorage.getItem("themeName")
                    : initialState.themeName;

                const isDark =
                    localStorage.getItem("isDarkMode") === "true" ||
                    localStorage.getItem("isDarkMode") === true;
                draft.isDarkMode = isDark;
                break;
            case AppActionType.SwitchPaletteType:
                localStorage.setItem("isDarkMode", !draft.isDarkMode);
                draft.isDarkMode = !draft.isDarkMode;
                break;
            case AppActionType.SwitchThemeName:
                localStorage.setItem("themeName", action.payload);
                draft.themeName = action.payload;
                break;
            case AppActionType.AgreeTermCondition:
                draft.agreeTermCondition = action.payload;
                break;
            default:
                break;
        }
    });

export default appReducer;
