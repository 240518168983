/* eslint-disable react/no-array-index-key */
import { memo, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import LspTextField from "@components/LspTextField";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import LspAddress from "@components/LspAddress";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Paper,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

import {
    WEALTH_CREDIT_CARD_STEPS,
    WEALTH_OCCUPATIONS,
} from "../wealthConstants";

const EMPLOYEE_STATUS = {
    FULL_TIME: 0,
    PART_TIME: 1,
    INDEFINITE: 2,
    RETIRED: 3,
};
const COMPANY_TYPES = {
    ADMINISTRATIVE: 0,
    BUSINESS_PARTNERSHIP: 1,
    INDIVIDUAL_BUSINESS_HOUSEHOLD: 2,
    LIMITED_LIABILITY: 3,
    PRIVATE_COMPANY: 4,
    VENTURE_BUSINESS: 5,
    COOPERATIVE: 6,
    JOIN_STOCK: 7,
    POLICE_MILITARY: 8,
    STATE_OWNER: 9,
};
const COMPANY_INDUSTRY = {
    FINANCE: 0,
    RESTAURANT: 1,
    INSURANCE: 2,
    COMMUNICATION: 3,
    TRANSPORTATION: 4,
    REAL_ESTATE: 5,
};

const MIN_WORKING_MONTHS = 3;

const WealthCreditCardFinanceForm = ({
    onNext,
    onBack,
    setFinanceForm,
    financeForm,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const [errors, setErrors] = useState({
        occupation: null,
        occupationOther: null,
        employeeStatus: null,
        companyName: null,
        companyType: null,
        companyIndustry: null,
        workingMonths: null,
        companyPhone: null,
    });

    const [companyErrors, setCompanyErrors] = useState({
        address: null,
        province: null,
        district: null,
        ward: null,
    });

    const OCCUPATION_LIST = useMemo(
        () => [
            {
                cfgKey: WEALTH_OCCUPATIONS.SELF_EMPLOYEE,
                cfgValue: t("wealth:mc_v3_occupation_option1"),
            },
            {
                cfgKey: WEALTH_OCCUPATIONS.OFFICE_WORKER,
                cfgValue: t("wealth:mc_v3_occupation_option2"),
            },
            {
                cfgKey: WEALTH_OCCUPATIONS.OTHERS,
                cfgValue: t("wealth:mc_v3_occupation_option3"),
            },
        ],
        [t]
    );

    const EMPLOYEE_STATUS_LIST = useMemo(
        () => [
            {
                cfgKey: EMPLOYEE_STATUS.FULL_TIME,
                cfgValue: t("wealth:mc_v3_employment_status_option1"),
            },
            {
                cfgKey: EMPLOYEE_STATUS.PART_TIME,
                cfgValue: t("wealth:mc_v3_employment_status_option2"),
            },
            {
                cfgKey: EMPLOYEE_STATUS.INDEFINITE,
                cfgValue: t("wealth:mc_v3_employment_status_option3"),
            },
            {
                cfgKey: EMPLOYEE_STATUS.RETIRED,
                cfgValue: t("wealth:mc_v3_employment_status_option4"),
            },
        ],
        [t]
    );

    const COMPANY_TYPE_LIST = useMemo(
        () => [
            {
                cfgKey: COMPANY_TYPES.LIMITED_LIABILITY,
                cfgValue: t("wealth:mc_v3_company_type_option4"),
            },
            {
                cfgKey: COMPANY_TYPES.ADMINISTRATIVE,
                cfgValue: t("wealth:mc_v3_company_type_option1"),
            },
            {
                cfgKey: COMPANY_TYPES.COOPERATIVE,
                cfgValue: t("wealth:mc_v3_company_type_option7"),
            },
            {
                cfgKey: COMPANY_TYPES.STATE_OWNER,
                cfgValue: t("wealth:mc_v3_company_type_option10"),
            },
            {
                cfgKey: COMPANY_TYPES.JOIN_STOCK,
                cfgValue: t("wealth:mc_v3_company_type_option8"),
            },
        ],
        [t]
    );
    const COMPANY_INDUSTRY_LIST = useMemo(
        () => [
            {
                cfgKey: COMPANY_INDUSTRY.INSURANCE,
                cfgValue: t("wealth:mc_v3_company_industry_option3"),
            },
            {
                cfgKey: COMPANY_INDUSTRY.COMMUNICATION,
                cfgValue: t("wealth:mc_v3_company_industry_option4"),
            },
            {
                cfgKey: COMPANY_INDUSTRY.TRANSPORTATION,
                cfgValue: t("wealth:mc_v3_company_industry_option5"),
            },
            {
                cfgKey: COMPANY_INDUSTRY.RESTAURANT,
                cfgValue: t("wealth:mc_v3_company_industry_option2"),
            },
            {
                cfgKey: COMPANY_INDUSTRY.FINANCE,
                cfgValue: t("wealth:mc_v3_company_industry_option1"),
            },
        ],
        [t]
    );

    // ------ Start: Init params ------

    const [occupation, setOccupation] = useState(
        financeForm?.occupation ? financeForm?.occupation : null
    );

    const [occupationOther, setOccupationOther] = useState(
        financeForm?.occupationOther ? financeForm?.occupationOther : ""
    );
    const [employeeStatus, setEmployeeStatus] = useState(
        financeForm?.employeeStatus ? financeForm?.employeeStatus : null
    );
    const [companyName, setCompanyName] = useState(
        financeForm?.companyName ? financeForm?.companyName : ""
    );
    const [companyType, setCompanyType] = useState(
        financeForm?.companyType ? financeForm?.companyType : null
    );
    const [companyIndustry, setCompanyIndustry] = useState(
        financeForm?.companyIndustry ? financeForm?.companyIndustry : null
    );

    const [workingMonths, setWorkingMonths] = useState(
        financeForm?.workingMonths ? financeForm?.workingMonths : ""
    );
    const [companyPhone, setCompanyPhone] = useState(
        financeForm?.companyPhone ? financeForm?.companyPhone : ""
    );

    const [companyAddress, setCompanyAddress] = useState(
        financeForm?.companyAddress ? financeForm?.companyAddress : ""
    );

    const [selectedCompanyProvince, setSelectedCompanyProvince] = useState(
        () => {
            return financeForm?.selectedCompanyProvince
                ? financeForm?.selectedCompanyProvince
                : null;
        }
    );

    const [selectedCompanyDistrict, setSelectedCompanyDistrict] = useState(
        () => {
            return financeForm?.selectedCompanyDistrict
                ? financeForm?.selectedCompanyDistrict
                : null;
        }
    );
    const [selectedCompanyWard, setSelectedCompanyWard] = useState(() => {
        return financeForm?.selectedCompanyWard
            ? financeForm?.selectedCompanyWard
            : null;
    });

    // ------ End: Init params ------

    // ------ Start: On change handler function ------

    const onChangeOccupation = (e) => {
        const { value } = e.target;
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                occupation: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                occupation: "",
            }));
        }
        setOccupation(value);
    };

    const onChangeOccupationOther = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.DESCRIPTION_REPLACEMENT,
            text: e.target.value,
        });

        setOccupationOther(value);

        if (!value) {
            setErrors((prev) => ({
                ...prev,
                occupation: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                occupation: "",
            }));
        }
    };

    const onChangeEmployeeStatus = ({ value }) => {
        if (value?.cfgKey === "") {
            setErrors((prev) => ({
                ...prev,
                employeeStatus: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                employeeStatus: "",
            }));
        }

        setEmployeeStatus(value);
    };

    const onChangeCompanyName = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NICK_NAME,
            text: e.target.value,
        });
        setCompanyName(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                companyName: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                companyName: "",
            }));
        }
    };
    const onChangeCompanyType = ({ value }) => {
        if (value?.cfgKey === "") {
            setErrors((prev) => ({
                ...prev,
                companyType: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                companyType: "",
            }));
        }

        setCompanyType(value);
    };
    const onChangeCompanyIndustry = ({ value }) => {
        if (value?.cfgKey === "") {
            setErrors((prev) => ({
                ...prev,
                companyIndustry: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                companyIndustry: "",
            }));
        }

        setCompanyIndustry(value);
    };

    const isWorkingMonthsValid = (workingTime) => {
        if (!workingTime) return false;

        return parseInt(workingTime) >= MIN_WORKING_MONTHS;
    };

    const onChangeWorkingMonths = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER,
            text: e.target.value,
        });
        setWorkingMonths(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                workingMonths: t("wealth:mc_v3_error_we_need"),
            }));
        } else if (!isWorkingMonthsValid(value)) {
            setErrors((prev) => ({
                ...prev,
                workingMonths: t("wealth:mc_v3_error_working_msg"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                workingMonths: "",
            }));
        }
    };

    const onChangeCompanyPhone = (e) => {
        const { value } = e.target;

        setCompanyPhone(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            })
        );

        if (!value) {
            setErrors((prev) => ({
                ...prev,
                companyPhone: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                companyPhone: "",
            }));
        }
    };

    // Company Address

    const onChangeCompanyProvince = (province) => {
        if (province?.cfgKey === "") {
            setCompanyErrors((prev) => ({
                ...prev,
                province: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCompanyErrors((prev) => ({
                ...prev,
                province: "",
            }));
        }

        setSelectedCompanyProvince(province);
    };
    const onChangeCompanyDistrict = (district) => {
        if (district?.cfgKey === "") {
            setCompanyErrors((prev) => ({
                ...prev,
                district: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCompanyErrors((prev) => ({
                ...prev,
                district: "",
            }));
        }
        setSelectedCompanyDistrict(district);
    };
    const onChangeCompanyWard = (ward) => {
        if (ward?.cfgKey === "") {
            setCompanyErrors((prev) => ({
                ...prev,
                ward: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setCompanyErrors((prev) => ({
                ...prev,
                ward: "",
            }));
        }
        setSelectedCompanyWard(ward);
    };

    const onChangeCompanyAddress = (e) => {
        setCompanyErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setCompanyAddress(address);
        if (!address) {
            setCompanyErrors((prev) => ({
                ...prev,
                address: t("wealth:mc_v3_error_we_need"),
            }));
        }
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        const payload = {
            occupation,
            employeeStatus,
            companyName,
            companyType,
            companyIndustry,
            workingMonths,
            companyPhone,
            companyAddress,
            selectedCompanyProvince,
            selectedCompanyDistrict,
            selectedCompanyWard,
        };

        if (occupation === WEALTH_OCCUPATIONS.OTHERS.toString()) {
            payload.occupationOther = occupationOther;
        }

        setFinanceForm(payload);

        onNext(WEALTH_CREDIT_CARD_STEPS.PAYMENT_SELECTIONS);
    };

    const onBackHandler = () => {
        setFinanceForm(null);
        onBack();
    };

    // ------ End: On submit form handler function ------

    return (
        <form onSubmit={onSubmitForm}>
            <PageHeaderBack onBack={onBackHandler}>
                {t("wealth:mc_v3_per_inf_title")}
            </PageHeaderBack>
            <Paper>
                <div className={sharedClasses.formHeader}>
                    <LspTypography variant="heading3" color="grey">
                        {t("wealth:mc_v3_occupation_title")}
                    </LspTypography>
                </div>

                <div className={sharedClasses.radioWrapper}>
                    <FormControl
                        component="fieldset"
                        style={{ marginBottom: 8 }}
                    >
                        <RadioGroup aria-label="occupation" name="occupation">
                            {OCCUPATION_LIST?.map((item) => {
                                return (
                                    <FormControlLabel
                                        key={item.cfgKey}
                                        value={item.cfgKey}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={
                                                    occupation ===
                                                    item?.cfgKey.toString()
                                                }
                                                onChange={onChangeOccupation}
                                            />
                                        }
                                        label={item.cfgValue}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>

                    {occupation === WEALTH_OCCUPATIONS.OTHERS.toString() && (
                        <LspTextField
                            name="occupationOther"
                            error={!!errors?.occupationOther}
                            label={t("wealth:mc_v3_answer_lb")}
                            helperText={errors?.occupationOther || " "}
                            inputProps={{
                                maxLength: 35,
                            }}
                            onChange={onChangeOccupationOther}
                            value={occupationOther}
                        />
                    )}

                    <Autocomplete
                        options={EMPLOYEE_STATUS_LIST}
                        getOptionLabel={(option) => option?.cfgValue || ""}
                        value={employeeStatus}
                        onChange={(_, value) =>
                            onChangeEmployeeStatus({ value })
                        }
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("wealth:mc_v3_employment_status")}
                                    error={!!errors.employeeStatus}
                                    helperText={errors.employeeStatus || " "}
                                    InputProps={inputProps}
                                />
                            );
                        }}
                    />
                    <LspTextField
                        name="companyName"
                        error={!!errors?.companyName}
                        label={t("wealth:mc_v3_fin_comp_name_lb")}
                        helperText={errors?.companyName || " "}
                        onChange={onChangeCompanyName}
                        value={companyName}
                    />
                    <Autocomplete
                        options={COMPANY_TYPE_LIST}
                        getOptionLabel={(option) => option?.cfgValue || ""}
                        value={companyType}
                        onChange={(_, value) => onChangeCompanyType({ value })}
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("wealth:mc_v3_company_type")}
                                    error={!!errors.companyType}
                                    helperText={errors.companyType || " "}
                                    InputProps={inputProps}
                                />
                            );
                        }}
                    />
                    <Autocomplete
                        options={COMPANY_INDUSTRY_LIST}
                        getOptionLabel={(option) => option?.cfgValue || ""}
                        value={companyIndustry}
                        onChange={(_, value) =>
                            onChangeCompanyIndustry({ value })
                        }
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("wealth:mc_v3_company_industry")}
                                    error={!!errors.companyIndustry}
                                    helperText={errors.companyIndustry || " "}
                                    InputProps={inputProps}
                                />
                            );
                        }}
                    />

                    <div className={sharedClasses.monthWrapper}>
                        <LspTextField
                            name="workingMonths"
                            error={!!errors?.workingMonths}
                            label={t("wealth:mc_v3_company_start_date")}
                            helperText={errors?.workingMonths || " "}
                            inputProps={{
                                maxLength: 5,
                            }}
                            onChange={onChangeWorkingMonths}
                            value={workingMonths}
                        />
                        {workingMonths && (
                            <span className={sharedClasses.monthLabel}>
                                {t("wealth:mc_v3_working_months_lb")}
                            </span>
                        )}
                    </div>

                    <LspTextField
                        name="companyPhone"
                        error={!!errors?.companyPhone}
                        label={t("wealth:mc_v3_company_phone")}
                        helperText={errors?.companyPhone || " "}
                        onChange={onChangeCompanyPhone}
                        value={companyPhone}
                    />
                </div>

                <div
                    className={sharedClasses.boxFormWrapper}
                    style={{ paddingTop: 0 }}
                >
                    <LspTypography
                        variant="body3"
                        color="grey"
                        className={sharedClasses.subtitle}
                    >
                        {t("wealth:mc_v3_company_address_title")}
                    </LspTypography>
                    <LspAddress
                        selectedProvince={selectedCompanyProvince}
                        onChangeProvince={onChangeCompanyProvince}
                        selectedDistrict={selectedCompanyDistrict}
                        onChangeDistrict={onChangeCompanyDistrict}
                        selectedWard={selectedCompanyWard}
                        onChangeWard={onChangeCompanyWard}
                        errors={companyErrors}
                        fullWidth
                    />
                    <LspTextField
                        name="companyAddress"
                        error={!!companyErrors?.address}
                        label={t("bill_lb_address")}
                        helperText={companyErrors?.address || " "}
                        inputProps={{
                            maxLength: 35,
                        }}
                        onChange={onChangeCompanyAddress}
                        value={companyAddress}
                    />
                </div>
            </Paper>
            <LspButton
                fullWidth
                type="submit"
                className={sharedClasses.button}
                disabled={
                    !occupation ||
                    (occupation === WEALTH_OCCUPATIONS.OTHERS.toString() &&
                        !occupationOther) ||
                    !employeeStatus ||
                    !companyName ||
                    !companyType ||
                    !companyIndustry ||
                    !isWorkingMonthsValid(workingMonths) ||
                    !companyPhone ||
                    !companyAddress ||
                    !selectedCompanyProvince?.cfgKey ||
                    !selectedCompanyDistrict?.cfgKey ||
                    !selectedCompanyWard?.cfgKey
                }
            >
                {t("wealth:mc_v3_btn_next")}
            </LspButton>
        </form>
    );
};

const mapState = (state) => ({
    financeForm: state.wealthCreditCard.financeForm,
});

const mapDispatch = (dispatch) => ({
    setFinanceForm: (payload) =>
        dispatch(wealthCreditCardAction.setFinanceForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardFinanceForm)
);
