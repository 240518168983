import {
    Box,
    Button,
    ButtonBase,
    Icon,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LspTextField from "../../components/LspTextField";
import { REGEX_PATTERN } from "../../config/constants";
import useNumber from "../../helpers/useNumber";
import lixiActions from "../../redux/actions/lixi";
import { LIXI_ACTIONS, LIXI_NAV } from "./constant";

const useStyles = makeStyles((theme) => ({
    lixiDetail: {
        maxWidth: "555px",
        margin: "auto",
        borderRadius: theme.shape.radiusLevels[0],
        textAlign: "center",
        color: "white",
        fontSize: theme.typography.pxToRem(20),
        position: "relative",
    },
    image: {
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        maxWidth: "360px",
    },
    amount: {
        fontSize: theme.typography.pxToRem(32),
    },
    label: {
        fontSize: theme.typography.pxToRem(14),
    },
    name: {
        textTransform: "uppercase",
        fontWeight: 600,
    },
    root: {
        margin: "auto",
    },
    closeIcon: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(22),
    },
    doneBtn: {
        marginTop: theme.spacing(4),
        border: "1px solid #FFF",
        color: "white",
        "&:hover": {
            border: "1px solid #FFF",
        },
    },
    editName: {
        marginTop: theme.spacing(2),
        "& .MuiFormHelperText-root": {
            color: "white",
        },
    },
    editIcon: {
        cursor: "pointer",
        marginLeft: theme.spacing(1),
    },
    controlBtns: {
        textAlign: "right",
        marginBottom: theme.spacing(2),
        "& button": {
            color: "white",
        },
    },
}));

const LixiDetail = ({
    detail,
    getDetail,
    loading,
    giftId,
    type,
    isReview,
    onClose,
    onDone,
}) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    // const { register, handleSubmit, errors, setValue, getValues } = useForm();
    const { t } = useTranslation("translation", "lixi");
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();

    const [hasShowDoneBtn, setHasShowDoneBtn] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [payeeName, setPayeeName] = useState("");
    const [payeeNameError, setPayeeNameError] = useState("");
    const [previousName, setPreviousName] = useState("");

    const themeList = useMemo(() => {
        const arrayThemes = t("lixi:LIXI_available_theme_keys", {
            returnObjects: true,
        });

        const list = [];

        arrayThemes.forEach((th) => {
            const thObj = t(`lixi:${th}`, { returnObjects: true });
            if (thObj) {
                list.push(thObj);
            }
        });

        // for (let i = 0; i < arrayThemes.length; i++) {
        //     const theme = t(`lixi:${arrayThemes[i]}`, { returnObjects: true });
        //     if (theme) {
        //         themeList.push(theme);
        //     }
        // }

        return list;
    }, [t]);

    const getThemeDetail = useCallback(
        (themeId) => {
            return themeList.find((item) => {
                return item.id === themeId;
            });
        },

        [themeList]
    );

    const getLixiColor = useCallback(
        (themeId) => {
            if (!themeId) {
                return;
            }
            const themeDetail = getThemeDetail(themeId);
            return themeDetail.color;
        },
        [getThemeDetail]
    );

    const getLixiImage = useCallback(
        (themeId) => {
            if (!themeId) {
                return;
            }

            const themeDetail = getThemeDetail(themeId);
            return themeDetail.link;
        },
        [getThemeDetail]
    );

    const isReceiveTab = useCallback(() => {
        return type === LIXI_ACTIONS.RECEIVE;
    }, [type]);

    const closeClick = () => {
        if (onClose) {
            onClose();
        } else {
            const url =
                type === LIXI_ACTIONS.RECEIVE
                    ? LIXI_NAV.RECEIVE_LIST
                    : LIXI_NAV.SEND_LIST;
            history.push(url);
        }
    };

    const doneClick = () => {
        if (isReceiveTab() && onDone) {
            onDone(detail);
        } else {
            closeClick();
        }
    };

    const doneClickForReview = () => {
        const newDetail = { ...detail, title: payeeName };
        onDone(newDetail);
    };

    const openEditName = () => {
        setIsEditing(true);
        setPayeeNameError("");
    };

    const closeEditName = () => {
        setPayeeName(previousName);
        setIsEditing(false);
    };

    const getName = () => {
        if (isReceiveTab()) {
            return detail?.title;
        }
        if (detail?.receiverName) {
            return detail?.receiverName;
        }
        if (detail?.phoneNumber) {
            return detail?.phoneNumber;
        }
        return "";

        // return isReceiveTab()
        //     ? detail?.title
        //     : detail?.receiverName
        //     ? detail?.receiverName
        //     : detail?.phoneNumber;
    };

    const getNameOnReview = () => {
        if (payeeName !== "") {
            return payeeName;
        }
        return getName();
    };

    const onChangeName = (event) => {
        const name = event.target.value;

        setPayeeName(name);
        if (name === "") {
            setPayeeNameError("msg_we_need_this");
            return;
        }

        const regex = new RegExp(REGEX_PATTERN.DESCRIPTION);

        if (!regex.test(name)) {
            setPayeeNameError("error_description_invalid");
        } else {
            setPayeeNameError("");
        }
    };

    const saveName = () => {
        setPreviousName(payeeName);
        setIsEditing(false);
    };

    useEffect(() => {
        if (!loading && giftId && !detail) {
            getDetail(giftId);
        }
    }, [getDetail, giftId, loading, detail]);

    useEffect(() => {
        if (detail) {
            const hasShowBtn =
                (detail.isCampaignEnd && isReceiveTab()) || !isReceiveTab();
            setHasShowDoneBtn(hasShowBtn);
            setPayeeName(detail.title);
            setPreviousName(detail.title);
        }
    }, [detail, isReceiveTab]);

    return (
        <>
            {!loading && (
                <Box
                    padding={isSmall ? 2 : 4}
                    spacing={4}
                    className={classes.lixiDetail}
                    style={{
                        background: getLixiColor(detail?.themeId),
                    }}
                >
                    {/* Receive tab have close icon at here */}
                    {isReceiveTab() && (
                        <div onClick={closeClick}>
                            <Icon
                                className={`font-icon icon-close ${classes.closeIcon}`}
                            />
                        </div>
                    )}
                    <div>
                        <span className={classes.amount}>
                            {formatNumber(detail?.amount)}
                        </span>
                        <span> (VND)</span>
                    </div>
                    <div>
                        <img
                            className={classes.image}
                            src={getLixiImage(detail?.themeId)}
                            alt=""
                        />
                    </div>
                    <div className={classes.label}>
                        {isReceiveTab() || isReview
                            ? t("lixi:lixi_from")
                            : t("lixi:lixi_to")}
                    </div>

                    {/* Using for view detail at receive tab and send tab */}
                    {!isReview && (
                        <div className={classes.name}>{getName()}</div>
                    )}

                    {/* Using for send lixi flow: review screen */}
                    {isReview && !isEditing && (
                        <div className={classes.name}>
                            {getNameOnReview()}
                            {/* If review screen, user can edit name */}
                            <Icon
                                onClick={openEditName}
                                className={`font-icon icon-edit2 ${classes.editIcon}`}
                            />
                        </div>
                    )}
                    {isReview && isEditing && (
                        <form onSubmit={saveName}>
                            <div className={classes.editName}>
                                <LspTextField
                                    autoFocus
                                    error={!!payeeNameError}
                                    helperText={payeeNameError}
                                    name="payeeName"
                                    label={t("lb_name")}
                                    inputProps={{
                                        type: "text",
                                        maxLength: 40,
                                        disabled: loading,
                                    }}
                                    onChange={(e) => onChangeName(e)}
                                    value={payeeName}
                                />
                                <div className={classes.controlBtns}>
                                    <Button type="submit">
                                        <Icon className="font-icon icon-ok" />
                                    </Button>
                                    <ButtonBase onClick={closeEditName}>
                                        <Icon className="font-icon icon-close" />
                                    </ButtonBase>
                                </div>
                            </div>
                        </form>
                    )}
                    <div>{detail?.text}</div>

                    {/* Button for view detail of receive tab and send tab */}
                    {hasShowDoneBtn && !isReview && (
                        <Button
                            className={classes.doneBtn}
                            fullWidth
                            color="primary"
                            size="large"
                            variant="outlined"
                            onClick={doneClick}
                        >
                            {isReceiveTab()
                                ? t("lixi:lb_respond")
                                : t("lixi:lb_close")}
                        </Button>
                    )}

                    {/* Button for next on review screen */}
                    {isReview && (
                        <Button
                            className={classes.doneBtn}
                            fullWidth
                            color="primary"
                            size="large"
                            variant="outlined"
                            onClick={doneClickForReview}
                        >
                            {t("lixi:lb_send")}
                        </Button>
                    )}
                </Box>
            )}
            {loading && (
                <Box
                    padding={isSmall ? 2 : 4}
                    spacing={4}
                    className={classes.lixiDetail}
                    style={{
                        background: getLixiColor(detail?.themeId),
                    }}
                >
                    <div className={classes.amount}>
                        <Skeleton />
                    </div>
                    <div>
                        <Skeleton
                            variant="rect"
                            height={350}
                            className={classes.image}
                        />
                    </div>
                    <div className={classes.label}>
                        <Skeleton
                            classes={{ root: classes.root }}
                            width="30%"
                        />
                    </div>
                    <div className={classes.name}>
                        <Skeleton
                            classes={{ root: classes.root }}
                            width="50%"
                        />
                    </div>
                    <div>
                        <Skeleton />
                        <Skeleton />
                    </div>
                </Box>
            )}
        </>
    );
};

const stateProps = (state) => ({
    detail: state.lixi.detailInfo,
    loading: state.lixi.detailLoading,
});

const dispatchProps = (dispatch) => ({
    getDetail: (payload) => dispatch(lixiActions.getLixiDetail(payload)),
});

export default connect(stateProps, dispatchProps)(LixiDetail);
