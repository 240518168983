import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    thumbNailLoading: {
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
}));

const ThumbnailLoading = ({ className }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
            <Skeleton
                className={classes.thumbNailLoading}
                variant="rect"
                width={164}
                height={164}
            />
        </div>
    );
};

export default ThumbnailLoading;
