import { useCallback, useState, memo, useMemo } from "react";
import { Box, Card, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CardInstallmentUpdate from "src/containers/CardManagement/CardInstallmentUpdate";
import Content from "@components/Content";
import { isEmpty } from "lodash";
import CardManagementActions from "@redux/actions/card-management";
import { connect } from "react-redux";
import useAccounts from "@helpers/useAccounts";
import useCard from "@helpers/useCard";
import CardButtons from "./CardButtons";
import CardInfo from "./CardInfo";
import CardStatusBar from "./CardStatusBar";
import CardECommerce from "./CardECommerce";
import { BANK_CARD_STATUS, CARD_STATUSES, CARD_TYPES } from "./constant";
import CardInternationalPayment from "./CardInternationalPayment";
import CardPhysicalButton from "./CardPhysicalButton";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        card: {
            border: `1px solid ${
                isDark
                    ? theme.palette.magicalGrey[500]
                    : theme.palette.magicalGrey[200]
            }  `,
        },
    };
});

const CardRender = ({
    className,
    cardList,
    cardNav,
    lockCardConfirmation,
    unlockCard,
    cardStatusUpdating,
    onChangeECommerceStatus,
    eCommerceSUpdating,
    resetPin,
    reissueCard,
    changePin,
    cardLimit,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "sysParamContent");
    const [activeCard, setActiveCard] = useState({});
    const { isCardFeatureNotAvailableForeigner } = useAccounts();

    const { isVirtualCard } = useCard();

    const isShowThisCard = useCallback(
        (card) => {
            let result;
            switch (cardNav) {
                case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD:
                case CARD_TYPES.DEBIT_CARD:
                    result =
                        card.cardType === CARD_TYPES.DEBIT_CARD ||
                        card.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                        card.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP;
                    break;
                case CARD_TYPES.VISA_CARD:
                case CARD_TYPES.MASTER_CARD:
                default:
                    result = card.cardType !== CARD_TYPES.DEBIT_CARD;
                    break;
            }
            return result;
        },
        [cardNav]
    );

    const emptyCardHandler = useCallback(() => {
        let cardInfo = {};
        switch (cardNav) {
            case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP:
            case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD:
                cardInfo = {
                    cardStatus: CARD_STATUSES.NO_CARD,
                    isDisabled: true,
                    cardType: cardNav
                        ? CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD
                        : CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP,
                    cardImageUrl:
                        process.env.REACT_APP_API_URL +
                        t("sysParamContent:DefaultCreditCardUrl"),
                    cardId: "no-card",
                };
                break;
            case CARD_TYPES.DEBIT_CARD:
                cardInfo = {
                    cardStatus: isCardFeatureNotAvailableForeigner
                        ? CARD_STATUSES.FOREIGNER_WARNING
                        : CARD_STATUSES.NO_CARD,
                    isDisabled: true,
                    cardType: CARD_TYPES.DEBIT_CARD,
                    cardImageUrl:
                        process.env.REACT_APP_API_URL +
                        t("sysParamContent:DefaultCardUrl"),
                    cardId: "no-card",
                };
                break;
            case CARD_TYPES.VISA_CARD:
            case CARD_TYPES.MASTER_CARD:
            default:
                cardInfo = {
                    cardStatus: isCardFeatureNotAvailableForeigner
                        ? CARD_STATUSES.FOREIGNER_WARNING
                        : CARD_STATUSES.NO_CARD,
                    isDisabled: true,
                    cardType: CARD_TYPES.VISA_CARD,
                    cardImageUrl:
                        process.env.REACT_APP_API_URL +
                        t("sysParamContent:DefaultCreditCardUrl"),
                    cardId: "no-card",
                };
                break;
        }

        return (
            <>
                <CardButtons />
                <Content>
                    <Grid
                        container
                        spacing={1}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={9}>
                            <Card>
                                <CardInfo
                                    className={className}
                                    card={cardInfo}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Content>
            </>
        );
    }, [cardNav, className, t, isCardFeatureNotAvailableForeigner]);

    const isVisaDebit = useCallback(
        (card) =>
            card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
            card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP ||
            card?.cardType === CARD_TYPES.VISA_CARD,
        []
    );

    const isBVBCard = useCallback(
        (card) =>
            card?.isAllowSetCardLimit ||
            card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP,
        []
    );

    const isShowInternationalPaymentBar = useMemo(() => {
        if (
            isVirtualCard(activeCard?.cardType) &&
            activeCard?.cardStatus !== CARD_STATUSES.INACTIVE
        ) {
            return true;
        }

        return false;

        // if (activeCard?.cardStatus === CARD_STATUSES.BLOCKED) {
        //     return false;
        // }
        // if (!isVisaDebit(activeCard)) return false;

        // if (activeCard.cardStatus === CARD_STATUSES.ACTIVE) return true;

        // const isCardNotClose =
        //     activeCard.cardStatus === CARD_STATUSES.BLOCKED &&
        //     activeCard.bankCardStatus !== BANK_CARD_STATUS.PICK_UP_L41;
        // if (isCardNotClose) return true;

        // return false;
    }, [activeCard, isVirtualCard]);

    const cardTemplate = useCallback(
        (cards) => {
            if (isEmpty(activeCard) && cards.length > 0) {
                setActiveCard(cards[0]);
            }
            return (
                <>
                    <Box mb={2}>
                        <CardButtons
                            key={`button-${activeCard?.cardId}`}
                            card={activeCard}
                            resetPin={resetPin}
                            reissueCard={reissueCard}
                            changePin={changePin}
                            cardLimit={cardLimit}
                        />
                        <Content>
                            <Grid
                                container
                                spacing={1}
                                justify="center"
                                alignItems="center"
                                key={`card-${activeCard.cardId}`}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={9}
                                    style={{
                                        maxWidth: 538,
                                        margin: "auto",
                                    }}
                                >
                                    <Card className={classes.card}>
                                        <CardInfo
                                            hideCardInfo={
                                                isVisaDebit(activeCard) &&
                                                isBVBCard(activeCard)
                                            }
                                            className={className}
                                            card={activeCard}
                                            cardList={cards}
                                            onChangeActiveCard={(data) => {
                                                setActiveCard(data);
                                            }}
                                        />

                                        <CardPhysicalButton card={activeCard} />

                                        <CardStatusBar
                                            card={activeCard}
                                            lockCard={lockCardConfirmation}
                                            unlockCard={unlockCard}
                                            fetching={cardStatusUpdating}
                                        />
                                        {isShowInternationalPaymentBar && (
                                            <CardInternationalPayment
                                                card={activeCard}
                                            />
                                        )}
                                        <CardECommerce
                                            card={activeCard}
                                            onChangeECommerceStatus={
                                                onChangeECommerceStatus
                                            }
                                            eCommerceSUpdating={
                                                eCommerceSUpdating
                                            }
                                        />
                                        <CardInstallmentUpdate
                                            card={activeCard}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Content>
                    </Box>
                </>
            );
        },
        [
            activeCard,
            resetPin,
            reissueCard,
            changePin,
            cardLimit,
            classes.card,
            isVisaDebit,
            isBVBCard,
            className,
            lockCardConfirmation,
            unlockCard,
            cardStatusUpdating,
            onChangeECommerceStatus,
            eCommerceSUpdating,
            isShowInternationalPaymentBar,
        ]
    );

    const cardListRender = useCallback(
        (mappedCard) => {
            return (
                <>
                    {cardNav === CARD_TYPES.DEBIT_CARD && (
                        <>
                            {mappedCard?.debit?.length > 0 &&
                                cardTemplate(mappedCard?.debit)}
                            {mappedCard?.debit?.length === 0 &&
                                emptyCardHandler()}
                        </>
                    )}
                    {cardNav === CARD_TYPES.VISA_CARD && (
                        <>
                            {mappedCard?.credit?.length > 0 &&
                                cardTemplate(mappedCard?.credit)}

                            {mappedCard?.credit?.length === 0 &&
                                emptyCardHandler()}
                        </>
                    )}
                </>
            );
        },
        [cardNav, emptyCardHandler, cardTemplate]
    );

    const initCardRender = useCallback(() => {
        if (!cardList) return;

        if (cardList.length === 0) {
            return emptyCardHandler();
        }

        const mappedCard = { debit: [], credit: [] };
        const DEBIT_CARD_CONSTANTS = [
            CARD_TYPES.DEBIT_CARD,
            CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD,
            CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP,
        ];

        (cardList || []).forEach((cardItem) => {
            const isDebit = DEBIT_CARD_CONSTANTS.findIndex(
                (card) => cardItem.cardType === card
            );

            if (isShowThisCard(cardItem)) {
                if (isDebit > -1) {
                    mappedCard.debit.push(cardItem);
                } else {
                    mappedCard.credit.push(cardItem);
                }
            }
        });

        const isExistDebit = mappedCard.debit.findIndex(
            (item) => item.cardType === CARD_TYPES.DEBIT_CARD
        );

        if (isExistDebit === -1) {
            mappedCard.debit.push({
                cardStatus: isCardFeatureNotAvailableForeigner
                    ? CARD_STATUSES.FOREIGNER_WARNING
                    : CARD_STATUSES.NO_CARD,
                isDisabled: true,
                cardType: CARD_TYPES.DEBIT_CARD,
                cardImageUrl:
                    process.env.REACT_APP_API_URL +
                    t("sysParamContent:DefaultCardUrl"),
                cardId: "no-card",
            });
        }

        return cardListRender(mappedCard);
    }, [
        cardList,
        cardListRender,
        emptyCardHandler,
        isShowThisCard,
        t,
        isCardFeatureNotAvailableForeigner,
    ]);

    return <>{initCardRender()}</>;
};

const mapDispatchToProps = (dispatch) => ({
    getMaskRequest: (payload) =>
        dispatch(CardManagementActions.getMaskRequest(payload)),
    clearMaskData: (payload) =>
        dispatch(CardManagementActions.clearMaskData(payload)),
});

export default memo(connect(null, mapDispatchToProps)(CardRender));
