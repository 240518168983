import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import { Autocomplete, Skeleton } from "@material-ui/lab";

const JOB_TITLE_DEFAULT = "Other";

const ProfileJobForm = ({
    successMessage,
    isUpdating,
    onSubmitForm,
    selected,
    errorMessage,
    isEdit,
    toggleEdit,
    list,
}) => {
    const classes = useProfileSharedClasses();

    const { t } = useTranslation();

    const defaultJobTitle = useMemo(() => {
        return list?.find((i) => i?.value === JOB_TITLE_DEFAULT);
    }, [list]);

    const selectedJobTitle = useMemo(() => {
        if (!selected) {
            return null;
        }
        return list?.find((i) => i?.value === selected);
    }, [selected, list]);

    const [jobTitle, setJobTitle] = useState(selectedJobTitle);

    const [error, setError] = useState(errorMessage || "");

    const jobTitleName = useMemo(() => {
        if (!selected) {
            return t("profile_edit_job_title_notupdate");
        }
        const currentJobTitle = list?.filter((item) => item.value === selected);

        return currentJobTitle[0]?.name || defaultJobTitle?.name;
    }, [list, selected, defaultJobTitle, t]);

    const toggleEditHandler = useCallback(
        (e) => {
            e.preventDefault();
            setJobTitle(selectedJobTitle);
            toggleEdit(PROFILE_FORM_TYPES.JOB_TITLE);
        },
        [selectedJobTitle, toggleEdit]
    );

    const onChangeHandler = ({ value }) => {
        setError("");
        setJobTitle(value);
    };

    const onSubmitHandler = useCallback(
        (e) => {
            e.preventDefault();
            if (!jobTitle) {
                setError(t("msg_we_need_this"));
                return;
            }

            onSubmitForm(jobTitle);
        },
        [jobTitle, onSubmitForm, t]
    );

    return (
        <form className={classes.formWrapper} onSubmit={onSubmitHandler}>
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-jobtitle ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("user_profile_job_title_txt")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {!isUpdating && jobTitleName}
                        {isUpdating && (
                            <Skeleton
                                width={170}
                                style={{ display: "inline-block" }}
                            />
                        )}
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    <ProfileEditComponent
                        isEdit={isEdit}
                        onEdit={toggleEditHandler}
                        onCancel={toggleEditHandler}
                        updating={isUpdating}
                    />
                </div>
            </div>

            {isEdit && (
                <div className={classes.formDetail}>
                    <Autocomplete
                        options={list}
                        getOptionLabel={(option) => option?.name || ""}
                        disabled={isUpdating}
                        value={jobTitle}
                        onChange={(_, value) => onChangeHandler({ value })}
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("ekyc:job_title_dropdown_title")}
                                    error={!!errorMessage || !!error}
                                    helperText={errorMessage || error || " "}
                                    InputProps={inputProps}
                                />
                            );
                        }}
                    />
                </div>
            )}
        </form>
    );
};

export default memo(ProfileJobForm);
