import { memo } from "react";
import { Box, ButtonBase, Divider, fade, makeStyles } from "@material-ui/core";
import Interweave from "interweave";
import clsx from "clsx";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    item: {
        width: "100%",
        background: theme.palette.background.paper,
        "&:hover": {
            background: fade(theme.palette.neutral.grey2, 0.16),
        },
    },
    emphasize: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    unread: {
        background: fade(theme.palette.neutral.grey1, 0.16),
        "&:hover": {
            background: fade(theme.palette.neutral.grey1, 0.24),
        },
    },
    title: {},
    content: {
        color: theme.palette.neutral.grey1,
        "& p": {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    divider: {
        backgroundColor: theme.palette.background.default,
        "&.contrast": {
            backgroundColor: theme.palette.grey[200],
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

const NotificationPopoverItem = ({
    title,
    content,
    lastItem,
    read,
    onClick,
    ...othersInfo
}) => {
    const classes = useStyles();

    const onClickHandler = () => {
        onClick({
            read,
            ...othersInfo,
        });
    };

    return (
        <Box>
            <ButtonBase
                classes={{ root: classes.item }}
                className={clsx({
                    [classes.unread]: !read,
                })}
                onClick={onClickHandler}
            >
                <Box
                    px={2}
                    py={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    textAlign="left"
                    width="100%"
                >
                    <LspTypography variant="heading3" component="div">
                        <Interweave
                            attributes={{ className: classes.title }}
                            tagName="div"
                            content={title}
                        />
                    </LspTypography>

                    <LspTypography variant="body3" component="div">
                        <Interweave
                            attributes={{ className: classes.content }}
                            tagName="div"
                            content={content}
                        />
                    </LspTypography>
                </Box>
            </ButtonBase>
            <Divider className={clsx(classes.divider)} />
        </Box>
    );
};

export default memo(NotificationPopoverItem);
