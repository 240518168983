import Content from "@components/Content";
import { connect } from "react-redux";
import WealthProductListItem from "./WealthProductListItem";
import WealthProductListLoading from "./WealthProductListLoading";

const WealthProductList = ({ productList }) => {
    return (
        <Content
            boxProps={{
                display: "flex",
                flexDirection: "column",
                mt: 4,
                mx: 2,
            }}
        >
            {!productList && <WealthProductListLoading />}
            {productList?.map((p) => (
                <WealthProductListItem
                    key={p.productKey}
                    productKey={p.productKey}
                />
            ))}
        </Content>
    );
};

const mapState = (state) => ({
    productList: state.wealth.list,
});

export default connect(mapState)(WealthProductList);
