export const INSTALLMENT_STATUSES = {
    DECLINED: "declined",
    ACTIVATED: "actived",
    PENDING: "pending",
    COMPLETED: "completed",
    NEW: "new",
    AVAILABLE: "available",
    UNAVAILABLE: "unavailable",
};

export const INSTALLMENT_ACTIONS = {
    CANCEL: "CANCEL",
    DELETE_FOR_DECLINE: "DELETE_FOR_DECLINE",
    DELETE_FOR_COMPLETE: "DELETE_FOR_COMPLETE",
};
