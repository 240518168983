import WealthLoans from "@containers/Wealth/WealthLoans";
import usePathname from "@helpers/usePathname";
import { useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import WealthVinaWealth from "@containers/Wealth/WealthVinaWealth";
import {
    WEALTH_NAVIGATION_KEYS,
    WEALTH_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import useWealth from "@containers/Wealth/useWealth";
import wealthAction from "@redux/actions/wealth";

import investmentBg from "@assets/images/vinacap-bg.png";
import creditCardBg from "@assets/images/wealh_mastercard_bg.jpg";
import ComingSoon from "@components/ComingSoon";
import WealthInsurance from "@containers/Wealth/WealthInsurance";
import WealthTravel from "@containers/Wealth/WealthInsurance/WealthTravel";
import WealthMotorbike from "@containers/Wealth/WealthInsurance/WealthMotorbike";
import WealthProductList from "./WealthProductList";
import WealthCreditCard from "./WealthCreditCard";
import LifeInsurance from "./LifeInsurance";
import LifeLongProtect from "./LifeInsurance/LifeLongProtect";
import LifeInsuranceManageInstant from "./LifeInsurance/LifeInsuranceInstant";
import InstantCoverage from "./LifeInsurance/InstantCoverage";

const useStyles = makeStyles(() => ({
    wealthContainer: {
        minHeight: "calc(100vh - 165px)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "left bottom",
    },
}));

const parentName = WEALTH_NAVIGATION_KEYS.ROOT;
const dashboardUrl = `${parentName}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`;

const Wealth = ({ productList, getProductList, systemParams }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const { getWealthPath } = useWealth();

    const { generateChildPathname } = usePathname();

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.DASHBOARD
                ),
                component: WealthProductList,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.LOANS
                ),
                component: WealthLoans,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.VINA_WEALTH
                ),
                component: WealthVinaWealth,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.CREDIT_CARD
                ),
                component: WealthCreditCard,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE
                ),
                component: LifeInsurance,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE_LONG_PROTECT
                ),
                component: LifeLongProtect,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE_MANAGE_INSTANT_COVERAGE
                ),
                component: LifeInsuranceManageInstant,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE_INSTANT_COVERAGE
                ),
                component: InstantCoverage,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.GENERAL_INSURANCE
                ),
                component: WealthInsurance,
            },
            {
                path: generateChildPathname(
                    parentName,
                    `${WEALTH_NAVIGATION_KEYS.GENERAL_INSURANCE}/${WEALTH_NAVIGATION_KEYS.TRAVEL_INSURANCE}`
                ),
                component: WealthTravel,
            },
            {
                path: generateChildPathname(
                    parentName,
                    `${WEALTH_NAVIGATION_KEYS.GENERAL_INSURANCE}/${WEALTH_NAVIGATION_KEYS.MOTORBIKE_INSURANCE}`
                ),
                component: WealthMotorbike,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    const getProductKeyByPath = useCallback(
        (path) => {
            if (!productList) return null;

            for (let i = 0; i < productList.length; i++) {
                const item = productList[i];
                const itemPath = getWealthPath(item.productKey);
                if (itemPath === path) {
                    return item.productKey;
                }
            }
            return null;
        },
        [productList, getWealthPath]
    );

    const isFeatureComingSoon = useMemo(() => {
        let comingSoonList = systemParams.ComingSoonWeathProducts;
        const productKey = location.pathname.includes(dashboardUrl)
            ? ""
            : getProductKeyByPath(location.pathname);
        if (comingSoonList !== "") {
            comingSoonList = comingSoonList.split(",");
            return comingSoonList.indexOf(productKey) > -1;
        }
        return false;
    }, [systemParams, location.pathname, getProductKeyByPath]);

    const contentInfo = useMemo(() => {
        const productKey = location.pathname.includes(dashboardUrl)
            ? ""
            : getProductKeyByPath(location.pathname);
        switch (productKey) {
            case WEALTH_PRODUCT_KEYS.LOANS:
                return {
                    title: t("wealth:OV00001"),
                    subtitle: t("wealth:VL00001"),
                    background: "",
                };
            case WEALTH_PRODUCT_KEYS.LIFE_INSURANCE:
                return {
                    title: t("wealth:SI00107"),
                    subtitle: t("wealth:SI00108"),
                    background: "",
                };
            case WEALTH_PRODUCT_KEYS.VINA_WEALTH_V2:
            case WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3:
                return {
                    title: t("wealth:OV00001"),
                    subtitle: t("wealth:OV00002"),
                    background: investmentBg,
                };
            case WEALTH_PRODUCT_KEYS.CARD:
            case WEALTH_PRODUCT_KEYS.MASTERCARD:
            case WEALTH_PRODUCT_KEYS.MASTERCARD3:
            case WEALTH_PRODUCT_KEYS.CREDIT_CARD_V4:
                return {
                    title: t("wealth:OV00001"),
                    subtitle: t("wealth:OV00002"),
                    background: creditCardBg,
                };
            default:
                return {
                    title: t("wealth:OV00001"),
                    subtitle: t("wealth:OV00002"),
                    background: "",
                };
        }
    }, [location.pathname, getProductKeyByPath, t]);

    useEffect(() => {
        getProductList();
    }, [getProductList]);

    return (
        <>
            <Banner
                title={contentInfo?.title}
                subtitle={contentInfo?.subtitle}
                // navbarItems={navbarItems}
                // disabledNavbarItem={isDisabledNavbarItems}
            />
            <ContentWrapper
                noPaddingX
                noPaddingY
                className={classes.wealthContainer}
                style={{ backgroundImage: `url(${contentInfo?.background})` }}
            >
                {!isFeatureComingSoon && (
                    <Switch>
                        {contentRoutes.map((r) => {
                            return (
                                <Route
                                    key={r.path}
                                    path={r.path}
                                    component={r.component}
                                />
                            );
                        })}
                        {!!defaultRoutes && (
                            <Route
                                component={() => (
                                    <Redirect to={defaultRoutes.path} />
                                )}
                            />
                        )}
                    </Switch>
                )}

                {isFeatureComingSoon && (
                    <ComingSoon content={t("wealth:coming_soon")} />
                )}
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    productList: state.wealth.list,
    systemParams: state.systemParams.info.sysparams["1.0"],
});

const mapDispatch = (dispatch) => ({
    getProductList: () => dispatch(wealthAction.getProductListRequest()),
});

export default connect(mapState, mapDispatch)(Wealth);
