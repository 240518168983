/* eslint-disable react/no-array-index-key */
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";

import parse from "date-fns/parse/index";
import format from "date-fns/format";
import { connect } from "react-redux";
import bookingActions from "@redux/actions/booking";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
        "& > label": {
            display: "block",
            fontWeight: 600,
        },
    },
    circle: {
        width: 6,
        height: 6,
        borderRadius: "50%",
        background: "black",
        display: "block",
        position: "absolute",
        top: theme.spacing(-0.25),
        right: theme.spacing(-1.25),
    },
    error: {
        background: theme.palette.status.error,
    },
    warning: {
        background: theme.palette.status.pending,
    },
    header: {
        fontWeight: 600,
        fontSize: 16,
    },
}));

const propertiesIndex = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const LspTimeHeader = ({
    bookingList,
    selectedDate,
    updateSelectedDate,
    updateSelectedTime,
    statusList,
}) => {
    const classes = useStyles();
    const selectedDateIndex = useMemo(() => {
        const index = bookingList?.findIndex(
            (item) => item?.day === selectedDate?.day
        );

        return index >= 0 ? index : 0;
    }, [selectedDate, bookingList]);

    const onSelectDate = useCallback(
        (selectedIndex) => {
            const dateInfo = bookingList?.find(
                (_, index) => index === selectedIndex
            );
            updateSelectedDate(dateInfo);
            updateSelectedTime({});
        },
        [bookingList, updateSelectedDate, updateSelectedTime]
    );

    const isDayOff = useCallback(
        (item) => item?.status === statusList.NON_OPERATIONAL,
        [statusList]
    );

    const isFullSlot = useCallback(
        (item) => {
            if (item?.status === statusList.NON_OPERATIONAL) return false;

            const isSlotAvailable = item?.slots?.find(
                (slot) => slot.remainingCapacity > 0
            );

            return !isSlotAvailable;
        },
        [statusList]
    );

    return (
        <Tabs
            value={selectedDateIndex}
            onChange={(_, value) => onSelectDate(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs date"
        >
            {bookingList?.map((item, index) => {
                return (
                    <Tab
                        key={`item-${item.day}`}
                        label={
                            <>
                                <div
                                    htmlFor={`label-${item.day}`}
                                    className={classes.wrapper}
                                >
                                    <span className={classes.header}>
                                        {format(
                                            parse(
                                                item.day,
                                                "yyyy-MM-dd",
                                                new Date()
                                            ),
                                            "EEEE"
                                        )}
                                    </span>
                                    {isDayOff(item) && (
                                        <span
                                            className={`${classes.circle} ${classes.error}`}
                                        />
                                    )}
                                    {isFullSlot(item) && (
                                        <span
                                            className={`${classes.circle} ${classes.warning}`}
                                        />
                                    )}
                                </div>
                                <div>
                                    {format(
                                        parse(
                                            item.day,
                                            "yyyy-MM-dd",
                                            new Date()
                                        ),
                                        "dd/MM/yyyy"
                                    )}
                                </div>
                            </>
                        }
                        {...propertiesIndex(index)}
                    />
                );
            })}
        </Tabs>
    );
};

const mapState = (state) => ({
    bookingList: state.booking.bookingList,
    selectedDate: state.booking.selectedDate,
});

const mapDispatch = (dispatch) => ({
    updateSelectedDate: (payload) =>
        dispatch(bookingActions.setSelectedDate(payload)),
    updateSelectedTime: (payload) =>
        dispatch(bookingActions.setSelectedTime(payload)),
});

export default memo(connect(mapState, mapDispatch)(LspTimeHeader));
