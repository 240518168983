import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        flexWrap: "wrap",
    },
    leftSide: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        order: 2,
        flex: 1,
        [theme.breakpoints.up("md")]: {
            order: 0,
        },
        borderRight: `1px solid ${theme.palette.neutral.grey3}`,
        minHeight: "calc(100vh - 176px)",
        height: "auto",
        marginBottom: theme.spacing(4),
    },
    rightSide: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "35%",
            maxWidth: 555,
            paddingLeft: theme.spacing(2),
        },
        marginBottom: theme.spacing(4),
    },
    aboutButton: {
        textTransform: "uppercase",
        borderTop: `1px solid ${theme.palette.neutral.grey4}`,
        paddingTop: theme.spacing(3),
        "& p": {
            color: theme.palette.status.success,
        },
    },
    box: {
        padding: theme.spacing(4, 2, 2),
        marginBottom: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(3),
    },
    formHeader: {
        background: theme.palette.background.header,
        padding: theme.spacing(1, 2),
    },
    formBody: {
        padding: theme.spacing(2),
    },
}));
