/* ------------- Types Definition ------------- */
export const TopUpActionType = Object.freeze({
    Reset: "TopUpActionType/Reset",
    GetPhoneConfigs: "TopUpActionType/GetPhoneConfigs",
    GetPhoneConfigsSuccess: "TopUpActionType/GetPhoneConfigsSuccess",
    GetPhoneConfigsError: "TopUpActionType/GetPhoneConfigsError",
    GetContactList: "TopUpActionType/GetContactList",
    GetContactListSuccess: "TopUpActionType/GetContactListSuccess",
    GetContactListError: "TopUpActionType/GetContactListError",
    ResetAddNewPhoneInfo: "TopUpActionType/resetAddNewPhoneInfo",
    ResetExistingPhoneScreen: "TopUpActionType/ResetExistingPhoneScreen",
    UpdatePhoneNumber: "TopUpActionType/UpdatePhoneNumber",
    UpdateAmount: "TopUpActionType/UpdateAmount",
    UpdatePayeeName: "TopUpActionType/UpdatePayeeName",
    UpdateSelectedPhoneObject: "TopUpActionType/UpdateSelectedPhoneObject",
    TopUpRequest: "TopUpActionType/TopUpRequest",
    TopUpError: "TopUpActionType/TopUpError",
    TopUpSuccess: "TopUpActionType/TopUpSuccess",
    OTPCommit: "TopUpActionType/OTPCommit",
    OTPCancel: "TopUpActionType/OTPCancel",
    TopUpWithOTP: "TopUpActionType/TopUpWithOTP",
    DeletePhone: "TopUpActionType/DeletePhone",
    DeletePhoneSuccess: "TopUpActionType/DeletePhoneSuccess",
    DeletePhoneError: "TopUpActionType/DeletePhoneError",
    UpdateHasSavePayee: "TopUpActionType/UpdateHasSavePayee",
    GetReceipt: "TopUpActionType/GetReceipt",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: TopUpActionType.Reset,
    };
};
const getContactList = () => {
    return {
        type: TopUpActionType.GetContactList,
    };
};
const getContactListSuccess = (payload) => {
    return {
        type: TopUpActionType.GetContactListSuccess,
        payload,
    };
};
const getContactListError = () => {
    return {
        type: TopUpActionType.GetContactListError,
    };
};
const getPhoneConfigs = () => {
    return {
        type: TopUpActionType.GetPhoneConfigs,
    };
};
const getPhoneConfigsSuccess = (payload) => {
    return {
        type: TopUpActionType.GetPhoneConfigsSuccess,
        payload,
    };
};
const getPhoneConfigsError = () => {
    return {
        type: TopUpActionType.GetPhoneConfigsError,
    };
};
const updatePhoneNumber = (payload) => {
    return {
        type: TopUpActionType.UpdatePhoneNumber,
        payload,
    };
};
const updateAmount = (payload) => {
    return {
        type: TopUpActionType.UpdateAmount,
        payload,
    };
};
const updatePayeeName = (payload) => {
    return {
        type: TopUpActionType.UpdatePayeeName,
        payload,
    };
};
const updateHasSavePayee = (payload) => {
    return {
        type: TopUpActionType.UpdateHasSavePayee,
        payload,
    };
};
const updateSelectedPhoneObject = (payload) => {
    return {
        type: TopUpActionType.UpdateSelectedPhoneObject,
        payload,
    };
};

const topUpRequest = (payload) => {
    return {
        type: TopUpActionType.TopUpRequest,
        payload,
    };
};
const topUpWithOTP = (payload) => {
    return {
        type: TopUpActionType.TopUpWithOTP,
        payload,
    };
};

const topUpError = () => {
    return {
        type: TopUpActionType.TopUpError,
    };
};
const topUpSuccess = (payload) => {
    return {
        type: TopUpActionType.TopUpSuccess,
        payload,
    };
};

const OTPCommit = (payload) => {
    return {
        type: TopUpActionType.OTPCommit,
        payload,
    };
};
const deletePhone = (payload) => {
    return {
        type: TopUpActionType.DeletePhone,
        payload,
    };
};
const deletePhoneSuccess = () => {
    return {
        type: TopUpActionType.DeletePhoneSuccess,
    };
};
const deletePhoneError = () => {
    return {
        type: TopUpActionType.DeletePhoneError,
    };
};
const resetAddNewPhoneInfo = () => {
    return {
        type: TopUpActionType.ResetAddNewPhoneInfo,
    };
};
const resetExistingPhoneScreen = () => {
    return {
        type: TopUpActionType.ResetExistingPhoneScreen,
    };
};
const OTPCancel = () => {
    return {
        type: TopUpActionType.OTPCancel,
    };
};

const topUpAction = {
    reset,
    getContactList,
    getContactListSuccess,
    getContactListError,
    getPhoneConfigs,
    getPhoneConfigsSuccess,
    getPhoneConfigsError,
    updatePhoneNumber,
    updateAmount,
    updatePayeeName,
    updateSelectedPhoneObject,
    topUpRequest,
    topUpWithOTP,
    topUpError,
    topUpSuccess,
    OTPCommit,
    deletePhone,
    deletePhoneSuccess,
    deletePhoneError,
    updateHasSavePayee,
    resetAddNewPhoneInfo,
    resetExistingPhoneScreen,
    OTPCancel,
};

export default topUpAction;
