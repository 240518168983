import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import useNumber from "@helpers/useNumber";
import PaymentImageTransfer from "@containers/PaymentRequest/PaymentImageTransfer";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.typography.pxToRem(18),
            "& > span": {
                fontWeight: 600,
            },
        },
        amount: {
            fontSize: theme.typography.pxToRem(30),
            padding: theme.spacing(2.75, 7),
            margin: theme.spacing(3.75, 0),
            border: `1px solid ${theme.palette.divider}`,
            fontWeight: 400,
            borderLeft: "none",
            borderRight: "none",
            width: "100%",
        },
        imgTransfer: {
            display: "flex",
            alignItems: "center",
            margin: theme.spacing(3, 0, 1.25, 0),
        },
        reason: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        },
    };
});
const PaymentRequestDetail = ({ info, isSent }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const name = useMemo(() => {
        return isSent ? info?.receiveName : info?.userSentName;
    }, [isSent, info]);

    const title = useMemo(() => {
        return isSent
            ? t("payment_title_request_sent_to")
            : t("payment_title_request_receive_from");
    }, [isSent, t]);

    return (
        <>
            <Box className={classes.title}>
                <LspTypography variant="title1">{title}</LspTypography>
                <div>{title} </div>
                <span> {name}</span>
            </Box>
            <PaymentImageTransfer avatar={info?.avatar} isSent={isSent} />
            <Box>
                <LspTypography color="grey" variant="body2">
                    {info?.modifyDate}
                </LspTypography>
            </Box>
            <Box className={classes.amount}>{formatNumber(info?.amount)}</Box>
            {info?.reason && (
                <Box className={classes.reason}>
                    <div>
                        <LspTypography variant="heading4">
                            {t("payment_title_reason_for_request")}
                        </LspTypography>
                    </div>
                    <span>
                        <LspTypography variant="body2">
                            {info?.reason}
                        </LspTypography>
                    </span>
                </Box>
            )}
        </>
    );
};

export default memo(PaymentRequestDetail);
