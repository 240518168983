import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { makeStyles } from "@material-ui/core";
import { memo, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    paymentHeader: {},
    paymentHeaderTitle: {
        textAlign: "center",
    },
    paymentHeaderAmount: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    paymentHeaderDescription: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    centerAlignItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
}));

const PaymentHeader = ({ payment, fetching }) => {
    const classes = useStyles();

    const { formatNumber } = useNumber();
    const { t } = useTranslation();

    const transferAmount = (amount = "0") => {
        return t("debitCard:reissuecard_review_app_service_charge_amt").replace(
            "%@",
            amount
        );
    };

    const fetchingLayer = useMemo(
        () => (
            <div
                className={`${classes.paymentHeader} ${classes.centerAlignItem}`}
            >
                <Skeleton animation="wave" height={20} width={100} />
                <Skeleton
                    animation="wave"
                    height={30}
                    width={200}
                    style={{ marginBottom: 16 }}
                />
                <Skeleton animation="wave" height={25} width={300} />
                <Skeleton
                    animation="wave"
                    height={25}
                    width={150}
                    style={{ marginBottom: 8 }}
                />
            </div>
        ),
        [classes.centerAlignItem, classes.paymentHeader]
    );

    return (
        <>
            {fetching && fetchingLayer}
            {!fetching && (
                <div className={classes.paymentHeader}>
                    <LspTypography
                        className={classes.paymentHeaderTitle}
                        variant="body1"
                        color="black"
                    >
                        {t("splitBill:sb_web_title")}
                    </LspTypography>
                    <LspTypography
                        className={classes.paymentHeaderAmount}
                        variant="title1"
                        color="purple"
                    >
                        {transferAmount(formatNumber(payment?.amount))}
                    </LspTypography>

                    <LspTypography
                        className={classes.paymentHeaderAmount}
                        variant="subheading1"
                        color="black"
                    >
                        {t("splitBill:sb_web_msg_pay_method")}
                    </LspTypography>
                </div>
            )}
        </>
    );
};

PaymentHeader.propTypes = {
    fetching: PropTypes.bool,
};

PaymentHeader.defaultProps = {
    fetching: false,
};

const stateProps = (state) => ({
    payment: state.bill.splitBillPayment.payment,
    fetching: state.bill.splitBillPayment.fetching,
});

export default memo(connect(stateProps)(PaymentHeader));
