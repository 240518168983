import produce from "immer";
import { OverdraftActionType } from "@redux/actions/overdraft";
import { FetchStatus } from "../../config/constants";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    detail: null,
};

/* ------------- Reducer ------------- */
const overdraftReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case OverdraftActionType.Reset:
                draft.fetching = false;
                draft.detail = null;
                break;
            /* ------------- Get overdraft detail ------------- */
            case OverdraftActionType.GetOverdraftDetail:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case OverdraftActionType.GetOverdraftDetailSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.detail = action.payload;
                break;
            case OverdraftActionType.GetOverdraftDetailError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default overdraftReducer;
