import LspTypography from "@components/LspTypography";
import { Box, Divider, Icon } from "@material-ui/core";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LspBox from "@components/LspBox";
import ProfileJobForm from "@containers/Profile/ProfileAccountPersonalInfo/ProfileJobForm";
import ProfileOccupationForm from "@containers/Profile/ProfileAccountPersonalInfo/ProfileOccupationForm";
import { DATA_DROPDOWN_TYPES, RESPONSE_CODE } from "@config/constants";
import userService from "@services/user";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";

import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import ProfileMaritalStatusForm from "@containers/Profile/ProfileAccountPersonalInfo/ProfileMaritalStatusForm";
import ProfileAddressForm from "@containers/Profile/ProfileAccountPersonalInfo/ProfileAddressForm";
import i18n from "@i18n/";
import ProfileFetchingForm from "@containers/Profile/ProfileFetchingForm";
import ProfileTaxForm from "./ProfileTaxForm";

const isEditInitialize = {
    address: "",
    maritalStatus: "",
    occupation: "",
    jobTitle: "",
    tax: "",
};
const ProfileAccountPersonalInfo = ({ user }) => {
    const classes = useProfileSharedClasses();
    const unmounted = useRef(false);
    const { t } = useTranslation();
    const firstLoaded = useRef(false);

    const formDataInitiation = useMemo(
        () => ({
            address: {},
            maritalId: "",
            jobCurrent: "",
            jobTitle: "",
            tax: "",
        }),
        []
    );

    const [formData, setFormData] = useState(
        {
            address: {
                addressName: user?.address.addressName,
                wardId: user?.address.wardId,
                wardName: user?.address.wardName,
                distId: user?.address.distId,
                distName: user?.address.distName,
                cityId: user?.address.cityId,
                cityName: user?.address.cityName,
            },
            maritalId: user?.maritalId,
            jobCurrent: user?.jobCurrent,
            jobTitle: user?.jobTitle,
            tax: user?.tax,
        },
        [user]
    );

    const [isFetchingList, setIsFetchingList] = useState({
        maritalStatus: true,
        occupation: true,
        jobTitle: true,
    });

    const [occupationList, setOccupationList] = useState([]);
    const [jobTitleList, setJobTitleList] = useState([]);
    const [maritalList, setMaritalList] = useState([]);

    const [isUpdating, setIsUpdating] = useState({
        address: false,
        maritalStatus: false,
        occupation: false,
        jobTitle: false,
    });
    const [successMessage, setSuccessMessage] = useState({
        address: "",
        maritalStatus: "",
        occupation: "",
        jobTitle: "",
    });
    const [errorMessage, setErrorMessage] = useState({
        address: "",
        maritalStatus: "",
        occupation: "",
        jobTitle: "",
    });

    const [isEdit, setIsEdit] = useState({
        address: "",
        maritalStatus: "",
        occupation: "",
        jobTitle: "",
    });

    const toggleEditHandler = (type) => {
        setIsEdit((prev) => ({
            ...isEditInitialize,
            [type]: !prev?.[type],
        }));
        setSuccessMessage((prev) => ({
            ...prev,
            [type]: "",
        }));
        setErrorMessage((prev) => ({
            ...prev,
            [type]: "",
        }));
    };

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onSubmit = useCallback(
        async (payload, type, callback) => {
            setIsUpdating((prev) => ({
                ...prev,
                [type]: true,
            }));

            const response = await userService.updateProfile(payload);

            if (unmounted.current) return;

            setIsUpdating((prev) => ({
                ...prev,
                [type]: false,
            }));

            const { code, data } = response.data;

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setSuccessMessage((prev) => ({
                        ...prev,
                        [type]: t("profile_msg_update"),
                    }));
                    setIsEdit((prev) => ({
                        ...prev,
                        [type]: false,
                    }));
                    if (callback) {
                        callback();
                    }
                    break;
                case RESPONSE_CODE.DATABASE_ERROR:
                    if (
                        type === PROFILE_FORM_TYPES.OCCUPATION ||
                        type === PROFILE_FORM_TYPES.MARITAL_STATUS
                    ) {
                        setErrorMessage((prev) => ({
                            ...prev,
                            [type]: data.message,
                        }));
                    }
                    break;
                default:
                    const message = data?.message
                        ? data?.message
                        : t("up_lb_update_address_failure");
                    setErrorMessage((prev) => ({
                        ...prev,
                        [type]: message,
                    }));
                    break;
            }
        },
        [t]
    );
    const onSubmitAddress = useCallback(
        (address) => {
            setFormData((prev) => ({
                ...prev,
                address,
            }));
            onSubmit(
                {
                    ...formDataInitiation,
                    address: {
                        addressName: address.addressName,
                        wardId: address.wardId,
                        distId: address.distId,
                        cityId: address.cityId,
                    },
                },
                PROFILE_FORM_TYPES.ADDRESS
            );
        },
        [formDataInitiation, onSubmit]
    );

    const onSubmitMaritalStatus = useCallback(
        (maritalStatus) => {
            setFormData((prev) => ({
                ...prev,
                maritalId: maritalStatus,
            }));
            onSubmit(
                { ...formDataInitiation, maritalId: maritalStatus },
                PROFILE_FORM_TYPES.MARITAL_STATUS
            );
        },
        [formDataInitiation, onSubmit]
    );

    const onSubmitOccupation = useCallback(
        (occupation) => {
            onSubmit(
                { ...formDataInitiation, occupation: occupation?.value },
                PROFILE_FORM_TYPES.OCCUPATION,
                () => {
                    setFormData((prev) => ({
                        ...prev,
                        jobCurrent: occupation?.value,
                    }));
                }
            );
        },
        [formDataInitiation, onSubmit]
    );

    const onSubmitJobTitle = useCallback(
        (job) => {
            onSubmit(
                { ...formDataInitiation, jobTitle: job?.value },
                PROFILE_FORM_TYPES.JOB_TITLE,
                () => {
                    setFormData((prev) => ({
                        ...prev,
                        jobTitle: job?.value,
                    }));
                }
            );
        },
        [formDataInitiation, onSubmit]
    );

    const onSubmitTax = useCallback(
        (tax) => {
            onSubmit(
                { ...formDataInitiation, tax },
                PROFILE_FORM_TYPES.TAX,
                () => {
                    setFormData((prev) => ({
                        ...prev,
                        tax,
                    }));
                }
            );
        },
        [formDataInitiation, onSubmit]
    );

    const getOccupationList = useCallback(async () => {
        const response = await userService.getJobList({
            lang: i18n.language,
            type: DATA_DROPDOWN_TYPES.OCCUPATION,
        });

        if (unmounted.current) return;

        setIsFetchingList((prev) => ({
            ...prev,
            occupation: false,
        }));

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setOccupationList(data);
                break;
            default:
                break;
        }
    }, []);

    const getJobTitleList = useCallback(async () => {
        const response = await userService.getJobList({
            lang: i18n.language,
            type: DATA_DROPDOWN_TYPES.JOB_TITLE,
        });

        if (unmounted.current) return;

        setIsFetchingList((prev) => ({
            ...prev,
            jobTitle: false,
        }));

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setJobTitleList(data);
                break;
            default:
                break;
        }
    }, []);

    const getMaritalList = useCallback(async () => {
        const response = await userService.getDataList({
            group: DATA_DROPDOWN_TYPES.MARITAL_STATUS,
            configKey: "",
            parentId: "",
            language: i18n.language,
        });

        if (unmounted.current) return;

        setIsFetchingList((prev) => ({
            ...prev,
            maritalStatus: false,
        }));

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setMaritalList(data);
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (!firstLoaded.current) {
            getOccupationList();
            getJobTitleList();
            getMaritalList();
            firstLoaded.current = true;
        }
    }, [getOccupationList, getJobTitleList, getMaritalList]);

    return (
        <LspBox className={classes.wrapperBox}>
            <Box className={classes.headerWrapper}>
                <Icon
                    className={`font-icon icon-personalinfo ${classes.headerIcon}`}
                />
                <LspTypography variant="title2" className={classes.header}>
                    {t("user_profile_personal_info_lb")}
                </LspTypography>
            </Box>

            <Divider className={classes.divider} />

            <ProfileAddressForm
                successMessage={successMessage?.address}
                isUpdating={isUpdating?.address}
                onSubmitForm={onSubmitAddress}
                selected={formData?.address}
                errorMessage={errorMessage?.address}
                isEdit={isEdit?.address}
                toggleEdit={toggleEditHandler}
            />

            <Divider className={classes.divider} />

            {isFetchingList?.maritalStatus && <ProfileFetchingForm />}

            {!isFetchingList?.maritalStatus && (
                <ProfileMaritalStatusForm
                    successMessage={successMessage?.maritalStatus}
                    isUpdating={isUpdating?.maritalStatus}
                    onSubmitForm={onSubmitMaritalStatus}
                    selected={formData?.maritalId}
                    errorMessage={errorMessage?.maritalStatus}
                    isEdit={isEdit?.maritalStatus}
                    toggleEdit={toggleEditHandler}
                    list={maritalList}
                />
            )}

            <Divider className={classes.divider} />

            {isFetchingList?.occupation && <ProfileFetchingForm />}

            {!isFetchingList?.occupation && (
                <ProfileOccupationForm
                    successMessage={successMessage?.occupation}
                    isUpdating={isUpdating?.occupation}
                    onSubmitForm={onSubmitOccupation}
                    selected={formData?.jobCurrent}
                    errorMessage={errorMessage?.occupation}
                    isEdit={isEdit?.occupation}
                    toggleEdit={toggleEditHandler}
                    list={occupationList}
                />
            )}

            <Divider className={classes.divider} />

            <ProfileJobForm
                successMessage={successMessage?.jobTitle}
                isUpdating={isUpdating?.jobTitle}
                onSubmitForm={onSubmitJobTitle}
                selected={formData?.jobTitle}
                isEdit={isEdit?.jobTitle}
                errorMessage={errorMessage?.jobTitle}
                toggleEdit={toggleEditHandler}
                list={jobTitleList}
            />

            <Divider className={classes.divider} />

            <ProfileTaxForm
                successMessage={successMessage?.tax}
                isUpdating={isUpdating?.tax}
                onSubmitForm={onSubmitTax}
                currentValue={formData?.tax}
                isEdit={isEdit?.tax}
                errorMessage={errorMessage?.tax}
                toggleEdit={toggleEditHandler}
            />
        </LspBox>
    );
};

export default memo(ProfileAccountPersonalInfo);
