import Content from "@components/Content";
import LspCard from "@components/LspCard";
import { ALIAS_TYPE } from "@config/constants";
import { makeStyles } from "@material-ui/core";
import userAction from "@redux/actions/user";
import Interweave from "interweave";
import { isEmpty } from "lodash";
import { useEffect, memo, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import LspHintInfo from "@components/LspHintInfo";
import AccountSettingItems from "./AccountSettingItems";
import NotFound from "./NotFound";

const useStyles = makeStyles((theme) => ({
    accountSettingList: {},
    accountSettingItem: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 0),
        "&:last-child": {
            marginBottom: 0,
        },
    },
}));

const AccountSettings = ({
    alias,
    fetchAliasData,
    fetchingAlias,
    featureState,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const skeletonList = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

    const enableAliasNickName = useMemo(() => {
        return featureState?.enableAliasNickName;
    }, [featureState?.enableAliasNickName]);

    useEffect(() => {
        if (!featureState?.enableAliasPhase2) {
            history.push("/home");
        }
    }, [featureState?.enableAliasPhase2, history]);

    useEffect(() => {
        if (isEmpty(alias)) {
            fetchAliasData();
        }
    }, [alias, fetchAliasData]);

    const isLoading = useMemo(() => fetchingAlias && alias.length === 0, [
        alias.length,
        fetchingAlias,
    ]);

    const aliasType = useCallback(
        (aliasItem) => {
            switch (aliasItem.aliasType) {
                case ALIAS_TYPE.PHONE_NUMBER:
                    return (
                        <AccountSettingItems
                            checked={aliasItem.status}
                            description={
                                <Interweave
                                    content={t(
                                        "IBFT:IBFT_alias_setting_desc_PhoneNumber"
                                    ).replace("%@", aliasItem?.alias)}
                                />
                            }
                            type={ALIAS_TYPE.PHONE_NUMBER}
                            item={aliasItem}
                            fetching={isLoading}
                        />
                    );
                case ALIAS_TYPE.ALIAS_NICKNAME:
                    return (
                        <AccountSettingItems
                            checked={aliasItem.status}
                            description={
                                <Interweave
                                    content={t(
                                        "IBFT:IBFT_alias_setting_desc_nickname_web"
                                    ).replace("%@", aliasItem?.alias)}
                                />
                            }
                            type={ALIAS_TYPE.PHONE_NUMBER}
                            item={aliasItem}
                            fetching={isLoading}
                        />
                    );

                default:
                    break;
            }
        },
        [isLoading, t]
    );

    const cardList = useCallback(
        (aliasItem) => {
            switch (aliasItem.aliasType) {
                case ALIAS_TYPE.PHONE_NUMBER:
                    return (
                        <LspCard
                            title={t(
                                "IBFT:IBFT_alias_setting_title_PhoneNumber"
                            )}
                            key={aliasItem.id}
                        >
                            <div className={classes.accountSettingItem}>
                                {aliasType(aliasItem)}
                            </div>
                        </LspCard>
                    );
                case ALIAS_TYPE.ALIAS_NICKNAME:
                    return (
                        <>
                            {enableAliasNickName && (
                                <LspCard
                                    title={t(
                                        "IBFT:IBFT_alias_setting_title_NickName"
                                    )}
                                    key={aliasItem.id}
                                >
                                    <div className={classes.accountSettingItem}>
                                        {aliasType(aliasItem)}
                                    </div>
                                </LspCard>
                            )}
                        </>
                    );
                default:
                    break;
            }
        },
        [aliasType, classes.accountSettingItem, enableAliasNickName, t]
    );

    return (
        <>
            <Content>
                <div className={classes.accountSettingList}>
                    {(alias || []).map((aliasItem) => cardList(aliasItem))}
                    <LspHintInfo content={t("IBFT:IBFT_alias_setting_hint")} />
                    {isLoading &&
                        skeletonList.map((item) => (
                            <AccountSettingItems
                                fetching
                                className={classes.accountSettingItem}
                                key={item.id}
                            />
                        ))}
                    {!isLoading && alias.length === 0 && <NotFound />}
                </div>
            </Content>
        </>
    );
};

const stateProps = (state) => ({
    alias: state.user.alias.data,
    fetchingAlias: state.user.alias.fetching,
    aliasStatus: state.user.alias.status,
    toggleAlias: state.user.toggleAlias.fetching,
    featureState: state.user.featureState.data,
});

const dispatchProps = (dispatch) => ({
    fetchAliasData: () => dispatch(userAction.getAliasRequest()),
    toggleAliasRequest: (payload) =>
        dispatch(userAction.toggleAliasRequest(payload)),
});
export default memo(connect(stateProps, dispatchProps)(AccountSettings));
