import LspButton from "@components/LspButton";
import LspCheckbox from "@components/LspCheckbox";
import { DeleteIcon, RefreshIcon } from "@components/LspIcon";
import { Box } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_CHECKBOX_TYPES } from "./constant";

const NotificationListActions = ({
    toggleAll,
    selectedAll,
    notificationListFetching,
    isShowDeleteButton,
    removeSelectedItems,
    checkboxStyle,
    refresh,
}) => {
    const { t } = useTranslation();

    return (
        <Box display="flex">
            <Box
                py={1}
                px={0.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <LspCheckbox
                    onChange={toggleAll}
                    checked={selectedAll !== NOTIFICATION_CHECKBOX_TYPES.NO}
                    className={checkboxStyle}
                    indeterminate={
                        selectedAll === NOTIFICATION_CHECKBOX_TYPES.APART
                    }
                    disabled={notificationListFetching}
                />
            </Box>
            <Box
                paddingY={2}
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
            >
                {!isShowDeleteButton && (
                    <LspButton
                        variant="secondary"
                        size="small"
                        buttonProps={{
                            startIcon: <RefreshIcon />,
                        }}
                        disabled={notificationListFetching}
                        onClick={refresh}
                    >
                        {t("lb_refresh")}
                    </LspButton>
                )}
                {isShowDeleteButton && (
                    <LspButton
                        variant="secondary"
                        size="small"
                        buttonProps={{
                            startIcon: <DeleteIcon />,
                        }}
                        onClick={removeSelectedItems}
                        disabled={notificationListFetching}
                    >
                        {t("payment_lb_btn_delete")}
                    </LspButton>
                )}
            </Box>
        </Box>
    );
};

export default memo(NotificationListActions);
