import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import {
    BOOKING_ACTIONS,
    BOOKING_SERVICES,
    BOOKING_TYPES,
} from "@containers/Booking/constant";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { format, parse } from "date-fns";
import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        fontSize: 16,
    },
    body: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
}));

const CreateBookingConfirmation = ({
    onBack,
    onCancel,
    onSubmit,
    method,
    selectedHangout,
    selectedTime,
    serviceName,
    submitting,
    type,
    id,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const submitClick = useCallback(() => {
        let fromTime = selectedTime?.fromTime;
        let toTime = selectedTime?.toTime;

        if (type === BOOKING_ACTIONS.CHANGE_BOOKING) {
            const parsedFromDate =
                parse(fromTime, "yyyy-MM-dd HH:mm", new Date()) || "";
            const parsedToDate =
                parse(toTime, "yyyy-MM-dd HH:mm", new Date()) || "";

            fromTime = format(parsedFromDate, "dd/MM/yyyy HH:mm:ss");
            toTime = format(parsedToDate, "dd/MM/yyyy HH:mm:ss");
        }

        // eslint-disable-next-line prefer-const
        let dataToPost = {
            fromTime,
            toTime,
            locationId: selectedHangout?.id,
            bookingType:
                serviceName === BOOKING_SERVICES.EKYC_BOOKING
                    ? BOOKING_TYPES.KYC
                    : BOOKING_TYPES.REPLACE_CHIP_CARD,
            deliveryMethod: method,
        };
        if (serviceName === BOOKING_SERVICES.REPLACE_CHIP_BOOKING) {
            dataToPost.replaceCardId = id;
        }

        onSubmit(dataToPost);
    }, [
        selectedTime,
        selectedHangout,
        serviceName,
        onSubmit,
        method,
        type,
        id,
    ]);

    const buttons = useMemo(() => {
        return [
            {
                label: t("wealth:mc_v3_btn_confirm2"),
                onClick: submitClick,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ];
    }, [t, onCancel, submitClick]);

    const content = useMemo(() => {
        const parsedTime =
            parse(selectedTime?.fromTime, "yyyy-MM-dd HH:mm", new Date()) || "";
        return {
            title: t("booking_confirmation"),
            address: {
                label: t("lb_location"),
                name: selectedHangout?.name,
                value: selectedHangout?.address,
            },
            time: {
                label: t("lb_time"),
                value: parsedTime
                    ? format(parsedTime, "HH:mm EEEE, dd/MM/yyyy")
                    : "",
            },
        };
    }, [t, selectedHangout, selectedTime]);

    return (
        <Content size="sm">
            <PageHeaderBack onBack={onBack}>{content?.title}</PageHeaderBack>
            <Paper className={classes.body}>
                <Box>
                    <div className={classes.label} htmlFor="address">
                        {content?.address?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.address?.name}
                    </LspTypography>
                    <LspTypography variant="body1">
                        {content?.address?.value}
                    </LspTypography>
                </Box>

                <Box mt={2}>
                    <div className={classes.label} htmlFor="address">
                        {content?.time?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.time?.value}
                    </LspTypography>
                    <LspTypography variant="body1">
                        {content?.time?.note}
                    </LspTypography>
                </Box>
            </Paper>
            <Box mt={3}>
                <DoubleButtons
                    primaryButton={buttons[0]}
                    secondaryButton={buttons[1]}
                    progressing={submitting}
                />
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    method: state.booking.method,
    selectedHangout: state.booking.selectedHangout,
    selectedTime: state.booking.selectedTime,
    serviceName: state.booking.serviceName,
    type: state.booking.type,
    id: state.booking.id,
});
export default connect(mapState)(CreateBookingConfirmation);
