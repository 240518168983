import { call, delay, fork, put, select } from "redux-saga/effects";
import { CancelToken } from "apisauce";
import authService from "@services/auth";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ApiCancelToken from "@helpers/controllers/ApiCancelToken";
import api from "@config/api";
import { LANGUAGE_OPTIONS, RESPONSE_CODE } from "@config/constants";
import LspTranslation from "@components/LspTranslation";
import LoginWarningDialog from "@containers/Login/LoginWarningDialog";
import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import Translate from "@i18n/translate";

import { twoFactorsWarningDialog } from "@containers/Security/twofactors/helper";
import appAction from "../actions/app";
import authAction, { AuthActionType } from "../actions/auth";

const getDialogOpened = (state) => state.otpDialog.opened;

export function* authSaga(action) {
    const { type, payload } = action;

    let response;

    switch (type) {
        case AuthActionType.SubmitDeviceAuthenticationOTPRequest:
            response = yield call(
                authService.submitDeviceAuthenticationOTP,
                payload
            );
            break;
        case AuthActionType.SubmitGGTwoFactorOTPRequest:
            response = yield call(authService.submitTwoFactorOTP, {
                ...payload,
                bypassErrorHandler: true,
            });
            break;
        case AuthActionType.SubmitTwoFactorOTPRequest:
            response = yield call(authService.submitTwoFactorOTP, payload);
            break;
        case AuthActionType.LoginRequest:
        default:
            const cancelToken = CancelToken.source();
            ApiCancelToken.setCancelToken(cancelToken);
            api.axiosInstance.defaults.cancelToken = cancelToken.token;
            response = yield call(authService.login, payload);
            break;
    }

    const opened = yield select(getDialogOpened);

    // if (response.ok) {
    const { code, data, currentAttempt, maxAttempt, secData } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
        case RESPONSE_CODE.SUCCESS_V2:
            QuickCodeDialogController.updateStatusInfo(false, false);
            if (opened) {
                OTPController.hide();
            }
            yield put(authAction.loginSuccess(data));
            break;
        case RESPONSE_CODE.GG_TWO_FACTOR:
            yield put(authAction.loginWithOTP(data));
            if (opened) {
                OTPController.hide();
                setTimeout(
                    () => OTPController.show(OTPDialogType.GGTwoFactor),
                    300
                );
            } else {
                OTPController.show(OTPDialogType.GGTwoFactor);
            }
            break;
        case RESPONSE_CODE.OTP_REQUIRED: // OTP required
            yield put(authAction.loginWithOTP(data));
            if (opened) {
                OTPController.hide();
                setTimeout(
                    () => OTPController.show(OTPDialogType.DeviceAuthorization),
                    300
                );
            } else {
                OTPController.show(OTPDialogType.DeviceAuthorization);
            }
            break;
        case RESPONSE_CODE.TWO_FACTOR_REQUIRED: // Two factor
            yield put(authAction.loginWithTwoFactor(data));
            if (opened) {
                OTPController.hide();
                setTimeout(
                    () => OTPController.show(OTPDialogType.TwoFactor),
                    300
                );
            } else {
                OTPController.show(OTPDialogType.TwoFactor);
            }
            break;
        case RESPONSE_CODE.INCORRECT_VALUE: // Incorrect OTP
        case RESPONSE_CODE.MISMATCH_VALUE: // OTP Mismatched
            yield put(authAction.loginWithOTPError());
            AlertController.show(
                <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                AlertType.Error
            );
            break;
        case 6666: // Reset password required
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_reset_required" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case 6111: // Device blocked
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_device_blocked" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case 6222: // Unsupported device
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_device_not_support" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case 6508: // Account blocked
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_account_blocked" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case 6333: // System stress
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_sys_stress" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case 6444: // System maintenance
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_pw_sys_maintenance" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.NEED_UPGRADE_APP_VERSION:
            if (opened) {
                OTPController.hide();
            }
            AlertController.show(
                <LspTranslation i18nKey="login_msg_fail_app_upgrade" />,
                AlertType.Error
            );
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.WAITING_APPROVAL:
            if (opened) {
                OTPController.hide();
            }
            const lang =
                localStorage.getItem("lang") === "en"
                    ? LANGUAGE_OPTIONS.EN.toUpperCase()
                    : LANGUAGE_OPTIONS.VI.toUpperCase();

            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    header: secData?.title ? secData.title[lang] : "",
                    content: secData?.msg ? secData.msg[lang] : "",
                    button: "ekyc:button_waiting_approval",
                },
            });
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.ACCOUNT_NO_CIF_WITHOUT_PASSWORD:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.show({
                component: () => (
                    <LoginWarningDialog
                        onClose={() => GlobalDialogController.hide()}
                    />
                ),
            });
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.UNAUTHORIZED: // Wrong username or password
            if (opened) {
                OTPController.hide();
            }
            if (
                currentAttempt &&
                maxAttempt &&
                currentAttempt === maxAttempt - 1
            ) {
                AlertController.show(
                    <LspTranslation i18nKey="login_msg_fail_final" />,
                    AlertType.Error
                );
            } else {
                AlertController.show(
                    <LspTranslation i18nKey="login_msg_fail" />,
                    AlertType.Error
                );
            }
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.INVALID:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.FE_USER_BLOCKED,
            });
            yield put(authAction.loginError());
            break;
        case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
            twoFactorsWarningDialog();
            yield put(authAction.loginError());
            break;
        default:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showError({ errorCode: code });
            yield put(authAction.loginError());
            break;
    }
    // } else {
    //     yield put(authAction.loginError());
    // }
}

export function* logoutSaga(action) {
    if (action.payload?.sessionTimeout) {
        yield fork(authService.logout);
        yield delay(100);
        yield put(authAction.forceLogout());
        Translate.cleanPrivateContent();
    } else {
        yield fork(authService.logout);
        yield delay(100);
        yield put(authAction.logoutSuccess());
        Translate.cleanPrivateContent();
    }
}

export function* clientLogoutSaga(action) {
    // reset required quickcode status
    QuickCodeDialogController.updateStatusInfo(false, false);
    if (action.type === AuthActionType.ForceLogout) {
        GlobalDialogController.showError({
            errorCode: RESPONSE_CODE.UNAUTHORIZED,
        });
    }
    ApiCancelToken.cancel();
    yield put(appAction.stop());
}
