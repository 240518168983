import { Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import useStepNavigation from "@helpers/useStepNavigation";
import { useCallback, useRef, memo, useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { useTranslation } from "react-i18next";
import LspCongratulation from "@components/LspCongratulation";
import wealthService from "@services/wealth";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import WealthTravelAreaSelection from "@containers/Wealth/WealthInsurance/WealthTravel/WealthTravelAreaSelection";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import LspTypography from "@components/LspTypography";
import { useHistory } from "react-router-dom";
import { KIND_OPTIONS } from "@containers/Wealth/wealthConstants";
import useOTP from "@helpers/useOTP";
import LspReceipt from "@components/LspReceipt";
import useNumber from "@helpers/useNumber";
import WealthTravelTimeRangeForm from "./WealthTravelTimeRangeForm";
import WealthTravelAttendantForm from "./WealthTravelAttendantForm";
import WealthTravelPackageInfo from "./WealthTravelPackageInfo";
import WealthTravelPackageSelection from "./WealthTravelPackageSelection";
import WealthTravelOwnerPolicy from "./WealthTravelOwnerPolicy";
import WealthTravelDependentPolicy from "./WealthTravelDependentPolicy";
import WealthTravelReviewInfo from "./WealthTravelReviewInfo";
import WealthTravelConfirmation from "./WealthTravelConfirmation";

const TRAVEL_STEPS = {
    NOT_ELIGIBLE: 0,
    AREA_SELECTION: 1,
    TIME_RANGE_FORM: 2,
    ATTENDANT_FORM: 3,
    PACKAGE_SELECTION: 4,
    PACKAGE_INFO: 5,
    OWNER_POLICY_FORM: 6,
    DEPENDENT_POLICY_FORM: 7,
    REVIEW_INFO: 8,
    CONFIRMATION: 9,
    CONGRATULATION: 10,
    RECEIPT: 11,
};

const oneSidePages = [
    TRAVEL_STEPS.NOT_ELIGIBLE,
    TRAVEL_STEPS.AREA_SELECTION,
    TRAVEL_STEPS.TIME_RANGE_FORM,
    TRAVEL_STEPS.ATTENDANT_FORM,
];

const useStyles = makeStyles((theme) => ({
    oneSidePages: {
        maxWidth: 400,
        marginLeft: theme.spacing(11),
        [theme.breakpoints.down("xs")]: {
            margin: "auto",
        },
    },
}));

const WealthTravel = ({ resetState, user, attendant, selectedPackage }) => {
    const unmounted = useRef(false);
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const history = useHistory();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const { formatNumber } = useNumber();

    const sharedClasses = useClassesShared();
    const classes = useStyles();
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: "",
    });

    const isOneSide = useMemo(() => oneSidePages.includes(step.current), [
        step,
    ]);

    const firstLoaded = useRef(false);
    const [submitting, setSubmitting] = useState(false);
    const [receipt, setReceipt] = useState(null);

    const initPage = useCallback(() => {
        firstLoaded.current = true;
        if (
            (user?.isForeigner || user?.isForeigner === "true") &&
            (!user?.hasResident || user?.hasResident === "false")
        ) {
            stepForward(TRAVEL_STEPS.NOT_ELIGIBLE);
            return;
        }

        stepForward(TRAVEL_STEPS.AREA_SELECTION);

        // fake
        // stepForward(TRAVEL_STEPS.OWNER_POLICY_FORM);
    }, [user, stepForward]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    useEffect(() => {
        if (firstLoaded.current) return;
        initPage();
    }, [initPage]);

    // ====== NAVIGATION ======

    const onBackDashboard = () => {
        history.goBack();
    };

    const onBackHandler = useCallback(() => {
        switch (step.current) {
            case TRAVEL_STEPS.TIME_RANGE_FORM:
                resetState(["travelDate"]);
                break;
            case TRAVEL_STEPS.ATTENDANT_FORM:
                resetState(["attendant"]);
                break;
            case TRAVEL_STEPS.PACKAGE_SELECTION:
                resetState(["packageList", "selectedPackage"]);
                break;
            case TRAVEL_STEPS.OWNER_POLICY_FORM:
                resetState(["ownerPolicy"]);
                break;
            case TRAVEL_STEPS.DEPENDENT_POLICY_FORM:
                resetState(["dependentPolicy", "formDependentPolicy"]);
                break;
            default:
                break;
        }
        stepBack();
    }, [stepBack, resetState, step]);

    const onNextPackageSelection = () => {
        if (isSmDown) {
            stepForward(TRAVEL_STEPS.PACKAGE_INFO);
            return;
        }

        stepForward(TRAVEL_STEPS.OWNER_POLICY_FORM);
    };

    const onNextForOwnerPolicy = useCallback(() => {
        if (attendant?.type === KIND_OPTIONS.ME) {
            if (isSmDown) {
                stepForward(TRAVEL_STEPS.REVIEW_INFO);
                return;
            }
            stepForward(TRAVEL_STEPS.CONFIRMATION);
            return;
        }
        stepForward(TRAVEL_STEPS.DEPENDENT_POLICY_FORM);
    }, [attendant, stepForward, isSmDown]);

    const onNextForDependentForm = () => {
        if (isSmDown) {
            stepForward(TRAVEL_STEPS.REVIEW_INFO);
            return;
        }

        stepForward(TRAVEL_STEPS.CONFIRMATION);
    };

    // ====== SUBMIT ======
    const renewTravel = () => {
        resetState();
        initPage();
    };

    const submitHandler = useCallback(
        ({ code, data }) => {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    // show receipt
                    stepForward(TRAVEL_STEPS.RECEIPT);
                    setReceipt(data);
                    break;
                case RESPONSE_CODE.SUCCESS_V2:
                    // show success screen
                    stepForward(TRAVEL_STEPS.CONGRATULATION);
                    break;
                case RESPONSE_CODE.NOT_ALLOWED:
                    const content = t(
                        "wealth_travel_error_insufficient_funds"
                    )?.replace("%@", formatNumber(selectedPackage?.pricing));

                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Error",
                            header: "global_title_insuficient_funds",
                            translatedContent: content,
                            button: "lb_ok",
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [selectedPackage, t, stepForward, formatNumber]
    );

    const commitOtp = useCallback(
        async (dataToPost) => {
            setLoading(true);
            const payload = {
                productKey: selectedPackage?.productKey,
                ...dataToPost,
            };
            const response = await wealthService.commitOTP(payload);

            if (unmounted.current) return;

            setLoading(false);
            closeOTPDialog();
            submitHandler(response.data);
        },
        [selectedPackage, closeOTPDialog, submitHandler, setLoading]
    );

    const onSubmit = async (payload) => {
        console.log("payload", payload);

        setSubmitting(true);

        const response = await wealthService.applyProduct(payload);

        if (unmounted.current) return;
        setSubmitting(false);

        const { code, data } = response.data;
        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: commitOtp,
            });
        } else {
            submitHandler(response.data);
        }
    };

    return (
        <>
            {isOneSide && (
                <div className={sharedClasses.leftSide}>
                    <div className={classes.oneSidePages}>
                        {step.current === TRAVEL_STEPS.NOT_ELIGIBLE && (
                            <LspCongratulation
                                title={t("lb_we_sorry")}
                                type="primary"
                                icon="apply"
                                component
                                button={{
                                    label: t("lb_ok"),
                                    onClick: () => onBackDashboard(),
                                }}
                            >
                                <LspTypography
                                    variant="heading2"
                                    color="primary"
                                >
                                    {t("wealth:lbi_foreigner_popup_title")}
                                </LspTypography>
                                <br />
                                <LspTypography>
                                    {t("wealth:lbi_foreigner_popup_message")}
                                </LspTypography>
                            </LspCongratulation>
                        )}
                        {step.current === TRAVEL_STEPS.AREA_SELECTION && (
                            <WealthTravelAreaSelection
                                onNext={() =>
                                    stepForward(TRAVEL_STEPS.TIME_RANGE_FORM)
                                }
                            />
                        )}
                        {step.current === TRAVEL_STEPS.TIME_RANGE_FORM && (
                            <WealthTravelTimeRangeForm
                                onBack={onBackHandler}
                                onNext={() =>
                                    stepForward(TRAVEL_STEPS.ATTENDANT_FORM)
                                }
                            />
                        )}
                        {step.current === TRAVEL_STEPS.ATTENDANT_FORM && (
                            <WealthTravelAttendantForm
                                onBack={onBackHandler}
                                onNext={() =>
                                    stepForward(TRAVEL_STEPS.PACKAGE_SELECTION)
                                }
                            />
                        )}
                    </div>
                </div>
            )}
            {!isOneSide && (
                <div className={sharedClasses.wrapper}>
                    <>
                        {/* For small devices: hide left side column & document will be display when users click apply on form */}
                        <Hidden smDown>
                            <div className={sharedClasses.leftSide}>
                                {step.current !== TRAVEL_STEPS.CONFIRMATION &&
                                    step.current !==
                                        TRAVEL_STEPS.REVIEW_INFO && (
                                        <WealthTravelPackageInfo />
                                    )}
                                {(step.current === TRAVEL_STEPS.CONFIRMATION ||
                                    step.current ===
                                        TRAVEL_STEPS.REVIEW_INFO) && (
                                    <WealthTravelReviewInfo />
                                )}
                            </div>
                        </Hidden>
                        {/* For small devices:  users click apply > show document > next > submit form */}
                        <div className={sharedClasses.rightSide}>
                            {step.current === TRAVEL_STEPS.PACKAGE_INFO && (
                                <WealthTravelPackageInfo
                                    onBack={onBackHandler}
                                    onNext={() =>
                                        stepForward(
                                            TRAVEL_STEPS.OWNER_POLICY_FORM
                                        )
                                    }
                                />
                            )}
                            {step.current ===
                                TRAVEL_STEPS.PACKAGE_SELECTION && (
                                <WealthTravelPackageSelection
                                    onBack={onBackHandler}
                                    onNext={onNextPackageSelection}
                                />
                            )}
                            {step.current ===
                                TRAVEL_STEPS.OWNER_POLICY_FORM && (
                                <WealthTravelOwnerPolicy
                                    onBack={onBackHandler}
                                    onNext={onNextForOwnerPolicy}
                                />
                            )}
                            {step.current ===
                                TRAVEL_STEPS.DEPENDENT_POLICY_FORM && (
                                <WealthTravelDependentPolicy
                                    onBack={onBackHandler}
                                    onNext={onNextForDependentForm}
                                />
                            )}
                            {step.current === TRAVEL_STEPS.REVIEW_INFO && (
                                <WealthTravelReviewInfo
                                    onBack={onBackHandler}
                                    onNext={() =>
                                        stepForward(TRAVEL_STEPS.CONFIRMATION)
                                    }
                                />
                            )}
                            {step.current === TRAVEL_STEPS.CONFIRMATION && (
                                <WealthTravelConfirmation
                                    onBack={onBackHandler}
                                    onSubmit={onSubmit}
                                    submitting={submitting}
                                />
                            )}
                            {step.current === TRAVEL_STEPS.CONGRATULATION && (
                                <LspCongratulation
                                    title={t("lbi_travel_label_apply_success")}
                                    contents={t("wealth:GE00096")}
                                    contentWithBox={{
                                        content: t("wealth:GE00056"),
                                    }}
                                    button={{
                                        label: t("lb_done"),
                                        onClick: renewTravel,
                                    }}
                                />
                            )}
                            {step.current === TRAVEL_STEPS.RECEIPT && (
                                <LspReceipt rawReceipt={receipt} />
                            )}
                        </div>
                    </>
                </div>
            )}
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    attendant: state.insurance.attendant,
    selectedPackage: state.insurance.selectedPackage,
});

const mapDispatch = (dispatch) => ({
    resetState: (payload) => dispatch(wealthInsuranceAction.reset(payload)),
});

export default memo(connect(mapState, mapDispatch)(WealthTravel));
