/* eslint-disable jsx-a11y/label-has-associated-control */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import PageHeader from "@components/PageHeader";
import PreviewTransfer from "@components/PreviewTransfer";
import { PRODUCT_TYPES, RESPONSE_CODE } from "@config/constants";
import PasswordGenerator from "@containers/SpendAccount/MoveMoney/PayAnyone/PasswordGenerator";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import moveMoneyAction from "@redux/actions/move-money";
import moveMoneyService from "@services/move-money";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Preview = ({
    next,
    back,
    cancel,
    submitting,
    phoneNumber,
    description,
    amount,
    password,
    passwordOption,
    disablePasswordOption,
    defaultPasswordOption,
    updatePassword,
    updatePasswordOption,
    updateDisabledPasswordOption,
}) => {
    const { t } = useTranslation("translation", "payAnyone");
    const [previewInfo, setPreviewInfo] = useState({});

    const defaultMaxAmount = 1000000;
    const maxAmount = parseFloat(
        t("payAnyone:payment_password_requirement_amount")
            .toString()
            .replace(/\D+/g, "") || defaultMaxAmount
    );

    const [passwordFetching, setPasswordFetching] = useState(false);

    useEffect(() => {
        if (amount.value >= maxAmount) {
            updatePasswordOption(true);
            updateDisabledPasswordOption(true);
        } else {
            updatePasswordOption(defaultPasswordOption === "true");
            updateDisabledPasswordOption(false);
        }
    }, [
        amount,
        maxAmount,
        updatePasswordOption,
        updateDisabledPasswordOption,
        defaultPasswordOption,
    ]);

    const passwordGeneration = useCallback(async () => {
        setPasswordFetching(true);
        const payload = {
            phoneNumber,
            fullName: "",
            paoType: PRODUCT_TYPES.PAY_ANYONE,
        };
        const response = await moveMoneyService.passwordGenerator(payload);
        const { code, data } = response.data;
        if (code === RESPONSE_CODE.SUCCESS) {
            updatePassword(data.secretCode);
        } else {
            GlobalDialogController.showError({ errorCode: code });
        }
        setPasswordFetching(false);
    }, [phoneNumber, updatePassword]);

    const onChangePasswordOption = (option) => {
        if (disablePasswordOption) return;

        if (option === null) {
            updatePasswordOption(passwordOption);
            return;
        }

        updatePasswordOption(option);
    };

    useEffect(() => {
        if (passwordOption) {
            passwordGeneration();
        }
    }, [passwordGeneration, passwordOption]);

    useEffect(() => {
        setPreviewInfo({
            amount: -amount.value,
            to: phoneNumber,
            description,
        });
    }, [amount, description, phoneNumber]);

    return (
        <div>
            <PageHeader backFunc={back} cancelFunc={cancel}>
                <div>{t("payAnyone:payment_review_header")} </div>
            </PageHeader>
            <Content size="sm">
                <PreviewTransfer info={previewInfo} />
                <PasswordGenerator
                    password={password}
                    option={passwordOption}
                    isDisabled={disablePasswordOption}
                    fetching={passwordFetching}
                    onChangePasswordOption={onChangePasswordOption}
                />
                <LspButton
                    type="submit"
                    fullWidth
                    progressing={submitting}
                    onClick={next}
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </div>
    );
};

const stateProps = (state) => ({
    password: state.moveMoney.passwordPA,
    passwordOption: state.moveMoney.passwordPAOption,
    disablePasswordOption: state.moveMoney.disabledPasswordOption,
    defaultPasswordOption:
        state.systemParams.info.sysparams["1.0"].defaultEnablePasswordPA,
});

const dispatchProps = (dispatch) => ({
    updatePassword: (payload) =>
        dispatch(moveMoneyAction.updatePasswordPayAnyone(payload)),
    updatePasswordOption: (payload) =>
        dispatch(moveMoneyAction.updatePasswordOptionPayAnyone(payload)),
    updateDisabledPasswordOption: (payload) =>
        dispatch(moveMoneyAction.updateDisabledPasswordPayAnyone(payload)),
});

export default connect(stateProps, dispatchProps)(Preview);
