import api from "@config/api";

const checkNotification = async () => {
    return await api.get("/user/notification/check");
    // return await apiMock.get("/user/notification/check");
};

const getNotificationList = async (params) => {
    const language = localStorage.getItem("lang");
    return await api.get(`/notification/${language}`, params);
    // return await apiMock.get(`/notification/${language}`, params);
};

const updateNotificationStatus = async (payload) => {
    return await api.post(`/notification/update`, payload);
};

const notificationService = {
    checkNotification,
    getNotificationList,
    updateNotificationStatus,
};

export default notificationService;
