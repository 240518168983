import { withStyles } from "@material-ui/core/styles";
import { DialogTitle, DialogContent } from "@material-ui/core";

export const LspDialogNoHeaderTitle = withStyles((theme) => ({
    root: {
        padding: theme.spacing(4, 4, 2, 4),
        borderTopRightRadius: theme.shape.radiusLevels[0],
        borderTopLeftRadius: theme.shape.radiusLevels[0],
        background: theme.palette.background.paper,
        "& h2": {
            color: theme.palette.text.header,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            fontSize: theme.typography.pxToRem(24),
            "& span": {
                marginBottom: theme.spacing(1),
                fontSize: `${theme.typography.pxToRem(100)} !important`,
                color: theme.palette.primary.main,
                display: "inline-block",
            },
        },
        "& .close-custom-icon": {
            position: "absolute",
            right: theme.spacing(4),
            top: theme.spacing(4),
            color: theme.palette.magicalGrey[400],
            cursor: "pointer",
            fontSize: theme.typography.pxToRem(18),
        },
    },
}))(DialogTitle);

export const LspDialogNoHeaderContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 4, 4, 4),
        background: theme.palette.background.paper,
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        color: theme.palette.magicalGrey[400],
    },
}))(DialogContent);
