import { memo } from "react";

import { useTranslation } from "react-i18next";

import LspCongratulation from "@components/LspCongratulation";
import { WEALTH_CREDIT_CARD_STEPS } from "@containers/Wealth/wealthConstants";
import { makeStyles } from "@material-ui/core";
import useSharedClasses from "@containers/Wealth/wealthSharedClasses";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 176,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
}));

const WealthCreditCardActivated = ({ onNext }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useSharedClasses();

    const cardImg = `${process.env.REACT_APP_API_URL}/${t(
        "sysParamContent:DefaultCreditCardUrl"
    )}`;

    return (
        <LspCongratulation
            title={t("wealth:mc_v3_already_approved_title")}
            contents={t("wealth:mc_v3_already_approved_msg")}
            contentWithAction={{
                label: t("wealth:mc_v3_link_about_mc"),
                onClick: () => {
                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                },
                className: [sharedClasses.aboutButton],
            }}
            component
        >
            <img className={classes.image} src={cardImg} alt="credit card" />
        </LspCongratulation>
    );
};

export default memo(WealthCreditCardActivated);
