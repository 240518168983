import api from "../config/api";

// ----- Get Card List

const getCardList = async () => {
    return await api.get("/card-management/cards");
    // return await apiMock.get(`/card-management/cards`, null);
};

// ----- Update Card Status

const updateCardStatus = async (payload) => {
    return await api.post(`/card-management/cards/${payload.cardId}`, payload, {
        bypassErrorHandler: true,
    });
    // return await apiMock.post(`/card-management/cards/10680515`, payload, {
    //     bypassErrorHandler: true,
    // });
};

const submitOTP = async (payload) => {
    const { cardId, cardAction, cardType, ...restProps } = payload;
    return await api.post(`/card-management/cards/${cardId}/commit`, restProps);
};

// ----- Reset Pin Card

const resetPin = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.put(
        `/card-management/cards/${payload.cardId}/pin/reset`,
        restProps,
        {
            bypassErrorHandler: true,
        }
    );
};

const submitOTPResetPin = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.put(
        `/card-management/cards/${cardId}/pin/reset/commit`,
        restProps
    );
};

const changePin = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.put(
        `/card-management/cards/${payload.cardId}/pin`,
        restProps,
        {
            bypassErrorHandler: true,
        }
    );
};

const commitOtpChangePin = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.put(
        `/card-management/cards/${payload.cardId}/pin/commit`,
        restProps,
        {
            bypassErrorHandler: true,
        }
    );
};

// ----- ReIssue Card

const getHangoutList = async (payload) => {
    const { language, id } = payload;
    return await api.get(`/card-management/cards/requestAction/${language}`, {
        actionId: id,
    });
};

const getFeeCardAction = async (payload) => {
    const { language, cardProduct } = payload;
    return await api.get(
        `/user/products/v1/${cardProduct}/request/${language}`
    );
    // return await apiMock.get(
    //     `/user/products/v1/physical-visa-debit-card/request/vn`
    // );
};

const reIssueCard = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.post(
        `/card-management/cards/${cardId}/reIssue/request`,
        restProps
    );
};

const submitOTPReIssueCard = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.post(
        `/card-management/cards/${cardId}/reIssue/commit`,
        restProps
    );
};

const checkCardNumber = async (payload) => {
    return await api.post(`/precus/ekyc/card/check`, payload, {
        bypassErrorHandler: true,
    });

    // return await apiMock.post(`/precus/ekyc/card/check`, payload, {
    //     bypassErrorHandler: true,
    // });
};

const cardActivation = async (payload) => {
    return await api.post(`/precus/ekyc/card/active`, payload);
    // return await apiMock.post(`/precus/ekyc/card/active`, payload);
};

const submitOTPCardActivation = async (payload) => {
    return await api.post(`/precus/ekyc/card/active/commit`, payload);
    // return await apiMock.post(`/precus/ekyc/card/active/commit`, payload);
};

const updateInstallmentNotification = async (payload) => {
    return await api.post("/user/setting/update/1by1", payload);
};

const reIssueCardV2 = async (payload) => {
    const { cardId, ...restProps } = payload;
    // return await apiMock.post(
    //     `/card-management/v2/cards/10683158/reIssue/request`,
    //     restProps
    // );
    return await api.post(
        `/card-management/v2/cards/${cardId}/reIssue/request`,
        restProps
    );
};

const submitOTPReIssueCardV2 = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.post(
        `/card-management/v2/cards/${cardId}/reIssue/commit`,
        restProps
    );
};

const reIssuesCard = async (payload) => {
    return await api.post(`card-management/v2/cards/requestAction`, payload);
};

const maskCard = async (payload) => {
    const { cardId } = payload;
    return await api.post(`/card-management/cards/mask`, { cardId });
};

const commitMaskCard = async (payload) => {
    return await api.post(`/card-management/cards/mask/commit`, payload);
};

const reissueVirtualVisaDebitCard = async (payload) => {
    return await api.post(
        `/card-management/v2/cards/reIssueVirtual/request`,
        payload
    );
    // return await apiMock.post(
    //     `/card-management/v2/cards/reIssueVirtual/request`,
    //     payload
    // );
};

const commitOtpReissueVirtualVisaDebitCard = async (payload) => {
    return await api.post(
        `/card-management/v2/cards/reIssueVirtual/commit`,
        payload
    );
};

const cardLimit = async (payload) => {
    return await api.put(`/card-management/cards/limit/amount`, payload);
};

const getCardLimit = async (payload) => {
    const { cardId, userId } = payload;
    return await api.get(`/card-management/cards/${cardId}/limit`, { userId });
};

const activeCard = async (payload) => {
    const { cardId } = payload;
    return await api.post(`/card-management/cards/${cardId}/active`, {
        cardId,
    });
};

const commitActiveCard = async (payload) => {
    const { cardId } = payload;
    return await api.post(
        `/card-management/cards/${cardId}/active/commit`,
        payload
    );
};

const requestPhysicalCard = async (payload) => {
    const { cardId, ...restProps } = payload;
    // return await apiMock.post(
    //     `/user/products/v1/physical-visa-debit-card/10683374/submit`,
    //     restProps
    // );
    return await api.post(
        `/user/products/v1/physical-visa-debit-card/${cardId}/submit`,
        restProps
    );
};

const submitOtpRequestPhysicalCard = async (payload) => {
    const { cardId, ...restProps } = payload;
    return await api.post(
        `/user/products/v1/physical-visa-debit-card/${cardId}/commit`,
        restProps
    );
};

const cardManagementService = {
    getCardList,
    updateCardStatus,
    submitOTP,
    resetPin,
    submitOTPResetPin,
    getHangoutList,
    reIssueCard,
    submitOTPReIssueCard,
    checkCardNumber,
    cardActivation,
    submitOTPCardActivation,
    updateInstallmentNotification,
    changePin,
    commitOtpChangePin,
    reIssueCardV2,
    submitOTPReIssueCardV2,
    reIssuesCard,
    maskCard,
    commitMaskCard,
    reissueVirtualVisaDebitCard,
    commitOtpReissueVirtualVisaDebitCard,
    cardLimit,
    getCardLimit,
    activeCard,
    commitActiveCard,
    getFeeCardAction,
    requestPhysicalCard,
    submitOtpRequestPhysicalCard,
};

export default cardManagementService;
