import { memo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        root: {
            marginTop: theme.spacing(2),
        },
        header: {
            fontWeight: "600",
            fontSize: theme.typography.pxToRem(14),
            backgroundColor: isDark
                ? theme.palette.grey[700]
                : theme.palette.neutral.grey3,
            color: isDark
                ? theme.palette.primary.contrastText
                : theme.palette.text.primary,
        },
        container: {
            borderRadius: theme.shape.radiusLevels[0],
        },
        date: {
            height: 45,
            [theme.breakpoints.up("sm")]: {
                height: 45,
            },
        },
        icon: {
            height: 26,
            width: 26,
            [theme.breakpoints.up("sm")]: {
                height: 32,
                width: 32,
            },
        },
        title: {
            height: 22,
            width: 100,
            [theme.breakpoints.up("sm")]: {
                height: 26,
                width: 200,
            },
        },
        subtitle: {
            height: 16,
            width: 32,
            [theme.breakpoints.up("sm")]: {
                height: 20,
                width: 60,
            },
        },
        value: {
            height: 22,
            width: 60,
            [theme.breakpoints.up("sm")]: {
                height: 26,
                width: 80,
            },
        },
        balance: {
            height: 16,
            width: 80,
            [theme.breakpoints.up("sm")]: {
                height: 20,
                width: 120,
            },
        },
    };
});

const TransactionListLoading = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box px={2} py={1} className={classes?.header}>
                <Skeleton width={100} />
            </Box>
            <Box paddingY={1.5}>
                {[...new Array(3)].map((_, index) => (
                    <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        padding={1.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.container}
                    >
                        <Box flex={1} paddingRight={1}>
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.title}
                            />
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.subtitle}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-end"
                        >
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.value}
                            />
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.balance}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default memo(TransactionListLoading);
