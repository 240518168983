/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import { memo, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { makeStyles, Grid, IconButton } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import overdraftService from "@services/overdraft";
import { FetchStatus, OVERDRAFT_STATUS } from "@config/constants";
import LspHintInfo from "@components/LspHintInfo";
import { Skeleton } from "@material-ui/lab";
import tomiHappy from "@assets/images/tomi_happy.png";
import { CloseIcon } from "@components/LspIcon";
import useStatus from "@helpers/useStatus";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

import tommyNoHope from "@assets/images/no_hope_with_status.png";
import { useHistory } from "react-router-dom";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import { connect } from "react-redux";
import overdraftAction from "@redux/actions/overdraft";
import OverdraftPreviewDialog from "./OverdraftPreviewDialog";
import OverdraftPreviewEarly from "./OverdraftPreviewEarly";
import OverdraftLockBox from "./OverdraftLockBox";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(4),
    },
    borderRight: {
        borderRight: `1.5px solid ${theme.palette.neutral.grey3}`,
    },
    borderBottom: {
        borderBottom: `1.5px solid ${theme.palette.neutral.grey3}`,
        margin: theme.spacing(2, 0),
    },
    info: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    hint: {
        marginBottom: theme.spacing(3),
    },
    earlyClosure: {
        color: theme.palette.status.error,
        display: "flex",
        justifyContent: "center",
    },
    centerItem: {
        display: "flex",
        justifyContent: "center",
    },

    alertBox: {
        padding: theme.spacing(1, 2),
        background: theme.palette.status.success,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    alertBoxError: {
        background: theme.palette.status.error,
    },
    alertBoxImg: {
        width: 42,
        marginRight: theme.spacing(1),
        color: theme.palette.neutral.white,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    alertBoxText: {
        flex: 1,
    },
    closeButton: {
        color: theme.palette.neutral.white,
    },
    lockStatus: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    lockStatusContent: {
        backgroundColor: theme.palette.magicalGrey[600],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
    },
}));
const OverdraftDetails = ({
    getOverdraftDetail,
    reset,
    loading,
    odStatus,
    overdraftDetails,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { status, setStatus } = useStatus();
    const history = useHistory();

    const firstLoad = useRef(false);

    const [earlyLoading, setEarlyLoading] = useState(false);

    const [alertBox, setAlertBox] = useState(
        status?.OVERDRAFT_BANNER === undefined
    );

    const overdraftLimit = useMemo(() => overdraftDetails?.limitAmount, [
        overdraftDetails,
    ]);

    useEffect(() => {
        if (odStatus === FetchStatus.Error) {
            history.push("/home");
        }
    }, [odStatus, history]);

    useEffect(() => {
        if (!firstLoad.current) {
            getOverdraftDetail();
            firstLoad.current = true;
        }
    }, [getOverdraftDetail, overdraftDetails]);

    const handleCloseBox = () => {
        setAlertBox(false);
        setStatus("OVERDRAFT_BANNER", false);
    };

    const handleCloseEarlyDialog = useCallback(() => {
        GlobalDialogController.hide();
        history.push("/overdraft/early");
    }, [history]);

    const handleCloseDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const handleViewDetail = () => {
        GlobalDialogController.show({
            component: () => (
                <OverdraftPreviewDialog onClose={handleCloseDialog} />
            ),
        });
    };

    const handleEarlyDialogFails = useCallback(() => {
        const contents = {
            banner: tommyNoHope,
            title: t(
                "overdraft:overdraft_early_closure_out_working_popup_title"
            ),
            description: t(
                "overdraft:overdraft_early_closure_out_working_popup_description"
            ),
            buttons: [
                {
                    variant: "primary",
                    label: t(
                        "overdraft:overdraft_early_closure_out_working_popup_cta"
                    ),
                    onClick: () => GlobalDialogController.hide(),
                },
            ],
        };

        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={contents} centerImg />
            ),
        });
    }, [t]);

    const handleEarlyDetail = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <OverdraftPreviewEarly
                    onClose={handleCloseEarlyDialog}
                    amount={overdraftDetails?.earlyFee}
                />
            ),
        });
    }, [handleCloseEarlyDialog, overdraftDetails?.earlyFee]);

    const handleEarlyOverdraft = useCallback(async () => {
        setEarlyLoading(true);
        const result = await overdraftService.checkEarlyOverdraft();
        const { data, code } = result?.data;

        setEarlyLoading(false);
        switch (data?.result) {
            case OVERDRAFT_STATUS.AVAILABLE:
                handleEarlyDetail();

                break;
            default:
                handleEarlyDialogFails();
                break;
        }
    }, [handleEarlyDetail, handleEarlyDialogFails]);

    const lockType = useMemo(() => {
        // const type = "A_NO_FUND_IN";
        // const type = "SWITCH_DEBT_GROUP";
        // const type = "A_SWITCH_DEBT_GROUP";
        // const type = "COPS_LOCK";
        // const type = "A_EXPIRATION";
        // const type = "FAIL_CLOSURE";
        // return type;
        return overdraftDetails?.lockType;
    }, [overdraftDetails?.lockType]);

    return (
        <Content>
            {lockType && <OverdraftLockBox lockType={lockType} />}

            {alertBox && (
                <div className={classes.alertBox}>
                    <div className={classes.alertBoxImg}>
                        <img src={tomiHappy} alt="Tomi happy" />
                    </div>

                    <LspTypography
                        variant="subheading4"
                        color="white"
                        className={classes.alertBoxText}
                    >
                        {t(
                            "overdraft:overdraft_dashboard_explore_message"
                        ).replace("%@_cta", "")}
                        {
                            <LspTypography
                                variant="hyperlink2"
                                component="span"
                                color="white"
                                onClick={handleViewDetail}
                            >
                                {t(
                                    "overdraft:overdraft_dashboard_explore_message_cta"
                                )}
                            </LspTypography>
                        }
                    </LspTypography>

                    <IconButton
                        className={classes.closeButton}
                        onClick={handleCloseBox}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
            <div className={classes.header}>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={6} className={classes.borderRight}>
                        <LspTypography variant="tiny" textCenter>
                            {t("overdraft:overdraft_balance_outstanding")}
                        </LspTypography>
                        <div className={classes.centerItem}>
                            <LspTypography variant="subheading2" textCenter>
                                {loading ? (
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        height={30}
                                    />
                                ) : (
                                    formatNumber(
                                        overdraftDetails?.outstandingAmount
                                    )
                                )}
                            </LspTypography>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="tiny" textCenter>
                            {t("overdraft:overdraft_balance_available")}
                        </LspTypography>
                        <div className={classes.centerItem}>
                            <LspTypography variant="subheading2" textCenter>
                                {loading ? (
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        height={30}
                                    />
                                ) : (
                                    formatNumber(
                                        overdraftDetails?.availableAmount
                                    )
                                )}
                            </LspTypography>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.info}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_limit")}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                formatNumber(overdraftLimit)
                            )}
                        </LspTypography>
                    </Grid>

                    {/* openDate */}
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_review_created_date")}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                overdraftDetails?.openDate
                            )}
                        </LspTypography>
                    </Grid>

                    {/* expiredDate */}
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_review_close_date")}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                overdraftDetails?.expiredDate
                            )}
                        </LspTypography>
                    </Grid>

                    {/* expenseSettlementDate */}
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_expense_settlement_date")}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                overdraftDetails?.expenseSettlementDate
                            )}
                        </LspTypography>
                    </Grid>

                    {/* overdraft_daily_annual_interest_rate */}
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_review_interest_rate")}
                        </LspTypography>
                    </Grid>

                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                t(
                                    "overdraft:overdraft_daily_annual_interest_rate"
                                )
                                    .replace(
                                        "%@_dailyInterestRate",
                                        overdraftDetails?.dailyInterestRate
                                    )
                                    .replace(
                                        "%@_annualInterestRate",
                                        `${overdraftDetails?.annualInterestRate}`
                                    )
                            )}
                        </LspTypography>
                    </Grid>
                </Grid>

                {/* nextInterestAccruedDate */}
                <div className={classes.borderBottom} />
                <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="body3" color="grey">
                            {t("overdraft:overdraft_interest_accrual_date")}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography
                            variant="subheading4"
                            textRight
                            color="grey"
                        >
                            {loading ? (
                                <Skeleton variant="text" width={100} />
                            ) : (
                                overdraftDetails?.nextInterestAccruedDate
                            )}
                        </LspTypography>
                    </Grid>
                </Grid>

                {/* totalInterestAccrued */}
                <div className={classes.borderBottom} />
                <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                        <LspTypography variant="subheading3">
                            {t(
                                "overdraft:overdraft_total_accrued_interest_title"
                            )}
                        </LspTypography>
                    </Grid>
                    <Grid item xs={6} md={6} justify="flex-end" container>
                        <LspTypography variant="subheading3" textRight>
                            {loading ? (
                                <Skeleton
                                    variant="text"
                                    width={100}
                                    height={30}
                                />
                            ) : (
                                formatNumber(
                                    overdraftDetails?.totalInterestAccrued
                                )
                            )}
                        </LspTypography>
                    </Grid>
                </Grid>
                <LspTypography variant="body3" color="grey">
                    {t("overdraft:overdraft_total_accrued_interest_desc")}
                </LspTypography>
            </div>

            <div className={classes.hint}>
                <LspHintInfo
                    content={t("overdraft:overdraft_dashboard_note")}
                />
            </div>

            <div className={classes.earlyClosure}>
                {!earlyLoading && (
                    <LspTypography
                        variant="hyperlink1"
                        textCenter
                        onClick={handleEarlyOverdraft}
                        color="error"
                    >
                        {t("overdraft:overdraft_dashboard_early_closure")}
                    </LspTypography>
                )}
                {earlyLoading && (
                    <Skeleton variant="text" width={100} height={30} />
                )}
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    loading: state.overdraft.fetching,
    odStatus: state.overdraft.status,
    overdraftDetails: state.overdraft.detail,
});

const mapDispatch = (dispatch) => ({
    getOverdraftDetail: () => dispatch(overdraftAction.getOverdraftDetail()),
    reset: () => dispatch(overdraftAction.reset()),
});

export default memo(connect(mapState, mapDispatch)(OverdraftDetails));
