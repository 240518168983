import { useMemo, useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PAYMENT_LABEL_STATUS, RESPONSE_CODE } from "@config/constants";
import { makeStyles, Box } from "@material-ui/core";
import { isFunction } from "lodash";

import DoubleButtons from "@components/DoubleButtons";
import LspButton from "@components/LspButton";
import paymentRequestService from "@services/payment-request";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import PaymentRequestActions from "@redux/actions/payment-request";
import { PAYMENT_REQUEST_ITEM_TYPES } from "@containers/PaymentRequest/paymentRequest.constant";

import Content from "@components/Content";
import PayNow from "../PayNow";
import PaymentRequestDetail from "../PaymentRequestDetail";
import PaymentStatusMessage from "../ PaymentStatusMessage";
import PaymentReceiverConfirmDelete from "./PaymentReceiverConfirmDelete";
import PaymentReceiverSpamScreen from "./PaymentReceiverSpamScreen";

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        actionBtn: {
            width: "100%",
            position: "absolute",
            padding: theme.spacing(2, 2),
            "& > svg": {
                cursor: "pointer",
            },
        },
    };
});
const PaymentReceive = ({
    paymentSendInfo,
    getPaymentList,
    sysParam,
    isPendingTask,
    onCompleteHandler,
    getPaymentRequestCompleteList,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [spamScreen, setSpamScreen] = useState(false);
    const [userAlreadyBlocked, setUserAlreadyBlocked] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [payNow, setPayNow] = useState(false);

    const [deleteInfo, setDeleteInfo] = useState({
        spam: false,
        note: false,
        noteText: "",
    });

    const unmounted = useRef(false);

    const onCancel = () => {
        setConfirmDelete(true);
    };

    const onBack = () => {
        if (spamScreen) {
            setSpamScreen(false);
            return;
        }
        setConfirmDelete(false);
    };

    const onClose = () => {
        GlobalDialogController.hide();
    };

    const refreshOutStandingList = useCallback(() => {
        const outstandingPayload = {
            type: PAYMENT_REQUEST_ITEM_TYPES.OPEN,
            lastId: "-1",
            modifyAt: "",
        };
        getPaymentList(outstandingPayload);
    }, [getPaymentList]);

    const refreshCompleteList = useCallback(() => {
        const completePayload = {
            type: PAYMENT_REQUEST_ITEM_TYPES.COMPLETE,
            lastId: "-1",
            modifyAt: "",
        };
        getPaymentRequestCompleteList(completePayload);
    }, [getPaymentRequestCompleteList]);

    const onPaySuccessHandler = () => {
        refreshOutStandingList();
        refreshCompleteList();
        if (onCompleteHandler && isFunction(onCompleteHandler)) {
            onCompleteHandler();
        }
    };

    const deleteRequest = useCallback(
        async (deleteOption, blockUser = false) => {
            const maxSpamStore = sysParam.MaxSpamForEachPerson || 3;
            const maxSpamForEachPerson =
                maxSpamStore < 1 ? 0 : maxSpamStore - 1;
            // const maxSpamForEachPerson = 0;
            const options = deleteOption || deleteInfo;
            if (
                options?.spam &&
                paymentSendInfo.countSpam >= maxSpamForEachPerson &&
                !spamScreen
            ) {
                // When user CHECKED Mark as spam
                if (paymentSendInfo.countSpam === maxSpamForEachPerson) {
                    // Confirm block sender
                    setSpamScreen(true);
                } else if (paymentSendInfo.countSpam > maxSpamForEachPerson) {
                    // User already blocked
                    setSpamScreen(true);
                    setUserAlreadyBlocked(true);
                }
            } else {
                setFetchingData(true);
                const dataToPost = {
                    id: paymentSendInfo.paymentRequestId,
                    receiveId: paymentSendInfo.payeeRequestId,
                    iTimo: true,
                    deleted: {
                        markSpam: options?.spam,
                        notify: options?.note,
                        blockForever: blockUser,
                        reason: options?.note ? options?.noteText : "",
                    },
                };

                const {
                    data,
                } = await paymentRequestService.deleteReceivedPaymentRequest(
                    dataToPost
                );
                if (unmounted.current) return;
                setFetchingData(false);
                switch (data.code) {
                    case RESPONSE_CODE.CREATED:
                        refreshOutStandingList();
                        GlobalDialogController.hide();
                        AlertController.show(
                            t("payment_msg_delete_request_from").replace(
                                "%@",
                                paymentSendInfo.userSentName
                            ),
                            AlertType.Error
                        );
                        break;

                    default:
                        GlobalDialogController.showError({
                            errorCode: data.code,
                        });
                        break;
                }
            }
        },
        [
            deleteInfo,
            paymentSendInfo,
            spamScreen,
            sysParam,
            t,
            refreshOutStandingList,
        ]
    );

    const onConfirmDelete = (deleteOption) => {
        setDeleteInfo(deleteOption);
        deleteRequest(deleteOption);
    };

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const paymentHasBeenCancelled = useMemo(() => {
        return (
            !!paymentSendInfo?.labelStatus &&
            paymentSendInfo?.labelStatus === PAYMENT_LABEL_STATUS.CANCEL
        );
    }, [paymentSendInfo]);

    return (
        <Content size="sm" boxProps={{ style: { position: "relative" } }}>
            <Box className={classes.actionBtn}>
                {confirmDelete && <ArrowBackIcon onClick={onBack} />}
                <CloseIcon
                    onClick={onClose}
                    style={{ float: "right" }}
                    datatestid="closebtn"
                />
            </Box>
            {!spamScreen && !payNow && (
                <Box p={4} pb={2} className={classes.wrapper}>
                    {!confirmDelete && (
                        <>
                            <PaymentRequestDetail
                                info={paymentSendInfo}
                                isSent={false}
                            />

                            <PaymentStatusMessage
                                info={paymentSendInfo}
                                isSent={false}
                            />

                            {!paymentHasBeenCancelled && (
                                <Box mt={4} width="100%">
                                    <DoubleButtons
                                        primaryButton={{
                                            label: t("payment_lb_btn_pay_now"),
                                            onClick: () => setPayNow(true),
                                        }}
                                        secondaryButton={{
                                            label: t("payment_lb_btn_delete"),
                                            onClick: onCancel,
                                        }}
                                    />
                                </Box>
                            )}

                            {paymentHasBeenCancelled && (
                                <Box mt={4} width="100%">
                                    <LspButton
                                        variant="primary"
                                        fullWidth
                                        progressing={fetchingData}
                                        onClick={deleteRequest}
                                    >
                                        {t("payment_btn_remove_from_list")}
                                    </LspButton>
                                </Box>
                            )}
                        </>
                    )}

                    {confirmDelete && (
                        <PaymentReceiverConfirmDelete
                            onConfirmDelete={onConfirmDelete}
                            progressing={fetchingData}
                            avatar={paymentSendInfo?.avatar}
                        />
                    )}
                </Box>
            )}

            {spamScreen && (
                <PaymentReceiverSpamScreen
                    userAlreadyBlocked={userAlreadyBlocked}
                    deleteRequest={deleteRequest}
                />
            )}
            {payNow && (
                <PayNow
                    paymentSendInfo={paymentSendInfo}
                    isPendingTask={isPendingTask}
                    onPaySuccessHandler={onPaySuccessHandler}
                />
            )}
        </Content>
    );
};

const stateProps = (state) => ({
    sysParam: state.systemParams.info.sysparams["1.0"],
    maxAmountLimitPerTransfer:
        state.systemParams.info.conf.MaxAmountLimitPerTransfer,
});

const mapDispatch = (dispatch) => ({
    getPaymentList: (payload) =>
        dispatch(PaymentRequestActions.getPaymentRequestList(payload)),
    getPaymentRequestCompleteList: (payload) =>
        dispatch(PaymentRequestActions.getPaymentRequestCompleteList(payload)),
});

export default connect(stateProps, mapDispatch)(PaymentReceive);
