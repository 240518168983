export const WEALTH_NAVIGATION_KEYS = {
    ROOT: "wealth",
    DASHBOARD: "dashboard",
    LOANS: "loans",
    VINA_WEALTH: "vinawealth",
    CREDIT_CARD: "creditCard",
    LIFE_INSURANCE: "lifeinsurance/products",
    LIFE_INSURANCE_LONG_PROTECT: "lifeinsurance/sllifelongprotection",
    LIFE_INSURANCE_MANAGE_INSTANT_COVERAGE:
        "lifeinsurance/slmanageinstantcoverage",
    LIFE_INSURANCE_INSTANT_COVERAGE: "lifeinsurance/slinstantcoverage",
    GENERAL_INSURANCE: "insurance",
    GENERAL_INSURANCE_DASHBOARD: "dashboard",
    TRAVEL_INSURANCE: "travel",
    MOTORBIKE_INSURANCE: "motorbike",
};

export const WEALTH_PRODUCT_KEYS = {
    CARD: "card",
    MASTERCARD: "card2",
    MASTERCARD3: "card3",
    CREDIT_CARD_V4: "card4",
    LIFE_INSURANCE2: "lifeinsurance2",
    LIFE_INSURANCE: "lifeinsurance",
    OVERDRAFT: "overdraft",
    GENERAL_INSURANCE: "insurance2", // for liberty insurance
    GENERAL_INSURANCE_MOTORBIKE: "insurancemoto", // for liberty insurance
    GENERAL_INSURANCE_TRAVEL: "insurancetravel", // for liberty insurance
    VINA_WEALTH_V2: "vinawealth2",
    VINA_WEALTH_V3: "vinawealth3",
    LOANS: "loan",
    OVERSEAS: "overseastransfer",
    LIFE_INSURANCE_MANAGE_INSTANT_COVERAGE: "slmanageinstantcoverage",
    LIFE_INSURANCE_INSTANT_COVERAGE: "slinstantcoverage",
    LIFE_INSURANCE_LONG_PROTECT: "sllifelongprotection",
    MANAGE_MAKE_CLAIM: "make_claim",
    MANAGE_RESEND_POLICY: "resend_policy",
    MANAGE_CHANGE_BENEFICIARY: "change_beneficiary",
};

export const WEALTH_LOANS_PURPOSE_LIST = [
    {
        id: "BUY_A_HOUSE",
        minAmount: 320000000,
        name: "loan_vpbank_purpose_buy_house",
    },
    {
        id: "BUILD_A_HOUSE",
        minAmount: 320000000,
        name: "loan_vpbank_purpose_build_house",
    },
    {
        id: "BUY_A_CAR",
        minAmount: 210000000,
        name: "loan_vpbank_purpose_buy_car",
    },
    {
        id: "FINANCE_BUSINESS",
        minAmount: 130000000,
        name: "loan_vpbank_purpose_finance_business",
    },
];

export const WEALTH_STATUSES = {
    PENDING: "pending",
    NEW: "new",
    UPDATE: "update",
    ACTIVE: "active",
    APPLIED: "applied",
    PREQUALIFIED: "prequalified",
    NON_PREQUALIFIED: "nonprequalified",
    BLOCKED: "blocked",
    NOT_ELIGIBLE: "not_eligible",
    EXPIRED: "expired",
    CLOSED: "closed",
};

export const WEALTH_VINA_TYPES = {
    APPLY: "linkAccountApply",
    COMMIT: "linkAccountCommit",
    DASHBOARD: "investmentDashboard",
    TRANSACTION: "transactionHistory",
    TALK_ADVISER: "talkToAdviser",
};

export const WEALTH_VINA_ACCOUNT_STATUS = {
    NEW_ACCOUNT: 0,
    EXISTING_ACCOUNT: 1,
};

export const VINA_WEALTH_STEPS = {
    DASHBOARD: 1,
    SELECTION_DASHBOARD: 2,
    LINK_ACCOUNT: 3,
    BEFORE_YOU_START: 7,
    FOREIGNER_WARNING: 8,
    INTRO: 4,
    DETAIL: 5,
    INPUT_AMOUNT: 6,
    TRANSACTION_HISTORIES: 12,
    TERM_CONDITION: 9,
    RESULT: 11,
    REVIEW_INFO: 10,
    ACCOUNT_OVERVIEW: 13,
    PASSPORT_INFO: 14,
};

export const VINA_WEALTH_HISTORY_TRANSACTION_TYPES = {
    ACCOUNT_INFO: 1,
    FULL_TRANSACTION_LIST: 2,
};

export const VINA_WEALTH_TXT_TRANSACTION_TYPES = {
    PENDING: "PENDING",
    COMPLETE: "COMPLETE",
    CANCEL: "CANCEL",
    ALL: "ALL",
};

export const WEALTH_CREDIT_CARD_STEPS = {
    INTRO: 1,
    METHOD_SELECTIONS: 2,
    METHOD_INTRO: 3,
    AMOUNT_LIMITATION: 4,
    PERSONAL_INFO_FORM: 5,
    ADDRESS_FORM: 6,
    MARITAL_STATUS_FORM: 7,
    FINANCE_FORM: 8,
    PAYMENT_SELECTIONS: 9,
    HANGOUT_SELECTIONS: 10,
    BOOKING_SCHEDULE: 11,
    CONFIRM_BOOKING_SCHEDULE: 12,
    CONGRATULATION: 13,
    APPLY_FAILED_SCREEN: 14,
    ACTIVATED_SCREEN: 15,
    PENDING_SCREEN: 16,
    BLOCKED_SCREEN: 17,
    INVALID_AGE_SCREEN: 18,
    FOREIGNER_WARNING: 19,
    TIME_SELECTION: 20,
    BOOKING_CONFIRM: 21,
};

export const WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS = {
    PRE_QUALIFIED: "prequalify2",
    NON_QUALIFIED: "nonqualify2",
    MINIMUM_BALANCE: "howtoprequalify2",
    SECURE_CARD: "securecard2",
    ONE_FOR_ONE_CARD: "1for1card2",
    PRE_QUALIFIED_v3: "prequalify3",
    NON_QUALIFIED_v3: "nonqualify3",
    MINIMUM_BALANCE_v3: "howtoprequalify3",
    SECURE_CARD_v3: "securecard3",
    ONE_FOR_ONE_CARD_v3: "1for1card3",
};

export const WEALTH_CREDIT_CARD_METHOD = {
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE]: 1,
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD]: 2,
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD]: 3,
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED]: 4,
};

export const WEALTH_PAYMENT_METHODS = {
    MINIMUM: 1,
    FULL: 2,
    MANUAL: 3, // only using for frontend, because BE use another way to get this info
    OUTSTANDING: 4,
};

export const WEALTH_SECURED_QUESTIONS = {
    ONE: 1,
    TWO: 2,
};

export const WEALTH_DELIVERY_ADDRESS = {
    CURRENT_ADDRESS: 1,
    RESIDENT_ADDRESS: 2,
    OTHER: 3,
    COMPANY_ADDRESS: 4,
};

export const WEALTH_OCCUPATIONS = {
    SELF_EMPLOYEE: 0,
    OFFICE_WORKER: 1,
    OTHERS: 2,
};

export const WEALTH_MARITAL_STATUS_KEYS = {
    SINGLE: 1,
    WIDOWED: 2,
    MARRIED: 3,
    DIVORCED: 4,
    OTHERS: 5,
};

export const MANAGE_INSTANT_STEPS = {
    INTRO: 1,
    CONGRATULATION: 2,
};

export const INSTANT_COVERAGE_STEPS = {
    FORM_INFO: 1,
    INIT: 0,
    REVIEW_POLICY: 2,
    CONGRATULATION: 3,

    MOBILE_INTRO: 4,
    MOBILE_REVIEW_POLICY: 5,
};

export const KIND_OPTIONS = {
    ME: "single",
    ME_AND_FAMILY: "family",
};
