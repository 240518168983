/* eslint-disable react/forbid-prop-types */
import { Box, ButtonBase } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import LspTypography from "../LspTypography";

/**
 * @typedef DetailItemObject
 * @type {object}
 * @property {string} label
 * @property {number} amount
 */

/**
 * @typedef ItemObject
 * @type {object}
 * @property {string} id
 * @property {DetailItemObject} left
 * @property {DetailItemObject} right
 */

/**
 * @typedef LspCardItemAdvanceProps
 * @type {object}
 * @property {object} classes
 * @property {function} onClick
 * @property {bool} selected
 * @property  {ItemObject} item
 */

const LspCardItemAdvance = ({ selected, onClick, item, classes }) => {
    const { thousandSeparator, decimalSeparator } = useNumber();

    return (
        <ButtonBase
            className={clsx(classes.buttonItem, {
                [classes.selected]: selected,
                [classes.advanceWrapper]: true,
            })}
            key={item?.id}
            onClick={() => onClick(item)}
        >
            <Box mb={1} width="100%">
                <LspTypography
                    variant="heading2"
                    color={selected ? "blue" : "primary"}
                >
                    {item?.left?.title.toUpperCase()}
                </LspTypography>
            </Box>

            <div className={classes.advanceItem}>
                <div>
                    <LspTypography variant="body2" color="grey">
                        {item?.left?.label}
                    </LspTypography>
                    <LspTypography variant="heading1" color="primary">
                        <NumberFormat
                            value={item?.left?.amount}
                            displayType="text"
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                        />
                    </LspTypography>
                </div>
                <div>
                    <LspTypography
                        variant="body2"
                        color="grey"
                        className={classes.label}
                    >
                        {item?.right?.label}
                    </LspTypography>
                    <LspTypography variant="heading2" color="black">
                        <NumberFormat
                            value={item?.right?.amount}
                            displayType="text"
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                        />
                    </LspTypography>
                </div>
            </div>
        </ButtonBase>
    );
};

LspCardItemAdvance.propTypes = {
    selected: PropTypes.bool,
    classes: PropTypes.object,
    onClick: PropTypes.func,
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        left: PropTypes.shape({
            label: PropTypes.string,
            amount: PropTypes.number,
        }),
        right: PropTypes.shape({
            label: PropTypes.string,
            amount: PropTypes.number,
        }),
    }),
};

LspCardItemAdvance.defaultProps = {
    selected: false,
    onClick: null,
    classes: null,
    item: null,
};

export default memo(LspCardItemAdvance);
