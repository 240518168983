/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core";
import { memo, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import clsx from "clsx";
import { CheckCircleIcon, CloseCircleIcon } from "./LspIcon";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    checkerIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.magicalGrey[400],
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    unchecked: {
        color: theme.palette.error.main,
    },
    checked: {
        color: theme.palette.green.main,
    },
    container: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(0.5),
        alignItems: "center",
    },
    dot: {
        background: theme.palette.magicalGrey[400],
        width: 12,
        height: 12,
        borderRadius: "50%",
    },
}));

const LspChecker = ({
    label,
    regExp,
    input,
    onDataChange,
    type,
    checked,
    emptyIcon,
    wrapperClass,
    iconClass,
}) => {
    const classes = useStyles();

    const [result, setResult] = useState(null);

    useEffect(() => {
        if (type === "regExp") {
            const reg = new RegExp(regExp);
            const resultRegExp = reg.test(input);
            setResult(resultRegExp);
        } else {
            setResult(checked);
        }
    }, [input, regExp]);

    useEffect(() => {
        if (isFunction(onDataChange)) {
            onDataChange({ result });
        }
    }, [result]);

    const isEmptyInput = useMemo(() => input.length === 0, [input]);

    const handleTextColor = useMemo(() => {
        if (isEmptyInput) {
            return "grey";
        }

        return result ? "success" : "error";
    }, [isEmptyInput, result]);

    return (
        <>
            <div
                className={clsx(classes.container, {
                    [wrapperClass]: wrapperClass,
                })}
            >
                <div className={classes.checkerIcon}>
                    {isEmptyInput && (
                        <>
                            {emptyIcon && (
                                <div className={iconClass}>{emptyIcon}</div>
                            )}
                            {!emptyIcon && <div className={classes.dot} />}
                        </>
                    )}
                    {result && !isEmptyInput && (
                        <CheckCircleIcon className={classes.checked} vb={24} />
                    )}
                    {!result && !isEmptyInput && (
                        <CloseCircleIcon
                            className={classes.unchecked}
                            vb={16}
                        />
                    )}
                </div>
                <LspTypography
                    variant="body2"
                    color={handleTextColor}
                    component="div"
                >
                    {label}
                </LspTypography>
            </div>
        </>
    );
};

LspChecker.propTypes = {
    input: PropTypes.string || PropTypes.number,
    regExp: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    checked: PropTypes.bool,
    wrapperClass: PropTypes.any,
    iconClass: PropTypes.any,
};

LspChecker.defaultProps = {
    input: "",
    regExp: "",
    label: "",
    type: "regExp",
    checked: false,
    wrapperClass: null,
    iconClass: null,
};

export default memo(LspChecker);
