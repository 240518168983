import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import useStepNavigation from "@helpers/useStepNavigation";
import { useCallback, useRef, memo, useState } from "react";
import { connect } from "react-redux";
import wealthAction from "@redux/actions/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { useTranslation } from "react-i18next";
import LspCongratulation from "@components/LspCongratulation";
import wealthService from "@services/wealth";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import WealthLoansDocument from "./WealthLoansDocument";
import WealthLoansForm from "./WealthLoansForm";
import { WEALTH_PRODUCT_KEYS, WEALTH_STATUSES } from "../wealthConstants";
import WealthLoansConfirmation from "./WealthLoansConfirmation";

const LOANS_STEPS = {
    FORM: 0,
    DOCUMENT: 1,
    CONFIRMATION: 2,
    CONGRATULATION: 3,
};

const WealthLoans = ({ getProductList, productList }) => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const classes = useClassesShared();
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: LOANS_STEPS.FORM,
    });
    const unmounted = useRef(false);
    const selectedPurpose = useRef(null);
    const loanForm = useRef({
        purpose: "",
        amount: "",
        period: "",
    });
    const [requesting, setRequesting] = useState(false);

    // ------ Start Submit info section

    const applyForm = useCallback(async () => {
        if (!loanForm.current) return;
        setRequesting(true);

        const payload = {
            amount: loanForm.current?.amount?.value,
            period: parseInt(loanForm.current?.period),
            purpose: t(selectedPurpose.current.name),
            productKey: WEALTH_PRODUCT_KEYS.LOANS,
        };

        const response = await wealthService.applyProduct(payload);

        if (unmounted.current) return;

        setRequesting(false);

        const { code } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            loanForm.current = { purpose: "", amount: "", period: "" };
            getProductList();
            stepForward(LOANS_STEPS.CONGRATULATION);
        } else {
            GlobalDialogController.showError({
                errorCode: code,
            });
        }
    }, [t, getProductList, stepForward]);

    const checkApplyAvailable = () => {
        const productInfo = productList?.filter(
            (item) => item.productKey === WEALTH_PRODUCT_KEYS.LOANS
        );

        if (productInfo[0]?.status === WEALTH_STATUSES.APPLIED) {
            stepForward(LOANS_STEPS.CONFIRMATION);
        } else {
            applyForm();
        }
    };

    const onNextLoansFormHandler = (data) => {
        selectedPurpose.current = data?.purpose;
        loanForm.current = { ...data, purpose: data?.purpose?.id };

        if (isSmDown) {
            stepForward(LOANS_STEPS.DOCUMENT);
        } else {
            checkApplyAvailable();
        }
    };
    // ------ End Submit info section

    return (
        <div className={classes.wrapper}>
            {/* For small devices: hide left side column & loan document will be display when users click apply on loans form */}
            <Hidden smDown>
                <div className={classes.leftSide}>
                    <WealthLoansDocument />
                </div>
            </Hidden>
            {/* For small devices:  users click apply > show loans document > next > submit form */}
            <div className={classes.rightSide}>
                {step.current === LOANS_STEPS.FORM && (
                    <WealthLoansForm
                        onSubmit={onNextLoansFormHandler}
                        formInfo={loanForm.current}
                        loading={requesting}
                    />
                )}
                {step.current === LOANS_STEPS.DOCUMENT && (
                    <WealthLoansDocument
                        onSubmit={checkApplyAvailable}
                        loading={requesting}
                        onBack={stepBack}
                    />
                )}
                {step.current === LOANS_STEPS.CONFIRMATION && (
                    <WealthLoansConfirmation
                        onSubmit={checkApplyAvailable}
                        onCancel={stepBack}
                        loading={requesting}
                    />
                )}
                {step.current === LOANS_STEPS.CONGRATULATION && (
                    <LspCongratulation
                        icon="like"
                        title={t("wealth:VL00009")}
                        contents={t("wealth:VL00010", { returnObjects: true })}
                        button={{
                            label: t("wealth:VL00011"),
                            onClick: () => stepForward(LOANS_STEPS.FORM),
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const mapState = (state) => ({
    responseData: state.wealth.applyProductResponse,
    productList: state.wealth.list,
});

const mapDispatch = (dispatch) => ({
    getProductList: () => dispatch(wealthAction.getProductListRequest()),
});

export default memo(connect(mapState, mapDispatch)(WealthLoans));
