import produce from "immer";
import { LayoutActionType } from "../actions/layout";

/* ------------- Initial State ------------- */
const initialState = {
    sideMenuOpened: false,
};

/* ------------- Reducer ------------- */
const layoutReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LayoutActionType.OpenSideMenu:
                draft.sideMenuOpened = true;
                break;
            case LayoutActionType.CloseSideMenu:
                draft.sideMenuOpened = false;
                break;
            default:
                break;
        }
    });

export default layoutReducer;
