import ColorBar from "@components/ColorBar";
import ContentModal from "@components/ContentModal";
import { LANGUAGE_OPTIONS, RESPONSE_CODE } from "@config/constants";
import useSharedClasses from "@helpers/styleContentModal";
import activationService from "@services/activation";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import querystring from "query-string";
import i18n from "@i18n/";
import useLanguage from "@helpers/useLanguage";
import { Skeleton } from "@material-ui/lab";

const SUBMITTED_STATUES = {
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    SUCCESS: "SUCCESS",
};

const VerifyEmailMobileJoin = () => {
    const sharedClasses = useSharedClasses();
    const { t } = useTranslation();
    const { switchLanguage } = useLanguage();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { token, name: fullName } = params;

    const currentLanguage = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? LANGUAGE_OPTIONS.EN.toUpperCase()
            : LANGUAGE_OPTIONS.VN.toUpperCase();
    }, []);

    const [result, setResult] = useState({
        title: "",
        status: "",
        message: null,
        subtitle: "",
    });

    const [verifying, setVerifying] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const verifyMailMobileJoin = useCallback(
        async (currentToken) => {
            if (!currentToken) return;
            setVerifying(true);

            const response = await activationService.verifyMailMobileJoin({
                joinToken: currentToken,
            });

            if (unmounted.current) return;

            const { code } = response.data;
            setVerifying(false);

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    // eslint-disable-next-line prefer-const
                    let title = t("join_verify_email_mobile_join_title");
                    if (fullName) {
                        const nameParts = fullName?.split("_");
                        const name = nameParts.join(" ");
                        title = title?.replace("%@", name);
                    }

                    setResult({
                        status: SUBMITTED_STATUES.SUCCESS,
                        title,
                        subtitle: t("join_verify_email_mobile_join_subtitle"),
                        message: t("join_verify_email_mobile_join_desc"),
                    });
                    break;
                case RESPONSE_CODE.TOKEN_EXPIRED:
                case RESPONSE_CODE.TOKEN_INVALID:
                    // show dialog TO DO
                    setResult({
                        status: SUBMITTED_STATUES.TOKEN_EXPIRED,
                        title: t(
                            "title_invalid_url_verification_email_profile"
                        ),
                        message: t(
                            "txt_invalid_url_verification_email_profile"
                        ),
                    });
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [t, fullName]
    );

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const languageContent = useMemo(() => {
        const languages = Object.keys(LANGUAGE_OPTIONS).filter(
            (item) => item !== LANGUAGE_OPTIONS.VI.toUpperCase()
        );
        return languages
            .sort((a, b) => b.localeCompare(a))
            .map((item, index) => (
                <span key={Math.random()}>
                    <span
                        className={`${
                            currentLanguage === item ? "active" : ""
                        }`}
                        onClick={
                            currentLanguage !== item ? changeLanguage : null
                        }
                    >
                        {item}
                    </span>
                    {index !== languages?.length - 1 && <span>|</span>}
                </span>
            ));
    }, [currentLanguage, changeLanguage]);

    useEffect(() => {
        verifyMailMobileJoin(token);
    }, [verifyMailMobileJoin, token]);

    const loadingLayer = useMemo(() => {
        return (
            <div>
                <div className={sharedClasses.desc}>
                    <Skeleton />
                </div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
        );
    }, [sharedClasses]);

    return (
        <ContentModal supportMobile>
            <div
                className={`${sharedClasses.modalForm} ${sharedClasses.modalFormMobile}`}
            >
                <div className={sharedClasses.language}>{languageContent}</div>
                <div
                    className={`${sharedClasses.modalFormContent} ${sharedClasses.hasLanguage} ${sharedClasses.modalFormContentMobile}`}
                >
                    {verifying && loadingLayer}
                    {!verifying && (
                        <>
                            <div className={sharedClasses.title}>
                                {result?.title}
                            </div>
                            {result?.subtitle && (
                                <div className={sharedClasses.succeedMsg}>
                                    <span>{result?.subtitle}</span>
                                </div>
                            )}
                            <div
                                className={clsx(sharedClasses.succeedMsg, {
                                    [sharedClasses.errorMsg]:
                                        result?.status ===
                                        SUBMITTED_STATUES.TOKEN_EXPIRED,
                                })}
                            >
                                <span>{result?.message}</span>
                            </div>
                        </>
                    )}
                </div>
                <ColorBar
                    className={`${sharedClasses.colorBar} ${sharedClasses.customColorBar}`}
                />
            </div>
        </ContentModal>
    );
};

export default VerifyEmailMobileJoin;
