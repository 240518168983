import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Interweave from "interweave";
import { Box, Grid, Icon, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import { useHistory } from "react-router-dom";

import PageHeaderBack from "@components/PageHeaderBack";
import DoubleButtons from "@components/DoubleButtons";
import LspTextField from "@components/LspTextField";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspButton from "@components/LspButton";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(3),
        width: "100%",
        margin: "auto",
        boxShadow: theme.shadows[1],
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
    },
    form: {
        textAlign: "center",
    },
}));

const CardPin = ({
    className,
    onClose,
    nextHandler,
    sysParam,
    type,
    submitting,
}) => {
    const classes = useStyles();

    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const { t } = useTranslation();
    const history = useHistory();
    const { register, handleSubmit, errors, getValues, setValue } = useForm();
    const [pinRules, setPinRules] = useState([]);

    const primaryButton = {
        label: t("lb_next"),
    };

    const secondaryButton = {
        label: t("lb_cancel"),
        onClick: () => {
            if (type === "dialog") {
                GlobalDialogController.hide();
            } else {
                history.push("/card-management/debit");
            }
        },
    };

    const loadPinRules = useCallback(() => {
        const pinCheck = t("sysParamContent:PinCheck").split(",");
        const pinRulesTmp = [];

        for (let i = 0; i < pinCheck.length; i++) {
            const currentNameRule = pinCheck[i];
            const rule =
                sysParam[currentNameRule] ||
                t(`sysParamContent:${currentNameRule}`);
            pinRulesTmp.push({
                name: currentNameRule,
                rule,
                error: t(`ekyc:${currentNameRule}_Match_Error`),
            });
        }
        setPinRules(pinRulesTmp);
    }, [t, sysParam]);

    const isInvalidPinCheck = (pin) => {
        let isInValid = false;
        let error = "";

        for (let i = 0; i < pinRules.length; i++) {
            const currentPinRule = pinRules[i];
            const regexString = currentPinRule.rule.replace("\\\\", "\\");
            const regex = new RegExp(regexString, "g");
            isInValid = regex.test(pin);
            if (isInValid) {
                error = currentPinRule.error;
                break;
            }
        }

        return {
            isInValid,
            error,
        };
    };

    const pinValidation = (pin) => {
        const isInvalidPin = isInvalidPinCheck(pin);
        if (isInvalidPin.isInValid) {
            return isInvalidPin.error;
        }
    };

    const pinConfirmValidation = (pinConfirm) => {
        const pin = getValues("pin");

        if (pin !== pinConfirm) {
            return t("mc_lb_enter_pin_error");
        }

        return true;
    };

    const submitPin = (data) => {
        if (nextHandler) {
            nextHandler(data.pin);
        }
    };

    const onChangePin = (e) => {
        const pin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });
        setValue("pin", pin);
    };

    useEffect(() => {
        loadPinRules();
    }, [loadPinRules]);

    const onBack = () => {
        onClose();
    };

    return (
        <Box className={className} marginTop={2}>
            <Grid container direction="row" justify="space-between">
                <Grid item md={9} xs={11} className={classes.wrapper}>
                    <form
                        onSubmit={handleSubmit(submitPin)}
                        className={classes.form}
                    >
                        <PageHeaderBack onBack={onBack}>
                            <div>
                                <Icon className="font-icon icon-Timo-Plus-Phone" />
                                <div>
                                    <Interweave
                                        content={t(
                                            "out_active_card_setup_new_pin"
                                        )}
                                    />
                                </div>
                            </div>
                        </PageHeaderBack>
                        <Box marginTop={4}>
                            <LspTextField
                                autoFocus
                                error={!!errors.pin}
                                helperText={errors.pin?.message || " "}
                                name="pin"
                                label={t("out_active_card_your_new_pin_hint")}
                                inputProps={{
                                    maxLength: 6,
                                    type: "password",
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: (value) =>
                                            pinValidation(value),
                                    }),
                                }}
                                onChange={onChangePin}
                            />
                            <LspTextField
                                error={!!errors.pinConfirm}
                                helperText={errors.pinConfirm?.message || " "}
                                name="pinConfirm"
                                label={t(
                                    "out_active_card_confirm_new_pin_hint"
                                )}
                                inputProps={{
                                    type: "password",
                                    maxLength: 6,
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: (value) =>
                                            pinConfirmValidation(value),
                                    }),
                                }}
                            />
                        </Box>

                        {onClose && (
                            <DoubleButtons
                                primaryButton={primaryButton}
                                secondaryButton={secondaryButton}
                                progressing={submitting}
                            />
                        )}

                        {!onClose && (
                            <LspButton
                                type="submit"
                                progressing={submitting}
                                fullWidth
                            >
                                {t("lb_next")}
                            </LspButton>
                        )}
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
};

const stateProps = (state) => ({
    sysParam: state.systemParams.info.sysparams["1.0"],
    submitting: state.cardInfo.activateCardLoading,
});

export default connect(stateProps)(CardPin);
