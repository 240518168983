export const OTPDialogType = {
    DeviceAuthorization: "OTPDialogType/DeviceAuthorization",
    GGTwoFactor: "OTPDialogType/GGTwoFactor",
    TwoFactor: "OTPDialogType/TwoFactor",
    MoveMoney: "OTPDialogType/MoveMoney",
    MoveMoneyByPhone: "OTPDialogType/MoveMoneyByPhone",
    UpdateCardStatus: "OTPDialogType/UpdateCardStatus",
    ResetPin: "OTPDialogType/ResetPin",
    ReIssueCard: "OTPDialogType/ReIssueCard",
    CardActivation: "OTPDialogType/CardActivation",
    TopUp: "OTPDialogType/TopUp",
    ClaimMoney: "OTPDialogType/ClaimMoney",
    CloseInstallmentEarly: "OTPDialogType/CloseInstallmentEarly",
    ForgotPassword: "OTPDialogType/ForgotPassword",
    PaymentRequest: "OTPDialogType/PaymentRequest",
    MaskRequest: "OTPDialogType/MaskRequest",
    CancelRequest: "OTPDialogType/CancelRequest",
    CreateAlias: "OTPDialogType/CreateAlias",
};

export default class OTPController {
    static opening;

    static openDialog;

    static closeDialog;

    static setOpen(open) {
        this.openDialog = open;
    }

    static setClose(close) {
        this.closeDialog = close;
    }

    /**
     * Show OTP dialog
     * @param {OTPDialogType} type
     */
    static show(type) {
        this.opening = true;
        this.openDialog({ type });
    }

    /**
     * Close OTP dialog
     */
    static hide() {
        this.opening = false;
        this.closeDialog();
    }
}
