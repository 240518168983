import { VALIDATION_ERROR_TYPES } from "@config/constants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const usePhoneValidation = () => {
    const { t } = useTranslation();
    const phoneConfigs = useSelector(
        (state) => state.systemParams.phoneConfigs
    );

    const phoneValidation = useCallback(
        (phone, isCheckPrefixPhone = false) => {
            if (!phone || phone === "") {
                return {
                    errorMsg: t("msg_we_need_this"),
                    errorType: VALIDATION_ERROR_TYPES.EMPTY,
                    valid: false,
                };
            }
            if (phone.length <= 9) {
                return {
                    errorMsg: t("topup_msg_invalid_mobile"),
                    errorType: VALIDATION_ERROR_TYPES.INVALID,
                    valid: false,
                };
            }

            if (phone && isCheckPrefixPhone) {
                // Check valid phone number with prefix list from BE
                let hasInList = false;
                for (let i = 0; i < phoneConfigs.length; i++) {
                    const prefixList = phoneConfigs[i].prefix;
                    const phonePrefix = phone.substr(0, 3);

                    const isExisting = prefixList.includes(phonePrefix);
                    if (isExisting) {
                        hasInList = true;
                        break;
                    }
                }

                if (!hasInList) {
                    return {
                        errorMsg: t("topup_msg_invalid_mobile"),
                        errorType: VALIDATION_ERROR_TYPES.NO_SUPPORT,
                        valid: false,
                    };
                }
            }

            return {
                errorMsg: "",
                errorType: "",
                valid: true,
            };
        },
        [phoneConfigs, t]
    );

    const validation = useMemo(
        () => ({
            phoneValidation,
        }),
        [phoneValidation]
    );

    return validation;
};

export default usePhoneValidation;
