import qrCodeGold from "@assets/images/qr_code_gold.png";
import bankIcon from "@assets/images/bank_icon.png";
import { makeStyles } from "@material-ui/core";
import html2canvas from "html2canvas";

import VietQRCode from "@containers/BankQRCode/VietQRCode";
import { memo, useMemo, useRef } from "react";
import { connect } from "react-redux";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import BankQRCodeSection from "@containers/BankQRCode/BankQRCodeSection";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { METRIC_NAME, METRIC_SCREEN } from "@config/constants";
import AlertController from "@helpers/controllers/AlertController";
import SplitBillCard from "../SplitBillCard";

const useStyles = makeStyles((theme) => ({
    paymentContentItem: {
        borderBottom: `2px solid rgba(239, 240, 232, 0.5)`,
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        position: "relative",
        "&:last-child": {
            marginBottom: 0,
            borderBottom: 0,
            paddingBottom: 0,
        },
    },
    paymentContentItemContentQR: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    paymentContentItemContentBank: {
        display: "flex",
        flexDirection: "column",
    },
    paymentContentItemContentBankContainer: {},
    paymentContentItemContentBankContent: {
        marginBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    paymentContentItemContentBankText: {
        flex: 1,
        marginRight: theme.spacing(1),
        "&:last-child": {
            marginRight: 0,
        },
    },
    paymentContentItemContentBankItemAction: {},
    paymentContentItemLeft: {
        width: 120,
        height: 120,
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
    paymentContentItemRight: {
        flex: 1,
    },
    paymentContentItemAction: {
        display: "flex",
        justifyContent: "center",
    },
    paymentContentItemTitle: {
        opacity: 0.6,
    },
    paymentContentItemText: {},
    paymentQrCodeDownload: {
        position: "absolute",
        left: "-2000px",
    },
}));

const PaymentContent = ({ payment, fetching, metricConfig }) => {
    const classes = useStyles();
    const qrCodeRef = useRef();
    const { t } = useTranslation();

    const sbWebMethodDetail = t("splitBill:sb_web_method_detail", {
        returnObjects: true,
    });

    const handleDownloadQr = () => {
        html2canvas(qrCodeRef.current).then((canvas) => {
            const qrDownload = document.createElement("a");
            const replaceAllNameSpace = (payment?.senderName || "").replaceAll(
                " ",
                "_"
            );
            qrDownload.download = `TIMO_Split_Bill_to_${replaceAllNameSpace}-${format(
                new Date(),
                "dd/MM/yyyy HH:mm"
            )}.png`;
            qrDownload.href = canvas.toDataURL("image/png");
            qrDownload.target = "_blank";
            qrDownload.click();
        });
    };

    const handleCopy = (data) => {
        navigator.clipboard.writeText(data);
        AlertController.show(t("member:timostarclub_msg_copy_successful"));
    };

    const fetchingLayer = useMemo(
        () => (
            <>
                <Skeleton animation="wave" height={20} width="30%" />
                <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 8 }}
                />
                <Skeleton animation="wave" height={20} width="30%" />
                <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 8 }}
                />
                <Skeleton animation="wave" height={20} width="30%" />
                <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 8 }}
                />
                <Skeleton animation="wave" height={20} width="30%" />
                <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 8 }}
                />
            </>
        ),
        []
    );

    return (
        <>
            <div className={classes.paymentContentItem}>
                {payment && (
                    <div
                        ref={qrCodeRef}
                        className={classes.paymentQrCodeDownload}
                    >
                        <BankQRCodeSection
                            defaultInfo={payment}
                            disableDefault
                            hiddenSaveButton
                            activeAmount
                            enableIBFT={payment?.enableIBFTGW}
                        />
                    </div>
                )}
                <SplitBillCard
                    loading={fetching}
                    icon={<img src={qrCodeGold} alt="QR Icon" />}
                    title={sbWebMethodDetail[0]?.sb_method_type}
                >
                    <div className={classes.paymentContentItemContentQR}>
                        <div className={classes.paymentContentItemLeft}>
                            <VietQRCode
                                size={115}
                                accountNumber={payment?.accNumber}
                                amount={payment?.amount}
                                description={payment?.description || ""}
                                iconSize={35}
                                level="M"
                                activeAmount
                                loading={fetching}
                                enableIBFT={payment?.enableIBFTGW}
                            />
                        </div>
                        {fetching && (
                            <div className={classes.paymentContentItemRight}>
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="80%"
                                    style={{ marginBottom: 8 }}
                                />
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="100%"
                                />
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="80%"
                                />
                            </div>
                        )}
                        {!fetching && (
                            <div className={classes.paymentContentItemRight}>
                                <LspTypography variant="body2" color="black">
                                    <Interweave
                                        content={
                                            sbWebMethodDetail[0]
                                                ?.sb_method_qr_details1
                                        }
                                    />
                                </LspTypography>
                                <LspTypography variant="body2" color="black">
                                    <Interweave
                                        content={
                                            sbWebMethodDetail[0]
                                                ?.sb_method_qr_details2
                                        }
                                    />
                                </LspTypography>
                            </div>
                        )}
                    </div>
                    <div className={classes.paymentContentItemAction}>
                        {fetching && (
                            <Skeleton
                                animation="wave"
                                height={20}
                                width="30%"
                            />
                        )}
                        {!fetching && (
                            <LspButton
                                enableMetric
                                forceRequest
                                metricData={{
                                    screen_name:
                                        METRIC_SCREEN.SPLIT_BILL_PAYMENT,
                                    metric_name: METRIC_NAME.TAP_DOWNLOAD,
                                    detail_data: {},
                                    screen_session_id:
                                        metricConfig.screenSessionId,
                                }}
                                variant="secondary"
                                size="small"
                                onClick={handleDownloadQr}
                            >
                                {sbWebMethodDetail[0]?.sb_method_qr_cta}
                            </LspButton>
                        )}
                    </div>
                </SplitBillCard>
            </div>
            <div className={classes.paymentContentItem}>
                <SplitBillCard
                    loading={fetching}
                    icon={<img src={bankIcon} alt="Bank Icon" />}
                    title={sbWebMethodDetail[1]?.sb_method_type}
                >
                    <div className={classes.paymentContentItemContentBank}>
                        {fetching && fetchingLayer}
                        {!fetching && (
                            <>
                                <div
                                    className={
                                        classes.paymentContentItemContentBankContainer
                                    }
                                >
                                    <LspTypography
                                        className={
                                            classes.paymentContentItemTitle
                                        }
                                        variant="body3"
                                        color="black"
                                    >
                                        {
                                            sbWebMethodDetail[1]
                                                ?.sb_method_transfer_fullname
                                        }
                                    </LspTypography>

                                    <div
                                        className={
                                            classes.paymentContentItemContentBankContent
                                        }
                                    >
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankText
                                            }
                                        >
                                            <LspTypography
                                                variant="subheading3"
                                                color="black"
                                            >
                                                {payment?.senderName}
                                            </LspTypography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.paymentContentItemContentBankContainer
                                    }
                                >
                                    <LspTypography
                                        className={
                                            classes.paymentContentItemTitle
                                        }
                                        variant="body3"
                                        color="black"
                                    >
                                        {
                                            sbWebMethodDetail[1]
                                                ?.sb_method_transfer_bank_acc
                                        }
                                    </LspTypography>
                                    <div
                                        className={
                                            classes.paymentContentItemContentBankContent
                                        }
                                    >
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankText
                                            }
                                        >
                                            <LspTypography
                                                variant="subheading3"
                                                color="black"
                                            >
                                                {payment?.accNumber}
                                            </LspTypography>
                                        </div>
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankItemAction
                                            }
                                        >
                                            <LspButton
                                                variant="secondary"
                                                size="small"
                                                onClick={() =>
                                                    handleCopy(
                                                        payment?.accNumber
                                                    )
                                                }
                                                enableMetric
                                                forceRequest
                                                metricData={{
                                                    screen_name:
                                                        METRIC_SCREEN.SPLIT_BILL_PAYMENT,
                                                    metric_name:
                                                        METRIC_NAME.TAP_COPY_ACC,
                                                    detail_data: {},
                                                    screen_session_id:
                                                        metricConfig.screenSessionId,
                                                }}
                                            >
                                                {
                                                    sbWebMethodDetail[1]
                                                        ?.sb_method_transfer_cta
                                                }
                                            </LspButton>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.paymentContentItemContentBankContainer
                                    }
                                >
                                    <LspTypography
                                        className={
                                            classes.paymentContentItemTitle
                                        }
                                        variant="body3"
                                        color="black"
                                    >
                                        {
                                            sbWebMethodDetail[1]
                                                ?.sb_method_transfer_bank_name
                                        }
                                    </LspTypography>

                                    <div
                                        className={
                                            classes.paymentContentItemContentBankContent
                                        }
                                    >
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankText
                                            }
                                        >
                                            <LspTypography
                                                variant="body2"
                                                color="black"
                                            >
                                                {payment?.enableIBFTGW
                                                    ? payment?.bankNameDomestic ||
                                                      ""
                                                    : `${payment?.bankShortName} - ${payment?.bankName}`}
                                            </LspTypography>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        classes.paymentContentItemContentBankContainer
                                    }
                                >
                                    <LspTypography
                                        className={
                                            classes.paymentContentItemTitle
                                        }
                                        variant="body3"
                                        color="black"
                                    >
                                        {
                                            sbWebMethodDetail[1]
                                                ?.sb_method_transfer_description
                                        }{" "}
                                        :
                                    </LspTypography>
                                    <div
                                        className={
                                            classes.paymentContentItemContentBankContent
                                        }
                                    >
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankText
                                            }
                                        >
                                            <LspTypography
                                                variant="body2"
                                                color="black"
                                            >
                                                {payment?.description}
                                            </LspTypography>
                                        </div>
                                        <div
                                            className={
                                                classes.paymentContentItemContentBankItemAction
                                            }
                                        >
                                            <LspButton
                                                variant="secondary"
                                                size="small"
                                                onClick={() =>
                                                    handleCopy(
                                                        payment?.description
                                                    )
                                                }
                                                enableMetric
                                                forceRequest
                                                metricData={{
                                                    screen_name:
                                                        METRIC_SCREEN.SPLIT_BILL_PAYMENT,
                                                    metric_name:
                                                        METRIC_NAME.TAP_COPY_DES,
                                                    detail_data: {},
                                                    screen_session_id:
                                                        metricConfig.screenSessionId,
                                                }}
                                            >
                                                {
                                                    sbWebMethodDetail[1]
                                                        ?.sb_method_transfer_cta
                                                }
                                            </LspButton>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </SplitBillCard>
            </div>
        </>
    );
};

PaymentContent.propTypes = {
    fetching: PropTypes.bool,
};

PaymentContent.defaultProps = {
    fetching: false,
};

const stateProps = (state) => ({
    payment: state.bill.splitBillPayment.payment,
    fetching: state.bill.splitBillPayment.fetching,
    metricConfig: state.metrics?.metricConfig,
});

export default memo(connect(stateProps)(PaymentContent));
