/* ------------- Types and Action Definition ------------- */
export const CardManagementActionTypes = Object.freeze({
    GetCardList: "CardManagementActionTypes/GetCardList",
    GetCardListSuccess: "CardManagementActionTypes/GetCardListSuccess",
    GetCardListError: "CardManagementActionTypes/GetCardListError",
    ResetGetCardListStatus: "CardManagementActionTypes/ResetGetCardListStatus",

    UpdateCardStatus: "CardManagementActionTypes/UpdateCardStatus",
    UpdateCardStatusSuccess:
        "CardManagementActionTypes/UpdateCardStatusSuccess",
    UpdateCardStatusError: "CardManagementActionTypes/UpdateCardStatusError",
    OTPRequired: "CardManagementActionTypes/OTPRequired",
    OTPCancel: "CardManagementActionTypes/OTPCancel",
    OTPCommit: "CardManagementActionTypes/OTPCommit",
    OTPCommitFinished: "CardManagementActionTypes/OTPCommitFinished",
    UpdateECommerce: "CardManagementActionTypes/UpdateECommerce",
    UpdateECommerceSuccess: "CardManagementActionTypes/UpdateECommerceSuccess",
    UpdateECommerceError: "CardManagementActionTypes/UpdateECommerceError",

    ResetPin: "CardManagementActionTypes/ResetPin",
    ResetPinSuccess: "CardManagementActionTypes/ResetPinSuccess",
    ResetPinError: "CardManagementActionTypes/ResetPinError",
    OTPCommitResetPin: "CardManagementActionTypes/OTPCommitResetPin",

    GetHangoutListReIssue: "CardManagementActionTypes/GetHangoutListReIssue",
    GetHangoutListReIssueSuccess:
        "CardManagementActionTypes/GetHangoutListReIssueSuccess",
    GetHangoutListReIssueError:
        "CardManagementActionTypes/GetHangoutListReIssueError",

    ReIssueCard: "CardManagementActionTypes/ReIssueCard",
    ReIssueCardSuccess: "CardManagementActionTypes/ReIssueCardSuccess",
    ReIssueCardError: "CardManagementActionTypes/ReIssueCardError",
    ReIssueCardV2: "CardManagementActionTypes/ReIssueCardV2",
    OTPCommitReIssueCard: "CardManagementActionTypes/OTPCommitReIssueCard",

    CheckCardNumber: "CardManagementActionTypes/CheckCardNumber",
    CheckCardNumberSuccess: "CardManagementActionTypes/CheckCardNumberSuccess",
    CheckCardNumberError: "CardManagementActionTypes/CheckCardNumberError",
    ResetStatus: "CardManagementActionTypes/ResetStatus",
    CardActivation: "CardManagementActionTypes/CardActivation",
    CardActivationSuccess: "CardManagementActionTypes/CardActivationSuccess",
    CardActivationError: "CardManagementActionTypes/CardActivationError",
    OTPCommitCardActivation:
        "CardManagementActionTypes/OTPCommitCardActivation",
    GetReissuedCard: "CardManagementActionTypes/GetReissuedCard",
    ModifyReissuedCard: "CardManagementActionTypes/ModifyReissuedCard",
    OTPRequiredV2: "CardManagementActionTypes/OTPRequiredV2",
    OTPCancelV2: "CardManagementActionTypes/OTPCancelV2",
    OTPCommitV2: "CardManagementActionTypes/OTPCommitV2",
    OTPCommitFinishedV2: "CardManagementActionTypes/OTPCommitFinishedV2",
    GetUserAddressRequest: "CardManagementActionTypes/GetUserAddressRequest",
    GetUserAddressSuccess: "CardManagementActionTypes/GetUserAddressSuccess",
    GetUserAddressError: "CardManagementActionTypes/GetUserAddressError",
    ResetState: "CardManagementActionTypes/ResetState",
    ResetStateNewReissue: "CardManagementActionTypes/ResetStateNewReissue",
    GetMaskRequest: "CardManagementActionTypes/GetMaskRequest",
    GetMaskSuccess: "CardManagementActionTypes/GetMaskSuccess",
    GetMaskError: "CardManagementActionTypes/GetMaskError",
    ClearMaskData: "CardManagementActionTypes/ClearMaskData",
    GetMaskOTPRequest: "CardManagementActionTypes/GetMaskOTPRequest",
    GetMaskOTPSuccess: "CardManagementActionTypes/GetMaskOTPSuccess",
    GetMaskOTPError: "CardManagementActionTypes/GetMaskOTPError",
    CancelGetMaskOTP: "CardManagementActionTypes/CancelGetMaskOTP",

    // Card Limit
    CardLimitRequest: "CardManagementActionTypes/CardLimitRequest",
    CardLimitSuccess: "CardManagementActionTypes/CardLimitSuccess",
    CardLimitError: "CardManagementActionTypes/CardLimitError",
    ResetCardLimit: "CardManagementActionTypes/ResetCardLimit",

    // Get Card Limit
    GetCardLimitRequest: "CardManagementActionTypes/GetCardLimitRequest",
    GetCardLimitSuccess: "CardManagementActionTypes/GetCardLimitSuccess",
    GetCardLimitError: "CardManagementActionTypes/GetCardLimitError",
    SetReviewDataRequestCard:
        "CardManagementActionTypes/SetReviewDataRequestCard",
});

/* ------------- Actions Definition ------------- */

// ---- Get Card List

const getCardList = () => {
    return {
        type: CardManagementActionTypes.GetCardList,
    };
};

const getCardListSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetCardListSuccess,
        payload,
    };
};

const getCardListError = () => {
    return {
        type: CardManagementActionTypes.GetCardListError,
    };
};

const resetGetCardListStatus = () => {
    return {
        type: CardManagementActionTypes.ResetGetCardListStatus,
    };
};

// ----- Update Card Status

const updateCardStatus = (payload) => {
    return {
        type: CardManagementActionTypes.UpdateCardStatus,
        payload,
    };
};

const updateCardStatusSuccess = () => {
    return {
        type: CardManagementActionTypes.UpdateCardStatusSuccess,
    };
};

const updateCardStatusError = () => {
    return {
        type: CardManagementActionTypes.UpdateCardStatusError,
    };
};

const otpRequired = (payload) => {
    return {
        type: CardManagementActionTypes.OTPRequired,
        payload,
    };
};

const otpCancel = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCancel,
        payload,
    };
};

const otpCommit = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCommit,
        payload,
    };
};

const otpCommitFinished = () => {
    return {
        type: CardManagementActionTypes.OTPCommitFinished,
    };
};

const updateECommerce = (payload) => {
    return {
        type: CardManagementActionTypes.UpdateECommerce,
        payload,
    };
};

const updateECommerceSuccess = () => {
    return {
        type: CardManagementActionTypes.UpdateECommerceSuccess,
    };
};

const updateECommerceError = () => {
    return {
        type: CardManagementActionTypes.UpdateECommerceError,
    };
};

// ---- Reset Pin

const resetPin = (payload) => {
    return {
        type: CardManagementActionTypes.ResetPin,
        payload,
    };
};

const resetPinSuccess = () => {
    return {
        type: CardManagementActionTypes.ResetPinSuccess,
    };
};

const resetPinError = () => {
    return {
        type: CardManagementActionTypes.ResetPinError,
    };
};

const otpCommitResetPin = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCommitResetPin,
        payload,
    };
};

// ----- ReIssue Card

const getHangoutListReIssue = (payload) => {
    return {
        type: CardManagementActionTypes.GetHangoutListReIssue,
        payload,
    };
};

const getHangoutListReIssueSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetHangoutListReIssueSuccess,
        payload,
    };
};

const getHangoutListReIssueError = () => {
    return {
        type: CardManagementActionTypes.GetHangoutListReIssueError,
    };
};

const reIssueCard = (payload) => {
    return {
        type: CardManagementActionTypes.ReIssueCard,
        payload,
    };
};

const reIssueCardSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.ReIssueCardSuccess,
        payload,
    };
};

const reIssueCardError = () => {
    return {
        type: CardManagementActionTypes.ReIssueCardError,
    };
};

const reIssueCardV2 = (payload) => {
    return {
        type: CardManagementActionTypes.ReIssueCardV2,
        payload,
    };
};

const otpCommitReIssueCard = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCommitReIssueCard,
        payload,
    };
};

// ----- Card Activation

const checkCardNumber = (payload) => {
    return {
        type: CardManagementActionTypes.CheckCardNumber,
        payload,
    };
};

const checkCardNumberSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.CheckCardNumberSuccess,
        payload,
    };
};

const checkCardNumberError = () => {
    return {
        type: CardManagementActionTypes.CheckCardNumberError,
    };
};

const resetStatus = () => {
    return {
        type: CardManagementActionTypes.ResetStatus,
    };
};

const cardActivation = (payload) => {
    return {
        type: CardManagementActionTypes.CardActivation,
        payload,
    };
};

const cardActivationSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.CardActivationSuccess,
        payload,
    };
};

const cardActivationError = () => {
    return {
        type: CardManagementActionTypes.CardActivationError,
    };
};

const otpCommitCardActivation = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCommitCardActivation,
        payload,
    };
};

const getReissuedCard = () => {
    return {
        type: CardManagementActionTypes.GetReissuedCard,
    };
};

const modifyReissuedCard = (payload) => {
    return {
        type: CardManagementActionTypes.ModifyReissuedCard,
        payload,
    };
};

const otpRequiredV2 = (payload) => {
    return {
        type: CardManagementActionTypes.OTPRequiredV2,
        payload,
    };
};

const otpCancelV2 = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCancelV2,
        payload,
    };
};

const otpCommitV2 = (payload) => {
    return {
        type: CardManagementActionTypes.OTPCommitV2,
        payload,
    };
};

const otpCommitFinishedV2 = () => {
    return {
        type: CardManagementActionTypes.OTPCommitFinishedV2,
    };
};

// ---- Get Card List

const getUserAddressRequest = (payload) => {
    return {
        type: CardManagementActionTypes.GetUserAddressRequest,
        payload,
    };
};
const getUserAddressSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetUserAddressSuccess,
        payload,
    };
};
const getUserAddressError = () => {
    return {
        type: CardManagementActionTypes.GetUserAddressError,
    };
};

const resetState = () => {
    return {
        type: CardManagementActionTypes.ResetState,
    };
};

const resetStateNewReissue = () => {
    return {
        type: CardManagementActionTypes.ResetStateNewReissue,
    };
};

// ---- Get Mask Request

const getMaskRequest = (payload) => {
    return {
        type: CardManagementActionTypes.GetMaskRequest,
        payload,
    };
};

const getMaskSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetMaskSuccess,
        payload,
    };
};

const getMaskError = () => {
    return {
        type: CardManagementActionTypes.GetMaskError,
    };
};

const clearMaskData = (payload) => {
    return {
        type: CardManagementActionTypes.ClearMaskData,
        payload,
    };
};

const getMaskOTPRequest = (payload) => {
    return {
        type: CardManagementActionTypes.GetMaskOTPRequest,
        payload,
    };
};

const getMaskOTPSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetMaskOTPSuccess,
        payload,
    };
};

const getMaskOTPError = () => {
    return {
        type: CardManagementActionTypes.GetMaskOTPError,
    };
};

const cancelGetMaskOTP = () => {
    return {
        type: CardManagementActionTypes.CancelGetMaskOTP,
    };
};

// ---- Set Card Limit

const cardLimitRequest = (payload) => {
    return {
        type: CardManagementActionTypes.CardLimitRequest,
        payload,
    };
};

const cardLimitSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.CardLimitSuccess,
        payload,
    };
};

const cardLimitError = () => {
    return {
        type: CardManagementActionTypes.CardLimitError,
    };
};

const resetCardLimit = () => {
    return {
        type: CardManagementActionTypes.ResetCardLimit,
    };
};

// ---- Get Card Limit

const getCardLimitRequest = (payload) => {
    return {
        type: CardManagementActionTypes.GetCardLimitRequest,
        payload,
    };
};

const getCardLimitSuccess = (payload) => {
    return {
        type: CardManagementActionTypes.GetCardLimitSuccess,
        payload,
    };
};

const getCardLimitError = () => {
    return {
        type: CardManagementActionTypes.GetCardLimitError,
    };
};

const setReviewDataRequestCard = (payload) => {
    return {
        type: CardManagementActionTypes.SetReviewDataRequestCard,
        payload,
    };
};

const CardManagementActions = {
    getCardList,
    getCardListSuccess,
    getCardListError,
    resetGetCardListStatus,

    updateCardStatus,
    updateCardStatusSuccess,
    updateCardStatusError,
    otpRequired,
    otpCancel,
    otpCommit,
    otpCommitFinished,
    updateECommerce,
    updateECommerceSuccess,
    updateECommerceError,

    resetPin,
    resetPinSuccess,
    resetPinError,
    otpCommitResetPin,

    getHangoutListReIssue,
    getHangoutListReIssueSuccess,
    getHangoutListReIssueError,
    reIssueCard,
    reIssueCardSuccess,
    reIssueCardError,
    otpCommitReIssueCard,

    checkCardNumber,
    checkCardNumberSuccess,
    checkCardNumberError,
    resetStatus,
    cardActivation,
    cardActivationSuccess,
    cardActivationError,
    otpCommitCardActivation,
    getReissuedCard,
    modifyReissuedCard,

    reIssueCardV2,
    otpRequiredV2,
    otpCancelV2,
    otpCommitV2,
    otpCommitFinishedV2,

    getUserAddressRequest,
    getUserAddressSuccess,
    getUserAddressError,

    resetState,
    resetStateNewReissue,
    getMaskSuccess,
    getMaskRequest,
    getMaskError,
    clearMaskData,
    getMaskOTPRequest,
    getMaskOTPSuccess,
    getMaskOTPError,
    cancelGetMaskOTP,

    // card limit
    cardLimitRequest,
    cardLimitSuccess,
    cardLimitError,
    resetCardLimit,

    // get card limit
    getCardLimitRequest,
    getCardLimitSuccess,
    getCardLimitError,
    setReviewDataRequestCard,
};

export default CardManagementActions;
