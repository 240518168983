import { call, put, select } from "redux-saga/effects";
import parse from "date-fns/parse";
import { ALIAS_TYPE, RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import userService from "@services/user";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import LspTranslation from "@components/LspTranslation";
import {
    blackListNickname,
    createAliasSuccess,
    createNicknameFail,
    errorCreateNickname,
    existNickname,
    maxDailyLimit,
    notEnoughMoney,
} from "@containers/Nickname/dialog/nicknameHelper";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import tommyNoHope from "@assets/images/no_hope_with_status_error.png";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import userAction from "../actions/user";

const getDialogOpened = (state) => state.otpDialog.opened;

export function* getProfileSaga(action) {
    const { payload } = action;
    const response = yield call(userService.getProfile, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.getProfileSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getProfileError());
            break;
    }
}

export function* getBankInfoSaga() {
    const response = yield call(userService.getBankInfo);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.getBankInfoSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getBankInfoError());
            break;
    }
}

export function* getServerTimeSaga() {
    const response = yield call(userService.getServerTime);

    const {
        code,
        data: { serverTime },
    } = response.data;

    switch (code) {
        case 200:
            const parsedTime = parse(
                serverTime,
                "dd/MM/yyyy HH:mm:ss xx",
                new Date()
            );
            yield put(userAction.getServerTimeSuccess(parsedTime));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getServerTimeError());
            break;
    }
}

export function* getPayeeListSaga() {
    const response = yield call(userService.getPayeeList);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.getPayeeListSuccess(data));
            break;
        case RESPONSE_CODE.NOT_ACCEPTABLE: // Empty list
            yield put(
                userAction.getPayeeListSuccess({
                    results: [],
                    rows: 0,
                })
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getPayeeListError());
            break;
    }
}

export function* updateSettingSaga(action) {
    const response = yield call(userService.updateSetting, action.payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.updateSettingSuccess(data));
            yield put(userAction.getProfileRequest());
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.updateSettingError());
            break;
    }
}

export function* getFeatureState(action) {
    const { payload } = action;
    const response = yield call(userService.getFeatureState, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.getFeatureStateSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getFeatureStateError());
            break;
    }
}

export function* reissueCheck(action) {
    const { payload } = action;
    const response = yield call(userService.reissueCheck, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.reissueCheckSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.reissueCheckError());
            break;
    }
}

export function* modifyAlias(action) {
    const { payload } = action;
    const response = yield call(userService.modifyAliasName, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.modifyAliasSuccess(data));
            yield put(userAction.getBankInfoRequest());
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.modifyAliasError());
            break;
    }
}

export function* getAliasList(action) {
    const { payload } = action;
    const response = yield call(userService.aliasSetting, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            if (data?.aliasList && data?.aliasList.length > 0) {
                yield put(userAction.getAliasSuccess(data?.aliasList || []));
            } else {
                yield put(userAction.getAliasError());
            }
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getAliasError());
            break;
    }
}

export function* toggleAlias(action) {
    const { payload } = action;

    const response = yield call(userService.toggleAlias, payload);

    const isNickname = payload?.type === ALIAS_TYPE.ALIAS_NICKNAME;

    const { code } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            if (payload?.enable) {
                AlertController.show(
                    <LspTranslation
                        i18nKey={
                            isNickname
                                ? "IBFT:IBFT_alias_setting_nickname_push_on"
                                : "IBFT:IBFT_alias_setting_push_on"
                        }
                    />,
                    AlertType.Success
                );
            } else {
                AlertController.show(
                    <LspTranslation
                        i18nKey={
                            isNickname
                                ? "IBFT:IBFT_alias_setting_nickname_push_off"
                                : "IBFT:IBFT_alias_setting_push_off"
                        }
                    />,
                    AlertType.Success
                );
            }

            yield put(userAction.toggleAliasSuccess());
            yield put(userAction.getBankInfoRequest());
            yield put(userAction.getAliasRequest());
            break;
        case RESPONSE_CODE.BVB_EXISTED_PHONE_NUMBER:
            const content = {
                title: (
                    <LspTranslation i18nKey="IBFT:IBFT_phone_number_popup_not_activate_title" />
                ),
                description: (
                    <LspTranslation i18nKey="IBFT:IBFT_phone_number_popup_not_activate_desc" />
                ),
                banner: tommyNoHope,
                buttons: [
                    {
                        variant: "primary",
                        label: (
                            <LspTranslation i18nKey="IBFT:IBFT_phone_number_popup_not_activate_btn_ok" />
                        ),
                        onClick: () => GlobalDialogController.hide(),
                    },
                ],
            };
            GlobalDialogController.show({
                component: () => (
                    <ConditionNotificationDialog content={content} centerImg />
                ),
            });
            yield put(userAction.toggleAliasError());
            break;
        default:
            AlertController.show(
                <LspTranslation i18nKey="IBFT:IBFT_alias_setting_push_error" />,
                AlertType.Error
            );
            yield put(userAction.toggleAliasError());
            break;
    }
}

export function* aliasFee(action) {
    const { payload } = action;
    const response = yield call(userService.aliasFee, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.FEE_SUCCESS_ERROR_CREATE:
            errorCreateNickname();
            yield put(userAction.getNicknameFeeError());
            break;
        case RESPONSE_CODE.EXISTED:
            existNickname();
            yield put(userAction.getNicknameFeeError());
            break;
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.getNicknameFeeSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.getNicknameFeeError());
            break;
    }
}

export function* validationAlias(action) {
    const { payload } = action;
    const response = yield call(userService.alias, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.BLACK_LIST:
            blackListNickname();
            yield put(userAction.checkNicknameError());

            break;
        case RESPONSE_CODE.EXISTED:
            existNickname();
            yield put(userAction.checkNicknameError());

            break;
        case RESPONSE_CODE.SUCCESS:
            yield put(userAction.checkNicknameSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.checkNicknameError());
            break;
    }
}

export function* submitAlias(action) {
    const { payload } = action;
    const response = yield call(userService.submitAlias, payload);

    const opened = yield select(getDialogOpened);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
            maxDailyLimit();
            yield put(userAction.submitNicknameError());

            break;
        case RESPONSE_CODE.NOT_ALLOWED:
            notEnoughMoney();
            yield put(userAction.submitNicknameError());

            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            yield put(userAction.submitNicknameSuccess(data));
            if (opened) {
                OTPController.hide();
            } else {
                OTPController.show(OTPDialogType.CreateAlias);
            }
            break;
        default:
            GlobalDialogController.showError({ errorCode: code, data });
            yield put(userAction.submitNicknameError());
            break;
    }
}

export function* submitOTP(action) {
    const { payload } = action;

    const response = yield call(userService.commitAlias, payload);

    const { code, data } = response.data;
    OTPController.hide();

    switch (code) {
        case RESPONSE_CODE.FEE_SUCCESS_ERROR_CREATE:
            errorCreateNickname();
            yield put(
                userAction.submitOTPNicknameError({ errorCode: code, data })
            );
            break;
        case RESPONSE_CODE.NICKNAME_EXISTED:
            createNicknameFail();
            yield put(
                userAction.submitOTPNicknameError({ errorCode: code, data })
            );
            break;
        case RESPONSE_CODE.CREATE_ALIAS_FAIL:
            createNicknameFail();

            yield put(
                userAction.submitOTPNicknameError({ errorCode: code, data })
            );
            break;
        case RESPONSE_CODE.SUCCESS:
            createAliasSuccess();
            yield put(userAction.submitOTPNicknameSuccess(data));
            break;
        case RESPONSE_CODE.INCORRECT_VALUE:
        case RESPONSE_CODE.MISMATCH_VALUE:
            AlertController.show(
                <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                AlertType.Error
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(userAction.submitOTPNicknameError(data));
            break;
    }
}
