import { memo, useCallback, useEffect } from "react";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom";
import ResetScrollOnRouteChange from "@components/ResetScrollOnRouteChange";
import Home from "@containers/Home";
import Layout from "@components/Layout";
import NotificationCenter from "@containers/NotificationCenter";
import SpendAccount from "@containers/SpendAccount";
import TermDeposit from "@containers/TermDeposit";
import CreditCard from "@containers/CreditCard";
import CardManagement from "@containers/CardManagement";
import BankInformation from "@containers/BankInformation";
import Lixi from "@containers/Lixi";
import Wealth from "@containers/Wealth";
import Invitation from "@containers/Invitation";
import { useIdleTimer } from "react-idle-timer";
import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import { connect } from "react-redux";
import authAction from "@redux/actions/auth";
import Security from "@containers/Security";
import ManageDevice from "@containers/ManageDevice";
import PaymentRequest from "@containers/PaymentRequest";
import PendingTask from "@containers/PendingTask";
import Profile from "@containers/Profile";
import RequestDocument from "@containers/RequestDocument";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import {
    FeatureComingSoonKey,
    FeatureUnavailableKey,
    SECURITY_KEY,
} from "@config/constants";
import Feedback from "@containers/FeedBack";
import Contact from "@containers/Contact";
import GoalSave from "@containers/GoalSave";
import FAQ from "@containers/FAQ";
import Reward from "@components/Reward";
import BankQRCode from "@containers/BankQRCode";
import AccountSettings from "@containers/BankInformation/AccountSettings";
import TransferLimit from "@containers/TransferLimit";
import TwoFactorsAuthentication from "@containers/Security/twofactors/TwoFactorsAuthentication";
import Overdraft from "@containers/Overdraft";
import { USER_ROUTES_PATH } from "./routes.constant";

const UserRoutes = ({ logoutRequest, featureState, quickCodeType }) => {
    // const quickCodeTimer = 10000;
    // const timeoutTimer = 15000;

    const quickCodeTimer = 1000 * 60 * 3; // 3 mins
    const timeoutTimer = 1000 * 60 * 15; // 15 mins
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const directTo = (link) => {
            history.push(link);
        };
        GlobalDialogController.setDirectLink(directTo);
    }, [history]);

    const checkComingSoonPage = useCallback(() => {
        const existedComingSoon = featureState?.comingSoon?.some((r) => {
            return location.pathname.includes(FeatureComingSoonKey[r]);
        });

        const existedUnavailable = featureState?.unavailableFeatures?.some(
            (r) => {
                return location.pathname.includes(FeatureUnavailableKey[r]);
            }
        );
        if (existedComingSoon) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Announcement",
                    header: "wealth:announcement_title",
                    content: "wealth:coming_soon",
                    button: "payee_list_btn_ben_bank_got_it",
                },
            });
            history.push("/home");
        } else if (existedUnavailable) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Announcement",
                    header: "wealth:announcement_title",
                    content: "err_lb_header_feature_unavailable",
                    button: "payee_list_btn_ben_bank_got_it",
                },
            });
            history.push("/home");
        }
    }, [
        featureState?.comingSoon,
        history,
        location.pathname,
        featureState?.unavailableFeatures,
    ]);

    const handleOnIdle = () => {
        // console.log("handleOnIdle");
        QuickCodeDialogController.quickCodeIdleHandler(quickCodeType);
    };

    const handleOnIdleTimeout = useCallback(() => {
        // console.log("handleOnIdleTimeout");
        logoutRequest({ sessionTimeout: true });
    }, [logoutRequest]);

    useIdleTimer({
        timeout: quickCodeTimer,
        onIdle: handleOnIdle,
        debounce: 500,
    });

    useIdleTimer({
        timeout: timeoutTimer,
        onIdle: handleOnIdleTimeout,
        debounce: 1000,
    });

    useEffect(() => {
        checkComingSoonPage();
    }, [location.pathname, checkComingSoonPage]);

    return (
        <Layout>
            <ResetScrollOnRouteChange />
            <Switch>
                <Route path={USER_ROUTES_PATH.HOME} component={Home} />

                <Route
                    path={USER_ROUTES_PATH.NOTIFICATION_CENTER}
                    component={NotificationCenter}
                />
                <Route
                    path={USER_ROUTES_PATH.SPEND_ACCOUNT}
                    component={SpendAccount}
                />
                <Route
                    path={USER_ROUTES_PATH.TERM_DEPOSIT}
                    component={TermDeposit}
                />
                <Route
                    path={USER_ROUTES_PATH.CREDIT_CARD}
                    component={CreditCard}
                />
                <Route
                    path={USER_ROUTES_PATH.CARD_MANAGEMENT}
                    component={CardManagement}
                />
                <Route
                    path={USER_ROUTES_PATH.BANK_INFORMATION}
                    component={BankInformation}
                />
                <Route
                    path={USER_ROUTES_PATH.ACCOUNT_SETTINGS}
                    component={AccountSettings}
                />
                <Route
                    path={USER_ROUTES_PATH.SECURITY}
                    component={Security}
                    exact
                />

                <Route
                    path={`${USER_ROUTES_PATH.SECURITY}/${SECURITY_KEY.TWO_FACTOR}`}
                    component={TwoFactorsAuthentication}
                    exact
                />

                <Route
                    path={USER_ROUTES_PATH.DEVICE_MANAGEMENT}
                    component={ManageDevice}
                />
                <Route
                    path={USER_ROUTES_PATH.REQUEST_DOCUMENT}
                    component={RequestDocument}
                />
                <Route path={USER_ROUTES_PATH.LIXI} component={Lixi} />
                <Route path={USER_ROUTES_PATH.WEALTH} component={Wealth} />
                <Route
                    path={USER_ROUTES_PATH.INVITATION}
                    component={Invitation}
                />
                <Route path={USER_ROUTES_PATH.FEEDBACK} component={Feedback} />
                <Route
                    path={USER_ROUTES_PATH.PAYMENT_REQUEST}
                    component={PaymentRequest}
                />
                <Route
                    path={USER_ROUTES_PATH.PENDING_TASKS}
                    component={PendingTask}
                />
                <Route path={USER_ROUTES_PATH.PROFILE} component={Profile} />
                <Route path={USER_ROUTES_PATH.CONTACT} component={Contact} />
                <Route path={USER_ROUTES_PATH.GOAL_SAVE} component={GoalSave} />
                <Route path={USER_ROUTES_PATH.FAQ} component={FAQ} />
                <Route path={USER_ROUTES_PATH.REWARD} component={Reward} />
                <Route
                    path={USER_ROUTES_PATH.BANK_QR_CODE}
                    component={BankQRCode}
                />
                <Route
                    path={USER_ROUTES_PATH.TRANSFER_LIMIT}
                    component={TransferLimit}
                />
                <Route
                    path={USER_ROUTES_PATH.OVERDRAFT_CREATE}
                    component={Overdraft}
                />

                <Route>
                    <Redirect to={USER_ROUTES_PATH.HOME} />
                </Route>
            </Switch>
        </Layout>
    );
};

const stateProps = (state) => ({
    quickCodeType: state.quickCodeInfo.type,
    featureState: state.user.featureState.data,
});

const dispatchProps = (dispatch) => ({
    logoutRequest: (payload) => dispatch(authAction.logoutRequest(payload)),
});

export default memo(connect(stateProps, dispatchProps)(UserRoutes));
