import api from "@config/api";

const getProductList = async () => {
    return await api.get("/user/products");
};

const applyProduct = async ({ productKey, ...payload }) => {
    return await api.post(`/user/products/${productKey}/apply`, payload, {
        bypassErrorHandler: true,
    });
};

const commitOTP = async ({ productKey, ...payload }) => {
    return await api.post(
        `/user/products/${productKey}/apply/commit`,
        payload,
        {
            bypassErrorHandler: true,
        }
    );
};

const commitOTPTopUp = async ({ productKey, ...payload }) => {
    return await api.post(
        `/user/products/${productKey}/topup/apply/commit`,
        payload,
        {
            bypassErrorHandler: true,
        }
    );
};

const getSubProducts = async (productKey) => {
    return await api.get(`/user/products/${productKey}`);
};

const actionWealthSubProducts = async ({ productKey, ...payload }) => {
    return await api.post(`/user/products/${productKey}/action`, payload, {
        // return await apiMock.post(`/user/products/${productKey}/action`, payload, {
        bypassErrorHandler: true,
    });
};

const topupWealthSubProducts = async ({ productKey, ...payload }) => {
    return await api.post(`/user/products/${productKey}/topup/apply`, payload, {
        bypassErrorHandler: true,
    });
};
const getBookedSchedule = async (payload) => {
    return await api.post(`/user/schedules/book/detail`, payload, {
        bypassErrorHandler: true,
    });
};

const checkProductStatus = async ({ productKey }) => {
    return await api.get(`/user/products/${productKey}/check`);
};

const checkWealthProductStatus = async (productKey) => {
    return await api.get(`/user/products/${productKey}/check`);
};

const getTimeList = async (payload) => {
    return await api.post(`/v3/user/schedules/list`, payload, {
        bypassErrorHandler: true,
    });
};
const bookCreditSchedule = async (payload) => {
    return await api.post(`/user/schedules/book`, payload, {
        bypassErrorHandler: true,
    });
};

const wealthService = {
    getProductList,
    applyProduct,
    getSubProducts,
    actionWealthSubProducts,
    topupWealthSubProducts,
    commitOTPTopUp,
    checkProductStatus,
    getBookedSchedule,
    commitOTP,
    checkWealthProductStatus,
    getTimeList,
    bookCreditSchedule,
};

export default wealthService;
