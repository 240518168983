import LspTypography from "@components/LspTypography";
import SwitchButton from "@components/SwitchButton";
import useNumber from "@helpers/useNumber";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Paper,
    makeStyles,
} from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useTermDeposit from "./useTermDeposit";

const useStyles = makeStyles((theme) => ({
    headerCell: {
        color: theme.palette.white.contrastText,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.background.header,
    },
    bodyCell: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
}));

const MultipleTDTable = ({
    option = "single", // | "multiple"
    detail,
    setSuggestion,
    interestOfEach,
    termName,
    totalDeposit,
    totalInterest,
    rollOver,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { generateName } = useTermDeposit();

    const displayDetail = useMemo(() => {
        if (option === "multiple") {
            return detail.map((d) => ({
                ...d,
                interest: interestOfEach,
            }));
        }
        return [
            {
                amountOfDeposit: totalDeposit,
                autoRoll: rollOver,
                interest: totalInterest,
            },
        ];
    }, [option, detail, rollOver, totalDeposit, totalInterest, interestOfEach]);

    const toggleRollover = useCallback(
        (idx, checked) => {
            setSuggestion((suggestion) => {
                if (option === "multiple") {
                    const clonedDetail = cloneDeep(suggestion.detail);
                    clonedDetail[idx].autoRoll = checked;

                    return {
                        ...suggestion,
                        detail: clonedDetail,
                    };
                }
                return {
                    ...suggestion,
                    rollOver: checked,
                };
            });
        },
        [option, setSuggestion]
    );

    return (
        <Box component={Paper} overflow="hidden" mb={3}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={3}
                                className={classes.headerCell}
                            >
                                <LspTypography variant="subheading3">
                                    {t("td_set_rollover_multds")}
                                </LspTypography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayDetail?.map((td, idx, arr) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={idx}>
                                <TableCell
                                    className={classes.bodyCell}
                                    style={{ width: "100%" }}
                                >
                                    <LspTypography variant="subheading2">
                                        {generateName(
                                            termName,
                                            arr.length > 1 ? idx + 1 : undefined
                                        )}
                                    </LspTypography>
                                    <LspTypography variant="body3">
                                        {t("td_interest_rollover")}
                                    </LspTypography>
                                </TableCell>
                                <TableCell
                                    className={classes.bodyCell}
                                    align="right"
                                >
                                    <LspTypography
                                        variant="subheading2"
                                        color="secondary"
                                    >
                                        {formatNumber(td.amountOfDeposit)}
                                    </LspTypography>
                                    <LspTypography variant="body3">
                                        {formatNumber(td.interest, true)}
                                    </LspTypography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const mapState = (state) => ({
    user: state.user.info,
});

export default connect(mapState)(MultipleTDTable);
