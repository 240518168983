import LspTranslation from "@components/LspTranslation";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import tommyNoHope from "@assets/images/no_hope_with_status_error.png";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";

const closeDialog = () => GlobalDialogController.hide();

const openDialog = (content) =>
    GlobalDialogController.show({
        component: () => (
            <ConditionNotificationDialog content={content} centerImg />
        ),
    });

export const TDSpecialRate = () => {
    const contents = {
        banner: tommyNoHope,
        title: <LspTranslation i18nKey="Bạn không thể sử dụng tính năng này" />,
        description: (
            <LspTranslation i18nKey="Tính năng này chỉ áp dụng với các tài khoản không áp dụng lãi suất ưu đãi" />
        ),
        buttons: [
            {
                variant: "primary",
                label: <LspTranslation i18nKey="lb_close" />,
                onClick: () => closeDialog(),
            },
        ],
    };
    openDialog(contents);
};
