import { Box, Icon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Interweave from "interweave";
import { memo, useCallback } from "react";
import { isFunction } from "lodash";
import { InformationIcon } from "./LspIcon";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    container: {
        "& > p": {
            textAlign: "left",
            marginLeft: theme.spacing(1),
        },
    },
    primary: {
        color: theme.palette.primary.main,
    },
    active: {
        color: theme.palette.status.active,
    },
    blue: {
        color: theme.palette.blue.main,
    },
}));

/**
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.content
 * @param {string} props.color
 * @param {string} props.contentColor
 * @param {React.ReactElement} props.customIcon
 */

const LspHintInfo = ({
    content,
    align,
    color,
    icon,
    onDirect,
    contentColor,
    customIcon,
}) => {
    const classes = useStyles();

    const handleSubmit = useCallback(() => {
        if (isFunction(onDirect)) {
            onDirect();
        }
    }, [onDirect]);

    const handleTransform = useCallback(
        (node, children, config) => {
            if (config.tagName === "a") {
                return (
                    <LspTypography
                        component="a"
                        variant="hyperlink1"
                        color="success"
                        onClick={handleSubmit}
                    >
                        {children}
                    </LspTypography>
                );
            }
        },
        [handleSubmit]
    );

    return (
        <Box className={classes.container} display="flex" alignItems={align}>
            {!customIcon && icon && (
                <Icon className={`font-icon icon-${icon} ${classes[color]}`} />
            )}
            {!customIcon && !icon && (
                <InformationIcon className={classes[color]} />
            )}
            {customIcon && customIcon}
            <LspTypography variant="body3" color={contentColor || "grey"}>
                <Interweave content={content} transform={handleTransform} />
            </LspTypography>
        </Box>
    );
};

LspHintInfo.propTypes = {
    content: PropTypes.string,
    align: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
    color: PropTypes.oneOf(["primary", "active", "blue"]),
    contentColor: PropTypes.string,
    customIcon: PropTypes.node,
};

LspHintInfo.defaultProps = {
    content: "",
    align: "center",
    color: "primary",
    contentColor: "",
    customIcon: null,
};

export default memo(LspHintInfo);
