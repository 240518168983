import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import { SettingIcon } from "@components/LspIcon";
import { BankDetails } from "@config/constants";
import usePathname from "@helpers/usePathname";
import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Nickname from "@containers/Nickname";
import AccountSettings from "./AccountSettings";
import BankInformationSection from "./BankInformationSection";

const parentName = "bank-information";

const BankInformation = ({ featureState }) => {
    const { t } = useTranslation();
    const { generateChildPathname } = usePathname();
    const location = useLocation();

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    BankDetails.BankInformation
                ),
                component: BankInformationSection,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    BankDetails.BankSettings
                ),
                component: AccountSettings,
            },
            {
                path: generateChildPathname(parentName, BankDetails.Nickname),
                component: Nickname,
            },
        ],
        [generateChildPathname]
    );

    const navbarItems = useMemo(() => {
        const defaultNavbar = [
            {
                title: "global_share_bank_detail_label",
                icon: "list",
                isSVGIcon: false,
                path: generateChildPathname(
                    parentName,
                    BankDetails.BankInformation
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        BankDetails.BankInformation
                    )
                ),
            },
        ];
        const accountSetting = {
            title: t("IBFT:IBFT_alias_setting_header"),
            icon: <SettingIcon vb={36} />,
            isSVGIcon: true,
            path: generateChildPathname(parentName, BankDetails.BankSettings),
            selected: location.pathname.includes(
                generateChildPathname(parentName, BankDetails.BankSettings)
            ),
            activeTooltip: true,
            id: "accountSettings",
            intro: true,
            introContent: "IBFT:IBFT_bankdetails_cogwheel_tooltip",
        };

        if (featureState?.enableAliasPhase2) {
            defaultNavbar.push(accountSetting);
        }

        return defaultNavbar;
    }, [
        generateChildPathname,
        location.pathname,
        t,
        featureState?.enableAliasPhase2,
    ]);

    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    return (
        <>
            <Banner
                title={t("global_share_bank_detail_label")}
                subtitle={t("global_share_bank_detail_desc_label")}
                navbarItems={navbarItems}
            />
            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};
const stateProps = (state) => ({
    featureState: state.user.featureState.data,
    user: state.user.info || {},
});

const dispatchProps = () => ({});
export default memo(connect(stateProps, dispatchProps)(BankInformation));
