export const GUEST_ROUTES_PATH = Object.freeze({
    LOGIN: "/login",
    RETRIEVE_USERNAME: "/retrieveusername",
    RESET_PASSWORD: "/resetpassword",
    SHARE_MONEY_WITH_LINK: "/p/:verifyCode",
    SPLIT_BILL: "/s/:verifyCode",
    SPLIT_BILL_PAYMENT: "/payment/:verifyCode",
    FORGOT_PASSWORD: "/forgotpassword",
    ACTIVATION: "/activation",
    // VERIFY_EMAIL_MOBILE_JOIN: "/join/verifyEmailMobileJoin",
    VERIFY_EMAIL_MOBILE_JOIN: "/join/verifyEmailMobileJoin",
    LOGIN_COMMUNITY: "/loginCommunity",
});

export const USER_ROUTES_PATH = Object.freeze({
    HOME: "/home",
    SPEND_ACCOUNT: "/spend-account",
    NOTIFICATION_CENTER: "/notification-center",
    TERM_DEPOSIT: "/term-deposit",
    CREDIT_CARD: "/credit-card",
    CARD_MANAGEMENT: "/card-management",
    CARD_MANAGEMENT_DEBIT: "/card-management/debit",
    BANK_INFORMATION: "/bank-information",
    ACCOUNT_SETTINGS: "/account-settings",
    SECURITY: "/security",
    DEVICE_MANAGEMENT: "/manage-device",
    REQUEST_DOCUMENT: "/request-document",
    LIXI: "/lixi",
    WEALTH: "/wealth",
    INVITATION: "/invitation",
    FEEDBACK: "/feedback",
    PAYMENT_REQUEST: "/payment-request",
    PENDING_TASKS: "/pending-task",
    PROFILE: "/profile",
    CONTACT: "/contact",
    GOAL_SAVE: "/goal-save",
    FAQ: "/faq",
    REWARD: "/reward",
    BANK_QR_CODE: "/bank-qr-code",
    TRANSFER_LIMIT: "/transfer-limit",
    CARD_LIMIT: "/limit-card-settings",
    OVERDRAFT_CREATE: "/overdraft",
});
