import { Box, Icon, makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Autocomplete, Skeleton } from "@material-ui/lab";
import LspTextField from "../../../components/LspTextField";
import topUpAction from "../../../redux/actions/top-up";

const useStyles = makeStyles((theme) => ({
    wrapperPhone: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    phoneActionIcon: {
        marginLeft: theme.spacing(1),
        zIndex: "100",
    },
    fetchingInput: {
        marginBottom: theme.typography.pxToRem(19),
    },
}));

const PhoneNumberDropdown = ({
    phoneNumber,
    contacts,
    contactsFetching,
    updatePhoneNumber,
    updateSelectedPhoneObject,
    selectedPhoneObject,
    deletePhone,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const initErrors = useMemo(
        () => ({
            phoneNumber: null,
        }),
        []
    );
    const [errors, setErrors] = useState(initErrors);
    const contactPhoneNumberList = useMemo(
        () => contacts.map((c) => c.phoneNo),
        [contacts]
    );
    const [deletedPhoneList, setDeletedPhoneList] = useState([]);
    const [isOpenPhoneDropdown, setIsOpenPhoneDropdown] = useState(false);

    const onChangePhoneNumber = (event, value) => {
        const contact = contacts.find((c) => c.phoneNo === value);
        updateSelectedPhoneObject(contact);
        updatePhoneNumber(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                phoneNumber: t("msg_we_need_this"),
            }));
        } else {
            setErrors((prev) => ({ ...prev, phoneNumber: null }));
        }
    };

    // Handler for delete phone number
    const onClickDeletePhone = (event, contact) => {
        event.preventDefault();
        event.stopPropagation();
        setDeletedPhoneList([...deletedPhoneList, contact.indexPhoneNo]);
    };

    const onClickUndoDeletePhone = (event, contact) => {
        event.preventDefault();
        event.stopPropagation();
        const undoPhoneList = deletedPhoneList.filter(
            (c) => c !== contact.indexPhoneNo
        );
        setDeletedPhoneList(undoPhoneList);
    };

    const deletePhoneNumber = async () => {
        const dataToPost = {
            indexPhoneNo: deletedPhoneList,
        };
        deletePhone(dataToPost);
    };

    const closeDropdownPhoneHandler = () => {
        setIsOpenPhoneDropdown(false);
        if (!deletedPhoneList || deletedPhoneList.length === 0) return;
        deletePhoneNumber();
    };
    // end Handler for delete phone number

    return (
        <>
            {contactsFetching && (
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={56}
                    className={classes.fetchingInput}
                />
            )}

            {!contactsFetching && (
                <Autocomplete
                    onOpen={() => {
                        setIsOpenPhoneDropdown(true);
                    }}
                    onClose={closeDropdownPhoneHandler}
                    open={isOpenPhoneDropdown}
                    disableClearable
                    disableCloseOnSelect
                    options={contactPhoneNumberList}
                    value={phoneNumber}
                    onChange={onChangePhoneNumber}
                    defaultValue={contacts[0]?.phoneNo}
                    renderOption={(option) => {
                        const contact = contacts.find(
                            (c) => c.phoneNo === option
                        );
                        const isMyPhoneNumber = contact.indexPhoneNo === "";
                        const isTemporaryDeleted = deletedPhoneList.includes(
                            contact?.indexPhoneNo
                        );
                        return (
                            <div className={classes.wrapperPhone}>
                                <Box
                                    flex={1}
                                    onClick={(event) => {
                                        if (isTemporaryDeleted) {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            return;
                                        }
                                        setIsOpenPhoneDropdown(false);
                                    }}
                                >
                                    {contact?.fullName
                                        ? contact?.fullName
                                        : t("tu_lb_phone_my_number")}{" "}
                                    - {contact?.phoneNo}
                                </Box>
                                {!isMyPhoneNumber && (
                                    <div className={classes.phoneActionIcon}>
                                        {isTemporaryDeleted ? (
                                            <div>
                                                <span>
                                                    {t("topup_lb_deleted")}
                                                </span>
                                                <Icon
                                                    className="font-icon icon-undo"
                                                    onClick={(event) =>
                                                        onClickUndoDeletePhone(
                                                            event,
                                                            contact
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <Icon
                                                className="font-icon icon-trashbin"
                                                onClick={(event) =>
                                                    onClickDeletePhone(
                                                        event,
                                                        contact
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                    renderInput={(params) => {
                        return (
                            <LspTextField
                                {...params}
                                label={
                                    selectedPhoneObject?.fullName ||
                                    t("tu_lb_phone_my_number")
                                }
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber || " "}
                            />
                        );
                    }}
                />
            )}
        </>
    );
};

const mapState = (state) => ({
    contacts: state.topUp.contacts,
    selectedPhoneObject: state.topUp.selectedPhoneObject,
    contactsFetching: state.topUp.contactsFetching,
});

const dispatchProps = (dispatch) => ({
    updatePhoneNumber: (payload) =>
        dispatch(topUpAction.updatePhoneNumber(payload)),
    deletePhone: (payload) => dispatch(topUpAction.deletePhone(payload)),
    updateSelectedPhoneObject: (payload) =>
        dispatch(topUpAction.updateSelectedPhoneObject(payload)),
});

export default connect(mapState, dispatchProps)(PhoneNumberDropdown);
