import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import LspTextField from "@components/LspTextField";
import DoubleButtons from "../DoubleButtons";
import {
    LspDialogContent,
    LspDialogTitle,
    LspDialogActions,
} from "../LspDialog";

const useStyles = makeStyles((theme) => ({
    desc: {
        marginBottom: theme.spacing(2),
    },
}));

const formControlDefaultProps = {
    variant: "outlined",
    margin: "dense",
    fullWidth: true,
};

const Password = ({ loading, onClose, nextHandler }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            password: null,
        },
    });

    const submitClick = (data) => {
        const { password } = data;
        nextHandler(password);
    };

    const primaryButton = {
        label: t("lb_continue"),
    };

    const secondaryButton = {
        label: t("lb_cancel"),
        onClick: () => {
            onClose();
        },
    };

    return (
        <div className={classes?.wrapper}>
            <form onSubmit={handleSubmit(submitClick)}>
                <LspDialogTitle>
                    <div>{t("quickcode_label_enter_password")}</div>
                </LspDialogTitle>
                <LspDialogContent>
                    <div>
                        <div className={classes.desc}>
                            {t("quickcode_label_enter_password_desc")}
                        </div>
                        <LspTextField
                            {...formControlDefaultProps}
                            error={!!errors.password}
                            name="password"
                            label={t("lb_password")}
                            type="password"
                            helperText={errors.password?.message || " "}
                            inputProps={{
                                ref: register({
                                    required: t("msg_we_need_this"),
                                }),
                            }}
                        />
                    </div>
                </LspDialogContent>
                <LspDialogActions>
                    <DoubleButtons
                        progressing={loading}
                        primaryButton={primaryButton}
                        secondaryButton={secondaryButton}
                    />
                </LspDialogActions>
            </form>
        </div>
    );
};

export default Password;
