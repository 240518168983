import useNumber from "@helpers/useNumber";
import { memo, useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import { Box, Chip, makeStyles } from "@material-ui/core";
import { isFunction } from "lodash";
import LspTypography from "./LspTypography";

const SUGGEST_CONTANTS = {
    DEFAULT_SUGGEST_AMOUNT: [100000, 1000000, 10000000],
    MAX_SUGGEST_AMOUNT: [10000000, 100000000],
    MAX_AMOUNT_LIMIT: 100000000,
    SUGGEST_AMOUNT_DOZEN: 10,
    SUGGEST_AMOUNT_HUNDRED: 100,
    SUGGEST_AMOUNT_THOUSAND: 1000,
    SUGGEST_AMOUNT_TEN_OF_THOUSAND: 10000,
    SUGGEST_AMOUNT_HUNDRED_OF_THOUSAND: 100000,
    SUGGEST_AMOUNT_MILION: 1000000,
};

const useStyles = makeStyles((theme) => ({
    suggestAmount: {
        marginBottom: theme.spacing(2.5),
    },
    suggestInput: {
        marginBottom: theme.spacing(2.5),
    },
    suggestTag: {
        display: "flex",
        flexDirection: "row",
    },
    suggestTagItem: {
        marginRight: theme.spacing(1),
        "&:last-child": {
            marginRight: 0,
        },
    },
    noMargin: {
        margin: 0,
    },
}));

const LspSuggestAmount = ({ amount, onChange, error, ...restProps }) => {
    const classes = useStyles();
    const {
        thousandSeparator,
        decimalSeparator,
        formatNumber,
        parseNumber,
    } = useNumber();

    const [suggestList, setSuggestList] = useState(() => {
        if (!parseNumber(amount)) {
            return SUGGEST_CONTANTS.DEFAULT_SUGGEST_AMOUNT;
        }
    });

    useEffect(() => {
        const amountNumber = parseNumber(amount);

        const AMOUNT_LENGTH =
            (amountNumber && amountNumber.toString().length) || 0;
        switch (AMOUNT_LENGTH) {
            case 0:
                setSuggestList(SUGGEST_CONTANTS.DEFAULT_SUGGEST_AMOUNT);
                break;
            case 1:
            case 2:
            case 3:
                setSuggestList([
                    amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                    amountNumber *
                        SUGGEST_CONTANTS.SUGGEST_AMOUNT_TEN_OF_THOUSAND,
                    amountNumber *
                        SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED_OF_THOUSAND,
                ]);
                break;
            case 4:
                if (amountNumber === SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND) {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                        amountNumber *
                            SUGGEST_CONTANTS.SUGGEST_AMOUNT_TEN_OF_THOUSAND,
                        amountNumber *
                            SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED_OF_THOUSAND,
                    ]);
                } else {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                        amountNumber *
                            SUGGEST_CONTANTS.SUGGEST_AMOUNT_TEN_OF_THOUSAND,
                    ]);
                }

                break;
            case 5:
                if (
                    amountNumber ===
                    SUGGEST_CONTANTS.SUGGEST_AMOUNT_TEN_OF_THOUSAND
                ) {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                        amountNumber *
                            SUGGEST_CONTANTS.SUGGEST_AMOUNT_TEN_OF_THOUSAND,
                    ]);
                } else {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                    ]);
                }

                break;
            case 6:
                if (
                    amountNumber ===
                    SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED_OF_THOUSAND
                ) {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_DOZEN,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_THOUSAND,
                    ]);
                } else {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_DOZEN,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED,
                    ]);
                }

                break;
            case 7:
                if (amountNumber === SUGGEST_CONTANTS.SUGGEST_AMOUNT_MILION) {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_DOZEN,
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_HUNDRED,
                    ]);
                } else {
                    setSuggestList([
                        amountNumber * SUGGEST_CONTANTS.SUGGEST_AMOUNT_DOZEN,
                    ]);
                }

                break;
            default:
                setSuggestList([]);
                break;
        }
    }, [amount, parseNumber]);

    const handleClick = (data) => {
        if (isFunction(onChange)) {
            onChange({ formattedValue: formatNumber(data), value: data });
        }
    };

    return (
        <Box
            className={`${classes.suggestAmount} ${
                !error && suggestList && suggestList.length > 0
                    ? ""
                    : classes.noMargin
            }`}
        >
            <NumberFormat
                {...restProps}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                disableHelperText={!error}
                error={error}
                className={classes.suggestInput}
                value={amount}
                numberValue={parseNumber(amount)}
            />
            {!error && suggestList && suggestList.length > 0 && (
                <div className={classes.suggestTag}>
                    {suggestList.map((suggest) => (
                        <div className={classes.suggestTagItem} key={suggest}>
                            <Chip
                                onClick={() => handleClick(suggest)}
                                label={
                                    <LspTypography
                                        component="span"
                                        variant="subheading3"
                                    >
                                        {formatNumber(suggest)}
                                    </LspTypography>
                                }
                            />
                        </div>
                    ))}
                </div>
            )}
        </Box>
    );
};

export default memo(LspSuggestAmount);
