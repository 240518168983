import LspButton from "@components/LspButton";
import { Box, Grid, Icon, makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "auto",
        background: "transparent",
    },
    wrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
        boxShadow: theme.shadows[1],
        textAlign: "center",
        margin: "auto",
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    header: {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    icon: {
        fontSize: theme.typography.pxToRem(130),
    },
    infoBox: {
        padding: theme.spacing(1, 2),
        background: theme.palette.magicalGrey[100],
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));
const PhysicalCardStatus = ({ title, description, note, imgUrl, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleOnClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    return (
        <Box marginTop={2}>
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={4}
                className={classes.root}
            >
                <Grid item md={9} xs={11} className={classes.wrapper}>
                    <div className={classes.header}>
                        {imgUrl && (
                            <img src={imgUrl} alt="Active Card Success" />
                        )}
                    </div>
                    <Box margin={2}>
                        <Box mb={2}>
                            <LspTypography
                                variant="heading1"
                                className={classes.title}
                            >
                                {title}
                            </LspTypography>
                            <LspTypography variant="body2" color="grey">
                                {description}
                            </LspTypography>
                        </Box>
                        <div className={classes.infoBox}>{note}</div>
                    </Box>

                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={() => handleOnClose()}
                    >
                        {t("wealth:mc_v2_activate_successful_got_it")}
                    </LspButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PhysicalCardStatus;
