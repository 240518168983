import LspTypography from "@components/LspTypography";
import { Box, makeStyles } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    item: {
        width: "50%",
        marginBottom: theme.spacing(1),
    },
}));

const BasicInfo = ({ data }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { formatNumber } = useNumber();
    return (
        <Box p={3} display="flex" flexWrap="wrap">
            <div className={classes.item}>
                <LspTypography color="black" variant="heading3">
                    {t("wealth:GE00027")}
                </LspTypography>
                <LspTypography color="primary" variant="body1">
                    {formatNumber(data?.pricing)}
                </LspTypography>
            </div>
            <div className={classes.item}>
                <LspTypography color="black" variant="heading3">
                    {t("wealth:GE00081")}
                </LspTypography>
                <LspTypography color="primary" variant="body1">
                    {data?.destination}
                </LspTypography>
            </div>
            <div className={classes.item}>
                <LspTypography color="black" variant="heading3">
                    {t("wealth:GE00082")}
                </LspTypography>
                <LspTypography color="primary" variant="body1">
                    {data?.policyType}
                </LspTypography>
            </div>
            <div className={classes.item}>
                <LspTypography color="black" variant="heading3">
                    {t("wealth:GE00028")}
                </LspTypography>
                <LspTypography color="primary" variant="body1">
                    {data?.travelDate}
                </LspTypography>
            </div>
        </Box>
    );
};

export default memo(BasicInfo);
