import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import { SpendAccountNavigationKey } from "@config/constants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";

import useNumber from "@helpers/useNumber";
import PendingPayment from "@containers/SpendAccount/PendingPayment";
import useAccounts from "@helpers/useAccounts";
import { connect } from "react-redux";
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import InfoTooltip from "@components/InfoTooltip";
import { LockIcon } from "@components/LspIcon";
import useOverdraft from "@helpers/useOverdraft";
import TransactionHistory from "./TransactionHistory";
import TopUp from "./TopUp";
import MoveMoney from "./MoveMoney";
import BillPayment from "./BillPayment";
import PayeeAddDestination from "./MoveMoney/PayeeAddDestination";

const parentName = "spend-account";

const useStyles = makeStyles((theme) => ({
    tooltip: {
        width: 200,
        display: "flex",
        flexDirection: "column",
        "& > div": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
        },
    },
    lockOverdraft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: theme.spacing(2.5),
        "& p": {
            fontWeight: 600,
        },
    },
    icon: {
        color: theme.palette.error.main,
        marginTop: "2px",
    },
}));

const SpendAccount = ({ hiddenBalanceOption, overdraftDetails }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const { formatNumber } = useNumber();
    const {
        overdraftLimit,
        isOverDraftUser,
        getCurrentBalance,
        getAvailableToSpend,
        isLockedOverDraft,
    } = useAccounts();
    const { generateChildPathname } = usePathname();

    const availableToSpend = getAvailableToSpend(true);

    const currentBalance = getCurrentBalance(true);

    const isSpendTransactionPage = useMemo(() => {
        return (
            location?.pathname ===
            `/${parentName}/${SpendAccountNavigationKey.TransactionList}`
        );
    }, [location?.pathname]);

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.TransactionList
                ),
                component: TransactionHistory,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.PendingPayment
                ),
                component: PendingPayment,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.TopUp
                ),
                component: TopUp,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.MoveMoney
                ),
                component: MoveMoney,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.BillPayment
                ),
                component: BillPayment,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.AddDestination,
                    ["payeeId", "hasTimoMember"]
                ),
                component: PayeeAddDestination,
            },
        ],
        [generateChildPathname]
    );

    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    const navbarItems = useMemo(
        () => [
            {
                title: "lb_transactions",
                icon: "transactionlist",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.TransactionList
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        SpendAccountNavigationKey.TransactionList
                    )
                ),
            },
            {
                title: "lb_pending",
                icon: "Pending-icon",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.PendingPayment
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        SpendAccountNavigationKey.PendingPayment
                    )
                ),
            },
            {
                title: "lb_top_up",
                icon: "topup",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.TopUp
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        SpendAccountNavigationKey.TopUp
                    )
                ),
            },
            {
                title: "lb_move_money",
                icon: "move-money",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.MoveMoney
                ),
                selected:
                    location.pathname.includes(
                        generateChildPathname(
                            parentName,
                            SpendAccountNavigationKey.MoveMoney
                        )
                    ) ||
                    location.pathname.includes(
                        generateChildPathname(
                            parentName,
                            SpendAccountNavigationKey.AddDestination
                        )
                    ),
            },
            {
                title: "home_label_bill_pay",
                icon: "paybill",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.BillPayment
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        SpendAccountNavigationKey.BillPayment
                    )
                ),
            },
        ],
        [location, generateChildPathname]
    );

    const tooltipOverdraftText = useMemo(() => {
        return (
            <Box className={classes.tooltip}>
                <Box>
                    <LspTypography color="white" variant="body2">
                        {`${t("overdraft:overdraft_current_balance")}: `}
                    </LspTypography>
                    <LspTypography color="white" variant="body2">
                        {formatNumber(currentBalance)}
                    </LspTypography>
                </Box>
                <Box>
                    <LspTypography color="white" variant="body2">
                        {`${t("overdraft:overdraft_limit")}: `}
                    </LspTypography>
                    <LspTypography color="white" variant="body2">
                        {formatNumber(overdraftLimit)}
                    </LspTypography>
                </Box>
            </Box>
        );
    }, [t, currentBalance, formatNumber, overdraftLimit, classes.tooltip]);

    const { getLockStatusContent, lockOverdraftDetail } = useOverdraft();

    const openLockOverdraftDetail = useCallback(() => {
        // const lockType = "A_NO_FUND_IN";
        // const lockType = "A_SWITCH_DEBT_GROUP";
        // const lockType = "COPS_LOCK";
        // const lockType = "A_EXPIRATION";
        // const lockType = "FAIL_CLOSURE";

        const lockType = overdraftDetails?.lockType || "";
        const content = getLockStatusContent(lockType);

        if (content && content?.dialog) {
            lockOverdraftDetail(content?.dialog);
        }
    }, [getLockStatusContent, overdraftDetails, lockOverdraftDetail]);

    const bannerSubtitle = useMemo(() => {
        if (!isSpendTransactionPage) {
            return (
                <Box display="flex" alignItems="center">
                    {isOverDraftUser && (
                        <Box display="flex">
                            {`${t("overdraft:overdraft_current_balance")}: `} &nbsp;
                            <LspTypography variant="heading4" color="grey">
                                {hiddenBalanceOption
                                    ? "*****"
                                    : formatNumber(currentBalance)}
                            </LspTypography>
                        </Box>
                    )}

                    <Box marginLeft={isOverDraftUser ? 3 : 0} display="flex">
                        {t("overdraft:overdraft_available_to_spend")} &nbsp;
                        <LspTypography variant="heading4" color="grey">
                            {hiddenBalanceOption
                                ? "*****"
                                : formatNumber(availableToSpend)}
                        </LspTypography>
                    </Box>

                    {isOverDraftUser && (
                        <InfoTooltip
                            color="black"
                            // wrapperClassName={classes.hint}
                            title={tooltipOverdraftText}
                            placement="bottom"
                            icon="InfoIconV2"
                        />
                    )}

                    {isLockedOverDraft && (
                        <Box className={classes.lockOverdraft}>
                            <LockIcon className={classes.icon} vb={20} />
                            <LspTypography color="error" variant="body3">
                                {t("overdraft:overdraft_lock_status_message")}
                            </LspTypography>
                            <ButtonBase
                                onClick={openLockOverdraftDetail}
                                style={{ marginLeft: 4 }}
                            >
                                <LspTypography
                                    variant="hyperlink2"
                                    color="error"
                                >
                                    {t(
                                        "overdraft:overdraft_lock_status_message_cta"
                                    )}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}
                </Box>
            );
        }

        return "";
    }, [
        t,
        formatNumber,
        hiddenBalanceOption,
        isSpendTransactionPage,
        isOverDraftUser,
        availableToSpend,
        currentBalance,
        tooltipOverdraftText,
        classes,
        openLockOverdraftDetail,
        isLockedOverDraft,
    ]);

    return (
        <>
            <Banner
                title={t("transaction_list_lb_spend_account")}
                subtitle={bannerSubtitle}
                navbarItems={navbarItems}
            />
            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    hiddenBalanceOption: state.account.spendAccount.hiddenBalance,
    overdraftDetails: state.overdraft.detail,
});

export default connect(mapState, null)(SpendAccount);
