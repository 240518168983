import LspTypography from "@components/LspTypography";
import { BOOKING_PERIOD, DELIVERY_METHODS } from "@containers/Booking/constant";
import { Box, Icon, makeStyles } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { format, parse } from "date-fns";
import Interweave from "interweave";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 22,
        margin: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(3),
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 100,
        color: theme.palette.primary.main,
        display: "block",
    },
    label: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        fontSize: 16,
    },
}));

const ViewBookingDelivery = ({ detail, onChangeBooking }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const getPeriodName = useCallback(
        (id) => {
            switch (id) {
                case BOOKING_PERIOD.MORNING:
                    return t("booking_delivery_morning");
                case BOOKING_PERIOD.AFTERNOON:
                    return t("booking_delivery_morning");
                case BOOKING_PERIOD.EVENING:
                    return t("booking_delivery_evening");
                default:
                    return "";
            }
        },
        [t]
    );

    const content = useMemo(() => {
        if (!detail || isEmpty(detail)) return;
        let note = "";

        let time = "";
        if (detail?.deliveryMethod === DELIVERY_METHODS.MOTORBIKE) {
            const parsedTime =
                parse(detail?.bookTime, "dd/MM/yyyy HH:mm:ss", new Date()) ||
                "";
            const name = getPeriodName(detail?.periodTime);
            time = `${name} ${format(parsedTime, "dd/MM/yyyy")}`;
        } else if (detail?.deliveryMethod === DELIVERY_METHODS.MAIl) {
            const parsedDate =
                parse(detail?.bookTime, "dd-MM-yyyy HH:mm:ss", new Date()) ||
                "";
            time = format(parsedDate, "dd/MM/yyyy");
            note = `${t("master:time_card_delivery")} <br /> ${t(
                "ekyc:login_contact_for_support"
            ).replace("%@", `<b>${t("hangout:callcenter_number")}</b>`)}`;
        }

        return {
            address: {
                name: t("booking_delivery_new_address"),
                value: detail?.fullAddress,
            },
            time: {
                name: t("lb_time"),
                value: time,
            },
            note,
        };
    }, [t, detail, getPeriodName]);

    const deliveryStatusContent = useMemo(() => {
        let name = "";
        let description = "";
        let color = "";
        switch (detail?.deliveryStatus) {
            case "PROCESSING":
                name = t(`master:delivery_status1`);
                description = t("master:time_card_delivery");
                color = "primary";
                break;
            case "ON_THE_WAY":
                name = t(`master:delivery_status2`);
                description = t("master:time_card_delivery");
                color = "success";
                break;
            case "MISSED_DELIVERY":
                name = t(`master:delivery_status3`);
                description = t("master:delivery_status3_content");
                color = "error";
                break;
            default:
                break;
        }

        return {
            name,
            description,
            color,
        };
    }, [t, detail]);

    return (
        <>
            <Icon className={`font-icon icon-Plus-booking ${classes.icon}`} />
            <LspTypography className={classes.title} color="grey">
                {t("booking_view_title")}
            </LspTypography>
            <Box mb={2}>
                <LspTypography>
                    {t("master:card_delivery_address")}
                </LspTypography>
            </Box>
            <Box>
                <LspTypography variant="heading1" color="primary">
                    {content?.address?.name}
                </LspTypography>
                <LspTypography variant="body1">
                    {content?.address?.value}
                </LspTypography>
            </Box>
            {content?.time?.value && (
                <Box mt={2}>
                    <LspTypography variant="heading1" color="primary">
                        {content?.time?.name}
                    </LspTypography>
                    <LspTypography variant="body1">
                        {content?.time?.value}
                    </LspTypography>
                </Box>
            )}
            {detail?.deliveryStatus && (
                <>
                    <Box mt={2}>
                        <LspTypography
                            variant="heading1"
                            color={deliveryStatusContent?.color}
                        >
                            {deliveryStatusContent?.name}
                        </LspTypography>
                    </Box>
                    <Box mt={2}>
                        <LspTypography>
                            {deliveryStatusContent?.description}
                        </LspTypography>
                    </Box>
                </>
            )}

            <Box mt={2} mb={1}>
                <LspTypography>
                    <Interweave content={content?.note} />
                </LspTypography>
            </Box>
            {/* <Box mb={2}>
                <ButtonBase onClick={onChangeBooking}>
                    <LspTypography color="success" variant="hyperlink1">
                        {t("booking_view_change_booking")}
                    </LspTypography>
                </ButtonBase>
            </Box> */}
        </>
    );
};

const mapState = (state) => ({
    serviceName: state.booking.serviceName,
    detail: state.booking.detail,
    onCancel: state.booking.onCancel,
});
const mapDispatch = (dispatch) => ({
    reset: () => dispatch(bookingActions.reset()),
});

export default connect(mapState, mapDispatch)(ViewBookingDelivery);
