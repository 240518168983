import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import querystring from "query-string";
import {
    Box,
    CircularProgress,
    InputAdornment,
    makeStyles,
    Paper,
    Step,
    StepLabel,
    Stepper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import {
    BANK_INFO,
    FetchStatus,
    INPUT_RULES,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
} from "@config/constants";
import DoubleButtons from "@components/DoubleButtons";
import LspReceipt from "@components/LspReceipt";
import moveMoneyService from "@services/move-money";
import useNumber from "@helpers/useNumber";
import moveMoneyAction from "@redux/actions/move-money";
import LspTextField from "@components/LspTextField";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useAccounts from "@helpers/useAccounts";
import useCapitalize from "@helpers/useCapitalize";
import useDescription from "@helpers/useDescription";
import LspSuggestAmount from "@components/LspSuggestAmount";
import LspBankList from "@components/LspBankList";
import useTransactionValidation from "../useTransactionValidation";
import TransferConfirmation from "./TransferConfirmation";

const useStyles = makeStyles((theme) => ({}));

const BankAccountTransfer = ({
    transferToAccountNumber,
    transferring,
    transferStatus,
    transferInfo,
    resetTransfer,
    errorCodeAmount,
    fullName,
    systemParams,
    featureState,
    branchList,
    provinceList,
    bankList,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const {
        validateAmount,
        getErrorByResponseCode,
    } = useTransactionValidation();
    const { spendAccount } = useAccounts();
    const { capitalizeSentence } = useCapitalize();
    const { getStandardDescription } = useDescription();
    const [isSupportFastTransfer, setSupportFastTransfer] = useState(false);

    const queryParams = querystring.parse(location.search);

    const defaultDescription = useMemo(() => {
        return t("mm_description_default").replace(
            "%@",
            capitalizeSentence(fullName)
        );
    }, [fullName, t, capitalizeSentence]);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            resetTransfer();
        };
    }, [resetTransfer]);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (transferStatus === FetchStatus.Success) {
            setActiveStep(2);
        }
    }, [transferStatus]);

    const steps = useMemo(
        () => [
            {
                id: 0,
                label: t("payee_bank_title"),
            },
            {
                id: 1,
                label: t("payee_transfer_review_title"),
            },
            {
                id: 2,
                label: t("transaction_btn_receipt"),
            },
        ],
        [t]
    );

    const [isAutofillProcessed, setIsAutofillProcessed] = useState(false);

    const [targetInfo, setTargetInfo] = useState("");
    const [selectedBank, setSelectedBank] = useState(null);
    const selectedBankBranchList = useRef([]);
    const [
        selectedBankProvinceBranchList,
        setSelectedBankProvinceBranchList,
    ] = useState([]);
    const [selectedBankProvinceList, setSelectedBankProvinceList] = useState(
        []
    );
    const [branch, setBranch] = useState(null);
    const [province, setProvince] = useState(null);
    const [ownerName, setOwnerName] = useState("");
    const [isOwnerNameDisabled, setIsOwnerNameDisabled] = useState(false);
    const [amount, setAmount] = useState({});
    const [description, setDescription] = useState("");
    const [standardDescription, setStandardDescription] = useState(null);

    const [checkingFastTransfer, setCheckingFastTransfer] = useState(false);
    const [isFastTransferAvailable, setIsFastTransferAvailable] = useState(
        false
    );

    // set this state to anything to call function getTargetAccount
    const [isAutoFilled, setIsAutoFilled] = useState(false);
    const [isAutoFilledTargetChecked, setIsAutoFilledTargetChecked] = useState(
        false
    );

    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);
    const [validatingAmount, setValidatingAmount] = useState(false);

    const isInValidIBFTNapas = useMemo(() => {
        return description.length > INPUT_RULES.MAXIMUM_CHARACTER;
    }, [description]);

    const initErrors = useMemo(
        () => ({
            selectedBank: null,
            province: null,
            branch: null,
            targetInfo: null,
            ownerName: null,
            amount: null,
            description: null,
        }),
        []
    );
    const [errors, setErrors] = useState(initErrors);

    const clearErrors = useCallback(() => setErrors(initErrors), [initErrors]);

    const [destination, setDestination] = useState(null);

    const back = useCallback(() => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const next = useCallback(() => {
        if (activeStep === steps.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, steps]);

    const cancel = useCallback(() => {
        history.push(`/spend-account/${SpendAccountNavigationKey.MoveMoney}`);
    }, [history]);

    const fastTransferNotSupportWarning = useCallback(() => {
        setErrors((prev) => ({
            ...prev,
            selectedBank: t(
                "fast_transfer_msg_currently_bank_not_available_for_fast_cash"
            ),
        }));
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Warning",
                header: "li_lb_tittle_service_unavailable",
                content: "mb_del_destination_create_new",
                doubleButton: [
                    {
                        label: t("fs_tf_retrieve_error_btn"),
                        onClick: () => {
                            GlobalDialogController.hide();
                            setIsOwnerNameDisabled(false);
                            setIsFastTransferAvailable(false);
                            setIsAutoFilled(false);
                            setSupportFastTransfer(false);
                        },
                    },
                    {
                        label: t("lb_cancel"),
                        onClick: () => {
                            // setDestination(null);
                            // setTargetInfo("");
                            setIsAutoFilled(false);
                            GlobalDialogController.hide();
                        },
                    },
                ],
            },
        });
    }, [t]);

    const checkFastTransferStatus = useCallback(
        async (bankId) => {
            setCheckingFastTransfer(true);
            const response = await moveMoneyService.checkFastTransferStatus({
                bankId,
            });

            if (unmounted.current) return;

            setCheckingFastTransfer(false);

            try {
                const {
                    code,
                    data: { fastTransferSupported, isAllowNormalTransfer },
                } = response.data;

                switch (code) {
                    case RESPONSE_CODE.SUCCESS:
                        if (fastTransferSupported) {
                            setSupportFastTransfer(fastTransferSupported);
                            return fastTransferSupported;
                        }

                        if (
                            !fastTransferSupported &&
                            isAllowNormalTransfer &&
                            queryParams.autofill === "1"
                        ) {
                            // BVB not support fast transfer
                            fastTransferNotSupportWarning();
                            setSupportFastTransfer(false);
                            return;
                        }

                        if (!fastTransferSupported && !isAllowNormalTransfer) {
                            // Mambu core not support fast transfer
                            setSupportFastTransfer(false);
                            GlobalDialogController.showCustomDialog({
                                dialogInfo: {
                                    iconImage: "Warning",
                                    header:
                                        "mm_transfer_mambubcore_popup_247_not_available_title",
                                    content:
                                        "mm_transfer_mambucore_popup_247_not_available_message",
                                    button: "payee_list_btn_ben_bank_got_it",
                                },
                                onClose: cancel,
                            });
                            return;
                        }
                        break;
                    default:
                        GlobalDialogController.showError({ errorCode: code });
                        history.push("/spend-account/move-money");
                        return;
                }
            } catch (error) {
                GlobalDialogController.showError({
                    errorCode: RESPONSE_CODE.TECHNICAL_DIFFICULT,
                });
                history.push("/spend-account/move-money");
                return;
            }

            return false;
        },
        [fastTransferNotSupportWarning, cancel, queryParams, history]
    );

    const getTargetAccount = useCallback(async () => {
        if (
            !spendAccount ||
            !selectedBank ||
            !targetInfo ||
            fetchingTargetAccount
        ) {
            return;
        }

        setErrors((prev) => ({
            ...prev,
            targetInfo: null,
            ownerName: null,
        }));
        setDestination({
            account: {
                accountNumber: targetInfo,
                bankName: selectedBank.bankName,
                bankId: selectedBank.bankId,
            },
        });

        if (!isFastTransferAvailable) {
            return;
        }

        // setOwnerName("");
        setIsOwnerNameDisabled(true);
        setFetchingTargetAccount(true);

        const payload = {
            bankId: selectedBank.bankId,
            targetInfo,
            bankAccount: spendAccount.no,
        };

        const response = await moveMoneyService.getAccountDetailByAccountNumber(
            payload
        );

        if (!unmounted.current) {
            setFetchingTargetAccount(false);

            const { code, data } = response.data;

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    if (data.bank.accountNumber === spendAccount.no) {
                        setDestination(null);
                        setErrors((prev) => ({
                            ...prev,
                            targetInfo: t(
                                "payee_card_number_invalid_payee_is_payer"
                            ),
                        }));
                    } else {
                        setIsOwnerNameDisabled(true);
                        setIsFastTransferAvailable(true);
                        setOwnerName(
                            data.account?.cardName ||
                                data.bank?.accountName ||
                                data.card?.accountName
                        );
                        setDestination(data);
                    }
                    break;
                case RESPONSE_CODE.WRONG_INFO:
                    fastTransferNotSupportWarning();
                    break;
                case RESPONSE_CODE.FAST_TRANSFER_NOT_SUPPORT_CORE:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Error",
                            header:
                                "mm_transfer_mambubcore_popup_247_not_available_title",
                            content:
                                "mm_transfer_mambucore_popup_247_not_available_message",
                            button: "payee_list_btn_ben_bank_got_it",
                        },
                        onClose: () => {
                            history.goBack();
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    history.push("/spend-account/move-money");

                    // setIsOwnerNameDisabled(false);
                    // setIsFastTransferAvailable(false);
                    // setErrors((prev) => ({
                    //     ...prev,
                    //     targetInfo: t("payee_account_number_submitted_invalid"),
                    // }));
                    break;
            }
        }
    }, [
        t,
        spendAccount,
        targetInfo,
        fetchingTargetAccount,
        selectedBank,
        isFastTransferAvailable,
        history,
        fastTransferNotSupportWarning,
    ]);

    // Use for existing destination
    useEffect(() => {
        if (isAutoFilled && !isAutoFilledTargetChecked) {
            setIsAutoFilledTargetChecked(true);
            getTargetAccount();
        }
    }, [getTargetAccount, isAutoFilled, isAutoFilledTargetChecked]);

    const onBankChange = useCallback(
        async ({ value, autofill }) => {
            if (checkingFastTransfer) {
                return;
            }

            let isFastTransferAvailableFromServer = false;

            if (!!value && value.realTimeTransfer === "Y") {
                isFastTransferAvailableFromServer = await checkFastTransferStatus(
                    value.bankId
                );
            } else {
                isFastTransferAvailableFromServer = false;
                setSupportFastTransfer(false);
            }
            if (unmounted.current) {
                return;
            }
            const filteredBranchList = value
                ? (branchList || []).filter((b) => b?.bankId === value?.bankId)
                : [];

            const filteredProvinceIdList = uniqBy(
                filteredBranchList,
                "provinceId"
            ).map((p) => p.provinceId);

            const filteredProvinceList = filter(provinceList, (p) =>
                filteredProvinceIdList.includes(p.provinceId)
            );

            clearErrors();

            selectedBankBranchList.current = filteredBranchList;

            if (
                !isFastTransferAvailableFromServer &&
                value?.realTimeTransfer === "Y"
            ) {
                setErrors((prev) => ({
                    ...prev,
                    selectedBank: t(
                        "fast_transfer_msg_currently_bank_not_available_for_fast_cash"
                    ),
                }));
            }

            setIsFastTransferAvailable(isFastTransferAvailableFromServer);
            setSelectedBankProvinceList(filteredProvinceList);
            setSelectedBank(value);
            setProvince(
                autofill?.provinceId
                    ? provinceList.find(
                          (p) => p.provinceId === autofill.provinceId
                      )
                    : null
            );
            setBranch(
                autofill?.branchId
                    ? branchList.find((b) => b.branchId === autofill.branchId)
                    : null
            );
            setTargetInfo(
                autofill?.accountNumber ? autofill?.accountNumber : ""
            );
            setOwnerName(autofill?.accountName ? autofill?.accountName : "");
            setIsOwnerNameDisabled(isFastTransferAvailableFromServer);

            if (autofill) {
                setIsAutoFilled(true);
            }
        },
        [
            branchList,
            checkFastTransferStatus,
            checkingFastTransfer,
            clearErrors,
            provinceList,
            t,
        ]
    );

    const onProvinceChange = useCallback((e, value) => {
        // const filteredBranchList = value
        //     ? selectedBankBranchList.current.filter(
        //           (b) => b.provinceId === value.provinceId
        //       )
        //     : [];

        setErrors((prevErrors) => ({
            ...prevErrors,
            province: null,
            branch: null,
        }));
        // setSelectedBankProvinceBranchList(filteredBranchList);
        setBranch(null);
        setProvince(value);
    }, []);

    const onBranchSelectionFocus = useCallback(() => {
        const filteredBranchList = province
            ? selectedBankBranchList.current.filter(
                  (b) => b.provinceId === province.provinceId
              )
            : [];
        setSelectedBankProvinceBranchList(filteredBranchList);
    }, [province]);

    const onBranchChange = useCallback((e, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            branch: null,
        }));
        setBranch(value);
    }, []);

    const onAccountNumberChange = useCallback(
        (e) => {
            if (errors.targetInfo) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    targetInfo: null,
                }));
            }
            setDestination(null);
            setTargetInfo(e.target.value);
        },
        [errors.targetInfo]
    );

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value: +value });
        },
        [errors.amount]
    );

    const onOwnerNameChange = useCallback(
        (e) => {
            if (errors.ownerName) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    ownerName: null,
                }));
            }
            setOwnerName(escapeAccentVietnamese(e.target.value));
        },
        [errors.ownerName]
    );

    const validateFormData = useCallback(() => {
        const formErrors = {};

        if (!selectedBank) {
            formErrors.selectedBank = t("msg_we_need_this");
        }
        if (!targetInfo) {
            formErrors.targetInfo = t("msg_we_need_this");
        }
        if (!isFastTransferAvailable) {
            if (!province) {
                formErrors.province = t("msg_we_need_this");
            }
            if (!branch) {
                formErrors.branch = t("msg_we_need_this");
            }
        }
        if (!ownerName) {
            formErrors.ownerName = t("msg_we_need_this");
        }
        if (!amount?.value) {
            formErrors.amount = t("msg_we_need_this");
        }

        setErrors(formErrors);

        const isValid = Object.keys(formErrors).length <= 0;

        return isValid;
    }, [
        t,
        selectedBank,
        targetInfo,
        isFastTransferAvailable,
        ownerName,
        province,
        branch,
        amount,
    ]);

    const onNextHandler = useCallback(async () => {
        const desc = await getStandardDescription({
            defaultDescription,
            description,
        });
        setStandardDescription(desc?.standardDescription);
        if (description === "") {
            setDescription(desc?.description);
        }
        next();
    }, [next, description, defaultDescription, getStandardDescription]);

    const submitForm = useCallback(
        async (event) => {
            event.preventDefault();

            const isValid = validateFormData();

            if (!spendAccount || fetchingTargetAccount || !isValid) {
                return;
            }

            validateAmount({
                amount: amount.value,
                transferType: "bank",
                isFastTransfer: isFastTransferAvailable,
                beforeRequest: () => setValidatingAmount(true),
                isCancelled: unmounted.current,
                afterResponse: () => setValidatingAmount(false),
                handleBalanceLessThanMinAmount: (minAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: `${t("spend_msg_for_least_amount").replace(
                            "%@",
                            formatNumber(minAmount)
                        )}`,
                    })),
                handleAmountOutOfRange: (minAmount, maxAmount) => {
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_amount_invalid")
                            .replace("@", formatNumber(minAmount))
                            .replace("%@", formatNumber(maxAmount)),
                    }));
                },
                handleAmountOverDailyLimit: () =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_daily_limit"),
                    })),
                handleAmountValid: () => {
                    if (!isFastTransferAvailable) {
                        setDestination((prev) => ({
                            ...prev,
                            account: {
                                ...prev.account,
                                accountName: ownerName,
                                branchId: branch.branchId,
                                provinceId: province.provinceId,
                            },
                        }));
                    }
                    onNextHandler();
                },
                canUseOverdraft: true,
            });
        },
        [
            validateFormData,
            spendAccount,
            fetchingTargetAccount,
            t,
            validateAmount,
            amount,
            formatNumber,
            isFastTransferAvailable,
            ownerName,
            branch,
            province,
            onNextHandler,
        ]
    );

    const onCompleteSendAgainAction = useCallback(() => {
        setActiveStep(0);
    }, []);

    const receiptOptions = useMemo(() => {
        return {
            enableSendAgainButton: true,
            onCompleteSendAgainHandler: onCompleteSendAgainAction,
        };
    }, [onCompleteSendAgainAction]);

    const confirmTransfer = useCallback(
        (saveAsPayee) => {
            const notification = "sms";

            const source = {
                accountNumber: spendAccount.no,
            };

            const target = {
                amount: +amount.value,
                desType: destination.type,
                description: standardDescription,
                originalFullDescription: description,
            };

            const destinationInfo = destination.account || destination.bank;

            target.accountName = destinationInfo.accountName
                ? destinationInfo.accountName
                : destinationInfo.cardName;
            target.accountNumber = destinationInfo.accountNumber
                ? destinationInfo.accountNumber
                : destinationInfo.cardNumber;
            target.bankId = destinationInfo.bankId;
            target.bankName = destinationInfo.bankName;
            target.branchId = destinationInfo.branchId;
            target.provinceId = destinationInfo.provinceId;
            target.isNormalTransferSelected = !isFastTransferAvailable;

            if (queryParams.autofill === "1") {
                const destinationStr = localStorage.getItem(
                    "transferDestination"
                );
                if (destinationStr) {
                    const autofillDestination = JSON.parse(destinationStr);
                    target.desType = "bankaccount";
                    if (autofillDestination?.payeeId) {
                        target.payeeId = autofillDestination?.payeeId;
                    }
                    if (autofillDestination?.accountId) {
                        target.desTypeId = autofillDestination?.accountId?.toString();
                    }
                }
            }

            if (saveAsPayee) {
                target.saveAsPayee = saveAsPayee;
            }

            const payload = {
                notification,
                source,
                target,
            };

            transferToAccountNumber(payload);
        },
        [
            spendAccount,
            destination,
            description,
            amount,
            transferToAccountNumber,
            standardDescription,
            queryParams,
            isFastTransferAvailable,
        ]
    );

    useEffect(() => {
        if (fetchingTargetAccount || isAutofillProcessed) {
            return;
        }
        if (queryParams.autofill === "1") {
            const destinationStr = localStorage.getItem("transferDestination");
            if (destinationStr) {
                const autofillDestination = JSON.parse(destinationStr);
                setIsAutofillProcessed(true);

                onBankChange({
                    value: bankList.find(
                        (b) => b.bankId === autofillDestination.bankId
                    ),
                    autofill: autofillDestination,
                });

                // use for send again button from receipt
                if (autofillDestination?.accName) {
                    setOwnerName(autofillDestination?.accName);
                }

                if (
                    autofillDestination?.filledDescription &&
                    autofillDestination?.filledDescription !== ""
                ) {
                    setDescription(autofillDestination?.filledDescription);
                }
                if (
                    autofillDestination?.amount &&
                    autofillDestination?.amount !== ""
                ) {
                    setAmount(autofillDestination?.amount);
                }
            }
        }
    }, [
        location.search,
        onBankChange,
        getTargetAccount,
        fetchingTargetAccount,
        isAutofillProcessed,
        queryParams.autofill,
        bankList,
    ]);

    useEffect(() => {
        if (errorCodeAmount) {
            const error = getErrorByResponseCode(errorCodeAmount);
            setErrors((prev) => ({
                ...prev,
                amount: error,
            }));
            setActiveStep(0); // back to input info amount
        }
    }, [errorCodeAmount, getErrorByResponseCode]);

    const isActiveFastTransfer = useMemo(() => {
        const maxAmountSML = parseFloat(systemParams?.conf?.MaxAmountSML);
        const amountPreview = amount.value;

        const bankId =
            destination?.bank?.bankId ||
            destination?.card?.bankId ||
            destination?.account?.bankId;

        if (bankId === BANK_INFO.DEFAULT_BANK_1_ID.toString()) {
            console.log("test.....");
            return false;
        }

        return (
            isSupportFastTransfer &&
            featureState?.enableNapas247Logo &&
            maxAmountSML >= amountPreview
        );
    }, [
        amount.value,
        featureState?.enableNapas247Logo,
        systemParams?.conf?.MaxAmountSML,
        isSupportFastTransfer,
        destination,
    ]);

    return (
        <Paper component={Box} overflow="hidden">
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((s) => (
                    <Step key={s.id}>
                        <StepLabel>{s.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 0 && (
                <form onSubmit={submitForm}>
                    <Box p={3} paddingTop={1}>
                        <LspBankList
                            disabled={checkingFastTransfer || isAutoFilled}
                            value={selectedBank}
                            onChange={(_, value) => onBankChange({ value })}
                            renderInput={(params) => {
                                const inputProps = checkingFastTransfer
                                    ? {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <CircularProgress size={16} />
                                              </InputAdornment>
                                          ),
                                      }
                                    : params.InputProps;
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t("payee_bank_name_label")}
                                        error={!!errors.selectedBank}
                                        helperText={errors.selectedBank || " "}
                                        InputProps={inputProps}
                                    />
                                );
                            }}
                        />

                        <LspTextField
                            label={t("lb_account_number")}
                            error={!!errors.targetInfo}
                            helperText={errors.targetInfo || " "}
                            disabled={!selectedBank || isAutoFilled}
                            onBlur={getTargetAccount}
                            onChange={onAccountNumberChange}
                            value={targetInfo}
                            InputProps={{
                                endAdornment: fetchingTargetAccount ? (
                                    <InputAdornment position="end">
                                        <CircularProgress size={16} />
                                    </InputAdornment>
                                ) : null,
                            }}
                        />

                        {selectedBank &&
                            !isFastTransferAvailable &&
                            selectedBank?.bankId !==
                                BANK_INFO.DEFAULT_BANK_2_ID.toString() && (
                                <>
                                    <Autocomplete
                                        options={selectedBankProvinceList}
                                        getOptionLabel={(option) =>
                                            option?.provinceName || ""
                                        }
                                        value={province}
                                        onChange={onProvinceChange}
                                        renderInput={(params) => (
                                            <LspTextField
                                                {...params}
                                                label={t(
                                                    "payee_bank_province_label"
                                                )}
                                                error={!!errors.province}
                                                helperText={
                                                    errors.province || " "
                                                }
                                            />
                                        )}
                                        disabled={isAutoFilled}
                                    />

                                    <Autocomplete
                                        options={selectedBankProvinceBranchList}
                                        getOptionLabel={(option) =>
                                            option?.branchName || ""
                                        }
                                        value={branch}
                                        onChange={onBranchChange}
                                        onFocus={onBranchSelectionFocus}
                                        renderInput={(params) => (
                                            <LspTextField
                                                {...params}
                                                label={t(
                                                    "payee_bank_branch_label"
                                                )}
                                                error={!!errors.branch}
                                                helperText={
                                                    errors.branch || " "
                                                }
                                            />
                                        )}
                                        disabled={isAutoFilled}
                                    />
                                </>
                            )}
                        <LspTextField
                            label={t("payee_account_owner_name_label")}
                            error={!!errors.ownerName}
                            disabled={isOwnerNameDisabled || isAutoFilled}
                            helperText={errors.ownerName || " "}
                            onChange={onOwnerNameChange}
                            value={ownerName}
                        />
                        <LspSuggestAmount
                            amount={amount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onAmountChange}
                            label={t("payee_card_amount_label")}
                            error={!!errors.amount}
                            helperText={errors.amount || " "}
                            customInput={LspTextField}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            numberValue={amount.value}
                            onChange={onAmountChange}
                            inputProps={{
                                id: "amountbox",
                            }}
                        />
                        <LspTextField
                            label={t("payee_card_description_label")}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            value={description}
                            multiline
                            error={isInValidIBFTNapas}
                            helperText={
                                isInValidIBFTNapas
                                    ? t(
                                          "master:move_money_desc_over_characters"
                                      )
                                    : ""
                            }
                            inputProps={{
                                maxLength: 254,
                                id: "descbox",
                            }}
                        />
                        <Box marginTop={1.5}>
                            <DoubleButtons
                                progressing={validatingAmount}
                                primaryButton={{
                                    label: t("lb_next"),
                                    disable: isInValidIBFTNapas,
                                }}
                                secondaryButton={{
                                    label: t("lb_cancel"),
                                    onClick: cancel,
                                }}
                            />
                        </Box>
                    </Box>
                </form>
            )}

            {activeStep === 1 && (
                <TransferConfirmation
                    onBack={back}
                    isFastTransfer={isActiveFastTransfer}
                    onConfirm={confirmTransfer}
                    destination={destination}
                    amount={amount.value}
                    description={description}
                    progressing={transferring}
                    disableSavePayee={queryParams.autofill === "1"}
                />
            )}
            {activeStep === 2 && (
                <LspReceipt
                    onClose={cancel}
                    rawReceipt={transferInfo}
                    options={receiptOptions}
                />
            )}
        </Paper>
    );
};

const mapState = (state) => ({
    transferring: state.moveMoney.fetching,
    transferStatus: state.moveMoney.status,
    transferInfo: state.moveMoney.info,
    existingPayee: state.moveMoney.existingPayee,
    errorCodeAmount: state.moveMoney.errorCodeAmount,
    fullName: state.user.info.fullName,
    systemParams: state.systemParams.info,
    featureState: state.user.featureState.data,
    bankList: state.bank.bankList.data,
    provinceList: state.bank.provinceList.data,
    branchList: state.bank.branchList.data,
});

const mapDispatch = (dispatch) => ({
    transferToAccountNumber: (payload) =>
        dispatch(moveMoneyAction.transferToAccountNumberRequest(payload)),
    resetTransfer: () => dispatch(moveMoneyAction.reset()),
});

export default connect(mapState, mapDispatch)(BankAccountTransfer);
