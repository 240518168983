import { useTranslation } from "react-i18next";

import LspTextField from "@components/LspTextField";
import NumberFormat from "react-number-format";

const PhoneNumberInput = ({ phoneNumber, updatePhoneNumber, error }) => {
    const { t } = useTranslation();

    return (
        <div>
            <NumberFormat
                value={phoneNumber}
                onValueChange={({ value }) => updatePhoneNumber(value)}
                label={t("topup_lb_new_mobile_number")}
                error={!!error}
                helperText={error || " "}
                customInput={LspTextField}
                format="### ### ####"
                mask="_"
            />
        </div>
    );
};

// const mapState = (state) => ({
//     phoneNumber: state.topUp.phoneNumber,
// });

export default PhoneNumberInput;
