import LspButton from "@components/LspButton";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { Box, makeStyles } from "@material-ui/core";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        contentIntro: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            margin: "0 auto",
            padding: theme.spacing(2, 3.75, 2, 2),
            maxWidth: "400px",
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        introBtn: {
            padding: theme.spacing(2, 3.75),
        },
        contentItem: {
            marginTop: theme.spacing(1.25),
        },
    };
});

const VinaWealthIntro = ({ user, onClickItem }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const contentIntro = useMemo(() => {
        const arrayContent = t("wealth:vc3_start_investing_content", {
            returnObjects: true,
        });
        return arrayContent;
    }, [t]);

    const checkForeigner = () => {
        if (user.isForeigner) {
            console.log("foreigner");
        } else {
            onClickItem(VINA_WEALTH_STEPS.DASHBOARD);
        }
    };
    return (
        <>
            <Box className={classes.titleContent}>
                {t("wealth:vc3_start_investing_header")}
            </Box>
            <Box pl={2} pr={2}>
                <Box className={classes.contentIntro}>
                    <Box>
                        <ul>
                            {contentIntro.map((item) => (
                                <li className={classes.contentItem}>{item}</li>
                            ))}
                        </ul>
                    </Box>
                    <Box className={classes.introBtn}>
                        <LspButton
                            type="submit"
                            fullWidth
                            onClick={checkForeigner}
                        >
                            {t("wealth:vc3_letgo_btn")}
                        </LspButton>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
});

export default connect(mapState, null)(VinaWealthIntro);
