/* eslint-disable react/no-array-index-key */
import { Paper } from "@material-ui/core";
import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import Interweave from "interweave";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

import { WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS } from "../wealthConstants";

const WealthCreditCardMethodDetail = ({
    method,
    onNext,
    onBack,
    isApplied,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();

    const content = useMemo(() => {
        switch (method) {
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE:
                return {
                    title: t("wealth:mc_v3_method1_title"),
                    subtitle: t("wealth:mc_v3_method1_subtitle"),
                    content: t("wealth:mc_v3_method1_msg", {
                        returnObjects: true,
                    }),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD:
                return {
                    title: t("wealth:mc_v3_method2_title"),
                    subtitle: t("wealth:mc_v3_method2_subtitle"),
                    content: t("wealth:mc_v3_method2_msg", {
                        returnObjects: true,
                    }),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD:
                return {
                    title: t("wealth:mc_v3_method3_title"),
                    subtitle: t("wealth:mc_v3_method3_subtitle"),
                    content: t("wealth:mc_v3_method3_msg", {
                        returnObjects: true,
                    }),
                };
            default:
                break;
        }
    }, [method, t]);

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Interweave content={content?.title} />
            </PageHeaderBack>
            <Paper className={`highlight ${sharedClasses.boxWrapper}`}>
                <LspTypography
                    variant="body2"
                    color="primary"
                    style={{ textAlign: "center" }}
                >
                    {content?.subtitle}
                </LspTypography>
                <ul className={sharedClasses.contentList}>
                    {content?.content?.map((c, index) => {
                        return <li key={index}>{c}</li>;
                    })}
                </ul>
            </Paper>
            {!isApplied && (
                <LspButton fullWidth onClick={onNext}>
                    {t("wealth:mc_v3_btn_get_card")}
                </LspButton>
            )}
        </>
    );
};

const mapState = (state) => ({
    isApplied: state.wealthCreditCard.isApplied,
});

export default memo(connect(mapState)(WealthCreditCardMethodDetail));
