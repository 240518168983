/* eslint-disable react/forbid-prop-types */
import { makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";
import { memo, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import LspCardItemBasic from "./LspCardItemBasic";
import LspCardItemAdvance from "./LspCardItemAdvance";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
    },
    buttonItem: {
        display: "flex",
        padding: theme.spacing(2.5, 4.25),
        background: theme.palette.background.header,
        marginBottom: theme.spacing(1.5),
        boxShadow: theme.shadows[3],
        justifyContent: "space-between",
        textAlign: "left",
        minHeight: 100,
        width: "100%",
        borderRadius: theme.shape.radiusLevels[0],
    },
    advanceWrapper: {
        flexDirection: "column",
    },
    advanceItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    selected: {
        background: theme.palette.background.paper,
        borderTop: `4px solid ${theme.palette.blue.main}`,
        width: "104%",
    },
    label: {
        fontStyle: "italic",
    },
    fetchingItem: {
        width: "100%",
        margin: theme.spacing(1),
        padding: theme.spacing(2.5),
        height: 100,
    },
}));

/**
 * @typedef ListObject
 * @type {object}
 * @property {any} id
 * @property {object} left
 * @property {object} right
 */

/**
 * @typedef LspCardListProps
 * @type {object}
 * @property {object} className
 * @property {object} selected
 * @property {function} onClick
 * @property {bool} fetching
 * @property {string} variant
 * @property  {Array.<ListObject>} list
 */

const LspCardSelection = ({
    className,
    selected,
    onClick,
    fetching,
    variant,
    list,
}) => {
    const classes = useStyles();
    const fetchingLayer = useMemo(() => {
        return (
            <>
                {[...new Array(3)]?.map((_, index) => {
                    return (
                        <Paper
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={classes.fetchingItem}
                        >
                            <Skeleton width={105} />
                            <Skeleton width={173} />
                            <Skeleton width={245} />
                        </Paper>
                    );
                })}
            </>
        );
    }, [classes]);

    return (
        <div
            className={clsx(classes.wrapper, {
                [className]: className,
            })}
        >
            {fetching && fetchingLayer}
            {!fetching &&
                list?.map((item) => (
                    <Fragment key={item?.id}>
                        {variant === "basic" && (
                            <LspCardItemBasic
                                item={item}
                                selected={selected?.id === item.id}
                                onClick={() => onClick(item)}
                                classes={classes}
                            />
                        )}

                        {variant === "advance" && (
                            <LspCardItemAdvance
                                item={item}
                                selected={selected?.id === item.id}
                                onClick={() => onClick(item)}
                                classes={classes}
                            />
                        )}
                    </Fragment>
                ))}
        </div>
    );
};

LspCardSelection.propTypes = {
    fetching: PropTypes.bool,
    className: PropTypes.object,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(["basic", "advance"]),
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            left: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                label: PropTypes.string,
                amount: PropTypes.number,
            }),
            right: PropTypes.shape({
                label: PropTypes.string,
                amount: PropTypes.number,
            }),
        })
    ),
    selected: PropTypes.object,
};

LspCardSelection.defaultProps = {
    fetching: false,
    onClick: null,
    className: null,
    variant: "basic",
    list: null,
    selected: null,
};

export default memo(LspCardSelection);

// For advance type
// const list = [
//     {
//         id: 0,
//         left: {
//             title: "FFFFFF",
//             label: "HHHHH",
//             amount: 12000,
//         },
//         right: {
//             label: "HHHHH",
//             amount: 12000,
//         },
//     },
//     {
//         id: 1,
//         left: {
//             title: "FFFFFF",
//             label: "HHHHH",
//             amount: 12000,
//         },
//         right: {
//             label: "HHHHH",
//             amount: 12000,
//         },
//     },
//     {
//         id: 2,
//         left: {
//             title: "FFFFFF",
//             label: "HHHHH",
//             amount: 12000,
//         },
//         right: {
//             label: "HHHHH",
//             amount: 12000,
//         },
//     },
// ];

// For basic type
// const list = [
//     {
//         id: 0,
//         left: {
//             title: "FFFFFF",
//             description:
//                 "An tâm vui sống mặc cho những bất ngờ không mong đợi",
//         },
//     },
//     {
//         id: 1,
//         left: {
//             title: "FFFFFF",
//         },
//         right: {
//             label: "HHHHH",
//             amount: 12000,
//         },
//     },
//     {
//         id: 2,
//         left: {
//             title: "FFFFFF",
//             description:
//                 "An tâm vui sống mặc cho những bất ngờ không mong đợi",
//         },
//         right: {
//             label: "HHHHH",
//             amount: 12000,
//         },
//     },
// ];
