/* eslint-disable no-unreachable */
import { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import JSEncrypt from "jsencrypt";
import Content from "@components/Content";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import tommySuccess2 from "@assets/images/tommy_success_status_2.png";
import tomiCry from "@assets/images/tommy_cry_status.png";

import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import usePathname from "@helpers/usePathname";
import {
    CARD_ACTIVATE_SUCCESS_TYPES,
    CARD_MANAGEMENT_SUB_NAV,
    CARD_TYPES,
    CORE_CARD,
} from "./constant";
import InputCardNumber from "./InputCardNumber";
import CardPin from "./CardPin";
import CardManagementActions from "../../redux/actions/card-management";
import { FetchStatus } from "../../config/constants";
import CardActivateSuccess from "./CardActivateSuccess";
import PhysicalCardStatus from "./physicalCard/PhysicalCardStatus";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAC);

const cryptMambu = new JSEncrypt();
cryptMambu.setPublicKey(process.env.REACT_APP_RSAC_CORE);

const useStyles = makeStyles((theme) => ({
    infoBoxText: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        color: theme.palette.magicalGrey[400],
        textAlign: "left",
        padding: theme.spacing(2),
    },
}));

const STATUS_ALLOW = [FetchStatus.Success, FetchStatus.Error];

const CardActivation = ({
    onClose,
    onDone,
    cardType,
    resetStatus,
    sysParam,
    userInfo,
    activateCard,
    activateCardStatus,
    isReplaceChipCard,
    type,
    featureState,
    getCardList,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [step, setStep] = useState(0);
    const [cardNumber, setCardNumber] = useState(null);
    const { getQueryParams } = usePathname();

    const cardId = getQueryParams("cardId");

    const coreCard = useMemo(() => {
        if (getQueryParams("coreCard") && getQueryParams("coreCard") !== "")
            return getQueryParams("coreCard");

        if (cardType === CARD_TYPES.VISA_CARD) return CORE_CARD.W4;

        return CORE_CARD.BVB;
    }, [cardType, getQueryParams]);

    const cardActivateSuccessType = useMemo(() => {
        return isReplaceChipCard
            ? CARD_ACTIVATE_SUCCESS_TYPES.REPLACE_CHIP_CARD
            : CARD_ACTIVATE_SUCCESS_TYPES.DEFAULT;
    }, [isReplaceChipCard]);

    const isVirtualDebitVisaPhysical = useMemo(
        () =>
            cardType ===
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD ||
            cardType ===
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP,
        [cardType]
    );

    const isCreditPhysical = useMemo(() => {
        return (
            cardType === CARD_TYPES.VISA_CARD &&
            featureState?.enableImmediatelyUseCCW4 &&
            coreCard !== CORE_CARD.BVB
        );
    }, [cardType, featureState?.enableImmediatelyUseCCW4, coreCard]);

    const isPhysicalScreen = useMemo(
        () => isCreditPhysical || isVirtualDebitVisaPhysical,
        [isCreditPhysical, isVirtualDebitVisaPhysical]
    );

    const closeDialog = () => {
        GlobalDialogController.hide();
    };

    const physicalCardSuccess = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <PhysicalCardStatus
                    imgUrl={tommySuccess2}
                    title={t(
                        "wealth:mc_v2_activate_offline_feature_successful_title"
                    )}
                    description={t(
                        "wealth:mc_v2_activate_offline_feature_successful_desc"
                    )}
                    note={
                        <>
                            <LspTypography className={classes.infoBoxText}>
                                {t(
                                    "wealth:mc_v2_activate_offline_feature_successful_note"
                                )}
                            </LspTypography>
                        </>
                    }
                    onClose={closeDialog}
                />
            ),
        });
    }, [classes.infoBoxText, t]);

    const physicalCardErrors = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <PhysicalCardStatus
                    imgUrl={tomiCry}
                    title={t(
                        "wealth:mc_v2_activate_offline_feature_fail_title"
                    )}
                    description={t(
                        "wealth:mc_v2_activate_offline_feature_fail_desc"
                    )}
                    onClose={closeDialog}
                />
            ),
        });
    }, [t]);

    const nextStep = useCallback(() => {
        setStep(step + 1);
        resetStatus();
    }, [step, resetStatus]);

    const backStep = () => {
        if (step <= 0) return;
        setStep(step - 1);
        resetStatus();
    };

    const isLast4NumberRequired = useCallback(() => {
        return sysParam.CheckLast4DebitCardNumber;
    }, [sysParam]);

    const isCreditCreatePinRequired = () => {
        return sysParam.AllowChangePinCC;
    };

    // Check init view
    useEffect(() => {
        if (cardType === CARD_TYPES.DEBIT_CARD) {
            if (isLast4NumberRequired()) {
                setStep(0);
            } else {
                setStep(1);
            }
        } else {
            setStep(0);
        }
    }, [isLast4NumberRequired, cardType]);

    // Activate card successfully ----> go to success page
    useEffect(() => {
        if (!STATUS_ALLOW.includes(activateCardStatus)) {
            return;
        }

        if (!isPhysicalScreen && activateCardStatus === FetchStatus.Success) {
            nextStep();
            return;
        }

        // isPhysicalScreen = true

        if (activateCardStatus === FetchStatus.Success) {
            physicalCardSuccess();
        } else {
            physicalCardErrors();
        }

        if (onDone) {
            onDone(cardType);
        }

        getCardList();
        resetStatus();
    }, [
        activateCardStatus,
        cardType,
        getCardList,
        nextStep,
        onDone,
        physicalCardErrors,
        physicalCardSuccess,
        resetStatus,
        isPhysicalScreen,
    ]);

    const onDoneClick = () => {
        if (onDone) {
            onDone(cardType);
        }

        getCardList();
    };

    const cardNumberNextHandler = (cardNum) => {
        if (!cardNum) return;
        setCardNumber(cardNum);
        nextStep();
    };

    const submitCardActivation = (pin) => {
        const newPin = crypt.encrypt(pin);
        const newPinMambu = cryptMambu.encrypt(pin);

        const dataToPost = {
            ekycId: userInfo.ekycId || "",
            cardType,
            enableImmediatelyUseCCW4: featureState?.enableImmediatelyUseCCW4,
            disableDefaultPopup: isPhysicalScreen,
        };

        switch (cardType) {
            case CARD_TYPES.DEBIT_CARD:
                dataToPost.newPin = newPin;
                dataToPost.newPinMambu = newPinMambu;
                if (isLast4NumberRequired()) {
                    dataToPost.cardNumber = cardNumber;
                    dataToPost.CC = false;
                }
                break;
            case CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD:
            case CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP:
                dataToPost.CC = true;
                dataToPost.cardNumber = cardNumber;
                dataToPost.cardId = cardId;
                dataToPost.physicalVirtual = true;
                dataToPost.cardType = CARD_TYPES[cardType];
                dataToPost.newPin = newPin;
                dataToPost.newPinMambu = newPinMambu;
                break;
            default:
                // credit
                dataToPost.CC = true;
                dataToPost.cardNumber = cardNumber;
                if (isCreditCreatePinRequired()) {
                    dataToPost.newPin = newPin;
                    dataToPost.newPinMambu = newPinMambu;
                }
                break;
        }
        activateCard(dataToPost);
    };

    return (
        <Content>
            {step === 0 && (
                <InputCardNumber
                    onClose={onClose}
                    nextHandler={cardNumberNextHandler}
                    cardType={cardType}
                    cardId={cardId}
                />
            )}
            {step === 1 && (
                <CardPin
                    type={type}
                    onClose={backStep}
                    nextHandler={submitCardActivation}
                />
            )}

            {step === 2 && (
                <CardActivateSuccess
                    onClose={onDoneClick}
                    action={cardActivateSuccessType}
                />
            )}
        </Content>
    );
};

const stateProps = (state) => ({
    sysParam: state.systemParams.info.sysparams["1.0"],
    userInfo: state.user.info,
    activateCardStatus: state.cardInfo.activateCardStatus,
    featureState: state.user.featureState.data,
});

const dispatchProps = (dispatch) => ({
    resetStatus: () => dispatch(CardManagementActions.resetStatus()),
    activateCard: (payload) =>
        dispatch(CardManagementActions.cardActivation(payload)),
    getCardList: () => dispatch(CardManagementActions.getCardList()),
});

export default connect(stateProps, dispatchProps)(CardActivation);
