/* eslint-disable react/no-array-index-key */
import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import { REGULATION_TYPE, RESPONSE_CODE } from "@config/constants";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import accountService from "@services/account";
import "react-circular-progressbar/dist/styles.css";
import { differenceInDays, parse, startOfToday } from "date-fns";
import { useHistory } from "react-router-dom";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspCongratulation from "@components/LspCongratulation";
import { connect } from "react-redux";
import accountAction from "@redux/actions/account";
import useInfiniteScroll from "react-infinite-scroll-hook";
import clsx from "clsx";
import LspButton from "@components/LspButton";
import GoalSaveDetail from "@containers/GoalSave/GoalSaveDetail";
import useForeignerDialog from "@helpers/useForeignerDialog";
import { DragItem } from "../Common/DragItem";
import GoalSaveTransfer from "../GoalSaveTransfer";
import GoalSaveItem from "./GoalSaveItem";
import GoalSaveCreate from "../GoalSaveCreate";
import GoalSaveForeigner from "./GoalSaveForeigner";
import PiggyPopup from "./PiggyPopup";
import GoalSaveItemLoading from "./GoalSaveItemLoading";

const useStyles = makeStyles((theme) => ({
    listGoalWrapper: {
        width: "100%",
        borderLeft: `1px solid ${theme.palette.divider}`,
        paddingLeft: 0,
        "&.fixed": {
            marginLeft: theme.spacing(20.25),
        },

        [theme.breakpoints.up("md")]: {
            "&.fixed": {
                marginLeft: theme.spacing(22.25),
            },
        },
    },
    listGoal: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        "& > div": {
            width: "100%",
            maxWidth: "220px",
        },

        [theme.breakpoints.up("sm")]: {
            "& > div": {
                width: "calc(50% - 16px)",
            },
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
            "& > div": {
                width: "calc(33.3% - 16px)",
            },
        },
    },
    spendAccount: {
        cursor: "pointer",
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.main,
        width: "130px",
        height: "130px",
        margin: theme.spacing(0, 0, 2.5, 0),
        display: "block",
        borderRadius: "5px",
        color: theme.palette.neutral.white,
        overflow: "hidden",
        textAlign: "center",
        "& > span": {
            overflow: "unset",
            display: "inline-block",
            paddingTop: theme.spacing(2),
        },
        "& > p": {
            marginTop: theme.spacing(1),
            overflowWrap: "anywhere",
        },
    },
    container: {
        display: "flex",
    },
    piggy: {
        background: theme.palette.background.paper,
        height: 50,
        paddingTop: theme.spacing(2),
    },
    leftPage: {
        paddingRight: theme.spacing(2),
        "&.fixed": {
            top: "20%",
            position: "fixed",
        },
        [theme.breakpoints.down("xs")]: {
            paddingRight: 0,
        },
    },
    root: {
        " & > div": {
            background: theme.palette.orange.main,
        },
    },
    createBtn: {
        textAlign: "right",
    },
}));

const GoalSaveGallery = ({ getAccountList, userInfo }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false); // fetching api
    const [loadingSuccess, setLoadingSuccess] = useState(false); // transfer success
    const [nameGoalTo, setNameGoalTo] = useState("");
    const [nameGoalFrom, setNameGoalFrom] = useState("");
    const [dataGoalSave, setDataGoalSave] = useState(null);
    const [dataGoalSaveDetail, setDataGoalSaveDetail] = useState(null);
    const [addNewSuccess, setAddNewSuccess] = useState(false);
    const { goalSave, spendAccount, getCurrentBalance } = useAccounts();
    const { formatNumber } = useNumber();
    const history = useHistory();
    const [scroll, setScroll] = useState(false);
    const { dialog } = useForeignerDialog();

    const currentBalance = getCurrentBalance(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onScroll = useCallback(() => {
        setScroll(window.scrollY > 140);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    const piggyAccountObj = useMemo(() => dataGoalSaveDetail?.specials[0], [
        dataGoalSaveDetail?.specials,
    ]);

    const spendAccountObj = useMemo(() => {
        return {
            accountName: spendAccount?.name,
            accountAmount: currentBalance,
            accountType: "spend",
            planId: 0,
        };
    }, [spendAccount, currentBalance]);

    const goalSaveFrom = useMemo(() => {
        if (nameGoalFrom !== "" && dataGoalSaveDetail) {
            if (nameGoalFrom === 0) {
                return spendAccountObj;
            }
            if (nameGoalFrom === dataGoalSaveDetail.specials[0].planId) {
                return {
                    ...dataGoalSaveDetail.specials[0],
                    accountType: "piggy",
                    accountName: t("goal_save_account_piggy_title"),
                };
            }
            const item = dataGoalSave.find(
                (elm) => elm.planId === nameGoalFrom
            );
            return item;
        }
    }, [nameGoalFrom, dataGoalSave, spendAccountObj, t, dataGoalSaveDetail]);

    const goalSaveTo = useMemo(() => {
        if (nameGoalTo !== "" && dataGoalSave) {
            if (nameGoalTo === 0) {
                return spendAccountObj;
            }
            if (nameGoalTo === dataGoalSaveDetail.specials[0].planId) {
                return {
                    ...dataGoalSaveDetail.specials[0],
                    accountType: "piggy",
                    accountName: t("goal_save_account_piggy_title"),
                };
            }
            const item = dataGoalSave.find((elm) => elm.planId === nameGoalTo);
            return item;
        }
    }, [nameGoalTo, dataGoalSave, spendAccountObj, t, dataGoalSaveDetail]);

    const checkOnTrack = (item) => {
        if (item.accountType) {
            // no need to check spend or piggy
            if (item.accountType === "piggy" || item.accountType === "spend") {
                item.isOnTrack = true;
                return true;
            }
        }

        if (item.recurringType === "") {
            item.isOnTrack = true;
            return true;
        }
        if (item.contributedAmount <= 0) {
            item.isOnTrack = true;
            return true;
        }

        let today = startOfToday();

        if (item.nextDateContribute) {
            today =
                parse(item.nextDateContribute, "dd/MM/yyyy", new Date()) || "";
        }

        const targetDate =
            parse(item.targetDate, "dd/MM/yyyy", new Date()) || "";

        const totalTime = differenceInDays(targetDate, today);

        if (item.recurringType === "daily") {
            if (item.targetDate !== "") {
                item.quota =
                    totalTime * item.recurringAmount + item.contributedAmount;
            }
        }

        if (item.recurringType === "weekly") {
            if (item.targetDate !== "") {
                item.quota =
                    totalTime * item.recurringAmount + item.contributedAmount;
            }
        }
        if (item.recurringType === "monthly") {
            if (item.targetDate !== "") {
                item.quota =
                    totalTime * item.recurringAmount + item.contributedAmount;
            }
        }
        if (item.quota >= item.targetAmount) {
            item.isOnTrack = true;
            return true;
        }
        item.isOnTrack = false;
        return false;
    };

    const goalSaveList = useMemo(() => {
        const tempList = [];
        if (dataGoalSave) {
            const gsList = dataGoalSave;
            for (let i = 0; i < gsList.length; i++) {
                gsList[i].goalCategory = gsList[i].goalCategory.toUpperCase();
                gsList[i].isDeleted = 0;
                if (gsList[i].recurringType === "daily") {
                    gsList[i].FERecurringType = t("gs_btn_daily");
                } else if (gsList[i].recurringType === "weekly") {
                    gsList[i].FERecurringType = t("gs_btn_weekly");
                } else if (gsList[i].recurringType === "monthly") {
                    gsList[i].FERecurringType = t("gs_btn_monthly");
                } else {
                    gsList[i].FERecurringType = t("gs_label_no_contribute");
                }
                gsList[i].FEPercent = Math.round(
                    (gsList[i].contributedAmount / gsList[i].targetAmount) * 100
                );
                if (gsList[i].FEPercent > 0 && gsList[i].FEPercent < 1) {
                    gsList[i].FEPercent = 1;
                }
                if (gsList[i].FEPercent > 100) {
                    gsList[i].FEPercent = 100;
                }
                if (
                    gsList[i].FEPercent === 100 &&
                    gsList[i].contributedAmount < gsList[i].targetAmount
                ) {
                    gsList[i].FEPercent = 99;
                }
                const isOnTrack = checkOnTrack(gsList[i]);
                tempList.push({ ...gsList[i], isOnTrack });
            }
        }

        // console.log("tempList", tempList);

        return tempList;
    }, [dataGoalSave, t]);

    const getData = useCallback(
        async (resetDate) => {
            setLoading(true);
            if (resetDate) {
                setLoadingSuccess(true);
            }
            const dataToPost = {
                accountNo: goalSave?.no,
                accountType: goalSave?.accountType,
            };
            if (!resetDate) {
                if (dataGoalSaveDetail?.createdAtIndex) {
                    dataToPost.createdAtIndex =
                        dataGoalSaveDetail.createdAtIndex;
                }
                if (dataGoalSaveDetail?.planIdIndex) {
                    dataToPost.planIdIndex = dataGoalSaveDetail.planIdIndex;
                }
            }

            const { data } = await accountService.getGoalSaveList(dataToPost);
            if (unmounted.current) return;
            setLoading(false);
            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    const listDiscountRes = data.data;
                    if (dataGoalSave?.length > 0 && !resetDate) {
                        setDataGoalSave((list) =>
                            list.concat(listDiscountRes.items)
                        );
                        setDataGoalSaveDetail(data.data);
                    } else {
                        setDataGoalSave(data.data.items);
                        setDataGoalSaveDetail(data.data);
                    }
                    setLoadingSuccess(false);
                    break;
                case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                    dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: data.code });
                    break;
            }
        },
        [goalSave, dataGoalSave, dataGoalSaveDetail, dialog]
    );

    const hasMore = useMemo(() => {
        if (dataGoalSaveDetail) {
            if (
                dataGoalSaveDetail.createdAtIndex &&
                dataGoalSaveDetail.planIdIndex
            )
                return true;
            return false;
        }
    }, [dataGoalSaveDetail]);

    const loadMore = useCallback(() => {
        if (hasMore) getData();
    }, [getData, hasMore]);

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: true,
        onLoadMore: loadMore,
        scrollContainer: "window",
        rootMargin: "0px 0px 100px 0px",
    });

    const resetState = useCallback(
        (isTransferSuccess) => {
            setTimeout(() => {
                if (isTransferSuccess) {
                    getAccountList();
                    getData(true);
                }
            }, 1000);

            GlobalDialogController.hide();
            setNameGoalFrom("");
            setNameGoalTo("");
        },
        [getAccountList, getData]
    );

    const reloadGoalListForUpdateGoal = () => {
        getAccountList();
        getData(true);
    };

    const makeTransfer = useCallback(() => {
        if (goalSaveFrom.accountType !== "spend") {
            const amount = parseFloat(goalSaveFrom.contributedAmount);
            if (amount <= 0) {
                GlobalDialogController.show({
                    component: () => (
                        <LspCongratulation
                            icon="close"
                            type="error"
                            title={t("gs_contribute_error_title")}
                            contents={t("gs_no_enough_money_to_transfer")}
                            button={{
                                label: t("lb_done"),
                                onClick: () => resetState(false),
                            }}
                        />
                    ),
                    onClose: () => {
                        resetState(false);
                    },
                });
                return;
            }
        }

        GlobalDialogController.show({
            component: () => (
                <GoalSaveTransfer
                    goalSaveFrom={goalSaveFrom}
                    goalSaveTo={goalSaveTo}
                    resetState={resetState}
                />
            ),
            onClose: () => {
                resetState(false);
            },
        });
    }, [goalSaveFrom, goalSaveTo, t, resetState]);

    const handleDrop = useCallback((name) => {
        setNameGoalTo(name);
    }, []);

    const handleDrag = useCallback((name) => {
        setNameGoalFrom(name);
    }, []);

    useEffect(() => {
        if (
            nameGoalTo !== "" &&
            nameGoalFrom !== "" &&
            nameGoalTo !== nameGoalFrom
        ) {
            makeTransfer();
        }
    }, [nameGoalTo, nameGoalFrom, makeTransfer]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openGoalSaveDetail = (detail) => {
        const onTrack = checkOnTrack(detail);
        GlobalDialogController.show({
            component: () => (
                <GoalSaveDetail
                    info={detail}
                    onClose={() => GlobalDialogController.hide()}
                    resetState={resetState}
                    spendAccountObj={spendAccountObj}
                    piggyAccountObj={piggyAccountObj}
                    refreshGoalList={reloadGoalListForUpdateGoal}
                    isOnTrack={onTrack}
                />
            ),
        });
    };

    useEffect(() => {
        if (addNewSuccess) {
            setTimeout(() => {
                setAddNewSuccess(false);
            }, 3000);
        }
    }, [addNewSuccess]);

    const showDialogForeigner = () => {
        dialog(REGULATION_TYPE.GS_PAGE);
    };

    const openGoalSaveCreate = () => {
        if (userInfo.isForeigner) {
            showDialogForeigner();
            return;
        }
        GlobalDialogController.show({
            component: () => (
                <GoalSaveCreate
                    getListGoalSave={getData}
                    setAddNewSuccess={setAddNewSuccess}
                />
            ),
        });
    };

    const showPiggyPopup = (item) => {
        GlobalDialogController.show({
            component: () => (
                <PiggyPopup amount={formatNumber(item?.contributedAmount)} />
            ),
        });
    };

    return (
        <>
            <Content size="lg">
                {userInfo.isForeigner && (
                    <GoalSaveForeigner showPopupInfo={showDialogForeigner} />
                )}
                <Box textAlign="right" ml={3} mr={3} mb={2} mt={2}>
                    <LspButton
                        onClick={() => openGoalSaveCreate()}
                        buttonProps={{
                            datatestid: "createGoalBtn",
                        }}
                    >
                        {t("gs_gallery_btn_create_goal")}
                    </LspButton>
                </Box>
                <div className={classes.container} ref={infiniteRef}>
                    <div
                        className={clsx(classes.leftPage, {
                            fixed: scroll,
                        })}
                    >
                        <DragItem
                            name={0}
                            onDrop={(name) => handleDrop(name)}
                            onDrag={(name) => handleDrag(name)}
                        >
                            <div
                                className={classes.spendAccount}
                                onClick={() =>
                                    history.push("/spend-account/transactions")
                                }
                                datatestid="goalSpendAccountItem"
                            >
                                <Icon className="font-icon icon-spendaccounticon" />
                                <LspTypography variant="heading2" color="white">
                                    {formatNumber(currentBalance)}
                                </LspTypography>
                                <LspTypography variant="body2" color="white">
                                    {spendAccount?.name}
                                </LspTypography>
                            </div>
                        </DragItem>

                        {dataGoalSaveDetail?.specials.map((item, key) => (
                            <DragItem
                                name={item.planId}
                                onDrop={(name) => handleDrop(name)}
                                onDrag={(name) => handleDrag(name)}
                                key={key}
                            >
                                <div
                                    className={classes.spendAccount}
                                    key={key}
                                    onClick={() => showPiggyPopup(item)}
                                    datatestid="goalPiggyBankItem"
                                >
                                    <Icon className="font-icon icon-goalsave" />
                                    <LspTypography
                                        variant="heading2"
                                        color="white"
                                    >
                                        {formatNumber(item?.contributedAmount)}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        className={classes.piggy}
                                    >
                                        {t("goal_save_account_piggy_title")}
                                    </LspTypography>
                                </div>
                            </DragItem>
                        ))}
                    </div>
                    <div
                        className={clsx(classes.listGoalWrapper, {
                            fixed: scroll,
                        })}
                    >
                        {!loadingSuccess && (
                            <Box className={classes.listGoal}>
                                {goalSaveList.map((item, key) => (
                                    <DragItem
                                        name={item.planId}
                                        onDrop={(name) => handleDrop(name)}
                                        onDrag={(name) => handleDrag(name)}
                                        key={key}
                                    >
                                        <GoalSaveItem
                                            goalSaveItem={item}
                                            addNewSuccess={addNewSuccess}
                                            index={key}
                                            onItemClick={openGoalSaveDetail}
                                        />
                                    </DragItem>
                                ))}
                            </Box>
                        )}

                        {loading && <GoalSaveItemLoading className={classes} />}
                    </div>
                </div>
            </Content>
        </>
    );
};

const stateProps = (state) => ({
    userInfo: state.user.info,
    systemParams: state.systemParams.info.conf,
});

const mapDispatch = (dispatch) => ({
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
});

export default connect(stateProps, mapDispatch)(GoalSaveGallery);
