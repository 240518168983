import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import PropTypes from "prop-types";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    bankQrAmount: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        backgroundColor: theme.palette.magicalGrey[100],
        borderRadius: theme.shape.radiusLevels[1],
    },
    bankQrAmountCurrency: {
        textAlign: "center",
        marginBottom: theme.spacing(0.5),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    bankQrAmountDescription: {
        color: theme.palette.magicalGrey[400],
    },
}));
const BankQRCodeAmount = ({ bankInfo }) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();

    return (
        <>
            <div className={classes.bankQrAmount}>
                <LspTypography
                    className={classes.bankQrAmountCurrency}
                    variant="title1"
                >
                    VND {formatNumber(bankInfo?.amount)}
                </LspTypography>
                {bankInfo?.description && (
                    <LspTypography
                        className={classes.bankQrAmountDescription}
                        variant="body1"
                    >
                        Description: {bankInfo?.description}
                    </LspTypography>
                )}
            </div>
        </>
    );
};

BankQRCodeAmount.propTypes = {
    bankInfo: PropTypes.shape({
        accNumber: PropTypes.string,
        amount: PropTypes.number,
        bankName: PropTypes.string,
        bankShortName: PropTypes.string,
        description: PropTypes.string,
        reminded: PropTypes.bool,
        sender: PropTypes.bool,
        senderId: PropTypes.number,
        senderName: PropTypes.string,
        status: PropTypes.string,
        timoUser: PropTypes.bool,
    }),
};

BankQRCodeAmount.defaultProps = {
    bankInfo: {},
};
export default memo(BankQRCodeAmount);
