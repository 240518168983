import {
    METRIC_NAME,
    METRIC_SCREEN,
    SPLIT_BILL_STATUS,
} from "@config/constants";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import billActions from "@redux/actions/bill";
import metricActions from "@redux/actions/metric";
import { isEmpty } from "lodash";
import { useEffect, memo, useMemo } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Bill from "./bill";
import NotFoundBill from "./NotFoundBill";
import SplitBillFooter from "./SplitBillFooter";
import SplitBillNav from "./SplitBillNav";
import SubSplitBillHeader from "./SubSplitBillHeader";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100vw",
        backgroundColor: theme.palette.background.default,
        margin: "auto",
        overflow: "auto",
    },
    wrapperDesktop: {
        width: "100vw",
        backgroundColor: theme.palette.background.default,
        maxWidth: 432,
        margin: "auto",
    },
    splitBill: {
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
        flexDirection: "column",
        border: `solid 1px ${theme.palette.magicalGrey[200]}`,
    },
    splitBillBody: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    splitBillContent: {
        flex: 1,
        display: "inline-flex",
        flexDirection: "column",
    },
    splitBillContentBody: {
        display: "inline-flex",
        flexDirection: "column",
        margin: `-${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
            2
        )}px ${theme.spacing(2)}px`,
    },
    splitBillFooter: {
        padding: theme.spacing(2),
    },
    termsAndConditions: {
        fontWeight: 400,
        textAlign: "center",
        marginBottom: theme.spacing(5),
        color: theme.palette.magicalGrey[400],
        textDecoration: "none",
    },
    boldText: {
        color: theme.palette.success.main,
        fontWeight: 600,
        textDecoration: "underline",
    },
}));

const SplitBill = ({
    splitBill,
    getSplitBill,
    fetching,
    metricRequest,
    metricSession,
    metricConfig,
}) => {
    const { verifyCode } = useParams();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const isBillActive = () =>
        !isEmpty(splitBill) &&
        (splitBill?.status === SPLIT_BILL_STATUS.COMPLETED ||
            splitBill?.status === SPLIT_BILL_STATUS.PENDING);

    useEffect(() => {
        if (isEmpty(splitBill)) {
            getSplitBill({ id: verifyCode });
            const time = new Date().getTime();
            const appSessionId = uuidv4();
            const screenSessionId = uuidv4();
            metricSession({ appSessionId, screenSessionId });

            const metricData = {
                data: {
                    detail_data: {
                        app_session_id: appSessionId || "",
                        screen_session_id: screenSessionId,
                        event_id: uuidv4() || "",
                        previous_event_id: "",
                    },
                    error_code: "",
                    error_message: "",
                    flow_name: "",
                    metric_location: "WEB",
                    previous_event_name: "",
                    previous_screen_name: "",
                    screen_name: METRIC_SCREEN.SPLIT_BILL,
                    metric_name: METRIC_NAME.START,
                    time_stamp_number: time || "",
                },
                force: true,
            };
            metricRequest(metricData);
        }
    }, [splitBill, getSplitBill, verifyCode, metricRequest, metricSession]);

    const fetchingLayer = useMemo(
        () => (
            <>
                <div className={classes.splitBillContent}>
                    <SubSplitBillHeader />
                    <div className={classes.splitBillContentBody}>
                        <Bill />
                    </div>
                </div>
            </>
        ),
        [classes]
    );
    return (
        <div
            className={`${classes.wrapper} ${
                !isMobile ? classes.wrapperDesktop : ""
            }`}
        >
            <div className={classes.splitBill}>
                <div className={classes.splitBillBody}>
                    <SplitBillNav />
                    {fetching && fetchingLayer}
                    {isBillActive() && (
                        <div className={classes.splitBillContent}>
                            <SubSplitBillHeader />
                            <div className={classes.splitBillContentBody}>
                                <Bill />
                            </div>
                        </div>
                    )}
                    {!isBillActive() && !fetching && <NotFoundBill />}
                </div>
                <div className={classes.splitBillFooter}>
                    <SplitBillFooter />
                </div>
            </div>
        </div>
    );
};

const stateProps = (state) => ({
    splitBill: state.bill.splitBill.bill,
    fetching: state.bill.splitBill.fetching,
    metricConfig: state.metrics?.metricConfig,
});

const dispatchProps = (dispatch) => ({
    getSplitBill: (payload) => dispatch(billActions.getSplitBill(payload)),
    metricRequest: (payload) =>
        dispatch(metricActions.metricRequestUpdate(payload)),
    metricSession: (payload) => dispatch(metricActions.metricSession(payload)),
});

export default connect(stateProps, dispatchProps)(memo(SplitBill));
