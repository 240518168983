import api from "../config/api";

const getInvitationDashboard = async () => {
    return await api.get("user/invitation/dashboard");
};

const getInvitationPendingList = async () => {
    return await api.get("user/invitation/pending-reward");
};

const toggleNoteOption = async (payload) => {
    return await api.post("user/invitation/hint/update", payload);
};

const updateReferralCode = async (payload) => {
    return await api.post("user/updateReferralCode", payload);
};

const getAutoSaveStatus = async () => {
    return await api.get("user/IsAutoAddPayee");
};

const updateAutoSaveStatus = async (payload) => {
    return await api.post("user/UpdateAutoAddPayee", payload);
};

const getJoinedList = async () => {
    return await api.get("user/getFriendJoinedList");
};

const autoAddPayee = async (payload) => {
    return await api.post("user/autoAddPayee", payload);
};

const invitationService = {
    getInvitationDashboard,
    getInvitationPendingList,
    toggleNoteOption,
    updateReferralCode,
    getAutoSaveStatus,
    updateAutoSaveStatus,
    getJoinedList,
    autoAddPayee,
};

export default invitationService;
