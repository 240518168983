import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import PageHeader from "@components/PageHeader";
import { Box, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const PhoneNumber = ({ next, onChange, phoneNumber, error, loading }) => {
    const { t } = useTranslation("translation", "payAnyone");

    return (
        <div>
            <PageHeader>
                <div>{t("payAnyone:enter_phone_number_header")} </div>
            </PageHeader>
            <Content size="sm">
                <Box component={Paper} p={3}>
                    <form onSubmit={next}>
                        <NumberFormat
                            value={phoneNumber}
                            onValueChange={({ value }) => onChange(value)}
                            label={t("payAnyone:enter_phone_number_header")}
                            error={!!error.errorMsg}
                            helperText={error.errorMsg || " "}
                            customInput={LspTextField}
                            format="### ### ####"
                            mask="_"
                        />
                        <LspButton
                            type="submit"
                            fullWidth
                            progressing={loading}
                        >
                            {t("lb_next")}
                        </LspButton>
                    </form>
                </Box>
            </Content>
        </div>
    );
};

export default PhoneNumber;
