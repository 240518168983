import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { UserActionType } from "../actions/user";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    info: null,
    bankInfo: {
        fetching: false,
        status: null,
        data: null,
    },
    serverTime: {
        fetching: false,
        status: null,
        value: null,
    },
    payee: {
        fetching: false,
        status: null,
        list: null,
        count: 0,
    },
    setting: {
        fetching: false,
        status: null,
        data: null,
    },
    featureState: {
        fetching: false,
        status: null,
        data: null,
    },

    reissue: {
        fetching: false,
        status: null,
        data: null,
    },

    modifyAlias: {
        fetching: false,
        status: null,
    },
    alias: {
        fetching: false,
        status: null,
        data: [],
    },
    toggleAlias: {
        fetching: false,
        status: null,
    },
    aliasFree: {
        fetching: false,
        status: null,
        data: {},
    },
    aliasCheck: {
        fetching: false,
        status: null,
        data: {},
    },
    submitAlias: {
        fetching: false,
        status: null,
        data: {},
    },
    aliasNickname: {
        fetching: false,
        status: null,
        data: {},
        code: null,
    },
};

const cachedListLength = 10;

/* ------------- Reducer ------------- */
const userReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case UserActionType.Reset:
                draft.fetching = false;
                draft.status = null;
                draft.info = null;
                draft.serverTime = {
                    fetching: false,
                    status: null,
                    value: null,
                };
                draft.payee = {
                    fetching: false,
                    status: null,
                    list: null,
                    count: 0,
                };
                break;

            /* ------------- Get Profile ------------- */
            case UserActionType.GetProfileRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case UserActionType.GetProfileSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.info = action.payload;

                // const fakeData = cloneDeep(action.payload);
                // draft.info = {
                //     ...fakeData,
                //     proofPeriod: 360,
                //     isForeigner: true,
                // };
                break;
            case UserActionType.GetProfileError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            /* ------------- Get server time ------------- */
            case UserActionType.GetServerTimeRequest:
                draft.serverTime.fetching = true;
                draft.serverTime.status = FetchStatus.Pending;
                break;
            case UserActionType.GetServerTimeSuccess:
                draft.serverTime.fetching = false;
                draft.serverTime.status = FetchStatus.Success;
                draft.serverTime.value = action.payload;
                break;
            case UserActionType.GetServerTimeError:
                draft.serverTime.fetching = false;
                draft.serverTime.status = FetchStatus.Error;
                draft.serverTime.value = new Date();
                break;
            case UserActionType.ResetServerTime:
                draft.serverTime.fetching = false;
                draft.serverTime.status = null;
                // draft.serverTime.value = new Date();
                break;
            /* ------------- Get payee list ------------- */
            case UserActionType.GetPayeeListRequest:
                draft.payee.fetching = true;
                draft.payee.status = FetchStatus.Pending;
                break;
            case UserActionType.GetPayeeListSuccess:
                draft.payee.fetching = false;
                draft.payee.status = FetchStatus.Success;
                draft.payee.list = action.payload.results;
                draft.payee.count = action.payload.rows;
                break;
            case UserActionType.GetPayeeListError:
                draft.payee.fetching = false;
                draft.payee.status = FetchStatus.Error;
                break;
            case UserActionType.ResetPayeeList:
                draft.payee.fetching = false;
                draft.payee.status = null;
                draft.payee.list = null;
                draft.payee.count = 0;
                break;
            case UserActionType.MinimizePayeeList:
                draft.payee.fetching = false;
                draft.payee.status = null;
                if (Array.isArray(draft.payee.list)) {
                    draft.payee.list = draft.payee.list.slice(
                        0,
                        cachedListLength
                    );
                } else {
                    draft.payee.list = null;
                }
                break;
            /* ------------- Get bank info ------------- */
            case UserActionType.GetBankInfoRequest:
                draft.bankInfo.fetching = true;
                draft.bankInfo.status = FetchStatus.Pending;
                break;
            case UserActionType.GetBankInfoSuccess:
                draft.bankInfo.fetching = false;
                draft.bankInfo.status = FetchStatus.Success;
                draft.bankInfo.data = action.payload;
                break;
            case UserActionType.GetBankInfoError:
                draft.bankInfo.fetching = false;
                draft.bankInfo.status = FetchStatus.Error;
                break;
            /* ------------- Update setting ------------- */
            case UserActionType.UpdateSettingRequest:
                draft.setting.fetching = true;
                draft.setting.status = FetchStatus.Pending;
                break;
            case UserActionType.UpdateSettingSuccess:
                draft.setting.fetching = false;
                draft.setting.status = FetchStatus.Success;
                break;
            case UserActionType.UpdateSettingError:
                draft.setting.fetching = false;
                draft.setting.status = FetchStatus.Error;
                break;
            case UserActionType.SetPhoneNumberOTP:
                draft.info = {
                    phoneNumber: action.payload,
                };
                break;
            case UserActionType.GetFeatureStateRequest:
                draft.featureState.fetching = true;
                draft.featureState.status = FetchStatus.Pending;
                break;
            case UserActionType.GetFeatureStateSuccess:
                draft.featureState.fetching = false;
                draft.featureState.status = FetchStatus.Success;
                draft.featureState.data = action.payload;
                break;
            case UserActionType.GetFeatureStateError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case UserActionType.ReIssuesCheckRequest:
                draft.reissue.fetching = true;
                draft.reissue.status = FetchStatus.Pending;
                break;
            case UserActionType.ReIssuesCheckSuccess:
                draft.reissue.fetching = false;
                draft.reissue.status = FetchStatus.Success;
                draft.reissue.data = action.payload;
                break;
            case UserActionType.ReIssuesCheckError:
                draft.reissue.fetching = false;
                draft.reissue.status = FetchStatus.Error;
                break;

            case UserActionType.ModifyAliasRequest:
                draft.modifyAlias.fetching = true;
                draft.modifyAlias.status = FetchStatus.Pending;
                break;
            case UserActionType.ModifyAliasSuccess:
                draft.modifyAlias.fetching = false;
                draft.modifyAlias.status = FetchStatus.Success;
                break;
            case UserActionType.ModifyAliasError:
                draft.modifyAlias.fetching = false;
                draft.modifyAlias.status = FetchStatus.Error;
                break;

            case UserActionType.GetAliasRequest:
                draft.alias.fetching = true;
                draft.alias.status = FetchStatus.Pending;
                break;
            case UserActionType.GetAliasSuccess:
                draft.alias.fetching = false;
                draft.alias.status = FetchStatus.Success;
                draft.alias.data = action.payload;
                break;
            case UserActionType.GetAliasError:
                draft.alias.fetching = false;
                draft.alias.status = FetchStatus.Error;
                break;

            case UserActionType.ToggleAliasRequest:
                draft.toggleAlias.fetching = true;
                draft.toggleAlias.status = FetchStatus.Pending;
                break;
            case UserActionType.ToggleAliasSuccess:
                draft.toggleAlias.fetching = false;
                draft.toggleAlias.status = FetchStatus.Success;
                break;
            case UserActionType.ToggleAliasError:
                draft.toggleAlias.fetching = false;
                draft.toggleAlias.status = FetchStatus.Error;
                break;

            case UserActionType.GetNicknameFeeRequest:
                draft.aliasFree.fetching = true;
                draft.aliasFree.status = FetchStatus.Pending;
                break;
            case UserActionType.GetNicknameFeeSuccess:
                draft.aliasFree.fetching = false;
                draft.aliasFree.status = FetchStatus.Success;
                draft.aliasFree.data = action.payload;
                break;
            case UserActionType.GetNicknameFeeError:
                draft.aliasFree.fetching = false;
                draft.aliasFree.status = FetchStatus.Error;
                break;

            case UserActionType.CheckNicknameRequest:
                draft.aliasCheck.fetching = true;
                draft.aliasCheck.status = FetchStatus.Pending;
                break;
            case UserActionType.CheckNicknameSuccess:
                draft.aliasCheck.fetching = false;
                draft.aliasCheck.status = FetchStatus.Success;
                draft.aliasCheck.data = action.payload;
                break;
            case UserActionType.CheckNicknameError:
                draft.aliasCheck.fetching = false;
                draft.aliasCheck.status = FetchStatus.Error;
                break;

            case UserActionType.SubmitNicknameRequest:
                draft.submitAlias.fetching = true;
                draft.submitAlias.status = FetchStatus.Pending;
                break;
            case UserActionType.SubmitNicknameSuccess:
                draft.submitAlias.fetching = false;
                draft.submitAlias.status = FetchStatus.Success;
                draft.submitAlias.data = action.payload;
                break;
            case UserActionType.SubmitNicknameError:
                draft.submitAlias.fetching = false;
                draft.submitAlias.status = FetchStatus.Error;
                break;

            case UserActionType.SubmitOTPNicknameRequest:
                draft.aliasNickname.fetching = true;
                draft.aliasNickname.status = FetchStatus.Pending;
                break;
            case UserActionType.SubmitOTPNicknameSuccess:
                draft.aliasNickname.fetching = false;
                draft.aliasNickname.status = FetchStatus.Success;
                draft.aliasNickname.data = action.payload;
                break;
            case UserActionType.SubmitOTPNicknameError:
                draft.aliasNickname.fetching = false;
                draft.aliasNickname.status = FetchStatus.Error;
                draft.aliasNickname.code = action.payload.errorCode;
                break;

            case UserActionType.SubmitOTPNicknameReset:
                draft.aliasNickname.fetching = false;
                draft.aliasNickname.status = FetchStatus.Error;
                draft.aliasNickname.code = null;
                break;
            default:
                break;
        }
    });

export default userReducer;
