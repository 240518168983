/* ------------- Types Definition ------------- */
export const NotificationActionType = Object.freeze({
    CheckNotificationRequest: "NotificationActionType/CheckNotificationRequest",
    CheckNotificationSuccess: "NotificationActionType/CheckNotificationSuccess",
    CheckNotificationError: "NotificationActionType/CheckNotificationError",
    GetNotificationListRequest:
        "NotificationActionType/GetNotificationListRequest",
    GetNotificationListSuccess:
        "NotificationActionType/GetNotificationListSuccess",
    GetNotificationListError: "NotificationActionType/GetNotificationListError",
});

/* ------------- Actions Definition ------------- */

const checkNotificationRequest = () => {
    return {
        type: NotificationActionType.CheckNotificationRequest,
    };
};
const checkNotificationSuccess = (payload) => {
    return {
        type: NotificationActionType.CheckNotificationSuccess,
        payload,
    };
};
const checkNotificationError = () => {
    return {
        type: NotificationActionType.CheckNotificationError,
    };
};

const getNotificationListRequest = (payload = {}) => {
    return {
        type: NotificationActionType.GetNotificationListRequest,
        payload,
    };
};
const getNotificationListSuccess = (payload) => {
    return {
        type: NotificationActionType.GetNotificationListSuccess,
        payload,
    };
};
const getNotificationListError = () => {
    return {
        type: NotificationActionType.GetNotificationListError,
    };
};

const notificationAction = {
    checkNotificationRequest,
    checkNotificationSuccess,
    checkNotificationError,
    getNotificationListRequest,
    getNotificationListSuccess,
    getNotificationListError,
};

export default notificationAction;
