import LspTypography from "@components/LspTypography";
import LspAvatar from "@components/LspAvatar";

const InvitationPendingItem = ({ detail, classes }) => {
    return (
        <div className={classes.pendingItem}>
            <LspAvatar avatar={detail.avatar} size={40} />
            <div className={classes.info}>
                <LspTypography variant="heading3" color="black">
                    {detail.fullName}
                </LspTypography>
                <LspTypography variant="body3" color="grey">
                    {detail.phoneNumber}
                </LspTypography>
            </div>
        </div>
    );
};

export default InvitationPendingItem;
