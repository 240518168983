import LspButton from "@components/LspButton";
import { AttachmentIcon, FolderLinkIcon } from "@components/LspIcon";
import { Box, makeStyles } from "@material-ui/core";
import { fromPairs, isEmpty, isFunction } from "lodash";
import { useMemo, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import lspReceiptService from "@services/lsp-receipt";
import { Skeleton } from "@material-ui/lab";
import { ATTACHMENT_MODE } from "@config/constants";
import { base } from "@config/theme/colors";
import { v4 as uuidv4 } from "uuid";
import LspAttachmentItem from "./LspAttachmentItem";

const useStyles = makeStyles((theme) => {
    return {
        attachments: {
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.background.paper,
            textAlign: "left",
            boxShadow: theme.shadows[2],
            cursor: "pointer",
        },
        attachmentsHeader: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing(1.5),
        },
        attachmentsHeaderIcon: {
            color: theme.palette.magicalGrey[400],
            marginRight: theme.spacing(3),
        },
        attachmentBox: {
            display: "flex",
            flexDirection: "row",
            cursor: "default",
        },
        attachmentAction: {
            background: base.lightPurple1,
            width: 60,
            height: 60,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.purple.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        },
    };
});

const LspAttachment = ({
    attachments,
    onViewAttachment,
    onAddAttachment,
    uploadAttachments,
    onUploadAttachmentSuccess,
    refNo,
    attachmentType,
    fileManageId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const isAttachment = useMemo(
        () =>
            (attachments && attachments.length > 0) ||
            (uploadAttachments && uploadAttachments.length > 0),
        [attachments, uploadAttachments]
    );

    const isFullAttachment = useMemo(
        () => attachments && attachments.length < 3,
        [attachments]
    );

    const handleClickAddAttachment = () => {
        if (isFunction(onAddAttachment)) {
            onAddAttachment();
        }
    };

    const handleViewAttachment = (data) => {
        if (isFunction(onViewAttachment)) {
            onViewAttachment(data);
        }
    };

    useEffect(() => {
        if (!isEmpty(uploadAttachments) && !loading) {
            setLoading(true);
            Promise.all(
                uploadAttachments.map(
                    (data) =>
                        new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append("txnAttachment", data.file);
                            formData.append("refNo", refNo);

                            resolve(formData);
                        })
                )
            )
                .catch((error) => {
                    console.log("Errors create formData: ", error);
                })
                .finally(() => {
                    console.log("Final create formData");
                })
                .then((multiparts) => {
                    const params = (multiparts || []).map((multipart) => {
                        if (attachmentType === ATTACHMENT_MODE.CREATE) {
                            return lspReceiptService.submitAttachment(
                                multipart
                            );
                        }
                        return lspReceiptService.modifyAttachment({
                            fileManageId,
                            file: multipart,
                        });
                    });
                    setLoading(true);
                    Promise.all(params)
                        .then(() => {
                            if (isFunction(onUploadAttachmentSuccess)) {
                                onUploadAttachmentSuccess();
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                });
        }
    }, [
        attachmentType,
        fileManageId,
        loading,
        onUploadAttachmentSuccess,
        refNo,
        uploadAttachments,
    ]);

    return (
        <>
            {!isAttachment && (
                <Box px={3} pb={1.5}>
                    <LspButton
                        type="button"
                        onClick={handleClickAddAttachment}
                        fullWidth
                        variant="secondary"
                        buttonProps={{
                            startIcon: <FolderLinkIcon />,
                        }}
                    >
                        {t("txnapptype:receipt_btn_attach_file")}
                    </LspButton>
                </Box>
            )}
            {isAttachment && (
                <Box px={3} pb={1.5}>
                    <div className={classes.attachments}>
                        <LspTypography
                            variant="subheading3"
                            component="div"
                            className={classes.attachmentsHeader}
                        >
                            <FolderLinkIcon
                                className={classes.attachmentsHeaderIcon}
                            />

                            {t("txnapptype:receipt_btn_attach_file")}
                        </LspTypography>
                        <div className={classes.attachmentBox}>
                            {!loading &&
                                (attachments || []).map((attachment) => {
                                    return (
                                        <LspAttachmentItem
                                            key={attachment?.fileManageId}
                                            item={attachment}
                                            onClick={(data) =>
                                                handleViewAttachment(data)
                                            }
                                            loading={loading}
                                        />
                                    );
                                })}
                            {loading && (
                                <>
                                    <LspAttachmentItem loading />
                                    <LspAttachmentItem loading />
                                    <LspAttachmentItem loading />
                                </>
                            )}

                            {isFullAttachment && !loading && (
                                <div
                                    className={classes.attachmentAction}
                                    onClick={() => handleClickAddAttachment()}
                                >
                                    <AttachmentIcon />
                                </div>
                            )}
                        </div>
                    </div>
                </Box>
            )}
        </>
    );
};

export default LspAttachment;
