import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import {
    RESPONSE_CODE,
    TermDepositStatus,
    TermDepositRollType,
} from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import {
    Box,
    Collapse,
    Divider,
    Icon,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import accountAction from "@redux/actions/account";
import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import termDepositService from "@services/term-deposit";
import clsx from "clsx";
import Interweave from "interweave";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import { useHistory, useParams } from "react-router-dom";
import termDepositAction from "@redux/actions/term-deposit";
import tommyNoHope from "@assets/images/no_hope_with_status_error.png";
import { isFunction } from "lodash";
import { EditIcon, RolloverIcon } from "@components/LspIcon";
import tomiFindding from "@assets/images/tommy_finding.png";
import EarlyRedemptionDialog from "./EarlyRedemptionDialog";
// import useTermDeposit from "./OpenTermDeposit/useTermDeposit";
import RolloverHistoryDialog from "./RolloverHistoryDialog";
import TermDepositProgressBar from "./TermDepositProgressBar";
import RolloverMethodDialog from "./OpenTermDeposit/RolloverMethodDialog";
import ConfirmRolloverDialog from "./OpenTermDeposit/ConfirmRolloverDialog";
import IntroPartialWithdraw from "./PartialWithdraw/IntroPartialWithdraw";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        tdItemRoot: {
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[2],
            marginBottom: theme.spacing(2),
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            "&.inactive": {
                backgroundColor: isDark
                    ? theme.palette.background.paper
                    : theme.palette.grey[200],
            },
        },
        clickableArea: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: theme.spacing(2),
        },
        tdIcon: {
            fontSize: `${theme.typography.pxToRem(24)} !important`,
            color: theme.palette.primary.main,
            marginRight: theme.spacing(0.5),
        },
        tdName: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        tdShortDesc: {
            color: theme.palette.text.secondary,
        },
        tdAmount: {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 600,
            color: theme.palette.success.main,
            textAlign: "right",
            "&.inactive": {
                color: theme.palette.text.disabled,
            },
        },
        detailTableRow: {
            height: 80,
            display: "flex",
            flexDirection: "row",
            borderBottom: `solid 1px ${theme.palette.divider}`,
            "&.inactive": {
                borderBottom: `solid 1px ${theme.palette.divider}`,
            },
        },
        detailTableRowCell: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "33.3%",
            textAlign: "center",
            color: theme.palette.text.primary,
            borderRight: `solid 1px ${theme.palette.divider}`,
            "&.inactive": {
                borderRight: `solid 1px ${theme.palette.divider}`,
            },
            "&.disableBorderRight": {
                borderRight: "none",
            },
        },
        cellLabel: {
            fontSize: theme.typography.pxToRem(12),
        },
        cellValue: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
        },
        rolloverContainer: {
            borderBottom: `solid 1px ${theme.palette.divider}`,
            display: "flex",
            flexDirection: "row",
            height: 60,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        rolloverLabel: {
            fontWeight: 600,
            paddingRight: theme.spacing(1),
        },
        rolloverInfoIcon: {
            color: theme.palette.grey[500],
            "&:hover": {
                opacity: theme.palette.opacity.hover,
            },
        },
        rolloverInfoPopper: {
            maxWidth: 500,
            fontSize: theme.typography.pxToRem(12),
            padding: theme.spacing(1.5, 2),
        },
        rolloverHistoryLink: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.success.main,
            fontWeight: 600,
            textDecoration: "underline",
        },
        lockedTermDepositNote: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.orange.main,
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            [theme.breakpoints.down("sm")]: {
                alignItems: "center",
            },
        },
        tdAction: {
            alignItems: "flex-start",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center",
            },
        },
    };
});

const TermDepositItem = ({
    termName,
    termDepositAcc,
    status,
    autoRoll,
    maturityDate,
    termDepositBalance,
    totalDay,
    openDate,
    term,
    interestRate,
    totalInterest,
    termId,
    reloadList, // reload the parent component list
    getAccountList,
    numberOfDayLeft,
    validationFetching,
    total,
    currentIndex,
    scrollBottom,
    enableRollingOnlyPrinciple,
    rollType,
    allowPartialWithDraw,
    blockType,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();
    const [expanded, setExpended] = useState(false);
    const [
        isRolloverHistoryDialogOpened,
        setIsRolloverHistoryDialogOpened,
    ] = useState(null);
    const [
        isEarlyRedemptionDialogOpened,
        setIsEarlyRedemptionDialogOpened,
    ] = useState(null);
    const [loadingAutoRollover, setLoadingAutoRollover] = useState(false);
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    // const { checkForeignerBeforeOpenTD } = useTermDeposit();
    const history = useHistory();
    const [activeId, setActiveId] = useState("");
    const [validationLoading, setValidationLoading] = useState(false);
    const tdRef = useRef();

    const { tdId } = useParams();

    const unmounted = useRef(false);

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const isInactive = useMemo(() => status === TermDepositStatus.Redeemed, [
        status,
    ]);

    const iconName = useMemo(() => {
        if (status === TermDepositStatus.Locked) {
            return "locked";
        }

        if (autoRoll && rollType === TermDepositRollType.TOTAL_ROLLOVER)
            return "rollover";

        return "fixedTD";
    }, [status, rollType, autoRoll]);

    const lockByOD = useMemo(() => blockType === "OD", [blockType]);

    const shortDesc = useMemo(() => {
        if (status === TermDepositStatus.Locked) {
            return lockByOD
                ? t("overdraft:overdraft_td_secured_note")
                : t("td_lb_locked_item_note");
        }
        let content = "";
        if (autoRoll) {
            content += `${t("td_redeem_rollover_date")}: `;
        } else {
            content += `${t("termDeposit_lb_redeem_date")}: `;
        }
        content += `<b>${maturityDate}</b>`;
        return <Interweave content={content} tagName="span" />;
    }, [status, autoRoll, maturityDate, lockByOD, t]);

    const formattedDaysLeft = useMemo(() => {
        if (numberOfDayLeft === 0) {
            return t("master:tdlist_countdown_date");
        }
        return t("td_lb_gl_days_left").replace("%@", numberOfDayLeft);
    }, [t, numberOfDayLeft]);

    const formattedTerm = useMemo(() => {
        if (term === 0) {
            return t("td_lb_interest_rate_na");
        }
        if (term === 1) {
            return `${term} ${t("termDeposit_lb_month").toLowerCase()}`;
        }
        return `${term} ${t("termDeposit_lb_months").toLowerCase()}`;
    }, [t, term]);

    const isRolloverAvailable = useMemo(
        () =>
            status !== TermDepositStatus.Redeemed &&
            status !== TermDepositStatus.Locked,
        [status]
    );

    const onRolloverClick = useCallback(
        async (forcePush = false, rolloverType = "") => {
            if (loadingAutoRollover && !forcePush) {
                return;
            }

            let payload = {};

            if (
                rolloverType === "" ||
                rolloverType === TermDepositRollType.NO_ROLLOVER
            ) {
                payload = {
                    convertDetails: [
                        {
                            termDepositId: termId,
                            autoRoll: !autoRoll,
                        },
                    ],
                };
            } else {
                payload = {
                    convertDetails: [
                        {
                            termDepositId: termId,
                            autoRoll: true,
                            rollType: rolloverType,
                        },
                    ],
                };
            }

            setLoadingAutoRollover(true);
            const response = await termDepositService.setAutoRolloverTermDeposit(
                payload
            );
            if (unmounted.current) {
                return;
            }
            setLoadingAutoRollover(false);

            if (response.ok) {
                const { code, data } = response.data;
                const success =
                    code === RESPONSE_CODE.SUCCESS &&
                    data.successTermDeposit > 0;
                const endOfDay = code === RESPONSE_CODE.END_OF_DAY_JOB_RUNNING;
                reloadList();

                if (success) {
                    const msg = autoRoll
                        ? t("td_set_nonrollover_successful")
                        : t("td_set_rollover_successful");
                    AlertController.show(msg);
                    return;
                }

                if (endOfDay) {
                    GlobalDialogController.showError({
                        errorCode: code,
                        type: GlobalDialogTypes.FinancialError,
                    });
                    return;
                }

                if (!success) {
                    AlertController.show(
                        t("td_set_rollover_unsuccessful"),
                        AlertType.Error
                    );
                }
            }
        },
        [autoRoll, loadingAutoRollover, reloadList, t, termId]
    );

    // const onConfirmRolloverClick = useCallback(() => {
    //     const content = (autoRoll
    //         ? t("td_details_popup_rollover")
    //         : t("td_details_popup_nonrollover")
    //     ).replace("%@", maturityDate);
    //     GlobalDialogController.showCustomDialog({
    //         dialogInfo: {
    //             header: "td_details_popup_confirm_title",
    //             translatedContent: content,
    //             doubleButton: [
    //                 {
    //                     label: t("loan_vpbank_button_confirm"),
    //                     onClick: () => {
    //                         GlobalDialogController.hide();
    //                         onRolloverClick();
    //                     },
    //                 },
    //                 {
    //                     label: t("lb_cancel"),
    //                     onClick: () => {
    //                         GlobalDialogController.hide();
    //                     },
    //                 },
    //             ],
    //         },
    //     });
    // }, [autoRoll, maturityDate, t, onRolloverClick]);

    // const checkForeignerBeforeSubmit = useCallback(() => {
    //     if (!autoRoll) {
    //         checkForeignerBeforeOpenTD({
    //             onConfirm: onRolloverClick,
    //         });
    //         return;
    //     }
    //     onConfirmRolloverClick();
    // }, [
    //     onConfirmRolloverClick,
    //     checkForeignerBeforeOpenTD,
    //     autoRoll,
    //     onRolloverClick,
    // ]);

    const openRolloverHistory = useCallback(() => {
        setIsRolloverHistoryDialogOpened(termId);
    }, [termId]);

    const closeRolloverHistory = useCallback(() => {
        setIsRolloverHistoryDialogOpened(null);
    }, []);

    const openEarlyRedemption = useCallback(
        (id) => {
            setIsEarlyRedemptionDialogOpened(id || termId);
        },
        [termId]
    );

    const closeEarlyRedemption = useCallback(
        (option) => {
            setIsEarlyRedemptionDialogOpened(null);
            if (option?.reload) {
                getAccountList();
                reloadList();
            }
        },
        [reloadList, getAccountList]
    );

    const handleIntroPartialWithdraw = useCallback(() => {
        history.push(`/term-deposit/partial-withdraw/${termId}`);
        GlobalDialogController.hide();
    }, [history, termId]);

    const handleOpenIntroPartialWithdraw = useCallback(() => {
        const statusConditionDialogString = localStorage.getItem(
            "statusConditionDialog"
        );

        if (statusConditionDialogString != null) {
            const statusConditionDialogJSON = JSON.parse(
                statusConditionDialogString
            );

            if (statusConditionDialogJSON.TD_INTRO_WITH_DRAW === undefined) {
                GlobalDialogController.show({
                    component: () => (
                        <IntroPartialWithdraw
                            onSubmit={handleIntroPartialWithdraw}
                            tdId={termId}
                        />
                    ),
                    size: "xs",
                });
                statusConditionDialogJSON.TD_INTRO_WITH_DRAW = true;
                localStorage.setItem(
                    "statusConditionDialog",
                    JSON.stringify(statusConditionDialogJSON)
                );
            } else {
                handleIntroPartialWithdraw();
            }
        } else {
            GlobalDialogController.show({
                component: () => (
                    <IntroPartialWithdraw
                        onSubmit={handleIntroPartialWithdraw}
                        tdId={termId}
                    />
                ),
                size: "xs",
            });
            localStorage.setItem(
                "statusConditionDialog",
                JSON.stringify({ TD_INTRO_WITH_DRAW: true })
            );
        }
    }, [handleIntroPartialWithdraw, termId]);

    const closeDialog = () => {
        GlobalDialogController.hide();
    };

    const TDSpecialRate = useCallback(() => {
        const contents = {
            banner: tommyNoHope,
            title: t("TD:td_early_redeem_partial_withdraw_reject_title"),
            description: t("TD:td_early_redeem_partial_withdraw_reject_body"),
            buttons: [
                {
                    variant: "primary",
                    label: t("TD:td_early_redeem_full_withdraw"),
                    onClick: () => {
                        openEarlyRedemption(termId);
                        closeDialog();
                    },
                },
                {
                    variant: "plain",
                    label: t("lb_close"),
                    onClick: () => closeDialog(),
                },
            ],
        };
        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={contents} centerImg />
            ),
        });
    }, [openEarlyRedemption, t, termId]);

    const handleValidation = useCallback(async () => {
        setValidationLoading(true);
        const response = await termDepositService.validationSpecialRate({
            termDepositId: termId,
        });

        const { code, data } = response.data;
        setValidationLoading(false);
        switch (code) {
            case RESPONSE_CODE.VALIDATION_SPECIAL_RATE:
                TDSpecialRate();
                break;
            case RESPONSE_CODE.SUCCESS:
                handleOpenIntroPartialWithdraw();
                break;
            default:
                break;
        }
    }, [TDSpecialRate, handleOpenIntroPartialWithdraw, termId]);

    useEffect(() => {
        if (parseFloat(tdId) === termId) {
            setExpended(true);
            if (total - currentIndex < 4) {
                if (isFunction(scrollBottom)) {
                    scrollBottom();
                    return;
                }
            }

            const { top } = tdRef.current.getBoundingClientRect();
            window.scrollTo({ top: top - 110, behavior: "smooth" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitChangeRollover = useCallback(
        (type) => {
            onRolloverClick(true, type);
            GlobalDialogController.hide();
        },
        [onRolloverClick]
    );

    const handleChangeRollover = useCallback(
        (type) => {
            GlobalDialogController.show({
                component: () => (
                    <ConfirmRolloverDialog
                        onSubmit={() => {
                            handleSubmitChangeRollover(type);
                        }}
                        onClose={() => GlobalDialogController.hide()}
                        rollType={type}
                        maturityDate={maturityDate}
                    />
                ),
            });
        },
        [handleSubmitChangeRollover, maturityDate]
    );

    const handleOpenChangeRollover = () => {
        let mappedRollType = rollType;

        if (!autoRoll || !rollType)
            mappedRollType = TermDepositRollType.NO_ROLLOVER;

        GlobalDialogController.show({
            component: () => (
                <RolloverMethodDialog
                    onClose={() => GlobalDialogController.hide()}
                    rollType={mappedRollType}
                    onSubmit={(data) => {
                        handleChangeRollover(data?.rollType || "");
                    }}
                    showRollover={enableRollingOnlyPrinciple}
                />
            ),
        });
    };

    const tdRollType = useMemo(() => {
        if (!autoRoll) return t("TD:td_no_rollover_method_title");

        if (rollType === TermDepositRollType.TOTAL_ROLLOVER)
            return t("TD:td_total_rollover_method_title");

        return t("TD:td_partial_rollover_method_title");
    }, [rollType, t, autoRoll]);

    const isRolloverG = useMemo(
        () =>
            autoRoll &&
            rollType === TermDepositRollType.PARTIAL_ROLLOVER &&
            status !== TermDepositStatus.Locked,
        [rollType, status, autoRoll]
    );

    // console.log("====================");
    // console.log("autoRoll", autoRoll);
    // console.log("tdRollType", tdRollType);
    // console.log("isRolloverG", isRolloverG);
    // console.log("iconName", iconName);

    const closeOverdraft = useCallback(() => {
        const contents = {
            banner: tomiFindding,
            title: t("overdraft:overdraft_close_to_unlock_td_popup_title"),
            description: t("overdraft:overdraft_close_to_unlock_td_popup_desc"),
            buttons: [
                {
                    variant: "primary",
                    label: t(
                        "overdraft:overdraft_close_to_unlock_td_popup_cta"
                    ),
                    onClick: () => {
                        history.push("/overdraft/details");
                        closeDialog();
                    },
                },
                {
                    variant: "plain",
                    label: t(
                        "overdraft:overdraft_close_to_unlock_td_popup_cancel_button"
                    ),
                    onClick: () => closeDialog(),
                },
            ],
        };
        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={contents} centerImg />
            ),
        });
    }, [history, t]);

    return (
        <div
            className={clsx(classes.tdItemRoot, { inactive: isInactive })}
            ref={tdRef}
        >
            <div
                className={classes.clickableArea}
                onClick={() => setExpended((prev) => !prev)}
            >
                {isRolloverG && <RolloverIcon className={classes.tdIcon} />}
                {!isRolloverG && (
                    <Icon
                        className={clsx(
                            `font-icon icon-${iconName}`,
                            classes.tdIcon
                        )}
                    />
                )}

                <Box flex={1} paddingX={1}>
                    <LspTypography variant="subheading2">
                        {termName || `TD# ${termDepositAcc}`}
                    </LspTypography>
                    <LspTypography color="grey" variant="body1">
                        {shortDesc}
                    </LspTypography>
                    <LspTypography color="grey" variant="body1">
                        {tdRollType}
                    </LspTypography>
                </Box>
                <Box>
                    <div
                        className={clsx(classes.tdAmount, {
                            inactive: isInactive,
                        })}
                    >
                        {formatNumber(termDepositBalance)}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <LspTypography color="grey" variant="body2">
                            {formattedDaysLeft}
                        </LspTypography>
                    </div>
                </Box>
            </div>
            <TermDepositProgressBar
                inactive={isInactive}
                value={numberOfDayLeft}
                maxValue={totalDay}
            />
            <Collapse in={expanded} timeout="auto">
                <Divider />
                <div
                    className={clsx(classes.detailTableRow, {
                        inactive: isInactive,
                    })}
                >
                    <div
                        className={clsx(classes.detailTableRowCell, {
                            inactive: isInactive,
                        })}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_deposit_date")}
                        </div>
                        <div className={classes.cellValue}>{openDate}</div>
                    </div>
                    <div
                        className={clsx(classes.detailTableRowCell, {
                            inactive: isInactive,
                        })}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_deposit_amount")}
                        </div>
                        <div className={classes.cellValue}>
                            {formatNumber(termDepositBalance)}
                        </div>
                    </div>
                    <div
                        className={clsx(
                            classes.detailTableRowCell,
                            "disableBorderRight",
                            {
                                inactive: isInactive,
                            }
                        )}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_term")}
                        </div>
                        <div className={classes.cellValue}>{formattedTerm}</div>
                    </div>
                </div>
                <div
                    className={clsx(classes.detailTableRow, {
                        inactive: isInactive,
                    })}
                >
                    <div
                        className={clsx(classes.detailTableRowCell, {
                            inactive: isInactive,
                        })}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_rate")}
                        </div>
                        <div className={classes.cellValue}>{interestRate}%</div>
                    </div>
                    <div
                        className={clsx(classes.detailTableRowCell, {
                            inactive: isInactive,
                        })}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_accrued")}
                        </div>
                        <div className={classes.cellValue}>
                            {formatNumber(totalInterest)}
                        </div>
                    </div>
                    <div
                        className={clsx(
                            classes.detailTableRowCell,
                            "disableBorderRight",
                            {
                                inactive: isInactive,
                            }
                        )}
                    >
                        <div className={classes.cellLabel}>
                            {t("termDeposit_lb_balance_redeem_date")}
                        </div>
                        <div className={classes.cellValue}>
                            {formatNumber(termDepositBalance + totalInterest)}
                        </div>
                    </div>
                </div>

                {isRolloverAvailable && (
                    <div className={classes.rolloverContainer}>
                        <Box flex={1} display="flex" px={1} alignItems="center">
                            <LspTypography variant="body3" component="span">
                                {t("TD:td_rollover_method")}:
                            </LspTypography>
                            &nbsp;
                            <LspTypography
                                variant="subheading3"
                                component="span"
                            >
                                {tdRollType}
                            </LspTypography>
                        </Box>
                        <Box display="flex" px={1} alignItems="center">
                            <LspButton
                                type="button"
                                buttonProps={{
                                    startIcon: <EditIcon />,
                                }}
                                variant="plain"
                                onClick={() => handleOpenChangeRollover()}
                            >
                                {t("TD:td_rollover_method_change")}
                            </LspButton>
                        </Box>
                    </div>
                )}
                <Box
                    display="flex"
                    alignItems="center"
                    px={2}
                    py={2.5}
                    className={classes.tdAction}
                >
                    <Box
                        flex={1}
                        textAlign={isInactive ? "center" : undefined}
                        mr={!isMobile ? 2 : 0}
                        mb={isMobile ? 2 : 0}
                    >
                        <LspTypography
                            onClick={openRolloverHistory}
                            variant="hyperlink1"
                            color="success"
                        >
                            {t("td_view_rollover_history")}
                        </LspTypography>
                    </Box>
                    {status !== TermDepositStatus.Locked &&
                        status !== TermDepositStatus.Redeemed && (
                            <LspButton
                                onClick={() => {
                                    openEarlyRedemption();
                                }}
                                mr={!isMobile ? 1 : 0}
                                mb={isMobile ? 1 : 0}
                                fullWidth={isMobile}
                                progressing={validationFetching}
                                variant={
                                    allowPartialWithDraw
                                        ? "secondary"
                                        : "primary"
                                }
                            >
                                {t("TD:td_early_redeem_full_withdraw")}
                            </LspButton>
                        )}

                    {allowPartialWithDraw &&
                        status !== TermDepositStatus.Locked &&
                        status !== TermDepositStatus.Redeemed && (
                            <LspButton
                                onClick={() => {
                                    handleValidation();
                                    setActiveId(termId);
                                }}
                                mr={!isMobile ? 1 : 0}
                                mb={isMobile ? 1 : 0}
                                fullWidth={isMobile}
                                progressing={validationLoading}
                            >
                                {t("TD:td_early_redeem_partial_withdraw")}
                            </LspButton>
                        )}
                    {status === TermDepositStatus.Locked && (
                        <Box className={classes.lockedTermDepositNote}>
                            {lockByOD
                                ? t("overdraft:overdraft_td_locked_detail_note")
                                : t("td_lb_locked_item_note_web")}
                            {lockByOD && (
                                <LspButton onClick={closeOverdraft}>
                                    {t(
                                        "overdraft:overdraft_close_to_unlock_td_detail_button"
                                    )}
                                </LspButton>
                            )}
                        </Box>
                    )}
                </Box>
            </Collapse>
            {isRolloverHistoryDialogOpened && (
                <RolloverHistoryDialog
                    tdId={isRolloverHistoryDialogOpened}
                    onClose={closeRolloverHistory}
                />
            )}
            {isEarlyRedemptionDialogOpened && (
                <EarlyRedemptionDialog
                    tdId={isEarlyRedemptionDialogOpened}
                    onClose={closeEarlyRedemption}
                />
            )}
        </div>
    );
};

const mapState = (state) => ({
    validation: state.termDeposit.validationTD.status,
    validationFetching: state.termDeposit.validationTD.fetching,
    validationTdId: state.termDeposit.validationTD.tdId,
});

const mapDispatch = (dispatch) => ({
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
    validationTD: (payload) =>
        dispatch(termDepositAction.validateSpecialRateRequest(payload)),
    clearValidationTD: () => dispatch(termDepositAction.clearValidateRate()),
});

export default connect(mapState, mapDispatch)(TermDepositItem);
