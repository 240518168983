/* eslint-disable react/no-array-index-key */
import { makeStyles } from "@material-ui/core";
import { memo, useEffect, useMemo } from "react";
import LspTimeItem from "@components/LspBooking/LspTime/LspTimeItem";
import bookingActions from "@redux/actions/booking";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { BOOKING_PERIOD } from "@containers/Booking/constant";

const useStyles = makeStyles((theme) => ({
    bookingList: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(3),
        alignItems: "flex-start",
        justifyContent: "center",
    },
    message: {
        alignItems: "center",
        height: 300,
        textAlign: "center",
    },
}));

const LspTimeBodyTab = ({
    selectedDate,
    bookingList,
    updateSelectedDate,
    onNext,
    statusList,
    type,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        if (isEmpty(selectedDate) && bookingList?.length > 0) {
            updateSelectedDate(bookingList[0]);
        }
    }, [selectedDate, bookingList, updateSelectedDate]);

    const isDayOff = useMemo(
        () => selectedDate?.status === statusList.NON_OPERATIONAL,
        [selectedDate, statusList]
    );

    const isFullSlot = useMemo(() => {
        if (selectedDate?.status === statusList.NON_OPERATIONAL) return false;

        const isSlotAvailable = selectedDate?.slots?.find(
            (item) => item.remainingCapacity > 0
        );

        return !isSlotAvailable;
    }, [selectedDate, statusList]);

    const periodList = useMemo(() => {
        return [
            {
                periodId: BOOKING_PERIOD.MORNING,
                name: t("booking_delivery_morning"),
            },
            {
                periodId: BOOKING_PERIOD.AFTERNOON,
                name: t("booking_delivery_afternoon"),
            },
            {
                periodId: BOOKING_PERIOD.EVENING,
                name: t("booking_delivery_evening"),
            },
        ];
    }, [t]);

    return (
        <div
            className={clsx(classes.bookingList, {
                [classes.message]: isDayOff || isFullSlot,
            })}
        >
            {isDayOff && (
                <LspTypography variant="body2">
                    {t("join_schedule_msg_days_off")}
                </LspTypography>
            )}
            {isFullSlot && (
                <LspTypography variant="body2">
                    {t("join_schedule_msg_fully_booked")}
                </LspTypography>
            )}

            {/*  Select time  */}
            {!isDayOff &&
                !isFullSlot &&
                type !== "period" &&
                selectedDate?.slots?.map((time, index) => (
                    <LspTimeItem
                        id={`time-${selectedDate?.day}-${time.name}`}
                        item={time}
                        key={`time-${selectedDate?.day}-${index}`}
                        onNext={onNext}
                    />
                ))}

            {/* Select period: Morning, afternoon, evening */}
            {!isDayOff &&
                !isFullSlot &&
                type === "period" &&
                periodList?.map((time, index) => (
                    <LspTimeItem
                        id={`time-${selectedDate?.day}-${time.name}`}
                        item={time}
                        key={`time-${selectedDate?.day}-${index}`}
                        onNext={onNext}
                    />
                ))}
        </div>
    );
};

const mapState = (state) => ({
    selectedDate: state.booking.selectedDate,
    bookingList: state.booking.bookingList,
});
const mapDispatch = (dispatch) => ({
    updateSelectedDate: (payload) =>
        dispatch(bookingActions.setSelectedDate(payload)),
});

export default memo(connect(mapState, mapDispatch)(LspTimeBodyTab));
