import { useEffect, useCallback, useRef, useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { CreditCardNavigationKey, RESPONSE_CODE } from "@config/constants";
import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { useHistory } from "react-router-dom";
import LspTypography from "@components/LspTypography";
import accountService from "@services/account";
import { parse } from "date-fns";
import { enUS, vi } from "date-fns/locale";
import Loading from "@components/Loading";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ChartDetail from "./ChartDetail";

const useStyles = makeStyles(() => ({
    period: {
        "&::first-letter": {
            textTransform: "uppercase",
        },
    },
}));

const CurrentStatement = () => {
    const history = useHistory();
    const classes = useStyles();
    const { t, i18n } = useTranslation("translation", "creditCard");
    const { formatNumber } = useNumber();
    const [currentData, setCurrentData] = useState({});
    const [emptyList, setEmptyList] = useState(false);
    const [loading, setLoading] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onMakePaymentClick = useCallback(() => {
        history.push("/credit-card/payment");
    }, [history]);

    const mappingData = useCallback(
        (data) => {
            if (Object.keys(data).length === 0) {
                setEmptyList(true);
                return;
            }
            if (data.status === "OVERDUE") {
                data.statusLabel = t("creditCard:stm_lb_overdue");
            } else if (data.status === "PAID") {
                data.statusLabel = t("creditCard:stm_lb_paid");
            }
            if (data.period) {
                data.period = format(
                    parse(data.period, "yyyyMM", new Date()),
                    "MMMM yyyy",
                    {
                        locale: i18n.language === "vn" ? vi : enUS,
                    }
                );
            }
            if (data.dueDate) {
                data.closeDateLabel = t("creditCard:stm_as_of_date").replace(
                    "%@",
                    data.cycleDate
                );
            }
            data.chartData = Object.values(data.summary || {});
            data.chartLabels = Object.keys(data.summary || {}).map(
                (summaryItem) => {
                    switch (summaryItem) {
                        case "food":
                            return t("creditCard:stm_expenses_fb");
                        case "shopping":
                            return t("creditCard:stm_expenses_ecom");
                        case "entertainments":
                            return t("creditCard:stm_expenses_lifestyle");
                        case "others":
                        default:
                            return t("creditCard:stm_expenses_other");
                    }
                }
            );

            setCurrentData(data);
        },
        [i18n, t]
    );

    const getCurrentStatementList = useCallback(async () => {
        setLoading(true);
        const { data } = await accountService.getCurrentStatementList({});
        if (unmounted.current) {
            return;
        }
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                mappingData(data.data);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [mappingData]);

    useEffect(() => {
        getCurrentStatementList();
    }, [getCurrentStatementList]);

    const viewPastStatement = (e) => {
        e.preventDefault();
        history.push(CreditCardNavigationKey.LastStatement);
    };

    return (
        <Content>
            {loading && <Loading />}
            {!loading && (
                <>
                    <AccountSummaryBox
                        leftItemName={t("creditCard:stm_due_date")}
                        rightItemName={t("creditCard:stm_min_payment")}
                        rightItemColor="blue"
                        leftItemValue={currentData?.dueDate || "-"}
                        rightItemValue={
                            currentData?.minimumPayment
                                ? formatNumber(currentData.minimumPayment)
                                : "-"
                        }
                        rightItemTooltip={t("creditCard:stm_min_payment_note")}
                    />
                    <Box
                        mt={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="end"
                    >
                        <LspButton
                            variant="secondary"
                            onClick={onMakePaymentClick}
                            mb={4}
                        >
                            {t("mastercard_menu_label_make_payment")}
                        </LspButton>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            mt={2}
                            mb={2}
                            pl={2}
                            pr={2}
                        >
                            <LspTypography
                                color="black"
                                variant="subheading2"
                                className={classes.period}
                            >
                                {currentData?.period}
                            </LspTypography>
                            <a href="#" onClick={viewPastStatement}>
                                <LspTypography
                                    color="grey"
                                    variant="hyperlink1"
                                    component="span"
                                >
                                    {t("creditCard:stm_past_stm")}
                                </LspTypography>
                            </a>
                        </Box>
                    </Box>

                    {!emptyList ? (
                        <ChartDetail currentData={currentData} />
                    ) : (
                        <Box paddingY={4} textAlign="center">
                            <Typography variant="body2">
                                {t("creditCard:stm_no_current_stm")}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Content>
    );
};

export default CurrentStatement;
