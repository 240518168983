import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sub, format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RESPONSE_CODE, TransactionGroupType } from "@config/constants";
import accountService from "@services/account";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspButton from "@components/LspButton";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import useAccounts from "@helpers/useAccounts";
import LspTypography from "@components/LspTypography";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TransactionListLoading from "@components/TransactionList/TransactionListLoading";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import mergeTransactionList from "@helpers/mergeTransactionList";

const useStyles = makeStyles((theme) => ({
    histories: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[0],
    },
    historiesHeader: {
        textAlign: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    historiesBody: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: "100%",
        maxHeight: "75vh",
        overflow: "auto",
    },
    emptyMessage: {
        fontStyle: "italic",
        minHeight: 286,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
const offset = -1;
const index = 0;

const PaymentHistoriesOfPayee = ({
    serverTime,
    payeeId,
    nickName,
    additionalInfo,
    onClose,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { spendAccount } = useAccounts();
    const [loading, setLoading] = useState(false);
    const [histories, setHistories] = useState([]);
    const [noHistory, setNoHistory] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const firstLoaded = useRef(false);

    const [dataLoadMore, setDataLoadMore] = useState({
        txnDateIndex: "",
        xidIndex: "",
    });
    const date = useMemo(() => {
        if (!serverTime)
            return {
                toDate: "",
                fromDate: "",
            };
        const fromDate = sub(serverTime, {
            months: 3,
        });
        return {
            toDate: format(serverTime, "dd/MM/yyyy") || "",
            fromDate: format(fromDate, "dd/MM/yyyy") || "",
        };
    }, [serverTime]);

    const unmounted = useRef(false);

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getHistoriesRequest = useCallback(async (payload) => {
        setLoading(true);
        const response = await accountService.getPayeeTransactionHistories(
            payload
        );

        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        firstLoaded.current = true;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setHasMore(
                    Array.isArray(data?.items) && data?.items?.length > 0
                );
                setDataLoadMore({
                    txnDateIndex: data?.txnDateIndex,
                    xidIndex: data?.xidIndex,
                });
                setHistories((prev) => {
                    return mergeTransactionList(prev, data?.items);
                });
                setLoading(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                setLoading(false);
                break;
        }
    }, []);

    const getHistories = useCallback(() => {
        const payload = {
            accountType: "1025",
            accountNo: spendAccount.no,
            toDate: date?.toDate,
            fromDate: date?.fromDate,
            index,
            offset,
            payeeId,
            format: "group",
        };

        if (loading) return;

        getHistoriesRequest(payload);
    }, [spendAccount, getHistoriesRequest, payeeId, loading, date]);

    const loadMoreHistories = useCallback(() => {
        const payload = {
            accountType: "1025",
            accountNo: spendAccount.no,
            toDate: date?.toDate,
            fromDate: date?.fromDate,
            index,
            offset,
            payeeId,
            txnDateIndex: dataLoadMore?.txnDateIndex,
            xidIndex: dataLoadMore?.xidIndex,
            format: "group",
        };

        if (loading) return;

        getHistoriesRequest(payload);
    }, [
        dataLoadMore,
        payeeId,
        loading,
        spendAccount,
        getHistoriesRequest,
        date,
    ]);

    useEffect(() => {
        if (serverTime && !firstLoaded.current) {
            getHistories();
        }
    }, [serverTime, getHistories]);

    useEffect(() => {
        if (!additionalInfo) {
            setNoHistory(true);
        }
    }, [additionalInfo]);

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: loadMoreHistories,
        scrollContainer: "window",
    });

    return (
        <Content size="md">
            <div className={classes.histories}>
                <LspTypography
                    variant="heading1"
                    color="grey"
                    className={classes.historiesHeader}
                >{`${t(
                    "payee_payment_history_to"
                )} ${nickName}`}</LspTypography>
                <div className={classes.historiesBody}>
                    {!loading && histories.length <= 0 && noHistory && (
                        <LspTypography
                            variant="body1"
                            color="grey"
                            className={classes.emptyMessage}
                        >
                            {t(
                                "mm_payment_history_no_transferred_to_destination"
                            )}
                        </LspTypography>
                    )}
                    {!loading && histories.length <= 0 && !noHistory && (
                        <LspTypography
                            variant="body1"
                            color="grey"
                            className={classes.emptyMessage}
                        >
                            {t(
                                "mm_payment_history_no_transferred_to_destination_in_period_time"
                            )}
                        </LspTypography>
                    )}
                    <div ref={infiniteRef}>
                        {histories?.map((txnGroup) => {
                            const key = `${txnGroup.dispDate}-${txnGroup.item.length}`;
                            return (
                                <TransactionGroup
                                    key={key}
                                    type={TransactionGroupType.SpendAccount}
                                    header={txnGroup.dispDate}
                                    items={txnGroup.item}
                                />
                            );
                        })}
                    </div>
                    {loading && <TransactionListLoading />}
                </div>
                {/* <TransactionListLoading /> */}
                <LspButton fullWidth onClick={onClose}>
                    {t("lb_close")}
                </LspButton>
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime.value,
});

export default connect(mapState)(PaymentHistoriesOfPayee);
