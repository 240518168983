import MarketingDialog from "@components/MarketingDialog";
import {
    COMMUNICATION_TYPES,
    NOTIFICATION_GROUPS,
} from "@containers/NotificationCenter/constant";
import notificationService from "@services/notification";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import GlobalDialogController from "./controllers/GlobalDialogController";
import useDeepLink from "./useDeepLink";

const useNotifyNavigation = () => {
    const history = useHistory();
    const { deepLinkHandler } = useDeepLink();

    const isMKTGroup = useCallback((detail) => {
        if (
            (detail?.type === COMMUNICATION_TYPES.MD ||
                detail?.type === COMMUNICATION_TYPES.TXT) &&
            (detail?.group === NOTIFICATION_GROUPS.MKT ||
                detail?.group === NOTIFICATION_GROUPS.MKTOB)
        ) {
            return true;
        }
        return false;
    }, []);

    const openMKTDialog = useCallback((detail, onClose = null) => {
        GlobalDialogController.show({
            component: () => (
                <MarketingDialog detail={detail} onCloseFunc={onClose} />
            ),
        });
    }, []);

    const directionHandler = useCallback(
        (detail) => {
            const group = detail.group || "";
            const deepLink = detail.deeplink || "";
            const action = detail.iD || "";

            switch (group) {
                case NOTIFICATION_GROUPS.GOAL_SAVE:
                    break;
                case NOTIFICATION_GROUPS.CARD_MANAGEMENT:
                    if (deepLink !== "") {
                        deepLinkHandler(deepLink);
                    } else {
                        history.push("/card-management/debit");
                    }
                    break;
                case NOTIFICATION_GROUPS.BILL:
                case NOTIFICATION_GROUPS.TRANSFER:
                    if (deepLink !== "") {
                        deepLinkHandler(deepLink);
                    } else {
                        history.push("/spend-account/transactions");
                    }
                    break;
                case NOTIFICATION_GROUPS.MASTERCARD:
                    if (deepLink !== "") {
                        deepLinkHandler(deepLink);
                    } else {
                        history.push("/credit-card/transactions");
                    }
                    break;
                case NOTIFICATION_GROUPS.TERM_DEPOSIT:
                    if (action === "rate") {
                        history.push("/term-deposit/active?open=rate");
                    } else {
                        history.push("/term-deposit/active");
                    }
                    break;
                case NOTIFICATION_GROUPS.LIXI:
                case NOTIFICATION_GROUPS.IOTP:
                case NOTIFICATION_GROUPS.INVITATION_LEVEL_UP:
                    if (deepLink !== "") {
                        deepLinkHandler(deepLink);
                    } else {
                        history.push("/notification-center");
                    }
                    break;
                case NOTIFICATION_GROUPS.PRODUCT:
                    if (deepLink !== "") {
                        deepLinkHandler(deepLink);
                    } else {
                        history.push("/notification-center");
                    }
                    break;
                case NOTIFICATION_GROUPS.SECURITY:
                    if (deepLink !== "") {
                        GlobalDialogController.showCustomDialog({
                            dialogInfo: {
                                iconImage: "Warning",
                                header: "oops",
                                content: "msg_feature_not_available_on_web",
                                button: "lb_ok",
                            },
                        });
                    } else {
                        history.push("/notification-center");
                    }
                    break;
                default:
                    history.push("/notification-center");
                    break;
            }
        },
        [deepLinkHandler, history]
    );

    const direction = useCallback(
        (detail) => {
            console.log("detail: ", detail);
            if (!detail) return;

            if (!detail.read) {
                const payload = {
                    action: "R",
                    id: detail.iD.toString(),
                };
                notificationService.updateNotificationStatus(payload);
            }

            if (isMKTGroup(detail)) {
                openMKTDialog(detail);
            } else {
                directionHandler(detail);
            }
        },
        [isMKTGroup, openMKTDialog, directionHandler]
    );

    return { direction, openMKTDialog };
};

export default useNotifyNavigation;
