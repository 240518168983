import { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import {
    RESPONSE_CODE,
    ERROR_TYPES,
    LANGUAGE_OPTIONS,
    ADJ_DIRECT,
} from "@config/constants";

import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import authAction from "@redux/actions/auth";
import useNumber from "@helpers/useNumber";
import otpDialogAction from "@redux/actions/otp-dialog";
import { isFunction } from "lodash";
import tomiFail from "@assets/images/no_hope_with_status_error.png";
import tommy from "@assets/images/no_hope_with_status.png";
import accountService from "@services/account";
import UpdatePasswordDialog from "../UpdatePasswordDialog";

import NotifyDialog from "./NotifyDialog";

const ErrorHandler = ({
    errorCode,
    close,
    type,
    dialogContent,
    isOpen,
    loading,
    isAuthenticated,
    data,
}) => {
    const { t, i18n } = useTranslation(
        "translation",
        "ekyc",
        "master",
        "hangout"
    );
    const [dialogInfo, setDialogInfo] = useState();
    const dispatch = useDispatch();
    const { formatNumber } = useNumber();

    const handleChangePassword = useCallback(() => {
        GlobalDialogController.hide();
        GlobalDialogController.show({
            component: UpdatePasswordDialog,
        });
    }, []);

    const handleCloseDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const handleOpenApp = useCallback(() => {
        const adjHomePage = ADJ_DIRECT.ADJ_HOME_PAGE_LINK;
        window.open(adjHomePage, "_blank");
    }, []);

    const handleChangePasswordLater = useCallback(async (params) => {
        GlobalDialogController.hide();
        const response = await accountService.changePasswordLater(params);

        const { code } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                window.location.href = "/home";
                GlobalDialogController.hide();
                break;

            default:
                break;
        }
    }, []);

    const getContent = useCallback(
        (errorType) => {
            switch (errorType) {
                case ERROR_TYPES.END_OF_DAY_JOB_RUNNING:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("mc_lb_title_notice"),
                        content: `${t("td_message_system_eod").replace(
                            "%@",
                            `<b>${t("td_message_system_eod_time")}</b>`
                        )}`,
                        button: t("lb_btn_ok_got_it"),
                    });
                    break;
                case ERROR_TYPES.DESTINATION_NOT_FOUND:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("td_lb_error_sorry"),
                        content: t(
                            "global_error_msg_cant_find_destination_info"
                        ),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.DESTINATION_INVALID:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("td_lb_error_sorry"),
                        content: t(
                            "global_error_msg_destination_no_longer_valid"
                        ),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.FTI:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("error_dialog_fti_header"),
                        content: t("error_dialog_fti_body"),
                        highlight: t("error_dialog_fti_advice"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.PASSWORD_EXPIRED:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("fc_lb_attention"),
                        content: t("password_expired_rule_msg"),
                        button: t("lb_ok"),
                        afterClose: () => {
                            GlobalDialogController.show({
                                component: UpdatePasswordDialog,
                            });
                        },
                    });
                    break;
                case ERROR_TYPES.NO_CONNECTION:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("error_dialog_no_connection_header"),
                        content: t("error_dialog_no_connection_body"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.FEATURE_UNAVAILABLE:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("error_dialog_service_unavailable_header"),
                        content: t("error_dialog_service_unavailable_body"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.UNSUPPORTED_OPERATION:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("global_header_unsupported_operation"),
                        content: t("global_msg_api_no_longer_supported"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.SERVER_UNAVAILABLE:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("error_dialog_server_unavailable_header"),
                        content: t("error_dialog_server_unavailable_body"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.TIMEOUT:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("error_dialog_connection_timeout_header"),
                        content: t("error_dialog_connection_timeout_body"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.TECHNICAL_DIFFICULT:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("error_dialog_technical_difficult_header"),
                        content: t("error_dialog_technical_difficult_body"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.IOTP_REQUIRED:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("otp_transaction_limit_title"),
                        content: t("otp_transaction_limit_content_web"),
                        button: t("otp_transaction_limit_btn"),
                    });
                    break;
                case ERROR_TYPES.UNAUTHORIZED:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: t("global_session_timeout"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.USER_BLOCKED:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("global_account_locked"),
                        content: t("global_account_locked_msg"),
                        contact: t("error_dialog_contact_box"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.MAX_FAIL_ATTEMPTS_REACHED:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: t("sc_ms_input_otp_wrong_three_times"),
                        button: t("lb_close"),
                    });
                    break;
                case ERROR_TYPES.CLOSED_ACCOUNT:
                    const text = t("ekyc:mc_ms_notice_closed_card2").replace(
                        "%@",
                        `<a href="tel: ${t("hangout:callcenter_number")}">${t(
                            "hangout:callcenter_number"
                        )}</a>`
                    );

                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("ekyc:mc_ms_notice_closed_card1"),
                        translatedContent: text,
                        button: t("lb_close"),
                    });
                    break;
                case ERROR_TYPES.ERROR_DYNAMIC_CONTENT:
                    const currentLanguage =
                        i18n.language === LANGUAGE_OPTIONS.EN
                            ? LANGUAGE_OPTIONS.EN.toUpperCase()
                            : LANGUAGE_OPTIONS.VI.toUpperCase();

                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            header:
                                (data?.title && data?.title[currentLanguage]) ||
                                "",
                            markdownContent:
                                (data?.msg && data?.msg[currentLanguage]) || "",
                            button:
                                (data?.button &&
                                    data?.button[currentLanguage]) ||
                                "lb_ok",
                        },
                    });
                    break;
                case ERROR_TYPES.DAILY_LIMIT_TRANSFER:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("global_title_daily_limit_exceed"),
                        content: t("ms_lb_transfer_daily_limit"),
                        button: t("lb_btn_ok_got_it"),
                    });
                    break;
                case ERROR_TYPES.TRANSFER_TO_YOURSELF:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: t("payee_card_number_invalid_payee_is_payer"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.CRYPTO_RELATED_TRANSACTION:
                    setDialogInfo({
                        iconImage: "Error",
                        header: t("lb_we_sorry"),
                        content: t("crypto_related_msg"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.FAST_CASH_LIMIT:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("fast_cash_title_error_limit_reached"),
                        boldContent: t(
                            "fast_cash_sub_title_error_limit_reached"
                        ),
                        content: t("fast_cash_msg_error_limit_reached"),
                        button: t("fast_cash_button_error_limit_reached"),
                    });
                    break;
                case ERROR_TYPES.EKYC_REACH_LIMIT:
                    const limitAmount =
                        data?.limitAmount || data?.remainingLimit || 0;
                    const content = t("mm_over_daily_limit").replace(
                        "%@",
                        formatNumber(limitAmount)
                    );
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("mc_lb_title_notice"),
                        content: `<b>${content}</b>`,
                        button: t("lb_btn_ok_got_it"),
                    });
                    break;
                case ERROR_TYPES.MONTHLY_LIMIT_FOREIGNER:
                    const amountLimit =
                        data?.limitAmount || data?.remainingLimit || 0;
                    const errContent = t("mm_over_daily_limit").replace(
                        "%@",
                        formatNumber(amountLimit)
                    );
                    setDialogInfo({
                        iconCustom: tomiFail,
                        header: t("gs_contribute_error_title"),
                        content: errContent,
                        button: t("lb_btn_ok_got_it"),
                    });
                    break;
                case ERROR_TYPES.NO_ENOUGH_MONEY:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("oops"),
                        content: t("payment_content_paynow_not_enough_money"),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.POT_NO_ENOUGH_MONEY:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("pot:mp_visavirtualcard_popup_balance_title"),
                        content: t("pot:mp_visavirtualcard_popup_balace_desc"),
                        button: t("pot:mp_visavirtualcard_popup_balance_btn"),
                    });
                    break;
                case ERROR_TYPES.FAST_CASH_NOT_ALLOWED:
                    setDialogInfo({
                        iconImage: "Warning",
                        header: t("fast_cash_title_error_transfer_not_allowed"),
                        content: t("fast_cash_msg_error_transfer_not_allowed"),
                        highlight: t(
                            "fast_cash_more_information_error_transfer_not_allowed"
                        ),
                        button: t("lb_ok"),
                    });
                    break;
                case ERROR_TYPES.PASSWORD_14_DAY_CHANGE_REMAIN:
                    const remainDays = data?.day ? data?.day : 0;
                    const header14 = t(
                        "dashboard:update_pw_popup_remind_14d_title"
                    )?.replace("%@", remainDays);
                    const content14 = t(
                        "dashboard:update_pw_popup_remind_14d_desc"
                    )?.replace("%@", remainDays);

                    setDialogInfo({
                        iconCustom: tommy,
                        header: header14,
                        content: content14,
                        doubleButton: [
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_change"
                                ),
                                onClick: () => handleChangePassword(),
                            },
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_later"
                                ),
                                onClick: () =>
                                    handleChangePasswordLater({
                                        code:
                                            RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_14_DAY,
                                    }),
                            },
                        ],
                    });
                    break;
                case ERROR_TYPES.PASSWORD_7_DAY_CHANGE_REMAIN:
                    const remainDays7 = data?.day ? data?.day : 0;
                    const header7 = t(
                        "dashboard:update_pw_popup_remind_7d_title"
                    )?.replace("%@", remainDays7);
                    const content7 = t(
                        "dashboard:update_pw_popup_remind_7d_desc"
                    )?.replace("%@", remainDays7);

                    setDialogInfo({
                        iconCustom: tommy,
                        header: header7,
                        content: content7,
                        doubleButton: [
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_change"
                                ),
                                onClick: () => handleChangePassword(),
                            },
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_later"
                                ),
                                onClick: () =>
                                    handleChangePasswordLater({
                                        code:
                                            RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_07_DAY,
                                    }),
                            },
                        ],
                    });
                    break;
                case ERROR_TYPES.PASSWORD_3_DAY_CHANGE_REMAIN:
                    const remainDays3 = data?.day ? data?.day : 0;
                    const header3 = t(
                        "dashboard:update_pw_popup_remind_3d_title"
                    )?.replace("%@", remainDays3);
                    const content3 = t(
                        "dashboard:update_pw_popup_remind_3d_desc"
                    )?.replace("%@", remainDays3);

                    setDialogInfo({
                        iconCustom: tommy,
                        header: header3,
                        content: content3,
                        doubleButton: [
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_change"
                                ),
                                onClick: () => handleChangePassword(),
                            },
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_later"
                                ),
                                onClick: () =>
                                    handleChangePasswordLater({
                                        code:
                                            RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_03_DAY,
                                    }),
                            },
                        ],
                    });
                    break;

                case ERROR_TYPES.PASSWORD_0_DAY_CHANGE_REMAIN:
                    setDialogInfo({
                        iconCustom: tommy,
                        header: "dashboard:update_pw_popup_remind_0d_title",
                        content: t("dashboard:update_pw_popup_remind_0d_desc"),
                        doubleButton: [
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_change"
                                ),
                                onClick: () => handleChangePassword(),
                            },
                            {
                                label: t(
                                    "dashboard:update_pw_popup_remind_btn_later"
                                ),
                                onClick: () =>
                                    handleChangePasswordLater({
                                        code:
                                            RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_0_DAY,
                                    }),
                            },
                        ],
                    });
                    break;
                case ERROR_TYPES.PASSWORD_FORCE_CHANGE_REMAIN:
                    setDialogInfo({
                        iconCustom: tommy,
                        header:
                            "dashboard:update_pw_popup_remind_expired_title",
                        content: t(
                            "dashboard:update_pw_popup_remind_expired_desc"
                        ),
                        button: "dashboard:update_pw_popup_remind_btn_change",
                        afterClose: () => handleChangePassword(),
                    });
                    break;
                case ERROR_TYPES.AUTH_BIOS:
                    setDialogInfo({
                        iconCustom: tommy,
                        header: "master:biometrics_web_auth_bios_popup_title",
                        content: t(
                            "master:biometrics_web_auth_bios_popup_desc"
                        ),
                        doubleButton: [
                            {
                                label: t("master:biometrics_web_cta_go_to_app"),
                                onClick: () => handleOpenApp(),
                            },
                            {
                                label: t("lb_cancel"),
                                onClick: () => handleCloseDialog(),
                            },
                        ],
                        afterClose: () => handleChangePassword(),
                    });
                    break;
                case ERROR_TYPES.ENROLL_DEFAULT:
                    setDialogInfo({
                        iconCustom: tommy,
                        header:
                            "master:biometrics_web_exceeded_limit_popup_title",
                        content: t(
                            "master:biometrics_web_exceeded_limit_popup_desc"
                        ),
                        doubleButton: [
                            {
                                label: t("master:biometrics_web_cta_go_to_app"),
                                onClick: () => handleOpenApp(),
                            },
                            {
                                label: t("lb_cancel"),
                                onClick: () => handleCloseDialog(),
                            },
                        ],
                    });
                    break;
                case ERROR_TYPES.ENROLL_EXCEEDED_LIMIT:
                    setDialogInfo({
                        iconCustom: tommy,
                        header: "master:biometrics_web_enroll_popup_title",
                        content: t("master:biometrics_web_enroll_popup_desc"),
                        doubleButton: [
                            {
                                label: t("master:biometrics_web_cta_go_to_app"),
                                onClick: () => handleOpenApp(),
                            },
                            {
                                label: t("lb_cancel"),
                                onClick: () => handleCloseDialog(),
                            },
                        ],
                    });
                    break;
                case ERROR_TYPES.ENROLL_FOREIGNER:
                    setDialogInfo({
                        iconCustom: tommy,
                        header:
                            "master:biometrics_web_foreigner_limit_popup_title",
                        content: t(
                            "master:biometrics_web_foreigner_limit_popup_desc"
                        ),
                        button: t("lb_btn_ok_got_it"),
                    });
                    break;
                default:
                    break;
            }
        },
        [
            t,
            i18n.language,
            data?.title,
            data?.msg,
            data?.button,
            data?.limitAmount,
            data?.remainingLimit,
            data?.day,
            formatNumber,
            handleChangePassword,
            handleChangePasswordLater,
            handleOpenApp,
            handleCloseDialog,
        ]
    );

    const showError = useCallback(
        (eCode) => {
            switch (eCode) {
                case RESPONSE_CODE.UNAUTHORIZED:
                    // open warning dialog
                    // after close dialog force logout and direct to login page
                    getContent(ERROR_TYPES.UNAUTHORIZED);
                    // dispatch(authAction.forceLogout());
                    break;

                case RESPONSE_CODE.DISCONNECT:
                case RESPONSE_CODE.DISCONNECT_V2:
                    getContent(ERROR_TYPES.NO_CONNECTION);
                    break;
                case RESPONSE_CODE.FEATURE_UNAVAILABLE:
                    getContent(ERROR_TYPES.FEATURE_UNAVAILABLE);
                    break;
                case RESPONSE_CODE.UNSUPPORTED_OPERATION:
                    getContent(ERROR_TYPES.UNSUPPORTED_OPERATION);
                    break;
                case RESPONSE_CODE.REQUEST_TIMEOUT:
                case RESPONSE_CODE.REQUEST_TIMEOUT_V2:
                case RESPONSE_CODE.OPERATION_TIMEOUT:
                    getContent(ERROR_TYPES.TIMEOUT);
                    break;
                case RESPONSE_CODE.TECHNICAL_DIFFICULT:
                    getContent(ERROR_TYPES.TECHNICAL_DIFFICULT);
                    break;
                case RESPONSE_CODE.IOTP_REQUIRED:
                    getContent(ERROR_TYPES.IOTP_REQUIRED);
                    break;
                case RESPONSE_CODE.FE_USER_BLOCKED:
                    getContent(ERROR_TYPES.USER_BLOCKED);
                    break;
                case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
                    getContent(ERROR_TYPES.MAX_FAIL_ATTEMPTS_REACHED);
                    dispatch(authAction.logoutSuccess());
                    break;
                case RESPONSE_CODE.CLOSED_ACCOUNT:
                    getContent(ERROR_TYPES.CLOSED_ACCOUNT);
                    if (isAuthenticated) {
                        dispatch(authAction.logoutSuccess());
                    }
                    break;
                case RESPONSE_CODE.ERROR_DYNAMIC_CONTENT:
                    getContent(ERROR_TYPES.ERROR_DYNAMIC_CONTENT);
                    break;
                case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                    getContent(ERROR_TYPES.DAILY_LIMIT_TRANSFER);
                    break;
                case RESPONSE_CODE.FAST_CASH_REACH_LIMIT:
                    getContent(ERROR_TYPES.FAST_CASH_LIMIT);
                    break;
                case RESPONSE_CODE.EKYC_REACH_LIMIT:
                    getContent(ERROR_TYPES.EKYC_REACH_LIMIT);
                    break;
                case RESPONSE_CODE.TRANSFER_TO_YOURSELF:
                    getContent(ERROR_TYPES.TRANSFER_TO_YOURSELF);
                    break;
                case RESPONSE_CODE.CRYPTO_RELATED_TRANSACTION:
                    getContent(ERROR_TYPES.CRYPTO_RELATED_TRANSACTION);
                    break;
                case RESPONSE_CODE.MONTHLY_LIMIT_FOREIGNER:
                    getContent(ERROR_TYPES.MONTHLY_LIMIT_FOREIGNER);
                    break;
                case RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_14_DAY:
                    getContent(ERROR_TYPES.PASSWORD_14_DAY_CHANGE_REMAIN);
                    break;
                case RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_07_DAY:
                    getContent(ERROR_TYPES.PASSWORD_7_DAY_CHANGE_REMAIN);
                    break;
                case RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_03_DAY:
                    getContent(ERROR_TYPES.PASSWORD_3_DAY_CHANGE_REMAIN);
                    break;
                case RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_0_DAY:
                    getContent(ERROR_TYPES.PASSWORD_0_DAY_CHANGE_REMAIN);
                    break;
                case RESPONSE_CODE.PASSWORD_EXPIRED:
                    getContent(ERROR_TYPES.PASSWORD_FORCE_CHANGE_REMAIN);
                    break;
                case RESPONSE_CODE.FE_CANCEL_TOKEN:
                    // no action to execute, using for prevent execute api request (quick code), dont show error dialog
                    break;
                case RESPONSE_CODE.AUTH_BIOS:
                case RESPONSE_CODE.AUTH:
                case RESPONSE_CODE.BIOS_NEW_DEVICE:
                    getContent(ERROR_TYPES.AUTH_BIOS);
                    break;
                case RESPONSE_CODE.FOREIGNER:
                    getContent(ERROR_TYPES.ENROLL_FOREIGNER);
                    break;
                case RESPONSE_CODE.RE_ENROLL:
                    getContent(ERROR_TYPES.ENROLL_EXCEEDED_LIMIT);
                    break;
                case RESPONSE_CODE.REQUIRED_ENROLL:
                    getContent(ERROR_TYPES.ENROLL_DEFAULT);
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE: // Handler on GlobalDialogController.js
                case RESPONSE_CODE.MISMATCH_VALUE: // Handler on GlobalDialogController.js
                case RESPONSE_CODE.OTP_EXPIRED: // Handler on GlobalDialogController.js
                case RESPONSE_CODE.SERVER_UNAVAILABLE:
                default:
                    getContent(ERROR_TYPES.SERVER_UNAVAILABLE);
                    break;
            }
        },
        [getContent, dispatch, isAuthenticated]
    );

    const showFinancialError = useCallback(
        (eCode) => {
            switch (eCode) {
                case RESPONSE_CODE.END_OF_DAY_JOB_RUNNING:
                    getContent(ERROR_TYPES.END_OF_DAY_JOB_RUNNING);
                    break;
                case RESPONSE_CODE.DESTINATION_NOT_FOUND:
                    getContent(ERROR_TYPES.DESTINATION_NOT_FOUND);
                    break;
                case RESPONSE_CODE.DESTINATION_INVALID:
                    getContent(ERROR_TYPES.DESTINATION_INVALID);
                    break;
                case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
                    getContent(ERROR_TYPES.MAX_FAIL_ATTEMPTS_REACHED);
                    dispatch(authAction.logoutSuccess());
                    break;
                case RESPONSE_CODE.NOT_ALLOWED: // 405 no enough money
                    getContent(ERROR_TYPES.NO_ENOUGH_MONEY);
                    break;
                case RESPONSE_CODE.POT_NO_ENOUGH_MONEY:
                    getContent(ERROR_TYPES.POT_NO_ENOUGH_MONEY);
                    break;
                case RESPONSE_CODE.FAST_CASH_NOT_ALLOW: // 7403
                    getContent(ERROR_TYPES.FAST_CASH_NOT_ALLOWED);
                    break;
                case RESPONSE_CODE.MONTHLY_LIMIT_FOREIGNER:
                case RESPONSE_CODE.UNAUTHORIZED:
                case RESPONSE_CODE.IOTP_REQUIRED:
                case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                case RESPONSE_CODE.FAST_CASH_REACH_LIMIT:
                case RESPONSE_CODE.EKYC_REACH_LIMIT:
                case RESPONSE_CODE.TRANSFER_TO_YOURSELF:
                case RESPONSE_CODE.CRYPTO_RELATED_TRANSACTION:
                case RESPONSE_CODE.ERROR_DYNAMIC_CONTENT:
                    showError(eCode);
                    break;
                case RESPONSE_CODE.FE_CANCEL_TOKEN:
                    // no action to execute, using for prevent execute api request (quick code), dont show error dialog
                    break;
                case RESPONSE_CODE.AUTH_BIOS:
                case RESPONSE_CODE.AUTH:
                case RESPONSE_CODE.BIOS_NEW_DEVICE:
                    getContent(ERROR_TYPES.AUTH_BIOS);
                    break;
                case RESPONSE_CODE.FOREIGNER:
                    getContent(ERROR_TYPES.ENROLL_FOREIGNER);
                    break;
                case RESPONSE_CODE.RE_ENROLL:
                    getContent(ERROR_TYPES.ENROLL_EXCEEDED_LIMIT);
                    break;
                case RESPONSE_CODE.REQUIRED_ENROLL:
                    getContent(ERROR_TYPES.ENROLL_DEFAULT);
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE: // Handler on GlobalDialogController.js
                case RESPONSE_CODE.MISMATCH_VALUE: // Handler on GlobalDialogController.js
                case RESPONSE_CODE.OTP_EXPIRED: // Handler on GlobalDialogController.js
                default:
                    getContent(ERROR_TYPES.FTI);
                    break;
            }
        },
        [getContent, showError, dispatch]
    );

    const closeHandler = () => {
        if (isFunction(close)) {
            close();
        }
        if (isFunction(dialogInfo?.afterClose)) {
            dialogInfo?.afterClose();
        }
        if (dialogInfo?.navigatePathForButton) {
            GlobalDialogController.directTo(dialogInfo?.navigatePathForButton);
        }
    };

    const dialogCustom = useCallback(() => {
        const dialog = {
            iconCustom: dialogContent?.iconCustom || "",
            iconImage: dialogContent?.iconImage || "",
            icon: dialogContent?.icon || "",
            header: t(dialogContent?.header) || "",
            content: t(dialogContent?.content) || "",
            translatedContent: dialogContent?.translatedContent || "",
            boldContent: t(dialogContent?.boldContent) || "",
            highlight: t(dialogContent?.highlight) || null,
            contact: t(dialogContent?.contact) || null,
            button: t(dialogContent?.button) || "",
            doubleButton: dialogContent?.doubleButton || null,
            lspTranslation: dialogContent?.lspTranslation || null,
            markdownContent: dialogContent?.markdownContent || null,
            linkContent: dialogContent?.linkContent || null,
            wrapperClasses: dialogContent?.wrapperClasses || "",
            navigatePathForButton: dialogContent?.navigatePathForButton || "",
        };
        setDialogInfo(dialog);
    }, [dialogContent, t]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (type === GlobalDialogTypes.FinancialError) {
            showFinancialError(errorCode);
        } else if (type === GlobalDialogTypes.Custom) {
            dialogCustom();
        } else {
            showError(errorCode);
        }
    }, [
        showFinancialError,
        showError,
        dialogCustom,
        errorCode,
        type,
        isOpen,
        close,
    ]);

    return (
        <NotifyDialog
            dialogContent={dialogInfo}
            closeHandler={closeHandler}
            loading={loading}
        />
    );
};

const stateProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});
const dispatchProps = (dispatch) => ({
    setOtpError: (error) => dispatch(otpDialogAction.setError(error)),
});
export default connect(stateProps, dispatchProps)(ErrorHandler);
