/* eslint-disable no-console */
export default class ApiCancelToken {
    static cancelToken;

    static cancel = () => {
        console.warn("cancel() hasn't been set");
    };

    static forceLogout = () => {
        console.warn("forceLogout() hasn't been set");
    };

    static setCancelToken(cancelToken) {
        this.cancelToken = cancelToken;
        this.cancel = cancelToken.cancel;
    }

    static setForceLogout(forceLogout) {
        this.forceLogout = forceLogout;
    }
}
