import LspButtonIconLink from "@components/LspButtonIconLink";
import LspTextField from "@components/LspTextField";
import { REGULATION_TYPE, RESPONSE_CODE } from "@config/constants";
import GoalSaveDetailBoxSuggestion from "@containers/GoalSave/GoalSaveDetail/GoalSaveDetailBoxSuggestion";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useForeignerDialog from "@helpers/useForeignerDialog";
import useNumber from "@helpers/useNumber";
import { Box, FormHelperText, Icon, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import accountService from "@services/account";
import { add, parse, format, isEqual } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(2),
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
            marginLeft: theme.spacing(2),
        },
    },
    halfColumn: {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            width: "calc(50% - 8px)",
        },
    },
    emptyError: {
        position: "absolute",
        bottom: theme.spacing(0.75),
        left: theme.spacing(1.75),
    },
}));

const GOAL_SAVE_MIN_CREATE = 100000;
const GOAL_SAVE_MAX_CREATE = 100000000000; // 1 billion

const GoalSaveDetailForm = ({
    detail,
    onClose,
    onSave,
    submitting,
    today,
    systemParams,
    isNotOnTrack,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const recurringTypeList = useMemo(
        () => [
            {
                id: "",
                label: t("gs_label_no_contribute"),
            },
            {
                id: "daily",
                label: t("gs_btn_daily"),
            },
            {
                id: "weekly",
                label: t("gs_btn_weekly"),
            },
            {
                id: "monthly",
                label: t("gs_btn_monthly"),
            },
        ],
        [t]
    );

    const [targetDate, setTargetDate] = useState(
        detail?.targetDate
            ? parse(detail?.targetDate, "dd/MM/yyyy", new Date())
            : null
    );
    const [targetAmount, setTargetAmount] = useState({
        value: detail?.targetAmount,
        formattedValue: detail?.targetAmount,
    });
    const [recurringAmount, setRecurringAmount] = useState({
        value: detail?.recurringAmount,
        formattedValue: detail?.recurringAmount,
    });

    const [recurringType, setRecurringType] = useState(() => {
        if (!detail?.recurringType) return recurringTypeList[0];
        const recurring = recurringTypeList?.find(
            (item) => item?.id === detail?.recurringType
        );

        return recurring || recurringTypeList[0];
    });

    const isNoContribute = useMemo(() => recurringType?.id === "", [
        recurringType,
    ]);

    const [startDate, setStartDate] = useState(
        detail?.startDate
            ? parse(detail?.startDate, "dd/MM/yyyy", new Date())
            : null
    );

    const [errors, setErrors] = useState({
        targetDate: false,
        targetDateEmpty: "",
        targetAmount: "",
        recurringAmount: "",
        recurringType: "",
        startDate: false,
        startDateEmpty: "",
    });

    const previousFormState = useRef({
        targetDate,
        targetMoney: targetAmount,
        recurringType,
        startDate,
    });

    const [suggestingField, setSuggestingField] = useState("");
    const [suggestingData, setSuggestingData] = useState(null);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const { dialog } = useForeignerDialog();

    const minLimitPerTransfer = useMemo(
        () => parseFloat(systemParams?.conf?.MinAmountLimitPerTransfer),
        [systemParams]
    );

    const maxTargetDate = useMemo(() => {
        return add(today, {
            years: 3,
            days: 1,
        });
    }, [today]);

    const minDate = useMemo(() => {
        return add(today, {
            days: 1,
        });
    }, [today]);

    const maxStartDate = useMemo(() => {
        return add(today, {
            months: 2,
            days: 1,
        });
    }, [today]);

    const isValidForm = useCallback(() => {
        if (isNoContribute) {
            return (
                !errors.targetDate &&
                !errors.targetDateEmpty &&
                !errors.targetAmount &&
                !errors.recurringType
            );
        }

        return (
            !errors.targetDate &&
            !errors.targetDateEmpty &&
            !errors.targetAmount &&
            !errors.recurringAmount &&
            !errors.recurringType &&
            !errors.startDate &&
            !errors.startDateEmpty
        );
    }, [errors, isNoContribute]);

    const isFieldChange = useCallback(() => {
        // console.log("previousFormState.current", previousFormState.current);
        // console.log("targetDate", targetDate);
        // console.log("targetAmount?.value", targetAmount?.value);
        // console.log("recurringAmount?.value", recurringAmount?.value);
        // console.log("startDate", startDate);
        return (
            !isEqual(previousFormState.current?.startDate, startDate) ||
            !isEqual(previousFormState.current?.endDate, targetDate) ||
            previousFormState.current?.targetMoney !== targetAmount?.value ||
            previousFormState.current?.suggestAmount !==
                recurringAmount?.value ||
            previousFormState.current?.recurringType?.id !== recurringType?.id
        );
    }, [recurringAmount, targetAmount, targetDate, startDate, recurringType]);

    const requestSuggestion = useCallback(
        async (suggestedField, value) => {
            // eslint-disable-next-line prefer-const
            let payload = {
                recurringType: recurringType.id, // Amount of each deposit.
                targetMoney: targetAmount?.value,
                startDate: startDate ? format(startDate, "dd/MM/yyyy") : "",
                endDate: targetDate ? format(targetDate, "dd/MM/yyyy") : "",
                suggestAmount: recurringAmount?.value,
                paraChange: suggestedField,
            };

            if (suggestedField === "recurringType") {
                payload.recurringType = value;
                payload.suggestAmount = null;
                payload.paraChange = null;
            }

            if (suggestedField === "startDate") {
                payload.suggestAmount = null;
                payload.paraChange = null;
            }

            const response = await accountService.suggestionGoalInfo(payload);
            if (unmounted.current) return;

            const { code, data } = response.data;

            if (code === RESPONSE_CODE.SUCCESS) {
                setSuggestingField(suggestedField);
                setShowSuggestion(true);
                setSuggestingData(data);

                previousFormState.current = {
                    recurringType, // Amount of each deposit.
                    targetMoney: targetAmount?.value,
                    startDate,
                    endDate: targetDate,
                    suggestAmount: recurringAmount?.value,
                };
                return;
            }

            if (code === RESPONSE_CODE.CANT_WORK_THIS_TIME) {
                dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                return;
            }

            GlobalDialogController.showError({
                errorCode: data.code,
            });
        },
        [
            recurringType,
            startDate,
            targetAmount,
            targetDate,
            recurringAmount,
            dialog,
        ]
    );

    const onBlurInputHandler = useCallback(
        (fieldName, value) => {
            // if (!isNotOnTrack) return;

            if (!isValidForm()) return;

            if (isNoContribute) return;

            if (!isFieldChange()) return;

            requestSuggestion(fieldName, value);
        },
        [isValidForm, isFieldChange, requestSuggestion, isNoContribute]
    );

    const onChangeTargetDate = useCallback(
        (d) => {
            setTargetDate(d ? new Date(d) : null);
            if (d) {
                onBlurInputHandler("endDate", new Date(d));
            }
        },
        [onBlurInputHandler]
    );

    const onTargetDateError = useCallback(
        (err) => {
            setErrors((value) => ({
                ...value,
                targetDate: Boolean(err),
                targetDateEmpty: !targetDate ? t("msg_we_need_this") : "",
            }));
        },
        [targetDate, t]
    );

    const onChangeStartDate = useCallback(
        (d) => {
            setStartDate(d ? new Date(d) : null);

            if (d) {
                onBlurInputHandler("startDate", new Date(d));
            }
        },
        [onBlurInputHandler]
    );

    const onStartDateError = useCallback(
        (err) => {
            if (isNoContribute) {
                setErrors((value) => ({
                    ...value,
                    startDate: Boolean(err),
                    startDateEmpty: "",
                }));
                return;
            }

            setErrors((value) => ({
                ...value,
                startDate: Boolean(err),
                startDateEmpty: !startDate ? t("msg_we_need_this") : "",
            }));
        },
        [isNoContribute, startDate, t]
    );

    const onTargetAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.targetAmount) {
                setErrors((prev) => ({
                    ...prev,
                    targetAmount: null,
                }));
            }

            if (!value) {
                setErrors((prev) => ({
                    ...prev,
                    targetAmount: t("msg_we_need_this"),
                }));
            } else if (value < GOAL_SAVE_MIN_CREATE) {
                setErrors((prev) => ({
                    ...prev,
                    targetAmount: t("gs_ms_goal_amount_is_invalid"),
                }));
            } else if (value > GOAL_SAVE_MAX_CREATE) {
                setErrors((prev) => ({
                    ...prev,
                    targetAmount: t("gs_ms_goal_amount_does_not_exceed"),
                }));
            }
            setTargetAmount({ formattedValue, value: +value });
        },
        [errors.targetAmount, t]
    );
    const onRecurringAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.recurringAmount) {
                setErrors((prev) => ({
                    ...prev,
                    recurringAmount: null,
                }));
            }

            if (isNoContribute) {
                return;
            }

            if (!value) {
                setErrors((prev) => ({
                    ...prev,
                    recurringAmount: t("msg_we_need_this"),
                }));
            } else if (value < 0) {
                setErrors((prev) => ({
                    ...prev,
                    recurringAmount: t("gs_recurring_amount_invalid"),
                }));
            } else if (value < minLimitPerTransfer) {
                setErrors((prev) => ({
                    ...prev,
                    recurringAmount: t(
                        "loan_vpbank_amount_required_min"
                    ).replace("%@", formatNumber(minLimitPerTransfer)),
                }));
            }
            setRecurringAmount({ formattedValue, value: +value });
        },
        [
            errors.recurringAmount,
            t,
            formatNumber,
            minLimitPerTransfer,
            isNoContribute,
        ]
    );

    const onRecurringTypeChange = ({ value }) => {
        if (errors.recurringType) {
            setErrors((prev) => ({
                ...prev,
                recurringType: null,
            }));
        }

        if (!value) {
            setErrors((prev) => ({
                ...prev,
                recurringType: t("msg_we_need_this"),
            }));
        }
        setRecurringType(value);

        // no contribute type > reset params
        if (value?.id === "") {
            setRecurringAmount({ formattedValue: null, value: null });
            setStartDate(null);
            return;
        }

        if (recurringAmount?.value && startDate) {
            onBlurInputHandler("recurringType", value?.id);
            return;
        }

        // have contribute type > required input below info
        if (!recurringAmount?.value) {
            setErrors((prev) => ({
                ...prev,
                recurringAmount: t("msg_we_need_this"),
            }));
        }

        if (!startDate) {
            setErrors((prev) => ({
                ...prev,
                startDateEmpty: t("msg_we_need_this"),
            }));
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isValidForm) {
            return;
        }

        // eslint-disable-next-line prefer-const
        let payload = {
            targetDate: format(targetDate, "dd/MM/yyyy"),
            targetAmount: targetAmount.value,
            recurringType,
        };

        if (!isNoContribute) {
            payload.recurringAmount = recurringAmount.value;
            payload.startDate = format(startDate, "dd/MM/yyyy");
        }

        onSave(payload);
    };

    const updateSuggestField = (fieldName, value) => {
        setShowSuggestion(false);
        setSuggestingData(null);
        setSuggestingField("");

        switch (fieldName) {
            case "endDate":
                previousFormState.current.endDate = value
                    ? parse(value, "dd/MM/yyyy", new Date())
                    : "";
                setTargetDate(
                    value ? parse(value, "dd/MM/yyyy", new Date()) : ""
                );
                break;
            case "targetMoney":
                previousFormState.current.targetMoney = value;
                setTargetAmount({
                    formattedValue: formatNumber(value),
                    value: +value,
                });
                break;
            case "suggestAmount":
                previousFormState.current.suggestAmount = value;
                setRecurringAmount({
                    formattedValue: formatNumber(value),
                    value: +value,
                });
                break;
            case "recurringType":
            default:
                break;
        }
    };

    const hideSuggestion = () => {
        setShowSuggestion(false);
    };

    return (
        <form onSubmit={onSubmit} className={classes.body}>
            <Box className={classes.actions}>
                <LspButtonIconLink
                    startIcon={<Icon className="font-icon icon-ok" />}
                    onClick={onSubmit}
                    color="success"
                    progressing={submitting}
                    type="submit"
                    disabled={!isValidForm()}
                    buttonProps={{
                        datatestid: "saveGoalBtn",
                    }}
                >
                    {t("spend_edit_payee_butt_save")}
                </LspButtonIconLink>
                <LspButtonIconLink
                    startIcon={<Icon className="font-icon icon-close" />}
                    onClick={onClose}
                    color="error"
                    buttonProps={{
                        datatestid: "cancelBtn",
                    }}
                >
                    {t("lb_cancel")}
                </LspButtonIconLink>
            </Box>
            <Box marginTop={2}>
                <Box position="relative">
                    <KeyboardDatePicker
                        TextFieldComponent={LspTextField}
                        error={errors.targetDate || !!errors.targetDateEmpty}
                        label={t("goalSave_lb_target_date")}
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        invalidDateMessage={t(
                            "kyc_one_date_of_birth_invalid_date_format"
                        )}
                        maxDateMessage={t("gs_msg_error_date_after_3_years")}
                        minDateMessage={t("gs_msg_error_date_after_3_years")}
                        value={targetDate}
                        onChange={onChangeTargetDate}
                        maxDate={maxTargetDate}
                        minDate={minDate}
                        onError={onTargetDateError}
                        onBlur={(e) =>
                            onBlurInputHandler("endDate", e.target.value)
                        }
                        inputProps={{
                            datatestid: "goalTargetDate",
                        }}
                    />

                    {!targetDate &&
                        !isNoContribute &&
                        !errors.targetDate &&
                        errors.targetDateEmpty && (
                            <FormHelperText
                                error
                                className={classes.emptyError}
                            >
                                {errors.targetDateEmpty}
                            </FormHelperText>
                        )}
                </Box>

                {showSuggestion && suggestingField === "endDate" && (
                    <GoalSaveDetailBoxSuggestion
                        onClose={hideSuggestion}
                        suggestingData={suggestingData}
                        updateSuggestField={updateSuggestField}
                        fieldName="endDate"
                    />
                )}

                {/* Target Amount */}

                <NumberFormat
                    value={targetAmount.formattedValue}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    onValueChange={onTargetAmountChange}
                    label={t("goalSave_lb_target_amount")}
                    error={!!errors.targetAmount}
                    helperText={errors.targetAmount || " "}
                    customInput={LspTextField}
                    allowLeadingZeros={false}
                    allowNegative={false}
                    allowedDecimalSeparators={false}
                    onBlur={(e) =>
                        onBlurInputHandler("targetMoney", e.target.value)
                    }
                    inputProps={{
                        datatestid: "goalTargetAmount",
                    }}
                />
                {showSuggestion && suggestingField === "targetMoney" && (
                    <GoalSaveDetailBoxSuggestion
                        onClose={hideSuggestion}
                        suggestingData={suggestingData}
                        updateSuggestField={updateSuggestField}
                        fieldName="targetMoney"
                    />
                )}

                <Box className={classes.halfColumn}>
                    {/* Recurring amount */}
                    <Box>
                        <NumberFormat
                            value={recurringAmount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onRecurringAmountChange}
                            label={t("gs_label_recurring_amount")}
                            error={!!errors.recurringAmount}
                            helperText={errors.recurringAmount || " "}
                            customInput={LspTextField}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            disabled={isNoContribute}
                            onBlur={(e) =>
                                onBlurInputHandler(
                                    "suggestAmount",
                                    e.target.value
                                )
                            }
                            inputProps={{
                                datatestid: "goalRecurringAmount",
                            }}
                        />
                    </Box>

                    {/* Recurring type */}

                    <Box>
                        <Autocomplete
                            options={recurringTypeList}
                            getOptionLabel={(option) => option?.label || ""}
                            value={recurringType}
                            onChange={(_, value) =>
                                onRecurringTypeChange({ value })
                            }
                            getOptionSelected={(option, value) =>
                                option?.id === value?.id
                            }
                            renderInput={(params) => {
                                const inputProps = params.InputProps;
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t("gs_label_frequency")}
                                        error={!!errors.recurringType}
                                        helperText={errors.recurringType || " "}
                                        InputProps={{
                                            ...inputProps,
                                            datatestid: "goalRecurringTypeItem",
                                        }}
                                    />
                                );
                            }}
                            disableClearable
                            datatestid="goalRecurringType"
                        />
                    </Box>
                </Box>

                {showSuggestion && suggestingField === "suggestAmount" && (
                    <GoalSaveDetailBoxSuggestion
                        onClose={hideSuggestion}
                        suggestingData={suggestingData}
                        updateSuggestField={updateSuggestField}
                        fieldName="suggestAmount"
                    />
                )}
                {showSuggestion && suggestingField === "recurringType" && (
                    <GoalSaveDetailBoxSuggestion
                        onClose={hideSuggestion}
                        suggestingData={suggestingData}
                        updateSuggestField={updateSuggestField}
                        fieldName="recurringType"
                    />
                )}

                {/* Recurring date */}

                <Box position="relative">
                    <KeyboardDatePicker
                        TextFieldComponent={LspTextField}
                        error={!!errors.startDate || !!errors.startDateEmpty}
                        label={t("gs_label_next_recurring")}
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        invalidDateMessage={t(
                            "kyc_one_date_of_birth_invalid_date_format"
                        )}
                        maxDateMessage={t("gs_recurring_date_invalid")}
                        minDateMessage={t("gs_recurring_date_invalid")}
                        value={startDate}
                        onChange={onChangeStartDate}
                        maxDate={maxStartDate}
                        minDate={minDate}
                        onError={onStartDateError}
                        disabled={isNoContribute}
                        onBlur={(e) =>
                            onBlurInputHandler("startDate", e.target.value)
                        }
                        inputProps={{
                            datatestid: "goalRecurringDate",
                        }}
                    />
                    {!startDate &&
                        !isNoContribute &&
                        !errors.startDate &&
                        errors.startDateEmpty && (
                            <FormHelperText
                                error
                                className={classes.emptyError}
                            >
                                {errors.startDateEmpty}
                            </FormHelperText>
                        )}
                </Box>
            </Box>
        </form>
    );
};

const stateProps = (state) => ({
    today: state.user.serverTime.value || new Date(),
    systemParams: state.systemParams.info,
});

export default memo(connect(stateProps)(GoalSaveDetailForm));
