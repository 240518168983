import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    Box,
    ButtonBase,
    Divider,
    Hidden,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import isFunction from "lodash/isFunction";
import useLanguage from "@helpers/useLanguage";
import layoutAction from "@redux/actions/layout";
import authAction from "@redux/actions/auth";
import { CloseIcon } from "@components/LspIcon";
import LspTypography from "@components/LspTypography";
import TermCondition from "@components/TermCondition";
import appAction from "@redux/actions/app";
import ThemeConfiguration from "@components/ThemeConfiguration";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

const useStyles = makeStyles((theme) => ({
    drawerCloseContainer: {
        justifyContent: "flex-end",
    },
    listWrapper: {
        paddingTop: 0,
        paddingRight: 0,
    },
    listItem: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
    },
    listItemIcon: {
        minWidth: theme.spacing(5),
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
    },
    minimumMenu: {
        marginLeft: theme.spacing(0.5),
    },
    listItemText: {
        "&.transparent": {
            marginLeft: theme.spacing(2),
        },
    },
    termCondition: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: "center",
        marginTop: theme.spacing(2),
        whiteSpace: "pre-line",
        "& > div": {
            textDecoration: "underline",
            color: "red",
        },
    },
    specificIcon: {
        marginRight: theme.spacing(1),
    },
    toggleBrightness: {
        "& svg": {
            color: theme.palette.primary.main,
        },
        marginRight: theme.spacing(1),
    },
    themeWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(2),
    },
    minimumThemeStyle: {
        flexDirection: "column",
        "& > button": {
            marginBottom: theme.spacing(2.75),
            marginRight: 0,
        },
    },
    hideItem: {
        display: "none",
    },
}));

const MenuContent = ({
    opened,
    logout,
    closeSideMenu,
    toggleDarkMode,
    isDarkMode,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const { switchLanguage } = useLanguage();
    const { pathname } = useLocation();
    const history = useHistory();

    const navigate = useCallback(
        (path) => {
            if (!path || pathname === path) {
                return;
            }
            history.push(path);
        },
        [history, pathname]
    );

    const openATMLocation = useCallback(() => {
        const url = t(`sysParamContent:find_location_web_${i18n.language}`);
        window.open(url, "_blank");
    }, [t, i18n.language]);

    const menuItems = useMemo(
        () => [
            {
                icon: "bank-details",
                label: "global_share_bank_detail_label",
                path: "/bank-information",
            },
            {
                icon: "card",
                label: "menu_lb_cards",
                path: "/card-management/debit",
                relativePaths: ["/card-management", "/card-management/credit"],
            },
            // {
            //     icon: "Lixi-Sentalixi",
            //     label: "menu_lb_deal",
            //     path: "/reward",
            //     isHide: !(sysParam.enableEvoucher === "true"),
            // },
            {
                icon: "edit1",
                label: "timo_plus_edit_profile",
                path: "/profile",
            },
            {
                icon: "securitylock",
                label: "menu_lb_security",
                path: "/security",
            },
            {
                icon: "requestdocs",
                label: "request_document_label",
                path: "/request-document/list",
            },
            {
                icon: "referral",
                label: "timo_plus_invite_friend",
                path: "/invitation/dashboard",
            },
            {
                icon: "location",
                label: "menu_lb_cdm_atm_locator",
                onClick: openATMLocation,
            },
            {
                icon: "feedback1",
                label: "feedback_label",
                path: "/feedback",
            },
            {
                icon: "call",
                label: "menu_lb_contact_timo",
                path: "/contact",
            },
            {
                icon: "faq1",
                label: "faq_label",
                path: "/faq",
            },
            {
                icon: "i18n",
                label: "timo_plus_switch_lang",
                onClick: switchLanguage,
            },
            {
                icon: "Logout",
                label: "nav_lb_btn_logout",
                onClick: logout,
                isHideInMinimum: true,
            },
        ],
        [logout, switchLanguage, openATMLocation]
    );

    const isSelected = useCallback(
        (item) => {
            if (pathname === item.path?.split("?")[0]) {
                return true;
            }
            if (Array.isArray(item.relativePaths)) {
                for (let i = 0; i < item.relativePaths.length; i++) {
                    const rPath = item.relativePaths[i];
                    if (pathname === rPath) {
                        return true;
                    }
                }
            }
            return false;
        },
        [pathname]
    );
    const logoutItem = useMemo(() => menuItems[menuItems?.length - 1], [
        menuItems,
    ]);

    // const darkModeItem = useMemo(() => {
    //     return (
    //         <Switch
    //             checked={isDarkMode}
    //             onChange={toggleDarkMode}
    //             color="primary"
    //             name="darkModeToggle"
    //             size="small"
    //         />
    //     );
    // }, [toggleDarkMode, isDarkMode]);

    // const getSpecificIcon = (name) => {
    //     switch (name) {
    //         case "darkMode":
    //             return darkModeItem;
    //         default:
    //             break;
    //     }
    // };

    return (
        <>
            <Hidden mdUp>
                <List>
                    <ListItem className={classes.drawerCloseContainer}>
                        <IconButton size="small" onClick={closeSideMenu}>
                            <CloseIcon />
                        </IconButton>
                    </ListItem>
                    <Divider />
                </List>
            </Hidden>
            <List
                classes={{
                    root: classes.listWrapper,
                }}
            >
                {menuItems.map(
                    (item) =>
                        !item.isHide && (
                            <ListItem
                                button
                                key={item.label}
                                onClick={() => {
                                    if (isSmDown) {
                                        closeSideMenu();
                                    }
                                    if (isFunction(item.onClick)) {
                                        item.onClick();
                                        return;
                                    }
                                    navigate(item.path);
                                }}
                                selected={isSelected(item)}
                                className={clsx(classes.listItem, {
                                    [classes.hideItem]:
                                        !!item?.isHideInMinimum && !opened,
                                })}
                            >
                                {!item?.isSpecific && (
                                    <ListItemIcon
                                        className={clsx(classes.listItemIcon, {
                                            [classes.minimumMenu]: !opened,
                                        })}
                                    >
                                        {item.icon === "i18n" ? (
                                            <Icon
                                                className={`font-icon icon-${i18n.language.toUpperCase()}`}
                                            />
                                        ) : (
                                            <Icon
                                                className={`font-icon icon-${item.icon}`}
                                            />
                                        )}
                                    </ListItemIcon>
                                )}

                                {/* {item?.isSpecific && (
                            <Box className={classes.specificIcon}>
                                {getSpecificIcon(item?.name)}
                            </Box>
                        )} */}

                                <ListItemText
                                    className={clsx(classes.listItemText, {
                                        transparent: !opened,
                                    })}
                                    variant="body2"
                                    primary={
                                        <LspTypography variant="body1">
                                            {t(item.label)}
                                        </LspTypography>
                                    }
                                />
                            </ListItem>
                        )
                )}

                {opened && (
                    <div className={classes.termCondition}>
                        <TermCondition
                            content="$KEY.T&C"
                            keyList={[
                                [
                                    "$KEY.T&C",
                                    t("ekyc:timo_tnc_short"),
                                        t("ekyc:timo_tnc_link"),
                                ],
                            ]}
                            type="DIALOG"
                        />

                        <TermCondition
                            content="$KEY.PRIVACY.TNC_TIMO"
                            keyList={[
                                [
                                    "$KEY.PRIVACY.TNC_TIMO",
                                    t("ekyc:ekyc_tnc"),
                                    t("ekyc:ekyc_tnc_link_v2"),
                                ],
                            ]}
                            type="NEW_TAB"
                        />

                        <TermCondition
                            content="$KEY.PRIVACY.POLICY"
                            keyList={[
                                [
                                    "$KEY.PRIVACY.POLICY",
                                    t("ekyc:privacy_data_policy"),
                                        t("ekyc:privacy_data_policy_link"),
                                ],
                            ]}
                            type="DIALOG"
                        />
                    </div>
                )}

                <Box
                    className={clsx(classes.themeWrapper, {
                        [classes.minimumThemeStyle]: !opened,
                    })}
                >
                    <ButtonBase
                        className={classes.toggleBrightness}
                        onClick={toggleDarkMode}
                    >
                        <Tooltip title={t("lb_hint_toggle_brightness")}>
                            {isDarkMode ? (
                                <Brightness7Icon />
                            ) : (
                                <Brightness4Icon />
                            )}
                        </Tooltip>
                    </ButtonBase>
                    <Box width="auto">
                        <ThemeConfiguration minimum />
                    </Box>
                </Box>

                {!opened && (
                    <Box marginTop={2.5}>
                        <ListItem
                            button
                            onClick={() => {
                                if (isSmDown) {
                                    closeSideMenu();
                                }
                                if (isFunction(logoutItem.onClick)) {
                                    logoutItem.onClick();
                                }
                            }}
                            className={classes.listItem}
                        >
                            <ListItemIcon
                                className={`${classes.listItemIcon} ${classes.minimumMenu}`}
                            >
                                <Icon
                                    className={`font-icon icon-${logoutItem.icon}`}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </Box>
                )}
            </List>
        </>
    );
};

const mapState = (state) => ({
    opened: state.layout.sideMenuOpened,
    isDarkMode:
        state.app.isDarkMode === true || state.app.isDarkMode === "true",
});

const mapDispatch = (dispatch) => ({
    logout: () => dispatch(authAction.logoutRequest()),
    closeSideMenu: () => dispatch(layoutAction.closeSideMenu()),
    toggleDarkMode: () => dispatch(appAction.switchPaletteType()),
});

export default connect(mapState, mapDispatch)(MenuContent);
