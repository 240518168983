import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Content from "@components/Content";
import { makeStyles, Paper } from "@material-ui/core";
import {
    MoveMoneyType,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
} from "@config/constants";
import LspTypography from "@components/LspTypography";
import moveMoneyService from "@services/move-money";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AlertController from "@helpers/controllers/AlertController";
import MoveMoneyChannelSelect from "../MoveMoneyChannelSelect";
import AddBankAccountDestination from "./AddBankAccountDestination";
import AddMemberDestination from "./AddMemberDestination";
import TransferConfirmationDialog, {
    TransferConfirmationDialogType,
} from "../TransferConfirmationDialog";
import AddCardDestination from "./AddCardDestination";
import CreditCardTransfer from "../CreditCardTransfer";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
    },
    title: {
        textAlign: "center",
        textTransform: "uppercase",
        marginBottom: theme.spacing(2),
    },
}));

const PayeeAddDestination = () => {
    const classes = useStyles();

    const { t } = useTranslation();
    const { payeeId, hasTimoMember } = useParams();
    const [loading, setLoading] = useState(false);

    const [selectedChannel, setSelectedChannel] = useState("");
    const unmounted = useRef(false);

    const history = useHistory();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onSelectChannel = useCallback((channel) => {
        setSelectedChannel(channel);
    }, []);

    const onCloseWarning = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const goToSpecificPayee = useCallback(
        (id) => {
            history.push(
                `/spend-account/${SpendAccountNavigationKey.MoveMoney}?payeeId=${id}`
            );
        },
        [history]
    );

    const goToExistingDestination = useCallback(
        (id) => {
            onCloseWarning();
            goToSpecificPayee(id);
        },
        [onCloseWarning, goToSpecificPayee]
    );

    const goBackToPayeeList = () => {
        history.goBack();
    };

    const showDuplicateDestinationWarning = useCallback(
        (info) => {
            GlobalDialogController.show({
                component: () => (
                    <TransferConfirmationDialog
                        open
                        payeeAvatar={info?.avatar}
                        payeeName={info?.nickName}
                        type={
                            TransferConfirmationDialogType.DestinationExistInPayee
                        }
                        progressing={loading}
                        onConfirm={() => goToExistingDestination(info?.payeeId)}
                        onCancel={() => {
                            onCloseWarning();
                        }}
                    />
                ),
                onClose: onCloseWarning,
            });
        },
        [loading, onCloseWarning, goToExistingDestination]
    );

    const addDestinationRequest = useCallback(
        async (info, manualType) => {
            let payload = {};

            const typeTransfer = manualType || selectedChannel;

            if (typeTransfer === MoveMoneyType.DebitCard) {
                payload = {
                    payeeId: parseInt(payeeId),
                    destinations: [
                        {
                            bankName:
                                info?.card?.bankName || info?.bank?.bankName,
                            cardNumber:
                                info?.card?.cardNumber || info?.targetInfo,
                            bankShortName:
                                info?.card?.bankShortName ||
                                info?.bank?.bankShortName,
                            bankId: info?.card?.bankId,
                            description:
                                info?.card?.bankShortName ||
                                info?.bank?.bankShortName,
                            nameOnCard:
                                info?.card.cardName || info?.bank?.accountName,
                            cardName:
                                info?.card.cardName || info?.bank?.accountName,
                            desType: 2,
                        },
                    ],
                };
            } else if (typeTransfer === MoveMoneyType.Internal) {
                payload = {
                    payeeId: parseInt(payeeId),
                    destinations: [
                        {
                            desType: 1,
                            accountName:
                                info?.bank?.accountName || info?.card?.cardName,
                            accountNumber:
                                info?.bank?.accountNumber ||
                                info?.card?.cardNumber,
                            bankId: info?.bank?.bankId,
                            bankShortName:
                                info?.bank?.bankShortName ||
                                info?.card?.bankShortName,
                            bankName: info?.bank?.bankName,
                            description:
                                info?.bank?.bankShortName ||
                                info?.card?.bankShortName,
                        },
                    ],
                };
            } else {
                const destinationDetail = {
                    desType: 1,
                    accountName: info?.accountName,
                    accountNumber: info?.accountNumber,
                    bankId: info?.bankId,
                    bankName: info?.bankName,
                    bankShortName: info?.bankShortName,
                };

                if (info.branchId) {
                    destinationDetail.branchId = info.branchId;
                }

                if (info.branchName) {
                    destinationDetail.branchName = info.branchName;
                }
                if (info.provinceId) {
                    destinationDetail.provinceId = info.provinceId;
                }
                if (info.provinceName) {
                    destinationDetail.province = info.provinceName;
                }

                payload = {
                    payeeId: parseInt(payeeId),
                    destinations: [destinationDetail],
                };
            }

            setLoading(true);

            const response = await moveMoneyService.addDestination(payload);

            if (unmounted.current) {
                return;
            }

            const { code, data } = response.data;
            setLoading(false);

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                case RESPONSE_CODE.CREATED:
                    AlertController.show(t("master:add_des_success"));
                    goToSpecificPayee(payeeId);
                    break;
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_CURRENT_PAYEE:
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO:
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST:
                    showDuplicateDestinationWarning(data);
                    break;
                case RESPONSE_CODE.EXISTING_DESTINATION:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Warning",
                            header: t("oops"),
                            content: t("ep_lb_destination_duplicate_error"),
                            button: t("lb_ok"),
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [
            selectedChannel,
            t,
            payeeId,
            goToSpecificPayee,
            showDuplicateDestinationWarning,
        ]
    );

    const checkExistingDestination = async (info) => {
        setLoading(true);

        let payload = {};

        if (selectedChannel === MoveMoneyType.Internal) {
            payload = {
                accountNumber: info.targetInfo,
            };
        } else if (selectedChannel === MoveMoneyType.DebitCard) {
            payload = {
                accountNumber: info.targetInfo,
            };
        } else {
            // bank: no need to check existing destination
        }

        const response = await moveMoneyService.checkPayeeDestinationExistence(
            payload
        );
        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.DESTINATION_NOT_EXISTING:
                addDestinationRequest(info);
                break;
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_CURRENT_PAYEE:
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO:
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST:
                setLoading(false);
                showDuplicateDestinationWarning(data);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const title = useMemo(() => {
        switch (selectedChannel) {
            case MoveMoneyType.Internal:
                return t("master:payee_add_timo_member_header");
            case MoveMoneyType.DebitCard:
                return t("master:payee_add_card_header");
            case MoveMoneyType.BankAccount:
                return t("master:payee_add_bank_account_header");
            case MoveMoneyType.Credit:
                return t("master:payee_add_credit_card_header");
            default:
                return t("master:add_new_des");
        }
    }, [selectedChannel, t]);

    return (
        <Content size="md">
            <LspTypography variant="title2" className={classes.title}>
                {title}
            </LspTypography>
            <MoveMoneyChannelSelect
                onSelectFunc={onSelectChannel}
                isDisabledMember={hasTimoMember}
                description={
                    hasTimoMember === "true"
                        ? t("payee_msg_only_one_timo_destination")
                        : ""
                }
                hidePAO
            />
            {!!selectedChannel && (
                <>
                    {selectedChannel !== MoveMoneyType.Credit && (
                        <Paper classes={{ root: classes.paper }}>
                            {selectedChannel === MoveMoneyType.Internal && (
                                <AddMemberDestination
                                    onCancel={goBackToPayeeList}
                                    onSave={checkExistingDestination}
                                    loading={loading}
                                />
                            )}
                            {selectedChannel === MoveMoneyType.DebitCard && (
                                <AddCardDestination
                                    onCancel={goBackToPayeeList}
                                    onSave={checkExistingDestination}
                                    loading={loading}
                                />
                            )}
                            {selectedChannel === MoveMoneyType.BankAccount && (
                                <AddBankAccountDestination
                                    onCancel={goBackToPayeeList}
                                    onSave={addDestinationRequest}
                                    loading={loading}
                                />
                            )}
                        </Paper>
                    )}

                    {selectedChannel === MoveMoneyType.Credit && (
                        <CreditCardTransfer
                            addingPayee
                            onCancel={goBackToPayeeList}
                            onSave={addDestinationRequest}
                            addPayeeLoading={loading}
                        />
                    )}
                </>
            )}
        </Content>
    );
};

export default PayeeAddDestination;
