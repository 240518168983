import { LANGUAGE_OPTIONS } from "@config/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useNumber = () => {
    const { i18n, t } = useTranslation();
    const [thousandSeparator, setThousandSeparator] = useState(".");
    const [decimalSeparator, setDecimalSeparator] = useState(",");

    const formatNumber = useCallback(
        /**
         * Add thousand separator to the number
         * @param {number|string} input - the number to be formatted
         * @param {boolean} [positiveSign=false] - add the "+" before positive number
         * @param {boolean} [currencyLabel=false] - add vnd in formatNumber
         * @returns {number|string} formatted number
         */
        (input = "", positiveSign = false, currencyLabel = false) => {
            if (input === 0 || input === "0") {
                return "0";
            }
            if (
                !input ||
                !(typeof input === "string" || typeof input === "number")
            ) {
                return "";
            }

            const locale =
                i18n.language === LANGUAGE_OPTIONS.EN ? "en-EN" : "vi-VN";

            const valid = /\d+\.?\d*/.test(input);

            if (!valid) {
                return NaN;
            }

            const formattedNumber = new Intl.NumberFormat(locale).format(input);

            if (positiveSign && input <= 0) {
                return `+${formattedNumber}`;
            }

            if (currencyLabel) {
                return i18n.language === LANGUAGE_OPTIONS.EN
                    ? `VND ${formattedNumber}`
                    : `${formattedNumber} VND`;
            }

            return formattedNumber;
        },
        [i18n]
    );

    const parseNumber = useCallback(
        /**
         * Remove thousand separator from the number
         * @param {string|number} input - the number to be parsed
         * @param {boolean} [returnNumber=true] - type of return value, default to true
         * @returns {number|string} formatted number
         */
        (input, returnNumber = true) => {
            if (input) {
                if (returnNumber)
                    return parseInt(input.toString().replace(/\D+/g, ""));
                return input.toString().replace(/\D+/g, "");
            }
            return returnNumber ? 0 : "0";
        },
        []
    );

    const formatCardNumber = useCallback(
        /**
         * Format the number as Debit/Credit Card number, eg: `XXXX XXXX XXXX XXXX`
         * @param {string|number} input - the number to be formatted
         * @returns {string} formatted number
         */
        (input, isMarkXString) => {
            if (
                !input ||
                !(typeof input === "string" || typeof input === "number")
            ) {
                return "";
            }
            const numberOnly = input.toString().replace(/\D+/g, "");
            const string = isMarkXString ? input.toString() : numberOnly;

            const output = string
                .split("")
                .reduce((accumulator, currentValue, currentIndex, arr) => {
                    if (
                        (currentIndex + 1) % 4 === 0 &&
                        currentIndex + 1 < arr.length
                    ) {
                        return `${accumulator}${currentValue} `;
                    }
                    return `${accumulator}${currentValue}`;
                }, "");

            return output;
        },
        []
    );

    const formatUnit = useCallback(
        (unit = "") => {
            const specialRatesLength = parseFloat(unit).toString().length;
            let amount = 0;

            switch (specialRatesLength) {
                case 12:
                    amount = unit.substr(0, 3);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_billion"
                    )}`;
                case 11:
                    amount = unit.substr(0, 2);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_billion"
                    )}`;

                case 10:
                    amount = unit.substr(0, 1);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_billion"
                    )}`;
                case 9:
                    amount = unit.substr(0, 3);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_million"
                    )}`;
                case 8:
                    amount = unit.substr(0, 2);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_million"
                    )}`;
                case 7:
                    amount = unit.substr(0, 1);
                    return `${amount} ${t(
                        "TD:td_special_rate_number_unit_million"
                    )}`;

                default:
                    amount = formatNumber(unit);
                    return amount;
            }
        },
        [formatNumber, t]
    );

    useEffect(() => {
        switch (i18n.language) {
            case "en":
                setThousandSeparator(",");
                setDecimalSeparator(".");
                break;
            case "vn":
            default:
                setThousandSeparator(".");
                setDecimalSeparator(",");
                break;
        }
    }, [i18n.language]);

    const numberFormat = useMemo(
        () => ({
            formatNumber,
            parseNumber,
            formatCardNumber,
            thousandSeparator,
            decimalSeparator,
            formatUnit,
        }),
        [
            formatNumber,
            parseNumber,
            formatCardNumber,
            thousandSeparator,
            decimalSeparator,
            formatUnit,
        ]
    );

    return numberFormat;
};

export default useNumber;
