import produce from "immer";
import { LspReceiptActionType } from "../actions/lsp-receipt";
import { FetchStatus } from "../../config/constants";

/* ------------- Initial State ------------- */
const initialState = {
    loading: false,
    info: null,
    notes: {
        fetching: false,
        data: undefined,
        status: null,
    },
    paymentDetail: {
        fetching: false,
        data: undefined,
        status: null,
    },
};

/* ------------- Reducer ------------- */
const lspReceiptReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LspReceiptActionType.GetReceipt:
                draft.loading = true;
                draft.info = initialState.info;
                break;
            case LspReceiptActionType.GetReceiptSuccess:
                draft.info = action.payload;
                draft.loading = false;
                break;
            case LspReceiptActionType.GetReceiptError:
                draft.loading = false;
                draft.info = null;
                break;
            case LspReceiptActionType.Reset:
                draft.loading = initialState.loading;
                draft.info = initialState.info;
                break;
            case LspReceiptActionType.SubmitNotes:
                draft.notes.fetching = true;
                draft.notes.status = FetchStatus.Pending;
                break;
            case LspReceiptActionType.SubmitNotesSuccess:
                draft.notes.fetching = false;
                draft.notes.status = FetchStatus.Success;
                draft.notes.data = action.payload;
                break;
            case LspReceiptActionType.SubmitNotesError:
                draft.notes.fetching = false;
                draft.notes.status = FetchStatus.Error;
                break;
            case LspReceiptActionType.ResetNotes:
                draft.notes = initialState.notes;
                break;

            case LspReceiptActionType.GetPaymentDetailRequest:
                draft.paymentDetail.fetching = true;
                draft.paymentDetail.status = FetchStatus.Pending;
                draft.paymentDetail.data = undefined;
                break;
            case LspReceiptActionType.GetPaymentDetailSuccess:
                draft.paymentDetail.fetching = false;
                draft.paymentDetail.status = FetchStatus.Success;
                draft.paymentDetail.data = action.payload;
                break;
            case LspReceiptActionType.GetPaymentDetailError:
                draft.paymentDetail.fetching = false;
                draft.paymentDetail.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default lspReceiptReducer;
