import { RESPONSE_CODE } from "@config/constants";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import userService from "@services/user";
import { isFunction } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import LspTextField from "./LspTextField";

/**
 * @param {object} props
 * @param {object} props.selected
 * @param {func} props.onChange
 * @param {string} props.error
 * @param {string} props.typeData
 * @param {string} props.parentId
 * @param {string} props.label
 * @param {bool} props.pendingLoad
 */
const LspDataDropdown = ({
    selectedData,
    onChangeData,
    error,
    typeData,
    parentId,
    labelData,
    pendingLoad,
    disabled,
    ...restProps
}) => {
    const [dataList, setDataList] = useState(null);
    const [fetching, setFetching] = useState(false);
    const unmounted = useRef(false);

    // Remove some unneeded props
    const {
        selectedProvince,
        selectedDistrict,
        selectedWard,
        errors,
        isHideWard,
        disabledAll,
        halfWidth,
        ...otherProps
    } = restProps;

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onChangeDataHandler = ({ value }) => {
        if (onChangeData && isFunction(onChangeData)) {
            onChangeData(
                value
                    ? {
                          cfgValue: value?.cfgValue,
                          cfgKey: value?.cfgKey,
                      }
                    : {
                          cfgValue: "",
                          cfgKey: "",
                      }
            );
        }
    };

    const getDataList = useCallback(async (group, paId) => {
        setFetching(true);

        const response = await userService.getDataList({
            group,
            configKey: "",
            parentId: paId,
        });

        setFetching(false);

        if (unmounted.current) return;

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setDataList(data);
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        if (!pendingLoad && typeData) {
            getDataList(typeData, parentId);
        }
    }, [getDataList, typeData, parentId, pendingLoad]);

    return (
        <Autocomplete
            style={{ width: "100%" }}
            options={dataList || []}
            getOptionLabel={(option) => option?.cfgValue || ""}
            onChange={(_, value) => onChangeDataHandler({ value })}
            getOptionSelected={(option, value) =>
                option?.cfgKey === value?.cfgKey || {}
            }
            value={selectedData || {}}
            renderInput={(params) => {
                const inputProps = fetching
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <CircularProgress size={16} />
                              </InputAdornment>
                          ),
                      }
                    : params.InputProps;
                return (
                    <LspTextField
                        {...params}
                        label={labelData}
                        error={!!error}
                        helperText={error || " "}
                        InputProps={inputProps}
                    />
                );
            }}
            disabled={disabled || fetching}
            {...otherProps}
        />
    );
};

LspDataDropdown.propTypes = {
    selectedData: PropTypes.shape({
        cfgKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cfgValue: PropTypes.string,
    }),
    onChangeData: PropTypes.func.isRequired,
    error: PropTypes.string,
    typeData: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    labelData: PropTypes.string.isRequired,
    pendingLoad: PropTypes.bool,
};

LspDataDropdown.defaultProps = {
    selectedData: null,
    error: "",
    parentId: "",
    pendingLoad: false,
};

export default memo(LspDataDropdown);
