import { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import format from "date-fns/format";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
import { TransactionGroupType } from "@config/constants";
import accountAction from "@redux/actions/account";
import TransactionListLoading from "@components/TransactionList/TransactionListLoading";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import Content from "@components/Content";
import useAccounts from "@helpers/useAccounts";

const GoalSaveTransaction = ({
    getTransactionList,
    transactionListFetching,
    transactionList,
    txnDateIndex,
    xidIndex,
    hasMore,
    serverTime,
    serverTimeFetching,
}) => {
    const { t } = useTranslation("translation", "payAnyone");
    const [filter, setFilter] = useState(null);

    const { goalSave } = useAccounts();

    const firstLoaded = useRef(false);

    const getTransactionHistory = useCallback(
        ({ pagingData, filterData } = {}) => {
            if (!goalSave || !serverTime) {
                return;
            }

            const payload = {
                accountNo: goalSave.no,
                accountType: goalSave.accountType,
                fromDate: filterData?.fromDate || "01/01/2015",
                toDate: filterData?.toDate || format(serverTime, "dd/MM/yyyy"),
            };
            if (filterData) {
                payload.filter = filterData;
            }
            if (pagingData?.txnDateIndex && pagingData.xidIndex) {
                payload.txnDateIndex = pagingData.txnDateIndex;
                payload.xidIndex = pagingData.xidIndex;
            }
            getTransactionList(payload);
        },
        [serverTime, goalSave, getTransactionList]
    );

    useEffect(() => {
        if (!serverTimeFetching && !firstLoaded.current) {
            firstLoaded.current = true;
            getTransactionHistory();
        }
    }, [serverTimeFetching, getTransactionHistory]);

    useEffect(() => {
        getTransactionHistory({ filterData: filter });
    }, [filter, getTransactionHistory]);

    const loadMore = () => {
        if (
            transactionListFetching ||
            serverTimeFetching ||
            !firstLoaded.current
        ) {
            return;
        }
        getTransactionHistory({
            filterData: filter,
            pagingData: {
                txnDateIndex,
                xidIndex,
            },
        });
    };

    const infiniteRef = useInfiniteScroll({
        loading: transactionListFetching,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    return (
        <Content>
            {!hasMore &&
                transactionList?.length === 0 &&
                !transactionListFetching && (
                    <Box paddingY={4} textAlign="center">
                        <Typography variant="body2">
                            {t("spend_dont_have_transactions_yet")}
                        </Typography>
                    </Box>
                )}
            <div ref={infiniteRef}>
                {transactionList?.map((txnGroup) => {
                    const key = `${txnGroup.dispDate}-${txnGroup.item.length}`;
                    return (
                        <TransactionGroup
                            key={key}
                            type={TransactionGroupType.GoalSave}
                            header={txnGroup.dispDate}
                            items={txnGroup.item}
                            onItemClick={null}
                        />
                    );
                })}
            </div>
            {hasMore && <TransactionListLoading />}
        </Content>
    );
};

const mapState = (state) => ({
    transactionList: state.account.spendAccount.transactionList,
    transactionListFetching: state.account.spendAccount.fetching,
    txnDateIndex: state.account.spendAccount.txnDateIndex,
    xidIndex: state.account.spendAccount.xidIndex,
    hasMore: state.account.spendAccount.hasMore,
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(accountAction.getSpendAccountTransactionListRequest(payload)),
});

export default connect(mapState, mapDispatch)(GoalSaveTransaction);
