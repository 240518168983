import { Box, Icon, makeStyles } from "@material-ui/core";
import { ArrowLeftIcon } from "@components/LspIcon";
import PropTypes from "prop-types";
import LspButtonIconLink from "@components/LspButtonIconLink";
import LspButton from "@components/LspButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& > div": {
            width: "100%",
            textAlign: "center",
        },
        "& > div:nth-child(2)": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
            "& button": {
                width: "100%",
            },
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            "& > div:nth-child(1)": {
                width: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
            },
            "& > div:nth-child(2)": {
                width: "60%",
                textAlign: "center",
                marginTop: 0,
                marginBottom: 0,
            },
            "& > div:nth-child(3)": {
                width: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
            },
        },
    },
    centerBtn: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "60%",
        },
    },
}));

/**
 * @typedef FooterControlActionsProps
 * @type {object}
 * @property {object} leftButton
 * @property {func} leftButton.onClick
 * @property {string} leftButton.label
 * @property {object} centerButton
 * * @property {func} centerButton.onClick
 * @property {string} centerButton.label
 * @property {string} centerButton.type
 * @property {object} rightButton
 * * @property {func} rightButton.onClick
 * @property {string} rightButton.label
 * @property {string} wrapperClasses
 * @property {bool} disabled
 * @property {bool} progressing
 */

/**
 * @param {FooterControlActionsProps} props
 */

const FooterControlActions = ({
    leftButton,
    centerButton,
    rightButton,
    wrapperClasses,
    disabled,
    progressing,
}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.footer, {
                [wrapperClasses]: !!wrapperClasses,
            })}
        >
            <Box>
                {leftButton && (
                    <LspButtonIconLink
                        startIcon={<ArrowLeftIcon />}
                        onClick={leftButton?.onClick}
                        color="default"
                        buttonProps={{
                            datatestid: "leftBtn",
                        }}
                    >
                        {leftButton?.label}
                    </LspButtonIconLink>
                )}
            </Box>
            <Box>
                {centerButton && (
                    <LspButton
                        type={centerButton?.type}
                        onClick={centerButton?.onClick}
                        disabled={disabled}
                        progressing={progressing}
                        className={classes.centerBtn}
                        buttonProps={{
                            datatestid: "centerBtn",
                        }}
                    >
                        {centerButton?.label}
                    </LspButton>
                )}
            </Box>
            <Box>
                {rightButton && (
                    <LspButtonIconLink
                        startIcon={<Icon className="font-icon icon-close" />}
                        onClick={rightButton?.onClick}
                        color="default"
                        buttonProps={{
                            datatestid: "rightBtn",
                        }}
                    >
                        {rightButton?.label}
                    </LspButtonIconLink>
                )}
            </Box>
        </div>
    );
};

FooterControlActions.propTypes = {
    leftButton: PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string,
    }),
    centerButton: PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string,
        type: PropTypes?.string,
    }),
    rightButton: PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    progressing: PropTypes.bool,
};
FooterControlActions.defaultProps = {
    leftButton: null,
    centerButton: null,
    rightButton: null,
    disabled: false,
    progressing: false,
};

export default FooterControlActions;
