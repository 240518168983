import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Content from "@components/Content";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import pendingTaskService from "@services/pending-task";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import InfiniteScroll from "react-infinite-scroll-component";

import PaymentRequestActions from "@redux/actions/payment-request";
import { connect } from "react-redux";
import Booking from "@containers/Booking";
import { BOOKING_ACTIONS } from "@containers/Booking/constant";
import PendingTaskItem from "./PendingTaskItem";
import PendingTaskLoading from "./PendingTaskLoading";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[3],
        boxShadow: theme.shadows[3],
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        background: theme.palette.background.paper,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
        },
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    description: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 64,
        },
        "& li::marker": {
            content: "•",
            color: theme.palette.primary.main,
        },
    },
    actions: {
        display: "flex",
        marginTop: theme.spacing(2),
        justifyContent: "space-between",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            justifyContent: "flex-end",
        },
    },
    multipleButton: {
        width: `calc(50% - 8px)`,
        [theme.breakpoints.up("sm")]: {
            width: "auto",
            marginLeft: theme.spacing(2),
        },
    },
}));

const pendingListInit = null;

const PendingTask = ({ paymentStatus, resetStatePaymentRequest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const unmounted = useRef(false);
    const [dataPagination, setDataPagination] = useState({
        lastTaskId: "",
        modifiedAtIndex: "",
    });
    const [hasMore, setHasMore] = useState(true);
    const isRefreshing = useRef(false);

    const [pendingList, setPendingList] = useState(pendingListInit);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getPendingTask = useCallback(async (payload = null) => {
        const response = await pendingTaskService.getPendingTask(payload);

        if (unmounted.current) return;
        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setDataPagination({
                    lastTaskId: data.lastTaskId,
                    modifiedAtIndex: data.modifiedAtIndex,
                });
                setPendingList((prevPendingList) => {
                    if (!prevPendingList) return data?.taskList;

                    return [...prevPendingList, ...data?.taskList];
                });
                if (data?.taskList.length === 0) {
                    setHasMore(false);
                }

                isRefreshing.current = false;
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, []);

    const loadMorePendingTask = useCallback(() => {
        getPendingTask(dataPagination);
    }, [dataPagination, getPendingTask]);

    const refreshPendingList = useCallback(() => {
        if (isRefreshing.current) return;
        setPendingList(pendingListInit);
        getPendingTask();
        isRefreshing.current = true;
    }, [getPendingTask]);

    useEffect(() => {
        getPendingTask();
    }, [getPendingTask]);

    // Refresh pending list after pay a payment request successfully
    useEffect(() => {
        if (paymentStatus) {
            resetStatePaymentRequest();
            GlobalDialogController.hide();
            refreshPendingList();
        }
    }, [paymentStatus, resetStatePaymentRequest, refreshPendingList]);

    const [isBookingView, setIsBookingView] = useState(false);
    const [bookingConfig, setBookingConfig] = useState({});

    const onCancelBooking = () => {
        setIsBookingView(false);
    };

    const openNewBooking = (id, serviceName) => {
        setBookingConfig({
            serviceName,
            type: BOOKING_ACTIONS.NEW_BOOKING,
            id,
        });
        setIsBookingView(true);
    };

    const onViewBooking = (id, serviceName) => {
        setBookingConfig({
            serviceName,
            type: BOOKING_ACTIONS.VIEW_BOOKING,
            id,
        });
        setIsBookingView(true);
    };

    const onChangeBooking = (id, serviceName) => {
        setBookingConfig({
            serviceName,
            type: BOOKING_ACTIONS.CHANGE_BOOKING,
            id,
        });
        setIsBookingView(true);
    };

    const onCompleteBooking = () => {
        setBookingConfig({});
        refreshPendingList();
        setIsBookingView(false);
    };

    return (
        <>
            <Banner title={t("outstanding_tasks_header")} />
            <ContentWrapper>
                <Content>
                    {!isBookingView && (
                        <InfiniteScroll
                            dataLength={pendingList?.length || 0}
                            next={loadMorePendingTask}
                            hasMore={hasMore}
                            loader={<PendingTaskLoading className={classes} />}
                        >
                            {pendingList?.map((item) => {
                                return (
                                    <PendingTaskItem
                                        item={item}
                                        key={item.taskId}
                                        className={classes}
                                        refreshList={refreshPendingList}
                                        openNewBooking={openNewBooking}
                                        onViewBooking={onViewBooking}
                                        onChangeBooking={onChangeBooking}
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    )}
                    {isBookingView && (
                        <Booking
                            serviceName={bookingConfig?.serviceName}
                            type={bookingConfig?.type}
                            id={bookingConfig?.id}
                            onCancel={onCancelBooking}
                            onComplete={onCompleteBooking}
                            onChangeBooking={onChangeBooking}
                        />
                    )}
                </Content>
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    paymentStatus: state.paymentRequest.status,
});

const mapDispatch = (dispatch) => ({
    resetStatePaymentRequest: () =>
        dispatch(PaymentRequestActions.resetState()),
});

export default memo(connect(mapState, mapDispatch)(PendingTask));
