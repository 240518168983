import { memo, useCallback, useEffect, useState } from "react";
import { Slide, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";

const SlideTransition = (props) => {
    return <Slide {...props} direction="up" />;
};

const anchorOrigin = { horizontal: "center", vertical: "bottom" };

const NetworkDetector = () => {
    const [opened, setOpened] = useState(false);
    const [severity, setSeverity] = useState("warning");
    const [message, setMessage] = useState("");
    const [icon, setIcon] = useState(null);
    const [autoHideDuration, setAutoHideDuration] = useState(null);
    const [key, setKey] = useState(null);

    const onClose = useCallback(() => {
        setOpened(false);
    }, []);

    const handleConnectionChange = useCallback(() => {
        setOpened(true);
        if (navigator.onLine) {
            setKey(new Date().getMilliseconds());
            setSeverity("success");
            setIcon(<WifiIcon />);
            setMessage("Your internet connection is back.");
            setAutoHideDuration(5000);
        } else {
            setKey(new Date().getMilliseconds());
            setSeverity("warning");
            setIcon(<WifiOffIcon />);
            setMessage("You are currently offline.");
            setAutoHideDuration(null);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("online", handleConnectionChange);
        window.addEventListener("offline", handleConnectionChange);
        return () => {
            window.removeEventListener("online", handleConnectionChange);
            window.removeEventListener("offline", handleConnectionChange);
        };
    }, [handleConnectionChange]);

    return (
        <Snackbar
            key={key}
            open={opened}
            anchorOrigin={anchorOrigin}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            ClickAwayListenerProps={{ onClickAway: () => {} }}
            TransitionComponent={SlideTransition}
            disableWindowBlurListener
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                severity={severity}
                icon={icon}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default memo(NetworkDetector);
