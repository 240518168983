import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { makeStyles, CircularProgress, IconButton } from "@material-ui/core";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE } from "@config/constants";
import manageDeviceService from "@services/manage-device";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import authAction from "@redux/actions/auth";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

const useStyles = makeStyles((theme) => ({
    deviceItem: {
        padding: theme.spacing(1),
        borderTop: `2px solid #dbdbdb`,
        display: "flex",
        fontSize: theme.typography.pxToRem(14),
        alignItems: "center",
        "&:last-child": {
            borderBottom: `2px solid #dbdbdb`,
        },
        position: "relative",
        overflow: "hidden",
        flexWrap: "wrap",
    },
    deviceIcon: {
        width: "20px",
        marginRight: theme.spacing(1),
    },
    deviceInfo: {
        textAlign: "left",
        "& i": {
            fontSize: theme.typography.pxToRem(10),
            display: "block",
        },
    },
    deviceAction: {
        flex: "1",
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    deviceActionPhone: {
        flex: "1",
        textAlign: "right",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        transition: "ease-in-out 0.2s",
    },
    deviceActionCurrent: {
        flex: "1",
        textAlign: "right",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    active: {
        transform: "translateX(0px) !important",
    },

    editDevice: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    successBanner: {
        position: "absolute",
        top: "0px",
        left: "0px",
        backgroundColor: "#21ce99",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
        color: "#fff",
    },
    deleteBanner: {
        position: "absolute",
        top: "0px",
        left: "0px",
        backgroundColor: "#e65365",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
        color: "#fff",
    },
    deleteBannerTitle: {
        flex: "1",
    },
    loading: {
        background: "rgba(255,255,255,.4)",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
    subtitle: {
        display: "block",
    },
    otherTitle: {
        marginTop: theme.spacing(4.25),
        marginBottom: theme.spacing(2),
    },
    moreAction: {
        display: "flex",
        alignItems: "center",
        transform: "translate3d(100%,0,0)",
        transition: "ease-in-out 0.2s",
        position: "relative",
        left: "-15px",
    },
}));

const ManageDevice = ({ logout }) => {
    const { t } = useTranslation();
    const [listDevice, setListDevice] = useState(null);
    const classes = useStyles();
    const [isEditNameDevice, setIsEditNameDevice] = useState(false); // show option when rename device
    const [isRemoveDevice, setIsRemoveDevice] = useState(false); // show option when remove device
    const [editDeviceId, setEditDeviceId] = useState("");
    const [deleteSuccess, setDeleteSuccess] = useState(false); // for display banner when remove device
    const [isActive, setActive] = useState(""); // trigger animation action click
    const [defaultDeviceSuccess, setDefaultSuccess] = useState(false); // for display banner when update default device
    const [loading, setLoading] = useState(false); // for display banner when update default device
    const [showCaution, setShowCaution] = useState(false); // for display caution when delete default device
    const unmounted = useRef(false);

    const defaultValues = {
        editDeviceName: "",
    };
    const { register, handleSubmit, errors } = useForm({
        defaultValues,
    });

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const getListDevice = async () => {
        const { data } = await manageDeviceService.getListDevice();
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setListDevice(data.data);
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };
    useEffect(() => {
        getListDevice();
    }, []);

    const editNameDevice = (device) => {
        setIsEditNameDevice(true);
        setEditDeviceId(device.timoDeviceId);
    };

    const removeDevice = (device, isDefaultDevice) => {
        if (isDefaultDevice) {
            setShowCaution(true);
        }
        setEditDeviceId(device.timoDeviceId);
        setIsRemoveDevice(true);
    };

    const actionClick = (key) => {
        setIsEditNameDevice(false);
        setIsRemoveDevice(false);
        setActive((prev) => (prev === key ? "" : key));
    };
    const cancelEditNameDevice = () => {
        setShowCaution(false);
        setIsEditNameDevice(false);
        setIsRemoveDevice(false);
        setEditDeviceId("");
    };

    const yesRemoveDevice = async () => {
        setLoading(true);
        const dataToPost = {
            deviceId: editDeviceId,
        };

        const { data } = await manageDeviceService.deleteDevice(dataToPost);
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setIsRemoveDevice(false);
                setDeleteSuccess(true);
                setShowCaution(false);
                setTimeout(() => {
                    setDeleteSuccess(false);
                    getListDevice();
                }, 3000);
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
        setLoading(false);
    };

    const saveEditNameDevice = async ({ editDeviceName }) => {
        setLoading(true);
        const dataToPost = {
            deviceId: editDeviceId,
            deviceName: editDeviceName,
        };
        const { data } = await manageDeviceService.editDeviceName(dataToPost);
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setIsEditNameDevice(false);
                setActive("");
                setEditDeviceId("");
                getListDevice();
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
        setLoading(false);
    };

    const setDeviceDefault = async (deviceId) => {
        setEditDeviceId(deviceId);
        setLoading(true);
        const dataToPost = {
            deviceId,
            trusted: "Default",
        };
        const { data } = await manageDeviceService.editDeviceName(dataToPost);
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setDefaultSuccess(true);
                setActive("");
                setEditDeviceId("");
                getListDevice();
                setTimeout(() => {
                    setDefaultSuccess(false);
                }, 1000);
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
        setLoading(false);
    };

    return (
        <>
            <Banner
                title={t("manage_device_banner_title")}
                subtitle={t("manage_device_banner_subtitle")}
            />
            <ContentWrapper>
                <Content boxProps={{ textAlign: "center" }}>
                    {!listDevice ? (
                        <CircularProgress size={18} />
                    ) : (
                        <>
                            <div>
                                <LspTypography
                                    variant="subheading1"
                                    color="grey"
                                >
                                    {t("manage_device_default_title")}
                                </LspTypography>
                                {listDevice?.default.length !== 0 && (
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        component="i"
                                    >
                                        {t("manage_device_default_subtitle")}
                                    </LspTypography>
                                )}

                                {listDevice?.default.length === 0 && (
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        component="i"
                                        className={classes.subtitle}
                                    >
                                        {t(
                                            "manage_device_default_empty_subtitle"
                                        )}
                                    </LspTypography>
                                )}
                                {showCaution ? (
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        component="i"
                                        className={classes.subtitle}
                                    >
                                        {t(
                                            "manage_device_default_removal_caution_text"
                                        )}
                                    </LspTypography>
                                ) : (
                                    <div className={classes.subtitle}>
                                        &nbsp;
                                    </div>
                                )}
                            </div>
                            <div className={classes.deviceDefault}>
                                {listDevice?.default.map((item) => (
                                    <div
                                        className={classes.deviceItem}
                                        key={item.timoDeviceId}
                                    >
                                        {isEditNameDevice &&
                                        editDeviceId === item.timoDeviceId ? (
                                            <div className={classes.editDevice}>
                                                <div>
                                                    <TextField
                                                        autoFocus
                                                        name="editDeviceName"
                                                        error={
                                                            !!errors.editDeviceName
                                                        }
                                                        label="Device Name"
                                                        helperText={
                                                            errors
                                                                .editDeviceName
                                                                ?.message || " "
                                                        }
                                                        inputProps={{
                                                            ref: register({
                                                                required: t(
                                                                    "msg_we_need_this"
                                                                ),
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <IconButton
                                                        onClick={handleSubmit(
                                                            saveEditNameDevice
                                                        )}
                                                    >
                                                        <CreateIcon color="secondary" />
                                                        <LspTypography
                                                            variant="body2"
                                                            color="secondary"
                                                            component="p"
                                                        >
                                                            {t(
                                                                "manage_device_text_save"
                                                            )}
                                                        </LspTypography>
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() =>
                                                            cancelEditNameDevice()
                                                        }
                                                    >
                                                        <CloseIcon color="error" />
                                                        <LspTypography
                                                            variant="body2"
                                                            color="error"
                                                            component="p"
                                                        >
                                                            {t("lb_cancel")}
                                                        </LspTypography>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        classes.deviceIcon
                                                    }
                                                >
                                                    {(item.deviceType ===
                                                        "phone" ||
                                                        item.deviceType ===
                                                            "tablet") && (
                                                        <PhoneAndroidIcon fontSize="small" />
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        classes.deviceInfo
                                                    }
                                                >
                                                    <div className="name">
                                                        {item.deviceName}
                                                    </div>
                                                    <i className="logged-time">
                                                        {`(${
                                                            t(
                                                                "manage_device_text_last_logged"
                                                            ) + item.lastLogin
                                                        })`}
                                                    </i>
                                                </div>
                                                <div
                                                    className={clsx(
                                                        classes.deviceAction
                                                    )}
                                                >
                                                    {isRemoveDevice &&
                                                    editDeviceId ===
                                                        item.timoDeviceId ? (
                                                        <>
                                                            <span>
                                                                {t(
                                                                    "manage_device_msg_del_device"
                                                                )}
                                                            </span>
                                                            <IconButton
                                                                onClick={
                                                                    yesRemoveDevice
                                                                }
                                                            >
                                                                <CheckIcon color="secondary" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="success"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "lb_yes"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() =>
                                                                    cancelEditNameDevice()
                                                                }
                                                            >
                                                                <CloseIcon color="error" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="error"
                                                                    component="p"
                                                                >
                                                                    {t("lb_no")}
                                                                </LspTypography>
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={clsx(
                                                                classes.moreAction,
                                                                {
                                                                    [classes.active]:
                                                                        isActive ===
                                                                        item.timoDeviceId,
                                                                }
                                                            )}
                                                        >
                                                            <MoreHorizIcon
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    actionClick(
                                                                        item.timoDeviceId
                                                                    )
                                                                }
                                                            />
                                                            <IconButton
                                                                onClick={() =>
                                                                    editNameDevice(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <CreateIcon color="secondary" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="secondary"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "lb_name"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() =>
                                                                    removeDevice(
                                                                        item,
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <CloseIcon color="error" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="error"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "manage_device_text_remove"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {defaultDeviceSuccess && (
                                            <div
                                                className={
                                                    classes.successBanner
                                                }
                                            >
                                                <CheckCircleOutlineIcon />
                                                <div
                                                    className={
                                                        classes.deleteBannerTitle
                                                    }
                                                >
                                                    {t(
                                                        "manage_device_msg_device_changed"
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {deleteSuccess &&
                                            editDeviceId ===
                                                item.timoDeviceId && (
                                                <div
                                                    className={
                                                        classes.deleteBanner
                                                    }
                                                >
                                                    <CheckCircleOutlineIcon />
                                                    <div
                                                        className={
                                                            classes.deleteBannerTitle
                                                        }
                                                    >
                                                        {t(
                                                            "manage_device_msg_device_deleted"
                                                        ).replace(
                                                            "%@",
                                                            item.deviceName
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {loading &&
                                            editDeviceId ===
                                                item.timoDeviceId && (
                                                <div
                                                    className={classes.loading}
                                                >
                                                    <CircularProgress
                                                        size={18}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                ))}
                            </div>

                            <div className={classes.otherTitle}>
                                <LspTypography
                                    variant="subheading1"
                                    color="grey"
                                >
                                    {t("manage_device_other_title")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    color="grey"
                                    component="i"
                                >
                                    {t("manage_device_other_subtitle")}
                                </LspTypography>
                            </div>
                            <div className={classes.deviceOther}>
                                {listDevice?.others.map((item) => (
                                    <div
                                        className={classes.deviceItem}
                                        key={item.timoDeviceId}
                                    >
                                        {isEditNameDevice &&
                                        editDeviceId === item.timoDeviceId ? (
                                            <div className={classes.editDevice}>
                                                <div>
                                                    <TextField
                                                        autoFocus
                                                        name="editDeviceName"
                                                        error={
                                                            !!errors.editDeviceName
                                                        }
                                                        label="Device Name"
                                                        helperText={
                                                            errors
                                                                .editDeviceName
                                                                ?.message || " "
                                                        }
                                                        inputProps={{
                                                            ref: register({
                                                                required: t(
                                                                    "msg_we_need_this"
                                                                ),
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <IconButton
                                                        onClick={handleSubmit(
                                                            saveEditNameDevice
                                                        )}
                                                    >
                                                        <CreateIcon color="secondary" />
                                                        <LspTypography
                                                            variant="body2"
                                                            color="secondary"
                                                            component="p"
                                                        >
                                                            {t(
                                                                "manage_device_text_save"
                                                            )}
                                                        </LspTypography>
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() =>
                                                            cancelEditNameDevice()
                                                        }
                                                    >
                                                        <CloseIcon color="error" />
                                                        <LspTypography
                                                            variant="body2"
                                                            color="error"
                                                            component="p"
                                                        >
                                                            {t("lb_cancel")}
                                                        </LspTypography>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        classes.deviceIcon
                                                    }
                                                >
                                                    {(item.deviceType ===
                                                        "phone" ||
                                                        item.deviceType ===
                                                            "tablet") && (
                                                        <PhoneAndroidIcon fontSize="small" />
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        classes.deviceInfo
                                                    }
                                                >
                                                    <div className="name">
                                                        {item.deviceName}
                                                    </div>
                                                    <i className="logged-time">
                                                        {`(${
                                                            t(
                                                                "manage_device_text_last_logged"
                                                            ) + item.lastLogin
                                                        })`}
                                                    </i>
                                                </div>
                                                <div
                                                    className={clsx({
                                                        [classes.deviceActionPhone]:
                                                            item.osPlatform !==
                                                            "WEB",
                                                        [classes.deviceAction]:
                                                            item.osPlatform ===
                                                            "WEB",
                                                    })}
                                                    id="actions"
                                                >
                                                    {isRemoveDevice &&
                                                    editDeviceId ===
                                                        item.timoDeviceId ? (
                                                        <>
                                                            <span>
                                                                {t(
                                                                    "manage_device_msg_del_device"
                                                                )}
                                                            </span>
                                                            <IconButton
                                                                onClick={
                                                                    yesRemoveDevice
                                                                }
                                                            >
                                                                <CheckIcon color="secondary" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="success"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "lb_yes"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() =>
                                                                    cancelEditNameDevice()
                                                                }
                                                            >
                                                                <CloseIcon color="error" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="error"
                                                                    component="p"
                                                                >
                                                                    {t("lb_no")}
                                                                </LspTypography>
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={clsx(
                                                                classes.moreAction,
                                                                {
                                                                    [classes.active]:
                                                                        isActive ===
                                                                        item.timoDeviceId,
                                                                }
                                                            )}
                                                        >
                                                            <MoreHorizIcon
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    actionClick(
                                                                        item.timoDeviceId
                                                                    )
                                                                }
                                                            />

                                                            {item.osPlatform !==
                                                                "WEB" && (
                                                                <IconButton
                                                                    onClick={handleSubmit(
                                                                        () =>
                                                                            setDeviceDefault(
                                                                                item.timoDeviceId
                                                                            )
                                                                    )}
                                                                >
                                                                    <CheckIcon />
                                                                    <LspTypography
                                                                        variant="body2"
                                                                        color="primary"
                                                                        component="p"
                                                                    >
                                                                        {t(
                                                                            "manage_device_text_default"
                                                                        )}
                                                                    </LspTypography>
                                                                </IconButton>
                                                            )}

                                                            <IconButton
                                                                onClick={() =>
                                                                    editNameDevice(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <CreateIcon color="secondary" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="secondary"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "lb_name"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() =>
                                                                    removeDevice(
                                                                        item,
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <CloseIcon color="error" />
                                                                <LspTypography
                                                                    variant="body2"
                                                                    color="error"
                                                                    component="p"
                                                                >
                                                                    {t(
                                                                        "manage_device_text_remove"
                                                                    )}
                                                                </LspTypography>
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {deleteSuccess &&
                                            editDeviceId ===
                                                item.timoDeviceId && (
                                                <div
                                                    className={
                                                        classes.deleteBanner
                                                    }
                                                >
                                                    <CheckCircleOutlineIcon />
                                                    <div
                                                        className={
                                                            classes.deleteBannerTitle
                                                        }
                                                    >
                                                        {t(
                                                            "manage_device_msg_device_deleted"
                                                        ).replace(
                                                            "%@",
                                                            item.deviceName
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        {loading &&
                                            editDeviceId ===
                                                item.timoDeviceId && (
                                                <div
                                                    className={classes.loading}
                                                >
                                                    <CircularProgress
                                                        size={18}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                ))}
                            </div>

                            <div className={classes.otherTitle}>
                                <LspTypography
                                    variant="subheading1"
                                    color="grey"
                                >
                                    {t("manage_device_currently_logged_title")}
                                </LspTypography>
                            </div>
                            <div className={classes.deviceOther}>
                                {listDevice?.current.map((item) => (
                                    <div
                                        className={classes.deviceItem}
                                        key={item.timoDeviceId}
                                    >
                                        <div className={classes.deviceIcon}>
                                            {item.deviceType === "phone" && (
                                                <PhoneAndroidIcon fontSize="small" />
                                            )}
                                        </div>
                                        <div className={classes.deviceInfo}>
                                            <div className="name">
                                                {item.deviceName} -{" "}
                                                {item.osVersion}
                                            </div>
                                            <i className="logged-time">
                                                {`(${
                                                    t(
                                                        "manage_device_text_last_logged"
                                                    ) + item.lastLogin
                                                })`}
                                            </i>
                                        </div>
                                        <div
                                            className={
                                                classes.deviceActionCurrent
                                            }
                                        >
                                            <IconButton onClick={logout}>
                                                <PowerSettingsNewIcon color="secondary" />
                                                <LspTypography
                                                    variant="body2"
                                                    color="secondary"
                                                    component="p"
                                                >
                                                    {t("nav_lb_btn_logout")}
                                                </LspTypography>
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </Content>
            </ContentWrapper>
        </>
    );
};
const mapDispatch = (dispatch) => ({
    logout: () => dispatch(authAction.logoutRequest()),
});

export default connect(null, mapDispatch)(ManageDevice);
