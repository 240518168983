import Content from "@components/Content";
import useStepNavigation from "@helpers/useStepNavigation";
import { REQUEST_DOC_STEPS } from "./constant";
import ConfirmDocument from "./ConfirmDocument";
import PaymentDocument from "./PaymentDocument";
import DocumentDetail from "./DocumentDetail";
import ChooseAccount from "./ChooseAccount";

const DocumentInfo = () => {
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: "",
    });

    return (
        <Content textAlign="center">
            {step.current === REQUEST_DOC_STEPS.CONFIRM_DOCUMENT && (
                <ConfirmDocument onBack={stepBack} onNext={stepForward} />
            )}
            {step.current ===
                REQUEST_DOC_STEPS.CONFIRM_AND_PAYMENT_DOCUMENT && (
                <PaymentDocument onBack={stepBack} onNext={stepForward} />
            )}
            {step.current === REQUEST_DOC_STEPS.CHOOSE_ACCOUNT && (
                <ChooseAccount onBack={stepBack} onNext={stepForward} />
            )}

            {step.current === "" && <DocumentDetail onNext={stepForward} />}
        </Content>
    );
};

export default DocumentInfo;
