import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import sha512 from "crypto-js/sha512";
import {
    makeStyles,
    Link,
    Divider,
    Hidden,
    InputAdornment,
    IconButton,
    Grow,
    Tooltip,
    ButtonBase,
    useTheme,
} from "@material-ui/core";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import { useHistory } from "react-router-dom";
import authAction from "@redux/actions/auth";
import LspButton from "@components/LspButton";
import {
    AccountStatus,
    AppActionType,
    LANGUAGE_OPTIONS,
} from "@config/constants";
import quickCodeActions from "@redux/actions/quick-code";
import LspTextField from "@components/LspTextField";
import ColorBar from "@components/ColorBar";
import useLanguage from "@helpers/useLanguage";
import appAction from "@redux/actions/app";
import { Logo } from "@components/LspBranding";
import getClientId from "@helpers/getClientId";
import Suggestion from "@components/Suggestion";
import AlertController from "@helpers/controllers/AlertController";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Helmet } from "react-helmet";
import JoinUs from "./JoinUs";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        minHeight: "101vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            height: "100%",
            display: "block",
            justifyContent: "unset",
            alignItems: "unset",
            minHeight: "unset",
        },
    },
    container: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            padding: 0,
            height: "100%",
        },
    },
    joinUsContainer: {
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        width: 260,
        paddingTop: theme.spacing(5.5),
        paddingBottom: theme.spacing(5.5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        boxShadow: theme.shadows[1],
        textAlign: "center",
        marginTop: 36, // height of language switcher
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            height: "100%",
        },
    },
    languageSwitcher: {
        color: theme.palette.grey[700],
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    languageIcon: {
        marginRight: theme.spacing(0.5),
    },
    loginForm: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[2],
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 420,
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            borderRadius: 0,
            boxShadow: "unset",
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
    },
    loginFormContent: {
        position: "relative",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(5.5),
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },

        [theme.breakpoints.down("xs")]: {
            minHeight: 680,
            flex: "1",
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    logoContainer: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },
    logo: {
        height: theme.spacing(7.5),
    },
    submitButton: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    extraActions: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
    },
    extraActionItem: {
        textAlign: "center",
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    colorBar: {
        marginTop: theme.spacing(5.5),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),

        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    toggleBrightness: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    header: {
        height: 75,
        padding: theme.spacing(3),
        display: "none",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            display: "inline-flex",
        },
    },

    bold: {
        fontWeight: "bold",
    },
}));

const Login = ({
    appStatus,
    appTriggered,
    startApp,
    login,
    fetching,
    isAuthenticated,
    accountStatus,
    quickCodeExisting,
    setPassword,
    toggleDarkMode,
    isDarkMode,
    isClearLoginData,
    clearLoginTextField,
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { switchLanguage } = useLanguage();
    const history = useHistory();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const theme = useTheme();

    const defaultValues = {
        username: "",
        password: "",
    };

    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues,
    });

    const accessible = useMemo(
        () =>
            isAuthenticated &&
            [AccountStatus.TimoIn, AccountStatus.TimoOut].includes(
                accountStatus
            ) &&
            quickCodeExisting,
        [isAuthenticated, accountStatus, quickCodeExisting]
    );

    useEffect(() => {
        if (!!isClearLoginData || isClearLoginData === "true") {
            setValue("username", "");
            setValue("password", "");
            clearLoginTextField(false);
        }
    }, [isClearLoginData, setValue, clearLoginTextField]);

    useEffect(() => {
        localStorage.removeItem("transferDestination");
        localStorage.removeItem("termObject"); // maintenance screen
    }, []);

    useEffect(() => {
        if (accessible) {
            history.push("/home");
        }
    }, [accessible, history]);

    const handlePasswordVisibility = useCallback(() => {
        setIsPasswordVisible((prev) => !prev);
    }, []);

    const sendLoginRequest = useCallback(
        (data) => {
            const { username, password } = data;
            const encryptedPassword = sha512(password).toString();
            setPassword(password);
            login({
                username,
                password: encryptedPassword,
                lang: i18n.language,
            });
        },
        [i18n.language, login, setPassword]
    );

    useEffect(() => {
        if (appStatus === AppActionType.Start && appTriggered && loginData) {
            sendLoginRequest(loginData);
        }
    }, [appStatus, appTriggered, sendLoginRequest, loginData]);

    useEffect(() => {
        const clientId = getClientId();
        if (clientId.includes("mobile")) {
            const bypassSuggestion = localStorage.getItem("bypassSuggestion");
            if (!bypassSuggestion) {
                setShowSuggestion(true);
            }
        }
    }, []);

    const loginClick = (data) => {
        AlertController.hide();
        if (appStatus === AppActionType.Start && appTriggered) {
            sendLoginRequest(data);
            return;
        }
        setLoginData(data);
        startApp();
    };

    const goToRetrievePage = () => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Announcement",
                header: "ekyc:ekyc_pop_up_login_with_phone_title",
                content: "ekyc:ekyc_pop_up_login_with_phone_content",
                button: "lb_btn_ok_got_it",
            },
        });
    };

    const gotoResetPwdPage = useCallback(() => {
        history.push("/resetPassword");
    }, [history]);

    const bypassSuggestion = useCallback(() => {
        localStorage.setItem("bypassSuggestion", true);
        setShowSuggestion(false);
    }, []);

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const isVI = () => i18n.language === LANGUAGE_OPTIONS.VN;

    return (
        <>
            {showSuggestion && (
                <Suggestion bypassSuggestion={bypassSuggestion} />
            )}

            {!showSuggestion && (
                <div className={classes.root}>
                    <Helmet
                        title={process.env.REACT_APP_NAME}
                        meta={[
                            {
                                name: `theme-color`,
                                content: theme.palette.background.paper,
                            },
                        ]}
                    />
                    <Grow in>
                        <div className={classes.container}>
                            <div className={classes.joinUsContainer}>
                                <JoinUs />
                            </div>
                            <div className={classes.formContainer}>
                                <div
                                    className={classes.header}
                                    onClick={changeLanguage}
                                >
                                    <span
                                        className={isVI() ? classes.bold : ""}
                                    >
                                        {LANGUAGE_OPTIONS.VN.toUpperCase()}
                                    </span>{" "}
                                    |{" "}
                                    <span
                                        className={isVI() ? "" : classes.bold}
                                    >
                                        {LANGUAGE_OPTIONS.EN.toUpperCase()}
                                    </span>
                                </div>

                                <Link
                                    onClick={switchLanguage}
                                    className={classes.languageSwitcher}
                                >
                                    <LanguageIcon
                                        fontSize="small"
                                        className={classes.languageIcon}
                                    />
                                    {t("menu_lb_language")}
                                </Link>
                                <form
                                    className={classes.loginForm}
                                    onSubmit={handleSubmit(loginClick)}
                                >
                                    <div className={classes.loginFormContent}>
                                        <ButtonBase
                                            className={classes.toggleBrightness}
                                            onClick={toggleDarkMode}
                                        >
                                            <Tooltip
                                                title={t(
                                                    "lb_hint_toggle_brightness"
                                                )}
                                            >
                                                {isDarkMode ? (
                                                    <Brightness7Icon />
                                                ) : (
                                                    <Brightness4Icon />
                                                )}
                                            </Tooltip>
                                        </ButtonBase>

                                        <div className={classes.logoContainer}>
                                            <Logo
                                                className={classes.logo}
                                                variant="pao"
                                            />
                                        </div>

                                        <LspTextField
                                            autoFocus
                                            error={!!errors.username}
                                            name="username"
                                            label={t("lb_username")}
                                            helperText={
                                                errors.username?.message || " "
                                            }
                                            inputProps={{
                                                ref: register({
                                                    required: t(
                                                        "msg_we_need_this"
                                                    ),
                                                    pattern: {
                                                        value: /^[+a-zA-Z0-9]{6,35}$/,
                                                        message: t(
                                                            "join_msg_username_invalid"
                                                        ),
                                                    },
                                                }),
                                                datatestid: "username",
                                            }}
                                        />
                                        <LspTextField
                                            error={!!errors.password}
                                            name="password"
                                            label={t("lb_password")}
                                            type={
                                                isPasswordVisible
                                                    ? "text"
                                                    : "password"
                                            }
                                            helperText={
                                                errors.password?.message || " "
                                            }
                                            inputProps={{
                                                ref: register({
                                                    required: t(
                                                        "msg_we_need_this"
                                                    ),
                                                }),
                                                datatestid: "password",
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handlePasswordVisibility
                                                            }
                                                        >
                                                            {isPasswordVisible ? (
                                                                <VisibilityOffOutlined />
                                                            ) : (
                                                                <VisibilityOutlined />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <LspButton
                                            type="submit"
                                            progressing={fetching}
                                            className={classes.submitButton}
                                            fullWidth
                                        >
                                            {t("lb_login")}
                                        </LspButton>
                                        <div className={classes.extraActions}>
                                            <Link
                                                onClick={gotoResetPwdPage}
                                                color="secondary"
                                                className={
                                                    classes.extraActionItem
                                                }
                                            >
                                                {t("login_lb_reset_password")}
                                            </Link>
                                            <Link
                                                onClick={goToRetrievePage}
                                                color="secondary"
                                                className={
                                                    classes.extraActionItem
                                                }
                                            >
                                                {t(
                                                    "login_lb_retrieve_username"
                                                )}
                                            </Link>
                                        </div>
                                        <Hidden implementation="js" mdUp>
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <JoinUs />
                                        </Hidden>
                                    </div>
                                    <ColorBar className={classes.colorBar} />
                                </form>
                            </div>
                        </div>
                    </Grow>
                </div>
            )}
        </>
    );
};

const mapState = (state) => ({
    appStatus: state.app.status,
    appTriggered: state.app.triggered,
    fetching: state.auth.fetching,
    isAuthenticated: state.auth.isAuthenticated,
    accountStatus: state.account.accountStatus,
    quickCodeExisting: state.quickCodeInfo.existing,
    isClearLoginData: state.auth.clearLoginTextfield,
    isDarkMode:
        state.app.isDarkMode === true || state.app.isDarkMode === "true",
});

const mapDispatch = (dispatch) => ({
    startApp: () => dispatch(appAction.start()),
    login: (payload) => dispatch(authAction.loginRequest(payload)),
    setPassword: (payload) => dispatch(quickCodeActions.setPassword(payload)),
    toggleDarkMode: () => dispatch(appAction.switchPaletteType()),
    clearLoginTextField: (payload) =>
        dispatch(authAction.updateClearLoginTextfield(payload)),
});

export default connect(mapState, mapDispatch)(Login);
