import QRCodeDialog from "@containers/SpendAccount/PendingPayment/QRCodeDialog";
import AlertController from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    box: {
        background: theme.palette.background.header,
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[0],
    },
    title: {
        color: theme.palette.blue.main,
        fontWeight: 600,
    },
    icon: {
        cursor: "pointer",
        marginLeft: theme.spacing(0.5),
    },
    info: {
        wordBreak: "break-all",
        marginRight: theme.spacing(2),
    },
    QRCode: {
        cursor: "pointer",
    },
}));

const ShareLinkPayment = ({ detail }) => {
    const classes = useStyles();
    const { t } = useTranslation(["translation", "payAnyone"]);

    const copyHandler = () => {
        AlertController.show(t("payAnyone:password_copy_success_banner"));
    };

    const onCloseHandler = () => {
        GlobalDialogController.hide();
    };

    const onClickOpenQRCodeDialog = () => {
        GlobalDialogController.show({
            component: () => (
                <QRCodeDialog
                    payLink={detail?.payLink}
                    onClose={onCloseHandler}
                />
            ),
        });
    };

    return (
        <Box variant="outline">
            <div className={classes.box}>
                <div className={classes.info}>
                    <Typography className={classes.title}>
                        {t("payAnyone:shareable_payment_link")}
                    </Typography>

                    <Typography>
                        {detail?.payLink}
                        <CopyToClipboard
                            text={detail?.payLink}
                            onCopy={copyHandler}
                        >
                            <Icon
                                className={`font-icon icon-copytoclipboard  ${classes.icon}`}
                            />
                        </CopyToClipboard>
                    </Typography>
                </div>
                {detail?.payLink && (
                    <div className={classes.QRCode}>
                        <QRCode
                            value={detail?.payLink}
                            size={65}
                            onClick={onClickOpenQRCodeDialog}
                        />
                    </div>
                )}
            </div>
        </Box>
    );
};

export default ShareLinkPayment;
