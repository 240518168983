/* eslint-disable react/no-array-index-key */
import { makeStyles, Paper } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { ArrowRightIcon } from "@components/LspIcon";
import PageHeaderBack from "@components/PageHeaderBack";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        marginBottom: theme.spacing(3),
        borderTop: `4px solid ${theme.palette.primary.main}`,
    },
    hangoutList: {
        listStyle: "none",
        paddingLeft: 0,
        margin: "0 auto",
    },
    hangoutItem: {
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        padding: theme.spacing(2, 1.25, 2, 2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        color: theme.palette.neutral.grey1,
        "& label": {
            display: "block",
            fontWeight: 600,
            color: theme.palette.neutral.black,
        },
        "&:last-child": {
            borderBottom: "none",
        },
    },
    subtitle: {
        background: theme.palette.neutral.grey4,
        padding: theme.spacing(1, 2),
        width: "100%",
    },
    description: {
        padding: theme.spacing(2),
    },
}));

/**
 * @typedef LspHangoutSelectionProps
 * @type {object}
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {function} onBack
 * @property {function} onNext
 * @property {array} hangouts
 * @property {array} hangouts
 * @property {object} selected
 * @property {bool} fetching
 */

/**
 * @param {LspHangoutSelectionProps} props
 */

const hangoutFetching = (classes) => {
    return (
        <ul className={classes.hangoutList}>
            {[...new Array(5)]?.map((item, index) => (
                <li className={classes.hangoutItem} key={index}>
                    <div style={{ width: "100%" }}>
                        <Skeleton width="37%" />
                        <Skeleton width="75%" />
                    </div>
                </li>
            ))}
        </ul>
    );
};

const LspHangoutSelection = ({
    title,
    subtitle,
    description,
    onBack,
    onNext,
    hangouts,
    selected,
    fetching = false,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onSelectHangoutHandler = (item) => {
        onNext(item);
    };

    return (
        <>
            <PageHeaderBack onBack={onBack || null}>
                {title || t("booking_choose_location_web")}
            </PageHeaderBack>
            <Paper className={classes.boxWrapper}>
                {description && (
                    <LspTypography
                        className={classes.description}
                        variant="body2"
                    >
                        {description}
                    </LspTypography>
                )}

                <LspTypography
                    variant="heading3"
                    color="grey"
                    className={classes.subtitle}
                >
                    {subtitle || t("booking_choose_location_web")}
                </LspTypography>
                {fetching && hangoutFetching(classes)}
                {!fetching && (
                    <ul className={classes.hangoutList}>
                        {hangouts?.map((item, index) => (
                            <li
                                className={clsx(classes.hangoutItem, {
                                    [classes.selected]:
                                        item?.id === selected?.id,
                                })}
                                key={index}
                                onClick={() => onSelectHangoutHandler(item)}
                            >
                                <div>
                                    <label htmlFor={`hangout-${index}`}>
                                        {item?.city}
                                    </label>
                                    <span>{item?.address}</span>
                                </div>
                                <ArrowRightIcon />
                            </li>
                        ))}
                    </ul>
                )}
            </Paper>
        </>
    );
};

export default memo(LspHangoutSelection);
