/* eslint-disable react/no-array-index-key */
import { memo, useMemo } from "react";
import { makeStyles, Icon } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import clsx from "clsx";

import useOverdraft from "@helpers/useOverdraft";

const useStyles = makeStyles((theme) => ({
    alertBox: {
        padding: theme.spacing(1, 2),
        background: theme.palette.status.success,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    alertBoxError: {
        background: theme.palette.status.error,
    },
    alertBoxImg: {
        width: 42,
        marginRight: theme.spacing(1),
        color: theme.palette.neutral.white,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    alertBoxText: {
        flex: 1,
    },
}));
const OverdraftLockBox = ({ lockType }) => {
    const classes = useStyles();
    const { getLockStatusContent, lockOverdraftDetail } = useOverdraft();

    const warningBanner = useMemo(() => {
        return getLockStatusContent(lockType || "");
    }, [lockType, getLockStatusContent]);

    return (
        <div className={clsx(classes.alertBox, classes.alertBoxError)}>
            <div className={classes.alertBoxImg}>
                <Icon
                    className={`font-icon icon-locked ${classes.actionBtnIcon}`}
                />
            </div>

            <div className={classes.alertBoxText}>
                <LspTypography variant="heading3" color="white">
                    {warningBanner?.title}
                </LspTypography>
                <LspTypography variant="subheading4" color="white">
                    {warningBanner?.desc?.replace("%@_cta", "")}{" "}
                    {
                        <LspTypography
                            variant="hyperlink2"
                            component="span"
                            color="white"
                            onClick={() =>
                                lockOverdraftDetail(warningBanner?.dialog)
                            }
                        >
                            {warningBanner?.hyperlink}
                        </LspTypography>
                    }
                </LspTypography>
            </div>
        </div>
    );
};

export default memo(OverdraftLockBox);
