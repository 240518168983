/* ------------- Types Definition ------------- */
export const PayeeActionType = Object.freeze({
    Reset: "PayeeActionType/Reset",
    GetPayeeDetailRequest: "PayeeActionType/GetPayeeDetailRequest",
    GetPayeeDetailSuccess: "PayeeActionType/GetPayeeDetailSuccess",
    GetPayeeDetailError: "PayeeActionType/GetPayeeDetailError",
    SavePayeeRequest: "PayeeActionType/SavePayeeRequest",
    SavePayeeSuccess: "PayeeActionType/SavePayeeSuccess",
    SavePayeeError: "PayeeActionType/SavePayeeError",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: PayeeActionType.Reset,
    };
};

const getPayeeDetailRequest = ({ payeeId }) => {
    return {
        type: PayeeActionType.GetPayeeDetailRequest,
        payload: { payeeId },
    };
};
const getPayeeDetailSuccess = (payload) => {
    return {
        type: PayeeActionType.GetPayeeDetailSuccess,
        payload,
    };
};
const getPayeeDetailError = (payload) => {
    return {
        type: PayeeActionType.GetPayeeDetailError,
        payload,
    };
};

const savePayeeRequest = (payload) => {
    return {
        type: PayeeActionType.GetPayeeDetailRequest,
        payload,
    };
};
const savePayeeSuccess = (payload) => {
    return {
        type: PayeeActionType.GetPayeeDetailSuccess,
        payload,
    };
};
const savePayeeError = () => {
    return {
        type: PayeeActionType.GetPayeeDetailError,
    };
};

const payeeAction = {
    reset,
    getPayeeDetailRequest,
    getPayeeDetailSuccess,
    getPayeeDetailError,
    savePayeeRequest,
    savePayeeSuccess,
    savePayeeError,
};

export default payeeAction;
