import { Box, CircularProgress, Hidden } from "@material-ui/core";
import { useCallback, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import wealthService from "@services/wealth";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";

import {
    INSTANT_COVERAGE_STEPS,
    WEALTH_PRODUCT_KEYS,
    WEALTH_STATUSES,
} from "@containers/Wealth/wealthConstants";
import { useHistory } from "react-router-dom";
import useStepNavigation from "@helpers/useStepNavigation";
import LspReceipt from "@components/LspReceipt";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LeftPage from "./LeftPage";
import RightPage from "./RightPage";
import RightPageForm from "./RightPage/RightPageForm";
import LeftPageReview from "./LeftPage/LeftPageReview";
import RightPageMoveMoney from "./RightPage/RightPageMoveMoney";

const defaultErrors = {
    province: "",
    district: "",
    ward: "",
    address: "",
};
const InstantCoverage = () => {
    const { t } = useTranslation();
    const classes = useClassesShared();
    const [loading, setLoading] = useState(false);
    const [leftDetail, setLeftDetail] = useState([]);
    const [rightDetail, setRightDetail] = useState([]);
    const [prokeySelected, setProkeySelected] = useState("");
    const [packageDetail, setPackageDetail] = useState(null);
    const history = useHistory();
    const [formInfo, setFormInfo] = useState(null);
    const [infoReceipt, setInfoReceipt] = useState(null);

    const [addressName, setAddressName] = useState("");
    const [selectedProvince, setSelectedProvince] = useState({
        cfgKey: "",
        cfgValue: "",
    });
    const [selectedDistrict, setSelectedDistrict] = useState({
        cfgKey: "",
        cfgValue: "",
    });

    const [errors, setErrors] = useState(defaultErrors);
    const [addressOption, setAddressOption] = useState({
        defaultAddress: true,
        newAddress: false,
    });
    const unmounted = useRef(false);
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: INSTANT_COVERAGE_STEPS.INIT,
    });
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getSubProduct = useCallback(async () => {
        setLoading(true);
        const response = await wealthService.getSubProducts(
            WEALTH_PRODUCT_KEYS.LIFE_INSURANCE_INSTANT_COVERAGE
        );

        const { code, data } = response.data;

        if (unmounted.current) return;

        if (code !== RESPONSE_CODE.SUCCESS) {
            GlobalDialogController.showError({ errorCode: code });
            return;
        }
        setLoading(false);
        if (data) {
            if (!data.status.toLowerCase() === WEALTH_STATUSES.APPLIED) {
                history.push("products");
            } else if (data.subProducts) {
                const packagePersonalAccident = [];
                const leftDetailPackage = [];
                data.subProducts.forEach((value) => {
                    const rightPackage = {};
                    rightPackage.prokey = value.productKey;
                    rightPackage.title = t(
                        `wealth:SI00013_${value.productKey}`
                    );
                    rightPackage.txtCoverage = t("wealth:SI00009");
                    rightPackage.valCoverage = t(
                        `wealth:SI00015_${value.productKey}`
                    );
                    rightPackage.txtYouPay = t("wealth:SI00018");
                    rightPackage.valYouPay = value.pricing;
                    packagePersonalAccident.push(rightPackage);

                    const leftPackage = {};
                    leftPackage.prokey = value.productKey;
                    leftPackage.iconHeader = "personalaccident";
                    leftPackage.titleHeader = "";
                    leftPackage.descHeader = t(
                        `wealth:SI00013_${value.productKey}`
                    );
                    leftPackage.txtYouPayHeader = t("wealth:SI00018");
                    leftPackage.valYouPayHeader = value.pricing;

                    leftPackage.content = [];
                    const subContent1 = {};
                    subContent1.panelHeader = t("wealth:SI00066");
                    subContent1.panelBody = t(
                        `wealth:SI00014_${value.productKey}`,
                        {
                            returnObjects: true,
                            defaultValue: "",
                        }
                    );
                    subContent1.panelFooter = t("wealth:SI00010", {
                        returnObjects: true,
                        defaultValue: "",
                    });
                    leftPackage.content.push(subContent1);

                    const subContent2 = {};
                    subContent2.panelHeader = t("wealth:SI00080");
                    subContent2.panelBody = t("wealth:SI00016", {
                        returnObjects: true,
                        defaultValue: "",
                    });
                    leftPackage.content.push(subContent2);
                    const subContent3 = {};
                    // eslint-disable-next-line prefer-destructuring
                    subContent3.panelHeader = t("wealth:SI00055", {
                        returnObjects: true,
                        defaultValue: "",
                    })[0];
                    subContent3.panelBody = t("wealth:SI00055", {
                        returnObjects: true,
                        defaultValue: "",
                    });
                    subContent3.panelFooter = t("wealth:SI00056", {
                        returnObjects: true,
                        defaultValue: "",
                    });
                    leftPackage.content.push(subContent3);

                    leftDetailPackage.push(leftPackage);
                });
                setRightDetail(packagePersonalAccident);
                setLeftDetail(leftDetailPackage);
            }
        }
    }, [t, history]);

    useEffect(() => {
        getSubProduct();
    }, [getSubProduct]);
    // ------ Start Submit info section

    // ------ End Submit info section

    return (
        <>
            {loading && (
                <Box textAlign="center" paddingTop={3}>
                    <CircularProgress size={18} />
                </Box>
            )}

            {!loading && (
                <div className={classes.wrapper}>
                    {/* For small devices: hide left side column & document will be display when users click apply form */}
                    <Hidden smDown>
                        <div className={classes.leftSide}>
                            {(step.current === INSTANT_COVERAGE_STEPS.INIT ||
                                step.current ===
                                    INSTANT_COVERAGE_STEPS.FORM_INFO) && (
                                <LeftPage
                                    leftDetail={leftDetail}
                                    prokeySelected={prokeySelected}
                                    step={step}
                                    stepBack={stepBack}
                                />
                            )}

                            {(step.current ===
                                INSTANT_COVERAGE_STEPS.REVIEW_POLICY ||
                                step.current ===
                                    INSTANT_COVERAGE_STEPS.CONGRATULATION) && (
                                <LeftPageReview
                                    rightPackage={rightDetail}
                                    setProkeySelected={setProkeySelected}
                                    prokeySelected={prokeySelected}
                                    stepForward={stepForward}
                                    leftDetail={leftDetail}
                                    packageDetail={packageDetail}
                                    formInfo={formInfo}
                                    step={step}
                                    setAddressName={setAddressName}
                                    setSelectedProvince={setSelectedProvince}
                                    setSelectedDistrict={setSelectedDistrict}
                                    addressName={addressName}
                                    selectedProvince={selectedProvince}
                                    selectedDistrict={selectedDistrict}
                                    setErrors={setErrors}
                                    errors={errors}
                                    addressOption={addressOption}
                                    setAddressOption={setAddressOption}
                                    stepBack={stepBack}
                                />
                            )}
                        </div>
                    </Hidden>
                    {/* For small devices:  users click apply > show document > next > submit form */}
                    <div className={classes.rightSide}>
                        {step.current === INSTANT_COVERAGE_STEPS.INIT && (
                            <RightPage
                                rightPackage={rightDetail}
                                setProkeySelected={setProkeySelected}
                                prokeySelected={prokeySelected}
                                stepForward={stepForward}
                                leftDetail={leftDetail}
                                setPackageDetail={setPackageDetail}
                            />
                        )}

                        {step.current === INSTANT_COVERAGE_STEPS.FORM_INFO && (
                            <RightPageForm
                                setFormInfo={setFormInfo}
                                stepForward={stepForward}
                                formInfo={formInfo}
                            />
                        )}
                        {step.current ===
                            INSTANT_COVERAGE_STEPS.MOBILE_INTRO && (
                            <LeftPage
                                leftDetail={leftDetail}
                                prokeySelected={prokeySelected}
                                stepBack={stepBack}
                                stepForward={stepForward}
                                showBtnMobile
                            />
                        )}

                        {step.current ===
                            INSTANT_COVERAGE_STEPS.MOBILE_REVIEW_POLICY && (
                            <LeftPageReview
                                rightPackage={rightDetail}
                                setProkeySelected={setProkeySelected}
                                prokeySelected={prokeySelected}
                                stepForward={stepForward}
                                leftDetail={leftDetail}
                                packageDetail={packageDetail}
                                formInfo={formInfo}
                                step={step}
                                setAddressName={setAddressName}
                                setSelectedProvince={setSelectedProvince}
                                setSelectedDistrict={setSelectedDistrict}
                                addressName={addressName}
                                selectedProvince={selectedProvince}
                                selectedDistrict={selectedDistrict}
                                setErrors={setErrors}
                                errors={errors}
                                addressOption={addressOption}
                                setAddressOption={setAddressOption}
                                stepBack={stepBack}
                                showBtnMobile
                            />
                        )}

                        {step.current ===
                            INSTANT_COVERAGE_STEPS.REVIEW_POLICY && (
                            <RightPageMoveMoney
                                rightPackage={rightDetail}
                                prokeySelected={prokeySelected}
                                formInfo={formInfo}
                                setInfoReceipt={setInfoReceipt}
                                stepForward={stepForward}
                                addressName={addressName}
                                selectedProvince={selectedProvince}
                                selectedDistrict={selectedDistrict}
                                setErrors={setErrors}
                                addressOption={addressOption}
                            />
                        )}
                        {step.current ===
                            INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                            <LspReceipt rawReceipt={infoReceipt} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default InstantCoverage;
