import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { AuthActionType } from "../actions/auth";

/* ------------- Initial State ------------- */
export const authInitialState = {
    fetching: false,
    status: null,
    code: null,
    token: null,
    refNo: null,
    timoDeviceId: null,
    userId: null,
    isAuthenticated: false,
    clearLoginTextfield: false,
};

/* ------------- Reducer ------------- */
const authReducer = (state = authInitialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case AuthActionType.SetIsAuthenticated:
                draft.isAuthenticated = !!action.payload;
                break;
            /* ------------- Login ------------- */
            case AuthActionType.LoginRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AuthActionType.LoginSuccess: {
                const { token, timoDeviceId, userId } = action.payload;
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.token = token;
                draft.refNo = null;
                draft.timoDeviceId = timoDeviceId;
                draft.userId = userId;
                break;
            }
            case AuthActionType.LoginWithOTP:
            case AuthActionType.LoginWithTwoFactor: {
                const { token, refNo } = action.payload;
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.token = token;
                draft.refNo = refNo;
                break;
            }
            case AuthActionType.LoginWithOTPError:
            case AuthActionType.LoginWithTwoFactorError: {
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            }
            case AuthActionType.LoginError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                draft.isAuthenticated = false;
                break;
            /* ------------- Logout ------------- */
            case AuthActionType.LogoutRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AuthActionType.LogoutSuccess:
            case AuthActionType.LogoutError: {
                const stateNames = Object.keys(authInitialState);
                stateNames.forEach((name) => {
                    draft[name] = authInitialState[name];
                });
                break;
            }
            /* ------------- Submit device authentication OTP ------------- */
            case AuthActionType.SubmitDeviceAuthenticationOTPRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AuthActionType.SubmitDeviceAuthenticationOTPSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                break;
            case AuthActionType.SubmitDeviceAuthenticationOTPError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            /* ------------- Submit GG  two factor authentication OTP ------------- */
            case AuthActionType.SubmitGGTwoFactorOTPRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AuthActionType.SubmitGGTwoFactorOTPSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                break;
            case AuthActionType.SubmitGGTwoFactorOTPError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            /* ------------- Submit two factor OTP ------------- */
            case AuthActionType.SubmitTwoFactorOTPRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case AuthActionType.SubmitTwoFactorOTPSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                break;
            case AuthActionType.SubmitTwoFactorOTPError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case AuthActionType.ClearLoginTextField:
                draft.clearLoginTextfield = action.payload;
                break;
            /* ------------- Reset to initial state ------------- */
            case AuthActionType.Reset: {
                const stateNames = Object.keys(authInitialState);
                stateNames.forEach((name) => {
                    draft[name] = authInitialState[name];
                });
                break;
            }
            default:
                break;
        }
    });

export default authReducer;
