import cloneDeep from "lodash/cloneDeep";

const mergeTransactionList = (currentList, newList) => {
    if (!Array.isArray(currentList) || currentList.length === 0) {
        return newList;
    }
    if (!Array.isArray(newList) || newList.length === 0) {
        return currentList;
    }

    const currentLastDate = currentList[currentList.length - 1].dispDate;
    const newFirstDate = newList[0].dispDate;

    if (currentLastDate !== newFirstDate) {
        return [...currentList, ...newList];
    }

    const clonedCurrentList = cloneDeep(currentList);
    clonedCurrentList[clonedCurrentList.length - 1].item = [
        ...clonedCurrentList[clonedCurrentList.length - 1].item,
        ...newList[0].item,
    ];
    return [...clonedCurrentList, ...newList.filter((_, index) => index !== 0)];
};

export default mergeTransactionList;
