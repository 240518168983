import { Icon, makeStyles, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "60%",
        textAlign: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontSize: theme.typography.pxToRem(14),
        "& a": {
            color: "#FFF",
            textDecoration: "underline",
        },
        [theme.breakpoints.up("sm")]: {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    title: {
        fontSize: theme.typography.pxToRem(16),
        textTransform: "uppercase",
        fontWeight: 600,
        marginTop: theme.spacing(2),
    },
    icon: {
        fontSize: theme.typography.pxToRem(45),
    },
    disabled: {
        opacity: theme.palette.opacity.disabled,
        pointerEvents: "none",
        cursor: "not-allowed",
    },
    button: {
        fontSize: theme.typography.pxToRem(14),
        textDecoration: "underline",
    },
}));

const QuickCodeContent = ({
    icon,
    title,
    content,
    resetQuickCode,
    forgotQuickCode,
    loading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.wrapper}>
            <Icon className={`font-icon icon-${icon} ${classes.icon}`} />
            <div className={classes.title}>{t(title)}</div>
            <div>{t(content)}</div>
            {resetQuickCode && (
                <ButtonBase
                    onClick={resetQuickCode}
                    className={clsx(
                        classes.button,
                        loading ? classes.disabled : null
                    )}
                >
                    {t("quickcode_btn_reset_step1_qc")}
                </ButtonBase>
            )}
            {forgotQuickCode && (
                <ButtonBase
                    onClick={forgotQuickCode}
                    className={clsx(
                        classes.button,
                        loading ? classes.disabled : null
                    )}
                >
                    {t("quickcode_forgot_quick_code")}
                </ButtonBase>
            )}
        </div>
    );
};

export default QuickCodeContent;
