import { makeStyles } from "@material-ui/core";
import { memo } from "react";

import napasLogo from "@assets/images/napas_logo.svg";
import timoLogo from "@assets/images/timo_logo.svg";
import bvbLogo from "@assets/images/bvb_logo.png";

const useStyles = makeStyles((theme) => ({
    bankLogo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `0 ${theme.spacing(2)}px`,
        "& img": {
            height: 24,
        },
    },
    bankLogoItem: {
        padding: `0 ${theme.spacing(2)}px`,
        borderRight: `1px solid ${theme.palette.neutral.grey3}`,
        "&:last-child": {
            borderRight: "unset",
        },
    },
}));

const BankQRCodeLogo = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.bankLogo}>
                <div className={classes.bankLogoItem}>
                    <img src={napasLogo} alt="Napas logo" />
                </div>
                <div className={classes.bankLogoItem}>
                    <img src={timoLogo} alt="Timo logo" />
                </div>
                <div className={classes.bankLogoItem}>
                    <img src={bvbLogo} alt="Bản Việt logo" />
                </div>
            </div>
        </>
    );
};

export default memo(BankQRCodeLogo);
