import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    VINA_WEALTH_TXT_TRANSACTION_TYPES,
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_TYPES,
} from "@containers/Wealth/wealthConstants";
import { memo, useState, useCallback, useEffect } from "react";
import Loading from "@components/Loading";
import { connect } from "react-redux";
import vinaWealthAction from "@redux/actions/vina-wealth";
import { FetchStatus } from "@config/constants";
import VinaWealthTransactionItem from "./VinaWealthTransactionItem";
import useSharedClasses from "./vinaWealthSharedClasses";

const VinaWealthCompleteTransaction = ({
    getTransactionList,
    completedTransactions,
    fetchingCompleted,
    status,
    lastId,
    isShowLoadMoreButton,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const [firstLoad, setFirstLoad] = useState(true);

    const getCompletedTransaction = useCallback(
        (lastTransactionId) => {
            const payload = {
                productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
                type: WEALTH_VINA_TYPES.TRANSACTION,
                lastId: lastTransactionId || -1,
                transactionType: [
                    VINA_WEALTH_TXT_TRANSACTION_TYPES.COMPLETE,
                    VINA_WEALTH_TXT_TRANSACTION_TYPES.CANCEL,
                ],
            };
            getTransactionList(payload);
        },
        [getTransactionList]
    );

    const onLoadMoreComplete = useCallback(async () => {
        getCompletedTransaction(lastId);
    }, [lastId, getCompletedTransaction]);

    useEffect(() => {
        getCompletedTransaction();
    }, [getCompletedTransaction]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            setFirstLoad(false);
        }
    }, [status]);

    return (
        <>
            {!firstLoad && (
                <div className={sharedClasses.statusLine}>
                    {t("wealth:vc3_status_completed")}
                </div>
            )}
            {completedTransactions &&
                completedTransactions?.map((trans) => (
                    <VinaWealthTransactionItem key={trans.id} item={trans} />
                ))}

            {isShowLoadMoreButton && !firstLoad && (
                <ButtonBase
                    className={sharedClasses.seeMore}
                    onClick={onLoadMoreComplete}
                    disabled={fetchingCompleted}
                >
                    {t("wealth:vc3_seemore_btn")}
                </ButtonBase>
            )}
            {fetchingCompleted && <Loading />}
        </>
    );
};

const mapState = (state) => ({
    completedTransactions: state.vinaWealth.completedTransaction.list,
    fetchingCompleted: state.vinaWealth.completedTransaction.fetching,
    lastId: state.vinaWealth.completedTransaction.lastId,
    status: state.vinaWealth.completedTransaction.status,
    isShowLoadMoreButton: state.vinaWealth.completedTransaction.isLoadMore,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(vinaWealthAction.getCompletedTransaction(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(VinaWealthCompleteTransaction)
);
