import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import { useMemo } from "react";
import ClaimMoneyHeader from "@containers/SpendAccount/MoveMoney/ClaimMoneyPayAnyone/ClaimMoneyHeader";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ClaimMoneyLixiDetailPopup from "@containers/SpendAccount/MoveMoney/ClaimMoneyPayAnyone/ClaimMoneyLixiDetailPopup";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        color: "white",
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(2, 0),
        textAlign: "center",
        height: "86vh",
    },
    img: {
        width: "100%",
        alignSelf: "center",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    senderName: {
        fontWeight: 600,
        textTransform: "uppercase",
        marginBottom: theme.spacing(1.25),
    },
    btn: {
        "& button": {
            backgroundColor: theme.palette.gold.main,
            minWidth: 183,
        },
    },
}));

const ClaimMoneyLixiDetail = ({ paymentDetail, parentClass }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const theme = useMemo(() => {
        const themes = t("lixi:LIXI_available_theme_keys", {
            returnObjects: true,
        });

        const themeRecord = themes.filter(
            (item) =>
                t(`lixi:${item}`, { returnObjects: true }).id ===
                paymentDetail.themeId
        );
        return t(`lixi:${themeRecord}`, { returnObjects: true });
    }, [paymentDetail, t]);

    const closePopup = () => {
        GlobalDialogController.hide();
    };

    const openLixiDetail = () => {
        GlobalDialogController.show({
            component: () => (
                <ClaimMoneyLixiDetailPopup
                    detail={paymentDetail}
                    theme={theme}
                    onClose={closePopup}
                />
            ),
        });
    };

    return (
        <div
            className={parentClass.content}
            style={{
                backgroundColor: theme?.cover_color,
                backgroundImage: `url(${theme.cover_background})`,
            }}
        >
            <ClaimMoneyHeader variant="white" />
            <Content>
                <div className={classes.wrapper}>
                    <LspTypography color="white">
                        {t("lixi:lixi_cover_text_from")}
                    </LspTypography>
                    <LspTypography
                        color="white"
                        variant="title2"
                        style={{ marginTop: 8 }}
                    >
                        {paymentDetail.senderName}
                    </LspTypography>
                    <img
                        className={classes.img}
                        src={theme?.cover_foreground}
                        alt="theme"
                    />
                    <LspButton onClick={openLixiDetail} className={classes.btn}>
                        {t("lixi:lixi_btn_open")}
                    </LspButton>
                </div>
            </Content>
        </div>
    );
};

export default ClaimMoneyLixiDetail;
