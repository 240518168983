import { makeStyles } from "@material-ui/core";
import ClaimMoneyLanguage from "@containers/SpendAccount/MoveMoney/ClaimMoneyPayAnyone/ClaimMoneyLanguage";
import { Logo } from "@components/LspBranding";

const useStyles = makeStyles((theme) => ({
    header: {
        position: "relative",
    },
    logoWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    logo: {
        width: "100%",
        maxWidth: theme.typography.pxToRem(100),
        marginRight: theme.spacing(1),
    },
}));

const ClaimMoneyHeader = ({ isHideHeader, variant = "pao" }) => {
    const classes = useStyles();

    return (
        <>
            <ClaimMoneyLanguage
                color={variant === "white" ? "white" : "black"}
            />
            {!isHideHeader && (
                <div className={classes.header}>
                    <div className={classes.logoWrapper}>
                        <Logo variant={variant} className={classes.logo} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ClaimMoneyHeader;
