import Content from "@components/Content";
import LspButton from "@components/LspButton";
import PageHeaderBack from "@components/PageHeaderBack";

import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { Box, makeStyles } from "@material-ui/core";
import vinaWealthAction from "@redux/actions/vina-wealth";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        contentIntro: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            padding: theme.spacing(2, 3.75, 2, 2),
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        introBtn: {
            padding: theme.spacing(2, 3.75),
        },
        contentItem: {
            marginTop: theme.spacing(1.25),
        },
        subDes: {
            color: theme.palette.primary.main,
        },
        overView: {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
        whyVC: {
            background: "rgba(255, 255, 255, 0.5)",
            padding: theme.spacing(2),
            marginTop: theme.spacing(2.5),
            border: "1px solid #f1f1f1",
            " & > span": {
                fontSize: theme.typography.pxToRem(16),
                fontWeight: "bold",
                "& > i": {
                    color: theme.palette.blue.main,
                    marginRight: theme.spacing(1),
                    fontSize: theme.typography.pxToRem(24),
                },
            },
            " & > div": {
                marginTop: theme.spacing(1.25),
                fontSize: theme.typography.pxToRem(13),
            },
        },
    };
});

const VinaWealthDetail = ({
    productDetail,
    isNotExistedAccount,
    user,
    onBack,
    onNext,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const checkValidDocumentInfo = () => {
        let documentIsValid = true;
        if (user) {
            const documentId = user.legalId;
            const documentIssueDate = user.docIssueDate;
            const documentIssuePlace = user.docIssuePlace;

            // if (documentId) {
            //     $scope.legalId = documentId;
            // }

            if (!documentId || !documentIssueDate || !documentIssuePlace) {
                onNext(VINA_WEALTH_STEPS.INPUT_AMOUNT);
                documentIsValid = false;
            }
        }
        return documentIsValid;
    };

    const createAccountClick = () => {
        // if (angular.isDefined(product)) {
        //     $scope.productDetail = product;
        //     $scope.previousStep = $scope.step.STEP_SUB_DASHBOARD;
        // }
        // const { productKey } = $scope.productDetail;
        // $scope.switchBG(productKey);
        if (isNotExistedAccount) {
            // need to confirm that only apply for passport????
            // Passport: missing info --> step passport info. Not missing --> step input amount
            if (checkValidDocumentInfo) {
                onNext(VINA_WEALTH_STEPS.INPUT_AMOUNT);
            } else {
                onNext(VINA_WEALTH_STEPS.PASSPORT_INFO);
            }
        } else {
            onNext(VINA_WEALTH_STEPS.INPUT_AMOUNT);
        }
    };

    return (
        <Content size="xs">
            <PageHeaderBack onBack={onBack}>
                {productDetail.FETitle}
            </PageHeaderBack>
            <Box>
                <Box className={classes.contentIntro}>
                    <Box>
                        <Box className={classes.subDes}>
                            <Interweave content={productDetail.FESubdesc} />
                        </Box>
                        <ul className={classes.overView}>
                            {productDetail.FEOverview.map((item) => (
                                <li className={classes.contentItem}>
                                    <Interweave content={item} />
                                </li>
                            ))}
                        </ul>
                    </Box>
                    <Box className={classes.introBtn}>
                        <LspButton
                            type="submit"
                            fullWidth
                            onClick={createAccountClick}
                        >
                            {productDetail.FEBtnText}
                        </LspButton>
                    </Box>
                </Box>
                <Box className={classes.whyVC}>
                    <span>
                        <i className="font-icon icon-hint" />
                        {t("wealth:vc3_why_vc_lb")}
                    </span>
                    <div>{t("wealth:vc3_why_vc_content")}</div>
                </Box>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    productDetail: state.vinaWealth.productDetail,
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    clearProductDetailStore: () =>
        dispatch(vinaWealthAction.clearProductDetail()),
});

export default connect(mapState, mapDispatch)(VinaWealthDetail);
