import LspCheckbox from "@components/LspCheckbox";
import {
    Box,
    ButtonBase,
    Divider,
    fade,
    makeStyles,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import Interweave from "interweave";
import { isFunction } from "lodash";
import { memo, useCallback } from "react";

const useStyles = makeStyles((theme) => ({
    button: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        "&:hover": {
            background: fade(theme.palette.neutral.grey2, 0.16),
        },
    },
    unread: {
        background: fade(theme.palette.neutral.grey1, 0.16),
        "&:hover": {
            background: fade(theme.palette.neutral.grey1, 0.24),
        },
    },
    emphasized: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    emphasizedCheckbox: {
        color: theme.palette.primary.contrastText,
        "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.contrastText,
        },
    },
    content: {
        textAlign: "left",
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.6,
        "& p": {
            margin: 0,
        },
    },
    item: {
        paddingRight: theme.spacing(2),
    },
}));

const NotificationListItem = ({
    notificationId,
    title,
    content,
    selected,
    onClick,
    className,
    toggleSelection,
    groupId,
    ...otherProps
}) => {
    const classes = useStyles();

    const selectItemHandler = useCallback(() => {
        if (isFunction(toggleSelection)) {
            toggleSelection(!selected, notificationId, groupId);
        }
    }, [toggleSelection, notificationId, groupId, selected]);

    const onClickHandler = () => {
        onClick({
            title,
            iD: notificationId,
            ...otherProps,
        });
    };

    return (
        <>
            <Box display="flex" className={classes.box}>
                <ButtonBase
                    classes={{
                        root: clsx(classes.button, {
                            [classes.unread]: !otherProps.read,
                        }),
                    }}
                >
                    <Box
                        py={1}
                        px={0.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <LspCheckbox
                            onChange={selectItemHandler}
                            checked={selected}
                            className={className}
                        />
                    </Box>
                    <Box
                        paddingY={2}
                        flex={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        onClick={onClickHandler}
                        className={classes.item}
                    >
                        <Box
                            display="flex"
                            alignItems="flex-end"
                            pb={1}
                            width="100%"
                        >
                            <Box
                                component={Typography}
                                variant="body2"
                                flex={1}
                                fontWeight={600}
                                textAlign="left"
                            >
                                <b>{title}</b>
                            </Box>
                            {/* <Typography variant="body2">
                                {createdDate}
                            </Typography> */}
                        </Box>
                        <Interweave
                            content={content}
                            attributes={{
                                className: classes.content,
                            }}
                        />
                    </Box>
                </ButtonBase>
            </Box>
            <Divider />
        </>
    );
};

export default memo(NotificationListItem);
