import Content from "@components/Content";
import { Icon, makeStyles, Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    // wrapper: {},
    QRCodeWrapper: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        textAlign: "center",
    },
    iconClose: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: theme.spacing(2),
        cursor: "pointer",
    },
    QRCodeContent: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
        },
    },
    text: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.blue.main,
        fontWeight: 600,
    },
}));

const QRCodeDialog = ({ payLink, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Content size="sm">
            <div className={classes.QRCodeWrapper}>
                <div className={classes.iconClose}>
                    <Icon
                        className={`font-icon icon-close `}
                        onClick={onClose}
                    />
                </div>
                <div className={classes.QRCodeContent}>
                    <QRCode
                        renderAs="svg"
                        value={payLink}
                        height="100%"
                        width="100%"
                    />
                </div>
                <Typography className={classes.text}>
                    {t("payAnyone:share_qr_code_payment")}
                </Typography>
            </div>
        </Content>
    );
};

export default QRCodeDialog;
