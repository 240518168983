import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const usePhoneNumber = () => {
    const { t } = useTranslation();

    const phoneRegex = useMemo(() => {
        return new RegExp(
            t("sysParamContent:PhoneNumberValidationRegexPattern")
        );
    }, [t]);

    const formatPhoneNumber = useCallback((phone = "") => {
        if (phone === "0123456789" || phone.length < 4) {
            return "";
        }
        if (phone.substring(0, 2) === "84") {
            return `0${phone.substring(2)}`;
        }
        if (phone.substring(0, 3) === "+84") {
            return `0${phone.substring(3)}`;
        }
        if (phone.substring(0, 4) === "0084") {
            return `0${phone.substring(4)}`;
        }
        return phone;
    }, []);

    const isPhoneNumberValid = useCallback(
        (phone = "", regex = null) => {
            if (!regex) {
                const result = phoneRegex?.test(phone);
                return result;
            }

            const rules = new RegExp(regex);
            return rules?.test(phone);
        },
        [phoneRegex]
    );

    const phoneNumberFormat = useMemo(
        () => ({
            formatPhoneNumber,
            isPhoneNumberValid,
        }),
        [formatPhoneNumber, isPhoneNumberValid]
    );

    return phoneNumberFormat;
};

export default usePhoneNumber;
