import api from "../config/api";

const createNewQuickCode = async (payload) => {
    return await api.post("/user/quickCode", payload, {
        bypassErrorHandler: true,
    });
};

const checkValidQuickCode = async (payload) => {
    return await api.get("/login/quickCode/check", payload, {
        headers: payload,
    });
};

const forgotQuickCode = async () => {
    return await api.get("/login/quickCode/forget");
};

const checkExistingQuickCode = async () => {
    return await api.get("/login/quickCode/checkExist", null, {
        bypassErrorHandler: true,
    });
};

const quickCodeService = {
    createNewQuickCode,
    checkValidQuickCode,
    forgotQuickCode,
    checkExistingQuickCode,
};

export default quickCodeService;
