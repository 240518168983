import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspCardList from "@components/LspCardList";

import PageHeaderBack from "@components/PageHeaderBack";
import { RESPONSE_CODE } from "@config/constants";
import {
    BOOKING_ACTIONS,
    BOOKING_SERVICES,
    BOOKING_STEPS,
    BOOKING_TYPES,
} from "@containers/Booking/constant";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, makeStyles } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import bookingService from "@services/booking";
import Interweave from "interweave";
import { useState, useRef, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
    list: {
        "& li": {
            minHeight: "auto",
            textTransform: "uppercase",
        },
    },
}));

const CreateBookingProvinceSelection = ({
    serviceName,
    onCancel,
    onNext,
    onBack,
    setBookingHangoutProvince,
    type,
}) => {
    const { t, i18n } = useTranslation();
    const unmounted = useRef(false);
    const [provinceList, setProvinceList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onSelectProvince = useCallback(
        (province) => {
            setBookingHangoutProvince(province);
            onNext(BOOKING_STEPS.HANGOUT_LOCATION_SELECTION);
        },
        [onNext, setBookingHangoutProvince]
    );

    const getProvinceList = useCallback(async () => {
        let bookingType = "";
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                bookingType = BOOKING_TYPES.KYC;
                break;
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
                bookingType = BOOKING_TYPES.REPLACE_CHIP_CARD;
                break;
            default:
                break;
        }

        setFetching(true);

        const payload = {
            services: bookingType.split(","),
            lang: i18n.language,
        };
        const response = await bookingService.getProvinceHangout(payload);
        if (unmounted.current) return;
        setFetching(false);
        const { code, data } = response.data;
        if (code !== RESPONSE_CODE.SUCCESS) {
            GlobalDialogController.showError({ errorCode: code });
            return;
        }

        const provinces = data?.result?.map((item) => ({
            ...item,
            id: item?.provinceId,
            title: item?.provinceName,
        }));

        setProvinceList(provinces);
    }, [i18n?.language, serviceName]);

    useEffect(() => {
        getProvinceList();
    }, [getProvinceList]);

    return (
        <Content size="sm">
            <PageHeaderBack
                onBack={type === BOOKING_ACTIONS.NEW_BOOKING ? onBack : null}
            >
                <Interweave
                    content={t("ekyc:choose_your_title_web")}
                    containerTagName="div"
                />
            </PageHeaderBack>
            <LspCardList
                list={provinceList}
                onClickItem={onSelectProvince}
                variant="primary"
                className={classes?.list}
                fetching={fetching}
            />
            <Box mt={3}>
                <LspButton fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    detail: state.booking.detail,
    type: state.booking.type,
    serviceName: state.booking.serviceName,
});

const mapDispatch = (dispatch) => ({
    setBookingHangoutProvince: (payload) =>
        dispatch(bookingActions.setBookingHangoutProvince(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingProvinceSelection);
