/* ------------- Types and Action Definition ------------- */
export const RequestDocumentTypes = Object.freeze({
    SetDocumentInfo: "RequestDocumentTypes/SetDocumentInfo",
    ResetDocumentInfo: "RequestDocumentTypes/ResetDocumentInfo",
});

/* ------------- Actions Definition ------------- */

const setDocumentInfo = (payload) => {
    return {
        type: RequestDocumentTypes.SetDocumentInfo,
        payload,
    };
};

const resetDocumentInfo = () => {
    return {
        type: RequestDocumentTypes.ResetDocumentInfo,
    };
};

const RequestDocumentActions = {
    setDocumentInfo,
    resetDocumentInfo,
};

export default RequestDocumentActions;
