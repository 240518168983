import { useMemo, useState, useEffect, useCallback, memo } from "react";
import { Box, Icon, IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import { cloneDeep, isFunction } from "lodash";
import { Logo } from "@components/LspBranding";
import {
    CheckCircleIcon,
    ResendIcon,
    VisibilityOffIcon,
    VisibilityOnIcon,
    EditIcon,
    WarningIcon2,
} from "@components/LspIcon";
import LspBreakLine from "@components/LspBreakLine";
import AlertController from "@helpers/controllers/AlertController";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

import LspAttachment from "@components/Attachment/LspAttachment";
import clsx from "clsx";
import useNumber from "../../helpers/useNumber";
import ReceiptLoading from "./ReceiptLoading";

const RECEIPT_STATUS = {
    SUCCESS: 0,
    PENDING: 1,
};

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            "& > div": {
                width: "100%",
            },
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.default,

            "&:first-child": {
                marginTop: theme.spacing(3),
                borderRadius: theme.shape.radiusLevels[3],
            },
        },
        header: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",

            "& > div:nth-child(2)": {
                margin: theme.spacing(1, 0),
            },
        },
        headerTitle: {
            flex: 1,
        },
        headerAction: {
            position: "absolute",
            right: theme.spacing(2),
        },
        headerIcon: {
            maxWidth: 90,
            "& img": {
                width: "100%",
                height: "100%",
            },
        },
        headerId: {
            flex: 1,
            display: "inline-flex",
            justifyContent: "flex-end",
        },
        headerText: {
            color: theme.palette.magicalGrey[400],
            textAlign: "right",
            cursor: "pointer",
        },
        container: {},
        body: {
            boxShadow: theme.shadows[2],
            borderRadius: theme.shape.radiusLevels[3],
            background: theme.palette.background.paper,
        },
        receiptStatus: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(2, 0),
            background: theme.palette.green.main,
            borderTopLeftRadius: theme.shape.radiusLevels[3],
            borderTopRightRadius: theme.shape.radiusLevels[3],
        },
        receiptWarningStatus: {
            background: theme.palette.gold.main,
        },
        receiptStatusIcon: {
            marginRight: theme.spacing(0.75),
            color: theme.palette.neutral.white,
        },
        receiptHeader: {
            padding: theme.spacing(2, 3),
        },
        receiptHeaderTitle: {
            marginBottom: theme.spacing(0.25),
            "&:last-child": {
                marginBottom: 0,
            },
        },
        receiptHeaderDate: {
            color: theme.palette.magicalGrey[400],
            marginBottom: theme.spacing(0.25),
            "&:last-child": {
                marginBottom: 0,
            },
        },
        receiptHeaderAmount: {
            marginBottom: theme.spacing(2),
            "&:last-child": {
                marginBottom: 0,
            },
        },
        receiptInfo: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(4, 2),
            alignItems: "center",
        },
        receiptInfoBox: {
            width: 370,
            paddingBottom: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.background.grey}`,
            "&:last-child": {
                paddingBottom: 0,
                marginBottom: 0,
                borderBottom: "none",
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        receiptInfoFrom: {},
        receiptInfoTo: {},
        receiptInfoNotes: {},
        receiptFromControl: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginBottom: theme.spacing(2),
            "&:last-child": {
                marginBottom: 0,
            },
        },
        receiptFromControlLeft: {
            width: 80,
            textAlign: "left",
        },
        receiptFromControlRight: {
            flex: 1,
            textAlign: "left",
        },
        receiptFormLabel: {
            color: theme.palette.magicalGrey[400],
            textAlign: "left",
        },
        receiptFormTitle: {
            color: theme.palette.magicalGrey[400],
            textAlign: "left",
        },
        line: {
            margin: theme.spacing(2),
            "&:nth-first-child": {
                marginTop: 0,
            },
            "&:nth-last-child": {
                marginBottom: 0,
            },
        },
        divider: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
        reviewReceipt: {
            borderRadius: "unset",
            backgroundColor: theme.palette.background.default,
        },
        noMarginTop: {
            "&:first-child": {
                marginTop: 0,
            },
        },
        eyeIcon: {
            "& svg": {
                width: 16,
                height: 16,
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        },
        notes: {
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.background.paper,
            textAlign: "left",
            boxShadow: theme.shadows[2],
            cursor: "pointer",
        },
        notesHeader: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: theme.spacing(1.5),
        },
        notesHeaderIcon: {
            color: theme.palette.magicalGrey[400],
            marginRight: theme.spacing(3),
        },
        paymentDetail: {
            color: theme.palette.blue.main,
        },
    };
});

/**
 * @param {object} props
 * @param {string} props.button
 * @param {function} props.onClose
 * @param {object} props.receiptData
 * @param {string} props.receiptData.title
 * @param {number} props.receiptData.txnAmount
 * @param {string} [props.receiptData.txnCurrency='VND']
 * @param {[string]} [props.receiptData.txnFrom]
 * @param {[string]} props.receiptData.txnTo
 * @param {string} props.receiptData.txnDesc
 * @param {boolean} props.loading
 * @param {object} props.options
 * @param {function} props.options.onCompleteSendAgainHandler
 * @param {boolean} props.options.enableSendAgainButton
 * @param {boolean} props.enableAddAttachment
 * @param {string} props.attachmentType
 * @returns
 */

const ReceiptContent = ({
    receiptData,
    onClose,
    button,
    loading,
    noMarginTop,
    noReceiptStatus,
    options,
    enableAddNote,
    onAddNote,
    note,
    onHandlePaymentDetailSubmit,
    onAddAttachment,
    attachments,
    onViewAttachment,
    uploadAttachments,
    refNo,
    onUploadAttachmentSuccess,
    enableAddAttachment,
    attachmentType,
    fileManageId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const { enableSendAgainButton, onCompleteSendAgainHandler } = options || {};

    const [fromInfo, setFromInfo] = useState(null);
    const [toInfo, setToInfo] = useState(null);

    const isHasNote = useMemo(() => note && note.length > 0, [note]);

    const toggleHideInfo = (index, isFromTxn) => {
        if (isFromTxn) {
            setFromInfo((prev) => {
                // eslint-disable-next-line prefer-const
                const prevList = cloneDeep(prev);
                prevList[index].hideStatus = !prev[index]?.hideStatus;
                return prevList;
            });
        } else {
            setToInfo((prev) => {
                // eslint-disable-next-line prefer-const
                const prevList = cloneDeep(prev);
                prevList[index].hideStatus = !prev[index]?.hideStatus;
                return prevList;
            });
        }
    };

    const maskedValue = useCallback((value, isMask) => {
        if (!isMask) return value;

        let string = "";
        for (let i = 0; i < value?.length; i++) {
            string += "*";
        }
        return string;
    }, []);

    const getIcon = useCallback(
        ({ index, enableHideInfo, hideStatus, isFromTxn }) => {
            if (!enableHideInfo) return <></>;

            return (
                <IconButton
                    size="small"
                    onClick={() => toggleHideInfo(index, isFromTxn)}
                    className={classes.eyeIcon}
                >
                    {hideStatus ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                </IconButton>
            );
        },
        [classes.eyeIcon]
    );

    const handleShowFromData = useCallback(
        (info, index, isFromTxn) => {
            const { value, enableHideInfo, hideStatus } = info || {};
            let variant = "heading2";
            let color = "black";

            if (index === 1) {
                variant = "subheading2";
                color = "grey";
            } else if (index === 2) {
                variant = "body1";
                color = "grey";
            }

            return (
                <Box display="flex" alignItems="center">
                    <LspTypography variant={variant} color={color}>
                        {maskedValue(value, hideStatus)}
                    </LspTypography>
                    {getIcon({ index, enableHideInfo, hideStatus, isFromTxn })}
                </Box>
            );
        },
        [maskedValue, getIcon]
    );

    const handleCopy = (data) => {
        navigator.clipboard.writeText(data);
        AlertController.show(t("member:timostarclub_msg_copy_successful"));
    };

    const isExistTXTFrom = useMemo(() => {
        return receiptData?.txnFrom.length > 0;
    }, [receiptData?.txnFrom]);

    const isExistTXTTo = useMemo(() => {
        return receiptData?.txnTo.length > 0;
    }, [receiptData?.txnTo]);

    const isEnableSendAgainButton = useMemo(() => {
        if (!enableSendAgainButton) return false;

        if (receiptData?.txnAmount > 0) return false;

        const { bankId, accNo, accName } = receiptData?.rawTxnTo || {};
        if (!bankId || !accNo || !accName) return false;

        return true;
    }, [receiptData, enableSendAgainButton]);

    const transferAgain = () => {
        GlobalDialogController.hide();

        const { bankId, accNo, accName, branchId, provinceId } =
            receiptData?.rawTxnTo || {};
        if (!bankId || !accNo || !accName) return;

        const amount = Math.abs(receiptData?.txnAmount) || 0;
        const transferInfo = {
            accountNumber: accNo, // use for bank transfer & internal transfer
            accName, // use for bank transfer
            cardNumber: accNo, // use for card transfer
            amount: {
                formattedValue: formatNumber(amount),
                value: amount,
            },
            filledDescription: receiptData?.txnDesc,
            bankId,
            branchId,
            provinceId,
        };

        localStorage.setItem(
            "transferDestination",
            JSON.stringify(transferInfo)
        );
        if (isFunction(onCompleteSendAgainHandler)) {
            onCompleteSendAgainHandler(receiptData?.rawTxnTo);
        }
    };

    useEffect(() => {
        if (!receiptData?.txnFrom) return;
        setFromInfo(receiptData?.txnFrom);
    }, [receiptData?.txnFrom]);

    useEffect(() => {
        if (!receiptData?.txnTo) return;
        setToInfo(receiptData?.txnTo);
    }, [receiptData?.txnTo]);

    const handleClickAddNote = () => {
        if (isFunction(onAddNote)) {
            onAddNote();
        }
    };

    const handlePaymentDetail = () => {
        if (isFunction(onHandlePaymentDetailSubmit)) {
            onHandlePaymentDetailSubmit();
        }
    };

    const isShowPaymentDetail = useMemo(() => {
        return receiptData?.feeAmount && receiptData?.feeAmount < 0;
    }, [receiptData?.feeAmount]);

    const isStatusPending = useMemo(() => {
        return receiptData?.txnStatus === RECEIPT_STATUS.PENDING;
    }, [receiptData?.txnStatus]);

    const receiptStatus = useMemo(() => {
        switch (receiptData?.txnStatus) {
            case RECEIPT_STATUS.SUCCESS:
                return (
                    <>
                        <CheckCircleIcon
                            vb={24}
                            className={classes.receiptStatusIcon}
                        />

                        <LspTypography
                            variant="subheading2"
                            component="span"
                            color="white"
                        >
                            {t("txnapptype:receipt_signal_status")}
                        </LspTypography>
                    </>
                );

            default:
                return (
                    <>
                        <WarningIcon2
                            vb={20}
                            className={classes.receiptStatusIcon}
                        />

                        <LspTypography
                            variant="subheading2"
                            component="span"
                            color="white"
                        >
                            {t("txnapptype:receipt_signal_status_pending")}
                        </LspTypography>
                    </>
                );
        }
    }, [classes.receiptStatusIcon, receiptData, t]);

    return (
        <div
            className={`${classes.wrapper} ${
                noMarginTop ? classes.noMarginTop : ""
            }`}
        >
            <>
                <Box p={3} pb={2} className={classes.header}>
                    <div className={classes.headerIcon}>
                        <Logo variant="pao" />
                    </div>
                    {(isExistTXTFrom || isExistTXTTo) && (
                        <div className={classes.headerId}>
                            <LspTypography
                                className={classes.headerText}
                                onClick={() => handleCopy(receiptData?.txnId)}
                                variant="body2"
                                component="span"
                            >
                                {t("txnapptype:receipt_id")}{" "}
                                {receiptData?.txnId}
                                <span className="icon-copy" />
                            </LspTypography>
                        </div>
                    )}
                    {loading && (
                        <Box p={3} pb={2} className={classes.headerId}>
                            <div className={classes.headerText}>
                                <Skeleton variant="text" width={200} />
                            </div>
                        </Box>
                    )}
                </Box>
                <Box p={3} pt={0} className={classes.container}>
                    <div className={classes.body}>
                        {!noReceiptStatus && (
                            <div
                                className={clsx(
                                    classes.receiptStatus,
                                    isStatusPending &&
                                        classes.receiptWarningStatus
                                )}
                            >
                                {!loading && receiptStatus}

                                {loading && (
                                    <div className={classes.headerText}>
                                        <Skeleton variant="text" width={200} />
                                    </div>
                                )}
                            </div>
                        )}
                        <Box className={classes.receiptHeader}>
                            {loading && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Skeleton
                                        variant="text"
                                        width={200}
                                        component="div"
                                    />
                                    <Skeleton variant="text" width={250} />
                                    <Skeleton
                                        variant="text"
                                        width={300}
                                        height={40}
                                    />
                                </Box>
                            )}
                            {!loading && receiptData?.title && (
                                <LspTypography
                                    variant="heading1"
                                    component="div"
                                    color="black"
                                    className={classes.receiptHeaderTitle}
                                >
                                    {receiptData?.title}
                                </LspTypography>
                            )}

                            {!loading && receiptData?.txnDate && (
                                <LspTypography
                                    className={classes.receiptHeaderDate}
                                    variant="body2"
                                    component="div"
                                >
                                    {receiptData?.txnDate}
                                </LspTypography>
                            )}

                            {!loading && receiptData?.txnAmount && (
                                <LspTypography component="div" variant="title1">
                                    {formatNumber(receiptData?.txnAmount)}{" "}
                                    {receiptData?.txnCurrency}
                                </LspTypography>
                            )}
                            {isShowPaymentDetail && (
                                <LspTypography
                                    component="div"
                                    variant="hyperlink1"
                                    className={classes.paymentDetail}
                                    onClick={handlePaymentDetail}
                                >
                                    {t("txnapptype:receipt_payment_detail")}
                                </LspTypography>
                            )}
                        </Box>
                        <LspBreakLine />
                        <Box className={classes.receiptInfo}>
                            {loading && <ReceiptLoading classes={classes} />}

                            {!loading && isExistTXTFrom && (
                                <div className={classes.receiptInfoBox}>
                                    <div className={classes.receiptInfoFrom}>
                                        <div
                                            className={
                                                classes.receiptFromControl
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.receiptFromControlLeft
                                                }
                                            >
                                                <LspTypography
                                                    component="div"
                                                    variant="subheading2"
                                                    color="grey"
                                                >
                                                    {t(
                                                        "txnapptype:receipt_from"
                                                    )}
                                                </LspTypography>
                                            </div>
                                            <div
                                                className={
                                                    classes.receiptFromControlRight
                                                }
                                            >
                                                {fromInfo?.map((r, index) => {
                                                    return (
                                                        <div
                                                            key={Math.random()}
                                                        >
                                                            {handleShowFromData(
                                                                r,
                                                                index,
                                                                true
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!loading && isExistTXTTo && (
                                <div className={classes.receiptInfoBox}>
                                    <div className={classes.receiptInfoTo}>
                                        <div
                                            className={
                                                classes.receiptFromControl
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.receiptFromControlLeft
                                                }
                                            >
                                                <LspTypography
                                                    component="div"
                                                    variant="subheading2"
                                                    color="grey"
                                                >
                                                    {t("txnapptype:receipt_to")}
                                                </LspTypography>
                                            </div>
                                            <div
                                                className={
                                                    classes.receiptFromControlRight
                                                }
                                            >
                                                {toInfo?.map((r, index) => {
                                                    return (
                                                        <div
                                                            key={Math.random()}
                                                        >
                                                            {handleShowFromData(
                                                                r,
                                                                index
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!loading && receiptData?.txnDesc && (
                                <div className={classes.receiptInfoBox}>
                                    <div className={classes.receiptInfoNotes}>
                                        <div
                                            className={
                                                classes.receiptFromControl
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.receiptFromControlLeft
                                                }
                                            >
                                                <LspTypography
                                                    component="div"
                                                    variant="subheading2"
                                                    color="grey"
                                                >
                                                    {t(
                                                        "txnapptype:receipt_des"
                                                    )}
                                                </LspTypography>
                                            </div>
                                            <div
                                                className={
                                                    classes.receiptFromControlRight
                                                }
                                            >
                                                <LspTypography
                                                    component="div"
                                                    variant="body1"
                                                >
                                                    {receiptData?.txnDesc}
                                                </LspTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Box>
                    </div>
                </Box>

                {enableAddNote && !isHasNote && !loading && (
                    <Box px={3} pb={1.5}>
                        <LspButton
                            type="button"
                            onClick={handleClickAddNote}
                            fullWidth
                            variant="secondary"
                            buttonProps={{
                                startIcon: <EditIcon />,
                            }}
                        >
                            {t("txnapptype:receipt_btn_add_note")}
                        </LspButton>
                    </Box>
                )}

                {enableAddNote && isHasNote && !loading && (
                    <Box px={3} pb={1.5}>
                        <div
                            className={classes.notes}
                            onClick={handleClickAddNote}
                        >
                            <LspTypography
                                variant="subheading3"
                                component="div"
                                className={classes.notesHeader}
                            >
                                <EditIcon className={classes.notesHeaderIcon} />

                                {t("txnapptype:receipt_btn_edit_note")}
                            </LspTypography>
                            <LspTypography
                                variant="body1"
                                component="div"
                                className={classes.notesHeader}
                            >
                                {note}
                            </LspTypography>
                        </div>
                    </Box>
                )}

                {enableAddAttachment && (
                    <LspAttachment
                        attachments={attachments}
                        onViewAttachment={onViewAttachment}
                        onAddAttachment={onAddAttachment}
                        uploadAttachments={uploadAttachments}
                        refNo={refNo}
                        onUploadAttachmentSuccess={() =>
                            onUploadAttachmentSuccess()
                        }
                        attachmentType={attachmentType}
                        fileManageId={fileManageId}
                    />
                )}

                <Box px={3} pb={1.5}>
                    {isEnableSendAgainButton && (
                        <>
                            <LspButton
                                type="button"
                                onClick={transferAgain}
                                fullWidth
                                variant="secondary"
                                buttonProps={{
                                    startIcon: <ResendIcon />,
                                }}
                            >
                                {t("txnapptype:receipt_btn_repeat_txn")}
                            </LspButton>
                        </>
                    )}
                </Box>
                <Box px={3} pb={3}>
                    {isFunction(onClose) && (
                        <>
                            {isFunction(onClose) && (
                                <LspButton
                                    type="button"
                                    onClick={onClose}
                                    fullWidth
                                    variant="plain"
                                    buttonProps={{
                                        id: "closeBtn",
                                    }}
                                >
                                    {button ||
                                        t("txnapptype:receipt_close_btn")}
                                </LspButton>
                            )}
                        </>
                    )}
                </Box>
            </>
        </div>
    );
};

ReceiptContent.propTypes = {
    noReceiptStatus: PropTypes.bool,
    noMarginTop: PropTypes.bool,
    options: PropTypes.shape({
        enableSendAgainButton: PropTypes.bool,
        onCompleteSendAgainHandler: PropTypes.func,
    }),
    enableAddNote: PropTypes.bool,
    enableAddAttachment: PropTypes.bool,
    attachmentType: PropTypes.number,
};

ReceiptContent.defaultProps = {
    noMarginTop: false,
    noReceiptStatus: false,
    options: {
        enableSendAgainButton: false,
        onCompleteSendAgainHandler: null,
    },
    enableAddNote: true,
    enableAddAttachment: false,
    attachmentType: 0,
};
export default memo(ReceiptContent);
