import { useCallback, useRef, memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import logo from "@assets/images/logo-liberty.png";
import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import LspCardList from "@components/LspCardList";
import wealthService from "@services/wealth";
import {
    WEALTH_NAVIGATION_KEYS,
    WEALTH_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import { DEEP_LINK_CONFIGS, RESPONSE_CODE } from "@config/constants";
import useWealth from "@containers/Wealth/useWealth";
import querystring from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import WealthInsuranceNotification from "./WealthInsuranceNotification";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        padding: theme.spacing(4, 0),
        "& img": {
            width: "100%",
            maxWidth: 200,
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
}));

const WealthInsuranceDashboard = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const [catalogueList, setCatalogueList] = useState(null);
    const { getWealthIcon } = useWealth();
    const history = useHistory();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { action, productKey, result } = params;

    const unmounted = useRef(false);

    const getCatalogues = useCallback(async () => {
        setFetching(true);
        const response = await wealthService.getSubProducts(
            WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE
        );

        if (unmounted.current) return;
        setFetching(false);

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const list = data?.subProducts?.map((item) => ({
                    ...item,
                    id: item?.productKey,
                    title: t(`wealth:GE00001_${item?.productKey}`),
                    icon: getWealthIcon(item?.productKey),
                    isComingSoon:
                        item?.productKey !==
                        WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_TRAVEL,
                }));

                setCatalogueList(list);
                break;
            default:
                break;
        }
    }, [getWealthIcon, t]);

    useEffect(() => {
        getCatalogues();
    }, [getCatalogues]);

    useEffect(() => {
        if (action && productKey && result) {
            // eslint-disable-next-line prefer-const
            let content = {};

            switch (productKey) {
                case WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_TRAVEL:
                    if (action === DEEP_LINK_CONFIGS.ACTION.PUSH) {
                        const isSuccess =
                            result === DEEP_LINK_CONFIGS.STATUS.SUCCESS;
                        content = {
                            header: isSuccess
                                ? t("lbi_travel_label_tittle_popup_success")
                                : t("lbi_travel_label_tittle_popup_fail"),
                            content: isSuccess
                                ? t("lbi_travel_message_apply_success")
                                : t("lbi_travel_message_apply_fail"),
                            otherContent: isSuccess
                                ? t("wealth:GE00056")
                                : t("wealth:lbi_travel_end_contact_failed"),
                            button: {
                                label: t("lb_ok"),
                                onClick: () => {
                                    GlobalDialogController.hide();
                                    history.push("/wealth/insurance/dashboard");
                                },
                            },
                            isSuccess,
                        };
                    }
                    break;
                case WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_MOTORBIKE:
                    break;
                default:
                    break;
            }

            GlobalDialogController.show({
                component: () => (
                    <WealthInsuranceNotification content={content} />
                ),
            });
        }
    }, [action, productKey, result, t, history]);

    const onClickItemHandler = (item) => {
        switch (item?.productKey) {
            case WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_TRAVEL:
                history.push(WEALTH_NAVIGATION_KEYS.TRAVEL_INSURANCE);
                break;
            case WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_MOTORBIKE:
            default:
                // nothing to do
                break;
        }
    };

    return (
        <Content size="xs">
            <div className={classes.wrapper}>
                <img alt="logo" src={logo} />
                <LspTypography>
                    {t("wealth:lbi_introduction_message")}
                </LspTypography>
                <LspTypography variant="heading2">
                    {t("wealth:lbi_introduction_message_highlight")}
                </LspTypography>
                <br />
                <br />

                <LspCardList
                    list={catalogueList}
                    onClickItem={onClickItemHandler}
                    size="large"
                    variant="primary"
                    fetching={fetching}
                />
            </div>
        </Content>
    );
};

export default memo(WealthInsuranceDashboard);
