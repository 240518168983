import { AccountType, BalanceVisibility } from "@config/constants";
import { useCallback, useMemo } from "react";

import { shallowEqual, useSelector } from "react-redux";

/**
 * @typedef {object} SpendAccount
 * @property {number} workingBalance - The balance that user can spend
 * @property {string} accountType
 * @property {string} name
 * @property {string} no - The account number
 *
 * @typedef {object} CreditCard
 * @property {number} workingBalance - The balance that user can spend
 * @property {string} accountType
 * @property {string} name
 * @property {string} no - The account number
 * @property {number} availableAmount - The balance that user owe the bank (credit card debt)
 * @property {number} payeeId
 *
 * @typedef {object} TermDeposit
 * @property {number} workingBalance - The balance that user can spend
 * @property {string} accountType
 * @property {string} name
 * @property {string} no - The account number
 */

const MIN_VALID_DOCUMENT_PERIOD_CARD = 360;
const useAccounts = () => {
    const accountList = useSelector(
        (state) => state.account.list,
        shallowEqual
    );

    const user = useSelector((state) => state.user.info, shallowEqual);
    const featureState = useSelector(
        (state) => state.user.featureState.data,
        shallowEqual
    );

    /** @type {SpendAccount} */
    const spendAccount = useMemo(
        () =>
            accountList
                ? accountList.find(
                      (acc) => acc.accountType === AccountType.SpendAccount
                  )
                : null,
        [accountList]
    );

    /** @type {CreditCard} */
    const creditCard = useMemo(
        () =>
            accountList
                ? accountList.find(
                      (acc) => acc.accountType === AccountType.CreditCard
                  )
                : null,
        [accountList]
    );

    /** @type {TermDeposit} */
    const termDeposit = useMemo(
        () =>
            accountList
                ? accountList.find(
                      (acc) => acc.accountType === AccountType.TermDeposit
                  )
                : null,
        [accountList]
    );

    /** @type {GoalSave} */
    const goalSave = useMemo(
        () =>
            accountList
                ? accountList.find(
                      (acc) => acc.accountType === AccountType.GoalSave
                  )
                : null,
        [accountList]
    );

    const allowAccessCredit = useMemo(() => {
        if (!creditCard) {
            return false;
        }

        if (creditCard?.no === AccountType.CreditCardOnline) {
            return false;
        }

        if (!!creditCard?.status && creditCard?.status !== "4") {
            return false;
        }

        return true;
    }, [creditCard]);

    const isBalanceHidden = useMemo(() => {
        return [
            BalanceVisibility.Hide,
            BalanceVisibility.HideAndNoHint,
        ].includes(user?.hideBalance);
    }, [user]);

    const isCardFeatureNotAvailableForeigner = useMemo(() => {
        const isForeigner = user?.isForeigner;
        const proofPeriodDays = user?.proofPeriod;

        if (
            isForeigner &&
            (!proofPeriodDays ||
                proofPeriodDays === "" ||
                parseInt(proofPeriodDays) < MIN_VALID_DOCUMENT_PERIOD_CARD)
        ) {
            return true;
        }
        return false;
    }, [user]);

    const isOverDraftUser = useMemo(
        () => featureState?.enableOverDraft && spendAccount?.isOverDraftUser,
        [featureState?.enableOverDraft, spendAccount?.isOverDraftUser]
    );

    const availableAmount = useMemo(() => {
        return spendAccount?.availableAmount;
    }, [spendAccount]);

    const overdraftLimit = useMemo(() => {
        return spendAccount?.limitAmount;
    }, [spendAccount]);

    // For display on UI
    const getCurrentBalance = useCallback(
        (canUseOverdraft = false) => {
            let balance = spendAccount?.workingBalance;

            if (isOverDraftUser) {
                balance =
                    !canUseOverdraft && availableAmount < 0
                        ? 0
                        : availableAmount;
            }

            return balance;
        },
        [spendAccount, isOverDraftUser, availableAmount]
    );

    // for validate amount function
    const getAvailableToSpend = useCallback(
        (canUseOverdraft = false) => {
            let balance = spendAccount?.workingBalance;

            if (isOverDraftUser && !canUseOverdraft && availableAmount < 0) {
                balance = 0;
            }

            return balance;
        },
        [spendAccount, isOverDraftUser, availableAmount]
    );

    const isLockedOverDraft = useMemo(() => {
        return spendAccount?.isLockedOverDraft;
    }, [spendAccount?.isLockedOverDraft]);

    return {
        spendAccount,
        creditCard,
        termDeposit,
        goalSave,
        allowAccessCredit,
        isBalanceHidden,
        isCardFeatureNotAvailableForeigner,
        getCurrentBalance,
        isOverDraftUser,
        overdraftLimit,
        getAvailableToSpend,
        availableAmount,
        isLockedOverDraft,
    };
};

export default useAccounts;
