/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useRef, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import PageHeaderBack from "@components/PageHeaderBack";
import LspCardSelection from "@components/LspCardSelection";

import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { WEALTH_PRODUCT_KEYS } from "@containers/Wealth/wealthConstants";
import LspButton from "@components/LspButton";

const WealthTravelPackageSelection = ({
    onBack,
    onNext,
    packageList,
    travelDate,
    destination,
    attendant,
    setSelectedPackage,
    selectedPackage,
    setPackageList,
}) => {
    const { t } = useTranslation();

    const unmounted = useRef(false);
    const [fetching, setFetching] = useState(false);
    const firstLoaded = useRef(false);

    const onClickItem = (item) => {
        setSelectedPackage(item);
    };

    const getPackages = useCallback(async () => {
        firstLoaded.current = true;
        setFetching(true);
        // eslint-disable-next-line prefer-const
        let requestInfo = {
            areaCode: destination?.id,
            fromDate: travelDate?.fromDate,
            toDate: travelDate?.toDate,
            policyType: attendant?.type,
            adults: attendant?.adult,
        };

        if (attendant?.child || attendant?.child === 0) {
            requestInfo.dependents = attendant?.child;
        }

        const payload = {
            type: "getquote",
            requestInfo,
            productKey: WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_TRAVEL,
        };
        const response = await wealthService.actionWealthSubProducts(payload);

        if (unmounted.current) return;

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const products = data.subProducts;

                const packageListTmp = products?.map((item) => {
                    const content = t(
                        `wealth:lbi_travel_package_${item?.productKey}`,
                        {
                            returnObjects: true,
                        }
                    );

                    return {
                        ...item,
                        id: item?.productKey,
                        left: {
                            title: content[0],
                        },
                        right: {
                            label: t("wealth:GE00005"),
                            amount: item?.pricing,
                        },
                    };
                });
                setSelectedPackage(packageListTmp[0]);
                setPackageList(packageListTmp);
                setFetching(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [
        attendant,
        destination,
        travelDate,
        t,
        setSelectedPackage,
        setPackageList,
    ]);

    useEffect(() => {
        if (!packageList && !firstLoaded.current) {
            getPackages();
        }
    }, [getPackages, packageList]);

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-selectproduct" />
                <LspTypography>{t("wealth:GE00120")}</LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <LspCardSelection
                    list={packageList}
                    onClick={onClickItem}
                    selected={selectedPackage}
                    fetching={fetching}
                />
                <br />
                <LspButton
                    onClick={onNext}
                    disabled={!selectedPackage}
                    fullWidth
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    packageList: state.insurance.packageList,
    destination: state.insurance.destination,
    travelDate: state.insurance.travelDate,
    attendant: state.insurance.attendant,
    selectedPackage: state.insurance.selectedPackage,
});

const mapDispatch = (dispatch) => ({
    setPackageList: (payload) =>
        dispatch(wealthInsuranceAction.setPackageList(payload)),
    setSelectedPackage: (payload) =>
        dispatch(wealthInsuranceAction.setSelectedPackage(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthTravelPackageSelection)
);
