import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    step: {
        whiteSpace: "pre-wrap",
        position: "absolute",
        top: theme.spacing(-0.5),
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
    },
    indicator: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255, .32)",

        "& i": {
            display: "block",
            color: theme.palette.neutral.white,
            width: theme.spacing(1),
            height: theme.spacing(1),
            borderRadius: "50%",
            background: theme.palette.neutral.white,
        },
    },
    label: {
        opacity: 0.5,
        position: "absolute",
        top: theme.spacing(3),
        width: 100,
    },
    left: {
        textAlign: "left",
    },
    right: {
        textAlign: "right",
    },
    leftLabel: {
        left: 0,
    },
    rightLabel: {
        right: 0,
    },
    active: {
        color: theme.palette.success.main,
        background: theme.palette.success.main,
    },
    textActive: {
        color: theme.palette.success.main,
        opacity: 1,
    },
}));

const InvitationLevelBarItem = ({
    tier,
    index,
    space,
    firstChild,
    lastChild,
    activePointIds,
}) => {
    const classes = useStyles();
    const isActive = useMemo(() => {
        return activePointIds.find((item) => item === tier.id);
    }, [activePointIds, tier]);

    return (
        <div
            className={clsx(classes.step, {
                [classes.left]: firstChild,
                [classes.right]: lastChild,
                [classes.active]: isActive,
            })}
            style={{ left: index * space - 8 }}
        >
            <div className={classes.indicator}>
                <i />
            </div>
            <LspTypography
                variant="heading3"
                color="white"
                className={clsx(classes.label, {
                    [classes.leftLabel]: firstChild,
                    [classes.rightLabel]: lastChild,
                    [classes.textActive]: isActive,
                })}
            >
                {tier.contentName}
            </LspTypography>
        </div>
    );
};

export default InvitationLevelBarItem;
