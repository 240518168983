import { INVITATION_TIERS } from "@containers/Invitation/constant";
import BeginnerStarIcon from "@assets/images/beginnerStar.svg";
import RisingStarIcon from "@assets/images/risingStar.svg";
import ShiningStarIcon from "@assets/images/shiningStar.svg";
import SuperStarIcon from "@assets/images/superStar.svg";

const InvitationAvatar = ({ type }) => {
    return (
        <>
            {type === INVITATION_TIERS.BEGINNER && (
                <img src={BeginnerStarIcon} alt="icon" />
            )}
            {type === INVITATION_TIERS.RISING_STAR && (
                <img src={RisingStarIcon} alt="icon" />
            )}
            {type === INVITATION_TIERS.SHINING_STAR && (
                <img src={ShiningStarIcon} alt="icon" />
            )}
            {type === INVITATION_TIERS.SUPER_STAR && (
                <img src={SuperStarIcon} alt="icon" />
            )}
        </>
    );
};

export default InvitationAvatar;
