import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflow: "hidden",
    },
    item: {
        width: "25%",
        height: theme.spacing(3),
        "&:nth-child(1)": {
            backgroundColor: theme.palette.purple.main,
        },
        "&:nth-child(2)": {
            backgroundColor: theme.palette.blue.main,
        },
        "&:nth-child(3)": {
            backgroundColor: theme.palette.orange.main,
        },
        "&:nth-child(4)": {
            backgroundColor: theme.palette.gold.main,
        },
    },
}));

const ColorBar = ({ className }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.container, className)}>
            <div className={classes.item} />
            <div className={classes.item} />
            <div className={classes.item} />
            <div className={classes.item} />
        </div>
    );
};

export default memo(ColorBar);
