/* eslint-disable react-hooks/exhaustive-deps */
import Content from "@components/Content";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import accountService from "@services/account";
import { useEffect, useState, useCallback, useRef } from "react";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import cloneDeep from "lodash/cloneDeep";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Skeleton } from "@material-ui/lab";
import LspTypography from "@components/LspTypography";
import DiscountList from "./DiscountList";
import SearchDiscount from "./SearchDiscount";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: theme.shape.borderRadius,
        "& ul": {
            paddingLeft: 17,
        },
    },

    uppercase: {
        textTransform: "uppercase",
    },

    discountList: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    },
    loadingItem: {
        margin: theme.spacing(1.5),
        borderRadius: theme.shape.radiusLevels[0],
    },
    resultLabel: {
        margin: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        paddingBottom: theme.spacing(2),
    },
}));

const Discount = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [discountList, setDiscountList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [hasMore, setHasmore] = useState(false);
    const [categoriesFilter, setCategoriesFilter] = useState({
        keyword: "",
        lastId: -1,
        tags: [],
    });

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const loadingSkeleton = () => {
        const skeleton = [];
        for (let i = 0; i < 3; i++) {
            skeleton.push(
                <div className={classes.loadingItem} key={i}>
                    <Skeleton variant="rect" width={210} height={150} />
                    <Skeleton />
                    <Skeleton width="60%" />
                </div>
            );
        }
        return <div className={classes.discountList}>{skeleton}</div>;
    };

    const getTagList = useCallback(async () => {
        const { data } = await accountService.getTagList();
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setTagList(data.data);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, []);

    const getListCategories = useCallback(async () => {
        setLoading(true);
        const { data } = await accountService.getListCategories(
            categoriesFilter
        );

        if (unmounted.current) {
            return;
        }

        setLoading(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                const listDiscountRes = data.data;
                if (categoriesFilter.lastId > -1) {
                    const discountListMore = cloneDeep(discountList);
                    for (let i = 0; i < listDiscountRes.length; i++) {
                        discountListMore.push(data[i]);
                    }
                    setDiscountList(discountListMore);
                } else {
                    setDiscountList(listDiscountRes);
                }
                setCategoriesFilter((prev) => ({
                    ...prev,
                    lastId:
                        listDiscountRes && listDiscountRes.length > 0
                            ? listDiscountRes[listDiscountRes.length - 1].id
                            : -1,
                }));
                setHasmore(listDiscountRes && listDiscountRes.length > 0);
                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [categoriesFilter, discountList]);

    const loadMore = useCallback(() => {
        if (hasMore) getListCategories();
    }, [getListCategories, hasMore]);

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    useEffect(() => {
        getListCategories();
    }, [categoriesFilter.tags, categoriesFilter.keyword]);

    useEffect(() => {
        getTagList();
    }, []);

    return (
        <Content size="md">
            <div className={classes.wrapper}>
                <SearchDiscount
                    categoriesFilter={categoriesFilter}
                    tagList={tagList}
                    setCategoriesFilter={setCategoriesFilter}
                />

                {categoriesFilter?.keyword !== "" && (
                    <LspTypography
                        variant="body1"
                        color="grey"
                        className={classes.resultLabel}
                    >
                        {t("creditCard:discount_result_found")}
                    </LspTypography>
                )}

                <DiscountList
                    discountList={discountList}
                    infiniteRef={infiniteRef}
                />

                {discountList.length === 0 && (
                    <Box textAlign="center" mt={2}>
                        {!categoriesFilter.keyword && (
                            <LspTypography variant="body2" color="grey">
                                {t("creditCard:discount_empty_list")}
                            </LspTypography>
                        )}

                        <LspTypography variant="body2" color="grey">
                            {t("creditCard:discount_empty_list_with_search")}
                        </LspTypography>
                    </Box>
                )}

                {loading && loadingSkeleton()}
            </div>
        </Content>
    );
};

export default Discount;
