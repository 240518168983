import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { WealthActionType } from "../actions/wealth";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    list: null,
};

/* ------------- Reducer ------------- */
const wealthReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case WealthActionType.GetProductListRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case WealthActionType.GetProductListSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                const productList = action.payload?.filter(
                    (item) => item?.productKey !== "pfm"
                );
                draft.list = productList;
                break;
            case WealthActionType.GetProductListError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default wealthReducer;
