/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { Box, Grow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const PendingTaskLoading = ({ className }) => {
    return (
        <>
            {[...new Array(3)]?.map((_, index) => {
                return (
                    <Grow in key={index}>
                        <Box className={className.item}>
                            <div className={className.heading}>
                                <div className={className.avatar}>
                                    <Skeleton
                                        variant="rect"
                                        width={48}
                                        height={48}
                                        style={{ borderRadius: 8 }}
                                    />
                                </div>
                                <div>
                                    <Skeleton width={200} />
                                    <Skeleton width={130} />
                                </div>
                            </div>
                            <div className={className.description}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </div>
                            <div className={className.actions} />
                        </Box>
                    </Grow>
                );
            })}
        </>
    );
};

export default PendingTaskLoading;
