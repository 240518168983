import { useMemo, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import userAction from "@redux/actions/user";
import {
    Box,
    ButtonBase,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Interweave from "interweave";
import LspTypography from "@components/LspTypography";
import SwitchButton from "@components/SwitchButton";
import { RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import InfoTooltip from "@components/InfoTooltip";
import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import { USER_ROUTES_PATH } from "@containers/App/routes.constant";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import { Skeleton } from "@material-ui/lab";
import securityService from "../../services/security";
import { twoFactorInit } from "./twofactors/helper";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        cardContent: {
            width: "100%",
            boxShadow: theme.shadows[29],
            marginBottom: theme.spacing(3),
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
        },
        cardItem: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
            borderBottom: `1px solid ${
                isDark
                    ? theme.palette.magicalGrey[500]
                    : theme.palette.magicalGrey[200]
            }`,
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(2, 3),
                transition: theme.transitions.create("background-color", {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeInOut,
                }),
            },
            "&:last-child": {
                borderBottom: "unset",
            },
        },
        left: {
            textAlign: "left",
            width: "70%",
        },
        title: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(18),
        },
        right: {
            flex: 1,
            display: "flex",
            alignItems: "flex-start	",
            justifyContent: "flex-end",
            color: theme.palette.primary.main,
        },
        settingName: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(1, 3, 1, 3),
            background: theme.palette.background.header,
            fontSize: theme.typography.pxToRem(14),
        },
        settingDesc: {
            fontSize: theme.typography.pxToRem(12),
            fontStyle: "italic",
        },
        warningEmail: {
            backgroundColor: theme.palette.status.error,
            fontSize: theme.typography.pxToRem(14),
            padding: theme.spacing(0.5, 3, 0.5, 3),
            textAlign: "left",
        },
        warningEmailTxt: {
            color: "#fff",
        },
    };
});

const Security = ({ sysParam, getUserProfile, featureState }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { formatNumber } = useNumber();
    const [dataSetting, setDataSetting] = useState(null);

    const [loading, setLoading] = useState({
        getData: false,
        otpRequired: false,
        emailNotification: false,
        smsNotification: false,
        "2Factors": false,
        hideBalance: false,
        Gg2Factors: false,
    });
    const [
        isShowWarningEmailNotification,
        setIsShowWarningEmailNotification,
    ] = useState(false);

    const twoFactorAllowance = useMemo(
        () =>
            sysParam.Allow2FactorsSetting === "true" ||
            sysParam.Allow2FactorsSetting === true ||
            sysParam.Allow2FactorsSetting === 1 ||
            sysParam.Allow2FactorsSetting === "1",
        [sysParam]
    );

    const smsAlertSettingAllowance = useMemo(
        () =>
            sysParam.AllowSMSAlertSetting === "true" ||
            sysParam.AllowSMSAlertSetting === true ||
            sysParam.AllowSMSAlertSetting === 1 ||
            sysParam.AllowSMSAlertSetting === "1",
        [sysParam]
    );

    const getSetting = useCallback(async () => {
        const { data } = await securityService.getSetting();
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setLoading((prev) => {
                    return {
                        ...prev,
                        getData: false,
                    };
                });

                console.log("data: ", data.data);
                setDataSetting(data.data);
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
        return data;
    }, []);

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const changeStatus = async (type) => {
        if (
            // eslint-disable-next-line no-prototype-builtins
            type.hasOwnProperty("emailNotification") &&
            !type.emailNotification
        ) {
            setIsShowWarningEmailNotification(true);
            return;
        }

        const key = Object.keys(type).toString();
        setLoading((prev) => {
            return {
                ...prev,
                [key]: true,
            };
        });
        const dataToPost = { ...dataSetting, ...type };
        delete dataToPost.nonOTPDailyLimit;
        delete dataToPost.otpRequireAmount;
        delete dataToPost.smsAmountCharge;
        delete dataToPost.smsAnnounceShow;
        const { data } = await securityService.updateSetting(dataToPost);
        setLoading((prev) => {
            return {
                ...prev,
                [key]: false,
            };
        });
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                if (type.hideBalance === 0 || type.hideBalance === 1) {
                    getUserProfile();
                }
                await getSetting();
                break;
            case RESPONSE_CODE.NOT_ALLOWED:
                GlobalDialogController.showCustomDialog({
                    onClose: closeDialog,
                    dialogInfo: {
                        iconImage: "Error",
                        header: "oops",
                        content: "security_sms_fee_no_enough_money",
                        button: "lb_ok",
                    },
                });
                break;
            default:
                GlobalDialogController.showCustomDialog({
                    onClose: closeDialog,
                    dialogInfo: {
                        iconImage: "Error",
                        header: "oops",
                        content: "security_and_alerts_saving_error_msg",
                        button: "lb_ok",
                    },
                });
                break;
        }
    };

    const confirmReceiveSMS = async (type) => {
        if (type.smsNotification && dataSetting.smsAnnounceShow) {
            const doubleButton = [
                {
                    label: t("loan_vpbank_button_confirm"),
                    onClick: () => {
                        changeStatus(type);
                        GlobalDialogController.hide();
                    },
                },
                {
                    label: t("lb_cancel"),
                    onClick: () => {
                        GlobalDialogController.hide();
                    },
                },
            ];
            const { data } = await securityService.getSettingNotifySMS();
            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    const res = data.data;
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            icon: "turnonsms",
                            header: t("security_title_turn_on_sms"),
                            content: t("security_content_turn_on_sms")
                                .replace("%@", res.fee.currency)
                                .replace("$@", t(formatNumber(res.fee.amount))),
                            lspTranslation: t("security_note_expect"),
                            boldContent: t("security_title_expects"),
                            doubleButton,
                            listContent: res.includes,
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showError({
                        errorCode: data.code,
                    });
                    break;
            }
        } else {
            await changeStatus(type);
        }
    };

    useEffect(() => {
        setLoading((prev) => {
            return {
                ...prev,
                getData: true,
            };
        });
        getSetting();
    }, [getSetting]);

    const enableTimeBaseOtp = useMemo(() => featureState?.enableTimeBaseOtp, [
        featureState?.enableTimeBaseOtp,
    ]);
    const onConfirmTwoFactor = () => {
        GlobalDialogController.hide();
        history.push(`${USER_ROUTES_PATH.SECURITY}/two-factor`);
    };

    const skeletonTemplate = useMemo(() => {
        const cardBox = (
            <Box mb={4}>
                <Box mb={1}>
                    <Skeleton variant="rect" height={50} />
                </Box>
                <Skeleton variant="rect" height={100} />
            </Box>
        );
        return (
            <>
                {cardBox}
                {cardBox}

                <Box mb={3}>
                    <Skeleton variant="rect" height={50} />
                </Box>
                {cardBox}
            </>
        );
    }, []);

    return (
        <>
            <Banner
                title={t("security_alerts_title")}
                subtitle={t("security_alerts_subtitle")}
            />
            <ContentWrapper>
                <Content boxProps={{ textAlign: "center" }}>
                    {loading.getData && skeletonTemplate}
                    {!loading.getData && (
                        <>
                            <Box className={classes.cardContent}>
                                <Box display="flex">
                                    <ButtonBase
                                        className={classes.cardItem}
                                        onClick={() => {
                                            QuickCodeDialogController.change();
                                        }}
                                    >
                                        <div className={classes.left}>
                                            <LspTypography
                                                variant="body1"
                                                className={classes.title}
                                            >
                                                {t("quickcode_label_reset_qc")}
                                            </LspTypography>
                                        </div>
                                        <div className={classes.right}>
                                            <NavigateNextIcon />
                                        </div>
                                    </ButtonBase>
                                </Box>
                            </Box>

                            <Box mt={3} className={classes.cardContent}>
                                <div className={classes.cardItem}>
                                    <LspTypography
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {t("security_settings_label")}
                                    </LspTypography>
                                </div>
                                <div className={classes.settingName}>
                                    {t("security_otp_label")}
                                </div>
                                <div className={classes.cardItem}>
                                    <div className={classes.left}>
                                        {t("security_dont_require_otp_option")}
                                        <div className={classes.settingDesc}>
                                            <Interweave
                                                content={t(
                                                    "security_otp_explaination"
                                                )
                                                    .replace(
                                                        "%@dailylimit",
                                                        t(
                                                            formatNumber(
                                                                dataSetting?.nonOTPDailyLimit
                                                            )
                                                        )
                                                    )
                                                    .replace(
                                                        "%@transferlimit",
                                                        t(
                                                            formatNumber(
                                                                dataSetting?.otpRequireAmount
                                                            )
                                                        )
                                                    )}
                                                tagName="div"
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.right}>
                                        <SwitchButton
                                            checked={!!dataSetting?.otpRequired}
                                            onChange={(e) =>
                                                changeStatus({
                                                    otpRequired:
                                                        e.target.checked,
                                                })
                                            }
                                            // onClick={updateCardStatus}
                                            fetching={loading.otpRequired}
                                        />
                                    </div>
                                </div>

                                {twoFactorAllowance && (
                                    <>
                                        <div className={classes.settingName}>
                                            <span>{t("sec_lb_2factor")}</span>
                                            <InfoTooltip
                                                title={t("sec_desc_2factor")}
                                                placement="bottom"
                                            />
                                        </div>
                                        {!enableTimeBaseOtp && (
                                            <div className={classes.cardItem}>
                                                <div className={classes.left}>
                                                    {t("sec_lb_2factor")}
                                                </div>
                                                <div className={classes.right}>
                                                    <SwitchButton
                                                        checked={
                                                            !!dataSetting?.[
                                                                "2Factors"
                                                            ]
                                                        }
                                                        onChange={(e) =>
                                                            changeStatus({
                                                                "2Factors":
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {enableTimeBaseOtp && (
                                            <div className={classes.cardItem}>
                                                <div className={classes.left}>
                                                    {t(
                                                        "master:sc_gg_authen_title"
                                                    )}
                                                    <LspTypography
                                                        variant="body3"
                                                        color="grey"
                                                    >
                                                        {t(
                                                            "master:sc_gg_authen_desc"
                                                        )}
                                                    </LspTypography>
                                                </div>
                                                <div className={classes.right}>
                                                    <SwitchButton
                                                        checked={
                                                            !!dataSetting?.Gg2Factors
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                !dataSetting?.Gg2Factors
                                                            ) {
                                                                twoFactorInit(
                                                                    () =>
                                                                        onConfirmTwoFactor()
                                                                );
                                                            } else {
                                                                changeStatus({
                                                                    Gg2Factors: !dataSetting?.Gg2Factors,
                                                                });
                                                            }
                                                        }}
                                                        fetching={
                                                            loading.Gg2Factors
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Box>
                            <Box mt={3} className={classes.cardContent}>
                                <div className={classes.cardItem}>
                                    <LspTypography
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {t("alerts_label")}
                                    </LspTypography>
                                    <InfoTooltip
                                        title={t("alerts_info_msg")}
                                        placement="bottom"
                                    />
                                </div>

                                {isShowWarningEmailNotification && (
                                    <div className={classes.warningEmail}>
                                        <LspTypography
                                            variant="body1"
                                            className={classes.warningEmailTxt}
                                        >
                                            {t(
                                                "sec_msg_warning_for_turn_off_push_notification"
                                            )}
                                        </LspTypography>
                                    </div>
                                )}

                                <div className={classes.cardItem}>
                                    <div className={classes.left}>
                                        {t("receive_via_email")}
                                    </div>
                                    <div className={classes.right}>
                                        <SwitchButton
                                            checked={
                                                !!dataSetting?.emailNotification
                                            }
                                            onChange={(e) =>
                                                changeStatus({
                                                    emailNotification:
                                                        e.target.checked,
                                                })
                                            }
                                            fetching={loading.emailNotification}
                                        />
                                    </div>
                                </div>
                                {smsAlertSettingAllowance && (
                                    <>
                                        <div className={classes.cardItem}>
                                            <div className={classes.left}>
                                                <div>
                                                    {t("receive_via_SMS")}
                                                </div>
                                                <div
                                                    className={
                                                        classes.settingDesc
                                                    }
                                                >
                                                    <Interweave
                                                        content={t(
                                                            "sms_notification_monthly_charge"
                                                        ).replace(
                                                            "%@",
                                                            t(
                                                                formatNumber(
                                                                    dataSetting?.smsAmountCharge
                                                                )
                                                            )
                                                        )}
                                                        tagName="div"
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.right}>
                                                <SwitchButton
                                                    checked={
                                                        !!dataSetting?.smsNotification
                                                    }
                                                    onChange={(e) =>
                                                        confirmReceiveSMS({
                                                            smsNotification:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }
                                                    fetching={
                                                        loading.smsNotification
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Box>

                            <Box mt={3} className={classes.cardContent}>
                                <Box display="flex">
                                    <ButtonBase
                                        className={classes.cardItem}
                                        disableRipple
                                        onClick={() => {
                                            history.push("/manage-device");
                                        }}
                                    >
                                        <div className={classes.left}>
                                            <LspTypography
                                                variant="body1"
                                                className={classes.title}
                                            >
                                                {t("manage_device_menu_name")}
                                            </LspTypography>
                                        </div>
                                        <div className={classes.right}>
                                            <NavigateNextIcon />
                                        </div>
                                    </ButtonBase>
                                </Box>
                            </Box>
                            <Box mt={3} className={classes.cardContent}>
                                <div className={classes.cardItem}>
                                    <LspTypography
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {t("master:sc_set_limit_title")}
                                    </LspTypography>
                                </div>

                                <ButtonBase
                                    className={classes.cardItem}
                                    disableRipple
                                    onClick={() => {
                                        history.push(
                                            USER_ROUTES_PATH.TRANSFER_LIMIT
                                        );
                                    }}
                                >
                                    <div className={classes.left}>
                                        <LspTypography variant="body2">
                                            {t("master:sc_set_limit_acc")}
                                        </LspTypography>
                                    </div>
                                    <div className={classes.right}>
                                        <NavigateNextIcon />
                                    </div>
                                </ButtonBase>
                                <ButtonBase
                                    className={classes.cardItem}
                                    disableRipple
                                    onClick={() => {
                                        history.push(
                                            USER_ROUTES_PATH.CARD_MANAGEMENT_DEBIT
                                        );
                                    }}
                                >
                                    <div className={classes.left}>
                                        <LspTypography variant="body2">
                                            {t("master:setting_card_header")}
                                        </LspTypography>
                                    </div>
                                    <div className={classes.right}>
                                        <NavigateNextIcon />
                                    </div>
                                </ButtonBase>
                            </Box>

                            <Box mt={3} className={classes.cardContent}>
                                <div className={classes.cardItem}>
                                    <LspTypography
                                        variant="body1"
                                        className={classes.title}
                                    >
                                        {t("master:hide_balance_setting_title")}
                                    </LspTypography>
                                </div>
                                <div className={classes.cardItem}>
                                    <div className={classes.left}>
                                        <div>
                                            {t(
                                                "master:hide_balance_setting_subtitle2"
                                            )}
                                        </div>
                                        <div>
                                            {t(
                                                "master:hide_balance_setting_subtitle3_web"
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.right}>
                                        <SwitchButton
                                            checked={
                                                dataSetting?.hideBalance !== 0
                                            }
                                            onChange={(e) =>
                                                changeStatus({
                                                    hideBalance: e.target
                                                        .checked
                                                        ? 1
                                                        : 0,
                                                })
                                            }
                                            fetching={loading.hideBalance}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </>
                    )}
                </Content>
            </ContentWrapper>
        </>
    );
};
const stateProps = (state) => ({
    sysParam: state.systemParams.info.sysparams["1.0"],
    featureState: state.user.featureState.data,
});
const mapDispatch = (dispatch) => ({
    getUserProfile: () => dispatch(userAction.getProfileRequest()),
});

export default connect(stateProps, mapDispatch)(Security);
