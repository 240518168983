import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useNumber from "@helpers/useNumber";
import useCallCenter from "@helpers/useCallCenter";
import Interweave from "interweave";
import { makeStyles } from "@material-ui/core";

/**
 * @param {object} props
 * @param {string} props.i18nKey
 * @param {Array.<string>} props.replacingKeys - Array of i18nKey/text to replace `%@` in the original translation,
 * prefix with `#` to skip the translation,
 * prefix with `$` to use number formatting
 * @param {boolean} prop.hyperlink
 */

const useStyles = makeStyles((theme) => ({
    hyperlink: {
        "& a": {
            color: theme.palette.success.main,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 700,
            textDecoration: "underline",
            lineHeight: 22 / 14,
            cursor: "pointer",
        },
    },
}));

const LspTranslation = ({ i18nKey, replacingKeys, hyperlink }) => {
    const { formatNumber } = useNumber();
    const { getCallCenterContent } = useCallCenter();
    const classes = useStyles();

    // const namespaces = useMemo(() => {
    //     const nsArr = ["translation"];

    //     if (i18nKey.includes(":")) {
    //         const ns = i18nKey.split(":")[0];
    //         if (!nsArr.includes(ns)) {
    //             nsArr.push(ns);
    //         }
    //     }

    //     if (replacingKeys.length === 0) {
    //         return nsArr;
    //     }

    //     replacingKeys.forEach((rKey) => {
    //         if (rKey.includes(":")) {
    //             const rNs = rKey.split(":")[0];
    //             if (!nsArr.includes(rNs)) {
    //                 nsArr.push(rNs);
    //             }
    //         }
    //     });
    //     return nsArr;
    // }, [i18nKey, replacingKeys]);

    // const { t } = useTranslation(namespaces);
    const { t } = useTranslation();

    const text = useMemo(() => {
        let transText = t(i18nKey);

        replacingKeys.forEach((rKey) => {
            if (rKey.indexOf("#") === 0) {
                transText = transText.replace("%@", rKey.slice(1));
            } else if (rKey.indexOf("$") === 0) {
                transText = transText.replace(
                    "%@",
                    formatNumber(rKey.slice(1))
                );
            } else if (hyperlink) {
                const content = getCallCenterContent(t(i18nKey));
                transText = content;
            } else {
                transText = transText.replace("%@", t(rKey));
            }
        });

        if (hyperlink) {
            return (
                <div className={classes.hyperlink}>
                    <Interweave content={transText} />
                </div>
            );
        }
        return transText;
    }, [
        t,
        i18nKey,
        replacingKeys,
        hyperlink,
        formatNumber,
        getCallCenterContent,
        classes.hyperlink,
    ]);

    return <>{text}</>;
};

LspTranslation.propTypes = {
    i18nKey: PropTypes.string.isRequired,
    replacingKeys: PropTypes.arrayOf(PropTypes.string),
    hyperlink: PropTypes.bool,
};

LspTranslation.defaultProps = {
    replacingKeys: [],
    hyperlink: false,
};

export default memo(LspTranslation);
