import metricActions from "@redux/actions/metric";
import { isEmpty } from "lodash";
import { useEffect, memo, useCallback } from "react";

import { connect } from "react-redux";

let intervalMetric = null;

const Metric = ({ metric, metricList, initMetric, metricRequest, force }) => {
    const handleMetricInterval = useCallback(() => {
        if (!isEmpty(metric) && metricList.length > 0) {
            const time = metric.metricLimitTime || 0;
            const size = metric.metricLimitSize || 10;
            const token = metric.token || "";

            if (force) {
                metricRequest({ data: metricList, token });
                return;
            }

            const interval = setInterval(() => {
                if (metricList.length >= size) {
                    metricRequest({ data: metricList, token });
                    clearInterval(intervalMetric);
                }
            }, 1000 * time);

            intervalMetric = interval;
        }
    }, [force, metric, metricList, metricRequest]);

    useEffect(() => {
        if (metricList.length > 0) {
            clearInterval(intervalMetric);
            handleMetricInterval();
        }
    }, [metricList, handleMetricInterval]);

    useEffect(() => {
        if (isEmpty(metric) && metricList.length > 0) {
            initMetric();
        }
    }, [initMetric, metric, metricList]);

    return <></>;
};

const mapState = (state) => ({
    metric: state.metrics?.metric?.data,
    metricList: state.metrics?.metricList?.data,
    force: state.metrics?.metricList?.force,
    metricConfig: state.metrics?.metricConfig,
});

const dispatchProps = (dispatch) => ({
    initMetric: () => dispatch(metricActions.initMetricRequest()),
    metricRequest: (payload) => dispatch(metricActions.metricRequest(payload)),
});

export default connect(mapState, dispatchProps)(memo(Metric));
