import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import { RESPONSE_CODE } from "@config/constants";
import moveMoneyService from "@services/move-money";
import useAccounts from "@helpers/useAccounts";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import UsingOverdraftWarningDialog from "@components/UsingOverdraftWarningDialog";

export const ERROR_TYPE_LIMIT_TRANSFER = {
    OVER_ALLOWED_MAX_AMOUNT: "OVER_ALLOWED_MAX_AMOUNT",
    DAILY_LIMIT: "DAILY_LIMIT",
    OUT_OF_RANGE: "OUT_OF_RANGE",
    NO_ENOUGH_MONEY: "NO_ENOUGH_MONEY",
    UNDER_ALLOWED_MIN_AMOUNT: "UNDER_ALLOWED_MIN_AMOUNT",
    SMALLER_THAN: "SMALLER_THAN",
};

/**
 * `useTransactionValidation` will check if amount transfer limit is reach or not
 */
const useTransactionValidation = () => {
    const {
        spendAccount,
        isOverDraftUser,
        getAvailableToSpend,
        availableAmount,
    } = useAccounts();
    const user = useSelector((state) => state.user.info);
    const systemParams = useSelector((state) => state.systemParams.info);
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const isEKYCBasicUser = useMemo(
        () => user?.currentOnboardingType === "eKYCBasic",
        [user]
    );

    const maxEKycPerMonth = useMemo(
        () => parseFloat(systemParams?.conf?.eKYCLimitPerMonth),
        [systemParams]
    );
    const maxPayAnyone = useMemo(
        () => parseFloat(systemParams?.conf?.MaxAmountPayanyoneTransfer),
        [systemParams]
    );
    const minLimitPerTransfer = useMemo(
        () => parseFloat(systemParams?.conf?.MinAmountLimitPerTransfer),
        [systemParams]
    );
    const maxLimitPerTransfer = useMemo(
        () => parseFloat(systemParams?.conf?.MaxAmountLimitPerTransfer),
        [systemParams]
    );
    const maxSML = useMemo(() => parseFloat(systemParams?.conf?.MaxAmountSML), [
        systemParams,
    ]);

    const getError = useCallback(
        ({ type, min, max }) => {
            switch (type) {
                case ERROR_TYPE_LIMIT_TRANSFER.UNDER_ALLOWED_MIN_AMOUNT:
                    return t("spend_msg_for_least_amount").replace(
                        "%@",
                        formatNumber(min)
                    );
                case ERROR_TYPE_LIMIT_TRANSFER.NO_ENOUGH_MONEY:
                    return t("payee_card_amount_submitted_invalid");
                case ERROR_TYPE_LIMIT_TRANSFER.OVER_ALLOWED_MAX_AMOUNT:
                    return t("mm_over_daily_limit").replace(
                        "%@",
                        formatNumber(max)
                    );
                case ERROR_TYPE_LIMIT_TRANSFER.DAILY_LIMIT:
                    return t("ms_lb_transfer_daily_limit");
                // case ERROR_TYPE_LIMIT_TRANSFER.SMALLER_THAN:
                //     return t("ms_lb_transfer_amount_invalid")
                //         .replace("@", formatNumber(min))
                //         .replace("%@", formatNumber(max));
                case ERROR_TYPE_LIMIT_TRANSFER.OUT_OF_RANGE:
                default:
                    return `${t("ms_lb_transfer_amount_invalid")
                        .replace("@", formatNumber(min))
                        .replace("%@", formatNumber(max))}`;
                // return `${t("payee_card_amount_from")} ${formatNumber(
                //     min
                // )} ${t("moveMoney_label_amount_and")} ${formatNumber(max)}`;
            }
        },
        [formatNumber, t]
    );

    const getErrorByResponseCode = useCallback(
        (code) => {
            switch (code) {
                case RESPONSE_CODE.DAILY_LIMIT_TRANSFER: // 409
                    return getError({
                        type: ERROR_TYPE_LIMIT_TRANSFER.DAILY_LIMIT,
                    });
                case RESPONSE_CODE.OVER_ALLOWED_MAX_AMOUNT: // 7001
                    return getError({
                        type: ERROR_TYPE_LIMIT_TRANSFER.OVER_ALLOWED_MAX_AMOUNT,
                    });
                case RESPONSE_CODE.UNDER_MIN_TRANSFER: // 414
                    return getError({
                        type:
                            ERROR_TYPE_LIMIT_TRANSFER.UNDER_ALLOWED_MIN_AMOUNT,
                        min: minLimitPerTransfer,
                    });
                case RESPONSE_CODE.NOT_ALLOWED: // 405
                    return getError({
                        type: ERROR_TYPE_LIMIT_TRANSFER.NO_ENOUGH_MONEY,
                    });
                default:
                    return null;
            }
        },
        [minLimitPerTransfer, getError]
    );

    const checkLimitPerTransaction = useCallback(
        /**
         * @param {object} props
         * @param {('member'|'card'|'bank'|'pa')} props.transferType
         * @param {number} props.amount
         * @param {boolean} props.isFastTransfer
         * @param {boolean} props.canUseOverdraft // allow using overdraft money
         */
        ({ transferType, amount, isFastTransfer, canUseOverdraft }) => {
            if (isEKYCBasicUser) {
                if (amount > maxEKycPerMonth) {
                    return {
                        type: ERROR_TYPE_LIMIT_TRANSFER.OVER_ALLOWED_MAX_AMOUNT,
                        max: maxEKycPerMonth,
                        min: minLimitPerTransfer,
                    };
                }
                return null;
            }

            const availableToSpend = getAvailableToSpend(canUseOverdraft);

            let max;
            // let isNapasOrFastTransfer = false;
            switch (transferType) {
                case "pa":
                    max = maxPayAnyone;
                    break;
                case "member":
                    // max = maxLimitPerTransfer;
                    max = Math.min(availableToSpend, maxLimitPerTransfer);
                    break;
                case "card":
                    max = maxSML;
                    // isNapasOrFastTransfer = true;
                    break;
                case "bank":
                default:
                    // max = !isFastTransfer ? maxLimitPerTransfer : maxSML;
                    max = Math.min(
                        availableToSpend,
                        !isFastTransfer ? maxLimitPerTransfer : maxSML
                    );
                    // isNapasOrFastTransfer = !!isFastTransfer;
                    break;
            }

            // // Not using anymore - For card transfer and bank fast transfer check max amount with >= max
            // if (
            //     isNapasOrFastTransfer &&
            //     (amount < minLimitPerTransfer || amount >= max)
            // ) {
            //     return {
            //         type: ERROR_TYPE_LIMIT_TRANSFER.SMALLER_THAN,
            //         min: minLimitPerTransfer,
            //         max,
            //     };
            // }

            // For others case check max amount with > max
            if (amount < minLimitPerTransfer || amount > max) {
                return {
                    type: ERROR_TYPE_LIMIT_TRANSFER.OUT_OF_RANGE,
                    min: minLimitPerTransfer,
                    max,
                };
            }
            return null;
        },
        [
            isEKYCBasicUser,
            maxEKycPerMonth,
            minLimitPerTransfer,
            maxPayAnyone,
            maxLimitPerTransfer,
            maxSML,
            getAvailableToSpend,
        ]
    );

    const checkLimitPerMonth = useCallback(
        async ({ amount }) => {
            // check limit per month
            const response = await moveMoneyService.getLimitations({
                accountNumber: spendAccount.no,
            });
            const { code, data } = response.data;

            let errorType = null;

            if (code === RESPONSE_CODE.SUCCESS) {
                const {
                    transactionMaxAmountLimit,
                    transactionMinAmountLimit,
                    todayAmount,
                    todayLimit,
                } = data;

                if (
                    amount < transactionMinAmountLimit ||
                    amount > transactionMaxAmountLimit
                ) {
                    errorType = {
                        type: ERROR_TYPE_LIMIT_TRANSFER.OUT_OF_RANGE,
                        min: transactionMinAmountLimit,
                        max: transactionMaxAmountLimit,
                    };
                }

                if (
                    todayAmount >= todayLimit ||
                    todayAmount + amount > todayLimit
                ) {
                    errorType = {
                        type: ERROR_TYPE_LIMIT_TRANSFER.DAILY_LIMIT,
                    };
                }
            }
            return errorType;
        },
        [spendAccount]
    );

    const warningUsingOverdraftMoneyDialog = useCallback((onClose) => {
        const onCloseHandler = () => {
            if (onClose && isFunction(onClose)) {
                onClose();
            }
            GlobalDialogController.hide();
        };

        GlobalDialogController.show({
            component: () => (
                <UsingOverdraftWarningDialog onClose={onCloseHandler} />
            ),
        });
    }, []);

    const isUsingOverdraftMoney = useCallback(
        ({ canUseOverdraft, amount }) => {
            return (
                canUseOverdraft &&
                isOverDraftUser &&
                availableAmount - amount <= 0
            );
        },
        [isOverDraftUser, availableAmount]
    );

    const validateAmount = useCallback(
        /**
         * - Option 1: `handleAmountInvalid(error)`: Receive error object as param. Call `getError(error)` from this hook to get error message (using for error handler from server response)
         * - Option 2: Handle errors with callback functions: `handleBalanceLessThanMinAmount`, `handleAmountOutOfRange`, `handleAmountOverDailyLimit`
         * @param {object} props
         * @param {number|string} props.amount
         * @param {('member'|'card'|'bank'|'pa')} props.transferType
         * @param {boolean} [props.isFastTransfer=false]
         * @param {function} [props.beforeRequest] - trigger before sending API request to validate
         * @param {boolean} props.isCancelled - a ref to let handler know if its parent component is unmounted
         * @param {function} [props.afterResponse] - trigger immediately after receive API response
         * @param {function} [props.handleAmountValid]
         * @param {(error:{type:string, min:number, max:number}) => void} [props.handleAmountInvalid]
         * @param {(minAmount:number) => void} [props.handleBalanceLessThanMinAmount]
         * @param {(minAmount:number, maxAMount:number) => void} [props.handleAmountOutOfRange]
         * @param {function} [props.handleAmountOverDailyLimit]
         * @param {boolean} [props.canUseOverdraft] - feature is support overdraft
         */
        async ({
            amount,
            transferType,
            isFastTransfer = false,
            beforeRequest,
            isCancelled,
            afterResponse,
            handleAmountValid,
            handleAmountInvalid,
            handleBalanceLessThanMinAmount,
            handleAmountOutOfRange,
            handleAmountOverDailyLimit,
            canUseOverdraft,
        }) => {
            const availableToSpend = getAvailableToSpend(canUseOverdraft);

            if (availableToSpend === undefined || availableToSpend === null) {
                return;
            }

            const parsedAmount = parseFloat(amount);

            if (isFunction(handleAmountOutOfRange)) {
                if (availableToSpend < minLimitPerTransfer) {
                    if (isFunction(handleBalanceLessThanMinAmount)) {
                        handleBalanceLessThanMinAmount(minLimitPerTransfer);
                        return;
                    }
                }

                if (amount > availableToSpend) {
                    handleAmountOutOfRange(
                        minLimitPerTransfer,
                        availableToSpend
                    );
                    return;
                }
            }

            let error = null;

            error = checkLimitPerTransaction({
                transferType,
                amount: parsedAmount,
                isFastTransfer,
                canUseOverdraft,
            });

            if (!isObject(error)) {
                if (isFunction(beforeRequest)) {
                    beforeRequest();
                }

                error = await checkLimitPerMonth({
                    amount: parsedAmount,
                });

                if (isCancelled) {
                    return;
                }

                if (isFunction(afterResponse)) {
                    afterResponse();
                }
            }

            if (isObject(error)) {
                if (isFunction(handleAmountInvalid)) {
                    handleAmountInvalid(error);
                    return;
                }

                const { type, min, max } = error;
                switch (type) {
                    case ERROR_TYPE_LIMIT_TRANSFER.UNDER_ALLOWED_MIN_AMOUNT:
                        if (isFunction(handleBalanceLessThanMinAmount)) {
                            handleBalanceLessThanMinAmount(min);
                        }
                        return;
                    case ERROR_TYPE_LIMIT_TRANSFER.DAILY_LIMIT:
                        if (isFunction(handleAmountOverDailyLimit)) {
                            handleAmountOverDailyLimit();
                        }
                        return;
                    case ERROR_TYPE_LIMIT_TRANSFER.OUT_OF_RANGE:
                    case ERROR_TYPE_LIMIT_TRANSFER.SMALLER_THAN:
                    case ERROR_TYPE_LIMIT_TRANSFER.OVER_ALLOWED_MAX_AMOUNT:
                        if (isFunction(handleAmountOutOfRange)) {
                            handleAmountOutOfRange(min, max);
                        }
                        return;
                    default:
                        return;
                }
            }

            if (isFunction(handleAmountValid)) {
                if (isUsingOverdraftMoney({ canUseOverdraft, amount })) {
                    warningUsingOverdraftMoneyDialog(handleAmountValid);
                    return;
                }

                handleAmountValid();
            }
        },
        [
            checkLimitPerTransaction,
            checkLimitPerMonth,
            minLimitPerTransfer,
            warningUsingOverdraftMoneyDialog,
            getAvailableToSpend,
            isUsingOverdraftMoney,
        ]
    );

    const validatePayeeDestination = useCallback(
        async ({
            isCancelled,
            accountNumber = "",
            payeeName,
            beforeRequest,
            afterResponse,
            handlePayeeDestinationValid,
            handleDestinationExistInPayee,
            handleInternalDestinationExistInPayee,
            handleInternalDestinationNotExistInPayee,
        }) => {
            if (isFunction(beforeRequest)) {
                beforeRequest();
            }

            const response = await moveMoneyService.checkPayeeDestinationExistence(
                {
                    accountNumber,
                    payeeName,
                }
            );

            if (isCancelled) {
                return;
            }
            if (isFunction(afterResponse)) {
                afterResponse();
            }
            if (response.ok) {
                switch (response.data.code) {
                    case 200: // Available to add || Not have Timo Payee or Destination
                    case 4100: // Account not found, it is bank/debit card transfer
                        if (isFunction(handlePayeeDestinationValid)) {
                            handlePayeeDestinationValid(response.data.data);
                        }
                        break;
                    case 4101: // This destination already belongs to a Payee
                        if (isFunction(handleDestinationExistInPayee)) {
                            handleDestinationExistInPayee(response.data.data);
                        }
                        break;
                    case 4102: // This Payee already exists & has a Timo destination
                        if (isFunction(handleInternalDestinationExistInPayee)) {
                            handleInternalDestinationExistInPayee(
                                response.data.data
                            );
                        }
                        break;
                    case 4103: // This Payee already exists & not have Timo destination
                        if (
                            isFunction(handleInternalDestinationNotExistInPayee)
                        ) {
                            handleInternalDestinationNotExistInPayee(
                                response.data.data
                            );
                        }
                        break;
                    default:
                        break;
                }
            }
        },
        []
    );

    const validations = useMemo(
        () => ({
            validateAmount,
            validatePayeeDestination,
            getError,
            getErrorByResponseCode,
            warningUsingOverdraftMoneyDialog,
            isUsingOverdraftMoney,
        }),
        [
            validateAmount,
            validatePayeeDestination,
            getError,
            getErrorByResponseCode,
            warningUsingOverdraftMoneyDialog,
            isUsingOverdraftMoney,
        ]
    );

    return validations;
};

export default useTransactionValidation;
