import { makeStyles } from "@material-ui/core";
import { ArrowLeftIcon } from "@components/LspIcon";
import PropTypes from "prop-types";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => ({
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    headerIcon: {
        background: theme.palette.background.paper,
        borderRadius: "50%",
        border: `1px solid ${theme.palette.neutral.grey3}`,
        cursor: "pointer",
    },
    headerText: {
        fontSize: 20,
        color: theme.palette.primary.main,
        flex: 1,
        textAlign: "center",
        textTransform: "uppercase",
    },
    right: {
        width: 24,
    },
}));

/**
 * @typedef PageHeaderBackProps
 * @type {object}
 * @property {function} onBack
 * @property {any} children
 */

/**
 * @param {PageHeaderBackProps} props
 */

const PageHeaderBack = ({ onBack = null, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            {isFunction(onBack) && (
                <ArrowLeftIcon
                    className={classes.headerIcon}
                    onClick={onBack}
                />
            )}
            <div className={classes.headerText}>{children}</div>
            {!!onBack && <div className={classes.right} />}
        </div>
    );
};

PageHeaderBack.propTypes = {
    onBack: PropTypes.func,
};
PageHeaderBack.defaultProps = {
    onBack: undefined,
};

export default PageHeaderBack;
