import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE, TermDepositRollType } from "@config/constants";
import useNumber from "@helpers/useNumber";
import {
    CircularProgress,
    Icon,
    IconButton,
    Menu,
    MenuItem,
    makeStyles,
} from "@material-ui/core";
import { useMemo, memo, useState, useCallback } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useTranslation } from "react-i18next";

import termDepositService from "@services/term-deposit";
import termDepositAction from "@redux/actions/term-deposit";
import { connect } from "react-redux";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import tommyNoHope from "@assets/images/no_hope_with_status.png";
import useOTP from "@helpers/useOTP";
import LspTranslation from "@components/LspTranslation";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 1, 3, 3),
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        boxShadow: theme.shadows[29],
        position: "relative",
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    text: {
        marginBottom: theme.spacing(0.5),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    action: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    icon: {
        color: theme.palette.magicalGrey[400],
        marginRight: theme.spacing(2),
    },
}));
const AutoDepositCard = ({
    termName,
    nextDepositDate,
    amountOfDeposit,
    rollType,
    term,
    autoRoll,
    recurringId,
    getAutoDepositList,
}) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const { t } = useTranslation();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [isLoadingDropdown, setLoadingDropdown] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const tdRollType = useMemo(() => {
        if (!autoRoll) {
            return t("TD:td_no_rollover_method_title");
        }
        switch (rollType) {
            case TermDepositRollType.PARTIAL_ROLLOVER:
                return t("TD:td_early_redeem_partial_withdraw");
            case TermDepositRollType.TOTAL_ROLLOVER:
                return t("TD:td_early_redeem_full_withdraw");
            default:
                break;
        }
    }, [autoRoll, rollType, t]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteAuToDepositResponseHandler = useCallback(
        (code) => {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    AlertController.show(t("TD:td_delete_success_msg"));
                    getAutoDepositList();
                    closeOTPDialog();
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE:
                    AlertController.show(
                        <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                        AlertType.Error
                    );
                    break;
                default:
                    closeOTPDialog();
                    AlertController.show(
                        t("TD:td_recurring_setup_failure"),
                        AlertType.Error
                    );
                    break;
            }
        },
        [getAutoDepositList, t, closeOTPDialog]
    );

    const handleCommitOTPDeleteAutoDeposit = useCallback(
        async (refNo) => {
            setLoading(true);

            const response = await termDepositService.commitDeleteAuToDeposit(
                refNo
            );

            const { code } = response.data;

            setLoading(false);

            deleteAuToDepositResponseHandler(code);
        },
        [setLoading, deleteAuToDepositResponseHandler]
    );

    const handleDeleteItem = async () => {
        handleClose();
        setLoadingDropdown(true);
        const response = await termDepositService.deleteAuToDeposit({
            recurringId,
        });

        const { code, data } = response.data;
        setLoadingDropdown(false);

        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data?.refNo || "",
                submitFunc: handleCommitOTPDeleteAutoDeposit,
                dialogOnCancel: () => {
                    deleteAuToDepositResponseHandler(
                        RESPONSE_CODE.TECHNICAL_DIFFICULT
                    );
                },
            });
            return;
        }
        deleteAuToDepositResponseHandler(code);
    };

    const handleShowConfirmDelete = () => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconCustom: tommyNoHope,
                header: t("TD:td_recurring_popup_delete_title"),
                content: t("TD:td_recurring_popup_delete_message"),
                doubleButton: [
                    {
                        label: t("lb_yes"),
                        onClick: () => {
                            handleDeleteItem();
                            GlobalDialogController.hide();
                        },
                    },

                    {
                        label: t("lb_cancel"),
                        onClick: () => {
                            GlobalDialogController.hide();
                            handleClose();
                        },
                    },
                ],
            },
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <LspTypography variant="subheading2">{termName}</LspTypography>
            </div>

            <LspTypography
                variant="body2"
                color="grey"
                component="div"
                className={classes.text}
            >
                {t("TD:td_recurring_amount_field")}&nbsp;
                <LspTypography
                    variant="subheading3"
                    component="span"
                    className={classes.text}
                >
                    {formatNumber(amountOfDeposit, false, true)}
                </LspTypography>
            </LspTypography>
            <LspTypography
                variant="body2"
                color="grey"
                component="div"
                className={classes.text}
            >
                {t("TD:td_recurring_term_field")}&nbsp;
                <LspTypography variant="subheading3" component="span">
                    {term}
                </LspTypography>
            </LspTypography>
            <LspTypography
                variant="body2"
                color="grey"
                component="div"
                className={classes.text}
            >
                {t("TD:td_recurring_rollover_field")}&nbsp;
                <LspTypography variant="subheading3" component="span">
                    {tdRollType}
                </LspTypography>
            </LspTypography>
            <LspTypography
                variant="body2"
                color="grey"
                component="div"
                className={classes.text}
            >
                {t("TD:td_recurring_next_deposit_field")}&nbsp;
                <LspTypography variant="subheading3" component="span">
                    {nextDepositDate}
                </LspTypography>
            </LspTypography>

            <IconButton
                aria-label="more"
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.action}
            >
                {isLoadingDropdown ? (
                    <CircularProgress size={18} />
                ) : (
                    <MoreVertIcon />
                )}
            </IconButton>

            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleShowConfirmDelete}>
                    <Icon className={`font-icon icon-delete ${classes.icon}`} />
                    <LspTypography variant="heading3">
                        {t("TD:td_recurring_popup_delete_title")}
                    </LspTypography>
                </MenuItem>
            </Menu>
        </div>
    );
};

const dispatchProps = (dispatch) => ({
    getAutoDepositList: () => dispatch(termDepositAction.getAutoDepositList()),
});

export default connect(null, dispatchProps)(memo(AutoDepositCard));
