import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import useSharedClasses from "@helpers/styleContentModal";
import activationService from "@services/activation";
import { memo, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { JSEncrypt } from "jsencrypt/bin/jsencrypt";
import { isFunction } from "lodash";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAPK);

const ActivationForm = ({ statusList, token, resultHandler }) => {
    const classes = useSharedClasses();
    const { t } = useTranslation();
    const defaultValues = {
        username: "",
        password: "",
    };

    const {
        register,
        handleSubmit,
        errors,
        formState,
        getValues,
        setError,
    } = useForm({
        defaultValues,
    });

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const usernameValidation = () => {
        const uName = getValues("username");
        if (!uName) {
            return;
        }
        return (
            (!REGEX_PATTERN.FIRST_CHARACTER_IS_LETTER.test(uName) &&
                t("kyc_error_msg_username_should_begin_with_letter")) ||
            null
        );
    };

    const responseHandler = (info) => {
        if (isFunction(resultHandler)) {
            resultHandler(info);
        }
    };

    const requestCreateAuthentication = async (data) => {
        const payload = {
            activationToken: token,
            username: data?.username,
            password: crypt.encrypt(data?.password),
        };

        const response = await activationService.requestCreateAuthentication(
            payload
        );

        if (unmounted.current) return;

        const { code } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                responseHandler({
                    status: statusList.SUCCESS,
                    message: t("new_join_activate_congratulation_desc_1"),
                });
                break;
            case RESPONSE_CODE.DUPLICATED_USERNAME:
                setError("username", {
                    type: "manual",
                    message: t("join_msg_username_duplicated"),
                });
                break;
            case RESPONSE_CODE.TOKEN_EXPIRED:
                responseHandler({
                    status: statusList.TOKEN_EXPIRED,
                    message: t("new_join_txt_subtitle_expired_token"),
                });
                break;
            case RESPONSE_CODE.PASSWORD_POLICIES_VIOLATED:
            case RESPONSE_CODE.USERNAME_POLICIES_VIOLATED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Error",
                        header: "popup_title_security",
                        content:
                            code === RESPONSE_CODE.PASSWORD_POLICIES_VIOLATED
                                ? "password_rule_msg"
                                : "username_rule_msg",
                        button: "lb_ok",
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };
    return (
        <form onSubmit={handleSubmit(requestCreateAuthentication)}>
            <div className={classes.desc}>{t("new_join_activate_desc")}</div>
            <LspTextField
                autoFocus
                name="username"
                error={!!errors.username}
                label={t("join_lb_username")}
                helperText={errors.username?.message || " "}
                inputProps={{
                    maxLength: 35,
                    ref: register({
                        required: t("msg_we_need_this"),
                        pattern: {
                            value: REGEX_PATTERN.USERNAME,
                            message: t("join_msg_username_invalid"),
                        },
                        validate: () => usernameValidation(),
                    }),
                }}
            />
            <LspTextField
                autoFocus
                name="password"
                error={!!errors.password}
                label={t("join_lb_password")}
                helperText={errors.password?.message || " "}
                inputProps={{
                    maxLength: 32,
                    type: "password",
                    ref: register({
                        required: t("msg_we_need_this"),
                        pattern: {
                            value: REGEX_PATTERN.PASSWORD,
                            message: t("join_msg_password_invalid"),
                        },
                        minLength: {
                            value: 8,
                            message: t("global_msg_password_error"),
                        },
                        maxLength: {
                            value: 32,
                            message: t("global_msg_password_error"),
                        },
                    }),
                }}
            />
            <LspButton
                type="submit"
                progressing={formState.isSubmitting}
                className={classes.submitButton}
                fullWidth
            >
                {t("new_join_btn_submit_my_info")}
            </LspButton>
        </form>
    );
};

export default memo(ActivationForm);
