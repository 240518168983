import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { NotificationActionType } from "../actions/notification";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    list: null,
    idIndex: null,
    hasMore: true,
    numberOfNotification: 0,
    suggestedNotifications: [],
};

/* ------------- Reducer ------------- */
const notificationReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case NotificationActionType.CheckNotificationRequest:
                break;
            case NotificationActionType.CheckNotificationSuccess:
                draft.numberOfNotification =
                    action.payload.numberOfNotification;
                draft.suggestedNotifications =
                    action.payload.suggestedNotifications;
                break;
            case NotificationActionType.CheckNotificationError:
                break;

            case NotificationActionType.GetNotificationListRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                if (!action.payload.loadMore) {
                    draft.idIndex = null;
                    draft.hasMore = true;
                    draft.list = null;
                }
                break;
            case NotificationActionType.GetNotificationListSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                if (!draft.idIndex) {
                    draft.list = action.payload.notifyList;
                } else {
                    draft.list = [...draft.list, ...action.payload.notifyList];
                }
                draft.idIndex = action.payload.idIndex;
                draft.hasMore = Boolean(action.payload.idIndex);
                break;
            case NotificationActionType.GetNotificationListError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default notificationReducer;
