import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import PageHeader from "@components/PageHeader";
import TermConditionDialog from "@components/TermCondition/TermConditionDialog";
import {
    REGULATION_TYPE,
    RESPONSE_CODE,
    TermDepositRollType,
} from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useForeignerDialog from "@helpers/useForeignerDialog";
import {
    Box,
    Checkbox,
    FormControlLabel,
    makeStyles,
    Typography,
} from "@material-ui/core";
import accountAction from "@redux/actions/account";
import termDepositService from "@services/term-deposit";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import termDepositAction from "@redux/actions/term-deposit";
import useOTP from "@helpers/useOTP";
import LspTranslation from "@components/LspTranslation";
import MultipleTDTable from "./MultipleTDTable";
import RedemptionPrediction from "./RedemptionPrediction";
import useTermDeposit from "./useTermDeposit";
import AutoDepositSetting from "../AutoDeposit/AutoDepositSetting";
import RolloverMethod from "./RolloverMethod";

const useStyles = makeStyles((theme) => ({
    suggestionText: {
        textAlign: "center",
        color: theme.palette.grey[500],
        fontStyle: "italic",
    },
    previewInput: {
        marginBottom: theme.spacing(2),
    },
}));

const TermDepositReview = ({
    stepBack,
    payload,
    suggestion,
    setSuggestion,
    getAccountList,
    checkAutoDeposit,
    isExisted,
    featureState,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { dialog } = useForeignerDialog();
    const { checkForeignerBeforeOpenTD } = useTermDeposit();

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const history = useHistory();
    const { generateName } = useTermDeposit();
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [autoDepositCheckBox, setAutoDepositCheckBox] = useState(false);
    const [rollType, setRollType] = useState(
        suggestion?.enableRollingOnlyPrinciple
            ? TermDepositRollType.PARTIAL_ROLLOVER
            : TermDepositRollType.TOTAL_ROLLOVER
    );

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    useEffect(() => {
        checkAutoDeposit();
    }, [checkAutoDeposit]);

    useEffect(() => {
        setAutoDepositCheckBox(!isExisted);
    }, [isExisted]);

    const multipleTDAvailable = useMemo(
        () => Boolean(suggestion?.numberOfDeposit > 1),
        [suggestion]
    );

    const [option, setOption] = useState(() => {
        if (multipleTDAvailable) {
            return "multiple";
        }
        return "single";
    });

    const [accepted, setAccepted] = useState(false);

    const onOptionChange = useCallback((e) => {
        if (e.target.checked) {
            setOption("multiple");
            return;
        }
        setOption("single");
    }, []);

    const onNameChange = useCallback((e) => {
        setName(e.target.value);
    }, []);

    const handleGenerationParams = useCallback(() => {
        const clonedPayload = cloneDeep(payload);
        clonedPayload.termName = generateName(name);

        if (option === "multiple") {
            if (rollType !== TermDepositRollType.NO_ROLLOVER) {
                clonedPayload.detail = suggestion.detail.map((item, idx) => ({
                    ...item,
                    termName: name?.trim() ? generateName(name, idx + 1) : "",
                    autoRoll: true,
                    rollType,
                }));
            } else {
                clonedPayload.detail = suggestion.detail.map((item, idx) => ({
                    termName: name?.trim() ? generateName(name, idx + 1) : "",
                    autoRoll: false,
                    amountOfDeposit: item.amountOfDeposit,
                }));
            }
            clonedPayload.numberOfDeposit = suggestion.numberOfDeposit;
        } else {
            clonedPayload.numberOfDeposit = 1;
            const params = {
                amountOfDeposit: payload.amountOfDeposit,
                termName: name?.trim() ? generateName(name) : "",
            };

            if (rollType === TermDepositRollType.NO_ROLLOVER) {
                params.autoRoll = false;
            } else {
                params.rollType = rollType;
                params.autoRoll = true;
            }

            clonedPayload.detail = [params];
        }
        return clonedPayload;
    }, [
        generateName,
        name,
        option,
        payload,
        rollType,
        suggestion.detail,
        suggestion.numberOfDeposit,
    ]);

    const handleCreateTD = useCallback(async () => {
        const params = handleGenerationParams();

        let response = null;
        if (autoDepositCheckBox) {
            response = await termDepositService.openTermDeposit(params);
        } else {
            response = await termDepositService.openTermDepositCheck(params);
        }

        if (unmounted.current) {
            return;
        }
        const { code, data } = response.data;
        setSubmitting(false);
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                for (let i = 0; i < data?.failedTermDeposit; i++) {
                    AlertController.show(
                        t("td_ms_open_1_td_fail"),
                        AlertType.Error
                    );
                }
                for (let i = 0; i < data?.successTermDeposit; i++) {
                    AlertController.show(t("td_msg_success_openTD"));
                }
                setLoading(false);
                closeOTPDialog();
                history.push("/term-deposit");
                break;
            case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                dialog(REGULATION_TYPE.TD_CANNOT_OPEN);
                break;
            default:
                history.push("/term-deposit");
                GlobalDialogController.showFinancialError({ errorCode: code });
                break;
        }
        getAccountList();
    }, [
        autoDepositCheckBox,
        closeOTPDialog,
        dialog,
        getAccountList,
        handleGenerationParams,
        history,
        setLoading,
        t,
    ]);

    const autoDepositResponseHandler = useCallback(
        (code, data) => {
            setLoading(false);
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    if (data?.termId) {
                        handleCreateTD();
                        return;
                    }
                    closeOTPDialog();
                    AlertController.show(
                        t("TD:td_recurring_setup_failure"),
                        AlertType.Error
                    );
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE:
                    AlertController.show(
                        <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                        AlertType.Error
                    );
                    break;
                case RESPONSE_CODE.AUTH_BIOS:
                case RESPONSE_CODE.AUTH:
                case RESPONSE_CODE.BIOS_NEW_DEVICE:
                case RESPONSE_CODE.FOREIGNER:
                case RESPONSE_CODE.RE_ENROLL:
                case RESPONSE_CODE.REQUIRED_ENROLL:
                case RESPONSE_CODE.RE_ENROLL_ERROR:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
                default:
                    closeOTPDialog();
                    AlertController.show(
                        t("TD:td_recurring_setup_failure"),
                        AlertType.Error
                    );

                    break;
            }
        },
        [handleCreateTD, closeOTPDialog, t, setLoading]
    );

    const commitOtpAutoDeposit = useCallback(
        async (refNo) => {
            setLoading(true);

            const commitAutoRepositResponse = await termDepositService.commitOpenAutoDeposit(
                refNo
            );
            const { code, data } = commitAutoRepositResponse.data;

            autoDepositResponseHandler(code, data);
        },
        [setLoading, autoDepositResponseHandler]
    );

    const submit = useCallback(async () => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const params = handleGenerationParams();

        if (autoDepositCheckBox && featureState?.enableRecurringTd) {
            const autoRepositResponse = await termDepositService.openAutoDeposit(
                params
            );

            const { code, data } = autoRepositResponse.data;

            setSubmitting(false);

            if (code === RESPONSE_CODE.OTP_REQUIRED) {
                openOTPDialog({
                    refNo: data?.refNo,
                    dialogDescription: t(
                        "iotp_general_otp_td_auto_deposit_desc"
                    ),
                    submitFunc: commitOtpAutoDeposit,
                    dialogOnCancel: () => {
                        autoDepositResponseHandler(
                            RESPONSE_CODE.TECHNICAL_DIFFICULT
                        );
                    },
                });
                return;
            }

            autoDepositResponseHandler(code, data);
            return;
        }
        handleCreateTD();
    }, [
        submitting,
        handleGenerationParams,
        autoDepositCheckBox,
        featureState?.enableRecurringTd,
        handleCreateTD,
        openOTPDialog,
        commitOtpAutoDeposit,
        autoDepositResponseHandler,
        t,
    ]);

    const checkForeignerBeforeSubmit = useCallback(() => {
        const haveRollOver =
            option === "multiple"
                ? suggestion?.detail.filter((item) => item?.autoRoll).length > 0
                : suggestion?.rollOver;

        if (haveRollOver) {
            checkForeignerBeforeOpenTD({
                onConfirm: submit,
            });
            return;
        }

        submit();
    }, [
        option,
        suggestion?.detail,
        suggestion?.rollOver,
        submit,
        checkForeignerBeforeOpenTD,
    ]);

    const openTermCondition = (e) => {
        e.preventDefault();
        const url = `${process.env.REACT_APP_API_URL}${t(
            "tnc_popup_content_url_web"
        )}`;

        GlobalDialogController.show({
            component: () => (
                <TermConditionDialog
                    header={t("td_title_term_conditions")}
                    url={url}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    return (
        <>
            <Content size="xl">
                <PageHeader backFunc={stepBack}>
                    {t("td_lb_review_details")}
                    <Typography variant="body1">
                        {t("td_desc_open_td_1")} {t("td_desc_open_td_2")}
                    </Typography>
                </PageHeader>
            </Content>
            <Content size="sm" boxProps={{ pt: 0.5 }}>
                <div
                    className={multipleTDAvailable ? "" : classes.previewInput}
                >
                    <LspTextField
                        label={t("td_txt_placeholder_name_td")}
                        value={name}
                        onChange={onNameChange}
                        inputProps={{
                            maxLength: 30,
                            datatestid: "termCustomName",
                        }}
                    />
                </div>

                {multipleTDAvailable && (
                    <>
                        <Box>
                            <Typography
                                variant="body2"
                                className={classes.suggestionText}
                            >
                                {t("td_lb_avoid_losing_interest")}
                            </Typography>
                        </Box>
                        <Box
                            mb={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={option === "multiple"}
                                        onChange={onOptionChange}
                                        color="primary"
                                        inputProps={{
                                            datatestid: "multipleTDOption",
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {t("td_lb_open_multi_td")}
                                    </Typography>
                                }
                            />
                        </Box>
                    </>
                )}
            </Content>
            <Content size="sm">
                <MultipleTDTable
                    option={option}
                    {...suggestion}
                    setSuggestion={setSuggestion}
                    termName={name}
                />

                <Box mb={3}>
                    <RedemptionPrediction
                        amount={payload.amountOfDeposit}
                        termDetail={payload.termDetail}
                    />
                </Box>

                {featureState?.enableRecurringTd && (
                    <Box mb={3}>
                        <AutoDepositSetting
                            checked={autoDepositCheckBox}
                            onClick={() =>
                                setAutoDepositCheckBox((prev) => !prev)
                            }
                        />
                    </Box>
                )}
                <RolloverMethod
                    selected={
                        !suggestion?.enableRollingOnlyPrinciple
                            ? TermDepositRollType.TOTAL_ROLLOVER
                            : TermDepositRollType.PARTIAL_ROLLOVER
                    }
                    onSelected={({ id }) => {
                        setRollType(id);
                    }}
                    showRollover={suggestion?.enableRollingOnlyPrinciple}
                />

                <Box textAlign="center" mb={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={accepted}
                                onChange={(e) => setAccepted(e.target.checked)}
                                color="primary"
                                inputProps={{
                                    datatestid: "termConditionOption",
                                }}
                            />
                        }
                        label={
                            <Box display="flex">
                                <LspTypography variant="body2">
                                    {t("td_text_accept_term_conditions_1")}{" "}
                                </LspTypography>
                                <a
                                    onClick={openTermCondition}
                                    style={{ marginLeft: 4 }}
                                >
                                    <LspTypography
                                        variant="hyperlink1"
                                        color="success"
                                    >
                                        {t("td_text_accept_term_conditions_2")}
                                    </LspTypography>
                                </a>
                            </Box>
                        }
                    />
                </Box>
                <LspButton
                    fullWidth
                    onClick={checkForeignerBeforeSubmit}
                    disabled={!accepted}
                    progressing={submitting}
                    buttonProps={{
                        datatestid: "submitOpenTD",
                    }}
                >
                    {t("termDeposit_lb_open_td")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    isExisted: state.termDeposit.autoDeposit.isExisted,
    featureState: state.user.featureState.data,
});

const mapDispatch = (dispatch) => ({
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
    checkAutoDeposit: () => dispatch(termDepositAction.checkAutoDeposit()),
});

export default connect(mapState, mapDispatch)(TermDepositReview);
