import { memo, useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import {
    Icon,
    makeStyles,
    Box,
    Paper,
    CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ContentWrapper from "@components/ContentWrapper";
import logo from "@assets/images/bitmap-min.png";
import LspTypography from "@components/LspTypography";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { WEALTH_PRODUCT_KEYS, WEALTH_STATUSES } from "../wealthConstants";

const useStyles = makeStyles((theme) => {
    return {
        listItem: {
            marginTop: theme.spacing(5),
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
            "& > div": {
                position: "relative",
                padding: theme.spacing(5, 2.5),
                flex: 1,
                textAlign: "center",
                marginRight: theme.spacing(2),
                boxShadow: theme.shadows[4],
                [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    marginBottom: theme.spacing(2),
                },
                "&:last-child": {
                    marginRight: 0,
                },

                "& > p": {
                    marginBottom: theme.spacing(0.5),

                    "&:nth-child(0)": {
                        margin: theme.spacing(3, 0, 0.5, 0),
                    },
                },
                "& > i": {
                    fontSize: "50px",
                },
            },
        },
        logo: {
            width: 160,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(5),
        },
        icon: {
            fontSize: theme.typography.pxToRem(50),
            color: theme.palette.primary.main,
        },
        status: {
            position: "absolute",
            top: 0,
            right: 0,
            color: theme.palette.neutral.white,
            padding: theme.spacing(1, 2),
            fontWeight: "700",
            background: "#398ae9",
            borderBottomLeftRadius: theme.spacing(1.25),
            fontSize: theme.typography.pxToRem(12),
            borderRadius: "2px",
        },
    };
});

const LifeInsurance = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [listItemDashboard, setListItemDashboard] = useState([]);
    const classes = useStyles();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const navigateLife = useCallback(
        (productKey) => {
            history.push(productKey);
        },
        [history]
    );

    const getSubProducts = useCallback(async () => {
        setLoading(true);
        const { data } = await wealthService.getSubProducts(
            WEALTH_PRODUCT_KEYS.LIFE_INSURANCE2
        );

        if (unmounted.current) return;

        if (data.code !== RESPONSE_CODE.SUCCESS) {
            GlobalDialogController.showError({ errorCode: data.code });
            return;
        }
        setLoading(false);
        const { subProducts } = data.data;
        const contentDashboardList = [];
        if (data.data.isSkipIntro) {
            console.log("skip");
            // wealthProductsFactory.setLifeInsuranceIntro(dataReturn.isSkipIntro);
            // navigateLife(dataReturn.subProKey);
        } else if (subProducts) {
            subProducts.forEach((item) => {
                const contentDashboard = {};
                contentDashboard.productKey = item.productKey;
                contentDashboard.productName = item.productName || "";
                contentDashboard.FEIcon = t(
                    `wealth:SI00019_${item.productKey}`
                );
                if (
                    item.productKey ===
                    WEALTH_PRODUCT_KEYS.LIFE_INSURANCE_INSTANT_COVERAGE
                ) {
                    if (item.status.toLowerCase() === WEALTH_STATUSES.APPLIED) {
                        contentDashboard.FEStatus = t("wealth:SI00091");
                        contentDashboard.FEBtnLabel = t("wealth:SI00065");
                        contentDashboard.FEBtnLink = () => {
                            navigateLife(
                                WEALTH_PRODUCT_KEYS.LIFE_INSURANCE_MANAGE_INSTANT_COVERAGE
                            );
                        };
                    } else {
                        if (item.status.toLowerCase() === WEALTH_STATUSES.NEW) {
                            contentDashboard.FEStatus = WEALTH_STATUSES.NEW;
                        } else {
                            contentDashboard.FEStatus = item.status;
                        }
                        contentDashboard.FEBtnLabel = t(
                            `wealth:SI00003_${item.productKey}`
                        );
                        contentDashboard.FEBtnLink = () => {
                            navigateLife(item.productKey);
                        };
                    }
                } else {
                    contentDashboard.FEStatus = "";
                    contentDashboard.FEBtnLabel = t(
                        `wealth:SI00003_${item.productKey}`
                    );
                    contentDashboard.FEBtnLink = () => {
                        navigateLife(item.productKey);
                    };
                }
                contentDashboard.FEDesc = t(
                    `wealth:SI00002_${item.productKey}`
                );
                contentDashboardList.push(contentDashboard);
            });
            setListItemDashboard(contentDashboardList);
        }
    }, [t, navigateLife]);

    useEffect(() => {
        getSubProducts();
    }, [getSubProducts]);

    return (
        <ContentWrapper>
            <Content>
                {loading && (
                    <Box textAlign="center">
                        <CircularProgress size={18} />
                    </Box>
                )}
                {!loading && (
                    <>
                        <Box textAlign="center">
                            <img src={logo} alt="" className={classes.logo} />
                            <LspTypography variant="body2" color="black">
                                {t("wealth:SI00053")}
                            </LspTypography>
                        </Box>
                        <Box className={classes.listItem}>
                            {listItemDashboard.map((elm) => (
                                <Paper key={elm.productKey}>
                                    {elm.FEStatus && (
                                        <span className={classes.status}>
                                            {elm.FEStatus}
                                        </span>
                                    )}
                                    <Icon
                                        className={`font-icon icon-${elm.FEIcon} ${classes.icon}`}
                                    />
                                    <Box mt={3} mb={0.5}>
                                        <LspTypography
                                            variant="title1"
                                            color="primary"
                                        >
                                            {elm.productName}
                                        </LspTypography>
                                    </Box>
                                    <Box mb={2} minHeight="50px">
                                        <LspTypography
                                            variant="body1"
                                            color="black"
                                        >
                                            {elm.FEDesc}
                                        </LspTypography>
                                    </Box>
                                    <LspButton
                                        onClick={elm.FEBtnLink}
                                        fullWidth
                                    >
                                        {elm.FEBtnLabel}
                                    </LspButton>
                                </Paper>
                            ))}
                        </Box>
                    </>
                )}
            </Content>
        </ContentWrapper>
    );
};

export default memo(LifeInsurance);
