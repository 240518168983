import { connect } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import querystring from "query-string";
import {
    Box,
    Icon,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Link,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Skeleton } from "@material-ui/lab";
import Interweave from "interweave";
import Content from "@components/Content";
import PageHeaderBack from "@components/PageHeaderBack";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { CARD_ACTIONS, CardManagementNavigation } from "./constant";
import useNumber from "../../helpers/useNumber";
import CardManagementActions from "../../redux/actions/card-management";
import { FetchStatus } from "../../config/constants";
import LspReceipt from "../../components/LspReceipt";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    header: {
        padding: theme.spacing(3, 3, 1, 3),
        fontSize: theme.typography.pxToRem(20),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
        textAlign: "center",
        "& > div": {
            marginTop: theme.spacing(1),
        },
    },
    subtitle: {
        padding: theme.spacing(0, 3),
    },
    divider: {
        margin: theme.spacing(2, "auto"),
        width: "100%",
        height: theme.typography.pxToRem(1),
        background: theme.palette.grey[300],
    },
    hangoutLoading: {
        padding: theme.spacing(0, 3, 3, 3),
    },
    hangoutList: {
        listStyle: "none",
        padding: 0,
        cursor: "pointer",
    },
    hangoutItem: {
        padding: theme.spacing(1, 3),
        "&:hover": {
            background: theme.palette.grey[200],
        },
    },
    confirmStep: {
        padding: theme.spacing(0, 3, 3, 3),
    },
    error: {
        color: theme.palette.error.main,
        textAlign: "center",
    },
    successScreen: {
        textAlign: "center",
        paddingBottom: theme.spacing(4),
    },
    receipt: {
        maxWidth: theme.typography.pxToRem(555),
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.radiusLevels[0],
        margin: "auto",
        overflow: "hidden",
    },
    receiptBtn: {},
}));

const CardReIssue = ({
    reIssueLoading,
    reIssueCard,
    hangoutListLoading,
    getHangoutList,
    hangoutList,
    serverTime,
    reIssueCardFetchStatus,
    bankXID,
    resetState,
}) => {
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { cardId } = params;
    const classes = useStyles();
    const { t, i18n } = useTranslation("translation", "hangout");
    const [mappedHangouts, setMappedHangouts] = useState(null);
    const [selectedHangout, setSelectedHangout] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const { formatNumber } = useNumber();
    // const bankXID = 'TF201030141926491';
    const history = useHistory();

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    const steps = useMemo(
        () => [
            {
                id: 0,
                value: "HANGOUT_STEP",
            },
            {
                id: 1,
                value: "CONFIRM_STEP",
            },
            {
                id: 2,
                value: "SUCCESS_STEP",
            },
            {
                id: 3,
                value: "RECEIPT_STEP",
            },
        ],
        []
    );

    const back = useCallback(() => {
        if (activeStep === 0) {
            history.goBack();
            return;
        }
        setActiveStep(activeStep - 1);
    }, [activeStep, history]);

    const next = useCallback(() => {
        if (activeStep === steps.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, steps]);

    const getHangoutListReIssue = useCallback(() => {
        const payload = {
            id: CARD_ACTIONS.REISSUE_NEW_CARD_PIN,
            language: i18n.language,
        };
        getHangoutList(payload);
    }, [getHangoutList, i18n.language]);

    const mapHangout = useCallback(() => {
        const hangoutListContent = t("hangout:hangouts", {
            returnObjects: true,
        });
        const hangouts = [];
        const defaultHangout = hangoutListContent[0]; // HCM hangout

        for (let i = 0; i < hangoutList.length; i++) {
            const foundHangout = hangoutListContent.find(
                (item) => item?.provinceId === hangoutList[i].provinceId
            );

            const amount = hangoutList[i]?.amount || 0;
            let fullName;
            let address;

            if (foundHangout) {
                fullName = foundHangout?.fullName;
                address = hangoutList[i]?.receiveDetail;
                // address = foundHangout?.address;
            } else {
                fullName = defaultHangout?.fullName;
                address = hangoutList[i]?.receiveDetail;
            }

            hangouts.push({
                ...hangoutList[i],
                FEFullName:
                    amount > 0
                        ? `${fullName} - ${formatNumber(amount)} VND`
                        : fullName,
                FEAddress: address,
            });
        }
        setMappedHangouts(hangouts);
    }, [hangoutList, t, formatNumber]);

    const hangoutClickHandler = (hangout) => {
        setSelectedHangout(hangout);
        next();
    };

    const reIssueCardHandler = () => {
        const dataToPost = {
            cardId,
            requestId: CARD_ACTIONS.REISSUE_NEW_CARD_PIN,
            receiveRequestId: selectedHangout.receiveRequestId,
            receiveDetail: selectedHangout.receiveDetail,
            lang: i18n.language,
            notification: "sms",
            provinceId: parseInt(selectedHangout.provinceId),
            requestedDate: format(serverTime.value, "dd/MM/yyyy"),
        };
        reIssueCard(dataToPost);
    };

    const getErrorMsg = () => {
        const txt = t("spend_msg_for_least_amount").replace(
            "%@",
            `<b>${formatNumber(selectedHangout.amount)}</b>`
        );

        return (
            <Interweave
                content={`
                        <div>${txt}</div>
                        <div>${t("rq_document_please_contribute")}</div>
                    `}
            />
        );
    };

    const viewReceipt = (event) => {
        event.preventDefault();
        if (bankXID) {
            // LspReceiptController.dialog(bankXID, () => {});
            // open  receipt.....
            next();
        }
    };

    const reIssueSuccessfully = () => {
        history.push(CardManagementNavigation.Debit);
    };

    useEffect(() => {
        getHangoutListReIssue();
    }, [getHangoutListReIssue]);

    useEffect(() => {
        if (hangoutList) {
            mapHangout();
        }
    }, [hangoutList, mapHangout]);

    useEffect(() => {
        if (reIssueCardFetchStatus === FetchStatus.Success) {
            next();
        }
    }, [reIssueCardFetchStatus, next]);

    return (
        <Content size="sm">
            <div className={classes.wrapper}>
                {activeStep !== 3 && (
                    <Paper>
                        <Box>
                            <Box
                                paddingTop={2}
                                paddingLeft={2}
                                paddingRight={2}
                            >
                                <PageHeaderBack onBack={back}>
                                    <Icon className="font-icon icon-billsplit" />
                                    <div>{t("reissue_new_pin_card_title")}</div>
                                    <LspTypography variant="body2" inherit>
                                        {t(
                                            "rq_document_confirm_document_question"
                                        )}
                                    </LspTypography>
                                </PageHeaderBack>
                            </Box>

                            {/* Hangout List */}
                            {activeStep === 0 && (
                                <div>
                                    <div className={classes.subtitle}>
                                        <div className={classes.divider} />
                                        <div style={{ marginBottom: "8px" }}>
                                            <b>
                                                {t(
                                                    "request_document_lb_pick_up_hangout"
                                                )}
                                            </b>
                                        </div>
                                    </div>

                                    {hangoutListLoading && (
                                        <div className={classes.hangoutLoading}>
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </div>
                                    )}

                                    {!hangoutListLoading && (
                                        <List disablePadding>
                                            {mappedHangouts?.map((hangout) => (
                                                <ListItem
                                                    button
                                                    key={
                                                        hangout.receiveRequestId
                                                    }
                                                    className={
                                                        classes.hangoutItem
                                                    }
                                                    onClick={() =>
                                                        hangoutClickHandler(
                                                            hangout
                                                        )
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            hangout.FEFullName
                                                        }
                                                        secondary={
                                                            hangout.FEAddress
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </div>
                            )}

                            {/* Confirm step */}
                            {activeStep === 1 && (
                                <div className={classes.confirmStep}>
                                    <div style={{ textAlign: "center" }}>
                                        <p>
                                            <Interweave
                                                content={t(
                                                    "reissue_new_pin_card_confirm_address_and_date"
                                                ).replace(
                                                    "[ADDRESS]",
                                                    selectedHangout?.receiveDetail
                                                )}
                                            />
                                        </p>
                                        <p>
                                            <Interweave
                                                content={t(
                                                    "reissue_new_pin_card_service_fee"
                                                ).replace(
                                                    "[AMOUNT]",
                                                    formatNumber(
                                                        selectedHangout?.amount
                                                    )
                                                )}
                                            />
                                        </p>
                                    </div>
                                    {reIssueCardFetchStatus ===
                                        FetchStatus.Error && (
                                        <div className={classes.error}>
                                            {getErrorMsg()}
                                        </div>
                                    )}
                                    <div style={{ marginTop: "32px" }}>
                                        <LspButton
                                            onClick={reIssueCardHandler}
                                            progressing={reIssueLoading}
                                            fullWidth
                                        >
                                            {t("lb_continue")}
                                        </LspButton>
                                    </div>
                                </div>
                            )}

                            {/* Success Screen */}
                            {activeStep === 2 && (
                                <div className={classes.successScreen}>
                                    <Interweave
                                        content={t(
                                            "reissue_new_card_pin_successful_content"
                                        )}
                                    />
                                    {!!bankXID && (
                                        <div className={classes.receiptBtn}>
                                            <Link
                                                to="/#"
                                                href="#"
                                                onClick={viewReceipt}
                                            >
                                                {t(
                                                    "investigation_btn_view_receipt"
                                                )}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Box>
                    </Paper>
                )}

                {/* Receipt */}
                {activeStep === 3 && (
                    <div className={classes.receipt}>
                        <LspReceipt
                            bankXID={bankXID}
                            onClose={reIssueSuccessfully}
                        />
                    </div>
                )}
            </div>
        </Content>
    );
};

const stateProps = (state) => ({
    reIssueLoading: state.cardInfo.reIssueCardLoading,
    hangoutListLoading: state.cardInfo.hangoutListLoading,
    hangoutList: state.cardInfo.hangoutList,
    serverTime: state.user.serverTime,
    reIssueCardFetchStatus: state.cardInfo.reIssueCardFetchStatus,
    bankXID: state.cardInfo.bankXID,
});

const dispatchProps = (dispatch) => ({
    reIssueCard: (payload) =>
        dispatch(CardManagementActions.reIssueCard(payload)),
    getHangoutList: (payload) =>
        dispatch(CardManagementActions.getHangoutListReIssue(payload)),
    resetState: () => dispatch(CardManagementActions.resetState()),
});

export default connect(stateProps, dispatchProps)(CardReIssue);
