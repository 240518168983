import LspTypography from "@components/LspTypography";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { INVITATION_TIERS } from "@containers/Invitation/constant";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import InfoTooltip from "@components/InfoTooltip";
import InvitationLevelBarItem from "./InvitationLevelBarItem";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "auto",
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            width: 285,
            marginTop: theme.spacing(4),
        },
    },
    icon: {
        color: "rgba(255,255,255, 0.5)",
        "& svg": {
            fontSize: "1rem",
        },
    },
    barWrapper: {
        position: "relative",
        height: 84,
        width: 338,
        paddingTop: theme.spacing(2),
        paddingRight: 0,
        paddingLeft: theme.spacing(1),
        margin: "auto",
        [theme.breakpoints.up("sm")]: {
            width: 285,
        },
    },
    progress: {
        position: "absolute",
        height: theme.spacing(1),
        background: theme.palette.success.main,
        transition: "width 0.2s",
    },
    barBackground: {
        position: "absolute",
        height: theme.spacing(1),
        background: "rgba(255,255,255, 0.32)",
    },
}));

const InvitationLevelBar = ({ detail, tierList }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const [currentWidth, setCurrentWidth] = useState(0);
    const [activePointIds, setActivePointIds] = useState([]);

    const getCurrentTierInfo = useCallback(
        (currentTier) => {
            const tier = tierList.filter(
                (item) => item.className === currentTier
            );
            return tier.length > 0 ? tier[0] : {};
        },
        [tierList]
    );

    const getContentName = useCallback(
        (className) => {
            let name = "";
            switch (className) {
                case INVITATION_TIERS.RISING_STAR:
                    name = t("member:timostarclub_lb_bar_rising_star");
                    break;
                case INVITATION_TIERS.SHINING_STAR:
                    name = t("member:timostarclub_lb_bar_shining_star");
                    break;
                case INVITATION_TIERS.SUPER_STAR:
                default:
                    name = t("member:timostarclub_lb_bar_super_star");
                    break;
            }
            return name;
        },
        [t]
    );

    const title = useMemo(() => {
        if (!detail.currentClass) return;

        const tier = getCurrentTierInfo(detail?.currentClass);

        const maxPeople =
            tier?.className === INVITATION_TIERS.SUPER_STAR
                ? tier?.fromPoint
                : tier?.toPoint + 1;

        return t("member:timostarclub_lb_count_bar")
            .replace("%@", detail?.currentPoint)
            .replace("%@", maxPeople);
    }, [detail, t, getCurrentTierInfo]);

    const tierBarList = useMemo(() => {
        if (!tierList) return;

        const list = tierList.filter(
            (item) => item.className !== INVITATION_TIERS.BEGINNER
        );
        return list.map((item) => {
            const contentName = getContentName(item.className);

            return {
                ...item,
                id: item.className.replace(/\s/g, ""),
                contentName,
            };
        });
    }, [tierList, getContentName]);

    const barConfigs = useMemo(() => {
        if (!tierBarList) return;

        const space = isMobile ? 160 : 134;
        return {
            itemLength: tierBarList?.length,
            space,
            iconSize: 16,
            maxWidth: (tierBarList?.length - 1) * space,
            maxPoint: tierBarList[tierBarList?.length - 1]?.fromPoint || 0,
        };
    }, [tierBarList, isMobile]);

    const initBar = useCallback(
        (info) => {
            if (
                !info?.currentClass ||
                info?.currentClass === INVITATION_TIERS.BEGINNER ||
                info?.currentPoint === 0
            )
                return;

            const tier = getCurrentTierInfo(info?.currentClass);

            let width = 0;
            const pointScale = tier.toPoint - tier.fromPoint + 1; // start fromPoint -> +1
            let percent = 0;
            const point = info?.currentPoint;
            const activeIds = [];

            for (let i = 0; i < tierBarList.length; i++) {
                activeIds.push(tierBarList[i].id);
                if (tierBarList[i].className === info?.currentClass) {
                    percent = ((point - tier.fromPoint) * 100) / pointScale;
                    break;
                } else {
                    width += barConfigs.space;
                }
            }

            width += (barConfigs.space * percent) / 100;

            if (
                width > barConfigs?.maxWidth ||
                info?.currentPoint >= barConfigs?.maxPoint
            ) {
                width = barConfigs?.maxWidth;
            }
            setCurrentWidth(width || 0);
            setActivePointIds(activeIds);
        },
        [barConfigs, getCurrentTierInfo, tierBarList]
    );

    useEffect(() => {
        if (detail?.currentClass) {
            initBar(detail);
        }
    }, [initBar, detail]);

    return (
        <div className={classes.wrapper}>
            <InfoTooltip
                color="black"
                title={t("member:timostarclub_lb_hint_count_people")}
                className={classes.icon}
                icon="QuestionMarkIcon"
            >
                <LspTypography variant="heading3" color="white" component="div">
                    {title}
                </LspTypography>
            </InfoTooltip>

            <div className={classes.barWrapper}>
                <div
                    className={classes.progress}
                    style={{ width: currentWidth }}
                >
                    <div
                        className={classes.barBackground}
                        style={{
                            width: barConfigs?.maxWidth || 0,
                        }}
                    />
                    {tierBarList?.map((tier, index) => {
                        return (
                            <Fragment key={tier.id}>
                                <InvitationLevelBarItem
                                    tier={tier}
                                    index={index}
                                    space={barConfigs?.space}
                                    firstChild={index === 0}
                                    lastChild={index === tierBarList.length - 1}
                                    activePointIds={activePointIds}
                                />
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default InvitationLevelBar;
