import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { memo } from "react";
import Content from "./Content";

const useStyles = makeStyles((theme) => ({
    notifyDialogWrapper: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        textAlign: "center",
        borderRadius: theme.shape.radiusLevels[3],
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: theme.spacing(2.5),
        color: theme.palette.white.contrastText,
        "& img": {
            width: "100%",
            maxWidth: 61,
            marginBottom: theme.spacing(2.5),
        },
    },
    content: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    actions: {
        paddingTop: theme.spacing(2),
    },
}));

/**
 * @param {Object} props
 * @param {string} [props.className]
 * @param {("xs"|"sm"|"lg"|"xl"|"md")} [props.size]
 */
const LspNotifyDialogComponent = ({ children, className, size }) => {
    const classes = useStyles();
    return (
        <Content size={size}>
            <div className={`${classes.notifyDialogWrapper} ${className}`}>
                {children}
            </div>
        </Content>
    );
};

LspNotifyDialogComponent.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "lg", "xl", "md"]),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

LspNotifyDialogComponent.defaultProps = {
    size: "sm",
    className: "",
};

export const LspNotifyDialog = memo(LspNotifyDialogComponent);

/**
 * @param {Object} props
 * @param {string} [props.className]
 */
const LspNotifyDialogTitleComponent = ({ children, className, ...props }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.title} ${className}`} {...props}>
            {children}
        </div>
    );
};

LspNotifyDialogTitleComponent.propTypes = {
    className: PropTypes.string,
};

LspNotifyDialogTitleComponent.defaultProps = {
    className: "",
};

export const LspNotifyDialogTitle = memo(LspNotifyDialogTitleComponent);

/**
 * @param {Object} props
 * @param {string} [props.className]
 */
const LspNotifyDialogContentComponent = ({ children, className, ...props }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.content} ${className}`} {...props}>
            {children}
        </div>
    );
};

LspNotifyDialogContentComponent.propTypes = {
    className: PropTypes.string,
};

LspNotifyDialogContentComponent.defaultProps = {
    className: "",
};

export const LspNotifyDialogContent = memo(LspNotifyDialogContentComponent);

/**
 * @param {Object} props
 * @param {string} [props.className]
 */
const LspNotifyDialogActionsComponent = ({ children, className, ...props }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.actions} ${className}`} {...props}>
            {children}
        </div>
    );
};

LspNotifyDialogActionsComponent.propTypes = {
    className: PropTypes.string,
};

LspNotifyDialogActionsComponent.defaultProps = {
    className: "",
};

export const LspNotifyDialogActions = memo(LspNotifyDialogActionsComponent);
