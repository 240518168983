import LspButton from "@components/LspButton";
import useSharedClasses from "@helpers/styleContentModal";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const ActivationFormLoading = () => {
    const classes = useSharedClasses();
    const { t } = useTranslation();

    return (
        <div>
            <div className={classes.desc}>
                <Skeleton />
            </div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <br />
            <LspButton
                type="submit"
                className={classes.submitButton}
                fullWidth
                disabled
            >
                {t("new_join_btn_submit_my_info")}
            </LspButton>
        </div>
    );
};

export default memo(ActivationFormLoading);
