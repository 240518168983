import { useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import LspButton from "@components/LspButton";
import PaymentRequestActions from "@redux/actions/payment-request";
import PaymentRequestList from "@containers/PaymentRequest/PaymentList/PaymentRequestList";
import PaymentCreate from "../PaymentCreate";
import { PAYMENT_REQUEST_ITEM_TYPES } from "../paymentRequest.constant";

const useStyles = makeStyles((theme) => {
    return {
        cover: {
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(2),
            boxShadow: theme.shadows[2],
            overflow: "hidden",
        },
    };
});
const PaymentList = ({
    serverTime,
    paymentStatus,
    resetStatePaymentRequest,
    getPaymentList,
    outStandingList,
    completeList,
    summaryBox,
    getPaymentCompleteList,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const firstLoadOutStandingList = useRef(false);
    const firstLoadCompleteList = useRef(false);

    const getOutStandingList = useCallback(() => {
        firstLoadOutStandingList.current = true;
        const outstandingPayload = {
            type: PAYMENT_REQUEST_ITEM_TYPES.OPEN,
            lastId: outStandingList?.lastId,
            modifyAt: outStandingList?.modifyAt,
        };
        getPaymentList(outstandingPayload);
    }, [getPaymentList, outStandingList]);

    const getCompleteList = useCallback(() => {
        firstLoadCompleteList.current = true;
        const completePayload = {
            type: PAYMENT_REQUEST_ITEM_TYPES.COMPLETE,
            lastId: completeList?.lastId,
            modifyAt: completeList?.modifyAt,
        };
        getPaymentCompleteList(completePayload);
    }, [getPaymentCompleteList, completeList]);

    useEffect(() => {
        if (serverTime && !firstLoadOutStandingList.current) {
            getOutStandingList();
        }
    }, [serverTime, getOutStandingList, firstLoadOutStandingList]);

    useEffect(() => {
        if (serverTime && !firstLoadCompleteList.current) {
            getCompleteList();
        }
    }, [serverTime, getCompleteList, firstLoadCompleteList]);

    useEffect(() => {
        return () => {
            resetStatePaymentRequest();
        };
    }, [resetStatePaymentRequest]);

    useEffect(() => {
        if (paymentStatus) {
            GlobalDialogController.hide();
            getPaymentList("moveMoneySuccess");
        }
    }, [paymentStatus, getPaymentList]);

    const openPaymentCreate = () => {
        GlobalDialogController.show({
            component: () => <PaymentCreate getPaymentList={getPaymentList} />,
        });
    };

    return (
        <>
            <Content>
                {summaryBox && (
                    <AccountSummaryBox
                        leftItemName={t("gb_lb_available_to_spend")}
                        rightItemName={t("payment_lb_total_to_receive")}
                        totalToPay={t("payment_lb_total_to_pay")}
                        leftItemValue={formatNumber(summaryBox?.spendAmount)}
                        rightItemValue={
                            formatNumber(summaryBox?.totalReceived) || 0
                        }
                        totalToPayValue={
                            formatNumber(summaryBox?.totalSent) || 0
                        }
                    />
                )}

                <Box mt={2} textAlign="right">
                    <LspButton
                        variant="secondary"
                        onClick={() => openPaymentCreate()}
                        fullWidth={!isSmUp}
                        buttonProps={{
                            datatestid: "newpaymentrequest",
                        }}
                    >
                        {t("payment_request_initialization_header")}
                    </LspButton>
                </Box>
                <Box datatestid="outstanding-list" className={classes.cover}>
                    <PaymentRequestList
                        headerText={t("payment_lb_outstanding")}
                        list={outStandingList?.list}
                        loading={outStandingList?.loading}
                        loadMoreFunc={getOutStandingList}
                        hasLoadMore={outStandingList?.hasLoadMore}
                        emptyInfo={{
                            status:
                                outStandingList?.list?.length <= 0 &&
                                firstLoadOutStandingList.current,
                            desc: t("payment_no_outstanding_list"),
                        }}
                    />
                </Box>
                <Box datatestid="completed-list" className={classes.cover}>
                    <PaymentRequestList
                        headerText={t("payment_lb_complete")}
                        list={completeList?.list}
                        loading={completeList?.loading}
                        loadMoreFunc={getCompleteList}
                        hasLoadMore={completeList?.hasLoadMore}
                        emptyInfo={{
                            status:
                                completeList?.list?.length <= 0 &&
                                firstLoadCompleteList.current,
                            desc: t("payment_no_complete_list"),
                            subDesc: t("payment_no_complete_list_desc"),
                        }}
                    />
                </Box>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime.value,
    paymentStatus: state.paymentRequest.status,
    paymentRefno: state.paymentRequest.refNo,
    outStandingList: state.paymentRequest.outStandingList,
    completeList: state.paymentRequest.completeList,
    summaryBox: state.paymentRequest.summaryBox,
});

const mapDispatch = (dispatch) => ({
    resetStatePaymentRequest: () =>
        dispatch(PaymentRequestActions.resetState()),
    getPaymentList: (payload) =>
        dispatch(PaymentRequestActions.getPaymentRequestList(payload)),
    getPaymentCompleteList: (payload) =>
        dispatch(PaymentRequestActions.getPaymentRequestCompleteList(payload)),
});

export default connect(mapState, mapDispatch)(PaymentList);
