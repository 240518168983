import produce from "immer";
import { FetchStatus } from "@config/constants";
import { TopUpActionType } from "@redux/actions/top-up";

/* ------------- Initial State ------------- */
const initialState = {
    submitting: false,
    refNo: "",
    contacts: [],
    phoneConfigs: [],
    contactsFetching: false,
    phoneConfigsFetching: false,
    phoneNumber: "",
    selectedPhoneObject: {},
    payeeName: "",
    hasSavePayee: false,
    amount: "",
    info: {},
    status: "",
};

/* ------------- Reducer ------------- */
const topUpReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TopUpActionType.GetContactList:
                draft.contactsFetching = true;
                break;
            case TopUpActionType.GetContactListSuccess:
                draft.contactsFetching = false;
                draft.contacts = action.payload;
                break;
            case TopUpActionType.GetContactListError:
                draft.contactsFetching = false;
                break;
            case TopUpActionType.GetPhoneConfigs:
                draft.phoneConfigsFetching = true;
                break;
            case TopUpActionType.GetPhoneConfigsSuccess:
            case TopUpActionType.GetPhoneConfigsError:
                draft.phoneConfigsFetching = false;
                draft.phoneConfigs = action.payload;
                break;
            case TopUpActionType.UpdatePhoneNumber:
                draft.phoneNumber = action.payload;
                break;
            case TopUpActionType.UpdateAmount:
                draft.amount = action.payload;
                break;
            case TopUpActionType.UpdatePayeeName:
                draft.payeeName = action.payload;
                break;
            case TopUpActionType.UpdateHasSavePayee:
                draft.hasSavePayee = action.payload;
                break;
            case TopUpActionType.UpdateSelectedPhoneObject:
                draft.selectedPhoneObject = action.payload;
                break;
            case TopUpActionType.TopUpRequest:
                draft.status = FetchStatus.Pending;
                draft.submitting = true;
                break;
            case TopUpActionType.TopUpWithOTP:
                draft.submitting = false;
                const { refNo } = action.payload;
                draft.refNo = refNo;
                break;
            case TopUpActionType.TopUpSuccess:
                draft.submitting = false;
                draft.refNo = null;
                draft.info = { ...action.payload };
                draft.status = FetchStatus.Success;
                break;
            case TopUpActionType.TopUpError:
                draft.status = FetchStatus.Error;
                draft.submitting = false;
                break;
            case TopUpActionType.OTPCommit:
                draft.submitting = true;
                break;
            case TopUpActionType.ResetAddNewPhoneInfo:
                draft.payeeName = initialState.payeeName;
                draft.hasSavePayee = initialState.hasSavePayee;
                break;
            case TopUpActionType.ResetExistingPhoneScreen:
                draft.selectedPhoneObject = initialState.selectedPhoneObject;
                break;
            case TopUpActionType.OTPCancel:
                draft.submitting = initialState.submitting;
                draft.refNo = initialState.refNo;
                draft.status = FetchStatus.Error;
                break;
            /* ------------- Reset to initial state ------------- */
            case TopUpActionType.Reset: {
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            }
            default:
                break;
        }
    });

export default topUpReducer;
