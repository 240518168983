/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, Grid, makeStyles } from "@material-ui/core";
import LspButton from "@components/LspButton";
import RequestDocumentActions from "@redux/actions/request-document";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { REQUEST_DOC_STEPS } from "./constant";

const useStyles = makeStyles((theme) => ({
    item: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0.75, 0, 1.5, 0),
    },
    nextBtn: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > button": {
            width: "50%",
            margin: "auto",
        },
    },
}));

const ChooseAccountReview = ({
    totalAmount,
    dataInit,
    dataSelected,
    setDocumentInfo,
    onNext,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const classes = useStyles();

    const list = useMemo(() => {
        const dataReviewInit = cloneDeep(dataInit);
        const listIdSelectList = dataSelected.map((item) => item.FEID);
        const dataShow = dataReviewInit.filter((item) =>
            listIdSelectList.includes(item.FEID)
        );
        const idTermDeposit = dataReviewInit.findIndex(
            (item) => item.childAccounts
        );
        const idTermDepositSelected = dataSelected.findIndex(
            (item) => item.childAccounts
        );
        const data = dataReviewInit[
            idTermDeposit
        ]?.childAccounts.filter((item) => listIdSelectList.includes(item.FEID));
        dataReviewInit[idTermDeposit].childAccounts = data;
        if (data.length > 0) {
            if (idTermDepositSelected < 0) {
                dataShow.push(dataReviewInit[idTermDeposit]);
            }
        }

        return dataShow;
    }, [dataSelected, dataInit]);

    const submitRequest = () => {
        setDocumentInfo({ accountList: list, totalAmount });
        onNext(REQUEST_DOC_STEPS.CONFIRM_DOCUMENT);
    };

    return (
        <Content size="sm">
            {list.map((item, key) => (
                <div key={key} className={classes.item}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <LspTypography variant="subheading2" color="black">
                                {item.name}
                            </LspTypography>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                textAlign: "right",
                            }}
                        >
                            {!item.childAccounts && (
                                <LspTypography
                                    variant="subheading2"
                                    color="black"
                                >
                                    {formatNumber(item.balance)}
                                </LspTypography>
                            )}
                        </Grid>
                    </Grid>
                    {item.childAccounts && (
                        <Box pl={4}>
                            {item.childAccounts.map((elm, k) => (
                                <Grid container spacing={2} key={k}>
                                    <Grid item xs={8}>
                                        <LspTypography
                                            variant="body1"
                                            color="grey"
                                        >
                                            {elm.name || elm.number}
                                        </LspTypography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            textAlign: "right",
                                        }}
                                    >
                                        <LspTypography
                                            variant="subheading2"
                                            color="black"
                                        >
                                            {formatNumber(elm.balance)}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                    )}
                </div>
            ))}
            <Box textAlign="right" mt={4}>
                <LspTypography variant="body1" color="black">
                    {t("gs_lb_balance_piggy_bank")}
                    <LspTypography
                        variant="subheading1"
                        color="black"
                        component="span"
                    >
                        {`: ${formatNumber(totalAmount)}`}
                    </LspTypography>
                </LspTypography>
            </Box>
            <LspButton onClick={submitRequest} className={classes.nextBtn}>
                {t("lb_next")}
            </LspButton>
        </Content>
    );
};

const mapState = (state) => ({
    documentInfo: state.requestDocument,
    serverTime: state.user.serverTime,
});

const mapDispatch = (dispatch) => ({
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
});

export default memo(connect(mapState, mapDispatch)(ChooseAccountReview));
