import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles } from "@material-ui/core";
import LspTextField from "@components/LspTextField";
import LspButton from "@components/LspButton";
import userService from "@services/user";
import LspCongratulation from "@components/LspCongratulation";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    formLine: {
        marginTop: theme.spacing(3),
        display: "flex",
    },
    inputIcon: {
        margin: theme.spacing(2, 2, 1, 1),
        color: theme.palette.primary.main,
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
        },
    },
    button: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(6),
        },
    },
}));

const Feedback = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [feedbackContent, setFeedbackContent] = useState("");
    const [loading, setLoading] = useState(false);
    const [sendFeedbackSuccess, setSendFeedbackSuccess] = useState(false);
    const history = useHistory();

    const handleChange = (event) => {
        setFeedbackContent(event.target.value);
    };

    const sendFeedback = async () => {
        setLoading(true);
        const dataToPost = { content: feedbackContent };
        const { data } = await userService.sendUserFeedback(dataToPost);
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setSendFeedbackSuccess(true);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    return (
        <>
            <Banner title={t("feedback_title").toUpperCase()} />
            <ContentWrapper>
                <Content size="sm" boxProps={{ textAlign: "center" }}>
                    {!sendFeedbackSuccess && (
                        <>
                            <LspTypography variant="body2" color="black">
                                {t("feedback_txt_sub_description")}
                            </LspTypography>
                            <div className={classes.formLine}>
                                <Icon
                                    className={`font-icon icon-description ${classes.inputIcon}`}
                                />
                                <LspTextField
                                    name="period"
                                    value={feedbackContent}
                                    label={t(
                                        "feedback_txt_description_placeholder"
                                    )}
                                    inputProps={{
                                        maxLength: 10000,
                                    }}
                                    onChange={handleChange}
                                    multiline
                                    fullWidth
                                />
                            </div>
                            <div>
                                <LspButton
                                    onClick={sendFeedback}
                                    fullWidth
                                    className={classes.button}
                                    disabled={
                                        feedbackContent.trim().split(" ")
                                            .length < 5
                                    }
                                    progressing={loading}
                                >
                                    {t("feedback_lb_btn_send_feedback")}
                                </LspButton>
                            </div>
                        </>
                    )}

                    {sendFeedbackSuccess && (
                        <LspCongratulation
                            icon="like"
                            type="success"
                            title={t("feedback_successful_title")}
                            contents={t("feedback_successful_content")}
                            button={{
                                label: t("lb_done"),
                                onClick: () => history.push("/home"),
                            }}
                        />
                    )}
                </Content>
            </ContentWrapper>
        </>
    );
};

export default Feedback;
