import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { INVITATION_TIERS } from "@containers/Invitation/constant";
import i18n from "@i18n/";
import { LANGUAGE_OPTIONS } from "@config/constants";
import InvitationTier from "./InvitationTier";
import InvitationTierListLoading from "./InvitationTierListLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[1],
    },
    tierListHeader: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            marginBottom: theme.spacing(2),
            textAlign: "left",
        },
    },
    tierList: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
        },
    },
    tierItem: {
        boxShadow: theme.shadows[3],
        width: "100%",
        padding: theme.spacing(3),
        minHeight: 296,
        height: "100%",
        background: theme.palette.background.paper,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: theme.shape.radiusLevels[1],
        textAlign: "center",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginBottom: theme.spacing(3),
            minHeight: 348,
            width: "calc(50% - 12px)",
            "&:nth-child(odd)": {
                marginRight: theme.spacing(1.5),
                marginLeft: 0,
            },
            "&:nth-child(even)": {
                marginRight: 0,
                marginLeft: theme.spacing(1.5),
            },
        },
    },
    titleItem: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
    },
    description: {
        color: theme.palette.neutral.grey1,
        marginBottom: theme.spacing(1),
        display: "block",
        "& b": {
            color: theme.palette.white.contrastText,
        },
    },
}));

const InvitationTierList = ({ list, size = "md", onClose, loading }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { formatNumber } = useNumber();
    const [tierList, setTierList] = useState(null);

    const mapTierList = useCallback(
        (tiers) => {
            if (!tiers) return;

            const sortedList = sortBy(tiers, ["order"]);

            return sortedList.map((item) => {
                const id = item.className
                    ? item.className.replace(/\s/g, "")
                    : "";
                let contentName = "";
                let description = "";
                switch (item.className) {
                    case INVITATION_TIERS.BEGINNER:
                        description = [
                            t("member:timostarclub_lb_min_invitee_beginner"),
                        ];
                        break;
                    case INVITATION_TIERS.RISING_STAR:
                        contentName = t(
                            "member:timostarclub_lb_bar_rising_star"
                        );
                        break;
                    case INVITATION_TIERS.SHINING_STAR:
                        contentName = t(
                            "member:timostarclub_lb_bar_shining_star"
                        );
                        break;
                    case INVITATION_TIERS.SUPER_STAR:
                    default:
                        contentName = t(
                            "member:timostarclub_lb_bar_super_star"
                        );
                        break;
                }

                if (item.className !== INVITATION_TIERS.BEGINNER) {
                    const contentAmount =
                        i18n.language === LANGUAGE_OPTIONS.EN
                            ? `<b>VND ${formatNumber(item.rewardValue)}</b>`
                            : `<b>${formatNumber(item.rewardValue)} VNĐ </b>`;
                    description = [
                        t("member:timostarclub_lb_min_invitee").replace(
                            "%@",
                            item.fromPoint
                        ),
                        t("member:timostarclub_lb_receive_amt").replace(
                            "%@",
                            contentAmount
                        ),
                    ];
                }

                return { ...item, id, contentName, description };
            });
        },
        [t, formatNumber]
    );

    useEffect(() => {
        setTierList(mapTierList(list));
    }, [mapTierList, list]);

    return (
        <>
            {size === "md" && (
                <>
                    {loading && <InvitationTierListLoading classes={classes} />}
                    {!loading && (
                        <>
                            <div className={classes.tierListHeader}>
                                <LspTypography
                                    variant="subheading1"
                                    color="black"
                                >
                                    {t(
                                        "member:timostarclub_title_tier_benefit"
                                    )}
                                </LspTypography>
                            </div>

                            <div className={classes.tierList}>
                                {tierList?.map((tier) => {
                                    return (
                                        <InvitationTier
                                            key={tier.id}
                                            detail={tier}
                                            classes={classes}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </>
            )}
            {/* Mobile view */}
            {size !== "md" && (
                <Content size={size} boxProps={{ className: classes.root }}>
                    <div className={classes.tierListHeader}>
                        <LspTypography variant="subheading1" color="black">
                            {t("member:timostarclub_title_tier_benefit")}
                        </LspTypography>
                    </div>

                    <div className={classes.tierList}>
                        {tierList?.map((tier) => {
                            return (
                                <InvitationTier
                                    key={tier.id}
                                    detail={tier}
                                    classes={classes}
                                />
                            );
                        })}
                    </div>
                    <LspButton variant="secondary" fullWidth onClick={onClose}>
                        {t("lb_close")}
                    </LspButton>
                </Content>
            )}
        </>
    );
};

export default InvitationTierList;
