/* eslint-disable react/no-array-index-key */
import { ButtonBase, makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";

import clsx from "clsx";
import bookingActions from "@redux/actions/booking";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    item: {
        textAlign: "center",
        background: theme.palette.background.grey,
        borderRadius: theme.shape.radiusLevels[0],
        padding: theme.spacing(1, 2),
        cursor: "pointer",
        margin: theme.spacing(0.75),
    },
    active: {
        background: theme.palette.primary.main,
        color: theme.palette.neutral.white,
    },
}));

const LspTimeItem = ({
    id,
    item,
    selectedTime,
    updateSelectedTime,
    selectedDate,
    onNext,
}) => {
    const classes = useStyles();

    const name = useMemo(() => item?.name.split("-")[0], [item]);

    const onSelectHandler = useCallback(
        (time) => {
            updateSelectedTime(time);
            onNext({
                date: selectedDate,
                time,
            });
        },
        [selectedDate, updateSelectedTime, onNext]
    );

    return (
        <ButtonBase
            className={clsx(classes.item, {
                [classes.active]: selectedTime?.id === id,
            })}
            onClick={() => onSelectHandler({ ...item, id })}
            disabled={item?.remainingCapacity === 0}
        >
            {name}
        </ButtonBase>
    );
};

const mapState = (state) => ({
    selectedTime: state.booking.selectedTime,
    selectedDate: state.booking.selectedDate,
});

const mapDispatch = (dispatch) => ({
    updateSelectedTime: (payload) =>
        dispatch(bookingActions.setSelectedTime(payload)),
});

export default memo(connect(mapState, mapDispatch)(LspTimeItem));
