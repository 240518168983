import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    wrapperBox: {
        marginBottom: theme.spacing(2),
        background: theme.palette.background.paper,
        "& .MuiIcon-root": {
            overflow: "initial",
        },
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
        flexWrap: "wrap",
    },
    header: {
        textTransform: "uppercase",
    },
    headerIcon: {
        fontSize: theme.typography.pxToRem(36),
        marginRight: theme.spacing(1),
        color: theme.palette.status.success,
    },
    line: {
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "center",
        "& > div": {
            width: "100%",
            "&:last-child": {
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
                [theme.breakpoints.up("sm")]: {
                    width: "30%",
                },
            },
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "space-between",
            flexDirection: "row",
        },
    },
    expandLine: {
        flexWrap: "wrap",
    },
    divider: {
        width: "100%",
        margin: theme.spacing(2),
    },
    refCode: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.neutral.grey4,
        padding: theme.spacing(0.25, 1, 0.25, 2),
        borderRadius: theme.shape.radiusLevels[0],
    },
    leftIcon: {
        marginRight: theme.spacing(1),
        color: theme.palette.neutral.grey1,
        fontSize: theme.typography.pxToRem(20),
    },
    successMessage: {
        fontStyle: "italic",
    },
    formWrapper: {
        width: "100%",
    },
    formDetail: {
        marginTop: theme.spacing(2),
    },
}));
