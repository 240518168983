import otpDialogAction from "@redux/actions/otp-dialog";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPController from "./controllers/OTPController";

const useOTP = () => {
    const opened = useSelector((state) => state.otpDialog.opened);
    const dispatch = useDispatch();

    const setLoading = useCallback(
        /**
         * Set OTP dialog loading state
         * @param {boolean} loading
         */
        (loading) => dispatch(otpDialogAction.setLoading(loading)),
        [dispatch]
    );

    const openOTPDialog = useCallback(
        /**
         * Open OTP dialog
         * @param {object} options
         * @param {string} options.type - OTPDialogType
         * @param {string} options.refNo
         * @param {({refNo:string, otp:string}) => any} options.submitFunc
         * @param {({refNo:string}) => any} options.resendFunc
         * @param {string} [options.dialogTitle]
         * @param {string} [options.dialogSubtitle]
         * @param {string} [options.dialogDescription]
         * @param {string} [options.dialogHideResendButton]
         * @param {string} [options.dialogOnCancel]
         */
        (options) => {
            dispatch(otpDialogAction.open(options));
            OTPController.opening = true;
        },
        [dispatch]
    );

    const closeOTPDialog = useCallback(
        /**
         * Close the OTP dialog
         */
        () => {
            OTPController.opening = false;
            dispatch(otpDialogAction.close());
        },
        [dispatch]
    );

    return {
        opened,
        setLoading,
        openOTPDialog,
        closeOTPDialog,
    };
};

export default useOTP;
