/* eslint-disable react/no-array-index-key */
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    item: {
        background: theme.palette.background.default,
        boxShadow: theme.shadows[2],
        border: "none",
        borderRadius: theme.shape.radiusLevels[0],
        padding: 0,
        width: "100%",
        height: "202px",
        marginRight: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    wrapper: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        borderRadius: 4,
        overflow: "hidden",
    },
}));

const GoalSaveItemLoading = ({ className }) => {
    const classes = useStyles();

    return (
        <Box className={className.listGoal}>
            {[...new Array(3)].map((item, index) => {
                return (
                    <Box className={classes.wrapper} key={index}>
                        <div className={classes.item}>
                            <Skeleton width="80%" height="70%" />
                            <Skeleton width="50%" />
                        </div>
                    </Box>
                );
            })}
        </Box>
    );
};

export default memo(GoalSaveItemLoading);
