/* eslint-disable react/no-array-index-key */
import { Box } from "@material-ui/core";
import { memo, Fragment } from "react";

import LspHintInfo from "@components/LspHintInfo";
import { useTranslation } from "react-i18next";

const ChildDependentHint = ({ pages, currentScreen }) => {
    const { t } = useTranslation();

    return (
        <Box paddingTop={2} paddingBottom={1}>
            {pages?.map((value, index) => {
                return (
                    <Fragment key={`hint-${index}`}>
                        {currentScreen === index && (
                            <>
                                {value?.indexOf("child") > -1 && (
                                    <LspHintInfo
                                        icon="hint"
                                        color="active"
                                        content={t(
                                            "wealth_travel_desc_for_policy_dependent"
                                        )}
                                    />
                                )}
                            </>
                        )}
                    </Fragment>
                );
            })}
        </Box>
    );
};

export default memo(ChildDependentHint);
