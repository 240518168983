import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BANK_INFO } from "@config/constants";
import { Box, makeStyles } from "@material-ui/core";

import LspAvatar from "@components/LspAvatar";
import DoubleButtons from "@components/DoubleButtons";

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            color: theme.palette.primary.main,
        },

        duplicateHeader: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            paddingBottom: theme.spacing(4),
            background: theme.palette.primary.main,
            color: theme.palette.neutral.white,
            "& > div": {
                width: "70%",
                margin: "0 auto",
                [theme.breakpoints.down("sm")]: {
                    width: "90%",
                },
            },
        },
        duplicateTxt: {
            width: "90%",
            margin: "0 auto",
            marginTop: theme.spacing(5),
            color: "rgba(0, 0, 0, 0.54)",
            [theme.breakpoints.down("xs")]: {
                width: "90%",
            },
        },

        duplicateFooter: {
            width: "90%",
            margin: "auto",
            padding: theme.spacing(4, 0),
            [theme.breakpoints.down("xs")]: {
                width: "90%",
            },
        },
    };
});
const ViewPayeeExist = ({
    resetState,
    getListPayee,
    duplicateNameData,
    closeNewPayee,
    typeDuplicateName,
    addPayeeInfo,
    destinationInfo,
    closeDuplicateName,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const body = useMemo(() => {
        if (typeDuplicateName === "code_payee_exist_not_have_destination") {
            return t("payee_content_exist_not_have_destination");
        }
        return t("payee_content_destination_added_in_list");
    }, [typeDuplicateName, t]);

    // This destination already belongs to a Payee!
    // 04-DupDe&DupName-Payment Request
    const btnTypeDupAll = useMemo(() => {
        if (typeDuplicateName === "code_payee_exist_not_have_destination") {
            return true;
        }
        return false;
    }, [typeDuplicateName]);

    const onCancelDuplicateName = () => {
        resetState();
    };

    const onContinueDuplicateName = () => {
        closeNewPayee();
        resetState();
        getListPayee(duplicateNameData.payeeId);
    };

    const onChangeName = () => {
        closeDuplicateName();
    };

    const onAddDestination = () => {
        const infoPayeeAccount = {
            accounts: [
                {
                    payeeId: duplicateNameData.payeeId,
                    description: "",
                    bankId: BANK_INFO.DEFAULT_BANK_1_ID.toString(),
                    provinceId: "",
                    branchId: "",
                    branchName: "",
                    accountName: "",
                    accountNumber: destinationInfo.data.bank.accountNumber,
                    nickName: duplicateNameData.nickName,
                },
            ],
            cards: [
                {
                    payeeId: duplicateNameData.payeeId,
                    description: "",
                    nameOnCard: "",
                    cardNumber: "",
                    nickName: duplicateNameData.nickName,
                },
            ],
        };

        addPayeeInfo(infoPayeeAccount, "bankaccount");
        resetState();
    };

    return (
        <Box p={0}>
            <Box p={4} pb={2} className={classes.duplicateHeader}>
                <div>{t("payee_content_exist_not_have_destination")}</div>
                <Box
                    pt={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box mr={1}>
                        <LspAvatar avatar={duplicateNameData.avatar} />
                    </Box>
                    <Box>{duplicateNameData.nickName}</Box>
                </Box>
            </Box>
            <Box className={classes.duplicateTxt}>{body}</Box>
            <Box className={classes.duplicateFooter}>
                {!btnTypeDupAll && (
                    <DoubleButtons
                        primaryButton={{
                            label: t("lb_continue"),
                            onClick: onContinueDuplicateName,
                        }}
                        secondaryButton={{
                            label: t("lb_cancel"),
                            onClick: onCancelDuplicateName,
                        }}
                    />
                )}
                {btnTypeDupAll && (
                    <DoubleButtons
                        primaryButton={{
                            label: t("payee_btn_add_destination"),
                            onClick: onAddDestination,
                        }}
                        secondaryButton={{
                            label: t("payee_txt_change_name"),
                            onClick: onChangeName,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ViewPayeeExist;
