import { useCallback, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import LspButton from "@components/LspButton";
import {
    LANGUAGE_OPTIONS,
    REGEX_PATTERN,
    RESPONSE_CODE,
} from "@config/constants";
import LspTextField from "@components/LspTextField";
import ColorBar from "@components/ColorBar";
import clsx from "clsx";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ContentModal from "@components/ContentModal";
import querystring from "query-string";
import forgotPassordService from "@services/forgot-password";
import JSEncrypt from "jsencrypt";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import ForgotPasswordActions from "@redux/actions/forgor-password";
import Interweave from "interweave";
import useSharedClasses from "@helpers/styleContentModal";
import useLanguage from "@helpers/useLanguage";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAPK);

const ForgotPassword = ({ sendInforOTP, verityOTPSuccess }) => {
    const location = useLocation();
    const { switchLanguage } = useLanguage();

    const classes = useSharedClasses();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [msgAfterSubmitted, setMsgAfterSubmitted] = useState(() => {
        const msg = sessionStorage.getItem("resultMessage");
        return msg || "";
    });
    const [statusAfterSubmitted, setStatusAfterSubmitted] = useState(() => {
        const status = sessionStorage.getItem("resultStatus");
        return status || "";
    });
    const [showForm, setShowForm] = useState(false);
    const isShowTitle =
        msgAfterSubmitted === "" ||
        (msgAfterSubmitted !== "" && statusAfterSubmitted !== "success");
    const isShowTitleSuccess =
        statusAfterSubmitted !== "" && statusAfterSubmitted === "success";
    const getTokenFromURL = useCallback(() => {
        const params = querystring.parse(location.search);
        return params.sk;
    }, [location.search]);

    const currentLanguage = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? LANGUAGE_OPTIONS.EN.toUpperCase()
            : LANGUAGE_OPTIONS.VN.toUpperCase();
    }, [i18n.language]);

    const updateMessage = (message) => {
        sessionStorage.setItem("resultMessage", message);
        setMsgAfterSubmitted(message);
    };
    const updateStatus = (status) => {
        sessionStorage.setItem("resultStatus", status);
        setStatusAfterSubmitted(status);
    };

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const languageContent = useMemo(() => {
        const languages = Object.keys(LANGUAGE_OPTIONS).filter(
            (item) => item !== LANGUAGE_OPTIONS.VI.toUpperCase()
        );
        return languages
            .sort((a, b) => b.localeCompare(a))
            .map((item, index) => (
                <span key={Math.random()}>
                    <span
                        className={`${
                            currentLanguage === item ? "active" : ""
                        }`}
                        onClick={
                            currentLanguage !== item ? changeLanguage : null
                        }
                    >
                        {item}
                    </span>
                    {index !== languages?.length - 1 && <span>|</span>}
                </span>
            ));
    }, [currentLanguage, changeLanguage]);

    useEffect(() => {
        if (verityOTPSuccess) {
            updateStatus("success");
            setShowForm(false);
            updateMessage("fotgotpassword_msg_submit_success");
        }
    }, [verityOTPSuccess, t]);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("resultMessage");
            sessionStorage.removeItem("resultStatus");
        };
    }, []);

    useEffect(() => {
        const storedStatus = sessionStorage.getItem("resultStatus");
        if (storedStatus === "success" || storedStatus === "error") return;

        const checkTokenValid = async () => {
            const token = getTokenFromURL();
            if (!token) {
                history.push("/login");
                return;
            }
            const { data } = await forgotPassordService.initToken(token);
            switch (data.code) {
                case RESPONSE_CODE.BAD_REQUEST: // token invalid
                    updateStatus("error");
                    updateMessage("fotgotpassword_msg_token_error");
                    setShowForm(false);
                    break;
                case 9000:
                    setShowForm(true);
                    break;
                case RESPONSE_CODE.TOKEN_EXPIRED:
                    updateStatus("error");
                    updateMessage("reset_password_msg_reset_link_expired");
                    setShowForm(false);
                    break;
                case RESPONSE_CODE.TOKEN_INVALID:
                    updateStatus("error");
                    updateMessage("fotgotpassword_msg_token_error");
                    setShowForm(false);
                    break;
                default:
                    GlobalDialogController.showError({
                        errorCode: data.code,
                    });
                    break;
            }
        };
        checkTokenValid();
    }, [getTokenFromURL, t, history]);

    const goToLoginPage = useCallback(() => {
        history.push("/login");
    }, [history]);

    const defaultValues = {
        newPassword: "",
        reTypePassword: "",
    };

    const { register, handleSubmit, errors, formState, getValues } = useForm({
        defaultValues,
    });

    const passwordValidation = () => {
        const newPassword = getValues("newPassword");
        const confirmNewPassword = getValues("reTypePassword");

        if (
            !!confirmNewPassword &&
            !!newPassword &&
            newPassword !== confirmNewPassword
        ) {
            return t("user_profile_new_password_un_match");
        }
    };

    const submitNewPwd = async (value) => {
        const token = getTokenFromURL();
        const encryptedNewPassword = crypt.encrypt(value.newPassword);
        const { data } = await forgotPassordService.setNewPassword(
            encryptedNewPassword,
            token
        );
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                updateStatus("success");
                setShowForm(false);
                updateMessage("fotgotpassword_msg_submit_success");
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                sendInforOTP(data);
                OTPController.show(OTPDialogType.ForgotPassword);
                break;
            case 9000:
            case 8100:
                updateStatus("error");
                setShowForm(false);
                updateMessage(data.message);
                break;
            case RESPONSE_CODE.TOKEN_EXPIRED: // token expired (9101)
                updateStatus("error");
                setShowForm(false);
                updateMessage("reset_password_msg_reset_link_expired");
                break;
            case RESPONSE_CODE.TOKEN_INVALID: // token invalid (9103)
                setShowForm(false);
                updateStatus("error");
                updateMessage("fotgotpassword_msg_token_error");
                break;
            case RESPONSE_CODE.PASSWORD_POLICIES_VIOLATED: // PASSWORD_POLICIES_VIOLATED 2004
                AlertController.show(t("password_rule_msg"), AlertType.Error);
                break;
            default:
                updateStatus("");
                updateMessage("");
                setShowForm(true);
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    return (
        <ContentModal>
            <form
                className={classes.modalForm}
                onSubmit={handleSubmit(submitNewPwd)}
            >
                <div className={classes.language}>{languageContent}</div>

                <div className={classes.modalFormContent}>
                    <div className={`${classes.title} ${classes.bottomSpace}`}>
                        {isShowTitle && t("login_set_new_password")}
                        {isShowTitleSuccess &&
                            t(
                                "reset_password_title_password_successfully_changed"
                            )}
                    </div>
                    {showForm && (
                        <>
                            <LspTextField
                                type="password"
                                autoFocus
                                name="newPassword"
                                error={!!errors.newPassword}
                                label={t("login_lb_new_password")}
                                helperText={errors.newPassword?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        pattern: {
                                            value: REGEX_PATTERN.PASSWORD,
                                            message: t(
                                                "join_msg_password_invalid"
                                            ),
                                        },
                                        minLength: {
                                            value: 8,
                                            message: t(
                                                "global_msg_password_error"
                                            ),
                                        },
                                        maxLength: {
                                            value: 32,
                                            message: t(
                                                "global_msg_password_error"
                                            ),
                                        },
                                    }),
                                }}
                            />
                            <LspTextField
                                type="password"
                                autoFocus
                                name="reTypePassword"
                                error={!!errors.reTypePassword}
                                label={t("login_lb_re_enter_new_password")}
                                helperText={
                                    errors.reTypePassword?.message || " "
                                }
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        pattern: {
                                            value: REGEX_PATTERN.PASSWORD,
                                            message: t("ms_lb_email_invalid"),
                                        },
                                        validate: () => passwordValidation(),
                                    }),
                                }}
                            />
                        </>
                    )}

                    {statusAfterSubmitted !== "" && (
                        <div
                            className={clsx(classes.succeedMsg, {
                                [classes.errorMsg]:
                                    statusAfterSubmitted === "error",
                            })}
                        >
                            <span>
                                <Interweave content={t(msgAfterSubmitted)} />
                            </span>
                        </div>
                    )}

                    {showForm && (
                        <>
                            <LspButton
                                type="submit"
                                progressing={formState.isSubmitting}
                                className={classes.submitButton}
                                fullWidth
                            >
                                {t("lb_done")}
                            </LspButton>
                            <div className={classes.extraActions}>
                                <Link
                                    onClick={goToLoginPage}
                                    color="secondary"
                                    className={classes.extraActionItem}
                                >
                                    {t("login_reset_password_back_btn")}
                                </Link>
                            </div>
                        </>
                    )}

                    {!showForm && (
                        <LspButton
                            onClick={goToLoginPage}
                            type="submit"
                            className={classes.submitButton}
                            fullWidth
                        >
                            {t("login_reset_password_back_btn")}
                        </LspButton>
                    )}
                </div>
                <ColorBar className={classes.colorBar} />
            </form>
        </ContentModal>
    );
};

const mapStateToProps = (state) => ({
    verityOTPSuccess: state.forgotPassword.status,
});
const mapDispatchToProps = (dispatch) => ({
    sendInforOTP: (payload) =>
        dispatch(ForgotPasswordActions.otpRequired(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
