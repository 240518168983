import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo, useMemo } from "react";
import { connect } from "react-redux";
import BillMemberItem from "./billMemberItem";

const useStyles = makeStyles((theme) => ({
    billMember: {
        margin: `${theme.spacing(1)}px 0`,
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    billMemberItem: {
        margin: theme.spacing(1.5),
        marginBottom: 0,
        borderBottom: "2px solid rgba(239, 240, 232, 0.5)",
        "&:last-child": {
            border: 0,
        },
    },
    skeletonLoading: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(1.5),
    },
    skeletonLoadingLeft: {
        width: 40,
        marginRight: theme.spacing(2),
    },
    skeletonLoadingRight: {
        flex: 1,
    },
}));
const BillMember = ({ billMembers, fetching }) => {
    const classes = useStyles();

    const fetchingLayer = useMemo(() => {
        return (
            <>
                <div className={classes.billMemberItem}>
                    <div className={classes.skeletonLoading}>
                        <div className={classes.skeletonLoadingLeft}>
                            <Skeleton
                                animation="wave"
                                variant="circle"
                                width={40}
                                height={40}
                            />
                        </div>
                        <div className={classes.skeletonLoadingRight}>
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.billMemberItem}>
                    <div className={classes.skeletonLoading}>
                        <div className={classes.skeletonLoadingLeft}>
                            <Skeleton
                                animation="wave"
                                variant="circle"
                                width={40}
                                height={40}
                            />
                        </div>
                        <div className={classes.skeletonLoadingRight}>
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.billMemberItem}>
                    <div className={classes.skeletonLoading}>
                        <div className={classes.skeletonLoadingLeft}>
                            <Skeleton
                                animation="wave"
                                variant="circle"
                                width={40}
                                height={40}
                            />
                        </div>
                        <div className={classes.skeletonLoadingRight}>
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.billMemberItem}>
                    <div className={classes.skeletonLoading}>
                        <div className={classes.skeletonLoadingLeft}>
                            <Skeleton
                                animation="wave"
                                variant="circle"
                                width={40}
                                height={40}
                            />
                        </div>
                        <div className={classes.skeletonLoadingRight}>
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.billMemberItem}>
                    <div className={classes.skeletonLoading}>
                        <div className={classes.skeletonLoadingLeft}>
                            <Skeleton
                                animation="wave"
                                variant="circle"
                                width={40}
                                height={40}
                            />
                        </div>
                        <div className={classes.skeletonLoadingRight}>
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }, [classes]);

    return (
        <>
            <div className={classes.billMember}>
                {fetching && fetchingLayer}
                {!fetching &&
                    (billMembers || []).map((member) => (
                        <div className={classes.billMemberItem} key={member.id}>
                            <BillMemberItem memberItem={member} />
                        </div>
                    ))}
            </div>
        </>
    );
};

const stateProps = (state) => ({
    billMembers: state.bill.splitBill?.bill?.splitBillComponents,
    fetching: state.bill.splitBill.fetching,
});

export default memo(connect(stateProps)(BillMember));
