import { Icon } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import PageHeader from "@components/PageHeader";

import topUpAction from "@redux/actions/top-up";
import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import PhoneNumberInput from "./PhoneNumberInput";
import HasSavePayeeInput from "./HasSavePayeeInput";
import AmountInput from "./AmountInput";

const AddNewPhoneScreen = ({
    nextHandler,
    parentClasses,
    backPrevious,
    phoneNumber,
    hasSavePayee,
    payeeName,
    amount,
    contacts,
    phoneConfigs,
    updatePhoneNumber,
    resetExistingPhoneScreen,
    updateHasSavePayee,
    updatePayeeName,
}) => {
    const { t } = useTranslation();

    const initErrors = useMemo(
        () => ({
            phoneNumber: null,
            amount: null,
            payeeName: null,
        }),
        []
    );
    const [errors, setErrors] = useState(initErrors);
    // const [isDirty, setIsDirty] = useState(false);

    const clearErrors = useCallback(
        (field) => {
            setErrors((prev) => {
                return {
                    ...prev,
                    [field]: initErrors.phoneNumber,
                };
            });
        },
        [initErrors]
    );

    useEffect(() => {
        resetExistingPhoneScreen();
    }, [resetExistingPhoneScreen]);

    const phoneValidation = useCallback(
        (phone, isCheckInList) => {
            // isCheckInList using when click next btn. on change new phone no need isCheckInList
            if (!phone) {
                setErrors((prev) => ({
                    ...prev,
                    phoneNumber: t("msg_we_need_this"),
                }));

                return false;
            }
            if (phone.length <= 9) {
                setErrors((prev) => ({
                    ...prev,
                    phoneNumber: t("topup_msg_invalid_mobile"),
                }));
                return false;
            }

            if (isCheckInList) {
                // Check valid phone number with prefix list from BE
                let hasInList = false;
                for (let i = 0; i < phoneConfigs.length; i++) {
                    const prefixList = phoneConfigs[i].prefix;
                    const phonePrefix = phone.substr(0, 3);

                    const isExisting = prefixList.includes(phonePrefix);
                    if (isExisting) {
                        hasInList = true;
                        break;
                    }
                }

                if (!hasInList) {
                    setErrors((prev) => ({
                        ...prev,
                        phoneNumber: t("topup_msg_invalid_mobile"),
                    }));
                    return false;
                }
            }

            setErrors((prev) => ({
                ...prev,
                phoneNumber: initErrors.phoneNumber,
            }));
            return true;
        },
        [phoneConfigs, t, initErrors]
    );

    const checkExistingPayeeName = (name) => {
        const existingName = contacts.filter(
            (contact) => contact.fullName === name
        );
        return existingName.length > 0;
    };

    const payeeValidation = (name) => {
        if (hasSavePayee && !name) {
            setErrors((prev) => ({
                ...prev,
                payeeName: t("msg_we_need_this"),
            }));
            return false;
        }

        if (hasSavePayee && checkExistingPayeeName(name)) {
            setErrors((prev) => ({
                ...prev,
                payeeName: t("topup_msg_existing_recipient_name"),
            }));
            return false;
        }

        setErrors((prev) => ({
            ...prev,
            payeeName: "",
        }));
        return true;
    };

    const nextStep = () => {
        let isValid = true;

        if (!phoneValidation(phoneNumber, true)) {
            isValid = false;
        }

        if (!amount) {
            setErrors((prev) => ({ ...prev, amount: t("msg_we_need_this") }));
            isValid = false;
        }

        if (!payeeValidation(payeeName)) {
            isValid = false;
        }

        if (isValid) {
            nextHandler();
        }
    };

    const onChangeSavePayee = (value) => {
        clearErrors("payeeName");
        const isChecked = value === "true";
        if (!isChecked) {
            updatePayeeName("");
        }
        updateHasSavePayee(isChecked);
    };

    const onChangePayeeName = (value) => {
        clearErrors("payeeName");
        updatePayeeName(value);
    };

    const onChangePhoneNumber = (value) => {
        clearErrors("phoneNumber");
        updatePhoneNumber(value);
    };

    return (
        <>
            <PageHeader>
                <div className={parentClasses.headerWrapper}>
                    <div>{t("topup_lb_new_mobile_number")} </div>
                    <div className={parentClasses.iconWrapper}>
                        <Icon
                            className={`font-icon icon-phone2 ${parentClasses.headerIcon}`}
                        />
                    </div>
                </div>
            </PageHeader>
            <Content size="sm">
                <div className={parentClasses.bodyWrapper}>
                    <PhoneNumberInput
                        updatePhoneNumber={onChangePhoneNumber}
                        error={errors.phoneNumber}
                        phoneNumber={phoneNumber}
                    />
                    <AmountInput />
                    <HasSavePayeeInput
                        error={errors.payeeName}
                        payeeName={payeeName}
                        hasSavePayee={hasSavePayee}
                        updatePayeeName={onChangePayeeName}
                        updateHasSavePayee={onChangeSavePayee}
                    />
                </div>

                <DoubleButtons
                    primaryButton={{
                        onClick: nextStep,
                        label: t("lb_next"),
                    }}
                    secondaryButton={{
                        onClick: backPrevious,
                        label: t("lb_back"),
                    }}
                />
            </Content>
        </>
    );
};

const mapState = (state) => ({
    hasSavePayee: state.topUp.hasSavePayee,
    payeeName: state.topUp.payeeName,
    phoneNumber: state.topUp.phoneNumber,
    amount: state.topUp.amount,
    contacts: state.topUp.contacts,
    phoneConfigs: state.topUp.phoneConfigs,
});

const dispatchProps = (dispatch) => ({
    updatePhoneNumber: (payload) =>
        dispatch(topUpAction.updatePhoneNumber(payload)),
    resetExistingPhoneScreen: () =>
        dispatch(topUpAction.resetExistingPhoneScreen()),
    updatePayeeName: (payload) =>
        dispatch(topUpAction.updatePayeeName(payload)),
    updateHasSavePayee: (payload) =>
        dispatch(topUpAction.updateHasSavePayee(payload)),
});

export default connect(mapState, dispatchProps)(AddNewPhoneScreen);
