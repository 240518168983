import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Checkbox,
    FormControlLabel,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import DoubleButtons from "@components/DoubleButtons";
import LspTextField from "@components/LspTextField";
import useNumber from "@helpers/useNumber";
import moveMoneyAction from "@redux/actions/move-money";
import { INPUT_RULES } from "@config/constants";
import napasLogo from "@assets/images/napas_logo.svg";
import PropTypes from "prop-types";
import useTransactionValidation from "../useTransactionValidation";
import TransferConfirmationDialog, {
    TransferConfirmationDialogType,
} from "./TransferConfirmationDialog";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: theme.spacing(1, 2),
    },
    table: {
        "& .MuiTableCell-sizeSmall": {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        "& .MuiTypography-colorPrimary": {
            fontWeight: 600,
        },
        "& .MuiTableRow-root:last-child": {
            "& .MuiTableCell-root": {
                borderBottom: "none",
            },
        },
    },
    fastTransferLabel: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        "&:last-child": {
            marginBottom: 0,
        },
    },
}));

const TransferConfirmation = ({
    onBack,
    onConfirm,
    destination,
    amount,
    description,
    disableSavePayee,
    progressing,
    duplicatedPayee,
    removeDuplicatedPayee,
    isFastTransfer,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        formatCardNumber,
        thousandSeparator,
        decimalSeparator,
    } = useNumber();
    const { validatePayeeDestination } = useTransactionValidation();
    const [isSaveChecked, setIsSaveChecked] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const targetIdNumber = useMemo(() => {
        if (!destination) {
            return "";
        }

        if (destination.account?.cardNumber) {
            return destination.account?.cardNumber;
        }
        if (destination.account?.accountNumber) {
            return destination.account?.accountNumber;
        }
        if (destination.bank?.accountNumber) {
            return destination.bank?.accountNumber;
        }
        return formatCardNumber(destination.card?.cardNumber);
    }, [destination, formatCardNumber]);

    const targetBankName = useMemo(() => {
        if (!destination) {
            return "";
        }
        if (destination.account?.bankName) {
            return destination.account.bankName;
        }
        if (destination.bank?.bankName) {
            return destination.bank.bankName;
        }
        return destination.card?.bankName;
    }, [destination]);

    const targetName = useMemo(() => {
        if (!destination) {
            return "";
        }
        if (destination.account?.accountName) {
            return destination.account.accountName;
        }
        if (destination.account?.cardName) {
            return destination.account.cardName;
        }
        if (destination.bank?.accountName) {
            return destination.bank.accountName;
        }
        return destination.card?.cardName;
    }, [destination]);

    const [payeeName, setPayeeName] = useState(targetName);
    const [payeeNameError, setPayeeNameError] = useState(false);

    const [existingPayee, setExistingPayee] = useState(null);

    const onConfirmButtonClick = useCallback(async () => {
        if (isSaveChecked) {
            if (!payeeName) {
                setPayeeNameError(true);
                return;
            }
            validatePayeeDestination({
                isCancelled: unmounted.current,
                accountNumber:
                    destination.account?.cardNumber ||
                    destination.bank?.accountNumber,
                payeeName,
                handlePayeeDestinationValid: () => {
                    console.log("handlePayeeDestinationValid");
                    if (onConfirm) {
                        onConfirm(payeeName);
                    }
                },
                handleDestinationExistInPayee: (data) => {
                    console.log("handleDestinationExistInPayee", data);
                    setExistingPayee(data);
                },
                handleInternalDestinationExistInPayee: () => {
                    console.log("handleInternalDestinationExistInPayee");
                    setPayeeNameError(true);
                    AlertController.show(
                        t("payee_msg_exist_payee_have_destination_other"),
                        AlertType.Error
                    );
                },
                handleInternalDestinationNotExistInPayee: (data) => {
                    console.log(
                        "handleInternalDestinationNotExistInPayee",
                        data
                    );
                    setExistingPayee(data);
                },
            });
            return;
        }
        if (onConfirm) {
            onConfirm();
        }
    }, [
        isSaveChecked,
        payeeName,
        destination,
        onConfirm,
        validatePayeeDestination,
        t,
    ]);

    const onPayeeNameChange = useCallback((e) => {
        setPayeeNameError(false);
        setPayeeName(e.target.value);
    }, []);

    return (
        <>
            <TransferConfirmationDialog
                open={!!existingPayee || !!duplicatedPayee}
                payeeAvatar={existingPayee?.avatar || duplicatedPayee?.avatar}
                payeeName={existingPayee?.nickName || duplicatedPayee?.nickName}
                type={
                    existingPayee
                        ? TransferConfirmationDialogType.DestinationExistInPayee
                        : TransferConfirmationDialogType.DuplicatedPayeeName
                }
                progressing={progressing}
                onConfirm={() => onConfirm()}
                onCancel={() => {
                    setExistingPayee(null);
                    removeDuplicatedPayee();
                }}
            />
            <Box p={3} paddingTop={1}>
                <Paper>
                    <TableContainer
                        component={Box}
                        className={classes.tableContainer}
                        marginBottom={2}
                    >
                        <Table size="small" className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="body2">
                                            {t("lb_to")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {targetIdNumber && (
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {targetIdNumber}
                                            </Typography>
                                        )}
                                        {targetBankName && (
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {targetBankName}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="body2">
                                            {t("lb_account_name")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {targetName && (
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {targetName}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="body2">
                                            {t("lb_amount_web")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            <NumberFormat
                                                value={amount}
                                                displayType="text"
                                                thousandSeparator={
                                                    thousandSeparator
                                                }
                                                decimalSeparator={
                                                    decimalSeparator
                                                }
                                            />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="body2">
                                            {t("payee_card_description_label")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            {description}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                {isFastTransfer && (
                    <Box marginBottom={2} className={classes.fastTransferLabel}>
                        <Typography variant="body2">
                            {t("master:move_money_method_247")}
                        </Typography>
                        &nbsp;
                        <img src={napasLogo} alt="Napas logo" />
                    </Box>
                )}

                {!disableSavePayee && (
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSaveChecked}
                                    onChange={(e) =>
                                        setIsSaveChecked(e.target.checked)
                                    }
                                    name="saveAsPayee"
                                    color="primary"
                                />
                            }
                            label={t("card_payee_save_question")}
                        />
                        {isSaveChecked && (
                            <LspTextField
                                label={t("payee_name_label")}
                                disableHelperText
                                error={payeeNameError}
                                value={payeeName}
                                onChange={onPayeeNameChange}
                                inputProps={{
                                    maxLength: INPUT_RULES.payeeNameLength,
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Box p={3} paddingTop={1}>
                <DoubleButtons
                    progressing={progressing}
                    primaryButton={{
                        label: t("lb_move_money"),
                        onClick: onConfirmButtonClick,
                    }}
                    secondaryButton={{ label: t("lb_back"), onClick: onBack }}
                />
            </Box>
        </>
    );
};

TransferConfirmation.propTypes = {
    isFastTransfer: PropTypes.bool,
};

TransferConfirmation.defaultProps = {
    isFastTransfer: false,
};

const mapState = (state) => ({
    duplicatedPayee: state.moveMoney.existingPayee,
});

const mapsDispatch = (dispatch) => ({
    removeDuplicatedPayee: () =>
        dispatch(moveMoneyAction.removeExistingPayee()),
});

export default connect(mapState, mapsDispatch)(TransferConfirmation);
