import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, Drawer, Hidden } from "@material-ui/core";
import layoutAction from "../../redux/actions/layout";
import MenuContent from "./MenuContent";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: theme.drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    mobileDrawer: {
        width: theme.drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        [theme.breakpoints.down(400)]: {
            width: "100%",
        },
    },
    drawerPaper: {
        paddingTop: theme.mixins.toolbar.minHeight,
    },
    drawerOpen: {
        width: theme.drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(8) + 1,
        },
    },
}));

const SideMenu = ({ opened, closeSideMenu }) => {
    const classes = useStyles();

    return (
        <>
            <Hidden smDown>
                <Drawer
                    variant="permanent"
                    anchor="right"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: opened,
                        [classes.drawerClose]: !opened,
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: opened,
                            [classes.drawerClose]: !opened,
                        }),
                    }}
                >
                    <MenuContent />
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    classes={{
                        paper: classes.mobileDrawer,
                    }}
                    open={opened}
                    onClose={closeSideMenu}
                >
                    <MenuContent />
                </Drawer>
            </Hidden>
        </>
    );
};

const mapState = (state) => ({
    opened: state.layout.sideMenuOpened,
});

const mapDispatch = (dispatch) => ({
    closeSideMenu: () => dispatch(layoutAction.closeSideMenu()),
});

export default connect(mapState, mapDispatch)(SideMenu);
