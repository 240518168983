import LspTypography from "@components/LspTypography";
import { Divider, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    typo: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: 153,
        justifyContent: "center",
    },
    wrapper: {
        paddingBottom: theme.spacing(2),
    },
}));

const InstallmentDetailLoading = ({ className }) => {
    const styles = useStyles();
    return (
        <>
            <div className={className.box}>
                <div className={className.headerWrapper}>
                    <div className={`${className.headerStatus}`}>
                        <LspTypography
                            variant="heading2"
                            color="white"
                            className={styles.typo}
                        >
                            <Skeleton width="30%" />
                        </LspTypography>
                    </div>
                    <div className={styles.header}>
                        <Skeleton width="60%" />
                        <Skeleton width="30%" />
                        <Skeleton width="50%" />
                        <Skeleton width="80%" />
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                    <Divider className={className.divider} />
                    <div className={className.infoLine}>
                        <LspTypography
                            variant="body2"
                            className={`${className.label} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            className={`${className.value} ${styles.typo}`}
                        >
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InstallmentDetailLoading;
