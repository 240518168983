import {
    CARD_TYPES,
    PHYSICAL_CARD_STATUS,
} from "@containers/CardManagement/constant";
import { useCallback } from "react";

const useCard = () => {
    // const user = useSelector((state) => state.user.info, shallowEqual);

    const isCreditCard = useCallback((cardType) => {
        return (
            cardType === CARD_TYPES.VISA_CARD ||
            cardType === CARD_TYPES.MASTER_CARD
        );
    }, []);

    const isVirtualCard = useCallback((cardType) => {
        return (
            cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
            cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
        );
    }, []);

    const isDebitCard = useCallback((cardType) => {
        return cardType === CARD_TYPES.DEBIT_CARD;
    }, []);

    const isPhysicalVirtualDebitVisa = useCallback(
        (cardType, physicalVirtualVDCardStatus) => {
            return (
                isVirtualCard(cardType) &&
                physicalVirtualVDCardStatus === PHYSICAL_CARD_STATUS.CARD_OK
            );
        },
        [isVirtualCard]
    );

    const isRequestedPhysicalVirtualDebitVisa = useCallback(
        (cardType, physicalVirtualVDCardStatus) => {
            return (
                isVirtualCard(cardType) &&
                physicalVirtualVDCardStatus &&
                physicalVirtualVDCardStatus !== PHYSICAL_CARD_STATUS.NEW
            );
        },
        [isVirtualCard]
    );

    return {
        isPhysicalVirtualDebitVisa,
        isRequestedPhysicalVirtualDebitVisa,
        isVirtualCard,
        isDebitCard,
        isCreditCard,
    };
};

export default useCard;
