import { useMemo, useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    makeStyles,
    Radio,
    RadioGroup,
    Tooltip,
} from "@material-ui/core";
import requestDocumentService from "@services/request-document";
import LspButton from "@components/LspButton";
import Loading from "@components/Loading";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { connect } from "react-redux";
import userAction from "@redux/actions/user";
import { format, parse, subDays, subMonths } from "date-fns";
import RequestDocumentActions from "@redux/actions/request-document";
import LspTextField from "@components/LspTextField";
import clsx from "clsx";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PageHeaderBack from "@components/PageHeaderBack";
import { useHistory } from "react-router-dom";
import {
    REQUEST_DOC_FORM_TYPE,
    REQUEST_DOC_STEPS,
    REQUEST_DOC_TYPE,
} from "./constant";

const useStyles = makeStyles((theme) => ({
    contentPreview: {
        width: "70%",
        margin: "auto",
        "& > div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& > p": {
                width: "50%",
                textAlign: "left",
            },
            "&:last-child": {
                marginBottom: theme.spacing(2),
            },
        },
    },
    desc: {
        fontStyle: "italic",
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    transactionInput: {
        width: "50%",
        display: "block",
        margin: "auto",
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    nextBtn: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > button": {
            width: "50%",
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    },
    itemDate: {
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: "100%",
        },
    },
    cycleList: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexWrap: "wrap",
            "& > label": {
                width: "45%",
            },
        },
    },
}));

const DocumentDetail = ({
    getServerTime,
    openDateCreateAccount,
    serverTime,
    setDocumentInfo,
    documentInfo,
    onNext,
}) => {
    const { t, i18n } = useTranslation();
    const [showView, setShowView] = useState("");
    const [loading, setLoading] = useState(true);
    const [transactionId, setTransactionId] = useState("");
    const [errorTransactionId, setErrorTransactionId] = useState("");
    const [contentPreview, setContentPreview] = useState(null);
    const [showPreviewInformation, setShowPreviewInformation] = useState(false);
    const [checkingTransactionID, setCheckingTransactionID] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [errors, setErrors] = useState({
        fromDate: false,
        toDate: false,
    });
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const [cycleOption, setCycleOption] = useState(0);
    const history = useHistory();

    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const invalidTimeRange = useMemo(
        () => errors.fromDate || errors.toDate || !fromDate || !toDate,
        [fromDate, toDate, errors]
    );
    const cycleList = useMemo(() => {
        const list = [
            { id: 1, text: t("rq_label_last_billing_cycle") },
            { id: 2, text: t("rq_label_last_2_billing_cycle") },
            { id: 3, text: t("rq_label_last_3_billing_cycle") },
            { id: 0, text: t("rq_label_customize") },
        ];
        return list;
    }, [t]);

    const { formatNumber } = useNumber();
    const classes = useStyles();

    const openDate = useMemo(() => {
        if (openDateCreateAccount === "") {
            return subMonths(new Date(serverTime.value), 3);
        }
        if (openDateCreateAccount) {
            return parse(openDateCreateAccount, "dd/MM/yyyy", new Date());
        }
    }, [openDateCreateAccount, serverTime]);

    const currentDate = useMemo(() => {
        if (serverTime.value) {
            const dateFormatted = subDays(new Date(serverTime.value), 1);
            return dateFormatted;
        }
    }, [serverTime]);

    const onChangeDocumentId = (e) => {
        const { value } = e.target;

        if (!value) {
            setErrorTransactionId(t("msg_we_need_this"));
        } else {
            setErrorTransactionId("");
        }
        setTransactionId(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.TRANSACTION_ID,
                text: value,
            })
        );
    };

    const descTitle = useMemo(() => {
        let content = "";
        if (showView === REQUEST_DOC_FORM_TYPE.oneFieldDate) {
            content = t("rq_desc_choose_date_balance_request");
        } else if (
            showView === REQUEST_DOC_FORM_TYPE.twoFieldsDate ||
            showView === REQUEST_DOC_FORM_TYPE.threeFields
        ) {
            content = t("rq_desc_choose_perior_statement_request");
        } else {
            content = t("rq_desc_provide_more_detail");
        }
        return content;
    }, [t, showView]);

    const initData = useCallback(
        (view) => {
            const setDate = () => {
                if (documentInfo.fromDate && documentInfo.toDate) {
                    setFromDate(
                        format(
                            parse(
                                documentInfo.fromDate,
                                "dd/MM/yyyy",
                                new Date()
                            ),
                            "MM/dd/yyyy"
                        )
                    );

                    setToDate(
                        format(
                            parse(
                                documentInfo.toDate,
                                "dd/MM/yyyy",
                                new Date()
                            ),
                            "MM/dd/yyyy"
                        )
                    );
                }
            };
            if (view === REQUEST_DOC_FORM_TYPE.transactionID) {
                setTransactionId(documentInfo.transactionId);
            }

            if (view === REQUEST_DOC_FORM_TYPE.twoFieldsDate) {
                setDate();
            }

            if (documentInfo.cycleType !== "") {
                setCycleOption(documentInfo.cycleType);
                if (documentInfo.cycleType === 0) {
                    setDate();
                }
            }
        },
        [
            documentInfo.fromDate,
            documentInfo.toDate,
            documentInfo.cycleType,
            documentInfo.transactionId,
        ]
    );

    const getDocumentTemplate = useCallback(async () => {
        if (!documentInfo.requestId) {
            history.push("list");
            return;
        }
        const { data } = await requestDocumentService.getDocumentTemplate(
            i18n.language,
            documentInfo.requestId
        );
        if (unmounted.current) {
            return;
        }
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setDocumentInfo({ view: data.data.formId });

                if (data.data.formId === REQUEST_DOC_FORM_TYPE.oneFieldDate) {
                    setDocumentInfo({
                        detailConfirmationType:
                            data.data.detailConfirmationType,
                    });
                    // case GET_ACCOUNT_LIST
                    if (
                        data.data.detailConfirmationType ===
                        REQUEST_DOC_TYPE.GET_ACCOUNT_LIST
                    ) {
                        onNext(REQUEST_DOC_STEPS.CHOOSE_ACCOUNT);
                        return;
                    }

                    onNext(REQUEST_DOC_STEPS.CONFIRM_DOCUMENT);
                } else {
                    setShowView(data.data.formId);
                }
                initData(data.data.formId);
                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [
        history,
        initData,
        setDocumentInfo,
        documentInfo.requestId,
        i18n,
        onNext,
    ]);

    const checkTransactionID = useCallback(async () => {
        setCheckingTransactionID(true);
        const { data } = await requestDocumentService.getInfoTransactionID(
            transactionId
        );
        setCheckingTransactionID(false);
        if (data) {
            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    const summaryInfo = {
                        amount: data.data.amount.toString().replace("-", ""),
                        transactionDate: data.data.txnDate
                            ? format(
                                  parse(
                                      data.data.txnDate,
                                      "yyyy-MM-dd HH:mm",
                                      new Date()
                                  ),
                                  "dd/MM/yyyy"
                              )
                            : "",

                        bankName: data.data.bankName,
                        fullName: data.data.accountName || data.data.cardName,
                        accountNumber:
                            data.data.accountNumber || data.data.cardNumber,
                        isCard: !data.data.accountNumber,
                    };
                    setContentPreview(summaryInfo);
                    setDocumentInfo({ transactionId });
                    setShowPreviewInformation(true);
                    break;

                default:
                    // $scope.isCheckingTransactionID = false;
                    setErrorTransactionId(
                        t("rq_msg_error_cannot_find_transactionID")
                    );
                    break;
            }
        } else {
            setErrorTransactionId(t("rq_msg_error_cannot_find_transactionID"));
        }
    }, [transactionId, t, setDocumentInfo]);

    const onFromDateChange = useCallback((d) => {
        if (!d) {
            setFromDate(" ");
            return;
        }
        setFromDate(new Date(d));
    }, []);

    const onToDateChange = useCallback((d) => {
        if (!d) {
            setToDate(" ");
            return;
        }
        setToDate(new Date(d));
    }, []);

    const onFromDateError = useCallback((err) => {
        setErrors((value) => ({
            ...value,
            fromDate: Boolean(err),
        }));
    }, []);

    const onToDateError = useCallback((err) => {
        setErrors((value) => ({
            ...value,
            toDate: Boolean(err),
        }));
    }, []);

    const onChangeCycleHandler = (e) => {
        const { value } = e.target;
        setCycleOption(+value);
    };

    const validateForm = () => {
        if (showView === REQUEST_DOC_FORM_TYPE.oneFieldDate) {
            onNext(REQUEST_DOC_STEPS.CHOOSE_ACCOUNT);
        } else if (
            showView === REQUEST_DOC_FORM_TYPE.twoFieldsDate ||
            showView === REQUEST_DOC_FORM_TYPE.threeFields
        ) {
            const checkValidateForDate = () => {
                setErrors({
                    fromDate: !fromDate,
                    toDate: !toDate,
                });

                if (invalidTimeRange) {
                    return;
                }

                setDocumentInfo({
                    fromDate: format(new Date(fromDate), "dd/MM/yyyy"),
                    toDate: format(new Date(toDate), "dd/MM/yyyy"),
                });

                onNext(REQUEST_DOC_STEPS.CONFIRM_DOCUMENT);
            };
            if (showView === REQUEST_DOC_FORM_TYPE.threeFields) {
                setDocumentInfo({
                    cycleType: cycleOption,
                });
                if (cycleOption === 0) {
                    checkValidateForDate();
                } else {
                    onNext(REQUEST_DOC_STEPS.CONFIRM_DOCUMENT);
                }
            } else {
                checkValidateForDate();
            }
        } else if (!transactionId) {
            setErrorTransactionId(t("msg_we_need_this"));
        } else {
            checkTransactionID();
        }
    };

    const backToBeforeStep = () => {
        if (showPreviewInformation) {
            setShowPreviewInformation(false);
            return;
        }
        history.goBack();
    };

    useEffect(() => {
        getServerTime();
        getDocumentTemplate();
    }, [getServerTime, getDocumentTemplate]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <PageHeaderBack onBack={backToBeforeStep}>
                        {documentInfo.desc}
                    </PageHeaderBack>
                    {!showPreviewInformation && (
                        <>
                            {showView ===
                                REQUEST_DOC_FORM_TYPE.transactionID && (
                                <LspTypography
                                    variant="body2"
                                    color="grey"
                                    className={classes.desc}
                                >
                                    {t("rq_transaction_id_notes")}
                                </LspTypography>
                            )}

                            <LspTypography
                                variant="body2"
                                color="grey"
                                className={classes.desc}
                            >
                                {descTitle}
                            </LspTypography>
                        </>
                    )}

                    {showView === REQUEST_DOC_FORM_TYPE.transactionID && (
                        <>
                            {!showPreviewInformation ? (
                                <Box textAlign="center">
                                    <LspTextField
                                        label={t("rq_lb_transactionID")}
                                        value={transactionId}
                                        onChange={onChangeDocumentId}
                                        inputProps={{
                                            maxLength: 17,
                                        }}
                                        className={classes.transactionInput}
                                        error={!!errorTransactionId}
                                        helperText={errorTransactionId || " "}
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip
                                                    arrow
                                                    title={t(
                                                        "rq_hint_desc_remittance_confirm"
                                                    )}
                                                    classes={{
                                                        tooltip:
                                                            classes.rolloverInfoPopper,
                                                    }}
                                                >
                                                    <Icon
                                                        className={clsx(
                                                            "font-icon icon-vcinfo",
                                                            classes.rolloverInfoIcon
                                                        )}
                                                    />
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box textAlign="center">
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        className={classes.desc}
                                    >
                                        {t(
                                            "rq_desc_preview_transaction_information"
                                        )}
                                    </LspTypography>
                                    <div className={classes.contentPreview}>
                                        <div>
                                            <LspTypography
                                                variant="body1"
                                                color="black"
                                            >
                                                {t("rq_lb_transactionID")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="subheading1"
                                                color="primary"
                                            >
                                                {transactionId}
                                            </LspTypography>
                                        </div>
                                        {contentPreview?.transactionDate && (
                                            <div>
                                                <LspTypography
                                                    variant="body1"
                                                    color="black"
                                                >
                                                    {t(
                                                        "rq_lb_transaction_date"
                                                    )}
                                                </LspTypography>
                                                <LspTypography
                                                    variant="subheading1"
                                                    color="primary"
                                                >
                                                    {
                                                        contentPreview?.transactionDate
                                                    }
                                                </LspTypography>
                                            </div>
                                        )}
                                        {contentPreview?.amount && (
                                            <div>
                                                <LspTypography
                                                    variant="body1"
                                                    color="black"
                                                >
                                                    {t("lb_amount_web")}
                                                </LspTypography>
                                                <LspTypography
                                                    variant="subheading1"
                                                    color="primary"
                                                >
                                                    {formatNumber(
                                                        contentPreview?.amount
                                                    )}
                                                </LspTypography>
                                            </div>
                                        )}
                                    </div>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        className={classes.desc}
                                    >
                                        {t("rq_desc_payee_detail")}
                                    </LspTypography>
                                    <div className={classes.contentPreview}>
                                        {contentPreview?.fullName && (
                                            <div>
                                                <LspTypography
                                                    variant="body1"
                                                    color="black"
                                                >
                                                    {t("rq_lb_full_name")}
                                                </LspTypography>
                                                <LspTypography
                                                    variant="subheading1"
                                                    color="primary"
                                                >
                                                    {contentPreview?.fullName}
                                                </LspTypography>
                                            </div>
                                        )}
                                        {contentPreview?.accountNumber && (
                                            <div>
                                                <LspTypography
                                                    variant="body1"
                                                    color="black"
                                                >
                                                    {t(
                                                        "payee_card_number_label"
                                                    )}
                                                </LspTypography>
                                                <LspTypography
                                                    variant="subheading1"
                                                    color="primary"
                                                >
                                                    {
                                                        contentPreview?.accountNumber
                                                    }
                                                </LspTypography>
                                            </div>
                                        )}

                                        {contentPreview?.bankName && (
                                            <div>
                                                <LspTypography
                                                    variant="body1"
                                                    color="black"
                                                >
                                                    {t("payee_bank_name_label")}
                                                </LspTypography>
                                                <LspTypography
                                                    variant="subheading1"
                                                    color="primary"
                                                >
                                                    {contentPreview?.bankName}
                                                </LspTypography>
                                            </div>
                                        )}
                                    </div>
                                    <LspButton
                                        onClick={() =>
                                            onNext(
                                                REQUEST_DOC_STEPS.CONFIRM_DOCUMENT
                                            )
                                        }
                                        progressing={checkingTransactionID}
                                        className={classes.nextBtn}
                                    >
                                        {t("lb_next")}
                                    </LspButton>
                                </Box>
                            )}
                        </>
                    )}
                    {(showView === REQUEST_DOC_FORM_TYPE.twoFieldsDate ||
                        showView === REQUEST_DOC_FORM_TYPE.threeFields) && (
                        <>
                            {showView === REQUEST_DOC_FORM_TYPE.threeFields && (
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        defaultValue="bottom"
                                        className={classes.cycleList}
                                        style={{ justifyContent: "center" }}
                                    >
                                        {cycleList?.map((item) => {
                                            return (
                                                <FormControlLabel
                                                    key={item.id}
                                                    value={item.id}
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            checked={
                                                                item.id ===
                                                                cycleOption
                                                            }
                                                            onChange={
                                                                onChangeCycleHandler
                                                            }
                                                        />
                                                    }
                                                    label={item.text}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            )}

                            {cycleOption === 0 && (
                                <Box marginY={4}>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="column"
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.itemDate}
                                        >
                                            <KeyboardDatePicker
                                                TextFieldComponent={
                                                    LspTextField
                                                }
                                                error={errors.fromDate}
                                                disableHelperText
                                                label={t("rq_lb_from_date")}
                                                disableToolbar
                                                autoOk
                                                disableFuture
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                invalidDateMessage=" "
                                                maxDateMessage=" "
                                                value={fromDate}
                                                onChange={onFromDateChange}
                                                onError={onFromDateError}
                                                maxDate={currentDate}
                                                minDate={openDate}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.itemDate}
                                        >
                                            <KeyboardDatePicker
                                                TextFieldComponent={
                                                    LspTextField
                                                }
                                                error={errors.toDate}
                                                disableHelperText
                                                label={t("rq_lb_to_date")}
                                                disableToolbar
                                                autoOk
                                                disableFuture
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                invalidDateMessage=" "
                                                maxDateMessage=" "
                                                minDateMessage=" "
                                                value={toDate}
                                                minDate={fromDate}
                                                disabled={!fromDate}
                                                onChange={onToDateChange}
                                                onError={onToDateError}
                                                maxDate={currentDate}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </>
                    )}
                    {!showPreviewInformation && (
                        <LspButton
                            onClick={validateForm}
                            progressing={checkingTransactionID}
                            className={classes.nextBtn}
                        >
                            {t("lb_next")}
                        </LspButton>
                    )}
                </>
            )}
        </>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime,
    openDateCreateAccount: state.user.info.displayKYCDate,
    documentInfo: state.requestDocument,
});

const mapDispatch = (dispatch) => ({
    getServerTime: () => dispatch(userAction.getServerTimeRequest()),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
});

export default connect(mapState, mapDispatch)(DocumentDetail);
