export const CLAIM_MONEY_SCREENS = {
    LOADING: "LOADING",
    SECURITY_OPTIONS: "SECURITY_OPTIONS",
    INPUT_PASSWORD: "INPUT_PASSWORD",
    INPUT_OTP: "INPUT_OTP",
    PAYMENT_DETAIL: "PAYMENT_DETAIL",
    LIXI_DETAIL: "LIXI_DETAIL",
    JOIN_TIMO: "JOIN_TIMO",
    BANK_DETAIL: "BANK_DETAIL",
    RESULT: "RESULT",
};

export const CLAIM_MONEY_ERROR_TYPES = {
    WRONG_PASSWORD_MANY_TIMES: "WRONG_PASSWORD_MANY_TIMES",
    ERROR_PROCESSING: "ERROR_PROCESSING",
    BASE_ON_STATUS: "BASE_ON_STATUS",
};
