/* ------------- Types and Action Definition ------------- */
export const PaymentRequestTypes = Object.freeze({
    OTPRequired: "PaymentRequestTypes/OTPRequired",
    OTPCommit: "PaymentRequestTypes/OTPCommit",
    OTPCommitSuccess: "PaymentRequestTypes/OTPCommitSuccess",
    OTPCommitError: "PaymentRequestTypes/OTPCommitError",
    ResetState: "PaymentRequestTypes/ResetState",
    GetPaymentRequestList: "PaymentRequestTypes/GetPaymentRequestList",
    GetPaymentRequestListSuccess:
        "PaymentRequestTypes/GetPaymentRequestListSuccess",
    GetPaymentRequestListError:
        "PaymentRequestTypes/GetPaymentRequestListError",
    GetPaymentRequestCompleteList:
        "PaymentRequestTypes/GetPaymentRequestCompleteList",
    GetPaymentRequestCompleteListSuccess:
        "PaymentRequestTypes/GetPaymentRequestCompleteListSuccess",
    GetPaymentRequestCompleteListError:
        "PaymentRequestTypes/GetPaymentRequestCompleteListError",
});

/* ------------- Actions Definition ------------- */

const otpRequired = (payload) => {
    return {
        type: PaymentRequestTypes.OTPRequired,
        payload,
    };
};

const otpCommit = (payload) => {
    return {
        type: PaymentRequestTypes.OTPCommit,
        payload,
    };
};

const otpCommitSuccess = (payload) => {
    return {
        type: PaymentRequestTypes.OTPCommitSuccess,
        payload,
    };
};

const otpCommitError = (payload) => {
    return {
        type: PaymentRequestTypes.OTPCommitError,
        payload,
    };
};

const resetState = (payload) => {
    return {
        type: PaymentRequestTypes.ResetState,
        payload,
    };
};

const getPaymentRequestList = (payload) => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestList,
        payload,
    };
};
const getPaymentRequestListSuccess = (payload) => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestListSuccess,
        payload,
    };
};
const getPaymentRequestListError = () => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestListError,
    };
};
const getPaymentRequestCompleteList = (payload) => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestCompleteList,
        payload,
    };
};
const getPaymentRequestCompleteListSuccess = (payload) => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestCompleteListSuccess,
        payload,
    };
};
const getPaymentRequestCompleteListError = () => {
    return {
        type: PaymentRequestTypes.GetPaymentRequestCompleteListError,
    };
};

const PaymentRequestActions = {
    otpRequired,
    otpCommit,
    otpCommitSuccess,
    otpCommitError,
    resetState,
    getPaymentRequestList,
    getPaymentRequestListSuccess,
    getPaymentRequestListError,
    getPaymentRequestCompleteList,
    getPaymentRequestCompleteListSuccess,
    getPaymentRequestCompleteListError,
};

export default PaymentRequestActions;
