import { Icon, makeStyles } from "@material-ui/core";
import { memo, useEffect } from "react";
import introImg from "@assets/images/partial_introduction.png";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { HandTouchIcon, ThreeStartIcon } from "@components/LspIcon";
import LspButton from "@components/LspButton";
import { isFunction, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { connect } from "react-redux";
import termDepositAction from "@redux/actions/term-deposit";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    introImg: {
        display: "flex",
        justifyContent: "center",
    },
    content: {
        padding: theme.spacing(0, 4),
        marginBottom: theme.spacing(3),
    },
    footer: {},

    footerText: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(0, 4),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    line: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    lineIcon: {
        marginRight: theme.spacing(2),
        alignSelf: "center",
        color: theme.palette.magicalGrey[400],
        maxWidth: 24,

        "& .font-icon": {
            color: theme.palette.magicalGrey[400],
        },
    },
    iconColor: {
        color: theme.palette.grey[400],
    },
    lineText: {},
}));

const IntroPartialWithdraw = ({
    onSubmit,
    earlyRedeemTD,
    earlyRedeemTDId,
    getEarlyRedeemTD,
    tdId,
    earlyRedeemTDIdFetching,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit = () => {
        if (isFunction(onSubmit)) {
            onSubmit();
        }
    };

    useEffect(() => {
        if (earlyRedeemTDId !== tdId) {
            getEarlyRedeemTD({ termDepositId: tdId });
        }
    }, [earlyRedeemTD, earlyRedeemTDId, getEarlyRedeemTD, tdId]);

    return (
        <Content size="xs">
            <div className={classes.container}>
                <div className={classes.introImg}>
                    <img src={introImg} alt="Intro Partial Img" />
                </div>
                <LspTypography
                    variant="title2"
                    textCenter
                    className={classes.title}
                >
                    {t("TD:td_partial_withdraw_intro_title")}
                </LspTypography>
                <div className={classes.content}>
                    {/* ======================================== */}
                    <div className={classes.line}>
                        <div className={classes.lineIcon}>
                            <HandTouchIcon />
                        </div>
                        <div className={classes.lineText}>
                            <Interweave
                                content={t(
                                    "TD:td_partial_withdraw_intro_body1"
                                ).replace(
                                    "%@",
                                    earlyRedeemTDIdFetching
                                        ? "..."
                                        : earlyRedeemTD?.annualInterestRateNow
                                )}
                            />
                        </div>
                    </div>

                    {/* ======================================== */}
                    <div className={classes.line}>
                        <div className={classes.lineIcon}>
                            <Icon className="font-icon icon-creditcard" />
                        </div>
                        <div className={classes.lineText}>
                            <Interweave
                                content={t(
                                    "TD:td_partial_withdraw_intro_body2"
                                )}
                            />
                        </div>
                    </div>

                    {/* ======================================== */}
                    <div className={classes.line}>
                        <div className={classes.lineIcon}>
                            <Icon className="font-icon icon-cash-v2" />
                        </div>
                        <div className={classes.lineText}>
                            <Interweave
                                content={t(
                                    "TD:td_partial_withdraw_intro_body3"
                                )}
                            />
                        </div>
                    </div>

                    {/* ======================================== */}
                    <div className={classes.line}>
                        <div className={classes.lineIcon}>
                            <ThreeStartIcon />
                        </div>
                        <div className={classes.lineText}>
                            <Interweave
                                content={t(
                                    "TD:td_partial_withdraw_intro_body4"
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <LspTypography
                        variant="body3"
                        className={classes.footerText}
                        color="grey"
                    >
                        {t("TD:td_partial_withdraw_intro_note")}
                    </LspTypography>
                    <LspButton
                        variant="primary"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        {t("td_processing_convert1")}
                    </LspButton>
                </div>
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    earlyRedeemTD: state.termDeposit.earlyRedemptionTD?.data,
    earlyRedeemTDId: state.termDeposit.earlyRedemptionTD?.tdId,
    earlyRedeemTDIdFetching: state.termDeposit.earlyRedemptionTD?.fetching,
});

const dispatchProps = (dispatch) => ({
    getEarlyRedeemTD: (payload) =>
        dispatch(termDepositAction.earlyRedeemTDRequest(payload)),
});

export default connect(mapState, dispatchProps)(memo(IntroPartialWithdraw));
