import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import PageHeader from "@components/PageHeader";
import { FetchStatus, ONE_MILLION, RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import {
    Box,
    Collapse,
    Fade,
    Grow,
    Slide,
    Zoom,
    makeStyles,
} from "@material-ui/core";
import termDepositService from "@services/term-deposit";
import { format, parse } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import LspTypography from "@components/LspTypography";
import LspHintInfo from "@components/LspHintInfo";
import { CelebrationIcon } from "@components/LspIcon";
import clsx from "clsx";
import { isEmpty } from "lodash";
import termDepositAction from "@redux/actions/term-deposit";
import TermSelection from "./TermSelection";
import useTermDeposit from "./useTermDeposit";

const useStyles = makeStyles((theme) => ({
    infoBox: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[2],
        marginBottom: theme.spacing(2),

        "& .interestRateBonusBlue": {
            color: theme.palette.blue.main,
            fontWeight: "bold",
        },
        "& .interestRateBonusSuccess": {
            color: theme.palette.success.main,
            fontWeight: "bold",
        },
    },
    infoBoxNormal: {
        backgroundColor: "#2774AE0D",
    },
    infoBoxSuccess: {
        backgroundColor: "#30CD9A0D",
    },
}));

const AmountAndTerm = ({
    stepForward,
    payload,
    setPayload,
    setSuggestion,
    user,
    serverTimeStatus,
    sysParam,
    termDepositRate,
    getTermRates,
    termDepositRateStatus,
    termDepositRateFetch,
}) => {
    const classes = useStyles();
    const { openTDStep } = useTermDeposit();
    const { t } = useTranslation();
    const [minDeposit, setMinDeposit] = useState("1000000");
    const [amountSuffix, setAmountSuffix] = useState({});

    const [rates, setRates] = useState([]);
    const [specialRates, setSpecialRates] = useState(0);

    useEffect(() => {
        if (isEmpty(termDepositRate)) {
            getTermRates();
        }
    }, [getTermRates, termDepositRate]);

    useEffect(() => {
        if (
            !isEmpty(termDepositRate) &&
            termDepositRateStatus === FetchStatus.Success
        ) {
            setSpecialRates(termDepositRate.value || 0);
            setRates(termDepositRate.items);
        }
    }, [termDepositRate, termDepositRateStatus]);

    const {
        thousandSeparator,
        decimalSeparator,
        formatNumber,
        parseNumber,
        formatUnit,
    } = useNumber();

    const numberWithCommas = (numberWithComma, type) => {
        return numberWithComma
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, type);
    };

    // const amountSuffix = useMemo(() => {
    //     return numberWithCommas(minDeposit, thousandSeparator);
    // }, [thousandSeparator, minDeposit]);

    const defaultAmount = useMemo(
        () => ({
            errorText: "",
            formattedValue: amountSuffix,
            value: "1",
        }),
        [amountSuffix]
    );

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    useEffect(() => {
        setMinDeposit(sysParam.MinAmountLimitPerTD);
    }, [sysParam.MinAmountLimitPerTD]);

    const [amount, setAmount] = useState(() => {
        const formattedValue = formatNumber(payload.amountOfDeposit);
        const value = formattedValue.replace(amountSuffix, "");
        return {
            errorText: "",
            formattedValue,
            value,
        };
    });

    useEffect(() => {
        const amountSuffixAmount = numberWithCommas(
            minDeposit,
            thousandSeparator
        );

        setAmountSuffix(amountSuffixAmount);
        setAmount({
            errorText: "",
            formattedValue: amountSuffixAmount,
            value: formatNumber(amountSuffixAmount),
        });
    }, [
        minDeposit,
        sysParam.MinAmountLimitPerTD,
        thousandSeparator,
        formatNumber,
    ]);

    const [selectedTerm, setSelectedTerm] = useState();

    const [loading, setLoading] = useState(false);

    const onAmountChange = useCallback(({ formattedValue, value }) => {
        setAmount({ errorText: "", formattedValue, value });
    }, []);

    const onAmountBlur = useCallback(() => {
        if (!amount.value || !+amount.value) {
            setAmount(defaultAmount);
        }

        const minTD = minDeposit;
        if (+amount.value < +minTD) {
            setAmount(defaultAmount);
            return;
        }

        const formatAmountValue = parseNumber(amount.value);
        const formatMinDeposit = parseNumber(minDeposit);
        const roundNumber =
            Math.floor(formatAmountValue / formatMinDeposit) * formatMinDeposit;
        setAmount({
            errorText: "",
            formattedValue: roundNumber,
            value: formatNumber(roundNumber),
        });
    }, [amount.value, defaultAmount, formatNumber, minDeposit, parseNumber]);

    const foreignerNotice = useMemo(() => {
        if (!user.isForeigner) {
            return null;
        }

        let formattedVisaDateTo = "";

        if (user.visaDateTo) {
            formattedVisaDateTo = format(
                parse(user.visaDateTo, "yyyy-MM-dd", new Date()),
                "dd/MM/yyyy"
            );
        }

        return `${t("regulation:td_maturity_info")} ${t(
            "regulation:td_notice_exceed_visa2"
        )}`.replace("%@", formattedVisaDateTo);
    }, [t, user]);

    const submit = useCallback(async () => {
        if (loading || !selectedTerm) {
            return;
        }
        const amountOfDeposit = parseNumber(amount.formattedValue);
        setLoading(true);
        setPayload((currentPayload) => ({
            ...currentPayload,
            amountOfDeposit,
            termId: selectedTerm.termId,
            termDetail: selectedTerm,
        }));

        const res = await termDepositService.getOpeningSuggestion({
            amount: amountOfDeposit,
            termId: selectedTerm.termId,
        });

        if (unmounted.current) {
            return;
        }

        const { code, data } = res.data;
        setLoading(false);

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const clonedData = cloneDeep(data);
                clonedData.detail = clonedData.detail.map((item) => ({
                    ...item,
                    autoRoll: clonedData.rollOver,
                }));
                setSuggestion(clonedData);
                stepForward(openTDStep.termDepositReview);
                break;
            case RESPONSE_CODE.INVALID_AMOUNT:
            case 7101: // code_amount_round_divide_1mil
                break;
            case RESPONSE_CODE.NO_ENOUGH_MONEY:
                setAmount((prev) => ({
                    ...prev,
                    errorText: t("td_msg_user_no_enough_money"),
                }));
                break;
            default:
                GlobalDialogController.showFinancialError({ errorCode: code });
                break;
        }
    }, [
        loading,
        amount,
        selectedTerm,
        parseNumber,
        setPayload,
        stepForward,
        openTDStep,
        setSuggestion,
        t,
    ]);

    const MAX_VAL = sysParam.MaxAmountLimitPerTD || 100000000000;

    const withValueLimit = ({ formattedValue }) => {
        const amountOfDeposit = parseNumber(formattedValue);
        return amountOfDeposit <= MAX_VAL;
    };

    const isMathSpecialRate = useMemo(() => {
        return parseNumber(amount.value) >= specialRates;
    }, [amount.value, parseNumber, specialRates]);

    return (
        <>
            <PageHeader>
                {t("td_lb_how_much")}
                <LspTypography variant="body1">
                    {t("master:td_lb_ex_input_amount")}
                </LspTypography>
            </PageHeader>
            <Content size="sm" boxProps={{ py: 1 }}>
                {foreignerNotice && (
                    <Box mb={2}>
                        <LspTypography variant="body2">
                            {foreignerNotice}
                        </LspTypography>
                    </Box>
                )}
                <NumberFormat
                    value={amount.formattedValue}
                    onValueChange={onAmountChange}
                    onBlur={onAmountBlur}
                    label={t("lb_amount_web")}
                    customInput={LspTextField}
                    allowEmptyFormatting
                    allowLeadingZeros={false}
                    allowNegative={false}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    decimalScale={0}
                    error={Boolean(amount.errorText)}
                    helperText={amount.errorText}
                    isAllowed={withValueLimit}
                    inputProps={{
                        datatestid: "termAmount",
                    }}
                />
                {termDepositRate?.isAvailableTdRate && (
                    <>
                        <Grow
                            in={
                                selectedTerm?.interestRateBonus &&
                                !isMathSpecialRate
                            }
                            mountOnEnter
                            unmountOnExit
                            style={{ transformOrigin: "0 0 0" }}
                            {...(selectedTerm?.interestRateBonus &&
                            !isMathSpecialRate
                                ? { timeout: 1000 }
                                : {})}
                        >
                            <div
                                className={clsx(
                                    classes.infoBox,
                                    classes.infoBoxNormal
                                )}
                            >
                                <LspHintInfo
                                    content={t(
                                        "TD:td_special_rate_open_message_normal_td"
                                    )
                                        .replace("#@", formatUnit(specialRates))
                                        .replace(
                                            "%@",
                                            `<span class="interestRateBonusBlue">${selectedTerm?.interestRateBonus}%</span>`
                                        )}
                                    color="blue"
                                    contentColor="black"
                                />
                            </div>
                        </Grow>
                        <Grow
                            in={
                                selectedTerm?.interestRateBonus &&
                                isMathSpecialRate
                            }
                            mountOnEnter
                            unmountOnExit
                            style={{ transformOrigin: "0 0 0" }}
                            {...(selectedTerm?.interestRateBonus &&
                            isMathSpecialRate
                                ? { timeout: 1000 }
                                : {})}
                        >
                            <div
                                className={clsx(
                                    classes.infoBox,
                                    classes.infoBoxSuccess
                                )}
                            >
                                <LspHintInfo
                                    content={t(
                                        "TD:td_special_rate_open_message_large_td"
                                    )
                                        .replace("#@", formatUnit(specialRates))
                                        .replace(
                                            "%@",
                                            `<span class="interestRateBonusSuccess">${selectedTerm?.interestRateBonus}%</span>`
                                        )}
                                    customIcon={<CelebrationIcon />}
                                    contentColor="black"
                                />
                            </div>
                        </Grow>
                    </>
                )}

                {serverTimeStatus === FetchStatus.Success && (
                    <TermSelection
                        amount={amount}
                        selectedTerm={selectedTerm?.termId}
                        setSelectedTerm={setSelectedTerm}
                    />
                )}
                <LspButton
                    fullWidth
                    disabled={!selectedTerm}
                    progressing={loading}
                    onClick={submit}
                    buttonProps={{
                        datatestid: "openTDNext",
                    }}
                >
                    {t("td_lb_open_1_td")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    serverTimeStatus: state.user.serverTime.status,
    sysParam: state.systemParams.info.conf,
    termDepositRate: state.termDeposit.termRates.data,
    termDepositRateStatus: state.termDeposit.termRates.status,
    termDepositRateFetch: state.termDeposit.termRates.fetching,
});

const dispatchProps = (dispatch) => ({
    getTermRates: () => dispatch(termDepositAction.getTermDepositRates()),
});

export default connect(mapState, dispatchProps)(AmountAndTerm);
