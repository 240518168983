import api from "../config/api";

const getInternalAccountDetail = async (payload) => {
    const checkType = payload?.checkType || "timo";

    return await api.post("user/smartLink/cardName", { ...payload, checkType });
};

const getAccountDetailByCardNumber = async (payload) => {
    return await api.post("user/smartLink/cardName", {
        ...payload,
        checkType: "smartlink",
    });
};
const getAccountDetailByCreditCardNumber = async (payload) => {
    return await api.post("user/smartLink/creditCardName", {
        ...payload,
        checkType: "smartlink",
    });
};

const getAccountDetailByAccountNumber = async (payload) => {
    if (payload.bankId === "0") {
        // INTERNAL TRANSFER
        return await getInternalAccountDetail({
            ...payload,
            checkType: "vpbank",
        });
    }
    return await api.post("user/fastTransfer/getInfo", payload);
};

const getLimitations = async ({ accountNumber }) => {
    return await api.post("account/spend/transfer/limit", { accountNumber });
};

const transferToAccountNumber = async (payload) => {
    return await api.post("user/txn/transfer/account", payload);
};

const transferToDebitCard = async (payload) => {
    return await api.post("user/txn/transfer/card", payload);
};

const resendOTP = async (payload) => {
    return await api.post("user/otp/resend", payload);
};

const submitOTP = async (payload) => {
    return await api.post("user/txn/commit", payload);
    // return await apiMock.post("user/txn/commit", null);
};

const checkFastTransferStatus = async (params) => {
    // return await apiMock.get(`bank/bankId`, null);
    return await api.get(`bank/${params.bankId}`, null);
};

const checkPayeeDestinationExistence = async (payload) => {
    return await api.post("user/payee/destination/timo/validate", payload);
};

const addDestination = async (payload) => {
    return await api.post("user/payee/create-or-update", payload);
};

const addPayee = async (payload) => {
    return await api.post("user/payee/add", payload);
};

const getPhoneListForTopUp = async () => {
    return await api.get("/user/topup/contacts", {});
};

const getAmountListForTopUp = async () => {
    return await api.get("/user/topup/config/amountList", {});
};

const deletePhoneForTopUp = async (payload) => {
    return await api.delete("/user/topup/contacts", null, { data: payload });
};

const topUpRequest = async (payload) => {
    return await api.post("/user/topup/request", payload);
};

const passwordGenerator = async (payload) => {
    return await api.post(
        `/user/payanyone/${payload.paoType}/secretCode`,
        payload
    );
};

const transferByPhoneNumber = async (payload) => {
    return await api.post(`/user/payanyone/${payload.paoType}/create`, payload);
};

const commitOtpTransferByPhoneNumber = async (payload) => {
    return await api.post(
        `/user/payanyone/${payload.paoType}/create/commit`,
        payload
    );
};

const checkSecurityOptionOnClaimMoney = async (payload) => {
    return await api.post(`/receiver/payanyone/check`, payload);
    // return await apiMock.post(`/receiver/payanyone/check`, payload);
};

const verifySecurityClaimMoney = async ({ paoType, token, ...payload }) => {
    return await api.post(
        `/receiver/payanyone/${paoType}/secretcode`,
        payload,
        {
            headers: {
                token,
            },
            bypassErrorHandler: true,
        }
    );
};

const claimMoneyRequest = async ({ paoType, token, ...payload }) => {
    return await api.post(
        `/receiver/payanyone/${paoType}/bank/moveMoney`,
        payload,
        {
            headers: {
                token,
            },
            bypassErrorHandler: true,
        }
    );
};
const claimMoneyResendOtp = async ({ paoType, token, ...payload }) => {
    return await api.post(`/receiver/payanyone/${paoType}/resendOTP`, payload, {
        headers: {
            token,
        },
        bypassErrorHandler: true,
    });
};

const claimMoneyCommitOtp = async ({ paoType, token, ...payload }) => {
    return await api.post(
        `/receiver/payanyone/${paoType}/smsOtpCommit`,
        payload,
        {
            headers: {
                token,
            },
            bypassErrorHandler: true,
        }
    );
};

const getAccountInfoPayAnyone = async ({
    paoType,
    token,
    bankId,
    accountNumber,
}) => {
    return await api.get(
        `/receiver/payanyone/${paoType}/bank/${bankId}/${accountNumber}`,
        {},
        {
            headers: {
                token,
            },
            bypassErrorHandler: true,
        }
    );
};

const claimMoneySubmit = async ({ paoType, ...payload }) => {
    return await api.post(
        `/receiver/payanyone/${paoType}/bank/update/commit`,
        payload,
        {
            bypassErrorHandler: true,
        }
    );
};

const getAdsContentPayAnyone = async ({ verifyCode }) => {
    return await api.get(`/receiver/payanyone/PA/ads?verifyCode=${verifyCode}`);
};

const transferToCreditCard = async (payload) => {
    return await api.post("/user/txn/transfer/mastercard", payload);
};

const closeEarlyInstallment = async ({ txnId, ...payload }) => {
    return await api.post(
        `user/creditcard/installment/pay/${txnId}/apply`,
        payload
    );
};

const commitOtpCloseEarlyInstallment = async ({ txnId, ...payload }) => {
    return await api.post(
        `user/creditcard/installment/pay/${txnId}/commit`,
        payload
    );
};

const getStandardDescription = async (payload) => {
    return await api.post(`/user/txn/standardize-description`, payload, {
        bypassErrorHandler: true,
    });
};
const getAmountSuggestionListCreditCard = async () => {
    return await api.get(`user/txn/transfer/mastercard/suggest`);
};

const moveMoneyService = {
    getInternalAccountDetail,
    getAccountDetailByCardNumber,
    getAccountDetailByAccountNumber,
    getLimitations,
    transferToAccountNumber,
    transferToDebitCard,
    resendOTP,
    submitOTP,
    checkFastTransferStatus,
    checkPayeeDestinationExistence,
    getPhoneListForTopUp,
    getAmountListForTopUp,
    deletePhoneForTopUp,
    topUpRequest,
    passwordGenerator,
    transferByPhoneNumber,
    commitOtpTransferByPhoneNumber,
    checkSecurityOptionOnClaimMoney,
    verifySecurityClaimMoney,
    claimMoneyRequest,
    getAccountInfoPayAnyone,
    claimMoneySubmit,
    claimMoneyResendOtp,
    getAdsContentPayAnyone,
    transferToCreditCard,
    closeEarlyInstallment,
    commitOtpCloseEarlyInstallment,
    addDestination,
    addPayee,
    claimMoneyCommitOtp,
    getStandardDescription,
    getAccountDetailByCreditCardNumber,
    getAmountSuggestionListCreditCard,
};

export default moveMoneyService;
