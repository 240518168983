import { RequestDocumentTypes } from "@redux/actions/request-document";
import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { WealthActionType } from "../actions/wealth";

/* ------------- Initial State ------------- */
const initialState = {
    desc: "",
    requestId: "",
    requestDate: "",
    fromDate: "",
    toDate: "",
    transactionId: "",
    view: "",
    feeCharge: "",
    detailConfirmationType: "",
    accountList: "",
    cycleType: "",
    confirm_msg: "",
    data_to_post: "",
    selected_doc: "",
    submitted_time: "",
    bank_xid: "",
    totalAmount: "",
};

/* ------------- Reducer ------------- */
const requestDocumentReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case RequestDocumentTypes.SetDocumentInfo:
                const key = Object.keys(action.payload);
                key.forEach((item) => {
                    draft[item] = action.payload[item];
                });
                break;
            case RequestDocumentTypes.ResetDocumentInfo:
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            case WealthActionType.GetProductListError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default requestDocumentReducer;
