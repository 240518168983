import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";

import visaIcon from "@assets/images/visa_icon.png";

import warningImg from "@assets/images/warning.png";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { useCallback, useMemo } from "react";
import userService from "@services/user";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import icon from "@assets/images/no_hope_with_status.png";
import useAccounts from "@helpers/useAccounts";
import ReIssuedCard from "./ReIssuedCard";
import {
    CARD_MANAGEMENT_SUB_NAV,
    CARD_STATUSES,
    CARD_TYPES,
    CardManagementNavigation,
    PHYSICAL_CARD_STATUS,
    REISSUE_CARD_VERSION,
} from "./constant";

const useStyles = makeStyles((theme) => ({
    physicalWrapper: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    customButton: {
        width: "100%",
        padding: theme.spacing(1.5, 3),
        background: theme.palette.neutral.grey4,
        borderRadius: theme.shape.borderRadius,
    },
    visaIcon: {
        width: 24,
        marginRight: theme.spacing(0.5),
    },
    warningBox: {
        background: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    warningImg: {
        width: 48,
        height: 48,
        position: "absolute",
        top: -theme.spacing(2.25),
        left: theme.spacing(3),
    },
    warningContent: {
        padding: theme.spacing(6, 3, 3, 3),
    },
}));

const CardPhysicalButton = ({ card }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { spendAccount } = useAccounts();

    const history = useHistory();

    const physicalVirtualVDCardStatus = useMemo(
        () => card?.physicalVirtualVDCardStatus,
        [card]
    );

    console.log("=============================");
    console.log("card", card);

    const isShowPhysicalSection = useCallback(() => {
        const isShow =
            physicalVirtualVDCardStatus &&
            physicalVirtualVDCardStatus !== PHYSICAL_CARD_STATUS.CARD_OK;

        return (
            isShow &&
            (card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP)
        );
    }, [physicalVirtualVDCardStatus, card]);

    const requestedPhysicalCard = useMemo(
        () =>
            physicalVirtualVDCardStatus &&
            physicalVirtualVDCardStatus !== PHYSICAL_CARD_STATUS.NEW,
        [physicalVirtualVDCardStatus]
    );

    const disabledActivateButton = useMemo(() => {
        return (
            requestedPhysicalCard &&
            physicalVirtualVDCardStatus !==
                PHYSICAL_CARD_STATUS.WAIT_ACTIVE_PHYSICAL
        );
    }, [requestedPhysicalCard, physicalVirtualVDCardStatus]);

    const requestPhysicalCard = useCallback(async () => {
        const { cardId } = card;
        const response = await userService.reissueCheck();
        const isValidIDDocument =
            response?.data?.data?.isValidIDDocument || false;

        const tel = `<a href='tel: ${t("hangout:callcenter_number")}'>${t(
            "hangout:callcenter_number"
        )}</a>`;

        const desc = t(
            "virtualcard:reissued_virtual_error_ID_invalid_desc"
        ).replace("%@", tel);

        if (!isValidIDDocument) {
            const content = {
                banner: icon,
                title: t("td_lb_error_sorry"),
                description: desc,
                buttons: [
                    {
                        variant: "primary",
                        label: t("lb_btn_ok_got_it"),
                        onClick: () => GlobalDialogController.hide(),
                    },
                ],
            };

            GlobalDialogController.show({
                component: () => (
                    <ConditionNotificationDialog content={content} centerImg />
                ),
            });

            return;
        }

        let props = {};

        if (card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP) {
            props = {
                virtualAccountName: card?.virtualAccountName,
                virtualAccountId: card?.virtualAccountId,
            };
        } else {
            props = {
                accountNumber: spendAccount?.no,
            };
        }

        GlobalDialogController.show({
            size: "sm",
            component: () => (
                <ReIssuedCard
                    cardId={cardId}
                    isValidIDDocument={isValidIDDocument}
                    reissueVersion={
                        REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD
                    }
                    {...props}
                />
            ),
        });
    }, [card, t, spendAccount]);

    const activateCard = useCallback(() => {
        if (card.cardStatus === CARD_STATUSES.BLOCKED) {
            const content = {
                banner: icon,
                title: t("debitCard:phys_visa_active_popup_title"),
                description: t("debitCard:phys_visa_active_popup_desc"),
                buttons: [
                    {
                        variant: "primary",
                        label: t("debitCard:phys_visa_active_popup_CTA_ok"),
                        onClick: () => GlobalDialogController.hide(),
                    },
                ],
            };

            GlobalDialogController.show({
                component: () => (
                    <ConditionNotificationDialog content={content} centerImg />
                ),
            });
            return;
        }

        const type =
            card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD
                ? CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD
                : CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP;

        history.push({
            pathname: CardManagementNavigation.ActivateCard,
            search: `?type=${type}&coreCard=${card?.coreCard}&cardId=${card?.cardId}`,
        });
    }, [card, history, t]);

    return (
        <>
            {isShowPhysicalSection() && (
                <Box className={classes.physicalWrapper}>
                    {!requestedPhysicalCard && (
                        <ButtonBase
                            className={classes.customButton}
                            onClick={requestPhysicalCard}
                        >
                            <img
                                src={visaIcon}
                                alt="visa icon"
                                className={classes.visaIcon}
                            />
                            <LspTypography
                                variant="subheading4"
                                color="black"
                                uppercase
                            >
                                {t("debitCard:manage_card_phys_visa_CTA_apply")}
                            </LspTypography>
                        </ButtonBase>
                    )}
                    {requestedPhysicalCard && (
                        <>
                            <Box className={classes.warningBox}>
                                <img
                                    src={warningImg}
                                    alt="warning"
                                    className={classes.warningImg}
                                />
                                <Box className={classes.warningContent}>
                                    {t(
                                        "debitCard:manage_card_phys_visa_card_inactive"
                                    )}
                                </Box>
                            </Box>
                            <LspButton
                                variant="primary"
                                fullWidth
                                onClick={activateCard}
                                disabled={disabledActivateButton}
                            >
                                {t(
                                    "debitCard:manage_card_phys_visa_card_CTA_active"
                                )}
                            </LspButton>
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

const mapState = (state) => ({
    systemParams: state.systemParams.info.sysparams["1.0"],
});

export default connect(mapState)(CardPhysicalButton);
