import { call, put } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import vinaWealthAction from "@redux/actions/vina-wealth";

export function* getPendingTransactionSaga(action) {
    const response = yield call(
        wealthService.actionWealthSubProducts,
        action.payload
    );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                vinaWealthAction.getPendingTransactionSuccess(data.transactions)
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(vinaWealthAction.getPendingTransactionError());
            break;
    }
}

export function* getCompletedTransactionSaga(action) {
    const response = yield call(
        wealthService.actionWealthSubProducts,
        action.payload
    );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                vinaWealthAction.getCompletedTransactionSuccess(
                    data.transactions
                )
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(vinaWealthAction.getCompletedTransactionError());
            break;
    }
}
