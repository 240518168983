import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTextField from "@components/LspTextField";
import LspCheckbox from "@components/LspCheckbox";
import { REGEX_PATTERN } from "@config/constants";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { Box, Icon, InputAdornment, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: 20,
        cursor: "default",
    },
    samplePic: {
        position: "relative",
        "& img": {
            width: "100%",
            maxWidth: 300,
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(-4.5),
            zIndex: 1,
        },
    },
    fetching: {
        "& span": {
            margin: "auto",
        },
    },
}));

const maxLengthCustomerIdInputDefault = 18;
const maxLengthExtraCodeInputDefault = 30;

const NewBillCustomerInput = ({
    fetching,
    formData,
    onCancel,
    onNext,
    fetchingDetail,
    errors,
    setErrors,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const [hoverCustomerSample, setHoverCustomerSample] = useState(false);
    const [hoverExtraCodeSample, setHoverExtraCodeSample] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const [extraCode, setExtraCode] = useState("");
    const [isSaveBill, setIsSaveBill] = useState(false);

    // console.log("formData", formData);

    const fetchingLayer = useMemo(() => {
        return (
            <Box className={classes.fetching}>
                {[...new Array(6)]?.map((_, index) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Skeleton width="80%" key={index} />
                    );
                })}
            </Box>
        );
    }, [classes.fetching]);

    const getInputMaxLength = useCallback(
        (fieldName) => {
            const fieldInfo = formData?.find(
                (item) => item?.fieldName?.toLowerCase() === fieldName
            );
            return fieldInfo?.length || maxLengthCustomerIdInputDefault;
        },
        [formData]
    );

    const getSamplePicById = useCallback(
        (fieldName) => {
            const fieldInfo = formData?.find(
                (item) => item?.fieldName?.toLowerCase() === fieldName
            );
            return fieldInfo?.url || "";
        },
        [formData]
    );

    const samplePicCustomer = useMemo(() => {
        const url = getSamplePicById("customerid");
        return url ? (
            <Box className={classes.samplePic}>
                <img src={url} alt="Customer ID sample" />
            </Box>
        ) : null;
    }, [getSamplePicById, classes.samplePic]);

    const samplePicExtraCode = useMemo(() => {
        const url = getSamplePicById("extracode");
        return url ? (
            <Box className={classes.samplePic}>
                <img src={url} alt="Extra code sample" />
            </Box>
        ) : null;
    }, [getSamplePicById, classes.samplePic]);

    const onChangeCustomerId = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.BILL_CUSTOMER_ID_REPLACEMENT,
            text: e.target.value,
        });
        setCustomerId(value);

        if (value === "") {
            setErrors((prev) => ({
                ...prev,
                customerId: t("msg_we_need_this"),
            }));
        } else if (errors?.customerId !== "") {
            setErrors((prev) => ({
                ...prev,
                customerId: "",
            }));
        }
    };

    const toggleCustomerIdMouseEvent = () => {
        setHoverCustomerSample((prev) => !prev);
    };

    const toggleExtraCodeMouseEvent = () => {
        setHoverExtraCodeSample((prev) => !prev);
    };

    // Extra code

    const extraCodeForm = useMemo(() => {
        return formData?.find(
            (item) => item?.fieldName?.toLowerCase() === "extracode"
        );
    }, [formData]);

    const onChangeExtraCode = (e) => {
        setExtraCode(e.target.value);
        if (e.target.value === "") {
            setErrors((prev) => ({
                ...prev,
                extraCode: t("msg_we_need_this"),
            }));
        } else if (errors?.extraCode !== "") {
            setErrors((prev) => ({
                ...prev,
                extraCode: "",
            }));
        }
    };

    const onChangeSaveBill = () => {
        setIsSaveBill((prev) => !prev);
    };

    const getCustomerFormData = useCallback(() => {
        const form = formData?.find(
            (item) => item?.fieldName?.toLowerCase() === "customerid"
        );

        return { ...form, value: customerId };
    }, [formData, customerId]);

    const getExtraCodeFormData = useCallback(() => {
        const form = formData?.find(
            (item) => item?.fieldName?.toLowerCase() === "extracode"
        );

        return { ...form, value: extraCode };
    }, [formData, extraCode]);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();

            const customerData = getCustomerFormData();
            // eslint-disable-next-line prefer-const
            let formPayload = [customerData];
            if (extraCodeForm) {
                const extraCodeData = getExtraCodeFormData();
                formPayload.push(extraCodeData);
            }
            const payload = {
                saveBill: isSaveBill,
                formData: formPayload,
            };
            onNext(payload);
        },
        [
            getCustomerFormData,
            getExtraCodeFormData,
            onNext,
            extraCodeForm,
            isSaveBill,
        ]
    );

    const buttons = useMemo(
        () => [
            {
                label: t("bill_butt_get_bill"),
                onClick: onSubmit,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ],
        [onSubmit, onCancel, t]
    );

    return (
        <Content size="sm">
            <form onSubmit={onSubmit}>
                <Box className={classes.wrapper}>
                    {fetching && fetchingLayer}
                    {!fetching && (
                        <Box width="100%">
                            <Box>
                                <LspTextField
                                    fullWidth
                                    autoFocus
                                    error={!!errors?.customerId}
                                    helperText={errors?.customerId}
                                    name="customerId"
                                    label={t("bill_lb_customer_id")}
                                    inputProps={{
                                        type: "text",
                                        maxLength: getInputMaxLength(
                                            "customerid"
                                        ),
                                        disabled: fetchingDetail,
                                    }}
                                    onChange={onChangeCustomerId}
                                    value={customerId}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon
                                                    className={`font-icon icon-help ${classes.icon}`}
                                                    onMouseEnter={
                                                        toggleCustomerIdMouseEvent
                                                    }
                                                    onMouseLeave={
                                                        toggleCustomerIdMouseEvent
                                                    }
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {hoverCustomerSample && samplePicCustomer}
                            </Box>
                            {extraCodeForm && (
                                <Box>
                                    <LspTextField
                                        fullWidth
                                        autoFocus
                                        error={!!errors?.extraCode}
                                        helperText={errors?.extraCode}
                                        name="extraCode"
                                        label={t("bill_lb_extra_code")}
                                        inputProps={{
                                            type: "text",
                                            maxLength:
                                                extraCodeForm?.length ||
                                                maxLengthExtraCodeInputDefault,
                                            disabled: fetchingDetail,
                                        }}
                                        onChange={onChangeExtraCode}
                                        value={extraCode}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon
                                                        className={`font-icon icon-help ${classes.icon}`}
                                                        onMouseEnter={
                                                            toggleExtraCodeMouseEvent
                                                        }
                                                        onMouseLeave={
                                                            toggleExtraCodeMouseEvent
                                                        }
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {hoverExtraCodeSample && samplePicExtraCode}
                                </Box>
                            )}

                            <Box>
                                <LspCheckbox
                                    color="primary"
                                    onChange={onChangeSaveBill}
                                    checked={isSaveBill}
                                />
                                {t("bill_lb_save_to_bill_list")}
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box mt={3}>
                    <DoubleButtons
                        primaryButton={buttons[0]}
                        secondaryButton={buttons[1]}
                        progressing={fetchingDetail}
                        disabled={fetching}
                    />
                </Box>
            </form>
        </Content>
    );
};

export default memo(NewBillCustomerInput);
