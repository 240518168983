import { FetchStatus } from "@config/constants";
import { TERM_DEPOSIT } from "@redux/actions/term-deposit";
import produce from "immer";

/* ------------- Initial State ------------- */
const initialState = {
    termRates: {
        data: {},
        fetching: false,
        status: null,
    },
    tdDetail: {
        data: {},
        fetching: false,
        status: null,
    },

    validationTD: {
        data: {},
        fetching: false,
        status: null,
        tdId: null,
    },

    previewTD: {
        data: {},
        fetching: false,
        status: null,
    },

    partialTD: {
        data: {},
        fetching: false,
        status: null,
    },
    earlyRedemptionTD: {
        data: {},
        fetching: false,
        status: null,
        tdId: "",
    },

    autoDeposit: {
        isExisted: false,
        fetching: false,
        status: null,
    },
    autoDepositList: {
        fetching: false,
        status: null,
        data: [],
    },
};

/* ------------- Reducer ------------- */
const ternReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case TERM_DEPOSIT.GetTermDepositRates:
                draft.termRates.fetching = true;
                draft.termRates.status = FetchStatus.Pending;
                break;
            case TERM_DEPOSIT.GetTermDepositRatesSuccess:
                draft.termRates.fetching = false;
                draft.termRates.status = FetchStatus.Success;
                const rates = action.payload;
                const isAvailableTdRate = (rates.items || []).some(
                    (item) => item.interestRateSpecial != null
                );
                const result = {
                    ...rates,
                    isAvailableTdRate,
                };
                draft.termRates.data = result;

                break;
            case TERM_DEPOSIT.GetTermDepositRatesErrors:
                draft.termRates.fetching = false;
                draft.termRates.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.GetTermDepositDetail:
                draft.tdDetail.fetching = true;
                draft.tdDetail.status = FetchStatus.Pending;
                break;

            case TERM_DEPOSIT.GetTermDepositDetailSuccess:
                draft.tdDetail.fetching = false;
                draft.tdDetail.status = FetchStatus.Success;
                draft.tdDetail.data = action.payload;
                break;

            case TERM_DEPOSIT.GetTermDepositDetailErrors:
                draft.tdDetail.fetching = false;
                draft.tdDetail.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.ValidateSpecialRateRequest:
                draft.validationTD.fetching = true;
                draft.validationTD.status = FetchStatus.Pending;
                break;

            case TERM_DEPOSIT.ValidateSpecialRateSuccess:
                draft.validationTD.fetching = false;
                draft.validationTD.status = FetchStatus.Success;
                draft.validationTD.tdId = action.payload?.tdId;
                break;

            case TERM_DEPOSIT.ValidateSpecialRateError:
                draft.validationTD.fetching = false;
                draft.validationTD.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.ClearValidateSpecialRateError:
                draft.validationTD.fetching = false;
                draft.validationTD.status = null;

                draft.previewTD.fetching = false;
                draft.previewTD.status = null;

                draft.partialTD.fetching = false;
                draft.partialTD.status = null;
                draft.partialTD.data = {};
                break;

            case TERM_DEPOSIT.PreviewSpecialRateRequest:
                draft.previewTD.fetching = true;
                draft.previewTD.status = FetchStatus.Pending;
                break;

            case TERM_DEPOSIT.PreviewSpecialRateSuccess:
                draft.previewTD.fetching = false;
                draft.previewTD.status = FetchStatus.Success;
                draft.previewTD.data = action.payload;
                break;

            case TERM_DEPOSIT.PreviewSpecialRateError:
                draft.previewTD.fetching = false;
                draft.previewTD.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.PartialTDRequest:
                draft.partialTD.fetching = true;
                draft.partialTD.status = FetchStatus.Pending;
                break;

            case TERM_DEPOSIT.PartialTDSuccess:
                draft.partialTD.fetching = false;
                draft.partialTD.status = FetchStatus.Success;
                draft.partialTD.data = action.payload;
                break;

            case TERM_DEPOSIT.PartialTDErrors:
                draft.partialTD.fetching = false;
                draft.partialTD.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.EarlyRedeemTDRequest:
                draft.earlyRedemptionTD.fetching = true;
                draft.earlyRedemptionTD.status = FetchStatus.Pending;
                draft.earlyRedemptionTD.tdId = action.payload.termDepositId;
                break;

            case TERM_DEPOSIT.EarlyRedeemTDSuccess:
                draft.earlyRedemptionTD.fetching = false;
                draft.earlyRedemptionTD.status = FetchStatus.Success;
                draft.earlyRedemptionTD.data = action.payload;

                break;

            case TERM_DEPOSIT.EarlyRedeemTDErrors:
                draft.earlyRedemptionTD.fetching = false;
                draft.earlyRedemptionTD.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.CheckAutoDeposit:
                draft.autoDeposit.fetching = false;
                draft.autoDeposit.status = FetchStatus.Pending;
                break;
            case TERM_DEPOSIT.CheckAutoDepositSuccess:
                draft.autoDeposit.fetching = false;
                draft.autoDeposit.status = FetchStatus.Success;
                draft.autoDeposit.isExisted = action.payload;
                break;
            case TERM_DEPOSIT.CheckAutoDepositError:
                draft.autoDeposit.fetching = false;
                draft.autoDeposit.status = FetchStatus.Error;
                break;

            case TERM_DEPOSIT.GetAutoDepositList:
                draft.autoDepositList.fetching = false;
                draft.autoDepositList.status = FetchStatus.Pending;
                break;
            case TERM_DEPOSIT.GetAutoDepositListSuccess:
                draft.autoDepositList.fetching = false;
                draft.autoDepositList.status = FetchStatus.Success;
                draft.autoDepositList.data = action.payload;
                break;
            case TERM_DEPOSIT.GetAutoDepositListError:
                draft.autoDepositList.fetching = false;
                draft.autoDepositList.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default ternReducer;
