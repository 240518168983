import { useState, useCallback, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Paper } from "@material-ui/core";
import LspTextField from "@components/LspTextField";
import LspButton from "@components/LspButton";
import LspCongratulation from "@components/LspCongratulation";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import { ArrowRightAlt } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import accountService from "@services/account";
import { connect } from "react-redux";
import GoalSaveItem from "../GoalSaveGallery/GoalSaveItem";
import SpendItem from "../Common/SpendItem";

const useStyles = makeStyles((theme) => ({
    item: {
        backgroundColor: theme.palette.neutral.white,
        boxShadow: theme.shadows[2],
        border: "none",
        borderRadius: "5px",
        overflow: "hidden",
        padding: 0,
        cursor: "pointer",
        maxWidth: "200px",
        width: "130px",
        height: "130px",
        display: "block",
        textAlign: "center",
        margin: theme.spacing(0, 0, 3, 5),
        "&:hover ": {
            "& $italicTxt": {
                visibility: "visible",
                opacity: 1,
            },
        },
    },
    content: {
        height: "142px",
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        position: "relative",
        textAlign: "center",
    },
    icon: {
        fontSize: theme.typography.pxToRem(100),
        color: theme.palette.neutral.white,
        opacity: 0.4,
        display: "inline-block",
        paddingTop: theme.spacing(3),
        height: "100%",
    },
    onTopImg: {
        position: "absolute",
        width: "100%",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "0 auto",
        "& > svg": {
            width: "60px",
        },
        padding: theme.spacing(2),
    },

    italicTxt: {
        fontStyle: "italic",
        "& > span": {
            fontStyle: "initial",
        },
        opacity: 0,
        visibility: "hidden",
        transition: "opacity 600ms, visibility 600ms",
    },
    title: {
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    spendAccount: {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        height: "200px",
        margin: theme.spacing(0, 0, 2.5, 0),
        display: "block",
        borderRadius: "5px",
        color: theme.palette.neutral.white,
        overflow: "hidden",
        textAlign: "center",
        "& > span": {
            overflow: "unset",
            display: "inline-block",
            paddingTop: theme.spacing(5),
        },
        "& > p": {
            marginTop: theme.spacing(1),
        },
    },

    piggy: {
        background: theme.palette.neutral.white,
        height: 80,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(3),
    },

    transfer: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(4),

        "& > div": {
            margin: theme.spacing(0),
        },
    },

    container: {
        position: "relative",
        padding: theme.spacing(6),
        textAlign: "center",
    },
    closeIcon: {
        position: "absolute",
        top: "20px",
        right: "20px",
        cursor: "pointer",
    },
}));

const GoalSaveTransfer = ({
    goalSaveTo,
    goalSaveFrom,
    systemParams,
    resetState,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [contributeSuccess, setContributeSuccess] = useState(false);
    const { thousandSeparator, decimalSeparator, formatNumber } = useNumber();
    const [errAmount, setErrAmount] = useState("");
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const validateAmount = (amount) => {
        const maxLimitInternal = systemParams.MaxAmountLimitPerTransfer;
        const minLimitTransfer = systemParams.MinAmountLimitPerTransfer;

        if (
            goalSaveFrom.accountType === "spend" ||
            goalSaveTo.accountType === "spend"
        ) {
            let maxAmount = 0;

            if (goalSaveFrom.accountAmount) {
                maxAmount = parseFloat(goalSaveFrom.accountAmount);
            } else {
                maxAmount = goalSaveFrom.contributedAmount
                    ? parseFloat(goalSaveFrom.contributedAmount)
                    : 0;
            }

            if (maxAmount < 1000) {
                setErrAmount(
                    t("spend_msg_for_least_amount").replace(
                        "%@",
                        formatNumber(minLimitTransfer)
                    )
                );
            } else if (amount < 1000) {
                setErrAmount(
                    t("loan_vpbank_amount_required_min").replace(
                        "%@",
                        formatNumber(minLimitTransfer)
                    )
                );
            } else if (maxAmount < amount) {
                setErrAmount(
                    `${`${t("payee_card_amount_from")} `}${formatNumber(
                        minLimitTransfer
                    )}${` ${t("moveMoney_label_amount_and")} `}${formatNumber(
                        maxAmount
                    )}`
                );
            } else if (maxAmount > maxLimitInternal) {
                setErrAmount(
                    `${`${t("payee_card_amount_from")} `}${`${formatNumber(
                        minLimitTransfer
                    )} `}${t("moveMoney_label_amount_and")}${formatNumber(
                        maxLimitInternal
                    )}`
                );
            }
        } else if (amount > goalSaveFrom.contributedAmount || amount < 1) {
            setErrAmount(
                `${`${t("payee_card_amount_from")} `}1${` ${t(
                    "moveMoney_label_amount_and"
                )} `}${formatNumber(goalSaveFrom.contributedAmount)}`
            );
        } else if (amount <= 0) {
            setErrAmount(t("gs_transfer_minimum_goal_to_goal"));
        }
    };

    const titleSuccess = useCallback(
        (amount) => {
            let str = t("gs_contribute_success_msg");
            str = str.replace("%@", amount);
            if (
                goalSaveFrom.accountType === "spend" ||
                goalSaveFrom.accountType === "piggy"
            ) {
                str = str.replace("%@", goalSaveFrom.accountName);
            } else {
                str = str.replace("%@", goalSaveFrom.planName);
            }
            if (
                goalSaveTo.accountType === "spend" ||
                goalSaveTo.accountType === "piggy"
            ) {
                str = str.replace("%@", goalSaveTo.accountName);
            } else {
                str = str.replace("%@", goalSaveTo.planName);
            }
            return str;
        },
        [goalSaveFrom, t, goalSaveTo]
    );

    const contributeGoal = async (value) => {
        if (parseInt(value.amount) < 1) {
            validateAmount(parseInt(value.amount));
            return;
        }
        const dataToPost = {
            destinationPlanId: goalSaveTo.planId,
            sourceGoalPlanId: goalSaveFrom.planId,
            transferAmount: parseInt(value.amount?.replace(/[.,]/g, "")),
        };
        const { data } = await accountService.contributeGoalSave(dataToPost);
        if (unmounted.current) return;
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setContributeSuccess(titleSuccess(value.amount));
                break;
            case RESPONSE_CODE.FEATURE_UNAVAILABLE:
            case RESPONSE_CODE.SPEND_NO_ENOUGH_MONEY:
            case RESPONSE_CODE.CANT_TRANSFER_TO_BANK:
                validateAmount(parseInt(value.amount?.replace(/[.,]/g, "")));
                break;
            case RESPONSE_CODE.DAILY_LIMIT_GS:
                setErrAmount(t("ms_lb_transfer_daily_limit"));
                break;
            case RESPONSE_CODE.FAST_CASH_REACH_LIMIT: // not using anymore
                resetState();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("fast_cash_title_error_limit_reached"),
                        boldContent: t(
                            "fast_cash_sub_title_error_limit_reached"
                        ),
                        content: t("fast_cash_msg_error_limit_reached"),
                        button: t("fast_cash_button_error_limit_reached"),
                    },
                });
                break;
            case RESPONSE_CODE.FAST_CASH_NOT_ALLOW:
                // update base on spi_2775: not allow using FC to transfer to goal
                validateAmount(parseInt(value.amount?.replace(/[.,]/g, "")));
                // resetState();
                // GlobalDialogController.showCustomDialog({
                //     dialogInfo: {
                //         iconImage: "Warning",
                //         header: t("fast_cash_title_error_transfer_not_allowed"),
                //         content: t("fast_cash_msg_error_transfer_not_allowed"),
                //         highlight: t(
                //             "fast_cash_more_information_error_transfer_not_allowed"
                //         ),
                //         button: t("lb_ok"),
                //     },
                // });
                break;
            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const itemTransfer = useCallback(
        (item) => {
            if (item.accountType === "spend") {
                return (
                    <div className={classes.spendAccount}>
                        <SpendItem item={item} />
                    </div>
                );
            }
            if (item.accountType === "piggy") {
                return (
                    <div className={classes.spendAccount}>
                        <Icon className="font-icon icon-goalsave" />
                        <LspTypography variant="heading2" color="white">
                            {formatNumber(item?.contributedAmount)}
                        </LspTypography>
                        <LspTypography
                            variant="body2"
                            color="grey"
                            className={classes.piggy}
                            component="div"
                        >
                            {t("goal_save_account_piggy_title")}
                        </LspTypography>
                    </div>
                );
            }
            return <GoalSaveItem goalSaveItem={item} isTransfer />;
        },
        [classes.spendAccount, formatNumber, classes.piggy, t]
    );
    const {
        handleSubmit,
        control,
        formState: { isValid, isSubmitting },
    } = useForm({
        mode: "onChange",
    });
    return (
        <>
            {contributeSuccess && (
                <LspCongratulation
                    icon="ok"
                    title={t("gs_contribute_success_title")}
                    contents={contributeSuccess}
                    button={{
                        label: t("lb_done"),
                        onClick: () => resetState(true),
                    }}
                />
            )}
            {!contributeSuccess && (
                <form
                    onSubmit={handleSubmit(contributeGoal)}
                    style={{
                        width: "100%",
                    }}
                >
                    <Paper className={classes.container}>
                        <LspTypography
                            variant="body1"
                            color="grey"
                            className={classes.closeIcon}
                            onClick={() => resetState(false)}
                        >
                            <Icon className="font-icon icon-close " />
                        </LspTypography>
                        <LspTypography variant="body1" color="grey">
                            {t("gs_transfer_title_transfer_from")}
                            <LspTypography
                                variant="heading2"
                                color="grey"
                                component="span"
                            >
                                {` ${
                                    goalSaveFrom.planName ||
                                    goalSaveFrom.accountName
                                } `}
                            </LspTypography>
                            <LspTypography
                                variant="body1"
                                color="grey"
                                component="span"
                            >
                                {`${t("lb_to")} `}
                            </LspTypography>
                            <LspTypography
                                variant="heading2"
                                color="grey"
                                component="span"
                            >
                                {goalSaveTo.planName || goalSaveTo.accountName}
                            </LspTypography>
                        </LspTypography>
                        <div className={classes.transfer}>
                            {itemTransfer(goalSaveFrom)}
                            <ArrowRightAlt />
                            {itemTransfer(goalSaveTo)}
                        </div>
                        <Controller
                            as={
                                <NumberFormat
                                    customInput={LspTextField}
                                    autoComplete="off"
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    label={t("bill_amount")}
                                    allowLeadingZeros={false}
                                    allowNegative={false}
                                    allowedDecimalSeparators={false}
                                    value={null}
                                    inputProps={{
                                        maxLength: 19,
                                        type: "text",
                                    }}
                                    onValueChange={() => setErrAmount("")}
                                />
                            }
                            error={!!errAmount}
                            helperText={errAmount}
                            name="amount"
                            variant="outlined"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={null}
                        />
                        <LspButton
                            type="submit"
                            disabled={!isValid}
                            progressing={isSubmitting}
                        >
                            {t("gs_transfer_btn")}
                        </LspButton>
                    </Paper>
                </form>
            )}
        </>
    );
};

const stateProps = (state) => ({
    userInfo: state.user.info,
    systemParams: state.systemParams.info.conf,
});

export default connect(stateProps, null)(GoalSaveTransfer);
