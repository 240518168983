import introNickname from "@assets/images/intro_nickname.png";
import { makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";

import { isFunction } from "lodash";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { connect } from "react-redux";

import useNumber from "@helpers/useNumber";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: 350,
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[2],
        padding: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(5),
    },
    mainImg: {
        marginBottom: theme.spacing(3),
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    header: {
        textAlign: "center",
        marginBottom: theme.spacing(1),
    },
    body: {
        textAlign: "center",
        color: theme.palette.magicalGrey[400],
        marginBottom: theme.spacing(3),
    },
    priceBox: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[1],
        boxShadow: theme.shadows[2],
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    action: {},
}));

const IntroNickname = ({ onDirect, aliasFreeFetching, aliasFree }) => {
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleDirect = () => {
        if (isFunction(onDirect)) {
            onDirect();
        }
        GlobalDialogController.hide();
    };

    const handleClose = () => {
        GlobalDialogController.hide();
    };

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.container}>
                    <div className={classes.mainImg}>
                        <img src={introNickname} alt="Nickname" />
                    </div>
                    <LspTypography className={classes.header} variant="title2">
                        {t("IBFT:IBFT_nickname_intro_title")}
                    </LspTypography>
                    <LspTypography className={classes.body} variant="body2">
                        {t("IBFT:IBFT_nickname_intro_desc")}
                    </LspTypography>
                    <div className={classes.priceBox}>
                        <LspTypography variant="body2" color="grey">
                            {t("IBFT:IBFT_nickname_intro_fee")}
                        </LspTypography>
                        {aliasFreeFetching && (
                            <Skeleton
                                animation="wave"
                                variant="text"
                                width="50%"
                                height={32}
                            />
                        )}
                        {!aliasFreeFetching && (
                            <LspTypography variant="heading1">
                                {formatNumber(aliasFree?.feeAmount)}
                            </LspTypography>
                        )}
                        <LspTypography variant="body2" color="grey">
                            {t("IBFT:IBFT_nickname_intro_fee_other")}
                        </LspTypography>
                    </div>
                </div>
                <div className={classes.action}>
                    <LspButton
                        fullWidth
                        onClick={() => handleDirect()}
                        disabled={aliasFreeFetching}
                    >
                        {t("IBFT:IBFT_nickname_intro_btn_create")}
                    </LspButton>
                    <LspButton
                        variant="plain"
                        fullWidth
                        onClick={() => handleClose()}
                    >
                        {t("lb_close")}
                    </LspButton>
                </div>
            </div>
        </>
    );
};
const mapState = (state) => ({
    aliasFree: state.user.aliasFree?.data,
    aliasFreeStatus: state.user.aliasFree?.status,
    aliasFreeFetching: state.user.aliasFree?.fetching,
});

export default connect(mapState, null)(IntroNickname);
