import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Avatar, makeStyles } from "@material-ui/core";
import api from "@config/api";
import defaultAvatar from "@assets/images/default_avatar.svg";
import PropTypes from "prop-types";
import { LogoMark } from "./LspBranding";

const useStyles = makeStyles((theme) => ({
    avatarWrapper: {
        position: "relative",
    },
    avatar: {
        width: theme.typography.pxToRem(48),
        height: theme.typography.pxToRem(48),
    },
    icon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.neutral.white,
        boxShadow: theme.shadows[1],
        borderRadius: "50%",
        position: "absolute",
        right: theme.typography.pxToRem(-3),
        bottom: theme.typography.pxToRem(-3),
        "& .MuiIcon-root": {
            fontSize: theme.typography.pxToRem(24),
            color: theme.palette.primary.main,
        },
    },
}));

/**
 * @param {object} props
 * @param {string} props.avatar
 * @param {boolean} props.hasIcon
 * @param {string} props.size
 * @param {string} props.defaultAvatarInput
 * @param {string} props.variant
 */

const defaultSize = 50;
const defaultIconSize = 26;

const LspAvatar = ({
    avatar,
    hasIcon,
    size,
    avatarIconSize,
    defaultAvatarInput,
    variant,
    isBase64,
}) => {
    const unmounted = useRef(false);
    const [avatarImg, setAvatarImg] = useState(null);
    const classes = useStyles();
    const borderRadius = useMemo(() => (variant === "circle" ? "50%" : "8px"), [
        variant,
    ]);

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    useEffect(() => {
        if (!avatar) {
            return;
        }
        if (isBase64) {
            setAvatarImg(avatar);
            return;
        }

        api.get(avatar, null, {
            responseType: "arraybuffer",
            bypassErrorHandler: true,
        }).then((response) => {
            if (response.ok && !unmounted.current) {
                const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ""
                    )
                );
                setAvatarImg(`data:image/png;base64, ${base64}`);
            }
        });
    }, [avatar, isBase64]);

    return (
        <div className={classes.avatarWrapper} style={{ width: size }}>
            <Avatar
                style={{
                    width: size,
                    height: size,
                    borderRadius,
                }}
                src={avatarImg || defaultAvatarInput || defaultAvatar}
                alt="avatar"
            />
            {hasIcon && (
                <div
                    style={{
                        width: avatarIconSize,
                        height: avatarIconSize,
                    }}
                    className={classes.icon}
                >
                    <LogoMark variant="filled" />
                </div>
            )}
        </div>
    );
};

LspAvatar.propTypes = {
    hasIcon: PropTypes.bool,
    avatar: PropTypes.string,
    size: PropTypes.number,
    defaultAvatarInput: PropTypes.string,
    variant: PropTypes.string,
    avatarIconSize: PropTypes.number,
};

LspAvatar.defaultProps = {
    hasIcon: false,
    avatar: null,
    size: defaultSize,
    avatarIconSize: defaultIconSize,
    defaultAvatarInput: null,
    variant: "rect",
};

export default memo(LspAvatar);
