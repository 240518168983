import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon, Paper } from "@material-ui/core";
import { memo, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import LspTextField from "@components/LspTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import { add, isAfter } from "date-fns";
import LspButton from "@components/LspButton";
import parse from "date-fns/parse";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import format from "date-fns/format";
import LspHintInfo from "@components/LspHintInfo";
import PageHeaderBack from "@components/PageHeaderBack";

const MAX_DATE_FROM_TODAY = 90;

const WealthTravelTimeRangeForm = ({
    onBack,
    onNext,
    today,
    user,
    setTravelDate,
    travelDate,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();

    const maxDate = add(today, { days: MAX_DATE_FROM_TODAY });

    const [fromDate, setFromDate] = useState(
        () =>
            travelDate?.fromDate
                ? parse(travelDate?.fromDate, "dd/MM/yyyy", new Date())
                : null,
        [travelDate?.fromDate]
    );

    const [toDate, setToDate] = useState(
        () =>
            travelDate?.toDate
                ? parse(travelDate?.toDate, "dd/MM/yyyy", new Date())
                : null,
        [travelDate?.toDate]
    );

    const [errors, setErrors] = useState({
        fromDate: "",
        toDate: "",
    });

    const onChangeFromDate = (d) => {
        if (!d) {
            setFromDate(null);
            setErrors((prev) => ({
                ...prev,
                fromDate: t("wealth:mc_v3_error_we_need"),
            }));
            return;
        }

        const result = isAfter(d, maxDate);
        if (result) {
            setFromDate(null);
            setErrors((prev) => ({
                ...prev,
                fromDate: t("wealth_travel_error_start_date_max").replace(
                    "%@",
                    maxDate
                ),
            }));
            return;
        }

        setErrors((prev) => ({
            ...prev,
            fromDate: "",
        }));
        setFromDate(d);
    };

    const onChangeToDate = (d) => {
        if (!d) {
            setToDate(null);
            setErrors((prev) => ({
                ...prev,
                toDate: t("wealth:mc_v3_error_we_need"),
            }));
            return;
        }

        const result = isAfter(d, maxDate);
        if (result) {
            setFromDate(null);
            setErrors((prev) => ({
                ...prev,
                toDate: t("wealth_travel_error_start_date_max").replace(
                    "%@",
                    maxDate
                ),
            }));
            return;
        }

        setErrors((prev) => ({
            ...prev,
            toDate: "",
        }));
        setToDate(d);
    };

    const formValidation = (e) => {
        e.preventDefault();
        const isForeigner = user?.isForeigner;
        // const visaExpired = user?.visaExpired;

        if (isForeigner) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Error",
                    header: t("wealth:lbi_foreigner_popup_title"),
                    translatedContent: t("wealth:lbi_foreigner_popup_message"),
                    button: t("lb_ok"),
                },
            });
            // if (
            //     visaExpired &&
            //     isAfter(fromDate, parse(visaExpired, "dd/MM/yyyy", new Date()))
            // ) {
            //     GlobalDialogController.showCustomDialog({
            //         dialogInfo: {
            //             iconImage: "Error",
            //             header: t("wealth:lbi_foreigner_popup_title"),
            //             translatedContent: t(
            //                 "wealth:lbi_foreigner_popup_message"
            //             ),
            //             button: t("lb_ok"),
            //         },
            //     });
            // }
            return;
        }

        setTravelDate({
            fromDate: format(fromDate, "dd/MM/yyyy"),
            toDate: format(toDate, "dd/MM/yyyy"),
        });

        onNext();
    };

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-travelinsurance" />
                <LspTypography>
                    {t("wealth:lbi_travel_time_to_travel_title")}
                </LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <form onSubmit={formValidation}>
                    <Paper className={sharedClasses.box}>
                        <KeyboardDatePicker
                            autoOk
                            disablePast
                            format="dd/MM/yyyy"
                            variant="inline"
                            onChange={onChangeFromDate}
                            disableToolbar
                            value={fromDate}
                            TextFieldComponent={(params) => {
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t("wealth:GE00125")}
                                        error={!!errors?.fromDate}
                                        helperText={errors?.fromDate || " "}
                                    />
                                );
                            }}
                        />
                        <KeyboardDatePicker
                            autoOk
                            disablePast
                            format="dd/MM/yyyy"
                            variant="inline"
                            onChange={onChangeToDate}
                            disableToolbar
                            value={toDate}
                            minDate={fromDate}
                            disabled={!fromDate}
                            TextFieldComponent={(params) => {
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t("wealth:GE00126")}
                                        error={!!errors?.toDate}
                                        helperText={errors?.toDate || " "}
                                    />
                                );
                            }}
                        />
                    </Paper>

                    <LspHintInfo
                        content={t("wealth:lbi_travel_tooltip_when")}
                        icon="hint"
                    />

                    <LspButton
                        type="submit"
                        fullWidth
                        disabled={!fromDate || !toDate}
                        className={sharedClasses.button}
                    >
                        {t("lb_next")}
                    </LspButton>
                </form>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    today: state.user.serverTime.value,
    user: state.user.info,
    travelDate: state.insurance.travelDate,
});

const mapDispatch = (dispatch) => ({
    setTravelDate: (payload) =>
        dispatch(wealthInsuranceAction.setTravelDate(payload)),
});

export default memo(connect(mapState, mapDispatch)(WealthTravelTimeRangeForm));
