import { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Box, Paper } from "@material-ui/core";
import userAction from "@redux/actions/user";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import Payee from "./Payee";
import PayeeListLoading from "./PayeeListLoading";
import PayeeListSearchInput from "./PayeeListSearchInput";

const PayeeList = ({
    payeeList,
    getPayeeList,
    fetchingPayeeList,
    minimizePayeeList,
}) => {
    const [filteredPayeeList, setFilteredPayeeList] = useState(payeeList);

    const debounceRef = useRef(null);

    const location = useLocation();
    const params = querystring.parse(location.search);
    const openedPayeeId = params?.payeeId || null;

    useEffect(() => {
        debounceRef.current = setTimeout(() => {
            getPayeeList();
        }, 300);
        return () => {
            minimizePayeeList();
            clearTimeout(debounceRef.current);
        };
    }, [getPayeeList, minimizePayeeList]);

    useEffect(() => {
        setFilteredPayeeList(payeeList);
    }, [payeeList]);

    const filter = useCallback(
        (term) => {
            if (!payeeList) {
                return;
            }
            if (!term) {
                setFilteredPayeeList(payeeList);
                return;
            }
            setFilteredPayeeList(
                payeeList.map((payee) => ({
                    ...payee,
                    invisible: !(
                        payee.fullName
                            ?.toLowerCase()
                            .includes(term.toLowerCase()) ||
                        payee.nickName
                            ?.toLowerCase()
                            .includes(term.toLowerCase())
                    ),
                }))
            );
        },
        [payeeList]
    );

    return (
        <>
            <Paper>
                <Box paddingY={1.5} paddingX={2} textAlign="right">
                    <PayeeListSearchInput filter={filter} />
                </Box>
            </Paper>
            {filteredPayeeList?.map((payee, index) => (
                <Payee
                    qaId={index}
                    key={payee.payeeId}
                    {...payee}
                    refreshPayeeList={getPayeeList}
                    isExpandPayee={openedPayeeId === payee.payeeId.toString()}
                />
            ))}
            {fetchingPayeeList && <PayeeListLoading />}
        </>
    );
};

const mapState = (state) => ({
    fetchingPayeeList: state.user.payee.fetching,
    payeeList: state.user.payee.list,
});

const mapDispatch = (dispatch) => ({
    getPayeeList: () => dispatch(userAction.getPayeeListRequest()),
    // resetPayeeList: () => dispatch(userAction.resetPayeeList()),
    minimizePayeeList: () => dispatch(userAction.minimizePayeeList()),
});

export default connect(mapState, mapDispatch)(PayeeList);
