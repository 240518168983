import api from "@config/api";

const getPaymentList = async (payload) => {
    let url = "/user/social/payment/dashboard";
    if (payload?.type) {
        url = `${url}?type=${payload.type}`;
    }
    if (payload?.lastId) {
        url = `${url}&lastId=${payload.lastId}`;
    }
    if (payload?.modifyAt) {
        url = `${url}&modifyAt=${payload.modifyAt}`;
    }
    return await api.get(url, null);
};

const getPayeeList = async () => {
    return await api.get("/user/social/payment/payeeList", null);
};

const createPaymentRequest = async (data) => {
    return await api.post("/user/social/payment/create", data);
};

const checkCardNumber = async (data) => {
    return await api.post("/user/smartLink/cardName", data);
};

const addNewPayeeInfoAccount = async (data, type) => {
    let url = "";
    if (type === "cardnumber") {
        url = "/user/payee/card/add";
    } else if (type === "bankaccount") {
        url = "/user/payee/bankAccount/add";
    }
    return await api.post(url, data);
};

const getPaymentDetail = async (isSent, params) => {
    let url = "";
    if (!isSent) {
        url = "/user/social/payment/payeedetail";
    } else {
        url = "/user/social/payment/paymentdetail";
    }
    return await api.get(url, params);
};

const paymentActionOfSender = async (data) => {
    return await api.post("/user/social/payment/action", data);
};

const deleteReceivedPaymentRequest = async (data) => {
    return await api.post("/user/social/payment/payeeaction", data);
};

const commitOTP = async (data) => {
    return await api.post("/user/social/paynow/commit", data);
};

const paymentRequestService = {
    getPaymentList,
    getPayeeList,
    createPaymentRequest,
    checkCardNumber,
    addNewPayeeInfoAccount,
    getPaymentDetail,
    paymentActionOfSender,
    deleteReceivedPaymentRequest,
    commitOTP,
};

export default paymentRequestService;
