import { GUEST_ROUTES_PATH } from "@containers/App/routes.constant";
import QuickCode from "@components/QuickCode";
import GlobalDialogController from "./GlobalDialogController";
import { QuickCodeFlows } from "../../redux/actions/quick-code";
import ApiCancelToken from "./ApiCancelToken";

const IGNORED_QUICK_CODE_PAGES = Object.values(GUEST_ROUTES_PATH);

const API_BY_PASS_WHEN_REQUIRED_QUICKCODE = [
    "/login/quickCode/check",
    "/login/quickCode/forget",
    "/logout",
    "user/public/contents/check",
];

const DIRECT_HOME_WITH_PATHS = [
    "/card-management/debit",
    "/card-management/credit",
];

const splitParams = (url) => url.split("/:");

export default class QuickCodeDialogController {
    static open;

    static close;

    static isOpened;

    static directFunc;

    static setOpen(open) {
        this.open = open;
    }

    static setClose(close) {
        this.close = close;
    }

    static open() {
        this.open();
    }

    static setDirectFunc(direct) {
        this.directFunc = direct;
    }

    static randomId = "jex8zk";

    static cachedName = `lspStatus_${this.randomId}`;

    static opening = false;

    static resetStatusInfo() {
        const stringifyStatuses = JSON.stringify({
            required: false,
        });
        localStorage.setItem(this.cachedName, stringifyStatuses);
    }

    static updateStatusInfo(required, opening = true) {
        this.opening = opening;
        const cached = JSON.parse(localStorage.getItem(this.cachedName));
        const stringifyStatuses = JSON.stringify({
            ...cached,
            required,
        });
        localStorage.setItem(this.cachedName, stringifyStatuses);
    }

    static isEnableQuickCodeForPage = (pathname) => {
        const isExisting = !IGNORED_QUICK_CODE_PAGES.some((item) => {
            const pathWithoutParams = splitParams(item);
            // get path without params. Ex: /p/:verifyCode --> /p/
            const pathItem =
                pathWithoutParams.length > 1
                    ? `${pathWithoutParams[0]}/`
                    : pathWithoutParams[0];
            return pathname.includes(pathItem);
        });

        return isExisting;
    };

    static byPassPreventRequestApi = (url) => {
        return API_BY_PASS_WHEN_REQUIRED_QUICKCODE?.some((item) =>
            url.includes(item)
        );
    };

    // If update this function, please check in GlobalDialogController too.
    static isRequiredQuickCode() {
        const status = JSON.parse(localStorage.getItem(this.cachedName));
        // console.log("status: ", status?.required);
        // console.log("this.opening: ", this.opening);
        return status?.required;
    }

    static isAuthenticated() {
        const cached = JSON.parse(sessionStorage.getItem("persist:auth"));
        return !!cached?.token && cached?.token !== "null";
    }

    // If api checkValidQuickCode --> continue execute request api
    // else if required quick code and current request api !== checkValidQuickCode --> cancelToken
    static isPreventRequestApi(url) {
        if (!this.isAuthenticated()) {
            return false;
        }

        const isEnableQuickCode = this.isEnableQuickCodeForPage(
            window.location.pathname
        );
        // Pages without authentication > don't required quick code
        if (!isEnableQuickCode) return false;

        const isRequiredQC = this.isRequiredQuickCode();

        const isCancelToken =
            isRequiredQC && !this.byPassPreventRequestApi(url);

        if (isCancelToken) {
            ApiCancelToken.cancel(
                "Cancel api request because required quick code"
            );
        }

        const isShowDialog = isCancelToken && !this.opening;
        if (isShowDialog) {
            this.show(() => {
                window.location.reload();
            });
        }

        return isCancelToken;
    }

    // Problem:
    // 1. Error dialog is opening & quickcode dialog coming
    // 2. Verified quickcode successfully & we can't show before error dialog again (only one dialog was shown at current time)

    // Solution: direct to home after verified quick code successfully
    static workaroundForDialog() {
        const pathName = window.location.pathname;
        const isExisting = DIRECT_HOME_WITH_PATHS?.find(
            (item) => item === pathName
        );

        if (isExisting) {
            this.directFunc("/home");
        }
    }

    static quickCodeIdleHandler(currentQuickCodeType) {
        const isEnableQuickCode = this.isEnableQuickCodeForPage(
            window.location.pathname
        );

        // Pages without authentication > don't required quick code
        if (!isEnableQuickCode) return;

        // For case: change quick code is opening & required quick code is coming.
        // Replace change quick code dialog with required quick code dialog
        if (currentQuickCodeType === QuickCodeFlows.CHANGE) {
            this.show();
            return;
        }

        if (!this.opening) {
            this.show(() => {
                this.workaroundForDialog();
            });
        }
    }

    static show(afterClose) {
        this.updateStatusInfo(true);
        GlobalDialogController.show({
            size: "sm",
            component: () => (
                <QuickCode
                    flow={QuickCodeFlows.VERIFY}
                    afterClose={afterClose}
                />
            ),
            onClose: () => {
                this.close();
            },
        });
    }

    static create(afterClose) {
        this.updateStatusInfo(false);

        GlobalDialogController.show({
            size: "sm",
            component: () => (
                <QuickCode
                    flow={QuickCodeFlows.CREATE}
                    afterClose={afterClose}
                />
            ),
            onClose: () => {
                this.close();
            },
        });
    }

    static change() {
        this.updateStatusInfo(false);
        GlobalDialogController.show({
            size: "sm",
            component: () => <QuickCode flow={QuickCodeFlows.CHANGE} />,
            onClose: () => {
                this.close();
            },
        });
    }

    static hide() {
        this.updateStatusInfo(false, false);
        this.close();
        GlobalDialogController.hide();
    }

    static notifyMessage(message) {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                icon: message?.icon,
                header: message?.header,
                content: message?.content,
                button: message?.button,
            },
        });
    }
}
