import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { Box, Icon, makeStyles, Grid } from "@material-ui/core";
import format from "date-fns/format";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
import {
    BANK_INFO,
    MoveMoneyType,
    RECEIPT_TYPE,
    SpendAccountNavigationKey,
    TransactionGroupType,
} from "@config/constants";
import accountAction from "@redux/actions/account";
import TransactionListLoading from "@components/TransactionList/TransactionListLoading";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import useAccounts from "@helpers/useAccounts";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import LspTypography from "@components/LspTypography";
import noHope from "@assets/images/no_hope.png";

import { isFunction } from "lodash";
import AccountSummaryBoxOverdraft from "@components/Account/AccountSummaryBoxOverdraft";
import Filter from "./Filter";
import Export from "./Export";

const useStyles = makeStyles((theme) => ({
    actionButton: {
        minWidth: theme.spacing(15),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        "&:first-child": {
            marginLeft: 0,
        },
        "&:last-child": {
            marginRight: 0,
        },
    },
    emptyLogo: {
        marginBottom: theme.spacing(3),

        "& img": {
            width: 86,
        },
    },
}));

const TransactionHistory = ({
    getTransactionList,
    transactionListFetching,
    transactionList,
    txnDateIndex,
    xidIndex,
    hasMore,
    serverTime,
    serverTimeFetching,
    getPayAnyoneDashboard,
    totalBalance,
    totalAmountPending,
    updateTransactionList,
    updateHideBalanceOption,
    hiddenBalanceOption,
    featureState,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "payAnyone");
    const { formatNumber } = useNumber();
    const [filter, setFilter] = useState(null);
    const [filterVisible, setFilterVisible] = useState(false);
    const [exportOpened, setExportOpened] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const openedTxnId = params?.txnId || null;

    const openedReceipt = useRef(false);

    const {
        spendAccount,
        isBalanceHidden,
        getCurrentBalance,
        getAvailableToSpend,
        isOverDraftUser,
    } = useAccounts();

    const firstLoaded = useRef(false);

    const updatedHiddenOption = useRef(false);

    const isEnableOverdraft = useMemo(() => featureState?.enableOverDraft, [
        featureState?.enableOverDraft,
    ]);

    const availableToSpend = getAvailableToSpend(true);

    const currentBalance = getCurrentBalance(true);

    // reset state for balance option when unmounted
    useEffect(() => {
        if (!updatedHiddenOption.current) {
            updateHideBalanceOption(isBalanceHidden);
            updatedHiddenOption.current = true;
        }

        return () => {
            updateHideBalanceOption(isBalanceHidden);
            updatedHiddenOption.current = false;
        };
    }, [isBalanceHidden, updateHideBalanceOption]);

    const hiddenBalanceConfig = useMemo(() => {
        return {
            enableHideBalance: true,
            balanceOption: hiddenBalanceOption,
            toggleBalanceFunc: updateHideBalanceOption,
        };
    }, [updateHideBalanceOption, hiddenBalanceOption]);

    useEffect(() => {
        getPayAnyoneDashboard();
    }, [getPayAnyoneDashboard]);

    const getTransactionHistory = useCallback(
        ({ pagingData, filterData } = {}) => {
            if (!spendAccount || !serverTime) {
                return;
            }

            const payload = {
                accountNo: spendAccount.no,
                accountType: spendAccount.accountType,
                fromDate: filterData?.fromDate || "01/01/2015",
                toDate: filterData?.toDate || format(serverTime, "dd/MM/yyyy"),
            };
            if (filterData) {
                payload.filter = filterData;
            }
            if (pagingData?.txnDateIndex && pagingData.xidIndex) {
                payload.txnDateIndex = pagingData.txnDateIndex;
                payload.xidIndex = pagingData.xidIndex;
            }
            getTransactionList(payload);
        },
        [serverTime, spendAccount, getTransactionList]
    );

    const onCompleteSendAgainAction = useCallback(
        (info) => {
            const { bankId, accNo } = info || {};

            let channel = MoveMoneyType.BankAccount;
            if (bankId === BANK_INFO.DEFAULT_BANK_1_ID.toString()) {
                channel = MoveMoneyType.Internal;
            } else if (accNo.length >= 16) {
                channel = MoveMoneyType.DebitCard;
            }

            const path = `/spend-account/${SpendAccountNavigationKey.MoveMoney}/${channel}?autofill=1`;
            history.push(path);
        },
        [history]
    );

    const updateList = useCallback(
        (data) => {
            updateTransactionList(data);
        },
        [updateTransactionList]
    );

    const showReceipt = useCallback(
        (txn, dispDate, callbackFunc) => {
            const refNo = txn.refNo || "";

            const options = {
                enableSendAgainButton: true,
                onCompleteSendAgainHandler: onCompleteSendAgainAction,
            };
            LspReceiptController.dialog(
                refNo,
                (data) => {
                    if (isFunction(callbackFunc)) {
                        callbackFunc();
                    }

                    if (data?.isNoteUpdate) {
                        updateList({
                            type: RECEIPT_TYPE.SPEND_ACCOUNT,
                            dispDate,
                            refNo,
                            list: transactionList,
                            data: {
                                note: data?.note || "",
                            },
                        });
                    }
                },
                null,
                options
            );
        },
        [onCompleteSendAgainAction, transactionList, updateList]
    );

    useEffect(() => {
        if (openedTxnId) {
            openedReceipt.current = true;
            showReceipt({ refNo: openedTxnId }, null, () => {});

            history.replace({
                txnId: "",
            });
        }
    }, [history, openedTxnId, showReceipt]);

    useEffect(() => {
        return () => {
            openedReceipt.current = false;
        };
    }, []);

    useEffect(() => {
        if (!serverTimeFetching && !firstLoaded.current) {
            firstLoaded.current = true;
            getTransactionHistory();
        }
    }, [serverTimeFetching, getTransactionHistory]);

    useEffect(() => {
        if (filter !== null) {
            getTransactionHistory({ filterData: filter });
        }
    }, [filter, getTransactionHistory]);

    const loadMore = () => {
        if (
            transactionListFetching ||
            serverTimeFetching ||
            !firstLoaded.current
        ) {
            return;
        }
        getTransactionHistory({
            filterData: filter,
            pagingData: {
                txnDateIndex,
                xidIndex,
            },
        });
    };

    const infiniteRef = useInfiniteScroll({
        loading: transactionListFetching,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const subItems = useMemo(() => {
        // eslint-disable-next-line prefer-const
        let items = [
            {
                id: "currentBalance",
                name: t("overdraft:overdraft_current_balance"),
                value: formatNumber(currentBalance),
                valueColor: "primary",
                primary: true,
            },
            {
                id: "availableToSpend",
                name: t("overdraft:overdraft_available_to_spend"),
                value: formatNumber(availableToSpend),
                tooltipText: t(
                    "overdraft:overdraft_available_to_spend_tooltip"
                ),
            },
        ];

        if (totalAmountPending && totalAmountPending > 0) {
            items.push({
                id: "pendingAmount",
                name: t("payAnyone:dashboard_pending_payment"),
                value: formatNumber(totalAmountPending),
            });
        }
        return items;
    }, [currentBalance, t, availableToSpend, totalAmountPending, formatNumber]);

    const handleGotoOverdraft = () => {
        history.push("/overdraft");
    };

    const handleManagementOverdraft = () => {
        history.push("/overdraft/details");
    };

    return (
        <Content>
            {!isEnableOverdraft && (
                <AccountSummaryBox
                    leftItemName={t("payAnyone:total_balance")}
                    rightItemName={t("payAnyone:dashboard_pending_payment")}
                    rightItemColor="blue"
                    leftItemValue={formatNumber(totalBalance)}
                    rightItemValue={formatNumber(totalAmountPending)}
                    leftItemId="availableBalance"
                    rightItemId="pendingAmount"
                    hiddenBalanceConfig={hiddenBalanceConfig}
                />
            )}

            {isEnableOverdraft && (
                <AccountSummaryBoxOverdraft
                    hiddenBalanceConfig={hiddenBalanceConfig}
                    items={subItems}
                />
            )}

            <Box mt={2} mb={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        {!isOverDraftUser && isEnableOverdraft && (
                            <LspButton
                                onClick={() => handleGotoOverdraft()}
                                variant="secondary"
                                buttonProps={{
                                    startIcon: (
                                        <Icon className="font-icon icon-fasttransfer" />
                                    ),
                                }}
                                className={classes.actionButton}
                                fullWidth
                            >
                                {t("overdraft:overdraft_intro_header")}
                            </LspButton>
                        )}
                        {isOverDraftUser && (
                            <LspButton
                                onClick={() => handleManagementOverdraft()}
                                variant="secondary"
                                buttonProps={{
                                    startIcon: (
                                        <Icon className="font-icon icon-fasttransfer" />
                                    ),
                                }}
                                className={classes.actionButton}
                                fullWidth
                            >
                                {t("overdraft:overdraft_dashboard_title")}
                            </LspButton>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                        >
                            <LspButton
                                onClick={() => setExportOpened(true)}
                                variant="secondary"
                                buttonProps={{
                                    startIcon: (
                                        <Icon className="font-icon icon-EXPORT" />
                                    ),
                                }}
                                className={classes.actionButton}
                                fullWidth
                            >
                                {t("spend_download_transaction_list")}
                            </LspButton>
                            <LspButton
                                onClick={() =>
                                    setFilterVisible((value) => !value)
                                }
                                variant={
                                    filterVisible ? "primary" : "secondary"
                                }
                                buttonProps={{
                                    startIcon: (
                                        <Icon className="font-icon icon-FILTER" />
                                    ),
                                }}
                                className={classes.actionButton}
                                fullWidth
                            >
                                {t("transaction_filter_label")}
                            </LspButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Filter
                visible={filterVisible}
                hide={() => setFilterVisible(false)}
                setFilter={setFilter}
            />

            <Export
                open={exportOpened}
                onClose={() => setExportOpened(false)}
            />
            {!hasMore &&
                transactionList?.length === 0 &&
                !transactionListFetching && (
                    <Box paddingY={4} textAlign="center">
                        <div className={classes.emptyLogo}>
                            <img src={noHope} alt="Empty" />
                        </div>
                        <LspTypography variant="subheading1">
                            {featureState?.enableFilterTransactionList
                                ? t("txnList:txn_list_filter_error_data")
                                : t("spend_dont_have_transactions_yet")}
                        </LspTypography>
                    </Box>
                )}
            <div ref={infiniteRef}>
                {transactionList?.map((txnGroup) => {
                    const key = `${txnGroup.dispDate}-${txnGroup.item.length}`;
                    return (
                        <TransactionGroup
                            key={key}
                            type={TransactionGroupType.SpendAccount}
                            header={txnGroup.dispDate}
                            items={txnGroup.item}
                            onItemClick={(data) =>
                                showReceipt(data, txnGroup.dispDate)
                            }
                            enableAddPayee
                            showRemainBalance
                            hiddenRemainBalanceOption={hiddenBalanceOption}
                        />
                    );
                })}
            </div>
            {hasMore && <TransactionListLoading />}
        </Content>
    );
};

const mapState = (state) => ({
    transactionList: state.account.spendAccount.transactionList,
    transactionListFetching: state.account.spendAccount.fetching,
    txnDateIndex: state.account.spendAccount.txnDateIndex,
    xidIndex: state.account.spendAccount.xidIndex,
    hasMore: state.account.spendAccount.hasMore,
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
    totalBalance: state.account.payAnyoneDashboard.totalBalance,
    totalAmountPending: state.account.payAnyoneDashboard.totalAmountPending,
    hiddenBalanceOption: state.account.spendAccount.hiddenBalance,
    featureState: state.user.featureState.data,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(accountAction.getSpendAccountTransactionListRequest(payload)),
    getPayAnyoneDashboard: () =>
        dispatch(accountAction.getPayAnyoneDashboardRequest()),
    updateTransactionList: (payload) =>
        dispatch(accountAction.updateTransactionList(payload)),
    updateHideBalanceOption: (payload) =>
        dispatch(accountAction.updateHideBalanceOption(payload)),
});

export default connect(mapState, mapDispatch)(TransactionHistory);
