import { ArrowRightIcon } from "@components/LspIcon";
import { Icon, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: "none",
        paddingLeft: 0,
        margin: "0 auto",
    },
    item: {
        padding: theme.spacing(2, 1.25, 2, 2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        color: theme.palette.neutral.grey1,
        boxShadow: theme.shadows[1],
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        minHeight: 90,
        "& label": {
            display: "block",
            fontWeight: 600,
            color: theme.palette.neutral.black,
        },
        "&:last-child": {
            borderBottom: "none",
        },
        "& .description": {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    info: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        flex: 1,
        "& label": {
            cursor: "pointer",
        },
    },
    icon: {
        marginRight: theme.spacing(2),
        fontSize: 30,
    },
    large: {
        fontSize: theme.typography.pxToRem(20),
        "& .description": {
            fontSize: theme.typography.pxToRem(16),
        },
    },
    primary: {
        "& > span": {
            color: theme.palette.primary.main,
        },
        "& label": {
            color: theme.palette.primary.main,
        },
    },
    fetching: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
}));

/**
 * @typedef ListObject
 * @type {object}
 * @property {string} id
 * @property {string} icon
 * @property {string} title
 * @property {string} description
 * @property {bool} isComingSoon
 */

/**
 * @typedef LspCardListProps
 * @type {object}
 * @property {string} className
 * @property {function} onClickItem
 * @property {string} selected
 * @property {string} variant
 * @property {string} size
 * @property {bool} fetching
 * @property  {Array.<ListObject>} list
 */

const LspCardList = ({
    className,
    list,
    selected,
    onClickItem,
    variant,
    size,
    fetching,
    hideStyle,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const fetchingLayer = useMemo(() => {
        return (
            <ul className={classes.list}>
                {[...new Array(2)]?.map((_, index) => {
                    return (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            className={`${classes.item} ${classes.fetching}`}
                        >
                            <Skeleton width={125} />
                            <Skeleton width={245} />
                        </li>
                    );
                })}
            </ul>
        );
    }, [classes]);

    return (
        <ul className={`${classes.list} ${className}`}>
            {fetching && fetchingLayer}
            {!fetching &&
                list?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                        {(!hideStyle || (hideStyle && item?.isAvailable)) && (
                            <li
                                className={clsx(classes.item, {
                                    [classes.selected]:
                                        item?.id === selected?.id,
                                })}
                                onClick={() => onClickItem(item)}
                            >
                                <div
                                    className={clsx(classes.info, {
                                        [classes.primary]:
                                            variant === "primary",
                                        [classes.large]: size === "large",
                                    })}
                                >
                                    {item?.icon && (
                                        <Icon
                                            className={`font-icon icon-${item?.icon} ${classes.icon}`}
                                        />
                                    )}

                                    <div>
                                        <label htmlFor={`title-${index}`}>
                                            {item?.title}
                                        </label>
                                        <span className="description">
                                            {item?.description}
                                        </span>
                                    </div>
                                </div>
                                {item?.isComingSoon && (
                                    <>
                                        {t(
                                            "wealth:lbi_dashboard_prodname_sub_mtbk"
                                        )}
                                    </>
                                )}
                                {!item?.isComingSoon && <ArrowRightIcon />}
                            </li>
                        )}
                    </Fragment>
                ))}
        </ul>
    );
};

LspCardList.propTypes = {
    fetching: PropTypes.bool,
    hideStyle: PropTypes.bool,
    className: PropTypes.string,
    onClickItem: PropTypes.func,
    variant: PropTypes.oneOf(["primary", ""]),
    size: PropTypes.oneOf(["", "large"]),
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            icon: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            isComingSoon: PropTypes.bool,
        })
    ),
};

LspCardList.defaultProps = {
    hideStyle: false,
    fetching: false,
    onClickItem: null,
    className: "",
    variant: "",
    size: "",
    list: [
        {
            id: "",
            icon: "",
            title: "",
            description: "",
            isComingSoon: false,
        },
    ],
};

export default memo(LspCardList);
