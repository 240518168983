import Content from "@components/Content";
import { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useStepNavigation from "@helpers/useStepNavigation";
import Loading from "@components/Loading";
import vinaWealthAction from "@redux/actions/vina-wealth";
import {
    VINA_WEALTH_STEPS,
    VINA_WEALTH_HISTORY_TRANSACTION_TYPES,
    WEALTH_PRODUCT_KEYS,
} from "../wealthConstants";
import VinaWealthSelection from "./VinaWealthSelection";
import VinaWealthDashboard from "./VinaWealthDashboard";
import VinaWealthLinkAccount from "./VinaWealthLinkAccount";
import VinaWealthBeforeYouStart from "./VinaWealthBeforeYouStart";
import VinaWealthForeignerWarning from "./VinaWealthForeignerWarning";
import VinaWealthIntro from "./VinaWealthIntro";
import VinaWealthDetail from "./VinaWealthDetail";
import VinaWealthInputAmount from "./VinaWealthInputAmount";
import VinaWealthTermCondition from "./VinaWealthTermCondition";
import VinaWealthReviewInfo from "./VinaWealthReviewInfo";
import VinaWealthResult from "./VinaWealthResult";
import AccountOverview from "./VinaWealthDashboard/AccountOverview";
import VinaWealthPassport from "./VinaWealthPassport";
import VinaWealthTransaction from "./VinaWealthTransaction";

const WealthVinaWealth = ({ resetState }) => {
    const [notExistedAccount, setNotExistedAccount] = useState(true);

    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: "",
    });
    const unmounted = useRef(false);
    const firstLoaded = useRef(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    const getSubProduct = useCallback(async () => {
        setFetching(true);
        const { data } = await wealthService.getSubProducts(
            WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3
        );

        if (unmounted.current) return;

        setFetching(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                const noExistedAccount = data.data.canlinkAccount;
                setNotExistedAccount(noExistedAccount);
                if (noExistedAccount) {
                    stepForward(VINA_WEALTH_STEPS.SELECTION_DASHBOARD);
                } else {
                    stepForward(VINA_WEALTH_STEPS.DASHBOARD);
                }
                firstLoaded.current = true;
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    }, [stepForward]);

    useEffect(() => {
        if (firstLoaded.current) return;
        getSubProduct();
    }, [getSubProduct]);

    const onInitPage = () => {
        stepForward("");
        getSubProduct();
    };

    return (
        <Content
            boxProps={{
                paddingTop: "50px",
                paddingLeft: "16px",
                paddingRight: "16px",
            }}
        >
            {fetching && <Loading />}
            {step.current === VINA_WEALTH_STEPS.SELECTION_DASHBOARD && (
                <VinaWealthSelection onClickItem={stepForward} />
            )}
            {step.current === VINA_WEALTH_STEPS.DASHBOARD && (
                <VinaWealthDashboard
                    notExistedAccount={notExistedAccount}
                    onClickItem={stepForward}
                    onBack={stepBack}
                    onMoreTransaction={() =>
                        stepForward(VINA_WEALTH_STEPS.TRANSACTION_HISTORIES)
                    }
                />
            )}
            {step.current === VINA_WEALTH_STEPS.LINK_ACCOUNT && (
                <VinaWealthLinkAccount
                    onBack={stepBack}
                    onComplete={onInitPage}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.BEFORE_YOU_START && (
                <VinaWealthBeforeYouStart
                    onBack={stepBack}
                    onNext={stepForward}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.FOREIGNER_WARNING && (
                <VinaWealthForeignerWarning onComplete={onInitPage} />
            )}
            {step.current === VINA_WEALTH_STEPS.INTRO && (
                <VinaWealthIntro onClickItem={stepForward} />
            )}
            {step.current === VINA_WEALTH_STEPS.DETAIL && (
                <VinaWealthDetail
                    onBack={stepBack}
                    onNext={stepForward}
                    notExistedAccount={notExistedAccount}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.INPUT_AMOUNT && (
                <VinaWealthInputAmount
                    onBack={stepBack}
                    onNext={stepForward}
                    notExistedAccount={notExistedAccount}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.TRANSACTION_HISTORIES && (
                <VinaWealthTransaction
                    type={
                        VINA_WEALTH_HISTORY_TRANSACTION_TYPES.FULL_TRANSACTION_LIST
                    }
                    onBack={stepBack}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.TERM_CONDITION && (
                <VinaWealthTermCondition
                    onBack={stepBack}
                    onNext={stepForward}
                />
            )}
            {step.current === VINA_WEALTH_STEPS.REVIEW_INFO && (
                <VinaWealthReviewInfo onBack={stepBack} onNext={stepForward} />
            )}
            {step.current === VINA_WEALTH_STEPS.RESULT && (
                <VinaWealthResult onBack={stepBack} onNext={stepForward} />
            )}
            {step.current === VINA_WEALTH_STEPS.ACCOUNT_OVERVIEW && (
                <AccountOverview onBack={stepBack} onNext={stepForward} />
            )}
            {step.current === VINA_WEALTH_STEPS.PASSPORT_INFO && (
                <VinaWealthPassport onBack={stepBack} onNext={stepForward} />
            )}
        </Content>
    );
};

const mapDispatch = (dispatch) => ({
    resetState: () => dispatch(vinaWealthAction.reset()),
});

export default connect(null, mapDispatch)(WealthVinaWealth);
