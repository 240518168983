import { call, put } from "redux-saga/effects";
import sortBy from "lodash/sortBy";
import accountService from "@services/account";
import { AccountStatus, RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import WarningVerifyEmailEkycDialog from "@components/WarningVerifyEmailEkycDialog";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import systemParamsAction from "@redux/actions/system-params";
import authAction from "../actions/auth";
import accountAction from "../actions/account";

function warningVerifyEmailDialog() {
    GlobalDialogController.show({
        component: WarningVerifyEmailEkycDialog,
    });
}

export function* getAccountStatusSaga() {
    const response = yield call(accountService.getAccountStatus);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            const { status, webStatus } = data;
            switch (webStatus) {
                case AccountStatus.TimoOut:
                case AccountStatus.TimoIn:
                    yield put(accountAction.getAccountStatusSuccess(webStatus));
                    yield put(authAction.setIsAuthenticated(true));
                    break;
                case AccountStatus.New:
                    warningVerifyEmailDialog();
                    yield put(accountAction.getAccountStatusSuccess(webStatus));
                    break;
                case AccountStatus.Active:
                    yield put(accountAction.getAccountStatusSuccess(webStatus));
                    break;
                case AccountStatus.Blocked:
                    GlobalDialogController.showError({
                        errorCode: RESPONSE_CODE.FE_USER_BLOCKED,
                    });
                    yield put(accountAction.getAccountStatusSuccess(webStatus));
                    yield put(authAction.logoutSuccess());
                    break;
                case AccountStatus.Info:
                    yield put(accountAction.getAccountStatusSuccess(webStatus));
                    break;
                case AccountStatus.NA:
                    if (status === AccountStatus.New) {
                        warningVerifyEmailDialog();
                        yield put(
                            accountAction.getAccountStatusSuccess(status)
                        );
                    } else {
                        yield put(
                            accountAction.getAccountStatusSuccess(webStatus)
                        );
                    }
                    break;
                default:
                    if (status === AccountStatus.Blocked) {
                        warningVerifyEmailDialog();
                    }
                    yield put(accountAction.getAccountStatusError());
                    yield put(authAction.logoutSuccess());
                    break;
            }
            break;
        // case RESPONSE_CODE.PASSWORD_EXPIRED:
        //     yield put(accountAction.getAccountStatusError());
        //     yield put(authAction.logoutSuccess());
        //     AlertController.show(
        //         "Password is expired. This should be handled later!!!",
        //         AlertType.Error
        //     );
        //     break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getAccountStatusError());
            break;
    }
}

export function* getAccountListSaga() {
    const response = yield call(accountService.getAccountList);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            const sortedAccounts = sortBy(data.accounts, ["order"]);
            yield put(accountAction.getAccountListSuccess(sortedAccounts));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getAccountListError());
            break;
    }
}

export function* getSpendAccountTransactionListSaga(action) {
    const response = yield call(accountService.getSpendAccountTransactionList, {
        format: "group",
        index: 0,
        offset: -1,
        ...action.payload,
    });
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                accountAction.getSpendAccountTransactionListSuccess(data)
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getSpendAccountTransactionListError());
            break;
    }
}

export function* getCreditCardTransactionListSaga(action) {
    const response = yield call(accountService.getCreditCardTransactionList, {
        format: "group",
        index: 0,
        offset: -1,
        ...action.payload,
    });
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(accountAction.getCreditCardTransactionListSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getCreditCardTransactionListError());
            break;
    }
}

export function* getInstallmentDataSaga() {
    const response = yield call(accountService.getInstallmentData, {
        idIndex: 0,
        listName: "",
    });
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(accountAction.getInstallmentDataSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getInstallmentDataError());
            break;
    }
}

export function* getPayAnyoneDashboardSaga() {
    const response = yield call(accountService.getPayAnyoneDashboard);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                accountAction.getPayAnyoneDashboardSuccess(data.paoanyoneList)
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getPayAnyoneDashboardError());
            break;
    }
}

export function* getPayAnyoneListSaga(action) {
    const response = yield call(
        accountService.getPayAnyoneList,
        action.payload
    );
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                accountAction.getPayAnyoneListSuccess({
                    list: data.paoanyoneList,
                })
            );
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getPayAnyoneListError());
            break;
    }
}

export function* updatePaymentStatusSaga(action) {
    const { payload } = action;
    const response = yield call(accountService.updatePaymentStatus, payload);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(accountAction.updatePaymentStatusSuccess());
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.updatePaymentStatusError());
            break;
    }
}

export function* getAccountLimit(action) {
    const { payload } = action;
    const response = yield call(accountService.accountLimit, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(accountAction.getAccountLimitSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.getAccountListError());
            break;
    }
}

export function* modifyAccountLimit(action) {
    const { payload } = action;
    const response = yield call(accountService.modifyAccountLimit, payload);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(accountAction.modifyAccountLimitSuccess());
            yield put(systemParamsAction.getSystemParamsRequest());
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(accountAction.modifyAccountLimitError());
            break;
    }
}
