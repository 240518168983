import { makeStyles } from "@material-ui/core";
import useStepNavigation from "@helpers/useStepNavigation";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Content from "@components/Content";
import wealthService from "@services/wealth";
import { CREDIT_CARD_STATUES, RESPONSE_CODE } from "@config/constants";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import { connect } from "react-redux";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useOTP from "@helpers/useOTP";
import { add, format, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import WealthCreditCardFetching from "@containers/Wealth/WealthCreditCard/WealthCreditCardFetching";
import { isObject } from "lodash";
import LspHangoutSelection from "@components/LspBooking/LspHangoutSelection";
import LspTimeSelection from "@components/LspBooking/LspTime/LspTimeSelection";
import bookingActions from "@redux/actions/booking";
import WealthCreditCardBookingConfirmation from "@containers/Wealth/WealthCreditCard/WealthCreditCardBookingConfirmation";
import { useHistory } from "react-router-dom";
import { BOOKING_TYPES } from "@containers/Booking/constant";
import WealthCreditCardIntro from "./WealthCreditCardIntro";
import {
    WEALTH_CREDIT_CARD_STEPS,
    WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS,
    WEALTH_PRODUCT_KEYS,
    WEALTH_CREDIT_CARD_METHOD,
} from "../wealthConstants";
import WealthCreditCardMethodSelections from "./WealthCreditCardMethodSelections";
import WealthCreditCardMethodDetail from "./WealthCreditCardMethodDetail";
import WealthCreditCardForeignerWarning from "./WealthCreditCardForeignerWarning";
import WealthCreditCardAmountLimitation from "./WealthCreditCardAmountLimitation";
import WealthCreditCardPersonalForm from "./WealthCreditCardPersonalForm";
import WealthCreditCardAddressForm from "./WealthCreditCardAddressForm";
import WealthCreditCardMaritalStatusForm from "./WealthCreditCardMaritalStatusForm";
import WealthCreditCardFinanceForm from "./WealthCreditCardFinanceForm";
import WealthCreditCardPaymentSelection from "./WealthCreditCardPaymentSelection";

import WealthCreditCardFailed from "./WealthCreditCardResult/WealthCreditCardFailed";
import WealthCreditCardBlocked from "./WealthCreditCardResult/WealthCreditCardBlocked";
import WealthCreditCardInvalidAge from "./WealthCreditCardResult/WealthCreditCardInvalidAge";
import WealthCreditCardActivated from "./WealthCreditCardResult/WealthCreditCardActivated";
import WealthCreditCardApplySuccessfully from "./WealthCreditCardResult/WealthCreditCardApplySuccessfully";

const useStyles = makeStyles((theme) => ({
    creditWrapper: {
        maxWidth: 600,
        paddingTop: theme.spacing(4),
        "& .MuiPaper-rounded": {
            overflow: "hidden",
        },
    },
}));

const productKey = WEALTH_PRODUCT_KEYS.MASTERCARD;

const WealthCreditCard = ({
    setSubProductKey,
    resetState,
    setIsAppliedCreditCard,
    isForeigner,
    today,
    getBookingList,
    resetBookingInfo,
}) => {
    const sharedClasses = useClassesShared();
    const classes = useStyles();
    const { t } = useTranslation();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const history = useHistory();

    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: "",
    });

    const hangouts = t("hangout:hangouts", { returnObjects: true });

    const [applyMethod, setApplyMethod] = useState(null);
    const [creditCardStatus, setCreditCardStatus] = useState(null);
    const [bookingInfo, setBookingInfo] = useState(null);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [fetchingBooking, setFetchingBooking] = useState(false);
    const [fetchingCreditStatus, setFetchingCreditStatus] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedHangout, setSelectedHangout] = useState({});
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});
    const [bookingSubmitting, setBookingSubmitting] = useState(false);

    const firstLoad = useRef(true);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    useEffect(() => {
        return () => {
            resetBookingInfo();
        };
    }, [resetBookingInfo]);

    const mapBookingDate = useCallback(
        (schedule) => {
            // Have booked info > show screens with booked information
            if (isObject(schedule)) {
                const { locationId } = schedule;
                const bookingDate = schedule.bookingDate
                    ? format(
                          parse(
                              schedule.bookingDate,
                              "yyyy-MM-dd HH:mm",
                              new Date()
                          ),
                          "HH:mm dd/MM/yyyy, EEEE"
                      )
                    : "";
                const hangoutList = t("hangout:hangouts", {
                    returnObjects: true,
                });
                const hangout = hangoutList?.filter(
                    (item) => item.id === locationId
                );

                setBookingInfo({
                    bookingDate,
                    hangoutId: hangout[0]?.id,
                    hangoutAddress: hangout[0]?.address,
                    hangoutLabel: `${hangout[0]?.city} ${t("lb_hangout")}`,
                });
            } else {
                // Just show congratulation screens
            }
        },
        [t]
    );

    const getBookedSchedule = useCallback(async () => {
        setFetchingBooking(true);
        const response = await wealthService.getBookedSchedule({
            bookingType: "CC",
        });

        if (unmounted.current) return;

        const { code, data } = response.data;
        setFetchingBooking(false);

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                mapBookingDate(data.result);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [mapBookingDate]);

    const getPendingInfo = useCallback(
        (data) => {
            const { method, deliveryAddress, isChangeInfo } = data;
            if (method) {
                // Get method key with value from backend
                // eslint-disable-next-line no-restricted-syntax
                for (const [key, value] of Object.entries(
                    WEALTH_CREDIT_CARD_METHOD
                )) {
                    if (method === value) {
                        setApplyMethod(key);
                        break;
                    }
                }
            }

            setDeliveryInfo({
                address: deliveryAddress || null,
                isChangeInfo: isChangeInfo || false,
            });

            // Fake data
            // setApplyMethod(WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED);
            // setDeliveryInfo({
            //     address: "Red velvet home",
            //     isChangeInfo: false,
            // });
            getBookedSchedule();
        },
        [setApplyMethod, getBookedSchedule]
    );

    const getCreditCardStatus = useCallback(
        async (isSubmitted = false) => {
            setFetchingCreditStatus(true);
            const response = await wealthService.checkProductStatus({
                productKey,
            });

            if (unmounted.current) return;
            setFetchingCreditStatus(false);

            const { code, data } = response.data;

            // const code = 200; //qualified
            // const code = 4204; //non qualified
            // const code = 4200; //pending
            // const code = 4202; // applied
            // const code = 4201; // blocked
            // const code = 4206; // invalid ages

            switch (code) {
                case RESPONSE_CODE.SUCCESS: // Pre-qualified
                    setCreditCardStatus(CREDIT_CARD_STATUES.PRE_QUALIFIED);
                    setSubProductKey(
                        WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED
                    );
                    if (!isSubmitted) {
                        stepForward(WEALTH_CREDIT_CARD_STEPS.INTRO);
                    }
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_NOT_PRE_QUALIFIED:
                    setCreditCardStatus(CREDIT_CARD_STATUES.NON_QUALIFIED);
                    setSubProductKey(
                        WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.NON_QUALIFIED
                    );
                    if (!isSubmitted) {
                        stepForward(WEALTH_CREDIT_CARD_STEPS.INTRO);
                    }
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_PENDING:
                    setCreditCardStatus(CREDIT_CARD_STATUES.PENDING);
                    setIsAppliedCreditCard(true);
                    if (!isSubmitted) {
                        getPendingInfo(data);
                        stepForward(WEALTH_CREDIT_CARD_STEPS.CONGRATULATION);
                    }
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_BLOCKED:
                    setCreditCardStatus(CREDIT_CARD_STATUES.BLOCKED);
                    setIsAppliedCreditCard(true);
                    if (!isSubmitted) {
                        stepForward(WEALTH_CREDIT_CARD_STEPS.BLOCKED_SCREEN);
                    }
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_APPLIED:
                    setCreditCardStatus(CREDIT_CARD_STATUES.APPLIED);
                    setIsAppliedCreditCard(true);
                    if (!isSubmitted) {
                        stepForward(WEALTH_CREDIT_CARD_STEPS.ACTIVATED_SCREEN);
                    }
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_NOT_ELIGIBLE_AGE:
                    setCreditCardStatus(CREDIT_CARD_STATUES.INVALID_AGE);
                    stepForward(WEALTH_CREDIT_CARD_STEPS.INVALID_AGE_SCREEN);
                    setIsAppliedCreditCard(true);
                    break;
                case RESPONSE_CODE.CREDIT_CARD_APPLICATION_FAILED:
                    stepForward(WEALTH_CREDIT_CARD_STEPS.APPLY_FAILED_SCREEN);
                    break;
                default:
                    break;
            }
        },
        [
            setCreditCardStatus,
            stepForward,
            setSubProductKey,
            setIsAppliedCreditCard,
            getPendingInfo,
        ]
    );

    const introPageOnNextHandler = useCallback(() => {
        switch (creditCardStatus) {
            case CREDIT_CARD_STATUES.PRE_QUALIFIED:
                stepForward(WEALTH_CREDIT_CARD_STEPS.PERSONAL_INFO_FORM);
                break;
            case CREDIT_CARD_STATUES.NON_QUALIFIED:
            case CREDIT_CARD_STATUES.PENDING:
                stepForward(WEALTH_CREDIT_CARD_STEPS.METHOD_SELECTIONS);
                break;
            case CREDIT_CARD_STATUES.APPLIED:
            case CREDIT_CARD_STATUES.BLOCKED:
            case CREDIT_CARD_STATUES.INVALID_AGE:
                stepForward(WEALTH_CREDIT_CARD_STEPS.METHOD_SELECTIONS);
                break;
            default:
                break;
        }
    }, [creditCardStatus, stepForward]);

    useEffect(() => {
        if (!firstLoad.current) return;
        getCreditCardStatus();
        firstLoad.current = false;
    }, [getCreditCardStatus]);

    const selectedApplyMethodHandler = (method) => {
        setApplyMethod(method);
        stepForward(WEALTH_CREDIT_CARD_STEPS.METHOD_INTRO);
    };

    const applyCreditHandler = (code) => {
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.CREDIT_CARD_APPLICATION_PENDING:
            case RESPONSE_CODE.CREDIT_CARD_APPLICATION_BLOCKED:
            case RESPONSE_CODE.CREDIT_CARD_APPLICATION_APPLIED:
            case RESPONSE_CODE.CREDIT_CARD_APPLICATION_NOT_PRE_QUALIFIED:
                // after that, direct to hangout step
                getCreditCardStatus(true);
                stepForward(WEALTH_CREDIT_CARD_STEPS.HANGOUT_SELECTIONS);
                break;
            case RESPONSE_CODE.CREDIT_CARD_APPLICATION_FAILED:
                getCreditCardStatus(true);
                break;
            default:
                GlobalDialogController.showFinancialError({ errorCode: code });
                break;
        }
    };

    const commitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            productKey,
            ...dataToPost,
        };
        const response = await wealthService.commitOTP(payload);

        if (unmounted.current) return;

        setLoading(false);
        closeOTPDialog();
        applyCreditHandler(response.data.code);
    };

    const applyCreditCard = async (dataToPost) => {
        setSubmitting(true);
        const payload = {
            ...dataToPost,
            productKey,
            dataVersion: productKey,
        };

        const response = await wealthService.applyProduct(payload);

        if (unmounted.current) return;
        setSubmitting(false);

        const { code, data } = response.data;
        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: commitOtp,
            });
        } else {
            applyCreditHandler(code);
        }
    };

    const onNextMethodDetailHandler = () => {
        if (isForeigner) {
            stepForward(WEALTH_CREDIT_CARD_STEPS.FOREIGNER_WARNING);
        } else {
            stepForward(WEALTH_CREDIT_CARD_STEPS.AMOUNT_LIMITATION);
        }
    };

    const getTimeList = useCallback(
        async (hangoutId) => {
            const nextSevenDay = add(today, { days: 7 });

            const payload = {
                bookingType: BOOKING_TYPES.CREDIT_CARD,
                from: format(today, "yyyy-MM-dd"),
                to: format(nextSevenDay, "yyy-MM-dd"),
                locationId: hangoutId || selectedHangout.id,
            };

            getBookingList(payload);
        },
        [selectedHangout, today, getBookingList]
    );

    const onSelectHangoutHandler = (hangout) => {
        resetBookingInfo();
        setSelectedHangout(hangout);
        getTimeList(hangout.id);
        stepForward(WEALTH_CREDIT_CARD_STEPS.TIME_SELECTION);
    };
    const onSelectTimeHandler = ({ date, time }) => {
        setSelectedDate(date);
        setSelectedTime(time);
        stepForward(WEALTH_CREDIT_CARD_STEPS.BOOKING_CONFIRM);
    };

    const sendBookingSubmit = useCallback(async () => {
        setBookingSubmitting(true);
        const payload = {
            fromTime: selectedTime.fromTime,
            toTime: selectedTime.toTime,
            locationId: selectedHangout.id,
            bookingType: BOOKING_TYPES.CREDIT_CARD,
        };
        const response = await wealthService.bookCreditSchedule(payload);
        if (unmounted.current) return;

        setBookingSubmitting(false);

        const { code } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setBookingInfo({
                    bookingDate: format(
                        parse(
                            selectedTime.fromTime,
                            "yyyy-MM-dd HH:mm",
                            new Date()
                        ),
                        "HH:mm EEEE, dd/MM/yyyy"
                    ),
                    hangoutId: selectedHangout?.id,
                    hangoutAddress: selectedHangout?.address,
                    hangoutLabel: `${selectedHangout?.city} ${t("lb_hangout")}`,
                });
                stepForward(WEALTH_CREDIT_CARD_STEPS.CONGRATULATION);
                break;
            case RESPONSE_CODE.RETRY_EXCEEDED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        header: t("gs_contribute_error_title"),
                        content: t("join_schedule_msg_booking_retry_exceeded"),
                        button: t("lb_ok"),
                    },
                    onClose: () => {
                        history.push("/home");
                    },
                });
                break;
            case RESPONSE_CODE.NON_BOOKABLE_TIME_SCHEDULE:
                getTimeList();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        header: t("join_schedule_title_warning_full_booked"),
                        content: t("join_schedule_content_warning_full_booked"),
                        button: t("lb_ok"),
                    },
                    onClose: () => {
                        stepBack();
                    },
                });
                break;
            case RESPONSE_CODE.TOKEN_EXPIRED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        header: t("gs_contribute_error_title"),
                        content: t("join_schedule_msg_token_expired"),
                        button: t("lb_ok"),
                    },
                    onClose: () => {
                        history.push("/home");
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [
        selectedTime,
        selectedHangout,
        t,
        stepForward,
        getTimeList,
        stepBack,
        history,
    ]);

    const onClickNextMaritualForm = useCallback(() => {
        if (applyMethod === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD) {
            stepForward(WEALTH_CREDIT_CARD_STEPS.PAYMENT_SELECTIONS);
            return;
        }

        stepForward(WEALTH_CREDIT_CARD_STEPS.FINANCE_FORM);
    }, [applyMethod, stepForward]);

    return (
        <div className={`${sharedClasses.wrapper} ${classes.creditWrapper}`}>
            <Content size="xs" boxProps={{ paddingTop: 3, paddingBottom: 3 }}>
                {(fetchingCreditStatus || fetchingBooking) && (
                    <WealthCreditCardFetching />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.INTRO && (
                    <WealthCreditCardIntro
                        onNext={introPageOnNextHandler}
                        onBack={stepBack}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.METHOD_SELECTIONS && (
                    <WealthCreditCardMethodSelections
                        onBack={stepBack}
                        onNext={selectedApplyMethodHandler}
                    />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.METHOD_INTRO && (
                    <WealthCreditCardMethodDetail
                        onBack={stepBack}
                        method={applyMethod}
                        onNext={onNextMethodDetailHandler}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.FOREIGNER_WARNING && (
                    <WealthCreditCardForeignerWarning
                        onBack={stepBack}
                        onNext={() =>
                            stepForward(
                                WEALTH_CREDIT_CARD_STEPS.AMOUNT_LIMITATION
                            )
                        }
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.AMOUNT_LIMITATION && (
                    <WealthCreditCardAmountLimitation
                        method={applyMethod}
                        onBack={stepBack}
                        onNext={stepForward}
                        productKey={productKey}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.PERSONAL_INFO_FORM && (
                    <WealthCreditCardPersonalForm
                        onBack={stepBack}
                        onNext={stepForward}
                    />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.ADDRESS_FORM && (
                    <WealthCreditCardAddressForm
                        onBack={stepBack}
                        onNext={stepForward}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.MARITAL_STATUS_FORM && (
                    <WealthCreditCardMaritalStatusForm
                        onBack={stepBack}
                        onNext={onClickNextMaritualForm}
                    />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.FINANCE_FORM && (
                    <WealthCreditCardFinanceForm
                        onBack={stepBack}
                        onNext={stepForward}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.PAYMENT_SELECTIONS && (
                    <WealthCreditCardPaymentSelection
                        onBack={stepBack}
                        onApplyCreditCard={applyCreditCard}
                        submitting={submitting}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.HANGOUT_SELECTIONS && (
                    <LspHangoutSelection
                        title={t("wealth:mc_v3_timohangout_title")}
                        subtitle={t("wealth:mc_v3_timohangout_title")}
                        description={t("wealth:mc_v3_timohangout_msg")}
                        onNext={onSelectHangoutHandler}
                        hangouts={hangouts}
                        selected={selectedHangout}
                    />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.TIME_SELECTION && (
                    <LspTimeSelection
                        title={t("wealth:mc_v3_when_title")}
                        description={t("wealth:mc_v3_when_msg")}
                        onBack={stepBack}
                        onNext={onSelectTimeHandler}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                    />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.BOOKING_CONFIRM && (
                    <WealthCreditCardBookingConfirmation
                        hangout={selectedHangout}
                        time={selectedTime}
                        onSubmit={sendBookingSubmit}
                        submitting={bookingSubmitting}
                        onBack={stepBack}
                    />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.APPLY_FAILED_SCREEN && (
                    <WealthCreditCardFailed onNext={stepForward} />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.BLOCKED_SCREEN && (
                    <WealthCreditCardBlocked onNext={stepForward} />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.ACTIVATED_SCREEN && (
                    <WealthCreditCardActivated onNext={stepForward} />
                )}
                {step.current ===
                    WEALTH_CREDIT_CARD_STEPS.INVALID_AGE_SCREEN && (
                    <WealthCreditCardInvalidAge onNext={stepForward} />
                )}
                {step.current === WEALTH_CREDIT_CARD_STEPS.CONGRATULATION && (
                    <WealthCreditCardApplySuccessfully
                        onNext={stepForward}
                        bookingInfo={bookingInfo}
                        deliveryInfo={deliveryInfo}
                        fetching={fetchingBooking}
                        applyMethod={applyMethod}
                    />
                )}
            </Content>
        </div>
    );
};

const mapState = (state) => ({
    isForeigner: state.user.info?.isForeigner,
    today: state.user.serverTime.value || new Date(),
});

const mapDispatch = (dispatch) => ({
    setSubProductKey: (payload) =>
        dispatch(wealthCreditCardAction.setSubProductKey(payload)),
    setIsAppliedCreditCard: (payload) =>
        dispatch(wealthCreditCardAction.setIsAppliedCreditCard(payload)),
    resetState: () => dispatch(wealthCreditCardAction.reset()),
    getBookingList: (payload) =>
        dispatch(bookingActions.getBookingList(payload)),
    resetBookingInfo: () => dispatch(bookingActions.reset()),
});

export default memo(connect(mapState, mapDispatch)(WealthCreditCard));
