import LspCheckbox from "@components/LspCheckbox";
import LspTypography from "@components/LspTypography";
import useDate from "@helpers/useDate";
import { Box, makeStyles } from "@material-ui/core";
import { useCallback, memo, useMemo } from "react";

import { NOTIFICATION_CHECKBOX_TYPES } from "./constant";
import NotificationListItem from "./NotificationListItem";

const useStyles = makeStyles((theme) => ({
    line: {
        background: theme.palette.background.default,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));

const NotificationListGroup = ({
    group,
    direction,
    className,
    selectGroup,
    selectItem,
    virtualGroup,
}) => {
    const classes = useStyles();
    const { getLabelByDate } = useDate();

    const getDateGroup = useCallback(
        (item) => getLabelByDate(item?.date, "dd/MM/yyyy", item?.originalDate),
        [getLabelByDate]
    );

    const selectGroupHandler = () => {
        selectGroup(virtualGroup);
    };

    const checked = useMemo(() => {
        if (
            virtualGroup?.checkedType &&
            virtualGroup?.checkedType !== NOTIFICATION_CHECKBOX_TYPES.NO
        ) {
            return true;
        }
        return false;
    }, [virtualGroup?.checkedType]);

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    className={classes.line}
                >
                    <LspCheckbox
                        onChange={selectGroupHandler}
                        checked={checked}
                        className={className}
                        indeterminate={
                            checked &&
                            virtualGroup?.checkedType ===
                                NOTIFICATION_CHECKBOX_TYPES.APART
                        }
                    />

                    <LspTypography variant="heading3" color="black">
                        {getDateGroup(group)}
                    </LspTypography>
                </Box>

                {group?.list?.map((item, index) => {
                    let isChecked = false;
                    if (virtualGroup?.list) {
                        isChecked = virtualGroup?.list[index]?.checked || false;
                    }

                    return (
                        <NotificationListItem
                            key={item.iD}
                            notificationId={item.iD}
                            {...item}
                            toggleSelection={selectItem}
                            selected={isChecked}
                            onClick={direction}
                            className={className}
                            groupId={group.id}
                        />
                    );
                })}
            </Box>
        </>
    );
};

export default memo(NotificationListGroup);
