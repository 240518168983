import Content from "@components/Content";
import { makeStyles, Paper, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageHeaderBack from "@components/PageHeaderBack";
import LspTextField from "@components/LspTextField";
import { useState, useRef, useEffect, useCallback, useMemo, memo } from "react";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import LspButton from "@components/LspButton";
import LspHintInfo from "@components/LspHintInfo";
import {
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_TYPES,
} from "@containers/Wealth/wealthConstants";

import wealthService from "@services/wealth";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useOTP from "@helpers/useOTP";
import LspCongratulation from "@components/LspCongratulation";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        padding: theme.spacing(2),
    },
    error: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        marginTop: theme.spacing(3),
    },
}));

const VinaWealthLinkAccount = ({ onBack, onComplete }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [error, setError] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [requesting, setRequesting] = useState(false);
    const unmounted = useRef(false);
    const [isApplied, setIsApplied] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const hotline = useMemo(() => t("wealth:vc3_vinacapital_hotline"), [t]);
    const contentLinkSuccessfully = useMemo(
        () =>
            t("wealth:vinawealth3_succsesful_web_content").replace(
                "%@",
                hotline
            ),
        [t, hotline]
    );

    const contentLinkFailed = useMemo(
        () => t("wealth:vinawealth3_unsuccessful_mss").replace("%@", hotline),
        [t, hotline]
    );

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onChangeDocumentId = (e) => {
        const { value } = e.target;
        setDocumentId(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PASSPORT_IDNUMBER_REPLACEMENT,
                text: value,
            })
        );
        if (error) {
            setError("");
        }
    };

    const onChangePhoneNumber = (e) => {
        const { value } = e.target;
        setPhoneNumber(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            })
        );
        if (error) {
            setError("");
        }
    };

    const linkAccountHandler = useCallback(
        (code) => {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setIsApplied(true);
                    break;
                case RESPONSE_CODE.LINK_ACCOUNT_EXISTED:
                    setError(
                        t("wealth:vinawealth3_link_account_error2").replace(
                            "%@",
                            hotline
                        )
                    );
                    break;
                case RESPONSE_CODE.LINK_ACCOUNT_FAILED:
                    setError(
                        t("wealth:vinawealth3_link_account_error2").replace(
                            "%@",
                            hotline
                        )
                    );
                    break;
                default:
                    GlobalDialogController.showError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [t, hotline]
    );

    const commitOtp = useCallback(
        async (payload) => {
            setLoading(true);

            const response = await wealthService.actionWealthSubProducts({
                ...payload,
                type: WEALTH_VINA_TYPES.COMMIT,
            });

            if (unmounted.current) {
                return;
            }
            setLoading(false);
            closeOTPDialog();
            const { code } = response.data;

            if (code === RESPONSE_CODE.LINK_ACCOUNT_FAILED) {
                setIsFailed(true);
            } else {
                linkAccountHandler(code);
            }
        },
        [linkAccountHandler, closeOTPDialog, setLoading]
    );

    const onClickLinkAccount = useCallback(
        async (e) => {
            e.preventDefault();
            if (requesting) return;

            setRequesting(true);

            const payload = {
                productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
                phoneNumber,
                idNo: documentId,
                type: WEALTH_VINA_TYPES.APPLY,
            };

            const response = await wealthService.actionWealthSubProducts(
                payload
            );
            if (unmounted.current) return;

            const { code, data } = response.data;

            setRequesting(false);

            if (code === RESPONSE_CODE.OTP_REQUIRED) {
                const desc = t("wealth:vinawealth3_otp_tooltip").replace(
                    "%@",
                    hotline
                );
                openOTPDialog({
                    refNo: data.refNo,
                    submitFunc: commitOtp,
                    dialogDescription: desc,
                });
            } else {
                linkAccountHandler(code);
            }
        },
        [
            phoneNumber,
            documentId,
            requesting,
            commitOtp,
            openOTPDialog,
            linkAccountHandler,
            hotline,
            t,
        ]
    );

    return (
        <Content size="xs">
            {!isApplied && (
                <>
                    <PageHeaderBack onBack={onBack}>
                        {t("wealth:vinawealth3_link_account_header")}
                    </PageHeaderBack>
                    <Paper>
                        <form
                            className={classes.formWrapper}
                            onSubmit={onClickLinkAccount}
                        >
                            <LspTextField
                                autoFocus
                                name="documentId"
                                value={documentId}
                                label={t(
                                    "wealth:vinawealth3_link_account_id_lb"
                                )}
                                inputProps={{
                                    maxLength: 15,
                                }}
                                onChange={onChangeDocumentId}
                            />
                            <LspTextField
                                name="phoneNumber"
                                value={phoneNumber}
                                label={t(
                                    "wealth:vinawealth3_link_account_phone_lb"
                                )}
                                inputProps={{
                                    maxLength: 11,
                                }}
                                onChange={onChangePhoneNumber}
                            />

                            {error && (
                                <FormHelperText className={classes.error} error>
                                    {error}
                                </FormHelperText>
                            )}

                            <LspHintInfo
                                color="active"
                                content={t(
                                    "wealth:vinawealth3_link_account_tooltip"
                                )}
                                icon="hint"
                            />
                            <LspButton
                                fullWidth
                                disabled={!documentId || !phoneNumber}
                                className={classes.button}
                                progressing={requesting}
                                type="submit"
                            >
                                {t("wealth:vinawealth3_link_account_btn")}
                            </LspButton>
                        </form>
                    </Paper>
                </>
            )}
            {isApplied && (
                <LspCongratulation
                    icon="like"
                    title={t("wealth:vinawealth3_successful_message")}
                    contents={contentLinkSuccessfully}
                    button={{
                        label: t("wealth:vinawealth3_ok_btn"),
                        onClick: () => onComplete(),
                    }}
                />
            )}
            {isFailed && (
                <LspCongratulation
                    icon="like"
                    title={t("wealth:vinawealth3_unsuccessful_title")}
                    contents={contentLinkFailed}
                    button={{
                        label: t("wealth:vinawealth3_ok_btn"),
                        onClick: () => onComplete(),
                    }}
                />
            )}
        </Content>
    );
};

export default memo(VinaWealthLinkAccount);
