/* eslint-disable react/no-array-index-key */
import LspTypography from "@components/LspTypography";
import { Skeleton } from "@material-ui/lab";

const InvitationJoinedListLoading = ({ className }) => {
    return (
        <>
            {[...new Array(3)].map((_, index) => {
                return (
                    <div className={className.item} key={index}>
                        <div>
                            <LspTypography>
                                <Skeleton width={200} />
                            </LspTypography>
                            <LspTypography>
                                <Skeleton width={80} />
                            </LspTypography>
                        </div>
                        <div>
                            <Skeleton width={115} height={40} />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default InvitationJoinedListLoading;
