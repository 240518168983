import PropTypes from "prop-types";

import AddDeliveryAddress from "@components/Delivery/AddDeliveryAddress";
import DeliveryOptions from "@components/Delivery/DeliveryOptions";
import DeliveryPickUpHangOut from "@components/Delivery/DeliveryPickUpHangOut";
import DeliveryReview from "@components/Delivery/DeliveryReview";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import i18n from "@i18n/";
import { Fade, Grid, Icon, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import CardManagementActions from "@redux/actions/card-management";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState, memo, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

import CarouselCard from "@components/Delivery/CarouselCard";
import { DELIVERY_ADDRESS } from "@containers/Booking/constant";
import useNumber from "@helpers/useNumber";
import cardManagementService from "@services/card-management";
import { RESPONSE_CODE } from "@config/constants";
import { CARD_PRODUCTS, REISSUE_CARD_VERSION } from "../constant";
import PhysicalCardIntro from "./PhysicalCardIntro";

const useStyles = makeStyles((theme) => ({
    reIssuedCard: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
    },
    reIssuedCardHeader: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        color: theme.palette.black.main,
        marginBottom: theme.spacing(5.5),
        marginTop: theme.spacing(1.5),
    },
    reIssuedCardBody: {
        marginBottom: theme.spacing(8.5),
    },
    reIssuedCardBodyCarouselCard: {
        marginBottom: theme.spacing(3),
    },
    reIssuedCardText: {
        display: "flex",
        justifyContent: "center",
        color: theme.palette.grey[400],
        textAlign: "center",
        minHeight: 44,
    },
    reIssuedCardFooter: {},
    reIssuedCardModalContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
        outline: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    reIssuedCardModalBody: {
        width: 580,
    },
    reIssuedSuccess: {
        backgroundColor: theme.palette.success.main,
    },
}));

const TABS = {
    DELIVERY_REISSUED_CARD: 0,
    DELIVERY_OPTIONS: 1,
    DELIVERY_ITEMS: 2,
    DELIVERY_PICK_UP_HANG_OUT: 3,
    DELIVERY_REVIEW: 4,
    DELIVERY_ADD_NEW_ADDRESS: 5,
    DELIVERY_ERROR: 6,
    SUCCESS: 7,
    INTRO: 8,
};

const DELIVERY_TYPES = {
    HANGOUT: 7,
    DELIVERY: 9,
};

const DELIVERY_METHOD = {
    MAIL: "Mail",
    HANGOUT: "Hangout",
};

/**
 * @param {object} props
 * @param {string} props.cardId
 * @param {string} props.cardImageUrl
 * @param {(REISSUE_CARD_VERSION.VIRTUAL|REISSUE_CARD_VERSION.CARD_V2|REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD)} props.reissueVersion
 * @param {boolean} props.isValidIDDocument
 * @param {object} props.contentWithAction
 */

const ReIssuedCard = ({
    getUserAddressRequest,
    hangoutListCard,
    isValidIDDocument,
    userInfo,
    deliveryFeeCard,
    reIssueCardSubmit,
    addressFetching,
    isSubmitLoading,
    resetState,
    cardId,
    isEkycUser,
    cardImageUrl,
    reissueVersion,
    addressData,
    virtualAccountName,
    virtualAccountId,
    accountNumber,
    setSuccessData,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedCard, setSelectedCard] = useState({
        activeBorder: false,
        selectedItem: "",
        disableSelect: false,
    });

    const [activeDisableViewMode, setActiveDisableViewMode] = useState(false);
    const [activeTab, setActiveTab] = useState(() => {
        switch (reissueVersion) {
            case REISSUE_CARD_VERSION.VIRTUAL:
                return TABS.DELIVERY_REVIEW;
            case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
                return TABS.INTRO;
            case REISSUE_CARD_VERSION.CARD_V2:
            default:
                return TABS.DELIVERY_REISSUED_CARD;
        }
    }, [reissueVersion]);

    const [reissuedCardData, setReissuedCardData] = useState(null);
    const [open, setOpen] = useState(true);
    const [reviewData, setReviewData] = useState({});
    const [submitData, setSubmitData] = useState({});
    const [selectAddress, setSelectAddress] = useState(null);
    const [hangoutList, setHangoutList] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [address, setAddress] = useState({});
    const [cardImage, setCardImage] = useState(cardImageUrl);

    const { formatNumber } = useNumber();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
            resetState();
        };
    }, [resetState]);

    useEffect(() => {
        if (reissueVersion !== REISSUE_CARD_VERSION.VIRTUAL) return;

        const reviewShowData = {
            fullName: userInfo?.fullName || "",
            phoneNumber: userInfo?.phoneNumber || "",
            legalId: userInfo?.legalId || userInfo?.nationalId || "",
            cardImageUrl: cardImage,
        };

        setReviewData(reviewShowData);
    }, [
        reissueVersion,
        cardImage,
        userInfo?.fullName,
        userInfo?.phoneNumber,
        userInfo?.legalId,
        userInfo?.nationalId,
    ]);

    const getFeeAction = useCallback(async () => {
        const payload = {
            language: i18n.language,
            cardProduct: CARD_PRODUCTS.PHYSICAL_VISA_DEBIT,
        };
        const response = await cardManagementService.getFeeCardAction(payload);

        const { code, data } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            setAddress(data);
            setHangoutList(data?.listReceiveRequestFeeHO);
            setDelivery(data?.listReceiveRequestFeeDelivery);
            setCardImage(data?.cardImageUrl);
            return;
        }

        GlobalDialogController.showError({ errorCode: code });
    }, []);

    useEffect(() => {
        if (reissueVersion === REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD) {
            getFeeAction();
            return;
        }

        if (!hangoutListCard) {
            getUserAddressRequest({
                lang: i18n.language,
                actionId: [DELIVERY_TYPES.HANGOUT, DELIVERY_TYPES.DELIVERY],
            });
            return;
        }

        setHangoutList(hangoutListCard);
    }, [getUserAddressRequest, hangoutListCard, getFeeAction, reissueVersion]);

    useEffect(() => setDelivery(deliveryFeeCard), [deliveryFeeCard]);
    useEffect(() => setAddress(addressData), [addressData]);

    useEffect(() => {
        if (isEmpty(reissuedCardData?.hangout) && hangoutList?.length > 0) {
            const defaultHangout = hangoutList.find(
                (hangout) => hangout.receiveRequestId === 1
            );

            setReissuedCardData((prev) => ({
                ...prev,
                hangout: {
                    ...defaultHangout,
                    title: defaultHangout?.content,
                    description: defaultHangout?.contentDetail,
                },
            }));
        }
    }, [reissuedCardData?.hangout, hangoutList]);

    const handleSelectCarouselCard = (data) => {
        setSelectedCard((prev) => ({
            ...prev,
            selectedItem: data.cardVersion || "",
            cardImageUrl: data.cardImageUrl || "",
            amount: data.amount || 0,
            idCardVersion: data.idCardVersion || "",
        }));
    };

    const handleOpenDeliveryOptions = () => {
        setActiveTab(TABS.DELIVERY_OPTIONS);
    };

    const handleOpen = () => {
        if (selectedCard.selectedItem) {
            setOpen(true);
            setActiveDisableViewMode(true);
            setSelectedCard((prev) => ({
                ...prev,
                disableSelect: true,
                activeBorder: true,
            }));
        }
    };

    const handleClose = () => {
        GlobalDialogController.hide();
    };

    // for delivery options

    const onHangOutSubmitChange = () => {
        setActiveTab(TABS.DELIVERY_PICK_UP_HANG_OUT);
    };

    const handleNewAddressChange = () => {
        setActiveTab(TABS.DELIVERY_ADD_NEW_ADDRESS);
    };

    const mapbodyRequestPhysicalCard = (reviewInfo, originalData) => {
        const deliveryType = originalData?.delivery;

        const bodyReq = {
            cardId: reviewInfo?.cardId,
            lang: i18n.language,
            notification: "sms",
            deliveryMethod: reviewInfo?.deliveryMethod,
            addressType: reviewInfo?.addressType,
            provinceId: reviewInfo?.provinceId,
        };

        if (accountNumber) {
            bodyReq.accountNumber = accountNumber;
        } else {
            bodyReq.virtualAccountId =
                virtualAccountId || t("home_lb_spend_account");
        }
        switch (deliveryType) {
            case DELIVERY_ADDRESS.CURRENT_ADDRESS:
            case DELIVERY_ADDRESS.PERMANENT_ADDRESS:
                const addressInfo = originalData?.data;
                bodyReq.address = addressInfo?.addressName;
                bodyReq.province = addressInfo?.cityId;
                bodyReq.district = addressInfo?.distId;
                bodyReq.ward = addressInfo?.wardId;
                bodyReq.fullAddress = addressInfo?.address;
                bodyReq.receiveDetail = addressInfo?.address;
                bodyReq.receiveRequestDeliveryId =
                    reviewInfo?.receiveRequestDeliveryId;
                break;
            case DELIVERY_ADDRESS.OTHER_ADDRESS:
                bodyReq.address = reviewInfo?.address;
                bodyReq.province = reviewInfo?.province;
                bodyReq.district = reviewInfo?.district;
                bodyReq.ward = reviewInfo?.ward;
                bodyReq.fullAddress = reviewInfo?.fullAddress;
                bodyReq.receiveDetail = reviewInfo?.receiveDetail;
                bodyReq.receiveRequestDeliveryId =
                    reviewInfo?.receiveRequestDeliveryId;
                break;
            default:
                bodyReq.receiveRequestHOId = reviewInfo?.receiveRequestHOId;
                bodyReq.receiveDetail = reviewInfo?.receiveDetail;
                break;
        }

        return bodyReq;
    };

    const handleDeliveryOptionSubmit = (data) => {
        let review = {};
        // eslint-disable-next-line prefer-const
        let reviewShowData = {
            fullName: userInfo?.fullName || "",
            phoneNumber: userInfo?.phoneNumber || "",
            legalId: userInfo?.legalId || userInfo?.nationalId || "",
            reIssueFree: selectedCard?.amount || 0,
            cardVersion: selectedCard.selectedItem,
            cardImageUrl: selectedCard.cardImageUrl || cardImage,
        };

        let isCurrentAddressSelected = false;
        switch (data.delivery) {
            case DELIVERY_ADDRESS.CURRENT_ADDRESS:
                isCurrentAddressSelected = true;
            /* falls through */
            case DELIVERY_ADDRESS.PERMANENT_ADDRESS:
                const pickUpPrivate = delivery.find(
                    (item) => item.receiveType === DELIVERY_ADDRESS.PRIVATE
                );
                reviewShowData.deliveryFee = pickUpPrivate.amount;
                reviewShowData.receiveDetail = data?.data?.address;
                reviewShowData.address = data?.data?.addressName;
                review = {
                    ...review,
                    cardId,
                    cardVersion: selectedCard?.selectedItem,
                    lang: i18n.language,
                    notification: "sms",
                    requestHOId: DELIVERY_TYPES.HANGOUT,
                    addressType:
                        data.delivery === DELIVERY_ADDRESS.CURRENT_ADDRESS
                            ? 1
                            : 2,
                    deliveryMethod: DELIVERY_METHOD.MAIL,
                    receiveRequestDeliveryId: 2,
                    requestDeliveryId: DELIVERY_TYPES.DELIVERY,
                    receiveRequestHOId:
                        reissuedCardData?.hangout?.receiveRequestId,
                    provinceId: parseInt(data?.data?.cityId),
                    idCardVersion: selectedCard.idCardVersion,
                };
                if (isCurrentAddressSelected) {
                    setSelectAddress({ currentAddress: true });
                } else {
                    setSelectAddress({ permanentAddress: true });
                }
                break;
            case DELIVERY_ADDRESS.OTHER_ADDRESS:
                review = {
                    ...review,
                    cardId,
                    cardVersion: selectedCard.selectedItem,
                    lang: i18n.language,
                    notification: "sms",
                    requestHOId: DELIVERY_TYPES.HANGOUT,
                    addressType: 3,
                    deliveryMethod: DELIVERY_METHOD.MAIL,
                    address: data?.data?.addressName,
                    province: data?.data?.cityId,
                    district: data?.data?.distId,
                    ward: data?.data?.wardId,
                    fullAddress: data?.data?.address,
                    receiveDetail: data?.data?.address,
                    receiveRequestDeliveryId: 3,
                    requestDeliveryId: DELIVERY_TYPES.DELIVERY,
                    receiveRequestHOId:
                        reissuedCardData?.hangout?.receiveRequestId,
                    provinceId: parseInt(data?.data?.cityId),
                    idCardVersion: selectedCard.idCardVersion,
                };
                const pickUpOther = delivery.find(
                    (item) =>
                        item.receiveType === DELIVERY_ADDRESS.OTHER_ADDRESS
                );
                reviewShowData.deliveryFee = pickUpOther.amount;
                reviewShowData.receiveDetail = data?.data?.address;
                setSelectAddress({ newAddress: true });

                break;
            default:
                reviewShowData.receiveDetail = data?.data?.contentDetail;
                reviewShowData.isHangout = true;
                reviewShowData.deliveryFee = 0;
                reviewShowData.addressLabel = `${data?.data?.content} ${t(
                    "lb_hangout"
                )}`;
                review = {
                    ...review,
                    cardId,
                    cardVersion: selectedCard?.selectedItem,
                    lang: i18n.language,
                    notification: "sms",
                    requestHOId: DELIVERY_TYPES.HANGOUT,
                    receiveRequestHOId:
                        reissuedCardData?.hangout?.receiveRequestId,
                    deliveryMethod: DELIVERY_METHOD.HANGOUT,
                    provinceId: parseInt(reissuedCardData?.hangout?.provinceId),
                    idCardVersion: selectedCard.idCardVersion,
                    receiveDetail: data?.data?.contentDetail,
                };
                setSelectAddress({ hangOut: true });

                break;
        }

        if (reissueVersion === REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD) {
            reviewShowData.virtualAccountName =
                virtualAccountName || t("home_lb_spend_account");

            reviewShowData.annualFee = address?.annualFee || 0;
            reviewShowData.issuanceFee = address?.issuanceFee || 0;
            reviewShowData.deliveryFee = address?.deliveryFee || 0;
            reviewShowData.reIssueFree = undefined;
        }

        const finalSubmitData =
            reissueVersion === REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD
                ? mapbodyRequestPhysicalCard(review, data)
                : review;

        setSuccessData(reviewShowData);
        setReviewData(reviewShowData);
        setSubmitData(finalSubmitData);
        setActiveTab(TABS.DELIVERY_REVIEW);
    };

    const handleDeliveryOptionCancel = () => {
        if (reissueVersion === REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD) {
            setActiveTab(TABS.INTRO);
            return;
        }

        setActiveTab(TABS.DELIVERY_REISSUED_CARD);
    };
    // for add new address modal
    const handleOpenAddNewAddress = () => {
        setActiveTab(TABS.DELIVERY_ADD_NEW_ADDRESS);
    };

    const onAddDeliveryAddressSubmit = (data) => {
        setReissuedCardData((prev) => ({
            ...prev,
            newAddress: data,
        }));
        setActiveTab(TABS.DELIVERY_OPTIONS);
        setSelectAddress({ newAddress: true });
    };

    const onAddDeliveryAddressClose = () => {
        setActiveTab(TABS.DELIVERY_OPTIONS);
    };

    // for pick up at hangout
    const handleHangOutClose = () => {
        setActiveTab(TABS.DELIVERY_OPTIONS);
    };

    const handleHangOutSubmit = (data) => {
        const hangouts = hangoutList.find(
            (hangout) => hangout.receiveRequestId === data.cfgKey
        );
        setReissuedCardData((prev) => ({
            ...prev,
            hangout: {
                ...hangouts,
                title: hangouts?.content,
                description: hangouts?.contentDetail,
            },
        }));
        setSelectAddress({ hangOut: true });
        setActiveTab(TABS.DELIVERY_OPTIONS);
    };

    // for review

    const handleReviewCancel = useCallback(() => {
        if (reissueVersion === REISSUE_CARD_VERSION.VIRTUAL) {
            GlobalDialogController.hide();
            return;
        }

        setActiveTab(TABS.DELIVERY_OPTIONS);
    }, [reissueVersion]);

    const handleReviewSubmit = useCallback(() => {
        const payload =
            reissueVersion === REISSUE_CARD_VERSION.VIRTUAL
                ? { cardId }
                : cloneDeep(submitData);

        reIssueCardSubmit({ ...payload, version: reissueVersion });
    }, [reIssueCardSubmit, submitData, cardId, reissueVersion]);

    const handleBackAction = () => {
        setActiveDisableViewMode(false);
        setSelectedCard((pre) => ({
            ...pre,
            activeBorder: false,
            disableSelect: false,
        }));
    };

    const disableUserAddress = useMemo(() => {
        const isHidePermanentAddress = !!userInfo?.isForeigner;

        if (
            reissueVersion !== REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD &&
            (!isValidIDDocument || isEkycUser)
        ) {
            return {
                currentAddress: true,
                permanentAddress: true,
                newAddress: true,
                hangOut: false,
                hidePermanentAddress: isHidePermanentAddress,
            };
        }

        return {
            currentAddress: false,
            permanentAddress: false,
            newAddress: false,
            hangOut: false,
            hidePermanentAddress: isHidePermanentAddress,
        };
    }, [isValidIDDocument, isEkycUser, reissueVersion, userInfo]);

    return (
        <>
            <Fade in={open}>
                <div className={classes.reIssuedCardModalContainer}>
                    <div className={classes.reIssuedCardModalBody}>
                        {activeTab === TABS.DELIVERY_REISSUED_CARD && (
                            <div className={classes.reIssuedCard}>
                                <div className={classes.reIssuedCardHeader}>
                                    <LspTypography variant="title2">
                                        {addressFetching ? (
                                            <Skeleton width={250} />
                                        ) : (
                                            t(
                                                `ekyc:select_card_${selectedCard.selectedItem}_title`
                                            )
                                        )}
                                    </LspTypography>
                                </div>
                                <div className={classes.reIssuedCardBody}>
                                    <div
                                        className={
                                            classes.reIssuedCardBodyCarouselCard
                                        }
                                    >
                                        <CarouselCard
                                            activeBorder={
                                                selectedCard.activeBorder
                                            }
                                            selectedItem={
                                                selectedCard.selectedItem
                                            }
                                            disableSelect={
                                                selectedCard.disableSelect
                                            }
                                            onSelectCard={(data) =>
                                                handleSelectCarouselCard(data)
                                            }
                                            loading={addressFetching}
                                        />
                                    </div>
                                    <div className={classes.reIssuedCardText}>
                                        {addressFetching ? (
                                            <Skeleton width="50%" height={40} />
                                        ) : (
                                            <LspTypography
                                                variant="title2"
                                                color="black"
                                            >
                                                {`${formatNumber(
                                                    selectedCard?.amount
                                                )} VND`}
                                            </LspTypography>
                                        )}
                                    </div>

                                    <LspTypography
                                        className={classes.reIssuedCardText}
                                        variant="body2"
                                    >
                                        {addressFetching ? (
                                            <Skeleton width="80%" height={15} />
                                        ) : (
                                            <>
                                                {!selectedCard?.selectedItem &&
                                                    t(
                                                        "ekyc:reissue_card_V4_details"
                                                    )}
                                                {selectedCard?.selectedItem &&
                                                    t(
                                                        `ekyc:reissue_card_${selectedCard.selectedItem}_details`
                                                    )}
                                            </>
                                        )}
                                    </LspTypography>
                                </div>
                                <div className={classes.reIssuedCardFooter}>
                                    {!activeDisableViewMode && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <LspButton
                                                    fullWidth
                                                    disabled={addressFetching}
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                >
                                                    {t("lb_cancel")}
                                                </LspButton>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LspButton
                                                    fullWidth
                                                    onClick={handleOpen}
                                                    disabled={
                                                        addressFetching ||
                                                        selectedCard
                                                            .selectedItem
                                                            .cardVersion === ""
                                                    }
                                                >
                                                    {t(
                                                        "ekyc:select_card_CTA_select"
                                                    )}
                                                </LspButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeDisableViewMode && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <LspButton
                                                    fullWidth
                                                    variant="secondary"
                                                    onClick={handleBackAction}
                                                >
                                                    {t("lb_back")}
                                                </LspButton>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LspButton
                                                    fullWidth
                                                    variant="success"
                                                    onClick={
                                                        handleOpenDeliveryOptions
                                                    }
                                                    buttonProps={{
                                                        startIcon: (
                                                            <Icon className="font-icon icon-checkmark" />
                                                        ),
                                                    }}
                                                >
                                                    {t("lb_next")}
                                                </LspButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                </div>
                            </div>
                        )}

                        {activeTab === TABS.INTRO && (
                            <PhysicalCardIntro
                                onClose={handleClose}
                                onNext={handleOpenDeliveryOptions}
                                data={address}
                                cardImageUrl={cardImage}
                            />
                        )}

                        {activeTab === TABS.DELIVERY_OPTIONS && (
                            <DeliveryOptions
                                disableUserAddress={disableUserAddress}
                                onAddNewAddressOpen={handleOpenAddNewAddress}
                                newAddress={reissuedCardData?.newAddress}
                                onHangOutSubmitChange={onHangOutSubmitChange}
                                hangout={reissuedCardData?.hangout}
                                onNewAddressChange={handleNewAddressChange}
                                onDeliveryOptionsSubmit={
                                    handleDeliveryOptionSubmit
                                }
                                onDeliveryOptionsClose={
                                    handleDeliveryOptionCancel
                                }
                                selectAddress={selectAddress}
                                address={address}
                            />
                        )}

                        {activeTab === TABS.DELIVERY_ADD_NEW_ADDRESS && (
                            <AddDeliveryAddress
                                onAddDeliveryAddressSubmit={(data) =>
                                    onAddDeliveryAddressSubmit(data)
                                }
                                onAddDeliveryAddressClose={
                                    onAddDeliveryAddressClose
                                }
                                selected={reissuedCardData?.newAddress}
                            />
                        )}

                        {activeTab === TABS.DELIVERY_PICK_UP_HANG_OUT && (
                            <DeliveryPickUpHangOut
                                onDeliveryPickUpHangOutClose={
                                    handleHangOutClose
                                }
                                onDeliveryPickUpHangOutSubmit={(data) =>
                                    handleHangOutSubmit(data)
                                }
                                hangoutList={hangoutList}
                                loading={addressFetching}
                            />
                        )}

                        {activeTab === TABS.DELIVERY_REVIEW && (
                            <DeliveryReview
                                reviews={reviewData}
                                onReviewCancel={handleReviewCancel}
                                onReviewSubmit={handleReviewSubmit}
                                loading={isSubmitLoading}
                                deliveryVersion={reissueVersion}
                            />
                        )}
                    </div>
                </div>
            </Fade>
        </>
    );
};

ReIssuedCard.propTypes = {
    cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cardImageUrl: PropTypes.string,
    reissueVersion: PropTypes.string,
    isValidIDDocument: PropTypes.bool,
};

ReIssuedCard.defaultProps = {
    cardId: "",
    cardImageUrl: "",
    reissueVersion: REISSUE_CARD_VERSION.CARD_V2,
    isValidIDDocument: false,
};

const mapStateToProps = (state) => ({
    addressData: state.cardInfo.address.data,
    addressFetching: state.cardInfo.address.fetching,
    hangoutListCard: state.cardInfo.address?.data?.listReceiveRequestFeeHO,
    userInfo: state.user.info,
    deliveryFeeCard:
        state.cardInfo.address?.data?.listReceiveRequestFeeDelivery,
    isEkycUser: state.cardInfo.address?.data?.isEkycUser,
    isSubmitLoading: state.cardInfo.reIssueCardLoading,
});

const dispatchProps = (dispatch) => ({
    getUserAddressRequest: (payload) =>
        dispatch(CardManagementActions.getUserAddressRequest(payload)),
    reIssueCardSubmit: (payload) =>
        dispatch(CardManagementActions.reIssueCardV2(payload)),
    resetState: () => dispatch(CardManagementActions.resetStateNewReissue()),
    setSuccessData: (payload) =>
        dispatch(CardManagementActions.setReviewDataRequestCard(payload)),
});

export default connect(mapStateToProps, dispatchProps)(memo(ReIssuedCard));
