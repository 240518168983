/* eslint-disable react/forbid-prop-types */
import { Box, ButtonBase } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import LspTypography from "../LspTypography";

/**
 * @typedef LeftObject
 * @type {object}
 * @property {string} title
 * @property {string} description
 */

/**
 * @typedef RightObject
 * @type {object}
 * @property {string} label
 * @property {number} amount
 */

/**
 * @typedef ItemObject
 * @type {object}
 * @property {string} id
 * @property {LeftObject} left
 * @property {RightObject} right
 */

/**
 * @typedef LspCardItemBasicProps
 * @type {object}
 * @property {object} classes
 * @property {function} onClick
 * @property {bool} selected
 * @property  {ItemObject} item
 */

const LspCardItemBasic = ({ selected, onClick, item, classes }) => {
    const { thousandSeparator, decimalSeparator } = useNumber();

    return (
        <ButtonBase
            className={clsx(classes.buttonItem, {
                [classes.selected]: selected,
            })}
            key={item?.id}
            onClick={() => onClick(item)}
        >
            <div>
                <Box mb={item?.left?.description ? 1 : 0} width="100%">
                    <LspTypography
                        variant="heading2"
                        color={selected ? "blue" : "primary"}
                    >
                        {item?.left?.title.toUpperCase()}
                    </LspTypography>
                </Box>
                <LspTypography variant="body2" color="grey">
                    {item?.left?.description}
                </LspTypography>
                <LspTypography variant="heading2" color="primary">
                    {item?.left?.amount}
                </LspTypography>
            </div>
            <div>
                <LspTypography
                    variant="body2"
                    color="grey"
                    className={classes.label}
                >
                    {item?.right?.label}
                </LspTypography>
                <LspTypography variant="heading2" color="primary">
                    <NumberFormat
                        value={item?.right?.amount}
                        displayType="text"
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                    />
                </LspTypography>
            </div>
        </ButtonBase>
    );
};

LspCardItemBasic.propTypes = {
    selected: PropTypes.bool,
    classes: PropTypes.object,
    onClick: PropTypes.func,
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        left: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        right: PropTypes.shape({
            label: PropTypes.string,
            amount: PropTypes.number,
        }),
    }),
};

LspCardItemBasic.defaultProps = {
    selected: false,
    onClick: null,
    classes: null,
    item: null,
};

export default memo(LspCardItemBasic);
