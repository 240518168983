import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import invitationService from "@services/invitation";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import EditReferralCode from "./EditReferralCode";
import EditReferralCodeConfirmation from "./EditReferralCodeConfirmation";

const InvitationEditReferralCode = ({
    referralCode,
    onClose,
    onUpdateReferralCode,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [refCode, setRefCode] = useState("");
    const [error, setError] = useState("");
    const unmounted = useRef(false);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        if (unmounted.current) return;

        if (referralCode) {
            setRefCode(referralCode);
        }
    }, [referralCode]);

    const onConfirm = () => {
        setStep(1);
    };

    const onCancel = () => {
        setStep(0);
    };

    const saveReferralCodeHandler = async () => {
        setLoading(true);
        const response = await invitationService.updateReferralCode({
            referralCode: refCode,
        });

        if (unmounted.current) return;

        const { code, data } = response.data;
        setLoading(false);
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                AlertController.show(t("master:invite_friend_green_dropdown"));
                onUpdateReferralCode(data.referralCode);
                break;
            case RESPONSE_CODE.REFERRAL_CODE_INVALID: // Referral Code is blank or existed
                setError(t("master:invite_friend_used_code_msg"));
                onCancel();
                break;
            default:
                AlertController.show(
                    t("master:invite_friend_red_dropdown"),
                    AlertType.Error
                );
                onCancel();
                break;
        }
    };

    const onChangeRefCode = (event) => {
        let code = event.target.value;
        code = code.replace(REGEX_PATTERN.REFERRAL_CODE, "");
        setRefCode(code);
    };

    return (
        <>
            {step === 0 && (
                <EditReferralCode
                    referralCode={refCode}
                    onClose={onClose}
                    onConfirmSaveReferralCode={onConfirm}
                    onChangeRefCode={onChangeRefCode}
                    error={error}
                />
            )}
            {step === 1 && (
                <EditReferralCodeConfirmation
                    onCancel={onCancel}
                    saveReferralCodeHandler={saveReferralCodeHandler}
                    loading={loading}
                />
            )}
        </>
    );
};

export default InvitationEditReferralCode;
