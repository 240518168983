/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import { makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { memo } from "react";
import LspButton from "@components/LspButton";
import PageHeaderBack from "@components/PageHeaderBack";
import { isFunction } from "lodash";
import { connect } from "react-redux";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: theme.spacing(3),
    },
    list: {
        paddingLeft: 0,
        "& > li": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
            "&:last-child": {
                marginBottom: theme.spacing(3),
            },
        },
    },
}));

const VinaWealthBeforeYouStart = ({ onBack, onNext, isForeigner }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const content = t("wealth:vc3_start_investing_content", {
        returnObjects: true,
    });

    const onClickHandler = () => {
        if (isForeigner) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "managepolicy",
                    header: t("wealth:vinawealth2_foreign_popup_title"),
                    translatedContent: t(
                        "wealth:vinawealth2_foreign_popup_body"
                    ),
                    content: t("wealth:vinawealth2_foreign_popup_share"),
                    lspTranslation: t(
                        "wealth:vinawealth2_foreign_popup_disclaim"
                    ),
                    button: t("wealth:vinawealth2_foreign_popup_btn"),
                    doubleButton: [
                        {
                            label: t("wealth:vinawealth2_foreign_popup_btn"),
                            onClick: () => {
                                GlobalDialogController.hide();
                                if (onNext && isFunction(onNext)) {
                                    onNext(VINA_WEALTH_STEPS.FOREIGNER_WARNING);
                                }
                            },
                        },
                        {
                            label: t("lb_cancel"),
                            onClick: () => GlobalDialogController.hide(),
                        },
                    ],
                },
            });
            return;
        }

        if (onNext && isFunction(onNext)) {
            onNext(VINA_WEALTH_STEPS.DASHBOARD);
        }
    };

    return (
        <Content size="xs">
            <PageHeaderBack onBack={onBack}>
                {t("wealth:vc3_start_investing_header")}
            </PageHeaderBack>
            <Paper className={classes.box}>
                <ul className={classes.list}>
                    {content?.map((c, index) => (
                        <li key={index}>{c}</li>
                    ))}
                </ul>
                <LspButton
                    fullWidth
                    className={classes.button}
                    onClick={onClickHandler}
                >
                    {t("wealth:vc3_letgo_btn")}
                </LspButton>
            </Paper>
        </Content>
    );
};

const mapState = (state) => ({
    isForeigner: state.user.info?.isForeigner,
});

export default memo(connect(mapState)(VinaWealthBeforeYouStart));
