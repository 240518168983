import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import PageHeader from "@components/PageHeader";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import useNumber from "@helpers/useNumber";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    phoneBox: {
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.magicalGrey[200]}`,
        padding: theme.spacing(2),
        textAlign: "center",
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(2.5),
    },
    amount: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(24),
    },
    transferWrapper: {},
}));

const TransferInfo = ({
    back,
    next,
    phoneNumber,
    onAmountChange,
    amount,
    onChangeDescription,
    description,
    errors,
    loading,
}) => {
    const { t } = useTranslation("translation", "payAnyone");
    const classes = useStyles();
    const { thousandSeparator, decimalSeparator } = useNumber();

    return (
        <div>
            <PageHeader backFunc={back}>
                <div>{t("lb_move_money")} </div>
            </PageHeader>
            <Content size="sm">
                <form onSubmit={next}>
                    <Box
                        component={Paper}
                        p={3}
                        className={classes.transferWrapper}
                    >
                        <div className={classes.phoneBox}>
                            <Typography>
                                {t("payAnyone:to_this_phone_title")}
                            </Typography>
                            <Typography className={classes.amount}>
                                {phoneNumber}
                            </Typography>
                        </div>

                        <NumberFormat
                            value={amount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onAmountChange}
                            label={t("payee_card_amount_label")}
                            error={!!errors.amount}
                            helperText={errors.amount || " "}
                            customInput={LspTextField}
                        />
                        <LspTextField
                            label={t("payee_card_description_label")}
                            helperText=" "
                            onChange={(e) => {
                                onChangeDescription(
                                    escapeAccentVietnamese(e.target.value)
                                );
                            }}
                            value={description}
                            inputProps={{
                                maxLength: 150,
                            }}
                            multiline
                        />
                        <LspButton
                            type="submit"
                            fullWidth
                            progressing={loading}
                        >
                            {t("lb_move_money")}
                        </LspButton>
                    </Box>
                </form>
            </Content>
        </div>
    );
};

export default TransferInfo;
