export const REQUEST_DOC_FORM_TYPE = {
    oneFieldDate: "1", // request date
    twoFieldsDate: "2", // fromDate - toDate
    transactionID: "3", // one field transaction ID
    threeFields: "5", // fromDate - toDate - cycle field
};

export const REQUEST_DOC_STEPS = {
    CONFIRM_DOCUMENT: 1,
    CONFIRM_AND_PAYMENT_DOCUMENT: 2,
    CHOOSE_ACCOUNT: 3,
};

export const RD_RECEIVED_PLACE = {
    HANGOUT: "Hangout",
    PRIVATE: "Private",
    OTHER: "Other",
};

export const REQUEST_DOC_TYPE = {
    GET_ACCOUNT_LIST: "1",
};
