import api from "../config/api";

const getListDevice = async () => {
    return await api.get("/user/device", null);
};

const editDeviceName = async (data) => {
    return await api.post(`/user/device/${data.deviceId}`, data);
};

const deleteDevice = async (data) => {
    return await api.delete(`/user/device/${data.deviceId}`, data);
};

const manageDeviceService = {
    getListDevice,
    editDeviceName,
    deleteDevice,
};

export default manageDeviceService;
