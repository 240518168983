import { isObject } from "lodash";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReceiptContent from "./ReceiptContent";

/**
 * A Receipt-Like preview before make a transfer
 * @param {object} props
 * @param {object} props.receiptData
 * @param {string} props.receiptData.title
 * @param {number} props.receiptData.txnAmount
 * @param {string} [props.receiptData.txnCurrency='VND']
 * @param {object} [props.receiptData.txnFrom]
 * @param {string} [props.receiptData.txnFrom.accName]
 * @param {string} [props.receiptData.txnFrom.bankName]
 * @param {string} [props.receiptData.txnFrom.accNo]
 * @param {object} props.receiptData.txnTo
 * @param {string} props.receiptData.txnTo.accName
 * @param {string} props.receiptData.txnTo.bankName
 * @param {string} props.receiptData.txnTo.accNo
 * @param {string} props.receiptData.txnDesc
 * @returns
 */
const LspReceiptPreview = (props) => {
    const { t } = useTranslation();
    const { noMarginTop } = props;
    const receiptInfo = useMemo(() => {
        const mappedFrom = [];
        const mappedTo = [];

        if (isObject(props.txnFrom)) {
            Object.keys(props.txnFrom).forEach((fieldName) => {
                // mappedFrom.push(props.txnFrom[fieldName]);

                mappedFrom.push({
                    value: props?.txnTo[fieldName],
                    key: fieldName,
                    enableHideInfo: false,
                    hideStatus: false,
                });
            });
        }
        if (isObject(props.txnTo)) {
            Object.keys(props.txnTo).forEach((fieldName) => {
                mappedTo.push({
                    value: props?.txnTo[fieldName],
                    key: fieldName,
                    enableHideInfo: false,
                    hideStatus: false,
                });
            });
        }

        return {
            title: t("lb_amount_web"),
            ...props,
            txnFrom: mappedFrom,
            txnTo: mappedTo,
        };
    }, [props, t]);

    return (
        <ReceiptContent
            receiptData={receiptInfo}
            noMarginTop={noMarginTop}
            noReceiptStatus
            enableAddNote={false}
            enableAddAttachment={false}
        />
    );
};

export default memo(LspReceiptPreview);
