import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspCardList from "@components/LspCardList";
import PageHeaderBack from "@components/PageHeaderBack";
import { Box } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BOOKING_SERVICES, BOOKING_STEPS, DELIVERY_METHODS } from "../constant";

const CreateBookingPickupMethod = ({
    serviceName,
    onCancel,
    onNext,
    setBookingMethod,
    availableMethod,
}) => {
    const { t } = useTranslation();

    const content = useMemo(() => {
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
            default:
                const methods = [
                    {
                        id: DELIVERY_METHODS.HANGOUT,
                        title: t("booking_pickup"),
                        description: t("booking_pickup_description"),
                        isAvailable: availableMethod?.isHangout,
                    },
                    {
                        id: DELIVERY_METHODS.DELIVERY,
                        title: t("booking_delivery"),
                        description: t("booking_delivery_description"),
                        isAvailable: availableMethod?.isDelivery,
                    },
                ];

                return {
                    title: t("master:choose_way_to_receive_card"),
                    methods,
                };
        }
    }, [serviceName, t, availableMethod]);

    const onSelectMethod = useCallback(
        (method) => {
            switch (method?.id) {
                case DELIVERY_METHODS.HANGOUT:
                    setBookingMethod(method?.id);
                    onNext(BOOKING_STEPS.HANGOUT_PROVINCE_SELECTION);
                    break;
                case DELIVERY_METHODS.DELIVERY:
                    // Only have delivery by mail option > skip delivery method screen
                    if (serviceName === BOOKING_SERVICES.REPLACE_CHIP_BOOKING) {
                        setBookingMethod(DELIVERY_METHODS.MAIL);
                        onNext(BOOKING_STEPS.DELIVERY_ADDRESS_SELECTION);
                        return;
                    }

                    onNext(BOOKING_STEPS.DELIVERY_METHOD_SELECTION);
                    break;
                default:
                    break;
            }
        },
        [onNext, setBookingMethod, serviceName]
    );

    return (
        <Content size="sm">
            <PageHeaderBack>{content?.title}</PageHeaderBack>
            <LspCardList
                list={content?.methods}
                onClickItem={onSelectMethod}
                variant="primary"
                hideStyle
            />
            <Box mt={3}>
                <LspButton fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    detail: state.booking.detail,
    serviceName: state.booking.serviceName,
});

const mapDispatch = (dispatch) => ({
    setBookingMethod: (payload) =>
        dispatch(bookingActions.setBookingMethod(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingPickupMethod);
