import Content from "@components/Content";
import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import invitationService from "@services/invitation";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ContentWrapper from "@components/ContentWrapper";
import InvitationTierList from "./InvitationTierList";
import InvitationDetailLoading from "./InvitationDetailLoading";
import InvitationDetail from "./InvitationDetail";

const useStyles = makeStyles((theme) => ({
    tierListWrapper: {
        display: "none",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
}));

const InvitationDashBoard = () => {
    const classes = useStyles();
    const unmounted = useRef(false);
    const [loading, setLoading] = useState(true);

    const [tierList, setTierList] = useState(null);
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getDashboard = useCallback(async () => {
        const response = await invitationService.getInvitationDashboard();

        if (unmounted.current) return;

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setDetail(data);
                setTierList(data.allClass);
                setLoading(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, []);

    useEffect(() => {
        getDashboard();
    }, [getDashboard]);

    return (
        <ContentWrapper>
            <Content>
                <div className={classes.starWrapper}>
                    {!loading && <InvitationDetail data={detail} />}
                    {loading && <InvitationDetailLoading />}
                </div>

                <div className={classes.tierListWrapper}>
                    <InvitationTierList list={tierList} loading={loading} />
                </div>
            </Content>
        </ContentWrapper>
    );
};

export default InvitationDashBoard;
