import api from "@config/api";

const checkOverdraftStatus = async () => {
    return await api.get("/overdraft/backed-td/check");
};

const getOverdraftList = async (payload) => {
    return await api.get("/overdraft/backed-td/term-deposits", payload);
};

const getOverdraftPreview = async (payload) => {
    const { termId } = payload;
    return await api.get(`/overdraft/backed-td/info-request`, {
        termDepositId: termId,
    });
};

const applyOTP = async (payload) => {
    return await api.post(`/overdraft/backed-td/apply`, payload);
};

const commitOTP = async (payload) => {
    return await api.post(`/overdraft/backed-td/commit`, payload);
};

const manageOverdraft = async () => {
    return await api.get(`/overdraft/backed-td/manage`);
};

const checkEarlyOverdraft = async () => {
    return await api.get("/overdraft/backed-td/check-early-closure");
};

const applyEarlyClosure = async (payload) => {
    return await api.post("/overdraft/backed-td/apply-early-closure", payload);
};
const commitEarlyClosure = async (payload) => {
    return await api.post("/overdraft/backed-td/commit-early-closure", payload);
};

const overdraftService = {
    checkOverdraftStatus,
    getOverdraftList,
    getOverdraftPreview,
    applyOTP,
    commitOTP,
    manageOverdraft,
    checkEarlyOverdraft,
    applyEarlyClosure,
    commitEarlyClosure,
};

export default overdraftService;
