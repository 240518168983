import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar-edit";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

const dragBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderWidth: 2,
    borderRadius: 2,
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    maxWidth: 500,
    cursor: "pointer",
};

const activeStyle = {
    borderStyle: "dashed",
    borderColor: "#2196f3",
};

const rejectStyle = {
    borderStyle: "dashed",
    borderColor: "#E65365",
};

const useStyles = makeStyles((theme) => ({
    orLabel: {
        textTransform: "uppercase",
        textAlign: "center",
    },
    iconUpload: {
        fontSize: theme.typography.pxToRem(100),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
    previewFiles: {
        columns: "240px",
        columnGap: `${theme.spacing(2)}px`,
        width: "100%",
    },
    previewFile: {
        alignSelf: "center",
        marginBottom: theme.spacing(2),
        display: "flex",
        maxWidth: 250,
        cursor: "default",
        "& img": {
            width: "100%",
        },
        "& embed": {
            overflow: "hidden",
        },
    },
    previewPdf: {
        height: 120,
        width: 240,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontSize: theme.typography.pxToRem(32),
        color: theme.palette.magicalGrey[400],
        position: "relative",
    },
    previewPdfOverflow: {
        position: "absolute",
        width: "100%",
        height: "100%",
        cursor: "pointer",
    },
}));

const LspUploadFile = ({
    acceptedFileExtensions,
    avatarMaxSize,
    onError,
    onUpload,
    files,
    type,
    maxFiles,
    modifyMode,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const isLargeFiles = useCallback(
        (fileSize) => fileSize / (1024 * 1024) >= avatarMaxSize,
        [avatarMaxSize]
    );

    const onDrop = useCallback(
        (acceptFile, rejectFile) => {
            const isLargeFile = acceptFile.some((item) =>
                isLargeFiles(item?.size)
            );
            if (!isEmpty(rejectFile)) {
                if (rejectFile.length > maxFiles) {
                    onError(
                        modifyMode
                            ? t(
                                  "txnapptype:receipt_attach_file_error_number_file_change_exceed_desc"
                              )
                            : t(
                                  "txnapptype:receipt_attach_file_error_number_file_exceed_desc"
                              )
                    );
                    return;
                }
                onError(
                    t(
                        "txnapptype:receipt_attach_file_error_format_unsuport_desc"
                    )
                );
            } else if (isLargeFile) {
                onError(
                    t("txnapptype:receipt_attach_file_error_size_exceed_desc")
                );
            } else {
                onError("");
                if (type === "multiple") {
                    const fileList = acceptFile.map((item) => {
                        return {
                            preview: URL.createObjectURL(item),
                            file: item,
                            id: uuidv4(),
                        };
                    });
                    onUpload({
                        files: fileList,
                    });
                    return;
                }
                const file = acceptFile[0];
                onUpload({ ...file, preview: URL.createObjectURL(file) });
            }
        },
        [isLargeFiles, maxFiles, onError, t, modifyMode, type, onUpload]
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept: acceptedFileExtensions,
        onDrop,
        noClick: true,
        maxFiles,
    });

    const style = useMemo(
        () => ({
            ...dragBox,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    const onClose = () => {
        onUpload(null);
    };

    const onCrop = (previewInfo) => {
        onUpload((prev) => ({ ...prev, preview: previewInfo }));
    };

    const handleOpenPDF = useCallback((url) => {
        window.open(url, "_blank");
    }, []);

    const handleRenderContent = useCallback(
        (item) => {
            const fileType = item?.file?.type;
            switch (fileType) {
                case "application/pdf":
                    return (
                        <div className={classes.previewFile}>
                            <div className={classes.previewPdf}>
                                <embed
                                    width={240}
                                    height={120}
                                    src={item.preview}
                                    type="application/pdf"
                                />
                                <div
                                    className={classes.previewPdfOverflow}
                                    onClick={() => handleOpenPDF(item.preview)}
                                />
                            </div>
                        </div>
                    );

                default:
                    return (
                        <div className={classes.previewFile}>
                            <img src={item.preview} alt="upload img" />
                        </div>
                    );
            }
        },
        [
            classes.previewFile,
            classes.previewPdf,
            classes.previewPdfOverflow,
            handleOpenPDF,
        ]
    );

    return (
        <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isEmpty(files) && (
                <>
                    <LspTypography className={classes.orLabel} color="primary">
                        {t("lb_or")}
                    </LspTypography>
                    <Icon
                        className={`font-icon icon-avatarchange ${classes.iconUpload}`}
                    />
                    <LspTypography color="primary" variant="body1">
                        {t("profile_txt_button_drag_photo")}
                    </LspTypography>
                </>
            )}
            {!isEmpty(files) && (
                <>
                    <div className={classes.previewFiles}>
                        {files.map((item) => {
                            return (
                                <div key={item?.file?.name}>
                                    {handleRenderContent(item)}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </Box>
    );
};

LspUploadFile.propTypes = {
    maxFiles: PropTypes.number,
    modifyMode: PropTypes.bool,
};

LspUploadFile.defaultProps = {
    maxFiles: 3,
    modifyMode: false,
};

export default memo(LspUploadFile);
