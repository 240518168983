/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import useWealth from "../useWealth";
import { WEALTH_PRODUCT_KEYS } from "../wealthConstants";

const useStyles = makeStyles((theme) => ({
    documentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "flex-start",
        },
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(56),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },
    title: {
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
    },
    list: {},
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));
const WealthLoansDocument = ({ onSubmit, loading, onBack }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { getWealthIcon, mapDocumentContent } = useWealth();
    const icon = getWealthIcon(WEALTH_PRODUCT_KEYS.LOANS);

    const descriptions = mapDocumentContent(
        t("wealth:VL00008", {
            returnObjects: true,
        })
    );

    return (
        <Content>
            <div className={classes.documentWrapper}>
                <Icon className={`font-icon icon-${icon} ${classes.icon}`} />
                <div>
                    <LspTypography
                        variant="title1"
                        color="primary"
                        className={classes.title}
                    >
                        {t("wealth:VL00002")}
                    </LspTypography>
                    <br />
                    <LspTypography variant="body1">
                        {t("wealth:VL00003")}
                    </LspTypography>
                    <ul className={classes.list}>
                        {descriptions?.map((row, index) => {
                            return (
                                <li
                                    key={`row-${index}`}
                                    className={classes.item}
                                >
                                    <LspTypography variant="body1">
                                        {row.title}
                                    </LspTypography>
                                    {row?.descriptions?.map((item, i) => {
                                        return (
                                            <LspTypography
                                                key={`item-${i}`}
                                                variant="body1"
                                                color="grey"
                                            >
                                                {item}
                                            </LspTypography>
                                        );
                                    })}
                                </li>
                            );
                        })}
                    </ul>
                    {onSubmit && (
                        <div className={classes.button}>
                            <DoubleButtons
                                progressing={loading}
                                primaryButton={{
                                    label: t("loan_btn_apply"),
                                    onClick: onSubmit,
                                }}
                                secondaryButton={{
                                    label: t("lb_back"),
                                    onClick: onBack,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Content>
    );
};

export default memo(WealthLoansDocument);
