import api from "@config/api";

const checkExistPublicContent = async () => {
    return await api.get("user/public/contents/check");
};

const getPublicContent = async () => {
    return await api.get("public/contents");
};

const checkExistPrivateContent = async () => {
    return await api.get("user/contents/check");
};

const getPrivateContent = async (key) => {
    return await api.get(`protected/contents/${key}`);
};

const translateService = {
    checkExistPublicContent,
    getPublicContent,
    checkExistPrivateContent,
    getPrivateContent,
};

export default translateService;
