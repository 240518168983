import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 82,
        borderRadius: theme.shape.radiusLevels[0],
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    icon: {},
}));

const TermDepositListLoading = () => {
    const classes = useStyles();
    return (
        <>
            {[...new Array(3)].map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={classes.root}>
                    <Box display="flex" px={1} height={82 - 8}>
                        <Box
                            px={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Skeleton
                                animation="pulse"
                                variant="circle"
                                width={24}
                                height={24}
                            />
                        </Box>
                        <Box
                            px={1}
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={90}
                                />
                            </Typography>
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={150}
                                />
                            </Typography>
                        </Box>
                        <Box
                            px={1}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={110}
                                />
                            </Typography>
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={80}
                                />
                            </Typography>
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Skeleton animation="pulse" variant="rect" height={8} />
                    </Box>
                </div>
            ))}
        </>
    );
};

export default memo(TermDepositListLoading);
