import {
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LspTextField from "@components/LspTextField";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import {
    LspDialog,
    LspDialogActions,
    LspDialogTitle,
    LspDialogContent,
} from "@components/LspDialog";
import DoubleButtons from "@components/DoubleButtons";
import accountService from "@services/account";

const fileTypeOptions = [
    {
        label: "PDF",
        value: "pdf",
    },
    {
        label: "XLS",
        value: "xls",
    },
];

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    },
}));

const Export = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fileType, setFileType] = useState(fileTypeOptions[0].value);
    const [errors, setErrors] = useState({
        fromDate: false,
        toDate: false,
    });
    const [progressing, setProgressing] = useState(false);
    const downloadClicked = useRef(false);

    const invalidTimeRange = useMemo(
        () => errors.fromDate || errors.toDate || !fromDate || !toDate,
        [fromDate, toDate, errors]
    );

    const onDownloadClick = useCallback(async () => {
        if (!downloadClicked.current) {
            downloadClicked.current = true;
            setErrors({
                fromDate: !fromDate,
                toDate: !toDate,
            });
        }
        if (invalidTimeRange || progressing) {
            return;
        }

        setProgressing(true);
        const data = {
            from: format(new Date(fromDate), "dd/MM/yyyy"),
            to: format(new Date(toDate), "dd/MM/yyyy"),
            type: fileType,
        };
        const response = await accountService.exportSpendAccountTransactionList(
            data
        );
        if (response.ok) {
            const linkEl = document.createElement("a");
            const windowURL = window.URL || window.webkitURL;
            const objectURL = windowURL.createObjectURL(response.data);
            linkEl.href = objectURL;
            linkEl.download = `transaction_list_${format(
                new Date(),
                "yyyy_MM-dd"
            )}.${fileType}`;
            linkEl.click();
            setTimeout(() => {
                windowURL.revokeObjectURL(linkEl);
            }, 150);
        }
        setProgressing(false);
    }, [fromDate, toDate, fileType, invalidTimeRange, progressing]);

    const onFromDateChange = useCallback((d) => {
        if (!d) {
            setFromDate(" ");
            return;
        }
        setFromDate(new Date(d));
    }, []);

    const onToDateChange = useCallback((d) => {
        if (!d) {
            setToDate(" ");
            return;
        }
        setToDate(new Date(d));
    }, []);

    const onFromDateError = useCallback((err) => {
        setErrors((value) => ({
            ...value,
            fromDate: Boolean(err),
        }));
    }, []);

    const onToDateError = useCallback((err) => {
        setErrors((value) => ({
            ...value,
            toDate: Boolean(err),
        }));
    }, []);

    return (
        <LspDialog
            open={open}
            maxWidth="sm"
            onEscapeKeyDown={onClose}
            scroll="body"
        >
            <LspDialogTitle>
                {t(
                    "spend_transaction_export_title_choose_period_of_transaction"
                )}
            </LspDialogTitle>
            <LspDialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            TextFieldComponent={LspTextField}
                            error={errors.fromDate}
                            disableHelperText
                            label={t("payee_transfer_success_from")}
                            disableToolbar
                            autoOk
                            disableFuture
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            invalidDateMessage=" "
                            maxDateMessage=" "
                            value={fromDate}
                            onChange={onFromDateChange}
                            onError={onFromDateError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            TextFieldComponent={LspTextField}
                            error={errors.toDate}
                            disableHelperText
                            label={t("lb_to")}
                            disableToolbar
                            autoOk
                            disableFuture
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            invalidDateMessage=" "
                            maxDateMessage=" "
                            minDateMessage=" "
                            value={toDate}
                            minDate={fromDate}
                            disabled={!fromDate}
                            onChange={onToDateChange}
                            onError={onToDateError}
                        />
                    </Grid>
                </Grid>
                <RadioGroup
                    className={classes.radioGroup}
                    value={fileType}
                    onChange={(event) => setFileType(event.target.value)}
                >
                    {fileTypeOptions.map((option) => {
                        return (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                            />
                        );
                    })}
                </RadioGroup>
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    progressing={progressing}
                    primaryButton={{
                        onClick: onDownloadClick,
                        label: t(
                            "spend_transaction_export_placeholder_download"
                        ),
                    }}
                    secondaryButton={{
                        onClick: onClose,
                        label: t("lb_cancel"),
                    }}
                />
            </LspDialogActions>
        </LspDialog>
    );
};

export default memo(Export);
