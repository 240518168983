import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspCardList from "@components/LspCardList";
import PageHeaderBack from "@components/PageHeaderBack";
import {
    BOOKING_SERVICES,
    BOOKING_STEPS,
    DELIVERY_ADDRESS,
    DELIVERY_METHODS,
} from "@containers/Booking/constant";
import useAddress from "@helpers/useAddress";
import { Box } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const CreateBookingAddressSelection = ({
    serviceName,
    onCancel,
    onBack,
    onNext,
    setBookingAddressType,
    address,
    setBookingAddress,
    method,
}) => {
    const { t } = useTranslation();
    const { joinAddress } = useAddress();

    const optionList = useMemo(() => {
        // eslint-disable-next-line prefer-const
        let addressList = [];
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
            default:
                let permanentAddress;
                if (
                    address?.permanentAddress ||
                    address?.permanentCityName ||
                    address?.permanentDistName ||
                    address?.permanentWardName
                ) {
                    permanentAddress = joinAddress({
                        address: address?.permanentAddress,
                        province: address?.permanentCityName,
                        district: address?.permanentDistName,
                        ward: address?.permanentWardName,
                    });
                }

                let currentAddress;
                if (
                    address?.addressName ||
                    address?.cityName ||
                    address?.distName ||
                    address?.wardName
                ) {
                    currentAddress = joinAddress({
                        address: address?.addressName,
                        province: address?.cityName,
                        district: address?.distName,
                        ward: address?.wardName,
                    });
                }

                if (permanentAddress) {
                    addressList.push({
                        id: DELIVERY_ADDRESS.PERMANENT_ADDRESS,
                        title: t("booking_my_permanent_address"),
                        fullAddress: permanentAddress,
                        description: permanentAddress,
                        address: address?.permanentAddress,
                        province: {
                            cfgKey: address?.permanentCityId,
                            cfgValue: address?.permanentCityName,
                        },
                        district: {
                            cfgKey: address?.permanentDistId,
                            cfgValue: address?.permanentDistName,
                        },
                        ward: {
                            cfgKey: address?.permanentWardId,
                            cfgValue: address?.permanentWardName,
                        },
                    });
                }

                if (currentAddress) {
                    addressList.push({
                        id: DELIVERY_ADDRESS.CURRENT_ADDRESS,
                        title: t("booking_my_current_address"),
                        fullAddress: currentAddress,
                        description: currentAddress,
                        address: address?.addressName,
                        province: {
                            cfgKey: address?.cityId,
                            cfgValue: address?.cityName,
                        },
                        district: {
                            cfgKey: address?.distId,
                            cfgValue: address?.distName,
                        },
                        ward: {
                            cfgKey: address?.wardId,
                            cfgValue: address?.wardName,
                        },
                    });
                }

                addressList.push({
                    id: DELIVERY_ADDRESS.OTHER_ADDRESS,
                    title: t("master:add_new_delivery_location"),
                    fullAddress: "",
                    address: "",
                    province: {},
                    district: {},
                    ward: {},
                });

                return addressList;
        }
    }, [serviceName, t, address, joinAddress]);

    const onSelectAddressType = useCallback(
        (selectedAddress) => {
            setBookingAddressType(selectedAddress?.id);

            if (selectedAddress?.id === DELIVERY_ADDRESS.OTHER_ADDRESS) {
                onNext(BOOKING_STEPS.DELIVERY_OTHER_ADDRESS_INPUT);
                return;
            }

            const { title, description, ...othersProp } = selectedAddress;

            setBookingAddress(othersProp);

            if (method === DELIVERY_METHODS.MOTORBIKE) {
                onNext(BOOKING_STEPS.DELIVERY_BOOKING_TIME);
                return;
            }

            onNext(BOOKING_STEPS.DELIVERY_CONFIRMATION);
        },
        [onNext, setBookingAddressType, setBookingAddress, method]
    );

    return (
        <Content size="sm">
            <PageHeaderBack onBack={onBack}>
                {t("master:choose_address_to_receive_card")}
            </PageHeaderBack>
            <LspCardList
                list={optionList}
                onClickItem={onSelectAddressType}
                variant="primary"
            />
            <Box mt={3}>
                <LspButton fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    serviceName: state.booking.serviceName,
    method: state.booking.method,
    address: state.user?.info?.address,
});

const mapDispatch = (dispatch) => ({
    setBookingAddressType: (payload) =>
        dispatch(bookingActions.setBookingAddressType(payload)),
    setBookingAddress: (payload) =>
        dispatch(bookingActions.setBookingAddress(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingAddressSelection);
