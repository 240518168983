/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    Icon,
    makeStyles,
    Paper,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { useMemo, useCallback, useState, memo, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { RESPONSE_CODE } from "@config/constants";
import LspButton from "@components/LspButton";
import wealthService from "@services/wealth";
import { useHistory } from "react-router-dom";
import {
    MANAGE_INSTANT_STEPS,
    WEALTH_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LeftPage from "../LeftPage";

const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        display: "block",
        width: "100%",
        textAlign: "right",
        cursor: "pointer",
    },

    actionBox: {
        padding: theme.spacing(3.75, 5),
        "& > div": {
            marginTop: theme.spacing(1.25),
        },
    },
}));

const RightPage = ({
    leftContentTitle,
    stepForward,
    leftDetailPackage,
    leftDetailPackage2,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState({
        makeClaim: false,
        resendPolicy: false,
        changeBen: false,
    });

    const [typeAction, setTypeAction] = useState(null);
    const theme = useTheme();

    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const callAPIAction = useCallback(
        async (type, nameAction) => {
            const dataToPost = {
                productKey: leftContentTitle.prokey,
                type,
            };
            if (isSmDown) {
                setTypeAction(dataToPost);
                return;
            }
            setLoading((prev) => ({
                ...prev,
                [nameAction]: true,
            }));

            const { data } = await wealthService.actionWealthSubProducts(
                dataToPost
            );

            if (unmounted.current) {
                return;
            }

            setLoading((prev) => ({
                ...prev,
                [nameAction]: false,
            }));

            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    stepForward(MANAGE_INSTANT_STEPS.CONGRATULATION);
                    break;

                default:
                    GlobalDialogController.showError({ errorCode: data.code });
                    break;
            }
        },
        [leftContentTitle, isSmDown, stepForward]
    );

    const actionManage = useMemo(() => {
        const action = [
            {
                icon: <Icon className="font-icon icon-makeaclaim" />,
                text: t("wealth:SI00068"),
                clickAct: () => {
                    callAPIAction(
                        WEALTH_PRODUCT_KEYS.MANAGE_MAKE_CLAIM,
                        "makeClaim"
                    );
                },
                loading: loading.makeClaim,
            },
            {
                icon: <Icon className="font-icon icon-paperairplane" />,
                text: t("wealth:SI00069"),

                clickAct: () => {
                    callAPIAction(
                        WEALTH_PRODUCT_KEYS.MANAGE_RESEND_POLICY,
                        "resendPolicy"
                    );
                },
                loading: loading.resendPolicy,
            },
            {
                icon: <Icon className="font-icon icon-changebeneficiary" />,
                text: t("wealth:SI00070"),
                clickAct: () => {
                    callAPIAction(
                        WEALTH_PRODUCT_KEYS.MANAGE_CHANGE_BENEFICIARY,
                        "changeBen"
                    );
                },
                loading: loading.changeBen,
            },
        ];

        return action;
    }, [t, callAPIAction, loading]);
    return (
        <Content size="xs">
            {!typeAction && (
                <div>
                    <Icon
                        onClick={() => history.push("products")}
                        className={`font-icon icon-close ${classes.closeIcon}`}
                    />
                    <div className={classes.boxTitle}>
                        <Icon
                            className={`font-icon icon-people2 ${classes.icon}`}
                        />
                        <LspTypography variant="heading1" color="primary">
                            {t("wealth:SI00071")}
                        </LspTypography>
                    </div>
                    <Paper className={classes.actionBox}>
                        {actionManage.map((item, key) => (
                            <LspButton
                                key={key}
                                fullWidth
                                buttonProps={{
                                    startIcon: item.icon,
                                }}
                                onClick={item.clickAct}
                                progressing={item.loading}
                            >
                                {item.text}
                            </LspButton>
                        ))}
                    </Paper>
                </div>
            )}

            {typeAction && (
                <LeftPage
                    leftContentTitle={leftContentTitle}
                    leftDetailPackage={leftDetailPackage}
                    leftDetailPackage2={leftDetailPackage2}
                    stepForward={stepForward}
                    isShowBtnSubmit
                    typeAction={typeAction}
                />
            )}
        </Content>
    );
};

export default memo(RightPage);
