/* ------------- Types and Action Definition ------------- */
export const ForgotPasswordActionTypes = Object.freeze({
    OTPRequired: "ForgotPasswordActionTypes/OTPRequired",
    OTPCommit: "ForgotPasswordActionTypes/OTPCommit",
    OTPCommitSuccess: "ForgotPasswordActionTypes/OTPCommitSuccess",
});

/* ------------- Actions Definition ------------- */

const otpRequired = (payload) => {
    return {
        type: ForgotPasswordActionTypes.OTPRequired,
        payload,
    };
};

const otpCommit = (payload) => {
    return {
        type: ForgotPasswordActionTypes.OTPCommit,
        payload,
    };
};

const otpCommitSuccess = (payload) => {
    return {
        type: ForgotPasswordActionTypes.OTPCommitSuccess,
        payload,
    };
};

const ForgotPasswordActions = {
    otpRequired,
    otpCommit,
    otpCommitSuccess,
};

export default ForgotPasswordActions;
