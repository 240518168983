export const NOTIFICATION_CHECKBOX_TYPES = {
    ALL: "ALL",
    NO: "NO",
    APART: "APART",
};

export const COMMUNICATION_TYPES = {
    NATIVE: "NATIVE",
    MD: "MD",
    TXT: "TXT",
};

export const ALLOWED_NOTIFICATION_TYPES = [
    COMMUNICATION_TYPES.NATIVE,
    COMMUNICATION_TYPES.MD,
    COMMUNICATION_TYPES.TXT,
];

export const NOTIFICATION_GROUPS = {
    GOAL_SAVE: "GoalSave",
    CARD_MANAGEMENT: "ManageCard",
    BILL: "Bill",
    TRANSFER: "Transfer",
    MASTERCARD: "MasterCard",
    TERM_DEPOSIT: "TermDeposit",
    PAYMENT_REQUEST: "PaymentRequest",
    PRODUCT: "Product",
    COMMUNICATION: "ComMod",
    MKTOB: "MKTOB",
    MKT: "MKT",
    LIXI: "LIXI",
    SECURITY: "SECURITY",
    IOTP: "IOTP",
    INVITATION_LEVEL_UP: "timostar_levelup",
};
