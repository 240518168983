import { Box, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    carouselCard: {},
    carouselCardBody: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: "100%",
        height: 260,
        position: "relative",
        overflow: "hidden",
    },
    carouselCardItem: {
        overflow: "hidden",
        margin: "auto",
        width: 155,
        height: 246,
        position: "absolute",
        left: 0,
        right: 0,
        cursor: "pointer",
        transition: "all 0.4s ease",
        zIndex: 0,
        transform: " scale(0.9)",
        opacity: 0,
        "& img": {
            width: 145,
            height: 237,
            borderRadius: theme.spacing(1),
        },
    },
    level0: {
        zIndex: 2,
        transform: "scale(1)",
        opacity: 1,
    },

    level2: {
        transform: "translate(-100%, 0px) scale(0.9)",
        zIndex: 1,
        opacity: 1,
    },
    level1: {
        zIndex: 1,
        opacity: 1,
        transform: "translate(100%, 0px) scale(0.9)",
    },
    carouselCardActionItem: {
        marginRight: theme.spacing(3),
        color: theme.palette.neutral.black,
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(30),
        "&:last-child": {
            marginRight: 0,
        },
    },
    carouselBorder: {
        "& img": {
            border: `3px solid ${theme.palette.green.main}`,
            borderRadius: 12,
        },
    },
    disableSelect: {
        color: theme.palette.grey[400],
        cursor: "unset",
    },
    actionLeft: {
        position: "absolute",
        top: "50%",
        left: 0,
    },
    actionRight: {
        position: "absolute",
        top: "50%",
        right: 0,
    },
    carouselCardMobileAction: {
        display: "flex",
        justifyContent: "center",
    },
    carouselCardMobileActionItem: {
        marginRight: theme.spacing(3),
        color: theme.palette.grey[400],
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(16),
        "&:last-child": {
            marginRight: 0,
        },
    },
}));

const CarouselCard = ({
    cardList,
    selectedItem,
    disableSelect,
    activeBorder,
    onSelectCard,
    loading,
}) => {
    const classes = useStyles();
    const [activeCardList, setActiveCardList] = useState([]);
    const [active, setActive] = useState(0);
    const [isFirstInit, setFirstInit] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        if (activeCardList.length > 0 && isFirstInit && selectedItem) {
            const findItemSelect = activeCardList.find(
                (item) => item.cardVersion === selectedItem
            );
            if (findItemSelect) {
                setActive(findItemSelect.key);
                onSelectCard(findItemSelect);
            }
            setFirstInit(false);
        }
    }, [activeCardList, isFirstInit, onSelectCard, selectedItem]);

    useEffect(() => {
        const addKeyActiveCard = (cardList || []).map((card, index) => ({
            ...card,
            key: index,
        }));
        console.log("addKeyActiveCard:", addKeyActiveCard);
        setActiveCardList(addKeyActiveCard);

        if (cardList && cardList.length > 0 && isEmpty(selectedItem)) {
            onSelectCard(addKeyActiveCard[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardList]);

    const handleClickItem = (item) => {
        if (!disableSelect) {
            setActive(item.key);
            onSelectCard(item);
        }
    };

    const generateClassItems = (index = [], list) => {
        if (list.length === 1) {
            return "level0";
        }
        if (active === 0 && index === activeCardList.length - 1) {
            return activeCardList.length === 2 ? "level1" : "level2";
        }

        if (active === activeCardList.length - 1 && index === 0) {
            return "level1";
        }

        if (index === active) {
            return "level0";
        }

        if (index === active - 1) {
            return "level2";
        }

        if (index === active + 1) {
            return "level1";
        }
    };

    const moveLeft = () => {
        if (!disableSelect) {
            let newActive = active;
            newActive--;
            const calcActive =
                newActive < 0 ? activeCardList.length - 1 : newActive;
            setActive(calcActive);
            const findItemSelect = activeCardList.find(
                (item) => item.key === calcActive
            );

            if (findItemSelect) {
                onSelectCard(findItemSelect);
            }
        }
    };

    const moveRight = () => {
        if (!disableSelect) {
            const newActive = (active + 1) % activeCardList.length;
            setActive(newActive);
            const findItemSelect = activeCardList.find(
                (item) => item.key === newActive
            );

            if (findItemSelect) {
                onSelectCard(findItemSelect);
            }
        }
    };

    return (
        <>
            <Box className={classes.carouselCard}>
                <div className={classes.carouselCardBody}>
                    {!loading &&
                        activeCardList.map((item, index, list) => (
                            <div
                                onClick={() => handleClickItem(item)}
                                key={item.cardVersion}
                                className={`${classes.carouselCardItem} ${
                                    classes[generateClassItems(index, list)]
                                } ${
                                    activeBorder && active === index
                                        ? classes.carouselBorder
                                        : ""
                                }`}
                            >
                                <img
                                    alt={item.cardVersion}
                                    src={item.cardImageUrl}
                                />
                            </div>
                        ))}
                    {loading && (
                        <div className={classes.carouselCard}>
                            <div
                                className={`${classes.carouselCardItem} ${classes.level1}`}
                            >
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={204}
                                />
                            </div>
                            <div
                                className={`${classes.carouselCardItem} ${classes.level0}`}
                            >
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={204}
                                />
                            </div>
                            <div
                                className={`${classes.carouselCardItem}  ${classes.level2}`}
                            >
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={204}
                                />
                            </div>
                        </div>
                    )}
                    {!loading && (
                        <>
                            {!isMobile && (
                                <>
                                    <span
                                        onClick={() => moveLeft()}
                                        className={`${
                                            classes.carouselCardActionItem
                                        } icon-left-open-big ${
                                            disableSelect
                                                ? classes.disableSelect
                                                : ""
                                        } ${classes.actionLeft}`}
                                    />
                                    <span
                                        onClick={() => moveRight()}
                                        className={`${
                                            classes.carouselCardActionItem
                                        } icon-right-open-big ${
                                            disableSelect
                                                ? classes.disableSelect
                                                : ""
                                        } ${classes.actionRight}`}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>

                {isMobile && (
                    <div className={classes.carouselCardMobileAction}>
                        {loading && (
                            <Skeleton variant="rect" width={80} height={20} />
                        )}

                        {!loading && (
                            <>
                                <span
                                    onClick={() => moveLeft()}
                                    className={`${classes.carouselCardMobileActionItem} icon-longarrowleft`}
                                />
                                <span
                                    onClick={() => moveRight()}
                                    className={`${classes.carouselCardMobileActionItem} icon-longarrowright`}
                                />
                            </>
                        )}
                    </div>
                )}
            </Box>
        </>
    );
};

CarouselCard.propTypes = {
    selectedItem: PropTypes.string,
    activeBorder: PropTypes.bool,
    disableSelect: PropTypes.bool,
    loading: PropTypes.bool,
};

CarouselCard.defaultProps = {
    selectedItem: "",
    activeBorder: false,
    disableSelect: false,
    loading: true,
};

const mapStateToProps = (state) => ({
    cardList: state.cardInfo.address.data?.listCardsInfo,
});

export default connect(mapStateToProps)(memo(CarouselCard));
