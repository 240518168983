import { useDrag, useDrop } from "react-dnd";

function getStyles(canDrop, isActive, isDragging) {
    const commonStyles = {
        padding: "8px",
        margin: "8px",
        borderRadius: "4px",
        overflow: "hidden",
    };

    if (isActive) {
        return {
            ...commonStyles,
            border: "1px dashed gray",
            borderRadius: "4px",
            opacity: 0.6,
        };
    }

    if (isDragging) {
        return {
            ...commonStyles,
            border: "1px dashed gray",
            borderRadius: "4px",
            opacity: 0,
        };
    }

    return {
        ...commonStyles,
    };
}
export const DragItem = ({ children, name, onDrop, onDrag }) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "dragItem",
            end(props, monitor) {
                const dropResult = monitor.getDropResult();
                if (dropResult) {
                    onDrag(name);
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );
    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: "dragItem",
            drop() {
                onDrop(name);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        []
    );

    const isActive = canDrop && isOver;

    return (
        <div ref={drop} style={getStyles(canDrop, isActive, isDragging)}>
            <div ref={drag}>{children}</div>
        </div>
    );
};
