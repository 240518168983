import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import BellIcon from "@assets/images/Bell.svg";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    emptyList: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "30vh",
        [theme.breakpoints.down("xs")]: {
            minHeight: "calc(100vh - 152px)",
        },
        "& img": {
            marginBottom: theme.spacing(3),
        },
    },
    text: {
        color: theme.palette.neutral.grey1,
    },
}));

const NotificationPopoverEmpty = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.emptyList}>
            <img src={BellIcon} alt="bell icon" />
            <LspTypography variant="body3" className={classes.text}>
                {t("notification_msg_for_new_customer")}
            </LspTypography>
        </div>
    );
};

export default memo(NotificationPopoverEmpty);
