/* ------------- Types Definition ------------- */
export const AccountActionType = Object.freeze({
    Reset: "AccountActionType/Reset",
    GetAccountStatusRequest: "AccountActionType/GetAccountStatusRequest",
    GetAccountStatusSuccess: "AccountActionType/GetAccountStatusSuccess",
    GetAccountStatusError: "AccountActionType/GetAccountStatusError",
    GetAccountListRequest: "AccountActionType/GetAccountListRequest",
    GetAccountListSuccess: "AccountActionType/GetAccountListSuccess",
    GetAccountListError: "AccountActionType/GetAccountListError",
    GetSpendAccountTransactionListRequest:
        "AccountActionType/GetSpendAccountTransactionListRequest",
    GetSpendAccountTransactionListSuccess:
        "AccountActionType/GetSpendAccountTransactionListSuccess",
    GetSpendAccountTransactionListError:
        "AccountActionType/GetSpendAccountTransactionListError",
    GetCreditCardTransactionListRequest:
        "AccountActionType/GetCreditCardTransactionListRequest",
    GetCreditCardTransactionListSuccess:
        "AccountActionType/GetCreditCardTransactionListSuccess",
    GetCreditCardTransactionListError:
        "AccountActionType/GetCreditCardTransactionListError",
    SetTotalActiveTermDepositAmount:
        "AccountActionType/SetTotalActiveTermDepositAmount",
    SetTotalRedeemedTermDepositAmount:
        "AccountActionType/SetTotalRedeemedTermDepositAmount",
    GetInstallmentDataRequest: "AccountActionType/GetInstallmentDataRequest",
    GetInstallmentDataSuccess: "AccountActionType/GetInstallmentDataSuccess",
    GetInstallmentDataError: "AccountActionType/GetInstallmentDataError",
    GetPayAnyoneDashboardRequest:
        "AccountActionType/GetPayAnyoneDashboardRequest",
    GetPayAnyoneDashboardSuccess:
        "AccountActionType/GetPayAnyoneDashboardSuccess",
    GetPayAnyoneDashboardError: "AccountActionType/GetPayAnyoneDashboardError",
    GetPayAnyoneListRequest: "AccountActionType/GetPayAnyoneListRequest",
    GetPayAnyoneListSuccess: "AccountActionType/GetPayAnyoneListSuccess",
    GetPayAnyoneListError: "AccountActionType/GetPayAnyoneListError",
    ResetPaymentDetailData: "AccountActionType/ResetPaymentDetailData",
    UpdatePaymentStatusRequest: "AccountActionType/UpdatePaymentStatusRequest",
    UpdatePaymentStatusError: "AccountActionType/UpdatePaymentStatusError",
    UpdatePaymentStatusSuccess: "AccountActionType/UpdatePaymentStatusSuccess",
    UpdateTransactionList: "AccountActionType/UpdateTransactionList",
    UpdateHideBalanceOption: "AccountActionType/UpdateHideBalanceOption",

    GetAccountLimitRequest: "AccountActionType/GetAccountLimitRequest",
    GetAccountLimitSuccess: "AccountActionType/GetAccountLimitSuccess",
    GetAccountLimitError: "AccountActionType/GetAccountLimitError",

    ModifyAccountLimitRequest: "AccountActionType/ModifyAccountLimitRequest",
    ModifyAccountLimitSuccess: "AccountActionType/ModifyAccountLimitSuccess",
    ModifyAccountLimitError: "AccountActionType/ModifyAccountLimitError",

    ResetAccountLimit: "AccountActionType/ResetAccountLimit",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: AccountActionType.Reset,
    };
};

const getAccountStatusRequest = () => {
    return {
        type: AccountActionType.GetAccountStatusRequest,
    };
};
const getAccountStatusSuccess = (payload) => {
    return {
        type: AccountActionType.GetAccountStatusSuccess,
        payload,
    };
};
const getAccountStatusError = () => {
    return {
        type: AccountActionType.GetAccountStatusError,
    };
};

const getAccountListRequest = () => {
    return {
        type: AccountActionType.GetAccountListRequest,
    };
};
const getAccountListSuccess = (payload) => {
    return {
        type: AccountActionType.GetAccountListSuccess,
        payload,
    };
};
const getAccountListError = () => {
    return {
        type: AccountActionType.GetAccountListError,
    };
};

const getSpendAccountTransactionListRequest = (payload) => {
    return {
        type: AccountActionType.GetSpendAccountTransactionListRequest,
        payload,
    };
};
const getSpendAccountTransactionListSuccess = (payload) => {
    return {
        type: AccountActionType.GetSpendAccountTransactionListSuccess,
        payload,
    };
};
const getSpendAccountTransactionListError = () => {
    return {
        type: AccountActionType.GetSpendAccountTransactionListError,
    };
};

const getCreditCardTransactionListRequest = (payload) => {
    return {
        type: AccountActionType.GetCreditCardTransactionListRequest,
        payload,
    };
};
const getCreditCardTransactionListSuccess = (payload) => {
    return {
        type: AccountActionType.GetCreditCardTransactionListSuccess,
        payload,
    };
};
const getCreditCardTransactionListError = () => {
    return {
        type: AccountActionType.GetCreditCardTransactionListError,
    };
};

const setTotalActiveTermDepositAmount = (payload) => {
    return {
        type: AccountActionType.SetTotalActiveTermDepositAmount,
        payload,
    };
};
const setTotalRedeemedTermDepositAmount = (payload) => {
    return {
        type: AccountActionType.SetTotalRedeemedTermDepositAmount,
        payload,
    };
};

const getInstallmentDataRequest = (payload) => {
    return {
        type: AccountActionType.GetInstallmentDataRequest,
        payload,
    };
};
const getInstallmentDataSuccess = (payload) => {
    return {
        type: AccountActionType.GetInstallmentDataSuccess,
        payload,
    };
};
const getInstallmentDataError = () => {
    return {
        type: AccountActionType.GetInstallmentDataError,
    };
};

const getPayAnyoneDashboardRequest = () => {
    return {
        type: AccountActionType.GetPayAnyoneDashboardRequest,
    };
};
const getPayAnyoneDashboardSuccess = (payload) => {
    return {
        type: AccountActionType.GetPayAnyoneDashboardSuccess,
        payload,
    };
};
const getPayAnyoneDashboardError = () => {
    return {
        type: AccountActionType.GetPayAnyoneDashboardError,
    };
};

const getPayAnyoneListRequest = (payload) => {
    return {
        type: AccountActionType.GetPayAnyoneListRequest,
        payload,
    };
};
const getPayAnyoneListSuccess = (payload) => {
    return {
        type: AccountActionType.GetPayAnyoneListSuccess,
        payload,
    };
};

const getPayAnyoneListError = () => {
    return {
        type: AccountActionType.GetPayAnyoneListError,
    };
};

const updatePaymentStatusRequest = (payload) => {
    return {
        type: AccountActionType.UpdatePaymentStatusRequest,
        payload,
    };
};
const updatePaymentStatusError = () => {
    return {
        type: AccountActionType.UpdatePaymentStatusError,
    };
};
const updatePaymentStatusSuccess = () => {
    return {
        type: AccountActionType.UpdatePaymentStatusSuccess,
    };
};
const resetPaymentDetailData = () => {
    return {
        type: AccountActionType.ResetPaymentDetailData,
    };
};

const updateTransactionList = (payload) => {
    return {
        type: AccountActionType.UpdateTransactionList,
        payload,
    };
};

const updateHideBalanceOption = (payload) => {
    return {
        type: AccountActionType.UpdateHideBalanceOption,
        payload,
    };
};

// account limit
const getAccountLimitRequest = (payload) => {
    return {
        type: AccountActionType.GetAccountLimitRequest,
        payload,
    };
};
const getAccountLimitSuccess = (payload) => {
    return {
        type: AccountActionType.GetAccountLimitSuccess,
        payload,
    };
};
const getAccountLimitError = () => {
    return {
        type: AccountActionType.GetAccountLimitError,
    };
};

// modify account limit
const modifyAccountLimitRequest = (payload) => {
    return {
        type: AccountActionType.ModifyAccountLimitRequest,
        payload,
    };
};
const modifyAccountLimitSuccess = (payload) => {
    return {
        type: AccountActionType.ModifyAccountLimitSuccess,
        payload,
    };
};
const modifyAccountLimitError = () => {
    return {
        type: AccountActionType.ModifyAccountLimitError,
    };
};

const resetAccountLimit = () => {
    return {
        type: AccountActionType.ResetAccountLimit,
    };
};
const accountAction = {
    reset,
    getAccountStatusRequest,
    getAccountStatusSuccess,
    getAccountStatusError,
    getAccountListRequest,
    getAccountListSuccess,
    getAccountListError,
    getSpendAccountTransactionListRequest,
    getSpendAccountTransactionListSuccess,
    getSpendAccountTransactionListError,
    getCreditCardTransactionListRequest,
    getCreditCardTransactionListSuccess,
    getCreditCardTransactionListError,
    setTotalActiveTermDepositAmount,
    setTotalRedeemedTermDepositAmount,
    getInstallmentDataRequest,
    getInstallmentDataSuccess,
    getInstallmentDataError,
    getPayAnyoneDashboardRequest,
    getPayAnyoneDashboardSuccess,
    getPayAnyoneDashboardError,
    getPayAnyoneListRequest,
    getPayAnyoneListSuccess,
    getPayAnyoneListError,
    updatePaymentStatusRequest,
    updatePaymentStatusError,
    updatePaymentStatusSuccess,
    resetPaymentDetailData,
    updateTransactionList,
    updateHideBalanceOption,

    // account limit
    getAccountLimitRequest,
    getAccountLimitSuccess,
    getAccountLimitError,

    // modify account limit
    modifyAccountLimitRequest,
    modifyAccountLimitSuccess,
    modifyAccountLimitError,

    resetAccountLimit,
};

export default accountAction;
