import appAction from "@redux/actions/app";

import PropTypes from "prop-types";

import { Box, ButtonBase, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThemeName } from "@config/constants";
import clsx from "clsx";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    themeWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
    },
    themeItem: {
        width: 25,
        height: 17,
        margin: theme.spacing(1, 0.25),
        cursor: "pointer",
    },
    purple: {
        background: theme.palette.purple.main,
    },
    blue: {
        background: theme.palette.blue.main,
    },
    orange: {
        background: theme.palette.orange.main,
    },
    gold: {
        background: theme.palette.gold.main,
    },
    active: {
        transform: "scale(1.1)",
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& li": {
            padding: 0,
        },
    },
    activeItem: {
        boxShadow: theme.shadows[1],
        "& button": {
            margin: theme.spacing(0.35, 0.35),
        },
    },
}));

/**
 * @typedef ThemeConfigurationProps
 * @type {object}
 * @property {{"list"|"dropdown"}} variant
 * @property {string} className
 */

/**
 * @param {ThemeConfigurationProps} props
 */

const ThemeConfiguration = ({
    variant,
    className,
    onChangeTheme,
    minimum,
    appTheme,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const themeNameList = useMemo(
        () => [
            {
                id: 1,
                name: ThemeName.Purple,
            },
            {
                id: 2,
                name: ThemeName.Blue,
            },
            {
                id: 3,
                name: ThemeName.Orange,
            },
            {
                id: 4,
                name: ThemeName.Gold,
            },
        ],
        []
    );

    const onSwitchTheme = (name) => {
        onChangeTheme(name);
    };

    return (
        <>
            <Box className={classes.themeWrapper}>
                {!minimum &&
                    themeNameList?.map((item) => {
                        return (
                            <ButtonBase
                                className={clsx(classes.themeItem, {
                                    [classes[item?.name]]: item?.name,
                                    [classes.active]: item?.name === appTheme,
                                })}
                                onClick={() => onSwitchTheme(item?.name)}
                                key={item?.id}
                            />
                        );
                    })}

                {minimum && (
                    <>
                        <Box className={classes.activeItem}>
                            <ButtonBase
                                className={clsx(classes.themeItem, {
                                    [classes[appTheme]]: appTheme,
                                })}
                                onClick={handleClick}
                            />
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            classes={{
                                list: classes.root,
                            }}
                        >
                            {themeNameList?.map((item) => {
                                return (
                                    <MenuItem
                                        onClick={handleClose}
                                        key={item?.id}
                                    >
                                        <ButtonBase
                                            className={clsx(classes.themeItem, {
                                                [classes[item?.name]]:
                                                    item?.name,
                                                [classes.active]:
                                                    item?.name === appTheme,
                                            })}
                                            onClick={() =>
                                                onSwitchTheme(item?.name)
                                            }
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </>
                )}
            </Box>
            {!minimum && (
                <Box textAlign="center">
                    <LspTypography variant="body2" color="grey">
                        {t("lb_hint_change_theme")}
                    </LspTypography>
                </Box>
            )}
        </>
    );
};

ThemeConfiguration.propTypes = {
    variant: PropTypes.oneOf(["list", "dropdown"]),
    className: PropTypes.string,
};

ThemeConfiguration.defaultProps = {
    variant: "list",
    className: "",
};

const mapState = (state) => ({
    appTheme: state.app.themeName,
});

const mapDispatch = (dispatch) => ({
    onChangeTheme: (payload) => dispatch(appAction.switchThemeName(payload)),
});

export default memo(connect(mapState, mapDispatch)(ThemeConfiguration));
