import { useCallback, useMemo } from "react";
import tommyIcon from "@assets/images/tommy_finding_status.png";
import useStatus from "@helpers/useStatus";

import { METRIC_NAME, METRIC_SCREEN } from "@config/constants";

import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

import { connect } from "react-redux";
import authAction from "@redux/actions/auth";
import { v4 as uuidv4 } from "uuid";
import metricActions from "@redux/actions/metric";
import ConditionNotificationDialog from "./NotificationPopover/ConditionNotificationDialog";

const AgreeTnc = ({ onReset, logout, token, userId, metricRequest }) => {
    const { updateAgreeTNCStatus } = useStatus();

    const { t } = useTranslation();

    const handleSubmitMetric = useCallback(() => {
        const time = new Date().getTime();
        const metric = {
            error_code: "",
            error_message: "",
            event_id: uuidv4() || "",
            flow_name: "",
            metric_location: "WEB",
            previous_event_id: "",
            previous_event_name: "",
            time_stamp_number: time || "",
            screen_name: METRIC_SCREEN.LOGIN,
            metric_name: METRIC_NAME.AGREE_POLICY,
            detail_data: {
                timo_id: userId.toString(),
            },
        };
        const metricRequestData = { data: metric, force: true, token };
        metricRequest(metricRequestData);
    }, [metricRequest, token, userId]);

    const onAgreeTNC = useCallback(() => {
        GlobalDialogController.hide();
        handleSubmitMetric();
        updateAgreeTNCStatus(true);
        onReset();
    }, [handleSubmitMetric, updateAgreeTNCStatus, onReset]);

    const onDisAgreeTNC = useCallback(() => {
        GlobalDialogController.hide();
        updateAgreeTNCStatus(false);
        onReset();
        logout();
    }, [updateAgreeTNCStatus, logout, onReset]);

    const descTNC = useMemo(() => {
        const tncLink = `${process.env.REACT_APP_API_URL}/${t(
            "ekyc:privacy_data_policy_link"
        )}`;
        const shortTNC = `<a href="${tncLink}" target="_blank">${t(
            "ekyc:privacy_data_policy"
        )}</a>`;

        const phoneCallCenter = `<a href="tel: ${t(
            "hangout:callcenter_number"
        )}">${t("hangout:callcenter_number")}</a>`;

        const tnc = t("ekyc:privacy_data_policy_update_popup_desc");
        return tnc.replace("%@", shortTNC).replace("%@", phoneCallCenter);
    }, [t]);

    const content = useMemo(() => {
        return {
            banner: tommyIcon,
            title: t("ekyc:privacy_data_policy_update_popup_title"),
            description: descTNC,
            buttons: [
                {
                    variant: "primary",
                    label: t("ekyc:privacy_data_policy_update_popup_agree_btn"),
                    onClick: () => onAgreeTNC(),
                },
                {
                    variant: "plain",
                    label: t(
                        "ekyc:privacy_data_policy_update_popup_disagree_btn"
                    ),
                    onClick: () => onDisAgreeTNC(),
                },
            ],
        };
    }, [t, onAgreeTNC, onDisAgreeTNC, descTNC]);

    return <ConditionNotificationDialog content={content} centerImg />;
};
const mapStateToProps = (state) => ({
    token: state.auth.token,
    userId: state.auth?.userId || "",
});

const mapDispatch = (dispatch) => ({
    logout: () => dispatch(authAction.logoutSuccess()),
    metricRequest: (payload) =>
        dispatch(metricActions.metricRequestUpdate(payload)),
});

export default connect(mapStateToProps, mapDispatch)(AgreeTnc);
