import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    transactionBox: {
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
    },
    headerLine: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2),
        background: theme.palette.neutral.grey3,
    },
    title: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    body: {
        minHeight: 67,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
    },
    statusLine: {
        width: "100%",
        background: theme.palette.primary.main,
        textTransform: "uppercase",
        fontSize: 14,
        color: theme.palette.neutral.white,
        padding: theme.spacing(0.75, 2),
        fontWeight: 600,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    seeMore: {
        textDecoration: "underline",
        textTransform: "uppercase",
        padding: theme.spacing(1.25, 2),
    },
}));
