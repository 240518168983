import { RESPONSE_CODE } from "@config/constants";
import translateService from "@services/translate";
import i18next from "i18next";
import DB from "@config/database";

const TRANSLATE_TITLE = {
    contentBase: "regulation",
    prc000009: "creditCard",
    wealth: [
        "prc000006",
        "prc000008",
        "prc000010",
        "mastercard",
        "mastercardv3",
        "prc_vinawealth",
        "liberty_nationalities",
        "wealthproduct",
    ],
    txnapptype: "txnapptype",
    master: "master",
    mastercardv3: "mastercardv3",
    pendingtask: "pendingTask",
    lixi: "lixi",
    payanyone: "payAnyone",
    debitcard: "debitCard",
    splitbill: "splitBill",
    qrpayment: "qrpayment",
    virtualcard: "virtualcard",
    TD: "TD",
    overdraft: "overdraft",
    pot: "pot",
};
const TRANSLATE_VN_KEYS = [
    "contentBase_vn",
    "prc000001_vn",
    "prc000002_vn",
    "prc000005_vn",
    "prc000006_vn",
    "prc000007_vn",
    "prc000008_vn",
    "prc000009_vn",
    "prc000010_vn",
    "mastercard_vn",
    "mastercardv3_vn",
    "relationshipList_vn",
    "prc_vinawealth_vn",
    "liberty_nationalities_vn",
    "wealthproduct_vn",
    "txnapptype_vn",
    "master_vn",
    "pendingtask_vn",
    "lixi_vn",
    "payanyone_vn",
    "debitcard_vn",
    "splitbill_vn",
    "qrpayment_vn",
    "txnlist_vn",
    "IBFT_vn",
    "virtualcard_vn",
    "TD_vn",
    "overdraft_vn",
    "moneypot_vn",
];

const TRANSLATE_EN_KEYS = [
    "contentBase_en",
    "prc000001_en",
    "prc000002_en",
    "prc000005_en",
    "prc000006_en",
    "prc000007_en",
    "prc000008_en",
    "prc000009_en",
    "prc000010_en",
    "mastercard_en",
    "mastercardv3_en",
    "relationshipList_en",
    "prc_vinawealth_en",
    "liberty_nationalities_en",
    "wealthproduct_en",
    "txnapptype_en",
    "master_en",
    "pendingtask_en",
    "lixi_en",
    "payanyone_en",
    "debitcard_en",
    "splitbill_en",
    "qrpayment_en",
    "txnlist_en",
    "IBFT_en",
    "virtualcard_en",
    "TD_en",
    "overdraft_en",
    "moneypot_en",
];

const insertResourceTranslate = (lang, key, data) => {
    // update resource translate
    let convertKey = "";

    if (TRANSLATE_TITLE[key]) {
        convertKey = TRANSLATE_TITLE[key];
    } else if (TRANSLATE_TITLE.wealth.includes(key)) {
        i18next.addResourceBundle(lang, "wealth", data, true, true);
        return;
    } else {
        convertKey = key;
    }
    i18next.addResourceBundle(lang, convertKey, data, true, true);
};

const getContentTranslate = async (indexedDb, key, dbKey) => {
    const db = indexedDb.result;

    const requestApis = await translateService.getPrivateContent(key);

    if (requestApis.status === RESPONSE_CODE.SUCCESS) {
        const { data } = requestApis;

        if (!db.objectStoreNames.contains(dbKey)) {
            const openRequest = DB();
            openRequest.onsuccess = async () => {
                const newDB = openRequest.result;
                if (newDB.objectStoreNames.contains(dbKey)) {
                    const transactionContent = newDB.transaction(
                        dbKey,
                        "readwrite"
                    );
                    const translateKeysContentData = transactionContent.objectStore(
                        dbKey
                    );

                    // save content data
                    if (data) {
                        translateKeysContentData.put({
                            keys: key,
                            value: data,
                        });
                        if (TRANSLATE_VN_KEYS.includes(key)) {
                            insertResourceTranslate(
                                "vn",
                                key.replace("_vn", ""),
                                data
                            );
                        }

                        if (TRANSLATE_EN_KEYS.includes(key)) {
                            insertResourceTranslate(
                                "en",
                                key.replace("_en", ""),
                                data
                            );
                        }
                    }

                    return;
                }
                const objectStore = newDB.createObjectStore(dbKey, {
                    keyPath: "keys",
                    autoIncrement: true,
                });

                objectStore.createIndex(dbKey, "keys", {
                    unique: false,
                });

                openRequest.result.close();
            };
            return;
        }

        const transactionContent = db.transaction(dbKey, "readwrite");
        const translateKeysContentData = transactionContent.objectStore(dbKey);

        // save content data
        if (data) {
            translateKeysContentData.put({ keys: key, value: data });
            if (TRANSLATE_VN_KEYS.includes(key)) {
                insertResourceTranslate("vn", key.replace("_vn", ""), data);
            }

            if (TRANSLATE_EN_KEYS.includes(key)) {
                insertResourceTranslate("en", key.replace("_en", ""), data);
            }
        }
    }
};

const getTranslateKeys = async (indexedDb) => {
    const db = indexedDb.result;

    const requestApis = await translateService.checkExistPrivateContent();

    if (requestApis.status === RESPONSE_CODE.SUCCESS) {
        const { data } = requestApis.data;

        const transaction = db.transaction("translatePrivate", "readwrite"); // (1)
        const translateKeysData = transaction.objectStore("translatePrivate");

        if (data) {
            Object.keys(data).forEach((item) => {
                const isTranslateVnExist = TRANSLATE_VN_KEYS.includes(item);
                const isTranslateENExist = TRANSLATE_EN_KEYS.includes(item);

                if (isTranslateVnExist || isTranslateENExist) {
                    const checkExist = translateKeysData.get(item);

                    // on check success
                    checkExist.onsuccess = (event) => {
                        const { result } = event.target;
                        if (!result || data[item] !== result.value) {
                            // console.log(`The translate with ${item} not found`);
                            const translateKey = {
                                value: data[item],
                                keys: item,
                            };

                            getContentTranslate(
                                indexedDb,
                                item,
                                "translatePrivateContent"
                            );
                            translateKeysData.put(translateKey);
                        } else {
                            // console.log(
                            //     `The translate with key ${item} existed`
                            // );
                        }
                    };
                }
            });
        }

        transaction.oncomplete = () => {
            const transactionContent = db.transaction(
                "translatePrivateContent",
                "readwrite"
            );
            const translateKeysContentData = transactionContent.objectStore(
                "translatePrivateContent"
            );

            // update content for vn
            TRANSLATE_VN_KEYS.forEach((item) => {
                const contentData = translateKeysContentData.get(item);
                contentData.onsuccess = (event) => {
                    const { result } = event.target;
                    if (result) {
                        if (TRANSLATE_VN_KEYS.includes(result.keys)) {
                            insertResourceTranslate(
                                "vn",
                                result.keys.replace("_vn", ""),
                                result.value
                            );
                        }
                    }
                };
            });

            // update content for en
            TRANSLATE_EN_KEYS.forEach((item) => {
                const contentData = translateKeysContentData.get(item);
                contentData.onsuccess = (event) => {
                    const { result } = event.target;
                    if (result) {
                        if (TRANSLATE_EN_KEYS.includes(result.keys)) {
                            insertResourceTranslate(
                                "en",
                                result.keys.replace("_en", ""),
                                result.value
                            );
                        }
                    }
                };
            });
        };
    }
};

const initTranslate = () => {
    const openRequest = DB();
    openRequest.onsuccess = async () => {
        getTranslateKeys(openRequest);
    };
    openRequest.onupgradeneeded = async () => {
        // triggers if the client had no database
        // ...perform initialization...
        const db = openRequest.result;

        if (!db.objectStoreNames.contains("translatePrivate")) {
            // if there's no "translatePrivate" store
            const objectStore = db.createObjectStore("translatePrivate", {
                keyPath: "keys",
                autoIncrement: true,
            });

            objectStore.createIndex("translatePrivateIndex", "keys", {
                unique: false,
            });
        }

        if (!db.objectStoreNames.contains("translatePrivateContent")) {
            // if there's no "translatePrivateContent" store
            const objectStore = db.createObjectStore(
                "translatePrivateContent",
                {
                    keyPath: "keys",
                    autoIncrement: true,
                }
            );

            objectStore.createIndex("translatePrivateContentIndex", "keys", {
                unique: false,
            });
        }

        if (!db.objectStoreNames.contains("translate")) {
            // if there's no "translate" store
            const objectStore = db.createObjectStore("translate", {
                keyPath: "keys",
                autoIncrement: true,
            });
            objectStore.createIndex("translateIndex", "keys", {
                unique: false,
            });
        }

        if (!db.objectStoreNames.contains("translateContent")) {
            // if there's no "translateContent" store
            const objectStore = db.createObjectStore("translateContent", {
                keyPath: "keys",
                autoIncrement: true,
            });

            objectStore.createIndex("translateContentIndex", "keys", {
                unique: false,
            });
        }
    };
};

const cleanPrivateContent = () => {
    const openRequest = DB();
    openRequest.onsuccess = () => {
        const db = openRequest.result;
        const transactionContent = db.transaction(
            "translatePrivateContent",
            "readwrite"
        );
        const translateKeysContentData = transactionContent.objectStore(
            "translatePrivateContent"
        );

        const transaction = db.transaction("translatePrivate", "readwrite"); // (1)
        const translateKeysData = transaction.objectStore("translatePrivate");
        translateKeysContentData.clear();
        translateKeysData.clear();

        translateKeysContentData.onsuccess = () => {
            // report the success of our request
            console.log("translatePrivateContent clear");
            db.close();
        };

        translateKeysData.onsuccess = () => {
            // report the success of our request
            console.log("translateKeysData clear");
            db.close();
        };
    };
};

const Translate = {
    initTranslate,
    cleanPrivateContent,
};

export default Translate;
