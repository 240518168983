import DoubleButtons from "@components/DoubleButtons";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
    boxOption: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
}));

const PaymentCancelConfirmation = ({ onConfirm, progressing }) => {
    const { t } = useTranslation("translation", "payAnyone");
    const [isDeleteCheckbox, setIsDeleteCheckbox] = useState(true);
    const classes = useStyles();

    const onCloseHandler = () => {
        GlobalDialogController.hide();
    };

    const onChangeHandler = (e) => {
        setIsDeleteCheckbox(e.target.checked);
    };

    return (
        <>
            <LspDialogTitle>
                <div>{t("payment_lb_confirm_delete")} </div>
            </LspDialogTitle>
            <LspDialogContent className={classes.wrapper}>
                <Typography>
                    {t("payAnyone:popup_payment_cancel_content")}
                </Typography>
                <FormGroup className={classes.boxOption}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isDeleteCheckbox}
                                onChange={onChangeHandler}
                                name="isDeleteCheckbox"
                            />
                        }
                        label={t("payAnyone:popup_payment_cancel_content2")}
                    />
                </FormGroup>
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    progressing={progressing}
                    primaryButton={{
                        label: t("reissue_new_pin_card_lp_btn_confirm"),
                        onClick: () => onConfirm(isDeleteCheckbox),
                    }}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: () => onCloseHandler(),
                    }}
                />
            </LspDialogActions>
        </>
    );
};

const stateProps = (state) => ({
    progressing: state.account.paymentDetail.fetching,
});

export default connect(stateProps)(PaymentCancelConfirmation);
