/* eslint-disable react/no-array-index-key */
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import { Box, Grid, Icon, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCallback, useRef, useEffect, useState } from "react";
import accountService from "@services/account";
import {
    CreditCardNavigationKey,
    REGEX_PATTERN,
    RESPONSE_CODE,
} from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { useHistory, useParams } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import LspTextField from "@components/LspTextField";
import TermCondition from "@components/TermCondition";
import useNumber from "@helpers/useNumber";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import useOTP from "@helpers/useOTP";
import LspTranslation from "@components/LspTranslation";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import moveMoneyService from "@services/move-money";
import Loading from "@components/Loading";
import PageHeader from "@components/PageHeader";

const useStyles = makeStyles((theme) => ({
    uppercase: {
        textTransform: "uppercase",
    },
    converison: {
        padding: theme.spacing(4),
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[3],
    },
    selection: {
        display: "flex",
        justifyContent: "space-between",
        "& > p": {
            border: `1px solid ${theme.palette.success.main}`,
            flex: 1,
            textAlign: "center",
            borderRadius: theme.spacing(3.25),
            padding: theme.spacing(0.5, 0),
            marginRight: theme.spacing(1),
            cursor: "pointer",
            "&.active": {
                color: theme.palette.neutral.white,
                backgroundColor: theme.palette.success.main,
            },
        },
        marginBottom: theme.spacing(2.5),
    },
    bottomRight: {
        justifyContent: "flex-end",
    },
    bottomColumn: {
        padding: theme.spacing(2, 0),
        backgroundColor: theme.palette.grey[200],
    },
    outerColumn: {
        padding: theme.spacing(2, 0),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    centerColumn: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    boxDetail: {
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(2.25),
    },
    icon: {
        background: theme.palette.primary.main,
        borderRadius: "50%",
        padding: theme.spacing(0.25),
        marginRight: theme.spacing(1),
        width: "25px",
        height: "25px",
        color: theme.palette.neutral.white,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: "25px",
        textAlign: "center",
    },
    disableSelect: {
        cursor: "not-allowed !important",
    },
}));

const InstallmentConversion = () => {
    const classes = useStyles();
    const { t } = useTranslation("creditCard");
    const history = useHistory();
    const { txnId } = useParams();
    const unmounted = useRef(false);
    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [installmentName, setInstallmentName] = useState("");
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [termSelected, setTermSelected] = useState(null);
    const { generateChildPathname } = usePathname();
    const { formatNumber } = useNumber();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const [error, setError] = useState("");
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const closeDialog = useCallback(() => {
        history.goBack();
    }, [history]);

    const check = useCallback(async () => {
        setFetching(true);
        const { data } = await accountService.checkTransactionInstallment({
            txnId,
        });

        setFetching(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setPaymentDetail(data.data);
                setTermSelected(data.data.paymentTerms[0]);
                setInstallmentName(data.data.txnDesc);
                break;
            case RESPONSE_CODE.CONVERSION_NOT_QUALIFIED:
                GlobalDialogController.showCustomDialog({
                    onClose: closeDialog,
                    dialogInfo: {
                        iconImage: "Error",
                        header: "td_lb_error_sorry",
                        content: t("ins_cannot_convert"),
                        button: "lb_ok",
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [t, closeDialog, txnId]);

    const openInstallmentDetail = useCallback(
        (id) => {
            const path = CreditCardNavigationKey.InstallmentDetail;
            history.push(`/${path}/${id}`);
        },
        [history]
    );

    const getInstallmentById = useCallback(async () => {
        const response = await accountService.getInstallmentById({ id: txnId });
        if (unmounted.current) {
            return;
        }
        const { code } = response.data;
        setFetching(false);

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                openInstallmentDetail(txnId);
                break;
            case RESPONSE_CODE.DATABASE_NOT_FOUND:
                check();
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [txnId, openInstallmentDetail, check]);

    const parseRateValue = (value) => {
        const val = value || 0.0;
        return parseFloat(val).toFixed(1);
    };

    const onTermClick = (item, disableSelect) => {
        if (disableSelect) {
            return;
        }
        const selected = paymentDetail.paymentTerms.find(
            (elm) => elm.month === item
        );
        setTermSelected(selected);
    };

    const onChangeName = (e) => {
        const { value } = e.target;
        if (!value) {
            setError(t("translation:msg_we_need_this"));
        } else {
            setError("");
        }
        const name = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.INSTALLMENT_NAME,
            text: value,
        });

        setInstallmentName(name);
    };

    const commitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            txnId,
            ...dataToPost,
        };
        const { data } = await accountService.commitOTP(payload);
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                closeOTPDialog();
                history.push(CreditCardNavigationKey.InstallmentList);
                AlertController.show(
                    <LspTranslation i18nKey="creditCard:dropdown_banner_submitted" />,
                    AlertType.Success
                );
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const submit = async () => {
        setSubmitting(true);
        const payload = {
            totalPayment: termSelected.totalPayment,
            term: termSelected.month,
            monthlyPayment: termSelected.monthlyPayment,
            title: installmentName,
            txnAmount: paymentDetail.txnAmount,
            txnId,
        };
        const { data } = await accountService.applyInstallment(payload);
        setSubmitting(false);
        switch (data.code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: commitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.CONVERSION_NOT_QUALIFIED:
                GlobalDialogController.showCustomDialog({
                    onClose: closeDialog,
                    dialogInfo: {
                        iconImage: "Error",
                        header: "td_lb_error_sorry",
                        content: t("ins_cannot_convert"),
                        button: "lb_ok",
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };
    useEffect(() => {
        if (firstLoad) {
            getInstallmentById();
            setFirstLoad(false);
        }
    }, [getInstallmentById, firstLoad]);

    return (
        <Content size="md">
            <PageHeader backFunc={() => history.goBack()}>
                {t("creditCard:convert_ins_header")}
            </PageHeader>
            {fetching && <Loading />}
            {!fetching && (
                <Box maxWidth={555} margin="auto" mt={3}>
                    <Paper className={classes.converison}>
                        <LspTextField
                            autoFocus
                            name="accountNumber"
                            label={t("IN00022")}
                            value={installmentName || ""}
                            inputProps={{
                                maxLength: 50,
                            }}
                            error={!!error}
                            helperText={error || " "}
                            onChange={onChangeName}
                        />
                        <LspTypography variant="body1" color="grey">
                            {t("IN00019")}
                        </LspTypography>
                        <div className={classes.selection}>
                            {paymentDetail?.paymentTerms.map((item, key) => (
                                <LspTypography
                                    key={key}
                                    variant="body1"
                                    color="success"
                                    onClick={() =>
                                        onTermClick(
                                            item.month,
                                            !item.enableInstallment
                                        )
                                    }
                                    className={` ${
                                        !item.enableInstallment
                                            ? classes.disableSelect
                                            : ""
                                    }${
                                        termSelected?.month === item.month
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    {item.month}
                                </LspTypography>
                            ))}
                        </div>

                        <div className={classes.boxDetail}>
                            <Grid container>
                                <Grid
                                    container
                                    direction="column"
                                    item
                                    xs
                                    align="center"
                                >
                                    <Grid item className={classes.outerColumn}>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("IN00037")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="blue"
                                        >
                                            {formatNumber(
                                                -paymentDetail?.txnAmount
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item className={classes.outerColumn}>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("IN00007")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="black"
                                        >
                                            {`${parseRateValue(
                                                termSelected?.convertFeeRate
                                            )}%`}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item className={classes.outerColumn}>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("lb_close_early_fee")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="black"
                                        >
                                            {`${parseRateValue(
                                                termSelected?.closeEarlyFee
                                            )}%`}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" item xs={6}>
                                    <Grid
                                        item
                                        align="center"
                                        className={classes.centerColumn}
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("lb_monthly_principal")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="black"
                                        >
                                            {formatNumber(
                                                termSelected?.monthlyPrincipal
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid
                                        item
                                        align="center"
                                        className={classes.centerColumn}
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("lb_total_interest")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="black"
                                        >
                                            {formatNumber(
                                                termSelected?.convertFeeAmount
                                            ) || 0}
                                        </LspTypography>
                                    </Grid>
                                    <Grid
                                        item
                                        align="center"
                                        className={classes.centerColumn}
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                        >
                                            {t("IN00011")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="black"
                                        >
                                            {formatNumber(
                                                termSelected?.totalPayment
                                            )}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    align="center"
                                    className={classes.bottomColumn}
                                >
                                    <LspTypography
                                        variant="body2"
                                        color="black"
                                    >
                                        {t("lb_monthly_ins")}
                                    </LspTypography>
                                    <LspTypography
                                        variant="title2"
                                        color="primary"
                                    >
                                        {formatNumber(
                                            termSelected?.monthlyPayment
                                        )}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                        </div>

                        <Box display="flex">
                            <Icon
                                className={`font-icon icon-hint ${classes.icon}`}
                            />
                            <LspTypography variant="body2" color="black">
                                {t("convert_ins_note")}
                            </LspTypography>
                        </Box>

                        <Box textAlign="center" mt={2}>
                            <TermCondition
                                content={t("IN00041")}
                                keyList={t("IN00043", {
                                    returnObjects: true,
                                })}
                                type="DIALOG"
                            />
                        </Box>
                    </Paper>
                    <Box mt={4}>
                        <LspButton
                            onClick={submit}
                            fullWidth
                            progressing={submitting}
                            disabled={!installmentName}
                        >
                            {t("translation:lb_next")}
                        </LspButton>
                    </Box>
                </Box>
            )}
        </Content>
    );
};

export default InstallmentConversion;
