import { takeLatest, all, take, cancel } from "redux-saga/effects";

/* ------------- Action Types ------------- */
import { BillActionTypes } from "@redux/actions/bill";
import { NotificationActionType } from "@redux/actions/notification";
import { AppActionType } from "@config/constants";
import { WealthActionType } from "@redux/actions/wealth";
import { ForgotPasswordActionTypes } from "@redux/actions/forgor-password";
import { PaymentRequestTypes } from "@redux/actions/payment-request";
import { VinaWealthActionType } from "@redux/actions/vina-wealth";
import { BookingActionTypes } from "@redux/actions/booking";
import { getBookingList, getBookingDetail } from "@redux/sagas/booking";
import { MetricActionTypes } from "@redux/actions/metric";
import { TERM_DEPOSIT } from "@redux/actions/term-deposit";
import bank, { BANK_ACTIONS } from "@redux/actions/bank";
import overdraftAction, { OverdraftActionType } from "@redux/actions/overdraft";
import { AuthActionType } from "../actions/auth";
import { SystemParamActionType } from "../actions/system-params";
import { AccountActionType } from "../actions/account";
import { UserActionType } from "../actions/user";
import { QuickCodeActionTypes } from "../actions/quick-code";
import { MoveMoneyActionType } from "../actions/move-money";
import { PayeeActionType } from "../actions/payee";
import { CardManagementActionTypes } from "../actions/card-management";

/* ------------- Sagas ------------- */
import { authSaga, clientLogoutSaga, logoutSaga } from "./auth";
import {
    getSystemParamsSaga,
    getPhoneConfigsSysParamSaga,
} from "./system-params";
import {
    getAccountStatusSaga,
    getAccountListSaga,
    getSpendAccountTransactionListSaga,
    getCreditCardTransactionListSaga,
    getInstallmentDataSaga,
    getPayAnyoneDashboardSaga,
    getPayAnyoneListSaga,
    updatePaymentStatusSaga,
    getAccountLimit,
    modifyAccountLimit,
} from "./account";
import {
    getBankInfoSaga,
    getPayeeListSaga,
    getProfileSaga,
    getServerTimeSaga,
    updateSettingSaga,
    getFeatureState,
    reissueCheck,
    modifyAlias,
    getAliasList,
    toggleAlias,
    aliasFee,
    validationAlias,
    submitAlias,
    submitOTP,
} from "./user";
import {
    createNewQuickCodeSaga,
    checkValidQuickCodeSaga,
    forgotQuickCodeSaga,
    checkQuickCodeExistenceSaga,
} from "./quick-code";
import {
    commitOTPTransferByPhone,
    moveMoneySaga,
    transferByPhone,
} from "./move-money";
import { getPayeeDetailSaga } from "./payee";
import {
    getCardListSaga,
    updateCardStatusSaga,
    submitOTPCardManagementSaga,
    resetPinSaga,
    submitOTPResetPinSaga,
    getHangoutListReIssueCardSaga,
    reIssueCardSaga,
    submitOTPReIssueCardSaga,
    checkCardNumberSaga,
    cardActivationSaga,
    submitOTPCardActivationSaga,
    reIssueCardV2Saga,
    reIssuesCard,
    getMaskRequest,
    getMaskRequestCommit,
    cardLimitRequest,
    getCardLimitRequest,
} from "./card-management";
import { LspReceiptActionType } from "../actions/lsp-receipt";
import {
    getPaymentDetailSaga,
    getReceiptSaga,
    submitNotesSaga,
} from "./lsp-receipt";
import { LixiActionTypes } from "../actions/lixi";
import {
    getDashboardSaga,
    getLixiDetailSaga,
    getReadLixiListSaga,
    getUnreadLixiListSaga,
} from "./lixi";

import { TopUpActionType } from "../actions/top-up";
import {
    getContactListSaga,
    getPhoneConfigsSaga,
    topUpSaga,
    topUpOtpCommitSaga,
    deletePhoneNumberSaga,
} from "./top-up";

import {
    getBillListSaga,
    getSplitBillPaymentSaga,
    getSplitBillSaga,
} from "./bill";
import { checkNotificationSaga, getNotificationListSaga } from "./notification";
import { getProductListSaga } from "./wealth";
import { forgotPasswordSaga } from "./forgot-password";
import { getPaymentRequestList } from "./payment-request";
import {
    getPendingTransactionSaga,
    getCompletedTransactionSaga,
} from "./vina-wealth";
import { initMetricSaga, metricRequestSaga } from "./metrics";
import {
    earlyRedeemTD,
    getTernDepositDetail,
    getTernDepositRates,
    partialTD,
    previewPartialRate,
    validateSpecialRate,
    checkAutoDeposit,
    getAutoDeposit,
} from "./term-deposit";

import { getBankList, getBranchList, getProvinceList } from "./bank";
import { getOverdraftDetailSaga } from "./overdraft";

/* ------------- Connect Action Types To Sagas ------------- */
export default function* root() {
    while (true) {
        take(AppActionType.Start);

        const appTasks = yield all([
            takeLatest(AuthActionType.LoginRequest, authSaga),
            takeLatest(
                AuthActionType.SubmitDeviceAuthenticationOTPRequest,
                authSaga
            ),
            takeLatest(AuthActionType.SubmitGGTwoFactorOTPRequest, authSaga),
            takeLatest(AuthActionType.SubmitTwoFactorOTPRequest, authSaga),
            takeLatest(AuthActionType.LogoutRequest, logoutSaga),
            takeLatest(AuthActionType.LogoutSuccess, clientLogoutSaga),
            takeLatest(AuthActionType.ForceLogout, clientLogoutSaga),
            takeLatest(
                AccountActionType.GetAccountStatusRequest,
                getAccountStatusSaga
            ),
            takeLatest(
                SystemParamActionType.GetSystemParamsRequest,
                getSystemParamsSaga
            ),
            takeLatest(
                SystemParamActionType.GetPhoneConfigs,
                getPhoneConfigsSysParamSaga
            ),
            takeLatest(
                AccountActionType.GetAccountListRequest,
                getAccountListSaga
            ),
            takeLatest(UserActionType.GetProfileRequest, getProfileSaga),
            takeLatest(UserActionType.GetFeatureStateRequest, getFeatureState),
            takeLatest(UserActionType.GetBankInfoRequest, getBankInfoSaga),
            takeLatest(
                AccountActionType.GetSpendAccountTransactionListRequest,
                getSpendAccountTransactionListSaga
            ),
            takeLatest(
                AccountActionType.GetCreditCardTransactionListRequest,
                getCreditCardTransactionListSaga
            ),
            takeLatest(UserActionType.UpdateSettingRequest, updateSettingSaga),
            takeLatest(UserActionType.GetServerTimeRequest, getServerTimeSaga),
            takeLatest(UserActionType.GetPayeeListRequest, getPayeeListSaga),
            takeLatest(
                QuickCodeActionTypes.CheckQuickCodeExistence,
                checkQuickCodeExistenceSaga
            ),
            takeLatest(
                QuickCodeActionTypes.CreateNewQuickCode,
                createNewQuickCodeSaga
            ),
            takeLatest(
                QuickCodeActionTypes.CheckValidQuickCode,
                checkValidQuickCodeSaga
            ),
            takeLatest(
                QuickCodeActionTypes.ForgotQuickCode,
                forgotQuickCodeSaga
            ),
            takeLatest(
                MoveMoneyActionType.TransferToDebitCardRequest,
                moveMoneySaga
            ),
            takeLatest(
                MoveMoneyActionType.TransferToAccountNumberRequest,
                moveMoneySaga
            ),
            takeLatest(
                MoveMoneyActionType.TransferToCreditCardRequest,
                moveMoneySaga
            ),
            takeLatest(MoveMoneyActionType.SubmitOTP, moveMoneySaga),
            takeLatest(
                PayeeActionType.GetPayeeDetailRequest,
                getPayeeDetailSaga
            ),

            // Card Management
            takeLatest(CardManagementActionTypes.GetCardList, getCardListSaga),
            takeLatest(
                CardManagementActionTypes.UpdateCardStatus,
                updateCardStatusSaga
            ),
            takeLatest(
                CardManagementActionTypes.OTPCommit,
                submitOTPCardManagementSaga
            ),
            takeLatest(
                CardManagementActionTypes.UpdateECommerce,
                updateCardStatusSaga
            ),
            takeLatest(CardManagementActionTypes.ResetPin, resetPinSaga),
            takeLatest(
                CardManagementActionTypes.OTPCommitResetPin,
                submitOTPResetPinSaga
            ),
            takeLatest(
                CardManagementActionTypes.GetHangoutListReIssue,
                getHangoutListReIssueCardSaga
            ),
            takeLatest(CardManagementActionTypes.ReIssueCard, reIssueCardSaga),
            takeLatest(
                CardManagementActionTypes.ReIssueCardV2,
                reIssueCardV2Saga
            ),
            takeLatest(
                CardManagementActionTypes.OTPCommitReIssueCard,
                submitOTPReIssueCardSaga
            ),
            takeLatest(LspReceiptActionType.GetReceipt, getReceiptSaga),
            takeLatest(
                CardManagementActionTypes.CheckCardNumber,
                checkCardNumberSaga
            ),
            takeLatest(
                CardManagementActionTypes.CardActivation,
                cardActivationSaga
            ),
            takeLatest(
                CardManagementActionTypes.OTPCommitCardActivation,
                submitOTPCardActivationSaga
            ),
            takeLatest(
                CardManagementActionTypes.GetUserAddressRequest,
                reIssuesCard
            ),

            // Lixi
            takeLatest(LixiActionTypes.GetDashboard, getDashboardSaga),
            takeLatest(LixiActionTypes.GetReadLixiList, getReadLixiListSaga),
            takeLatest(
                LixiActionTypes.GetUnreadLixiList,
                getUnreadLixiListSaga
            ),
            takeLatest(LixiActionTypes.GetLixiDetail, getLixiDetailSaga),

            takeLatest(TopUpActionType.GetContactList, getContactListSaga),
            takeLatest(TopUpActionType.GetPhoneConfigs, getPhoneConfigsSaga),
            takeLatest(TopUpActionType.TopUpRequest, topUpSaga),
            takeLatest(TopUpActionType.OTPCommit, topUpOtpCommitSaga),
            takeLatest(TopUpActionType.DeletePhone, deletePhoneNumberSaga),

            takeLatest(BillActionTypes.GetBillList, getBillListSaga),
            takeLatest(
                NotificationActionType.CheckNotificationRequest,
                checkNotificationSaga
            ),
            takeLatest(
                NotificationActionType.GetNotificationListRequest,
                getNotificationListSaga
            ),
            takeLatest(
                AccountActionType.GetInstallmentDataRequest,
                getInstallmentDataSaga
            ),
            takeLatest(
                AccountActionType.GetPayAnyoneDashboardRequest,
                getPayAnyoneDashboardSaga
            ),
            takeLatest(
                AccountActionType.GetPayAnyoneListRequest,
                getPayAnyoneListSaga
            ),
            takeLatest(MoveMoneyActionType.TransferByPhone, transferByPhone),
            takeLatest(
                MoveMoneyActionType.CommitOTPTransferByPhone,
                commitOTPTransferByPhone
            ),
            takeLatest(
                WealthActionType.GetProductListRequest,
                getProductListSaga
            ),
            takeLatest(
                AccountActionType.UpdatePaymentStatusRequest,
                updatePaymentStatusSaga
            ),
            takeLatest(
                PaymentRequestTypes.GetPaymentRequestList,
                getPaymentRequestList
            ),
            takeLatest(
                PaymentRequestTypes.GetPaymentRequestCompleteList,
                getPaymentRequestList
            ),
            takeLatest(ForgotPasswordActionTypes.OTPCommit, forgotPasswordSaga),
            takeLatest(
                VinaWealthActionType.GetPendingTransaction,
                getPendingTransactionSaga
            ),
            takeLatest(
                VinaWealthActionType.GetCompletedTransaction,
                getCompletedTransactionSaga
            ),
            takeLatest(BookingActionTypes.GetBookingList, getBookingList),
            takeLatest(BookingActionTypes.GetBookingDetail, getBookingDetail),
            takeLatest(UserActionType.ReIssuesCheckRequest, reissueCheck),
            takeLatest(BillActionTypes.GetSplitBill, getSplitBillSaga),
            takeLatest(
                BillActionTypes.GetSplitBillPayment,
                getSplitBillPaymentSaga
            ),
            takeLatest(MetricActionTypes.InitMetricRequest, initMetricSaga),
            takeLatest(MetricActionTypes.MetricRequest, metricRequestSaga),

            takeLatest(UserActionType.ModifyAliasRequest, modifyAlias),
            takeLatest(UserActionType.GetAliasRequest, getAliasList),
            takeLatest(UserActionType.ToggleAliasRequest, toggleAlias),
            takeLatest(
                CardManagementActionTypes.GetMaskRequest,
                getMaskRequest
            ),
            takeLatest(
                CardManagementActionTypes.GetMaskOTPRequest,
                getMaskRequestCommit
            ),
            takeLatest(LspReceiptActionType.SubmitNotes, submitNotesSaga),
            takeLatest(
                LspReceiptActionType.GetPaymentDetailRequest,
                getPaymentDetailSaga
            ),
            takeLatest(
                CardManagementActionTypes.CardLimitRequest,
                cardLimitRequest
            ),
            takeLatest(
                CardManagementActionTypes.GetCardLimitRequest,
                getCardLimitRequest
            ),
            takeLatest(
                AccountActionType.GetAccountLimitRequest,
                getAccountLimit
            ),
            takeLatest(
                AccountActionType.ModifyAccountLimitRequest,
                modifyAccountLimit
            ),

            takeLatest(UserActionType.GetNicknameFeeRequest, aliasFee),
            takeLatest(UserActionType.CheckNicknameRequest, validationAlias),
            takeLatest(UserActionType.SubmitNicknameRequest, submitAlias),
            takeLatest(UserActionType.SubmitOTPNicknameRequest, submitOTP),
            takeLatest(TERM_DEPOSIT.GetTermDepositRates, getTernDepositRates),
            takeLatest(BANK_ACTIONS.GetBankList, getBankList),
            takeLatest(BANK_ACTIONS.GetBranchList, getBranchList),
            takeLatest(BANK_ACTIONS.GetProvinceList, getProvinceList),
            takeLatest(TERM_DEPOSIT.GetTermDepositDetail, getTernDepositDetail),
            takeLatest(
                TERM_DEPOSIT.ValidateSpecialRateRequest,
                validateSpecialRate
            ),
            takeLatest(
                TERM_DEPOSIT.PreviewSpecialRateRequest,
                previewPartialRate
            ),
            takeLatest(TERM_DEPOSIT.PartialTDRequest, partialTD),
            takeLatest(TERM_DEPOSIT.EarlyRedeemTDRequest, earlyRedeemTD),
            takeLatest(TERM_DEPOSIT.CheckAutoDeposit, checkAutoDeposit),
            takeLatest(TERM_DEPOSIT.GetAutoDepositList, getAutoDeposit),
            takeLatest(
                OverdraftActionType.GetOverdraftDetail,
                getOverdraftDetailSaga
            ),
        ]);

        yield take(AppActionType.Stop);

        yield cancel(appTasks);
        console.log("tasks cancelled");
    }
}
