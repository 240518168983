import { RESPONSE_CODE } from "@config/constants";
import overdraftService from "@services/overdraft";
import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import useNumber from "@helpers/useNumber";
import { isEmpty } from "lodash";
import LspButton from "@components/LspButton";
import useOTP from "@helpers/useOTP";
import useAccounts from "@helpers/useAccounts";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import tommySuccess2 from "@assets/images/tommy_success_status_2.png";
import tommyNoHope from "@assets/images/no_hope_with_status_error.png";
import { Skeleton } from "@material-ui/lab";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OverdraftStatus from "./OverdraftStatus";

const CLOSE_VIEW_MODE = {
    MAIN: "main",
    STATUS: "status",
};

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
        textTransform: "uppercase",
    },
    content: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(5),
        boxShadow: theme.shadows[29],
        background: theme.palette.neutral.white,
    },
    skeleton: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));
const OverdraftClose = () => {
    const [isLoading, setIsLoading] = useState({
        content: false,
        action: false,
    });
    const [overdraftDetails, setOverdraftDetails] = useState({});
    const { formatNumber } = useNumber();
    const { t } = useTranslation();
    const classes = useStyles();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const { spendAccount } = useAccounts();
    const [viewMode, setViewMode] = useState(CLOSE_VIEW_MODE.MAIN);
    const history = useHistory();
    const [status, setStatus] = useState(null);

    const firstLoad = useRef(false);
    const unmounted = useRef(false);

    const handleGetManageOverdraft = useCallback(async () => {
        setIsLoading((prev) => ({ ...prev, content: true }));

        const result = await overdraftService.manageOverdraft();
        const { data, code } = result?.data;

        if (unmounted.current) return;

        setIsLoading((prev) => ({ ...prev, content: false }));
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setOverdraftDetails(data);

                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, []);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        if (firstLoad.current) return;

        handleGetManageOverdraft();
        firstLoad.current = true;
    }, [handleGetManageOverdraft, overdraftDetails]);

    const total = useMemo(
        () =>
            overdraftDetails?.outstandingAmount +
            overdraftDetails?.totalInterestAccrued +
            overdraftDetails?.earlyFee,
        [
            overdraftDetails?.earlyFee,
            overdraftDetails?.outstandingAmount,
            overdraftDetails?.totalInterestAccrued,
        ]
    );

    const closeEarlyResponseHandler = useCallback(
        (code) => {
            switch (code) {
                case RESPONSE_CODE.INCORRECT_VALUE:
                case RESPONSE_CODE.MISMATCH_VALUE:
                case RESPONSE_CODE.OTP_EXPIRED:
                    GlobalDialogController.showError({
                        errorCode: code,
                    });
                    break;
                case RESPONSE_CODE.SUCCESS:
                case RESPONSE_CODE.TECHNICAL_DIFFICULT:
                case RESPONSE_CODE.BAD_REQUEST:
                    setViewMode(CLOSE_VIEW_MODE.STATUS);
                    setStatus(code);
                    closeOTPDialog();
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [closeOTPDialog]
    );

    const submitOtp = useCallback(
        async (dataToPost) => {
            setIsLoading((prev) => ({ ...prev, action: true }));
            setLoading(true);

            const result = await overdraftService.commitEarlyClosure(
                dataToPost
            );
            const { code } = result?.data;

            setIsLoading((prev) => ({ ...prev, action: false }));
            setLoading(false);

            closeEarlyResponseHandler(code);
        },
        [setLoading, closeEarlyResponseHandler]
    );

    const handleCloseOd = useCallback(async () => {
        setIsLoading(true);
        setIsLoading((prev) => ({ ...prev, action: true }));
        const params = {
            source: {
                debitAccount: spendAccount?.no,
            },
        };
        const result = await overdraftService.applyEarlyClosure(params);
        const { data, code } = result?.data;

        setIsLoading((prev) => ({ ...prev, action: false }));
        setLoading(true);
        switch (code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.refNo,
                    submitFunc: submitOtp,
                });

                break;
            default:
                closeEarlyResponseHandler(code);
                break;
        }
    }, [
        openOTPDialog,
        setLoading,
        spendAccount?.no,
        submitOtp,
        closeEarlyResponseHandler,
    ]);
    const handleSubmitStatus = useCallback(() => {
        history.push("/home");
    }, [history]);

    const overdraftStatus = useMemo(() => {
        switch (status) {
            case RESPONSE_CODE.SUCCESS:
                return (
                    <>
                        <OverdraftStatus
                            src={tommySuccess2}
                            title={
                                <>
                                    <LspTypography variant="title2">
                                        {t(
                                            "overdraft:overdraft_result_early_closure_success_title"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            content={
                                <>
                                    <LspTypography
                                        variant="body2"
                                        textCenter
                                        className={classes.infoTitle}
                                        color="grey"
                                    >
                                        {t(
                                            "overdraft:overdraft_result_early_closure_success_description"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            action
                            actionTitle={t(
                                "overdraft:overdraft_result_early_closure_success_cta"
                            )}
                            onSubmit={handleSubmitStatus}
                            noContentBr
                        />
                    </>
                );

            default:
                return (
                    <>
                        <OverdraftStatus
                            src={tommyNoHope}
                            title={
                                <>
                                    <LspTypography variant="title2">
                                        {t(
                                            "overdraft:overdraft_result_early_closure_failure_title"
                                        )}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        textCenter
                                    >
                                        {t(
                                            "overdraft:overdraft_result_early_closure_failure_description"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            action
                            actionTitle={t(
                                "overdraft:overdraft_result_early_closure_failure_cta"
                            )}
                            noContentBr
                            onSubmit={handleSubmitStatus}
                        />
                    </>
                );
        }
    }, [classes.infoTitle, handleSubmitStatus, status, t]);
    return (
        <>
            <Content>
                {viewMode === CLOSE_VIEW_MODE.MAIN && (
                    <>
                        <div className={classes.title}>
                            <LspTypography
                                variant="title1"
                                textCenter
                                color="primary"
                            >
                                {t("overdraft:overdraft_early_closure_title")}
                            </LspTypography>
                        </div>
                        <Box mb={1}>
                            <LspTypography
                                variant="subheading1"
                                color="grey"
                                uppercase
                            >
                                {t(
                                    "overdraft:overdraft_early_closure_information"
                                )}
                            </LspTypography>
                        </Box>
                        <div className={classes.content}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_early_closure_information_total_amount"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography
                                        variant="subheading3"
                                        textRight
                                        component="div"
                                    >
                                        {!isLoading?.content &&
                                            formatNumber(total, false, true)}
                                        {isLoading?.content && (
                                            <div className={classes.skeleton}>
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_early_closure_information_outstanding"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography
                                        variant="subheading3"
                                        textRight
                                        component="div"
                                    >
                                        {!isLoading?.content &&
                                            formatNumber(
                                                overdraftDetails?.outstandingAmount
                                            )}
                                        {isLoading?.content && (
                                            <div className={classes.skeleton}>
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_early_closure_information_interest_accrued"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography
                                        variant="subheading3"
                                        textRight
                                        component="div"
                                    >
                                        {!isLoading?.content &&
                                            formatNumber(
                                                overdraftDetails?.totalInterestAccrued
                                            )}
                                        {isLoading?.content && (
                                            <div className={classes.skeleton}>
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_early_closure_information_fee"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography
                                        variant="subheading3"
                                        textRight
                                        component="div"
                                    >
                                        {!isLoading?.content &&
                                            formatNumber(
                                                overdraftDetails?.earlyFee
                                            )}
                                        {isLoading?.content && (
                                            <div className={classes.skeleton}>
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_early_closure_information_message"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <LspTypography
                                        variant="subheading3"
                                        textRight
                                        component="div"
                                    >
                                        {!isLoading?.content &&
                                            t(
                                                "overdraft:overdraft_early_closure_information_message_move_money"
                                            )}
                                        {isLoading?.content && (
                                            <div className={classes.skeleton}>
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                        </div>
                        <LspButton
                            fullWidth
                            onClick={() => handleCloseOd()}
                            progressing={isLoading?.action}
                            disabled={isLoading?.action}
                        >
                            {t(
                                "overdraft:overdraft_early_closure_move_money_cta"
                            )}
                        </LspButton>
                    </>
                )}
                {viewMode === CLOSE_VIEW_MODE.STATUS && overdraftStatus}
            </Content>
        </>
    );
};

export default OverdraftClose;
