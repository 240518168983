import { Box, Divider, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import thumbnailDefault from "@assets/images/safetyBox.png";
import { GENERAL_CONTENT, LANGUAGE_OPTIONS } from "@config/constants";
import useNumber from "@helpers/useNumber";
import { UAParser } from "ua-parser-js";
import Content from "@components/Content";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        color: "white",
        textAlign: "center",
        padding: theme.spacing(2),
        backgroundPosition: "center",
        borderRadius: theme.shape.borderRadius,
    },
    thumbnail: {
        maxWidth: 144,
        width: "100%",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    label: {
        opacity: "50%",
        marginTop: theme.spacing(2),
        fontSize: 14,
    },
    footer: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        "& button": {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
    },
}));

const appleDevices = ["ios", "mac os"];

const ClaimMoneyLixiDetailPopup = ({ detail, theme, onClose }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { formatNumber } = useNumber();
    const { os } = UAParser();

    const isAppleDevice = useCallback((name) => {
        return (
            appleDevices?.find((item) => item === name?.toLowerCase())?.length >
            0
        );
    }, []);

    const downloadApp = useCallback(() => {
        if (isAppleDevice(os?.name)) {
            window.open(GENERAL_CONTENT.APP_STORE, "_blank");
        } else {
            window.open(GENERAL_CONTENT.CH_PLAY, "_blank");
        }
    }, [os?.name, isAppleDevice]);

    const getMeaning = useCallback(
        (amount) => {
            const availableAmounts = t("lixi:LIXI_available_amount_keys", {
                returnObjects: true,
            }).concat(
                t("lixi:TD_available_amount_keys", { returnObjects: true })
            );

            const meaningKey = availableAmounts.find((i) => {
                const content = t(`lixi:${i}`, { returnObjects: true });
                return content?.amount === amount.toString();
            });

            if (meaningKey) {
                return t(`lixi:${meaningKey}`, { returnObjects: true })?.msg;
            }

            return false;
        },
        [t]
    );

    const thumbnail = useMemo(() => {
        return theme?.detail_foreground
            ? theme?.detail_foreground
            : thumbnailDefault;
    }, [theme]);

    const title = useMemo(
        () =>
            detail?.lixiType === "TD"
                ? t("lixi:lixi_td_detail_text_from")
                : t("lixi:lixi_mm_detail_text_from"),
        [detail, t]
    );

    const amount = useMemo(() => {
        const label =
            i18n.language === LANGUAGE_OPTIONS.EN ? "VND %@" : "%@ VND";
        const meaning = getMeaning(detail.amount);
        return {
            value: label.replace("%@", formatNumber(detail?.amount)),
            meaning: meaning && meaning !== "" ? meaning : "",
        };
    }, [detail, i18n, getMeaning, formatNumber]);

    return (
        <Content size="xs">
            <div
                className={classes.wrapper}
                style={{
                    backgroundColor: theme?.detail_color,
                    backgroundImage: `url(${theme?.detail_background})`,
                }}
            >
                <img
                    className={classes.thumbnail}
                    src={thumbnail}
                    alt="theme"
                />
                <LspTypography
                    color="white"
                    className={classes.label}
                    variant="body2"
                >
                    {title}
                </LspTypography>
                <LspTypography
                    color="white"
                    variant="heading2"
                    style={{ marginTop: 8 }}
                >
                    {detail.senderName}
                </LspTypography>

                <LspTypography color="white" className={classes.label}>
                    {t("lixi:lixi_amount")}
                </LspTypography>
                <LspTypography color="white" variant="title1">
                    {amount?.value}
                </LspTypography>
                <LspTypography color="white" variant="body2">
                    {amount?.meaning}
                </LspTypography>

                <LspTypography color="white" className={classes.label}>
                    {t("lixi:lixi_message")}
                </LspTypography>
                <LspTypography color="white" variant="body2">
                    {detail?.text}
                </LspTypography>
                <div className={classes.footer}>
                    <LspButton
                        variant="plain"
                        onClick={onClose}
                        className={classes.btn}
                    >
                        {t("lixi:lb_close")}
                    </LspButton>
                    <LspButton
                        variant="secondary"
                        onClick={downloadApp}
                        className={classes.btn}
                    >
                        {t("lixi:lixi_btn_install")}
                    </LspButton>
                </div>
                <Divider />
                <Box marginTop={1}>
                    <LspTypography color="white">
                        {t("lixi:lixi_text_install").replace(
                            "%@",
                            detail?.phoneNumber
                        )}
                    </LspTypography>
                </Box>
            </div>
        </Content>
    );
};

export default ClaimMoneyLixiDetailPopup;
