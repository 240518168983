/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Box, Paper } from "@material-ui/core";
import { useCallback, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { RESPONSE_CODE } from "@config/constants";
import LspButton from "@components/LspButton";
import wealthService from "@services/wealth";
import { useHistory } from "react-router-dom";
import useNumber from "@helpers/useNumber";
import { INSTANT_COVERAGE_STEPS } from "@containers/Wealth/wealthConstants";
import useAccounts from "@helpers/useAccounts";
import logo from "@assets/images/bitmap-min.png";
import useOTP from "@helpers/useOTP";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import moveMoneyService from "@services/move-money";

const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px",
    },
    reviewHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(99deg, #c86dd7, #3023ae)",
        padding: theme.spacing(2, 0),
    },
    logo: {
        width: 160,
        marginBottom: theme.spacing(2.5),
        marginTop: theme.spacing(5),
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        display: "block",
        width: "100%",
        textAlign: "right",
        cursor: "pointer",
    },

    reviewAmount: {
        padding: theme.spacing(2, 0),
        margin: theme.spacing(0, 5),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },

    reviewFooter: {
        textAlign: "center",
        border: `1px solid ${theme.palette.grey[500]}`,
        padding: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
}));

const RightPageMoveMoney = ({
    rightPackage,
    addressOption,
    prokeySelected,
    stepForward,
    formInfo,
    userAddress,
    setInfoReceipt,
    addressName,
    selectedProvince,
    selectedDistrict,
    setErrors,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const history = useHistory();
    const { formatNumber } = useNumber();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const { spendAccount } = useAccounts();
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const packageSelected = rightPackage.find(
        (item) => item.prokey === prokeySelected
    );

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const submitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            productKey: prokeySelected,
            ...dataToPost,
        };

        const { data } = await wealthService.commitOTP(payload);
        if (unmounted.current) return;

        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setInfoReceipt(data.data);
                stepForward(INSTANT_COVERAGE_STEPS.CONGRATULATION);
                closeOTPDialog();
                break;

            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const onCompleteHandler = () => {
        history.push(`products`);
    };

    const applyProduct = async () => {
        if (addressOption.newAddress) {
            if (
                !addressName ||
                selectedProvince.cfgKey === "" ||
                selectedDistrict.cfgKey === ""
            ) {
                setErrors({
                    address: !addressName ? t("msg_we_need_this") : "",
                    province:
                        selectedProvince.cfgKey === ""
                            ? t("msg_we_need_this")
                            : "",
                    district:
                        selectedDistrict.cfgKey === ""
                            ? t("msg_we_need_this")
                            : "",
                });
                return;
            }
        }

        setFetching(true);
        const dataToPost = {
            productKey: prokeySelected,
            name: formInfo.beneficiaryName,
            relationship: formInfo.relationship,
            dateOfBirth: formInfo.dateOfBirth,
            legalId: formInfo.legalId,
            gender: formInfo.genderName,
            shipAddress: addressOption.newAddress
                ? addressName
                : userAddress.addressName,
            shipCity: addressOption.newAddress
                ? selectedProvince.cfgValue
                : userAddress.cityName,
            shipDistrict: addressOption.newAddress
                ? selectedDistrict.cfgValue
                : userAddress.wardName,
        };

        const { data } = await wealthService.applyProduct(dataToPost);
        if (unmounted.current) return;
        setFetching(false);
        switch (data.code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: submitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.SUCCESS:
                setInfoReceipt(data.data);
                stepForward(INSTANT_COVERAGE_STEPS.CONGRATULATION);
                break;
            case RESPONSE_CODE.NOT_ALLOWED:
                GlobalDialogController.showCustomDialog({
                    onClose: onCompleteHandler,
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("wealth:SI00083"),
                        content: t("wealth:SI00084").replace(
                            "%@",
                            `<b>${formatNumber(packageSelected.valYouPay)}</b>`
                        ),
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
                GlobalDialogController.showCustomDialog({
                    onClose: onCompleteHandler,
                    dialogInfo: {
                        iconImage: "Error",
                        header: t("wealth:SI00087"),
                        content: t("wealth:SI00088"),
                        button: t("lb_ok"),
                    },
                });
                break;
            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    return (
        <Content size="xs">
            <div>
                <Icon
                    onClick={() => history.push("products")}
                    className={`font-icon icon-close ${classes.closeIcon}`}
                />
                <div className={classes.boxTitle}>
                    <Icon
                        className={`font-icon icon-insuranceinformation ${classes.icon}`}
                    />
                    <LspTypography variant="heading1" color="primary">
                        {t("wealth:SI00098")}
                    </LspTypography>
                </div>
                <Paper>
                    <Box textAlign="center" pb={4}>
                        <div className={classes.reviewHeader}>
                            <LspTypography variant="heading2" color="white">
                                {`${t("available_to_spend")} ${formatNumber(
                                    spendAccount?.availableAmount
                                )}`}
                            </LspTypography>
                        </div>
                        <img src={logo} alt="" className={classes.logo} />
                        <LspTypography
                            variant="title1"
                            color="primary"
                            className={classes.reviewAmount}
                        >
                            {formatNumber(packageSelected.valYouPay)}
                        </LspTypography>
                    </Box>
                </Paper>
                <div className={classes.reviewFooter}>
                    <LspTypography
                        variant="body2"
                        color="primary"
                        component="i"
                    >
                        {t("wealth:SI00050")}
                    </LspTypography>
                </div>
                <Box mt={3}>
                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={applyProduct}
                        progressing={fetching}
                    >
                        {t("wealth:SI00051")}
                    </LspButton>
                </Box>
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    userAddress: state.user.info.address,
});

export default connect(mapState, null)(RightPageMoveMoney);
