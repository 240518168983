import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect, useState, useCallback, useMemo, memo } from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";
import receiptActions from "@redux/actions/lsp-receipt";
import { isArray } from "lodash";
import LspNotes from "@components/LspNotes";
import { Box, makeStyles } from "@material-ui/core";

import clsx from "clsx";

import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { ATTACHMENT_MODE } from "@config/constants";
import AttachmentController from "@components/Attachment/AttachmentController";
import ReceiptContent from "./ReceiptContent";
import PaymentDetail from "./PaymentDetail";

const VIEW_MODE = {
    RECEIPT: 0,
    NOTE: 1,
    PAYMENT_DETAIL: 2,
    ATTACHMENT: 3,
};

const MAX_ATTACHMENT = 3;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            background: theme.palette.background.default,
            borderRadius: theme.shape.radiusLevels[3],
        },
        noBgColor: {
            backgroundColor: "unset",
            borderRadius: "unset",
        },
    };
});

/**
 * @param {object} props
 * @param {string} props.bankXID
 * @param {function} props.onClose
 * @param {string} props.button
 * @param {object} props.rawReceipt
 * @param {object} props.options
 * @param {bool} props.options.hiddenCurrency
 * @param {array} props.options.hiddenCurrencyKey
 * @param {bool} props.options.enableSendAgainButton
 * @param {func} props.options.onCompleteSendAgainHandler
 * @param {boolean} props.noMarginTop
 * @param {string} props.mode
 * @returns
 */

const LspReceipt = ({
    getReceipt,
    receipt,
    bankXID,
    onClose,
    loading,
    rawReceipt,
    button,
    reset,
    options,
    noMarginTop,
    enableAddNote,
    enableAddAttachment,
    viewType,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "txnapptype");
    const [parsedReceipt, setParsedReceipt] = useState(null);
    const [viewMode, setViewMode] = useState(VIEW_MODE.RECEIPT);
    const [noteUpdate, setNoteUpdate] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [attachmentView, setAttachmentView] = useState(null);
    const [attachmentConfig, setAttachmentConfig] = useState({
        type: ATTACHMENT_MODE.CREATE,
    });
    const [maxAttachment, setMaxAttachment] = useState(0);
    const [mode, setMode] = useState("create");

    const isEnableHideInfoOption = useCallback((field, list, amount) => {
        return list?.find((item) => item === field)?.length > 0 && amount < 0;
    }, []);

    const parseReceiptInfo = useCallback(
        ({ templateName, template, receiptObj, hiddenInfoOptions }) => {
            const info = [];
            for (let temp = 0; temp < template.length; temp++) {
                const line = template[temp].split("_");
                const field = line[0];
                const key = line[1];

                if (receiptObj[field]?.[key]) {
                    const label = t(
                        `txnapptype:${template[temp]}_${templateName}`,
                        {
                            defaultValue: "",
                        }
                    );
                    const item = {
                        value: `${label}${receiptObj[field][key]}`,
                        enableHideInfo: isEnableHideInfoOption(
                            key,
                            hiddenInfoOptions,
                            receiptObj?.txnAmount
                        ),
                        hideStatus: false,
                        key,
                    };
                    info.push(item);
                }
            }
            return info;
        },
        [t, isEnableHideInfoOption]
    );

    const handleCurrency = useCallback(
        (txnCurrency) => {
            if (options?.hiddenCurrency) {
                return options?.hiddenCurrencyKey.includes(txnCurrency)
                    ? " "
                    : txnCurrency;
            }
            return txnCurrency;
        },
        [options?.hiddenCurrencyKey, options?.hiddenCurrency]
    );

    const getReceiptTemplate = useCallback(
        (receiptObj) => {
            let parsedFromTemplate = [];
            let parsedToTemplate = [];
            const txtAppType = receiptObj.txnAppType;
            const templateName =
                receiptObj.title ||
                t(`txnapptype:${txtAppType}_template`, {
                    defaultValue: t("txnapptype:default_template"),
                });
            const title = t(`txnapptype:${txtAppType}_title`, {
                defaultValue: t("txnapptype:default_title"),
            });
            const fromTemplate = t(
                `txnapptype:receipt_txnFrom_order_${templateName}`,
                {
                    returnObjects: true,
                    defaultValue: "",
                }
            );
            const toTemplate = t(
                `txnapptype:receipt_txnTo_order_${templateName}`,
                {
                    returnObjects: true,
                    defaultValue: "",
                }
            );

            if (fromTemplate && receiptObj.txnFrom) {
                const hideAvailableListFrom = t(
                    `txnapptype:receipt_txnFrom_can_hide_${templateName}`,
                    { returnObjects: true }
                );

                parsedFromTemplate = parseReceiptInfo({
                    templateName,
                    template: fromTemplate,
                    receiptObj,
                    hiddenInfoOptions: isArray(hideAvailableListFrom)
                        ? hideAvailableListFrom
                        : [],
                });
            }

            if (toTemplate && receiptObj.txnTo) {
                const hideAvailableListTo = t(
                    `txnapptype:receipt_txnTo_can_hide_${templateName}`,
                    { returnObjects: true }
                );
                parsedToTemplate = parseReceiptInfo({
                    templateName,
                    template: toTemplate,
                    receiptObj,
                    hiddenInfoOptions: isArray(hideAvailableListTo)
                        ? hideAvailableListTo
                        : [],
                });
            }

            setParsedReceipt({
                title,
                txnAmount: receiptObj.txnAmount,
                txnFrom: parsedFromTemplate,
                txnTo: parsedToTemplate,
                txnDate: receiptObj.txnTimestamp
                    ? format(receiptObj.txnTimestamp, "dd/MM/yyyy - HH:mm")
                    : null,
                txnDesc: receiptObj.txnDesc,
                txnId: receiptObj?.traceNo
                    ? receiptObj?.traceNo
                    : receiptObj?.txnId,
                txnCurrency: handleCurrency(receiptObj?.txnCurrency),
                rawTxnTo: receiptObj?.txnTo,
                note: receiptObj?.note || "",
                bankXID: receiptObj?.bankXID || "",
                feeAmount: receiptObj?.feeAmount || "",
                enableAddAttachment: receiptObj?.isEnableAttachment || false,
                urlAttachments: receiptObj?.urlAttachments,
                txnStatus: receiptObj?.txnStatus,
            });
        },
        [t, handleCurrency, parseReceiptInfo]
    );

    useEffect(() => {
        if (rawReceipt) {
            getReceiptTemplate(rawReceipt);
        } else if (bankXID) {
            getReceipt({ refNo: bankXID });
        }
    }, [getReceipt, bankXID, rawReceipt, getReceiptTemplate]);

    useEffect(() => {
        if (!rawReceipt && receipt) {
            getReceiptTemplate(receipt);
        }
    }, [rawReceipt, receipt, getReceiptTemplate]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const handleClickAddNote = () => {
        setViewMode(VIEW_MODE.NOTE);
    };

    const handleChangeAttachment = useCallback(
        ({ fileManageId }) => {
            setViewMode(VIEW_MODE.ATTACHMENT);
            setMaxAttachment(1);
            setAttachmentConfig({
                type: ATTACHMENT_MODE.MODIFY,
                fileManageId,
            });
            if (rawReceipt) {
                GlobalDialogController.hide();
            }
        },
        [rawReceipt]
    );

    const handleCloseDialog = useCallback(() => {
        if (viewType !== "dialog") {
            GlobalDialogController.hide();
        }
    }, [viewType]);

    const handleOpenAttachment = useCallback(
        (view, attachmentData) => {
            GlobalDialogController.show({
                component: () => (
                    <>
                        <AttachmentController
                            refNo={bankXID || rawReceipt?.txnId}
                            viewData={attachmentData}
                            onBack={() => {
                                setViewMode(VIEW_MODE.RECEIPT);
                                handleCloseDialog();
                                setAttachmentConfig({
                                    type: ATTACHMENT_MODE.CREATE,
                                });
                            }}
                            onDelete={() => {
                                setViewMode(VIEW_MODE.RECEIPT);
                                getReceipt({
                                    refNo: bankXID || rawReceipt?.txnId,
                                });
                                handleCloseDialog();
                            }}
                            onUpload={(data) => {
                                setAttachments(data);
                                setViewMode(VIEW_MODE.RECEIPT);
                                handleCloseDialog();
                            }}
                            onModify={({ fileManageId }) =>
                                handleChangeAttachment({ fileManageId })
                            }
                            mode={view}
                            receipt={receipt || parsedReceipt}
                        />
                    </>
                ),
            });
        },
        [
            bankXID,
            getReceipt,
            handleChangeAttachment,
            handleCloseDialog,
            parsedReceipt,
            rawReceipt?.txnId,
            receipt,
        ]
    );

    const handleClickAddAttachment = () => {
        setMode("create");
        setAttachmentView(null);

        if (viewType === "dialog") {
            setViewMode(VIEW_MODE.ATTACHMENT);
            return;
        }
        handleOpenAttachment("create");
    };

    const handleViewAttachMent = (data) => {
        setAttachmentView(data);
        setMode("view");
        if (viewType === "dialog") {
            setViewMode(VIEW_MODE.ATTACHMENT);
            return;
        }
        handleOpenAttachment("view", data);
    };

    const handleClickCloseAddNote = () => {
        setViewMode(VIEW_MODE.RECEIPT);
    };

    const handleSaveNotes = () => {
        getReceipt({ refNo: bankXID || rawReceipt.txnId });
        setViewMode(VIEW_MODE.RECEIPT);
        setNoteUpdate(true);
    };

    const getNote = useMemo(() => {
        return parsedReceipt?.note || receipt?.note;
    }, [parsedReceipt, receipt]);

    const handlePaymentDetail = () => {
        setViewMode(VIEW_MODE.PAYMENT_DETAIL);
    };

    const handlePaymentDetailBack = () => {
        setViewMode(VIEW_MODE.RECEIPT);
    };

    const getAttachment = useMemo(() => {
        const attachmentFiles = receipt?.urlAttachments;

        setMaxAttachment(
            MAX_ATTACHMENT - ((attachmentFiles && attachmentFiles.length) || 0)
        );
        return attachmentFiles;
    }, [receipt]);

    const handleUploadSuccess = () => {
        setViewMode(VIEW_MODE.RECEIPT);
        setAttachments([]);
        getReceipt({ refNo: bankXID || rawReceipt.txnId });
        setAttachmentConfig({
            type: ATTACHMENT_MODE.CREATE,
        });
    };

    return (
        <Box
            className={clsx(
                classes.wrapper,
                (viewMode === VIEW_MODE.VIEW_ATTACHMENT ||
                    viewMode === VIEW_MODE.ATTACHMENT) &&
                    classes.noBgColor
            )}
        >
            {viewMode === VIEW_MODE.RECEIPT && (
                <ReceiptContent
                    receiptData={parsedReceipt}
                    onClose={() =>
                        onClose({
                            isNoteUpdate: noteUpdate,
                            note: parsedReceipt?.note,
                        })
                    }
                    button={button}
                    loading={loading}
                    noMarginTop={noMarginTop}
                    options={options}
                    onAddNote={() => handleClickAddNote()}
                    onAddAttachment={() => {
                        handleClickAddAttachment();
                    }}
                    enableAddNote={options?.enableAddNote && enableAddNote}
                    note={getNote}
                    attachments={getAttachment}
                    uploadAttachments={attachments}
                    onHandlePaymentDetailSubmit={() => handlePaymentDetail()}
                    onViewAttachment={(data) => {
                        handleViewAttachMent(data);
                    }}
                    onUploadAttachmentSuccess={() => handleUploadSuccess()}
                    refNo={bankXID || rawReceipt?.txnId}
                    enableAddAttachment={parsedReceipt?.enableAddAttachment}
                    attachmentType={attachmentConfig?.type}
                    fileManageId={attachmentConfig?.fileManageId}
                />
            )}

            {viewMode === VIEW_MODE.PAYMENT_DETAIL && (
                <PaymentDetail
                    onBack={handlePaymentDetailBack}
                    bankXID={parsedReceipt?.bankXID}
                />
            )}

            {viewMode === VIEW_MODE.NOTE && (
                <LspNotes
                    refNo={bankXID || rawReceipt?.txnId}
                    note={getNote}
                    onSuccess={() => handleSaveNotes()}
                    onClose={() => handleClickCloseAddNote()}
                />
            )}

            {viewMode === VIEW_MODE.ATTACHMENT && (
                <AttachmentController
                    refNo={bankXID || rawReceipt.txnId}
                    viewData={attachmentView}
                    onBack={() => {
                        setViewMode(VIEW_MODE.RECEIPT);
                        setAttachmentConfig({
                            type: ATTACHMENT_MODE.CREATE,
                        });
                    }}
                    onDelete={() => {
                        setViewMode(VIEW_MODE.RECEIPT);
                        getReceipt({ refNo: bankXID || rawReceipt.txnId });
                    }}
                    onUpload={(data) => {
                        setAttachments(data);
                        setViewMode(VIEW_MODE.RECEIPT);
                    }}
                    onModify={({ fileManageId }) =>
                        handleChangeAttachment({ fileManageId })
                    }
                    mode={mode}
                    receipt={parsedReceipt}
                />
            )}
        </Box>
    );
};

LspReceipt.propTypes = {
    bankXID: PropTypes.string,
    onClose: PropTypes.func,
    button: PropTypes.string,
    options: PropTypes.shape({
        hiddenCurrency: PropTypes.bool,
        hiddenCurrencyKey: PropTypes.arrayOf(PropTypes.oneOf(["none", "VND"])),
        enableSendAgainButton: PropTypes.bool,
        onCompleteSendAgainHandler: PropTypes.func,
        enableAddNote: PropTypes.bool,
    }),
    noMarginTop: PropTypes.bool,
    enableAddNote: PropTypes.bool,
    enableAddAttachment: PropTypes.bool,
    viewType: PropTypes.oneOf(["dialog", ""]),
};

LspReceipt.defaultProps = {
    bankXID: null,
    onClose: null,
    button: null,
    options: {
        hiddenCurrency: false,
        hiddenCurrencyKey: ["none"],
        enableSendAgainButton: false,
        onCompleteSendAgainHandler: null,
        enableAddNote: true,
    },
    noMarginTop: false,
    enableAddNote: true,
    enableAddAttachment: false,
    viewType: "",
};

const stateProps = (state) => ({
    loading: state.receipt.loading,
    receipt: state.receipt.info,
});

const dispatchProps = (dispatch) => ({
    getReceipt: (payload) => dispatch(receiptActions.getReceipt(payload)),
    reset: () => dispatch(receiptActions.reset()),
});
export default connect(stateProps, dispatchProps)(memo(LspReceipt));
