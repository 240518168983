/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { ButtonBase, Icon, makeStyles } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { useMemo } from "react";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import FooterControlActions from "@components/FooterControlActions";

const useStyles = makeStyles((theme) => ({
    body: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        padding: theme.spacing(0, 3),
        "& > div": {
            width: "30%",
            textAlign: "center",
            margin: theme.spacing(2, 0),
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    iconWrapper: {
        width: "64px",
        height: "64px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.primary.main,
        margin: "0 auto",
        "&:hover": {
            background: theme.palette.status.success,
        },
        cursor: "pointer",
    },

    icon: {
        color: theme.palette.neutral.white,
        fontSize: theme.typography.pxToRem(40),
    },
    footer: {
        padding: theme.spacing(2, 3, 3, 3),
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
}));

const GoalSavePurpose = ({ stepForward, onNextDetail }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const iconGoal = useMemo(() => {
        const listIcon = [
            { icon: "laptop", title: "gs_lb_electronics", type: "electronics" },
            { icon: "phone", title: "gs_lb_phone" },
            { icon: "vehicle", title: "gs_lb_vehicle" },
            { icon: "travel", title: "gs_lb_travel" },
            { icon: "diamond", title: "gs_lb_jewelry", type: "jewelry" },
            { icon: "ring", title: "gs_lb_wedding", type: "wedding" },
            { icon: "baby", title: "gs_lb_baby" },
            { icon: "study", title: "gs_lb_study" },
            { icon: "other", title: "gs_lb_other" },
        ];
        return listIcon;
    }, []);

    const onChooseOption = (item) => {
        onNextDetail({ goalCategory: item.type || item.icon });
        stepForward(2);
    };

    const buttons = {
        label: t("lb_cancel"),
        onClick: () => GlobalDialogController.hide(),
    };

    return (
        <>
            <div className={classes.body}>
                {iconGoal.map((item, key) => (
                    <div key={key}>
                        <ButtonBase
                            className={classes.iconWrapper}
                            onClick={() => onChooseOption(item)}
                            datatestid={`goalType${item?.icon}`}
                        >
                            <Icon
                                className={`font-icon icon-${item.icon} ${classes.icon}`}
                            />
                        </ButtonBase>
                        <LspTypography variant="body2" color="grey">
                            {t(`${item.title}`)}
                        </LspTypography>
                    </div>
                ))}
            </div>
            <FooterControlActions
                rightButton={buttons}
                wrapperClasses={classes.footer}
            />
        </>
    );
};

export default GoalSavePurpose;
