/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE } from "@config/constants";
import { Box, makeStyles } from "@material-ui/core";
import invitationService from "@services/invitation";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import InvitationJoinedItem from "./InvitationJoinedItem";
import InvitationJoinedListLoading from "./InvitationJoinedListLoading";

const useStyles = makeStyles((theme) => ({
    joinedList: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        justifyContent: "center",
        alignItems: "center",
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 3.5),
        borderRadius: theme.shape.radiusLevels[1],
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& > div:first-child": {
            flex: 1,
            marginRight: theme.spacing(2),
        },
    },
}));

const InvitationJoinedList = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const unmounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [joinedList, setJoinedList] = useState(null);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getJoinedList = useCallback(async () => {
        setLoading(true);
        const response = await invitationService.getJoinedList();
        setLoading(false);
        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setJoinedList(data);
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        getJoinedList();
    }, [getJoinedList]);

    return (
        <Content>
            <Box className={classes.joinedList} paddingX={2}>
                {loading && <InvitationJoinedListLoading className={classes} />}
                {!loading && (
                    <>
                        {joinedList?.length === 0 && (
                            <LspTypography variant="body1" color="grey">
                                {t("if_lb_empty_joined_invites")}
                            </LspTypography>
                        )}
                        {joinedList?.length > 0 &&
                            joinedList?.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <InvitationJoinedItem
                                            item={item}
                                            index={index}
                                            className={classes}
                                        />
                                    </Fragment>
                                );
                            })}
                    </>
                )}
            </Box>
        </Content>
    );
};

export default InvitationJoinedList;
