import { connect } from "react-redux";
import {
    AppBar,
    Toolbar,
    makeStyles,
    Typography,
    ButtonBase,
    Hidden,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import layoutAction from "@redux/actions/layout";
import { Logo } from "@components/LspBranding";
import { MenuIcon, ScanIcon } from "@components/LspIcon";
import NotificationButton from "./NotificationButton";

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main,
    },
    logo: {
        width: 112,
        cursor: "pointer",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    horizontalMenu: {
        ...theme.mixins.toolbar,
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        [theme.breakpoints.up("sm")]: {
            justifyContent: "center",
        },
    },
    horizontalMenuItem: {
        // width: 108,
        paddingTop: 4,
        borderBottomWidth: 4,
        borderBottomColor: "transparent",
        borderBottomStyle: "solid",
        transition: "ease 300ms border-color",
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: "pointer",
        textTransform: "uppercase",
        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
        "&:hover": {
            borderBottomColor: "rgba(255, 255, 255, 0.3)",
        },
        "&.selected": {
            borderBottomColor: "rgba(255, 255, 255, 1)",
        },
    },
    toolbar: {
        paddingRight: 0,
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.up("xs")]: {
            paddingLeft: 0,
        },
    },
    iconButton: {
        height: theme.mixins.toolbar.minHeight,
        width: theme.spacing(6),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(8),
        },
    },
}));

const menuItems = [
    {
        label: "nav_lb_my_account",
        path: "/home",
        id: "homeTopBar",
    },
    {
        label: "nav_lb_lifestyle",
        path: "/wealth/dashboard",
        id: "lifeStyleTopBar",
    },
];

const TopBar = ({ sideMenuOpened, openSideMenu, closeSideMenu }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();

    const toggleSideMenu = () => {
        if (sideMenuOpened) {
            closeSideMenu();
            return;
        }
        openSideMenu();
    };

    return (
        <AppBar elevation={0} position="fixed" className={classes.root}>
            <Toolbar classes={{ gutters: classes.toolbar }}>
                <Hidden xsDown>
                    <Logo
                        variant="light"
                        className={classes.logo}
                        onClick={() => history.push("/home")}
                    />
                </Hidden>
                <div className={classes.horizontalMenu}>
                    {menuItems.map((item) => (
                        <ButtonBase
                            key={item.label}
                            className={clsx(
                                classes.horizontalMenuItem,
                                pathname === item.path ? "selected" : null
                            )}
                            onClick={() => {
                                if (pathname === item.path) {
                                    return;
                                }
                                if (item.path) {
                                    history.push(item.path);
                                }
                            }}
                            datatestid={item?.id}
                        >
                            <Typography>{t(item.label)}</Typography>
                        </ButtonBase>
                    ))}
                </div>
                <ButtonBase
                    className={classes.iconButton}
                    aria-label="scan"
                    onClick={() => history.push("/bank-qr-code")}
                >
                    <ScanIcon />
                </ButtonBase>
                <NotificationButton className={classes.iconButton} />
                <ButtonBase
                    className={classes.iconButton}
                    aria-label="menu"
                    onClick={toggleSideMenu}
                >
                    <MenuIcon />
                </ButtonBase>
            </Toolbar>
        </AppBar>
    );
};

const mapState = (state) => ({
    sideMenuOpened: state.layout.sideMenuOpened,
});

const mapDispatch = (dispatch) => ({
    openSideMenu: () => dispatch(layoutAction.openSideMenu()),
    closeSideMenu: () => dispatch(layoutAction.closeSideMenu()),
});

export default connect(mapState, mapDispatch)(TopBar);
