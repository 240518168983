import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    themeItem: {
        cursor: "pointer",
        margin: theme.spacing(1),
        maxWidth: theme.typography.pxToRem(164),
        width: `calc(50% - ${theme.spacing(3)}px)`,
        [theme.breakpoints.up("sm")]: {
            width: `calc(35% - ${theme.spacing(3)}px)`,
        },
        [theme.breakpoints.up("md")]: {
            width: `calc(25% - ${theme.spacing(4)}px)`,
        },
    },
    thumbnail: {
        width: "100%",
        height: "100%",
        border: `1px solid ${theme.palette.magicalGrey[200]}`,
        borderRadius: theme.spacing(1),
        "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    thumbnailActive: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

const Thumbnail = ({ theme, activeItem, onSelect }) => {
    const classes = useStyles();

    return (
        <div
            onClick={() => onSelect(theme)}
            className={classes.themeItem}
            key={theme.id}
        >
            <img
                className={clsx(classes.thumbnail, {
                    [classes.thumbnailActive]: activeItem === theme.id,
                })}
                src={theme.link}
                alt="theme thumbnail"
            />
        </div>
    );
};

export default memo(Thumbnail);
