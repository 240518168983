import Content from "@components/Content";
import { makeStyles, Paper, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageHeaderBack from "@components/PageHeaderBack";
import LspTextField from "@components/LspTextField";
import { useState } from "react";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import LspButton from "@components/LspButton";
import LspHintInfo from "@components/LspHintInfo";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { format } from "date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import LspTypography from "@components/LspTypography";
import vinaWealthAction from "@redux/actions/vina-wealth";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        padding: theme.spacing(2),
    },
    error: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        marginTop: theme.spacing(3),
    },
}));

const VinaWealthPassport = ({ onBack, user, setPassportInfo, onNext }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const [customFromDate, setCustomFromDate] = useState(null);

    const [placeOfIssue, setPlaceOfIssue] = useState("");

    const onClickLinkAccount = () => {
        const payload = {
            placeIdNumber: placeOfIssue,
            dateIdNumber: format(customFromDate, "yyyy-MM-dd"),
        };
        setPassportInfo(payload);
        onNext(VINA_WEALTH_STEPS.INPUT_AMOUNT);
    };
    const onChangePlace = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: e.target.value,
        });
        setPlaceOfIssue(value);
    };

    return (
        <Content size="xs">
            <>
                <PageHeaderBack onBack={onBack}>
                    {t("wealth:vc3_id_title")}
                </PageHeaderBack>
                <Paper>
                    <form
                        className={classes.formWrapper}
                        onSubmit={onClickLinkAccount}
                    >
                        <Box display="flex" p={1} alignItems="center">
                            <Box pl={2} pr={2}>
                                {t("wealth:vc3_id_number")}
                            </Box>
                            <Box>
                                <LspTypography variant="body1" color="black">
                                    {user.nationalId}
                                </LspTypography>
                            </Box>
                        </Box>
                        <LspTextField
                            autoFocus
                            name="placeOfIssue"
                            value={placeOfIssue}
                            label={t("wealth:vc3_id_place")}
                            inputProps={{
                                maxLength: 15,
                            }}
                            onChange={onChangePlace}
                        />
                        <KeyboardDatePicker
                            TextFieldComponent={LspTextField}
                            label={t("wealth:vc3_id_date")}
                            disableToolbar
                            autoOk
                            disableFuture
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            invalidDateMessage=" "
                            maxDateMessage=" "
                            value={customFromDate}
                            onChange={(d) => {
                                if (!d) {
                                    setCustomFromDate(null);
                                    return;
                                }
                                setCustomFromDate(new Date(d));
                            }}
                        />

                        <LspHintInfo
                            color="active"
                            content={t("wealth:vc3_id_tooltip")}
                            icon="hint"
                        />
                        <LspButton
                            fullWidth
                            className={classes.button}
                            onClick={onClickLinkAccount}
                            disabled={!placeOfIssue || !customFromDate}
                        >
                            {t("wealth:vc3_link_account_btn")}
                        </LspButton>
                    </form>
                </Paper>
            </>
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    setPassportInfo: (payload) =>
        dispatch(vinaWealthAction.setPassportInfo(payload)),
});

export default connect(mapState, mapDispatch)(VinaWealthPassport);
