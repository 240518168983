import { Chip, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import { EarthIcon2 } from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    optionWrapper: {
        padding: theme.spacing(0, 3, 3, 3),
        color: theme.palette.grey[700],
        marginTop: theme.spacing(4),
        display: "flex",
    },
    actionIcon: {
        cursor: "pointer",
        color: theme.palette.magicalGrey[300],
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    icon: {
        marginRight: theme.spacing(3),
        width: 24,
        height: 24,
        color: theme.palette.magicalGrey[400],
    },
    content: {
        display: "flex",
    },
}));

const CardInternationalPayment = ({ card }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const openDialog = useCallback(() => {
        const content = {
            title: t("master:manage_card_visa_virtual_INTL_popup_body"),
            banner: `${process.env.REACT_APP_API_URL}/${t(
                "master:manage_card_visa_virtual_INTL_popup_image"
            )}`,
            buttons: [
                {
                    variant: "primary",
                    label: t("lb_btn_ok_got_it"),
                    onClick: () => GlobalDialogController.hide(),
                },
            ],
        };
        GlobalDialogController.show({
            component: () => <ConditionNotificationDialog content={content} />,
        });
    }, [t]);

    const internationalPaymentIsNotReady = useMemo(
        () => !card?.isInternationalPayment,
        [card]
    );

    return (
        <div className={clsx(classes.optionWrapper)}>
            <Grid container alignItems="center">
                <Grid item xs={8} className={classes.content}>
                    <span className={classes.icon}>
                        <EarthIcon2 vb={24} />
                    </span>
                    {t("master:manage_card_visa_virtual_international_payment")}
                    <Chip
                        className={classes.chip}
                        label={
                            internationalPaymentIsNotReady
                                ? t(
                                      "master:manage_card_visa_virtual_INTL_pay_label_not_ready"
                                  )
                                : t(
                                      "master:manage_card_visa_virtual_INTL_pay_label_ready"
                                  )
                        }
                        color={
                            internationalPaymentIsNotReady
                                ? "secondary"
                                : "primary"
                        }
                        size="small"
                    />
                </Grid>
                {internationalPaymentIsNotReady && (
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <span
                            className={classes.actionIcon}
                            onClick={openDialog}
                        >
                            <i className="font-icon icon-right-open-big" />
                        </span>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default memo(CardInternationalPayment);
