import { makeStyles } from "@material-ui/core";

import tommyIcon from "@assets/images/tommy_icon.png";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    splitBillCancel: {
        height: 263,
        backgroundColor: theme.palette.neutral.white,
        margin: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
    },
    splitBillCancelLogo: {
        width: 220,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    splitBillCancelText: {
        marginTop: theme.spacing(-4),
        fontWeight: 400,
    },
}));

const NotFoundBill = () => {
    const { t } = useTranslation();

    const classes = useStyles();
    return (
        <>
            <div className={classes.splitBillCancel}>
                <div className={classes.splitBillCancelLogo}>
                    <img src={tommyIcon} alt="Tommy" />
                </div>
                <div className={classes.splitBillCancelText}>
                    <LspTypography variant="body1" color="black">
                        {t("splitBill:sb_split_bill_cancel_msg")}
                    </LspTypography>
                </div>
            </div>
        </>
    );
};

export default NotFoundBill;
