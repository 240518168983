import Content from "@components/Content";
import { LogoMark } from "@components/LspBranding";
import LspButton from "@components/LspButton";
import PageHeader from "@components/PageHeader";
import {
    Box,
    FormControlLabel,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    accountBox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: theme.palette.magicalGrey[400],
        textTransform: "uppercase",
    },
    memberIcon: {
        fontSize: `${theme.typography.pxToRem(24)} !important`,
        marginRight: theme.spacing(2),
        color: theme.palette.magicalGrey[200],
    },
    bankName: {
        fontWeight: 600,
        color: theme.palette.grey.A400,
    },
    name: {
        fontSize: theme.typography.pxToRem(14),
    },
}));

const ExistingAccount = ({
    back,
    next,
    existingAccount,
    transferOption,
    updateTransferOption,
    transferOptions,
}) => {
    const { t } = useTranslation("translation", "payAnyone");
    const classes = useStyles();

    return (
        <div>
            <PageHeader backFunc={back}>
                <div>{t("payAnyone:timo_user_found_title")} </div>
            </PageHeader>
            <Content size="sm">
                <form onSubmit={next}>
                    <Box
                        component={Paper}
                        p={3}
                        className={classes.existingAccountWrapper}
                    >
                        <Typography style={{ textAlign: "center" }}>
                            {t("payAnyone:timo_user_found_content")}
                        </Typography>
                        <Box
                            component={Paper}
                            p={2}
                            elevation={3}
                            className={classes.accountBox}
                        >
                            <LogoMark className={classes.memberIcon} />
                            <div>
                                <Typography className={classes.bankName}>
                                    {existingAccount?.bankName}
                                </Typography>
                                <Typography className={classes.name}>
                                    {existingAccount?.accountName}
                                </Typography>
                            </div>
                        </Box>
                        <Box my={2}>
                            <RadioGroup
                                aria-label="transferOption"
                                name="transferOption"
                                value={transferOption}
                                onChange={(event) =>
                                    updateTransferOption(event.target.value)
                                }
                            >
                                {transferOptions.map((option) => {
                                    return (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={<Radio color="primary" />}
                                            label={option.label}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </Box>

                        <LspButton type="submit" fullWidth>
                            {t("lb_next")}
                        </LspButton>
                    </Box>
                </form>
            </Content>
        </div>
    );
};

export default memo(ExistingAccount);
