import BankQRCodeSection from "@containers/BankQRCode/BankQRCodeSection";
import { memo } from "react";

import BankInformationBox from "./BankInformationBox";

const BankInformationBoxFetching = () => {
    return (
        <>
            <BankQRCodeSection fetching />
            <BankInformationBox fetching />
            <BankInformationBox fetching />
            <BankInformationBox fetching />
        </>
    );
};
export default memo(BankInformationBoxFetching);
