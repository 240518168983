import { call, put } from "redux-saga/effects";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import lspReceiptService from "@services/lsp-receipt";
import lspReceiptActions from "../actions/lsp-receipt";

export function* getReceiptSaga(action) {
    const { payload } = action;
    const response = yield call(lspReceiptService.getReceipt, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(lspReceiptActions.getReceiptSuccess(data));
            break;
        default:
            yield put(lspReceiptActions.getReceiptError());
            GlobalDialogController.hide();
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* submitNotesSaga(action) {
    const { payload } = action;
    const response = yield call(lspReceiptService.submitNotes, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(lspReceiptActions.submitNotesSuccess(data));
            break;
        default:
            yield put(lspReceiptActions.submitNotesError());

            break;
    }
}

export function* getPaymentDetailSaga(action) {
    const { payload } = action;
    const response = yield call(lspReceiptService.paymentDetail, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(lspReceiptActions.getPaymentDetailSuccess(data));
            break;
        default:
            GlobalDialogController.hide();
            GlobalDialogController.showError({
                errorCode: code,
            });
            yield put(lspReceiptActions.getPaymentDetailError());
            break;
    }
}
