import { useCallback, useEffect, useRef, useState } from "react";
import {
    Box,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import { isFunction } from "lodash";
import LspButton from "@components/LspButton";
import payeeService from "@services/payee";
import LspAvatar from "@components/LspAvatar";
import LspTextField from "@components/LspTextField";
import userAction from "@redux/actions/user";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import {
    INPUT_RULES,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
} from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { useHistory } from "react-router-dom";
import AvatarButtonUploader from "@components/AvatarUploader/AvatarButtonUploader";
import PayeeDestination from "./PayeeDestination";
import PaymentHistoriesOfPayee from "./PaymentHistoriesOfPayee";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    invisible: {
        display: "none",
    },
    clickableArea: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    avatar: {
        borderRadius: "50%",
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
    },
    name: {
        fontWeight: "600",
        wordBreak: "break-word",
    },
    deletePayeeButton: {
        color: theme.palette.orange.main,
    },
    restorePayeeButton: {
        color: theme.palette.primary.main,
    },
}));

const Payee = ({
    avatar,
    fullName,
    nickName,
    payeeId,
    payeeType,
    addtionalInfo,
    hasTimoMem,
    invisible,
    getPayeeList,
    isExpandPayee,
    qaId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [expanded, setExpended] = useState(false);
    const [loading, setLoading] = useState(false);
    const [destinations, setDestinations] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const history = useHistory();

    const [avatarEdit, setAvatarEdit] = useState("");
    const [editedName, setEditedName] = useState("");
    const [deletingDestinations, setDeletingDestinations] = useState([]);

    const unmounted = useRef(false);
    const positionRef = useRef();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getPayeeDetail = useCallback(
        async (callback) => {
            setLoading(true);
            const response = await payeeService.getPayee({
                payeeId,
                payeeType,
            });
            if (response.ok) {
                const { code, data } = response.data;
                const sortedDestinationList = sortBy(
                    data.destinations,
                    "UIIndex"
                );
                switch (code) {
                    case RESPONSE_CODE.SUCCESS:
                        setLoading(false);
                        setDestinations(sortedDestinationList);
                        setExpended(true);
                        break;
                    default:
                        break;
                }
                if (isFunction(callback)) {
                    callback();
                }
            }
        },
        [payeeId, payeeType]
    );

    useEffect(() => {
        if (isExpandPayee) {
            getPayeeDetail(() => {
                setTimeout(() => {
                    positionRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }, 200);
            });
        }
    }, [isExpandPayee, getPayeeDetail, payeeId]);

    const onPayeeClick = useCallback(() => {
        if (loading || isEditMode) {
            return;
        }
        if (!expanded) {
            getPayeeDetail();
        } else {
            setExpended(false);
        }
    }, [isEditMode, expanded, loading, getPayeeDetail]);

    const toggleEditMode = useCallback(() => {
        setAvatarEdit("");

        if (!isEditMode) {
            getPayeeDetail();
        }
        setIsEditMode(!isEditMode);
    }, [isEditMode, getPayeeDetail]);

    useEffect(() => {
        if (isEditMode) {
            setEditedName(nickName);
        }
    }, [nickName, isEditMode]);

    const onEditedNameChange = useCallback((e) => {
        setEditedName(e.target.value);
    }, []);

    const onDeleteDestinationClick = (destination, isRestoration) => {
        const destinationObj = {};

        if (destination.accountId) {
            destinationObj.type = "accountId";
            destinationObj.id = destination.accountId;
        } else if (destination.cardId) {
            destinationObj.type = "cardId";
            destinationObj.id = destination.cardId;
        }
        if (!isRestoration) {
            setDeletingDestinations((curList) => [...curList, destinationObj]);
        } else {
            const compareId = destination.accountId ? "accountId" : "cardId";
            setDeletingDestinations((curList) => {
                return curList.filter(
                    (item) =>
                        !(
                            item.type === destinationObj.type &&
                            item.id === destination[compareId]
                        )
                );
            });
        }
    };

    const isDestinationDeleted = useCallback(
        (inputDestination) => {
            const compareType = inputDestination.accountId
                ? "accountId"
                : "cardId";
            const compareId = inputDestination.accountId
                ? "accountId"
                : "cardId";

            return !!deletingDestinations.find((des) => {
                return (
                    des.type === compareType &&
                    des.id === inputDestination[compareId]
                );
            });
        },
        [deletingDestinations]
    );

    const submitToUpdatePayee = useCallback(
        async (callbackOnSuccess, callbackOnError) => {
            const payload = {
                emails: [],
                avatar: avatarEdit,
                company: "",
                payeeId,
                fullName,
                phoneNos: [],
                nickName: editedName,
                payeeType,
            };
            const response = await payeeService.updatePayee(payload);
            if (unmounted.current) {
                return;
            }
            if (response.ok) {
                switch (response.data.code) {
                    case RESPONSE_CODE.SUCCESS:
                        if (isFunction(callbackOnSuccess)) {
                            callbackOnSuccess(response);
                        }
                        break;
                    case RESPONSE_CODE.DUPLICATED_PAYEE:
                        AlertController.show(
                            t("payee_duplicated_name_body"),
                            AlertType.Error
                        );
                        setLoading(false);
                        break;
                    default:
                        if (isFunction(callbackOnError)) {
                            callbackOnError(response);
                        }
                        break;
                }
            } else if (isFunction(callbackOnError)) {
                callbackOnError(response);
            }
        },
        [editedName, fullName, payeeId, payeeType, t, avatarEdit]
    );

    const submitToDeleteDestination = useCallback(
        async (callback) => {
            const accountId = [];
            const cardId = [];

            deletingDestinations?.forEach((des) => {
                if (des.type === "accountId") {
                    accountId.push(des.id);
                } else if (des.type === "cardId") {
                    cardId.push(des.id);
                }
            });

            const payload = {
                payeeId,
                accountId,
                cardId,
            };
            const response = await payeeService.deleteDestination(payload);
            if (unmounted.current) {
                return;
            }
            if (isFunction(callback)) {
                callback(response);
            }
        },
        [deletingDestinations, payeeId]
    );

    const onSaveClick = useCallback(() => {
        setLoading(true);
        submitToUpdatePayee(
            () => {
                if (deletingDestinations.length > 0) {
                    submitToDeleteDestination(() => {
                        getPayeeList();
                        getPayeeDetail();
                        setIsEditMode(false);
                        setLoading(false);
                    });
                } else {
                    getPayeeList();
                    getPayeeDetail();
                    setIsEditMode(false);
                    setLoading(false);
                }
            },
            () => {
                setIsEditMode(false);
                setLoading(false);
            }
        );
    }, [
        getPayeeList,
        getPayeeDetail,
        submitToUpdatePayee,
        deletingDestinations,
        submitToDeleteDestination,
    ]);

    const deletePayee = useCallback(async () => {
        const isConfirmed = window.confirm(t("payee_confirm_delete_payee"));
        if (!isConfirmed) {
            return;
        }
        setLoading(true);
        const payload = {
            payeeId,
            payeeType,
        };
        const response = await payeeService.deletePayee(payload);
        if (unmounted.current) {
            return;
        }
        if (response.ok) {
            const message = `${nickName} ${t(
                "payee_msg_payee_has_been_deleted"
            )}`;
            AlertController.show(message);
            getPayeeList();
        }
    }, [t, payeeId, payeeType, getPayeeList, nickName]);

    const paymentHistories = () => {
        GlobalDialogController.show({
            size: "lg",
            component: () => (
                <PaymentHistoriesOfPayee
                    payeeId={payeeId}
                    nickName={nickName}
                    additionalInfo={addtionalInfo}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    const addDestination = () => {
        history.push(
            `/spend-account/${SpendAccountNavigationKey.AddDestination}/${payeeId}/${hasTimoMem}`
        );
    };

    const uploadAvatarRequest = async (url) => {
        setAvatarEdit(url);
    };

    const uploadAvatarErrorHandler = (error) => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Error",
                header: t("gs_contribute_error_title"),
                translatedContent: error,
                button: t("lb_close"),
            },
        });
    };

    return (
        <div
            className={clsx(classes.root, { [classes.invisible]: invisible })}
            ref={positionRef}
        >
            <div className={classes.clickableArea}>
                <Box
                    onClick={onPayeeClick}
                    display="flex"
                    alignItems="center"
                    flex={1}
                    py={2}
                    id={`payee-${qaId}`}
                >
                    <Box pr={1} position="relative">
                        <Box>
                            {isEditMode && (
                                <AvatarButtonUploader
                                    avatar={avatarEdit || avatar}
                                    hasTimoMem={hasTimoMem}
                                    className={classes.avatar}
                                    avatarSize={56}
                                    isBase64={!!avatarEdit}
                                    onError={uploadAvatarErrorHandler}
                                    onUpload={uploadAvatarRequest}
                                />
                            )}
                            {!isEditMode && (
                                <LspAvatar
                                    avatar={avatarEdit || avatar}
                                    hasIcon={hasTimoMem}
                                    className={classes.avatar}
                                    size={56}
                                    isBase64={!!avatarEdit}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box flex={1} px={1}>
                        {!loading && isEditMode ? (
                            <LspTextField
                                label={t("payee_name_label")}
                                value={editedName}
                                onChange={onEditedNameChange}
                                disableHelperText
                                autoFocus
                                inputProps={{
                                    maxLength: INPUT_RULES.payeeNameLength,
                                }}
                            />
                        ) : (
                            <>
                                <Typography className={classes.name}>
                                    {nickName || fullName}
                                </Typography>
                                <Typography variant="caption">
                                    {addtionalInfo?.date
                                        ? t("bulk_label_last_paid_on").replace(
                                              "%@",
                                              addtionalInfo.date
                                          )
                                        : t("mc_no_last_payment")}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" py={2}>
                    {loading && <CircularProgress size={16} />}
                    {!loading && !isEditMode && (
                        <IconButton
                            size="small"
                            onClick={toggleEditMode}
                            id={`edit-button-${qaId}`}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    {!loading && isEditMode && (
                        <IconButton
                            size="small"
                            className={classes.deletePayeeButton}
                            onClick={deletePayee}
                            id={`delete-button-${qaId}`}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Box>
            </div>
            <Collapse in={expanded} timeout="auto">
                <Divider />
                <Box paddingY={2}>
                    {destinations?.map((d, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={index} display="flex" alignItems="center">
                            <PayeeDestination
                                destination={d}
                                disabled={loading || isEditMode}
                                isDeleted={isDestinationDeleted(d)}
                            />
                            {!loading && isEditMode && (
                                <Box pl={1}>
                                    {!isDestinationDeleted(d) ? (
                                        <IconButton
                                            size="small"
                                            className={
                                                classes.deletePayeeButton
                                            }
                                            onClick={() =>
                                                onDeleteDestinationClick(d)
                                            }
                                            id={`delete-destination-button-${qaId}-${index}`}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            size="small"
                                            className={
                                                classes.restorePayeeButton
                                            }
                                            onClick={() =>
                                                onDeleteDestinationClick(
                                                    d,
                                                    true
                                                )
                                            }
                                            id={`reverse-destination-button-${qaId}-${index}`}
                                        >
                                            <RestoreIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </Box>
                    ))}
                    <Box
                        justifyContent="space-between"
                        marginTop={1.5}
                        display="flex"
                    >
                        {isEditMode ? (
                            <Box
                                flex={1}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <LspButton
                                    mr={1}
                                    variant="secondary"
                                    onClick={toggleEditMode}
                                >
                                    {t("lb_cancel")}
                                </LspButton>
                                <LspButton
                                    onClick={onSaveClick}
                                    disabled={loading}
                                >
                                    {t("spend_edit_payee_butt_save")}
                                </LspButton>
                            </Box>
                        ) : (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <LspButton
                                            fullWidth={isMobile}
                                            variant="secondary"
                                            wrapText
                                            onClick={addDestination}
                                            style={{ textAlign: "left" }}
                                        >
                                            {t("spend_lb_payment_destination")}
                                        </LspButton>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ textAlign: "right" }}
                                    >
                                        <LspButton
                                            fullWidth={isMobile}
                                            variant="secondary"
                                            wrapText
                                            onClick={paymentHistories}
                                        >
                                            {t("spend_lb_transfer_history")}
                                        </LspButton>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </Box>
            </Collapse>
        </div>
    );
};

const mapDispatch = (dispatch) => ({
    getPayeeList: () => dispatch(userAction.getPayeeListRequest()),
});

export default connect(null, mapDispatch)(Payee);
