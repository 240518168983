import clsx from "clsx";
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import { memo } from "react";
import PropTypes from "prop-types";
import LspTypography from "./LspTypography";
import Content from "./Content";
import { ArrowLeftIcon } from "./LspIcon";

const useStyles = makeStyles((theme) => ({
    pageHeaderRoot: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: 1,
        alignItems: "flex-start",
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
        justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
            justifyContent: "space-between",
        },
    },
    pageHeaderIcon: {
        fontSize: theme.typography.pxToRem(20),
    },
    pageHeaderTitleContainer: {
        textAlign: "center",
        order: 3,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "auto",
            order: 2,
            flex: 3,
        },
    },
    pageHeaderTitle: {
        textTransform: "uppercase",
    },
    pageHeaderActionContainer: {
        flex: 1,
        paddingBottom: theme.spacing(0.75),
        [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(0.75),
            paddingTop: theme.spacing(0.75),
        },
    },
    pageHeaderBack: {
        order: 1,
        textAlign: "left",
    },
    pageHeaderCancel: {
        order: 2,
        textAlign: "right",
        [theme.breakpoints.up("sm")]: {
            order: 3,
        },
    },
    button: {
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    onlyIcon: {
        background: "white",
        borderRadius: "50%",
        border: `1px solid ${theme.palette.neutral.grey3}`,
    },
}));

/**
 * @typedef PageHeaderProps
 * @type {object}
 * @property {string} [className]
 * @property {function} backFunc
 * @property {function} cancelFunc
 * @property {string} type
 */

/**
 * @param {PageHeaderProps} props
 */
const PageHeader = ({ children, className, backFunc, cancelFunc, type }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Content size="lg">
            <div
                className={clsx(classes.pageHeaderRoot, {
                    [className]: !!className,
                })}
            >
                <div
                    className={clsx(
                        classes.pageHeaderActionContainer,
                        classes.pageHeaderBack
                    )}
                >
                    {isFunction(backFunc) && (
                        <>
                            {type === "default" && (
                                <Box display="flex" alignItems="center">
                                    <ButtonBase
                                        onClick={backFunc}
                                        disableRipple
                                        className={classes.button}
                                    >
                                        <ArrowLeftIcon />
                                        <LspTypography
                                            variant="button2"
                                            color="grey"
                                        >
                                            {t("lb_back")}
                                        </LspTypography>
                                    </ButtonBase>
                                </Box>
                            )}

                            {type === "onlyIcon" && (
                                <Box display="flex" alignItems="center">
                                    <ButtonBase
                                        onClick={backFunc}
                                        disableRipple
                                        className={classes.onlyIcon}
                                    >
                                        <ArrowLeftIcon />
                                    </ButtonBase>
                                </Box>
                            )}
                        </>
                    )}
                </div>
                <div className={classes.pageHeaderTitleContainer}>
                    <LspTypography
                        component="div"
                        className={classes.pageHeaderTitle}
                        variant="title2"
                        color="primary"
                    >
                        {children}
                    </LspTypography>
                </div>
                <div
                    className={clsx(
                        classes.pageHeaderActionContainer,
                        classes.pageHeaderCancel
                    )}
                >
                    {isFunction(cancelFunc) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <ButtonBase
                                onClick={cancelFunc}
                                disableRipple
                                className={classes.button}
                            >
                                <LspTypography variant="button2" color="grey">
                                    {t("lb_cancel")}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}
                </div>
            </div>
        </Content>
    );
};

PageHeader.propTypes = {
    className: PropTypes.string,
    backFunc: PropTypes.func,
    cancelFunc: PropTypes.func,
    type: PropTypes.oneOf(["default", "onlyIcon"]),
};

PageHeader.defaultProps = {
    className: "",
    backFunc: undefined,
    cancelFunc: undefined,
    type: "default",
};

export default memo(PageHeader);
