/* ------------- Types Definition ------------- */
export const OverdraftActionType = Object.freeze({
    Reset: "OverdraftActionType/Reset",
    GetOverdraftDetail: "OverdraftActionType/GetOverdraftDetail",
    GetOverdraftDetailSuccess: "OverdraftActionType/GetOverdraftDetailSuccess",
    GetOverdraftDetailError: "OverdraftActionType/GetOverdraftDetailError",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: OverdraftActionType.Reset,
    };
};

const getOverdraftDetail = () => {
    return {
        type: OverdraftActionType.GetOverdraftDetail,
    };
};
const getOverdraftDetailSuccess = (payload) => {
    return {
        type: OverdraftActionType.GetOverdraftDetailSuccess,
        payload,
    };
};
const getOverdraftDetailError = () => {
    return {
        type: OverdraftActionType.GetOverdraftDetailError,
    };
};

const overdraftAction = {
    reset,
    getOverdraftDetail,
    getOverdraftDetailSuccess,
    getOverdraftDetailError,
};

export default overdraftAction;
