import {
    Box,
    CircularProgress,
    Grid,
    Icon,
    makeStyles,
} from "@material-ui/core";
import Interweave from "interweave";
import WarningIcon from "@assets/images/Warning1.png";
import SuccessIcon from "@assets/images/Success1.png";
import ErrorIcon from "@assets/images/Error1.png";
import AnnouncementIcon from "@assets/images/announcement.png";

import LspTypography from "@components/LspTypography";
import { memo, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import {
    LspNotifyDialogTitle,
    LspNotifyDialogContent,
    LspNotifyDialogActions,
    LspNotifyDialog,
} from "../LspNotifyDialog";
import DoubleButtons from "../DoubleButtons";
import LspButton from "../LspButton";

const useStyles = makeStyles((theme) => ({
    notifyDialogWrapper: {
        maxWidth: theme.notifyDialog,
        margin: "auto",
    },
    hightLight: {
        marginTop: theme.spacing(1),
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    content: {
        whiteSpace: "pre-wrap",
    },
    bold: {
        fontWeight: 600,
    },
    icon: {
        fontSize: theme.typography.pxToRem(60),
        marginBottom: theme.spacing(1),
    },
    body: {
        "& a": {
            color: theme.palette.success.main,
            fontWeight: 600,
        },
    },
    iconCustom: {
        maxWidth: "160px !important",
    },
}));

/**
 *
 * @typedef DoubleButton
 * @type {object}
 * @property {string} label
 * @property {function} onClick
 */

/**
 * @typedef DialogInfo
 * @type {object}
 * @property {string} iconImage - use for Error.png, Warning.png, Success.png icon.
 * @property {string} icon - icon name.
 * @property {string} [header] - header translation key.
 * @property {string} [content] - content translation key.
 * @property {string} [markdownContent] - content mark down.
 * @property {string} [highlight]
 * @property {string} [contact]
 * @property {string} button
 * @property {Array.<DoubleButton>} [doubleButton]
 */

/**
 * @param {{dialogContent: DialogInfo, loading:bool, closeHandler: function}} dialogOptions
 */

const NotifyDialog = ({ dialogContent, loading, closeHandler }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const transformContact = useCallback((node, children, config) => {
        if (config.tagName === "a") {
            return (
                <LspTypography
                    component="a"
                    variant="hyperlink1"
                    color="success"
                    href={node.getAttribute("href")}
                >
                    {children}
                </LspTypography>
            );
        }
    }, []);

    return (
        <LspNotifyDialog
            size="xs"
            className={dialogContent?.wrapperClasses || ""}
        >
            <LspNotifyDialogTitle>
                {dialogContent?.iconCustom && (
                    <img
                        src={dialogContent?.iconCustom}
                        alt="icon"
                        className={classes.iconCustom}
                    />
                )}
                {dialogContent?.icon && (
                    <Icon
                        className={`font-icon icon-${dialogContent?.icon} ${classes.icon}`}
                    />
                )}
                {dialogContent?.iconImage === "Error" && (
                    <img src={ErrorIcon} alt="icon" />
                )}
                {dialogContent?.iconImage === "Warning" && (
                    <img src={WarningIcon} alt="icon" />
                )}
                {dialogContent?.iconImage === "Success" && (
                    <img src={SuccessIcon} alt="icon" />
                )}
                {dialogContent?.iconImage === "Announcement" && (
                    <img src={AnnouncementIcon} alt="icon" />
                )}

                <LspTypography variant="heading1">
                    {t(dialogContent?.header)}
                </LspTypography>
            </LspNotifyDialogTitle>
            <LspNotifyDialogContent className={classes.body}>
                <LspTypography component="div" variant="body2" color="grey">
                    {dialogContent?.boldContent && (
                        <Box mb={1}>
                            <LspTypography
                                component="div"
                                variant="subheading3"
                            >
                                <Interweave
                                    content={dialogContent.boldContent}
                                />
                            </LspTypography>{" "}
                        </Box>
                    )}
                    {dialogContent?.translatedContent && (
                        <Box mb={1}>
                            <Interweave
                                containerTagName="div"
                                content={dialogContent.translatedContent}
                            />
                        </Box>
                    )}
                    {dialogContent?.content && (
                        <Box mb={1}>
                            <Interweave
                                containerTagName="div"
                                content={dialogContent.content}
                            />
                        </Box>
                    )}

                    {dialogContent?.markdownContent && (
                        <Box mb={1}>
                            <ReactMarkdown>
                                {dialogContent.markdownContent}
                            </ReactMarkdown>
                        </Box>
                    )}

                    {dialogContent?.linkContent && (
                        <Box mb={1}>
                            <LspTypography
                                variant="subheading3"
                                color="success"
                                component="a"
                                href={dialogContent.linkContent.link}
                                target="_blank"
                            >
                                <Interweave
                                    content={dialogContent.linkContent.label}
                                />
                            </LspTypography>
                        </Box>
                    )}
                    {dialogContent?.lspTranslation && (
                        <Box mb={1}>{dialogContent.lspTranslation}</Box>
                    )}

                    {dialogContent?.highlight && (
                        <Box mb={1}>
                            <LspTypography
                                variant="subheading3"
                                color="primary"
                            >
                                <Interweave content={dialogContent.highlight} />
                            </LspTypography>
                        </Box>
                    )}
                    {dialogContent?.contact && (
                        <Interweave
                            transform={transformContact}
                            content={dialogContent.contact}
                        />
                    )}
                </LspTypography>
            </LspNotifyDialogContent>
            <LspNotifyDialogActions>
                {!dialogContent?.doubleButton && (
                    <LspButton fullWidth onClick={closeHandler}>
                        {t(dialogContent?.button)}
                    </LspButton>
                )}
                {dialogContent?.doubleButton && (
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <LspButton
                                    {...dialogContent?.doubleButton[1]}
                                    disabled={loading}
                                    buttonProps={{
                                        startIcon: loading ? (
                                            <CircularProgress size={18} />
                                        ) : (
                                            ""
                                        ),
                                    }}
                                    fullWidth
                                    variant="secondary"
                                >
                                    {dialogContent?.doubleButton[1]?.label}
                                </LspButton>
                            </Grid>
                            <Grid item xs={6}>
                                <LspButton
                                    disabled={loading}
                                    {...dialogContent?.doubleButton[0]}
                                    fullWidth
                                >
                                    {dialogContent?.doubleButton[0]?.label}
                                </LspButton>
                            </Grid>
                        </Grid>
                    </>
                )}
            </LspNotifyDialogActions>
        </LspNotifyDialog>
    );
};
export default memo(NotifyDialog);
