import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import LspAddress from "@components/LspAddress";
import { Skeleton } from "@material-ui/lab";

const defaultErrors = {
    province: "",
    district: "",
    ward: "",
    address: "",
};

const ProfileAddressForm = ({
    successMessage,
    isUpdating,
    onSubmitForm,
    selected,
    errorMessage,
    isEdit,
    toggleEdit,
}) => {
    const classes = useProfileSharedClasses();

    const { t } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const unmounted = useRef(false);
    const [errors, setErrors] = useState(defaultErrors);

    const fullAddress = useMemo(() => {
        let addressInfo = selected?.addressName;
        if (selected?.wardName) {
            addressInfo = `${addressInfo}, ${t("user_profile_ward_prefix")} ${
                selected?.wardName
            }`;
        }

        if (selected?.distName) {
            addressInfo = `${addressInfo}, ${t(
                "user_profile_district_prefix"
            )} ${selected?.distName}`;
        }

        if (selected?.cityName) {
            addressInfo = `${addressInfo}, ${selected?.cityName}`;
        }

        return addressInfo;
    }, [selected, t]);

    const [addressName, setAddressName] = useState(selected?.addressName);
    const [selectedProvince, setSelectedProvince] = useState({
        cfgKey: selected?.cityId,
        cfgValue: selected?.cityName,
    });
    const [selectedDistrict, setSelectedDistrict] = useState({
        cfgKey: selected?.distId,
        cfgValue: selected?.distName,
    });
    const [selectedWard, setSelectedWard] = useState({
        cfgKey: selected?.wardId,
        cfgValue: selected?.wardName,
    });

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const toggleEditHandler = (e) => {
        e.preventDefault();
        toggleEdit(PROFILE_FORM_TYPES.ADDRESS);
    };

    const onChangeWard = (ward) => {
        setErrors((prev) => ({
            ...prev,
            ward: "",
        }));
        setSelectedWard(ward);
    };

    const onChangeDistrict = (district) => {
        setErrors((prev) => ({
            ...prev,
            district: "",
        }));
        setSelectedDistrict(district);
    };

    const onChangeProvince = (province) => {
        setErrors((prev) => ({
            ...prev,
            province: "",
        }));
        setSelectedProvince(province);
    };

    const onChangeAddress = (e) => {
        setErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressName(address);
        if (!address) {
            setErrors((prev) => ({
                ...prev,
                address: t("msg_we_need_this"),
            }));
        }
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (
            !addressName ||
            selectedProvince.cfgKey === "" ||
            selectedDistrict.cfgKey === "" ||
            selectedWard.cfgKey === ""
        ) {
            setErrors({
                address: !addressName ? t("msg_we_need_this") : "",
                province:
                    selectedProvince.cfgKey === "" ? t("msg_we_need_this") : "",
                district:
                    selectedDistrict.cfgKey === "" ? t("msg_we_need_this") : "",
                ward: selectedWard.cfgKey === "" ? t("msg_we_need_this") : "",
            });
            return;
        }

        if (
            addressName !== selected?.addressName ||
            selectedWard?.cfgKey !== selected?.wardId ||
            selectedDistrict?.cfgKey !== selected?.distId ||
            selectedProvince?.cfgKey !== selected?.cityId
        ) {
            onSubmitForm({
                addressName,
                wardId: selectedWard?.cfgKey,
                wardName: selectedWard?.cfgValue,
                distId: selectedDistrict?.cfgKey,
                distName: selectedDistrict?.cfgValue,
                cityId: selectedProvince?.cfgKey,
                cityName: selectedProvince?.cfgValue,
            });
        } else {
            toggleEdit(PROFILE_FORM_TYPES.ADDRESS);
        }
    };

    return (
        <form className={classes.formWrapper} onSubmit={onSubmitHandler}>
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-currentaddress ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("user_profile_address_txt")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {!isUpdating && fullAddress}
                        {isUpdating && (
                            <Skeleton
                                width={130}
                                style={{ display: "inline-block" }}
                            />
                        )}
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    <ProfileEditComponent
                        isEdit={isEdit}
                        onEdit={toggleEditHandler}
                        onCancel={toggleEditHandler}
                        updating={isUpdating}
                    />
                </div>
            </div>

            {isEdit && (
                <div style={{ marginTop: 16 }}>
                    <LspAddress
                        selectedProvince={selectedProvince}
                        onChangeProvince={onChangeProvince}
                        selectedDistrict={selectedDistrict}
                        onChangeDistrict={onChangeDistrict}
                        selectedWard={selectedWard}
                        onChangeWard={onChangeWard}
                        errors={errors}
                        submitting={isUpdating}
                    />
                    <LspTextField
                        autoFocus
                        name="addressName"
                        error={!!errors?.address}
                        label={t("bill_lb_address")}
                        helperText={errors?.address || " "}
                        inputProps={{
                            maxLength: 35,
                        }}
                        onChange={onChangeAddress}
                        disabled={isUpdating}
                        value={addressName}
                    />
                    {errorMessage && (
                        <LspTypography color="error" variant="body3">
                            {errorMessage}
                        </LspTypography>
                    )}
                </div>
            )}
        </form>
    );
};

export default memo(ProfileAddressForm);
