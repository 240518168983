/* ------------- Types and Action Definition ------------- */
export const OTPDialogActionType = Object.freeze({
    Open: "OTPDialogActionType/Open",
    Close: "OTPDialogActionType/Close",
    SetLoading: "OTPDialogActionType/SetLoading",
    SetError: "OTPDialogActionType/SetError",
});
/* ------------- Actions Definition ------------- */
const open = (payload) => {
    return {
        type: OTPDialogActionType.Open,
        payload,
    };
};
const close = () => {
    return {
        type: OTPDialogActionType.Close,
    };
};

const setLoading = (payload) => {
    return {
        type: OTPDialogActionType.SetLoading,
        payload,
    };
};

const setError = (payload) => {
    return {
        type: OTPDialogActionType.SetError,
        payload,
    };
};

const otpDialogAction = {
    open,
    close,
    setLoading,
    setError,
};

export default otpDialogAction;
