import PageHeader from "@components/PageHeader";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import useStepNavigation from "@helpers/useStepNavigation";
import { connect } from "react-redux";
import moveMoneyAction from "@redux/actions/move-money";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import moveMoneyService from "@services/move-money";
import { RESPONSE_CODE } from "@config/constants";
import { WEALTH_PAYMENT_METHODS } from "@containers/Wealth/wealthConstants";
import PaymentAmount from "./PaymentAmount";
import PaymentConfirmation from "./PaymentConfirmation";
import PaymentSuccess from "./PaymentSuccess";
import WarningExceedOutStandingBalance from "./WarningExceedOutStandingBalance";

const Payment = ({ resetTransfer }) => {
    const { t } = useTranslation();
    const { creditCard } = useAccounts();
    const { formatNumber } = useNumber();
    const unmounted = useRef(false);
    const amountSuggestionLoaded = useRef(false);

    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: 1,
    });

    const amountDefault = useMemo(() => {
        return creditCard.availableAmount
            ? Math.abs(creditCard.availableAmount)
            : "";
    }, [creditCard]);

    const [amountSuggestionList, setAmountSuggestionList] = useState(null);
    const [selectedAmountOption, setSelectedAmountOption] = useState(null);
    const [suggest, setSuggest] = useState(null);
    const [amountSuggestionLoading, setAmountSuggestionLoading] = useState(
        false
    );

    const [amount, setAmount] = useState(null);

    const [customAmount, setCustomAmount] = useState(
        {
            formattedValue: "",
            value: "",
        },
        []
    );

    const defaultDescription = useMemo(() => t("mc_default_description"), [t]);

    const [description, setDescription] = useState(t("mc_default_description"));

    useEffect(() => {
        return () => {
            resetTransfer();
            unmounted.current = true;
            amountSuggestionLoaded.current = false;
        };
    }, [resetTransfer]);

    const renderTitle = useCallback(() => {
        switch (step.current) {
            case 1:
            case 2:
                return t("timo_mastercard_payment");
            case 3:
                return t("transaction_label_receipt");
            default:
                return null;
        }
    }, [t, step]);

    const onYesDialogWarning = useCallback(() => {
        GlobalDialogController.hide();
        stepForward(2);
    }, [stepForward]);

    const onNextAmountScreen = useCallback(() => {
        const outStandingAmount = parseInt(creditCard.availableAmount) || 0;
        if (-outStandingAmount >= amount?.value) {
            stepForward(2);
            return;
        }

        // show warning
        GlobalDialogController.show({
            component: () => (
                <WarningExceedOutStandingBalance
                    outStandingBalance={outStandingAmount}
                    amount={amount?.value}
                    onCancel={() => GlobalDialogController.hide()}
                    onYes={onYesDialogWarning}
                />
            ),
        });
    }, [amount, onYesDialogWarning, stepForward, creditCard.availableAmount]);

    const onAmountOptionChange = useCallback(
        (item) => {
            setSelectedAmountOption(item);
            if (item?.id === WEALTH_PAYMENT_METHODS.MANUAL) {
                setAmount(customAmount);
                return;
            }

            setAmount({
                formattedValue: item?.amount ? formatNumber(item?.amount) : 0,
                value: item?.amount,
            });
        },
        [customAmount, formatNumber]
    );

    const onChangeCustomAmount = (currentAmount) => {
        setCustomAmount(currentAmount);
        setAmount(currentAmount);
    };

    const renderStepContent = useCallback(() => {
        switch (step.current) {
            case 1:
                return (
                    <PaymentAmount
                        amount={customAmount}
                        setAmount={onChangeCustomAmount}
                        description={description}
                        setDescription={setDescription}
                        next={onNextAmountScreen}
                        defaultDesc={defaultDescription}
                        amountSuggestionList={amountSuggestionList}
                        onAmountOptionChange={onAmountOptionChange}
                        selectedAmountOption={selectedAmountOption}
                        amountSuggestionLoading={amountSuggestionLoading}
                        suggest={suggest}
                    />
                );
            case 2:
                return (
                    <PaymentConfirmation
                        amount={amount}
                        description={description}
                        next={() => stepForward(3)}
                    />
                );
            case 3:
                return <PaymentSuccess />;
            default:
                return null;
        }
    }, [
        step,
        stepForward,
        amount,
        customAmount,
        description,
        defaultDescription,
        onNextAmountScreen,
        amountSuggestionList,
        selectedAmountOption,
        amountSuggestionLoading,
        onAmountOptionChange,
    ]);

    const getAmountSuggestionList = useCallback(async () => {
        setAmountSuggestionLoading(true);
        const response = await moveMoneyService.getAmountSuggestionListCreditCard();

        if (unmounted.current) return;

        amountSuggestionLoaded.current = true;

        const { code, data } = response.data || {};

        setAmountSuggestionLoading(false);

        if (code !== RESPONSE_CODE.SUCCESS) {
            GlobalDialogController.showError({ errorCode: code });
            return;
        }
        setSuggest(data);
        const minimumAmount = data?.minAmountPayment || 0;
        const fullAmount = data?.fullAmountPayment || 0;

        const optionList = [
            {
                id: WEALTH_PAYMENT_METHODS.MINIMUM,
                title: t("wealth:make_payment_method_minimum_title"),
                subtitle: t("wealth:make_payment_method_minimum_desc"),
                amount: minimumAmount,
            },
            {
                id: WEALTH_PAYMENT_METHODS.FULL,
                title: t("wealth:make_payment_method_full_title"),
                subtitle: t("wealth:make_payment_method_full_desc"),
                amount: fullAmount,
            },
            {
                id: WEALTH_PAYMENT_METHODS.OUTSTANDING,
                title: t(
                    "wealth:make_payment_method_total_outstanding_balance"
                ),
                subtitle: "",
                amount: Math.abs(creditCard?.availableAmount),
            },
            {
                id: WEALTH_PAYMENT_METHODS.MANUAL,
                title: t("wealth:make_payment_method_manual_title"),
                amount: "",
            },
        ];

        setAmountSuggestionList(optionList);

        const selectedItem =
            minimumAmount > 0
                ? optionList[0]
                : optionList[optionList?.length - 1];

        setSelectedAmountOption(selectedItem);
        setAmount({
            formattedValue: selectedItem?.amount
                ? formatNumber(selectedItem?.amount)
                : 0,
            value: selectedItem?.amount,
        });
    }, [t, formatNumber, creditCard?.availableAmount]);

    useEffect(() => {
        if (amountSuggestionLoaded.current) return;

        getAmountSuggestionList();
    }, [getAmountSuggestionList]);

    return (
        <>
            <PageHeader backFunc={step.current < 3 ? stepBack : null}>
                {renderTitle()}
            </PageHeader>
            {renderStepContent()}
        </>
    );
};

const mapDispatch = (dispatch) => ({
    resetTransfer: () => dispatch(moveMoneyAction.reset()),
});

export default connect(null, mapDispatch)(Payment);
