import { memo } from "react";
import { useTranslation } from "react-i18next";

import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import tommyScared from "@assets/images/tommy_scared_status.png";

const RequestCardErrorDialog = () => {
    const { t } = useTranslation();

    const contentError = {
        banner: tommyScared,
        title: t("debitCard:phys_visa_fail_payment_popup_title"),
        description: t("debitCard:phys_visa_fail_payment_popup_desc"),
        buttons: [
            {
                variant: "primary",
                label: t("debitCard:phys_visa_fail_payment_popup_btn"),
                onClick: () => GlobalDialogController.hide(),
            },
        ],
    };

    return <ConditionNotificationDialog content={contentError} centerImg />;
};

export default memo(RequestCardErrorDialog);
