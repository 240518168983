import { memo } from "react";
import Content from "@components/Content";
import { Box, makeStyles } from "@material-ui/core";
import banner from "@assets/images/libertypopup@2x.png";

import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import Interweave from "interweave";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.neutral.white,
        borderRadius: theme.shape.radiusLevels[1],
        overflow: "hidden",
    },
    banner: {
        width: "100%",
        height: 150,
        backgroundSize: "cover !important",
    },
    box: {
        margin: theme.spacing(4, 0, 0, 0),
        border: `1px solid ${theme.palette.neutral.grey3}`,
        padding: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        padding: theme.spacing(0, 4, 4),
    },
}));

const WealthInsuranceNotification = ({ content }) => {
    const classes = useStyles();

    return (
        <Content size="sm">
            <div className={classes.wrapper}>
                <div
                    className={classes.banner}
                    style={{
                        background: `url(${banner}) no-repeat top center`,
                    }}
                />
                <Box
                    p={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LspTypography
                        variant="heading1"
                        color={content?.isSuccess ? "success" : "error"}
                    >
                        {content?.header}
                    </LspTypography>
                    <LspTypography>
                        <Interweave content={content?.content} />
                    </LspTypography>
                    <LspTypography
                        className={classes.box}
                        color="primary"
                        variant="body2"
                    >
                        <Interweave content={content?.otherContent} />
                    </LspTypography>
                </Box>
                <Box className={classes.button}>
                    <LspButton fullWidth onClick={content?.button?.onClick}>
                        {content?.button?.label}
                    </LspButton>
                </Box>
            </div>
        </Content>
    );
};

export default memo(WealthInsuranceNotification);
