/* eslint-disable react/no-array-index-key */
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DATA_DROPDOWN_TYPES,
    REGEX_PATTERN,
    RESPONSE_CODE,
} from "@config/constants";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import { Box, Grid, makeStyles, Radio } from "@material-ui/core";
import requestDocumentService from "@services/request-document";
import LspButton from "@components/LspButton";
import Loading from "@components/Loading";
import RequestDocumentActions from "@redux/actions/request-document";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import Interweave from "interweave";
import LspTextField from "@components/LspTextField";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import LspDataDropdown from "@components/LspDataDropdown";
import { useHistory } from "react-router-dom";
import {
    RD_RECEIVED_PLACE,
    REQUEST_DOC_STEPS,
    REQUEST_DOC_TYPE,
} from "./constant";

const useStyles = makeStyles((theme) => ({
    nextBtn: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > button": {
            width: "50%",
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    },
    listAddress: {
        paddingLeft: theme.spacing(2),
    },
    chargeTxt: {
        "& > span > span": {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(20),
            fontWeight: "600",
        },
    },
    item: {
        fontStyle: "italic",
    },
    content: {
        fontStyle: "italic",
        textAlign: "center",
    },
    address: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            paddingRight: theme.spacing(2),
        },
        "& > div": {
            "&:last-child": {
                width: "70%",
                [theme.breakpoints.down("sm")]: {
                    width: "100%",
                },
            },
            "&:first-child": {
                width: "25% !important",
                [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                },
            },
        },
    },
}));

const ConfirmDocument = ({ onBack, documentInfo, setDocumentInfo, onNext }) => {
    const { t, i18n } = useTranslation();
    const [docList, setDocList] = useState([]);
    const [documentSelected, setDocumentSelected] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [errors, setErrors] = useState(true);
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const [addressName, setAddressName] = useState("");
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const [showChild, setShowChild] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState({
        cfgKey: "",
        cfgValue: "",
    });
    const history = useHistory();

    const onChangeAddress = (e) => {
        setErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressName(address);
        if (!address) {
            setErrors((prev) => ({
                ...prev,
                address: t("msg_we_need_this"),
            }));
        }
    };

    const onChangeProvince = (province) => {
        setErrors((prev) => ({
            ...prev,
            province: "",
        }));
        setSelectedProvince(province);
    };

    const initData = useCallback(() => {
        if (documentInfo.selected_doc) {
            setDocumentSelected(documentInfo.selected_doc);
        }
    }, [documentInfo.selected_doc]);

    const mapDocumentList = useCallback(
        (documentList) => {
            const docListTmp = []; // final list
            if (documentList && documentList.length > 0) {
                const hangoutList = [];
                const othersList = [];

                let currentDoc = "";
                for (let i = 0; i < documentList.length; i++) {
                    currentDoc = documentList[i];

                    if (!currentDoc?.disabled) {
                        if (
                            currentDoc.receiveType === RD_RECEIVED_PLACE.HANGOUT
                        ) {
                            hangoutList.push(currentDoc);
                        } else {
                            othersList.push(currentDoc);
                        }
                    }
                }

                docListTmp.push({
                    content: t("request_document_lb_pick_up_hangout"),
                    list: hangoutList,
                    receiveType: RD_RECEIVED_PLACE.HANGOUT,
                });

                for (let i = 0; i < othersList.length; i++) {
                    docListTmp.push(othersList[i]);
                }

                setDocList(docListTmp);
                initData();
            }
        },
        [initData, t]
    );

    const getDocumentOption = useCallback(async () => {
        const { data } = await requestDocumentService.getDocumentOptionList(
            documentInfo.requestId,
            i18n.language
        );
        setFetching(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                mapDocumentList(data.data.available);
                break;

            default:
                break;
        }
    }, [i18n, documentInfo, mapDocumentList]);

    const onChangeRadio = (item) => {
        const type = item.receiveType || "";
        if (type === RD_RECEIVED_PLACE.OTHER) {
            setShowChild(true);
        } else {
            setShowChild(false);
        }
        setDocumentSelected(item);
    };

    const submitRequest = (address) => {
        const dataToPost = {
            requestId: documentInfo.requestId,
            receiveRequestId: documentSelected.receiveRequestId,
            receiveDetail: address || documentSelected.receiveDetail,
            formId: documentInfo.view,
            lang: i18n.language,
            notification: "sms",
            provinceId: +selectedProvince.cfgKey || documentSelected.provinceId,
        };
        setDocumentInfo({ selected_doc: documentSelected });
        setDocumentInfo({ data_to_post: dataToPost });
        if (documentSelected.receiveType === RD_RECEIVED_PLACE.HANGOUT) {
            setDocumentInfo({
                confirm_msg: t(
                    "rq_document_confirm_address_and_date_at_hangout"
                ),
            });
        } else {
            setDocumentInfo({
                confirm_msg: t("rq_document_confirm_address_and_date"),
            });
        }
        onNext(REQUEST_DOC_STEPS.CONFIRM_AND_PAYMENT_DOCUMENT);
    };

    const validateForm = () => {
        if (showChild) {
            if (!addressName || selectedProvince.cfgKey === "") {
                setErrors({
                    address: !addressName ? t("msg_we_need_this") : "",
                    province:
                        selectedProvince.cfgKey === ""
                            ? t("msg_we_need_this")
                            : "",
                });
            } else {
                const address = `${addressName}, ${selectedProvince.cfgValue}`;
                submitRequest(address);
            }
            return;
        }
        submitRequest();
    };

    const goBeforePage = () => {
        if (
            documentInfo.detailConfirmationType !==
            REQUEST_DOC_TYPE.GET_ACCOUNT_LIST
        ) {
            history.goBack();
            return;
        }
        onBack();
        setDocumentInfo({ selected_doc: "" });
    };

    useEffect(() => {
        getDocumentOption();
    }, [getDocumentOption]);
    return (
        <>
            {fetching && <Loading />}
            {!fetching && (
                <>
                    <PageHeaderBack onBack={goBeforePage}>
                        {documentInfo.desc}
                    </PageHeaderBack>

                    <LspTypography
                        variant="body2"
                        color="grey"
                        className={classes.content}
                    >
                        {t("rq_document_confirm_document_question")}
                    </LspTypography>
                    {docList.map((item, k) => (
                        <Box mt={3} key={k} className={classes.listAddress}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <LspTypography
                                        variant="heading3"
                                        color="black"
                                    >
                                        {item.content}
                                    </LspTypography>
                                </Grid>
                                {item.receiveType !==
                                    RD_RECEIVED_PLACE.HANGOUT && (
                                    <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "right" }}
                                    >
                                        <Radio
                                            value={item.receiveRequestId}
                                            color="primary"
                                            checked={
                                                documentSelected?.receiveRequestId ===
                                                item.receiveRequestId
                                            }
                                            onChange={() => onChangeRadio(item)}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {(item.receiveType !== RD_RECEIVED_PLACE.OTHER ||
                                !showChild) && (
                                <LspTypography
                                    variant="body2"
                                    color="black"
                                    className={classes.item}
                                >
                                    {item.receiveDetail}
                                </LspTypography>
                            )}

                            {item.receiveType === RD_RECEIVED_PLACE.OTHER &&
                                showChild && (
                                    <div className={classes.address}>
                                        <LspDataDropdown
                                            selectedData={selectedProvince}
                                            onChangeData={onChangeProvince}
                                            typeData={
                                                DATA_DROPDOWN_TYPES.PROVINCE
                                            }
                                            labelData={t("province_city_lb")}
                                            error={errors.province}
                                        />

                                        <LspTextField
                                            autoFocus
                                            name="addressName"
                                            error={!!errors?.address}
                                            label={t("bill_lb_address")}
                                            helperText={errors?.address || " "}
                                            inputProps={{
                                                maxLength: 35,
                                            }}
                                            onChange={onChangeAddress}
                                            value={addressName}
                                        />
                                    </div>
                                )}

                            {item.list?.length > 0 &&
                                item.list?.map((elm, key) => {
                                    return (
                                        <Grid container spacing={2} key={key}>
                                            <Grid item xs={10}>
                                                <LspTypography
                                                    variant="body2"
                                                    color="black"
                                                    className={classes.item}
                                                >
                                                    {elm.receiveDetail}
                                                </LspTypography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={2}
                                                style={{ textAlign: "right" }}
                                            >
                                                <Radio
                                                    value={elm.receiveRequestId}
                                                    color="primary"
                                                    checked={
                                                        documentSelected?.receiveRequestId ===
                                                        elm.receiveRequestId
                                                    }
                                                    onChange={() =>
                                                        onChangeRadio(elm)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Box>
                    ))}

                    {documentSelected && (
                        <Box textAlign="center" mt={2}>
                            <LspTypography
                                variant="body1"
                                color="black"
                                className={classes.chargeTxt}
                            >
                                <Interweave
                                    content={t(
                                        "rq_document_service_charge"
                                    ).replace(
                                        "%@",
                                        formatNumber(documentSelected?.amount)
                                    )}
                                />
                            </LspTypography>
                        </Box>
                    )}
                    {docList.length === 0 ? (
                        <LspTypography
                            variant="body2"
                            color="error"
                            className={classes.content}
                        >
                            Sorry, No options to choose.
                        </LspTypography>
                    ) : (
                        <LspButton
                            mt={2}
                            onClick={validateForm}
                            type="submit"
                            disabled={!documentSelected}
                            className={classes.nextBtn}
                        >
                            {t("lb_next")}
                        </LspButton>
                    )}
                </>
            )}
        </>
    );
};

const mapState = (state) => ({
    documentInfo: state.requestDocument,
});

const mapDispatch = (dispatch) => ({
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
});

export default memo(connect(mapState, mapDispatch)(ConfirmDocument));
