/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";

import sha512 from "crypto-js/sha512";
import { useHistory, useParams } from "react-router-dom";
import { PayAnyoneStatus, PRODUCT_TYPES, RESPONSE_CODE } from "@config/constants";
import moveMoneyService from "@services/move-money";
import useStepNavigation from "@helpers/useStepNavigation";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ClaimMoneyHeader from "@containers/SpendAccount/MoveMoney/ClaimMoneyPayAnyone/ClaimMoneyHeader";
import ClaimMoneySecurityOptions from "./ClaimMoneySecurityOptions";
import ClaimMoneyResult from "./ClaimMoneyResult";
import { CLAIM_MONEY_SCREENS } from "./constant";
import ClaimMoneyJoinTimo from "./ClaimMoneyJoinTimo";
import ClaimMoneyPaymentDetail from "./ClaimMoneyPaymentDetail";
import ClaimMoneyBankDetail from "./ClaimMoneyBankDetail";
import ClaimMoneyLixiDetail from "./ClaimMoneyLixiDetail";
import ClaimMoneyLoading from "./ClaimMoneyLoading";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100vw",
        backgroundColor: theme.palette.background.paper,
        maxWidth: 400,
        minWidth: 320,
        margin: "auto"
    },
    content: {
        color: theme.palette.grey.A400,
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        position: "relative",
    },
    body: {
        textAlign: "center",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        minHeight: "100vh",
        height: "100%"
    },
    title: {
        fontWeight: 300,
        paddingTop: theme.spacing(2),
        whiteSpace: "pre-line",
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.white.contrastText,
    },
    subtitle: {
        fontWeight: 400,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {},
    logoWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4)
    },
    logo: {
        width: "100%",
        maxWidth: theme.typography.pxToRem(100),
        marginRight: theme.spacing(1),
    },
    language: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        color: theme.palette.white.contrastText,
        "& .active": {
            fontWeight: 600,
        },
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)"
    },
    methods: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    methodsOption: {
        borderRadius: theme.shape.radiusLevels[1],
        boxShadow: theme.shadows[3],
        width: "100%",
        border: "none",
        backgroundColor: "transparent",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: 0,
        display: "flex",
        flexDirection: "column",
        "&:active": {
            opacity: 0.7,
        },
    },
    colorFullBottom: {
        overflow: "hidden",
    },
    colorFullBottomBar: {
        height: theme.spacing(0.5),
        width: "100%",
        display: "flex",
        flexDirection: "row",
        "& div": {
            width: "25%",
        },
        "& div:nth-child(1)": {
            backgroundColor: theme.palette.purple.main,
        },
        "& div:nth-child(2)": {
            backgroundColor: theme.palette.blue.main,
        },
        "& div:nth-child(3)": {
            backgroundColor: theme.palette.orange.main,
        },
        "& div:nth-child(4)": {
            backgroundColor: theme.palette.gold.main,
        },
    },
    optionContent: {
        borderRadius: theme.shape.radiusLevels[1],
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        textAlign: "left",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
    },
    optionDesc: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        width: 28,
        textAlign: "center"
    },
    optionName: {
        fontWeight: 600,
        flex: 1,
        width: "100%",
        minWidth: 240
    },
    optionIcon: {
        color: theme.palette.primary.main,
        fontSize: "120%",
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    navigationIcon: {
        color: theme.palette.neutral.grey1,
        marginRight: theme.spacing(1),
    },
}));

const ClaimMoneyForPayAnyone = () => {
    const classes = useStyles();
    const { verifyCode } = useParams();
    const history = useHistory();
    const { step, stepForward } = useStepNavigation({
        initialStep: null,
    });

    const unmounted = useRef();
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [resultStatus, setResultStatus] = useState(null);
    const [loading, setFetching] = useState(false);
    const refNo = useRef("");
    const token = useRef("");
    const hashedPassword = useRef("");
    const otpSuccessToken = useRef("");
    const firstLoad = useRef(true);

    useEffect(() => {
        return () => {
            unmounted.current = true;
            window.onbeforeunload = null;
        };
    }, []);

    window.onbeforeunload = (e) => {
        e = e || window.event;

        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = "Sure?";
        }

        // For Safari
        return "Sure?";
    };

    // BEGIN ----- Check security options -----
    const checkSecurityResponseHandler = useCallback(
        (response) => {
            const { code, data, secData } = response.data;
            if (secData?.token) {
                token.current = secData?.token;
            }

            if (data?.refNo) {
                refNo.current = data?.refNo;
            }

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    // const { status, paoType } = data;
                    if (!data?.status || (data?.status && data?.status !== PayAnyoneStatus.Active)) {
                        setResultStatus(PayAnyoneStatus.Failed);
                        stepForward(CLAIM_MONEY_SCREENS.RESULT);
                        return;
                    }
                    setPaymentDetail(data);
                    if (data?.paoType === PRODUCT_TYPES.LIXI) {
                        stepForward(CLAIM_MONEY_SCREENS.LIXI_DETAIL);
                    } else {
                        stepForward(CLAIM_MONEY_SCREENS.PAYMENT_DETAIL);
                    }
                    break;
                case RESPONSE_CODE.OTP_REQUIRED:
                    setPaymentDetail(data);
                    stepForward(CLAIM_MONEY_SCREENS.INPUT_OTP);
                    break;
                case RESPONSE_CODE.PASSWORD_REQUIRED:
                    setPaymentDetail(data);
                    stepForward(CLAIM_MONEY_SCREENS.INPUT_PASSWORD);
                    break;
                default:
                    // history.push("/login");
                    break;
            }
        },
        [stepForward]
    );

    const checkSecurityOption = useCallback(async () => {
        setFetching(true);

        const payload = {
            verifyCode: sha512(verifyCode).toString(),
        };
        const response = await moveMoneyService.checkSecurityOptionOnClaimMoney(
            payload
        );
        if (unmounted.current) {
            return;
        }
        firstLoad.current = false;
        setFetching(false);
        checkSecurityResponseHandler(response);
    }, [verifyCode, checkSecurityResponseHandler]);

    useEffect(() => {
        if (!loading && firstLoad.current) {
            checkSecurityOption()
        }
    }, [checkSecurityOption, loading]);

    // END ----- Check security options -----

    const wrongInfoManyTimesHandler = useCallback(() => {
        setResultStatus(PayAnyoneStatus.FE_WRONG_SECURITY_MANY_TIMES);
        stepForward(CLAIM_MONEY_SCREENS.RESULT);
    },[stepForward])

    const onVerifySecurityResponseHandler = useCallback(({ code, data, secData }) => {
        if (secData?.token) {
            token.current= secData?.token;
        }

        if (data?.refNo) {
            refNo.current = data?.refNo;
        }

        if (data?.hashedPassword) {
            hashedPassword.current = data?.hashedPassword;
        }
        
        if (data?.otpSuccessToken) {
            otpSuccessToken.current = data?.otpSuccessToken;
        }

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const { status, paoType } = data;
                if (status) {
                    setResultStatus(status);
                    stepForward(CLAIM_MONEY_SCREENS.RESULT);
                } else {
                    setPaymentDetail(data);
                    if (paoType === PRODUCT_TYPES.LIXI) {
                        stepForward(CLAIM_MONEY_SCREENS.LIXI_DETAIL);
                    } else {
                        stepForward(CLAIM_MONEY_SCREENS.PAYMENT_DETAIL);
                    }
                }
                break;
            case RESPONSE_CODE.WRONG_PASSWORD_MANY_TIMES:
                wrongInfoManyTimesHandler();
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [stepForward, wrongInfoManyTimesHandler]);

    const onSubmitResponseHandler = ({code, status}) => {
        switch(code) {
            case RESPONSE_CODE.SUCCESS:
                setResultStatus(status || PayAnyoneStatus.FE_CLAIM_MONEY_SUCCESS);
                stepForward(CLAIM_MONEY_SCREENS.RESULT);
                break;
            case RESPONSE_CODE.WRONG_PASSWORD_MANY_TIMES:
            case RESPONSE_CODE.DESTINATION_INVALID:
                wrongInfoManyTimesHandler();
                break;
            default:
                setResultStatus(PayAnyoneStatus.Failed);
                stepForward(CLAIM_MONEY_SCREENS.RESULT);
                break;
        }
    }

    return (
        <div className={classes.wrapper}>
            {
                step.current !== CLAIM_MONEY_SCREENS.LIXI_DETAIL &&   
                <div className={classes.content}>
                    <ClaimMoneyHeader isHideHeader={step.current === CLAIM_MONEY_SCREENS.JOIN_TIMO} />
                    {loading && (
                        <div className={classes.body}>
                            <ClaimMoneyLoading />
                        </div>
                    )}

                    {!loading && (
                        <div className={classes.body}>
                            {(step.current ===
                                CLAIM_MONEY_SCREENS.INPUT_PASSWORD ||
                                step.current ===
                                    CLAIM_MONEY_SCREENS.INPUT_OTP) && (
                                <ClaimMoneySecurityOptions
                                    classes={classes}
                                    onResult={onVerifySecurityResponseHandler}
                                    detail={paymentDetail}
                                    step={step.current}
                                    refNo={refNo.current}
                                    token={token.current}
                                />
                            )}
                            {step.current === CLAIM_MONEY_SCREENS.RESULT && (
                                <ClaimMoneyResult
                                    parentClasses={classes}
                                    status={resultStatus}
                                    onDownloadApp={() =>
                                        stepForward(
                                            CLAIM_MONEY_SCREENS.JOIN_TIMO
                                        )
                                    }
                                    verifyCode={sha512(verifyCode).toString()}
                                />
                            )}
                            {step.current ===
                                CLAIM_MONEY_SCREENS.PAYMENT_DETAIL && (
                                <ClaimMoneyPaymentDetail
                                    paymentDetail={paymentDetail}
                                    onDownloadApp={() =>
                                        stepForward(
                                            CLAIM_MONEY_SCREENS.JOIN_TIMO
                                        )
                                    }
                                    onOpenBankDetail={() =>
                                        stepForward(
                                            CLAIM_MONEY_SCREENS.BANK_DETAIL
                                        )
                                    }
                                    parentClasses={classes}
                                />
                            )}
                            {step.current ===
                                CLAIM_MONEY_SCREENS.BANK_DETAIL && (
                                <ClaimMoneyBankDetail
                                    classes={classes}
                                    onResponseHandler={onSubmitResponseHandler}
                                    hashedPassword={hashedPassword.current}
                                    token={token.current}
                                    refNo={refNo.current}
                                    paoType={paymentDetail?.paoType}
                                    otpSuccessToken={otpSuccessToken.current}
                                />
                            )}
                            {step.current === CLAIM_MONEY_SCREENS.JOIN_TIMO && (
                                <ClaimMoneyJoinTimo />
                            )}
                        </div>
                    )}
                </div>
            }

            {step.current === CLAIM_MONEY_SCREENS.LIXI_DETAIL && (
                <ClaimMoneyLixiDetail paymentDetail={paymentDetail} parentClass={classes}  />
            )}
        </div>
    );
};

export default ClaimMoneyForPayAnyone;
