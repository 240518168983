import { makeStyles } from "@material-ui/core";

import logo from "@assets/images/Timo.png";
import chevronRight from "@assets/images/chevron-right.png";
import { UAParser } from "ua-parser-js";
import { useCallback } from "react";
import { ADJ_DIRECT, GENERAL_CONTENT } from "@config/constants";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    splitBillFooter: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        justifyContent: "center",
    },
    splitBillFooterContent: {
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: theme.palette.neutral.white,
        height: 64,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[4],
    },
    splitBillFooterContentBody: {
        flex: 1,
        fontWeight: 700,
    },
    splitBillFooterContentAction: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    splitBillFooterLogo: {
        width: 24,
        height: 24,
        marginRight: theme.spacing(2),
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    splitBillText: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(10),
        lineHeight: "16px",
        textTransform: "uppercase",
        color: theme.palette.magicalGrey[400],
        marginRight: theme.spacing(1),
    },
    splitBillIcon: {
        height: "10px",
    },
    splitBillLineColor: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    splitBillLine: {
        height: "4px",
        width: "100%",
    },
    purple: {
        backgroundColor: theme.palette.purple.main,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
    blue: {
        backgroundColor: theme.palette.blue.main,
    },
    orange: {
        backgroundColor: theme.palette.orange.main,
    },
    gold: {
        backgroundColor: theme.palette.gold.main,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    termsAndConditions: {
        fontWeight: 400,
        textAlign: "center",
        marginBottom: theme.spacing(5),
        color: theme.palette.magicalGrey[400],
        textDecoration: "none",
    },
    boldText: {
        color: theme.palette.success.main,
        fontWeight: 600,
        textDecoration: "underline",
    },
}));

const SplitBillFooter = () => {
    const classes = useStyles();
    const { os } = UAParser();
    const { t } = useTranslation();

    const openAppStore = useCallback(() => {
        const homePage = ADJ_DIRECT.ADJ_STORE;
        const androidStore = GENERAL_CONTENT.CH_PLAY;
        const adjHomePage = `${ADJ_DIRECT.HOME_PAGE}?`;

        if (os.name === "Android") {
            const decodeHomePage = encodeURIComponent(androidStore);
            const adj = (ADJ_DIRECT.ADJ_LINK || "")
                .split("__HOME_PAGE__")
                .join(adjHomePage)
                .split("__DECODE_URI__")
                .join(decodeHomePage);
            window.open(adj, "_blank");
            return;
        }
        const decodeHomePage = encodeURIComponent(homePage);
        const adj = (ADJ_DIRECT.ADJ_LINK || "")
            .split("__HOME_PAGE__")
            .join(adjHomePage)
            .split("__DECODE_URI__")
            .join(decodeHomePage);
        window.open(adj, "_blank");
    }, [os]);

    return (
        <>
            <div className={classes.splitBillFooter}>
                <div className={classes.splitBillFooterContent}>
                    <div className={classes.splitBillFooterLogo}>
                        <img src={logo} alt="Timo" />
                    </div>

                    <div className={classes.splitBillFooterContentBody}>
                        <LspTypography variant="heading3" color="black">
                            {t("splitBill:sb_web_install_app")}
                        </LspTypography>
                    </div>
                    <div
                        className={classes.splitBillFooterContentAction}
                        onClick={openAppStore}
                    >
                        <span className={classes.splitBillText}>
                            {t("splitBill:sb_web_install_app_free")}
                        </span>
                        <img
                            className={classes.splitBillIcon}
                            src={chevronRight}
                            alt={t("splitBill:sb_web_install_app_free")}
                        />
                    </div>
                </div>
                <div className={classes.splitBillLineColor}>
                    <div
                        className={`${classes.splitBillLine} ${classes.purple}`}
                    />
                    <div
                        className={`${classes.splitBillLine} ${classes.blue}`}
                    />
                    <div
                        className={`${classes.splitBillLine} ${classes.orange}`}
                    />
                    <div
                        className={`${classes.splitBillLine} ${classes.gold}`}
                    />
                </div>
            </div>
        </>
    );
};

export default SplitBillFooter;
