import api from "@config/api";

const getBillList = async () => {
    return await api.get("/user/payee/list?group=2");
};

const getBillDetail = async (payload) => {
    return await api.post("/user/bill/getBillInfo", payload);
    // return await apiMock.get(`/user/bill/getBillInfo`, {
    //     params: { type: "success" },
    // });
    // return await apiMock.get(`/user/bill/getBillInfo`, {
    //     params: { type: "noBill" },
    // });
};

const payBillRequest = async (payload) => {
    return await api.post(`/user/txn/bill/payment`, payload, {
        bypassErrorHandler: true,
    });
};

const getBillHistory = async (payload) => {
    return await api.post("/user/bill/getBillHistory", payload);
    // return await apiMock.get(`/user/bill/getBillHistory`, payload);
};

const getBillProvider = async (payload) => {
    return await api.get(`/user/bill/getProviderList/${payload?.supplierId}`);
};
const getBillForm = async (payload) => {
    return await api.post(`/user/bill/getBillForm`, payload);
};

const getSplitBill = async (payload) => {
    return await api.post(`/moneypots/split-bill/public/service/${payload.id}`);
};

const getSplitBillPayment = async (payload) => {
    return await api.post(
        `/moneypots/split-bill/public/service/component/${payload.id}`
    );
};

const billServices = {
    getBillList,
    getBillDetail,
    payBillRequest,
    getBillHistory,
    getBillProvider,
    getBillForm,
    getSplitBill,
    getSplitBillPayment,
};

export default billServices;
