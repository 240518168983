/* eslint-disable no-unreachable */
import { useEffect, useState, useCallback, useMemo } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SHA256 } from "crypto-js";
import sha512 from "crypto-js/sha512";

import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import { isFunction } from "lodash";
import { history } from "@containers/App/Initialization";
import AlertController, {
    AlertType,
} from "../../helpers/controllers/AlertController";

import quickCodeActions, {
    QuickCodeScreens,
    QuickCodeFlows,
} from "../../redux/actions/quick-code";
import QuickCodeKeyBoard from "./QuickCodeKeyBoard";
import QuickCodeIntro from "./QuickCodeIntro";
import QuickCodeContent from "./QuickCodeContent";
import Password from "./Password";
import authAction from "../../redux/actions/auth";
import GlobalDialogController from "../../helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: theme.shadows[4],
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.primary.main,
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.radiusLevels[0],
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    transparentBg: {
        background: "transparent",
    },
    content: {
        textAlign: "center",
        fontSize: theme.typography.pxToRem(14),
        paddingLeft: theme.spacing(3),
        "& h3": {
            fontSize: theme.typography.pxToRem(16),
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        padding: theme.spacing(2, 4),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "600",
        borderRadius: theme.shape.radiusLevels[0],
        textTransform: "uppercase",
    },
}));

const QuickCode = ({
    submitting,
    flow,
    openQuickCode,
    closeQuickCode,
    password,
    create,
    activeStepTmp,
    setActiveStepSuccess,
    checkValidQuickCode,
    forgot,
    logoutRequest,
    afterClose,
    isActionAfterClose,
    token,
}) => {
    const classes = useStyles();

    const steps = useMemo(() => {
        switch (flow) {
            case QuickCodeFlows.CREATE:
                return [
                    QuickCodeScreens.INTRO,
                    QuickCodeScreens.INPUT_NEW_QUICK_CODE,
                    QuickCodeScreens.CONFIRM_NEW_QUICK_CODE,
                ];
            case QuickCodeFlows.CHANGE:
                return [
                    QuickCodeScreens.INPUT_NEW_QUICK_CODE_FOR_CHANGE,
                    QuickCodeScreens.INPUT_PASSWORD,
                ];
            default:
                // VERIFY
                return [QuickCodeScreens.INPUT_CURRENT_QUICK_CODE];
        }
    }, [flow]);

    const [currentScreen, setCurrentScreen] = useState(steps[0]);

    const { t } = useTranslation();
    const [quickCode, setQuickCode] = useState("");

    // Set open function to controller
    useEffect(() => {
        QuickCodeDialogController.setOpen(() => openQuickCode(flow));
    }, [openQuickCode, flow]);

    // Set close function to controller
    useEffect(() => {
        QuickCodeDialogController.setClose(closeQuickCode);
    }, [closeQuickCode]);

    useEffect(() => {
        QuickCodeDialogController.setDirectFunc((url) => {
            history.push(url);
        });
    }, []);

    const close = () => {
        QuickCodeDialogController.hide();
    };

    const closeWithForceLogout = () => {
        close();
        logoutRequest({ sessionTimeout: true });
    };

    const findIndexStep = (step) => {
        const stepName = step || currentScreen;
        return steps.findIndex((s) => s === stepName);
    };

    const nextStep = () => {
        const currentIndex = findIndexStep();
        setCurrentScreen(steps[currentIndex + 1]);
    };

    const backStep = () => {
        const currentIndex = findIndexStep();
        if (currentIndex <= 0) return;
        setCurrentScreen(steps[currentIndex - 1]);
    };

    useEffect(() => {
        QuickCodeDialogController.open();
    }, []);

    // using for go to specific step when call api and got error
    useEffect(() => {
        if (activeStepTmp) {
            setCurrentScreen(activeStepTmp);
            setActiveStepSuccess();
        }
    }, [activeStepTmp, setActiveStepSuccess]);

    const goToConfirmQuickCode = (qcString) => {
        setQuickCode(qcString);
        nextStep();
    };

    const resetQuickCode = () => {
        setQuickCode("");
        backStep();
    };

    const createQuickCodeApi = useCallback(
        (data) => {
            const encryptedPassword = sha512(data.password).toString();
            const encryptedQuickCode = SHA256(data.quickCode).toString();

            create({
                quickCode: encryptedQuickCode,
                password: encryptedPassword,
                successMsg: data.successMsg,
            });
        },
        [create]
    );

    const createQuickCode = useCallback(() => {
        createQuickCodeApi({
            password,
            quickCode,
            successMsg: {
                icon: "quickcode",
                header: "quickcode_title_msg_success",
                content: "quickcode_content_msg_success",
                button: "lb_ok",
                isCreateFlow: true,
            },
        });
    }, [quickCode, createQuickCodeApi, password]);

    const changeQuickCodeAction = useCallback(
        (pass) => {
            createQuickCodeApi({
                password: pass,
                quickCode,
                successMsg: {
                    icon: "quickcode",
                    header: "quickcode_title_msg_success_changed",
                    content: "quickcode_content_msg_success_changed",
                    button: "lb_ok",
                },
            });
        },
        [quickCode, createQuickCodeApi]
    );

    const quickCodeValidation = useCallback(
        (qcConfirm) => {
            if (quickCode !== qcConfirm) {
                AlertController.show(
                    t("quick_code_error_no_match"),
                    AlertType.Error
                );
            } else {
                createQuickCode(qcConfirm);
            }
        },
        [quickCode, createQuickCode, t]
    );

    const checkCurrentQuickIsValid = (qcString) => {
        setQuickCode(qcString);
        const encryptedQuickCode = SHA256(qcString).toString();
        checkValidQuickCode({ quickCode: encryptedQuickCode, token });
    };

    const confirmForgotQuickCode = () => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                icon: "quickcode",
                header: "quickcode_notify_logout",
                content: "quickcode_notify_logout_desc",
                doubleButton: [
                    {
                        label: t("lb_continue"),
                        onClick: () => {
                            forgot();
                        },
                    },
                    {
                        label: t("lb_back"),
                        onClick: () => {
                            if (afterClose && isFunction(afterClose)) {
                                QuickCodeDialogController.show(() => {
                                    window.location.reload();
                                });
                            } else {
                                QuickCodeDialogController.show();
                            }
                        },
                    },
                ],
            },
        });
    };

    const goToInputPassword = (qcString) => {
        setQuickCode(qcString);
        nextStep();
    };

    useEffect(() => {
        if (isActionAfterClose) {
            close();
        }
        if (isActionAfterClose && isFunction(afterClose)) {
            afterClose();
        }
    }, [isActionAfterClose, afterClose]);

    return (
        <div
            className={clsx(
                classes.container,
                currentScreen === QuickCodeScreens.INPUT_PASSWORD
                    ? classes.transparentBg
                    : ""
            )}
        >
            {currentScreen === QuickCodeScreens.INTRO && (
                <QuickCodeIntro
                    parentClasses={classes}
                    nextHandler={nextStep}
                />
            )}

            {currentScreen === QuickCodeScreens.INPUT_NEW_QUICK_CODE && (
                <>
                    <QuickCodeContent
                        icon="quickcode"
                        title="quick_code_title_setup"
                        content="quick_code_desc_setup"
                    />
                    <QuickCodeKeyBoard nextHandler={goToConfirmQuickCode} />
                </>
            )}

            {currentScreen === QuickCodeScreens.CONFIRM_NEW_QUICK_CODE && (
                <>
                    <QuickCodeContent
                        icon="quickcode"
                        title="quick_code_title_confirm"
                        content="quick_code_desc_confirm"
                        resetQuickCode={resetQuickCode}
                        loading={submitting}
                    />
                    <QuickCodeKeyBoard
                        nextHandler={quickCodeValidation}
                        loading={submitting}
                    />
                </>
            )}

            {currentScreen === QuickCodeScreens.INPUT_CURRENT_QUICK_CODE && (
                <>
                    <QuickCodeContent
                        icon="lockcard"
                        title="quickcode_title_change_qc"
                        content="quick_code_desc_setup"
                        forgotQuickCode={confirmForgotQuickCode}
                        loading={submitting}
                    />
                    <QuickCodeKeyBoard
                        nextHandler={checkCurrentQuickIsValid}
                        loading={submitting}
                        onClose={closeWithForceLogout}
                        isCloseIcon
                    />
                </>
            )}

            {currentScreen ===
                QuickCodeScreens.INPUT_NEW_QUICK_CODE_FOR_CHANGE && (
                <>
                    <QuickCodeContent
                        icon="quickcode"
                        title="quickcode_enter_new_quickcode"
                        content="quick_code_desc_setup"
                        loading={submitting}
                    />
                    <QuickCodeKeyBoard
                        nextHandler={goToInputPassword}
                        loading={submitting}
                        onClose={close}
                        isCloseIcon
                    />
                </>
            )}

            {currentScreen === QuickCodeScreens.INPUT_PASSWORD && (
                <>
                    <Password
                        onClose={close}
                        nextHandler={changeQuickCodeAction}
                        loading={submitting}
                    />
                </>
            )}
        </div>
    );
};

const stateToProps = (state) => ({
    submitting: state.quickCodeInfo.submitting,
    password: state.quickCodeInfo.password,
    activeStepTmp: state.quickCodeInfo.activeStepTmp,
    isActionAfterClose: state.quickCodeInfo.isActionAfterClose,
    token: state.auth.token,
});

const dispatchToProps = (dispatch) => ({
    openQuickCode: (payload) =>
        dispatch(quickCodeActions.openQuickCodeDialog(payload)),
    closeQuickCode: () => dispatch(quickCodeActions.closeQuickCodeDialog()),
    setActiveStepSuccess: () =>
        dispatch(quickCodeActions.setActiveStepSuccess()),
    create: (payload) => dispatch(quickCodeActions.createNewQuickCode(payload)),
    isExistingQuickCode: () => dispatch(quickCodeActions.isExistingQuickCode()),
    checkValidQuickCode: (payload) =>
        dispatch(quickCodeActions.checkValidQuickCode(payload)),
    forgot: () => dispatch(quickCodeActions.forgotQuickCode()),
    logoutRequest: (payload) => dispatch(authAction.logoutRequest(payload)),
});

export default connect(stateToProps, dispatchToProps)(QuickCode);
