import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import LspButton from "@components/LspButton";
import Interweave from "interweave";
import clsx from "clsx";
import { ArrowRightIcon } from "@components/LspIcon";

const useStyles = makeStyles((theme) => {
    return {
        tdItem: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            width: "calc(50% - 8px)",
            marginBottom: theme.spacing(2),
            borderRadius: theme.shape.radiusLevels[1],
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        headerList: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2, 2.5),
            "& > div": {
                textAlign: "left",
                color: theme.palette.primary.main,
                fontWeight: "600",
            },
            "& > a": {
                color: theme.palette.status.success,
                fontSize: theme.typography.pxToRem(14),
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            },
        },
        tdList: {
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
        },
        contentList: {
            minHeight: "202px",
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        fundExisted: {
            padding: theme.spacing(2.5, 2),
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            textAlign: "left",
        },
        fundUnit: {
            width: "48%",
            display: "inline-block",
            margin: "0",
        },
        lineSpacing: {
            margin: theme.spacing(2.5, 0),
            border: "0",
            borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
        footerList: {
            padding: theme.spacing(2.5, 6),
        },
        footerBtn: {
            " & > button > span > p ": {
                fontSize: theme.typography.pxToRem(14),
            },
        },
        contentTable: {
            "& > h3": {
                padding: theme.spacing(1.25, 2.25),
                fontSize: theme.typography.pxToRem(13),
                background: theme.palette.background.header,
                margin: 0,
                color: theme.palette.primary.main,
            },
            "& > div": {
                marginTop: theme.spacing(2),
                padding: theme.spacing(0, 2.25),
            },
        },
        fundExistedTitle: {
            fontSize: theme.typography.pxToRem(13),
            color: theme.palette.grey[400],
            marginBottom: theme.spacing(0.75),
        },
        fundExistedBalance: {
            fontSize: theme.typography.pxToRem(22),
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        status: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.status.success,
        },
        down: {
            color: theme.palette.error.main,
        },
    };
});

const VinaWealthItem = ({ product }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Box className={classes.tdItem}>
            <Box className={classes.headerList}>
                <div> {product.FETitle}</div>
                <a onClick={() => product.FELearnMoreFunc(product)}>
                    <span> {t("wealth:vc3_learn_more_btn")}</span>
                    <ArrowRightIcon />
                </a>
            </Box>
            <Box className={classes.contentList}>
                {!product.FEIsContributeAccount && (
                    <div className={classes.contentTable}>
                        <h3>{product.FESubTitle}</h3>
                        <div>{product.FEDesc}</div>
                        <div>
                            <Interweave content={product.FESubdesc} />
                        </div>
                    </div>
                )}
                {product.FEIsContributeAccount && (
                    <div className={classes.fundExisted}>
                        <div className={classes.fundExistedTitle}>
                            {t("wealth:vc3_acc_balance")}
                        </div>
                        <span className={classes.fundExistedBalance}>
                            {product.FECurrentBalance}
                        </span>
                        <hr className={classes.lineSpacing} />
                        <div className={classes.fundUnit}>
                            <div className={classes.fundExistedTitle}>
                                {t("wealth:vc3_acc_unreallized_gain_loss")}
                            </div>
                            <span>{product.FEBalanceUnrealizedGainLoss}</span>
                        </div>
                        <div className={classes.fundUnit}>
                            <div className={classes.fundExistedTitle}>
                                {t("wealth:vc3_acc_lastest_nav")}
                            </div>
                            <span>{product.FENAV}</span>
                            {product.percent && (
                                <span
                                    className={clsx(
                                        classes.status,
                                        classes[product.FEStatusUnit]
                                    )}
                                >
                                    {product.percent}%
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </Box>
            <Box className={classes.footerList}>
                <LspButton
                    fullWidth
                    className={classes.footerBtn}
                    onClick={product.FEBtnFunc}
                >
                    {product.FEBtnText}
                </LspButton>
            </Box>
        </Box>
    );
};

export default VinaWealthItem;
