import LspButton from "@components/LspButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, Grid, Icon, makeStyles } from "@material-ui/core";
import CardManagementActions from "@redux/actions/card-management";
import Interweave from "interweave";
import { memo, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CARD_ACTIVATE_SUCCESS_TYPES } from "./constant";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "auto",
        background: "transparent",
    },
    wrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
        boxShadow: theme.shadows[1],
        textAlign: "center",
        margin: "auto",
    },
    header: {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    icon: {
        fontSize: theme.typography.pxToRem(130),
    },
}));

const CardActivateSuccess = ({ onClose, action, getCardList }) => {
    const classes = useStyles();

    const { t } = useTranslation(["translation", "master"]);

    const msg = useMemo(() => {
        switch (action) {
            case CARD_ACTIVATE_SUCCESS_TYPES.REPLACE_CHIP_CARD:
                return t("master:activate_success_content_chipcard");
            case CARD_ACTIVATE_SUCCESS_TYPES.VISA_DEBIT_VIRTUAL_CARD:
                return "";
            default:
                return t("master:activate_success_content1");
        }
    }, [action, t]);

    const onCloseHandler = useCallback(() => {
        if (action === CARD_ACTIVATE_SUCCESS_TYPES.VISA_DEBIT_VIRTUAL_CARD) {
            GlobalDialogController.hide();
            getCardList();
            return;
        }

        onClose();
    }, [action, getCardList, onClose]);

    return (
        <Box marginTop={2}>
            <Grid
                container
                direction="row"
                justify="space-between"
                spacing={4}
                className={classes.root}
            >
                <Grid item md={9} xs={11} className={classes.wrapper}>
                    <div className={classes.header}>
                        <div>
                            <Icon
                                className={`font-icon icon-Plus-SUCCESS ${classes.icon}`}
                            />
                            <div>{t("mc_ms_active_card_success")}</div>
                        </div>
                    </div>
                    <Box margin={4}>
                        <p>
                            <Interweave content={msg} />
                        </p>

                        <p>
                            <Interweave
                                content={t("master:activate_success_content2")}
                            />
                        </p>
                    </Box>

                    <LspButton type="submit" fullWidth onClick={onCloseHandler}>
                        {t("lb_btn_ok_got_it")}
                    </LspButton>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getCardList: () => dispatch(CardManagementActions.getCardList()),
});

export default memo(connect(null, mapDispatchToProps)(CardActivateSuccess));
