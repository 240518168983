import { useCallback, useMemo } from "react";
import { WEALTH_NAVIGATION_KEYS } from "@containers/Wealth/wealthConstants";

const rootPath = WEALTH_NAVIGATION_KEYS.ROOT;

const useWealth = () => {
    const icons = useMemo(
        () => ({
            card2: "card-v2",
            card3: "card-v2",
            mastercard: "card-v2",
            mastercard3: "card-v2",
            creditCardV4: "card-v2",
            lifeinsurance: "people-v2",
            lifeinsurance2: "people-v2",
            slinstantcoverage: "instantcoverage",
            sllifelongprotection: "lifelongprotection",
            slllaccident: "protectionsolutions",
            slllhealth: "healthwellnesssolution",
            slllflexsaving: "savingplan",
            overdraft: "fastcash",
            insurance: "plane-v2",
            insurance2: "plane-v2",
            vinawealth2: "cash-v2",
            vinawealth3: "cash-v2",
            loan: "loan-v2",
            insurancemoto: "motorbike",
            insurancetravel: "travelinsurance",
            // motorbike: "motorbike",
            // auto: "auto",
            // domestictravel: "localtravel",
            // internationaltravel: "internationaltravel",
            // overseastransfer: "oversea",
        }),
        []
    );

    const paths = useMemo(
        () => ({
            card2: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`,
            card3: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`,
            mastercard: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`,
            mastercard3: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`,
            creditCardV4: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`,
            lifeinsurance: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE}`,
            lifeinsurance2: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            slinstantcoverage: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            sllifelongprotection: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            slllaccident: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            slllhealth: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            slllflexsaving: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            overdraft: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            insurance: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.TRAVEL_INSURANCE}`,
            insurance2: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.TRAVEL_INSURANCE}`,
            vinawealth2: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.VINA_WEALTH}`,
            vinawealth3: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.VINA_WEALTH}`,
            loan: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.LOANS}`,
            // motorbike: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            // auto: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            // domestictravel: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            // internationaltravel: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
            // overseastransfer: `/${rootPath}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`,
        }),
        []
    );

    const mapDocumentContent = useCallback((contents) => {
        if (!contents) return;

        const finalContent = [];
        for (let i = 0; i < contents.length; i++) {
            const row = contents[i];
            let titleTmp = "";
            const descriptionsTmp = [];
            for (let j = 0; j < row.length; j++) {
                const info = row[j];
                if (j === 0) {
                    titleTmp = info;
                } else {
                    descriptionsTmp.push(info);
                }
            }
            finalContent.push({
                title: titleTmp,
                descriptions: descriptionsTmp,
            });
        }

        return finalContent;
    }, []);

    const getWealthIcon = useCallback(
        (productKey) => {
            return icons[productKey];
        },
        [icons]
    );

    const getWealthPath = useCallback(
        (productKey) => {
            return paths[productKey];
        },
        [paths]
    );

    return { getWealthIcon, getWealthPath, mapDocumentContent };
};

export default useWealth;
