import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { makeStyles, Icon, Radio } from "@material-ui/core";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1.5, 0),
        cursor: "pointer",
    },
    content: {
        flex: 1,
    },
    action: {
        display: "flex",
        alignItems: "center",
    },
    radioCircle: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        border: `1.5px solid ${theme.palette.magicalGrey[300]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    radioCircleActive: {
        border: `1.5px solid rgba(16, 24, 32, 0.1);`,
        backgroundColor: theme.palette.green.main,
    },
    radioCircleChild: {
        borderRadius: "50%",
        width: 12,
        height: 12,
        backgroundColor: theme.palette.neutral.white,
    },
    disable: {
        opacity: 0.5,
        cursor: "unset",
    },
}));
const OverdraftItem = ({ item, checked, onSelected }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const handleRate = useMemo(() => {
        return (
            <>
                <LspTypography variant="body3">
                    {t("overdraft:overdraft_review_close_date")}{" "}
                    {item?.termName}
                </LspTypography>
                <LspTypography variant="body3">
                    {t("fast_cash_content_facility_term").replace(
                        "%@",
                        item?.term
                    )}
                    &nbsp;&&nbsp;{item?.interestRate}
                    {t("TD:td_interest_table_unit_detail")}
                </LspTypography>
            </>
        );
    }, [item?.interestRate, item?.term, item?.termName, t]);

    const desc = useMemo(() => {
        switch (item?.blockType) {
            case "OTHER":
                return (
                    <LspTypography variant="body3">
                        {t("overdraft:overdraft_register_td_block_type_cc")}
                    </LspTypography>
                );
            case "AMOUNT_MINIMUM":
                return (
                    <LspTypography variant="body3">
                        {t(
                            "overdraft:overdraft_register_td_block_type_amount_minimum"
                        )}
                    </LspTypography>
                );

            default:
                return handleRate;
        }
    }, [handleRate, item?.blockType, t]);

    const handleSelectItem = () => {
        if (onSelected && item?.eligible) {
            onSelected(item);
        }
    };

    return (
        <div
            className={clsx(
                classes.container,
                !item?.eligible && classes.disable
            )}
            onClick={handleSelectItem}
        >
            <div className={classes.content}>
                <LspTypography variant="subheading2">
                    {item?.termName}
                </LspTypography>
                {desc}
            </div>
            <div className={classes.action}>
                <LspTypography variant="heading2">
                    {formatNumber(item?.termDepositBalance)}
                </LspTypography>
                {item?.eligible && (
                    <Radio
                        className={classes.customRadioButton}
                        checkedIcon={
                            <div
                                className={`${classes.radioCircle} ${classes.radioCircleActive}`}
                            >
                                <div className={classes.radioCircleChild} />
                            </div>
                        }
                        checked={checked}
                    />
                )}
            </div>
        </div>
    );
};

export default OverdraftItem;
