import api from "../config/api";

const login = async (payload) => {
    return await api.post("login", payload, { bypassForceLogout: true });
};

const logout = async (data) => {
    return await api.post("logout", data, { bypassForceLogout: true });
};

const submitDeviceAuthenticationOTP = async (payload) => {
    return await api.post("login/commit", payload);
};

const resendDeviceAuthenticationOTP = async (payload) => {
    return await api.post("login/resend/otp", payload);
};

const submitTwoFactorOTP = async (payload) => {
    const { bypassErrorHandler } = payload;
    return await api.post("login/afs/commit", payload, {
        bypassErrorHandler,
    });
};

const resendTwoFactorOTP = async (payload) => {
    return await api.post("login/afs/resend", payload);
};

const checkExistingQuickCode = async () => {
    return await api.put("/login/quickCode/checkExist", null);
};

const timeBaseOTP = async () => {
    return await api.post("user/timebase-otp/setup");
};

const timeBaseOTPCommit = async (payload) => {
    return await api.post("/user/timebase-otp/setup/commit", payload);
};

const authService = {
    login,
    logout,
    submitDeviceAuthenticationOTP,
    submitTwoFactorOTP,
    resendDeviceAuthenticationOTP,
    resendTwoFactorOTP,
    checkExistingQuickCode,
    timeBaseOTP,
    timeBaseOTPCommit,
};

export default authService;
