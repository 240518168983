import Banner from "@components/Banner";
import Content from "@components/Content";

import PageHeaderBack from "@components/PageHeaderBack";
import { memo, useRef, useEffect, useCallback, useState, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Box, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import QRCode from "qrcode.react";
import LspButton from "@components/LspButton";
import { Skeleton } from "@material-ui/lab";

import authService from "@services/auth";
import { RESPONSE_CODE } from "@config/constants";

import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import { useTranslation } from "react-i18next";
import useOTP from "@helpers/useOTP";
import Interweave from "interweave";

import { connect } from "react-redux";
import authAction from "@redux/actions/auth";

import { twoFactorsWarningSetupDialog } from "./helper";

const FACTOR_STATUS = {
    VALID: "VALID",
    INVALID: "INVALID",
    MAX_FAILED_ATTEMPTS_EXCEEDED: "MAX_FAILED_ATTEMPTS_EXCEEDED",
};

const TwoFactorsAuthentication = ({ forceLogout }) => {
    const history = useHistory();
    const [twoFactor, setTwoFactor] = useState(null);
    const { t } = useTranslation();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const [isLoading, setIsLoading] = useState(false);

    const theme = useTheme();

    const isDownXS = useMediaQuery(theme.breakpoints.down("xs"));

    const firstInit = useRef(null);

    const onCreateTwoFactor = useCallback(async () => {
        setIsLoading(true);
        const request = await authService.timeBaseOTP();

        setIsLoading(false);

        const { code, data } = request?.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setTwoFactor(data);
                break;

            default:
                GlobalDialogController.showError({ errorCode: code });
                history.push("/");
                break;
        }
    }, [history]);

    useEffect(() => {
        if (!firstInit.current) {
            onCreateTwoFactor();
            firstInit.current = true;
        }
    }, [onCreateTwoFactor]);

    const onBack = () => {
        history.goBack();
    };

    const secretCode = useMemo(() => {
        if (twoFactor && twoFactor?.secretCode) {
            const code = twoFactor?.secretCode.match(/.{1,4}/g);
            return code.join(" ");
        }
    }, [twoFactor]);

    const handleCopyKey = () => {
        if (twoFactor?.secretCode) {
            navigator.clipboard.writeText(twoFactor?.secretCode);
            AlertController.show(t("member:timostarclub_msg_copy_successful"));
        }
    };

    const handleEnableFail = () => {
        history.push("/security");
        GlobalDialogController.hide();
        closeOTPDialog();
    };

    const handleSubmit = async (params) => {
        const { otp } = params;
        setLoading(true);
        const request = await authService.timeBaseOTPCommit({ otp });
        setLoading(false);

        const { code, data } = request?.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const { readableCode } = data;

                switch (readableCode) {
                    case FACTOR_STATUS.VALID:
                        closeOTPDialog();

                        AlertController.show(
                            t("gg_authen:gg_authen_notice_enable_success")
                        );
                        history.push("/security");
                        break;
                    case FACTOR_STATUS.INVALID:
                        AlertController.show(
                            t("gg_authen:gg_authen_enter_code_error"),
                            AlertType.Error
                        );

                        break;
                    case FACTOR_STATUS.MAX_FAILED_ATTEMPTS_EXCEEDED:
                        twoFactorsWarningSetupDialog(() => handleEnableFail());
                        break;
                    default:
                        break;
                }
                break;

            default:
                GlobalDialogController.showError({ errorCode: code });
                history.push("/");
                break;
        }
    };

    const handleSubmitOTP = () => {
        const content = {
            dialogTitle: (
                <Interweave
                    content={t("gg_authen:gg_authen_enter_code_title")}
                />
            ),
            dialogSubtitle: t("gg_authen:gg_authen_enter_code_desc"),
            dialogDescription: t("gg_authen:gg_authen_enter_code_note"),
            dialogHideResendButton: true,
            submitFunc: handleSubmit,
        };

        openOTPDialog(content);
    };

    return (
        <>
            <Banner title="Two Factors" />
            <Content size={isDownXS ? "xs" : "md"}>
                <Box mt={2} mb={3}>
                    <PageHeaderBack onBack={onBack || null}>
                        {t("gg_authen:gg_authen_setup_header")}
                    </PageHeaderBack>
                    <Paper>
                        <Box p={4}>
                            <Box mb={2}>
                                <LspTypography
                                    color="primary"
                                    variant="heading3"
                                >
                                    {t(
                                        "gg_authen:gg_authen_setup_step1"
                                    ).replace("%@", "")}
                                    <LspTypography
                                        component="span"
                                        color="black"
                                        variant="subheading3"
                                    >
                                        {t(
                                            "gg_authen:gg_authen_setup_step1_title"
                                        )}
                                    </LspTypography>
                                </LspTypography>
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "gg_authen:gg_authen_setup_step1_details"
                                    )}
                                </LspTypography>
                                <LspTypography
                                    variant="hyperlink2"
                                    color="success"
                                    href={t(
                                        "gg_authen:gg_authen_setup_step1_install_appstore_link"
                                    )}
                                    component="a"
                                    target="_blank"
                                >
                                    {t(
                                        "gg_authen:gg_authen_setup_step1_install"
                                    )}
                                </LspTypography>
                            </Box>

                            <Box mb={4}>
                                <LspTypography
                                    color="primary"
                                    variant="heading3"
                                >
                                    {t(
                                        "gg_authen:gg_authen_setup_step2"
                                    ).replace("%@", "")}
                                    <LspTypography
                                        component="span"
                                        color="black"
                                        variant="subheading3"
                                    >
                                        {t(
                                            "gg_authen:gg_authen_setup_step2_title"
                                        )}
                                    </LspTypography>
                                </LspTypography>
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "gg_authen:gg_authen_setup_step2_details"
                                    )}
                                </LspTypography>
                            </Box>

                            <Box display="flex" justifyContent="center" mb={4}>
                                {!isLoading && (
                                    <QRCode
                                        renderAs="canvas"
                                        value={twoFactor?.qrCode || ""}
                                        level="M"
                                    />
                                )}

                                {isLoading && (
                                    <Skeleton
                                        variant="rect"
                                        height={128}
                                        width={128}
                                    />
                                )}
                            </Box>
                            <Box mb={2}>
                                <LspTypography color="grey" variant="body2">
                                    {t("gg_authen:gg_authen_setup_step2_web")}
                                </LspTypography>
                                <LspTypography variant="heading1">
                                    {!isLoading && secretCode}
                                    {isLoading && (
                                        <>
                                            <Skeleton variant="text" />
                                            <Skeleton
                                                variant="text"
                                                width={200}
                                            />
                                        </>
                                    )}
                                </LspTypography>

                                {!isLoading && (
                                    <LspTypography
                                        variant="hyperlink2"
                                        color="success"
                                        onClick={handleCopyKey}
                                    >
                                        {t(
                                            "gg_authen:gg_authen_setup_step2_copy_key"
                                        )}
                                    </LspTypography>
                                )}
                            </Box>

                            <Box mb={2}>
                                <LspTypography
                                    color="primary"
                                    variant="heading3"
                                >
                                    {t(
                                        "gg_authen:gg_authen_setup_step3"
                                    ).replace("%@", "")}
                                    <LspTypography
                                        component="span"
                                        color="black"
                                        variant="subheading3"
                                    >
                                        {t(
                                            "gg_authen:gg_authen_setup_step3_title"
                                        )}
                                    </LspTypography>
                                </LspTypography>
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "gg_authen:gg_authen_setup_step3_details"
                                    )}
                                </LspTypography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <LspButton fullWidth onClick={handleSubmitOTP} mb={4}>
                    {t("gg_authen:gg_authen_setup_cta_next")}
                </LspButton>
            </Content>
        </>
    );
};

const mapDispatch = (dispatch) => ({
    forceLogout: () => dispatch(authAction.forceLogout()),
});

export default connect(null, mapDispatch)(memo(TwoFactorsAuthentication));
