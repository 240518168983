/* eslint-disable react/no-array-index-key */
import { ButtonBase, Icon, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { memo, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import LspTypography from "@components/LspTypography";
import {
    VINA_WEALTH_TXT_TRANSACTION_TYPES,
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_TYPES,
} from "@containers/Wealth/wealthConstants";
import Loading from "@components/Loading";
import vinaWealthAction from "@redux/actions/vina-wealth";
import VinaWealthTransactionItem from "./VinaWealthTransactionItem";
import useSharedClasses from "./vinaWealthSharedClasses";

const useStyles = makeStyles((theme) => ({
    accountInfo: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "& > div": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                paddingBottom: theme.spacing(1),
            },
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        },
    },
    contact: {
        width: "100%",
        textAlign: "center",
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.neutral.grey3}`,
    },
}));

const VinaWealthTransactionBox = ({
    user,
    getTransactionList,
    products,
    onMoreTransaction,
    fetching,
    transactions,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useSharedClasses();
    const naLabel = t("wealth:vc3_lb_na");
    const contact = t("wealth:vc3_acc_contact_support").replace(
        "%@",
        t("wealth:vc3_vinacapital_hotline")
    );

    const lastTransaction = useMemo(() => {
        if (transactions && transactions.length > 0) {
            return transactions[transactions.length - 1];
        }
        return [];
    }, [transactions]);

    const firstTransactionDate = useMemo(() => {
        if (!transactions) return null;

        const existedFirstDates = transactions.filter(
            (transaction) =>
                transaction?.firstTranDate && transaction?.firstTranDate !== ""
        );

        if (!existedFirstDates || existedFirstDates.length === 0) return null;

        const firstDateTrans = existedFirstDates[existedFirstDates.length - 1];

        return firstDateTrans.split(" ")[0];
    }, [transactions]);

    const accountNumber = useMemo(() => {
        if (!products) return null;

        const existedAccountNumber = products.filter(
            (product) => product?.accountNumber && product?.accountNumber !== ""
        );

        if (!existedAccountNumber || existedAccountNumber.length === 0)
            return null;

        return existedAccountNumber[existedAccountNumber.length - 1]
            ?.accountNumber;
    }, [products]);

    useEffect(() => {
        const payload = {
            productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
            type: WEALTH_VINA_TYPES.TRANSACTION,
            lastId: -1,
            transactionType: [VINA_WEALTH_TXT_TRANSACTION_TYPES.PENDING],
        };
        getTransactionList(payload);
    }, [getTransactionList]);

    return (
        <>
            {fetching && <Loading />}
            {!fetching && (
                <Paper className={sharedClasses.transactionBox}>
                    <div className={sharedClasses.headerLine}>
                        <div className={sharedClasses.title}>
                            <Icon
                                className={`font-icon icon-vctransaction ${sharedClasses.icon}`}
                            />
                            <LspTypography variant="heading3" color="primary">
                                {t("wealth:vc3_manage_tab2")}
                            </LspTypography>
                        </div>
                        <div>
                            <ButtonBase onClick={onMoreTransaction}>
                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                >
                                    {t("wealth:vc3_seemore_btn")}
                                </LspTypography>
                            </ButtonBase>
                        </div>
                    </div>
                    <div className={sharedClasses.body}>
                        {!lastTransaction && (
                            <LspTypography variant="body2">
                                {t("wealth:vc3_pending_empty")}
                            </LspTypography>
                        )}
                        {lastTransaction && (
                            <VinaWealthTransactionItem
                                item={lastTransaction}
                                individual
                            />
                        )}
                    </div>
                    <div className={sharedClasses.headerLine}>
                        <div className={sharedClasses.title}>
                            <Icon
                                className={`font-icon icon-vcaccount ${sharedClasses.icon}`}
                            />
                            <LspTypography variant="heading3" color="primary">
                                {t("wealth:vinawealth3_account_info_title")}
                            </LspTypography>
                        </div>
                        <div />
                    </div>
                    <div
                        className={`${sharedClasses.body} ${classes.accountInfo}`}
                    >
                        <div>
                            <LspTypography variant="body2" color="grey">
                                {t("wealth:vc3_acc_name")}
                            </LspTypography>
                            <LspTypography>{user?.fullName}</LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="body2" color="grey">
                                {t("wealth:vc3_acc_number")}
                            </LspTypography>
                            <LspTypography>
                                {accountNumber || naLabel}
                            </LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="body2" color="grey">
                                {t("wealth:vc3_acc_type")}
                            </LspTypography>
                            <LspTypography>
                                {t("wealth:vc3_acc_type_vinaflex")}
                            </LspTypography>
                        </div>
                        <div>
                            <LspTypography variant="body2" color="grey">
                                {t("wealth:vc3_acc_date_open")}
                            </LspTypography>
                            <LspTypography>
                                {firstTransactionDate || naLabel}
                            </LspTypography>
                        </div>
                    </div>
                    <div className={classes.contact}>
                        <LspTypography variant="body2">{contact}</LspTypography>
                    </div>
                </Paper>
            )}
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    transactions: state.vinaWealth.pendingTransaction.list,
    fetching: state.vinaWealth.pendingTransaction.fetching,
});
const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(vinaWealthAction.getPendingTransaction(payload)),
});

export default memo(connect(mapState, mapDispatch)(VinaWealthTransactionBox));
