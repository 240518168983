import { Box, Icon } from "@material-ui/core";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import PageHeader from "@components/PageHeader";

import topUpAction from "@redux/actions/top-up";
import LspButton from "@components/LspButton";
import Content from "@components/Content";
import AmountInput from "./AmountInput";
import PhoneNumberDropdown from "./PhoneNumberDropdown";

const ExistingPhoneScreen = ({
    nextHandler,
    parentClasses,
    getContacts,
    switchAddPhone,
    resetAddNewPhoneInfo,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        getContacts();
    }, [getContacts]);

    useEffect(() => {
        resetAddNewPhoneInfo();
    }, [resetAddNewPhoneInfo]);

    return (
        <>
            <PageHeader>
                <div className={parentClasses.headerWrapper}>
                    <div>{t("topup_lb_title")} </div>
                    <div className={parentClasses.iconWrapper}>
                        <Icon
                            className={`font-icon icon-phone2 ${parentClasses.headerIcon}`}
                        />
                    </div>
                </div>
            </PageHeader>
            <Content size="sm">
                <div className={parentClasses.bodyWrapper}>
                    <Box textAlign="right" mb={3}>
                        <LspButton
                            variant="secondary"
                            onClick={switchAddPhone}
                            buttonProps={{
                                startIcon: (
                                    <Icon className="font-icon icon-plus" />
                                ),
                            }}
                        >
                            {t("topup_lb_new_mobile_number")}
                        </LspButton>
                    </Box>
                    <PhoneNumberDropdown />
                    <AmountInput />
                </div>
                <LspButton
                    type="button"
                    fullWidth
                    onClick={() => nextHandler()}
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    phoneConfigs: state.topUp.phoneConfigs,
});

const dispatchProps = (dispatch) => ({
    getContacts: () => dispatch(topUpAction.getContactList()),
    resetAddNewPhoneInfo: () => dispatch(topUpAction.resetAddNewPhoneInfo()),
});

export default connect(mapState, dispatchProps)(ExistingPhoneScreen);
