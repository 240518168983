import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import useWealth from "../useWealth";
import { WEALTH_PRODUCT_KEYS } from "../wealthConstants";

const useStyles = makeStyles((theme) => ({
    confirmationWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    confirmationContent: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    title: {
        width: "100%",
        textAlign: "center",
    },
    button: {
        width: "100%",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
        marginBottom: theme.spacing(4),
    },
}));
const WealthLoansConfirmation = ({ loading, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { getWealthIcon } = useWealth();
    const icon = getWealthIcon(WEALTH_PRODUCT_KEYS.LOANS);

    return (
        <Content size="xs">
            <div className={classes.confirmationWrapper}>
                <Icon className={`font-icon icon-${icon} ${classes.icon}`} />
                <LspTypography
                    variant="heading1"
                    color="primary"
                    className={classes.title}
                >
                    {t("wealth:VL00004")}
                </LspTypography>
                <LspTypography
                    variant="body1"
                    className={classes.confirmationContent}
                >
                    {t("loan_vpbank_confirmation_text")}
                </LspTypography>
                <div className={classes.button}>
                    <DoubleButtons
                        progressing={loading}
                        primaryButton={{
                            label: t("loan_vpbank_button_confirm"),
                            onClick: onSubmit,
                        }}
                        secondaryButton={{
                            label: t("lb_cancel"),
                            onClick: onCancel,
                        }}
                    />
                </div>
            </div>
        </Content>
    );
};

export default memo(WealthLoansConfirmation);
