import { CreditCardNavigationKey } from "@config/constants";
import usePathname from "@helpers/usePathname";
import { useMemo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ComingSoon from "@components/ComingSoon";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import LastStatement from "./LastStatement";
import CurrentStatement from "./CurrentStatement";

const parentName = "credit-card/statements";

const Statement = ({ sysParam }) => {
    const { generateChildPathname } = usePathname();
    const { t } = useTranslation("creditCard");

    const hasStatementAvailable = useMemo(() => {
        return sysParam.enableCreditCardStatement === "true";
    }, [sysParam]);

    const contentRoutes = useMemo(
        () => [
            {
                path: CreditCardNavigationKey.CurrentStatement,
                component: CurrentStatement,
                default: true,
            },
            {
                path: CreditCardNavigationKey.LastStatement,
                component: LastStatement,
            },
        ],
        []
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    return (
        <>
            {hasStatementAvailable && (
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            )}
            {!hasStatementAvailable && (
                <Content>
                    <ComingSoon content={t("creditCard:stm_coming_soon_msg")} />
                </Content>
            )}
        </>
    );
};

const stateProps = (state) => ({
    sysParam: state.systemParams.info.sysparams["1.0"],
});

export default connect(stateProps, null)(Statement);
