/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
import {
    FormControl,
    FormControlLabel,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { memo, useState, useMemo, Fragment } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import clsx from "clsx";
import PageHeaderBack from "@components/PageHeaderBack";

import {
    WEALTH_PAYMENT_METHODS,
    WEALTH_OCCUPATIONS,
    WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS,
    WEALTH_MARITAL_STATUS_KEYS,
    WEALTH_CREDIT_CARD_METHOD,
} from "../wealthConstants";
import useSharedClasses from "./wealthCreditCardSharedClasses";

const useStyles = makeStyles((theme) => ({
    description: {
        paddingLeft: theme.spacing(4),
    },
    manualDesc: {
        marginTop: -31,
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            marginTop: -33,
        },
    },
}));

const WealthCreditCardPaymentSelection = ({
    onApplyCreditCard,
    onBack,
    submitting,
    user,
    personalForm,
    addressForm,
    maritalStatusForm,
    financeForm,
    subProductKey,
    suggestLimitationForm,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useSharedClasses();

    const [paymentMethod, setPaymentMethod] = useState(null);

    const PAYMENT_METHOD_LIST = useMemo(
        () => [
            {
                id: 1,
                name: t("wealth:mc_v3_payment_auto_lb"),
                child: [
                    {
                        cfgKey: WEALTH_PAYMENT_METHODS.MINIMUM,
                        cfgValue: t("wealth:mc_v3_payment_auto_min_lb"),
                        description: t("wealth:mc_v3_payment_auto_min_msg"),
                    },
                    {
                        cfgKey: WEALTH_PAYMENT_METHODS.FULL,
                        cfgValue: t("wealth:mc_v3_payment_auto_full_lb"),
                        description: t("wealth:mc_v3_payment_auto_full_msg"),
                    },
                ],
            },
            {
                id: 2,
                name: t("wealth:mc_v3_payment_manual_lb"),
                child: [
                    {
                        cfgKey: WEALTH_PAYMENT_METHODS.MANUAL,
                        cfgValue: "",
                        description: t(
                            "wealth:mc_v3_payment_manual_msg"
                        ).replace("%@", t("wealth:mc_v3_timocare_no")),
                    },
                ],
            },
        ],
        [t]
    );

    const onSubmitForm = () => {
        const personalInfo = {
            fullName: user?.fullName,
            nationality: user?.nationality,
            documentNumber: personalForm?.documentId,
            dateIssued: personalForm?.dateOfIssue,
            placeIssued: personalForm?.placeOfIssue?.value,
            documentType: user?.docType,
            mobilePhone: personalForm?.phoneNumber,
            emailAddress: personalForm?.email,
        };

        const contactInfo = {
            permanentAddress: {
                address: addressForm?.permanentAddress,
                ward: addressForm?.selectedPermanentWard?.cfgKey,
                district: addressForm?.selectedPermanentDistrict?.cfgKey,
                province: addressForm?.selectedPermanentProvince?.cfgKey,
            },
            currentAddress: {
                address: addressForm?.addressName,
                ward: addressForm?.selectedCurrentWard?.cfgKey,
                district: addressForm?.selectedCurrentDistrict?.cfgKey,
                province: addressForm?.selectedCurrentProvince?.cfgKey,
            },
            curHousingSituation: parseInt(addressForm?.housingSituation),
            livingMonths: addressForm?.livingMonths,
        };

        const methodId = WEALTH_CREDIT_CARD_METHOD[subProductKey];

        const payload = {
            idInfo: personalInfo,
            contactInfo,
            method: methodId,
            paymentType: parseInt(paymentMethod),
            maritalStatus: parseInt(maritalStatusForm?.maritalStatus),
            isChangeInfo: personalForm?.isChangeInfo,
            creditLimitRef: suggestLimitationForm?.amountSuggestion,
            amount: suggestLimitationForm?.amountInput,
        };

        if (
            subProductKey !==
            WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD.toString()
        ) {
            let financialInfo = {
                occupation: parseInt(financeForm?.occupation),
                employeeStatus: financeForm?.employeeStatus.cfgKey,
                companyType: financeForm?.companyType.cfgKey,
                companyIndustry: financeForm?.companyIndustry.cfgKey,
                workingMonths: financeForm?.workingMonths,
                companyPhone: financeForm?.companyPhone,
                companyAddress: {
                    address: financeForm?.companyAddress,
                    ward: financeForm?.selectedCompanyWard.cfgKey,
                    district: financeForm?.selectedCompanyDistrict.cfgKey,
                    province: financeForm?.selectedCompanyProvince.cfgKey,
                },
                nameOfCompany: financeForm?.companyName,
            };

            if (
                financeForm?.occupation === WEALTH_OCCUPATIONS.OTHERS.toString()
            ) {
                financialInfo.occupationOther = financeForm?.occupationOther;
            }
            payload.financialInfo = financialInfo;
        }

        if (
            subProductKey ===
            WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD.toString()
        ) {
            payload.bankReference = suggestLimitationForm?.bankRef?.bankGroup;
        }

        let referenceInfo = [
            {
                ...maritalStatusForm?.referencePerson1,
                province: maritalStatusForm?.referencePerson1?.province?.cfgKey,
                district: maritalStatusForm?.referencePerson1?.district?.cfgKey,
                ward: maritalStatusForm?.referencePerson1?.ward?.cfgKey,
            },
        ];

        if (
            maritalStatusForm?.maritalStatus ===
            WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()
        ) {
            payload.spouseInfo = {
                refFullName: maritalStatusForm?.spouseInfo?.fullName,
                refMobilePhone: maritalStatusForm?.spouseInfo?.phoneNumber,
            };
        } else {
            referenceInfo.push({
                ...maritalStatusForm?.referencePerson2,
                province: maritalStatusForm?.referencePerson2?.province?.cfgKey,
                district: maritalStatusForm?.referencePerson2?.district?.cfgKey,
                ward: maritalStatusForm?.referencePerson2?.ward?.cfgKey,
            });
        }

        payload.referenceInfo = referenceInfo;

        if (
            maritalStatusForm?.maritalStatus ===
            WEALTH_MARITAL_STATUS_KEYS.OTHERS.toString()
        ) {
            payload.maritalDifferent = maritalStatusForm?.maritalStatusOther;
        }

        onApplyCreditCard(payload);
    };

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                {t("wealth:mc_v3_per_inf_title")}
            </PageHeaderBack>
            <Paper>
                {PAYMENT_METHOD_LIST?.map((method) => {
                    return (
                        <Fragment key={`method-${method.id}`}>
                            <div className={sharedClasses.formHeader}>
                                <LspTypography variant="heading3" color="grey">
                                    {method.name}
                                </LspTypography>
                            </div>
                            <div className={sharedClasses.radioWrapper}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="occupation"
                                        name="occupation"
                                    >
                                        {method?.child?.map((item) => {
                                            return (
                                                <Fragment key={item?.cfgKey}>
                                                    <FormControlLabel
                                                        value={item?.cfgKey}
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                checked={
                                                                    paymentMethod ===
                                                                    item?.cfgKey?.toString()
                                                                }
                                                                onChange={(e) =>
                                                                    setPaymentMethod(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={item?.cfgValue}
                                                    />
                                                    <LspTypography
                                                        variant="body2"
                                                        className={clsx(
                                                            classes.description,
                                                            {
                                                                [classes.manualDesc]:
                                                                    item?.cfgValue ===
                                                                    "",
                                                            }
                                                        )}
                                                        onClick={
                                                            item?.cfgValue ===
                                                            ""
                                                                ? () =>
                                                                      setPaymentMethod(
                                                                          item?.cfgKey.toString()
                                                                      )
                                                                : null
                                                        }
                                                    >
                                                        {item?.description}
                                                    </LspTypography>
                                                </Fragment>
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Fragment>
                    );
                })}
            </Paper>
            <LspButton
                className={sharedClasses.button}
                fullWidth
                onClick={onSubmitForm}
                disabled={!paymentMethod}
                progressing={submitting}
            >
                {t("wealth:mc_v3_btn_next")}
            </LspButton>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    suggestLimitationForm: state.wealthCreditCard.suggestLimitationForm,
    personalForm: state.wealthCreditCard.personalForm,
    addressForm: state.wealthCreditCard.addressForm,
    maritalStatusForm: state.wealthCreditCard.maritalStatusForm,
    financeForm: state.wealthCreditCard.financeForm,
    paymentMethod: state.wealthCreditCard.paymentMethod,
    subProductKey: state.wealthCreditCard.subProductKey,
});

export default memo(connect(mapState)(WealthCreditCardPaymentSelection));
