import { CircularProgress, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LspButton from "@components/LspButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { BANK_INFO, REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import moveMoneyService from "@services/move-money";
import { isFunction } from "lodash";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import LspBankList from "@components/LspBankList";
import { connect } from "react-redux";

const ClaimMoneyBankDetail = ({
    classes,
    hashedPassword,
    token,
    paoType,
    refNo,
    onResponseHandler,
    otpSuccessToken,
    bankList,
}) => {
    const { t, i18n } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const initErrors = useMemo(
        () => ({
            selectedBank: null,
            // province: null,
            // branch: null,
            ownerName: null,
            accountNumber: null,
        }),
        []
    );

    const MAX_BANK_ACCOUNT_REMAINING_ATTEMPTS = 5;
    const [errors, setErrors] = useState(initErrors);
    const clearErrors = useCallback(
        (field) => {
            if (field) {
                setErrors((prev) => ({ ...prev, [field]: initErrors[field] }));
            } else {
                setErrors(initErrors);
            }
        },
        [initErrors]
    );

    const [bankAccountFetching, setBankAccountFetching] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // const bankAccountFetching = useRef(false);
    const previousAccountNumber = useRef("");
    const newToken = useRef(token);
    const newRefNo = useRef(refNo);
    // const submitting = useRef(false);

    const [selectedBank, setSelectedBank] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [ownerName, setOwnerName] = useState("");
    // const selectedBankBranchList = useRef([]);
    // const [
    //     selectedBankProvinceBranchList,
    //     setSelectedBankProvinceBranchList,
    // ] = useState([]);
    // const [selectedBankProvinceList, setSelectedBankProvinceList] = useState(
    //     []
    // );
    // const [branch, setBranch] = useState(null);
    // const [province, setProvince] = useState(null);

    const memorizedBankList = useMemo(() => {
        const tmpBanks = bankList.filter(
            (item) => item.realTimeTransfer === "Y"
        );
        const defaultBank2 = bankList.find(
            (item) => item?.bankId === BANK_INFO.DEFAULT_BANK_2_ID.toString()
        );

        const internalBank = {
            ...defaultBank2,
            bankName: BANK_INFO.DEFAULT_BANK_NAME_1,
            logo: t("payAnyone:logo_bank_timo"),
        };
        return [internalBank, ...tmpBanks];
    }, [t]);

    const onBankChange = useCallback(
        ({ value }) => {
            clearErrors();
            setSelectedBank(value);
        },
        [clearErrors]
    );

    // const onBankChange = useCallback(
    //     async ({ value, autofill }) => {
    //         if (unmounted.current) {
    //             return;
    //         }
    //         const filteredBranchList = value
    //             ? branchList.filter((b) => b.bankId === value.bankId)
    //             : [];

    //         const filteredProvinceIdList = uniqBy(
    //             filteredBranchList,
    //             "provinceId"
    //         ).map((p) => p.provinceId);

    //         const filteredProvinceList = filter(provinceList, (p) =>
    //             filteredProvinceIdList.includes(p.provinceId)
    //         );

    //         clearErrors();

    //         selectedBankBranchList.current = filteredBranchList;
    //         setSelectedBankProvinceList(filteredProvinceList);
    //         setSelectedBank(value);
    //         setProvince(
    //             autofill?.provinceId
    //                 ? provinceList.find(
    //                       (p) => p.provinceId === autofill.provinceId
    //                   )
    //                 : null
    //         );
    //         setBranch(
    //             autofill?.branchId
    //                 ? branchList.find((b) => b.branchId === autofill.branchId)
    //                 : null
    //         );
    //     },
    //     [clearErrors]
    // );

    // const onProvinceChange = useCallback((e, value) => {
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         province: null,
    //         branch: null,
    //     }));
    //     setBranch(null);
    //     setProvince(value);
    // }, []);

    // const onBranchChange = useCallback((e, value) => {
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         branch: null,
    //     }));
    //     setBranch(value);
    // }, []);

    // const onBranchSelectionFocus = useCallback(() => {
    //     const filteredBranchList = province
    //         ? selectedBankBranchList.current.filter(
    //               (b) => b.provinceId === province.provinceId
    //           )
    //         : [];
    //     setSelectedBankProvinceBranchList(filteredBranchList);
    // }, [province]);

    const validateFormData = useCallback(() => {
        const formErrors = {};

        if (!selectedBank) {
            formErrors.selectedBank = t("msg_we_need_this");
        }
        if (!accountNumber) {
            formErrors.accountNumber = t("msg_we_need_this");
        }
        if (!ownerName) {
            formErrors.ownerName = t("msg_we_need_this");
        }

        setErrors(formErrors);
        const isValid = Object.keys(formErrors).length <= 0;
        return isValid;
    }, [t, selectedBank, accountNumber, ownerName]);

    const onSubmitHandler = useCallback(async () => {
        if (!validateFormData || submitting) {
            return;
        }
        setSubmitting(true);

        const payload = {
            bankId: selectedBank.bankId,
            bankName: selectedBank.bankName,
            accNumber: accountNumber,
            accName: ownerName,
            secretCode: hashedPassword,
            token: newToken.current,
            paoType,
            lang: i18n.language,
            dontCheckAccName: true, // skip checking account name when fast transfer is available
        };

        if (otpSuccessToken) {
            payload.otpSuccessToken = otpSuccessToken;
        }

        const response = await moveMoneyService.claimMoneyRequest(payload);

        setSubmitting(false);

        if (unmounted.current) {
            return;
        }

        const { code, data, secData } = response.data;

        if (secData?.token) {
            newToken.current = secData?.token;
        }

        if (data?.refNo) {
            newRefNo.current = data?.refNo;
        }
        switch (code) {
            case RESPONSE_CODE.WRONG_INFO: // fastTransferNotAvailableWarning
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("payAnyone:pao_to_bank_error_popup_title"),
                        translatedContent: t(
                            "payAnyone:pao_to_bank_error_popup_content"
                        ),
                        button: t("payment_request_btn_again"),
                    },
                });
                break;
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
            case RESPONSE_CODE.EKYC_REACH_LIMIT:
            case RESPONSE_CODE.MONTHLY_LIMIT_FOREIGNER:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("mc_lb_title_notice"),
                        translatedContent: t(
                            "payAnyone:popup_sender_exceed_daily_limit"
                        ),
                        button: t("lb_btn_ok_got_it"),
                    },
                });
                break;
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.WRONG_PASSWORD_MANY_TIMES:
            case RESPONSE_CODE.DESTINATION_INVALID:
            default:
                if (isFunction(onResponseHandler)) {
                    onResponseHandler({
                        code,
                        status: data?.status,
                    });
                }
                break;
        }

        // if (selectedBank.bankId !== "0") {
        //     payload.provinceId = province?.provinceId;
        //     payload.branchId = branch?.branchId;
        // }
    }, [
        validateFormData,
        selectedBank,
        accountNumber,
        ownerName,
        hashedPassword,
        i18n.language,
        paoType,
        newToken,
        otpSuccessToken,
        t,
        onResponseHandler,
        submitting,
    ]);

    const onChangeAccountNumberHandler = (e) => {
        const accountNum = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER,
            text: e.target.value,
        });

        setAccountNumber(accountNum);
        if (accountNum) {
            clearErrors("accountNumber");
        }
    };

    const onChangeOwnerNameHandler = (e) => {
        const name = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.OWNER_NAME,
            text: e.target.value,
        });

        setOwnerName(name);
        if (name) {
            clearErrors("ownerName");
        }
    };

    const getAccountInfo = useCallback(async () => {
        if (
            bankAccountFetching ||
            !accountNumber ||
            !selectedBank ||
            previousAccountNumber.current === accountNumber
        ) {
            return;
        }

        setBankAccountFetching(true);
        previousAccountNumber.current = accountNumber;

        const payload = {
            accountNumber,
            bankId: selectedBank.bankId,
            paoType,
            token: newToken.current,
        };

        // setTimeout(() => {
        //     setBankAccountFetching(false);
        // }, 2000);

        // return;

        const response = await moveMoneyService.getAccountInfoPayAnyone(
            payload
        );

        setBankAccountFetching(false);

        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                const remainingAttempts =
                    data && data.remainingAttempts
                        ? data.remainingAttempts
                        : MAX_BANK_ACCOUNT_REMAINING_ATTEMPTS;
                setOwnerName(data?.accName || "");

                if (data?.accName && data?.accName !== "") {
                    return;
                }

                if (remainingAttempts > 2) {
                    setErrors((prev) => ({
                        ...prev,
                        accountNumber: t("payAnyone:pao_incorrect_bankaccount"),
                    }));
                } else {
                    const key = t("payAnyone:pao_attempt_remain");

                    setErrors((prev) => ({
                        ...prev,
                        accountNumber: key.replace("%@", remainingAttempts),
                    }));
                }
                break;
            case RESPONSE_CODE.WRONG_INFO: // fastTransferNotAvailableWarning
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("payAnyone:pao_to_bank_error_popup_title"),
                        translatedContent: t(
                            "payAnyone:pao_to_bank_error_popup_content"
                        ),
                        button: t("payment_request_btn_again"),
                    },
                });
                break;
            case RESPONSE_CODE.DESTINATION_INVALID:
                if (isFunction(onResponseHandler)) {
                    onResponseHandler({
                        code: RESPONSE_CODE.DESTINATION_INVALID,
                    });
                }
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: code,
                });
                break;
        }
    }, [
        selectedBank,
        accountNumber,
        bankAccountFetching,
        paoType,
        newToken,
        onResponseHandler,
        t,
    ]);

    return (
        <div>
            <div className={classes.title}>
                {t("payAnyone:provide_bank_title")}
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitHandler();
                }}
                style={{ marginTop: 24 }}
            >
                <LspBankList
                    disableFastTransfer
                    options={memorizedBankList}
                    getOptionLabel={(option) => option?.bankName || ""}
                    value={selectedBank}
                    onChange={(_, value) => onBankChange({ value })}
                    renderInput={(params) => {
                        const inputProps = params.InputProps;
                        return (
                            <LspTextField
                                {...params}
                                label={t("payee_bank_name_label")}
                                error={!!errors.selectedBank}
                                helperText={errors.selectedBank || " "}
                                InputProps={inputProps}
                            />
                        );
                    }}
                />
                <LspTextField
                    label={t("lb_account_number")}
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber || " "}
                    onChange={onChangeAccountNumberHandler}
                    value={accountNumber}
                    onBlur={getAccountInfo}
                    InputProps={{
                        endAdornment: bankAccountFetching ? (
                            <InputAdornment position="end">
                                <CircularProgress size={16} />
                            </InputAdornment>
                        ) : null,
                    }}
                    disabled={!!bankAccountFetching}
                />
                <LspTextField
                    label={t("payee_account_owner_name_label")}
                    error={!!errors.ownerName}
                    helperText={errors.ownerName || " "}
                    onChange={onChangeOwnerNameHandler}
                    value={ownerName}
                    disabled
                />

                {/* {selectedBank && selectedBank?.bankId !== "0" && (
                    <>
                        <Autocomplete
                            options={selectedBankProvinceList}
                            getOptionLabel={(option) =>
                                option?.provinceName || ""
                            }
                            value={province}
                            onChange={onProvinceChange}
                            renderInput={(params) => (
                                <LspTextField
                                    {...params}
                                    label={t("payee_bank_province_label")}
                                    error={!!errors.province}
                                    helperText={errors.province || " "}
                                />
                            )}
                        />

                        <Autocomplete
                            options={selectedBankProvinceBranchList}
                            getOptionLabel={(option) =>
                                option?.branchName || ""
                            }
                            value={branch}
                            onChange={onBranchChange}
                            onFocus={onBranchSelectionFocus}
                            renderInput={(params) => (
                                <LspTextField
                                    {...params}
                                    label={t("payee_bank_branch_label")}
                                    error={!!errors.branch}
                                    helperText={errors.branch || " "}
                                />
                            )}
                        />
                    </>
                )} */}

                <LspButton
                    type="submit"
                    fullWidth
                    progressing={submitting}
                    disabled={
                        submitting ||
                        !selectedBank ||
                        !accountNumber ||
                        !ownerName
                    }
                >
                    {t("lb_submit")}
                </LspButton>
            </form>
        </div>
    );
};

const mapState = (state) => ({
    bankList: state.bank.bankList.data,
});

export default connect(mapState)(ClaimMoneyBankDetail);
