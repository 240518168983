import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "@assets/images/vinacapital@2x.png";
import { ArrowRightIcon } from "@components/LspIcon";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    selectionWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    logoWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    title: {
        fontSize: 20,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600,
    },
    logo: {
        width: 160,
    },
    selection: {
        listStyle: "none",
        paddingLeft: 0,
    },
    selectionItem: {
        boxShadow: theme.shadows[3],
        background: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[0],
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(16),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1.5),
        minHeight: 80,
        cursor: "pointer",
        "& > span": {
            textAlign: "center",
            flex: 1,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    link: {
        fontSize: 14,
        color: theme.palette.neutral.black,
        textDecoration: "underline",
    },
}));

const VinaWealthSelection = ({ onClickItem }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const learnMoreClick = () => {
        const path = t("wealth:vc3_learnmore_vc_link");
        window.open(path, "_blank");
    };

    return (
        <Content size="xs">
            <div className={classes.selectionWrapper}>
                <div className={classes.title}>
                    {t("wealth:vc3_main_tagline")}
                </div>
                <div className={classes.logoWrapper}>
                    <LspTypography>
                        {t("wealth:vinawealth2_onboard_sponsor")}
                    </LspTypography>
                    <img src={logo} alt="" className={classes.logo} />
                </div>
                <ul className={classes.selection}>
                    <li
                        className={classes.selectionItem}
                        onClick={() =>
                            onClickItem(VINA_WEALTH_STEPS.BEFORE_YOU_START)
                        }
                    >
                        <span>{t("wealth:vc3_db_main_button")}</span>
                        <ArrowRightIcon />
                    </li>
                    <li
                        className={classes.selectionItem}
                        onClick={() =>
                            onClickItem(VINA_WEALTH_STEPS.LINK_ACCOUNT)
                        }
                    >
                        <span>{t("wealth:vc3_db_sub_button")}</span>
                        <ArrowRightIcon />
                    </li>
                </ul>
                <ButtonBase className={classes.link} onClick={learnMoreClick}>
                    {t("wealth:vc3_db_link")}
                </ButtonBase>
            </div>
        </Content>
    );
};

export default memo(VinaWealthSelection);
