import { Box, IconButton, makeStyles, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useRef, useState, useEffect, useMemo, forwardRef } from "react";
import useProductTourStorage from "@helpers/useProductTourStorage";

import { CloseIcon } from "./LspIcon";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    introWrapper: {
        background: theme.palette.black.main,
        maxWidth: 350,
        opacity: 0.8,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        zIndex: 1,
        position: "relative",
        marginTop: "10px",
        "&::before": {
            backgroundColor: theme.palette.black.main,
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            left: "calc(85% - 6px)",
        },
    },
    introCloseBtn: {
        color: theme.palette.white.main,
        padding: 0,
        position: "absolute",
        top: 6,
        right: 6,
    },
    popover: {
        background: "rgba(0,0,0,0.67)",
    },
}));

/**
 * @typedef IntroPopoverProps
 * @type {object}
 * @property {string} [content]
 * @property {boolean} [open]
 */

/**
 * @param {IntroPopoverProps} props
 */

const IntroPopover = forwardRef(({ content, open, id }, ref) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [popoverEl, setPopoverEl] = useState(null);
    const popoverLoaded = useRef(false);
    const { getConfigStatus, updateConfigStatus } = useProductTourStorage();

    useEffect(() => {
        const isOpen = getConfigStatus(id);

        if (popoverLoaded.current || !isOpen) return;

        const timer = setTimeout(() => {
            setPopoverEl(ref.current);
            popoverLoaded.current = true;
            updateConfigStatus(id);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [ref, id, getConfigStatus, updateConfigStatus]);

    const onClosePopover = (event) => {
        setPopoverEl((el) => (el ? null : event.currentTarget));
    };

    const openPopover = useMemo(() => Boolean(popoverEl) && open, [
        popoverEl,
        open,
    ]);

    return (
        <Popover
            open={openPopover}
            anchorEl={popoverEl}
            anchorOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    marginTop: 16,
                    background: "transparent",
                },
            }}
            classes={{
                root: classes.popover,
            }}
        >
            <Box className={classes.introWrapper}>
                <IconButton
                    onClick={onClosePopover}
                    className={classes.introCloseBtn}
                >
                    <CloseIcon />
                </IconButton>
                <LspTypography color="white" variant="body2">
                    {t(content)}
                </LspTypography>
            </Box>
        </Popover>
    );
});

IntroPopover.propTypes = {
    content: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.string,
};

IntroPopover.defaultProps = {
    content: "",
    open: false,
    id: "",
};

IntroPopover.displayName = "IntroPopover";

export default IntroPopover;
