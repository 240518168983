/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Paper, Box, withStyles } from "@material-ui/core";
import { memo } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import useNumber from "@helpers/useNumber";
import { INSTANT_COVERAGE_STEPS } from "@containers/Wealth/wealthConstants";
import CustomizedAccordions from "../../LifeInsuranceInstant/Accordion";

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3.75),
        display: "block",
        "& > ul:first-child": {
            listStyleType: "none",
            padding: 0,
            " & > li": {
                margin: theme.spacing(1.25, 0),
                "& > span:first-child": {
                    borderRadius: "50%",
                    background: theme.palette.primary.main,
                    color: theme.palette.neutral.white,
                    display: "inline-block",
                    textAlign: "center",
                    marginRight: theme.spacing(1),
                    padding: "1px 7px",
                },
            },
        },
    },
}))(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,

        fontSize: theme.typography.pxToRem(35),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },
    iconPackage: {
        color: theme.palette.neutral.white,
        fontSize: theme.typography.pxToRem(35),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },
    iconBack: {
        cursor: "pointer",
    },

    list: {
        listStyleType: "none",
        padding: "0",
    },
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    boxTitle: {
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },
    boxBody: {
        "& > ul > li": {
            listStyleType: "none",
            padding: theme.spacing(0, 0, 1.5, 3),
            position: "relative",
            "&::before": {
                position: "absolute",
                content: "''",
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.main,
                left: 0,
                top: 7,
            },
        },
    },
    boxBodyItem: {
        marginTop: theme.spacing(1.25),
        position: "relative",
        "&::before": {
            position: "absolute",
            content: "''",
            width: 5,
            height: 5,
            borderRadius: "50%",
            backgroundColor: theme.palette.neutral.black,
            left: -13,
            top: 7,
        },
    },
    panelHeader: {
        " & > p:last-child": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        marginBottom: theme.spacing(3.75),
    },
    boxTitleAmount: {
        flex: 1,
        textAlign: "right",
    },

    boxTitlePackage: {
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },
}));

const LeftPage = ({
    leftDetail,
    prokeySelected,
    stepBack,
    showBtnMobile,
    stepForward,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { formatNumber } = useNumber();

    const goBack = () => {
        stepBack();
    };

    return (
        <Content>
            <div>
                <Icon
                    onClick={goBack}
                    className={`font-icon icon-longarrowleft ${classes.iconBack}`}
                />

                <div>
                    {!prokeySelected && (
                        <>
                            <div className={classes.panelHeader}>
                                <LspTypography variant="title1" color="primary">
                                    {t("wealth:SI00044")}
                                </LspTypography>
                                <LspTypography variant="title2" color="white">
                                    {`${t("wealth:SI00045")} ${t(
                                        "wealth:SI00046"
                                    )}`}
                                </LspTypography>
                            </div>
                            <div className={classes.list}>
                                <Paper>
                                    <div className={classes.boxTitle}>
                                        <Icon
                                            className={`font-icon icon-personalaccident ${classes.icon}`}
                                        />
                                        <div>
                                            <LspTypography
                                                variant="heading1"
                                                color="primary"
                                            >
                                                {t(
                                                    "wealth:SI00005_slinstantcoverage"
                                                )}
                                            </LspTypography>

                                            <LspTypography
                                                variant="body2"
                                                color="black"
                                            >
                                                {t(
                                                    "wealth:SI00008_slinstantcoverage"
                                                )}
                                            </LspTypography>
                                        </div>
                                    </div>
                                    <div className={classes.boxBody}>
                                        <ul>
                                            {t(
                                                "wealth:SI00006_slinstantcoverage",
                                                {
                                                    returnObjects: true,
                                                    defaultValue: "",
                                                }
                                            ).map((item, key) => (
                                                <li key={key}>
                                                    <div>{item}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <Accordion square>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <LspTypography
                                                variant="body2"
                                                color="grey"
                                            >
                                                {t("wealth:SI00011")}
                                            </LspTypography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                {t("wealth:SI00016", {
                                                    returnObjects: true,
                                                    defaultValue: "",
                                                }).map((item, key) => (
                                                    <li key={key}>
                                                        <span>{key + 1}</span>
                                                        <Interweave
                                                            content={item}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                </Paper>
                            </div>
                        </>
                    )}

                    <div className={classes.list}>
                        {leftDetail?.map((row, index) => {
                            return (
                                prokeySelected === row.prokey && (
                                    <div key={index}>
                                        <Paper>
                                            <div
                                                className={
                                                    classes.boxTitlePackage
                                                }
                                            >
                                                <Icon
                                                    className={`font-icon icon-${row.iconHeader} ${classes.iconPackage}`}
                                                />
                                                <div>
                                                    <LspTypography
                                                        variant="heading1"
                                                        color="white"
                                                    >
                                                        {t(
                                                            "wealth:SI00023_slinstantcoverage"
                                                        )}
                                                    </LspTypography>
                                                    <LspTypography
                                                        variant="body2"
                                                        color="white"
                                                    >
                                                        {row.descHeader}
                                                    </LspTypography>
                                                </div>
                                                <div
                                                    className={
                                                        classes.boxTitleAmount
                                                    }
                                                >
                                                    <LspTypography
                                                        variant="heading2"
                                                        color="white"
                                                    >
                                                        {row.txtYouPayHeader}
                                                    </LspTypography>
                                                    <LspTypography
                                                        variant="title2"
                                                        color="white"
                                                    >
                                                        {formatNumber(
                                                            row.valYouPayHeader
                                                        )}
                                                    </LspTypography>
                                                </div>
                                            </div>

                                            <CustomizedAccordions
                                                leftDetailPackage={row.content}
                                            />
                                        </Paper>
                                    </div>
                                )
                            );
                        })}
                    </div>
                    {showBtnMobile && (
                        <Box mt={3}>
                            <LspButton
                                type="submit"
                                fullWidth
                                onClick={() =>
                                    stepForward(
                                        INSTANT_COVERAGE_STEPS.FORM_INFO
                                    )
                                }
                            >
                                {t("lb_next")}
                            </LspButton>
                        </Box>
                    )}
                </div>
            </div>
        </Content>
    );
};

export default memo(LeftPage);
