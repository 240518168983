import { LANGUAGE_OPTIONS } from "@config/constants";
import api from "../config/api";
import ApiCancelToken from "../helpers/controllers/ApiCancelToken";

const getProfile = async () => {
    return await api.get("user/getProfile", null);
};

const getBankInfo = async () => {
    return await api.get("user/bankinfo", null);
};

const getServerTime = async () => {
    return await api.get("user/serverTime", null);
};

const switchLanguage = async ({ lang = LANGUAGE_OPTIONS.VN }) => {
    return await api.post("user/setting/lang", { lang });
};

const updatePassword = async ({
    currentPassword,
    newPassword,
    confirmNewPassword,
}) => {
    const payload = {
        password: currentPassword,
        newPassword,
        retypedPassword: confirmNewPassword,
    };
    return await api.put("/user/profile/password", payload, {
        bypassErrorHandler: true,
    });
};

const commitOtpUpdatePassword = async (payload) => {
    return await api.put("user/profile/password/commit", payload);
};
const getPayeeList = async () => {
    return await api.get("user/payee/list", { group: 1 });
};

const verifyEmailForEkycUser = async () => {
    return await api.post(
        "/precus/ekyc/email/resend-verification",
        {},
        {
            bypassErrorHandler: true,
            cancelToken: ApiCancelToken.cancelToken.token,
        }
    );
};

const updateSetting = async (payload) => {
    return await api.post("/user/setting/update", payload);
};

const getDataList = async ({ language = LANGUAGE_OPTIONS.EN, ...payload }) => {
    return await api.post("data/sysConfig/list", {
        ...payload,
        lang: language,
    });
};

const getJobList = async ({ language = LANGUAGE_OPTIONS.EN, ...payload }) => {
    return await api.post("data/occupation/list", {
        ...payload,
        lang: language,
    });
};

const uploadAvatar = async (payload) => {
    return await api.post("/user/profile/uploadAvatar", payload);
};

const updateProfile = async (payload) => {
    return await api.post("user/updateProfile", payload);
};

const getFeatureState = async () => {
    return await api.get("user/features/states", null);
};

const sendUserFeedback = async (payload) => {
    return await api.post("user/feedback", payload);
};

const getHangoutService = async (payload) => {
    return await api.post("v3/user/location/list", payload);
};

const getGoalSaveSuggest = async (payload) => {
    return await api.post("user/account/goalSave/suggest", payload);
};

const addNewGoalSave = async (payload) => {
    return await api.post("user/account/goalSave/plan/add", payload);
};

const getListReward = async () => {
    return await api.get("user/evouchers/rewards", null);
};

const trackUserGetVoucher = async (voucherId) => {
    return await api.get(`user/evouchers/rewards/${voucherId}/claim`, null);
};

const sendVoucherCodeByEmail = async (voucherId) => {
    return await api.get(`user/evouchers/rewards/${voucherId}/sendEmail`, null);
};

const reissueCheck = async () => {
    return await api.get(`/v3/user/chipcard/reissue/check`);
};

const changeDefaultAliasName = async (payload) => {
    return await api.post("user/alias/update-default-name", payload);
};

const aliasSetting = async () => {
    return await api.get("user/alias/setting/get");
};

const toggleAlias = async (payload) => {
    return await api.post("user/alias/setting/update", payload);
};

const aliasFee = async (payload) => {
    return await api.get("user/alias/nickname/fee", payload);
};

const alias = async (payload) => {
    return await api.post("user/alias", payload, {
        bypassErrorHandler: true,
    });
};

const submitAlias = async (payload) => {
    return await api.post("user/alias/submit", payload, {
        bypassErrorHandler: true,
    });
};

const commitAlias = async (payload) => {
    return await api.post("user/alias/commit", payload, {
        bypassErrorHandler: true,
    });
};

const submitiOTPAlias = async (payload) => {
    return await api.post("/user/alias/setting/on/submit", payload);
};

const commitiOTPAlias = async (payload) => {
    return await api.post("/user/alias/setting/on/commit", payload);
};

const userService = {
    getProfile,
    getBankInfo,
    getServerTime,
    switchLanguage,
    updatePassword,
    commitOtpUpdatePassword,
    getPayeeList,
    verifyEmailForEkycUser,
    updateSetting,
    getDataList,
    uploadAvatar,
    updateProfile,
    getFeatureState,
    sendUserFeedback,
    getHangoutService,
    getGoalSaveSuggest,
    addNewGoalSave,
    getListReward,
    trackUserGetVoucher,
    sendVoucherCodeByEmail,
    reissueCheck,
    modifyAliasName: changeDefaultAliasName,
    aliasSetting,
    toggleAlias,
    aliasFee,
    alias,
    submitAlias,
    commitAlias,
    getJobList,
    submitiOTPAlias,
    commitiOTPAlias,
};

export default userService;
