import clsx from "clsx";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import { useMemo, Fragment, useEffect } from "react";
import LspTypography from "@components/LspTypography";
import { Link } from "react-router-dom";
import LspButton from "@components/LspButton";
import { INSTALLMENT_STATUSES } from "./constant";
import InstallmentOthersStatusBody from "./InstallmentOthersStatusBody";
import InstallmentCompletedStatusBody from "./InstallmentCompletedStatusBody";
import InstallmentDetailLoading from "./InstallmentDetailLoading";

const InstallmentStatus = ({
    detail,
    openReceipt,
    className,
    actionLoading,
    loading,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const status = useMemo(() => detail.installStatus, [detail.installStatus]);

    return (
        <>
            {loading && <InstallmentDetailLoading className={className} />}
            {!loading && (
                <>
                    <div className={className.box}>
                        <div className={className.headerWrapper}>
                            <div
                                className={`${className.headerStatus} ${detail.FEStatus}`}
                            >
                                <LspTypography variant="heading2" color="white">
                                    {detail.FELabelStatus}
                                </LspTypography>
                            </div>
                            <div className={className.headerInfo}>
                                <LspTypography
                                    variant="title2"
                                    className={className.headerTitle}
                                >
                                    {detail.title || t("tr_lb_not_available")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    className={className.label}
                                >
                                    {t("creditCard:IN00015")}
                                </LspTypography>
                                <LspTypography variant="title1">
                                    {formatNumber(
                                        detail.currentOutstandingAmount
                                    ) || t("tr_lb_not_available")}
                                </LspTypography>
                                {status !== INSTALLMENT_STATUSES.COMPLETED && (
                                    <div className={className.infoLine}>
                                        <LspTypography
                                            variant="body2"
                                            className={className.label}
                                        >
                                            {t("creditCard:IN00008")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body2"
                                            className={className.value}
                                        >
                                            {`${
                                                detail?.remainMonth?.toString() ||
                                                ""
                                            }/${detail.term || ""} ${t(
                                                "td_lb_gl_months"
                                            )}`}
                                        </LspTypography>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={className.bodyWrapper}>
                            {status !== INSTALLMENT_STATUSES.COMPLETED && (
                                <InstallmentOthersStatusBody
                                    detail={detail}
                                    classes={className}
                                />
                            )}

                            {status === INSTALLMENT_STATUSES.COMPLETED && (
                                <InstallmentCompletedStatusBody
                                    detail={detail}
                                    classes={className}
                                />
                            )}

                            <Divider className={className.divider} />
                            <Link to="/#" href="#" onClick={openReceipt}>
                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                    className={className.linkNavigation}
                                >
                                    {t("creditCard:view_ori_txn")}
                                </LspTypography>
                            </Link>
                        </div>
                    </div>
                    <div className={className.footer}>
                        {detail.FEActions.map((btn) => {
                            return (
                                <Fragment key={btn.id}>
                                    <LspButton
                                        className={clsx({
                                            [className.plainBtn]:
                                                btn.styles === "link",
                                        })}
                                        variant={
                                            btn.styles === "link"
                                                ? "plain"
                                                : "primary"
                                        }
                                        onClick={btn.onClick}
                                        fullWidth
                                        progressing={
                                            btn.isLoadingOption
                                                ? actionLoading
                                                : false
                                        }
                                    >
                                        {btn.label}
                                    </LspButton>
                                </Fragment>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default InstallmentStatus;
