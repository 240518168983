import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ColorBar from "@components/ColorBar";
import { Logo } from "@components/LspBranding";
import DownloadApp from "@components/DownloadApp";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        minHeight: "101vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    container: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    logoContainer: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },
    logo: {
        height: theme.spacing(5.5),
    },
    colorBarSuggestion: {
        width: "100%",
        position: "absolute",
        bottom: theme.spacing(0),
        left: theme.spacing(0),
    },
    suggestionDesc: {
        display: "block",
        textAlign: "center",
        marginBottom: theme.spacing(4),
        textDecoration: "underline",
        cursor: "pointer",
    },
    desc: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.neutral.grey1,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    suggestion: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
}));

const Suggestion = ({ bypassSuggestion }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClickSuggestion = () => {
        bypassSuggestion();
    };
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.formContainer}>
                    <div className={classes.suggestion}>
                        <div className={classes.logoContainer}>
                            <Logo className={classes.logo} />
                        </div>
                        <div className={classes.desc}>
                            {t("web_download_app_join_note")}
                        </div>
                        <DownloadApp />
                    </div>
                    <div className={classes.colorBarSuggestion}>
                        <a
                            className={classes.suggestionDesc}
                            onClick={() => onClickSuggestion()}
                        >
                            {t("device_warning_btn_link_continue")}
                        </a>
                        <ColorBar />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Suggestion;
