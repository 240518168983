import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import lixiActions from "@redux/actions/lixi";
import PageHeader from "@components/PageHeader";
import Themes from "./Themes";
import AmountList from "./AmountList";

const useStyles = makeStyles((theme) => ({
    themeSelectionContainer: {
        maxWidth: theme.typography.pxToRem(816),
        margin: "auto",
    },
}));

const LixiTheme = ({ backFunc, nextFunc, setSubmitInfo, theme, amount }) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [loading, setLoading] = useState(true);

    // const nextClickHandler = (amount, theme) => {
    //     if (!amount) return;
    //     if (!theme) return;
    // };

    return (
        <div className={classes.themeSelectionContainer}>
            <div>
                <PageHeader backFunc={backFunc}>
                    {t("lixi:send_lixi_title")}
                </PageHeader>
            </div>
            <Themes backFunc={backFunc} />
            <AmountList />
            <Button variant="container" color="primary" disabled={!amount}>
                {t("lb_next")}
            </Button>
        </div>
    );
};

const stateProps = (state) => ({
    theme: state.lixi.theme,
    amount: state.lixi.amount,
});

const dispatchProps = (dispatch) => ({
    setSubmitInfo: (payload) => dispatch(lixiActions.setSubmitInfo(payload)),
});

export default connect(stateProps, dispatchProps)(LixiTheme);
