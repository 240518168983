import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { format, parse } from "date-fns";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    billHeader: {
        position: "relative",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "row",
        fontStyle: "normal",
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
            2.75
        )}px ${theme.spacing(2)}px`,
        alignItems: "center",
    },
    billHeaderLeft: {
        flex: 1,
        textAlign: "left",
    },
    billHeaderTotal: {
        fontWeight: 700,
        marginBottom: theme.spacing(0.5),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    billHeaderDescription: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: "19px",
        letterSpacing: "0.5px",
        color: theme.palette.magicalGrey[400],
    },
    billHeaderRight: {},
    billHeaderRightAmount: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: "34px",
        letterSpacing: "0.3px",
    },
    skeletonLoading: {
        padding: theme.spacing(2),
    },
}));
const BillHeader = ({ splitBill, fetching }) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const { t } = useTranslation();

    const fetchingLayer = useMemo(
        () => (
            <>
                <div className={classes.skeletonLoading}>
                    <Skeleton
                        animation="wave"
                        height={15}
                        width="100%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={15}
                        width="100%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={15}
                        width="100%"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={15}
                        width="100%"
                        style={{ marginBottom: 6 }}
                    />
                </div>
            </>
        ),
        [classes]
    );

    const formatDate = (date) => {
        if (date) {
            const serverDate = parse(
                date,
                "dd/MM/yyyy HH:mm:ss xxxx",
                new Date()
            );
            return format(serverDate, "dd/MM/yyyy HH:mm");
        }
    };

    return (
        <>
            {fetching && fetchingLayer}
            {!fetching && (
                <div className={classes.billHeader}>
                    <div className={classes.billHeaderLeft}>
                        <div className={classes.billHeaderTotal}>
                            <LspTypography variant="heading3" color="black">
                                {t(
                                    "splitBill:sb_split_bill_title_total_amount"
                                )}
                            </LspTypography>
                        </div>
                        {splitBill?.refTxnCreatedAt && (
                            <div className={classes.billHeaderDescription}>
                                {formatDate(splitBill?.refTxnCreatedAt)}
                            </div>
                        )}
                        {splitBill?.refTxnDescription && (
                            <div className={classes.billHeaderDescription}>
                                {splitBill?.refTxnDescription}
                            </div>
                        )}
                    </div>
                    <div className={classes.billHeaderRight}>
                        <div className={classes.billHeaderRightAmount}>
                            <LspTypography variant="title2" color="black">
                                {formatNumber(splitBill?.totalAmount)}
                            </LspTypography>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const stateProps = (state) => ({
    splitBill: state.bill.splitBill.bill,
    fetching: state.bill.splitBill.fetching,
});

export default memo(connect(stateProps)(BillHeader));
