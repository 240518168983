import { memo, useCallback, useState, Fragment, useRef } from "react";
import {
    Typography,
    makeStyles,
    Icon,
    ButtonBase,
    useTheme,
    useMediaQuery,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isFunction } from "lodash";
import ContentWrapper from "./ContentWrapper";
import LspTypography from "./LspTypography";
import IntroPopover from "./IntroPopover";
import Content from "./Content";
import LspButton from "./LspButton";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        banner: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: theme.zIndex.appBar - 1,
            height: theme.spacing(15),
            textAlign: "center",
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${
                isDark
                    ? theme.palette.magicalGrey[500]
                    : theme.palette.magicalGrey[100]
            }`,
        },
        bannerTitle: {
            fontSize: theme.typography.pxToRem(24),
            fontWeight: "600",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
        },
        bannerSubtitle: {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.main,
        },
        bannerContent: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
        },
        bannerHelper: {
            position: "absolute",
            right: 0,
        },
        navbar: {
            top: theme.mixins.toolbar.minHeight,
            position: "sticky",
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            zIndex: theme.zIndex.appBar - 2,
            borderBottom: "none",
        },
        navbarItem: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: theme.spacing(1.5, 1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: grey[700],
            "&.selected": {
                color: theme.palette.primary.main,
            },
            "&:active": {
                opacity: theme.palette.opacity.active,
            },
            "& .MuiIcon-root, .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
            "&.Mui-disabled": {
                opacity: theme.palette.opacity.disabled,
            },
            "& .font-icon": {
                fontSize: theme.typography.pxToRem(24),
            },
        },
        listItemIcon: {
            minWidth: theme.spacing(5),
        },
    };
});

/**
 * @param {object} banner
 * @param {string} banner.title
 * @param {string} banner.subtitle
 * @param {bool} banner.disabledNavbarItem
 * @param {bool} banner.helper
 * @param {func} banner.onHelperChange
 */

const Banner = ({
    title,
    subtitle,
    navbarItems,
    disabledNavbarItem = false,
    helper,
    helperTitle,
    onHelperChange,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const { t } = useTranslation();
    const history = useHistory();

    const initRef = useRef();

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const onItemClick = useCallback(
        (path) => {
            if (path) {
                history.push(path);
            }
        },
        [history]
    );

    const handleHelper = () => {
        if (isFunction(onHelperChange)) {
            onHelperChange();
        }
    };

    return (
        <>
            <ContentWrapper className={classes.banner}>
                <Content>
                    <div className={classes.bannerContent}>
                        <LspTypography
                            variant="title2"
                            color="primary"
                            datatestid="homepageaccname"
                        >
                            {title}
                        </LspTypography>
                        <LspTypography
                            variant="body3"
                            color="grey"
                            component="div"
                            textCenter
                        >
                            {subtitle}
                        </LspTypography>
                        {helper && (
                            <div className={classes.bannerHelper}>
                                <LspButton
                                    variant="plain"
                                    size="small"
                                    onClick={handleHelper}
                                >
                                    {helperTitle}
                                </LspButton>
                            </div>
                        )}
                    </div>
                </Content>
            </ContentWrapper>

            {/* {!!navbarItems && <Divider />} */}
            {isMdUp && !!navbarItems && !disabledNavbarItem && (
                <ContentWrapper noPaddingY className={classes.navbar}>
                    {navbarItems.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                            <ButtonBase
                                disableRipple
                                key={item.title}
                                className={clsx(classes.navbarItem, {
                                    selected: item.selected,
                                })}
                                onClick={() => onItemClick(item?.path)}
                                ref={item?.intro ? initRef : null}
                            >
                                {!item?.isSVGIcon && (
                                    <Icon
                                        className={`font-icon icon-${item.icon} ${item?.iconClasses}`}
                                    />
                                )}
                                {item?.isSVGIcon && item.icon}
                                <Typography variant="subtitle2">
                                    {t(item.title)}
                                </Typography>
                            </ButtonBase>
                            {item?.intro && (
                                <IntroPopover
                                    content={item?.introContent}
                                    ref={initRef}
                                    open
                                    id={item?.id}
                                />
                            )}
                        </Fragment>
                    ))}
                </ContentWrapper>
            )}
            {!isMdUp && !!navbarItems && !disabledNavbarItem && (
                <>
                    <ContentWrapper noPaddingY className={classes.navbar}>
                        <ButtonBase
                            className={clsx(classes.navbarItem, "selected")}
                            onClick={openMenu}
                        >
                            <Icon
                                className={`font-icon icon-${
                                    navbarItems.find((item) => item.selected)
                                        ?.icon
                                }`}
                            />
                            <Typography variant="subtitle2">
                                {t(
                                    navbarItems.find((item) => item.selected)
                                        ?.title
                                )}
                            </Typography>
                            <KeyboardArrowDownIcon fontSize="small" />
                        </ButtonBase>
                    </ContentWrapper>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeMenu}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        getContentAnchorEl={null}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        {navbarItems.map((item) => (
                            <MenuItem
                                key={item.title}
                                selected={item.selected}
                                onClick={() => {
                                    onItemClick(item.path);
                                    closeMenu();
                                }}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    {!item?.isSVGIcon && (
                                        <Icon
                                            fontSize="small"
                                            className={`font-icon icon-${item.icon} ${item?.iconClasses}`}
                                        />
                                    )}
                                    {item?.isSVGIcon && item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            {t(item.title)}
                                        </Typography>
                                    }
                                />
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </>
    );
};

export default memo(Banner);
