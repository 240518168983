import { withStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";

export const LspDialog = withStyles((theme) => ({
    paper: {
        width: "100%",
        backgroundColor: "transparent",
        borderRadius: 8,
        margin: theme.spacing(2),
        boxShadow: "none",
        [theme.breakpoints.up("sm")]: {
            margin: "auto",
        },
    },
}))(Dialog);

export const LspDialogTitle = withStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: theme.shape.radiusLevels[0],
        borderTopLeftRadius: theme.shape.radiusLevels[0],
        "& h2": {
            color: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 600,
            textTransform: "uppercase",
            "& span": {
                fontSize: `${theme.typography.pxToRem(42)} !important`,
                display: "inline-block",
                // marginBottom: theme.spacing(1,
            },
        },
    },
}))(DialogTitle);

export const LspDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        background: theme.palette.background.paper,
        fontSize: theme.typography.pxToRem(14),
        textAlign: "center",
        color: theme.palette.primary.main,
    },
}))(DialogContent);

export const LspDialogActions = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 4, 4, 4),
        justifyContent: "center",
        background: theme.palette.background.paper,
        borderBottomRightRadius: theme.shape.radiusLevels[0],
        borderBottomLeftRadius: theme.shape.radiusLevels[0],
        "& > div:not(:first-child)": {
            marginLeft: theme.spacing(2),
        },
    },
}))(DialogActions);
