import { makeStyles, ButtonBase, Hidden, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { QuickCodeScreens } from "../../redux/actions/quick-code";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(7),
            paddingBottom: theme.spacing(7),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        padding: theme.spacing(2, 4),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "600",
        borderRadius: theme.shape.radiusLevels[0],
        textTransform: "uppercase",
    },
    icon: {
        fontSize: theme.typography.pxToRem(180),
    },
}));
const QuickCodeIntro = ({ parentClasses, nextHandler }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <div className={classes.wrapper}>
                <div className={parentClasses.content}>
                    <h3>{t("quick_code_title_start")}</h3>
                    <div>{t("quick_code_desc_start")}</div>
                    <ButtonBase
                        className={classes.button}
                        onClick={() =>
                            nextHandler(QuickCodeScreens.INPUT_NEW_QUICK_CODE)
                        }
                    >
                        {t("quick_code_button_get_start")}
                    </ButtonBase>
                </div>
                <Hidden smDown>
                    <Icon
                        className={`font-icon icon-enterquickcode ${classes.icon}`}
                    />
                </Hidden>
            </div>
        </>
    );
};

export default QuickCodeIntro;
