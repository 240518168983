import { FetchStatus } from "@config/constants";
import { MetricActionTypes } from "@redux/actions/metric";
import produce from "immer";

/* ------------- Initial State ------------- */
export const metricInitialState = {
    metric: {
        data: {},
        fetching: false,
        status: null,
    },
    metricList: {
        fetching: false,
        status: null,
        data: [],
        force: false,
    },
    metricConfig: {
        appSessionId: null,
    },
};

/* ------------- Reducer ------------- */
const metricReducer = (state = metricInitialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case MetricActionTypes.InitMetricRequest:
                draft.metric.fetching = true;
                draft.metric.status = FetchStatus.Pending;
                break;
            case MetricActionTypes.InitMetricSuccess:
                draft.metric.fetching = false;
                draft.metric.status = FetchStatus.Success;
                draft.metric.data = action.payload;
                break;
            case MetricActionTypes.InitMetricError:
                draft.metric.fetching = false;
                draft.metric.status = FetchStatus.Error;
                break;
            case MetricActionTypes.MetricRequest:
                draft.metricList.fetching = true;
                draft.metricList.status = FetchStatus.Pending;
                break;
            case MetricActionTypes.MetricRequestSuccess:
                draft.metricList.data = [];
                draft.metricList.fetching = false;
                draft.metricList.status = FetchStatus.Success;
                break;
            case MetricActionTypes.MetricRequestError:
                draft.metricList.fetching = false;
                draft.metricList.status = FetchStatus.Error;
                break;
            case MetricActionTypes.MetricRequestUpdate:
                const data = action.payload?.data;
                const detailData = data?.detail_data;
                const metricData = {
                    ...data,
                    detail_data: {
                        ...detailData,
                        app_session_id: data.app_session_id
                            ? data.app_session_id
                            : draft.metricConfig.appSessionId,
                        previous_event_id:
                            draft.metricConfig.previous_event_id ||
                            data.previous_event_id ||
                            "",
                    },
                    previous_event_name:
                        draft.metricConfig.previous_event_name ||
                        data.previous_event_name ||
                        "",
                    previous_screen_name:
                        draft.metricConfig.previous_screen_name ||
                        data.previous_screen_name ||
                        "",
                };

                draft.metricList.data.push(metricData);
                draft.metricList.force = action.payload?.force;
                draft.metricConfig = {
                    ...draft.metricConfig,
                    previous_event_id: metricData.event_id || "",
                    previous_event_name: metricData.metric_name || "",
                    previous_screen_name: metricData.screen_name || "",
                };
                break;
            case MetricActionTypes.MetricSession:
                draft.metricConfig = {
                    ...(draft.metricConfig || {}),
                    ...action.payload,
                };
                break;
            default:
                break;
        }
    });

export default metricReducer;
