import Content from "@components/Content";
import { useTranslation } from "react-i18next";
import {
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_TYPES,
} from "@containers/Wealth/wealthConstants";
import { memo } from "react";
import LspCongratulation from "@components/LspCongratulation";
import { isFunction } from "lodash";
import wealthService from "@services/wealth";

const VinaWealthForeignerWarning = ({ onComplete }) => {
    const { t } = useTranslation();
    const onCompleteHandler = () => {
        const payload = {
            productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
            type: WEALTH_VINA_TYPES.TALK_ADVISER,
        };
        wealthService.actionWealthSubProducts(payload);
        if (onComplete && isFunction(onComplete)) {
            onComplete();
        }
    };

    return (
        <Content size="xs">
            <LspCongratulation
                icon="like"
                title={t("wealth:vinawealth2_foreign_end")}
                contents={t("wealth:vinawealth2_foreign_end_mes")}
                button={{
                    label: t("wealth:vc_return_to_home"),
                    onClick: () => onCompleteHandler(),
                }}
            />
        </Content>
    );
};

export default memo(VinaWealthForeignerWarning);
