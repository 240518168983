import LspButton from "@components/LspButton";
import { Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState, memo } from "react";
import { isEmpty, isFunction } from "lodash";

import { useTranslation } from "react-i18next";
import LspRadioButton from "@components/LspRadioButton";

const useStyles = makeStyles((theme) => ({
    deliveryCard: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
    },
    deliveryCardHeader: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(23),
        lineHeight: "34px",
        letterSpacing: "0.3px",
        color: theme.palette.white.contrastText,
        marginTop: theme.spacing(1.5),
        padding: theme.spacing(3),
    },
    deliveryCardBody: {
        marginBottom: theme.spacing(6.5),
    },
    deliveryCardBodyCarouselCard: {
        marginBottom: theme.spacing(3),
    },
    deliveryBoxItem: {
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
    deliveryCardFooter: {
        padding: theme.spacing(3),
    },
    deliveryAlert: {
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(
            3
        )}px`,
        "& .MuiAlert-message": {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: "22px",
        },
    },
}));

const DeliveryPickUpHangOut = ({
    hangoutList,
    onDeliveryPickUpHangOutClose,
    onDeliveryPickUpHangOutSubmit,
    loading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [isItemSelected, setItemSelected] = useState({});

    const handleItemSelected = (data) => {
        setItemSelected(data);
    };

    const handleSubmit = () => {
        if (isEmpty(isItemSelected)) {
            return;
        }
        if (isFunction(onDeliveryPickUpHangOutSubmit)) {
            onDeliveryPickUpHangOutSubmit(isItemSelected);
        }
    };

    const handleClose = () => {
        if (isFunction(onDeliveryPickUpHangOutClose)) {
            onDeliveryPickUpHangOutClose();
        }
    };

    return (
        <>
            <div className={classes.deliveryCard}>
                <div className={classes.deliveryCardHeader}>
                    {t("debitCard:reissuecard_HO_location")}
                </div>
                <div className={classes.deliveryCardBody}>
                    <div className={classes.deliveryAlert}>
                        <Alert variant="filled" severity="warning">
                            {t("debitCard:reissuecard_HO_location_warn")}
                        </Alert>
                    </div>

                    {(hangoutList || []).map((hangout) => (
                        <div
                            className={classes.deliveryBoxItem}
                            key={hangout.receiveRequestId}
                        >
                            <LspRadioButton
                                title={hangout.content}
                                cfgKey={hangout?.receiveRequestId}
                                isSelected={
                                    isItemSelected.cfgKey ===
                                    hangout?.receiveRequestId
                                }
                                loading={loading}
                                onItemSelected={handleItemSelected}
                                description={hangout.contentDetail}
                            />
                        </div>
                    ))}
                </div>
                <div className={classes.deliveryCardFooter}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="secondary"
                                onClick={handleClose}
                            >
                                {t("lb_back")}
                            </LspButton>
                        </Grid>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="primary"
                                onClick={handleSubmit}
                            >
                                {t("lb_next")}
                            </LspButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default memo(DeliveryPickUpHangOut);
