/* eslint-disable react/no-array-index-key */
import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(1, 3),
    },
    body: {
        padding: theme.spacing(1, 2),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        "& > div": {
            margin: theme.spacing(0.75),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
}));

const LspTimeFetching = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>
                <Skeleton variant="rect" height={50} width="100%" />
            </div>
            <div className={classes.body}>
                {[...new Array(25)]?.map((_, index) => (
                    <div key={index}>
                        <Skeleton variant="rect" height={30} width={60} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(LspTimeFetching);
