import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, makeStyles } from "@material-ui/core";
import LspAvatar from "@components/LspAvatar";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => {
    return {
        imgTransfer: {
            display: "flex",
            alignItems: "center",
            margin: theme.spacing(3, 0, 1.25, 0),
            width: "100%",
            justifyContent: "center",
        },
    };
});
const PaymentImageTransfer = ({ avatar, isSent }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.imgTransfer}>
            <Avatar
                variant="rounded"
                style={{
                    width: "70px",
                    height: "70px",
                }}
                className={classes.rounded}
            >
                {t("payment_lb_me")}
            </Avatar>
            <Box marginX={2}>
                {isSent ? (
                    <ArrowForwardIcon color="secondary" />
                ) : (
                    <ArrowBackIcon color="error" />
                )}
            </Box>
            <LspAvatar avatar={avatar} size={70} />
        </Box>
    );
};
export default memo(PaymentImageTransfer);
