import { memo, useCallback, useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, fade } from "@material-ui/core";
import useNumber from "@helpers/useNumber";
import Interweave from "interweave";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    "MuiFilledInput-root": {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
        "&.Mui-focused": {
            backgroundColor: theme.palette.background.paper,
        },
        "&.MuiAutocomplete-inputRoot": {
            paddingTop: `${theme.spacing(0.5)}px !important`,
            paddingBottom: theme.spacing(1.5),
        },
        "&.MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            paddingTop: `${theme.spacing(2.25)}px !important`,
            paddingBottom: `${theme.spacing(0.375)}px !important`,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.background.paper,
        },
        "&.Mui-error": {
            borderLeftWidth: theme.spacing(0.5),
            borderLeftStyle: "solid",
            borderLeftColor: theme.palette.error.main,
        },
        "&.Mui-error:after": {
            left: theme.spacing(1),
        },
    },
    "MuiInputLabel-filled": {
        fontWeight: "600",
        transform: "translate(12px, 20px) scale(1)",
        "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 8px) scale(0.7)",
        },
        "&.MuiFormLabel-filled + .MuiFilledInput-underline:hover:before": {
            borderColor: "rgba(0, 0, 0, 0.42)",
        },
        "&.MuiFormLabel-filled + .MuiFilledInput-underline:before": {
            borderColor: "rgba(0, 0, 0, 0.42)",
        },
    },
    "MuiFilledInput-input": {
        paddingBottom: theme.spacing(1.875),
        paddingTop: theme.spacing(2.75),
        borderRadius: theme.shape.borderRadius,
        "&.MuiFilledInput-input-error": {
            paddingLeft: theme.spacing(1),
        },
        "&.MuiFilledInput-inputMultiline": {
            paddingTop: 0,
            minHeight: theme.spacing(5),
        },
    },
    "MuiFilledInput-underline": {
        "&:before": {
            borderColor: "transparent",
            marginTop: 10,
            bottom: 10,
            left: theme.spacing(1.5),
            right: theme.spacing(1.5),
        },
        "&:after": {
            marginTop: 10,
            bottom: 10,
            left: theme.spacing(1.5),
            right: theme.spacing(1.5),
        },
        "&:hover:before": {
            borderColor: "transparent",
        },
    },
    "MuiFormHelperText-root": {
        marginBottom: theme.spacing(0.75),
    },
    selectWrapper: {
        marginTop: theme.spacing(1),
    },
    red: {
        color: theme.palette.status.error,
    },
    lspTextField: {
        position: "relative",
        width: "100%",
    },
    suggest: {
        position: "absolute",
        top: "64px",
        width: "100%",
        borderRadius: "8px",
        boxShadow: theme.shadows[2],
        background: theme.palette.background.paper,
        zIndex: 15,
    },
    suggestDetail: {
        padding: theme.spacing(2, 1.75),
        fontSize: theme.typography.pxToRem(16),
        lineHeight: "19px",
        fontWeight: "400",
        cursor: "pointer",
        "&:first-child": {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
        },
        "&:last-child": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
        },
        "&:hover": {
            backgroundColor: fade(theme.palette.neutral.grey1, 0.24),
        },
    },
    countValue: {
        marginTop: theme.spacing(1),
        textAlign: "right",
    },
}));

/**
 * @param {Object} props
 * @param {string} [props.label]
 * @param {("text"|"password"|"number"|"tel")} [props.type]
 * @param {string} [props.name]
 * @param {string|number} [props.value]
 * @param {function} [props.onChange]
 * @param {function} [props.onBlur]
 * @param {boolean} [disableHelperText]
 * @param {string} [helperText]
 * @param {("on"|"off")} [autoComplete]
 * @param {boolean} [autoFocus]
 * @param {boolean} [error]
 * @param {boolean} [fullWidth]
 * @param {object} [InputProps]
 * @param {object} [InputLabelProps]
 * @param {boolean} [select]
 * @param {boolean} [disabled=false]
 */

const LspTextField = ({
    label,
    type,
    name,
    value,
    onChange,
    onBlur,
    disableHelperText,
    helperText,
    autoComplete,
    autoFocus,
    error,
    fullWidth,
    InputProps,
    InputLabelProps,
    select,
    disabled,
    children,
    helperTextColor,
    suggestAmount,
    numberValue,
    onAmountChange,
    enableCountValue,
    ...restProps
}) => {
    const classes = useStyles();
    const [suggestList, setSuggestList] = useState([]);
    const { formatNumber } = useNumber();
    const [activeSuggest, setActiveSuggest] = useState(false);
    const [isFirstInit, setFirstInit] = useState(true);

    const mapHelperText = useCallback((input) => {
        if (!input) {
            return " ";
        }
        return <Interweave content={input} />;
    }, []);

    const checkActiveSuggest = (amountNumber) => {
        if (amountNumber === 0) {
            setActiveSuggest(false);
            setSuggestList([]);
        }
    };

    useEffect(() => {
        if (suggestAmount && Number(numberValue) && activeSuggest) {
            const firstAuto = numberValue * 1000;
            const secondAuto = numberValue * 10000;
            const thirdAuto = numberValue * 100000;
            const suggests = [
                {
                    formattedValue: formatNumber(firstAuto),
                    value: firstAuto,
                    floatValue: parseFloat(firstAuto),
                },
                {
                    formattedValue: formatNumber(secondAuto),
                    value: secondAuto,
                    floatValue: parseFloat(secondAuto),
                },
                {
                    formattedValue: formatNumber(thirdAuto),
                    value: thirdAuto,
                    floatValue: parseFloat(thirdAuto),
                },
            ];
            setSuggestList(suggests);
            if (isFirstInit) {
                setFirstInit(false);
                setActiveSuggest(false);
            }
        }

        checkActiveSuggest(numberValue);
    }, [formatNumber, suggestAmount, numberValue, activeSuggest, isFirstInit]);

    useEffect(() => {
        setActiveSuggest(true);
    }, [value, numberValue]);

    const onClickSuggest = (suggestValue) => {
        onAmountChange(suggestValue);
        setActiveSuggest(false);
        setSuggestList([]);
    };

    const onBlurAuto = (blurValue) => {
        if (suggestAmount) {
            setActiveSuggest(false);
        }

        if (onBlur != null) {
            onBlur(blurValue);
        }
    };

    return (
        <Box className={classes.lspTextField}>
            <TextField
                {...restProps}
                autoFocus={!!autoFocus}
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlurAuto}
                type={type}
                variant="filled"
                margin="none"
                size="medium"
                fullWidth={fullWidth}
                name={name}
                error={!!error}
                disabled={disabled}
                helperText={!disableHelperText ? mapHelperText(helperText) : ""}
                InputLabelProps={{
                    ...InputLabelProps,
                    classes: {
                        filled: classes["MuiInputLabel-filled"],
                    },
                }}
                InputProps={{
                    autoComplete,
                    ...InputProps,
                    classes: {
                        root: classes["MuiFilledInput-root"],
                        input: clsx(classes["MuiFilledInput-input"], {
                            "MuiFilledInput-input-error": error,
                        }),
                        textarea: classes["MuiFilledInput-inputMultiline"],
                        underline: classes["MuiFilledInput-underline"],
                    },
                }}
                FormHelperTextProps={{
                    classes: {
                        root: clsx(classes["MuiFormHelperText-root"], {
                            [classes[helperTextColor]]: helperTextColor,
                        }),
                    },
                }}
                select={select}
                SelectProps={{
                    MenuProps: {
                        className: classes.selectWrapper,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                    },
                }}
            >
                {select && children}
            </TextField>
            {enableCountValue && (
                <LspTypography
                    className={classes.countValue}
                    variant="subheading4"
                    color="grey"
                >
                    {value?.length || 0}/{restProps?.inputProps?.maxLength || 0}
                </LspTypography>
            )}
            {suggestAmount && suggestList && activeSuggest && !isFirstInit && (
                <div className={classes.suggest}>
                    {suggestList.map((suggestItem) => (
                        <LspTypography
                            key={suggestItem.formattedValue}
                            onMouseDown={() => onClickSuggest(suggestItem)}
                            className={classes.suggestDetail}
                        >
                            {suggestItem.formattedValue}
                        </LspTypography>
                    ))}
                </div>
            )}
        </Box>
    );
};

LspTextField.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(["text", "password", "number", "tel"]),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disableHelperText: PropTypes.bool,
    helperText: PropTypes.string,
    autoComplete: PropTypes.oneOf(["on", "off"]),
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    select: PropTypes.bool,
    disabled: PropTypes.bool,
    helperTextColor: PropTypes.oneOf(["red", ""]),
    suggestAmount: PropTypes.bool,
    numberValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enableCountValue: PropTypes.bool,
};

LspTextField.defaultProps = {
    label: undefined,
    type: undefined,
    name: undefined,
    value: undefined,
    onChange: undefined,
    onBlur: undefined,
    disableHelperText: false,
    helperText: " ",
    autoComplete: "off",
    autoFocus: false,
    error: false,
    fullWidth: true,
    select: false,
    disabled: false,
    helperTextColor: "",
    suggestAmount: false,
    numberValue: undefined,
    enableCountValue: false,
};

export default memo(LspTextField);
