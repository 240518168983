import { useState, useEffect } from "react";
import userAction from "@redux/actions/user";
import { connect } from "react-redux";
import { FetchStatus } from "@config/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CreateNickname from "./CreateNickname";
import PreviewNickname from "./PreviewNickname";

const STEP = {
    CREATE: 0,
    PREVIEW: 1,
};

const Nickname = ({
    aliasFree,
    checkNickname,
    aliasCheckStatus,
    aliasNicknameStatus,
    submitAlias,
    getBankInfo,
    featureState,
}) => {
    const [step, setStep] = useState(STEP.CREATE);
    const [nickname, setNickname] = useState(null);
    const history = useHistory();

    const handleOnCreateNameSubmit = (data) => {
        setNickname(data.nickname);
        checkNickname({
            requestNickNameId: aliasFree.requestNickNameId,
            nickname: data.nickname,
        });
    };

    useEffect(() => {
        if (!featureState?.enableAliasNickName) {
            history.push("/home");
        }
    }, [featureState?.enableAliasNickName, history]);

    useEffect(() => {
        if (aliasFree.requestNickNameId == null) {
            history.push("/home");
        }
    }, [aliasFree, aliasFree.requestNickNameId, history]);

    const handleOnBack = () => {
        setStep(STEP.CREATE);
    };

    useEffect(() => {
        if (nickname != null && aliasCheckStatus === FetchStatus.Success) {
            setStep(STEP.PREVIEW);
        }
    }, [aliasCheckStatus, nickname]);

    const handleSubmit = () => {
        submitAlias({ requestNickNameId: aliasFree.requestNickNameId });
    };

    useEffect(() => {
        if (aliasNicknameStatus === FetchStatus.Success) {
            history.push("/bank-information/bank-details");
            getBankInfo();
        }
    }, [aliasNicknameStatus, getBankInfo, history]);
    return (
        <>
            {step === STEP.CREATE && (
                <CreateNickname
                    onSubmit={handleOnCreateNameSubmit}
                    nickname={nickname}
                />
            )}
            {step === STEP.PREVIEW && (
                <PreviewNickname
                    nickname={nickname}
                    onBack={handleOnBack}
                    onPreviewSubmit={handleSubmit}
                />
            )}
        </>
    );
};

const mapState = (state) => ({
    aliasFree: state.user.aliasFree?.data,
    aliasFreeStatus: state.user.aliasFree?.status,
    aliasNicknameStatus: state.user.aliasNickname?.status,
    aliasCheckStatus: state.user.aliasCheck?.status,
    featureState: state.user.featureState.data,
});

const mapDispatch = (dispatch) => ({
    checkNickname: (payload) =>
        dispatch(userAction.checkNicknameRequest(payload)),
    submitAlias: (payload) =>
        dispatch(userAction.submitNicknameRequest(payload)),
    getBankInfo: () => dispatch(userAction.getBankInfoRequest()),
});

export default connect(mapState, mapDispatch)(Nickname);
