import LspButton from "@components/LspButton";
import { Box, makeStyles } from "@material-ui/core";
import billActions from "@redux/actions/bill";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import BillItem from "src/containers/SpendAccount/BillPayment/BillItem";

import sendIcon from "@assets/images/icon_send_money.png";
import BillListFetching from "src/containers/SpendAccount/BillPayment/BillListFetching";
import LspTypography from "@components/LspTypography";
import { useHistory } from "react-router-dom";
import {
    BillNavigationKey,
    SpendAccountNavigationKey,
} from "@config/constants";

const useStyles = makeStyles((theme) => ({
    billBody: {
        fontSize: theme.typography.pxToRem(16),
    },
    billList: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: "auto",
        marginRight: "auto",
    },
    emptyList: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginRight: theme.spacing(7),
        "& > div": {
            marginRight: theme.spacing(1),
        },
    },
    billItem: {
        boxShadow: theme.shadows[1],
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(0.5),
        display: "flex",
        marginBottom: theme.spacing(1),
        cursor: "pointer",
        color: theme.palette.magicalGrey[400],
        flexDirection: "column",
    },
}));

const BillList = ({ bills, fetching, getBillList, reset }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    useEffect(() => {
        getBillList();
    }, [getBillList]);

    const openNewBill = () => {
        history.push(
            `/spend-account/${SpendAccountNavigationKey.BillPayment}/${BillNavigationKey.NewBill}`
        );
    };

    return (
        <div>
            <div className={classes.billBody}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <LspTypography variant="heading2" color="grey">
                        {t("spend_label_bill_list")}
                    </LspTypography>
                    <LspButton type="submit" onClick={openNewBill}>
                        {t("spend_butt_new_bill")}
                    </LspButton>
                </Box>

                <div className={classes.billList}>
                    {/* Have bill list */}
                    {!fetching &&
                        bills.length > 0 &&
                        bills.map((bill) => {
                            return (
                                <BillItem
                                    key={bill.payeeId}
                                    parentClasses={classes}
                                    item={bill}
                                />
                            );
                        })}

                    {/* Empty bill list */}
                    {!fetching && bills.length === 0 && (
                        <div className={classes.emptyList}>
                            <div>
                                {t("spend_text_tap_to_move_money_first_time")}
                            </div>
                            <img src={sendIcon} alt="logo" />
                        </div>
                    )}

                    {/* Loading */}
                    {fetching && <BillListFetching parentClasses={classes} />}
                </div>
            </div>
        </div>
    );
};

const stateProps = (state) => ({
    bills: state.bill.bills,
    fetching: state.bill.billsFetching,
});

const dispatchProps = (dispatch) => ({
    getBillList: () => dispatch(billActions.getBillList()),
    reset: () => billActions.reset(),
});
export default connect(stateProps, dispatchProps)(BillList);
