import { call, put, select } from "redux-saga/effects";
import { RESPONSE_CODE } from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import cardManagementService from "@services/card-management";
import {
    CARD_ACTIONS,
    CARD_ACTIVATE_SUCCESS_TYPES,
    CARD_TYPES,
    REISSUE_CARD_VERSION,
} from "@containers/CardManagement/constant";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import LspTranslation from "@components/LspTranslation";
import CardActivateSuccess from "@containers/CardManagement/CardActivateSuccess";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import tommyNoHope from "@assets/images/no_hope_with_status.png";
import RequestCardSuccessDialog from "@containers/CardManagement/ReIssuedCard/RequestCardSuccessDialog";
import RequestCardErrorDialog from "@containers/CardManagement/ReIssuedCard/RequestCardErrorDialog";
import CardManagementActions from "../actions/card-management";

const closeDialog = () => GlobalDialogController.hide();

const getDialogOpened = (state) => state.otpDialog.opened;
const successDataRequestCard = (state) => state.cardInfo.reviewRequestCardData;

const disableDefaultPopup = (state) => state.cardInfo.disableDefaultPopup;

// ------ Get Card List

export function* getCardListSaga() {
    const response = yield call(cardManagementService.getCardList);

    const { code, data } = response.data;
    yield put(CardManagementActions.getCardListSuccess(data));
}

// ------ Update card status

function isDebit(cardType) {
    switch (cardType) {
        case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD:
        case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP:
            return "VIRTUAL VD"; // no need to replace type in msg content. It use difference msg with debit & credit
        case CARD_TYPES.DEBIT_CARD:
            return "mc_lb_timo_debit_card";

        default:
            return "mc_lb_timo_credit_card";
    }
}

const isVisaDebit = (type) => {
    return (
        type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
        type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
    );
};

function getUpdateStatusMsg(cardAction, cardType) {
    let msg;
    switch (cardAction) {
        case CARD_ACTIONS.ACTIVATE_CARD:
            break;
        // case CARD_ACTIONS.ACTIVATE_VIRTUAL_CARD:
        //     msg = {
        //         success: "master:manage_card_visa_virtual_notice_success",
        //     };
        //     break;
        case CARD_ACTIONS.LOCK_CARD:
            msg = {
                success: isVisaDebit(cardType)
                    ? "master:manage_card_visa_virtual_notice_lock_success"
                    : "mc_dropdown_ms_lock_card_success",
                error: "mc_dropdown_ms_lock_card_failure",
                replaceWith: isDebit(cardType),
            };
            break;
        case CARD_ACTIONS.UNLOCK_CARD:
            msg = {
                success: isVisaDebit(cardType)
                    ? "master:manage_card_visa_virtual_notice_unlock_success"
                    : "mc_dropdown_ms_unlock_card_success",
                error: "mc_dropdown_ms_unlock_card_failure",
                replaceWith: isDebit(cardType),
            };
            break;
        case CARD_ACTIONS.REISSUE_NEW_CARD_PIN:
            break;
        case CARD_ACTIONS.DISABLE_ECOMMERCE:
            msg = {
                success: "card_msg_disable_ecommerce_successfully",
            };
            break;
        case CARD_ACTIONS.ENABLE_ECOMMERCE:
            msg = {
                success: "card_msg_enable_ecommerce_successfully",
            };
            break;
        default:
            break;
    }

    return msg;
}

function* cardActivationResponseHandler(response) {
    const opened = yield select(getDialogOpened);
    const disabled = yield select(disableDefaultPopup);
    if (opened) {
        OTPController.hide();
    }
    const { code } = response.data;

    if (disabled) {
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                yield put(CardManagementActions.cardActivationSuccess());
                break;
            default:
                yield put(CardManagementActions.cardActivationError());
                break;
        }
        return;
    }

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(CardManagementActions.cardActivationSuccess());
            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            break;
        case RESPONSE_CODE.PIN_FAILED_NOT_BLOCK:
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "problem",
                    header: "mc_lb_title_change_pin_not_success",
                    content: "mb_jn_content_error_setup_pin_not_blocked",
                    contact: "error_dialog_contact_box",
                    button: "lb_ok",
                },
            });
            yield put(CardManagementActions.cardActivationError());
            break;
        case RESPONSE_CODE.PIN_FAILED_BLOCK:
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "problem",
                    header: "mc_lb_title_change_pin_not_success",
                    content: "mb_jn_content_error_setup_pin",
                    contact: "error_dialog_contact_box",
                    button: "lb_btn_ok_got_it",
                },
            });
            yield put(CardManagementActions.cardActivationError());
            break;
        case RESPONSE_CODE.INVALID_CARD:
        case RESPONSE_CODE.ALREADY_RESET_CARD_PIN:
        case RESPONSE_CODE.ACTIVATE_CARD_FAILURE:
        case RESPONSE_CODE.NOT_FOUND_IN_DATABASE:
        default:
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "problem",
                    header: "mc_lb_title_change_pin_not_success",
                    content: "join_activate_card_unsuccessful",
                    button: "lb_btn_ok_got_it",
                },
            });
            yield put(CardManagementActions.cardActivationError());
            break;
    }
}

function* responseUpdateCardStatusHandler(response, cardAction, msg) {
    switch (cardAction) {
        case CARD_ACTIONS.LOCK_CARD:
        case CARD_ACTIONS.UNLOCK_CARD:
            const { code } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    OTPController.hide();
                    yield put(CardManagementActions.updateCardStatusSuccess());
                    yield put(CardManagementActions.getCardList());

                    AlertController.show(
                        <LspTranslation
                            i18nKey={msg.success}
                            replacingKeys={[msg.replaceWith]}
                        />
                    );
                    break;
                case RESPONSE_CODE.OTP_REQUIRED:
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE:
                case RESPONSE_CODE.MISMATCH_VALUE:
                    AlertController.show(
                        <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                        AlertType.Error
                    );
                    break;
                default:
                    OTPController.hide();
                    yield put(CardManagementActions.updateCardStatusError());
                    if (msg.error || code !== RESPONSE_CODE.UNAUTHORIZED) {
                        AlertController.show(
                            <LspTranslation
                                i18nKey={msg.error}
                                replacingKeys={[msg.replaceWith]}
                            />,
                            AlertType.Error
                        );
                    } else {
                        GlobalDialogController.showError({
                            errorCode: code,
                        });
                    }
                    break;
            }

            break;
        case CARD_ACTIONS.DISABLE_ECOMMERCE:
        case CARD_ACTIONS.ENABLE_ECOMMERCE:
            const { code: resCode } = response.data;
            switch (resCode) {
                case RESPONSE_CODE.SUCCESS:
                    OTPController.hide();
                    yield put(CardManagementActions.updateECommerceSuccess());
                    yield put(CardManagementActions.getCardList());
                    AlertController.show(
                        <LspTranslation i18nKey={msg.success} />,
                        AlertType.Success
                    );
                    break;
                case RESPONSE_CODE.OTP_REQUIRED:
                    break;
                case RESPONSE_CODE.INCORRECT_VALUE:
                case RESPONSE_CODE.MISMATCH_VALUE:
                    AlertController.show(
                        <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                        AlertType.Error
                    );
                    break;
                default:
                    OTPController.hide();
                    yield put(CardManagementActions.updateECommerceError());
                    GlobalDialogController.showError({
                        errorCode: resCode,
                    });
                    break;
            }
            break;
        case CARD_ACTIONS.ACTIVATE_VIRTUAL_CARD:
            const { code: activateResCode } = response.data;
            //  use for activate card visa debit virtual card
            if (activateResCode === RESPONSE_CODE.SUCCESS) {
                yield put(CardManagementActions.updateCardStatusSuccess());
                GlobalDialogController.show({
                    component: () => (
                        <CardActivateSuccess
                            action={
                                CARD_ACTIVATE_SUCCESS_TYPES.VISA_DEBIT_VIRTUAL_CARD
                            }
                        />
                    ),
                });
                yield put(CardManagementActions.getCardList());
                return;
            }

            yield cardActivationResponseHandler(response);
            yield put(CardManagementActions.updateCardStatusError());
            break;
        case CARD_ACTIONS.ACTIVATE_CARD:
        case CARD_ACTIONS.REISSUE_NEW_CARD_PIN:
        default:
            break;
    }
}

export function* updateCardStatusSaga(action) {
    const { payload } = action;
    const { cardType, cardStatus, cardId } = payload;

    const msg = getUpdateStatusMsg(cardStatus, cardType);

    const requestedData = {
        cardId,
        status: cardStatus,
    };
    const response = yield call(
        cardManagementService.updateCardStatus,
        requestedData
    );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.OTP_REQUIRED:
            const { refNo } = data;
            yield put(
                CardManagementActions.otpRequired({
                    refNo,
                    cardId,
                    cardAction: cardStatus,
                    cardType,
                })
            );
            OTPController.show(OTPDialogType.UpdateCardStatus);
            break;
        default:
            yield responseUpdateCardStatusHandler(response, cardStatus, msg);
            break;
    }
}

export function* submitOTPCardManagementSaga(action) {
    const { payload } = action;
    const { cardAction, cardType } = payload;
    const msg = getUpdateStatusMsg(cardAction, cardType);
    const response = yield call(cardManagementService.submitOTP, payload);

    yield put(CardManagementActions.otpCommitFinished());
    yield responseUpdateCardStatusHandler(response, cardAction, msg);
}

// ------ Reset Pin

function* resetPinResponseHandler(response) {
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            AlertController.show(
                <LspTranslation i18nKey="mc_lb_change_pin_success" />
            );
            yield put(CardManagementActions.resetPinSuccess());
            yield put(CardManagementActions.getCardList());
            OTPController.hide();
            window.history.back();
            break;
        case RESPONSE_CODE.UNAUTHORIZED:
            AlertController.show(
                <LspTranslation i18nKey="quickcode_msg_incorrect_password" />,
                AlertType.Error
            );
            yield put(CardManagementActions.resetPinError());
            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            break;
        case RESPONSE_CODE.PIN_FAILED_NOT_BLOCK:
            AlertController.show(
                <LspTranslation i18nKey="mc_lb_content_forgot_pin_not_success" />,
                AlertType.Error
            );
            yield put(CardManagementActions.resetPinError());
            break;
        case RESPONSE_CODE.PIN_FAILED_BLOCK:
            AlertController.show(
                <LspTranslation i18nKey="mc_lb_content_forgot_pin_warning" />,
                AlertType.Error
            );
            yield put(CardManagementActions.resetPinError());
            break;
        default:
            yield put(CardManagementActions.resetPinError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* resetPinSaga(action) {
    const { payload } = action;
    const { cardId } = payload;
    const response = yield call(cardManagementService.resetPin, payload);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.OTP_REQUIRED:
            const { refNo } = data;
            yield put(
                CardManagementActions.otpRequired({
                    refNo,
                    cardId,
                })
            );
            OTPController.show(OTPDialogType.ResetPin);
            break;
        default:
            yield resetPinResponseHandler(response);
            break;
    }
}

export function* submitOTPResetPinSaga(action) {
    const { payload } = action;
    const response = yield call(
        cardManagementService.submitOTPResetPin,
        payload
    );
    yield put(CardManagementActions.otpCommitFinished());
    yield resetPinResponseHandler(response);
}

// ----- ReIssue Card

export function* getHangoutListReIssueCardSaga(action) {
    const { payload } = action;
    const response = yield call(cardManagementService.getHangoutList, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                CardManagementActions.getHangoutListReIssueSuccess(
                    data.available
                )
            );
            break;
        default:
            yield put(CardManagementActions.getHangoutListReIssueError());
            break;
    }
}

function* reIssueCardResponseHandler(response, version) {
    const opened = yield select(getDialogOpened);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.REQUEST_PHYSICAL_CARD_SUCCESS:
            if (opened) {
                OTPController.hide();
            }

            yield put(CardManagementActions.reIssueCardSuccess(data?.bankXID));

            switch (version) {
                case REISSUE_CARD_VERSION.VIRTUAL:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Success",
                            header: "master:request_virtual_card_success_title",
                            content: "master:request_virtual_card_success_desc",
                            button: "lb_btn_ok_got_it",
                            navigatePathForButton: "/card-management/debit",
                        },
                        onClose: GlobalDialogController.hide(),
                    });
                    break;
                case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
                    const successData = yield select(successDataRequestCard);

                    GlobalDialogController.show({
                        component: () => (
                            <RequestCardSuccessDialog
                                onClose={() => GlobalDialogController.hide()}
                                address={successData?.receiveDetail}
                                addressName={successData?.addressLabel}
                            />
                        ),
                    });

                    break;
                case REISSUE_CARD_VERSION.CARD_V2:
                default:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Success",
                            header: "debitCard:reissuecard_request_success_app",
                            content:
                                "debitCard:reissuecard_request_success_web_desc",
                            button: "lb_btn_ok_got_it",
                            navigatePathForButton: "/card-management/debit",
                        },
                        onClose: GlobalDialogController.hide(),
                    });
                    break;
            }
            yield put(CardManagementActions.getCardList());
            break;
        case RESPONSE_CODE.REQUEST_PHYSICAL_CARD_PAYMENT_ERROR:
            if (opened) {
                OTPController.hide();
            }

            GlobalDialogController.show({
                component: () => <RequestCardErrorDialog />,
            });

            yield put(CardManagementActions.reIssueCardError());
            break;
        case RESPONSE_CODE.NOT_ALLOWED:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    header: "debitCard:reissuecard_error_enough_money_title",
                    content: "debitCard:reissuecard_error_enough_money_desc",
                    button: "lb_btn_ok_got_it",
                    navigatePathForButton: "/home",
                },
            });
            yield put(CardManagementActions.reIssueCardError());
            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            break;
        case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
            if (opened) {
                OTPController.hide();
            }
            const limitedContent = {
                banner: tommyNoHope,
                title: <LspTranslation i18nKey="oops" />,
                description: (
                    <LspTranslation i18nKey="ms_lb_transfer_daily_limit" />
                ),
                buttons: [
                    {
                        variant: "primary",
                        label: <LspTranslation i18nKey="lb_ok" />,
                        onClick: () => closeDialog(),
                    },
                ],
            };
            GlobalDialogController.show({
                component: () => (
                    <ConditionNotificationDialog
                        content={limitedContent}
                        centerImg
                    />
                ),
            });
            yield put(CardManagementActions.reIssueCardError());
            break;
        case RESPONSE_CODE.ACTIVATED_CARD_FAILED: // Virtual card
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    header: "master:request_virtual_card_success_desc",
                    content: "master:request_virtual_card_fail_active_desc",
                    button: "lb_btn_ok_got_it",
                    navigatePathForButton: "/card-management/debit",
                },
                onClose: GlobalDialogController.hide(),
            });
            yield put(CardManagementActions.getCardList());
            break;
        case RESPONSE_CODE.REGISTER_CARD_FAILED: // Virtual card
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Error",
                    header: "master:request_virtual_card_fail_all_title",
                    content: "master:request_virtual_card_fail_all_desc",
                    button: "lb_btn_ok_got_it",
                    navigatePathForButton: "/card-management/debit",
                },
                onClose: GlobalDialogController.hide(),
            });
            yield put(CardManagementActions.getCardList());
            break;
        case RESPONSE_CODE.REISSUED_CARD_HIT_LIMIT_TIMES: // Virtual card
            if (opened) {
                OTPController.hide();
            }

            const content = (
                <LspTranslation
                    i18nKey="virtual:reissued_virtual_error_exceed_limit_reissuances"
                    hyperlink
                    replacingKeys={["hangout:callcenter_number"]}
                />
            );
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    header: "ekyc:sorry_title",
                    lspTranslation: content,
                    button: "lb_btn_ok_got_it",
                    navigatePathForButton: "/card-management/debit",
                },
                onClose: GlobalDialogController.hide(),
            });
            break;
        case RESPONSE_CODE.REISSUED_CARD_HIT_LIMIT_AMOUNT: // Virtual card
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    header: "ekyc:sorry_title",
                    content: "virtual:reissued_virtual_error_hit_ekyc_limit",
                    button: "lb_btn_ok_got_it",
                    navigatePathForButton: "/card-management/debit",
                },
                onClose: GlobalDialogController.hide(),
            });
            break;
        default:
            if (opened) {
                OTPController.hide();
            }
            yield put(CardManagementActions.reIssueCardError());
            if (
                version === REISSUE_CARD_VERSION.CARD_V2 ||
                version === REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD
            ) {
                GlobalDialogController.showFinancialError({
                    errorCode: code,
                });
                return;
            }

            GlobalDialogController.showError({
                errorCode: code,
            });

            break;
    }
}

export function* reIssueCardSaga(action) {
    const { payload } = action;
    const { cardId } = payload;
    const response = yield call(cardManagementService.reIssueCard, payload);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.OTP_REQUIRED:
            const { refNo } = data;
            yield put(
                CardManagementActions.otpRequired({
                    refNo,
                    cardId,
                })
            );
            OTPController.show(OTPDialogType.ReIssueCard);
            break;
        default:
            yield reIssueCardResponseHandler(response, "v1");
            break;
    }
}

export function* reIssueCardV2Saga(action) {
    const { payload } = action;
    const { version, ...otherData } = payload;
    const { cardVersion, cardId } = otherData;
    // const version = REISSUE_CARD_VERSION.CARD_V2;

    let response;

    switch (version) {
        case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
            response = yield call(
                cardManagementService.requestPhysicalCard,
                otherData
            );
            break;
        case REISSUE_CARD_VERSION.VIRTUAL:
            response = yield call(
                cardManagementService.reissueVirtualVisaDebitCard,
                otherData
            );
            break;
        case REISSUE_CARD_VERSION.CARD_V2:
        default:
            response = yield call(
                cardManagementService.reIssueCardV2,
                otherData
            );
            break;
    }
    // const response =
    //     version === REISSUE_CARD_VERSION.CARD_V2
    //         ? yield call(cardManagementService.reIssueCardV2, otherData)
    //         : yield call(
    //               cardManagementService.reissueVirtualVisaDebitCard,
    //               otherData
    //           );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.OTP_REQUIRED:
            GlobalDialogController.hide();
            const { refNo } = data;
            yield put(
                CardManagementActions.otpRequired({
                    refNo,
                    cardVersion,
                    version,
                    cardId,
                })
            );
            OTPController.show(OTPDialogType.ReIssueCard);
            break;
        default:
            yield reIssueCardResponseHandler(response, version);
            break;
    }
}

export function* submitOTPReIssueCardSaga(action) {
    const { payload } = action;
    const { version, ...dataToPost } = payload;

    switch (version) {
        case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
            const physicalVDRes = yield call(
                cardManagementService.submitOtpRequestPhysicalCard,
                dataToPost
            );
            yield put(CardManagementActions.otpCommitFinished());
            yield reIssueCardResponseHandler(physicalVDRes, version);
            break;
        case REISSUE_CARD_VERSION.VIRTUAL:
            const resVirtual = yield call(
                cardManagementService.commitOtpReissueVirtualVisaDebitCard,
                dataToPost
            );
            yield put(CardManagementActions.otpCommitFinished());
            yield reIssueCardResponseHandler(resVirtual, version);
            break;
        case REISSUE_CARD_VERSION.CARD_V2:
            const resV2 = yield call(
                cardManagementService.submitOTPReIssueCardV2,
                dataToPost
            );
            yield put(CardManagementActions.otpCommitFinished());
            yield reIssueCardResponseHandler(resV2, version);
            break;
        case REISSUE_CARD_VERSION.CARD_V1:
        default:
            const resV1 = yield call(
                cardManagementService.submitOTPReIssueCard,
                dataToPost
            );
            yield put(CardManagementActions.otpCommitFinished());
            yield reIssueCardResponseHandler(resV1, version);
            break;
    }
}

// ----- Card Activation

export function* checkCardNumberSaga(action) {
    const { payload } = action;

    const response = yield call(cardManagementService.checkCardNumber, payload);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(CardManagementActions.checkCardNumberSuccess());
            break;
        default:
            yield put(CardManagementActions.checkCardNumberError());
            break;
    }
}

export function* cardActivationSaga(action) {
    const { payload } = action;
    const { cardType, ...restProps } = payload;

    const response = yield call(
        cardManagementService.cardActivation,
        restProps
    );

    if (response.ok) {
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                const { refNo } = data;
                yield put(
                    CardManagementActions.otpRequired({
                        refNo,
                        cardType,
                    })
                );
                OTPController.show(OTPDialogType.CardActivation);
                return;
            default:
                break;
        }
    }

    yield cardActivationResponseHandler(response);
}

export function* submitOTPCardActivationSaga(action) {
    const { payload } = action;

    const response = yield call(
        cardManagementService.submitOTPCardActivation,
        payload
    );

    const { code } = response.data;
    if (code === RESPONSE_CODE.INCORRECT_VALUE) {
        GlobalDialogController.showFinancialError({ errorCode: code });
        yield put(CardManagementActions.otpCommitFinished());
        return;
    }
    yield put(CardManagementActions.otpCommitFinished());
    yield cardActivationResponseHandler(response);
}

export function* reIssuesCard(action) {
    const { payload } = action;
    const response = yield call(cardManagementService.reIssuesCard, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(CardManagementActions.getUserAddressSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(CardManagementActions.getUserAddressError());
            break;
    }
}

export function* getMaskRequest(action) {
    const { payload } = action;
    const { cardId } = payload;
    const response = yield call(cardManagementService.maskCard, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.OTP_REQUIRED:
            const { refNo } = data;
            yield put(
                CardManagementActions.otpRequired({
                    refNo,
                    cardId,
                })
            );
            OTPController.show(OTPDialogType.MaskRequest);
            yield put(CardManagementActions.getMaskSuccess());

            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(CardManagementActions.getMaskError());
            break;
    }
}

export function* getMaskRequestCommit(action) {
    const { payload } = action;
    const { cardId } = payload;

    const response = yield call(cardManagementService.commitMaskCard, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                CardManagementActions.getMaskOTPSuccess({ ...data, cardId })
            );
            OTPController.hide();
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(CardManagementActions.getMaskOTPError());
            break;
    }
}

export function* cardLimitRequest(action) {
    const { payload } = action;

    const response = yield call(cardManagementService.cardLimit, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(CardManagementActions.cardLimitSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(CardManagementActions.cardLimitError());
            break;
    }
}

export function* getCardLimitRequest(action) {
    const { payload } = action;

    const response = yield call(cardManagementService.getCardLimit, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(CardManagementActions.getCardLimitSuccess(data));
            break;
        default:
            yield put(CardManagementActions.getCardLimitError());
            GlobalDialogController.showError({ errorCode: code });

            break;
    }
}
