import mapPaymentRequestList from "@helpers/mapPaymentRequestList";
import { PaymentRequestTypes } from "@redux/actions/payment-request";

import produce from "immer";
/* ------------- Initial State ------------- */
const initialState = {
    id: "",
    receiveId: "",
    refNo: "",
    status: false,
    loading: false,
    summaryBox: {
        spendAmount: null,
        totalSent: null,
        totalReceived: null,
    },
    outStandingList: {
        loading: false,
        list: [],
        lastId: "-1",
        modifyAt: "",
        hasLoadMore: true,
        payloadLastId: "-1", // use for call list at the first time
    },
    completeList: {
        loading: false,
        list: [],
        lastId: "-1",
        modifyAt: "",
        hasLoadMore: true,
    },
};

/* ------------- Reducer ------------- */
const paymentRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case PaymentRequestTypes.OTPRequired:
                const { paymentId, receiveId, refNo } = action.payload;
                draft.refNo = refNo;
                draft.id = paymentId;
                draft.receiveId = receiveId;
                draft.status = false;
                break;
            case PaymentRequestTypes.OTPCommit:
                draft.status = false;
                draft.loading = true;
                break;
            case PaymentRequestTypes.OTPCommitSuccess:
                draft.status = true;
                draft.loading = false;
                draft.refNo = "";
                draft.id = "";
                draft.receiveId = "";
                break;
            case PaymentRequestTypes.OTPCommitError:
                draft.status = false;
                draft.loading = false;
                break;
            case PaymentRequestTypes.ResetState:
                draft.status = false;
                draft.loading = false;
                draft.refNo = "";
                draft.id = "";
                draft.receiveId = "";
                draft.outStandingList = initialState.outStandingList;
                draft.completeList = initialState.completeList;
                draft.summaryBox = initialState.summaryBox;
                break;
            case PaymentRequestTypes.GetPaymentRequestList:
                draft.outStandingList.loading = true;
                draft.outStandingList.payloadLastId = action?.payload?.lastId;
                break;
            case PaymentRequestTypes.GetPaymentRequestListSuccess:
                draft.summaryBox = {
                    spendAmount: action?.payload?.spendAmount,
                    totalReceived: action?.payload?.totalReceived,
                    totalSent: action?.payload?.totalSent,
                };
                const outstandingItems = action?.payload?.outStanding;
                if (outstandingItems?.length > 0) {
                    const lastItem =
                        outstandingItems[outstandingItems.length - 1];
                    draft.outStandingList.lastId = lastItem?.paymentId;
                    draft.outStandingList.modifyAt = lastItem?.modifyDate;

                    const currentList =
                        action?.payload?.lastId === "-1"
                            ? []
                            : draft.outStandingList.list;
                    draft.outStandingList.list = mapPaymentRequestList(
                        currentList,
                        outstandingItems
                    );
                    draft.outStandingList.hasLoadMore = true;
                } else {
                    draft.outStandingList.hasLoadMore = false;
                    if (draft.outStandingList.payloadLastId === "-1") {
                        draft.outStandingList.list = [];
                    }
                }
                draft.outStandingList.loading = false;
                break;
            case PaymentRequestTypes.GetPaymentRequestListError:
                draft.outStandingList.loading = false;
                break;
            case PaymentRequestTypes.GetPaymentRequestCompleteList:
                draft.completeList.loading = true;
                break;
            case PaymentRequestTypes.GetPaymentRequestCompleteListSuccess:
                const completeItems = action?.payload?.completed;
                if (completeItems?.length > 0) {
                    const lastItem = completeItems[completeItems.length - 1];
                    draft.completeList.lastId = lastItem?.paymentId;
                    draft.completeList.modifyAt = lastItem?.modifyDate;

                    const currentCompleteList =
                        action?.payload?.lastId === "-1"
                            ? []
                            : draft.completeList.list;

                    draft.completeList.list = mapPaymentRequestList(
                        currentCompleteList,
                        completeItems
                    );
                    draft.completeList.hasLoadMore = true;
                } else {
                    draft.completeList.hasLoadMore = false;
                }

                draft.completeList.loading = false;
                break;
            case PaymentRequestTypes.GetPaymentRequestCompleteListError:
                draft.completeList.loading = false;
                break;
            default:
                break;
        }
    });

export default paymentRequestReducer;
