import { call, put, select } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import moveMoneyService from "@services/move-money";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import payeeService from "@services/payee";
import {
    PayeeDestinationType,
    PRODUCT_TYPES,
    RESPONSE_CODE,
} from "@config/constants";
import LspTranslation from "@components/LspTranslation";
import tommy from "@assets/images/no_hope_with_status.png";
import accountAction from "../actions/account";
import moveMoneyAction, { MoveMoneyActionType } from "../actions/move-money";

const unifyResponseCode = (code) => {
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
        case RESPONSE_CODE.SUCCESS_V2:
            return RESPONSE_CODE.SUCCESS;
        case RESPONSE_CODE.INCORRECT_VALUE:
        case RESPONSE_CODE.MISMATCH_VALUE:
            return RESPONSE_CODE.INCORRECT_VALUE;
        default:
            return code;
    }
};

const getDialogOpened = (state) => state.otpDialog.opened;
const getExistingPayee = (state) => state.moveMoney.existingPayee;
const getSystemConfig = (state) => state.systemParams.info.conf;

export function* moveMoneySaga(action) {
    const { type, payload } = action;

    const existingPayee = yield select(getExistingPayee);

    if (
        existingPayee &&
        [
            MoveMoneyActionType.TransferToDebitCardRequest,
            MoveMoneyActionType.TransferToAccountNumberRequest,
        ].includes(type)
    ) {
        const savePayeePayload = {};
        if (payload.target.cardNumber) {
            savePayeePayload.cards = [
                {
                    ...payload.target,
                    payeeId: existingPayee.payeeId,
                },
            ];
            savePayeePayload.destinationType = PayeeDestinationType.Card;
        } else {
            savePayeePayload.accounts = [
                {
                    ...payload.target,
                    payeeId: existingPayee.payeeId,
                },
            ];
            savePayeePayload.destinationType = PayeeDestinationType.BankAccount;
        }

        yield call(payeeService.savePayee, savePayeePayload);
    }

    let response;

    switch (type) {
        case MoveMoneyActionType.SubmitOTP:
            response = yield call(moveMoneyService.submitOTP, payload);
            break;
        case MoveMoneyActionType.TransferToDebitCardRequest:
            response = yield call(
                moveMoneyService.transferToDebitCard,
                payload
            );
            break;
        case MoveMoneyActionType.TransferToAccountNumberRequest:
            response = yield call(
                moveMoneyService.transferToAccountNumber,
                payload
            );
            break;
        case MoveMoneyActionType.TransferToCreditCardRequest:
            response = yield call(
                moveMoneyService.transferToCreditCard,
                payload
            );
            break;
        default:
            return;
    }

    const opened = yield select(getDialogOpened);

    const { code, data } = response.data;
    const unifiedCode = unifyResponseCode(code);
    switch (unifiedCode) {
        case RESPONSE_CODE.SUCCESS:
            if (opened) {
                OTPController.hide();
            }
            yield put(moveMoneyAction.transferSuccess(data));
            yield put(accountAction.getAccountListRequest());
            break;

        case RESPONSE_CODE.OTP_REQUIRED:
            yield put(moveMoneyAction.transferWithOTP(data));
            if (opened) {
                OTPController.hide();
            } else {
                OTPController.show(OTPDialogType.MoveMoney);
            }
            break;
        case RESPONSE_CODE.FAST_TRANSFER_ERROR_CODE:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconCustom: tommy,
                    header: "master:transfer_napas_failed_popup_title",
                    content: "master:transfer_napas_failed_popup_desc",
                    button: "master:transfer_napas_failed_popup_CTA",
                    navigatePathForButton: "/spend-account/move-money",
                },
                onClose: GlobalDialogController.hide(),
            });
            yield put(moveMoneyAction.transferError());
            break;
        case RESPONSE_CODE.INCORRECT_VALUE:
        case RESPONSE_CODE.OTP_EXPIRED:
            yield put(moveMoneyAction.submitOTPError());
            GlobalDialogController.showError({
                errorCode: unifiedCode,
                type: GlobalDialogTypes.FinancialError,
            });
            break;
        case RESPONSE_CODE.DUPLICATE_FIELD: // Payee existing
            if (opened) {
                OTPController.hide();
            }
            const existingPayeeResponse = yield call(
                payeeService.getPayee,
                data
            );
            if (existingPayeeResponse.ok) {
                yield put(
                    moveMoneyAction.getExistingPayeeSuccess(
                        existingPayeeResponse.data.data
                    )
                );
            }
            break;
        case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
        case RESPONSE_CODE.OVER_ALLOWED_MAX_AMOUNT:
        case RESPONSE_CODE.UNDER_MIN_TRANSFER:
        case RESPONSE_CODE.NOT_ALLOWED:
            if (opened) {
                OTPController.hide();
            }
            yield put(moveMoneyAction.transferErrorWithAmount(unifiedCode));
            break;
        case RESPONSE_CODE.AUTH_BIOS:
            if (opened) {
                OTPController.hide();
            }

            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.AUTH_BIOS,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(moveMoneyAction.transferError());
            break;

        case RESPONSE_CODE.RE_ENROLL_ERROR:
        case RESPONSE_CODE.REQUIRED_ENROLL:
            if (opened) {
                OTPController.hide();
            }

            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.REQUIRED_ENROLL,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(moveMoneyAction.transferError());
            break;

        case RESPONSE_CODE.FOREIGNER:
            if (opened) {
                OTPController.hide();
            }

            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.FOREIGNER,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(moveMoneyAction.transferError());
            break;

        case RESPONSE_CODE.RE_ENROLL:
            if (opened) {
                OTPController.hide();
            }

            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.RE_ENROLL,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(moveMoneyAction.transferError());
            break;
        default:
            if (opened) {
                OTPController.hide();
            }
            GlobalDialogController.showError({
                errorCode: unifiedCode,
                data,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(moveMoneyAction.transferError());
            break;
    }
}

function* responseHandlerForTransferByPhone({ code, data }) {
    const systemConfig = yield select(getSystemConfig);

    const opened = yield select(getDialogOpened);

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            if (opened) {
                OTPController.hide();
            }
            yield put(moveMoneyAction.transferSuccess(data));
            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            yield put(moveMoneyAction.transferWithOTP(data));
            if (!opened) {
                OTPController.show(OTPDialogType.MoveMoneyByPhone);
            }
            break;
        case RESPONSE_CODE.UNDER_MIN_TRANSFER:
            const limitMinAmount =
                systemConfig.MinAmountLimitPerTransfer || 1000;
            const contentLimitMinAmount = (
                <LspTranslation
                    i18nKey="spend_msg_for_least_amount"
                    replacingKeys={[`$${limitMinAmount}`]}
                />
            );
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "serverunavailable",
                    header: "lb_we_sorry",
                    content: contentLimitMinAmount,
                    button: "lb_ok",
                },
            });
            yield put(moveMoneyAction.transferError());
            break;
        case RESPONSE_CODE.MAX_AMOUNT_TRANSFER:
            const limitMaxAmount = systemConfig.MaxAmountPayanyoneTransfer || 0;
            const contentLimitMaxAmount = (
                <LspTranslation
                    i18nKey="payAnyone:popup_max_amount_pao"
                    replacingKeys={[`$${limitMaxAmount}`]}
                />
            );

            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "serverunavailable",
                    header: "lb_we_sorry",
                    content: contentLimitMaxAmount,
                    button: "lb_ok",
                },
            });
            yield put(moveMoneyAction.transferError());
            break;
        case RESPONSE_CODE.MAX_LIMIT_AMOUNT_PAY_ANYONE:
            const limitAmount =
                systemConfig.PayanyoneToPhoneAmountLimitPerDay || 0;
            const content = (
                <LspTranslation
                    i18nKey="payAnyone:popup_exceed_limit_pao"
                    replacingKeys={[`$${limitAmount}`]}
                />
            );
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "serverunavailable",
                    header: "lb_we_sorry",
                    lspTranslation: content,
                    button: "lb_ok",
                },
            });
            yield put(moveMoneyAction.transferError());
            break;
        default:
            GlobalDialogController.showFinancialError({ errorCode: code });
            yield put(moveMoneyAction.transferError());
            break;
    }
}

export function* transferByPhone(action) {
    const { payload } = action;

    const response = yield call(
        moveMoneyService.transferByPhoneNumber,
        payload
    );
    yield responseHandlerForTransferByPhone(response.data);
}

export function* commitOTPTransferByPhone(action) {
    const { payload } = action;
    const response = yield call(
        moveMoneyService.commitOtpTransferByPhoneNumber,
        { ...payload, paoType: PRODUCT_TYPES.PAY_ANYONE }
    );
    yield responseHandlerForTransferByPhone(response.data);
}
