import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import { makeStyles, Icon } from "@material-ui/core";
import LspButton from "@components/LspButton";
import { useEffect, useState, useCallback, useRef } from "react";
import { isEmpty, isFunction } from "lodash";
import overdraftService from "@services/overdraft";
import { OVERDRAFT_KEY, RESPONSE_CODE } from "@config/constants";

import InfoTooltip from "@components/InfoTooltip";
import useNumber from "@helpers/useNumber";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Skeleton } from "@material-ui/lab";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OverdraftItem from "./OverdraftItem";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    header: {
        display: "flex",
        marginBottom: theme.spacing(2),
    },
    headerContent: {
        flex: 1,
    },
    headerAction: {},

    overdraftContent: {
        marginBottom: theme.spacing(3),
        flex: 1,
    },
    overdraftContentList: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    overdraftContentItem: {
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        "&:last-child": {
            borderBottom: "unset",
        },
    },
    overdraftFooter: {
        position: "sticky",
        bottom: 0,
        width: "100%",
        background: theme.palette.background.default,
    },
    overdraftFooterContent: {
        display: "flex",
        flexDirection: "row",
    },

    overdraftFooterTitle: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    overdraftFooterAmount: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
    },
}));

const parentName = "overdraft";

const OverdraftCreate = ({ onInit }) => {
    const classes = useStyles();
    const firstLoaded = useRef(false);

    const [overdraftData, setOverdraftData] = useState({
        items: [],
    });
    const [selectedItem, setSelectedItem] = useState({});
    const { formatNumber } = useNumber();
    const [loading, setLoading] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);

    const [hasMore, setHasMore] = useState(true);
    const [overdraftPreview, setOverdraftPreview] = useState({});

    const [page, setPage] = useState({
        page: 0,
        size: 20,
    });

    const { t } = useTranslation();
    const history = useHistory();
    const { termId } = useParams();

    const handleGetODDetail = useCallback(async (selectedTd) => {
        setPreviewLoading(true);
        const result = await overdraftService.getOverdraftPreview({
            termId: selectedTd?.termId,
        });
        setPreviewLoading(false);
        const { data, code } = result?.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setOverdraftPreview(data);
                break;

            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, []);

    const handleGetOverdraftList = useCallback(async () => {
        setLoading(true);
        const result = await overdraftService.getOverdraftList(page);
        setLoading(false);
        const { data, code } = result?.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setOverdraftData((prev) => ({
                    ...prev,
                    ...data,
                    items: prev.items.concat(data.items || []),
                }));
                if (termId && !isEmpty(data?.items)) {
                    const findTd = (data?.items || []).find(
                        (item) => item.termId === +termId
                    );
                    if (findTd) {
                        setSelectedItem(findTd);
                        handleGetODDetail(findTd);
                    }
                }

                if (data?.items.length === 0) {
                    setHasMore(false);
                } else {
                    setPage((prev) => ({
                        ...prev,
                        page: ++prev.page,
                    }));
                }

                break;

            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [handleGetODDetail, page, termId]);

    useEffect(() => {
        if (!firstLoaded.current) {
            handleGetOverdraftList();

            if (onInit && isFunction(onInit)) {
                onInit();
            }
            firstLoaded.current = true;
        }
    }, [handleGetOverdraftList, onInit, overdraftData]);

    const handleSubmit = () => {
        if (selectedItem?.termId) {
            history.push(
                `/${parentName}/${OVERDRAFT_KEY.PREVIEW}/${selectedItem.termId}`
            );
        }
    };

    const handleOpenTD = () => {
        history.push("/term-deposit/open");
    };

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: handleGetOverdraftList,
        scrollContainer: "window",
    });

    return (
        <>
            <Content>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <div className={classes.headerContent}>
                            <LspTypography variant="subheading2">
                                {t("overdraft:overdraft_register_header")}
                            </LspTypography>
                            <LspTypography variant="body3" color="grey">
                                {t("overdraft:overdraft_register_description")}
                            </LspTypography>
                        </div>

                        <div className={classes.headerAction}>
                            <LspButton
                                variant="plain"
                                buttonProps={{
                                    startIcon: (
                                        <Icon
                                            className={`font-icon icon-plus `}
                                        />
                                    ),
                                }}
                                onClick={handleOpenTD}
                            >
                                {t("overdraft:overdraft_register_header_cta")}
                            </LspButton>
                        </div>
                    </div>
                    <div className={classes.overdraftContent}>
                        <div
                            className={classes.overdraftContentList}
                            ref={infiniteRef}
                        >
                            {(overdraftData?.items || []).map((overdraft) => (
                                <div className={classes.overdraftContentItem}>
                                    <OverdraftItem
                                        key={overdraft.id}
                                        item={overdraft}
                                        onSelected={(data) => {
                                            setSelectedItem(data);
                                            handleGetODDetail(data);
                                        }}
                                        checked={
                                            selectedItem.termId ===
                                            overdraft.termId
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {!isEmpty(selectedItem) && (
                        <div className={classes.overdraftFooter}>
                            <div className={classes.overdraftFooterContent}>
                                <div className={classes.overdraftFooterTitle}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_register_limit"
                                        )}
                                    </LspTypography>
                                    <InfoTooltip
                                        color="black"
                                        title={
                                            <>
                                                <LspTypography
                                                    variant="body3"
                                                    color="white"
                                                >
                                                    {t(
                                                        "overdraft:overdraft_minimum_limit"
                                                    )}
                                                    :
                                                    {formatNumber(
                                                        overdraftData?.minAmount
                                                    )}
                                                    đ
                                                </LspTypography>
                                                <LspTypography
                                                    variant="body3"
                                                    color="white"
                                                >
                                                    {t(
                                                        "overdraft:overdraft_maximum_limit"
                                                    )}
                                                    :
                                                    {formatNumber(
                                                        overdraftData?.maxAmount
                                                    )}
                                                    đ
                                                </LspTypography>
                                            </>
                                        }
                                    />
                                </div>
                                {overdraftPreview && (
                                    <div
                                        className={
                                            classes.overdraftFooterAmount
                                        }
                                    >
                                        <LspTypography variant="heading1">
                                            {!previewLoading &&
                                                formatNumber(
                                                    overdraftPreview?.approvalAmount
                                                )}
                                            {previewLoading && (
                                                <Skeleton
                                                    variant="text"
                                                    width={100}
                                                />
                                            )}
                                        </LspTypography>
                                    </div>
                                )}
                            </div>

                            <LspButton fullWidth onClick={handleSubmit} mb={2}>
                                {t("overdraft:overdraft_register_cta")}
                            </LspButton>
                        </div>
                    )}
                </div>
            </Content>
        </>
    );
};

export default OverdraftCreate;
