import LspButtonIconLink from "@components/LspButtonIconLink";
import { REGULATION_TYPE, RESPONSE_CODE } from "@config/constants";
import GoalSaveDetailBody from "@containers/GoalSave/GoalSaveDetail/GoalSaveDetailBody";
import GoalSaveDetailConfirmDelete from "@containers/GoalSave/GoalSaveDetail/GoalSaveDetailConfirmDelete";
import GoalSaveDetailForm from "@containers/GoalSave/GoalSaveDetail/GoalSaveDetailForm";
import GoalSaveDetailHeader from "@containers/GoalSave/GoalSaveDetail/GoalSaveDetailHeader";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useForeignerDialog from "@helpers/useForeignerDialog";
import useNumber from "@helpers/useNumber";
import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import accountService from "@services/account";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: 424,
        width: "90%",
        margin: "auto",
        borderRadius: theme.shape.radiusLevels[1],
        overflow: "hidden",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2, 2),
    },
}));

const GoalSaveDetail = ({
    info,
    onClose,
    resetState,
    spendAccountObj,
    piggyAccountObj,
    refreshGoalList,
    isOnTrack,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const [progressing, setProgressing] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [contributeToSpend, setContributeToSpend] = useState(true);
    const [detail, setDetail] = useState(info);
    const { dialog } = useForeignerDialog();

    const goalHasMoney = useMemo(() => info?.contributedAmount > 0, [
        info?.contributedAmount,
    ]);
    const [goalBackground, setGoalBackground] = useState(info?.linkImage || "");

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    // ========== Delete Goal Save ==========

    const deleteGoal = useCallback(async () => {
        setProgressing(true);

        const { data } = await accountService.deleteGoalSave({
            planId: info?.planId,
        });

        if (unmounted.current) return;

        setProgressing(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                let msg = "";
                if (goalHasMoney) {
                    // Have money in deleted GS
                    msg = t("gs_msg_money_move_to")
                        .replace("%@", formatNumber(info?.contributedAmount))
                        .replace(
                            "%s@",
                            contributeToSpend
                                ? t("transaction_list_lb_spend_account")
                                : t("goal_save_account_piggy_title")
                        );
                } else {
                    // No money in deleted GS
                    msg = t("gs_msg_deleted").replace("%@", info?.planName);
                }
                AlertController.show(msg, AlertType.SUCCESS);
                resetState(true);
                break;
            case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    }, [
        goalHasMoney,
        resetState,
        t,
        contributeToSpend,
        info,
        formatNumber,
        dialog,
    ]);

    const toggleConfirmDelete = () => {
        setIsConfirming((prev) => !prev);
    };
    const toggleEdit = () => {
        setIsEditing((prev) => !prev);
    };

    const yesDeleteWithGoalNoMoney = () => {
        toggleConfirmDelete();
        deleteGoal();
    };

    const contributeMoneyBeforeDeleteGoal = useCallback(
        async (isTransferToSpend) => {
            setProgressing(true);

            const payload = {
                sourceGoalPlanId: info?.planId,
                transferAmount: info?.contributedAmount,
                destinationPlanId: isTransferToSpend
                    ? spendAccountObj?.planId
                    : piggyAccountObj?.planId,
            };

            const { data } = await accountService.contributeGoalSave(payload);

            if (unmounted.current) return;
            setProgressing(false);

            switch (data.code) {
                case RESPONSE_CODE.SUCCESS:
                    deleteGoal();
                    break;
                case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                    dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: data.code,
                    });
                    break;
            }
        },
        [spendAccountObj, piggyAccountObj, info, deleteGoal, dialog]
    );

    const yesDeleteWithGoalHasMoney = (destination) => {
        toggleConfirmDelete();
        setContributeToSpend(destination === "spend");
        contributeMoneyBeforeDeleteGoal(destination === "spend");
    };

    // ========== Update Goal Save Info ==========

    const onUploadBackground = (imageUrl) => {
        setGoalBackground(imageUrl);
    };

    const onCloseEdit = () => {
        toggleEdit();
        setGoalBackground(info?.linkImage ? info?.linkImage : "");
    };

    const onUpdateGoal = async (updatedInfo) => {
        const payload = {
            planId: info?.planId,
            planName: info?.planName,
            contributedAmount: info?.contributedAmount,
            goalCategory: info?.goalCategory,
            startDate: updatedInfo?.startDate,
            goalAmount: updatedInfo?.targetAmount,
            recurringAmount: updatedInfo?.recurringAmount,
            targetDate: updatedInfo?.targetDate,
            recurringType: updatedInfo?.recurringType?.id,
        };
        const { data } = await accountService.updateGoalSave(payload);
        if (unmounted.current) return;

        setProgressing(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setDetail({
                    ...info,
                    ...updatedInfo,
                    linkImage: goalBackground,
                    nextDateContribute: updatedInfo?.startDate,
                    FERecurringType: updatedInfo?.recurringType?.label,
                    recurringType: updatedInfo?.recurringType?.id,
                });
                toggleEdit();
                refreshGoalList();
                break;
            case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const uploadGoalBackground = async ({ planId, background }) => {
        const payload = {
            goalSaveId: planId,
            avatar: background,
        };

        const { data } = await accountService.updateBackgroundGoalSave(payload);
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                // nothing to do
                break;
            case RESPONSE_CODE.CANT_WORK_THIS_TIME:
                dialog(REGULATION_TYPE.GS_CANNOT_CONTRIBUTE);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const onSave = async (updatedInfo) => {
        setProgressing(true);

        if (goalBackground !== "" && goalBackground !== info?.linkImage) {
            uploadGoalBackground({
                planId: info?.planId,
                background: goalBackground,
            });
        }

        onUpdateGoal(updatedInfo);
    };

    return (
        <Paper className={classes.wrapper}>
            {!isConfirming && (
                <>
                    <GoalSaveDetailHeader
                        detail={detail}
                        isEditing={isEditing}
                        goalBackground={goalBackground}
                        onUpdateGoalBackground={onUploadBackground}
                        isNotOnTrack={!isOnTrack}
                    />
                    {!isEditing && (
                        <>
                            <GoalSaveDetailBody
                                toggleEdit={toggleEdit}
                                detail={detail}
                            />

                            <Box className={classes.footer}>
                                <LspButtonIconLink
                                    startIcon={
                                        <Icon className="font-icon icon-trashbin" />
                                    }
                                    onClick={toggleConfirmDelete}
                                    color="error"
                                    progressing={progressing}
                                    buttonProps={{
                                        datatestid: "deleteGoalBtn",
                                    }}
                                >
                                    {t("gs_button_delete")}
                                </LspButtonIconLink>
                                <LspButtonIconLink
                                    startIcon={
                                        <Icon className="font-icon icon-close" />
                                    }
                                    onClick={onClose}
                                    buttonProps={{
                                        datatestid: "closeBtn",
                                    }}
                                >
                                    {t("lb_close")}
                                </LspButtonIconLink>
                            </Box>
                        </>
                    )}
                    {isEditing && (
                        <GoalSaveDetailForm
                            onClose={onCloseEdit}
                            detail={detail}
                            onSave={onSave}
                            submitting={progressing}
                            isNotOnTrack={!isOnTrack}
                        />
                    )}
                </>
            )}
            {isConfirming && (
                <GoalSaveDetailConfirmDelete
                    amount={detail?.contributedAmount}
                    planName={detail?.planName}
                    onConfirm={
                        goalHasMoney
                            ? yesDeleteWithGoalHasMoney
                            : yesDeleteWithGoalNoMoney
                    }
                    onClose={() => setIsConfirming((prev) => !prev)}
                    goalHasMoney={goalHasMoney}
                />
            )}
        </Paper>
    );
};

export default memo(GoalSaveDetail);
