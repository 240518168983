import LspButton from "@components/LspButton";

import { makeStyles } from "@material-ui/core";
import { isFunction } from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },

    info: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(3),
    },
    termAndConditionAction: {},
    termAndConditionStatus: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(4),
    },
    termAndConditionStatusIMG: {
        width: 180,
        marginBottom: theme.spacing(5),
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    noContentBr: {
        backgroundColor: "unset",
    },
}));
const OverdraftStatus = ({
    src,
    onSubmit,
    title,
    content,
    actionTitle,
    action,
    noContentBr,
    wrapperContentClass,
}) => {
    const classes = useStyles();

    const handleSubmit = () => {
        if (isFunction(onSubmit)) {
            onSubmit();
        }
    };
    return (
        <>
            <div className={classes.container}>
                <div className={classes.termAndConditionStatus}>
                    <div className={classes.termAndConditionStatusIMG}>
                        <img src={src} alt="Tomi Status" />
                    </div>
                    {title}
                </div>
                {content && (
                    <div
                        className={clsx(
                            classes.info,
                            noContentBr && classes.noContentBr,
                            wrapperContentClass && wrapperContentClass
                        )}
                    >
                        {content}
                    </div>
                )}
                {action && (
                    <LspButton fullWidth onClick={handleSubmit}>
                        {actionTitle}
                    </LspButton>
                )}
            </div>
        </>
    );
};

export default OverdraftStatus;
