import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import Interweave from "interweave";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles, Typography } from "@material-ui/core";
import Content from "@components/Content";
import PageHeader from "@components/PageHeader";

import moveMoneyAction from "@redux/actions/move-money";
import {
    FetchStatus,
    MoveMoneyType,
    PayAnyoneStatus,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
} from "@config/constants";
import accountService from "@services/account";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

import ShareLinkPayment from "@containers/SpendAccount/MoveMoney/PayAnyone/ShareLinkPayment";
import DoubleButtons from "@components/DoubleButtons";
import PaymentDetailFetching from "@containers/SpendAccount/PendingPayment/PaymentDetailFetching";
import PaymentBoxInfo from "@containers/SpendAccount/PendingPayment/PaymentBoxInfo";
import LspButton from "@components/LspButton";
import AlertController from "@helpers/controllers/AlertController";
import PaymentCancelConfirmation from "@containers/SpendAccount/PendingPayment/PaymentCancelConfirmation";
import accountAction from "@redux/actions/account";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
    },
    subtitle: {
        color: theme.palette.blue.main,
        fontWeight: 600,
        marginTop: theme.spacing(2),
    },
    footer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(4),
        },
    },
    text: {
        color: theme.palette.magicalGrey[400],
        marginTop: theme.spacing(2),
        "&.warning": {
            color: theme.palette.orange.main,
        },
        "&.expired": {
            color: theme.palette.gold.main,
        },
        "&.failed": {
            color: theme.palette.orange.main,
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(4),
        },
    },
    highlight: {
        "& b:nth-child(2)": {
            color: theme.palette.orange.main,
        },
    },
}));

const PaymentDetail = ({
    isCreate,
    txnId,
    setExternalData,
    updatePaymentStatus,
    progressing,
    updateStatus,
    reset,
    updatedAction,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "payAnyone");
    const history = useHistory();

    const [status, setStatus] = useState("");
    const [noticeText, setNoticeText] = useState("");
    const [detail, setDetail] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [isShareLink, setIsShareLink] = useState(false);
    const [hasSecretCode, setHasSecretCode] = useState(false);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const mapContent = useCallback(
        (paymentDetail) => {
            if (!paymentDetail) return;
            const paymentStatus = paymentDetail?.status.toLowerCase() || "";
            setStatus(paymentStatus);

            switch (paymentDetail?.status) {
                case PayAnyoneStatus.Active:
                    setIsShareLink(true);
                    let activeContent = t("payAnyone:pending_details_note");
                    activeContent = activeContent.replace(
                        "%@",
                        paymentDetail?.openDate.split(" ")[0]
                    );

                    activeContent = activeContent.replace(
                        "%@",
                        paymentDetail?.expireDate.split(" ")[0]
                    );
                    setNoticeText(activeContent);
                    setHasSecretCode(!!paymentDetail?.secretCode);
                    break;
                case PayAnyoneStatus.Expired:
                    setNoticeText(t("payAnyone:status_expired_detail"));
                    break;
                case PayAnyoneStatus.Failed:
                    setNoticeText(t("payAnyone:status_failed_detail"));
                    break;
                case PayAnyoneStatus.Cancelled:
                default:
                    setNoticeText(t("payAnyone:status_cancelled_detail"));
                    break;
            }
        },
        [t]
    );

    const getPaymentDetail = useCallback(
        async (id) => {
            if (!id || fetching) return;
            setFetching(true);

            const payload = {
                paId: id,
            };

            const response = await accountService.getPaymentDetailById(payload);
            const { code, data } = response.data;

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setDetail(data.detail);
                    mapContent(data.detail);
                    // const test = data.detail;
                    // test.isShortExpired = true;
                    // setDetail(test);
                    // mapContent(test);
                    setFetching(false);
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [fetching, mapContent]
    );

    const onCompleteHandler = useCallback(() => {
        history.push(
            `/spend-account/${SpendAccountNavigationKey.PendingPayment}`
        );
    }, [history]);

    const updatePaymentStatusRequest = async (action) => {
        const payload = {
            paId: detail?.paId,
            status: action || PayAnyoneStatus.Cancelled,
        };

        updatePaymentStatus(payload);
    };

    const resendPayment = () => {
        setExternalData({
            phoneNumber: detail?.phoneNumber,
            amount: detail?.amount,
            description: detail?.desc,
            paId: detail?.paId,
        });
        history.push(
            `/spend-account/${SpendAccountNavigationKey.MoveMoney}/${MoveMoneyType.PayAnyone}`
        );
    };

    const deletePayment = () => {
        updatePaymentStatusRequest(PayAnyoneStatus.Deleted);
    };

    const onConfirmCancelPaymentHandler = (isDelete) => {
        updatePaymentStatusRequest(
            isDelete ? PayAnyoneStatus.Deleted : PayAnyoneStatus.Cancelled
        );
    };

    const confirmCancelPayment = (event) => {
        event.preventDefault();

        GlobalDialogController.show({
            component: () => (
                <PaymentCancelConfirmation
                    classes={classes}
                    onConfirm={onConfirmCancelPaymentHandler}
                />
            ),
        });
    };

    const onCloseHandler = () => {
        history.push("/home");
    };

    useEffect(() => {
        if (!detail) {
            getPaymentDetail(txnId);
        }
    }, [getPaymentDetail, txnId, detail]);

    useEffect(() => {
        if (updateStatus === FetchStatus.Success) {
            GlobalDialogController.hide();
            const content =
                updatedAction === PayAnyoneStatus.Deleted
                    ? t("payAnyone:dropdown_payment_delete")
                    : t("payAnyone:dropdown_payment_cancel");
            AlertController.show(content);
            onCompleteHandler();
        }

        return () => {};
    }, [updateStatus, updatedAction, onCompleteHandler, t]);

    return (
        <>
            <Content size="lg">
                <PageHeader
                    backFunc={
                        !isCreate && onCompleteHandler
                            ? onCompleteHandler
                            : null
                    }
                >
                    {t("payAnyone:payment_detail_header")}
                </PageHeader>
            </Content>
            <Content size="sm" boxProps={{ className: classes.wrapper }}>
                {fetching && <PaymentDetailFetching />}
                {!fetching && (
                    <>
                        {isCreate && (
                            <div>
                                <LspTypography variant="title2">
                                    {t("payAnyone:pending_payment_title")}
                                </LspTypography>
                                <Typography className={classes.subtitle}>
                                    {t("payAnyone:pending_payment_content")}
                                </Typography>
                            </div>
                        )}
                        <PaymentBoxInfo
                            detail={detail}
                            hasSecretCode={hasSecretCode}
                            status={status}
                        />
                        {isShareLink && <ShareLinkPayment detail={detail} />}
                        <Typography
                            className={`${classes.text} ${status} ${
                                detail?.isShortExpired ? classes.highlight : ""
                            }`}
                        >
                            <Interweave content={noticeText} />
                        </Typography>
                        <div className={classes.footer}>
                            {/*  For others status from payment detail */}
                            {!isShareLink && (
                                <DoubleButtons
                                    progressing={progressing}
                                    secondaryButton={{
                                        label: t("payment_lb_btn_delete"),
                                        onClick: deletePayment,
                                    }}
                                    primaryButton={{
                                        label: t("lb_resend"),
                                        onClick: resendPayment,
                                    }}
                                />
                            )}
                            {/* For active status from create flow */}
                            {isCreate && (
                                <LspButton fullWidth onClick={onCloseHandler}>
                                    {t("lb_close")}
                                </LspButton>
                            )}
                            {/* For active status from payment detail  */}
                            {!isCreate && isShareLink && (
                                <Link
                                    to="#"
                                    href="#"
                                    onClick={confirmCancelPayment}
                                >
                                    {t("payAnyone:lb_cancel_payment")}
                                </Link>
                            )}
                        </div>
                    </>
                )}
            </Content>
        </>
    );
};

const stateProps = (state) => ({
    progressing: state.account.paymentDetail.fetching,
    updateStatus: state.account.paymentDetail.status,
    updatedAction: state.account.paymentDetail.action,
});

const dispatchProps = (dispatch) => ({
    setExternalData: (payload) =>
        dispatch(moveMoneyAction.setExternalData(payload)),
    updatePaymentStatus: (payload) =>
        dispatch(accountAction.updatePaymentStatusRequest(payload)),
    reset: () => dispatch(accountAction.resetPaymentDetailData()),
});

export default connect(stateProps, dispatchProps)(PaymentDetail);
