/* eslint-disable react/no-array-index-key */
import { memo, useMemo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, Grid, makeStyles } from "@material-ui/core";
import requestDocumentService from "@services/request-document";
import LspCheckbox from "@components/LspCheckbox";
import LspButton from "@components/LspButton";
import Loading from "@components/Loading";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import RequestDocumentActions from "@redux/actions/request-document";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import ChooseAccountReview from "./ChooseAccountReview";

const useStyles = makeStyles((theme) => ({
    content: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    item: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    nextBtn: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > button": {
            width: "50%",
            margin: "auto",
        },
    },
}));

const ChooseAccount = ({ onBack, documentInfo, onNext, resetDocumentInfo }) => {
    const { t, i18n } = useTranslation();
    const [selectedList, setSelectedList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [showReview, setShowReview] = useState(false);
    const [fetching, setFetching] = useState(true);
    const { formatNumber } = useNumber();
    const classes = useStyles();

    const totalAmount = useMemo(() => {
        const content = selectedList.reduce(
            (acc, curr) => acc + (curr.balance || 0),
            0
        );
        return content;
    }, [selectedList]);

    const listIDSelected = useMemo(() => {
        const content = selectedList?.map((item) => item.FEID);
        return content;
    }, [selectedList]);

    const getDocumentAccount = useCallback(async () => {
        const { data } = await requestDocumentService.getDocumentAccount(
            i18n.language,
            documentInfo.requestId
        );
        setFetching(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                const accountArr = data.data.accounts;
                for (let i = 0; i < accountArr.length; i++) {
                    accountArr[i].FEID = i;
                    accountArr[i].FEChecked = false;
                    accountArr[i].FEDisabled = accountArr[i].balance <= 0;
                    if (accountArr[i].childAccounts) {
                        const listChildAccLength =
                            accountArr[i].childAccounts.length;
                        for (let iii = 0; iii < listChildAccLength; iii++) {
                            accountArr[i].childAccounts[
                                iii
                            ].FEID = `${i}${iii}`;
                            accountArr[i].childAccounts[iii].FEChecked = false;
                            accountArr[i].childAccounts[iii].FEDisabled =
                                accountArr[i].childAccounts[iii].balance <= 0;
                        }
                    }
                }
                setDataList(accountArr);
                if (documentInfo.accountList.length > 0) {
                    let accountSelected = [...documentInfo.accountList];
                    const idTermDepositSelected = accountSelected.findIndex(
                        (item) => item.childAccounts
                    );
                    const idTermDepositInit = accountArr.findIndex(
                        (item) => item.childAccounts
                    );
                    if (idTermDepositSelected > -1) {
                        accountSelected = accountSelected.concat(
                            accountSelected[idTermDepositSelected].childAccounts
                        );
                        if (
                            accountArr[idTermDepositInit].childAccounts
                                .length !==
                            accountSelected[idTermDepositSelected].childAccounts
                                .length
                        ) {
                            accountSelected.splice(idTermDepositSelected, 1);
                        }
                    }

                    setSelectedList(accountSelected);

                    setShowReview(true);
                }

                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });

                break;
        }
    }, [documentInfo.requestId, i18n, documentInfo.accountList]);

    const onCheckBox = (item) => {
        const listIdChild = item.childAccounts
            ? item.childAccounts.map((e) => e.FEID)
            : [];
        let listSelected = [...selectedList];
        if (item.childAccounts) {
            // clear all when check all Term Deposit
            listSelected = listSelected.filter((elm) => {
                return !listIdChild.includes(elm.FEID);
            });
        }

        const existedId = listSelected.findIndex((elm) => {
            return elm.FEID === item.FEID;
        });

        if (existedId > -1) {
            listSelected.splice(existedId, 1);
            const idTermDeposit = listSelected.findIndex(
                (value) => value.childAccounts?.length
            );

            if (idTermDeposit > -1) {
                const listIdChildArr = listSelected[
                    idTermDeposit
                ]?.childAccounts.map((e) => e.FEID);

                if (listIdChildArr?.includes(item.FEID)) {
                    listSelected.splice(idTermDeposit, 1);
                }
            }
        } else {
            listSelected.push(item);
            if (item.childAccounts) {
                listSelected = listSelected.concat(item.childAccounts);
            }
        }

        setSelectedList(listSelected);
    };

    const goBeforePage = () => {
        if (showReview) {
            setShowReview(false);
            return;
        }
        resetDocumentInfo();
        onBack();
    };

    useEffect(() => {
        getDocumentAccount();
    }, [getDocumentAccount]);

    return (
        <Content size="sm">
            {fetching && <Loading />}
            {!fetching && (
                <>
                    <PageHeaderBack onBack={goBeforePage}>
                        {documentInfo.desc}
                    </PageHeaderBack>
                    <LspTypography
                        variant="body2"
                        color="grey"
                        className={classes.content}
                    >
                        {showReview
                            ? t("rq_document_txt_confirm_choose_accounts")
                            : t("rq_document_txt_choose_accounts")}
                    </LspTypography>

                    {!showReview && (
                        <>
                            {dataList?.map((item, key) => (
                                <div key={key} className={classes.item}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item xs={8}>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                <LspCheckbox
                                                    onChange={() =>
                                                        onCheckBox(item)
                                                    }
                                                    checked={listIDSelected.includes(
                                                        item.FEID
                                                    )}
                                                    disabled={
                                                        item.balance === 0
                                                    }
                                                />
                                                {item.name}
                                            </LspTypography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ textAlign: "right" }}
                                        >
                                            {!item.childAccounts && (
                                                <LspTypography
                                                    variant="subheading2"
                                                    color="black"
                                                >
                                                    {formatNumber(item.balance)}
                                                </LspTypography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {item.childAccounts && (
                                        <Box pl={4}>
                                            {item.childAccounts.map(
                                                (elm, k) => (
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        key={k}
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={8}>
                                                            <LspTypography
                                                                variant="body1"
                                                                color="grey"
                                                            >
                                                                <LspCheckbox
                                                                    onChange={() =>
                                                                        onCheckBox(
                                                                            elm
                                                                        )
                                                                    }
                                                                    checked={listIDSelected.includes(
                                                                        elm.FEID
                                                                    )}
                                                                />
                                                                {elm.name ||
                                                                    elm.number}
                                                            </LspTypography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <LspTypography
                                                                variant="subheading2"
                                                                color="black"
                                                            >
                                                                {formatNumber(
                                                                    elm.balance
                                                                )}
                                                            </LspTypography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                        </Box>
                                    )}
                                </div>
                            ))}
                            <Box textAlign="right" p={2}>
                                <LspTypography variant="body1" color="black">
                                    {t("gs_lb_balance_piggy_bank")}
                                    <LspTypography
                                        variant="subheading1"
                                        color="black"
                                        component="span"
                                    >
                                        {`: ${formatNumber(totalAmount)}`}
                                    </LspTypography>
                                </LspTypography>
                            </Box>

                            <LspButton
                                onClick={() => setShowReview(true)}
                                className={classes.nextBtn}
                                disabled={selectedList?.length === 0}
                            >
                                {t("lb_next")}
                            </LspButton>
                        </>
                    )}
                    {showReview && (
                        <ChooseAccountReview
                            dataSelected={selectedList}
                            dataInit={dataList}
                            onNext={onNext}
                            totalAmount={totalAmount}
                        />
                    )}
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    documentInfo: state.requestDocument,
    serverTime: state.user.serverTime,
});

const mapDispatch = (dispatch) => ({
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
});

export default memo(connect(mapState, mapDispatch)(ChooseAccount));
