/* eslint-disable no-unreachable */
import { DOCUMENT_ERROR_TYPE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { differenceInMonths, isPast } from "date-fns";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MIN_VALID_DOCUMENT_PERIOD = 180; // days ~ 6 months

const useTermDeposit = () => {
    const { t } = useTranslation();
    const proofPeriodDays = useSelector(
        (state) => state.user?.info?.proofPeriod
    );
    const isForeigner = useSelector((state) => state.user.info?.isForeigner);

    const defaultName = useMemo(() => "TD", []);

    const openTDStep = useMemo(
        () => ({
            amountAndTerm: "OpenTD/AmountAndTerm",
            termDepositReview: "OpenTD/TermDepositReview",
        }),
        []
    );

    const monthToText = useCallback(
        (month) => {
            if (month > 1) {
                return `${month} ${t("td_lb_gl_months")}`;
            }
            return `${month} ${t("td_lb_gl_1_month")}`;
        },
        [t]
    );

    const generateName = useCallback(
        (name, index) => {
            let generatedName = name;
            if (typeof name !== "string" || !name.trim()) {
                generatedName = defaultName;
            }
            if (index) {
                return `${generatedName} | ${index}`;
            }
            return generatedName;
        },
        [defaultName]
    );

    const checkVisaIsValid = useCallback(
        (visaDateTo, today) => {
            if (!visaDateTo) return;

            if (
                !proofPeriodDays ||
                proofPeriodDays === "" ||
                parseInt(proofPeriodDays) < MIN_VALID_DOCUMENT_PERIOD
            ) {
                return {
                    type: DOCUMENT_ERROR_TYPE.VISA_PERIOD_LESS_SIX_MONTHS,
                };
            }

            // remaining visa < today ==> expired warning
            if (isPast(visaDateTo)) {
                return {
                    type: DOCUMENT_ERROR_TYPE.EXPIRED_VISA,
                };
            }

            const remainingMonths = differenceInMonths(visaDateTo, today);

            // remaining visa < 1 months ==> less month warning
            if (remainingMonths < 1) {
                return {
                    type: DOCUMENT_ERROR_TYPE.VISA_DATE_LESS_MONTH,
                };
            }

            // remaining visa < 6 months ==> less 6 months warning
            // if (remainingMonths < MIN_VALID_DOCUMENT_PERIOD) {
            //     return {
            //         type: DOCUMENT_ERROR_TYPE.VISA_PERIOD_LESS_SIX_MONTHS,
            //     };
            // }

            // remaining visa valid
            return {
                type: DOCUMENT_ERROR_TYPE.VISA_DATE_VALID,
                remainingMonths,
            };
        },
        [proofPeriodDays]
    );

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const checkForeignerBeforeOpenTD = useCallback(
        ({ onConfirm, onCancel }) => {
            if (isForeigner) {
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("master:popup_td_rollover_foreigner_title"),
                        content: t("master:popup_td_rollover_foreigner_desc"),
                        doubleButton: [
                            {
                                label: t(
                                    "master:popup_td_rollover_foreigner_btn_confirm"
                                ),
                                onClick: () => {
                                    closeDialog();
                                    onConfirm();
                                },
                            },
                            {
                                label: t(
                                    "master:popup_td_rollover_foreigner_btn_cancel"
                                ),
                                onClick: () => {
                                    closeDialog();
                                    if (onCancel) {
                                        onCancel();
                                    }
                                },
                            },
                        ],
                    },
                });

                return;
            }
            onConfirm();
        },
        [closeDialog, t, isForeigner]
    );

    return {
        openTDStep,
        monthToText,
        generateName,
        checkVisaIsValid,
        checkForeignerBeforeOpenTD,
    };
};

export default useTermDeposit;
