import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Toggle from "@components/Toggle";
import { useCallback, useEffect, useRef, useState } from "react";
import invitationService from "@services/invitation";
import { RESPONSE_CODE } from "@config/constants";
import Content from "@components/Content";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspTypography from "@components/LspTypography";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    settingWrapper: {
        background: theme.palette.background.grey,
        padding: theme.spacing(2),
    },
    box: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
        },
    },
}));

const InvitationSetting = () => {
    const unmounted = useRef(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const [autoSaveOption, setAutoSaveOption] = useState(false);
    const [autoSaveSettingLoading, setAutoSaveSettingLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getAutoSaveStatus = useCallback(async () => {
        setFetching(true);
        const response = await invitationService.getAutoSaveStatus();

        setFetching(false);
        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setAutoSaveOption(data.autoAddPayee || false);
                break;
            default:
                break;
        }
    }, []);

    const updateAutoSaveOption = async (option) => {
        if (autoSaveSettingLoading) return;

        setAutoSaveSettingLoading(true);
        const response = await invitationService.updateAutoSaveStatus({
            autoAddPayee: !option,
        });
        setAutoSaveSettingLoading(false);

        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setAutoSaveOption(data?.autoAddPayee);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    useEffect(() => {
        getAutoSaveStatus();
    }, [getAutoSaveStatus]);

    return (
        <div className={classes.settingWrapper}>
            <Content>
                <Box className={classes.box}>
                    <div style={{ marginRight: 8 }}>
                        <LspTypography variant="subheading2" color="blue">
                            {t("master:invite_friend_auto_save_toggle")}
                        </LspTypography>
                        <LspTypography variant="body2" color="grey">
                            {t("master:invite_friend_auto_save_msg")}
                        </LspTypography>
                    </div>
                    <div>
                        {!fetching && (
                            <Toggle
                                status={autoSaveOption}
                                onChangeHandler={() =>
                                    updateAutoSaveOption(autoSaveOption)
                                }
                                loading={autoSaveSettingLoading}
                            />
                        )}

                        {fetching && <Skeleton width={115} height={50} />}
                    </div>
                </Box>
            </Content>
        </div>
    );
};

export default InvitationSetting;
