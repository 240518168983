import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { LogoMark } from "@components/LspBranding";
import DoubleButtons from "../../../components/DoubleButtons";
import payeeService from "../../../services/payee";
import GlobalDialogController from "../../../helpers/controllers/GlobalDialogController";
import { RESPONSE_CODE } from "../../../config/constants";
import lixiActions from "../../../redux/actions/lixi";
import ReviewPayeeLoading from "./ReviewPayeeLoading";
import LspAvatar from "../../../components/LspAvatar";

const useStyles = makeStyles((theme) => ({
    reviewPayee: {
        width: "100%",
        maxWidth: "555px",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        "& .MuiSkeleton-root": {
            marginLeft: "auto",
            marginRight: "auto",
        },
        fontSize: theme.typography.pxToRem(16),
    },
    avatar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
    box: {
        display: "flex",
        padding: "16px",
        boxShadow: "0px 2px 8px 2px rgba(0, 0, 0, 0.1)",
        marginTop: "16px",
        textTransform: "uppercase",
        justifyContent: "flex-start",
        textAlign: "left",
        alignItems: "center",
        color: theme.palette.grey[600],
        fontWeight: 600,
        borderRadius: "8px",
        marginBottom: theme.spacing(4),
    },
    name: {
        fontWeight: 600,
    },
    icon: {
        fontSize: theme.typography.pxToRem(30),
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    footer: {
        marginBottom: theme.spacing(4),
    },
}));

const ReviewPayee = ({
    payee,
    cancelFunc,
    nextFunc,
    setPayeeDetail,
    payeeDetail,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [loading, setLoading] = useState(true);

    const getPayeeDetail = useCallback(async () => {
        const response = await payeeService.getPayee({
            payeeId: payee.payeeId,
        });
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                if (data && data.accounts.length > 0) {
                    setPayeeDetail({
                        accountNumber: data.accounts[0].accountNumber,
                        accountName: data.nickName
                            ? data.nickName
                            : data.accounts[0].accountName,
                        bankName: data.accounts[0].bankName,
                        avatar: data.avatar,
                    });
                }
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: code,
                });
                break;
        }
        setLoading(false);
    }, [payee.payeeId, setPayeeDetail]);

    useEffect(() => {
        getPayeeDetail();
    }, [getPayeeDetail]);

    return (
        <div className={classes.reviewPayee}>
            <div>{t("lixi:send_lixi_back")}</div>
            {loading && <ReviewPayeeLoading className={classes} />}
            {!loading && (
                <>
                    <div className={classes.avatar}>
                        <LspAvatar avatar={payeeDetail.avatar} size={60} />
                    </div>
                    <div className={classes.name}>
                        {payeeDetail.accountName}
                    </div>
                    <div className={classes.box}>
                        <div>
                            <LogoMark className={classes.icon} />
                        </div>
                        <div className={classes.info}>
                            <div>{payeeDetail.bankName}</div>
                            <div>{payeeDetail.accountName}</div>
                            <div>{payeeDetail.accountNumber}</div>
                        </div>
                    </div>
                    <div className={classes.footer}>
                        <DoubleButtons
                            progressing={loading}
                            primaryButton={{
                                label: t("loan_vpbank_button_confirm"),
                                onClick: nextFunc,
                            }}
                            secondaryButton={{
                                label: t("lb_cancel"),
                                onClick: cancelFunc,
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const stateProps = (state) => ({
    payeeDetail: state.lixi.payee,
});

const dispatchProps = (dispatch) => ({
    setPayeeDetail: (payload) => dispatch(lixiActions.setPayeeDetail(payload)),
});

export default connect(stateProps, dispatchProps)(ReviewPayee);
