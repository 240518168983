import { makeStyles } from "@material-ui/core";
import tommyDizziness from "@assets/images/tommy_dizziness.png";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    notFound: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        textAlign: "center",
    },
    notFoundImg: {
        width: 220,
    },
    notFoundTitle: {
        fontSize: theme.typography.pxToRem(16),
        marginBottom: theme.spacing(3),
    },
    notFoundBody: {
        fontSize: theme.typography.pxToRem(16),
    },
}));

const NotFound = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    return (
        <>
            <div className={classes.notFound}>
                {/* <div ></div> */}
                <img
                    className={classes.notFoundImg}
                    src={tommyDizziness}
                    alt="tommy dizzziness"
                />
                <LspTypography
                    className={classes.notFoundTitle}
                    variant="subheading2"
                >
                    {t("IBFT:IBFT_alias_setting_error_header")}
                </LspTypography>
                <LspTypography variant="body2">
                    {t("IBFT:IBFT_alias_setting_error_desc")}
                </LspTypography>
            </div>
        </>
    );
};

export default NotFound;
