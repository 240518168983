import Content from "@components/Content";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import successIcon from "@assets/images/Success1.png";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";

import LspTranslation from "@components/LspTranslation";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: "center",
        padding: theme.spacing(3),
    },
    body: {
        paddingTop: 76,
        paddingBottom: 76,
        maxWidth: 424,
        margin: "auto",
    },
    deliveryBox: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.20)",
        borderRadius: theme.shape.borderRadius,
        "& p:nth-child(odd)": {
            color: theme.palette.neutral.grey6,
        },
    },
    icon: {
        marginBottom: theme.spacing(2),
        width: 104,
    },
}));

const RequestCardSuccessDialog = ({ onClose, addressName, address }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Content size="sm">
            <Paper className={classes.wrapper}>
                <Box className={classes.body}>
                    <img
                        src={successIcon}
                        alt="icon"
                        className={classes.icon}
                    />
                    <LspTypography variant="title2">
                        {t("debitCard:reissuecard_request_success_app")}
                    </LspTypography>
                    {!addressName && (
                        <>
                            <Box className={classes.deliveryBox}>
                                <Box marginBottom={1}>
                                    <LspTypography variant="body2">
                                        {t("master:card_delivery_address")}:
                                    </LspTypography>
                                </Box>
                                <LspTypography
                                    variant="subheading1"
                                    color="primary"
                                >
                                    {t("booking_delivery_new_address")}
                                </LspTypography>
                                <LspTypography variant="body2">
                                    {address}
                                </LspTypography>
                            </Box>
                            <Box marginBottom={2}>
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "debitCard:phys_visa_success_delivery_desc"
                                    )}
                                </LspTypography>
                            </Box>

                            <LspTypography
                                component="div"
                                variant="body2"
                                color="grey"
                            >
                                <LspTranslation
                                    i18nKey="debitCard:phys_visa_success_delivery_contact_care"
                                    replacingKeys={[
                                        "hangout:callcenter_number",
                                    ]}
                                    hyperlink
                                />
                            </LspTypography>
                        </>
                    )}

                    {addressName && (
                        <>
                            <Box className={classes.deliveryBox}>
                                <Box marginBottom={1}>
                                    <LspTypography variant="body2">
                                        {t("booking_pickup_will_reserve_card")}
                                    </LspTypography>
                                </Box>
                                <LspTypography
                                    variant="subheading1"
                                    color="primary"
                                >
                                    {addressName}
                                </LspTypography>
                                <LspTypography variant="body2">
                                    {address}
                                </LspTypography>
                            </Box>
                            <LspTypography variant="body2" color="grey">
                                {t("phys_visa_success_pickup_desc")?.replace(
                                    "%@",
                                    t("mb_jn_end_foreigner_doc")
                                )}
                            </LspTypography>
                        </>
                    )}
                </Box>

                <LspButton fullWidth onClick={onClose}>
                    {t("debitCard:phys_visa_active_CTA_ok")}
                </LspButton>
            </Paper>
        </Content>
    );
};

export default memo(RequestCardSuccessDialog);
