/* ------------- Types Definition ------------- */
export const UserActionType = Object.freeze({
    Reset: "UserActionType/Reset",
    GetProfileRequest: "UserActionType/GetProfileRequest",
    GetProfileSuccess: "UserActionType/GetProfileSuccess",
    GetProfileError: "UserActionType/GetProfileError",
    GetServerTimeRequest: "UserActionType/GetServerTimeRequest",
    GetServerTimeSuccess: "UserActionType/GetServerTimeSuccess",
    GetServerTimeError: "UserActionType/GetServerTimeError",
    ResetServerTime: "UserActionType/ResetServerTime",
    GetPayeeListRequest: "UserActionType/GetPayeeListRequest",
    GetPayeeListSuccess: "UserActionType/GetPayeeListSuccess",
    GetPayeeListError: "UserActionType/GetPayeeListError",
    ResetPayeeList: "UserActionType/ResetPayeeList",
    MinimizePayeeList: "UserActionType/MinimizePayeeList",
    GetBankInfoRequest: "UserActionType/GetBankInfoRequest",
    GetBankInfoSuccess: "UserActionType/GetBankInfoSuccess",
    GetBankInfoError: "UserActionType/GetBankInfoError",
    UpdateSettingRequest: "UserActionType/UpdateSettingRequest",
    UpdateSettingSuccess: "UserActionType/UpdateSettingSuccess",
    UpdateSettingError: "UserActionType/UpdateSettingError",
    SetPhoneNumberOTP: "UserActionType/SetPhoneNumberOTP",
    GetFeatureStateRequest: "UserActionType/GetFeatureStateRequest",
    GetFeatureStateSuccess: "UserActionType/GetFeatureStateSuccess",
    GetFeatureStateError: "UserActionType/GetFeatureStateError",
    ReIssuesCheckRequest: "UserActionType/ReIssuesCheckRequest",
    ReIssuesCheckSuccess: "UserActionType/ReIssuesCheckSuccess",
    ReIssuesCheckError: "UserActionType/ReIssuesCheckError",
    ModifyAliasRequest: "UserActionType/ModifyAliasRequest",
    ModifyAliasSuccess: "UserActionType/ModifyAliasSuccess",
    ModifyAliasError: "UserActionType/ModifyAliasError",
    GetAliasRequest: "UserActionType/GetAliasRequest",
    GetAliasSuccess: "UserActionType/GetAliasSuccess",
    GetAliasError: "UserActionType/GetAliasError",
    ToggleAliasRequest: "UserActionType/ToggleAliasRequest",
    ToggleAliasSuccess: "UserActionType/ToggleAliasSuccess",
    ToggleAliasError: "UserActionType/ToggleAliasError",

    // alias fee
    GetNicknameFeeRequest: "UserActionType/GetNicknameFeeRequest",
    GetNicknameFeeSuccess: "UserActionType/GetNicknameFeeSuccess",
    GetNicknameFeeError: "UserActionType/GetNicknameFeeError",

    // check alias name
    CheckNicknameRequest: "UserActionType/CheckNicknameRequest",
    CheckNicknameSuccess: "UserActionType/CheckNicknameSuccess",
    CheckNicknameError: "UserActionType/CheckNicknameError",

    // submit alias
    SubmitNicknameRequest: "UserActionType/SubmitNicknameRequest",
    SubmitNicknameSuccess: "UserActionType/SubmitNicknameSuccess",
    SubmitNicknameError: "UserActionType/SubmitNicknameError",

    SubmitOTPNicknameRequest: "UserActionType/SubmitOTPNicknameRequest",
    SubmitOTPNicknameSuccess: "UserActionType/SubmitOTPNicknameSuccess",
    SubmitOTPNicknameError: "UserActionType/SubmitOTPNicknameError",
    SubmitOTPNicknameReset: "UserActionType/SubmitOTPNicknameReset",
});

/* ------------- Actions Definition ------------- */
const reset = () => {
    return {
        type: UserActionType.Reset,
    };
};

const getProfileRequest = (payload) => {
    return {
        type: UserActionType.GetProfileRequest,
        payload,
    };
};
const getProfileSuccess = (payload) => {
    return {
        type: UserActionType.GetProfileSuccess,
        payload,
    };
};
const getProfileError = () => {
    return {
        type: UserActionType.GetProfileError,
    };
};

const getServerTimeRequest = () => {
    return {
        type: UserActionType.GetServerTimeRequest,
    };
};
const getServerTimeSuccess = (payload) => {
    return {
        type: UserActionType.GetServerTimeSuccess,
        payload,
    };
};
const getServerTimeError = () => {
    return {
        type: UserActionType.GetServerTimeError,
    };
};
const resetServerTime = () => {
    return {
        type: UserActionType.ResetServerTime,
    };
};

const getPayeeListRequest = () => {
    return {
        type: UserActionType.GetPayeeListRequest,
    };
};
const getPayeeListSuccess = (payload) => {
    return {
        type: UserActionType.GetPayeeListSuccess,
        payload,
    };
};
const getPayeeListError = () => {
    return {
        type: UserActionType.GetPayeeListError,
    };
};
const resetPayeeList = () => {
    return {
        type: UserActionType.ResetPayeeList,
    };
};
const minimizePayeeList = () => {
    return {
        type: UserActionType.MinimizePayeeList,
    };
};

const getBankInfoRequest = () => {
    return {
        type: UserActionType.GetBankInfoRequest,
    };
};
const getBankInfoSuccess = (payload) => {
    return {
        type: UserActionType.GetBankInfoSuccess,
        payload,
    };
};
const getBankInfoError = () => {
    return {
        type: UserActionType.GetBankInfoError,
    };
};

const updateSettingRequest = (payload) => {
    return {
        type: UserActionType.UpdateSettingRequest,
        payload,
    };
};
const updateSettingSuccess = () => {
    return {
        type: UserActionType.UpdateSettingSuccess,
    };
};
const updateSettingError = () => {
    return {
        type: UserActionType.UpdateSettingError,
    };
};

const setPhoneNumberOTP = (payload) => {
    return {
        type: UserActionType.SetPhoneNumberOTP,
        payload,
    };
};

const getFeatureStateRequest = (payload) => {
    return {
        type: UserActionType.GetFeatureStateRequest,
        payload,
    };
};
const getFeatureStateSuccess = (payload) => {
    return {
        type: UserActionType.GetFeatureStateSuccess,
        payload,
    };
};
const getFeatureStateError = () => {
    return {
        type: UserActionType.GetFeatureStateError,
    };
};

const reissueCheckRequest = () => {
    return {
        type: UserActionType.ReIssuesCheckRequest,
    };
};

const reissueCheckSuccess = (payload) => {
    return {
        type: UserActionType.ReIssuesCheckSuccess,
        payload,
    };
};

const reissueCheckError = () => {
    return {
        type: UserActionType.ReIssuesCheckError,
    };
};

const modifyAliasRequest = (payload) => {
    return {
        type: UserActionType.ModifyAliasRequest,
        payload,
    };
};

const modifyAliasSuccess = (payload) => {
    return {
        type: UserActionType.ModifyAliasSuccess,
        payload,
    };
};

const modifyAliasError = () => {
    return {
        type: UserActionType.ModifyAliasError,
    };
};

const getAliasRequest = () => {
    return {
        type: UserActionType.GetAliasRequest,
    };
};

const getAliasSuccess = (payload) => {
    return {
        type: UserActionType.GetAliasSuccess,
        payload,
    };
};

const getAliasError = () => {
    return {
        type: UserActionType.GetAliasError,
    };
};

const toggleAliasRequest = (payload) => {
    return {
        type: UserActionType.ToggleAliasRequest,
        payload,
    };
};

const toggleAliasSuccess = () => {
    return {
        type: UserActionType.ToggleAliasSuccess,
    };
};

const toggleAliasError = () => {
    return {
        type: UserActionType.ToggleAliasError,
    };
};

const getNicknameFeeRequest = (payload) => {
    return {
        type: UserActionType.GetNicknameFeeRequest,
        payload,
    };
};
const getNicknameFeeSuccess = (payload) => {
    return {
        type: UserActionType.GetNicknameFeeSuccess,
        payload,
    };
};
const getNicknameFeeError = () => {
    return {
        type: UserActionType.GetNicknameFeeError,
    };
};

const checkNicknameRequest = (payload) => {
    return {
        type: UserActionType.CheckNicknameRequest,
        payload,
    };
};
const checkNicknameSuccess = (payload) => {
    return {
        type: UserActionType.CheckNicknameSuccess,
        payload,
    };
};

const checkNicknameError = () => {
    return {
        type: UserActionType.CheckNicknameError,
    };
};

const submitNicknameRequest = (payload) => {
    return {
        type: UserActionType.SubmitNicknameRequest,
        payload,
    };
};
const submitNicknameSuccess = (payload) => {
    return {
        type: UserActionType.SubmitNicknameSuccess,
        payload,
    };
};
const submitNicknameError = () => {
    return {
        type: UserActionType.SubmitNicknameError,
    };
};

const submitOTPNicknameRequest = (payload) => {
    return {
        type: UserActionType.SubmitOTPNicknameRequest,
        payload,
    };
};
const submitOTPNicknameSuccess = (payload) => {
    return {
        type: UserActionType.SubmitOTPNicknameSuccess,
        payload,
    };
};

const submitOTPNicknameError = (payload) => {
    return {
        type: UserActionType.SubmitOTPNicknameError,
        payload,
    };
};

const submitOTPNicknameReset = (payload) => {
    return {
        type: UserActionType.SubmitOTPNicknameReset,
        payload,
    };
};

const userAction = {
    reset,
    getProfileRequest,
    getProfileSuccess,
    getProfileError,
    getServerTimeRequest,
    getServerTimeSuccess,
    getServerTimeError,
    resetServerTime,
    getPayeeListRequest,
    getPayeeListSuccess,
    getPayeeListError,
    resetPayeeList,
    minimizePayeeList,
    getBankInfoRequest,
    getBankInfoSuccess,
    getBankInfoError,
    updateSettingRequest,
    updateSettingSuccess,
    updateSettingError,
    setPhoneNumberOTP,
    getFeatureStateRequest,
    getFeatureStateSuccess,
    getFeatureStateError,

    reissueCheckRequest,
    reissueCheckSuccess,
    reissueCheckError,

    modifyAliasRequest,
    modifyAliasSuccess,
    modifyAliasError,

    getAliasRequest,
    getAliasSuccess,
    getAliasError,

    toggleAliasRequest,
    toggleAliasSuccess,
    toggleAliasError,

    getNicknameFeeRequest,
    getNicknameFeeSuccess,
    getNicknameFeeError,

    checkNicknameRequest,
    checkNicknameSuccess,
    checkNicknameError,

    submitNicknameRequest,
    submitNicknameSuccess,
    submitNicknameError,

    submitOTPNicknameRequest,
    submitOTPNicknameSuccess,
    submitOTPNicknameError,
    submitOTPNicknameReset,
};

export default userAction;
