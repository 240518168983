/* ------------- Types Definition ------------- */
export const LayoutActionType = Object.freeze({
    OpenSideMenu: "LayoutActionType/OpenSideMenu",
    CloseSideMenu: "LayoutActionType/CloseSideMenu",
});

/* ------------- Actions Definition ------------- */

const openSideMenu = () => {
    return {
        type: LayoutActionType.OpenSideMenu,
    };
};
const closeSideMenu = () => {
    return {
        type: LayoutActionType.CloseSideMenu,
    };
};

const layoutAction = {
    openSideMenu,
    closeSideMenu,
};

export default layoutAction;
