export default (version) => {
    const dbVersion = version || 1;
    const request = indexedDB.open("timoTranslate", dbVersion);

    request.onerror = (e) => {
        console.log("Database Error", e);
    };

    request.onsuccess = (e) => {
        console.log("Database Opened");
    };

    request.onupgradeneeded = (e) => {
        const db = e.target.result;

        if (!db.objectStoreNames.contains("translatePrivate")) {
            // if there's no "translatePrivate" store
            db.createObjectStore("translatePrivate", {
                keyPath: "keys",
                autoIncrement: true,
            });
        }

        if (!db.objectStoreNames.contains("translatePrivateContent")) {
            // if there's no "translatePrivateContent" store
            db.createObjectStore("translatePrivateContent", {
                keyPath: "keys",
                autoIncrement: true,
            });
        }
    };

    return request;
};
