import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import Banner from "@components/Banner";
import InvitationDashBoard from "./InvitationDashBoard";
import InvitationList from "./InvitationList";

const INVITATION_NAVIGATION_KEYS = {
    DASHBOARD: "dashboard",
    JOINED_LIST: "list",
};

const parentName = "invitation";

const Invitation = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { generateChildPathname } = usePathname();

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    INVITATION_NAVIGATION_KEYS.DASHBOARD
                ),
                component: InvitationDashBoard,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    INVITATION_NAVIGATION_KEYS.JOINED_LIST
                ),
                component: InvitationList,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    const navbarItems = useMemo(
        () => [
            {
                title: "master:invite_friend_send_tab",
                icon: "sendinvite",
                path: generateChildPathname(
                    parentName,
                    INVITATION_NAVIGATION_KEYS.DASHBOARD
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        INVITATION_NAVIGATION_KEYS.DASHBOARD
                    )
                ),
            },
            {
                title: "master:invite_friend_join_tab",
                icon: "view_joined",
                path: generateChildPathname(
                    parentName,
                    INVITATION_NAVIGATION_KEYS.JOINED_LIST
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        INVITATION_NAVIGATION_KEYS.JOINED_LIST
                    )
                ),
            },
        ],
        [location, generateChildPathname]
    );

    return (
        <>
            <Banner
                title={t("timo_plus_invite_friend")}
                navbarItems={navbarItems}
            />
            <Switch>
                {contentRoutes.map((r) => {
                    return (
                        <Route
                            key={r.path}
                            path={r.path}
                            component={r.component}
                        />
                    );
                })}
                {defaultRoutes && (
                    <Route
                        component={() => <Redirect to={defaultRoutes.path} />}
                    />
                )}
            </Switch>
        </>
    );
};

export default Invitation;
