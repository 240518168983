import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PAYMENT_LABEL_STATUS, RESPONSE_CODE } from "@config/constants";
import { Box, makeStyles } from "@material-ui/core";
import DoubleButtons from "@components/DoubleButtons";
import LspButton from "@components/LspButton";
import paymentRequestService from "@services/payment-request";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import { PAYMENT_REQUEST_ITEM_TYPES } from "@containers/PaymentRequest/paymentRequest.constant";
import { connect } from "react-redux";
import PaymentRequestActions from "@redux/actions/payment-request";
import Content from "@components/Content";
import PaymentRequestDetail from "../PaymentRequestDetail";
import PaymentStatusMessage from "../ PaymentStatusMessage";

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        confirmDeleteTxt: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            textTransform: "uppercase",
            padding: theme.spacing(1.25, 2.25),
        },
        actionBtn: {
            width: "100%",
            position: "absolute",
            padding: theme.spacing(2, 2),
            "& > svg": {
                cursor: "pointer",
            },
        },
    };
});
const PaymentSend = ({ paymentSendInfo, getPaymentList }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onCancel = () => {
        setConfirmDelete(true);
    };

    const refreshOutStandingList = () => {
        const outstandingPayload = {
            type: PAYMENT_REQUEST_ITEM_TYPES.OPEN,
            lastId: "-1",
            modifyAt: "",
        };
        getPaymentList(outstandingPayload);
    };

    const paymentSenderAction = async (isRemind) => {
        setFetchingData(true);
        const dataToPost = {
            id: paymentSendInfo.paymentRequestId,
            receiveId: paymentSendInfo.payeeRequestId,
            remind: isRemind,
            cancel: !isRemind,
        };
        const { data } = await paymentRequestService.paymentActionOfSender(
            dataToPost
        );
        if (unmounted.current) return;
        setFetchingData(false);
        switch (data.code) {
            case RESPONSE_CODE.CREATED:
                GlobalDialogController.hide();
                if (isRemind) {
                    AlertController.show(
                        t("payment_msg_remind_successfully"),
                        AlertType.Success
                    );
                } else {
                    AlertController.show(
                        t("payment_msg_request_to_has_been_deleted").replace(
                            "%@",
                            paymentSendInfo.receiveName
                        ),
                        AlertType.Error
                    );
                }

                refreshOutStandingList();
                break;
            case RESPONSE_CODE.HAS_PAID:
                refreshOutStandingList();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: "payment_msg_request_already_paid",
                        button: t("lb_ok"),
                    },
                });

                break;
            case RESPONSE_CODE.DELETED_PAYMENT_ALREADY:
                refreshOutStandingList();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: "payment_was_deleted_by_receiver",
                        button: t("lb_ok"),
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const onBack = () => {
        setConfirmDelete(false);
    };

    const onClose = () => {
        GlobalDialogController.hide();
    };

    const paymentHasBeenDeleted = useMemo(() => {
        return (
            !!paymentSendInfo?.labelPaymentStatus &&
            paymentSendInfo?.labelPaymentStatus === PAYMENT_LABEL_STATUS.DELETED
        );
    }, [paymentSendInfo]);

    return (
        <Content size="sm" boxProps={{ style: { position: "relative" } }}>
            <Box className={classes.actionBtn}>
                {confirmDelete && <ArrowBackIcon onClick={onBack} />}
                <CloseIcon onClick={onClose} style={{ float: "right" }} />
            </Box>
            <Box p={4} pb={2} className={classes.wrapper}>
                {confirmDelete && (
                    <div className={classes.confirmDeleteTxt}>
                        {t("payment_lb_confirm_delete")}
                    </div>
                )}
                <PaymentRequestDetail info={paymentSendInfo} isSent />
                <PaymentStatusMessage
                    info={paymentSendInfo}
                    isSent
                    isHideMessage={confirmDelete}
                />

                {confirmDelete && (
                    <Box mt={4} width="100%">
                        <LspButton
                            variant="primary"
                            fullWidth
                            progressing={fetchingData}
                            onClick={() => paymentSenderAction(false)}
                        >
                            {t("payment_btn_sender_cancel_it")}
                        </LspButton>
                    </Box>
                )}
                {!paymentHasBeenDeleted && !confirmDelete && (
                    <Box mt={4} width="100%">
                        <DoubleButtons
                            progressing={fetchingData}
                            primaryButton={{
                                label: t("payment_btn_remind"),
                                onClick: () => paymentSenderAction(true),
                                disable: !paymentSendInfo.ableRemind,
                            }}
                            secondaryButton={{
                                label: t("lb_cancel"),
                                onClick: onCancel,
                            }}
                        />
                    </Box>
                )}
                {paymentHasBeenDeleted && !confirmDelete && (
                    <Box mt={4} width="100%">
                        <LspButton
                            variant="primary"
                            fullWidth
                            progressing={fetchingData}
                            onClick={() => paymentSenderAction(false)}
                        >
                            {t("payment_btn_remove_from_list")}
                        </LspButton>
                    </Box>
                )}
            </Box>
        </Content>
    );
};

const mapDispatch = (dispatch) => ({
    getPaymentList: (payload) =>
        dispatch(PaymentRequestActions.getPaymentRequestList(payload)),
});

export default connect(null, mapDispatch)(PaymentSend);
