import {
    Box,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    Paper,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LspTextField from "@components/LspTextField";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState, useEffect } from "react";
import {
    addDays,
    addMonths,
    format,
    lastDayOfMonth,
    startOfMonth,
    startOfToday,
    startOfWeek,
    subMonths,
} from "date-fns";
import { connect } from "react-redux";
import LspButton from "@components/LspButton";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import { EditIcon } from "@components/LspIcon";
import { subDays } from "date-fns/esm";

const TimeRangeValue = {
    All: "filter_all",
    LastWeek: "filter_lastweek",
    LastMonth: "filter_lastmonth",
    LastThreeMonths: "filter_last3months",
    Custom: "filter_custom",
};
const TimeRangeValueNew = {
    All: "txn_list_filter_all_time",
    LastSevenDay: "txn_list_filter_last_7_days",
    LastThirtyDay: "txn_list_filter_last_30_days",
    Custom: "txn_list_filter_custom_range_time",
};

const TRANSACTION_AMOUNT = [
    {
        key: "filter_all",
        value: "txn_list_filter_all_txn",
    },
    {
        key: "filter_custom",
        value: "txn_list_filter_custom_amt",
    },
];

const MoneyTypeOption = {
    All: {
        label: "filter_all",
        value: "all",
    },
    In: {
        label: "filter_moneyin",
        value: "in",
    },
    Out: {
        label: "filter_moneyout",
        value: "out",
    },
};

const useStyles = makeStyles(() => ({
    button: {
        minWidth: 140,
    },
    buttonContainer: {
        textAlign: "right",
    },
}));

const Filter = ({ visible, hide, serverTime, setFilter, featureState }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [timeRange, setTimeRange] = useState("");
    const [moneyType, setMoneyType] = useState(MoneyTypeOption.All.value);
    const [customFromDate, setCustomFromDate] = useState(null);
    const [customToDate, setCustomToDate] = useState(null);
    const [activeFilterAmount, setActiveFilterAmount] = useState(false);
    const [transactionAmount, setTransactionAmount] = useState(
        TRANSACTION_AMOUNT[0]
    );

    const [minAmount, setMinAmount] = useState(null);
    const [maxAmount, setMaxAmount] = useState(null);

    const { thousandSeparator } = useNumber();

    const [errors, setErrors] = useState({
        customFromDate: true,
        customToDate: true,
        maxAmount: true,
        minAmount: true,
    });

    const timeRangeOptions = useMemo(() => {
        return Object.values(TimeRangeValue);
    }, []);

    const timeRangeOptionsNew = useMemo(() => {
        return Object.values(TimeRangeValueNew);
    }, []);

    const moneyTypeOptions = useMemo(() => {
        return Object.values(MoneyTypeOption);
    }, []);

    const onTimeRangeChange = useCallback((e) => {
        setTimeRange(e.target.value);
    }, []);

    const onMoneyTypeChange = useCallback((e) => {
        setMoneyType(e.target.value);
    }, []);

    const onCustomFromDateError = useCallback(
        (err) => {
            if (Boolean(err) === errors.customFromDate) {
                return;
            }
            setErrors((curErrors) => ({
                ...curErrors,
                customFromDate: Boolean(err),
            }));
        },
        [errors.customFromDate]
    );

    const onCustomToDateError = useCallback(
        (err) => {
            if (Boolean(err) === errors.customToDate) {
                return;
            }
            setErrors((curErrors) => ({
                ...curErrors,
                customToDate: Boolean(err),
            }));
        },
        [errors.customToDate]
    );

    const isValidFilter = useMemo(() => {
        if (timeRange === TimeRangeValue.Custom) {
            return (
                customFromDate &&
                customToDate &&
                !errors.customFromDate &&
                !errors.customToDate
            );
        }

        if (activeFilterAmount && minAmount == null && maxAmount == null) {
            return false;
        }

        if (
            featureState?.enableFilterTransactionList &&
            (errors.maxAmount || errors.maxAmount)
        ) {
            return !errors.maxAmount || !errors.maxAmount;
        }
        return true;
    }, [
        timeRange,
        activeFilterAmount,
        minAmount,
        maxAmount,
        featureState?.enableFilterTransactionList,
        errors.maxAmount,
        errors.customFromDate,
        errors.customToDate,
        customFromDate,
        customToDate,
    ]);

    const applyFilter = useCallback(() => {
        const firstDayOfWeek = startOfWeek(serverTime, { weekStartsOn: 1 });
        const todayOfLastMonth = addMonths(serverTime, -1);
        const todayOfThreeMonthsAgo = addMonths(serverTime, -3);
        const todayOfSixMonthsAgo = addMonths(serverTime, -6);
        const todayOfTwelfthMonthsAgo = addMonths(serverTime, -12);

        let fromDate = null;
        let toDate = null;

        switch (timeRange) {
            case TimeRangeValueNew.LastSevenDay:
                fromDate = addDays(startOfToday(), -7);
                toDate = startOfToday();
                break;
            case TimeRangeValue.LastWeek:
                fromDate = addDays(firstDayOfWeek, -7);
                toDate = addDays(firstDayOfWeek, -1);
                break;
            case TimeRangeValueNew.LastMonth:
            case TimeRangeValue.LastMonth:
                fromDate = startOfMonth(todayOfLastMonth);
                toDate = lastDayOfMonth(todayOfLastMonth);
                break;
            case TimeRangeValueNew.LastThirtyDay:
                fromDate = addDays(startOfToday(), -30);
                toDate = startOfToday();
                break;
            case TimeRangeValueNew.LastThreeMonths:
                fromDate = startOfMonth(todayOfThreeMonthsAgo);
                toDate = lastDayOfMonth(todayOfLastMonth);
                break;
            case TimeRangeValue.LastThreeMonths:
                fromDate = subMonths(new Date(), 3);
                toDate = startOfToday();
                break;
            case TimeRangeValueNew.LastSixMonths:
                fromDate = subMonths(new Date(), 6);
                toDate = startOfToday();
                break;
            case TimeRangeValueNew.LastTwelfthMonths:
                fromDate = subMonths(new Date(), 12);
                toDate = startOfToday();
                break;
            case TimeRangeValue.Custom:
            case TimeRangeValueNew.Custom:
                fromDate = customFromDate;
                toDate = customToDate;
                break;
            case TimeRangeValue.All:
            case TimeRangeValueNew.All:
            default:
                break;
        }
        setFilter({
            moneyType,
            fromDate: fromDate ? format(fromDate, "dd/MM/yyyy") : fromDate,
            toDate: toDate ? format(toDate, "dd/MM/yyyy") : toDate,
            minAmount,
            maxAmount,
        });
    }, [
        serverTime,
        timeRange,
        setFilter,
        moneyType,
        minAmount,
        maxAmount,
        customFromDate,
        customToDate,
    ]);

    const cancelFilter = useCallback(() => {
        hide();
        setFilter(null);
    }, [hide, setFilter]);

    useEffect(() => {
        if (!transactionAmount) {
            setMinAmount(null);
            setMaxAmount(null);
        }
    }, [transactionAmount]);

    useEffect(() => {
        if (maxAmount != null && maxAmount < minAmount) {
            setErrors((curErrors) => ({
                ...curErrors,
                maxAmount: true,
            }));
            return;
        }
        setErrors((curErrors) => ({
            ...curErrors,
            maxAmount: false,
        }));
    }, [maxAmount, minAmount]);

    useEffect(() => {
        if (maxAmount != null && minAmount > maxAmount) {
            setErrors((curErrors) => ({
                ...curErrors,
                minAmount: true,
            }));
            return;
        }
        setErrors((curErrors) => ({
            ...curErrors,
            minAmount: false,
        }));
    }, [maxAmount, minAmount]);

    useEffect(() => {
        setTimeRange(
            featureState?.enableFilterTransactionList
                ? TimeRangeValueNew.All
                : TimeRangeValue.All
        );
    }, [featureState?.enableFilterTransactionList]);

    return (
        <Collapse in={visible}>
            <Paper component={Box} mt={2} p={2}>
                {featureState?.enableFilterTransactionList && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                disableClearable
                                options={TRANSACTION_AMOUNT}
                                value={transactionAmount}
                                getOptionLabel={(option) =>
                                    t(`txnList:${option?.value}`) || ""
                                }
                                onChange={(e, data) => {
                                    const { key } = data;
                                    setTransactionAmount(data);
                                    if (key) {
                                        setActiveFilterAmount(
                                            key === "filter_custom"
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <LspTextField
                                        {...params}
                                        disableHelperText
                                        label={t(
                                            "txnList:txn_list_filter_by_amt_section"
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
                {activeFilterAmount && (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <NumberFormat
                                value={minAmount}
                                customInput={LspTextField}
                                label={t(
                                    "txnList:txn_list_filter_custom_min_amt"
                                )}
                                helperText={
                                    errors.minAmount
                                        ? t(
                                              "txnList:txn_list_filter_custome_error"
                                          )
                                        : ""
                                }
                                allowNegative={false}
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={false}
                                disableHelperText={!errors?.minAmount}
                                autoComplete="off"
                                error={!!errors?.minAmount}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onValueChange={(amount) =>
                                    setMinAmount(amount?.floatValue)
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat
                                value={maxAmount}
                                customInput={LspTextField}
                                label={t(
                                    "txnList:txn_list_filter_custom_max_amt"
                                )}
                                allowNegative={false}
                                helperText={
                                    errors.maxAmount
                                        ? t(
                                              "txnList:txn_list_filter_custome_error"
                                          )
                                        : ""
                                }
                                autoComplete="off"
                                thousandSeparator={thousandSeparator}
                                decimalSeparator={false}
                                disableHelperText={!errors?.maxAmount}
                                error={!!errors?.maxAmount}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <EditIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onValueChange={(amount) => {
                                    setMaxAmount(amount?.floatValue);
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LspTextField
                            label={t("txnList:txn_list_filter_by_type_section")}
                            select
                            value={moneyType}
                            onChange={onMoneyTypeChange}
                            disableHelperText
                        >
                            {moneyTypeOptions?.map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                    {t(opt.label)}
                                </MenuItem>
                            ))}
                        </LspTextField>
                    </Grid>
                    <Grid item xs={6}>
                        <LspTextField
                            label={t("txnList:txn_list_filter_by_time_section")}
                            select
                            value={timeRange}
                            onChange={onTimeRangeChange}
                            disableHelperText
                        >
                            {!featureState?.enableFilterTransactionList &&
                                timeRangeOptions?.map((opt) => (
                                    <MenuItem key={opt} value={opt}>
                                        {t(opt)}
                                    </MenuItem>
                                ))}
                            {featureState?.enableFilterTransactionList &&
                                timeRangeOptionsNew?.map((opt) => (
                                    <MenuItem key={opt} value={opt}>
                                        {t(`txnList:${opt}`)}
                                    </MenuItem>
                                ))}
                        </LspTextField>
                    </Grid>
                    {timeRange === TimeRangeValueNew.Custom && (
                        <>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    TextFieldComponent={LspTextField}
                                    label={t(
                                        "txnList:txn_list_filter_custome_range_time_from"
                                    ).replace("%@", "")}
                                    disableToolbar
                                    autoOk
                                    disableFuture
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    invalidDateMessage=" "
                                    maxDateMessage=" "
                                    value={customFromDate}
                                    onChange={(d) => {
                                        if (!d) {
                                            setCustomFromDate(null);
                                            return;
                                        }
                                        setCustomFromDate(new Date(d));
                                    }}
                                    onError={onCustomFromDateError}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    TextFieldComponent={LspTextField}
                                    label={t(
                                        "txnList:txn_list_filter_custome_range_time_to"
                                    ).replace("%@", "")}
                                    disableToolbar
                                    autoOk
                                    disableFuture
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    invalidDateMessage=" "
                                    maxDateMessage=" "
                                    minDateMessage=" "
                                    value={customToDate}
                                    minDate={customFromDate}
                                    disabled={!customFromDate}
                                    onChange={(d) => {
                                        if (!d) {
                                            setCustomToDate(null);
                                            return;
                                        }
                                        setCustomToDate(new Date(d));
                                    }}
                                    onError={onCustomToDateError}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <LspButton
                            mr={1}
                            variant="secondary"
                            buttonProps={{
                                classes: { root: classes.button },
                            }}
                            onClick={cancelFilter}
                        >
                            {t("txnList:txn_list_filter_btn_close")}
                        </LspButton>
                        <LspButton
                            disabled={!isValidFilter}
                            buttonProps={{
                                classes: { root: classes.button },
                            }}
                            onClick={applyFilter}
                        >
                            {t("txnList:txn_list_filter_btn_apply")}
                        </LspButton>
                    </Grid>
                </Grid>
            </Paper>
        </Collapse>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime.value,
    featureState: state.user.featureState.data,
});

export default connect(mapState)(Filter);
