import PropTypes from "prop-types";

import bookingActions from "@redux/actions/booking";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";

import { connect } from "react-redux";

import { BOOKING_ACTIONS, BOOKING_SERVICES } from "./constant";
import CreateBooking from "./CreateBooking";
import ViewBooking from "./ViewBooking";
import WarningBookingNotAvailable from "./WarningBookingNotAvailable";

/**
 * @param {object} props
 * @param {string} props.serviceName
 * @param {string} props.type
 * @param {string} props.id
 * @param {func} props.onComplete
 * @param {func} props.onCancel
 */

const Booking = ({
    serviceName,
    type,
    id,
    onComplete,
    onCancel,
    reset,
    allowJoinBooking,
    setBookingConfigHandler,
    getBookingDetail,
}) => {
    // View, New booking, Change booking
    const unmounted = useRef(false);
    const [bookingType, setBookingType] = useState(type);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    useEffect(() => {
        setBookingConfigHandler({
            id,
            type,
            serviceName,
            onComplete,
            onCancel,
        });
    }, [onComplete, onCancel, setBookingConfigHandler, id, type, serviceName]);

    const isAvailableBooking = useMemo(() => {
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
            default:
                return allowJoinBooking;
        }
    }, [serviceName, allowJoinBooking]);

    const initBooking = useCallback(() => {
        if (!isAvailableBooking && type !== BOOKING_ACTIONS.VIEW_BOOKING) {
            return;
        }
        switch (type) {
            case BOOKING_ACTIONS.VIEW_BOOKING:
            case BOOKING_ACTIONS.CHANGE_BOOKING:
                getBookingDetail({ serviceName, id });
                break;
            case BOOKING_ACTIONS.NEW_BOOKING:
            default:
                break;
        }
    }, [type, id, serviceName, getBookingDetail, isAvailableBooking]);

    const onClickChangeBooking = () => {
        setBookingType(BOOKING_ACTIONS.CHANGE_BOOKING);
        setBookingConfigHandler({
            serviceName,
            type: BOOKING_ACTIONS.CHANGE_BOOKING,
            id,
            onComplete,
            onCancel,
        });
    };

    useEffect(() => {
        initBooking();
    }, [initBooking]);

    return (
        <>
            {isAvailableBooking && (
                <>
                    {bookingType === BOOKING_ACTIONS.VIEW_BOOKING && (
                        <ViewBooking onChangeBooking={onClickChangeBooking} />
                    )}

                    {/* For NEW BOOKING & CHANGE BOOKING */}
                    {bookingType !== BOOKING_ACTIONS.VIEW_BOOKING && (
                        <CreateBooking id={id} />
                    )}
                </>
            )}
            {!isAvailableBooking && <WarningBookingNotAvailable />}
        </>
    );
};

Booking.propTypes = {
    serviceName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onComplete: PropTypes.func,
    onCancel: PropTypes.func,
};

Booking.defaultProps = {
    onComplete: null,
    onCancel: null,
};

const mapState = (state) => ({
    allowJoinBooking:
        state.systemParams?.info?.sysparams["1.0"]?.AllowJoinBooking || true,
});

const mapDispatch = (dispatch) => ({
    reset: () => dispatch(bookingActions.reset()),
    setBookingConfigHandler: (payload) =>
        dispatch(bookingActions.setBookingConfigHandler(payload)),
    getBookingDetail: (payload) =>
        dispatch(bookingActions.getBookingDetail(payload)),
});

export default connect(mapState, mapDispatch)(Booking);
