import { useCallback } from "react";
import accountService from "../../services/account";

const useTermDepositList = () => {
    const getActiveTermDepositList = useCallback(async (params = {}) => {
        const { createDateIndex, statusIndex } = params;
        const response = await accountService.getTermDepositList({
            createDateIndex,
            statusIndex,
            listStatus: "open,locked",
        });
        return response.data?.data;
    }, []);

    const getInactiveTermDepositList = useCallback(async (params = {}) => {
        const { createDateIndex, statusIndex } = params;
        const response = await accountService.getTermDepositList({
            createDateIndex,
            statusIndex,
            listStatus: "closed",
        });
        return response.data?.data;
    }, []);

    return { getActiveTermDepositList, getInactiveTermDepositList };
};

export default useTermDepositList;
