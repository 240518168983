import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase } from "@material-ui/core";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
        },
        buttonWrapper: {
            position: "relative",
            display: "inline-flex",
        },
        button: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.neutral.grey1,
            "& > span": {
                fontSize: 14,
                marginRight: theme.spacing(0.75),
            },
            "& p": {
                textTransform: "inherit",
            },
        },
        error: {
            color: theme.palette.error.main,
        },
        success: {
            color: theme.palette.success.main,
        },
        disabled: {
            cursor: "default",
            opacity: theme.palette.opacity.disabled,
            "& .MuiButton-startIcon": {
                color: theme.palette.primary.contrastText,
                opacity: theme.palette.opacity.disabled,
            },
        },
        small: {
            "& > span": {
                fontSize: 12,
                marginRight: theme.spacing(0.5),
            },
        },
        large: {
            "& > span": {
                fontSize: 16,
            },
        },
    };
});

const useCircularProps = (buttonSize) => {
    let size = 18;
    if (buttonSize === "large") {
        size = 20;
    } else if (buttonSize === "small") {
        size = 16;
    } else {
        size = 14;
    }
    const inlineStyles = {
        marginTop: -size / 2,
        marginLeft: -size / 2,
    };
    return [size, inlineStyles];
};

/**
 * @param {Object} props
 * @param {number} props.ml
 * @param {number} props.mr
 * @param {number} props.mt
 * @param {number} props.mb
 * @param {boolean} props.progressing
 * @param {boolean} props.disabled
 * @param {string} props.className
 * @param {object} [props.buttonProps]
 * @param {object} [props.buttonProps.classes]
 * @param {object} [props.circularProps]
 * @param {function} props.onClick
 * @param {("large"|"small")} props.size
 * @param {("submit"|"button")} props.type
 */

const LspButtonIconLink = ({
    ml,
    mr,
    mt,
    mb,
    progressing,
    disabled,
    className,
    children,
    buttonProps,
    circularProps,
    onClick,
    size,
    color,
    startIcon,
}) => {
    const classes = useStyles();
    const [circularSize, circularInlineStyles] = useCircularProps(size);

    const typoVariant = useMemo(() => {
        switch (size) {
            case "small":
                return "button3";
            case "medium":
                return "button2";
            case "large":
            default:
                return "button1";
        }
    }, [size]);

    const typoColor = useMemo(() => {
        switch (color) {
            case "success":
                return "success";
            case "error":
                return "error";
            default:
                return "grey";
        }
    }, [color]);

    return (
        <Box
            ml={ml}
            mr={mr}
            mb={mb}
            mt={mt}
            className={clsx(classes.buttonWrapper, {
                [className]: !!className,
            })}
        >
            <ButtonBase
                className={clsx(classes.button, {
                    [classes.disabled]: disabled || progressing,
                    [classes.large]: size === "large",
                    [classes.small]: size === "small",
                    [classes[color]]: color,
                })}
                onClick={onClick}
                disabled={disabled || progressing}
                {...buttonProps}
            >
                {startIcon}
                <LspTypography color={typoColor} variant={typoVariant}>
                    {children}
                </LspTypography>
            </ButtonBase>
            {progressing && (
                <CircularProgress
                    {...circularProps}
                    className={clsx(classes.buttonProgress, {
                        [circularProps?.className]: !!circularProps?.className,
                    })}
                    style={circularInlineStyles}
                    size={circularSize}
                />
            )}
        </Box>
    );
};

LspButtonIconLink.propTypes = {
    ml: PropTypes.number,
    mr: PropTypes.number,
    mt: PropTypes.number,
    mb: PropTypes.number,
    progressing: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["large", "small", "medium"]),
    color: PropTypes.oneOf(["success", "error", "default"]),
    type: PropTypes.oneOf(["submit", "button"]),
};

LspButtonIconLink.defaultProps = {
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
    progressing: false,
    disabled: false,
    className: "",
    onClick: undefined,
    size: "medium",
    color: "default",
    type: "button",
};

export default memo(LspButtonIconLink);
