import { Icon, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { memo, useMemo, useEffect } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    listbox: {
        padding: 0,
    },
    option: {
        padding: 0,
    },
    bankItem: {
        width: "100%",
        padding: theme.spacing(2, 0),
        margin: theme.spacing(0, 2),
        borderBottom: `1px solid ${theme.palette.background.grey}`,

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    bankItemIcon: {
        marginRight: theme.spacing(1),
        width: 32,
        display: "flex",
        justifyContent: "center",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: theme.shape.radiusLevels[0],
        },
    },
    bankItemName: {
        flex: 1,
    },
    fastTransferIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(14),
        opacity: 0,
        "&.visible": {
            opacity: 1,
        },
    },
}));

const LspBankList = ({ disableFastTransfer, bankList, ...props }) => {
    const classes = useStyles();

    const bankListDisplay = useMemo(() => {
        return (bankList || []).filter((item) => !item?.isRemove);
    }, [bankList]);

    const defaultImgURL = useMemo(
        () =>
            `${process.env.REACT_APP_API_URL}/cxpassets/frontend/images/banklist-logos/Default.png`,
        []
    );

    const filterOptions = (options, { inputValue }) => {
        const bank = options?.filter((item) => {
            const { bankName, keyWord } = item;

            return (
                bankName?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
                keyWord?.toLowerCase()?.includes(inputValue?.toLowerCase())
            );
        });

        return bank;
    };
    return (
        <Autocomplete
            options={bankListDisplay}
            classes={{
                option: classes.option,
                listbox: classes.listbox,
            }}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option?.bankName || ""}
            renderOption={(option) => {
                const hostUrl = process.env.REACT_APP_API_URL;
                const url = hostUrl + option.logo;
                return (
                    <>
                        <div className={classes.bankItem}>
                            <div className={classes.bankItemIcon}>
                                <img
                                    src={url || defaultImgURL}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = defaultImgURL;
                                    }}
                                    alt="Bank Logo"
                                />
                            </div>

                            <LspTypography
                                className={classes.bankItemName}
                                variant="body2"
                            >
                                {option.bankName}
                            </LspTypography>

                            {!disableFastTransfer && (
                                <Icon
                                    className={clsx(
                                        "font-icon icon-fasttransfer",
                                        classes.fastTransferIcon,
                                        {
                                            visible:
                                                option.realTimeTransfer === "Y",
                                        }
                                    )}
                                />
                            )}
                        </div>
                    </>
                );
            }}
            {...props}
        />
    );
};

LspBankList.propTypes = {
    disableFastTransfer: PropTypes.bool,
    bankList: PropTypes.instanceOf(Array),
};

LspBankList.defaultProps = {
    disableFastTransfer: false,
    bankList: [],
};

const mapState = (state) => ({
    bankList: state.bank.bankList.data,
});
export default connect(mapState, null)(LspBankList);
