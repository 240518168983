import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { Box, Icon, makeStyles, Paper, Divider } from "@material-ui/core";
import { memo, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import logo from "@assets/images/logo-liberty.png";
import { connect } from "react-redux";
import LspCheckbox from "@components/LspCheckbox";
import TermCondition from "@components/TermCondition";
import { KIND_OPTIONS } from "../../wealthConstants";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        "& img": {
            width: 200,
            margin: "auto",
            display: "block",
        },
    },
    body: {
        overflow: "hidden",
        textAlign: "center",
    },
    header: {
        padding: theme.spacing(2),
        background: theme.palette.primary.main,
        color: theme.palette.neutral.white,
        textAlign: "center",
        fontSize: theme.typography.pxToRem(16),
        "& > span:last-child": {
            fontWeight: 600,
        },
    },
    divider: {
        margin: theme.spacing(2, 4),
    },
}));
const WealthTravelConfirmation = ({
    onBack,
    onSubmit,
    submitting,
    selectedPackage,
    ownerPolicy,
    travelDate,
    destination,
    attendant,
    dependentPolicy,
}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useClassesShared();
    const { spendAccount } = useAccounts();
    const { formatNumber } = useNumber();

    const onSubmitHandler = useCallback(() => {
        const policyHolder = {
            fullName: ownerPolicy?.fullName,
            phoneNumber: ownerPolicy?.phoneNumber,
            address: ownerPolicy?.address,
            legalId: ownerPolicy?.legalId,
            dateOfBirth: ownerPolicy?.dateOfBirth,
            nationality: ownerPolicy?.nationality?.key,
            gender: parseInt(ownerPolicy?.gender),
            email: ownerPolicy?.email,
            city: ownerPolicy?.city?.cfgKey,
            district: ownerPolicy?.district?.cfgKey,
        };
        // eslint-disable-next-line prefer-const
        let payload = {
            productKey: selectedPackage?.productKey,
            fromDate: travelDate?.fromDate,
            toDate: travelDate?.toDate,
            areaCode: destination?.id,
            policyType: attendant?.type,
            amount: selectedPackage?.pricing,
            policyHolder,
            certLang: i18n?.language,
        };

        if (attendant?.type === KIND_OPTIONS.ME_AND_FAMILY) {
            payload.adults = attendant?.adult;
            payload.dependents = attendant?.child ? attendant?.child : 0;
            payload.dependentPeople = dependentPolicy;
        }
        onSubmit(payload);
    }, [
        ownerPolicy,
        selectedPackage,
        travelDate,
        destination,
        attendant,
        i18n?.language,
        onSubmit,
        dependentPolicy,
    ]);

    const [agree, setAgree] = useState(false);

    const termConditionContent = useMemo(() => {
        return t("wealth:GE00050", { returnObjects: true })[1];
    }, [t]);

    const termConditionKeys = useMemo(() => {
        return t("wealth:GE00051", { returnObjects: true })[1];
    }, [t]);

    return (
        <div className={classes.wrapper}>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-move-money" />
                <LspTypography>{t("wealth:GE00121")}</LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <Paper className={classes.body}>
                    <Box className={classes.header}>
                        <span>{`${t("wealth:GE00092")}: `}</span>
                        <span>
                            {formatNumber(spendAccount?.availableAmount)}
                        </span>
                    </Box>
                    <Box p={2}>
                        <img alt="logo" src={logo} />
                        <Divider className={classes.divider} />
                        <LspTypography variant="title2" color="primary">
                            {formatNumber(selectedPackage?.pricing)}
                        </LspTypography>
                        <Divider className={classes.divider} />
                        <LspTypography uppercase variant="heading2">
                            {t("payee_card_description_label")}
                        </LspTypography>
                        <LspTypography color="grey">
                            {t("wealth:lbi_travel_receipt_desc_label")}
                        </LspTypography>
                    </Box>
                </Paper>

                <Box display="flex" marginTop={2} alignItems="flex-start">
                    <LspCheckbox
                        onChange={() => setAgree((prev) => !prev)}
                        checked={agree}
                    />
                    <TermCondition
                        content={termConditionContent}
                        keyList={termConditionKeys}
                        type="NEW_TAB"
                    />
                </Box>

                <LspButton
                    fullWidth
                    className={sharedClasses.button}
                    onClick={onSubmitHandler}
                    disabled={submitting || !agree}
                    progressing={submitting}
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </div>
    );
};
const mapState = (state) => ({
    ownerPolicy: state.insurance.ownerPolicy,
    selectedPackage: state.insurance.selectedPackage,
    destination: state.insurance.destination,
    attendant: state.insurance.attendant,
    travelDate: state.insurance.travelDate,
    dependentPolicy: state.insurance.dependentPolicy,
});

export default memo(connect(mapState)(WealthTravelConfirmation));
