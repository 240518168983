import { useCallback, useEffect, useState } from "react";
import { Badge, ButtonBase } from "@material-ui/core";
import NotificationPopover from "@components/NotificationPopover";
import { connect } from "react-redux";
import notificationAction from "@redux/actions/notification";
import { NotificationInactiveIcon } from "@components/LspIcon";
import NotificationPopoverMKT from "@components/NotificationPopover/NotificationPopoverMKT";
import notificationService from "@services/notification";
// import notificationService from "@services/notification";

const NotificationButton = ({
    className,
    checkNotification,
    numberOfNotification,
}) => {
    const [notificationEl, setNotificationEl] = useState(null);

    const updateAllNotifyStatus = useCallback(() => {
        const payload = {
            action: "R",
            id: "ALL",
        };
        notificationService.updateNotificationStatus(payload);
    }, []);

    const toggleNotificationPopover = useCallback(
        (event) => {
            if (numberOfNotification > 0) {
                updateAllNotifyStatus();
                setTimeout(() => {
                    checkNotification();
                }, 1000);
            }

            setNotificationEl((el) => (el ? null : event.currentTarget));
        },
        [numberOfNotification, updateAllNotifyStatus, checkNotification]
    );

    useEffect(() => {
        checkNotification();
    }, [checkNotification]);

    return (
        <>
            <ButtonBase
                className={className}
                aria-label="notification"
                onClick={toggleNotificationPopover}
            >
                <Badge
                    badgeContent={numberOfNotification}
                    max={9}
                    color="error"
                >
                    <NotificationInactiveIcon />
                </Badge>
            </ButtonBase>
            <NotificationPopover
                anchorEl={notificationEl}
                onClose={toggleNotificationPopover}
            />
            <NotificationPopoverMKT />
        </>
    );
};

const mapState = (state) => ({
    numberOfNotification: state.notification.numberOfNotification,
    suggestedNotifications: state.notification.suggestedNotifications,
});

const mapDispatch = (dispatch) => ({
    checkNotification: () =>
        dispatch(notificationAction.checkNotificationRequest()),
});

export default connect(mapState, mapDispatch)(NotificationButton);
