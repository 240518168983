import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import LspButton from "@components/LspButton";
import { RESPONSE_CODE } from "@config/constants";
import Loading from "@components/Loading";
import { makeStyles } from "@material-ui/core";
import termDepositService from "@services/term-deposit";
import LspTypography from "@components/LspTypography";
import clsx from "clsx";
import RolloverHistoryRecord from "./RolloverHistoryRecord";

const useStyles = makeStyles((theme) => ({
    content: {
        color: theme.palette.black.main,
        minHeight: 100,
    },
    noRecord: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const RolloverHistoryDialog = ({ tdId, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getDetails = useCallback(async () => {
        if (!tdId) {
            return;
        }
        setLoading(true);
        const response = await termDepositService.getTermDeposit({ tdId });
        if (unmounted.current) {
            return;
        }
        if (response.ok) {
            const { code, data } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    if (Array.isArray(data?.history)) {
                        setList(data.history);
                    }
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    }, [tdId]);

    useEffect(() => {
        getDetails();
    }, [getDetails]);

    return (
        <LspDialog open={!!tdId} maxWidth="sm" onClose={onClose}>
            <LspDialogTitle>
                {t("td_details_rollover_history_lb")}
            </LspDialogTitle>
            <LspDialogContent
                className={clsx(classes.content, {
                    [classes.noRecord]: list.length === 0,
                })}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {list.length === 0 && (
                            <LspTypography variant="body2">
                                {t("td_no_rollover_history")}
                            </LspTypography>
                        )}
                        {list.map((item) => {
                            return (
                                <RolloverHistoryRecord
                                    key={item.histId}
                                    {...item}
                                />
                            );
                        })}
                    </>
                )}
            </LspDialogContent>
            <LspDialogActions>
                <LspButton fullWidth progressing={false} onClick={onClose}>
                    {t("lb_close")}
                </LspButton>
            </LspDialogActions>
        </LspDialog>
    );
};

export default memo(RolloverHistoryDialog);
