import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    bankInfo: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        fontFamily: theme.typography.fontFamily,
    },
    accountName: {
        color: theme.palette.white.contrastText,
        marginBottom: theme.spacing(0.5),
        textTransform: "uppercase",
        textAlign: "center",
    },
    bankNameBox: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.white.contrastText,
    },
    bankName: {
        color: theme.palette.magicalGrey[400],
        marginBottom: theme.spacing(0.5),
        textAlign: "center",
        cursor: "pointer",

        "&:last-child": {
            marginBottom: theme.spacing(0),
        },
        "& span": {
            fontSize: theme.typography.pxToRem(12),
            marginLeft: theme.spacing(0.5),
        },
    },
}));

const BankQRCodeInfo = ({ bankInfo, featureState, isActiveOnlyFullName }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    return (
        <>
            <div className={classes.bankInfo}>
                {isActiveOnlyFullName && (
                    <LspTypography
                        className={classes.accountName}
                        variant="heading1"
                    >
                        {bankInfo?.fullName}
                    </LspTypography>
                )}
                {!isActiveOnlyFullName && (
                    <>
                        <LspTypography
                            className={classes.accountName}
                            variant="heading1"
                        >
                            {bankInfo?.fullName}
                        </LspTypography>
                        <div className={classes.bankNameBox}>
                            <LspTypography
                                className={classes.bankName}
                                variant="body1"
                                aria-controls="simple-menu-account"
                                aria-haspopup="true"
                                component="span"
                            >
                                {bankInfo?.accountNumber}
                            </LspTypography>
                        </div>
                        <LspTypography
                            className={classes.bankName}
                            variant="body1"
                        >
                            {featureState?.enableIBFTGW
                                ? t(
                                      "qrpayment:qrpayment_dynamic_text_bank_Timo"
                                  )
                                : `${bankInfo?.bankShortName} - ${bankInfo?.bankName}`}
                        </LspTypography>
                    </>
                )}
            </div>
        </>
    );
};

BankQRCodeInfo.propTypes = {
    bankInfo: PropTypes.shape({
        accNumber: PropTypes.string,
        amount: PropTypes.number,
        bankName: PropTypes.string,
        bankShortName: PropTypes.string,
        description: PropTypes.string,
        reminded: PropTypes.bool,
        sender: PropTypes.bool,
        senderId: PropTypes.number,
        senderName: PropTypes.string,
        status: PropTypes.string,
        timoUser: PropTypes.bool,
    }),
    isActiveOnlyFullName: PropTypes.bool,
};

BankQRCodeInfo.defaultProps = {
    bankInfo: {},
    isActiveOnlyFullName: false,
};

const stateProps = (state) => ({
    featureState: state.user.featureState.data,
});

const dispatchProps = () => ({});
export default memo(connect(stateProps, dispatchProps)(BankQRCodeInfo));
