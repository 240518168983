import { Box, makeStyles } from "@material-ui/core";
import logo from "@assets/images/logo_discourse.png";

import CommunityAuthQr from "./CommunityAuthQr";
import CommunityAbout from "./CommunityAbout";

const useStyles = makeStyles((theme) => ({
    container: {
        background: `linear-gradient(0deg, rgba(28,28,28, 0.075) 0%, rgba(28,28,28, 0.075) 100%),linear-gradient(0deg, #f9fcff 100%, #f9fcff 100%)`,
        height: "100vh",
    },
    backgroundContainer: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        background: `linear-gradient(90deg, #1955bd 0%, #3877e5 100%)`,
        clipPath: `ellipse(148% 70% at 91% -14%)`,
    },
    headerBar: {
        position: "absolute",
        top: 0,
        background: "#f9fcff",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    headerBarWrapper: {
        width: "100%",
        maxWidth: "1150px",
    },
    logo: {
        maxWidth: 40,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.25),
    },
    mainOutlet: {
        width: "100%",
        marginTop: "5%",
        marginLeft: "auto",
        marginRight: "auto",
        background: theme.palette.neutral.grey4,
        borderRadius: "1em",
        maxWidth: "1150px",
        paddingBottom: theme.spacing(4),
        boxShadow: `0 24px 40px rgb(0 0 0 / 7%), 0 10.8529px 24.1177px rgb(0 0 0 / 5%), 0 4.50776px 10.0172px rgb(0 0 0 / 4%), 0 1.63037px 3.62304px rgb(0 0 0 / 2%)`,
        position: "relative",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            marginTop: "54px",
            borderRadius: 0,
            height: "100vh",
        },
    },
}));

const LoginCommunity = () => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.backgroundContainer} />
            <Box className={classes.headerBar}>
                <Box className={classes.headerBarWrapper}>
                    <img src={logo} alt="timo logo" className={classes.logo} />
                </Box>
            </Box>
            <Box className={classes.mainOutlet}>
                <CommunityAuthQr />
                <CommunityAbout />
            </Box>
        </Box>
    );
};

export default LoginCommunity;
