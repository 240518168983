import LspTypography from "@components/LspTypography";
import { Box, ButtonBase, Icon, makeStyles } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { format, isValid, parse } from "date-fns";
import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 22,
        margin: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(3),
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 100,
        color: theme.palette.primary.main,
        display: "block",
    },
    label: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        fontSize: 16,
    },
}));

const ViewBookingHangout = ({ detail, onChangeBooking }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const hangouts = t("hangout:hangouts", { returnObjects: true });

    const content = useMemo(() => {
        if (!detail) return;

        const parsedTime = parse(
            detail?.fromTime,
            "dd/MM/yyyy HH:mm:ss",
            new Date()
        );

        const hangout = hangouts?.find(
            (item) => item?.id === detail?.locationId
        );

        return {
            address: {
                label: t("booking_place_web"),
                name: hangout?.fullName || detail?.place,
                value: hangout?.address || detail?.fullAddress,
            },
            time: {
                label: t("lb_time"),
                value: isValid(parsedTime)
                    ? format(parsedTime, "HH:mm EEEE, dd/MM/yyyy")
                    : "",
            },
        };
    }, [t, detail, hangouts]);

    return (
        <>
            <Icon className={`font-icon icon-Plus-booking ${classes.icon}`} />
            <LspTypography className={classes.title} color="grey">
                {t("booking_view_title")}
            </LspTypography>
            <Box mb={2}>
                <LspTypography>{t("booking_view_notice")}</LspTypography>
            </Box>
            <Box>
                <div className={classes.label} htmlFor="address">
                    {content?.address?.label}
                </div>
                <LspTypography variant="heading1" color="primary">
                    {content?.address?.name}
                </LspTypography>
                <LspTypography variant="body1">
                    {content?.address?.value}
                </LspTypography>
            </Box>
            {content?.time?.value && (
                <Box mt={2}>
                    <div className={classes.label} htmlFor="address">
                        {content?.time?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.time?.value}
                    </LspTypography>
                </Box>
            )}
            <Box mt={2} mb={1}>
                <LspTypography>
                    {t("booking_pickup_please_bring_doc")}
                </LspTypography>
            </Box>
            <Box mb={2}>
                <ButtonBase onClick={onChangeBooking}>
                    <LspTypography color="success" variant="hyperlink1">
                        {t("booking_view_change_booking")}
                    </LspTypography>
                </ButtonBase>
            </Box>
        </>
    );
};

const mapState = (state) => ({
    serviceName: state.booking.serviceName,
    detail: state.booking.detail,
    onCancel: state.booking.onCancel,
});
const mapDispatch = (dispatch) => ({
    reset: () => dispatch(bookingActions.reset()),
});

export default connect(mapState, mapDispatch)(ViewBookingHangout);
