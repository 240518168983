/* eslint-disable react/forbid-prop-types */
import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    root: {
        width: "100%",
    },
    xSmall: {
        maxWidth: 400,
    },
    small: {
        maxWidth: 555,
    },
    medium: {
        maxWidth: 720,
    },
    large: {
        maxWidth: 900,
    },
    exLarge: {
        maxWidth: 1140,
    },
}));

/**
 * @typedef ContentProps
 * @type {object}
 * @property {("xs"|"sm"|"lg"|"xl"|"md")} [size="md"] - width of content
 * @property {object} boxProps - props of MuiBox
 */

/**
 * @param {ContentProps} props
 */
const Content = ({ size, children, boxProps }) => {
    const classes = useStyles();

    const classNameBySize = useMemo(() => {
        switch (size) {
            case "xs":
                return "xSmall";
            case "sm":
                return "small";
            case "lg":
                return "large";
            case "xl":
                return "exLarge";
            case "md":
            default:
                return "medium";
        }
    }, [size]);

    const className = useMemo(() => {
        if (boxProps?.className) {
            return clsx(
                classes.root,
                classes[classNameBySize],
                boxProps.className
            );
        }
        return clsx(classes.root, classes[classNameBySize]);
    }, [boxProps, classNameBySize, classes]);

    return (
        <div className={classes.wrapper}>
            <Box {...boxProps} className={className}>
                {children}
            </Box>
        </div>
    );
};

Content.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "lg", "xl", "md"]),
    boxProps: PropTypes.object,
    children: PropTypes.node,
};

Content.defaultProps = {
    size: "md",
    boxProps: {},
    children: <></>,
};

export default memo(Content);
