import { Divider, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import { InfoIcon } from "@components/LspIcon";

const InstallmentOthersStatusBody = ({ detail, classes }) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00009")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {detail.createdDate || t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:ins_detail_original_amount")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.originalAmount) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:lb_total_interest")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.convertFeeAmount) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00011")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.totalPayment) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:lb_monthly_principal")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.monthlyPrincipal) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.infoLine}>
                <LspTypography
                    variant="body2"
                    className={`${classes.label} ${classes.hint}`}
                    component="div"
                >
                    <div>{t("creditCard:IN00012")}</div>

                    <Tooltip title={t("creditCard:monthly_payment_note")} arrow>
                        <div className={classes.hintIcon}>
                            <InfoIcon />
                        </div>
                    </Tooltip>
                </LspTypography>
                <LspTypography variant="title2" color="error">
                    {formatNumber(detail.monthlyPayment) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
        </>
    );
};

export default InstallmentOthersStatusBody;
