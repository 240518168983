import { makeStyles, MenuItem } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import useNumber from "@helpers/useNumber";
import topUpAction from "@redux/actions/top-up";
import LspTextField from "@components/LspTextField";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    fetchingInput: {
        marginBottom: theme.typography.pxToRem(19),
    },
}));

const AmountInput = ({ amount, updateAmount, phoneNumber, phoneConfigs }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const amountListDefault = useMemo(() => {
        return ["30000", "50000", "100000", "200000", "300000", "500000"];
    }, []);
    const [amountList, setAmountList] = useState(amountListDefault);

    const getAmountList = useCallback(
        (phone, configs) => {
            if (!phone) return;

            if (!phone || phone === "" || !configs) return;

            let prefixValid = false;
            let list;

            for (let i = 0; i < configs.length; i++) {
                if (prefixValid) break;
                const supplier = configs[i];
                const { prefix } = supplier;

                for (let j = 0; j < prefix.length; j++) {
                    const numberPrefix = prefix[j].substr(1);
                    if (
                        phone.includes(`0${numberPrefix}`) ||
                        phone.includes(`0084${numberPrefix}`) ||
                        phone.includes(`+84${numberPrefix}`) ||
                        phone.includes(`84${numberPrefix}`)
                    ) {
                        prefixValid = true;
                        list = supplier.amount;
                        break;
                    }
                }
            }
            updateAmount(list ? list[0] : amountListDefault[0]);
            setAmountList(list || amountListDefault);
        },
        [amountListDefault, updateAmount]
    );

    useEffect(() => {
        if (phoneNumber && phoneConfigs.length > 0) {
            getAmountList(phoneNumber, phoneConfigs);
        }
    }, [phoneNumber, phoneConfigs, getAmountList]);

    return (
        <div>
            {!amountList && (
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={56}
                    className={classes.fetchingInput}
                />
            )}
            {amountList && (
                <LspTextField
                    label={t("topup_lb_amount")}
                    select
                    value={amount}
                >
                    {amountList?.map((a) => (
                        <MenuItem
                            key={a}
                            value={a}
                            onClick={() => updateAmount(a)}
                        >
                            {`${formatNumber(a)} VND`}
                        </MenuItem>
                    ))}
                </LspTextField>
            )}
        </div>
    );
};

const mapState = (state) => ({
    amount: state.topUp.amount,
    phoneNumber: state.topUp.phoneNumber,
    phoneConfigs: state.topUp.phoneConfigs,
});

const dispatchProps = (dispatch) => ({
    updateAmount: (payload) => dispatch(topUpAction.updateAmount(payload)),
});
export default connect(mapState, dispatchProps)(AmountInput);
