/* eslint-disable react/no-array-index-key */
import LspButton from "@components/LspButton";
import { EditIcon } from "@components/LspIcon";
import LspTypography from "@components/LspTypography";
import { INPUT_RULES, REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import invitationService from "@services/invitation";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    addedButton: {
        "& > button": {
            cursor: "default",
        },
    },
    cancelBtn: {
        marginLeft: theme.spacing(1),
    },
    editIconWrapper: {
        padding: 0,
        marginLeft: theme.spacing(1.75),
        marginRight: theme.spacing(1.75),
    },
    editIcon: {
        fontSize: 16,
    },
    name: {
        display: "flex",
    },
}));

const InvitationJoinedItem = ({ className, item, index }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const unmounted = useRef(false);
    const previousNickName = useRef(item.fullName || "");
    const [nickName, setNickName] = useState(item.fullName || "");
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState("");
    const [addPayeeLoading, setAddPayeeLoading] = useState(false);
    const [autoSaveSuccessfully, setAutoSaveSuccessfully] = useState(false);
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const isShowEditIcon = useMemo(() => {
        return (
            !item.isPayee &&
            !isEdit &&
            !autoSaveSuccessfully &&
            !addPayeeLoading
        );
    }, [item.isPayee, isEdit, autoSaveSuccessfully, addPayeeLoading]);

    const isShowAddPayeeButton = useMemo(() => {
        return !item.isPayee && !autoSaveSuccessfully;
    }, [item.isPayee, autoSaveSuccessfully]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onClickEditIcon = () => {
        setIsEdit(true);
    };

    const onClickCancelButton = () => {
        setNickName(previousNickName.current);
        setIsEdit(false);
    };

    const onChangeNickName = (e) => {
        const name = e.target.value;
        if (!name) {
            setError(t("msg_we_need_this"));
        } else {
            setError("");
        }
        setNickName(name);
    };

    const onAddPayeeHandler = async (e) => {
        e.preventDefault();
        if (!nickName) return;

        const nickNameConverted = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NICK_NAME,
            text: nickName,
        });

        setAddPayeeLoading(true);
        previousNickName.current = nickNameConverted;

        if (isEdit) {
            setIsEdit(false);
        }

        const response = await invitationService.autoAddPayee({
            accountNumber: item.accNumber,
            nickName: nickNameConverted,
        });

        setAddPayeeLoading(false);

        if (unmounted.current) return;

        const { code } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.CREATED:
                setAutoSaveSuccessfully(true);
                AlertController.show(
                    t("master:invite_friend_successful_added")
                );
                break;
            default:
                AlertController.show(
                    t("master:invite_friend_cannot_added", AlertType.Error)
                );
                break;
        }
    };

    return (
        <form
            className={className.item}
            key={index}
            onSubmit={onAddPayeeHandler}
        >
            <div>
                <div className={classes.name}>
                    {!isEdit && (
                        <LspTypography variant="subheading2">
                            {previousNickName.current}
                        </LspTypography>
                    )}

                    {isShowEditIcon && (
                        <IconButton
                            onClick={onClickEditIcon}
                            className={classes.editIconWrapper}
                        >
                            <EditIcon className={classes.editIcon} />
                        </IconButton>
                    )}

                    {isEdit && (
                        <>
                            <TextField
                                size="small"
                                value={nickName}
                                fullWidth
                                autoFocus
                                name="nickname"
                                error={!!error}
                                label={t("payee_name_label")}
                                helperText={error || " "}
                                inputProps={{
                                    maxLength: INPUT_RULES.payeeNameLength,
                                }}
                                onChange={onChangeNickName}
                            />
                        </>
                    )}
                </div>
                <LspTypography variant="body1">{item.phone}</LspTypography>
            </div>
            <div>
                {isShowAddPayeeButton && (
                    <>
                        <LspButton
                            type="submit"
                            size="small"
                            variant="secondary"
                            progressing={addPayeeLoading}
                        >
                            {t("payee_lb_add_payee")}
                        </LspButton>
                        {isEdit && (
                            <LspButton
                                size="small"
                                variant="error"
                                className={classes.cancelBtn}
                                onClick={onClickCancelButton}
                            >
                                {t("lb_cancel")}
                            </LspButton>
                        )}
                    </>
                )}

                {!isShowAddPayeeButton && (
                    <LspButton size="small" className={classes.addedButton}>
                        {t("if_btn_payee_added")}
                    </LspButton>
                )}
            </div>
        </form>
    );
};

export default InvitationJoinedItem;
