/* ------------- Types Definition ------------- */
export const WealthCreditCardActionType = Object.freeze({
    SetProductKey: "WealthCreditCardActionType/SetProductKey",
    SetSubProductKey: "WealthCreditCardActionType/SetSubProductKey",
    SetIsAppliedCreditCard: "WealthCreditCardActionType/SetIsAppliedCreditCard",
    SetCreditCardStatus: "WealthCreditCardActionType/SetCreditCardStatus",
    SetSuggestLimitationForm:
        "WealthCreditCardActionType/SetSuggestLimitationForm",
    SetPersonalForm: "WealthCreditCardActionType/SetPersonalForm",
    SetAddressForm: "WealthCreditCardActionType/SetAddressForm",
    SetFinanceForm: "WealthCreditCardActionType/SetFinanceForm",
    SetMaritalForm: "WealthCreditCardActionType/SetMaritalForm",
    SetDeliveryInfo: "WealthCreditCardActionType/SetDeliveryInfo",
    SetBookingInfo: "WealthCreditCardActionType/SetBookingInfo",
    SetPaymentMethod: "WealthCreditCardActionType/SetPaymentMethod",
    SetSubProductList: "WealthCreditCardActionType/SetSubProductList",
    Reset: "WealthCreditCardActionType/Reset",
});

/* ------------- Actions Definition ------------- */

const setProductKey = (payload) => {
    return {
        type: WealthCreditCardActionType.SetProductKey,
        payload,
    };
};
const setSubProductKey = (payload) => {
    return {
        type: WealthCreditCardActionType.SetSubProductKey,
        payload,
    };
};
const setIsAppliedCreditCard = (payload) => {
    return {
        type: WealthCreditCardActionType.SetIsAppliedCreditCard,
        payload,
    };
};
const setCreditCardStatus = (payload) => {
    return {
        type: WealthCreditCardActionType.SetCreditCardStatus,
        payload,
    };
};
const setSuggestLimitationForm = (payload) => {
    return {
        type: WealthCreditCardActionType.SetSuggestLimitationForm,
        payload,
    };
};
const setPersonalForm = (payload) => {
    return {
        type: WealthCreditCardActionType.SetPersonalForm,
        payload,
    };
};
const setAddressForm = (payload) => {
    return {
        type: WealthCreditCardActionType.SetAddressForm,
        payload,
    };
};

const setMaritalStatusForm = (payload) => {
    return {
        type: WealthCreditCardActionType.SetMaritalForm,
        payload,
    };
};
const setFinanceForm = (payload) => {
    return {
        type: WealthCreditCardActionType.SetFinanceForm,
        payload,
    };
};
const setPaymentMethod = (payload) => {
    return {
        type: WealthCreditCardActionType.SetPaymentMethod,
        payload,
    };
};
const setDeliveryInfo = (payload) => {
    return {
        type: WealthCreditCardActionType.SetDeliveryInfo,
        payload,
    };
};
const setBookingInfo = (payload) => {
    return {
        type: WealthCreditCardActionType.SetBookingInfo,
        payload,
    };
};
const setSubProductList = (payload) => {
    return {
        type: WealthCreditCardActionType.SetSubProductList,
        payload,
    };
};

const reset = (payload) => {
    return {
        type: WealthCreditCardActionType.Reset,
        payload,
    };
};

const wealthCreditCardAction = {
    setProductKey,
    setSubProductKey,
    setIsAppliedCreditCard,
    setCreditCardStatus,
    setSuggestLimitationForm,
    setPersonalForm,
    setAddressForm,
    setMaritalStatusForm,
    setFinanceForm,
    setPaymentMethod,
    setDeliveryInfo,
    setBookingInfo,
    setSubProductList,
    reset,
};

export default wealthCreditCardAction;
