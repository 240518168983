import PropTypes from "prop-types";

import { Box, makeStyles } from "@material-ui/core";
import downloadOnAppStore from "@assets/images/download-appstore.png";
import downloadOnGooglePlay from "@assets/images/download-googleplay.png";
import { GENERAL_CONTENT } from "@config/constants";
import { memo } from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    downloadApp: {
        margin: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    downloadImage: {
        width: 180,
        cursor: "pointer",
        margin: theme.spacing(0.5),
    },
    row: {
        flexDirection: "row",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    small: {
        "& img": {
            width: 158,
        },
    },
}));

/**
 * @typedef DownloadAppProps
 * @type {object}
 * @property {{"column"|"row"}} variant
 * @property {string} className
 * @property {string} size
 */

/**
 * @param {DownloadAppProps} props
 */

const DownloadApp = ({ variant, className, size }) => {
    const classes = useStyles();
    const directToStore = (store) => {
        window.open(GENERAL_CONTENT[store]);
    };

    return (
        <Box
            className={clsx(classes.downloadApp, {
                [classes.row]: variant === "row",
                [classes.small]: size === "small",
            })}
        >
            <img
                onClick={() => directToStore("APP_STORE")}
                alt="download_on_app_store"
                src={downloadOnAppStore}
                className={clsx(classes.downloadImage, {
                    [className]: className,
                })}
            />
            <img
                onClick={() => directToStore("CH_PLAY")}
                alt="download_on_google_play"
                src={downloadOnGooglePlay}
                className={clsx(classes.downloadImage, {
                    [className]: className,
                })}
            />
        </Box>
    );
};

DownloadApp.propTypes = {
    variant: PropTypes.oneOf(["column", "row"]),
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
};

DownloadApp.defaultProps = {
    variant: "column",
    className: "",
    size: "medium",
};

export default memo(DownloadApp);
