import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LspTextField from "@components/LspTextField";

const PayeeListSearchInput = ({ filter }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();

    const debounceSearch = useRef();

    // useEffect(() => {
    //     console.log("here");
    //     clearTimeout(debounceSearch.current);
    //     debounceSearch.current = setTimeout(() => {
    //         filter(searchTerm);
    //     }, 300);
    //     return () => clearTimeout(debounceSearch.current);
    // }, [searchTerm, filter]);

    const onChange = useCallback(
        (e) => {
            setSearchTerm(e.target.value);
            clearTimeout(debounceSearch.current);
            debounceSearch.current = setTimeout(() => {
                filter(e.target.value);
            }, 300);
        },
        [filter]
    );

    useEffect(
        () => () => {
            clearTimeout(debounceSearch.current);
        },
        []
    );

    return (
        <LspTextField
            disableHelperText
            label={t("investigation_lb_search")}
            value={searchTerm}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default memo(PayeeListSearchInput);
