import { useEffect, useRef, useState } from "react";
import { makeStyles, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import GlobalDialogController from "../helpers/controllers/GlobalDialogController";
import { LspDialogActions } from "./LspDialog";
import {
    LspDialogNoHeaderContent,
    LspDialogNoHeaderTitle,
} from "./LspNoHeaderDialog";
import LspButton from "./LspButton";
import { RESPONSE_CODE } from "../config/constants";
import userService from "../services/user";
import ApiCancelToken from "../helpers/controllers/ApiCancelToken";

const useStyles = makeStyles(() => ({
    wrapper: {
        margin: "auto",
    },
}));

const WarningVerifyEmailEkycDialog = () => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "hangout");
    const [resending, setResending] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const resendEmail = () => {
        setResending(true);
        userService.verifyEmailForEkycUser().then((response) => {
            setResending(false);

            if (unmounted.current) {
                return;
            }

            if (response.ok) {
                const { code } = response.data;
                if (code !== RESPONSE_CODE.SUCCESS) {
                    switch (code) {
                        case RESPONSE_CODE.UNAUTHORIZED:
                            ApiCancelToken.forceLogout();
                            break;
                        default:
                            break;
                    }
                }
            }
        });
    };

    const closePopup = () => {
        ApiCancelToken.forceLogout();
        GlobalDialogController.hide();
    };

    return (
        <div className={classes?.wrapper}>
            <LspDialogNoHeaderTitle>
                <div>
                    <Icon className="font-icon icon-Plus-EMAIL1" />
                    <Icon
                        className="font-icon icon-close close-custom-icon"
                        onClick={closePopup}
                    />
                </div>
                <div>{t("ekyc_verify_email_title_web")}</div>
            </LspDialogNoHeaderTitle>
            <LspDialogNoHeaderContent>
                <p>{t("ekyc_verify_email_web")}</p>
                <Interweave
                    content={t("ekyc_contact_plus_web").replace(
                        "%@",
                        t("hangout:callcenter_number")
                    )}
                    tagName="p"
                />
            </LspDialogNoHeaderContent>
            <LspDialogActions>
                <LspButton
                    type="submit"
                    fullWidth
                    progressing={resending}
                    onClick={resendEmail}
                >
                    {t("email_not_verified_btn_resend")}
                </LspButton>
            </LspDialogActions>
        </div>
    );
};

export default WarningVerifyEmailEkycDialog;
