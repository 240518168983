/* eslint-disable react/no-array-index-key */
import { ButtonBase, Paper } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import useSharedClasses from "@containers/Wealth/WealthCreditCard/wealthCreditCardSharedClasses";
import PageHeaderBack from "@components/PageHeaderBack";
import { WEALTH_CREDIT_CARD_STEPS } from "@containers/Wealth/wealthConstants";

const WealthCreditCardBlocked = ({ onNext }) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();

    return (
        <>
            <PageHeaderBack>
                {t("wealth:mc_v3_nolonger_mc_title")}
            </PageHeaderBack>
            <Paper className={`highlight ${sharedClasses.boxWrapper}`}>
                <LspTypography
                    variant="body2"
                    color="primary"
                    style={{ textAlign: "center" }}
                >
                    {t("wealth:mc_v3_nolonger_msg1")}
                </LspTypography>
                <br />
                <LspTypography variant="body2">
                    {t("wealth:mc_v3_nolonger_msg2").replace(
                        "%@",
                        t("wealth:mc_v3_timocare_no")
                    )}
                </LspTypography>

                <ButtonBase
                    style={{
                        marginTop: 24,
                        marginBottom: 16,
                        width: "100%",
                    }}
                    onClick={() => onNext(WEALTH_CREDIT_CARD_STEPS.INTRO)}
                >
                    <LspTypography variant="hyperlink1" color="success">
                        {t("wealth:mc_v3_link_about_mc")}
                    </LspTypography>
                </ButtonBase>
            </Paper>
        </>
    );
};

export default memo(WealthCreditCardBlocked);
