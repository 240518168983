/* eslint-disable react/no-array-index-key */
import { Paper } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import Interweave from "interweave";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

const WealthCreditCardForeignerWarning = ({ onNext, onBack }) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Interweave content={t("wealth:mc_v3_foreigner_title")} />
            </PageHeaderBack>
            <Paper className={`highlight ${sharedClasses.boxWrapper}`}>
                <LspTypography
                    variant="body1"
                    color="black"
                    style={{ textAlign: "center" }}
                >
                    {t("wealth:mc_v3_foreigner_subtitle")}
                </LspTypography>
                <ul className={sharedClasses.contentList}>
                    {t("wealth:mc_v3_foreigner_msg", {
                        returnObjects: true,
                    })?.map((c, index) => {
                        return (
                            <li key={index}>
                                <Interweave content={c} />
                            </li>
                        );
                    })}
                </ul>
            </Paper>
            <LspButton fullWidth onClick={onNext}>
                {t("wealth:mc_v3_btn_letsgo")}
            </LspButton>
        </>
    );
};

export default memo(WealthCreditCardForeignerWarning);
