export const ThemeName = Object.freeze({
    Purple: "purple",
    Blue: "blue",
    Orange: "orange",
    Gold: "gold",
});
/**
 * Fetching status for async reducer
 */
export const FetchStatus = Object.freeze({
    Success: "success",
    Error: "error",
    Pending: "pending",
    Cancel: "cancel",
});

export const AppActionType = {
    Start: "AppActionType/Start",
    Stop: "AppActionType/Stop",
    SwitchPaletteType: "AppActionType/SwitchPaletteType",
    SwitchThemeName: "AppActionType/SwitchThemeName",
    AgreeTermCondition: "AppActionType/AgreeTermCondition",
};

export const HeaderKey = Object.freeze({
    DeviceKey: "x-timo-devicekey",
    DeviceReg: "x-timo-devicereg",
    ContextId: "x-gofs-context-id",
    Token: "token",
});

export const DefaultOption = {
    Currency: "VND",
    Bank: "Timo",
};

export const AccountType = Object.freeze({
    SpendAccount: "1025",
    GoalSave: "1026",
    TermDeposit: "1027",
    MoneyPot: "1028",
    CreditCard: "9999",
    CreditCardOnline: "8888",
    SplitBill: "1910",
    SchedulePaymnet: "1909",
});

export const BalanceVisibility = Object.freeze({
    Show: 0,
    Hide: 1,
    HideAndNoHint: 2,
});

export const TransactionGroupType = Object.freeze({
    SpendAccount: "TransactionGroupType/SpendAccount",
    // GoalSave: "TransactionGroupType/GoalSave",
    TermDeposit: "TransactionGroupType/TermDeposit",
    CreditCard: "TransactionGroupType/CreditCard",
    Installment: "TransactionGroupType/Installment",
    PayAnyone: "TransactionGroupType/PayAnyone",
    GoalSave: "TransactionGroupType/GoalSave",
});

export const InstallmentStatus = Object.freeze({
    Active: "actived",
    Pending: "pending",
    Declined: "declined",
    Completed: "completed",
});

export const PayAnyoneStatus = Object.freeze({
    Active: "ACTIVE",
    Expired: "EXPIRED",
    Failed: "FAILED",
    Cancelled: "CANCELLED",
    Done: "DONE",
    Deleted: "DELETED",
    FE_WRONG_SECURITY_MANY_TIMES: "FE_WRONG_SECURITY_MANY_TIMES",
    FE_CLAIM_MONEY_SUCCESS: "FE_CLAIM_MONEY_SUCCESS",
});

export const AccountStatus = Object.freeze({
    New: "New",
    Active: "Active",
    Blocked: "Blocked",
    Info: "Info",
    TimoIn: "TimoIn",
    TimoOut: "TimoOut",
    NA: "N/A",
});

export const MoveMoneyType = {
    Internal: "internal",
    DebitCard: "debit-card",
    BankAccount: "bank-account",
    PayAnyone: "pay-anyone",
    Credit: "credit",
};

export const SpendAccountNavigationKey = {
    TransactionList: "transactions",
    TopUp: "top-up",
    MoveMoney: "move-money",
    BillPayment: "bill-payment",
    PendingPayment: "pending-payment",
    PaymentDetail: "detail",
    AddDestination: "add-destination",
};

export const SECURITY_KEY = {
    TWO_FACTOR: "two-factor",
};

export const TermDepositNavigationKey = {
    Active: "active",
    History: "history",
    OpenTermDeposit: "open",
    PartialWithdraw: "partial-withdraw",
    PreviewWithdraw: "preview-withdraw",
};

export const CreditCardNavigationKey = {
    root: "credit-card",
    TransactionList: "/credit-card/transactions",
    Installment: "/credit-card/installment",
    InstallmentDetail: "credit-card/installment/detail",
    InstallConversion: "credit-card/installment/conversion",
    InstallmentList: "/credit-card/installment/list",
    InstallmentAvailable: "/credit-card/installment/available",
    Statements: "/credit-card/statements",
    LastStatement: "/credit-card/statements/laststatement",
    CurrentStatement: "/credit-card/statements/currentstatement",
    Payment: "/credit-card/payment",
    Discount: "/credit-card/discount",
};

export const RequestDocumentKey = {
    documentInfo: "documentinfo",
};

export const BillNavigationKey = {
    BillList: "list",
    NewBill: "new",
};

export const CardNumberValidLengthValues = [16, 19];

export const PayeeDestinationType = {
    Card: "card",
    BankAccount: "bankAccount",
};

export const BankDetails = {
    BankInformation: "bank-details",
    BankSettings: "account-settings",
    Nickname: "nickname",
};

export const TermDepositStatus = {
    Opening: 1,
    Redeemed: 2,
    Locked: 5,
    Unknown: 0,
};

export const TermDepositRollType = Object.freeze({
    TOTAL_ROLLOVER: "GL",
    PARTIAL_ROLLOVER: "G",
    NO_ROLLOVER: "N",
});

export const RESPONSE_CODE = Object.freeze({
    DISCONNECT: -1,
    DISCONNECT_V2: 0,
    SUCCESS: 200,
    CREATED: 201,
    SUCCESS_V2: 210,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NO_EXISTING_MEMBER_ACCOUNT: 402,
    INVALID: 403,
    NOT_FOUND: 404,
    NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    WRONG_PASSWORD_MANY_TIMES: 407,
    NO_ENOUGH_MONEY_3: 407,
    REQUEST_TIMEOUT: 408,
    DAILY_LIMIT_TRANSFER: 409,
    WRONG_INFO: 410,
    NO_RECORD: 411,
    CANT_TRANSFER_TO_DESTINATION: 413,
    UNDER_MIN_TRANSFER: 414,
    CRYPTO_RELATED_TRANSACTION: 415,
    MAX_AMOUNT_TRANSFER: 416,
    EXISTING_DESTINATION: 418,
    TRANSFER_TO_YOURSELF: 419,
    MAX_LIMIT_AMOUNT_PAY_ANYONE: 420,
    DESTINATION_INVALID: 421,
    NO_ENOUGH_MONEY_2: 424,
    DESTINATION_NOT_FOUND: 422,
    FEATURE_UNAVAILABLE: 423,
    SPEND_NO_ENOUGH_MONEY: 424,
    CANT_TRANSFER_TO_BANK: 425,
    DAILY_LIMIT_GS: 426,
    CANT_WORK_THIS_TIME: 427,
    TECHNICAL_DIFFICULT: 500,
    UNSUPPORTED_OPERATION: 501,
    SERVER_UNAVAILABLE: 503,
    REFERRAL_CODE_INVALID: 505,
    REQUEST_TIMEOUT_V2: 508,
    CREATOR_CANCELED: 509,
    HAS_PAID: 510,
    DELETED_PAYMENT_ALREADY: 511,
    MAX_LIMIT_TRANSFER_PER_DAY: 513,
    MIN_LIMITED: 514,
    ERROR_DYNAMIC_CONTENT: 999,
    PAYMENT_UNAVAILABLE: 1005,
    FAST_TRANSFER_NOT_SUPPORT_CORE: 1503,
    DUPLICATED_USERNAME: 2003,
    PASSWORD_POLICIES_VIOLATED: 2004,
    USERNAME_POLICIES_VIOLATED: 2005,
    PASSWORD_EXPIRED: 2006,
    FAST_TRANSFER_ERROR_CODE: 2023,
    UNDER_MAINTENANCE: 3500,
    DESTINATION_NOT_EXISTING: 4100,
    DESTINATION_EXISTING_IN_CURRENT_PAYEE: 4101,
    DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO: 4102,
    DESTINATION_EXISTING_IN_PAYEE_LIST: 4103,
    CREDIT_CARD_APPLICATION_PENDING: 4200,
    CREDIT_CARD_APPLICATION_BLOCKED: 4201,
    CREDIT_CARD_APPLICATION_APPLIED: 4202,
    CREDIT_CARD_APPLICATION_NOT_PRE_QUALIFIED: 4204,
    CREDIT_CARD_APPLICATION_NOT_ELIGIBLE_AGE: 4206,
    CREDIT_CARD_APPLICATION_FAILED: 4302,
    DAILY_LIMITED_EXCEEDED: 4208,
    OPEN_VINA_WEALTH_SUCCESS_TOP_UP_FAILURE: 4211,
    LINK_ACCOUNT_EXISTED: 4212,
    LINK_ACCOUNT_FAILED: 4213,
    RETRY_EXCEEDED: 5004,
    NON_BOOKABLE_TIME_SCHEDULE: 5007,
    INVALID_CARD: 5011,
    ALREADY_RESET_CARD_PIN: 5014,
    ACTIVATE_CARD_FAILURE: 5015,
    DATABASE_ERROR: 5301,
    DUPLICATED_PAYEE: 5302,
    NOT_FOUND_IN_DATABASE: 5303,
    POT_NO_ENOUGH_MONEY: 5504,
    OTP_REQUIRED: 6001,
    PASSWORD_REQUIRED: 6002,
    IOTP_REQUIRED: 6006,
    TWO_FACTOR_REQUIRED: 6005,
    OPERATION_TIMEOUT: 6133,
    NEED_UPGRADE_APP_VERSION: 6777,
    MAX_FAIL_ATTEMPTS_REACHED: 6969,
    OVER_ALLOWED_MAX_AMOUNT: 7001,
    FAST_CASH_REACH_LIMIT: 7005,
    EKYC_REACH_LIMIT: 7006,
    PIN_FAILED_NOT_BLOCK: 7010,
    PIN_FAILED_BLOCK: 7011,
    INVALID_AMOUNT: 7100,
    NO_ENOUGH_MONEY: 7106,
    CANNOT_REDEEM_TERM_DEPOSIT: 7107,
    LOCKED_TERM_DEPOSIT: 7108,
    END_OF_DAY_JOB_RUNNING: 7121,
    CANNOT_PAYMENT_INSTALLMENT: 7201,
    CONVERSION_NOT_QUALIFIED: 7202,
    INSTALLMENT_LAST_MONTH_STATEMENT: 7203,
    DATABASE_NOT_FOUND: 7204,
    FAST_CASH_NOT_ALLOW: 7403,
    DUPLICATE_FIELD: 8101,
    INCORRECT_VALUE: 8102,
    MISMATCH_VALUE: 8103,
    OTP_EXPIRED: 8106,
    QUICK_CODE_INVALID: 8109,
    ACCOUNT_NO_CIF_WITHOUT_PASSWORD: 8110,
    REGISTER_CARD_FAILED: 8200,
    ACTIVATED_CARD_FAILED: 8202,
    REISSUED_CARD_HIT_LIMIT_TIMES: 8203,
    REISSUED_CARD_HIT_LIMIT_AMOUNT: 8204,
    REQUEST_PHYSICAL_CARD_PAYMENT_ERROR: 8228,
    REQUEST_PHYSICAL_CARD_SUCCESS: 8229,
    WAITING_APPROVAL: 8888,
    CLOSED_ACCOUNT: 8889,
    TOKEN_INVALID: 9103,
    TOKEN_EXPIRED: 9101,
    // FE_TOKEN_NULL: 520520,
    // FE_QUICK_CODE_REQUIRED: 313006,
    FE_USER_BLOCKED: 123123,
    CODE_USER_NOT_DONE_KYC: 4003,
    FE_CANCEL_TOKEN: -777,
    BVB_EXISTED_PHONE_NUMBER: 8305,
    BLACK_LIST: 8306,
    EXISTED: 8307,
    CREATE_ALIAS_FAIL: 8309,
    FEE_SUCCESS_ERROR_CREATE: 8308,
    NICKNAME_EXISTED: 8311,
    VALIDATION_SPECIAL_RATE: 7122,
    TD_ERROR: 7105,
    ACTIVE_OK_OFF_PHYSICAL_FALSE: 8226,
    MONTHLY_LIMIT_FOREIGNER: 8330,
    ACTIVE_PHYSICAL_FALSE: 8227,
    OVERDRAFT_REQUEST_PROCESSING_CODE: 272,
    GG_TWO_FACTOR: 6009,
    REMAIN_CHANGE_PASSWORD_14_DAY: 2007,
    REMAIN_CHANGE_PASSWORD_07_DAY: 2008,
    REMAIN_CHANGE_PASSWORD_03_DAY: 2009,
    REMAIN_CHANGE_PASSWORD_0_DAY: 2010,
    REQUIRED_ENROLL: 5200,
    RE_ENROLL: 6019,
    FOREIGNER: 5100,
    AUTH_BIOS: 6011,
    BIOS_NEW_DEVICE: 5000,
    AUTH: 5300,
    RE_ENROLL_ERROR: 6019,
});

export const ERROR_TYPES = Object.freeze({
    FTI: "FTI", // FTI stands for Financial Transaction Issue
    TIMEOUT: "TIMEOUT",
    SERVER_UNAVAILABLE: "SERVER_UNAVAILABLE",
    SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
    NO_CONNECTION: "NO_CONNECTION",
    TECHNICAL_DIFFICULT: "TECHNICAL_DIFFICULT",
    USER_BLOCKED: "USER_BLOCKED",
    FAST_CASH_LIMIT: "FAST_CASH_LIMIT",
    FAST_CASH_NOT_ALLOWED: "FASTCASH_NOT_ALLOWED",
    CUSTOM: "CUSTOM",
    FEATURE_UNAVAILABLE: "FEATURE_UNAVAILABLE",
    UNSUPPORTED_OPERATION: "UNSUPPORTED_OPERATION",
    DESTINATION_NOT_FOUND: "DESTINATION_NOT_FOUND",
    DESTINATION_INVALID: "DESTINATION_INVALID",
    PASSWORD_EXPIRED: "PASSWORD_EXPIRED",
    IOTP_REQUIRED: "IOTP_REQUIRED",
    EKYC_LIMIT: "EKYC_LIMIT",
    END_OF_DAY_JOB_RUNNING: "END_OF_DAY_JOB_RUNNING",
    UNAUTHORIZED: "UNAUTHORIZED",
    MAX_FAIL_ATTEMPTS_REACHED: "MAX_FAIL_ATTEMPTS_REACHED",
    CLOSED_ACCOUNT: "CLOSED_ACCOUNT",
    ERROR_DYNAMIC_CONTENT: "ERROR_DYNAMIC_CONTENT",
    DAILY_LIMIT_TRANSFER: "DAILY_LIMIT_TRANSFER",
    EKYC_REACH_LIMIT: "EKYC_REACH_LIMIT",
    TRANSFER_TO_YOURSELF: "TRANSFER_TO_YOURSELF",
    CRYPTO_RELATED_TRANSACTION: "CRYPTO_RELATED_TRANSACTION",
    NO_ENOUGH_MONEY: "NO_ENOUGH_MONEY",
    POT_NO_ENOUGH_MONEY: "POT_NO_ENOUGH_MONEY",
    MONTHLY_LIMIT_FOREIGNER: "MONTHLY_LIMIT_FOREIGNER",
    PASSWORD_14_DAY_CHANGE_REMAIN: "PASSWORD_14_DAY_CHANGE_REMAIN",
    PASSWORD_7_DAY_CHANGE_REMAIN: "PASSWORD_7_DAY_CHANGE_REMAIN",
    PASSWORD_3_DAY_CHANGE_REMAIN: "PASSWORD_3_DAY_CHANGE_REMAIN",
    PASSWORD_0_DAY_CHANGE_REMAIN: "PASSWORD_0_DAY_CHANGE_REMAIN",
    PASSWORD_FORCE_CHANGE_REMAIN: "PASSWORD_FORCE_CHANGE_REMAIN",
    ENROLL_DEFAULT: "ENROLL_DEFAULT",
    ENROLL_EXCEEDED_LIMIT: "ENROLL_EXCEEDED_LIMIT",
    ENROLL_FOREIGNER: "ENROLL_FOREIGNER",
    AUTH_BIOS: "AUTH_BIOS",
});

// If add new regex with need replace Vietnamese characters, MUSt TO update the useInputSpecialCharacter.js
export const REGEX_PATTERN = {
    PASSWORD: /^[a-z0-9A-Z!"#$%&'()*+,-./:;<=>?@`{|}_~\\[\]^\s]{1,}$/,
    PIN: /^[0-9]{6,6}$/,
    CARD_NUMBER: /^[0-9]{4,4}$/,
    DESCRIPTION: /^[a-z0-9A-Z\s]{1,}$/,
    PHONE: /^[0-9+]{1,10}$/,
    REFERRAL_CODE: /[^a-zA-Z0-9\s]/g,
    TRANSACTION_ID: /[^a-zA-Z0-9]/g,
    // eslint-disable-next-line no-useless-escape
    EMAIL: /^([\w-]+(?:\.[\w-]+)*)@((?:[a-z0-9\-]+\.)*[a-z0-9\-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{2})?)$/i,
    PASSPORT_IDNUMBER: /^[a-zA-Z0-9]{4,15}$/,
    PASSPORT_IDNUMBER_LIBERTY_CHILD: /^[a-zA-Z0-9-]{4,15}$/,
    PASSPORT_IDNUMBER_LIBERTY_ADULT: /^[a-zA-Z0-9]{4,15}$/,
    MEMBER_DETAIL: /[^a-zA-Z0-9@._\\-]/g,
    INSTALLMENT_NAME: /[^a-zA-Z0-9.]/g,
    NUMBER: /[^0-9\s]/g,
    USERNAME: /^[a-z0-9A-Z]{6,35}$/,
    FIRST_CHARACTER_IS_LETTER: /^[a-zA-Z]{1}[a-zA-Z0-9]+$/,
    NICK_NAME: /[^0-9A-Za-z\s]/g, // need replace Vietnamese characters to Universal characters
    OWNER_NAME: /[^A-Za-z\s]/g, // need replace Vietnamese characters to Universal characters,
    ADDRESS: /[^a-z0-9A-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ\-_().,/\s]{1,}$/,
    OWNER_NAME_REPLACEMENT: /[^a-z0-9A-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ\s]{1,}$/,
    DESCRIPTION_REPLACEMENT: /[^a-z0-9A-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ\s]{1,}$/,
    PASSPORT_IDNUMBER_REPLACEMENT: /[^a-zA-Z0-9]$/,
    PHONE_REPLACEMENT: /[^0-9+]$/,
    BILL_CUSTOMER_ID_REPLACEMENT: /[^a-z0-9A-Z_()-]$/,
    PASSWORD_REPLACEMENT: /[^a-z0-9A-Z!"#$%&'()*+,-./:;<=>?@`{|}_~\\[\]^\s]{1,}$/,
    NUMBER_REPLACEMENT: /[^0-9]/g,
    // eslint-disable-next-line no-useless-escape
    SPECIAL_CHARACTER_NICKNAME: /^(?=.*[a-z0-9A-Z])[a-z0-9A-Z'-]+$/g,
    LENGTH_CHARACTER_NICKNAME: /^.{4,19}$/,
    NOT_BEGIN_NUMBER_NICKNAME: /^[a-zA-Z!"#$%&'()*+,-./:;<=>?@`{|}_~\\[\]^\sZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ]/g,
};

export const APP_CONTENT = {
    LOGO_FONT_ICON: "font-icon icon-timonovp",
    LOGO_FONT_ICON_MINI: "font-icon icon-Timo-ReBrand",
    LOGO_FONT_ICON_MINI_CIRCLE: "font-icon icon-Logo-Payee",
    DEEPLINK_APP: "plus://plus.vn",
};

export const VALIDATION_ERROR_TYPES = {
    EMPTY: "EMPTY",
    INVALID: "INVALID",
    NO_SUPPORT: "NO_SUPPORT",
};

export const PRODUCT_TYPES = {
    ALL: "ALL",
    LIXI: "LIXI",
    PAY_ANYONE: "PA",
};

export const GENERAL_CONTENT = {
    APP_STORE: "https://apps.apple.com/app/id1521230347",
    CH_PLAY: "https://play.google.com/store/apps/details?id=io.lifestyle.plus",
};

export const INPUT_RULES = {
    payeeNameLength: 70,
    MAXIMUM_CHARACTER: 210,
};

export const LANGUAGE_OPTIONS = {
    EN: "en",
    VI: "vi",
    VN: "vn",
};

export const BANK_INFO = {
    DEFAULT_BANK_1_ID: 999,
    DEFAULT_BANK_1: "timo",
    DEFAULT_BANK_NAME_1: "Timo",
    DEFAULT_BANK_2_ID: 0, // BVB
    DEFAULT_BANK_2: "NH Ban Viet (VietCapital...",
    DEFAULT_BANK_2_FULL: "NH Ban Viet (VietCapital)",
};

export const PAYMENT_LABEL_STATUS = {
    NEW: "NEW",
    REMIND: "REMIND",
    CANCEL: "CANCEL",
    DELETED: "DELETED",
    HIDDEN: "HIDDEN",
};

// Using for LspDataDropdown component
export const DATA_DROPDOWN_TYPES = {
    PROVINCE: "City",
    DISTRICT: "District",
    WARD: "Ward",
    MARITAL_STATUS: "MaritalStatus",
    OCCUPATION: "Occupation",
    JOB_TITLE: "JobTitle",
};

export const TERM_CONDITION_TYPES = {
    DIALOG: "DIALOG",
    NEW_TAB: "NEW_TAB",
};

export const GENDER_LIST = [
    {
        cfgKey: "1",
        cfgValue: "lb_male",
    },
    {
        cfgKey: "0",
        cfgValue: "lb_female",
    },
];

export const DEEP_LINK_CONFIGS = {
    ACTION: {
        PUSH: "push",
        OPEN: "open",
    },
    STATUS: {
        FAIL: "fail",
        SUCCESS: "success",
    },
};

export const SHARED_CONFIG_KEY_SERVICE_NAME = {
    CHANGE_PIN: "CHANGE_PIN",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

export const FeatureComingSoonKey = {
    BillPayment: "bill-payment",
    Topup: "top-up",
    MasterCard: "creditCard",
    Loan: "loans",
    LifeInsurance: "lifeinsurance",
    PaymentRequest: "payment-request",
};
export const FeatureUnavailableKey = {
    PaymentRequest: "payment-request",
};

export const REGULATION_TYPE = {
    GS_PAGE: "gs_page",
    GS_CANNOT_CONTRIBUTE: "gs_cannot_contribute",
    TD_PAGE: "td_page",
    TD_CANNOT_OPEN: "td_cannot_open",
};

export const DOCUMENT_ERROR_TYPE = {
    EXPIRED_VISA: "expired_visa",
    VISA_PERIOD_LESS_SIX_MONTHS: "visa_period_less_six_months",
    VISA_DATE_LESS_MONTH: "visa_date_less_month",
    VISA_DATE_VALID: "visa_date_valid",
};

export const SPLIT_BILL_STATUS = Object.freeze({
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED",
});

export const ADJ_DIRECT = Object.freeze({
    HOME_PAGE: "plus.vn/HomeScreen/Open",
    ADJ_LINK:
        "https://qeek.adj.st/__HOME_PAGE__&adj_t=ipkdhr9&adj_fallback=__DECODE_URI__&adj_redirect=__DECODE_URI__&adj_redirect_macos=__DECODE_URI__",
    ADJ_HOME_PAGE_LINK:
        "https://qeek.adj.st/plus.vn/HomeScreen/Open?adj_t=lvdgk9q&adj_fallback=https://app.adjust.com/ub75sin?engagement_type=fallback_click",
    ADJ_STORE: "https://app.adjust.com/ub75sin?engagement_type=fallback_click",
    SPLIT_BILL: "plus.vn/splitbill.management/open",
});

export const METRIC_NAME = Object.freeze({
    TAP_PAYMENT: "tap_payment",
    START: "start",
    END: "end",
    TAP_DOWNLOAD: "tap_download",
    TAP_COPY_ACC: "tap_copy_acc",
    TAP_COPY_DES: "tap_copy_des",
    AGREE_POLICY: "agree_policy",
});

export const METRIC_SCREEN = Object.freeze({
    SPLIT_BILL: "web_spb_shareable_link",
    SPLIT_BILL_PAYMENT: "web_spb_method",
    LOGIN: "scr_login",
});

export const BANK_TRANSFER_TYPE = Object.freeze({
    INTERNAL: "internal",
    OVERSEA: "oversea",
    EWALLET: "eWallet",
    FAST_TRANSFER: "fast_transfer",
});

export const ALIAS_TYPE = Object.freeze({
    PHONE_NUMBER: "PhoneNumber",
    ALIAS_NICKNAME: "NickName",
});

export const SECRET_KEY = Object.freeze({
    SALT: "b0bcb8d7b17f65e742b51751b0182",
});

export const RECEIPT_TYPE = Object.freeze({
    CREDIT_CARD: "creditCard",
    SPEND_ACCOUNT: "spendAccount",
});

export const TRANSFER_DESTINATION_TYPES = Object.freeze({
    CREDIT: "creditcard",
    DEBIT: "debitcard",
    ACCOUNT: "BankAccount",
    DEFAULT_CARD: "Card",
});

export const CREDIT_CARD_STATUES = Object.freeze({
    PRE_QUALIFIED: 1,
    NON_QUALIFIED: 2,
    PENDING: 3,
    APPLIED: 4,
    BLOCKED: 5,
    INVALID_AGE: 6,
});

export const ONE_MILLION = 1000000000;

export const OVERDRAFT_KEY = {
    CREATE: "create",
    PREVIEW: "preview",
    DETAILS: "details",
    EARLY: "early",
};

export const OVERDRAFT_STATUS = {
    AVAILABLE: "AVAILABLE",
    FOREIGN: "FOREIGN",
    NOT_FOUND_TD_ELIGIBLE: "NOT_FOUND_TD_ELIGIBLE",
    PROCESSING: "PROCESS_OPENING",
    PROCESS_CLOSING: "PROCESS_CLOSING",
    OVERDRAFTED: "OVERDRAFTED",
    IN_BLACKLIST: "IN_BLACKLIST",
    HAVING_LOAN: "HAVING_LOAN",
    INELIGIBLE: "INELIGIBLE",
    OTP_WAITING: "OTP_WAITING",
};
export const ATTACHMENT_MODE = Object.freeze({
    CREATE: 0,
    MODIFY: 1,
});

export const OVERDRAFT_LOCK_TYPES = {
    A_NO_FUND_IN: "A_NO_FUND_IN",
    A_SWITCH_DEBT_GROUP: "A_SWITCH_DEBT_GROUP",
    SWITCH_DEBT_GROUP: "SWITCH_DEBT_GROUP",
    COPS_LOCK: "COPS_LOCK",
    A_EXPIRATION: "A_EXPIRATION",
    LOCK_CLOSURE: "LOCK_CLOSURE",
    FAIL_CLOSURE: "FAIL_CLOSURE",
};

export const LOCAL_STORAGE_STATUS = Object.freeze({
    OVERDRAFT_BANNER: "OVERDRAFT_BANNER",
    IBFT_BANK_NAME: "IBFT_BANK_NAME",
    IBFT_PHONE_NUMBER_AVAILABLE: "IBFT_PHONE_NUMBER_AVAILABLE",
    USER_ALLOW_TNC: "USER_ALLOW_TNC",
});
