import { Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoTooltip from "../../components/InfoTooltip";
import SwitchButton from "../../components/SwitchButton";
import { BANK_CARD_STATUS, CARD_STATUSES, CARD_TYPES } from "./constant";

const useStyles = makeStyles((theme) => ({
    cardStatus: {
        padding: theme.spacing(2, 3),
        background: theme.palette.background.grey,
        fontSize: theme.typography.pxToRem(16),
        textTransform: "uppercase",
        color: theme.palette.grey[700],
    },
    warning: {
        color: theme.palette.error.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    toggle: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));
const CardStatusBar = ({ card, fetching, lockCard, unlockCard }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [description, setDescription] = useState(null);
    const [isOn, setIsOn] = useState(false);

    const mapDebitCardLabel = useCallback(() => {
        let descriptionTmp = null;
        switch (card.cardStatus) {
            case CARD_STATUSES.ACTIVE:
                descriptionTmp = {
                    msg: t("mc_card_status").replace("%@", ""),
                    status: t("active_tobe_green"),
                    className: "success",
                };
                setIsOn(true);
                break;
            case CARD_STATUSES.BLOCKED:
                const isReIssueStatus =
                    card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                if (!isReIssueStatus) {
                    descriptionTmp = {
                        msg: t("mc_card_status").replace("%@", ""),
                        status: t("locked_tobe_red"),
                        className: "warning",
                    };
                    setIsOn(false);
                }
                break;
            default:
                break;
        }
        setDescription(descriptionTmp);
    }, [card, t]);

    const mapVisaDebitCardLabel = useCallback(() => {
        let descriptionTmp = null;
        switch (card.cardStatus) {
            case CARD_STATUSES.ACTIVE:
                descriptionTmp = {
                    msg: t("mc_card_status").replace("%@", ""),
                    status: t("active_tobe_green"),
                    className: "success",
                    type: CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD,
                };
                setIsOn(true);
                break;
            case CARD_STATUSES.BLOCKED:
                const isReIssueStatus =
                    card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                if (!isReIssueStatus) {
                    descriptionTmp = {
                        msg: t("mc_card_status").replace("%@", ""),
                        status: t("locked_tobe_red"),
                        className: "warning",
                        type: CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD,
                    };
                    setIsOn(false);
                }
                break;
            default:
                break;
        }
        setDescription(descriptionTmp);
    }, [card, t]);

    const mapCreditCardLabel = useCallback(() => {
        let descriptionTmp = null;
        switch (card.cardStatus) {
            case CARD_STATUSES.ACTIVE:
                if (card.avaiLockUnLockMC) {
                    descriptionTmp = {
                        msg: t("mc_card_status").replace("%@", ""),
                        status: t("active_tobe_green"),
                        className: "success",
                    };
                    setIsOn(true);
                }
                break;
            case CARD_STATUSES.BLOCKED:
                descriptionTmp = {
                    msg: t("mc_card_status").replace("%@", ""),
                    status: t("locked_tobe_red"),
                    className: "warning",
                };
                setIsOn(false);
                setIsOn(!card.unLockable);
                break;
            default:
                break;
        }
        setDescription(descriptionTmp);
    }, [card, t]);

    useEffect(() => {
        if (card) {
            if (card.cardType === CARD_TYPES.DEBIT_CARD) {
                mapDebitCardLabel();
            } else if (
                card.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
            ) {
                mapVisaDebitCardLabel();
            } else {
                mapCreditCardLabel();
            }
        }
    }, [card, mapDebitCardLabel, mapCreditCardLabel, mapVisaDebitCardLabel]);

    const updateCardStatus = () => {
        if (isOn) {
            lockCard(card);
        } else {
            unlockCard(card);
        }
    };

    const generationTooltip = (status, type) => {
        if (status === "success") {
            return type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
                ? t("master:manage_card_visa_virtual_popup_lock_desc")
                : t("mc_ms_notice_lock_card");
        }
        return type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
            type === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
            ? t("master:manage_card_visa_virtual_notice_lock_success")
            : t("mc_ms_notice_unlock_card");
    };

    return (
        <>
            {description && (
                <div className={classes.cardStatus}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <InfoTooltip
                                title={generationTooltip(
                                    description.className,
                                    description.type
                                )}
                                placement="bottom"
                            >
                                <span>{description?.msg}</span>
                                <span
                                    className={classes[description?.className]}
                                >
                                    {description?.status}
                                </span>
                            </InfoTooltip>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{ textAlign: "right" }}
                            className={classes.toggle}
                        >
                            <SwitchButton
                                checked={isOn}
                                onClick={updateCardStatus}
                                fetching={fetching}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default CardStatusBar;
