import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    Divider,
    FormControl,
    FormControlLabel,
    Icon,
    makeStyles,
    Paper,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { memo, useState, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import LspButton from "@components/LspButton";
import LspHintInfo from "@components/LspHintInfo";
import LspNumberCounter from "@components/LspNumberCounter";
import Interweave from "interweave";
import PageHeaderBack from "@components/PageHeaderBack";
import { KIND_OPTIONS } from "@containers/Wealth/wealthConstants";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    line: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.neutral.grey1,
    },
}));

const COUNTER_CONFIGS = {
    DEFAULT_ADULT: 1,
    MIN_ADULT: 1,
    MAX_ADULT: 2,
    DEFAULT_CHILD: 1,
    MIN_CHILD: 0,
};

const WealthTravelAttendantForm = ({
    onBack,
    onNext,
    setAttendantInfo,
    attendant,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();
    const classes = useStyles();

    const [kindOption, setKindOption] = useState(attendant?.type || null);
    const [personCounter, setPersonCounter] = useState(() => {
        return {
            adult: attendant?.adult
                ? attendant?.adult
                : COUNTER_CONFIGS.DEFAULT_ADULT,
            child:
                attendant?.child || attendant?.child === 0
                    ? attendant?.child
                    : COUNTER_CONFIGS.DEFAULT_CHILD,
        };
    }, [attendant]);

    const onChangeKindOption = (e) => {
        setKindOption(e.target.value);
    };

    const onChangeAdultCounter = (value) => {
        setPersonCounter((prev) => ({
            ...prev,
            adult: value,
        }));
    };

    const onChangeChildCounter = (value) => {
        setPersonCounter((prev) => ({
            ...prev,
            child: value,
        }));
    };

    const onNextHandler = useCallback(() => {
        setAttendantInfo({
            type: kindOption,
            adult:
                kindOption === KIND_OPTIONS.ME_AND_FAMILY
                    ? personCounter?.adult
                    : 1,
            child:
                kindOption === KIND_OPTIONS.ME_AND_FAMILY
                    ? personCounter?.child
                    : null,
        });
        onNext();
    }, [personCounter, setAttendantInfo, kindOption, onNext]);

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-travelinsurance" />
                <LspTypography>
                    {t("wealth:lbi_travel_travelers_title")}
                </LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <Paper className={sharedClasses.box}>
                    <FormControl className={classes.form}>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="bottom"
                        >
                            <FormControlLabel
                                key={KIND_OPTIONS.ME}
                                value={KIND_OPTIONS.ME}
                                control={
                                    <Radio
                                        color="primary"
                                        checked={kindOption === KIND_OPTIONS.ME}
                                        onChange={onChangeKindOption}
                                    />
                                }
                                label={t("wealth:lbi_travel_lb_justme")}
                            />
                        </RadioGroup>
                        <Divider className={classes.divider} />
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="bottom"
                        >
                            <FormControlLabel
                                key={KIND_OPTIONS.ME_AND_FAMILY}
                                value={KIND_OPTIONS.ME_AND_FAMILY}
                                control={
                                    <Radio
                                        color="primary"
                                        checked={
                                            kindOption ===
                                            KIND_OPTIONS.ME_AND_FAMILY
                                        }
                                        onChange={onChangeKindOption}
                                    />
                                }
                                label={t("wealth:lbi_travel_lb_meandmyfamily")}
                            />
                        </RadioGroup>
                    </FormControl>

                    <div>
                        <div className={classes.line}>
                            <p>{t("wealth_label_adults")}</p>
                            <LspNumberCounter
                                value={personCounter?.adult}
                                onChange={onChangeAdultCounter}
                                min={COUNTER_CONFIGS.MIN_ADULT}
                                max={COUNTER_CONFIGS.MAX_ADULT}
                                disabled={
                                    kindOption !== KIND_OPTIONS.ME_AND_FAMILY
                                }
                            />
                        </div>
                        <div className={classes.line}>
                            <Interweave
                                content={t(
                                    "wealth_label_dependent_with_number"
                                )}
                            />
                            <LspNumberCounter
                                value={personCounter?.child}
                                onChange={onChangeChildCounter}
                                min={COUNTER_CONFIGS.MIN_CHILD}
                                disabled={
                                    kindOption !== KIND_OPTIONS.ME_AND_FAMILY
                                }
                            />
                        </div>
                    </div>
                </Paper>

                <LspHintInfo
                    content={t("wealth:lbi_travel_tooltip_who")}
                    icon="hint"
                />

                <LspButton
                    fullWidth
                    disabled={
                        !kindOption ||
                        personCounter?.adult + personCounter?.child < 2
                    }
                    className={sharedClasses.button}
                    onClick={onNextHandler}
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    attendant: state.insurance.attendant,
});

const mapDispatch = (dispatch) => ({
    setAttendantInfo: (payload) =>
        dispatch(wealthInsuranceAction.setAttendantInfo(payload)),
});

export default memo(connect(mapState, mapDispatch)(WealthTravelAttendantForm));
