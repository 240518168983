import { memo } from "react";

import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    makeStyles,
} from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { ArrowDownIcon } from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        "&.Mui-expanded": {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    item: {
        width: "50%",
        marginBottom: theme.spacing(1),
    },
    borderLine: {
        borderTop: `1px solid ${theme.palette.neutral.grey3}`,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemWrapper: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(3),
        width: "100%",
    },
}));

const InfoSection = ({
    data,
    defaultExpanded = false,
    isHideHeader = false,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Accordion
            className={classes.wrapper}
            defaultExpanded={defaultExpanded}
        >
            {!isHideHeader && (
                <AccordionSummary
                    expandIcon={<ArrowDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box display="flex" flexDirection="column">
                        <LspTypography color="grey">
                            {data?.title}
                        </LspTypography>
                        <LspTypography color="black">
                            {data?.fullName}
                        </LspTypography>
                    </Box>
                </AccordionSummary>
            )}

            <AccordionDetails className={classes.borderLine}>
                <Box className={classes.itemWrapper}>
                    <div className={classes.item}>
                        <LspTypography color="black" variant="heading3">
                            {t("wealth:GE00084")}
                        </LspTypography>
                        <LspTypography color="grey" variant="body1">
                            {data?.fullName}
                        </LspTypography>
                    </div>
                    <div className={classes.item}>
                        <LspTypography color="black" variant="heading3">
                            {t("wealth:GE00086")}
                        </LspTypography>
                        <LspTypography color="grey" variant="body1">
                            {data?.legalId}
                        </LspTypography>
                    </div>
                    <div className={classes.item}>
                        <LspTypography color="black" variant="heading3">
                            {t("wealth:GE00085")}
                        </LspTypography>
                        <LspTypography color="grey" variant="body1">
                            {data?.gender}
                        </LspTypography>
                    </div>
                    {data?.phoneNumber && (
                        <div className={classes.item}>
                            <LspTypography color="black" variant="heading3">
                                {t("wealth:GE00088")}
                            </LspTypography>
                            <LspTypography color="grey" variant="body1">
                                {data?.phoneNumber}
                            </LspTypography>
                        </div>
                    )}
                    <div className={classes.item}>
                        <LspTypography color="black" variant="heading3">
                            {t("wealth:GE00087")}
                        </LspTypography>
                        <LspTypography color="grey" variant="body1">
                            {data?.dateOfBirth}
                        </LspTypography>
                    </div>
                    {data?.email && (
                        <div className={classes.item}>
                            <LspTypography color="black" variant="heading3">
                                {t("wealth:GE00089")}
                            </LspTypography>
                            <LspTypography color="grey" variant="body1">
                                {data?.email}
                            </LspTypography>
                        </div>
                    )}
                    <div className={classes.item}>
                        <LspTypography color="black" variant="heading3">
                            {t("wealth:GE00076")}
                        </LspTypography>
                        <LspTypography color="grey" variant="body1">
                            {data?.nationality}
                        </LspTypography>
                    </div>
                    {data?.address && (
                        <div className={classes.item}>
                            <LspTypography color="black" variant="heading3">
                                {t("wealth:GE00033")}
                            </LspTypography>
                            <LspTypography color="grey" variant="body1">
                                {data?.address}
                            </LspTypography>
                        </div>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default memo(InfoSection);
