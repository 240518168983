import Content from "@components/Content";
import LspReceipt from "@components/LspReceipt";
import PageHeader from "@components/PageHeader";
import { CreditCardNavigationKey, RESPONSE_CODE } from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useStepNavigation from "@helpers/useStepNavigation";
import { makeStyles } from "@material-ui/core";
import accountService from "@services/account";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { INSTALLMENT_ACTIONS, INSTALLMENT_STATUSES } from "./constant";
import InstallmentConfirmationDialog from "./InstallmentConfirmationDialog";
import InstallmentDeclineInfo from "./InstallmentDeclineInfo";
import InstallmentEarlyInfo from "./InstallmentEarlyInfo";
import InstallmentStatus from "./InstallmentStatus";

const useStyles = makeStyles((theme) => ({
    box: {
        color: theme.palette.neutral.black,
        borderRadius: theme.shape.radiusLevels[1],
        border: `1px solid #EBEBEC`,
        textAlign: "center",
        background: theme.palette.neutral.white,
        boxShadow: theme.shadows[2],
    },
    headerWrapper: {
        background: theme.palette.neutral.grey4,
    },
    headerStatus: {
        padding: theme.spacing(1, 2),
        background: theme.palette.neutral.grey1,
        borderTopLeftRadius: theme.shape.radiusLevels[1],
        borderTopRightRadius: theme.shape.radiusLevels[1],
        textTransform: "uppercase",
        "&.bg-decline": {
            background: theme.palette.status.rejected,
        },
        "&.bg-pending": {
            background: theme.palette.status.pending,
        },
        "&.bg-active": {
            background: theme.palette.status.active,
        },
    },
    headerTitle: {
        textTransform: "uppercase",
        marginBottom: theme.spacing(2),
    },
    headerInfo: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    label: {
        color: theme.palette.neutral.grey1,
    },
    value: {
        color: theme.palette.neutral.black,
    },

    infoLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        "& > p:first-child": {
            marginRight: theme.spacing(1),
        },
        "& > p:last-child": {
            marginLeft: theme.spacing(1),
        },
        "&:first-child": {
            paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(9),
            paddingRight: theme.spacing(9),
        },
    },
    divider: {
        margin: theme.spacing(1, 2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    },
    linkNavigation: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9),
        [theme.breakpoints.up("sm")]: {
            marginBottom: theme.spacing(4),
        },
    },
    hint: {
        display: "flex",
    },
    hintIcon: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(1),
    },
    amount: {
        color: theme.palette.status.rejected,
    },
    footer: {
        marginTop: theme.spacing(2),
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(4),
        },
    },
    plainBtn: {
        "& p": {
            textTransform: "initial",
            color: theme.palette.neutral.grey2,
            textDecoration: "underline",
        },
    },
}));

const InstallmentDetail = () => {
    const classes = useStyles();
    const { txnId } = useParams();
    const unmounted = useRef(false);
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [earlyReceipt, setEarlyReceipt] = useState(null);

    const INSTALLMENT_STEP_NAMES = useMemo(
        () => ({
            DETAIL: "DETAIL",
            EARLY_INFO: "EARLY_INFO",
            RECEIPT: "RECEIPT",
        }),
        []
    );

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: INSTALLMENT_STEP_NAMES.DETAIL,
    });

    const backInstallmentList = useCallback(() => {
        history.push(CreditCardNavigationKey.InstallmentList);
    }, [history]);

    const renderTitle = useCallback(() => {
        switch (step.current) {
            case INSTALLMENT_STEP_NAMES.DETAIL:
                return t("creditCard:ins_detail_header");
            case INSTALLMENT_STEP_NAMES.EARLY_INFO:
                return t("creditCard:ins_pay_early_title");
            case INSTALLMENT_STEP_NAMES.RECEIPT:
                return t("transaction_label_receipt");
            default:
                return null;
        }
    }, [t, step, INSTALLMENT_STEP_NAMES]);

    const openReceipt = useCallback(
        (e) => {
            e.preventDefault();
            stepForward(INSTALLMENT_STEP_NAMES.RECEIPT);
        },
        [stepForward, INSTALLMENT_STEP_NAMES.RECEIPT]
    );

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const openAEarlyReceipt = useCallback(
        (receipt) => {
            setEarlyReceipt(receipt);
            stepForward(INSTALLMENT_STEP_NAMES.RECEIPT);
        },
        [INSTALLMENT_STEP_NAMES.RECEIPT, stepForward]
    );

    const renderStepContent = useCallback(() => {
        switch (step.current) {
            case INSTALLMENT_STEP_NAMES.DETAIL:
                return (
                    <InstallmentStatus
                        className={classes}
                        detail={detail}
                        payEarly={() => {
                            stepForward(INSTALLMENT_STEP_NAMES.EARLY_INFO);
                        }}
                        openReceipt={openReceipt}
                        actionLoading={actionLoading}
                        loading={loading}
                    />
                );
            // return <InstallmentDetailLoading />;
            case INSTALLMENT_STEP_NAMES.EARLY_INFO:
                return (
                    <InstallmentEarlyInfo
                        className={classes}
                        title={detail.title}
                        txnId={txnId}
                        stepForward={openAEarlyReceipt}
                        stepBack={stepBack}
                    />
                );
            case INSTALLMENT_STEP_NAMES.RECEIPT:
                return (
                    <div className={classes.box}>
                        {earlyReceipt && (
                            <LspReceipt
                                rawReceipt={earlyReceipt}
                                onClose={() => backInstallmentList()}
                            />
                        )}

                        {!earlyReceipt && (
                            <LspReceipt
                                bankXID={txnId}
                                onClose={() => stepBack()}
                            />
                        )}
                    </div>
                );
            default:
                return null;
        }
    }, [
        step,
        detail,
        loading,
        INSTALLMENT_STEP_NAMES,
        stepForward,
        txnId,
        stepBack,
        openReceipt,
        classes,
        actionLoading,
        earlyReceipt,
        backInstallmentList,
        openAEarlyReceipt,
    ]);

    const cancelInstallment = useCallback(
        async (id) => {
            setActionLoading(true);
            const response = await accountService.cancelInstallment({
                txnId: id,
            });
            if (unmounted.current) {
                return;
            }
            const { code } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    AlertController.show(
                        t("creditCard:dropdown_banner_cancelled")
                    );
                    backInstallmentList();
                    break;
                default:
                    AlertController.show(
                        t("creditCard:dropdown_banner_not_cancelled"),
                        AlertType.Error
                    );
                    break;
            }
            setActionLoading(false);
        },
        [t, backInstallmentList]
    );

    const deleteInstallment = useCallback(
        async (id) => {
            setActionLoading(true);
            const deleteResponse = await accountService.deleteInstallment({
                txnId: id,
            });
            if (unmounted.current) {
                return;
            }
            const { code } = deleteResponse.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    AlertController.show(
                        t("creditCard:dropdown_banner_delete_record")
                    );
                    backInstallmentList();
                    break;
                default:
                    AlertController.show(
                        t("creditCard:dropdown_banner_not_delete_record"),
                        AlertType.Error
                    );
                    break;
            }
            setActionLoading(false);
        },
        [t, backInstallmentList]
    );

    const dialogConfirmation = useCallback(
        (id, type) => {
            let onConfirmFunc = null;
            switch (type) {
                case INSTALLMENT_ACTIONS.CANCEL:
                    onConfirmFunc = () => cancelInstallment(id);
                    break;
                case INSTALLMENT_ACTIONS.DELETE_FOR_COMPLETE:
                case INSTALLMENT_ACTIONS.DELETE_FOR_DECLINE:
                    onConfirmFunc = () => deleteInstallment(id);
                    break;
                default:
                    break;
            }

            GlobalDialogController.show({
                component: () => (
                    <InstallmentConfirmationDialog
                        onCancel={closeDialog}
                        onConfirm={onConfirmFunc}
                        type={type}
                    />
                ),
            });
        },
        [cancelInstallment, deleteInstallment, closeDialog]
    );

    const moreInfoAboutDecline = useCallback(
        (event) => {
            event.preventDefault();
            GlobalDialogController.show({
                component: () => (
                    <InstallmentDeclineInfo onClose={closeDialog} />
                ),
            });
        },
        [closeDialog]
    );

    const mapDetail = useCallback(
        (info, id) => {
            const status = info.installStatus;
            let FEStatus = "";
            let FELabelStatus = "";
            let FEActions = [];

            switch (status) {
                case INSTALLMENT_STATUSES.ACTIVATED:
                    FEStatus = "bg-active";
                    FELabelStatus = t("creditCard:lb_active");
                    FEActions = [
                        {
                            id: "primary",
                            label: t("creditCard:btn_close_early"),
                            onClick: () =>
                                stepForward(INSTALLMENT_STEP_NAMES.EARLY_INFO),
                        },
                    ];
                    break;
                case INSTALLMENT_STATUSES.PENDING:
                    FEStatus = "bg-pending";
                    FELabelStatus = t("creditCard:lb_pending_approval");
                    FEActions = [
                        {
                            id: "primary",
                            label: t("lb_cancel"),
                            onClick: () =>
                                dialogConfirmation(
                                    id,
                                    INSTALLMENT_ACTIONS.CANCEL
                                ),
                            isLoadingOption: true,
                        },
                    ];
                    break;
                case INSTALLMENT_STATUSES.COMPLETED:
                    FEStatus = "bg-close";
                    FELabelStatus = t("creditCard:lb_close");
                    FEActions = [
                        {
                            id: "primary",
                            label: t("creditCard:btn_delete_record"),
                            onClick: () =>
                                dialogConfirmation(
                                    id,
                                    INSTALLMENT_ACTIONS.DELETE_FOR_COMPLETE
                                ),
                            styles: "link",
                            isLoadingOption: true,
                        },
                    ];
                    break;
                case INSTALLMENT_STATUSES.DECLINED:
                    FEStatus = "bg-decline";
                    FELabelStatus = t("creditCard:lb_decline");
                    FEActions = [
                        {
                            id: "secondary",
                            label: t("creditCard:IN00023"),
                            onClick: moreInfoAboutDecline,
                            styles: "link",
                        },
                        {
                            id: "primary",
                            label: t("payment_lb_btn_delete"),
                            onClick: () =>
                                dialogConfirmation(
                                    id,
                                    INSTALLMENT_ACTIONS.DELETE_FOR_DECLINE
                                ),
                            isLoadingOption: true,
                        },
                    ];
                    break;
                default:
                    break;
            }
            return { ...info, FEStatus, FELabelStatus, FEActions };
        },
        [
            t,
            dialogConfirmation,
            moreInfoAboutDecline,
            stepForward,
            INSTALLMENT_STEP_NAMES.EARLY_INFO,
        ]
    );

    const getInstallmentById = useCallback(async () => {
        const response = await accountService.getInstallmentById({ id: txnId });
        if (unmounted.current) {
            return;
        }

        const { data, code } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setDetail(mapDetail(data, txnId));
                setLoading(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                backInstallmentList();
                break;
        }
    }, [txnId, mapDetail, backInstallmentList]);

    useEffect(() => {
        if (firstLoad) {
            getInstallmentById();
            setFirstLoad(false);
        }
    }, [getInstallmentById, firstLoad]);

    return (
        <Content size="xl">
            <PageHeader
                backFunc={
                    step.current !== INSTALLMENT_STEP_NAMES.RECEIPT
                        ? stepBack
                        : null
                }
            >
                {renderTitle()}
            </PageHeader>
            <Content size="sm">{renderStepContent()}</Content>
        </Content>
    );
};

const stateProps = (state) => ({
    earlyReceipt: state.moveMoney.info,
});

// const dispatchProps = (dispatch) => ({});

export default memo(connect(stateProps)(InstallmentDetail));
