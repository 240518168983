import { useEffect, useMemo, useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import { FetchStatus, ONE_MILLION } from "@config/constants";
import Loading from "@components/Loading";
import {
    Box,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import LspButton from "@components/LspButton";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import termDepositAction from "@redux/actions/term-deposit";
import { isEmpty, isFunction } from "lodash";

const useStyles = makeStyles((theme) => ({
    table: {
        border: `1px solid ${theme.palette.magicalGrey[700]}`,
    },
    tableHeader: {
        backgroundColor: theme.palette.background.header,
    },
    tableHeaderCell: {
        fontWeight: 600,
        color: theme.palette.text.header,
        textTransform: "uppercase",
    },
    tableCell: {
        fontWeight: 600,
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.magicalGrey[700]}`,
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.neutral.grey4,
        },
    },
}));

const InterestRatesDialog = ({
    open,
    onClose,
    serverTime,
    termDepositRate,
    getTermRates,
    termDepositRateStatus,
    termDepositRateFetch,
    sysParam,
    onDirect,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [rates, setRates] = useState([]);
    const { formatNumber, formatUnit } = useNumber();
    const [specialRates, setSpecialRates] = useState(0);
    const [minDeposit, setMinDeposit] = useState("1000000");

    useEffect(() => {
        setMinDeposit(sysParam.MinAmountLimitPerTD);
    }, [sysParam.MinAmountLimitPerTD]);
    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    useEffect(() => {
        if (isEmpty(termDepositRate)) {
            getTermRates();
        }
    }, [getTermRates, termDepositRate]);

    useEffect(() => {
        if (
            !isEmpty(termDepositRate) &&
            termDepositRateStatus === FetchStatus.Success
        ) {
            setRates(termDepositRate.items);
            setSpecialRates(termDepositRate.value);
        }
    }, [termDepositRate, termDepositRateStatus]);

    const subtitle = useMemo(() => {
        if (!serverTime) {
            return "";
        }
        return `${t("termDeposit_title_td_interest_rate")} ${format(
            serverTime,
            "dd/MM/yyyy"
        )}
        ${t("td_interest_table_foreigner")}`;
    }, [t, serverTime]);

    const openTD = (item) => {
        const link = `/term-deposit/open?termId=${item.termId}`;
        if (history) {
            history.push(link);
        }
        if (isFunction(onDirect)) {
            onDirect(link);
        }
        onClose();
    };

    const tdAmount = useMemo(() => {
        if (specialRates >= ONE_MILLION) {
            const amount = specialRates.substr(0, 1);
            return `${amount} ${t("TD:td_special_rate_number_unit_billion")}`;
        }
        return `${specialRates} ${t("TD:td_special_rate_number_unit_million")}`;
    }, [specialRates, t]);

    return (
        <LspDialog open={open} maxWidth="sm" scroll="body" onClose={onClose}>
            <LspDialogTitle>
                <div>{t("td_rate_table_header")}</div>
                <Box whiteSpace="pre-line">
                    <Typography variant="subtitle2">{subtitle}</Typography>
                </Box>
            </LspDialogTitle>
            <LspDialogContent>
                {termDepositRateFetch ? (
                    <Loading />
                ) : (
                    <TableContainer>
                        <Table className={classes.table} size="medium">
                            <TableHead classes={{ root: classes.tableHeader }}>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        classes={{
                                            head: classes.tableHeaderCell,
                                        }}
                                    >
                                        {t("termDeposit_lb_months")}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        classes={{
                                            head: classes.tableHeaderCell,
                                        }}
                                    >
                                        <>
                                            {termDepositRate?.isAvailableTdRate
                                                ? t(
                                                      "TD:td_interest_table_rate_column1"
                                                  )
                                                      .replace(
                                                          "#@",
                                                          formatUnit(
                                                              specialRates
                                                          )
                                                      )
                                                      .replace(
                                                          "%@",
                                                          formatNumber(
                                                              minDeposit
                                                          )
                                                      )
                                                : t(
                                                      "termDeposit_lb_annual_interest_rate"
                                                  )}
                                        </>
                                    </TableCell>
                                    {termDepositRate?.isAvailableTdRate && (
                                        <TableCell
                                            align="center"
                                            classes={{
                                                head: classes.tableHeaderCell,
                                            }}
                                        >
                                            {t(
                                                "TD:td_interest_table_rate_column2"
                                            ).replace(
                                                "%@",
                                                formatUnit(specialRates)
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rates?.map((r) => (
                                    <TableRow
                                        key={r.termId}
                                        classes={{
                                            root: classes.tableRow,
                                        }}
                                        onClick={() => openTD(r)}
                                    >
                                        <TableCell
                                            align="center"
                                            classes={{
                                                root: classes.tableCell,
                                            }}
                                        >
                                            {r.month}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            classes={{
                                                root: classes.tableCell,
                                            }}
                                        >
                                            {formatNumber(r.interestRate)}%
                                        </TableCell>
                                        {termDepositRate?.isAvailableTdRate && (
                                            <TableCell
                                                align="center"
                                                classes={{
                                                    root: classes.tableCell,
                                                }}
                                            >
                                                <LspTypography
                                                    variant="subheading3"
                                                    color="primary"
                                                >
                                                    {formatNumber(
                                                        r.interestRateSpecial ||
                                                            r.interestRate
                                                    )}
                                                    %
                                                </LspTypography>
                                                {r.interestRateBonus && (
                                                    <LspTypography
                                                        variant="subheading4"
                                                        color="success"
                                                    >
                                                        (+{r.interestRateBonus}
                                                        %)
                                                    </LspTypography>
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </LspDialogContent>
            <LspDialogActions>
                <LspButton fullWidth onClick={onClose}>
                    {t("lb_close")}
                </LspButton>
            </LspDialogActions>
        </LspDialog>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
    termDepositRate: state.termDeposit.termRates.data,
    termDepositRateStatus: state.termDeposit.termRates.status,
    termDepositRateFetch: state.termDeposit.termRates.fetching,
    sysParam: state.systemParams.info.conf,
});

const dispatchProps = (dispatch) => ({
    getTermRates: () => dispatch(termDepositAction.getTermDepositRates()),
});

export default connect(mapState, dispatchProps)(memo(InterestRatesDialog));
