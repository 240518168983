/* eslint-disable react/no-array-index-key */
import { memo } from "react";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        background: theme.palette.neutral.grey3,
        padding: theme.spacing(4, 3),
        marginBottom: theme.spacing(2),
    },
}));

const WealthCreditCardMethodSelectionsFetching = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.box}>
                {[...new Array(3)]?.map((_, index) => {
                    return (
                        <div className={classes.item} key={index}>
                            <Skeleton width="70%" />
                            <Skeleton width="90%" />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default memo(WealthCreditCardMethodSelectionsFetching);
