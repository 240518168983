/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Paper } from "@material-ui/core";
import {
    memo,
    useState,
    useMemo,
    useCallback,
    useEffect,
    Fragment,
} from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import LspButton from "@components/LspButton";

import PageHeaderBack from "@components/PageHeaderBack";

import { parseInt } from "lodash";
import { format } from "date-fns";
import OwnerInfoReview from "./OwnerInfoReview";
import DependentPolicyForm from "./DependentPolicyForm";
import ChildDependentHint from "./ChildDependentHint";
import DependentButtonControls from "./DependentButtonControls";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        overflow: "hidden",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    footerDirection: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttonControl: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey2,
    },
    item: {
        width: 8,
        height: 8,
        background: theme.palette.background.header,
        margin: theme.spacing(0.5),
        borderRadius: "50%",
        cursor: "pointer",
    },
    active: {
        background: theme.palette.primary.main,
    },
}));

const WealthTravelDependentPolicy = ({
    onBack,
    onNext,
    setDependentPolicy,
    attendant,
    setDependentPolicyForm,
    formDependentPolicy,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();
    const classes = useStyles();

    const [currentScreen, setCurrentScreen] = useState(0);

    const totalPage = useMemo(() => {
        return parseInt(attendant?.child) + parseInt(attendant?.adult) + 1;
    }, [attendant]);

    const [pages, setPages] = useState([]);

    const formGenerator = useCallback(() => {
        const form = {
            fullName: {
                value: "",
                error: "",
            },
            gender: {
                value: "",
                error: "",
            },
            dateOfBirth: {
                value: "",
                error: "",
            },
            legalId: {
                value: "",
                error: "",
            },
            nationality: {
                value: null,
                error: "",
            },
        };

        let formData = {};
        let formListForLoop = ["owner"];
        // attendant.adult =  owner + adult --> attendant.adult - 1
        const adultCounter = attendant?.adult ? attendant.adult - 1 : 0;
        if (attendant?.adult) {
            for (let i = 0; i < adultCounter; i++) {
                formData[`adult-${i}`] = { ...form, id: `adult-${i}` };
                formListForLoop.push(`adult-${i}`);
            }
        }

        if (attendant?.child) {
            const childCounter = adultCounter + attendant?.child;
            for (let i = adultCounter; i < childCounter; i++) {
                formData[`child-${i}`] = { ...form, id: `child-${i}` };
                formListForLoop.push(`child-${i}`);
            }
        }
        setPages(formListForLoop);
        setDependentPolicyForm(formData);
    }, [setDependentPolicyForm, attendant]);

    useEffect(() => {
        if (!formDependentPolicy) {
            formGenerator();
        } else {
            let formListForLoop = ["owner"];
            let forms = Object.keys(formDependentPolicy);
            for (let i = 0; i < forms?.length; i++) {
                formListForLoop.push(forms[i]);
            }
            setPages(formListForLoop);
        }
    }, [formGenerator, formDependentPolicy]);

    const isDisableButton = useMemo(() => {
        let isValid = true;
        if (formDependentPolicy) {
            const formData = Object.values(formDependentPolicy);

            for (let i = 0; i < formData?.length; i++) {
                if (
                    !formData[i]?.fullName?.value ||
                    formData[i]?.fullName?.error !== ""
                ) {
                    isValid = false;
                    break;
                }
                if (
                    !formData[i]?.gender?.value ||
                    formData[i]?.gender?.error !== ""
                ) {
                    isValid = false;
                    break;
                }
                if (
                    !formData[i]?.dateOfBirth?.value ||
                    formData[i]?.dateOfBirth?.error !== ""
                ) {
                    isValid = false;
                    break;
                }
                if (
                    !formData[i]?.legalId?.value ||
                    formData[i]?.legalId?.error !== ""
                ) {
                    isValid = false;
                    break;
                }
                if (
                    !formData[i]?.nationality?.value ||
                    formData[i]?.nationality?.error !== ""
                ) {
                    isValid = false;
                    break;
                }
            }
        }

        return !isValid;
    }, [formDependentPolicy]);

    const onSubmitForm = useCallback(() => {
        const form = Object.values(formDependentPolicy);
        let updatedData = [];
        for (let i = 0; i < form?.length; i++) {
            updatedData.push({
                fullName: form[i]?.fullName?.value,
                gender: parseInt(form[i]?.gender?.value),
                dateOfBirth: format(form[i]?.dateOfBirth?.value, "dd/MM/yyyy"),
                legalId: form[i]?.legalId?.value,
                nationality: form[i]?.nationality?.value?.key,
            });
        }

        console.log("updatedData", updatedData);

        setDependentPolicy(updatedData);
        onNext();
    }, [formDependentPolicy, setDependentPolicy, onNext]);

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-insuranceinformation" />
                <LspTypography>
                    {t("wealth_travel_label_insured_information")}
                </LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <Paper className={classes.wrapper}>
                    {pages?.map((value, index) => {
                        return (
                            <Fragment key={`form-${index}`}>
                                {currentScreen === index && (
                                    <>
                                        {value === "owner" && (
                                            <OwnerInfoReview
                                                parentClasses={classes}
                                            />
                                        )}
                                        {value !== "owner" && (
                                            <DependentPolicyForm
                                                parentClasses={classes}
                                                id={value}
                                            />
                                        )}
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
                </Paper>
                <DependentButtonControls
                    pages={pages}
                    setCurrentScreen={setCurrentScreen}
                    currentScreen={currentScreen}
                    totalPage={totalPage}
                />
                <ChildDependentHint
                    pages={pages}
                    currentScreen={currentScreen}
                />
                <LspButton
                    type="button"
                    fullWidth
                    className={sharedClasses.button}
                    disabled={isDisableButton}
                    onClick={onSubmitForm}
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    dependentPolicy: state.insurance.dependentPolicy,
    formDependentPolicy: state.insurance.formDependentPolicy,
    attendant: state.insurance.attendant,
});

const mapDispatch = (dispatch) => ({
    setDependentPolicy: (payload) =>
        dispatch(wealthInsuranceAction.setDependentPolicy(payload)),
    setDependentPolicyForm: (payload) =>
        dispatch(wealthInsuranceAction.setDependentPolicyForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthTravelDependentPolicy)
);
