/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import useCapitalize from "@helpers/useCapitalize";
import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Interweave from "interweave";
import { useMemo, memo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import { WEALTH_PRODUCT_KEYS } from "../../wealthConstants";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
    },
    header: {
        background: theme.palette.primary.main,
        color: theme.palette.neutral.white,
        display: "flex",
        padding: theme.spacing(2.5),
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(2),
        fontSize: 32,
    },
    body: {
        padding: theme.spacing(2.5),
    },
    bodyHeader: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        paddingBottom: theme.spacing(1.5),
    },
    list: {
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
        "& > li": {
            marginBottom: theme.spacing(1),
            "&::marker": {
                color: theme.palette.primary.main,
                fontSize: 16,
            },
        },
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
    },
    footer: {
        color: theme.palette.primary.main,
        fontStyle: "italic",
    },
    fetchingList: {
        listStyle: "none",
        paddingLeft: 0,
    },
}));
const WealthTravelPackageInfo = ({
    onBack,
    onNext,
    selectedPackage,
    attendantType,
}) => {
    const { t } = useTranslation();
    const { capitalizeWord } = useCapitalize();
    const classes = useStyles();
    const sharedClasses = useClassesShared();

    const contents = useMemo(() => {
        const content = t(
            `wealth:lbi_travel_package_${selectedPackage?.productKey}`,
            {
                returnObjects: true,
            }
        );

        return {
            header: {
                title: t(
                    `wealth:GE00001_${WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE_TRAVEL}`
                ),
                subTitle: capitalizeWord(content[0]),
            },
            subHeader: {
                label: t("wealth:GE00059"),
                value: t("wealth:lbi_benefit_lb"),
            },
            body: t(
                `wealth:GE00060_${selectedPackage?.productKey}_${attendantType}`,
                { returnObjects: true }
            ),
            footer: t(`wealth:GE00066`),
        };
    }, [selectedPackage, t, attendantType, capitalizeWord]);

    const fetchingLayer = useMemo(() => {
        return (
            <>
                <Paper className={classes.wrapper}>
                    <div className={classes.header}>
                        <Box>
                            <LspTypography color="white" variant="heading1">
                                <Skeleton width={170} />
                            </LspTypography>
                            <LspTypography color="white" variant="body2">
                                <Skeleton width={350} />
                            </LspTypography>
                        </Box>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.bodyHeader}>
                            <LspTypography color="white" variant="heading2">
                                <Skeleton width={200} />
                            </LspTypography>
                            <LspTypography color="white" variant="heading2">
                                <Skeleton width={100} />
                            </LspTypography>
                        </div>
                        <div>
                            <ul
                                className={`${classes.list} ${classes.fetchingList}`}
                            >
                                {[...new Array(15)]?.map((_, index) => {
                                    return (
                                        <li key={index}>
                                            <div className={classes.item}>
                                                <Skeleton width={300} />
                                                <Skeleton width={100} />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <LspTypography
                            className={classes.footer}
                            variant="body2"
                        >
                            <Skeleton width="95%" />
                            <Skeleton width="98%" />
                            <Skeleton width="100%" />
                        </LspTypography>
                    </div>
                </Paper>
            </>
        );
    }, [classes]);

    return (
        <Content>
            {onBack && <PageHeaderBack onBack={onBack} />}
            {!selectedPackage && fetchingLayer}
            {selectedPackage && (
                <>
                    <Paper className={classes.wrapper}>
                        <div className={classes.header}>
                            <Icon
                                className={`font-icon icon-travelinsurance ${classes.icon}`}
                            />
                            <Box>
                                <LspTypography color="white" variant="heading1">
                                    {contents?.header?.title}
                                </LspTypography>
                                <LspTypography color="white" variant="body2">
                                    {contents?.header?.subTitle}
                                </LspTypography>
                            </Box>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.bodyHeader}>
                                <LspTypography variant="heading2">
                                    {contents?.subHeader?.label}
                                </LspTypography>
                                <LspTypography variant="heading2">
                                    {contents?.subHeader?.value}
                                </LspTypography>
                            </div>
                            <div>
                                <ul className={classes.list}>
                                    {contents?.body?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <div className={classes.item}>
                                                    <Interweave
                                                        content={item[0]}
                                                    />
                                                    <Interweave
                                                        content={item[1]}
                                                    />
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <LspTypography
                                className={classes.footer}
                                variant="body2"
                            >
                                <Interweave content={contents?.footer} />
                            </LspTypography>
                        </div>
                    </Paper>
                    {onNext && (
                        <LspButton
                            type="button"
                            fullWidth
                            onClick={onNext}
                            className={sharedClasses.button}
                        >
                            {t("lb_next")}
                        </LspButton>
                    )}
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    selectedPackage: state.insurance.selectedPackage,
    attendantType: state.insurance.attendant?.type,
});

export default memo(connect(mapState)(WealthTravelPackageInfo));
