import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.radiusLevels[0],
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: "50%",
        marginRight: theme.spacing(2),
    },
    name: {
        width: theme.spacing(12),
        height: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(16),
        },
    },
    description: {
        width: theme.spacing(18),
        height: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(28),
        },
    },
}));

const PayeeListLoading = () => {
    const classes = useStyles();

    return (
        <>
            {[...new Array(3)].map((_, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={classes.root}>
                        <Skeleton
                            animation="pulse"
                            variant="circle"
                            className={classes.avatar}
                        />
                        <div>
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.name}
                            />
                            <Skeleton
                                animation="pulse"
                                variant="text"
                                className={classes.description}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default PayeeListLoading;
