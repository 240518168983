import Content from "@components/Content";
import { Icon, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    comingSoonWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "50vh",
    },
    icon: {
        fontSize: theme.typography.pxToRem(150),
        color: theme.palette.primary.main,
    },
}));

/**
 * @typedef ComingSoonProps
 * @type {object}
 * @property {string} [content]
 * @property {string} [icon]
 */

/**
 * @param {ComingSoonProps} props
 */

const ComingSoon = ({ content, icon }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Content>
            <div className={classes.comingSoonWrapper}>
                <Icon
                    className={`font-icon icon-${icon || "gear"}  ${
                        classes.icon
                    }`}
                />
                <p>{content || t("wealth:coming_soon")}</p>
            </div>
        </Content>
    );
};

ComingSoon.propTypes = {
    content: PropTypes.string,
    icon: PropTypes.string,
};

ComingSoon.defaultProps = {
    content: "",
    icon: "",
};

export default ComingSoon;
