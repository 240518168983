/* eslint-disable no-restricted-syntax */
import querystring from "query-string";

import { LANGUAGE_OPTIONS } from "@config/constants";
import userService from "@services/user";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";

const useLanguage = () => {
    const { i18n } = useTranslation();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const switching = useRef(false);
    const location = useLocation();
    const history = useHistory();
    const params = querystring.parse(location.search);
    const { language } = params;

    const removeLanguageParamsFromUrl = useCallback(() => {
        if (params) {
            const p = cloneDeep(params);
            delete p.language;
            let searchParams = "?";
            for (const [key, value] of Object.entries(p)) {
                const prefix = searchParams !== "?" ? "&" : "";
                searchParams += `${prefix}${key}=${value}`;
            }
            history.replace({
                search: searchParams,
            });
        }
    }, [params, history]);

    const switchLanguage = useCallback(async () => {
        if (switching.current) {
            return;
        }
        switching.current = true;
        const nextLang =
            i18n.language === LANGUAGE_OPTIONS.VN
                ? LANGUAGE_OPTIONS.EN
                : LANGUAGE_OPTIONS.VN;

        if (language) {
            removeLanguageParamsFromUrl();
        }

        localStorage.setItem("lang", nextLang.toLowerCase());
        if (isAuthenticated) {
            await userService.switchLanguage({ lang: nextLang });
        }
        switching.current = false;
        window.location.reload();
    }, [i18n, isAuthenticated, language, removeLanguageParamsFromUrl]);

    const langControllers = useMemo(() => ({ switchLanguage }), [
        switchLanguage,
    ]);

    return langControllers;
};

export default useLanguage;
