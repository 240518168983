/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Box, makeStyles } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { useMemo, useCallback, useState } from "react";
import useStepNavigation from "@helpers/useStepNavigation";
import GoalSavePurpose from "./GoalSavePurpose";
import GoalSaveDetail from "./GoalSaveDetail";
import GoalSaveReview from "./GoalSaveReview";

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        padding: theme.spacing(3),
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: 150,
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    wrapper: {
        background: theme.palette.background.paper,
    },
}));

const GoalSaveCreate = ({ getListGoalSave, setAddNewSuccess }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: 1,
    });

    const [infoGoalSave, setInfoGoalSave] = useState(null);

    const onNextDetail = useCallback(
        (info) => {
            setInfoGoalSave((prevState) => {
                return { ...prevState, ...info };
            });
            stepForward(3);
        },
        [stepForward]
    );

    const renderTitle = useMemo(() => {
        switch (step.current) {
            case 1:
                return {
                    title: t("goalSave_title_saving_for"),
                    subTitle: t("gs_lb_des_home"),
                };
            case 2:
                return {
                    title: t("gs_lb_title_name_input"),
                    subTitle: t("gs_lb_des_name_input"),
                };
            case 3:
                return {
                    title: t("gs_title_how_often_to_save"),
                    subTitle: t("gs_desc_how_often_to_save"),
                };
            default:
                return null;
        }
    }, [t, step]);

    const renderStepContent = useCallback(() => {
        switch (step.current) {
            case 1:
                return (
                    <GoalSavePurpose
                        stepForward={stepForward}
                        stepBack={stepBack}
                        onNextDetail={onNextDetail}
                    />
                );
            case 2:
                return (
                    <GoalSaveDetail
                        stepBack={stepBack}
                        onNextDetail={onNextDetail}
                        infoGoalSave={infoGoalSave}
                    />
                );
            case 3:
                return (
                    <GoalSaveReview
                        stepForward={stepForward}
                        stepBack={stepBack}
                        infoGoalSave={infoGoalSave}
                        getListGoalSave={getListGoalSave}
                        setAddNewSuccess={setAddNewSuccess}
                    />
                );
            default:
                return null;
        }
    }, [
        step,
        getListGoalSave,
        setAddNewSuccess,
        stepForward,
        stepBack,
        infoGoalSave,
        onNextDetail,
    ]);

    return (
        <Box className={classes.wrapper}>
            <div className={classes.header}>
                <LspTypography variant="heading1" color="white">
                    {renderTitle.title}
                </LspTypography>
                <LspTypography variant="body2" color="white">
                    {renderTitle.subTitle}
                </LspTypography>
            </div>
            {renderStepContent()}
        </Box>
    );
};

export default GoalSaveCreate;
