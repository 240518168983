/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Paper, Box, Dialog } from "@material-ui/core";
import userService from "@services/user";
import { RESPONSE_CODE } from "@config/constants";
import clsx from "clsx";
import Loading from "@components/Loading";

const useStyles = makeStyles((theme) => ({
    info: {
        display: "flex",
        marginTop: theme.spacing(2.5),
        "& > div:first-child": {
            width: "58%",
        },

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    item: {
        display: "flex",
        marginBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(2),
        "& > span": {
            marginRight: theme.spacing(2),
        },
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    locationBox: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        "& > div:first-child": {
            marginBottom: theme.spacing(1.5),
            display: "flex",
            "& > span": {
                marginRight: theme.spacing(1.5),
            },
        },
        "& > div:last-child": {
            width: "40%",
            "& > div > img": {
                width: "100%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            "& > div:last-child": {
                width: "100%",
            },
        },
    },
    link: {
        cursor: "pointer",
        opacity: 0.8,
        "&:hover": {
            opacity: 1,
            color: theme.palette.white.contrastText,
        },
        "&.selected": {
            opacity: 1,
            color: theme.palette.white.contrastText,
        },
    },
    titleLocation: {
        marginBottom: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.black.main}`,
    },
}));

const Contact = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [locationSelected, setLocationSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [open, setOpen] = useState(false);

    const contactInfo = useMemo(() => {
        const content = {
            phone: t("ci_lb_domestic").replace(
                "%@",
                t("hangout:callcenter_number")
            ),
            email: t("hangout:callcenter_email"),
            site: t("hangout:mktg_website"),
            link: `https://${t("hangout:mktg_website")}`,
            facebook: t("hangout:facebookUrl"),
            linkedIn: t("hangout:linkedInUrl"),
            facebookTitle: t("hangout:facebook_title"),
            phoneOversea: t("ci_lb_overseas").replace(
                "%@",
                t("hangout:callcenter_number_oversea")
            ),
            workingTime: t("hangout:callcenter_workingtime"),
        };
        return content;
    }, [t]);

    const getHangoutList = useCallback(async () => {
        setLoading(true);
        const dataToPost = {
            services: ["CONTACT"],
            lang: i18n.language,
        };
        const { data } = await userService.getHangoutService(dataToPost);
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                const finalLocations = [];
                const provinceList = data.data.result;
                for (
                    let province = 0;
                    province < provinceList.length;
                    province++
                ) {
                    const { locations } = provinceList[province];
                    for (
                        let location = 0;
                        location < locations.length;
                        location++
                    ) {
                        const currentLocation = locations[location];
                        if (province === 0 && location === 0) {
                            setLocationSelected(currentLocation);
                        }
                        if (
                            currentLocation.googleMapLink &&
                            currentLocation.googleMapLink.indexOf("http") > -1
                        ) {
                            // console.log("google")
                        } else {
                            currentLocation.FEImgMap =
                                currentLocation.googleMapLink;
                        }

                        finalLocations.push(locations[location]);
                    }
                }
                setLocationList(finalLocations);
                break;

            default:
                break;
        }
    }, [i18n.language]);

    const changeLocation = (item) => {
        setLocationSelected(item);
    };

    const showDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        getHangoutList();
    }, [getHangoutList]);

    return (
        <>
            <Banner
                title={t("contact_lb_title").toUpperCase()}
                subtitle={t("contact_lb_subtitle")}
            />
            {loading && <Loading />}
            {!loading && (
                <ContentWrapper>
                    <Content size="md">
                        <Dialog
                            onClose={closeDialog}
                            open={open}
                            maxWidth={null}
                            id="lspDialog"
                        >
                            {locationList.map((item, key) => (
                                <div key={key}>
                                    {locationSelected.id === item.id &&
                                        item.googleMapLink &&
                                        item.FEImgMap && (
                                            <img
                                                src={
                                                    require(`@assets/images/${item.FEImgMap}`)
                                                        .default
                                                }
                                                alt=""
                                                onClick={() => showDialog(item)}
                                            />
                                        )}
                                </div>
                            ))}
                        </Dialog>
                        <Paper className={classes.locationBox}>
                            <div>
                                <Icon className="font-icon icon-address" />
                                <div>
                                    <LspTypography
                                        variant="body2"
                                        color="black"
                                        className={classes.titleLocation}
                                    >
                                        {t("contact_lb_address")}
                                    </LspTypography>

                                    {locationList.map((item, key) => (
                                        <Box mb={2} key={key}>
                                            <LspTypography
                                                variant="heading4"
                                                color="black"
                                                className={clsx(
                                                    classes.link,
                                                    locationSelected.id ===
                                                        item.id
                                                        ? "selected"
                                                        : null
                                                )}
                                                onClick={() =>
                                                    changeLocation(item)
                                                }
                                            >
                                                {item.name}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body3"
                                                color="grey"
                                                className={clsx(
                                                    classes.link,
                                                    locationSelected.id ===
                                                        item.id
                                                        ? "selected"
                                                        : null
                                                )}
                                                onClick={() =>
                                                    changeLocation(item)
                                                }
                                            >
                                                {item.address}
                                            </LspTypography>
                                        </Box>
                                    ))}
                                </div>
                            </div>
                            <div>
                                {locationList.map((item, key) => (
                                    <div key={key}>
                                        {locationSelected.id === item.id &&
                                            item.googleMapLink &&
                                            (item.FEImgMap ? (
                                                <img
                                                    src={
                                                        require(`@assets/images/${item.FEImgMap}`)
                                                            .default
                                                    }
                                                    alt=""
                                                    onClick={() =>
                                                        showDialog(item)
                                                    }
                                                />
                                            ) : (
                                                <iframe
                                                    src={item.googleMapLink}
                                                    height="100%"
                                                    width="100%"
                                                    allowFullScreen
                                                    frameBorder="0"
                                                    style={{ border: 0 }}
                                                    title="location"
                                                />
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </Paper>
                        <div className={classes.info}>
                            <div>
                                <div className={classes.item}>
                                    <Icon className="font-icon icon-callcenter" />
                                    <div>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                            className={classes.title}
                                        >
                                            {t("contact_lb_callcenter")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                        >
                                            {contactInfo.phone}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                        >
                                            {contactInfo.phoneOversea}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                        >
                                            {contactInfo.workingTime}
                                        </LspTypography>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <Icon className="font-icon icon-internationaltravel" />
                                    <div>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                            className={classes.title}
                                        >
                                            {t("contact_lb_website")}
                                        </LspTypography>

                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                            component="a"
                                            href={contactInfo.link}
                                            target="_blank"
                                        >
                                            {contactInfo.site}
                                        </LspTypography>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={classes.item}>
                                    <Icon className="font-icon icon-email" />
                                    <div>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                            className={classes.title}
                                        >
                                            {t("contact_lb_email")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                            component="a"
                                            href={`mailto:${contactInfo.email}`}
                                        >
                                            {contactInfo.email}
                                        </LspTypography>
                                    </div>
                                </div>

                                <div className={classes.item}>
                                    <Icon className="font-icon icon-Plus-Facebook" />
                                    <div>
                                        <LspTypography
                                            variant="body2"
                                            color="black"
                                            className={classes.title}
                                        >
                                            {contactInfo.facebookTitle}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body3"
                                            color="grey"
                                            component="a"
                                            href={contactInfo.facebook}
                                            target="_blank"
                                        >
                                            {contactInfo.facebook}
                                        </LspTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </ContentWrapper>
            )}
        </>
    );
};

export default Contact;
