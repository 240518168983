import { useTranslation } from "react-i18next";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import LspButton from "@components/LspButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
    },
}));

const EditReferralCodeConfirmation = ({
    onCancel,
    saveReferralCodeHandler,
    loading,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LspDialogTitle>
                {t("td_details_popup_confirm_title")}
            </LspDialogTitle>
            <LspDialogContent>
                {t("master:invite_friend_popup_confirm_change_code")}
            </LspDialogContent>
            <LspDialogActions>
                <LspButton variant="secondary" fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
                <LspButton
                    variant="primary"
                    fullWidth
                    onClick={saveReferralCodeHandler}
                    progressing={loading}
                >
                    {t("reissue_new_pin_card_lp_btn_confirm")}
                </LspButton>
            </LspDialogActions>
            <div style={{ clear: "both" }} />
        </div>
    );
};

export default EditReferralCodeConfirmation;
