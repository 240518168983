import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import { Skeleton } from "@material-ui/lab";
import LspAvatar from "@components/LspAvatar";

const InvitationPendingListLoading = ({ classes }) => {
    return (
        <Content size="sm" boxProps={{ className: classes.wrapper }}>
            <div className={classes.header}>
                <LspTypography variant="heading1">
                    <Skeleton />
                    <Skeleton />
                </LspTypography>
            </div>
            <div className={classes.body}>
                <div className={classes.note}>
                    <div>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div>
                </div>
                <div className={classes.pendingList}>
                    {[...new Array(4)].map((_, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className={classes.pendingItem} key={index}>
                                <LspAvatar />
                                <div className={classes.info}>
                                    <LspTypography>
                                        <Skeleton />
                                    </LspTypography>
                                    <LspTypography>
                                        <Skeleton />
                                    </LspTypography>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Content>
    );
};

export default InvitationPendingListLoading;
