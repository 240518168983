import isFunction from "lodash/isFunction";
import GlobalDialogController from "./GlobalDialogController";
import LspReceipt from "../../components/LspReceipt";

export default class LspReceiptController {
    static parsedReceipt;

    static getReceipt() {
        return this.parsedReceipt;
    }

    static setReceipt(receipt) {
        this.parsedReceipt = receipt;
    }

    static dialog(refNo, onClose, rawReceipt, options) {
        GlobalDialogController.show({
            component: () => (
                <LspReceipt
                    onClose={(data) => {
                        if (isFunction(onClose)) {
                            onClose(data);
                        }
                        this.closeDialog();
                    }}
                    bankXID={refNo}
                    rawReceipt={rawReceipt}
                    options={options}
                    viewType="dialog"
                />
            ),
        });
    }

    static closeDialog() {
        GlobalDialogController.hide();
    }
}
