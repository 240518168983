import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE } from "@config/constants";
import BillDetail from "@containers/SpendAccount/BillPayment/BillDetail";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Box, ButtonBase, Icon, makeStyles } from "@material-ui/core";
import billActions from "@redux/actions/bill";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import billServices from "@services/bill";
import payeeService from "@services/payee";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import Loading from "@components/Loading";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.typography.pxToRem(50),
        height: theme.typography.pxToRem(50),
        borderRadius: "50%",
    },
    name: {
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    id: {
        fontStyle: "italic",
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
    },
    billHint: {
        width: "70%",
        textAlign: "right",
        flex: 1,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            width: "auto",
        },
    },
    iconAction: {
        fontSize: 14,
        marginRight: theme.spacing(1),
    },
    redColor: {
        color: theme.palette.status.error,
    },
    tickIcon: {
        color: theme.palette.status.success,
    },
    header: {
        height: 83,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    actionWrapper: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        justifyContent: "flex-end",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    deleteButtonWrapper: {
        marginLeft: theme.spacing(10),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
    },
    confirmAction: {
        width: "100%",
        display: "flex",
        marginBottom: theme.spacing(2),
        flexWrap: "wrap",
        "& > button:nth-child(2)": {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(3),
        },
    },
}));

const BillItem = ({ parentClasses, item, getBillList }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [expand, setExpand] = useState(false);
    const [editing, setEditing] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [detail, setDetail] = useState(null);
    const [fetching, setFetching] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const unpaidBillText = useMemo(() => {
        const totalBill = detail?.bills?.length || 0;

        if (totalBill <= 0) {
            return t("payee_bill_no_unpaid_bill");
        }

        if (totalBill === 1) {
            return `${totalBill} ${t("move_money_unpaid_bill")}`;
        }

        return `${totalBill} ${t("move_money_unpaid_bills")}`;
    }, [detail, t]);

    const toggleEdit = (e) => {
        setConfirming(false);
        setEditing((prev) => !prev);
        e.preventDefault();
    };

    const toggleConfirmation = () => {
        setConfirming((prev) => !prev);
    };

    const getBillDetail = useCallback(async () => {
        setFetching(true);
        const dataToPost = {
            serviceCode: item.serviceCode,
            providerCode: item.providerCode,
            saveBill: true,
            providerId: item.providerId,
            formData: item.billInfoJson.formData,
            formId: item.billFormId,
        };
        const response = await billServices.getBillDetail(dataToPost);

        if (unmounted.current) return;

        setFetching(false);

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.NO_RECORD:
                setDetail(data);
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: code,
                });
                break;
        }
    }, [item]);

    const onItemClick = useCallback(() => {
        if (!detail && !fetching) {
            getBillDetail();
        }
        setConfirming(false);
        setEditing(false);
        setExpand((prev) => !prev);
    }, [detail, getBillDetail, fetching]);

    const deleteBill = async () => {
        setDeleting(true);
        setConfirming(false);
        const payload = {
            payeeId: item?.payeeId,
            payeeType: item?.payeeType,
        };
        const response = await payeeService.deletePayee(payload);

        if (unmounted.current) return;
        setDeleting(false);
        const { code } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            getBillList();
            const message = `${item?.providerName} ${t(
                "payee_msg_payee_has_been_deleted"
            )}`;
            AlertController.show(message, AlertType.Success);
            return;
        }

        GlobalDialogController.showError({ errorCode: code });
    };

    return (
        <div className={parentClasses.billItem}>
            <Box p={1} className={classes.header}>
                <Box display="flex" alignItems="center" onClick={onItemClick}>
                    <Box px={1}>
                        <img
                            className={classes.avatar}
                            src={item.iconLink}
                            alt=""
                        />
                    </Box>
                    <Box px={1}>
                        <div className={classes.name}>{item.providerName}</div>
                        <div className={classes.id}>
                            {item.customerId
                                ? `${t("move_money_biller_id")}: ${
                                      item.customerId
                                  }`
                                : ""}
                        </div>
                    </Box>
                </Box>
                <Box className={classes.actionWrapper}>
                    <Box
                        px={1}
                        className={classes.billHint}
                        onClick={onItemClick}
                    >
                        <LspTypography
                            color={detail?.bills?.length > 0 ? "error" : "grey"}
                            variant="body3"
                        >
                            {unpaidBillText}
                        </LspTypography>
                    </Box>
                    {expand && !fetching && (
                        <Box px={3}>
                            {!editing && !deleting && (
                                <ButtonBase onClick={toggleEdit}>
                                    <Icon
                                        className={`font-icon icon-edit ${classes.iconAction}`}
                                    />
                                    <LspTypography color="grey" variant="body2">
                                        {t("move_money_biller_edit")}
                                    </LspTypography>
                                </ButtonBase>
                            )}

                            {editing && !deleting && (
                                <LspButton
                                    variant="error"
                                    onClick={toggleEdit}
                                    size="small"
                                >
                                    <Icon
                                        className={`font-icon icon-close ${classes.iconAction}`}
                                    />
                                    {t("lb_cancel")}
                                </LspButton>
                            )}
                            {deleting && <Loading />}
                        </Box>
                    )}
                </Box>
            </Box>
            {editing && (
                <>
                    {!confirming && !deleting && (
                        <Box className={classes.deleteButtonWrapper}>
                            <ButtonBase onClick={toggleConfirmation}>
                                <Icon
                                    className={`font-icon icon-trashbin ${classes.iconAction} ${classes.redColor}`}
                                />
                                <LspTypography color="error" variant="body2">
                                    {t("move_money_biller_delete")}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}

                    {confirming && !deleting && (
                        <Box
                            className={`${classes.deleteButtonWrapper} ${classes.confirmAction}`}
                        >
                            <LspTypography variant="body2">
                                {t("payee_confirm_delete_payee")}
                            </LspTypography>
                            <ButtonBase onClick={deleteBill}>
                                <Icon
                                    className={`font-icon icon-checkmark ${classes.iconAction} ${classes.tickIcon}`}
                                />
                                <LspTypography color="success" variant="body2">
                                    {t("move_money_biller_delete")}
                                </LspTypography>
                            </ButtonBase>
                            <ButtonBase onClick={toggleConfirmation}>
                                <Icon
                                    className={`font-icon icon-close ${classes.iconAction} ${classes.redColor}`}
                                />
                                <LspTypography color="error" variant="body2">
                                    {t("lb_cancel")}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}
                </>
            )}
            {expand && (
                <BillDetail
                    selectedBill={item}
                    detail={detail}
                    fetching={fetching}
                    title={unpaidBillText}
                    showHistoryButton
                />
            )}
        </div>
    );
};

const dispatchProps = (dispatch) => ({
    setSelectedBill: (payload) =>
        dispatch(billActions.setSelectedBill(payload)),
    getBillList: () => dispatch(billActions.getBillList()),
});

export default memo(connect(null, dispatchProps)(BillItem));
