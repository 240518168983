/* eslint-disable prefer-destructuring */
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import querystring from "query-string";

const DEFAULT_URL = "/notification-center";

const DEEP_NAME = {
    OPEN_PAYEE: "payee.list",
    SPEND_TRANSACTION: "SpendTransList",
    INVITATION: "invitation",
    TRAVEL_LIBERTY_APPLY: "lib.trvl.apply",
    SUNLIFE_EXPIRED: "sunlife.pa.expired",
    BILL_PAY: "bill.pay",
    PAYMENT_DELETE: "payment.delete",
    PAYMENT_PAY: "payment.pay",
    CARD_MANAGEMENT: "ManageCard",
    BILL_LIST: "billList",
    PAYMENT_DASHBOARD: "paymentRequest",
    SEND_LIXI_DASHBOARD: "lixi.send",
    RECEIVE_LIXI_DASHBOARD: "lixi.received",
    OPEN_INSTALLMENT_CONVERT_DETAIL: "creditcard.installment.convert",
    OPEN_CURRENT_STATEMENT: "creditcard.statement.current",
    FORCE_SETUP_IOTP: "forceSetupIOTP",
    TdOpen: "TdOpen",
};

const DEEP_LINK_CONFIGS = {
    PROTOCOL: "plus:",
    HOT_NAME: "plus.vn",
    TEMPLATE_NAME: {
        OPEN_PAYEE: DEEP_NAME.OPEN_PAYEE,
        SPEND_TRANSACTION: DEEP_NAME.SPEND_TRANSACTION,
        INVITATION: DEEP_NAME.INVITATION,
        OPEN_INSTALLMENT_CONVERT_DETAIL:
            DEEP_NAME.OPEN_INSTALLMENT_CONVERT_DETAIL,
        OPEN_CURRENT_STATEMENT: DEEP_NAME.OPEN_CURRENT_STATEMENT,
        TRAVEL_LIBERTY_APPLY: DEEP_NAME.TRAVEL_LIBERTY_APPLY,
    },
    TEMPLATE_CONFIG: [
        {
            KEY: DEEP_NAME.OPEN_PAYEE,
            PATH: "/spend-account/move-money",
        },
        {
            KEY: DEEP_NAME.SPEND_TRANSACTION,
            PATH: "/spend-account/transactions",
        },
        {
            KEY: DEEP_NAME.INVITATION,
            PATH: "/invitation/send",
        },
        {
            KEY: DEEP_NAME.TRAVEL_LIBERTY_APPLY,
            PATH: "/wealth/insurance/dashboard",
        },
        {
            KEY: DEEP_NAME.SUNLIFE_EXPIRED,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.BILL_PAY,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.PAYMENT_DELETE,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.PAYMENT_PAY,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.BILL_LIST,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.PAYMENT_DASHBOARD,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.SEND_LIXI_DASHBOARD,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.RECEIVE_LIXI_DASHBOARD,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.OPEN_INSTALLMENT_CONVERT_DETAIL,
            PATH: "/credit-card/installment/conversion",
        },
        {
            KEY: DEEP_NAME.OPEN_CURRENT_STATEMENT,
            PATH: "/credit-card/statements",
        },
        {
            KEY: DEEP_NAME.FORCE_SETUP_IOTP,
            PATH: DEFAULT_URL,
        },
        {
            KEY: DEEP_NAME.TdOpen,
            PATH: "term-deposit/active",
        },
    ],
};

const useDeepLink = () => {
    // plus://plus.vn/payee.list/open?payeeId=9703

    const history = useHistory();

    const parsedLinkToObject = (link) => {
        /**
         * Parsed link to get link info (object)
         * @param {string} link
         */

        const el = document.createElement("a");
        el.href = decodeURIComponent(link);
        return el;
    };

    const isHostNameValid = useCallback((link) => {
        /**
         * check host name is valid?
         * @param {string} link
         */

        let hostname;
        // find & remove protocol (http, ftp, etc.) and get hostname
        if (link.indexOf("://") > -1) {
            hostname = link.split("/")[2];
        } else {
            hostname = link.split("/")[0];
        }
        // find & remove port number
        hostname = hostname.split(":")[0];
        // find & remove "?"
        hostname = hostname.split("?")[0];

        return hostname === DEEP_LINK_CONFIGS.HOT_NAME;
    }, []);

    const isProtocolValid = useCallback((protocol) => {
        /**
         * check protocol is valid?
         * @param {string} protocol
         */
        return protocol === DEEP_LINK_CONFIGS.PROTOCOL;
    }, []);

    const getScreenByLink = useCallback((link) => {
        /**
         * Get screen with specific link
         * @param {string} link
         */

        const extractedLink = link.split("/");
        // Fixed for safari
        return extractedLink[3] ? extractedLink[3] : extractedLink[1];
    }, []);

    const getActionByLink = useCallback((link) => {
        return link.split("/")[4];
    }, []);

    const isTemplateNameValid = useCallback(
        (link) => {
            /**
             * Check template name is valid?
             * @param {string} link
             */
            const screen = getScreenByLink(link);
            const isExistingTemplate = DEEP_LINK_CONFIGS.TEMPLATE_CONFIG.filter(
                (item) => item.KEY === screen
            );
            return isExistingTemplate.length > 0;
        },
        [getScreenByLink]
    );

    const isValidDeepLink = useCallback(
        (deepLink) => {
            /**
             * DeepLink is valid?
             * @param {string} deepLink
             */

            if (!deepLink) return false;

            const parsedLink = parsedLinkToObject(deepLink);

            // Fixed for firefox
            let pathName = parsedLink.pathname;
            if (pathName === "") {
                const url = new URL(deepLink);
                pathName = url.pathname;
            }

            if (!isHostNameValid(deepLink)) {
                return false;
            }

            if (!isProtocolValid(parsedLink.protocol)) {
                return false;
            }

            if (!isTemplateNameValid(deepLink)) {
                return false;
            }

            return true;
        },
        [isTemplateNameValid, isHostNameValid, isProtocolValid]
    );

    const navigationHandler = useCallback(
        (link, isNewTab) => {
            /**
             * Navigation Handler
             * @param {string} link
             * @param {boolean} isNewTab - if external link --> open new tab with link
             */

            if (isNewTab) {
                window.open(link, "_blank");
            } else {
                history.push(link);
            }
        },
        [history]
    );

    const getURLNavigation = useCallback(
        (deepLink, isNewTab) => {
            const parsedLink = parsedLinkToObject(deepLink);
            const url = new URL(deepLink);

            let finalLink = "";

            // Fixed for firefox
            let pathName = parsedLink.pathname;
            if (pathName === "") {
                pathName = url.pathname;
            }

            let search = parsedLink.search;
            if (search === "") {
                search = url.search;
            }

            const screen = getScreenByLink(deepLink);
            const template = DEEP_LINK_CONFIGS.TEMPLATE_CONFIG.filter(
                (item) => item.KEY === screen
            )[0];

            const action = getActionByLink(deepLink);

            // console.log("screen", screen);
            // console.log("template", template);
            // console.log("action", action);
            // console.log("search", search);

            if (isNewTab) {
                finalLink += window.location.origin;
            }

            finalLink +=
                template.PATH.charAt(0) !== "/"
                    ? `/${template.PATH}`
                    : template.PATH;
            switch (screen) {
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME.OPEN_PAYEE:
                    const payeeId = querystring.parse(parsedLink.search)
                        ?.payeeId;
                    finalLink += `?payeeId=${payeeId}`;
                    break;
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME.SPEND_TRANSACTION:
                    const refNo = querystring.parse(parsedLink.search)?.refNo;
                    if (refNo) {
                        finalLink += `?txnId=${refNo}`;
                    }
                    break;
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME
                    .OPEN_INSTALLMENT_CONVERT_DETAIL:
                    const txnId = querystring.parse(parsedLink.search)?.txnId;
                    if (txnId) {
                        finalLink += `/${txnId}`;
                    }
                    break;
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME.INVITATION:
                    break;
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME.OPEN_CURRENT_STATEMENT:
                    break;
                case DEEP_LINK_CONFIGS.TEMPLATE_NAME.TRAVEL_LIBERTY_APPLY:
                    // timo://timo.vn/lib.trvl.apply/push/?prokey=insurancetravel&result=success
                    // console.log("action", action);
                    const productKey = querystring.parse(parsedLink.search)
                        ?.prokey;
                    const result = querystring.parse(parsedLink.search)?.result;
                    finalLink += `?action=${action}&productKey=${productKey}&result=${result}`;
                    break;
                default:
                    break;
            }

            return finalLink;
        },
        [getScreenByLink, getActionByLink]
    );

    const deepLinkHandler = useCallback(
        /**
         * Map deepLink
         * @param {string} deepLink
         */
        (deepLink) => {
            if (isValidDeepLink(deepLink)) {
                console.log(
                    "%c>>Valid format deep link :)",
                    "color:#009900;font-size:14px;"
                );

                const link = getURLNavigation(deepLink, false);
                navigationHandler(link);
            } else {
                console.log(
                    "%c>>Invalid format deep link :(",
                    "color:#ff9900;font-size:14px;"
                );
            }
        },
        [isValidDeepLink, navigationHandler, getURLNavigation]
    );

    return {
        deepLinkHandler,
        navigationHandler,
    };
};

export default useDeepLink;

// list[i].deeplink = 'timo://timo.vn/sunlife.pa.expired/open';
// list[i].deeplink = 'timo://timo.vn/cards.potential/open';
// list[i].deeplink = 'timo://timo.vn/lib.trvl.apply/push/?prokey=insurancetravel&result=fail';

// fake for manage card
// list[i].deeplink = 'timo://timo.vn/ManageCard/expand?cardID=810';
// list[i].deeplink = 'timo://timo.vn/ManageCard/open';
// list[i].group = 'ManageCard';
// list[i].deeplink = 'plus://plus.vn/creditcard.installment.convert/open?txnId=CC000001714689269';
// list[i].deeplink = 'plus://plus.vn/creditcard.statement.current/open';
// list[i].deeplink = 'plus://plus.vn/payee.list/open?payeeId=7505';
// list[i].deeplink = 'plus://plus.vn/forceSetupIOTP/open';
// list[i].deeplink =
// 	"plus://plus.vn/invitation/dashboard";
