import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { GENDER_LIST } from "@config/constants";
import LspTypography from "@components/LspTypography";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LspAddress from "@components/LspAddress";

const OwnerPolicyReview = ({ user, province, district, birthDay }) => {
    const { t } = useTranslation();

    return (
        <>
            <LspTextField
                autoFocus
                name="fullName"
                value={user?.fullName}
                label={t("wealth:GE00011")}
                disabled
            />
            <Box paddingBottom={1}>
                <LspTypography color="grey">{t("lb_gender")}</LspTypography>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="bottom"
                    >
                        {GENDER_LIST?.map((item) => {
                            return (
                                <FormControlLabel
                                    key={item?.cfgKey}
                                    value={item?.cfgKey}
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={
                                                user?.gender === item?.cfgKey
                                            }
                                            disabled
                                        />
                                    }
                                    label={t(item?.cfgValue)}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
            <KeyboardDatePicker
                autoOk
                disableFuture
                openTo="year"
                format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                variant="inline"
                disableToolbar
                value={birthDay}
                disabled
                TextFieldComponent={(params) => {
                    return (
                        <LspTextField {...params} label={t("wealth:GE00074")} />
                    );
                }}
            />
            <LspTextField
                autoFocus
                name="phoneNumber"
                value={user?.phoneNumber}
                label={t("wealth:GE00012")}
                disabled
            />
            <LspTextField
                autoFocus
                name="addressName"
                value={user?.address?.addressName}
                label={t("wealth:GE00078")}
                disabled
            />

            <LspAddress
                selectedProvince={province}
                selectedDistrict={district}
                fullWidth
                disabledAll
                isHideWard
                errors={{
                    province: "",
                    district: "",
                    ward: "",
                }}
            />
            <LspTextField
                autoFocus
                name="email"
                value={user?.userEmail}
                label={t("wealth:GE00079")}
                disabled
            />
        </>
    );
};

export default memo(OwnerPolicyReview);
