import { WEALTH_NAVIGATION_KEYS } from "@containers/Wealth/wealthConstants";
import WealthInsuranceDashboard from "@containers/Wealth/WealthInsurance/WealthInsuranceDashboard";
import WealthMotorbike from "@containers/Wealth/WealthInsurance/WealthMotorbike";
import WealthTravel from "@containers/Wealth/WealthInsurance/WealthTravel";
import usePathname from "@helpers/usePathname";
import { memo, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const parentName = `${WEALTH_NAVIGATION_KEYS.ROOT}/${WEALTH_NAVIGATION_KEYS.GENERAL_INSURANCE}`;

const GeneralInsurance = () => {
    const { generateChildPathname } = usePathname();
    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.DASHBOARD
                ),
                component: WealthInsuranceDashboard,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.TRAVEL_INSURANCE
                ),
                component: WealthTravel,
            },
            {
                path: generateChildPathname(
                    parentName,
                    WEALTH_NAVIGATION_KEYS.MOTORBIKE_INSURANCE
                ),
                component: WealthMotorbike,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    return (
        <>
            <Switch>
                {contentRoutes.map((r) => {
                    return (
                        <Route
                            key={r.path}
                            path={r.path}
                            component={r.component}
                        />
                    );
                })}
                {!!defaultRoutes && (
                    <Route
                        component={() => <Redirect to={defaultRoutes.path} />}
                    />
                )}
            </Switch>
        </>
    );
};

export default memo(GeneralInsurance);
