import { Skeleton } from "@material-ui/lab";

const PasswordGeneratorFetching = ({ className }) => {
    return (
        <>
            <div className={className.info}>
                <Skeleton />
                <Skeleton />
                <Skeleton width="50%" />
            </div>
            <div className={className.toggle}>
                <Skeleton width={70} />
            </div>
        </>
    );
};

export default PasswordGeneratorFetching;
