import { call, put } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import wealthAction from "@redux/actions/wealth";

export function* getProductListSaga() {
    const response = yield call(wealthService.getProductList);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(wealthAction.getProductListSuccess(data.products));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(wealthAction.getProductListError());
            break;
    }
}
