import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DownloadApp from "@components/DownloadApp";

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: "center",
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: "600",
        textAlign: "center",
    },
    desc: {
        color: theme.palette.white.contrastText,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "center",
        maxWidth: 260,
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
        },
    },
    app: {
        width: 158,
    },
}));

const JoinUs = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <div className={classes.title}>{t("login_lb_join_us")}</div>
            <div className={classes.desc}>
                {t("login_text_join_us_description")}
            </div>
            <DownloadApp size="small" />
        </div>
    );
};

export default JoinUs;
