import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import { Logo } from "@components/LspBranding";
import { format, isMatch, isSameDay, parse } from "date-fns";
import { enUS, vi } from "date-fns/locale";
import Interweave from "interweave";
import backgroundImg from "@assets/images/bg_login.jpg";
import { useHistory } from "react-router-dom";
import { GUEST_ROUTES_PATH } from "@containers/App/routes.constant";

const useStyles = makeStyles((theme) => ({
    body: {
        background: `url(${backgroundImg}) no-repeat top right; background-size: 100% 100%`,
        width: "100vw",
        height: "100vh",
        textAlign: "center",
        paddingTop: theme.spacing(6),
    },
    content: {
        marginTop: theme.spacing(10),
        "& > span": {
            fontSize: 60,
            color: theme.palette.neutral.white,
        },
    },
    logo: {
        maxWidth: 200,
        margin: "auto",
    },
}));

const Maintenance = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const responseTxt = useMemo(() => {
        // const data = {
        //     fromTime: "13/07/2017 15:00",
        //     toTime: "15/07/2017 17:00",
        // };
        const data = JSON.parse(localStorage.getItem("termObject"));

        if (data && data.fromTime && data.toTime) {
            let parsedFromTime = "";
            let parsedToTime = "";
            let fromTimeDisplay = "";
            let toTimeDisplay = "";

            if (isMatch(data?.fromTime, "dd/MM/yyyy HH:mm")) {
                parsedFromTime = parse(
                    data?.fromTime,
                    "dd/MM/yyyy HH:mm",
                    new Date()
                );
                fromTimeDisplay = format(
                    parsedFromTime,
                    "HH:mm cccc, dd/MM/yyyy",
                    {
                        locale: i18n.language === "vn" ? vi : enUS,
                    }
                );
            }

            if (isMatch(data?.toTime, "dd/MM/yyyy HH:mm")) {
                parsedToTime = parse(
                    data?.toTime,
                    "dd/MM/yyyy HH:mm",
                    new Date()
                );

                toTimeDisplay = format(parsedToTime, "HH:mm cccc, dd/MM/yyyy", {
                    locale: i18n.language === "vn" ? vi : enUS,
                });
            }

            if (
                parsedFromTime &&
                parsedToTime &&
                isSameDay(parsedFromTime, parsedToTime)
            ) {
                let content = t("maintenance_from_hour_to_hour");
                fromTimeDisplay = format(parsedFromTime, "cccc , dd/MM/yyyy", {
                    locale: i18n.language === "vn" ? vi : enUS,
                });
                content = content.replace("%@date", fromTimeDisplay);
                content = content.replace(
                    "%@start",
                    format(parsedFromTime, "hh:mm aa")
                );
                content = content.replace(
                    "%@end",
                    format(parsedToTime, "hh:mm aa")
                );
                return content;
            }

            let content = t("maintenance_msg_from_date_to_date");
            content = content.replace("%@start", fromTimeDisplay);
            content = content.replace("%@end", toTimeDisplay);
            return content;
        }
    }, [i18n, t]);

    const backToLogin = () => {
        history.push(GUEST_ROUTES_PATH.LOGIN);
    };

    return (
        <div className={classes.body}>
            <Logo
                variant="default"
                className={classes.logo}
                onClick={backToLogin}
            />
            <Content size="md">
                <div className={classes.content}>
                    <Icon className="font-icon icon-maintenance" />
                    <Box mt={3} mb={3.75}>
                        <LspTypography variant="heading2" color="white">
                            {t("maintenance_title")}
                        </LspTypography>
                    </Box>
                    <LspTypography variant="body1" color="white">
                        <Interweave content={responseTxt} />
                    </LspTypography>
                    <Box mt={2.5}>
                        <LspTypography variant="body1" color="white">
                            {t("maintenance_sorry_for_inconvenience")}
                        </LspTypography>
                    </Box>
                </div>
            </Content>
        </div>
    );
};

export default Maintenance;
