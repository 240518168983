/* eslint-disable no-unreachable */
import { memo, useEffect, useMemo, useRef, useState } from "react";

import useForeignerDialog from "@helpers/useForeignerDialog";
import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { parse } from "date-fns";
import { DOCUMENT_ERROR_TYPE, REGULATION_TYPE } from "@config/constants";
import NotificationBox from "@components/NotificationBox";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import Interweave from "interweave";
import LspButtonIconLink from "@components/LspButtonIconLink";
import useTermDeposit from "@containers/TermDeposit/OpenTermDeposit/useTermDeposit";

// const MIN_VALID_DOCUMENT_PERIOD = 6;

const useStyles = makeStyles((theme) => ({
    warningBox: {
        marginTop: theme.spacing(2),
    },
}));

const TermDepositForeignerBoxWarning = ({ today, user }) => {
    const { t } = useTranslation();
    const { dialog } = useForeignerDialog();
    const { checkVisaIsValid } = useTermDeposit();
    const classes = useStyles();

    const [contentKey, setContentKey] = useState("");
    const firstLoad = useRef(false);
    const isForeigner = useMemo(() => user?.isForeigner, [user?.isForeigner]);
    // const isForeigner = true;
    const visaDateTo = useMemo(() => {
        if (!user?.visaDateTo) return null;

        return parse(user?.visaDateTo, "yyyy-MM-dd", new Date());
    }, [user?.visaDateTo]);

    const getContent = (type) => {
        if (type === DOCUMENT_ERROR_TYPE.VISA_DATE_VALID) return;

        switch (type) {
            case DOCUMENT_ERROR_TYPE.VISA_DATE_LESS_MONTH:
                setContentKey("td_notice_visa_onemonth");
                break;
            case DOCUMENT_ERROR_TYPE.EXPIRED_VISA:
                setContentKey("td_notice_visa_expired");
                break;
            case DOCUMENT_ERROR_TYPE.VISA_PERIOD_LESS_SIX_MONTHS:
                setContentKey("td_notice_visa_sixmonths");
                break;
            default:
                break;
        }
    };

    const openWarningDialog = () => {
        dialog(REGULATION_TYPE.TD_PAGE);
    };

    useEffect(() => {
        if (isForeigner && !firstLoad.current) {
            firstLoad.current = true;
            const result = checkVisaIsValid(visaDateTo, today);
            getContent(result?.type);
        }
    }, [isForeigner, checkVisaIsValid, visaDateTo, today]);

    return (
        isForeigner &&
        contentKey && (
            <Content boxProps={{ className: classes.warningBox }}>
                <NotificationBox title={t("regulation:gs_notice_title")}>
                    <LspTypography variant="body2">
                        <Interweave content={t(`regulation:${contentKey}`)} />
                    </LspTypography>
                    <Box marginTop={1}>
                        <LspButtonIconLink
                            color="success"
                            onClick={openWarningDialog}
                        >
                            {t("regulation:td_tap_learn_more")}
                        </LspButtonIconLink>
                    </Box>
                </NotificationBox>
            </Content>
        )
    );
};

const mapState = (state) => ({
    today: state.user.serverTime.value || new Date(),
    user: state.user.info,
});

export default memo(connect(mapState)(TermDepositForeignerBoxWarning));
