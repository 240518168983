import { Box, Grid, Icon, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import LspTextField from "../../components/LspTextField";
import { FetchStatus, REGEX_PATTERN } from "../../config/constants";
import DoubleButtons from "../../components/DoubleButtons";
import CardManagementActions from "../../redux/actions/card-management";
import { CARD_MANAGEMENT_SUB_NAV, CARD_TYPES } from "./constant";
import LspButton from "../../components/LspButton";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(3),
        width: "100%",
        margin: "auto",
        boxShadow: theme.shadows[1],
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
    },
    form: {
        textAlign: "center",
    },
    header: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
        position: "relative",
    },
    closeIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        color: theme.palette.grey[400],
        cursor: "pointer",
    },
    rectSkeleton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const InputCardNumber = ({
    className,
    cardType,
    onClose,
    nextHandler,
    loading,
    checkCardNumber,
    status,
    featureState,
    cardId,
    resetStatus,
}) => {
    const classes = useStyles();

    const { t } = useTranslation(["translation", "master"]);
    const { register, handleSubmit, errors, setError, clearErrors } = useForm();
    const [cardNumber, setCardNumber] = useState("");

    const checkCardNumberSubmit = (data) => {
        // eslint-disable-next-line prefer-const
        let dataToPost = {
            cardNumber,
            CC: !(cardType === CARD_TYPES.DEBIT_CARD),
            enableImmediatelyUseCCW4: featureState?.enableImmediatelyUseCCW4,
        };

        if (cardId) {
            dataToPost.cardId = cardId;
        }
        if (
            cardType ===
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD ||
            cardType ===
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP
        ) {
            dataToPost.cardType = CARD_TYPES[cardType];
            dataToPost.physicalVirtual = true;
        }

        checkCardNumber(dataToPost);
    };

    const primaryButton = {
        label: t("lb_next"),
        onClick: (data) => {
            checkCardNumberSubmit(data);
        },
    };

    const secondaryButton = {
        label: t("lb_cancel"),
        onClick: () => {
            onClose();
        },
    };

    const cardNumberOnChange = (event) => {
        setCardNumber(event.target.value);
    };

    useEffect(() => {
        if (status === FetchStatus.Success) {
            nextHandler(cardNumber);
        } else if (status === FetchStatus.Error) {
            setError("cardNumber", {
                type: "manual",
                message: t("master:activate_card_error_msg"),
            });
        }
    }, [status, nextHandler, cardNumber, t, setError]);

    useEffect(() => {
        return () => {
            clearErrors("cardNumber");
            resetStatus();
        };
    }, [clearErrors, resetStatus]);

    return (
        <Box className={className} marginTop={2}>
            <Grid container direction="row" justify="space-between">
                <Grid item md={9} xs={11} className={classes.wrapper}>
                    <form
                        onSubmit={handleSubmit(checkCardNumberSubmit)}
                        className={classes.form}
                    >
                        <div className={classes.header}>
                            <div>
                                <Icon className="font-icon icon-Timo-Plus-Phone" />
                                <div>{t("master:activate_card_title")}</div>
                            </div>
                        </div>
                        <Box marginTop={4}>
                            <LspTextField
                                onChange={cardNumberOnChange}
                                autoFocus
                                error={!!errors.cardNumber}
                                helperText={errors.cardNumber?.message || " "}
                                name="cardNumber"
                                label={t("master:activate_card_subtitle_web")}
                                inputProps={{
                                    type: "password",
                                    maxLength: 4,
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        pattern: {
                                            value: REGEX_PATTERN.CARD_NUMBER,
                                            message: t(
                                                "master:activate_card_error_msg"
                                            ),
                                        },
                                    }),
                                    disabled: loading,
                                }}
                            />
                        </Box>

                        {onClose && (
                            <DoubleButtons
                                disabled={loading}
                                primaryButton={primaryButton}
                                secondaryButton={secondaryButton}
                                progressing={loading}
                            />
                        )}

                        {!onClose && (
                            <LspButton
                                type="submit"
                                progressing={loading}
                                fullWidth
                            >
                                {t("lb_next")}
                            </LspButton>
                        )}
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
};

const stateProps = (state) => ({
    loading: state.cardInfo.checkCardNumberLoading,
    status: state.cardInfo.checkCardNumberStatus,
    featureState: state.user.featureState.data,
});

const dispatchProps = (dispatch) => ({
    checkCardNumber: (payload) =>
        dispatch(CardManagementActions.checkCardNumber(payload)),
    resetStatus: () => dispatch(CardManagementActions.resetStatus()),
});

export default connect(stateProps, dispatchProps)(InputCardNumber);
