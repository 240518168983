import { Box, makeStyles } from "@material-ui/core";
import { memo, useState, useEffect, useCallback, useMemo } from "react";
import { isFunction } from "lodash";
import { connect } from "react-redux";
import lspReceiptActions from "@redux/actions/lsp-receipt";
import { FetchStatus } from "@config/constants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import LspButton from "../LspButton";

import LspTypography from "../LspTypography";
import ButtonUploader from "../AvatarUploader/ButtonUploader";
import DoubleButtons from "../DoubleButtons";
import LspUploadFile from "./LspUploadFile";

const attachmentSize = 10;

const useStyles = makeStyles((theme) => {
    return {
        container: {
            background: theme.palette.background.paper,
        },
        wrapper: {
            background: theme.palette.neutral.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(4),
            },
        },
        errorText: {
            marginTop: theme.spacing(2),
        },
        confirmDialog: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            "& img": {
                width: "100%",
                height: "100%",
            },
        },
        contentImg: {
            width: "100%",
            marginBottom: theme.spacing(2),
            display: "flex",
            justifyContent: "center",
            "& img": {
                width: 150,
                height: "100%",
            },
        },

        action: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: theme.spacing(2),
        },
        actionItem: {
            height: 28,
            marginRight: theme.spacing(9),
            cursor: "pointer",
            display: "flex",
            color: theme.palette.neutral.white,
            alignItems: "center",

            "&:last-child": {
                marginRight: 0,
            },
        },
        content: {
            maxWidth: 350,
            background: theme.palette.background.paper,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
        },
    };
});

const VIEW_MODE = {
    UPLOAD: 0,
    CONFIRM: 1,
};
const LspUploadAttachment = ({
    onSuccess,
    refNo,
    status,
    reset,
    loading,
    onCancel,
    max,
    systemParams,
    fileManageId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const hostUrl = process.env.REACT_APP_API_URL;
    const [files, setFiles] = useState([]);
    const [warningMessage, setWarningMessage] = useState("");
    const [viewMode, setViewMode] = useState(VIEW_MODE.UPLOAD);

    const [isLoading, setLoading] = useState(false);

    const isModifyMode = useMemo(() => {
        return fileManageId != null && fileManageId.length > 0;
    }, [fileManageId]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            if (isFunction(onSuccess)) {
                onSuccess();
            }
            reset();
        }
    }, [onSuccess, reset, status]);

    const onUploadHandler = (file) => {
        const fileList = files.concat(file.files);
        const fileLength = fileList.length;

        if (fileLength > max) {
            setWarningMessage(
                isModifyMode
                    ? t(
                          "txnapptype:receipt_attach_file_error_number_file_change_exceed_desc"
                      )
                    : t(
                          "txnapptype:receipt_attach_file_error_number_file_exceed_desc"
                      )
            );
            return;
        }
        setFiles(fileList);
    };

    const onCancelHandler = () => {
        if (isFunction(onCancel)) {
            onCancel();
        }
    };

    const onSubmitHandler = useCallback(async () => {
        if (isFunction(onSuccess)) {
            onSuccess(files);
        }
    }, [files, onSuccess]);

    const convertAcceptFile = useMemo(() => {
        if (systemParams?.conf?.ExtensionOfAttachment) {
            const splitExtension = systemParams?.conf?.ExtensionOfAttachment.split(
                "."
            );

            const convertSupportFiles = splitExtension
                .filter((item) => item !== "")
                .map((item) => {
                    switch (item) {
                        case "png":
                        case "jpg":
                        case "jpeg":
                            return `image/${item}`;

                        default:
                            return `application/${item}`;
                    }
                })
                .join(", ");

            return convertSupportFiles;
        }
    }, [systemParams]);

    const updateStatusDisplayConditionDialog = useCallback(
        (templateName, templateStatus) => {
            const cachedStatuses = JSON.parse(
                localStorage.getItem("statusConditionDialog")
            );
            const stringifyStatuses = JSON.stringify({
                ...cachedStatuses,
                [templateName]: templateStatus,
            });
            localStorage.setItem("statusConditionDialog", stringifyStatuses);
        },
        []
    );

    const getStatusDisplayConditionDialog = (templateName) => {
        const cachedStatuses = JSON.parse(
            localStorage.getItem("statusConditionDialog")
        );

        if (cachedStatuses && cachedStatuses[templateName])
            return cachedStatuses[templateName];

        return false;
    };

    useEffect(() => {
        const RECEIPT_CONFIRM_DIALOG_STATUS = getStatusDisplayConditionDialog(
            "RECEIPT_CONFIRM_DIALOG"
        );

        if (!RECEIPT_CONFIRM_DIALOG_STATUS) {
            setViewMode(VIEW_MODE.CONFIRM);
            updateStatusDisplayConditionDialog("RECEIPT_CONFIRM_DIALOG", true);
        }
    }, [updateStatusDisplayConditionDialog]);

    const handleCancel = () => {
        updateStatusDisplayConditionDialog("RECEIPT_CONFIRM_DIALOG", false);
        onCancelHandler();
    };

    const handleConfirm = () => {
        updateStatusDisplayConditionDialog("RECEIPT_CONFIRM_DIALOG", true);
        setViewMode(VIEW_MODE.UPLOAD);
    };

    return (
        <>
            {viewMode === VIEW_MODE.UPLOAD && (
                <div className={classes.container}>
                    <Box p={3} pb={4} className={classes.wrapper}>
                        <LspTypography
                            component="div"
                            variant="heading2"
                            color="grey"
                        >
                            {t("txnapptype:receipt_btn_attach_file")}
                        </LspTypography>

                        <ButtonUploader
                            onError={setWarningMessage}
                            onUpload={onUploadHandler}
                            avatarMaxSize={attachmentSize}
                            acceptedFileExtensions={convertAcceptFile}
                            type="multiple"
                            maxFiles={max}
                            modifyMode={isModifyMode}
                        />

                        <LspUploadFile
                            onError={setWarningMessage}
                            onUpload={onUploadHandler}
                            avatarMaxSize={attachmentSize}
                            acceptedFileExtensions={convertAcceptFile}
                            files={files}
                            type="multiple"
                            maxFiles={max}
                            modifyMode={isModifyMode}
                        />
                        <LspTypography
                            color="error"
                            textCenter
                            className={classes.errorText}
                        >
                            {warningMessage}
                        </LspTypography>
                    </Box>

                    <Box p={3} pt={0}>
                        <DoubleButtons
                            progressing={isLoading}
                            primaryButton={{
                                label: t("manage_device_text_save"),
                                onClick: onSubmitHandler,
                                disable:
                                    (files <= max &&
                                        warningMessage &&
                                        warningMessage.length > 0) ||
                                    files.length === 0,
                            }}
                            secondaryButton={{
                                label: t("lb_cancel"),
                                onClick: onCancelHandler,
                            }}
                        />
                    </Box>
                </div>
            )}
            {viewMode === VIEW_MODE.CONFIRM && (
                <>
                    <div className={classes.confirmDialog}>
                        <div className={classes.content}>
                            <div className={classes.contentImg}>
                                <img
                                    src={`${hostUrl}/cxpassets/frontend/images/pfm/custom-tag/alert_delete_tag.png`}
                                    alt="Confirm Dialog"
                                />
                            </div>
                            <LspTypography variant="heading1" textCenter>
                                {t(
                                    "txnapptype:receipt_attach_file_confirm_title"
                                )}
                            </LspTypography>
                            <LspTypography
                                variant="body2"
                                color="grey"
                                textCenter
                            >
                                {t(
                                    "txnapptype:receipt_attach_file_confirm_desc"
                                )}
                            </LspTypography>
                            <div className={classes.action}>
                                <LspButton
                                    fullWidth
                                    variant="secondary"
                                    mr={1}
                                    onClick={() => handleCancel()}
                                >
                                    {t(
                                        "txnapptype:receipt_attach_file_delete_btn_cancel"
                                    )}
                                </LspButton>
                                <LspButton
                                    fullWidth
                                    progressing={loading}
                                    onClick={() => handleConfirm()}
                                >
                                    {t(
                                        "txnapptype:receipt_attach_file_delete_btn_confirm"
                                    )}
                                </LspButton>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

LspUploadAttachment.propTypes = {
    status: PropTypes.string,
    loading: PropTypes.bool,
    refNo: PropTypes.string,
    onSuccess: PropTypes.func,
    max: PropTypes.number,
    fileManageId: PropTypes.string,
};

LspUploadAttachment.defaultProps = {
    status: null,
    loading: false,
    refNo: "",
    onSuccess: null,
    max: 0,
    fileManageId: "",
};

const mapStateToProps = (state) => ({
    status: state.receipt.notes.status,
    loading: state.receipt.notes.fetching,
    systemParams: state.systemParams.info,
});

const mapDispatchToProps = (dispatch) => ({
    submitNotes: (payload) => dispatch(lspReceiptActions.submitNotes(payload)),
    reset: () => dispatch(lspReceiptActions.resetNote()),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(LspUploadAttachment));
