import { memo } from "react";
import { makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TermDepositList from "./TermDepositList";
import AutoDeposit from "./AutoDeposit";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    activeItem: {
        marginBottom: theme.spacing(3),
    },
    title: {
        padding: theme.spacing(2, 3),
    },
}));

const ActiveList = ({ featureState }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Content>
            <div className={classes.container}>
                {featureState?.enableRecurringTd && (
                    <div className={classes.activeItem}>
                        <LspTypography
                            className={classes.title}
                            variant="subheading3"
                            uppercase
                            color="grey"
                        >
                            {t("TD:td_recurring_section_title")}
                        </LspTypography>
                        <AutoDeposit />
                    </div>
                )}
                <div className={classes.activeItem}>
                    <LspTypography
                        className={classes.title}
                        variant="subheading3"
                        uppercase
                        color="grey"
                    >
                        {t("TD:td_list_section_title")}
                    </LspTypography>
                    <TermDepositList />
                </div>
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    isExisted: state.termDeposit.autoDeposit.isExisted,
    featureState: state.user.featureState.data,
});
export default connect(mapState, null)(memo(ActiveList));
