import api from "../config/api";

const generateQRLogin = async ({ sso, sig }) => {
    const payload = {
        sso,
        sig,
    };

    return await api.post("community/qr/generate", payload, {
        bypassErrorHandler: true,
    });
};

const getQRStatus = async ({ uuid }) => {
    const payload = {
        uuid,
    };

    return await api.post("community/qr/status", payload, {
        bypassErrorHandler: true,
    });
};

const communityService = {
    generateQRLogin,
    getQRStatus,
};

export default communityService;
