import { memo, useMemo, useState, useEffect } from "react";

import vietQRLogo from "@assets/images/viet_qr_logo.svg";

import {
    CircularProgress,
    Icon,
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import { ALIAS_TYPE } from "@config/constants";
import userAction from "@redux/actions/user";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import BankQRCodeLogo from "./BankQRCodeLogo";
import BankQRCodeInfo from "./BankQRCodeInfo";
import BankQRCodeAmount from "./BankQRCodeAmount";
import VietQRCode from "./VietQRCode";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(3),
        width: "100%",
    },
    headerIcon: {
        width: "112px",
    },
    bankCodeLogo: {
        marginBottom: theme.spacing(4),
    },
    bankCodeInfo: {
        marginBottom: theme.spacing(3),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    bankCodeAmount: {},
    bankSelectAccount: {
        display: "flex",
        justifyContent: "center",
    },
    bankSelectItem: {
        width: 340,
        marginBottom: theme.spacing(3),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    bankDropdown: {
        padding: theme.spacing(0.5),
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    bankDropdownLeft: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
    },
    bankDropdownText: {
        color: theme.palette.magicalGrey[400],
        fontSize: theme.typography.pxToRem(14),
    },
    bankDropdownIcon: {
        marginRight: theme.spacing(2),
        "& .MuiIcon-root, .MuiSvgIcon-root": {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.magicalGrey[300],
        },
    },
    bankDropdownAction: {
        backgroundColor: theme.palette.magicalGrey[400],
        color: theme.palette.neutral.white,
        borderRadius: theme.shape.radiusLevels[0],
        padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    },
    autocompleteInput: {
        "& .MuiInputBase-input": {
            color: theme.palette.white.contrastText,
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(16),
        },
    },
}));

const BankQRCodeBox = ({
    bankInfo,
    activeAmount,
    hiddenUserInfo,
    enableIBFT,
    enableSelectAccount,
    modifyAliasRequest,
    enableFullName,
    fetching,
    modifyAliasFetching,
}) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const [isAliasSelected, setAliasSelected] = useState(null);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const [disableSelectAccount, setDisableSelectAccount] = useState(false);

    const aliasList = useMemo(() => {
        if (bankInfo) {
            if (isEmpty(bankInfo?.aliasList) && bankInfo?.accountNumber) {
                const defaultData = [
                    {
                        alias: bankInfo?.accountNumber,
                        aliasType: "Account",
                        isDefault: true,
                        status: true,
                    },
                ];
                setDefaultAccount(defaultData[0]);
                setDisableSelectAccount(true);
                return defaultData;
            }
            return bankInfo?.aliasList;
        }
    }, [bankInfo]);

    useEffect(() => {
        if (bankInfo?.aliasList) {
            const defaultValue = aliasList?.find(
                (aliasItem) => aliasItem.isDefault
            );
            if (defaultValue) {
                setAliasSelected(defaultValue.alias);
            }
            setDefaultAccount(defaultValue);
        }
    }, [aliasList, bankInfo?.aliasList]);

    const handleChangeAccount = (data) => {
        if (data?.value && data?.value?.alias) {
            setAliasSelected(data?.value?.alias);
            const modifyAliasModel = { aliasId: data?.value?.id };
            modifyAliasRequest(modifyAliasModel);
        }
        setDefaultAccount(data?.value);
    };

    return (
        <>
            <div className={classes.header}>
                <div className={classes.headerIcon}>
                    <img
                        src={vietQRLogo}
                        alt="Viet QR Code Icon"
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>

            <VietQRCode
                accountNumber={isAliasSelected || bankInfo?.accountNumber}
                activeAmount={activeAmount}
                amount={bankInfo?.amount}
                description={bankInfo?.description}
                enableIBFT={enableIBFT}
            />
            <div className={classes.bankCodeLogo}>
                <BankQRCodeLogo />
            </div>

            {enableFullName && bankInfo && (
                <BankQRCodeInfo
                    bankInfo={bankInfo}
                    isActiveOnlyFullName={enableFullName}
                />
            )}

            {enableSelectAccount && aliasList?.length > 0 && (
                <div className={classes.bankSelectAccount}>
                    <div className={classes.bankSelectItem}>
                        <Autocomplete
                            options={aliasList || []}
                            disableClearable
                            onChange={(_, value) =>
                                handleChangeAccount({ value })
                            }
                            getOptionDisabled={(option) => !option.status}
                            renderOption={(option) => (
                                <>
                                    <div className={classes.bankDropdown}>
                                        <div
                                            className={classes.bankDropdownLeft}
                                        >
                                            <div
                                                className={
                                                    classes.bankDropdownIcon
                                                }
                                            >
                                                {option.aliasType ===
                                                ALIAS_TYPE.PHONE_NUMBER ? (
                                                    <Icon className="font-icon icon-phone" />
                                                ) : (
                                                    <Icon className="font-icon icon-timo" />
                                                )}
                                            </div>

                                            <LspTypography
                                                component="div"
                                                className={
                                                    classes.bankDropdownText
                                                }
                                                variant="heading3"
                                            >
                                                {option.alias}
                                            </LspTypography>
                                        </div>

                                        {!option?.status && (
                                            <LspTypography
                                                component="span"
                                                className={
                                                    classes.bankDropdownAction
                                                }
                                                variant="subheading4"
                                            >
                                                {t(
                                                    "IBFT:IBFT_QR_code_type_acc_label_inactive"
                                                )}
                                            </LspTypography>
                                        )}
                                    </div>
                                </>
                            )}
                            disabled={disableSelectAccount}
                            value={defaultAccount}
                            getOptionLabel={(option) => option?.alias || ""}
                            renderInput={(params) => {
                                const inputProps =
                                    fetching || modifyAliasFetching
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CircularProgress
                                                          size={16}
                                                      />
                                                  </InputAdornment>
                                              ),
                                          }
                                        : params.InputProps;
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t(
                                            "IBFT:IBFT_bankdetails_type_acc"
                                        )}
                                        InputProps={{
                                            ...inputProps,
                                            readOnly: true,
                                            className:
                                                classes.autocompleteInput,
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
            )}

            {!hiddenUserInfo && (
                <div className={classes.bankCodeInfo}>
                    <BankQRCodeInfo bankInfo={bankInfo} />
                </div>
            )}
            {activeAmount && (
                <div className={classes.bankCodeAmount}>
                    <BankQRCodeAmount bankInfo={bankInfo} />
                </div>
            )}
        </>
    );
};

BankQRCodeAmount.propTypes = {
    bankInfo: PropTypes.shape({
        accNumber: PropTypes.string,
        amount: PropTypes.number,
        bankName: PropTypes.string,
        bankShortName: PropTypes.string,
        description: PropTypes.string,
        reminded: PropTypes.bool,
        sender: PropTypes.bool,
        senderId: PropTypes.number,
        senderName: PropTypes.string,
        status: PropTypes.string,
        timoUser: PropTypes.bool,
    }),
    activeAmount: PropTypes.bool,
    hiddenUserInfo: PropTypes.bool,
    enableIBFT: PropTypes.bool,
    enableSelectAccount: PropTypes.bool,
    enableFullName: PropTypes.bool,
    onAccountChange: PropTypes.func,
    fetching: PropTypes.bool,
};

BankQRCodeAmount.defaultProps = {
    bankInfo: {},
    activeAmount: false,
    hiddenUserInfo: false,
    enableIBFT: false,
    enableSelectAccount: false,
    enableFullName: false,
    fetching: false,
};

const stateProps = (state) => ({
    modifyAliasFetching: state.user.modifyAlias.fetching,
});

const dispatchProps = (dispatch) => ({
    modifyAliasRequest: (payload) =>
        dispatch(userAction.modifyAliasRequest(payload)),
});
export default memo(connect(stateProps, dispatchProps)(BankQRCodeBox));
