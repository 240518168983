/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from "@components/LspIcon";
import LspTypography from "@components/LspTypography";
import { LANGUAGE_OPTIONS } from "@config/constants";
import useNumber from "@helpers/useNumber";
import {
    Box,
    ButtonBase,
    IconButton,
    Paper,
    makeStyles,
} from "@material-ui/core";
import { isFunction } from "lodash";
import { useState, useMemo, memo } from "react";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: 24,
        color: theme.palette.neutral.grey6,
    },
    wrapper: {
        padding: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        position: "relative",
    },
    introCloseBtn: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    cardImage: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    moreButton: {
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    moreInfo: {
        width: 311,
        "& ul": {
            paddingLeft: theme.spacing(3),
            // marginTop: theme.spacing(2),
        },
    },
    termCondition: {
        fontSize: theme.typography.pxToRem(12),
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },
}));

const PhysicalCardIntro = ({ data, onClose, onNext, cardImageUrl }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const [showDetail, setShowDetail] = useState(false);

    const detailInfo = useMemo(() => {
        const bodyContent = [];

        bodyContent.push({
            title: t("debitCard:phys_visa_intro_learn_more_title_1"),
            body:
                t("debitCard:phys_visa_intro_learn_more_body_1", {
                    returnObjects: true,
                }) || [],
        });
        bodyContent.push({
            title: t("debitCard:phys_visa_intro_learn_more_title_2"),
            body:
                t("debitCard:phys_visa_intro_learn_more_body_2", {
                    returnObjects: true,
                }) || [],
        });

        return bodyContent;
    }, [t]);

    const termConditionText = useMemo(
        () => t("debitCard:phys_visa_intro_tnc")?.replace("%@", ""),
        [t]
    );
    const termConditionLink = useMemo(
        () =>
            `${process.env.REACT_APP_API_URL}${t(
                "debitCard:phys_visa_intro_tnc_details"
            )}`,
        [t]
    );

    const feeAmount = useMemo(() => {
        return data?.annualFee ? formatNumber(data?.annualFee) : 0;
    }, [formatNumber, data]);

    const feeAmountText = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? `VND ${feeAmount}`
            : `${feeAmount} VND`;
    }, [i18n.language, feeAmount]);

    const onNextHandler = () => {
        if (onNext && isFunction(onNext)) {
            onNext();
        }
    };

    return (
        <Content size="sm">
            <Paper className={classes.wrapper}>
                <IconButton className={classes.introCloseBtn} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <LspTypography className={classes.header}>
                    {t("debitCard:phys_visa_intro_title")}
                </LspTypography>
                <img
                    src={cardImageUrl}
                    alt="card"
                    width="190"
                    className={classes.cardImage}
                />
                <LspTypography variant="title1">{feeAmountText}</LspTypography>
                <LspTypography variant="heading4">
                    {t("debitCard:phys_visa_intro_annual_fee_explain")?.replace(
                        "%@",
                        feeAmount
                    )}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {t("debitCard:phys_visa_intro_body")}
                </LspTypography>
                <ButtonBase
                    className={classes.moreButton}
                    onClick={() => setShowDetail((prev) => !prev)}
                >
                    <LspTypography variant="hyperlink2" color="success">
                        {t("debitCard:phys_visa_intro_learn_more")}
                    </LspTypography>
                    {!showDetail && <ArrowDownIcon color="secondary" />}
                    {showDetail && <ArrowUpIcon color="secondary" />}
                </ButtonBase>

                {showDetail &&
                    detailInfo?.map((content, index) => {
                        return (
                            <Box
                                className={classes.moreInfo}
                                marginTop={1}
                                key={`content-${index}`}
                            >
                                <LspTypography variant="subheading2">
                                    {content.title}
                                </LspTypography>
                                <ul>
                                    {Array.isArray(content?.body) &&
                                        content?.body?.map((body, index2) => (
                                            <li key={`body-${index2}`}>
                                                <LspTypography variant="body3">
                                                    {body}
                                                </LspTypography>
                                            </li>
                                        ))}
                                </ul>
                            </Box>
                        );
                    })}

                <Box className={classes.termCondition}>
                    <LspTypography variant="body3">
                        {termConditionText}
                    </LspTypography>
                    <a
                        href={termConditionLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {t("debitCard:phys_visa_intro_tnc_link")}
                        </LspTypography>
                    </a>
                </Box>

                <LspButton fullWidth onClick={onNextHandler}>
                    {t("debitCard:phys_visa_intro_CTA_get_card")}
                </LspButton>
            </Paper>
        </Content>
    );
};

export default memo(PhysicalCardIntro);
