import LspTypography from "@components/LspTypography";
import { Box, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

import { useTranslation } from "react-i18next";

const PaymentAmountSuggestionLoading = ({ classes }) => {
    const { t } = useTranslation();

    return (
        <Paper component={Box} p={2} marginTop={1} marginBottom={3}>
            <LspTypography variant="subheading3" color="grey">
                {t("lb_amount_web")}
            </LspTypography>

            {[...new Array(3)].map((_, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={classes.item} key={index}>
                        <div className={classes.itemLeft}>
                            <Skeleton width={24} height={24} />
                        </div>
                        <div className={classes.itemRight}>
                            <Skeleton width="30%" />
                            <Skeleton width="50%" />
                        </div>
                        <div>
                            <Skeleton width={100} />
                        </div>
                    </div>
                );
            })}
        </Paper>
    );
};

export default memo(PaymentAmountSuggestionLoading);
