import InvitationJoinedList from "./InvitationJoinedList";
import InvitationSetting from "./InvitationSetting";

const InvitationList = () => {
    return (
        <>
            <InvitationSetting />
            <InvitationJoinedList />
        </>
    );
};

export default InvitationList;
