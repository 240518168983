import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LspButton from "@components/LspButton";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import LspTextField from "@components/LspTextField";
import ColorBar from "@components/ColorBar";
import retrieveUsernameService from "@services/retrieve-username";
import clsx from "clsx";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import ContentModal from "@components/ContentModal";
import useSharedClasses from "@helpers/styleContentModal";

const RetrieveUserName = () => {
    const classes = useSharedClasses();
    const { t } = useTranslation();
    const history = useHistory();
    const [msgAfterSubmitted, setMsgAfterSubmitted] = useState("");
    const [statusAfterSubmitted, setStatusAfterSubmitted] = useState("");

    const goToLoginPage = useCallback(() => {
        history.push("/login");
    }, [history]);

    const defaultValues = {
        email: "",
    };

    const { register, handleSubmit, errors, formState } = useForm({
        defaultValues,
    });

    const retrieveClick = async (email) => {
        const { data } = await retrieveUsernameService.retrieveUserName(email);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setStatusAfterSubmitted("success");
                setMsgAfterSubmitted(t("retrieveUserName_lb_result_success"));
                break;
            case RESPONSE_CODE.INVALID:
                GlobalDialogController.showError({
                    errorCode: RESPONSE_CODE.FE_USER_BLOCKED,
                });
                break;
            case RESPONSE_CODE.NOT_ACCEPTABLE: // 406
                setStatusAfterSubmitted("error");
                setMsgAfterSubmitted(
                    t("retrieveUserName_lb_result_user_error")
                );
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    return (
        <ContentModal>
            <form
                className={classes.modalForm}
                onSubmit={handleSubmit(retrieveClick)}
            >
                <div className={classes.modalFormContent}>
                    <div className={classes.title}>
                        {t("retrieveUserName_title")}
                    </div>
                    {statusAfterSubmitted === "" && (
                        <div className={classes.desc}>
                            {t("ms_lb_desc_retrieve_username")}
                        </div>
                    )}
                    {statusAfterSubmitted !== "success" && (
                        <LspTextField
                            autoFocus
                            name="email"
                            error={!!errors.email}
                            label={t("lb_hint_email")}
                            helperText={errors.email?.message || " "}
                            inputProps={{
                                ref: register({
                                    required: t("msg_we_need_this"),
                                    pattern: {
                                        value: REGEX_PATTERN.EMAIL,
                                        message: t("ms_lb_email_invalid"),
                                    },
                                }),
                            }}
                        />
                    )}

                    {statusAfterSubmitted !== "" && (
                        <div
                            className={clsx(classes.succeedMsg, {
                                [classes.errorMsg]:
                                    statusAfterSubmitted === "error",
                            })}
                        >
                            <span>{msgAfterSubmitted}</span>
                        </div>
                    )}

                    {statusAfterSubmitted !== "success" && (
                        <>
                            <LspButton
                                type="submit"
                                progressing={formState.isSubmitting}
                                className={classes.submitButton}
                                fullWidth
                            >
                                {t("lb_next")}
                            </LspButton>
                            <div className={classes.extraActions}>
                                <Link
                                    onClick={goToLoginPage}
                                    color="secondary"
                                    className={classes.extraActionItem}
                                >
                                    {t("login_reset_password_back_btn")}
                                </Link>
                            </div>
                        </>
                    )}

                    {statusAfterSubmitted === "success" && (
                        <LspButton
                            onClick={goToLoginPage}
                            type="submit"
                            className={classes.submitButton}
                            fullWidth
                        >
                            {t("login_reset_password_back_btn")}
                        </LspButton>
                    )}
                </div>
                <ColorBar className={classes.colorBar} />
            </form>
        </ContentModal>
    );
};

export default RetrieveUserName;
