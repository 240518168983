import { useMemo, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import { Box, ButtonBase, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    AccountType,
    ALIAS_TYPE,
    BalanceVisibility,
    RESPONSE_CODE,
} from "@config/constants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import accountAction from "@redux/actions/account";
import Content from "@components/Content";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
// import useOTP from "@helpers/useOTP";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import IntroduceBooking from "@containers/Booking/IntroduceBooking";
import useAccounts from "@helpers/useAccounts";
import termDepositAction from "@redux/actions/term-deposit";
import { isEmpty } from "lodash";
import AccountCard from "./AccountCard";
import PendingTaskBar from "./PendingTaskBar";
import QuickActions from "./QuickActions";
import HiddenBalanceHintBar from "./HiddenBalanceHintBar";

const Home = ({
    user,
    getAccountList,
    accountList,
    accountListFetching,
    getPayAnyoneDashboard,
    totalPendingPayAnyone,
    eKYCMonthlyLimitation,
    featureState,
    termDepositRate,
    getTermRates,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { allowAccessCredit, isBalanceHidden } = useAccounts();

    const creditArrays = [AccountType.CreditCardOnline, AccountType.CreditCard];
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        if (isEmpty(termDepositRate)) {
            getTermRates();
        }
    }, [getTermRates, termDepositRate]);

    useEffect(() => {
        getAccountList();
    }, [getAccountList]);

    useEffect(() => {
        getPayAnyoneDashboard();
    }, [getPayAnyoneDashboard]);

    const isEKYCBasic = useMemo(
        () => user?.currentOnboardingType === "eKYCBasic",
        [user]
    );

    const monthlyLimitationNotice = useMemo(() => {
        if (!isEKYCBasic) {
            return null;
        }

        if (!eKYCMonthlyLimitation) {
            return null;
        }
        // eKYCLimitPerMonth
        return t("member:onboard_you_have_limit").replace(
            "%@",
            formatNumber(eKYCMonthlyLimitation)
        );
    }, [t, isEKYCBasic, formatNumber, eKYCMonthlyLimitation]);

    const showHiddenBalanceHint = useMemo(() => {
        return user.hideBalance === BalanceVisibility.Hide;
    }, [user]);

    const handleFeatureNotSupportWarning = useCallback(() => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Warning",
                header: "oops",
                content: "msg_feature_not_available_on_web",
                button: "lb_ok",
            },
        });
    }, []);

    const noBalance = useCallback((accountType) => {
        switch (accountType) {
            case AccountType.SplitBill:
                return true;
            default:
                return false;
        }
    }, []);

    const accountOnClick = useCallback(
        (accountType) => {
            switch (accountType) {
                case AccountType.SpendAccount:
                    history.push("/spend-account");
                    break;
                case AccountType.TermDeposit:
                    history.push("/term-deposit");
                    break;
                case AccountType.CreditCard:
                    history.push("/credit-card/transactions");
                    break;
                case AccountType.GoalSave:
                    history.push("/goal-save");
                    break;
                case AccountType.MoneyPot:
                case AccountType.SplitBill:
                case AccountType.SchedulePaymnet:
                    handleFeatureNotSupportWarning();
                    break;
                default:
                    break;
            }
        },
        [history, handleFeatureNotSupportWarning]
    );

    const subActions = useMemo(() => {
        let spendAccountActionLabel = "";
        if (totalPendingPayAnyone === 1) {
            spendAccountActionLabel = t("payAnyone:dashboard_payment").replace(
                "%@",
                totalPendingPayAnyone
            );
        } else if (totalPendingPayAnyone > 1) {
            spendAccountActionLabel = t("payAnyone:dashboard_payment2").replace(
                "%@",
                totalPendingPayAnyone
            );
        }
        return {
            [AccountType.SpendAccount]: spendAccountActionLabel
                ? {
                      name: t("payAnyone:dashboard_pending_payment"),
                      actionLabel: spendAccountActionLabel,
                      onClick: () => {
                          history.push("/spend-account/pending-payment");
                      },
                  }
                : null,
        };
    }, [t, totalPendingPayAnyone, history]);

    // const { opened, setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const ekycLimitationMoreDetail = () => {
        GlobalDialogController.show({
            component: () => (
                <IntroduceBooking
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    const isShowAccountItem = (account) => {
        const isCreditCard = creditArrays.find(
            (item) => item === account?.accountType
        );
        if (!!isCreditCard && !allowAccessCredit) {
            return false;
        }

        return true;
    };

    const handleSelectNo = (accountData) => {
        if (
            accountData?.accountType === AccountType.SpendAccount &&
            featureState?.enableAliasPhase2
        ) {
            switch (accountData?.aliasType) {
                case ALIAS_TYPE.ALIAS_NICKNAME:
                    if (!isSmUp) {
                        return accountData?.aliasName;
                    }
                    return `${t("IBFT:IBFT_bankdetails_fast_nickname")}: ${
                        accountData?.aliasName
                    }`;
                case ALIAS_TYPE.PHONE_NUMBER:
                    if (!isSmUp) {
                        return accountData?.aliasName;
                    }
                    return `${t("IBFT:IBFT_home_phone_acc_number")}: ${
                        accountData?.aliasName
                    }`;
                default:
                    if (!isSmUp) {
                        return accountData?.no;
                    }
                    return `${t("lb_account_number")}: ${accountData?.no}`;
            }
        }
        return accountData?.no;
    };

    return (
        <>
            <Banner title={user?.fullName} />
            <ContentWrapper>
                <Content>
                    <Box mb={3}>
                        <PendingTaskBar />
                        {showHiddenBalanceHint && <HiddenBalanceHintBar />}
                    </Box>
                    {!!monthlyLimitationNotice && (
                        <Box mb={3} display="flex">
                            <LspTypography>
                                {monthlyLimitationNotice}
                            </LspTypography>
                            <ButtonBase
                                style={{ marginLeft: 4 }}
                                onClick={ekycLimitationMoreDetail}
                            >
                                <LspTypography
                                    variant="hyperlink1"
                                    color="success"
                                >
                                    {t("member:onboard_learn_more")}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}
                    <Box mb={3}>
                        <QuickActions />
                    </Box>
                    {accountListFetching &&
                        !accountList &&
                        [...new Array(4)].map((_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <AccountCard key={index} loading />
                        ))}
                    {accountList?.map((acc) => {
                        return (
                            <Fragment key={acc?.accountType}>
                                {isShowAccountItem(acc) && (
                                    <AccountCard
                                        {...acc}
                                        onClick={() =>
                                            accountOnClick(acc.accountType)
                                        }
                                        direct={
                                            acc.accountType ===
                                                AccountType.SpendAccount &&
                                            featureState?.enableAliasPhase2
                                        }
                                        link="/bank-information"
                                        no={handleSelectNo(acc)}
                                        subAction={subActions[acc.accountType]}
                                        noBalance={noBalance(acc.accountType)}
                                        hiddenBalance={isBalanceHidden}
                                        termDepositRate={termDepositRate}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                    {/* <button
                        type="button"
                        onClick={() => {
                            openOTPDialog({
                                dialogTitle: "Title",
                                dialogSubtitle: "Subtitle",
                                // dialogDescription: "Description",
                                refNo: "123123",
                                submitFunc: ({ otp, refNo }) => {
                                    setLoading(true);
                                    console.log({ otp, refNo });
                                },
                                resendFunc: ({ refNo }) => {
                                    console.log({ refNo });
                                },
                            });
                        }}
                    >
                        Open OTP
                    </button> */}
                </Content>
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info || {},
    accountList: state.account.list,
    accountListFetching: state.account.fetching,
    totalPendingPayAnyone: state.account.payAnyoneDashboard.totalNumberPending,
    eKYCMonthlyLimitation: state.systemParams.info?.conf?.eKYCLimitPerMonth,
    featureState: state.user.featureState.data,
    termDepositRate: state.termDeposit.termRates.data,
});

const mapDispatch = (dispatch) => ({
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
    getPayAnyoneDashboard: () =>
        dispatch(accountAction.getPayAnyoneDashboardRequest()),
    getTermRates: () => dispatch(termDepositAction.getTermDepositRates()),
});

export default connect(mapState, mapDispatch)(Home);
