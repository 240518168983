import { ButtonBase, makeStyles } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import initial from "lodash/initial";
import Content from "@components/Content";

import AddNewPhoneScreen from "src/containers/SpendAccount/TopUp/AddNewPhoneScreen";
import topUpAction from "@redux/actions/top-up";
import LspReceipt from "@components/LspReceipt";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { ArrowLeftIcon } from "@components/LspIcon";
import useAccounts from "@helpers/useAccounts";
import TopUpReview from "./TopupReview";
import ExistingPhoneScreen from "./ExistingPhoneScreen";
import useTransactionValidation from "../useTransactionValidation";

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    iconWrapper: {
        position: "relative",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    headerIcon: {
        position: "absolute",
        transform: `rotate(35deg) translateY(-50%)`,
        right: theme.spacing(-4),
        top: theme.spacing(1.5),
        fontSize: `${theme.typography.pxToRem(34)} !important`,
    },
    bodyWrapper: {
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3, 3, 2, 3),
        borderRadius: theme.shape.radiusLevels[1],
    },
    back: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
}));

const TopUp = ({ getPhoneConfigs, updatePhoneNumber, reset, receipt }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { isOverDraftUser } = useAccounts();
    const { warningUsingOverdraftMoneyDialog } = useTransactionValidation();

    const steps = useMemo(() => {
        return ["EXISTING_PHONE", "ADD_NEW_PHONE", "REVIEW", "RECEIPT"];
    }, []);
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const [prevSteps, setPrevSteps] = useState([steps[0]]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, [reset]);

    const backPrevious = () => {
        if (prevSteps.length > 0) {
            setCurrentStep(prevSteps[prevSteps.length - 2]);
            setPrevSteps(initial(prevSteps));
        }
    };

    const nextStep = (step) => {
        if (step) {
            setPrevSteps([...prevSteps, step]);
            setCurrentStep(step);
        } else {
            switch (currentStep) {
                case steps[0]:
                case steps[1]:
                    if (isOverDraftUser) {
                        warningUsingOverdraftMoneyDialog(() => {
                            setPrevSteps([...prevSteps, currentStep]);
                            setCurrentStep(steps[2]);
                        });
                        return;
                    }

                    setPrevSteps([...prevSteps, currentStep]);
                    setCurrentStep(steps[2]);
                    break;
                case steps[2]:
                    setPrevSteps([...prevSteps, currentStep]);
                    setCurrentStep(steps[3]);
                    break;
                default:
                    break;
            }
        }
    };

    const addNewPhoneScreen = () => {
        updatePhoneNumber("");
        nextStep(steps[1]);
    };

    useEffect(() => {
        getPhoneConfigs();
    }, [getPhoneConfigs]);

    const topUpDashboard = () => {
        reset();
        setCurrentStep(steps[0]);
        setPrevSteps([steps[0]]);
        getPhoneConfigs();
    };

    const goHomePage = () => {
        history.push("/home");
    };

    return (
        <>
            {currentStep === "EXISTING_PHONE" && (
                <ExistingPhoneScreen
                    parentClasses={classes}
                    nextHandler={nextStep}
                    switchAddPhone={addNewPhoneScreen}
                />
            )}

            {currentStep === "ADD_NEW_PHONE" && (
                <AddNewPhoneScreen
                    backPrevious={backPrevious}
                    parentClasses={classes}
                    nextHandler={nextStep}
                />
            )}
            {currentStep === "REVIEW" && (
                <TopUpReview
                    parentClasses={classes}
                    backPrevious={backPrevious}
                    nextStep={nextStep}
                />
            )}
            {currentStep === "RECEIPT" && (
                <Content size="sm">
                    <div className={classes.bodyWrapper} style={{ padding: 0 }}>
                        <LspReceipt
                            onClose={goHomePage}
                            rawReceipt={receipt}
                            button={t("lb_done")}
                        />
                    </div>
                    <ButtonBase
                        onClick={topUpDashboard}
                        className={classes.back}
                    >
                        <ArrowLeftIcon />
                        <LspTypography variant="body1" color="grey">
                            {t("topup_btn_return_topup")}
                        </LspTypography>
                    </ButtonBase>
                </Content>
            )}
        </>
    );
};

const stateProps = (state) => ({
    submitting: state.topUp.fetching,
    receipt: state.topUp.info,
});

const dispatchProps = (dispatch) => ({
    topUpRequest: (payload) => dispatch(topUpAction.topUpWithOTP(payload)),
    getPhoneConfigs: () => dispatch(topUpAction.getPhoneConfigs()),
    updatePhoneNumber: (payload) =>
        dispatch(topUpAction.updatePhoneNumber(payload)),
    reset: () => dispatch(topUpAction.reset()),
});

export default connect(stateProps, dispatchProps)(TopUp);
