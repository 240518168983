import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { CardManagementActionTypes } from "../actions/card-management";

/* ------------- Initial State ------------- */
const initialState = {
    cardList: null,
    cardListStatus: null,
    fetchingCardList: false,
    cardStatusUpdating: false,
    eCommerceSUpdating: false,
    refNo: null,
    cardId: null,
    cardAction: null,
    cardType: null,
    otpFetching: false,
    resetPinLoading: false,
    hangoutList: null,
    hangoutListLoading: false,
    reIssueCardLoading: false,
    reIssueCardFetchStatus: null,
    bankXID: null,
    checkCardNumberLoading: false,
    checkCardNumberStatus: null,
    activateCardLoading: false,
    activateCardStatus: null,
    reissuedCard: null,
    version: "v1",
    address: {
        fetching: false,
        status: null,
        data: null,
    },
    masks: {
        fetching: false,
        status: null,
        data: {},
        cancelOTPRequest: false,
    },
    cardLimitRequest: {
        fetching: false,
        status: null,
        data: null,
    },
    cardLimit: {
        fetching: false,
        status: null,
        data: null,
    },
    disableDefaultPopup: false,
    reviewRequestCardData: null,
};

/* ------------- Reducer ------------- */
const cardManagementReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CardManagementActionTypes.GetCardList:
                draft.fetchingCardList = true;
                draft.cardListStatus = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.GetCardListSuccess:
                draft.fetchingCardList = false;
                draft.cardListStatus = FetchStatus.Success;
                draft.cardList = action.payload;
                break;
            case CardManagementActionTypes.GetCardListError:
                draft.fetchingCardList = false;
                break;
            case CardManagementActionTypes.ResetGetCardListStatus:
                draft.cardListStatus = null;
                break;
            case CardManagementActionTypes.UpdateCardStatus:
                draft.cardStatusUpdating = true;
                break;
            case CardManagementActionTypes.UpdateCardStatusSuccess:
                draft.cardStatusUpdating = false;
                break;
            case CardManagementActionTypes.UpdateCardStatusError:
                draft.cardStatusUpdating = false;
                break;
            case CardManagementActionTypes.OTPRequired:
                const {
                    refNo,
                    cardId,
                    cardAction,
                    cardType,
                    version,
                } = action.payload;
                draft.refNo = refNo;
                draft.cardId = cardId;
                draft.cardAction = cardAction;
                draft.cardType = cardType;
                draft.version = version;
                break;
            case CardManagementActionTypes.OTPCommit:
            case CardManagementActionTypes.OTPCommitResetPin:
            case CardManagementActionTypes.OTPCommitReIssueCard:
            case CardManagementActionTypes.OTPCommitCardActivation:
                draft.otpFetching = true;
                break;
            case CardManagementActionTypes.OTPCommitFinished:
                draft.otpFetching = false;
                break;
            case CardManagementActionTypes.OTPCancel:
                draft.cardStatusUpdating = false;
                draft.eCommerceSUpdating = false;
                draft.resetPinLoading = false;
                draft.reIssueCardLoading = false;
                draft.refNo = null;
                draft.cardId = null;
                draft.cardAction = null;
                draft.cardType = null;
                draft.otpFetching = false;
                draft.bankXID = false;
                draft.checkCardNumberLoading = false;
                draft.checkCardNumberStatus = null;
                draft.activateCardLoading = false;
                draft.activateCardStatus = null;
                break;
            case CardManagementActionTypes.UpdateECommerce:
                draft.eCommerceSUpdating = true;
                break;
            case CardManagementActionTypes.UpdateECommerceSuccess:
                draft.eCommerceSUpdating = false;
                break;
            case CardManagementActionTypes.UpdateECommerceError:
                draft.eCommerceSUpdating = false;
                break;
            case CardManagementActionTypes.ResetPin:
                draft.resetPinLoading = true;
                break;
            case CardManagementActionTypes.ResetPinSuccess:
                draft.resetPinLoading = false;
                break;
            case CardManagementActionTypes.ResetPinError:
                draft.resetPinLoading = false;
                break;

            case CardManagementActionTypes.GetHangoutListReIssue:
                draft.hangoutListLoading = true;
                break;
            case CardManagementActionTypes.GetHangoutListReIssueSuccess:
                draft.hangoutList = action.payload;
                draft.hangoutListLoading = false;
                break;
            case CardManagementActionTypes.GetHangoutListReIssueError:
                draft.hangoutListLoading = false;
                break;
            case CardManagementActionTypes.ReIssueCard:
                draft.reIssueCardLoading = true;
                draft.reIssueCardFetchStatus = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.ReIssueCardSuccess:
                draft.reIssueCardLoading = false;
                draft.reIssueCardFetchStatus = FetchStatus.Success;
                draft.bankXID = action.payload;
                break;
            case CardManagementActionTypes.ReIssueCardError:
                const isEnoughMoney = action.payload;
                draft.reIssueCardLoading = false;
                if (isEnoughMoney) {
                    draft.reIssueCardFetchStatus = FetchStatus.Error;
                }
                break;
            case CardManagementActionTypes.ReIssueCardV2:
                draft.reIssueCardLoading = true;
                draft.reIssueCardFetchStatus = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.CheckCardNumber:
                draft.checkCardNumberLoading = true;
                draft.checkCardNumberStatus = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.CheckCardNumberSuccess:
                draft.checkCardNumberLoading = false;
                draft.checkCardNumberStatus = FetchStatus.Success;
                break;
            case CardManagementActionTypes.CheckCardNumberError:
                draft.checkCardNumberLoading = false;
                draft.checkCardNumberStatus = FetchStatus.Error;
                break;
            case CardManagementActionTypes.ResetStatus:
                draft.checkCardNumberStatus = null;
                draft.activateCardStatus = null;
                break;
            case CardManagementActionTypes.CardActivation:
                draft.activateCardLoading = true;
                draft.activateCardStatus = FetchStatus.Pending;
                draft.disableDefaultPopup = action.payload?.disableDefaultPopup;
                break;
            case CardManagementActionTypes.CardActivationSuccess:
                draft.activateCardLoading = false;
                draft.activateCardStatus = FetchStatus.Success;
                break;
            case CardManagementActionTypes.CardActivationError:
                draft.activateCardLoading = false;
                draft.activateCardStatus = FetchStatus.Error;
                break;
            case CardManagementActionTypes.GetReissuedCard:
                draft.reissuedCard = action.payload;
                break;
            case CardManagementActionTypes.ModifyReissuedCard:
                draft.reissuedCard = {
                    ...draft.reissuedCard,
                    ...action.payload,
                };
                break;
            case CardManagementActionTypes.GetUserAddressRequest:
                draft.address.fetching = true;
                draft.address.status = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.GetUserAddressSuccess:
                draft.address.fetching = false;
                draft.address.status = FetchStatus.Success;
                draft.address.data = action.payload;
                break;
            case CardManagementActionTypes.GetUserAddressError:
                draft.address.fetching = false;
                draft.address.status = FetchStatus.Error;
                break;
            case CardManagementActionTypes.ResetStateNewReissue:
                const allState = Object.keys(initialState);
                const states = allState.filter(
                    (i) =>
                        i !== "cardList" &&
                        i !== "cardListStatus" &&
                        i !== "masks" &&
                        i !== "reviewRequestCardData"
                );
                states.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            case CardManagementActionTypes.ResetState:
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            case CardManagementActionTypes.GetMaskRequest:
                draft.masks.cancelOTPRequest = false;
                draft.masks.fetching = true;
                draft.masks.status = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.GetMaskSuccess:
                draft.masks.fetching = false;
                draft.masks.status = FetchStatus.Success;
                break;
            case CardManagementActionTypes.GetMaskError:
                draft.masks.fetching = false;
                draft.masks.status = FetchStatus.Error;
                break;
            case CardManagementActionTypes.ClearMaskData:
                draft.masks.data[action.payload.cardId] = {};
                break;
            case CardManagementActionTypes.GetMaskOTPRequest:
                draft.masks.otpFetching = true;
                draft.masks.otpStatus = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.GetMaskOTPSuccess:
                draft.masks.otpFetching = false;
                draft.masks.otpStatus = FetchStatus.Success;
                const id = action.payload.cardId;
                draft.masks.data[id] = action.payload;
                break;
            case CardManagementActionTypes.GetMaskOTPError:
                draft.masks.otpFetching = false;
                draft.masks.otpStatus = FetchStatus.Error;
                break;
            case CardManagementActionTypes.CancelGetMaskOTP:
                draft.masks.cancelOTPRequest = true;
                draft.masks.otpStatus = FetchStatus.Cancel;
                break;

            case CardManagementActionTypes.CardLimitRequest:
                draft.cardLimit.fetching = true;
                draft.cardLimit.status = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.CardLimitSuccess:
                draft.cardLimit.fetching = false;
                draft.cardLimit.status = FetchStatus.Success;
                draft.cardLimit.data = action.payload;
                break;
            case CardManagementActionTypes.CardLimitError:
                draft.cardLimit.fetching = false;
                draft.cardLimit.status = FetchStatus.Error;
                break;
            case CardManagementActionTypes.ResetCardLimit:
                draft.cardLimit.fetching = false;
                draft.cardLimit.status = null;
                draft.cardLimit.data = null;

                draft.cardLimitRequest.fetching = false;
                draft.cardLimitRequest.status = null;
                draft.cardLimitRequest.data = null;
                break;
            case CardManagementActionTypes.GetCardLimitRequest:
                draft.cardLimitRequest.fetching = true;
                draft.cardLimitRequest.status = FetchStatus.Pending;
                break;
            case CardManagementActionTypes.GetCardLimitSuccess:
                draft.cardLimitRequest.fetching = false;
                draft.cardLimitRequest.status = FetchStatus.Success;
                draft.cardLimitRequest.data = action.payload;
                break;
            case CardManagementActionTypes.GetCardLimitError:
                draft.cardLimitRequest.fetching = false;
                draft.cardLimitRequest.status = FetchStatus.Error;
                break;
            case CardManagementActionTypes.SetReviewDataRequestCard:
                draft.reviewRequestCardData = action.payload;
                break;
            default:
                break;
        }
    });

export default cardManagementReducer;
