/* ------------- Types Definition ------------- */
export const WealthInsuranceActionType = Object.freeze({
    SetDestination: "WealthInsuranceActionType/SetDestination",
    SetTravelDate: "WealthInsuranceActionType/SetTravelDate",
    SetAttendantInfo: "WealthInsuranceActionType/SetAttendantInfo",
    SetPackageList: "WealthInsuranceActionType/SetPackageList",
    SetSelectedPackage: "WealthInsuranceActionType/SetSelectedPackage",

    SetOwnerPolicy: "WealthInsuranceActionType/SetOwnerPolicy",
    SetDependentPolicy: "WealthInsuranceActionType/SetDependentPolicy",
    SetDependentPolicyForm: "WealthInsuranceActionType/SetDependentPolicyForm",
    Reset: "WealthInsuranceActionType/Reset",
});

/* ------------- Actions Definition ------------- */

const setDestination = (payload) => {
    return {
        type: WealthInsuranceActionType.SetDestination,
        payload,
    };
};

const setTravelDate = (payload) => {
    return {
        type: WealthInsuranceActionType.SetTravelDate,
        payload,
    };
};
const setAttendantInfo = (payload) => {
    return {
        type: WealthInsuranceActionType.SetAttendantInfo,
        payload,
    };
};
const setPackageList = (payload) => {
    return {
        type: WealthInsuranceActionType.SetPackageList,
        payload,
    };
};
const setSelectedPackage = (payload) => {
    return {
        type: WealthInsuranceActionType.SetSelectedPackage,
        payload,
    };
};
const setOwnerPolicy = (payload) => {
    return {
        type: WealthInsuranceActionType.SetOwnerPolicy,
        payload,
    };
};
const setDependentPolicy = (payload) => {
    return {
        type: WealthInsuranceActionType.SetDependentPolicy,
        payload,
    };
};

const setDependentPolicyForm = (payload) => {
    return {
        type: WealthInsuranceActionType.SetDependentPolicyForm,
        payload,
    };
};
const reset = (payload) => {
    return {
        type: WealthInsuranceActionType.Reset,
        payload,
    };
};

const wealthInsuranceAction = {
    setDestination,
    setTravelDate,
    setAttendantInfo,
    setPackageList,
    setSelectedPackage,
    setOwnerPolicy,
    setDependentPolicy,
    setDependentPolicyForm,
    reset,
};

export default wealthInsuranceAction;
