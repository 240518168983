/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import { useParams, useHistory } from "react-router-dom";

import { useEffect, useCallback, useState, useMemo, memo } from "react";
import overdraftService from "@services/overdraft";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";

import { OVERDRAFT_STATUS, RESPONSE_CODE } from "@config/constants";
import { makeStyles, Grid, Icon } from "@material-ui/core";
import useNumber from "@helpers/useNumber";
import clsx from "clsx";
import LspChecker from "@components/LspChecker";
import LspButton from "@components/LspButton";
import useOTP from "@helpers/useOTP";
import tommySuccess2 from "@assets/images/tommy_success_status_2.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import querystring from "query-string";
import tommyNoHope from "@assets/images/tommy_no_hope.png";
import tommyNoHopeStatus from "@assets/images/no_hope_with_status_error.png";
import { Skeleton } from "@material-ui/lab";
import { connect } from "react-redux";
import accountAction from "@redux/actions/account";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OverdraftStatus from "./OverdraftStatus";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(3),
    },
    infoTitle: {
        marginBottom: theme.spacing(2),
    },
    termAndCondition: {},
    termAndConditionContent: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    termAndConditionTitle: {
        marginBottom: theme.spacing(1),
    },
    termAndConditionInfo: { flex: 1 },
    termAndConditionAmount: {
        display: "flex",
        alignItems: "center",
    },
    termAndConditionFooter: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    termAndConditionAction: {},
    termAndConditionStatus: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: theme.spacing(4),
    },
    termAndConditionStatusIMG: {
        width: 180,
        marginBottom: theme.spacing(5),
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
}));

const OVERDRAFT_VIEW_MODE = {
    PREVIEW: "preview",
    STATUS: "status",
};

const OverdraftPreview = ({ getAccountList }) => {
    const { t } = useTranslation();
    const [overdraftData, setOverdraftData] = useState({});
    const { formatNumber } = useNumber();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const history = useHistory();

    const classes = useStyles();

    const [viewMode, setViewMode] = useState(OVERDRAFT_VIEW_MODE.PREVIEW);
    const [isLoading, setIsLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const location = useLocation();
    const queryParams = querystring.parse(location.search);

    const { odStatus } = queryParams;

    const { overdraftId } = useParams();

    const handleGetOverdraftPreview = useCallback(async ({ termId }) => {
        setIsLoading(true);
        const result = await overdraftService.getOverdraftPreview({ termId });

        const { data, code } = result?.data;

        setIsLoading(false);

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setOverdraftData(data);
                break;

            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, []);

    useEffect(() => {
        if (odStatus === OVERDRAFT_STATUS.PROCESSING) {
            setViewMode(OVERDRAFT_VIEW_MODE.STATUS);
            setStatus(RESPONSE_CODE.OVERDRAFT_REQUEST_PROCESSING_CODE);
            return;
        }
        if (overdraftId) {
            handleGetOverdraftPreview({ termId: overdraftId });
        }
    }, [handleGetOverdraftPreview, odStatus, overdraftId]);

    const overdraftPreview = useMemo(() => {
        return t(
            "overdraft:overdraft_review_notes",

            {
                returnObjects: true,
                defaultValue: [],
            }
        );
    }, [t]);

    const overdraftPreviewSuccess = useMemo(() => {
        return t(
            "overdraft:overdraft_result_success_notes",

            {
                returnObjects: true,
                defaultValue: [],
            }
        );
    }, [t]);

    const createOverdraftResponseHandler = useCallback(
        (code) => {
            switch (code) {
                case RESPONSE_CODE.INCORRECT_VALUE:
                case RESPONSE_CODE.MISMATCH_VALUE:
                case RESPONSE_CODE.OTP_EXPIRED:
                    GlobalDialogController.showError({
                        errorCode: code,
                    });
                    break;
                case RESPONSE_CODE.SUCCESS:
                case RESPONSE_CODE.OVERDRAFT_REQUEST_PROCESSING_CODE:
                    setStatus(code);
                    setViewMode(OVERDRAFT_VIEW_MODE.STATUS);
                    closeOTPDialog();
                    getAccountList();
                    break;
                case RESPONSE_CODE.TECHNICAL_DIFFICULT:
                case RESPONSE_CODE.BAD_REQUEST:
                    setStatus(code);
                    setViewMode(OVERDRAFT_VIEW_MODE.STATUS);
                    closeOTPDialog();
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [closeOTPDialog, getAccountList]
    );

    const commitOTP = useCallback(
        async (payload) => {
            setLoading(true);
            const result = await overdraftService.commitOTP(payload);

            setLoading(false);

            const { code } = result?.data;

            createOverdraftResponseHandler(code);
        },
        [setLoading, createOverdraftResponseHandler]
    );

    const handleSubmit = useCallback(async () => {
        const params = {
            limitAmount: overdraftData?.approvalAmount,
            termId: overdraftData?.termDepositInfo?.termId,
        };

        setSubmitLoading(true);
        const result = await overdraftService.applyOTP(params);

        const { data, code } = result?.data;

        setSubmitLoading(false);

        switch (code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({ refNo: data?.refNo, submitFunc: commitOTP });
                break;

            default:
                createOverdraftResponseHandler(code);
                break;
        }
    }, [
        createOverdraftResponseHandler,
        commitOTP,
        openOTPDialog,
        overdraftData?.approvalAmount,
        overdraftData?.termDepositInfo?.termId,
    ]);

    const handleSubmitStatus = useCallback(() => {
        history.push("/home");
    }, [history]);

    const overdraftStatus = useMemo(() => {
        switch (status) {
            case RESPONSE_CODE.SUCCESS:
                return (
                    <>
                        <OverdraftStatus
                            src={tommySuccess2}
                            title={
                                <>
                                    <LspTypography variant="title2">
                                        {t(
                                            "overdraft:overdraft_result_success_title"
                                        )}
                                    </LspTypography>
                                    <LspTypography variant="body2" color="grey">
                                        {t(
                                            "overdraft:overdraft_result_success_description"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            content={
                                <>
                                    <LspTypography
                                        variant="subheading2"
                                        textCenter
                                        className={classes.infoTitle}
                                    >
                                        {t(
                                            "overdraft:overdraft_result_success_note_title"
                                        )}
                                    </LspTypography>
                                    {overdraftPreviewSuccess.map((item) => (
                                        <LspChecker
                                            label={item}
                                            emptyIcon={
                                                <Icon fontSize="small">👉</Icon>
                                            }
                                        />
                                    ))}
                                </>
                            }
                            action
                            actionTitle={t(
                                "overdraft:overdraft_result_success_cta"
                            )}
                            onSubmit={handleSubmitStatus}
                        />
                    </>
                );
            case RESPONSE_CODE.OVERDRAFT_REQUEST_PROCESSING_CODE:
                return (
                    <>
                        <OverdraftStatus
                            src={tommyNoHope}
                            title={
                                <>
                                    <LspTypography variant="title2">
                                        {t(
                                            "overdraft:overdraft_result_processing_title"
                                        )}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        textCenter
                                    >
                                        {t(
                                            "overdraft:overdraft_result_processing_description"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            action
                            actionTitle={t(
                                "overdraft:overdraft_result_processing_cta"
                            )}
                            onSubmit={handleSubmitStatus}
                        />
                    </>
                );

            default:
                return (
                    <>
                        <OverdraftStatus
                            src={tommyNoHopeStatus}
                            title={
                                <>
                                    <LspTypography variant="title2">
                                        {t(
                                            "overdraft:overdraft_result_failure_title"
                                        )}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body2"
                                        color="grey"
                                        textCenter
                                    >
                                        {t(
                                            "overdraft:overdraft_result_failure_description"
                                        )}
                                    </LspTypography>
                                </>
                            }
                            action
                            actionTitle={t(
                                "overdraft:overdraft_result_failure_cta"
                            )}
                            onSubmit={handleSubmitStatus}
                        />
                    </>
                );
        }
    }, [
        classes.infoTitle,
        handleSubmitStatus,
        overdraftPreviewSuccess,
        status,
        t,
    ]);

    return (
        <Content>
            {OVERDRAFT_VIEW_MODE.PREVIEW === viewMode && (
                <div className={classes.container}>
                    <div className={classes.title}>
                        <LspTypography variant="body3" textCenter>
                            {t("overdraft:overdraft_limit")}
                        </LspTypography>
                        <LspTypography variant="title2" textCenter>
                            {formatNumber(overdraftData?.approvalAmount)}
                        </LspTypography>
                    </div>
                    {!isLoading && (
                        <div className={classes.info}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_review_created_date"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LspTypography
                                        variant="body3"
                                        color="grey"
                                        textRight
                                    >
                                        {overdraftData?.effectiveDate}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_review_close_date"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LspTypography
                                        variant="body3"
                                        color="grey"
                                        textRight
                                    >
                                        {overdraftData?.maturityDate}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_expense_settlement_date"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LspTypography
                                        variant="body3"
                                        color="grey"
                                        textRight
                                    >
                                        {overdraftData?.expenseSettlementDate}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_review_interest_rate"
                                        )}
                                    </LspTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LspTypography
                                        variant="body3"
                                        color="grey"
                                        textRight
                                    >
                                        {t(
                                            "overdraft:overdraft_daily_annual_interest_rate"
                                        )
                                            .replace(
                                                "%@_dailyInterestRate",
                                                overdraftData?.dailyInterestRate
                                            )
                                            .replace(
                                                "%@_annualInterestRate",
                                                `${overdraftData?.annualInterestRate}`
                                            )}
                                    </LspTypography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {isLoading && (
                        <Skeleton
                            variant="rect"
                            animation="wave"
                            height={150}
                        />
                    )}

                    <div className={classes.termAndCondition}>
                        <LspTypography
                            variant="subheading1"
                            className={classes.termAndConditionTitle}
                        >
                            {t("overdraft:overdraft_review_mortgage_title")}
                        </LspTypography>
                        {!isLoading && (
                            <div
                                className={clsx(
                                    classes.info,
                                    classes.termAndConditionContent
                                )}
                            >
                                <div className={classes.termAndConditionInfo}>
                                    <LspTypography variant="subheading1">
                                        {
                                            overdraftData?.termDepositInfo
                                                ?.termName
                                        }
                                    </LspTypography>
                                    <LspTypography variant="body3" color="grey">
                                        {t(
                                            "overdraft:overdraft_review_overdraft_block"
                                        )}
                                    </LspTypography>
                                </div>
                                <div className={classes.termAndConditionAmount}>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                        mr={1}
                                    >
                                        {formatNumber(
                                            overdraftData?.termDepositInfo
                                                ?.termDepositBalance
                                        )}
                                    </LspTypography>
                                    <LspTypography
                                        variant="heading2"
                                        component="span"
                                    >
                                        <Icon
                                            className="font-icon icon-locked"
                                            color="primary"
                                        />
                                    </LspTypography>
                                </div>
                            </div>
                        )}

                        {isLoading && (
                            <Skeleton
                                variant="rect"
                                animation="wave"
                                height={100}
                            />
                        )}
                    </div>
                    <div className={classes.termAndConditionFooter}>
                        {overdraftPreview.map((item, index) => (
                            <LspChecker
                                label={item}
                                emptyIcon={<Icon fontSize="small">👉</Icon>}
                                key={`od-desc-${index}`}
                            />
                        ))}
                    </div>
                    <div className={classes.termAndConditionAction}>
                        <LspButton
                            fullWidth
                            onClick={handleSubmit}
                            progressing={submitLoading}
                        >
                            {t("overdraft:overdraft_next_cta")}
                        </LspButton>
                    </div>
                </div>
            )}
            {viewMode === OVERDRAFT_VIEW_MODE.STATUS && <>{overdraftStatus}</>}
        </Content>
    );
};

const mapDispatch = (dispatch) => ({
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
});

export default connect(null, mapDispatch)(memo(OverdraftPreview));
