import LspRadioButton from "@components/LspRadioButton";
import { TermDepositRollType } from "@config/constants";
import { Box, makeStyles } from "@material-ui/core";
import { isFunction } from "lodash";
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    headerCell: {
        color: theme.palette.white.contrastText,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.background.header,
    },
    bodyCell: {
        padding: theme.spacing(0, 3),
    },
    radioItem: {
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
}));

const defaultMethod = {
    [TermDepositRollType.TOTAL_ROLLOVER]: false,
    [TermDepositRollType.PARTIAL_ROLLOVER]: false,
    [TermDepositRollType.NO_ROLLOVER]: false,
};

const RolloverMethodGroup = ({ onSelected, selected, showRollover }) => {
    const classes = useStyles();
    const [methodSelected, setMethodSelected] = useState(defaultMethod);
    const { t } = useTranslation();

    const handleRadioChange = ({ cfgKey }) => {
        setMethodSelected(() => ({
            ...defaultMethod,
            [cfgKey]: true,
        }));

        if (isFunction(onSelected)) {
            onSelected({ id: cfgKey });
        }
    };

    useEffect(() => {
        if (
            !showRollover &&
            selected === TermDepositRollType.PARTIAL_ROLLOVER
        ) {
            setMethodSelected(() => ({
                ...defaultMethod,
                [TermDepositRollType.PARTIAL_ROLLOVER]: true,
            }));
            return;
        }
        setMethodSelected(() => ({
            ...defaultMethod,
            [selected]: true,
        }));
    }, [showRollover, selected]);

    return (
        <>
            <Box className={classes.bodyCell}>
                {showRollover && (
                    <div className={classes.radioItem}>
                        <LspRadioButton
                            title={t("TD:td_partial_rollover_method_title")}
                            description={t(
                                "TD:td_partial_rollover_method_body"
                            )}
                            isSelected={
                                methodSelected[
                                    TermDepositRollType.PARTIAL_ROLLOVER
                                ]
                            }
                            cfgKey={TermDepositRollType.PARTIAL_ROLLOVER}
                            onItemSelected={handleRadioChange}
                        />
                    </div>
                )}
                <div className={classes.radioItem}>
                    <LspRadioButton
                        title={t("TD:td_total_rollover_method_title")}
                        description={t("TD:td_total_rollover_method_body")}
                        isSelected={
                            methodSelected[TermDepositRollType.TOTAL_ROLLOVER]
                        }
                        cfgKey={TermDepositRollType.TOTAL_ROLLOVER}
                        onItemSelected={handleRadioChange}
                    />
                </div>
                <div className={classes.radioItem}>
                    <LspRadioButton
                        title={t("TD:td_no_rollover_method_title")}
                        description={t("TD:td_no_rollover_method_body")}
                        isSelected={
                            methodSelected[TermDepositRollType.NO_ROLLOVER]
                        }
                        cfgKey={TermDepositRollType.NO_ROLLOVER}
                        onItemSelected={handleRadioChange}
                    />
                </div>
            </Box>
        </>
    );
};

RolloverMethodGroup.propTypes = {
    showRollover: PropTypes.bool,
    selected: PropTypes.string,
};

RolloverMethodGroup.defaultProps = {
    showRollover: true,
    selected: "",
};

export default memo(RolloverMethodGroup);
