import { ForgotPasswordActionTypes } from "@redux/actions/forgor-password";
import produce from "immer";
/* ------------- Initial State ------------- */
const initialState = {
    status: false,
    refNo: null,
    phoneNumber: null,
    message: null,
    token: null,
};

/* ------------- Reducer ------------- */
const forgotPasswordReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ForgotPasswordActionTypes.OTPRequired:
                const { refNo, phoneNumber, message, token } = action.payload;
                draft.refNo = refNo;
                draft.phoneNumber = phoneNumber;
                draft.message = message;
                draft.token = token;
                break;
            case ForgotPasswordActionTypes.OTPCommitSuccess:
                draft.status = true;
                break;
            default:
                break;
        }
    });

export default forgotPasswordReducer;
