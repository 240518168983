export const AlertType = Object.freeze({
    Default: "default",
    Success: "success",
    Info: "info",
    Warning: "warning",
    Error: "error",
});

export default class AlertController {
    static open;

    static close;

    static setOpen(open) {
        this.open = open;
    }

    static setClose(close) {
        this.close = close;
    }

    static keys = [];

    /**
     * @param  {string} message
     * @param  {('default'|'success'|'info'|'warning'|'error')} [type]
     * @param  {boolean} [persist]
     */
    static show(message, type, persist) {
        const key = new Date().getTime();
        this.keys.push(key);
        this.open(message, {
            variant: type || AlertType.Success,
            persist: persist || false,
            key,
        });
    }

    static hide() {
        this.keys.forEach((key) => {
            this.close(key);
        });
        this.keys = [];
    }
}
