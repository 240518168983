export const CARD_STATUSES = {
    ACTIVE: 1,
    CHANGE_PIN_1ST: 2,
    PIN_BLOCKED: 3,
    INACTIVE: 4,
    BLOCKED: 5,
    EXPIRED: 6,
    CLOSED: 7,
    REISSUED: 8,
    LOCKED: 12,
    INACTIVE_2: 13,
    POTENTIAL_RISK: 16,
    NO_CARD: 999,
    FOREIGNER_WARNING: 998,
    UNDEFINE: 20,
    REQUEST_TO_CLOSED: 19,
};

export const CARD_ACTIONS = {
    ACTIVATE_CARD: 1,
    LOCK_CARD: 5,
    UNLOCK_CARD: 10,
    REISSUE_NEW_CARD_PIN: 7,
    DISABLE_ECOMMERCE: 12,
    ENABLE_ECOMMERCE: 13,
    FE_CHANGE_PIN_SUCCESS: 99,
    ACTIVATE_VIRTUAL_CARD: 18,
};

export const CARD_ACTIVATE_SUCCESS_TYPES = {
    REPLACE_CHIP_CARD: "REPLACE_CHIP_CARD",
    VISA_DEBIT_VIRTUAL_CARD: "VISA_DEBIT_VIRTUAL_CARD",
    DEFAULT: "DEFAULT",
};

export const CARD_TYPES = {
    MASTER_CARD: "MASTER CARD",
    VISA_CARD: "VISA CARD",
    DEBIT_CARD: "DEBIT CARD",
    VISA_DEBIT_VIRTUAL_CARD: "VIRTUAL VD",
    VISA_DEBIT_VIRTUAL_CARD_MP: "VIRTUAL VD - MP",
    FE_VISA_CARD: "visaCard",
    FE_DEBIT_CARD: "debitCard",
    FE_DEBIT_PARAM: "debit",
    FE_CREDIT_PARAM: "credit",
};

export const E_COMMERCE_STATUSES = {
    ENABLED: "1",
    DISABLED: "0",
};

export const BANK_CARD_STATUS = {
    PICK_UP_L41: "PickUp L 41",
    CARD_OK: "Card OK",
};

export const CARD_MANAGEMENT_SUB_NAV = {
    CREDIT: "credit",
    DEBIT: "debit",
    PHYSICAL_VISA_DEBIT_VIRTUAL_CARD: "VISA_DEBIT_VIRTUAL_CARD",
    PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP: "VISA_DEBIT_VIRTUAL_CARD_MP",
};

export const CardManagementNavigation = {
    Debit: "/card-management/debit",
    Credit: "/card-management/credit",
    ResetPin: "/card-management/resetPin",
    ChangePin: "/card-management/changePin",
    ActivateCard: "/card-management/activate",
    ReIssueCard: "/card-management/reissue",
    CardLimit: "/card-management/card-limit",
};

export const PERSONAL_FORM = {
    YEARS_EXPIRE_CARDID: 15,
    YEARS_EXPIRE_PASSPORT: 10,
    NEW_FORMAT_CARD_ID_LENGTH: 12,
    MIN_INCOME: 4500000,
    DOC_TYPE_PASSPORT: "PV",
    DOC_TYPE_CARD_ID: "IC",
};

export const CORE_CARD = {
    W4: "W4",
    BVB: "BVB",
};

export const REISSUE_CARD_VERSION = {
    VIRTUAL: "VIRTUAL",
    CARD_V1: "CARD_V1",
    CARD_V2: "CARD_V2",
    VIRTUAL_TO_PHYSICAL_CARD: "VIRTUAL_TO_PHYSICAL_CARD",
};

export const CARD_PRODUCTS = {
    PHYSICAL_VISA_DEBIT: "physical-visa-debit-card",
};

export const PHYSICAL_CARD_STATUS = {
    NEW: "NEW",
    WAIT_ISSUE: "WAIT_ISSUE",
    SEND_BANK_FAIL: "SEND_BANK_FAIL",
    WAIT_ACTIVE_PHYSICAL: "WAIT_ACTIVE_PHYSICAL",
    CARD_OK: "Card OK",
};
