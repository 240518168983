/* eslint-disable react/no-array-index-key */

import LspTypography from "@components/LspTypography";
import { makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    uppercase: {
        textTransform: "uppercase",
    },

    discountList: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },

    discountItem: {
        cursor: "pointer",
        boxShadow: theme.shadows[2],
        width: `calc(100%/3 - ${theme.spacing(3)}px)`,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1.5, 1.5, 1.5, 1.5),
        " & > img": {
            width: "100%",
            height: "155",
            borderRadius: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: "80%",
        },
    },
}));

const DiscountList = ({ discountList, infiniteRef }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClickItem = (item) => {
        const path = item.link;
        window.open(path, "_blank");
    };
    return (
        <div className={classes.discountList} ref={infiniteRef}>
            {discountList?.map((item, key) => (
                <Paper
                    className={classes.discountItem}
                    onClick={() => onClickItem(item)}
                    key={key}
                >
                    <img alt="" src={item.thumbnail} />
                    <LspTypography
                        variant="heading2"
                        color="black"
                        className={classes.uppercase}
                    >
                        {item.title}
                    </LspTypography>
                    <LspTypography
                        variant="body1"
                        color="black"
                        className={classes.uppercase}
                    >
                        {item.description}
                    </LspTypography>
                    <LspTypography variant="body3" color="grey">
                        {`${t("creditCard:discount_expiry_date")} ${
                            item.expireDate
                        }`}
                    </LspTypography>
                </Paper>
            ))}
        </div>
    );
};

export default memo(DiscountList);
