import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Icon,
    makeStyles,
} from "@material-ui/core";

import "react-circular-progressbar/dist/styles.css";
import { ArrowDownIcon } from "@components/LspIcon";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.neutral.white,
        fontSize: 18,
    },
}));

export const AccordionTitle = withStyles((theme) => ({
    root: {
        background: theme.palette.orange.main,
        opacity: "0.9",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
        "& > div": {
            justifyContent: "center",
            "& > p > img": {
                width: "16px",
                marginRight: theme.spacing(2),
            },
        },
    },
}))(AccordionSummary);

export const AccordionContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 4),
        display: "block",
        justifyContent: "center",
        alignItems: "center",
    },
}))(AccordionDetails);

const GoalSaveForeigner = ({ showPopupInfo }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Accordion defaultExpanded>
                <AccordionTitle
                    expandIcon={<ArrowDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Icon className={`font-icon icon-notice ${classes.icon}`} />

                    <LspTypography variant="body2" color="white">
                        {t("regulation:gs_notice_title")}
                    </LspTypography>
                </AccordionTitle>

                <AccordionContent>
                    <LspTypography variant="body3" color="black">
                        {t("regulation:gs_notice_content")}
                    </LspTypography>

                    <Box mt={1}>
                        <LspTypography
                            variant="body3"
                            color="success"
                            component="a"
                            onClick={showPopupInfo}
                        >
                            {t("regulation:gs_notice_btn")}
                        </LspTypography>
                    </Box>
                </AccordionContent>
            </Accordion>
        </>
    );
};

export default GoalSaveForeigner;
