import Banner from "@components/Banner";
import Content from "@components/Content";
import ContentWrapper from "@components/ContentWrapper";
import LspButton from "@components/LspButton";
import { EditIcon } from "@components/LspIcon";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import { FetchStatus } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import { Box, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import accountAction from "@redux/actions/account";
import Interweave from "interweave";
import { isNull } from "lodash";

import { useState, memo, useEffect, useCallback } from "react";
import tommySuccess from "@assets/images/tommy_success.png";

import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(4),
    },
    headerIcon: {
        marginBottom: theme.spacing(1),
        "& .font-icon": {
            fontSize: theme.typography.pxToRem(32),
        },
    },
    headerText: {
        color: theme.palette.primary.main,
        fontSize: 20,
        lineHeight: "27px",
        fontWeight: 400,
    },
    body: {
        marginBottom: theme.spacing(4),
    },
    footer: {
        textAlign: "center",
    },
    footerText: {
        marginBottom: theme.spacing(2),
    },
}));

const TransferLimit = ({
    accountLimit,
    modifyAccount,
    getAccountLimit,
    modifyAccountLimit,
    userId,
    accountLimitFetch,
    modifyAccountFetch,
    resetAccountLimit,
    modifyAccountStatus,
    accountLimitStatus,
}) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const [errors, setErrors] = useState(false);

    const [transferLimit, setTransferLimit] = useState(null);
    const [transferLimitDefault, setTransferLimitDefault] = useState(null);
    const [transferLimitUpdate, setTransferLimitUpdate] = useState(null);
    const [isModified, setModified] = useState(false);

    const {
        thousandSeparator,
        decimalSeparator,
        formatNumber,
        parseNumber,
    } = useNumber();

    const openDialog = useCallback((content) => {
        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={content} centerImg />
            ),
        });
    }, []);

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    useEffect(() => {
        if (!accountLimit && !accountLimitFetch) {
            getAccountLimit({ userId });
        } else {
            setTransferLimit(
                accountLimit?.accountLimitUpdate || accountLimit?.accountLimit
            );
            setTransferLimitDefault(accountLimit?.accountLimit);
            setTransferLimitUpdate(
                accountLimit?.accountLimitUpdate || accountLimit?.accountLimit
            );
        }
    }, [accountLimit, accountLimitFetch, getAccountLimit, userId]);

    useEffect(() => {
        if (modifyAccountFetch === FetchStatus.Success) {
            const contents = {
                banner: tommySuccess,
                title: t("master:sc_set_limit_acc_success_title"),
                description: t("master:sc_set_limit_acc_success_dessc"),
                buttons: [
                    {
                        variant: "primary",
                        label: t("master:sc_set_limit_acc_success_CTA"),
                        onClick: () => closeDialog(),
                    },
                ],
            };
            openDialog(contents);
            setModified(false);
            getAccountLimit({ userId });
            resetAccountLimit();
        }
        if (modifyAccountFetch === FetchStatus.Error) {
            setModified(false);
        }
    }, [
        closeDialog,
        getAccountLimit,
        modifyAccount,
        modifyAccountFetch,
        openDialog,
        resetAccountLimit,
        t,
        userId,
    ]);

    const onSubmitHandler = () => {
        if (!errors) {
            modifyAccountLimit({ userId, accountLimit: transferLimit });
        }
    };

    const onTransferLimitChange = (data) => {
        const { value } = data?.target;
        const amount = parseNumber(value);
        setTransferLimit(amount);
        setErrors(amount > transferLimitDefault);
    };

    useEffect(() => {
        if (
            transferLimit === transferLimitDefault &&
            transferLimitUpdate < transferLimit
        ) {
            setModified(true);
        } else {
            setModified(transferLimit !== transferLimitUpdate);
        }
    }, [transferLimit, transferLimitDefault, transferLimitUpdate]);

    return (
        <>
            <Banner title={t("master:sc_set_limit_title")} />
            <ContentWrapper>
                <Content size="sm">
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitHandler();
                            }}
                        >
                            <div className={classes.body}>
                                <NumberFormat
                                    name="transfer_limit"
                                    label={t(
                                        "master:sc_set_limit_acc_transfer"
                                    )}
                                    helperText={
                                        errors
                                            ? t("master:sc_set_limit_acc_error")
                                            : `${t(
                                                  "master:sc_set_limit_acc_max_day"
                                              )} <b>${formatNumber(
                                                  transferLimitDefault
                                              )} vnd</b>`
                                    }
                                    error={errors}
                                    value={transferLimit}
                                    onChange={(data) =>
                                        onTransferLimitChange(data)
                                    }
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    allowNegative={false}
                                    customInput={LspTextField}
                                    disabled={
                                        modifyAccountStatus ||
                                        accountLimitStatus
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className={classes.footer}>
                                <LspTypography
                                    color="grey"
                                    variant="body3"
                                    className={classes.footerText}
                                >
                                    <Interweave
                                        content={t(
                                            "master:sc_set_limit_acc_confirm"
                                        )}
                                    />
                                </LspTypography>
                                <LspButton
                                    type="submit"
                                    fullWidth
                                    variant="primary"
                                    disabled={errors || !isModified}
                                    progressing={
                                        modifyAccountStatus ||
                                        accountLimitStatus
                                    }
                                >
                                    {t("master:sc_set_limit_acc_CTA_apply")}
                                </LspButton>
                            </div>
                        </form>
                    </Box>
                </Content>
            </ContentWrapper>
        </>
    );
};
const stateProps = (state) => ({
    accountLimit: state.account.accountLimit.data,
    accountLimitFetch: state.account.accountLimit.fetch,
    accountLimitStatus: state.account.accountLimit.status,
    modifyAccount: state.account.modifyAccountLimit.data,
    modifyAccountFetch: state.account.modifyAccountLimit.fetching,
    modifyAccountStatus: state.account.modifyAccountLimit.status,
    userId: state.user.info?.userId,
});
const mapDispatch = (dispatch) => ({
    getAccountLimit: (payload) =>
        dispatch(accountAction.getAccountLimitRequest(payload)),
    modifyAccountLimit: (payload) =>
        dispatch(accountAction.modifyAccountLimitRequest(payload)),
    resetAccountLimit: () => dispatch(accountAction.resetAccountLimit()),
});

export default connect(stateProps, mapDispatch)(memo(TransferLimit));
