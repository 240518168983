import { memo } from "react";
import LspTypography from "@components/LspTypography";
import { ButtonBase, Icon } from "@material-ui/core";
import { ArrowRightIcon } from "@components/LspIcon";
import Interweave from "interweave";
import { connect } from "react-redux";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import useSharedClasses from "../wealthCreditCardSharedClasses";

const WealthCreditCardMethodSelectionsItem = ({
    item,
    onClick,
    setSubProductKey,
}) => {
    const sharedClasses = useSharedClasses();

    const onClickHandler = () => {
        setSubProductKey(item.proKey);
        onClick(item.proKey);
    };

    return (
        <>
            <ButtonBase
                onClick={onClickHandler}
                key={item.proKey}
                className={sharedClasses.listLine}
            >
                <Icon
                    className={`font-icon icon-${item.icon} ${sharedClasses.iconList}`}
                />
                <div>
                    <LspTypography variant="heading3" color="primary">
                        <Interweave content={item.title} />
                    </LspTypography>
                    <LspTypography variant="body2">
                        {item.content}
                    </LspTypography>
                </div>
                <ArrowRightIcon />
            </ButtonBase>
        </>
    );
};

const mapDispatch = (dispatch) => ({
    setSubProductKey: (payload) =>
        dispatch(wealthCreditCardAction.setSubProductKey(payload)),
});

export default memo(
    connect(null, mapDispatch)(WealthCreditCardMethodSelectionsItem)
);
