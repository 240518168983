import LspTypography from "@components/LspTypography";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    notificationBarRoot: {
        color: theme.palette.neutral.white,
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    purple: {
        backgroundColor: theme.palette.purple.main,
    },
    blue: {
        backgroundColor: theme.palette.blue.main,
    },
    orange: {
        backgroundColor: theme.palette.orange.main,
    },
    gold: {
        backgroundColor: theme.palette.gold.main,
    },
    message: {
        flex: 1,
    },
    icon: {
        marginRight: theme.spacing(1),
        opacity: 0.5,
    },
}));

/**
 *
 * @param {object} props
 * @param {React.ReactElement} props.IconComponent
 * @param {string} props.message
 * @param {("primary"|"secondary"|"purple"|"blue"|"orange"|"gold")} [props.color="primary"]
 * @param {React.ReactNode} props.buttonComponent
 */
const NotificationBar = ({
    IconComponent,
    message,
    color,
    buttonComponent,
}) => {
    const classes = useStyles();
    return (
        <Box
            className={clsx(classes.notificationBarRoot, classes[color])}
            height={38}
            display="flex"
            alignItems="center"
            borderRadius={6}
            width="100%"
            pl={2}
            pr={1.5}
            py={0.5}
            mb={2}
        >
            {IconComponent && (
                <IconComponent className={classes.icon} vb={24} />
            )}
            <LspTypography
                component="div"
                variant="body2"
                color="white"
                className={classes.message}
            >
                {message}
            </LspTypography>
            {buttonComponent}
        </Box>
    );
};

NotificationBar.propTypes = {
    IconComponent: PropTypes.elementType,
    message: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "purple",
        "blue",
        "orange",
        "gold",
    ]),
    buttonComponent: PropTypes.node,
};

NotificationBar.defaultProps = {
    IconComponent: null,
    color: "primary",
    buttonComponent: undefined,
};

export default memo(NotificationBar);
