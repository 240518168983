import LspTypography from "@components/LspTypography";
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { memo, useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import LspTextField from "@components/LspTextField";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";

import { Autocomplete } from "@material-ui/lab";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { GENDER_LIST, REGEX_PATTERN } from "@config/constants";
import clsx from "clsx";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useCapitalize from "@helpers/useCapitalize";
import { add, sub } from "date-fns";

const AGE_CONFIGS = {
    MAX_ADULT: 75,
    MIN_ADULT: 18,
};

const DependentPolicyForm = ({
    parentClasses,
    formDependentPolicy,
    id,
    setDependentPolicyForm,
    today,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();
    const { characterValidation } = useInputSpecialCharacter();
    const { capitalizeSentence } = useCapitalize();

    const nationalityList = useMemo(() => {
        return t("wealth:nationalities", { returnObjects: true });
    }, [t]);

    const title = useMemo(() => {
        const info = id.split("-");
        return {
            left: `${t("wealth_label_person")} ${+info[1] + 2}`,
            right:
                info[0] === "adult"
                    ? t("wealth_travel_adult")
                    : t("wealth_label_dependent"),
        };
    }, [id, t]);

    const data = useMemo(() => {
        return formDependentPolicy[id];
    }, [formDependentPolicy, id]);

    const fullName = useMemo(() => data?.fullName, [data?.fullName]);
    const gender = useMemo(() => data?.gender, [data?.gender]);
    const legalId = useMemo(() => data?.legalId, [data?.legalId]);

    const [dateOfBirth, setDateOfBirth] = useState(data?.dateOfBirth?.value);
    const [dateOfBirthError, setDateOfBirthError] = useState("");

    const nationality = useMemo(() => data?.nationality, [data?.nationality]);
    const type = useMemo(() => id.split("-")[0], [id]);

    // Age for adult: 18-75 years old
    // For child: 1 day - below 18 years old
    const maxDate = useMemo(() => {
        // for child max: 1 day old from today
        return type === "adult"
            ? sub(today, { years: AGE_CONFIGS.MIN_ADULT })
            : sub(today, { days: 1 });
    }, [today, type]);

    const minDate = useMemo(() => {
        // for child min: below 18 years old
        const tomorrow = add(today, { days: 1 });
        return type === "adult"
            ? sub(today, { years: AGE_CONFIGS.MAX_ADULT })
            : sub(tomorrow, { years: AGE_CONFIGS.MIN_ADULT });
    }, [today, type]);

    const onChangeFullName = useCallback(
        (value) => {
            const error = !value ? t("msg_we_need_this") : "";
            const prevInfo = {
                ...formDependentPolicy,
                [data?.id]: {
                    ...data,
                    fullName: {
                        value: capitalizeSentence(value),
                        error,
                    },
                },
            };
            setDependentPolicyForm(prevInfo);
        },
        [
            data,
            setDependentPolicyForm,
            t,
            formDependentPolicy,
            capitalizeSentence,
        ]
    );

    const onChangeGender = useCallback(
        (value) => {
            const prevInfo = {
                ...formDependentPolicy,
                [data?.id]: {
                    ...data,
                    gender: {
                        value,
                        error: "",
                    },
                },
            };
            setDependentPolicyForm(prevInfo);
        },
        [data, setDependentPolicyForm, formDependentPolicy]
    );
    const onChangeDateOfBirth = useCallback(
        (d) => {
            const error = !d ? t("wealth:mc_v3_error_we_need") : "";

            setDateOfBirth(d ? new Date(d) : null);
            setDateOfBirthError(error);

            const prevInfo = {
                ...formDependentPolicy,
                [data?.id]: {
                    ...data,
                    dateOfBirth: {
                        value: d ? new Date(d) : null,
                        error: "",
                    },
                },
            };
            setDependentPolicyForm(prevInfo);
        },
        [data, setDependentPolicyForm, formDependentPolicy, t]
    );

    const onChangeLegalId = useCallback(
        (value) => {
            let error = "";

            if (!value) {
                error = t("msg_we_need_this");
            }

            if (
                (type === "adult" &&
                    !characterValidation(
                        value,
                        REGEX_PATTERN.PASSPORT_IDNUMBER_LIBERTY_ADULT
                    )) ||
                (type === "child" &&
                    !characterValidation(
                        value,
                        REGEX_PATTERN.PASSPORT_IDNUMBER_LIBERTY_CHILD
                    ))
            ) {
                error = t("wealth_travel_error_passport_invalid");
            }

            const prevInfo = {
                ...formDependentPolicy,
                [data?.id]: {
                    ...data,
                    legalId: {
                        value,
                        error,
                    },
                },
            };
            setDependentPolicyForm(prevInfo);
        },
        [
            data,
            setDependentPolicyForm,
            t,
            formDependentPolicy,
            characterValidation,
            type,
        ]
    );

    const onChangeNationality = useCallback(
        ({ value }) => {
            const error = !value ? t("msg_we_need_this") : "";
            const prevInfo = {
                ...formDependentPolicy,
                [data?.id]: {
                    ...data,
                    nationality: {
                        value,
                        error,
                    },
                },
            };
            setDependentPolicyForm(prevInfo);
        },
        [data, setDependentPolicyForm, t, formDependentPolicy]
    );

    return (
        <>
            <Box
                className={clsx(sharedClasses.formHeader, {
                    [parentClasses?.header]: true,
                })}
            >
                <LspTypography variant="body2">{title?.left}</LspTypography>
                <LspTypography variant="body2" color="grey">
                    {title?.right}
                </LspTypography>
            </Box>
            <Box className={sharedClasses.formBody}>
                <LspTextField
                    autoFocus
                    error={!!fullName?.error}
                    name="fullName"
                    value={fullName?.value}
                    label={t("wealth:GE00011")}
                    helperText={fullName?.error || ""}
                    inputProps={{
                        maxLength: 35,
                    }}
                    onChange={(e) => onChangeFullName(e.target.value)}
                />
                <Box paddingBottom={1}>
                    <LspTypography color="grey">{t("lb_gender")}</LspTypography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="bottom"
                        >
                            {GENDER_LIST?.map((item) => {
                                return (
                                    <FormControlLabel
                                        key={item?.cfgKey}
                                        value={item?.cfgKey}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={
                                                    gender?.value ===
                                                    item?.cfgKey
                                                }
                                                onChange={(e) =>
                                                    onChangeGender(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        }
                                        label={t(item?.cfgValue)}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>

                <KeyboardDatePicker
                    disableFuture
                    openTo="year"
                    format="dd/MM/yyyy"
                    views={["year", "month", "date"]}
                    onChange={onChangeDateOfBirth}
                    disableToolbar
                    value={dateOfBirth}
                    TextFieldComponent={(params) => {
                        return (
                            <LspTextField
                                {...params}
                                label={t("wealth:GE00074")}
                                error={!!dateOfBirthError}
                                helperText={dateOfBirthError || " "}
                            />
                        );
                    }}
                    maxDate={maxDate}
                    minDate={minDate}
                />

                <LspTextField
                    error={!!legalId?.error}
                    name="legalId"
                    value={legalId?.value}
                    label={t("wealth:GE00075")}
                    helperText={legalId?.error || ""}
                    inputProps={{
                        maxLength: 150,
                    }}
                    onChange={(e) => onChangeLegalId(e.target.value)}
                />
                <Autocomplete
                    options={nationalityList}
                    getOptionLabel={(option) => option?.value || ""}
                    renderOption={(option) => <>{option.value}</>}
                    onChange={(_, value) => onChangeNationality({ value })}
                    value={nationality?.value}
                    renderInput={(params) => {
                        return (
                            <LspTextField
                                {...params}
                                label={t("wealth:GE00076")}
                                error={!!nationality?.error}
                                helperText={nationality?.error || " "}
                            />
                        );
                    }}
                />
            </Box>
        </>
    );
};

const mapState = (state) => ({
    formDependentPolicy: state.insurance.formDependentPolicy,
    today: state.user.serverTime.value,
});

const mapDispatch = (dispatch) => ({
    setDependentPolicyForm: (payload) =>
        dispatch(wealthInsuranceAction.setDependentPolicyForm(payload)),
});

export default memo(connect(mapState, mapDispatch)(DependentPolicyForm));
