import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { Logo } from "@components/LspBranding";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import ColorBar from "@components/ColorBar";
import DownloadApp from "@components/DownloadApp";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: 362,
        width: "90%",
        textAlign: "center",
        borderRadius: theme.shape.radiusLevels[1],
        margin: "auto",
        background: theme.palette.background.paper,
        overflow: "hidden",
    },
    body: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        width: 160,
    },
    colorBar: {
        borderBottomLeftRadius: theme.shape.radiusLevels[1],
        borderBottomRightRadius: theme.shape.radiusLevels[1],
    },
}));

const LoginWarningDialog = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.wrapper}>
            <Box paddingY={7} paddingX={2} className={classes.body}>
                <Logo className={classes.logo} />
                <Box marginY={5} marginX={3}>
                    <LspTypography variant="heading1" color="black">
                        {t("ekyc:ekyc_pop_up_install_app_title")}
                    </LspTypography>
                    <Box marginTop={2} marginBottom={3}>
                        <LspTypography variant="body2" color="grey">
                            {t("ekyc:ekyc_pop_up_install_app_content")}
                        </LspTypography>
                    </Box>
                    <DownloadApp />
                </Box>
                <Box width="100%">
                    <LspButton fullWidth onClick={onClose}>
                        {t("lb_close")}
                    </LspButton>
                </Box>
            </Box>
            <ColorBar className={classes.colorBar} />
        </div>
    );
};

export default memo(LoginWarningDialog);
