import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTimeSelection from "@components/LspBooking/LspTime/LspTimeSelection";
import { BOOKING_STEPS } from "@containers/Booking/constant";
import { Box } from "@material-ui/core";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const CreateBookingTimeSelection = ({ onCancel, onNext, onBack }) => {
    const { t } = useTranslation();
    const unmounted = useRef(false);
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedTime, setSelectedTime] = useState({});

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onSelectTime = useCallback(
        ({ date, time }) => {
            setSelectedDate(date);
            setSelectedTime(time);
        },
        [setSelectedDate, setSelectedTime]
    );

    const buttons = useMemo(() => {
        return [
            {
                label: t("lb_next"),
                onClick: () => {
                    onNext(BOOKING_STEPS.HANGOUT_CONFIRMATION);
                },
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ];
    }, [t, onCancel, onNext]);

    return (
        <Content size="sm">
            <LspTimeSelection
                onBack={onBack}
                description={t("wealth:mc_v3_when_msg")}
                onNext={onSelectTime}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
            />
            <Box mt={3}>
                <DoubleButtons
                    primaryButton={buttons[0]}
                    secondaryButton={buttons[1]}
                />
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
});

export default connect(mapState, null)(CreateBookingTimeSelection);
