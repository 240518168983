import PropTypes from "prop-types";
import { useState, memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import {
    Box,
    ButtonBase,
    Collapse,
    makeStyles,
    MenuItem,
} from "@material-ui/core";
import { orderBy } from "lodash";
import PaymentItem from "@containers/PaymentRequest/PaymentList/PaymentItem";
import PaymentRequestListLoading from "@containers/PaymentRequest/PaymentList/PaymentRequestListLoading";
import LspTypography from "@components/LspTypography";
import { PAYMENT_REQUEST_SORT_TYPES } from "../paymentRequest.constant";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        tdItemNoContent: {
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            display: "flex",
            justifyContent: "center",
            minHeight: "90px",
            borderTop: `1px solid ${theme.palette.neutral.grey3}`,
            flexDirection: "column",
            padding: theme.spacing(2),
            textAlign: "center",
        },
        tdItemRoot: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            "&.inactive": {
                backgroundColor: isDark
                    ? theme.palette.background.paper
                    : theme.palette.grey[200],
            },
            fontSize: theme.typography.pxToRem(16),
        },
        titlePanel: {
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(16),
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(1, 2),
        },
        expandBtn: {
            textAlign: "right",
            flex: "1",
            cursor: "pointer",
        },
        select: {
            color: theme.palette.primary.main,
            fontWeight: 600,
        },
        loadMoreBtn: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(2),
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            boxSizing: "box",
        },
    };
});

const PaymentRequestList = ({
    list,
    headerText,
    loading,
    loadMoreFunc,
    hasLoadMore,
    emptyInfo,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [expanded, setExpended] = useState(true);
    const [timeRange, setTimeRange] = useState(
        PAYMENT_REQUEST_SORT_TYPES.RECENT
    );
    const [renderingList, setRenderingList] = useState(list);

    const sortTime = () => {
        const data = orderBy(renderingList, ["milisecondTime"], ["desc"]);
        setRenderingList(data);
    };

    const sortAlpha = () => {
        const data = orderBy(
            renderingList,
            [(user) => user.nameDisplayCustom.toLowerCase()],
            ["asc"]
        );
        setRenderingList(data);
    };

    const onFilterChange = (e) => {
        if (e.target.value === "payment_lb_Alphanumeric") {
            sortAlpha();
        } else {
            sortTime();
        }
        setTimeRange(e.target.value);
    };

    useEffect(() => {
        if (list) {
            setRenderingList(list);
        }
    }, [list]);

    const sortOptions = useMemo(() => {
        return Object.values(PAYMENT_REQUEST_SORT_TYPES);
    }, []);

    const emptyContent = useMemo(() => {
        return (
            <div className={classes.tdItemNoContent}>
                <LspTypography>{emptyInfo?.desc}</LspTypography>
                {emptyInfo?.subDesc && (
                    <Box mt={1}>
                        <LspTypography variant="body2" color="grey">
                            {emptyInfo?.subDesc}
                        </LspTypography>
                    </Box>
                )}
            </div>
        );
    }, [emptyInfo, classes]);

    return (
        <>
            <div className={classes.tdItemRoot}>
                <div className={classes.titlePanel}>
                    <Box paddingX={1}>{headerText}</Box>
                    {renderingList?.length > 0 && (
                        <Box paddingX={1}>
                            <TextField
                                SelectProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.select,
                                    },
                                }}
                                select
                                value={timeRange}
                                onChange={(e) => onFilterChange(e)}
                            >
                                {sortOptions?.map((opt) => (
                                    <MenuItem key={opt} value={opt}>
                                        {t(opt)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    )}

                    <Box
                        onClick={() => setExpended((prev) => !prev)}
                        textAlign="right"
                        flex={1}
                        className={classes.expandBtn}
                    >
                        {expanded
                            ? t("payment_lb_hide")
                            : t("payment_lb_unhide")}
                    </Box>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto">
                {emptyInfo?.status && !loading && emptyContent}
                {renderingList?.map((item) => (
                    <PaymentItem
                        classes={classes}
                        item={item}
                        key={item.paymentId}
                    />
                ))}
                {loading && <PaymentRequestListLoading />}

                {hasLoadMore && !loading && (
                    <ButtonBase
                        onClick={loadMoreFunc}
                        className={classes.loadMoreBtn}
                    >
                        <LspTypography color="grey">
                            {t("global_load_more_label")}
                        </LspTypography>
                    </ButtonBase>
                )}
            </Collapse>
        </>
    );
};

PaymentRequestList.propTypes = {
    headerText: PropTypes.string,
    loadMoreFunc: PropTypes.func,
    loading: PropTypes.bool,
    hasLoadMore: PropTypes.bool,
    emptyInfo: PropTypes.shape({
        status: PropTypes.bool,
        desc: PropTypes.string,
        subDesc: PropTypes.string,
    }),
};

PaymentRequestList.defaultProps = {
    headerText: "",
    loadMoreFunc: undefined,
    loading: false,
    hasLoadMore: true,
    emptyInfo: {
        status: false,
        desc: "",
        subDesc: "",
    },
};

export default memo(PaymentRequestList);
