/* eslint-disable react/no-array-index-key */
import LspButton from "@components/LspButton";
import { ButtonBase, Icon, makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";
import Interweave from "interweave";
import { isArray } from "lodash";
import { useMemo, memo } from "react";
import PropTypes from "prop-types";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    congratulationWrapper: {
        overflow: "hidden",
    },
    iconWrapper: {
        width: 50,
        height: 50,
        borderRadius: "50%",
        background: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.primary.main}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "-25px",
        "&.success": {
            border: `1px solid ${theme.palette.status.success}`,
        },
        "&.error": {
            border: `1px solid ${theme.palette.status.error}`,
        },
    },
    icon: {
        color: theme.palette.primary.main,
        "&.success": {
            color: theme.palette.status.success,
        },
        "&.error": {
            color: theme.palette.status.error,
        },
    },
    header: {
        position: "relative",
        background: theme.palette.primary.main,
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 100,
        color: theme.palette.neutral.white,
        fontSize: theme.typography.pxToRem(18),
        textTransform: "uppercase",
        padding: theme.spacing(3, 2, 4, 2),
        "&.success": {
            background: theme.palette.status.success,
        },
        "&.error": {
            background: theme.palette.status.error,
        },
    },
    body: {
        margin: theme.spacing(6, 2, 4),
    },
    bodyWithBox: {
        marginBottom: theme.spacing(4),
    },
    contentLine: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    footer: {
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: theme.spacing(2),
    },
    button: {
        "&.success": {
            "& button": {
                background: theme.palette.status.success,
            },

            "& button:hover": {
                background: theme.palette.status.success,
            },
        },

        "&.error": {
            "& button": {
                background: theme.palette.status.error,
            },

            "& button:hover": {
                background: theme.palette.status.error,
            },
        },
    },
    box: {
        border: `1px solid ${theme.palette.neutral.grey3}`,
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(13),
        padding: theme.spacing(2.5),
        marginTop: theme.spacing(4),
        textAlign: "center",
    },
    warningBox: {
        border: `1px solid ${theme.palette.status.error}`,
        color: theme.palette.status.error,
    },
    contentWithActions: {
        width: "100%",
        marginTop: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
    component: {
        textAlign: "center",
    },
}));

/**
 * @param {object} props
 * @param {string} props.icon
 * @param {string} props.title
 * @param {string|array} props.contents
 * @param {string} props.otherContent
 * @param {string} props.contentWithBox
 * @param {object} props.button
 * @param {("success"|"primary")} props.type
 * @param {object} props.contentWithAction
 */

const LspCongratulation = ({
    icon,
    title,
    contents,
    otherContent,
    contentWithBox,
    button,
    type = "success",
    contentWithAction,
    component,
    children,
    noIcon,
}) => {
    const classes = useStyles();
    const contentIsArray = useMemo(() => isArray(contents), [contents]);

    return (
        <Paper className={classes.congratulationWrapper}>
            <div className={`${classes.header} ${type}`}>
                <div>{title}</div>
                {!noIcon && (
                    <div className={`${classes.iconWrapper} ${type}`}>
                        <Icon
                            className={`font-icon icon-${icon} ${classes.icon} ${type}`}
                        />
                    </div>
                )}
            </div>
            <div
                className={clsx(classes.body, {
                    [classes.bodyWithBox]: !!contentWithBox,
                })}
            >
                {contentIsArray &&
                    contents?.map((c, index) => (
                        <div className={classes.contentLine} key={index}>
                            {c}
                        </div>
                    ))}
                {!contentIsArray && contents && (
                    <div className={classes.contentLine}>
                        <Interweave content={contents} />
                    </div>
                )}

                {component && (
                    <div className={classes.component}>{children}</div>
                )}

                {contentWithBox && (
                    <div
                        className={clsx(classes.box, {
                            [classes.warningBox]:
                                contentWithBox && contentWithBox?.isWarning,
                        })}
                    >
                        <Interweave content={contentWithBox?.content} />
                    </div>
                )}

                {otherContent && (
                    <div className={classes.contentLine}>
                        <Interweave content={otherContent} />
                    </div>
                )}

                {contentWithAction && (
                    <ButtonBase
                        onClick={contentWithAction?.onClick}
                        className={clsx(classes.contentWithActions, {
                            [contentWithAction?.className]: !!contentWithAction?.className,
                        })}
                    >
                        <LspTypography variant="hyperlink1" color="grey">
                            {contentWithAction?.label}
                        </LspTypography>
                    </ButtonBase>
                )}
            </div>
            {button && !button?.isHide && (
                <div className={classes.footer}>
                    <LspButton
                        onClick={button?.onClick}
                        fullWidth
                        className={`${classes.button} ${type}`}
                    >
                        {button?.label}
                    </LspButton>
                </div>
            )}
        </Paper>
    );
};

LspCongratulation.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    contents: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    otherContent: PropTypes.string,
    contentWithBox: PropTypes.shape({
        content: PropTypes.string,
        isWarning: PropTypes.bool,
    }),
    type: PropTypes.oneOf(["success", "primary", "error"]),
    component: PropTypes.bool,
    contentWithAction: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
    }),
    noIcon: PropTypes.bool,
};

LspCongratulation.defaultProps = {
    icon: "like",
    title: "",
    contents: null,
    otherContent: null,
    contentWithBox: null,
    type: "success",
    component: false,
    contentWithAction: null,
    noIcon: false,
};

export default memo(LspCongratulation);
