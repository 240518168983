import api from "@config/api";

const getBookingList = async (payload) => {
    return await api.post(`/user/schedules/list`, payload, {
        bypassErrorHandler: true,
    });
};

const getBookingListV3 = async (payload) => {
    return await api.post(`/v3/user/schedules/list`, payload, {
        bypassErrorHandler: true,
    });
};

const getJoinBookingList = async (payload) => {
    return await api.post(`/v3/user/join/schedules/list`, payload, {
        bypassErrorHandler: true,
    });
};

const getBookingDetailEkyc = async (payload) => {
    return await api.post(`/precus/ekyc/booking/detail`, payload, {
        bypassErrorHandler: true,
    });
};

const getBookingDetailReplaceChipCard = async (payload) => {
    return await api.post(`/v3/user/chipcard/reissue/detail`, payload, {
        bypassErrorHandler: true,
    });
};

const getProvinceHangout = async (payload) => {
    return await api.post(`/v3/user/location/list`, payload, {
        bypassErrorHandler: true,
    });
};
const submitEkycBooking = async (payload) => {
    return await api.post(`/v3/user/join/schedules/book`, payload, {
        bypassErrorHandler: true,
    });
};
const submitEkycDeliveryBooking = async (payload) => {
    return await api.post(`/precus/ekyc/delivery/submit`, payload, {
        bypassErrorHandler: true,
    });
};
const submitPickupEkyc = async (payload) => {
    return await api.post(`/precus/ekyc/pickup/submit`, payload, {
        bypassErrorHandler: true,
    });
};

const updateEkycBooking = async (payload) => {
    return await api.post(`/precus/ekyc/booking/update`, payload, {
        bypassErrorHandler: true,
    });
};
const bookingReplaceChipCard = async (payload) => {
    return await api.post(`/v3/user/chipcard/reissue/submit`, payload, {
        bypassErrorHandler: true,
    });
};
const updateBookingReplaceChipCard = async (payload) => {
    return await api.post(`/v3/user/chipcard/reissue/editsubmit`, payload, {
        bypassErrorHandler: true,
    });
};

const bookingService = {
    getBookingList,
    getBookingListV3,
    getJoinBookingList,
    getBookingDetailEkyc,
    getBookingDetailReplaceChipCard,
    getProvinceHangout,
    submitEkycBooking,
    submitPickupEkyc,
    updateEkycBooking,
    bookingReplaceChipCard,
    updateBookingReplaceChipCard,
    submitEkycDeliveryBooking,
};

export default bookingService;
