import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { SystemParamActionType } from "../actions/system-params";

/* ------------- Initial State ------------- */
const initialState = {
    fetching: false,
    status: null,
    info: null,
    phoneConfigs: [],
    contactsFetching: false,
};

/* ------------- Reducer ------------- */
const systemParamsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SystemParamActionType.Reset: {
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            }
            /* ------------- GetSystemParams ------------- */
            case SystemParamActionType.GetSystemParamsRequest:
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case SystemParamActionType.GetSystemParamsSuccess:
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                draft.info = action.payload;
                break;
            case SystemParamActionType.GetSystemParamsError:
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case SystemParamActionType.GetPhoneConfigs:
                draft.phoneConfigsFetching = true;
                break;
            case SystemParamActionType.GetPhoneConfigsSuccess:
            case SystemParamActionType.GetPhoneConfigsError:
                draft.phoneConfigsFetching = false;
                draft.phoneConfigs = action.payload;
                break;
            default:
                break;
        }
    });

export default systemParamsReducer;
