/* ------------- Types Definition ------------- */
export const MetricActionTypes = Object.freeze({
    InitMetricRequest: "MetricActionTypes/InitMetricRequest",
    InitMetricError: "MetricActionTypes/InitMetricError",
    InitMetricSuccess: "MetricActionTypes/InitMetricSuccess",
    MetricRequest: "MetricActionTypes/MetricRequest",
    MetricRequestSuccess: "MetricActionTypes/MetricRequestSuccess",
    MetricRequestError: "MetricActionTypes/MetricRequestError",
    MetricRequestUpdate: "MetricActionTypes/MetricRequestUpdate",
    MetricSession: "MetricActionTypes/MetricSession",
});

/* ------------- Actions Definition ------------- */

const initMetricRequest = () => {
    return {
        type: MetricActionTypes.InitMetricRequest,
    };
};

const initMetricSuccess = (payload) => {
    return {
        type: MetricActionTypes.InitMetricSuccess,
        payload,
    };
};

const initMetricError = () => {
    return {
        type: MetricActionTypes.initMetricError,
    };
};

const metricRequest = (payload) => {
    return {
        type: MetricActionTypes.MetricRequest,
        payload,
    };
};

const metricRequestSuccess = (payload) => {
    return {
        type: MetricActionTypes.MetricRequestSuccess,
        payload,
    };
};

const metricRequestError = (payload) => {
    return {
        type: MetricActionTypes.MetricRequestError,
        payload,
    };
};

const metricRequestUpdate = (payload) => {
    return {
        type: MetricActionTypes.MetricRequestUpdate,
        payload,
    };
};

const metricSession = (payload) => {
    return {
        type: MetricActionTypes.MetricSession,
        payload,
    };
};

const metricActions = {
    initMetricRequest,
    initMetricSuccess,
    initMetricError,
    metricRequest,
    metricRequestSuccess,
    metricRequestError,
    metricRequestUpdate,
    metricSession,
};

export default metricActions;
