import { memo, useCallback, useMemo, useState, useEffect } from "react";
import {
    Box,
    ButtonBase,
    Divider,
    IconButton,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { AccountType } from "@config/constants";

import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";
import { VisibilityOnIcon, VisibilityOffIcon } from "@components/LspIcon";
import { useHistory } from "react-router-dom";
import discountImg from "@assets/images/discount_icon.png";
import Interweave from "interweave";
import { isFunction, maxBy } from "lodash";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import InterestRatesDialog from "@containers/TermDeposit/InterestRatesDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        "& a": {
            color: theme.palette.success.main,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 700,
            textDecoration: "underline",
            lineHeight: 22 / 14,
            cursor: "pointer",
        },
    },
    accountCard: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        "&.hasSub": {
            marginBottom: 0,
        },
        "&.sub": {
            marginTop: 0,
            padding: theme.spacing(1, 2),
            "&:active": {
                opacity: 1,
            },
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3),
            transition: theme.transitions.create("background-color", {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut,
            }),
            "&.sub": {
                padding: theme.spacing(2, 3),
                "&:hover": {
                    backgroundColor: theme.palette.background.paper,
                    "& p": {
                        color: "inherit",
                    },
                },
            },
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    accountCardIcon: {
        color: theme.palette.status.success,
        marginRight: theme.spacing(0.5),
    },
    visibilityButton: {
        alignSelf: "center",
        paddingLeft: theme.spacing(1),
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        // flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    nameSkeleton: {
        height: 22,
        width: 80,
        [theme.breakpoints.up("sm")]: {
            height: 26,
            width: 120,
        },
    },
    descriptionSkeleton: {
        height: 16,
        width: 120,
        [theme.breakpoints.up("sm")]: {
            height: 20,
            width: 220,
        },
    },
    balanceSkeleton: {
        height: 32,
        width: 100,
        [theme.breakpoints.up("sm")]: {
            height: 40,
            width: 140,
        },
    },
    subActionLink: {
        cursor: "pointer",
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    customCard: {
        display: "flex",
        flexDirection: "column",
        justifyItems: "flex-start",
    },
    tdSpecialRate: {
        width: "100%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1, 2),
        backgroundColor: "rgba(86, 61, 130, 0.05)",
        borderRadius: theme.shape.radiusLevels[3],
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    tdSpecialRateImg: {
        maxWidth: 40,
        height: 40,
        flex: 1,
        display: "flex",
        marginRight: theme.spacing(1),
    },
    tdSpecialRateText: {
        textAlign: "left",
    },
}));

const maskStr = "●●●●●●●";

const AccountCard = ({
    no,
    quantity,
    accountType,
    workingBalance,
    availableAmount,
    onClick,
    loading,
    subAction,
    hiddenBalance,
    noBalance,
    direct,
    link,
    termDepositRate,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
    const { t } = useTranslation();
    const { formatNumber, formatCardNumber, formatUnit } = useNumber();
    const history = useHistory();

    const [isHidden, setIsHidden] = useState(hiddenBalance);

    const description = useMemo(() => {
        const content = t(`dashboard:account_${accountType}`, {
            returnObjects: true,
        });
        switch (accountType) {
            case AccountType.SpendAccount:
                if (!isSmUp) {
                    return no;
                }
                return direct ? no : `${t("lb_account_number")}: ${no}`;
            case AccountType.CreditCard:
                return formatCardNumber(no, true);
            case AccountType.GoalSave:
            case AccountType.TermDeposit:
            case AccountType.MoneyPot:
            case AccountType.SplitBill:
            default:
                if (quantity > 1) {
                    return content?.subtitle_datas?.replace("%@", quantity);
                }
                if (quantity > 0) {
                    return content?.subtitle_data;
                }
                return content?.subtitle;
        }
    }, [t, accountType, isSmUp, direct, no, formatCardNumber, quantity]);

    const hasSub = useMemo(() => !!subAction, [subAction]);

    const name = useMemo(() => {
        return t(`dashboard:account_${accountType}`, { returnObjects: true })
            ?.title;
    }, [t, accountType]);

    const amount = useMemo(() => {
        const amountValue =
            accountType === AccountType.CreditCard
                ? formatNumber(availableAmount)
                : formatNumber(workingBalance);
        return t("dashboard:currency")?.replace("%@", amountValue);
    }, [accountType, availableAmount, workingBalance, t, formatNumber]);

    const toggleHidden = useCallback((action) => {
        if (isFunction(action.stopPropagation)) {
            action.stopPropagation();
        }

        if (action === "enter") {
            setIsHidden(false);
            return;
        }

        if (action === "leave") {
            setIsHidden(true);
            return;
        }

        setIsHidden((value) => !value);
    }, []);

    const handleMouseEvent = useCallback(
        (action) => {
            if (!hiddenBalance) {
                return;
            }
            toggleHidden(action);
        },
        [hiddenBalance, toggleHidden]
    );
    const maxInterestRateBonus = useMemo(() => {
        return maxBy(
            termDepositRate?.items || [],
            (item) => item.interestRateBonus
        );
    }, [termDepositRate]);

    const closeRatesDialog = () => {
        GlobalDialogController.hide();
    };

    const handleDirectTD = (tdLink) => {
        history.push(tdLink);
    };

    const handleOnClick = (event) => {
        event.stopPropagation();

        GlobalDialogController.show({
            component: () => (
                <InterestRatesDialog
                    open
                    onClose={closeRatesDialog}
                    onDirect={(tdLink) => handleDirectTD(tdLink)}
                />
            ),
        });
    };

    const tdLabel = () => {
        const translateTd = t("TD:td_special_rate_entry_point")
            .replace(
                "%@",
                `<strong>${formatNumber(
                    maxInterestRateBonus?.interestRateBonus
                )}${t("TD:td_interest_table_unit_detail")}</strong>`
            )
            .replace("#@", formatUnit(termDepositRate?.value))
            .replace("^@", "");
        return (
            <>
                <Interweave content={translateTd} />
                <LspTypography
                    variant="hyperlink1"
                    color="success"
                    component="span"
                    onClick={(event) => handleOnClick(event)}
                >
                    {t("TD:td_special_rate_learn_more")}
                </LspTypography>
            </>
        );
    };

    if (loading) {
        return (
            <div className={classes.root}>
                <div className={classes.left}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        className={classes.nameSkeleton}
                    />
                    <Skeleton
                        animation="wave"
                        variant="text"
                        className={classes.descriptionSkeleton}
                    />
                </div>
                <div className={classes.right}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        className={classes.balanceSkeleton}
                    />
                </div>
            </div>
        );
    }

    const handleDirect = ($event) => {
        $event.stopPropagation();
        history.push(link);
    };

    return (
        <Box className={classes.root}>
            <Box display="flex">
                <ButtonBase
                    className={clsx(
                        classes.accountCard,
                        { hasSub },
                        classes.customCard
                    )}
                    onClick={onClick}
                    disableRipple
                    onMouseEnter={() => handleMouseEvent("enter")}
                    onMouseLeave={() => handleMouseEvent("leave")}
                    datatestid={accountType}
                >
                    <div className={classes.container}>
                        <div className={classes.left}>
                            <LspTypography variant="heading2">
                                {name}
                            </LspTypography>
                            {direct ? (
                                <>
                                    <LspTypography
                                        variant="hyperlink1"
                                        color="success"
                                        onClick={handleDirect}
                                        component="span"
                                    >
                                        <span
                                            className={`${classes.accountCardIcon} icon-bank-v2`}
                                        />
                                        {description}
                                    </LspTypography>
                                </>
                            ) : (
                                <LspTypography variant="body2" color="grey">
                                    {description}
                                </LspTypography>
                            )}
                        </div>
                        {!noBalance && (
                            <div className={classes.right}>
                                {isHidden ? (
                                    <>
                                        {isSmUp && (
                                            <LspTypography
                                                variant="title2"
                                                color="primary"
                                            >
                                                {maskStr}
                                            </LspTypography>
                                        )}
                                    </>
                                ) : (
                                    <LspTypography
                                        variant="title2"
                                        color="primary"
                                    >
                                        {amount}
                                    </LspTypography>
                                )}
                            </div>
                        )}
                        {hiddenBalance && !isSmUp && (
                            <div className={classes.visibilityButton}>
                                <IconButton size="small" onClick={toggleHidden}>
                                    {isHidden ? (
                                        <VisibilityOnIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </div>
                        )}
                    </div>
                    {accountType === AccountType.TermDeposit &&
                        termDepositRate?.isAvailableTdRate && (
                            <div className={classes.tdSpecialRate}>
                                <div className={classes.tdSpecialRateImg}>
                                    <img
                                        src={discountImg}
                                        alt="Discount Icon"
                                    />
                                </div>
                                <LspTypography
                                    variant="body3"
                                    color="black"
                                    className={classes.tdSpecialRateText}
                                >
                                    {tdLabel()}
                                </LspTypography>
                            </div>
                        )}
                </ButtonBase>
            </Box>
            {hasSub && (
                <>
                    <Divider className={classes.divider} />
                    <div className={clsx(classes.accountCard, "sub")}>
                        <Box flex={1}>
                            <LspTypography variant="body2">
                                {subAction.name}
                            </LspTypography>
                        </Box>
                        <LspTypography
                            component="a"
                            variant="hyperlink1"
                            color="secondary"
                            onClick={subAction.onClick}
                            className={classes.subActionLink}
                        >
                            {subAction.actionLabel}
                        </LspTypography>
                    </div>
                </>
            )}
        </Box>
    );
};

export default memo(AccountCard);
