/* eslint-disable react/no-array-index-key */
import { memo, useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import LspTextField from "@components/LspTextField";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import LspAddress from "@components/LspAddress";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Paper,
} from "@material-ui/core";
import usePhoneNumber from "@helpers/usePhoneNumber";
import useCapitalize from "@helpers/useCapitalize";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

import {
    WEALTH_CREDIT_CARD_STEPS,
    WEALTH_MARITAL_STATUS_KEYS,
} from "../wealthConstants";

const WealthCreditCardMaritalStatusForm = ({
    onNext,
    onBack,
    setMaritalStatusForm,
    maritalStatusForm,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const { isPhoneNumberValid } = usePhoneNumber();
    const { capitalizeSentence } = useCapitalize();

    const [referencePerson1Errors, setReferencePerson1Errors] = useState({
        fullName: null,
        phoneNumber: null,
        address: null,
        province: null,
        district: null,
        ward: null,
    });

    const [referencePerson2Errors, setReferencePerson2Errors] = useState({
        fullName: null,
        phoneNumber: null,
        address: null,
        province: null,
        district: null,
        ward: null,
    });

    const [spouseInfoErrors, setSpouseInfoErrors] = useState({
        fullName: null,
        phoneNumber: null,
    });

    const [maritalStatusOtherError, setMaritalStatusOtherError] = useState("");

    const MARITAL_STATUS_LIST = useMemo(
        () => [
            {
                cfgKey: WEALTH_MARITAL_STATUS_KEYS.SINGLE,
                cfgValue: t("wealth:mc_v3_marital_opt1"),
            },
            {
                cfgKey: WEALTH_MARITAL_STATUS_KEYS.MARRIED,
                cfgValue: t("wealth:mc_v3_marital_opt2"),
            },
            {
                cfgKey: WEALTH_MARITAL_STATUS_KEYS.OTHERS,
                cfgValue: t("wealth:mc_v3_marital_opt4"),
            },
        ],
        [t]
    );

    // ------ Start: Init params ------

    const [maritalStatus, setMaritalStatus] = useState(
        maritalStatusForm?.maritalStatus
            ? maritalStatusForm?.maritalStatus
            : null
    );
    const [maritalStatusOther, setMaritalStatusOther] = useState(
        maritalStatusForm?.maritalStatusOther
            ? maritalStatusForm?.maritalStatusOther
            : ""
    );

    const [spouseInfo, setSpouseInfo] = useState(
        maritalStatusForm?.spouseInfo || {
            fullName: "",
            phoneNumber: "",
        }
    );

    // Reference person 1
    const [fullNameReferencePerson1, setFullNameReferencePerson1] = useState(
        maritalStatusForm?.referencePerson1?.fullName
            ? maritalStatusForm?.referencePerson1?.fullName
            : ""
    );
    const [
        phoneNumberReferencePerson1,
        setPhoneNumberReferencePerson1,
    ] = useState(
        maritalStatusForm?.referencePerson1?.mobilePhone
            ? maritalStatusForm?.referencePerson1?.mobilePhone
            : ""
    );

    const [addressReferencePerson1, setAddressReferencePerson1] = useState(
        maritalStatusForm?.referencePerson1?.address
            ? maritalStatusForm?.referencePerson1?.address
            : ""
    );

    const [
        selectedProvinceReferencePerson1,
        setSelectedProvinceReferencePerson1,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson1?.province
            ? maritalStatusForm?.referencePerson1?.province
            : null;
    });

    const [
        selectedDistrictReferencePerson1,
        setSelectedDistrictReferencePerson1,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson1?.district
            ? maritalStatusForm?.referencePerson1?.district
            : null;
    });

    const [
        selectedWardReferencePerson1,
        setSelectedWardReferencePerson1,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson1?.ward
            ? maritalStatusForm?.referencePerson1?.ward
            : null;
    });

    // Reference person 2
    const [fullNameReferencePerson2, setFullNameReferencePerson2] = useState(
        maritalStatusForm?.referencePerson2?.fullName
            ? maritalStatusForm?.referencePerson2?.fullName
            : ""
    );
    const [
        phoneNumberReferencePerson2,
        setPhoneNumberReferencePerson2,
    ] = useState(
        maritalStatusForm?.referencePerson2?.mobilePhone
            ? maritalStatusForm?.referencePerson2?.mobilePhone
            : ""
    );
    const [addressReferencePerson2, setAddressReferencePerson2] = useState(
        maritalStatusForm?.referencePerson2?.address
            ? maritalStatusForm?.referencePerson2?.address
            : ""
    );

    const [
        selectedProvinceReferencePerson2,
        setSelectedProvinceReferencePerson2,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson2?.province
            ? maritalStatusForm?.referencePerson2?.province
            : {
                  cfgKey: "",
                  cfgValue: "",
              };
    });

    const [
        selectedDistrictReferencePerson2,
        setSelectedDistrictReferencePerson2,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson2?.district
            ? maritalStatusForm?.referencePerson2?.district
            : {
                  cfgKey: "",
                  cfgValue: "",
              };
    });

    const [
        selectedWardReferencePerson2,
        setSelectedWardReferencePerson2,
    ] = useState(() => {
        return maritalStatusForm?.referencePerson2?.ward
            ? maritalStatusForm?.referencePerson2?.ward
            : {
                  cfgKey: "",
                  cfgValue: "",
              };
    });

    // ------ End: Init params ------

    // ------ Start: On change handler function ------

    const phoneValidation = useCallback(
        (phone) => {
            if (!phone) {
                return t("wealth:mc_v3_error_we_need");
            }
            if (phone.charAt(0) !== "0") {
                return t("wealth:mc_v3_phone_error_not_start_0");
            }
            if (phone.charAt(1) === "0") {
                return t("wealth:mc_v3_error_phone_second_character_not_be_0");
            }

            const isValid = isPhoneNumberValid(phone);

            if (!isValid) {
                return t("wealth:mc_v3_error_phone_invalid");
            }

            return "";
        },
        [t, isPhoneNumberValid]
    );

    const onChangeMaritalStatus = (e) => {
        const { value } = e.target;
        setMaritalStatus(value);
    };

    const onChangeMaritalStatusOther = (e) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.DESCRIPTION_REPLACEMENT,
            text: e.target.value,
        });

        setMaritalStatusOther(value);

        if (!value) {
            setMaritalStatusOtherError(t("wealth:mc_v3_error_we_need"));
        } else {
            setMaritalStatusOtherError("");
        }
    };

    // Spouse info
    const onChangeFullNameSpouse = (e) => {
        const name = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.OWNER_NAME_REPLACEMENT,
            text: e.target.value,
        });

        setSpouseInfo((prev) => ({
            ...prev,
            fullName: capitalizeSentence(name),
        }));
        if (!name) {
            setSpouseInfoErrors((prev) => ({
                ...prev,
                fullName: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setSpouseInfoErrors((prev) => ({
                ...prev,
                fullName: "",
            }));
        }
    };

    const onChangePhoneNumberSpouse = (e) => {
        const { value } = e.target;

        setSpouseInfo((prev) => ({
            ...prev,
            phoneNumber: replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            }),
        }));

        if (!value) {
            setSpouseInfoErrors((prev) => ({
                ...prev,
                phoneNumber: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setSpouseInfoErrors((prev) => ({
                ...prev,
                phoneNumber: "",
            }));
        }
    };

    // Reference person 1
    const onChangeFullNameReferencePerson1 = (e) => {
        const name = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.OWNER_NAME_REPLACEMENT,
            text: e.target.value,
        });

        setFullNameReferencePerson1(capitalizeSentence(name));
        if (!name) {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                fullName: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                fullName: "",
            }));
        }
    };

    const onChangePhoneNumberReferencePerson1 = (e) => {
        const { value } = e.target;
        setPhoneNumberReferencePerson1(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            })
        );

        if (!value) {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                phoneNumber: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                phoneNumber: "",
            }));
        }
    };

    const onChangeProvinceReferencePerson1 = (province) => {
        if (province?.cfgKey === "") {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                province: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                province: "",
            }));
        }

        setSelectedProvinceReferencePerson1(province);
    };
    const onChangeDistrictReferencePerson1 = (district) => {
        if (district?.cfgKey === "") {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                district: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                district: "",
            }));
        }
        setSelectedDistrictReferencePerson1(district);
    };
    const onChangeWardReferencePerson1 = (ward) => {
        if (ward?.cfgKey === "") {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                ward: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                ward: "",
            }));
        }
        setSelectedWardReferencePerson1(ward);
    };

    const onChangeAddressReferencePerson1 = (e) => {
        setReferencePerson1Errors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressReferencePerson1(address);
        if (!address) {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                address: t("wealth:mc_v3_error_we_need"),
            }));
        }
    };

    // Reference person 2

    const onChangeFullNameReferencePerson2 = (e) => {
        const name = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.OWNER_NAME_REPLACEMENT,
            text: e.target.value,
        });

        setFullNameReferencePerson2(capitalizeSentence(name));
        if (!name) {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                fullName: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                fullName: "",
            }));
        }
    };

    const onChangePhoneNumberReferencePerson2 = (e) => {
        const { value } = e.target;
        setPhoneNumberReferencePerson2(
            replaceSpecialCharacter({
                pattern: REGEX_PATTERN.PHONE_REPLACEMENT,
                text: value,
            })
        );

        if (!value) {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                phoneNumber: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                phoneNumber: "",
            }));
        }
    };

    const onChangeProvinceReferencePerson2 = (province) => {
        if (province?.cfgKey === "") {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                province: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                province: "",
            }));
        }

        setSelectedProvinceReferencePerson2(province);
    };
    const onChangeDistrictReferencePerson2 = (district) => {
        if (district?.cfgKey === "") {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                district: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                district: "",
            }));
        }
        setSelectedDistrictReferencePerson2(district);
    };
    const onChangeWardReferencePerson2 = (ward) => {
        if (ward?.cfgKey === "") {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                ward: t("wealth:mc_v3_error_we_need"),
            }));
        } else {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                ward: "",
            }));
        }
        setSelectedWardReferencePerson2(ward);
    };

    const onChangeAddressReferencePerson2 = (e) => {
        setReferencePerson1Errors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressReferencePerson2(address);
        if (!address) {
            setReferencePerson2Errors((prev) => ({
                ...prev,
                address: t("wealth:mc_v3_error_we_need"),
            }));
        }
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        let isValid = true;

        const phoneRef1Error = phoneValidation(phoneNumberReferencePerson1);
        if (phoneRef1Error !== "") {
            setReferencePerson1Errors((prev) => ({
                ...prev,
                phoneNumber: phoneRef1Error,
            }));
            isValid = false;
        }

        if (maritalStatus === WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()) {
            const phoneSpouseError = phoneValidation(spouseInfo?.phoneNumber);
            if (phoneSpouseError !== "") {
                setSpouseInfoErrors((prev) => ({
                    ...prev,
                    phoneNumber: phoneSpouseError,
                }));
                isValid = false;
            }
        }

        if (maritalStatus !== WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()) {
            const phoneRef2Error = phoneValidation(phoneNumberReferencePerson2);
            if (phoneRef2Error !== "") {
                setReferencePerson2Errors((prev) => ({
                    ...prev,
                    phoneNumber: phoneRef2Error,
                }));
                isValid = false;
            }
        }

        if (!isValid) return;

        const payload = {
            maritalStatus,
            referencePerson1: {
                fullName: fullNameReferencePerson1,
                mobilePhone: phoneNumberReferencePerson1,
                province: selectedProvinceReferencePerson1,
                district: selectedDistrictReferencePerson1,
                ward: selectedWardReferencePerson1,
                address: addressReferencePerson1,
            },
        };

        if (maritalStatus === WEALTH_MARITAL_STATUS_KEYS.OTHERS.toString()) {
            payload.maritalStatusOther = maritalStatusOther;
        }

        if (maritalStatus === WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()) {
            payload.spouseInfo = spouseInfo;
        }

        if (maritalStatus !== WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()) {
            payload.referencePerson2 = {
                fullName: fullNameReferencePerson2,
                mobilePhone: phoneNumberReferencePerson2,
                province: selectedProvinceReferencePerson2,
                district: selectedDistrictReferencePerson2,
                ward: selectedWardReferencePerson2,
                address: addressReferencePerson2,
            };
        }

        setMaritalStatusForm(payload);
        onNext();
    };

    const basicInfoInvalid = useCallback(() => {
        return (
            !maritalStatus ||
            !fullNameReferencePerson1 ||
            !phoneNumberReferencePerson1 ||
            !addressReferencePerson1 ||
            !selectedProvinceReferencePerson1?.cfgKey === "" ||
            !selectedDistrictReferencePerson1?.cfgKey === "" ||
            !selectedWardReferencePerson1?.cfgKey === "" ||
            referencePerson1Errors?.phoneNumber !== ""
        );
    }, [
        maritalStatus,
        fullNameReferencePerson1,
        phoneNumberReferencePerson1,
        addressReferencePerson1,
        selectedProvinceReferencePerson1,
        selectedDistrictReferencePerson1,
        selectedWardReferencePerson1,
        referencePerson1Errors?.phoneNumber,
    ]);

    const spouseInfoInvalid = useCallback(() => {
        return (
            spouseInfo?.fullName === "" ||
            spouseInfo?.phoneNumber === "" ||
            spouseInfoErrors?.phoneNumber !== ""
        );
    }, [spouseInfo, spouseInfoErrors?.phoneNumber]);

    const referencePerson2Invalid = useCallback(() => {
        return (
            !fullNameReferencePerson2 ||
            !phoneNumberReferencePerson2 ||
            !addressReferencePerson2 ||
            !selectedProvinceReferencePerson2?.cfgKey === "" ||
            !selectedDistrictReferencePerson2?.cfgKey === "" ||
            !selectedWardReferencePerson2?.cfgKey === "" ||
            referencePerson2Errors?.phoneNumber !== ""
        );
    }, [
        fullNameReferencePerson2,
        phoneNumberReferencePerson2,
        addressReferencePerson2,
        selectedProvinceReferencePerson2,
        selectedDistrictReferencePerson2,
        selectedWardReferencePerson2,
        referencePerson2Errors?.phoneNumber,
    ]);

    // useEffect(() => console.log("housingSituation", typeof housingSituation), [
    //     housingSituation,
    // ]);

    const onBackHandler = () => {
        setMaritalStatusForm(null);
        onBack();
    };

    // ------ End: On submit form handler function ------

    return (
        <form onSubmit={onSubmitForm}>
            <PageHeaderBack onBack={onBackHandler}>
                {t("wealth:mc_v3_per_inf_title")}
            </PageHeaderBack>
            <Paper>
                <div className={sharedClasses.formHeader}>
                    <LspTypography variant="heading3" color="grey">
                        {t("wealth:mc_v3_marital_title")}
                    </LspTypography>
                </div>
                <div className={sharedClasses.radioWrapper}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="maritalStatus"
                            name="maritalStatus"
                        >
                            {MARITAL_STATUS_LIST?.map((item) => {
                                return (
                                    <FormControlLabel
                                        key={item.cfgKey}
                                        value={item.cfgKey}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={
                                                    maritalStatus ===
                                                    item?.cfgKey.toString()
                                                }
                                                onChange={onChangeMaritalStatus}
                                            />
                                        }
                                        label={item.cfgValue}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    {maritalStatus ===
                        WEALTH_MARITAL_STATUS_KEYS.OTHERS.toString() && (
                        <LspTextField
                            error={!!maritalStatusOtherError}
                            name="maritalStatusOther"
                            label={t("wealth:mc_v3_answer_lb")}
                            value={maritalStatusOther}
                            helperText={maritalStatusOtherError || ""}
                            onChange={onChangeMaritalStatusOther}
                        />
                    )}
                </div>

                {/* For married status  */}

                {maritalStatus &&
                    maritalStatus ===
                        WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString() && (
                        <>
                            <div className={sharedClasses.formHeader}>
                                <LspTypography variant="heading3" color="grey">
                                    {t("wealth:mc_v3_spouse_title")}
                                </LspTypography>
                            </div>

                            <div className={sharedClasses.multipleFormWrapper}>
                                <LspTextField
                                    error={!!spouseInfoErrors?.fullName}
                                    name="fullNameSpouse"
                                    label={t("wealth:mc_v3_reference_name_lb")}
                                    value={spouseInfo?.fullName}
                                    helperText={
                                        spouseInfoErrors?.fullName || ""
                                    }
                                    onChange={onChangeFullNameSpouse}
                                />
                                <LspTextField
                                    error={!!spouseInfoErrors?.phoneNumber}
                                    name="phoneNumberSpouse"
                                    label={t("wealth:mc_v3_reference_phone_lb")}
                                    value={spouseInfo?.phoneNumber}
                                    helperText={
                                        spouseInfoErrors?.phoneNumber || ""
                                    }
                                    onChange={onChangePhoneNumberSpouse}
                                />
                            </div>
                        </>
                    )}

                {/* Reference person 1 */}
                {maritalStatus && (
                    <>
                        <div className={sharedClasses.formHeader}>
                            <LspTypography variant="heading3" color="grey">
                                {maritalStatus !==
                                WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString()
                                    ? t("wealth:mc_v3_reference1_title")
                                    : t("wealth:mc_v3_reference_title")}
                            </LspTypography>
                        </div>
                        <div className={sharedClasses.multipleFormWrapper}>
                            <LspTypography
                                variant="body3"
                                color="black"
                                className={sharedClasses.description}
                            >
                                {t("wealth:mc_v3_reference_subtitle")}
                            </LspTypography>
                            <LspTextField
                                error={!!referencePerson1Errors?.fullName}
                                name="fullNameReferencePerson1"
                                label={t("wealth:mc_v3_reference_name_lb")}
                                value={fullNameReferencePerson1}
                                helperText={
                                    referencePerson1Errors?.fullName || ""
                                }
                                onChange={onChangeFullNameReferencePerson1}
                            />
                            <LspTextField
                                error={!!referencePerson1Errors?.phoneNumber}
                                name="phoneNumberReferencePerson1"
                                label={t("wealth:mc_v3_reference_phone_lb")}
                                value={phoneNumberReferencePerson1}
                                helperText={
                                    referencePerson1Errors?.phoneNumber || ""
                                }
                                onChange={onChangePhoneNumberReferencePerson1}
                            />
                            <LspTypography
                                variant="body3"
                                color="grey"
                                className={sharedClasses.subtitle}
                            >
                                {t("wealth:mc_v3_current_address_title")}
                            </LspTypography>
                            <LspAddress
                                selectedProvince={
                                    selectedProvinceReferencePerson1
                                }
                                onChangeProvince={
                                    onChangeProvinceReferencePerson1
                                }
                                selectedDistrict={
                                    selectedDistrictReferencePerson1
                                }
                                onChangeDistrict={
                                    onChangeDistrictReferencePerson1
                                }
                                selectedWard={selectedWardReferencePerson1}
                                onChangeWard={onChangeWardReferencePerson1}
                                errors={referencePerson1Errors}
                                fullWidth
                            />
                            <LspTextField
                                name="addressReferencePerson1"
                                error={!!referencePerson1Errors?.address}
                                label={t("bill_lb_address")}
                                helperText={
                                    referencePerson1Errors?.address || " "
                                }
                                inputProps={{
                                    maxLength: 35,
                                }}
                                onChange={onChangeAddressReferencePerson1}
                                value={addressReferencePerson1}
                            />
                        </div>
                    </>
                )}

                {/* Reference person 2: for ! married status */}

                {maritalStatus &&
                    maritalStatus !==
                        WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString() && (
                        <>
                            <div className={sharedClasses.formHeader}>
                                <LspTypography variant="heading3" color="grey">
                                    {t("wealth:mc_v3_reference2_title")}
                                </LspTypography>
                            </div>
                            <div className={sharedClasses.multipleFormWrapper}>
                                <LspTypography
                                    variant="body3"
                                    color="black"
                                    className={sharedClasses.description}
                                >
                                    {t("wealth:mc_v3_reference_subtitle")}
                                </LspTypography>
                                <LspTextField
                                    error={!!referencePerson2Errors?.fullName}
                                    name="fullNameReferencePerson2"
                                    label={t("wealth:mc_v3_reference_name_lb")}
                                    value={fullNameReferencePerson2}
                                    helperText={
                                        referencePerson2Errors?.fullName || ""
                                    }
                                    onChange={onChangeFullNameReferencePerson2}
                                />
                                <LspTextField
                                    error={
                                        !!referencePerson2Errors?.phoneNumber
                                    }
                                    name="phoneNumberReferencePerson2"
                                    label={t("wealth:mc_v3_reference_phone_lb")}
                                    value={phoneNumberReferencePerson2}
                                    helperText={
                                        referencePerson2Errors?.phoneNumber ||
                                        ""
                                    }
                                    onChange={
                                        onChangePhoneNumberReferencePerson2
                                    }
                                />

                                <LspTypography
                                    variant="body3"
                                    color="grey"
                                    className={sharedClasses.subtitle}
                                >
                                    {t("wealth:mc_v3_current_address_title")}
                                </LspTypography>
                                <LspAddress
                                    selectedProvince={
                                        selectedProvinceReferencePerson2
                                    }
                                    onChangeProvince={
                                        onChangeProvinceReferencePerson2
                                    }
                                    selectedDistrict={
                                        selectedDistrictReferencePerson2
                                    }
                                    onChangeDistrict={
                                        onChangeDistrictReferencePerson2
                                    }
                                    selectedWard={selectedWardReferencePerson2}
                                    onChangeWard={onChangeWardReferencePerson2}
                                    errors={referencePerson2Errors}
                                    fullWidth
                                />
                                <LspTextField
                                    name="addressReferencePerson2"
                                    error={!!referencePerson2Errors?.address}
                                    label={t("bill_lb_address")}
                                    helperText={
                                        referencePerson2Errors?.address || " "
                                    }
                                    inputProps={{
                                        maxLength: 35,
                                    }}
                                    onChange={onChangeAddressReferencePerson2}
                                    value={addressReferencePerson2}
                                />
                            </div>
                        </>
                    )}
            </Paper>

            {maritalStatus && (
                <LspButton
                    fullWidth
                    type="submit"
                    className={sharedClasses.button}
                    disabled={
                        basicInfoInvalid() ||
                        (maritalStatus ===
                            WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString() &&
                            spouseInfoInvalid()) ||
                        (maritalStatus !==
                            WEALTH_MARITAL_STATUS_KEYS.MARRIED.toString() &&
                            referencePerson2Invalid())
                    }
                >
                    {t("wealth:mc_v3_btn_next")}
                </LspButton>
            )}
        </form>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    maritalStatusForm: state.wealthCreditCard.maritalStatusForm,
});

const mapDispatch = (dispatch) => ({
    setMaritalStatusForm: (payload) =>
        dispatch(wealthCreditCardAction.setMaritalStatusForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardMaritalStatusForm)
);
