import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { call, put } from "redux-saga/effects";
import paymentRequestService from "@services/payment-request";
import PaymentRequestActions from "@redux/actions/payment-request";
import { PAYMENT_REQUEST_ITEM_TYPES } from "@containers/PaymentRequest/paymentRequest.constant";

export function* getPaymentRequestList(action) {
    // eslint-disable-next-line no-console
    console.log("hi...");
    const response = yield call(
        paymentRequestService.getPaymentList,
        action.payload
    );
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
            if (action?.payload?.type === PAYMENT_REQUEST_ITEM_TYPES.OPEN) {
                yield put(
                    PaymentRequestActions.getPaymentRequestListSuccess({
                        ...data,
                        lastId: action?.payload?.lastId,
                        modifyAt: action?.payload?.modifyAt,
                    })
                );
            } else {
                yield put(
                    PaymentRequestActions.getPaymentRequestCompleteListSuccess({
                        ...data,
                        lastId: action?.payload?.lastId,
                        modifyAt: action?.payload?.modifyAt,
                    })
                );
            }
            break;
        default:
            GlobalDialogController.showError({
                errorCode: code,
            });

            if (action?.payload?.type === PAYMENT_REQUEST_ITEM_TYPES.OPEN) {
                yield put(PaymentRequestActions.getPaymentRequestListError());
            } else {
                yield put(
                    PaymentRequestActions.getPaymentRequestCompleteListError()
                );
            }
            break;
    }
}
