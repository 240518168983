import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { LANGUAGE_OPTIONS } from "@config/constants";
import useLanguage from "@helpers/useLanguage";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    language: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        color: theme.palette.white.contrastText,
        "& .active": {
            fontWeight: 600,
        },
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
    },
    white: {
        color: theme.palette.black.contrastText,
    },
}));

const ClaimMoneyLanguage = ({ color }) => {
    const { i18n } = useTranslation();
    const { switchLanguage } = useLanguage();
    const classes = useStyles();

    const currentLanguage = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? LANGUAGE_OPTIONS.EN.toUpperCase()
            : LANGUAGE_OPTIONS.VN.toUpperCase();
    }, [i18n]);

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const languages = Object.keys(LANGUAGE_OPTIONS).filter(
        (item) => item !== LANGUAGE_OPTIONS.VI.toUpperCase()
    );

    return (
        <div
            className={clsx(classes.language, {
                [classes.white]: color === "white",
            })}
        >
            {languages
                .sort((a, b) => b.localeCompare(a))
                .map((item, index) => (
                    <span key={Math.random()}>
                        <span
                            className={`${
                                currentLanguage === item ? "active" : ""
                            }`}
                            onClick={
                                currentLanguage !== item ? changeLanguage : null
                            }
                        >
                            {item}
                        </span>
                        {index !== languages?.length - 1 && <span>|</span>}
                    </span>
                ))}
        </div>
    );
};

export default ClaimMoneyLanguage;
