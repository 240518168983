import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { makeStyles } from "@material-ui/core";
import userAction from "@redux/actions/user";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import BankQRCodeBox from "./BankQRCodeBox";

const useStyles = makeStyles((theme) => ({
    qrCode: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    qrCodeDownload: {
        position: "absolute",
        left: "-2000px",
        padding: theme.spacing(4),
    },
    skeleton: {
        marginBottom: theme.spacing(2),
    },
}));

const BankQRCodeSection = ({
    getBankInfo,
    bankInfo,
    disableDefault,
    defaultInfo,
    hiddenSaveButton,
    activeAmount,
    hiddenUserInfo,
    enableIBFT,
    enableSelectAccount,
    enableFullName,
    bankInfoFetching,
    fetching,
}) => {
    const classes = useStyles();
    const qrCodeRef = useRef();
    const [qrInfo, setQrInfo] = useState({});
    const { t } = useTranslation();
    const [downloadQrCodeInfo, setDownloadQrCodeInfo] = useState({});

    useEffect(() => {
        if (!bankInfo && !disableDefault) {
            getBankInfo();
        }

        if (!disableDefault) {
            setQrInfo(bankInfo);
            const defaultAccount = (bankInfo?.aliasList || []).find(
                (aliasItem) => aliasItem.isDefault
            );
            setDownloadQrCodeInfo({
                ...bankInfo,
                accountNumber: defaultAccount?.alias || bankInfo?.accountNumber,
            });
        }
    }, [bankInfo, getBankInfo, disableDefault]);

    useEffect(() => {
        if (disableDefault) {
            const info = {
                ...defaultInfo,
                accountNumber: defaultInfo?.accNumber || "",
                fullName: defaultInfo?.senderName || "",
            };
            setQrInfo(info);
            setDownloadQrCodeInfo(info);
        }
    }, [defaultInfo, disableDefault]);

    const exportVietQRCode = () => {
        html2canvas(qrCodeRef.current).then((canvas) => {
            const qrDownload = document.createElement("a");
            const currentDate = new Date().getTime();
            qrDownload.download = `timo-vietqr-code-${currentDate}.png`;
            qrDownload.href = canvas.toDataURL("image/png");
            qrDownload.target = "_blank";
            qrDownload.click();
        });
    };

    const fetchingSkeleton = () => {
        return (
            <>
                <div className={classes.skeleton}>
                    <Skeleton variant="rect" width="100%" height={300} />
                </div>
            </>
        );
    };
    return (
        <>
            <Content boxProps={{ textAlign: "center" }}>
                {fetching && fetchingSkeleton()}
                {qrInfo && !fetching && (
                    <>
                        <div ref={qrCodeRef} className={classes.qrCodeDownload}>
                            <BankQRCodeBox
                                bankInfo={downloadQrCodeInfo}
                                disableDefault
                                hiddenSaveButton
                            />
                        </div>
                        <div className={classes.qrCode}>
                            <BankQRCodeBox
                                id="vietQRCode"
                                bankInfo={qrInfo}
                                activeAmount={activeAmount}
                                hiddenUserInfo={hiddenUserInfo}
                                enableIBFT={enableIBFT}
                                enableSelectAccount={enableSelectAccount}
                                enableFullName={enableFullName}
                                fetching={bankInfoFetching}
                            />
                        </div>

                        {!hiddenSaveButton && (
                            <div className="qr-action">
                                <LspButton fullWidth onClick={exportVietQRCode}>
                                    {t("master:lb_save_code")}
                                </LspButton>
                            </div>
                        )}
                    </>
                )}
            </Content>
        </>
    );
};

BankQRCodeSection.propTypes = {
    disableDefault: PropTypes.bool,
    defaultInfo: PropTypes.shape({
        accNumber: PropTypes.string,
        amount: PropTypes.number,
        bankName: PropTypes.string,
        bankShortName: PropTypes.string,
        description: PropTypes.string,
        reminded: PropTypes.bool,
        sender: PropTypes.bool,
        senderId: PropTypes.number,
        senderName: PropTypes.string,
        status: PropTypes.string,
        timoUser: PropTypes.bool,
    }),
    hiddenSaveButton: PropTypes.bool,
    hiddenUserInfo: PropTypes.bool,
    activeAmount: PropTypes.bool,
    enableIBFT: PropTypes.bool,
    enableSelectAccount: PropTypes.bool,
    enableFullName: PropTypes.bool,
    fetching: PropTypes.bool,
};

BankQRCodeSection.defaultProps = {
    disableDefault: false,
    defaultInfo: {},
    hiddenSaveButton: false,
    activeAmount: false,
    hiddenUserInfo: false,
    enableIBFT: false,
    enableSelectAccount: false,
    enableFullName: false,
    fetching: false,
};

const mapState = (state) => ({
    fetchStatus: state.user.bankInfo.status,
    bankInfo: state.user.bankInfo.data,
    bankInfoFetching: state.user.bankInfo.fetching,
});

const mapDispatch = (dispatch) => ({
    getBankInfo: () => dispatch(userAction.getBankInfoRequest()),
});

export default connect(mapState, mapDispatch)(BankQRCodeSection);
