import { Box, CircularProgress, Hidden } from "@material-ui/core";
import { useCallback, useRef, memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import wealthAction from "@redux/actions/wealth";
import { useTranslation } from "react-i18next";
import wealthService from "@services/wealth";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";

import { WEALTH_PRODUCT_KEYS } from "@containers/Wealth/wealthConstants";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LifeLongDocument from "./LifeLongDocument";
import LifeLongProduct from "./LifeLongProduct";

const LifeLongProtect = () => {
    const { t } = useTranslation();
    const classes = useClassesShared();
    const [loading, setLoading] = useState(false);
    const [leftDetail, setLeftDetail] = useState([]);
    const [rightDetail, setRightDetail] = useState([]);
    const [prokeySelected, setProkeySelected] = useState("");
    const [step, setStep] = useState("");

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getSubProduct = useCallback(async () => {
        setLoading(true);
        const { data } = await wealthService.getSubProducts(
            WEALTH_PRODUCT_KEYS.LIFE_INSURANCE_LONG_PROTECT
        );

        if (unmounted.current) return;
        if (data.code !== RESPONSE_CODE.SUCCESS) {
            GlobalDialogController.showError({ errorCode: data.code });
            return;
        }
        setLoading(false);
        const { subProducts } = data.data;
        const rightPackage = [];
        const leftDetailPackage = [];
        if (subProducts) {
            subProducts.forEach((item) => {
                const right = {};
                right.prokey = item.productKey;
                right.title = t(`wealth:SI00024_${item.productKey}`);
                right.desc = t(`wealth:SI00040_${item.productKey}`);
                rightPackage.push(right);

                const left = {};
                left.prokey = item.productKey;
                left.iconHeader = t(`wealth:SI00041_${item.productKey}`);
                left.titleHeader = t(`wealth:SI00024_${item.productKey}`);
                left.descHeader = t(`wealth:SI00025_${item.productKey}`);
                left.content = t(`wealth:SI00027_${item.productKey}`, {
                    returnObjects: true,
                    defaultValue: "",
                });

                leftDetailPackage.push(left);
            });

            setProkeySelected(leftDetailPackage[0].prokey);
            setLeftDetail(leftDetailPackage);
            setRightDetail(rightPackage);
        }
    }, [t]);

    useEffect(() => {
        getSubProduct();
    }, [getSubProduct]);

    return (
        <>
            {loading && (
                <Box textAlign="center" paddingTop={3}>
                    <CircularProgress size={18} />
                </Box>
            )}
            {!loading && (
                <div className={classes.wrapper}>
                    {/* For small devices: hide left side column & document will be display when users click apply on form */}
                    <Hidden smDown>
                        <div className={classes.leftSide}>
                            <LifeLongDocument
                                leftDetail={leftDetail}
                                prokeySelected={prokeySelected}
                                step={step}
                                setStep={setStep}
                            />
                        </div>
                    </Hidden>
                    {/* For small devices:  users click apply > show document > next > submit form */}
                    <div className={classes.rightSide}>
                        <LifeLongProduct
                            rightPackage={rightDetail}
                            setProkeySelected={setProkeySelected}
                            prokeySelected={prokeySelected}
                            step={step}
                            setStep={setStep}
                            leftDetail={leftDetail}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

const mapState = (state) => ({
    responseData: state.wealth.applyProductResponse,
    productList: state.wealth.list,
});

const mapDispatch = (dispatch) => ({
    getProductList: () => dispatch(wealthAction.getProductListRequest()),
});

export default memo(connect(mapState, mapDispatch)(LifeLongProtect));
