import { useCallback } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { InfoIcon } from "@components/LspIcon";
import userAction from "@redux/actions/user";
import { connect } from "react-redux";
import { BalanceVisibility } from "@config/constants";
import NotificationBar from "./NotificationBar";

const HiddenBalanceHintBar = ({ updateSetting }) => {
    const { t } = useTranslation();

    const close = useCallback(() => {
        const payload = {
            onlyUpdateHideBalance: true,
            hideBalance: BalanceVisibility.HideAndNoHint,
        };
        updateSetting(payload);
    }, [updateSetting]);

    return (
        <NotificationBar
            color="blue"
            IconComponent={InfoIcon}
            message={t("master:hide_balance_tip_web")}
            buttonComponent={
                <Button size="small" onClick={close}>
                    <LspTypography variant="button2" color="white">
                        {t("lb_close")}
                    </LspTypography>
                </Button>
            }
        />
    );
};

const mapDispatch = (dispatch) => ({
    updateSetting: (payload) =>
        dispatch(userAction.updateSettingRequest(payload)),
});

export default connect(null, mapDispatch)(HiddenBalanceHintBar);
