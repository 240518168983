import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";

import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import LspTextField from "@components/LspTextField";

const OCCUPATION_KEY_VALUE = "Other";

const ProfileOccupationForm = ({
    successMessage,
    isUpdating,
    onSubmitForm,
    selected,
    errorMessage,
    isEdit,
    toggleEdit,
    list,
}) => {
    const { t } = useTranslation();
    const classes = useProfileSharedClasses();
    const defaultOccupation = useMemo(() => {
        return list?.find((i) => i?.value === OCCUPATION_KEY_VALUE);
    }, [list]);

    const selectedOccupation = useMemo(() => {
        if (!selected) {
            return null;
        }
        return list?.find((i) => i?.value === selected);
    }, [selected, list]);

    const [occupation, setOccupation] = useState(selectedOccupation);
    const [error, setError] = useState("");

    const occupationName = useMemo(() => {
        if (!selected) {
            return t("profile_edit_job_title_notupdate");
        }
        const currentOccupation = list?.filter(
            (item) => item.value === selected
        );

        return currentOccupation[0]?.name || defaultOccupation?.name;
    }, [list, selected, defaultOccupation, t]);

    const toggleEditHandler = useCallback(
        (e) => {
            e.preventDefault();
            setOccupation(selectedOccupation);
            toggleEdit(PROFILE_FORM_TYPES.OCCUPATION);
        },
        [selectedOccupation, toggleEdit]
    );

    const onSubmitHandler = useCallback(
        (e) => {
            e.preventDefault();
            if (!occupation) {
                setError(t("msg_we_need_this"));
                return;
            }

            onSubmitForm(occupation);
        },
        [occupation, onSubmitForm, t]
    );

    const onChangeHandler = ({ value }) => {
        setError("");
        setOccupation(value);
    };

    return (
        <form className={classes.formWrapper} onSubmit={onSubmitHandler}>
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-occupation ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("user_profile_occupation_txt")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {!isUpdating && occupationName}
                        {isUpdating && (
                            <Skeleton
                                width={170}
                                style={{ display: "inline-block" }}
                            />
                        )}
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    <ProfileEditComponent
                        isEdit={isEdit}
                        onEdit={toggleEditHandler}
                        onCancel={toggleEditHandler}
                        updating={isUpdating}
                    />
                </div>
            </div>

            {isEdit && (
                <div className={classes.formDetail}>
                    <Autocomplete
                        options={list}
                        getOptionLabel={(option) => option?.name || ""}
                        disabled={isUpdating}
                        value={occupation}
                        onChange={(_, value) => onChangeHandler({ value })}
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t("ekyc:current_job_dropdown_title")}
                                    error={!!errorMessage || !!error}
                                    helperText={errorMessage || error || " "}
                                    InputProps={inputProps}
                                />
                            );
                        }}
                    />
                </div>
            )}
        </form>
    );
};

export default memo(ProfileOccupationForm);
