import { memo, useCallback, useMemo, useState } from "react";
import AmountAndTerm from "./AmountAndTerm";
import TermDepositReview from "./TermDepositReview";
import useTermDeposit from "./useTermDeposit";

const OpenTermDeposit = () => {
    const { openTDStep } = useTermDeposit();
    const validSteps = useMemo(() => Object.values(openTDStep), [openTDStep]);

    const [step, setStep] = useState({
        previous: [],
        current: openTDStep.amountAndTerm,
        data: null,
    });

    const stepForward = useCallback(
        (stepName) => {
            if (!validSteps.includes(stepName)) {
                return;
            }
            setStep({
                previous: [...step.previous, step.current],
                current: stepName,
            });
        },
        [validSteps, step]
    );

    const stepBack = useCallback(() => {
        if (step.previous.length > 0) {
            const prevStep = step.previous[step.previous.length - 1];
            setStep({
                previous: step.previous.slice(0, step.previous.length - 1),
                current: prevStep,
            });
        }
    }, [step]);

    const [payload, setPayload] = useState({
        amountOfDeposit: 1000000,
        numberOfDeposit: 0,
        termId: -1,
        termName: "",
        detail: [],
    });

    const [suggestion, setSuggestion] = useState();

    return (
        <>
            {step.current === openTDStep.amountAndTerm && (
                <AmountAndTerm
                    payload={payload}
                    setPayload={setPayload}
                    stepForward={stepForward}
                    setSuggestion={setSuggestion}
                />
            )}
            {step.current === openTDStep.termDepositReview && (
                <TermDepositReview
                    payload={payload}
                    stepBack={stepBack}
                    suggestion={suggestion}
                    setSuggestion={setSuggestion}
                />
            )}
        </>
    );
};

export default memo(OpenTermDeposit);
