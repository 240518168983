/* eslint-disable react/no-array-index-key */
import { makeStyles, Paper } from "@material-ui/core";
import { memo, useMemo, useState, useRef, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import LspTextField from "@components/LspTextField";
import useNumber from "@helpers/useNumber";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Autocomplete } from "@material-ui/lab";

import Loading from "@components/Loading";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import PageHeaderBack from "@components/PageHeaderBack";
import useSharedClasses from "./wealthCreditCardSharedClasses";

import {
    WEALTH_CREDIT_CARD_METHOD,
    WEALTH_CREDIT_CARD_STEPS,
    WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS,
} from "../wealthConstants";

const useStyles = makeStyles((theme) => ({
    limitAmountWrapper: {
        textAlign: "center",
    },
    amountWrapper: {
        "& .MuiFormHelperText-root": {
            textAlign: "center",
        },
        "& .MuiInputBase-input": {
            fontSize: 30,
            textAlign: "center",
            color: theme.palette.primary.main,
            paddingTop: theme.spacing(2),
        },
    },
    label: {
        marginBottom: theme.spacing(1),
    },
    suggest: {
        position: "relative",
        "& .MuiFormHelperText-root": {
            fontSize: 14,
        },
        "& .MuiInputBase-input": {
            color: theme.palette.status.success,
        },
    },
    loading: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
    },
}));

const AMOUNT_LIMITATION = {
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE]: {
        min: 6000000,
        max: null,
    },
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD]: {
        min: 10000000,
        max: 500000000,
    },
    [WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD]: {
        min: 20000000,
        max: 300000000,
    },
};

const WealthCreditCardAmountLimitation = ({
    onBack,
    onNext,
    method,
    productKey,
    suggestLimitationForm,
    setSuggestLimitationForm,
    bankList,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const classes = useStyles();
    const { decimalSeparator, thousandSeparator } = useNumber();
    const unmounted = useRef(false);

    const [fetching, setFetching] = useState(false);
    const [selectedBankRef, setSelectedBankRef] = useState(
        suggestLimitationForm?.bankRef || null
    );
    const [amountInput, setAmountInput] = useState(
        {
            formattedValue: suggestLimitationForm?.amountInput,
            value: suggestLimitationForm?.amountInput,
        } || {}
    );
    const [amountSuggestion, setAmountSuggestion] = useState({
        formattedValue: suggestLimitationForm?.amountSuggestion || 0,
        value: suggestLimitationForm?.amountSuggestion || 0,
    });
    const [errors, setErrors] = useState({
        amount: null,
        bankRef: null,
    });

    const isShowBankRef = useMemo(
        () => method === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD,
        [method]
    );
    const amountLimitation = useMemo(() => {
        return AMOUNT_LIMITATION[method];
    }, [method]);

    const amountInputContent = useMemo(() => {
        switch (method) {
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE:
                return {
                    label: t("wealth:mc_v3_monthly_income_question"),
                    hint: t("wealth:mc_v3_monthly_income_note1"),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD:
                return {
                    label: t("wealth:mc_v3_lock_td_question"),
                    hint: t("wealth:mc_v3_lock_td_note1"),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD:
                return {
                    label: t("wealth:mc_v3_existing_card_question2"),
                    hint: t("wealth:mc_v3_existing_card_note1"),
                };
            default:
                return {};
        }
    }, [method, t]);

    const suggestInputContent = useMemo(() => {
        switch (method) {
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE:
                return {
                    label: t("wealth:mc_v3_credit_limit_upto"),
                    hint: t("wealth:mc_v3_monthly_income_note2"),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD:
                return {
                    label: t("wealth:mc_v3_lock_td_note2"),
                    hint: t("wealth:mc_v3_lock_td_note3"),
                };
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD:
                return {
                    label: t("wealth:mc_v3_credit_limit_upto"),
                    hint: t("wealth:mc_v3_existing_card_note2"),
                };
            default:
                return {};
        }
    }, [method, t]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onChangeAmountInput = ({ formattedValue, value }) => {
        setAmountInput({ formattedValue, value: +value });

        if (
            +value < amountLimitation.min ||
            (amountLimitation.max && +value > amountLimitation.max)
        ) {
            setErrors((prev) => ({
                ...prev,
                amount: amountInputContent?.hint,
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                amount: null,
            }));
        }
    };

    const onChangeAmountSuggest = ({ formattedValue, value }) => {
        setAmountSuggestion({ formattedValue, value: +value });
    };

    const onSubmitForm = () => {
        setSuggestLimitationForm({
            amountInput: amountInput?.value,
            amountSuggestion: amountSuggestion?.value,
            bankRef: selectedBankRef,
        });
        onNext(WEALTH_CREDIT_CARD_STEPS.PERSONAL_INFO_FORM);
    };

    const requestAmountSuggestion = useCallback(async () => {
        if (
            fetching ||
            !amountInput?.value ||
            errors?.amount ||
            errors?.bankRef ||
            (isShowBankRef && !selectedBankRef)
        )
            return;

        setFetching(true);

        const payload = {
            productKey,
            type: "getcreditlimit",
            method: WEALTH_CREDIT_CARD_METHOD[method],
        };

        if (method === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE) {
            payload.monthlyCurrentIncome = amountInput?.value;
        } else if (method === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD) {
            payload.cardLimitExpected = amountInput?.value;
        } else {
            payload.otherCardLimit = amountInput?.value;
            payload.bankingGroup = selectedBankRef.bankGroup;
        }

        const response = await wealthService.actionWealthSubProducts(payload);

        if (unmounted.current) return;

        const { code, data } = response.data;
        if (code === RESPONSE_CODE.SUCCESS) {
            setAmountSuggestion({
                value: data.amount,
                formattedValue: data.amount,
            });
        } else {
            GlobalDialogController.showError({ errorCode: code });
        }

        setFetching(false);
    }, [
        fetching,
        productKey,
        method,
        amountInput,
        errors,
        isShowBankRef,
        selectedBankRef,
    ]);

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            requestAmountSuggestion();
        }
    };

    const onBlurHandler = () => {
        requestAmountSuggestion();
    };

    const onBankChange = ({ value }) => {
        setErrors((prev) => ({
            ...prev,
            bankRef: null,
        }));
        setSelectedBankRef(value);
        requestAmountSuggestion();
    };

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                {t("wealth:mc_v3_credit_limit_header")}
            </PageHeaderBack>
            <Paper
                className={`highlight ${sharedClasses.boxWrapper} ${classes.limitAmountWrapper}`}
            >
                {isShowBankRef && (
                    <Autocomplete
                        options={bankList}
                        getOptionLabel={(option) => option?.bankName || ""}
                        renderOption={(option) => (
                            <>{!option.isRemove && option.bankName}</>
                        )}
                        value={selectedBankRef}
                        onChange={(_, value) => onBankChange({ value })}
                        renderInput={(params) => {
                            const inputProps = params.InputProps;
                            return (
                                <LspTextField
                                    {...params}
                                    label={t(
                                        "wealth:mc_v3_existing_card_question1"
                                    )}
                                    error={!!errors.bankRef}
                                    helperText={errors.bankRef || " "}
                                    InputProps={inputProps}
                                    disabled={fetching}
                                />
                            );
                        }}
                    />
                )}

                <LspTypography
                    color="grey"
                    variant="heading2"
                    className={classes.label}
                >
                    {amountInputContent?.label}
                </LspTypography>
                <div className={classes.amountWrapper}>
                    <NumberFormat
                        value={amountInput.formattedValue}
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        onValueChange={onChangeAmountInput}
                        error={!!errors?.amount}
                        helperText={errors?.amount || amountInputContent?.hint}
                        customInput={LspTextField}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        allowedDecimalSeparators={false}
                        onKeyDown={onKeyDownHandler}
                        onBlur={onBlurHandler}
                        disabled={fetching}
                    />
                </div>

                <br />
                <br />

                <LspTypography
                    color="grey"
                    variant="heading2"
                    className={classes.label}
                >
                    {suggestInputContent?.label}
                </LspTypography>
                <div className={`${classes.suggest} ${classes.amountWrapper}`}>
                    <NumberFormat
                        value={amountSuggestion.formattedValue}
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        onValueChange={onChangeAmountSuggest}
                        customInput={LspTextField}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        allowedDecimalSeparators={false}
                        helperText={suggestInputContent?.hint}
                        disabled
                    />

                    {fetching && (
                        <div className={classes.loading}>
                            <Loading />
                        </div>
                    )}
                </div>
            </Paper>
            <LspButton
                fullWidth
                onClick={onSubmitForm}
                disabled={
                    !amountSuggestion.value ||
                    (isShowBankRef && !selectedBankRef) ||
                    fetching
                }
            >
                {t("wealth:mc_v3_btn_apply")}
            </LspButton>
        </>
    );
};

const mapState = (state) => ({
    suggestLimitationForm: state.wealthCreditCard.suggestLimitationForm,
    bankList: state.bank.bankList.data,
});
const mapDispatch = (dispatch) => ({
    setSuggestLimitationForm: (payload) =>
        dispatch(wealthCreditCardAction.setSuggestLimitationForm(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardAmountLimitation)
);
