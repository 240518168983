import { memo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "@containers/Login";
import ClaimMoneyForPayAnyone from "@containers/SpendAccount/MoveMoney/ClaimMoneyPayAnyone";
import RetrieveUserName from "@containers/RetrieveUserName";
import ResetPassword from "@containers/ResetPassword";
import ForgotPassword from "@containers/ForgotPassword";
import Activation from "@containers/Activation";
import SplitBills from "@containers/SplitBills";
import SplitBillPayment from "@containers/SplitBills/payment";
import VerifyEmailMobileJoin from "@containers/VerifyEmailMobileJoin";
import LoginCommunity from "@containers/LoginCommunity";
import { GUEST_ROUTES_PATH } from "./routes.constant";

const GuestRoutes = () => {
    return (
        <Switch>
            <Route path={GUEST_ROUTES_PATH.LOGIN} component={Login} />
            <Route
                path={GUEST_ROUTES_PATH.RETRIEVE_USERNAME}
                component={RetrieveUserName}
            />
            <Route
                path={GUEST_ROUTES_PATH.RESET_PASSWORD}
                component={ResetPassword}
            />
            <Route
                path={GUEST_ROUTES_PATH.SHARE_MONEY_WITH_LINK}
                component={ClaimMoneyForPayAnyone}
            />
            <Route
                exact
                path={GUEST_ROUTES_PATH.SPLIT_BILL}
                component={SplitBills}
            />
            <Route
                exact
                path={GUEST_ROUTES_PATH.SPLIT_BILL_PAYMENT}
                component={SplitBillPayment}
            />
            <Route
                path={GUEST_ROUTES_PATH.FORGOT_PASSWORD}
                component={ForgotPassword}
            />
            <Route path={GUEST_ROUTES_PATH.ACTIVATION} component={Activation} />
            <Route
                path={GUEST_ROUTES_PATH.VERIFY_EMAIL_MOBILE_JOIN}
                component={VerifyEmailMobileJoin}
            />
            <Route
                path={GUEST_ROUTES_PATH.LOGIN_COMMUNITY}
                component={LoginCommunity}
            />
            <Route>
                <Redirect to={GUEST_ROUTES_PATH.LOGIN} />
            </Route>
        </Switch>
    );
};

export default memo(GuestRoutes);
