import { format, parse } from "date-fns";
import { PAYMENT_LABEL_STATUS } from "@config/constants";

const mapPaymentRequestList = (currentList = [], newList) => {
    const mapData = (list) => {
        const mappedList = list?.map((payeeCurrent) => {
            const milisecondTime = format(
                parse(
                    payeeCurrent.modifyDate,
                    "dd/MM/yyyy HH:mm:ss",
                    new Date()
                ),
                "t"
            );

            let reason = payeeCurrent.reason ? payeeCurrent.reason : "";
            if (reason !== "") {
                reason = reason.split(" ");
            }

            const FEIndexId = payeeCurrent.payeePaymentId
                ? payeeCurrent.payeePaymentId
                : payeeCurrent.paymentId;

            let nameDisplayCustom = "???";

            if (payeeCurrent.nameDisplay) {
                nameDisplayCustom = payeeCurrent.nameDisplay;
            } else if (payeeCurrent.payeeList[0]) {
                nameDisplayCustom = payeeCurrent.payeeList[0].receiveName;
            }

            let titleContent = "";

            if (payeeCurrent.status === "OPEN") {
                titleContent = payeeCurrent.isSent
                    ? "payment_lb_request_to"
                    : "payment_lb_request_from";
            } else {
                titleContent = payeeCurrent.isSent
                    ? "payment_lb_received_from"
                    : "payment_lb_request_paid_to";
            }

            let labelStatusDisplay = {};

            if (!!payeeCurrent.labelStatus && !payeeCurrent.isDeletePopup) {
                if (
                    payeeCurrent.labelStatus === PAYMENT_LABEL_STATUS.NEW &&
                    !payeeCurrent.isSent
                ) {
                    labelStatusDisplay = {
                        text: "lb_status_new",
                        color: "green",
                        icon: "",
                    };
                } else if (
                    payeeCurrent.labelStatus === PAYMENT_LABEL_STATUS.REMIND &&
                    !payeeCurrent.isSent
                ) {
                    labelStatusDisplay = {
                        text: "payment_lb_status_reminder",
                        color: "orange",
                        icon: "timo-remind",
                    };
                } else if (
                    payeeCurrent.labelStatus === PAYMENT_LABEL_STATUS.CANCEL
                ) {
                    labelStatusDisplay = {
                        text: "payment_lb_status_canceled",
                        color: "red",
                        icon: " timo-cancel",
                    };
                } else if (
                    payeeCurrent.labelStatus === PAYMENT_LABEL_STATUS.DELETED
                ) {
                    labelStatusDisplay = {
                        text: "payment_lb_status_deleted",
                        color: "red",
                        icon: "timo-trashbin",
                    };
                }
            }

            let avatar = null;

            if (payeeCurrent.avatar) {
                avatar = payeeCurrent.avatar;
            }

            return {
                ...payeeCurrent,
                milisecondTime,
                FEReason: reason[0]?.toLowerCase(),
                FEIndexId,
                nameDisplayCustom,
                titleContent,
                labelStatusDisplay,
                avatar,
            };
        });

        return mappedList;
    };

    if (!Array.isArray(newList) || newList.length === 0) {
        return currentList;
    }

    const mappedNewList = mapData(newList);

    return [...currentList, ...mappedNewList];
};

export default mapPaymentRequestList;
