import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import moveMoneyService from "@services/move-money";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import sha512 from "crypto-js/sha512";
import i18next from "i18next";
import useOTP from "@helpers/useOTP";
import i18n from "@i18n/";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { isFunction } from "lodash";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { CLAIM_MONEY_SCREENS } from "./constant";

const ClaimMoneySecurityOptions = ({
    classes,
    detail,
    step,
    refNo,
    token,
    onResult,
}) => {
    const { t } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const { phoneNumber, paoType } = useMemo(() => detail, [detail]);

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [fetching, setFetching] = useState(false);
    const [password, setPassword] = useState("");
    const [phoneNumberInput, setPhoneNumberInput] = useState("");
    const [errors, setErrors] = useState({
        phoneNumber: "",
        password: "",
    });
    const newRefNo = useRef();
    const newToken = useRef();
    const hashedPassword = useRef();
    const phoneRegex = useMemo(() => {
        return new RegExp(
            t("sysParamContent:PhoneNumberValidationRegexPattern"),
            "g"
        );
    }, [t]);

    useEffect(() => {
        newRefNo.current = refNo;
        newToken.current = token;
    }, [refNo, token]);

    const unmounted = useRef();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const isShowPhoneNumberField = useMemo(() => {
        if (
            step === CLAIM_MONEY_SCREENS.INPUT_PASSWORD &&
            phoneNumber &&
            phoneNumber !== ""
        ) {
            return true;
        }
        if (step === CLAIM_MONEY_SCREENS.INPUT_OTP) {
            return true;
        }
        return false;
    }, [phoneNumber, step]);

    const isShowPasswordField = useMemo(
        () => step === CLAIM_MONEY_SCREENS.INPUT_PASSWORD,
        [step]
    );

    const isDisabledSubmitButton = useMemo(() => {
        return (
            (isShowPhoneNumberField && !phoneNumberInput) ||
            (isShowPasswordField && !password)
        );
    }, [
        isShowPhoneNumberField,
        isShowPasswordField,
        password,
        phoneNumberInput,
    ]);

    const formatPhoneNumber = (phone) => {
        if (phone === "0123456789" || phone.length < 4) {
            return "";
        }
        if (phone.substring(0, 2) === "84") {
            return `0${phone.substring(2)}`;
        }
        if (phone.substring(0, 3) === "+84") {
            return `0${phone.substring(3)}`;
        }
        if (phone.substring(0, 4) === "0084") {
            return `0${phone.substring(4)}`;
        }
        return phone;
    };

    const onChangePasswordHandler = (value) => {
        setErrors(() => ({ ...errors, password: "" }));
        setPassword(value);
    };

    const onChangePhoneNumberHandler = (value) => {
        setErrors(() => ({ ...errors, phoneNumber: "" }));

        const phone = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER,
            text: value,
        });

        setPhoneNumberInput(phone);
    };

    const verifySecurityResponseHandler = useCallback(
        (response) => {
            const { code, data, secData } = response.data;

            if (secData?.token) {
                newToken.current = response?.secData?.token;
            }

            if (data?.refNo) {
                newRefNo.current = data?.refNo;
            }
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                case RESPONSE_CODE.WRONG_PASSWORD_MANY_TIMES:
                    closeOTPDialog();
                    if (isFunction(onResult)) {
                        onResult({
                            secData,
                            code,
                            data: {
                                ...data,
                                hashedPassword: hashedPassword.current,
                            },
                        });
                    }
                    break;
                case RESPONSE_CODE.UNAUTHORIZED:
                    closeOTPDialog();
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: t("payAnyone:pao_enter_error_msg"),
                    }));
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },

        [t, closeOTPDialog, onResult]
    );

    const verifySecurityResendOtp = useCallback(async () => {
        const response = await moveMoneyService.claimMoneyResendOtp({
            paoType,
            lang: i18n.language,
            token: newToken.current,
            refNo: newRefNo.current,
            notification: "sms",
        });

        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                if (response?.secData?.token) {
                    newToken.current = response?.secData?.token;
                }

                if (data?.refNo) {
                    newRefNo.current = data?.refNo;
                }
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    }, [paoType]);

    const verifySecurityCommitOtp = useCallback(
        async (dataToPost) => {
            setLoading(true);
            const response = await moveMoneyService.claimMoneyCommitOtp({
                ...dataToPost,
                paoType,
                token: newToken.current,
                refNo: newRefNo.current,
            });

            if (unmounted.current) {
                return;
            }

            setLoading(false);
            verifySecurityResponseHandler(response);
        },
        [verifySecurityResponseHandler, paoType, setLoading]
    );

    const onSubmitHandler = useCallback(
        async (e) => {
            e.preventDefault();

            if (isShowPhoneNumberField) {
                const phone = formatPhoneNumber(phoneNumberInput);
                const isPhoneValid = phoneRegex?.test(phone);
                if (!isPhoneValid) {
                    setErrors({
                        phoneNumber: t("topup_msg_invalid_mobile"),
                    });
                    return;
                }
            }

            setFetching(true);
            const payload = {
                refNo: newRefNo.current,
                token: newToken.current,
                paoType,
                lang: i18next.language,
            };

            if (password) {
                const encryptedPassword = sha512(password).toString();
                hashedPassword.current = encryptedPassword;
                payload.secretCode = encryptedPassword;
            }

            if (phoneNumberInput) {
                payload.phoneNumber = phoneNumberInput;
            }

            const response = await moveMoneyService.verifySecurityClaimMoney(
                payload
            );

            if (unmounted.current) {
                return;
            }

            const { code, data } = response.data;
            setFetching(false);

            switch (code) {
                case RESPONSE_CODE.OTP_REQUIRED:
                    if (response?.secData?.token) {
                        newToken.current = response?.secData?.token;
                    }

                    if (data?.refNo) {
                        newRefNo.current = data?.refNo;
                    }
                    openOTPDialog({
                        refNo: data.refNo,
                        dialogTitle: t("payAnyone:pao_sms_otp_popup_title"),
                        dialogDescription: t(
                            "payAnyone:pao_sms_otp_popup_hint"
                        ).replace("%@", phoneNumber),
                        dialogSubtitle: " ",
                        submitFunc: verifySecurityCommitOtp,
                        resendFunc: verifySecurityResendOtp,
                    });
                    break;
                default:
                    verifySecurityResponseHandler(response);
                    break;
            }
        },
        [
            paoType,
            openOTPDialog,
            verifySecurityCommitOtp,
            verifySecurityResendOtp,
            password,
            phoneNumberInput,
            verifySecurityResponseHandler,
            isShowPhoneNumberField,
            t,
            phoneRegex,
            phoneNumber,
        ]
    );

    // BEGIN ----- Verify security info -----

    return (
        <form onSubmit={onSubmitHandler}>
            <div className={classes.title}>
                <LspTypography variant="heading1" color="black">
                    {t("payAnyone:pao_enter_password_authen_titile")}
                </LspTypography>
            </div>
            <div className={classes.subtitle}>
                <LspTypography variant="body3" color="grey">
                    {t("payAnyone:pao_enter_password_authen_content")}
                </LspTypography>
            </div>
            {isShowPasswordField && (
                <LspTextField
                    value={password}
                    onChange={(e) => onChangePasswordHandler(e.target.value)}
                    error={!!errors.password}
                    helperText={t(errors.password) || " "}
                    name="password"
                    label={t("payAnyone:pao_authen_password")}
                    // inputProps={{
                    //     type: "password",
                    // }}
                />
            )}

            {isShowPhoneNumberField && (
                <LspTextField
                    value={phoneNumberInput}
                    onChange={(e) => onChangePhoneNumberHandler(e.target.value)}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber || " "}
                    name="password"
                    label={t("pao_lb_phone_number")}
                />
            )}

            <LspButton
                fullWidth
                type="submit"
                disabled={fetching || isDisabledSubmitButton}
                progressing={fetching}
            >
                {t("lb_continue")}
            </LspButton>
        </form>
    );
};

export default memo(ClaimMoneySecurityOptions);
