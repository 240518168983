/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-alert */
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { useEffect, useCallback, useState, useMemo } from "react";
import communityService from "@services/community";
import { ADJ_DIRECT, RESPONSE_CODE } from "@config/constants";
import { ScanQRIcon, RefreshIcon } from "@components/LspIcon";

import QRCode from "qrcode.react";
import logoIcon from "@assets/images/circle-logo.png";
import LspButton from "@components/LspButton";
import { UAParser } from "ua-parser-js";
import DownloadApp from "@components/DownloadApp";

const useStyles = makeStyles((theme) => ({
    qr: {
        width: 256,
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            margin: "auto",
        },
    },
    infoWrapper: {
        background: "#f9fcff",
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    step: {
        marginTop: theme.spacing(4),
        "& li": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            fontSize: theme.typography.pxToRem(18),
        },
    },
    scanIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    overlayQR: {
        width: 267,
        height: 267,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        background: "rgba(256, 256, 256, 0.7)",
        "& p": {
            color: "white",
        },
    },
    reloadBtn: {
        background: "rgba(56, 119, 229, 0.7)",
        width: 200,
        height: 200,
        borderRadius: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    reloadIcon: {
        color: "white",
        fontSize: 32,
    },
    downloadAppWrapper: {
        "& > div": {
            flexDirection: "row !important",
        },
    },
}));

const QR_STATUSES = {
    PENDING: "pending",
    KILL: "kill",
    EXPIRED: "expired",
    SUCCESS: "success",
    NOT_FOUND: "not_found",
};

const COMMUNITY_DEEPLINK = "plus://plus.vn/community/open?uuid=";
const RETRY_GET_STATUS_TIME = 2000;

const CommunityAuthQr = () => {
    const classes = useStyles();

    const { t } = useTranslation();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const [uuid, setuuid] = useState("");
    const [qrStatus, setQrStatus] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [lostFocus, setLostFocus] = useState(false);

    const { device, browser, os } = UAParser();

    const isMobile = useMemo(
        () => device.type === "mobile" || device.type === "tablet",
        [device.type]
    );

    const isAndroid = useMemo(() => os.name?.toLowerCase() === "android", [os]);

    const isNotInstalledApp = useMemo(() => {
        return params?.not_installed;
    }, [params?.not_installed]);

    const generateQR = useCallback(async () => {
        const response = await communityService.generateQRLogin({
            sso: params?.sso,
            sig: params?.sig,
        });

        if (response?.data?.code !== RESPONSE_CODE.SUCCESS) return;

        const { data } = response.data;
        // eslint-disable-next-line no-underscore-dangle
        const _uuid = `${COMMUNITY_DEEPLINK}${data?.uuid}`;
        setQrCode(_uuid);
        setuuid(data?.uuid);
    }, [params?.sso, params?.sig]);

    const getQRStatus = useCallback(async () => {
        const response = await communityService.getQRStatus({
            uuid,
        });

        // APPI NOT FOUND --> KILL INTERVAL
        if (
            response?.status === RESPONSE_CODE.NOT_FOUND ||
            response?.data?.code !== RESPONSE_CODE.SUCCESS ||
            uuid === ""
        ) {
            setQrStatus(QR_STATUSES.KILL);
            return;
        }

        const { data } = response.data;

        if (data?.status === QR_STATUSES.NOT_FOUND) {
            setQrStatus(QR_STATUSES.EXPIRED);
            return;
        }

        if (data?.status === QR_STATUSES.PENDING) return;

        setQrStatus(QR_STATUSES.SUCCESS);
        window.location.href = data?.callBackUrl;
    }, [uuid]);

    const reloadQrCode = () => {
        // direct to disourse page & get another nonce
        window.location.href = process.env.REACT_APP_COMMUNITY_PAGE_URL;
    };
    const loginOnMobile = useCallback(() => {
        setLostFocus(true);
        const notInstalledAppFallback = `${window.location.href}&not_installed=true`;

        const decodeURIFallbackLink = encodeURIComponent(
            notInstalledAppFallback
        );

        const qr = `${qrCode}&mobile=true`;
        setQrStatus(QR_STATUSES.KILL);
        const deepLinkForAdjust = qr?.replace("plus://", "");

        setQrStatus(QR_STATUSES.KILL);

        const adj = (ADJ_DIRECT.ADJ_LINK || "")
            .split("__HOME_PAGE__")
            .join(deepLinkForAdjust)
            .split("__DECODE_URI__")
            .join(decodeURIFallbackLink);

        if (isAndroid) {
            window.open(adj, "_blank");
            return;
        }

        window.location.href = adj;
    }, [qrCode, isAndroid]);

    useEffect(() => {
        const interval = setInterval(() => {
            getQRStatus();
        }, RETRY_GET_STATUS_TIME);

        if (qrStatus === QR_STATUSES.KILL || qrStatus === QR_STATUSES.EXPIRED) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [getQRStatus, qrStatus]);

    useEffect(() => {
        generateQR();
    }, [generateQR]);

    const checkActiveTab = useCallback(() => {
        if (!document.hidden) {
            getQRStatus();
        }
    }, [getQRStatus]);

    useEffect(() => {
        if (
            isMobile &&
            browser?.name?.toLowerCase().includes("safari") &&
            (!lostFocus || isNotInstalledApp)
        ) {
            window.addEventListener("visibilitychange", checkActiveTab);
        }

        return () => {
            if (
                (lostFocus || isNotInstalledApp) &&
                qrStatus === QR_STATUSES.SUCCESS
            ) {
                window.removeEventListener("visibilitychange", checkActiveTab);
            }
        };
    }, [
        checkActiveTab,
        isMobile,
        browser?.name,
        lostFocus,
        qrStatus,
        isNotInstalledApp,
    ]);

    return (
        <Box className={classes.infoWrapper}>
            <Box flex={1}>
                <LspTypography variant="title1">
                    {t("community_header")}
                </LspTypography>
                <ol className={classes.step}>
                    <li>{t("community_step1")}</li>
                    <li>
                        <Box display="flex" alignItems="center">
                            {t("community_step2_1")}
                            <ScanQRIcon className={classes.scanIcon} />
                            {t("community_step2_2")}
                        </Box>
                    </li>
                    <li>{t("community_step3")}</li>
                </ol>
            </Box>

            {isMobile && (
                <>
                    {qrStatus === QR_STATUSES.EXPIRED && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            margin={4}
                        >
                            <LspButton onClick={reloadQrCode}>
                                {t("community_reload_qr_mobile_device")}
                            </LspButton>
                        </Box>
                    )}
                    {qrStatus !== QR_STATUSES.EXPIRED && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            margin={4}
                        >
                            <LspButton onClick={loginOnMobile}>
                                {t("community_login_mobile_device")}
                            </LspButton>
                        </Box>
                    )}
                    {isNotInstalledApp && (
                        <Box>
                            <Box marginBottom={2} textAlign="center">
                                <LspTypography color="error">
                                    {t("community_warning_not_installed_app")}
                                </LspTypography>
                            </Box>
                            <Box className={classes.downloadAppWrapper}>
                                <DownloadApp size="small" variant="row" />
                            </Box>
                        </Box>
                    )}
                </>
            )}

            {!isMobile && (
                <Box className={classes.qr}>
                    {qrStatus === QR_STATUSES.EXPIRED && (
                        <Box className={classes.overlayQR}>
                            <ButtonBase
                                onClick={reloadQrCode}
                                className={classes.reloadBtn}
                            >
                                <RefreshIcon className={classes.reloadIcon} />
                                <LspTypography variant="title2">
                                    {t("community_reload_btn")}
                                </LspTypography>
                            </ButtonBase>
                        </Box>
                    )}
                    <QRCode
                        renderAs="canvas"
                        value={qrCode}
                        size={265}
                        level="M"
                        imageSettings={{
                            src: logoIcon,
                            width: 65,
                            height: 65,
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default CommunityAuthQr;
