import { useCallback } from "react";

const configName = "productTourStatus";

const useProductTourStorage = () => {
    const updateConfigStatus = useCallback((name) => {
        const cachedStatuses = JSON.parse(localStorage.getItem(configName));
        const stringifyStatuses = JSON.stringify({
            ...cachedStatuses,
            [name]: false,
        });
        localStorage.setItem(configName, stringifyStatuses);
    }, []);

    const getConfigStatus = useCallback((name) => {
        const cachedStatuses = JSON.parse(localStorage.getItem(configName));

        if (!cachedStatuses || cachedStatuses[name] === undefined) return true;

        return false;
    }, []);

    return {
        getConfigStatus,
        updateConfigStatus,
    };
};

export default useProductTourStorage;
