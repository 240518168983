import api from "../config/api";

const getLixiDashboard = async () => {
    return await api.get("user/payanyone/dashboard", null);
};

const getLixiList = async (payload) => {
    return await api.post("user/payanyone/LIXI/list", payload);
};

const getLixiDetailList = async (payload) => {
    return await api.post(`/user/payanyone/LIXI/detail/${payload}`, null);
};

const lixiService = {
    getLixiDashboard,
    getLixiList,
    getLixiDetailList,
};

export default lixiService;
