import LspTypography from "@components/LspTypography";
import { Box, Divider, Icon } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import LspBox from "@components/LspBox";
import ProfilePasswordForm from "@containers/Profile/ProfileAccountDetail/ProfilePasswordForm";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";

const ProfileAccountDetail = ({ user, systemParam }) => {
    const { t } = useTranslation();
    const classes = useProfileSharedClasses();

    return (
        <LspBox className={classes.wrapperBox}>
            <Box className={classes.headerWrapper}>
                <Icon
                    className={`font-icon icon-accountdetail ${classes.headerIcon}`}
                />
                <LspTypography variant="title2" className={classes.header}>
                    {t("profile_lb_account_detail")}
                </LspTypography>
            </Box>

            <Divider className={classes.divider} />

            <div className={`${classes.line} ${classes.expandLine}`}>
                <div>
                    <Icon
                        className={`font-icon icon-username ${classes.leftIcon}`}
                    />

                    <LspTypography
                        variant="heading2"
                        component="span"
                        color="grey"
                    >
                        {t("lb_username")}
                    </LspTypography>
                    {": "}
                    <LspTypography
                        variant="body1"
                        component="span"
                        color="grey"
                    >
                        {user?.userName}
                    </LspTypography>
                </div>
                <div />
            </div>

            <Divider className={classes.divider} />

            <ProfilePasswordForm systemParam={systemParam} />

            <Divider className={classes.divider} />

            <div className={`${classes.line} ${classes.expandLine}`}>
                <div>
                    <Icon
                        className={`font-icon icon-email ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("user_profile_email_address_txt")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {user?.userEmail}
                    </LspTypography>
                </div>
                <div />
            </div>

            <Divider className={classes.divider} />

            <div className={`${classes.line} ${classes.expandLine}`}>
                <div>
                    <Icon
                        className={`font-icon icon-phone ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("lb_mobile_number")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {user?.phoneNumber}
                    </LspTypography>
                </div>
                <div />
            </div>
        </LspBox>
    );
};

export default memo(ProfileAccountDetail);
