/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    Icon,
    makeStyles,
    Box,
    Paper,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import LspTextField from "@components/LspTextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useTranslation } from "react-i18next";
import { GENDER_LIST, REGEX_PATTERN } from "@config/constants";
import LspButton from "@components/LspButton";
import { useHistory } from "react-router-dom";
import { INSTANT_COVERAGE_STEPS } from "@containers/Wealth/wealthConstants";
import { DatePicker } from "@material-ui/pickers";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { connect } from "react-redux";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { format, parse } from "date-fns";

const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        display: "block",
        width: "100%",
        textAlign: "right",
        cursor: "pointer",
    },

    beneficiaryInfo: {
        padding: theme.spacing(3, 4),
        " & > div": {
            marginBottom: theme.spacing(1.25),
        },
    },
    uppercase: {
        textTransform: "uppercase",
    },
    summary: {
        background: theme.palette.grey[200],
    },
    beneficiaryForm: {
        padding: theme.spacing(3),
    },
    beneficiaryTitle: {
        padding: theme.spacing(2),
        background: theme.palette.grey[200],
    },
    gender: {
        display: "block",
    },
}));

const RightPageForm = ({ stepForward, user, setFormInfo, formInfo }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const [relationShip, setRelationShip] = useState(
        () => formInfo?.relationship || "",
        [formInfo?.relationship]
    );
    const [dateOfBirth, setDateOfBirth] = useState(
        () =>
            formInfo?.dateOfBirth
                ? parse(formInfo?.dateOfBirth, "dd/MM/yyyy", new Date())
                : null,
        [formInfo?.dateOfBirth]
    );
    const [beneficiaryInfo, setBeneficiaryInfo] = useState(
        () => formInfo?.beneficiaryName || "",
        [formInfo?.beneficiaryName]
    );
    const [gender, setGender] = useState(() => formInfo?.genderName || "", [
        formInfo?.genderName,
    ]);
    const [legalId, setLegalId] = useState(() => formInfo?.legalId || "", [
        formInfo?.legalId,
    ]);
    const [errorsDate, setErrorsDate] = useState(false);

    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const theme = useTheme();

    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const nextToPolicy = () => {
        const data = {
            beneficiaryName: beneficiaryInfo,
            relationship: relationShip,
            dateOfBirth: format(dateOfBirth, "dd/MM/yyyy"),
            legalId: legalId || null,
            genderName: gender,
        };
        setFormInfo(data);

        if (isSmDown) {
            stepForward(INSTANT_COVERAGE_STEPS.MOBILE_REVIEW_POLICY);
            return;
        }
        stepForward(INSTANT_COVERAGE_STEPS.REVIEW_POLICY);
    };

    const relationShipList = useMemo(() => {
        const content = [];
        t("wealth:SI00039", {
            returnObjects: true,
            defaultValue: "",
        }).forEach((item) => {
            const relationship = {};
            // eslint-disable-next-line prefer-destructuring
            relationship.relKey = item[0];
            // eslint-disable-next-line prefer-destructuring
            relationship.relName = item[1];
            content.push(relationship);
        });
        return content;
    }, [t]);

    const check18YearOld = (valueRelationShip) => {
        setRelationShip(valueRelationShip);
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        const age = today.getFullYear() - birthDate.getFullYear();

        if (
            age < 18 &&
            valueRelationShip !== "child" &&
            valueRelationShip !== "brother"
        ) {
            setErrorsDate(true);
            setDateOfBirth(null);
        }
    };

    const fullAddress = useMemo(() => {
        const addressList = user.address;
        let strAddress = "";
        if (addressList.addressName) strAddress = addressList.addressName;
        if (addressList.wardName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_ward_prefix") +
                    addressList.wardName;
            }
        }
        if (addressList.distName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_district_prefix") +
                    addressList.distName;
            }
        }
        if (addressList.cityName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress += addressList.cityName;
            }
        }

        return strAddress;
    }, [user, t]);

    return (
        <Content size="xs">
            <div>
                <Icon
                    onClick={() => history.push("products")}
                    className={`font-icon icon-close ${classes.closeIcon}`}
                />
                <div className={classes.boxTitle}>
                    <Icon
                        className={`font-icon icon-insuranceinformation ${classes.icon}`}
                    />
                    <LspTypography variant="heading1" color="primary">
                        {t("wealth:SI00092")}
                    </LspTypography>
                </div>
                <div>
                    <Paper>
                        <div className={classes.beneficiaryTitle}>
                            <LspTypography variant="body1" color="black">
                                {t("wealth:SI00079")}
                            </LspTypography>
                        </div>
                        <div className={classes.beneficiaryForm}>
                            <LspTextField
                                autoFocus
                                label={t("wealth:SI00074")}
                                name="beneficiaryInfo"
                                inputProps={{
                                    maxLength: 70,
                                }}
                                onChange={(e) => {
                                    setBeneficiaryInfo(
                                        replaceSpecialCharacter({
                                            pattern: REGEX_PATTERN.NICK_NAME,
                                            text: e.target.value,
                                        })
                                    );
                                }}
                                value={beneficiaryInfo}
                            />

                            <Box mb={2} pl={1}>
                                <FormLabel>{t("lb_gender")}</FormLabel>

                                <RadioGroup
                                    className={classes.gender}
                                    onChange={(e) => {
                                        setGender(e.target.defaultValue);
                                    }}
                                    value={gender}
                                >
                                    {GENDER_LIST?.map((item) => {
                                        return (
                                            <FormControlLabel
                                                key={item?.cfgKey}
                                                value={item?.cfgKey}
                                                label={t(item?.cfgValue)}
                                                control={<Radio />}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Box>

                            <LspTextField
                                autoFocus
                                name="relationShip"
                                select
                                label={t("wealth:SI00077")}
                                onChange={(e) => {
                                    check18YearOld(e.target.value);
                                }}
                                value={relationShip}
                            >
                                {relationShipList?.map((item, key) => {
                                    return (
                                        <MenuItem
                                            value={item?.relKey}
                                            key={key}
                                        >
                                            {t(item?.relName)}
                                        </MenuItem>
                                    );
                                })}
                            </LspTextField>
                            <DatePicker
                                TextFieldComponent={LspTextField}
                                disableFuture
                                openTo="year"
                                format="dd/MM/yyyy"
                                disabled={!relationShip}
                                label={t("sunlife_placeholder_date_birth")}
                                views={["year", "month", "date"]}
                                value={dateOfBirth}
                                error={!!errorsDate}
                                helperText={
                                    errorsDate
                                        ? t(
                                              "sunlife_msg_error_beneficiary_must_older_18"
                                          )
                                        : ""
                                }
                                onChange={(d) => {
                                    if (!d) {
                                        setDateOfBirth(null);
                                        return;
                                    }
                                    const today = new Date();
                                    const birthDate = new Date(d);
                                    const age =
                                        today.getFullYear() -
                                        birthDate.getFullYear();

                                    if (
                                        age < 18 &&
                                        relationShip !== "child" &&
                                        relationShip !== "brother"
                                    ) {
                                        setErrorsDate(true);
                                        setDateOfBirth(null);
                                        return;
                                    }
                                    setErrorsDate(false);
                                    setDateOfBirth(new Date(d));
                                }}
                            />
                            <LspTextField
                                autoFocus
                                label={t("wealth:SI00104")}
                                name="legalId"
                                inputProps={{
                                    maxLength: 70,
                                }}
                                onChange={(e) => {
                                    setLegalId(
                                        replaceSpecialCharacter({
                                            pattern: REGEX_PATTERN.NICK_NAME,
                                            text: e.target.value,
                                        })
                                    );
                                }}
                                value={legalId}
                            />
                        </div>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                className={classes.summary}
                            >
                                <LspTypography variant="body1" color="black">
                                    {t("wealth:SI00096")}
                                </LspTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.beneficiaryInfo}>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("rq_lb_full_name")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body1"
                                            color="grey"
                                        >
                                            {user.fullName}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("sunlife_lb_id_passport")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body1"
                                            color="grey"
                                        >
                                            {user.nationalId}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("lb_date_of_birth")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body1"
                                            color="grey"
                                        >
                                            {user.displayDOB}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("lb_gender")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body1"
                                            color="grey"
                                        >
                                            {user.gender === 1
                                                ? t("lb_male")
                                                : t("lb_female")}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("sunlife_lb_phone")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body2"
                                            color="grey"
                                        >
                                            {user.phoneNumber}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("lb_hint_email")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body2"
                                            color="grey"
                                        >
                                            {user.userEmail}
                                        </LspTypography>
                                    </div>
                                    <div>
                                        <LspTypography
                                            variant="heading3"
                                            color="grey"
                                            className={classes.uppercase}
                                        >
                                            {t("bill_lb_address")}
                                        </LspTypography>
                                        <LspTypography
                                            variant="body2"
                                            color="grey"
                                        >
                                            {fullAddress}
                                        </LspTypography>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </div>
                <Box mt={3}>
                    <LspButton
                        type="submit"
                        fullWidth
                        disabled={
                            !relationShip ||
                            !dateOfBirth ||
                            !gender ||
                            !beneficiaryInfo
                        }
                        onClick={nextToPolicy}
                    >
                        {t("lb_next")}
                    </LspButton>
                </Box>
            </div>
        </Content>
    );
};
const mapState = (state) => ({
    user: state.user.info,
});

export default connect(mapState, null)(RightPageForm);
