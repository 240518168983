import { useState, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { REGEX_PATTERN } from "@config/constants";
import { Box, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import LspTextField from "@components/LspTextField";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import PaymentImageTransfer from "../PaymentImageTransfer";

const useStyles = makeStyles((theme) => {
    return {
        confirmDeleteTxt: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            textTransform: "uppercase",
            padding: theme.spacing(1.25, 2.25),
        },
    };
});
const PaymentReceiverConfirmDelete = ({
    onConfirmDelete,
    progressing,
    avatar,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const [deleteInfo, setDeleteInfo] = useState({
        spam: false,
        note: false,
        noteText: "",
    });

    const onClickHandler = useCallback(() => {
        onConfirmDelete(deleteInfo);
    }, [deleteInfo, onConfirmDelete]);

    return (
        <Box>
            <div className={classes.confirmDeleteTxt}>
                {t("payment_title_delete_request_receive")}
            </div>

            <PaymentImageTransfer avatar={avatar} isSent={false} />

            <Box textAlign="left" width="100%" marginTop={3}>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={!!deleteInfo.spam}
                                onChange={(e) =>
                                    setDeleteInfo((prev) => ({
                                        ...prev,
                                        spam: e.target.checked,
                                    }))
                                }
                                color="secondary"
                            />
                        }
                        label={
                            <LspTypography variant="heading3">
                                {t("payment_lb_delete_receive_spam")}
                            </LspTypography>
                        }
                    />
                    <Box marginLeft={3.5}>
                        {t("payment_content_notify_delete_receive_spam")}
                    </Box>
                </Box>
                <Box marginTop={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={!!deleteInfo.note}
                                onChange={(e) =>
                                    setDeleteInfo((prev) => ({
                                        ...prev,
                                        note: e.target.checked,
                                    }))
                                }
                                color="secondary"
                            />
                        }
                        label={
                            <LspTypography variant="heading3">
                                {t("payment_lb_delete_receive_notify")}
                            </LspTypography>
                        }
                    />

                    <Box marginTop={2}>
                        <LspTextField
                            label={t("payment_place_delete_receive_reason")}
                            value={deleteInfo.noteText}
                            inputProps={{
                                maxLength: 150,
                            }}
                            onChange={(e) => {
                                setDeleteInfo((prev) => ({
                                    ...prev,
                                    noteText: replaceSpecialCharacter({
                                        pattern: REGEX_PATTERN.REFERRAL_CODE,
                                        text: e.target.value,
                                    }),
                                }));
                            }}
                            multiline
                            disabled={!deleteInfo.note}
                        />
                    </Box>
                </Box>
            </Box>
            <Box mt={4} width="100%">
                <LspButton
                    variant="primary"
                    fullWidth
                    progressing={progressing}
                    onClick={onClickHandler}
                >
                    {t("payment_lb_btn_delete_now")}
                </LspButton>
            </Box>
        </Box>
    );
};

export default memo(PaymentReceiverConfirmDelete);
