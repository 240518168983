import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import { memo, useMemo } from "react";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { TermDepositRollType } from "@config/constants";

const ConfirmRolloverDialog = ({
    onClose,
    onSubmit,
    rollType,
    maturityDate,
}) => {
    const { t } = useTranslation();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
        }
    };

    const rollDescription = useMemo(() => {
        let content = "";
        switch (rollType) {
            case TermDepositRollType.TOTAL_ROLLOVER:
                content = t(
                    "TD:td_rollover_method_change_to_total_rollover"
                ).replace("%@", `<strong>${maturityDate}</strong>`);
                break;
            case TermDepositRollType.PARTIAL_ROLLOVER:
                content = t(
                    "TD:td_rollover_method_change_to_partial_rollover"
                ).replace("%@", `<strong>${maturityDate}</strong>`);
                break;
            default:
                content = t(
                    "TD:td_rollover_method_change_to_no_rollover"
                ).replace("%@", `<strong>${maturityDate}</strong>`);
                break;
        }

        return (
            <LspTypography>
                <Interweave content={content} />
            </LspTypography>
        );
    }, [maturityDate, rollType, t]);

    return (
        <>
            <LspDialog open maxWidth="sm" scroll="body">
                <LspDialogTitle>
                    {t("td_details_popup_confirm_title")}
                </LspDialogTitle>
                <LspDialogContent>{rollDescription}</LspDialogContent>
                <LspDialogActions>
                    <LspButton
                        variant="secondary"
                        fullWidth
                        onClick={handleClose}
                    >
                        {t("lb_close")}
                    </LspButton>
                    <LspButton type="submit" fullWidth onClick={handleSubmit}>
                        {t("loan_vpbank_button_confirm")}
                    </LspButton>
                </LspDialogActions>
            </LspDialog>
        </>
    );
};

export default memo(ConfirmRolloverDialog);
