import api from "@config/api";

const resetPasswordCheck = async (payload) => {
    return await api.post("/login/reset/password", payload);
};

const resetPasswordService = {
    resetPasswordCheck,
};

export default resetPasswordService;
