import LspTypography from "@components/LspTypography";
import {
    Box,
    makeStyles,
    Icon,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspButton from "@components/LspButton";
import { CopyIcon } from "@components/LspIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import AlertController from "@helpers/controllers/AlertController";
import InvitationEditReferralCode from "../InvitationEditReferralCode";

const useStyles = makeStyles((theme) => ({
    referralCode: {
        textAlign: "center",
        background: "rgba(124, 87, 217, .48)",
        padding: theme.spacing(3, 3, 2, 3),
        marginTop: theme.spacing(2),
        borderRadius: theme.shape.radiusLevels[1],
        color: theme.palette.neutral.white,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(3),
        },
    },
    editIcon: {
        cursor: "pointer",
        marginLeft: theme.spacing(1),
    },
}));

const InvitationViewRefCode = ({ referralCode, onUpdateReferralCode }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const editReferralCode = () => {
        GlobalDialogController.show({
            component: () => (
                <InvitationEditReferralCode
                    onClose={() => GlobalDialogController.hide()}
                    referralCode={referralCode}
                    onUpdateReferralCode={onUpdateReferralCode}
                />
            ),
        });
    };

    const copyHandler = () => {
        AlertController.show(t("if_btn_click_copy_clipboard"));
    };

    return (
        <div className={classes.referralCode}>
            <LspTypography
                variant="body2"
                style={{ opacity: 0.5 }}
                color="white"
            >
                {t("master:invite_friend_my_code")}
            </LspTypography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mb={2}
            >
                <LspTypography
                    variant={isMobile ? "title2" : "title1"}
                    color="white"
                >
                    {referralCode}
                </LspTypography>
                <Icon
                    className={`font-icon icon-Edit ${classes.editIcon}`}
                    onClick={editReferralCode}
                />
            </Box>

            <CopyToClipboard text={referralCode} onCopy={copyHandler}>
                <LspButton
                    variant="secondary"
                    size="small"
                    buttonProps={{
                        startIcon: <CopyIcon />,
                    }}
                >
                    {t("master:lb_share_this_code")}
                </LspButton>
            </CopyToClipboard>
        </div>
    );
};

export default InvitationViewRefCode;
