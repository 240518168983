import { ButtonBase, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import useNumber from "@helpers/useNumber";
import Interweave from "interweave";
import LspTypography from "@components/LspTypography";
import { memo, useMemo } from "react";
import { ArrowRightIcon, BankIcon } from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    amount: {
        color: theme.palette.blue.main,
        fontWeight: 600,
    },
    title: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        color: theme.palette.white.contrastText,
    },
    subtitle: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    desc: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
}));

const ClaimMoneyPaymentDetail = ({
    onOpenBankDetail,
    paymentDetail,
    parentClasses,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { formatNumber } = useNumber();

    const title = useMemo(
        () =>
            t("payAnyone:pao_sender_msg_view").replace(
                "%@",
                `${paymentDetail?.senderName}`
            ),
        [t, paymentDetail]
    );

    return (
        <Content size="sm">
            <div className={classes.title}>
                <Interweave content={title} />
            </div>
            <div className={classes.amount}>
                <LspTypography variant="title1" color="black">
                    {t("payAnyone:pao_amount").replace(
                        "%@",
                        `${formatNumber(paymentDetail?.amount)}`
                    )}
                </LspTypography>
            </div>
            <div className={classes.desc}>
                <LspTypography variant="body2" color="black">
                    {t("payAnyone:pao_detail_label_message")}
                </LspTypography>
                <LspTypography variant="subheading2" color="black">
                    {paymentDetail?.desc}
                </LspTypography>
            </div>
            <div className={classes.subtitle}>
                <LspTypography variant="body3" color="grey">
                    {t("payAnyone:pao_select_bank_note")}
                </LspTypography>
            </div>
            <div className={parentClasses.methods}>
                <ButtonBase
                    className={`${parentClasses.methodsOption}`}
                    onClick={onOpenBankDetail}
                >
                    <div className={parentClasses.optionContent}>
                        <div>
                            <BankIcon
                                className={`${parentClasses.optionIcon}`}
                            />
                        </div>
                        <LspTypography
                            variant="heading3"
                            className={parentClasses.optionName}
                        >
                            {t("payAnyone:pao_lb_to_bank")}
                        </LspTypography>
                        <LspTypography
                            variant="tiny"
                            className={parentClasses.optionDesc}
                        >
                            {t("payAnyone:lb_free")}
                        </LspTypography>
                        <ArrowRightIcon
                            className={parentClasses.navigationIcon}
                        />
                    </div>
                </ButtonBase>
            </div>
            {/* <HintInfo
                content={t("payAnyone:pao_claim_timo_account_guideline")}
            /> */}
        </Content>
    );
};

export default memo(ClaimMoneyPaymentDetail);
