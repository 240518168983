import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import moveMoneyService from "@services/move-money";
import { useCallback, useEffect, useMemo, useRef } from "react";

const useDescription = () => {
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const parseStandardDescription = useCallback(
        (desc) => {
            if (!desc) return "";
            return replaceSpecialCharacter({
                pattern: REGEX_PATTERN.DESCRIPTION_REPLACEMENT,
                text: desc,
                hasEscapeAccentVietnamese: true,
            });
        },
        [replaceSpecialCharacter]
    );

    const getStandardDescription = useCallback(
        async ({ defaultDescription, description }) => {
            let desc = description;
            let standardDesc = parseStandardDescription(defaultDescription);

            if (description === "") {
                return {
                    standardDescription: standardDesc,
                    description: defaultDescription,
                };
            }

            const response = await moveMoneyService.getStandardDescription({
                description,
            });

            if (unmounted.current) return;

            const { code, data } = response.data;
            if (code === RESPONSE_CODE.SUCCESS) {
                if (data?.standardizedDescription !== "") {
                    standardDesc = data?.standardizedDescription;
                }
            } else {
                // get standard description fail. The text will be trim by frontend
                desc = parseStandardDescription(description);
            }

            return {
                standardDescription: standardDesc,
                description: desc,
            };
        },
        [parseStandardDescription]
    );

    const descriptionFunc = useMemo(
        () => ({
            getStandardDescription,
        }),
        [getStandardDescription]
    );

    return descriptionFunc;
};

export default useDescription;
