import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";

import NumberFormat from "react-number-format";
import lixiActions from "@redux/actions/lixi";
import LspTextField from "@components/LspTextField";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    amountContainer: {
        textAlign: "center",
    },
    info: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.black.main,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    textBox: {
        maxWidth: theme.typography.pxToRem(555),
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(4),
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 600,
    },
    amountBox: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    amountItem: {
        cursor: "pointer",
        margin: theme.spacing(0.5),
        border: `1px solid ${theme.palette.magicalGrey[200]}`,
        padding: "13px 18px",
        width: "calc(50% - 12px)",
        maxWidth: "163px",
        textAlign: "center",
        borderRadius: theme.spacing(0.5),
    },
    amountActive: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

const AmountList = ({
    // backFunc,
    // nextFunc,
    setSubmitInfo,
    amount,
    sysParam,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [activeAmountItem, setActiveAmountItem] = useState("");
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [error, setError] = useState("");
    const { thousandSeparator, decimalSeparator, formatNumber } = useNumber();

    const hintAmount = useMemo(() => {
        return t("lixi:min_amount_hint").replace(
            "%@",
            formatNumber(sysParam.MinAmountLimitPerTransfer)
        );
    }, [sysParam, t, formatNumber]);

    const amountList = useMemo(() => {
        const arrayAmount = t("lixi:lixi_available_amount_keys", {
            returnObjects: true,
        });
        const amounts = [];
        for (let i = 0; i < arrayAmount.length; i++) {
            const suggestedAmount = t(`lixi:${arrayAmount[i]}`);
            if (suggestedAmount) {
                amounts.push(suggestedAmount);
            }
        }

        return amounts;
    }, [t]);

    const getAmountIndex = useCallback(
        (inputAmount) => {
            return amountList.findIndex((a) => a === inputAmount);
        },
        [amountList]
    );

    const onSelectAmount = useCallback(
        (selectedAmount) => {
            setIsFirstTime(false);
            setSubmitInfo({ key: "amount", value: selectedAmount });
            setActiveAmountItem(getAmountIndex(selectedAmount));
        },
        [setSubmitInfo, getAmountIndex]
    );

    const onChangeAmount = () => {
        setActiveAmountItem(-1);
        setError("");
    };

    useEffect(() => {
        if (amount && isFirstTime) {
            onSelectAmount(amount);
        }
    }, [amount, onSelectAmount, isFirstTime]);

    return (
        <div className={classes.amountContainer}>
            <div className={classes.info}>
                <div className={classes.title}>
                    {t("lixi:enter_amount_title")}
                </div>
                <div>{t("lixi:enter_amount_content")}</div>
                <div className={classes.textBox}>
                    <NumberFormat
                        error={!!error}
                        helperText={error || hintAmount}
                        name="pin"
                        label={t("bill_amount")}
                        inputProps={{
                            maxLength: 13,
                            type: "text",
                        }}
                        value={amount}
                        customInput={LspTextField}
                        type="text"
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        onValueChange={(e) => onChangeAmount(e.floatValue)}
                    />
                </div>
            </div>
            <div className={classes.amountBox}>
                {amountList.map((listItem, index) => {
                    return (
                        <Fragment key={listItem}>
                            <div
                                onClick={() => onSelectAmount(listItem)}
                                className={clsx(classes.amountItem, {
                                    [classes.amountActive]:
                                        activeAmountItem === index,
                                })}
                            >
                                {listItem}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

const stateProps = (state) => ({
    amount: state.lixi.amount,
    sysParam: state.systemParams.info.conf,
});

const dispatchProps = (dispatch) => ({
    setSubmitInfo: (payload) => dispatch(lixiActions.setSubmitInfo(payload)),
});

export default connect(stateProps, dispatchProps)(AmountList);
