import { LOCAL_STORAGE_STATUS } from "@config/constants";
import appAction from "@redux/actions/app";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHA256 } from "crypto-js";

/**
 * @typedef {object} JSON
 */

// support get status save in local localStorage
const useStatus = () => {
    const dispatch = useDispatch();
    const agreeTNC = useSelector((state) => state.app.agreeTermCondition);
    const userId = useSelector((state) => state.auth?.userId || "");

    // get status for statusConditionDialog
    const [changeStatus, setChangeStatus] = useState(false);

    /**
     * @type {JSON}
     */
    const status = useMemo(() => {
        const statusLocal = localStorage.getItem("statusConditionDialog");

        if (statusLocal === null) {
            localStorage.setItem("statusConditionDialog", "{}");
        }

        setChangeStatus(changeStatus);
        return JSON.parse(statusLocal);
    }, [changeStatus]);

    // update/add status local storage statusConditionDialog
    /**
     * @param {string} key key update
     * @param {boolean} boolean value fo status
     */
    const setStatus = useCallback((key, boolean) => {
        const statusLocal = localStorage.getItem("statusConditionDialog");
        const parseStatusToJson = JSON.parse(statusLocal) || {};
        parseStatusToJson[key] = boolean;
        localStorage.setItem(
            "statusConditionDialog",
            JSON.stringify(parseStatusToJson)
        );
    }, []);

    const getStoredData = useCallback(() => {
        try {
            const statusValue = localStorage.getItem("statusConditionDialog");
            return JSON.parse(statusValue);
        } catch (error) {
            return {};
        }
    }, []);

    const agreeTNCStatus = useMemo(() => {
        if (!userId) return true;
        const storedData = getStoredData();

        const storedValue =
            storedData && storedData[SHA256(userId.toString()).toString()];
        return storedValue || agreeTNC || false;
    }, [userId, agreeTNC, getStoredData]);

    /**
     * @param {boolean} boolean value fo status
     */
    const updateAgreeTNCStatus = useCallback(
        (value) => {
            setStatus(SHA256(userId.toString()).toString(), value);
            dispatch(appAction.agreeTermCondition(value));
        },
        [dispatch, setStatus, userId]
    );

    const forceReload = useCallback(() => {
        setChangeStatus(true);
    }, []);
    return {
        status,
        setStatus,
        forceReload,
        agreeTNCStatus,
        updateAgreeTNCStatus,
    };
};

export default useStatus;
