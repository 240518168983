/* eslint-disable react/no-array-index-key */
import { makeStyles, Paper } from "@material-ui/core";
import { memo, useEffect } from "react";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import LspTimeBodyTab from "@components/LspBooking/LspTime/LspTimeBodyTab";
import LspTimeHeader from "@components/LspBooking/LspTime/LspTimeHeader";
import bookingActions from "@redux/actions/booking";
import { connect } from "react-redux";
import LspTimeFetching from "@components/LspBooking/LspTime/LspTimeFetching";

const useStyles = makeStyles((theme) => ({
    boxWrapper: {
        marginBottom: theme.spacing(3),
        borderTop: `4px solid ${theme.palette.primary.main}`,
    },
    subtitle: {
        background: theme.palette.neutral.grey4,
        padding: theme.spacing(1, 2),
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    labelBox: {
        padding: theme.spacing(2),
    },
    statusList: {
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1, 0),
        "& > li": {
            marginRight: theme.spacing(4),
            color: theme.palette.neutral.grey1,
            position: "relative",
            display: "inline-block",
            fontSize: 12,
            "&::before": {
                content: `''`,
                position: "absolute",
                left: theme.spacing(-2),
                top: theme.spacing(0.65),
                width: 8,
                height: 8,
                borderRadius: "50%",
            },
            "&.error": {
                "&::before": {
                    background: theme.palette.error.main,
                },
            },
            "&.warning": {
                "&::before": {
                    background: theme.palette.gold.main,
                },
            },
        },
    },
    body: {
        minHeight: 370,
    },
}));

/**
 * @typedef LspTimeSelectionProps
 * @type {object}
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {string} note
 * @property {function} onBack
 * @property {function} onNext
 * @property {array} hangouts
 * @property {bool} fetching
 * @property {object} selectedDate
 * @property {object} selectedTime
 */

/**
 * @param {LspTimeSelectionProps} props
 */

const SCHEDULE_STATUS = {
    OPERATIONAL: "OPERATIONAL", // working days
    NON_OPERATIONAL: "NONOPERATIONAL", // days off
};

const LspTimeSelection = ({
    title,
    subtitle,
    description,
    note,
    onBack,
    onNext,
    fetching,
    selectedDate,
    selectedTime,
    updateSelectedDateRedux,
    updateSelectedTimeRedux,
    type = "time",
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    // Store selectedDate & selectedTime to redux > all change by select date & time will be affected on redux. After finish choose date & time. It will be passed to parent component
    useEffect(() => {
        updateSelectedDateRedux(selectedDate);
    }, [selectedDate, updateSelectedDateRedux]);

    useEffect(() => {
        updateSelectedTimeRedux(selectedTime);
    }, [selectedTime, updateSelectedTimeRedux]);

    return (
        <>
            <PageHeaderBack onBack={onBack || null}>
                {title || t("booking_choose_time_web")}
            </PageHeaderBack>
            <Paper className={classes.boxWrapper}>
                <div className={classes.labelBox}>
                    <LspTypography
                        className={classes.description}
                        variant="body2"
                    >
                        {description || t("booking_choose_right_time")}
                    </LspTypography>
                    <LspTypography variant="body3" color="grey">
                        {note || t("mb_jn_schedule_notice_label")}
                    </LspTypography>
                    <ul className={classes.statusList}>
                        <li className="warning">
                            {t("wealth:mc_v3_when_noavai_lb")}
                        </li>
                        <li className="error">
                            {t("wealth:mc_v3_when_closed_lb")}
                        </li>
                    </ul>
                </div>

                <LspTypography
                    variant="heading3"
                    color="grey"
                    className={classes.subtitle}
                >
                    {subtitle || t("booking_choose_time_web")}
                </LspTypography>

                {fetching && <LspTimeFetching />}
                {!fetching && (
                    <div className={classes.body}>
                        <LspTimeHeader statusList={SCHEDULE_STATUS} />
                        <LspTimeBodyTab
                            onNext={onNext}
                            statusList={SCHEDULE_STATUS}
                            type={type}
                        />
                    </div>
                )}
            </Paper>
        </>
    );
};

const mapState = (state) => ({
    fetching: state.booking.fetching,
});

const mapDispatch = (dispatch) => ({
    updateSelectedDateRedux: (payload) =>
        dispatch(bookingActions.setSelectedDate(payload)),
    updateSelectedTimeRedux: (payload) =>
        dispatch(bookingActions.setSelectedTime(payload)),
});

export default memo(connect(mapState, mapDispatch)(LspTimeSelection));
