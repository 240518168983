import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import MobileBackground from "@assets/images/pending-invitation-bg-mobile.png";
import DesktopBackground from "@assets/images/pending-invitation-bg.png";
import InvitationTierList from "../InvitationTierList";
import InvitationViewRefCode from "./InvitationViewRefCode";
import InvitationLevel from "./InvitationLevel";
import InvitationLevelBar from "./InvitationLevelBar";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: `url(${MobileBackground})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "rgba(255,255,255, 0.56)",
        borderRadius: theme.shape.radiusLevels[1],
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundSize: "cover",
        [theme.breakpoints.up("sm")]: {
            background: `url(${DesktopBackground})`,
            padding: theme.spacing(3),
            backgroundSize: "cover",
        },
    },
    viewBenefitLink: {
        display: "block",
        marginTop: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    info: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
}));

const InvitationDetail = ({ data }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [referralCode, setReferralCode] = useState("");
    const unmounted = useRef(false);
    const detail = useMemo(() => {
        if (!data) return {};

        const { allClass, ...othersInfo } = data;
        return othersInfo;
    }, [data]);

    const tierList = useMemo(() => data?.allClass, [data]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        if (unmounted.current) return;

        if (detail?.referralCode) {
            setReferralCode(detail?.referralCode);
        }
    }, [detail?.referralCode]);

    const onCloseDialog = () => {
        GlobalDialogController.hide();
    };

    const viewTierList = (e) => {
        e.preventDefault();
        GlobalDialogController.show({
            component: () => (
                <InvitationTierList
                    onClose={onCloseDialog}
                    size="xs"
                    list={tierList}
                />
            ),
        });
    };

    const onUpdateReferralCode = (newRefCode) => {
        setReferralCode(newRefCode);
        onCloseDialog();
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.info}>
                <InvitationLevel detail={detail} tierList={tierList} />
                <InvitationLevelBar detail={detail} tierList={tierList} />
            </div>
            <InvitationViewRefCode
                referralCode={referralCode}
                onUpdateReferralCode={onUpdateReferralCode}
            />

            <a
                href="#"
                className={classes.viewBenefitLink}
                onClick={viewTierList}
            >
                <LspTypography variant="hyperlink2" color="success">
                    {t("member:timostarclub_btn_view_benefit")}
                </LspTypography>
            </a>
        </div>
    );
};

export default memo(InvitationDetail);
