import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ButtonBase, makeStyles } from "@material-ui/core";
import Content from "@components/Content";
import userAction from "@redux/actions/user";
import LspTypography from "@components/LspTypography";
import BankQRCodeSection from "@containers/BankQRCode/BankQRCodeSection";
import { BANK_TRANSFER_TYPE } from "@config/constants";

import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import BankInformationBox from "./BankInformationBox";
import BankInformationBoxFetching from "./BankInformationBoxFetching";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[1],
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: "hidden",
        boxShadow: theme.shadows[1],
        paddingBottom: theme.spacing(1),
    },
    header: {
        background: theme.palette.background.header,
        textAlign: "left",
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    line: {
        display: "flex",
        padding: theme.spacing(0.75, 2),
        "& > p": {
            textAlign: "left",
            display: "inline-block",
        },
        "& > p:first-child": {
            width: "30%",
            marginRight: theme.spacing(2),
            minWidth: 120,
        },
        "& > p:last-child": {
            width: "68%",
        },
    },
    copyBtn: {
        cursor: "pointer",
    },
    transferInfoNotification: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.neutral.white,
        display: "flex",
        flexDirection: "row",
        borderRadius: theme.shape.radiusLevels[1],
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    transferInfoNotificationIcon: {
        marginRight: theme.spacing(1.5),
        fontSize: theme.typography.pxToRem(20),
    },
    transferInfoNotificationContainer: {
        display: "flex",
        flexDirection: "column",
    },
    transferInfoNotificationText: {
        textAlign: "left",
        marginBottom: theme.spacing(1),
    },
    transferInfoNotificationAction: {
        textAlign: "left",
        justifyContent: "flex-start",
    },
    breakLine: {
        border: `1px solid ${theme.palette.magicalGrey[200]}`,
    },
    eWalletTransfer: {
        marginBottom: theme.spacing(3),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    collapse: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    collapseText: {
        textAlign: "left",
        flex: 1,
        marginRight: theme.spacing(1),
    },
    collapseAction: {
        cursor: "pointer",
        color: theme.palette.magicalGrey[300],
        padding: `0 ${theme.spacing(1)}px`,
    },
    qrCodeSection: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(3),
        boxShadow: theme.shadows[2],
        "&:last-child": {
            marginBottom: 0,
        },
    },
    boxInformationContainer: {
        marginBottom: theme.spacing(2),
        "&:last-child": {
            marginBottom: theme.spacing(3),
        },
    },
    boxInformationContent: {
        padding: theme.spacing(2),
        textAlign: "left",
        borderRadius: theme.shape.radiusLevels[3],
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(2),
        "&:last-child": {
            marginBottom: 0,
        },
    },
    boxInformationTitle: {
        fontSize: theme.typography.pxToRem(14),
        marginBottom: theme.spacing(0.25),
        color: theme.palette.magicalGrey[400],

        "&:last-child": {
            marginBottom: 0,
        },
    },
    boxInformationText: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        "&:last-child": {
            marginBottom: 0,
        },
    },
    boxInformationNickname: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        "&:last-child": {
            marginBottom: 0,
        },
    },
    boxInformationAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    boxInformationActionIcon: {
        color: theme.palette.status.success,
        marginRight: theme.spacing(1),
    },
    napasTransferInfo: {
        margin: theme.spacing(1.5, 0),
        marginBottom: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    napasTransferInfoIcon: {
        marginRight: theme.spacing(1),
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.purple.main,
    },
    napasTransferInfoText: {
        color: theme.palette.grey[500],
        textAlign: "left",
    },
}));

const BankInformationSection = ({
    getBankInfo,
    bankInfo,
    fetching,
    featureState,
    user,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isActiveNotification, setActiveNotification] = useState(true);

    useEffect(() => {
        if (!bankInfo) {
            getBankInfo();
        }
    }, [bankInfo, getBankInfo]);

    const isActiveNotificationBox = useMemo(() => {
        return (
            isActiveNotification &&
            featureState?.enableIBFTGW &&
            !user?.isNewUserIBFT
        );
    }, [featureState?.enableIBFTGW, isActiveNotification, user?.isNewUserIBFT]);

    const isIBFTEnable = useMemo(() => {
        return featureState?.enableIBFTGW || featureState?.enableAliasPhase2;
    }, [featureState?.enableIBFTGW, featureState?.enableAliasPhase2]);

    const handleCloseNotification = () => {
        const isExist = localStorage.getItem("napasInfoNotification");

        if (isExist) {
            const convertToJSON = JSON.parse(isExist);
            localStorage.setItem("napasInfoNotification", !convertToJSON);
            setActiveNotification(convertToJSON);
            return;
        }

        localStorage.setItem("napasInfoNotification", false);
        setActiveNotification(false);
    };

    useEffect(() => {
        const isExistNapasNotification = localStorage.getItem(
            "napasInfoNotification"
        );

        if (!isExistNapasNotification) {
            setActiveNotification(true);
        } else {
            const convertToJSON = JSON.parse(isExistNapasNotification);
            setActiveNotification(convertToJSON);
        }
    }, []);

    const handleViewDetail = () => {
        window.open(t("IBFT:IBFT_bankdetails_notice_hyperlink"), "_blank");
    };

    return (
        <>
            <Content boxProps={{ textAlign: "center" }}>
                {isEmpty(bankInfo) && fetching && (
                    <>
                        <BankInformationBoxFetching classes={classes} />
                    </>
                )}
                {bankInfo && (
                    <>
                        <div className={classes.qrCodeSection}>
                            <BankQRCodeSection
                                hiddenSaveButton
                                hiddenUserInfo
                                enableSelectAccount={
                                    featureState?.enableAliasPhase2
                                }
                            />
                        </div>
                        {isActiveNotificationBox && (
                            <>
                                <div
                                    className={classes.transferInfoNotification}
                                >
                                    <div
                                        className={
                                            classes.transferInfoNotificationIcon
                                        }
                                    >
                                        <span className="icon-info-v2" />
                                    </div>
                                    <div
                                        className={
                                            classes.transferInfoNotificationContainer
                                        }
                                    >
                                        <div
                                            className={
                                                classes.transferInfoNotificationText
                                            }
                                        >
                                            <LspTypography
                                                variant="subheading3"
                                                component="div"
                                                color="white"
                                            >
                                                {t(
                                                    "IBFT:IBFT_bankdetails_notice"
                                                ).replace("%@", "")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="hyperlink1"
                                                color="success"
                                                component="span"
                                                onClick={handleViewDetail}
                                            >
                                                {t(
                                                    "IBFT:IBFT_bankdetails_notice_details"
                                                )}
                                            </LspTypography>
                                        </div>
                                        <ButtonBase
                                            onClick={handleCloseNotification}
                                            disableRipple
                                            className={
                                                classes.transferInfoNotificationAction
                                            }
                                        >
                                            <LspTypography
                                                variant="button2"
                                                color="white"
                                            >
                                                {t("lb_close")}
                                            </LspTypography>
                                        </ButtonBase>
                                    </div>
                                </div>
                            </>
                        )}

                        {isIBFTEnable && (
                            <BankInformationBox
                                classes={classes}
                                bankInfo={bankInfo}
                                type={BANK_TRANSFER_TYPE.FAST_TRANSFER}
                            />
                        )}
                        {isIBFTEnable && (
                            <BankInformationBox
                                classes={classes}
                                bankInfo={bankInfo}
                                type={BANK_TRANSFER_TYPE.EWALLET}
                            />
                        )}
                        <BankInformationBox
                            classes={classes}
                            bankInfo={bankInfo}
                            type={BANK_TRANSFER_TYPE.INTERNAL}
                        />
                        {bankInfo.swiftCode && (
                            <>
                                <BankInformationBox
                                    classes={classes}
                                    bankInfo={bankInfo}
                                    type={BANK_TRANSFER_TYPE.OVERSEA}
                                />
                            </>
                        )}
                    </>
                )}
            </Content>
        </>
    );
};

BankInformationSection.propTypes = {
    fetching: PropTypes.bool,
};

BankInformationSection.defaultProps = {
    fetching: false,
};

const mapState = (state) => ({
    fetching: state.user.bankInfo.fetching,
    fetchStatus: state.user.bankInfo.status,
    bankInfo: state.user.bankInfo.data,
    featureState: state.user.featureState.data,
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    getBankInfo: () => dispatch(userAction.getBankInfoRequest()),
});

export default connect(mapState, mapDispatch)(BankInformationSection);
