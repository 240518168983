const convertVietnameseCharacterToUnicode = (text = "") => {
    if (!text) {
        return;
    }
    text = text.replace(/[ÀÁÂÃĂẠẢẤẦẨẪẬẮẰẲẴẶ]/g, "A");
    text = text.replace(/[àáâãăạảấầẩẫậắằẳẵặ]/g, "a");
    text = text.replace(/[ÈÉÊẸẺẼẾỀỂỄỆ]/g, "E");
    text = text.replace(/[èéêẹẻẽếềểễệ]/g, "e");
    text = text.replace(/[ÌÍĨỈỊ]/g, "I");
    text = text.replace(/[ìíĩỉị]/g, "i");
    text = text.replace(/[ÒÓÔÕƠỌỎỐỒỔỖỘỚỜỞỠỢ]/g, "O");
    text = text.replace(/[òóôõơọỏốồổỗộớờởỡợ]/g, "o");
    text = text.replace(/[ÙÚŨƯỤỦỨỪỬỮỰ]/g, "U");
    text = text.replace(/[ùúũưụủứừửữự]/g, "u");
    text = text.replace(/[ỲỴÝỶỸ]/g, "Y");
    text = text.replace(/[ỳỵýỷỹ]/g, "y");
    text = text.replace(/[Đ]/g, "D");
    text = text.replace(/[đ]/g, "d");

    return text;
};

export default convertVietnameseCharacterToUnicode;
