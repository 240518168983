import {
    ALLOWED_NOTIFICATION_TYPES,
    NOTIFICATION_GROUPS,
} from "@containers/NotificationCenter/constant";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNotifyNavigation from "@helpers/useNotifyNavigation";
import notificationAction from "@redux/actions/notification";
import notificationService from "@services/notification";
import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import { LOCAL_STORAGE_STATUS } from "@config/constants";
import ConditionNotificationDialog from "./ConditionNotificationDialog";

const NotificationPopoverMKT = ({
    suggestedNotifications,
    checkNotificationRequest,
    enableIBFTGW,
    isNewUserIBFT,
    enableAliasPhase2,
}) => {
    const { t } = useTranslation();
    const { openMKTDialog } = useNotifyNavigation();
    const [
        conditionNotificationsLoaded,
        setConditionNotificationsLoaded,
    ] = useState(false);

    const [mktNotifications, setMktNotifications] = useState(null);
    const [conditionNotifications, setConditionNotifications] = useState(null);

    // ===== Marketing Dialog handler =====

    const isMktNotification = useCallback(({ type, group, read = false }) => {
        if (type && group) {
            const isAllowMKTType = ALLOWED_NOTIFICATION_TYPES.includes(type);
            // const isMKTGroup = NOTIFICATION_GROUPS.COMMUNICATION === group; // fake data
            const isMKTGroup = NOTIFICATION_GROUPS.MKTOB === group;
            return isAllowMKTType && isMKTGroup && !read;
        }
    }, []);

    const updateNotificationStatus = useCallback(async (item) => {
        await notificationService.updateNotificationStatus({
            id: item.iD.toString(),
            action: "R",
        });
    }, []);

    const onCloseDialogHandler = useCallback(() => {
        setMktNotifications((prevMktNotifications) => {
            const list = prevMktNotifications.filter((_, i) => i > 0);
            if (list?.length === 0) {
                checkNotificationRequest();
            }
            return list;
        });
    }, [checkNotificationRequest]);

    const mktDialogHandler = useCallback(() => {
        if (mktNotifications?.length <= 0) return;

        openMKTDialog(mktNotifications[0], onCloseDialogHandler);
        updateNotificationStatus(mktNotifications[0]);
    }, [
        mktNotifications,
        openMKTDialog,
        updateNotificationStatus,
        onCloseDialogHandler,
    ]);

    // Load mkt dialog when mktNotifications && conditionNotificationsLoaded
    useEffect(() => {
        if (mktNotifications?.length > 0 && conditionNotificationsLoaded) {
            mktDialogHandler();
        }
    }, [mktNotifications, mktDialogHandler, conditionNotificationsLoaded]);

    // Update mkt notifications
    useEffect(() => {
        if (suggestedNotifications) {
            const mktList = suggestedNotifications.filter((item) =>
                isMktNotification(item)
            );
            setMktNotifications(mktList);
        }
    }, [suggestedNotifications, isMktNotification]);

    // ===== End: Marketing Dialog handler =====

    // ===== Condition Dialog handler =====

    const updateStatusDisplayConditionDialog = useCallback(
        (templateName) => {
            const cachedStatuses = JSON.parse(
                localStorage.getItem("statusConditionDialog")
            );
            const stringifyStatuses = JSON.stringify({
                ...cachedStatuses,
                [templateName]: false,
            });
            localStorage.setItem("statusConditionDialog", stringifyStatuses);

            const list = conditionNotifications.filter((_, i) => i > 0);
            setConditionNotifications(list);
        },
        [conditionNotifications]
    );

    const getStatusDisplayConditionDialog = (templateName) => {
        const cachedStatuses = JSON.parse(
            localStorage.getItem("statusConditionDialog")
        );

        if (!cachedStatuses || cachedStatuses[templateName] === undefined)
            return true;

        return false;
    };

    const closeDialog = useCallback(
        (templateName) => {
            updateStatusDisplayConditionDialog(templateName);
            GlobalDialogController.hide();
        },
        [updateStatusDisplayConditionDialog]
    );

    const navigateMKTPage = useCallback(
        (templateName, path) => {
            window.open(path, "_blank");
            closeDialog(templateName);
        },
        [closeDialog]
    );

    const getContentCondition = useCallback(
        (templateName) => {
            let contents = {};
            switch (templateName) {
                case LOCAL_STORAGE_STATUS?.IBFT_BANK_NAME:
                    contents = {
                        banner: `${process.env.REACT_APP_API_URL}/${t(
                            "IBFT:IBFT_popup_image"
                        )}`,
                        title: t("IBFT:IBFT_popup_change_bank_title"),
                        description: t("IBFT:IBFT_popup_change_bank_desc"),
                        buttons: [
                            {
                                variant: "primary",
                                label: t(
                                    "IBFT:IBFT_popup_change_bank_btn_viewmore"
                                ),
                                onClick: () =>
                                    navigateMKTPage(
                                        templateName,
                                        t(
                                            "IBFT:IBFT_bankdetails_notice_hyperlink"
                                        )
                                    ),
                            },
                            {
                                variant: "plain",
                                label: t(
                                    "IBFT:IBFT_popup_change_bank_btn_close"
                                ),
                                onClick: () => closeDialog(templateName),
                            },
                        ],
                    };
                    break;
                case LOCAL_STORAGE_STATUS?.IBFT_PHONE_NUMBER_AVAILABLE:
                    contents = {
                        banner: `${process.env.REACT_APP_API_URL}/${t(
                            "IBFT:IBFT_popup_image_alias_phone"
                        )}`,
                        title: t("IBFT:IBFT_popup_alias_phone_title"),
                        description: t("IBFT:IBFT_popup_alias_phone_desc"),
                        buttons: [
                            {
                                variant: "primary",
                                label: t(
                                    "IBFT:IBFT_popup_change_bank_btn_viewmore"
                                ),
                                onClick: () =>
                                    navigateMKTPage(
                                        templateName,
                                        t(
                                            "IBFT:IBFT_bankdetails_notice_hyperlink"
                                        )
                                    ),
                            },
                            {
                                variant: "plain",
                                label: t(
                                    "IBFT:IBFT_popup_change_bank_btn_close"
                                ),
                                onClick: () => closeDialog(templateName),
                            },
                        ],
                    };
                    break;
                default:
                    break;
            }
            return contents;
        },
        [t, closeDialog, navigateMKTPage]
    );

    const openDialog = useCallback((content) => {
        GlobalDialogController.show({
            component: () => <ConditionNotificationDialog content={content} />,
        });
    }, []);

    const conditionDialogHandler = useCallback(
        (notifications) => {
            const templateName = notifications[0];
            const isShowDialog = getStatusDisplayConditionDialog(templateName);
            if (isShowDialog) {
                const content = getContentCondition(templateName);
                openDialog(content);
                return;
            }

            // if dont show > remove item from list
            const list = notifications.filter((_, i) => i > 0);
            setConditionNotifications(list);
        },
        [getContentCondition, openDialog]
    );

    // update conditionNotifications
    useEffect(() => {
        // eslint-disable-next-line prefer-const
        let list = [];

        if (enableIBFTGW && !isNewUserIBFT) {
            list.push(LOCAL_STORAGE_STATUS?.IBFT_BANK_NAME);
        }

        if (enableIBFTGW && !isNewUserIBFT && enableAliasPhase2) {
            list.push(LOCAL_STORAGE_STATUS?.IBFT_PHONE_NUMBER_AVAILABLE);
        }

        setConditionNotifications(list);
    }, [enableIBFTGW, isNewUserIBFT, enableAliasPhase2]);

    // first load condition list -> load done -> load mkt dialog
    useEffect(() => {
        if (conditionNotificationsLoaded || !conditionNotifications) return;
        if (conditionNotifications?.length > 0) {
            conditionDialogHandler(conditionNotifications);
            return;
        }
        setConditionNotificationsLoaded(true);
    }, [
        conditionNotifications,
        conditionDialogHandler,
        conditionNotificationsLoaded,
    ]);

    // ===== End: Condition Dialog handler =====

    return <div />;
};

const mapState = (state) => ({
    suggestedNotifications: state.notification.suggestedNotifications,
    isNewUserIBFT: state.user?.info?.isNewUserIBFT,
    enableIBFTGW: state.user?.featureState?.data?.enableIBFTGW,
    enableAliasPhase2: state.user?.featureState?.data?.enableAliasPhase2,
    // enableIBFTGW: true,
    // isNewUserIBFT: false,
    // enableAliasPhase2: true,
});
const mapsDispatch = (dispatch) => ({
    checkNotificationRequest: () =>
        dispatch(notificationAction.checkNotificationRequest()),
});

export default connect(mapState, mapsDispatch)(NotificationPopoverMKT);
