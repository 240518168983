import { useTranslation } from "react-i18next";

import LspTextField from "@components/LspTextField";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    makeStyles,
} from "@material-ui/core";
import useCapitalize from "@helpers/useCapitalize";

const useStyles = makeStyles((theme) => ({
    addPayeeOption: {
        marginBottom: theme.spacing(3),
    },
    submitBtn: {
        marginTop: theme.spacing(1),
    },
}));

const HasSavePayeeInput = ({
    payeeName,
    updatePayeeName,
    hasSavePayee,
    updateHasSavePayee,
    error,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { capitalizeSentence } = useCapitalize();

    const onChangePayeeName = (value) => {
        updatePayeeName(capitalizeSentence(value));
    };

    return (
        <div>
            <FormControl
                component="fieldset"
                className={classes.addPayeeOption}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hasSavePayee}
                            onChange={(event) =>
                                updateHasSavePayee(
                                    event.target.checked.toString()
                                )
                            }
                            name="hasSavePayee"
                            color="primary"
                        />
                    }
                    label={t("topup_lb_save_question_mobile_number")}
                />
            </FormControl>
            {hasSavePayee && (
                <LspTextField
                    value={payeeName}
                    onChange={(e) => onChangePayeeName(e.target.value)}
                    error={!!error}
                    helperText={error || " "}
                    name="payeeName"
                    label={t("topup_lb_recipient_name")}
                    inputProps={{
                        maxLength: 35,
                        type: "text",
                    }}
                />
            )}
        </div>
    );
};
export default HasSavePayeeInput;
