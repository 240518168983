import { connect } from "react-redux";

import { Box, InputAdornment, CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import {
    CardNumberValidLengthValues,
    REGEX_PATTERN,
    RESPONSE_CODE,
} from "@config/constants";
import DoubleButtons from "@components/DoubleButtons";
import useAccounts from "@helpers/useAccounts";
import moveMoneyService from "@services/move-money";
import useNumber from "@helpers/useNumber";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";

const AddCardDestination = ({ onCancel, onSave, loading }) => {
    const { t } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const { parseNumber, formatCardNumber } = useNumber();
    const [cardNumber, setCardNumber] = useState("");
    const [error, setError] = useState("");
    const { spendAccount } = useAccounts();
    const [destination, setDestination] = useState(null);
    const destinationDescription = useMemo(() => {
        if (destination) {
            return `${
                destination.bank.accountName || destination.card.cardName
            } | ${
                destination.bank.bankShortName || destination.card.bankShortName
            }`;
        }
        return " ";
    }, [destination]);
    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onChangeCardNumber = (event) => {
        const cardNum = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER,
            text: event.target.value,
        });

        const value = formatCardNumber(cardNum);

        setCardNumber(value);
        if (!value) {
            setError(t("msg_we_need_this"));
        } else {
            setError("");
        }
    };

    const getTargetAccount = useCallback(
        async (target, callbackFunc) => {
            if (!spendAccount || !target || fetchingTargetAccount) {
                return;
            }

            const parsedTargetInfo = parseNumber(target, false);

            if (
                !CardNumberValidLengthValues.includes(parsedTargetInfo.length)
            ) {
                setError(t("card_number_hint"));
                return;
            }

            setFetchingTargetAccount(true);

            const payload = {
                targetInfo: parsedTargetInfo,
                bankAccount: spendAccount.no,
            };

            const response = await moveMoneyService.getAccountDetailByCardNumber(
                payload
            );

            if (!unmounted.current) {
                setFetchingTargetAccount(false);
                const { code, data } = response.data;
                switch (code) {
                    case RESPONSE_CODE.SUCCESS:
                        if (data.bank.accountNumber === spendAccount.no) {
                            setDestination(null);
                            setError(
                                t("payee_card_number_invalid_payee_is_payer")
                            );
                        } else {
                            setDestination({ ...data, targetInfo: target });
                            if (callbackFunc) {
                                callbackFunc({ ...data, targetInfo: target });
                            }
                        }
                        break;
                    case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                    case RESPONSE_CODE.WRONG_INFO:
                        setDestination(null);
                        setError(t("spend_lb_invalid_card_number"));
                        break;
                    default:
                        break;
                }
            }
        },
        [t, spendAccount, fetchingTargetAccount, parseNumber]
    );

    const onSaveHandler = useCallback(
        (target, destinationInfo) => {
            if (!target) {
                setError(t("msg_we_need_this"));
                return;
            }
            if (!destinationInfo) {
                getTargetAccount(target, (info) => {
                    onSave(info);
                });
            } else {
                onSave(destinationInfo);
            }
        },
        [getTargetAccount, onSave, t]
    );

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                onSaveHandler(cardNumber, destination);
            }}
        >
            <Box>
                <LspTextField
                    autoFocus
                    error={!!error}
                    name="cardNumber"
                    label={t("payee_card_number_label")}
                    helperText={error || destinationDescription}
                    inputProps={{
                        maxLength: 23,
                    }}
                    onChange={onChangeCardNumber}
                    value={cardNumber}
                    InputProps={{
                        endAdornment: fetchingTargetAccount ? (
                            <InputAdornment position="end">
                                <CircularProgress size={16} />
                            </InputAdornment>
                        ) : null,
                        type: "search",
                    }}
                    onBlur={() => getTargetAccount(cardNumber)}
                />
            </Box>
            <DoubleButtons
                primaryButton={{
                    label: t("payee_btn_add_destination"),
                    onClick: (e) => {
                        e.preventDefault();
                        onSaveHandler(cardNumber, destination);
                    },
                }}
                secondaryButton={{
                    label: t("lb_cancel"),
                    onClick: onCancel,
                }}
                disabled={loading}
                progressing={loading}
            />
        </form>
    );
};

const stateProps = (state) => ({
    ownerEmail: state.user?.info?.userEmail,
});

export default connect(stateProps)(AddCardDestination);
