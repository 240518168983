import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { useMemo, memo } from "react";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.black.main,
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(1),
    },
}));

const RolloverHistoryRecord = ({
    totalInterest,
    maturityDate,
    redeemAmount,
    historyType,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const isPartialWithDrawHistory = useMemo(() => {
        return historyType === "withdraw";
    }, [historyType]);

    return (
        <div className={classes.root}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flex="1">
                    <Box flex={1} textAlign="left">
                        <LspTypography variant="body2" component="span">
                            {isPartialWithDrawHistory
                                ? t(
                                      "TD:td_details_partial_withdraw_history_interest"
                                  )
                                : t("TD:td_details_rollover_history_interest")}
                        </LspTypography>
                    </Box>
                    <Box textAlign="right">
                        {isPartialWithDrawHistory && (
                            <LspTypography variant="heading3" component="span">
                                {formatNumber(totalInterest)}
                            </LspTypography>
                        )}
                        {!isPartialWithDrawHistory && (
                            <LspTypography
                                variant="heading3"
                                component="span"
                                color="success"
                            >
                                +{formatNumber(totalInterest)}
                            </LspTypography>
                        )}
                    </Box>
                </Box>
                <Box display="flex" paddingBottom={1}>
                    <Box flex={1} textAlign="left">
                        <LspTypography variant="body2" component="span">
                            {t("td_details_rollover_history_balance").replace(
                                "%@",
                                maturityDate
                            )}
                        </LspTypography>
                    </Box>
                    <Box textAlign="right">
                        <LspTypography variant="body2" component="span">
                            {formatNumber(redeemAmount)}
                        </LspTypography>
                    </Box>
                </Box>
            </Box>

            <Divider />
        </div>
    );
};

export default memo(RolloverHistoryRecord);
