/* eslint-disable jsx-a11y/label-has-associated-control */
import LspTypography from "@components/LspTypography";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import useNumber from "@helpers/useNumber";
import { Box, Icon, makeStyles, useTheme } from "@material-ui/core";

import clsx from "clsx";
import Interweave from "interweave";
import { memo, useCallback, useMemo } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: theme.palette.primary.main,
        padding: theme.spacing(4, 2, 3, 2),
        position: "relative",
    },
    chart: {
        width: 56,
        height: 56,
        margin: theme.spacing(4),
    },
    suggestLine: {
        fontStyle: "italic",
        position: "absolute",
        fontSize: 12,
        "&:before": {
            content: '""',
            height: 16,
            width: 1,
            position: "absolute",
            transform: "rotate(30deg)",
        },
        "& b": {
            fontSize: 14,
        },
    },
    topSuggest: {
        borderBottom: `1px solid ${theme.palette.neutral.grey2}`,
        top: theme.spacing(3),
        left: " 50%",
        color: theme.palette.black.contrastText,
        "&:before": {
            background: theme.palette.neutral.grey2,
            top: 19,
            left: -5,
        },
    },
    bottomSuggest: {
        borderBottom: `1px solid ${theme.palette.status.success}`,
        color: theme.palette.status.success,
        bottom: theme.spacing(8.25),
        right: "50%",
        "&:before": {
            background: theme.palette.status.success,
            top: 6,
            right: -5,
        },
    },
    missingPlan: {
        borderBottom: `1px solid ${theme.palette.status.pending}`,
        color: theme.palette.status.pending,
        "&:before": {
            background: theme.palette.status.pending,
        },
    },
    missingText: {
        color: `${theme.palette.status.pending} !important`,
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.status.success,
    },
    categoryIcon: {
        color: theme.palette.neutral.grey4,
        opacity: 0.1,
        fontSize: 100,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    categoryImage: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0.2,
    },
    uploadIcon: {
        color: theme.palette.neutral.white,
        cursor: "pointer",
    },
    uploadInput: {
        display: "none",
    },
    uploadWrapper: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1,
    },
}));

const imageUploadMaxSize = 1000000;
const acceptedFileExtensions = "image/jpg, image/gif, image/jpeg, image/png";

const GoalSaveDetailHeader = ({
    detail,
    isEditing,
    onUpdateGoalBackground,
    goalBackground,
    isNotOnTrack,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const theme = useTheme();

    const remainingAmount = useMemo(() => {
        const amount =
            detail?.targetAmount < detail?.contributedAmount
                ? 0
                : formatNumber(
                      detail?.targetAmount - detail?.contributedAmount
                  );

        return `${t("gs_label_need")} <b>${amount}</b>`;
    }, [detail, formatNumber, t]);

    const contributedAmount = useMemo(
        () =>
            `${t("gs_label_contributed")}<b> ${formatNumber(
                detail?.contributedAmount
            )}</b>`,
        [detail, t, formatNumber]
    );

    const iconRender = useMemo(() => {
        let icon = "";
        switch (detail?.goalCategory) {
            case "PHONE":
                icon = "phone";
                break;
            case "ELECTRONICS":
                icon = "laptop";
                break;
            case "VEHICLE":
                icon = "vehicle";
                break;
            case "TRAVEL":
                icon = "travel";
                break;
            case "JEWELRY":
                icon = "diamond";
                break;
            case "WEDDING":
                icon = "ring";
                break;
            case "BABY":
                icon = "baby";
                break;
            case "STUDY":
                icon = "study";
                break;
            case "OTHER":
            default:
                icon = "other";
                break;
        }

        return (
            <Icon
                className={`font-icon icon-${icon} ${classes.categoryIcon}`}
            />
        );
    }, [detail, classes]);

    const onDrop = useCallback(
        (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);

            if (!acceptedFileExtensions.includes(file.type)) {
                AlertController.show(
                    t("upload_avatar_msg_wrong_extension"),
                    AlertType.Error
                );
                return;
            }

            if (file.size > imageUploadMaxSize) {
                AlertController.show(
                    t("upload_payee_avatar_limit_size"),
                    AlertType.Error
                );
                return;
            }

            reader.onloadend = () => {
                onUpdateGoalBackground(reader.result.toString());
            };
        },
        [t, onUpdateGoalBackground]
    );

    return (
        <Box className={classes.header}>
            <Box>
                {isEditing && (
                    <Box className={classes.uploadWrapper}>
                        <form>
                            <input
                                accept="image/*"
                                id="upload-avatar"
                                multiple
                                type="file"
                                onChange={onDrop}
                                className={classes.uploadInput}
                            />
                            <label
                                htmlFor="upload-avatar"
                                className={classes.avatar}
                            >
                                <Icon
                                    className={`font-icon icon-avatarchange ${classes.uploadIcon}`}
                                />
                            </label>
                        </form>
                    </Box>
                )}
                {goalBackground && (
                    <img
                        className={classes.categoryImage}
                        alt="category icon"
                        src={goalBackground}
                    />
                )}

                {!goalBackground && iconRender}
            </Box>
            <Box className={`${classes.suggestLine} ${classes.topSuggest}`}>
                <Interweave content={remainingAmount} />
            </Box>
            <Box className={classes.chart}>
                <CircularProgressbar
                    value={detail?.FEPercent}
                    text={`${detail?.FEPercent}%`}
                    styles={buildStyles({
                        textColor: theme.palette.neutral.white,
                        pathColor: isNotOnTrack
                            ? theme.palette.gold.main
                            : theme.palette.status.success,
                    })}
                />
            </Box>
            <Box
                className={clsx(
                    `${classes.suggestLine} ${classes.bottomSuggest}`,
                    {
                        [classes.missingPlan]: isNotOnTrack,
                    }
                )}
            >
                <Interweave content={contributedAmount} />
            </Box>

            <Box display="flex" marginTop={1}>
                <Icon
                    className={`font-icon icon-beat ${clsx(classes.icon, {
                        [classes.missingText]: isNotOnTrack,
                    })}`}
                />
                <LspTypography color={isNotOnTrack ? "gold" : "success"}>
                    {isNotOnTrack
                        ? t("gs_label_you_are_behind")
                        : t("gs_label_you_on_track")}
                </LspTypography>
            </Box>
        </Box>
    );
};

export default memo(GoalSaveDetailHeader);
