import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";

import { GENDER_LIST } from "@config/constants";
import { KIND_OPTIONS } from "@containers/Wealth/wealthConstants";
import PageHeaderBack from "@components/PageHeaderBack";
import format from "date-fns/format";
import LspButton from "@components/LspButton";
import Content from "@components/Content";
import BasicInfo from "./BasicInfo";
import InfoSection from "./InfoSection";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
    },
    header: {
        background: theme.palette.primary.main,
        color: theme.palette.neutral.white,
        display: "flex",
        padding: theme.spacing(2.5),
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(2),
        fontSize: 32,
    },
    body: {
        padding: theme.spacing(2.5),
    },
    item: {
        width: "50%",
        marginBottom: theme.spacing(1),
    },
}));

const WealthTravelReviewInfo = ({
    onBack,
    onNext,
    ownerPolicy,
    selectedPackage,
    destination,
    travelDate,
    attendant,
    formDependentPolicy,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();
    const classes = useStyles();

    const basicInfo = useMemo(() => {
        return {
            pricing: selectedPackage?.pricing,
            destination: destination?.title,
            policyType:
                attendant?.type === KIND_OPTIONS.ME
                    ? t("wealth:lbi_travel_policy_type_individual")
                    : t("wealth:lbi_travel_policy_type_family"),
            travelDate: `${travelDate?.fromDate} - ${travelDate?.toDate}`,
        };
    }, [selectedPackage, destination, attendant, t, travelDate]);

    const ownerInfo = useMemo(() => {
        return {
            title: `${t("wealth_label_person")} 1 (${t(
                "wealth_travel_label_poplicy_holder"
            )})`,
            fullName: ownerPolicy?.fullName,
            legalId: ownerPolicy?.legalId,
            gender:
                ownerPolicy?.gender === GENDER_LIST[0]?.cfgKey
                    ? t(GENDER_LIST[0]?.cfgValue)
                    : t(GENDER_LIST[1]?.cfgValue),
            phoneNumber: ownerPolicy?.phoneNumber,
            dateOfBirth: ownerPolicy?.dateOfBirth,
            email: ownerPolicy?.email,
            nationality: ownerPolicy?.nationality?.value,
            address: `${ownerPolicy?.address}, ${ownerPolicy?.district?.cfgValue}, ${ownerPolicy?.city?.cfgValue}`,
        };
    }, [ownerPolicy, t]);

    const dependentInfo = useMemo(() => {
        // eslint-disable-next-line prefer-const
        let dependentList = [];
        if (!formDependentPolicy) return dependentList;

        const forms = Object.entries(formDependentPolicy);

        for (let i = 0; i < forms?.length; i++) {
            const type = forms[i][0];
            const data = forms[i][1];

            const label =
                type?.indexOf("adult") > -1
                    ? t("wealth_travel_adult")
                    : t("wealth_label_dependent");
            dependentList.push({
                title: `${t("wealth_label_person")} ${i + 2} (${label})`,
                fullName: data?.fullName?.value,
                legalId: data?.legalId?.value,
                gender:
                    data?.gender?.value === GENDER_LIST[0]?.cfgKey
                        ? t(GENDER_LIST[0]?.cfgValue)
                        : t(GENDER_LIST[1]?.cfgValue),
                dateOfBirth: format(data?.dateOfBirth?.value, "dd/MM/yyyy"),
                nationality: data?.nationality?.value?.value,
            });
        }

        return dependentList;
    }, [formDependentPolicy, t]);

    return (
        <Content>
            {onBack && <PageHeaderBack onBack={onBack} />}

            <Paper className={classes.wrapper}>
                <div className={classes.header}>
                    <Icon
                        className={`font-icon icon-reviewpolicy ${classes.icon}`}
                    />
                    <Box>
                        <LspTypography color="white" variant="heading1">
                            {t("wealth:GE00024")}
                        </LspTypography>
                        <LspTypography color="white" variant="body2">
                            {t(`wealth:GE00080_${selectedPackage?.productKey}`)}
                        </LspTypography>
                    </Box>
                </div>
                <Box>
                    <BasicInfo data={basicInfo} />
                    <LspTypography className={sharedClasses.formHeader}>
                        {t("wealth_travel_label_insured_information")}
                    </LspTypography>
                    <InfoSection
                        data={ownerInfo}
                        defaultExpanded
                        isHideHeader={attendant?.type === KIND_OPTIONS.ME}
                    />
                    {dependentInfo?.map((item, index) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <InfoSection data={item} key={index} />;
                    })}
                </Box>
            </Paper>
            {onNext && (
                <LspButton
                    fullWidth
                    className={sharedClasses.button}
                    onClick={onNext}
                >
                    {t("lb_next")}
                </LspButton>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    formDependentPolicy: state.insurance.formDependentPolicy,
    ownerPolicy: state.insurance.ownerPolicy,
    selectedPackage: state.insurance.selectedPackage,
    destination: state.insurance.destination,
    attendant: state.insurance.attendant,
    travelDate: state.insurance.travelDate,
});

export default memo(connect(mapState)(WealthTravelReviewInfo));
