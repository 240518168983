/* ------------- Types Definition ------------- */
export const SystemParamActionType = Object.freeze({
    GetSystemParamsRequest: "SystemParamActionType/GetSystemParamsRequest",
    GetSystemParamsSuccess: "SystemParamActionType/GetSystemParamsSuccess",
    GetSystemParamsError: "SystemParamActionType/GetSystemParamsError",
    Reset: "SystemParamActionType/Reset",
    GetPhoneConfigs: "SystemParamActionType/GetPhoneConfigs",
    GetPhoneConfigsSuccess: "SystemParamActionType/GetPhoneConfigsSuccess",
    GetPhoneConfigsError: "SystemParamActionType/GetPhoneConfigsError",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: SystemParamActionType.Reset,
    };
};

const getSystemParamsRequest = (payload) => {
    return {
        type: SystemParamActionType.GetSystemParamsRequest,
        payload,
    };
};
const getSystemParamsSuccess = (payload) => {
    return {
        type: SystemParamActionType.GetSystemParamsSuccess,
        payload,
    };
};
const getSystemParamsError = () => {
    return {
        type: SystemParamActionType.GetSystemParamsError,
    };
};

const getPhoneConfigs = () => {
    return {
        type: SystemParamActionType.GetPhoneConfigs,
    };
};
const getPhoneConfigsSuccess = (payload) => {
    return {
        type: SystemParamActionType.GetPhoneConfigsSuccess,
        payload,
    };
};
const getPhoneConfigsError = () => {
    return {
        type: SystemParamActionType.GetPhoneConfigsError,
    };
};

const systemParamsAction = {
    reset,
    getSystemParamsRequest,
    getSystemParamsSuccess,
    getSystemParamsError,
    getPhoneConfigs,
    getPhoneConfigsSuccess,
    getPhoneConfigsError,
};

export default systemParamsAction;
