import LspButton from "@components/LspButton";
import { LspDialogActions } from "@components/LspDialog";
import {
    LspDialogNoHeaderContent,
    LspDialogNoHeaderTitle,
} from "@components/LspNoHeaderDialog";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles } from "@material-ui/core";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: "none",
        maxWidth: 400,
        width: 350,
        textAlign: "left",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        paddingLeft: 0,
        "& > li": {
            display: "flex",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
    icon: {
        fontSize: 15,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1.5),
    },
}));

const IntroduceBooking = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const items = t("member:onboard_prekyc_features", { returnObjects: true });

    return (
        <>
            <LspDialogNoHeaderTitle>
                <Icon className="font-icon icon-Plus-SUCCESS" />
                <div>{t("member:account_status_title")}</div>
            </LspDialogNoHeaderTitle>
            <LspDialogNoHeaderContent>
                <LspTypography variant="body2">
                    <Interweave content={t("member:account_status_no_book")} />
                </LspTypography>
                <ul className={classes.list}>
                    {items?.map((item) => {
                        return (
                            <li>
                                <Icon
                                    className={`font-icon icon-ok ${classes.icon}`}
                                />
                                <Interweave content={item} />
                            </li>
                        );
                    })}
                </ul>
            </LspDialogNoHeaderContent>
            <LspDialogActions>
                <LspButton fullWidth onClick={onClose}>
                    {t("lb_btn_ok_got_it")}
                </LspButton>
            </LspDialogActions>
        </>
    );
};

export default IntroduceBooking;
