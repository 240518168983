import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PAYMENT_LABEL_STATUS } from "@config/constants";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { format, parse } from "date-fns";
import Interweave from "interweave";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        message: {
            color: theme.palette.error.main,
            border: `1px solid ${theme.palette.error.main}`,
            padding: theme.spacing(3),
            fontSize: theme.typography.pxToRem(16),
            marginTop: theme.spacing(2),
        },
        deleteReasonTxt: {
            textAlign: "left",
            "& > div": {
                color: theme.palette.grey[900],
                fontSize: theme.typography.pxToRem(12),
                fontWeight: 700,
            },
            "& > span": {
                color: theme.palette.neutral.grey1,
            },
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    };
});
const PaymentStatusMessage = ({ info, isSent, isHideMessage = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const status = useMemo(() => {
        return isSent ? info.labelPaymentStatus : info.labelStatus;
    }, [info, isSent]);

    const deleteReason = useMemo(() => {
        let content = "";
        if (info?.payeeList) {
            content = info?.payeeList[0]?.deletedReason || "";
        }
        return content;
    }, [info]);

    const message = useMemo(() => {
        let dateModify = "";
        let content = "";
        if (info.modifyDate) {
            dateModify = format(
                parse(info.modifyDate, "dd/MM/yyyy HH:mm:ss", new Date()),
                "dd/MM/yyyy"
            );
        }

        if (!!status && status === PAYMENT_LABEL_STATUS.DELETED) {
            // For sender view
            content = info.receiveName
                ? t("payment_msg_request_delete").replace(
                      "%@name",
                      `<b>${info.receiveName}</b>`
                  )
                : t("payment_msg_request_delete");
            content = dateModify
                ? content.replace("%@date", dateModify)
                : content;
        } else if (!!status && status === PAYMENT_LABEL_STATUS.CANCEL) {
            // For receiver view
            content = info?.userSentName
                ? t("payment_msg_request_cancel").replace(
                      "%@name",
                      `<b>${info?.userSentName}</b>`
                  )
                : t("payment_msg_request_delete");
            content = dateModify
                ? content.replace("%@date", dateModify)
                : content;
        }
        return content;
    }, [info, t, status]);

    const lastSenderRemind = useMemo(() => {
        let remindAbleDate = "";
        let remindDate = "";
        let content = "";

        if (!isSent || info?.ableRemind) return content;

        if (info.payeeList && info.payeeList[0].remindAbleTime) {
            remindAbleDate = format(
                parse(
                    info.payeeList[0].remindAbleTime,
                    "dd/MM/yyyy HH:mm:ss",
                    new Date()
                ),
                "dd/MM/yyyy"
            );

            remindDate = format(
                parse(
                    info.payeeList[0].lastRemindTime,
                    "dd/MM/yyyy HH:mm:ss",
                    new Date()
                ),
                "dd/MM/yyyy"
            );
        } else {
            remindDate = format(
                parse(info.modifyDate, "dd/MM/yyyy HH:mm:ss", new Date()),
                "dd/MM/yyyy"
            );
        }

        content = t("payment_sender_last_remind")
            .replace("%@start", remindDate)
            .replace("%@end", remindAbleDate);

        return content;
    }, [info, t, isSent]);

    const lastReceiverRemind = useMemo(() => {
        let remindDate = "";
        let content = "";
        if (isSent) return content;

        if (info?.lastRemindTime) {
            remindDate = format(
                parse(info?.lastRemindTime, "dd/MM/yyyy HH:mm:ss", new Date()),
                "dd/MM/yyyy"
            );
            content = t("payment_receiver_last_remind").replace(
                "%@",
                remindDate
            );
        }
        return content;
    }, [info, t, isSent]);

    return (
        <Box>
            {lastSenderRemind && !isHideMessage && (
                <Box marginTop={2}>
                    <LspTypography variant="body2" color="success">
                        <Interweave content={lastSenderRemind} />
                    </LspTypography>
                </Box>
            )}
            {lastReceiverRemind && !isHideMessage && (
                <Interweave
                    attributes={{
                        className: classes.remindTxt,
                    }}
                    content={lastSenderRemind}
                />
            )}
            {message && !isHideMessage && (
                <Box className={classes.message}>
                    <Interweave content={message} />

                    {deleteReason && (
                        <>
                            <Divider className={classes.divider} />
                            <Box className={classes.deleteReasonTxt}>
                                <div>{t("payment_lb_delete_reason")}</div>
                                <span>{deleteReason}</span>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default memo(PaymentStatusMessage);
