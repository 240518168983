/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    ButtonBase,
    Hidden,
    Icon,
    makeStyles,
    Box,
    Paper,
} from "@material-ui/core";
import { useState, memo, useRef, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { RESPONSE_CODE } from "@config/constants";
import LspButton from "@components/LspButton";
import clsx from "clsx";
import wealthService from "@services/wealth";
import LspCongratulation from "@components/LspCongratulation";
import { useHistory } from "react-router-dom";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LifeLongDocument from "../LifeLongDocument";

const useStyles = makeStyles((theme) => ({
    boxTitle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "120px",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(36),
        display: "block",
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        display: "block",
        width: "100%",
        textAlign: "right",
        cursor: "pointer",
    },

    buttonItem: {
        padding: theme.spacing(2.5, 4.25),
        flexDirection: "column",
        textAlign: "left",
        background: theme.palette.background.header,
        marginBottom: theme.spacing(1.5),
        boxShadow: theme.shadows[3],
        "&.selected": {
            background: theme.palette.background.paper,
            borderTop: `4px solid ${theme.palette.blue.main}`,
            width: "104%",
            marginLeft: theme.spacing(-1.5),
            " & > div > p": {
                color: theme.palette.blue.main,
            },
        },
    },
    boxBody: {
        padding: theme.spacing(3.75),
        " & > li": {
            border: `1px solid ${theme.palette.grey[300]}`,
            padding: theme.spacing(2, 2.5),
            margin: theme.spacing(1.25, 0),
            fontSize: theme.typography.pxToRem(14),
        },
    },
}));

const STEP = {
    CONFIRM: "CONFIRM",
    SUCCESS: "SUCCESS",
    INTRO: "INTRO",
};

const LifeLongProduct = ({
    rightPackage,
    setProkeySelected,
    prokeySelected,
    step,
    setStep,
    leftDetail,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const applyProduct = async () => {
        setLoading(true);
        const dataToPost = {
            productKey: prokeySelected,
        };
        const { data } = await wealthService.applyProduct(dataToPost);
        if (unmounted.current) return;
        setLoading(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setStep(STEP.SUCCESS);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const onCompleteHandler = () => {
        history.push(`products`);
    };

    return (
        <Content size="xs">
            <div>
                {step === STEP.CONFIRM && (
                    <>
                        <Icon
                            onClick={onCompleteHandler}
                            className={`font-icon icon-close ${classes.closeIcon}`}
                        />
                        <div className={classes.boxTitle}>
                            <Icon
                                className={`font-icon icon-managepolicy ${classes.icon}`}
                            />
                            <LspTypography variant="heading1" color="primary">
                                {t("wealth:SI00028")}
                            </LspTypography>
                        </div>
                        <Paper className={classes.boxBody}>
                            <LspTypography variant="body2" color="grey">
                                {t("wealth:SI00029")}
                            </LspTypography>
                            <li>
                                {
                                    t("wealth:SI00030", {
                                        returnObjects: true,
                                        defaultValue: "",
                                    })[0]
                                }
                            </li>

                            <LspTypography variant="body2" color="grey">
                                {t("wealth:SI00031")}
                            </LspTypography>
                        </Paper>
                        <Box mt={4}>
                            <LspButton
                                type="submit"
                                fullWidth
                                onClick={applyProduct}
                                progressing={loading}
                            >
                                {t("lb_submit")}
                            </LspButton>
                        </Box>
                    </>
                )}
                {!step && (
                    <>
                        <div className={classes.boxTitle}>
                            <Icon
                                className={`font-icon icon-selectproduct ${classes.icon}`}
                            />
                            <LspTypography variant="heading1" color="primary">
                                {t("wealth:SI00094")}
                            </LspTypography>
                        </div>
                        <div>
                            {rightPackage.map((value, key) => (
                                <ButtonBase
                                    className={clsx(classes.buttonItem, {
                                        selected:
                                            value.prokey === prokeySelected,
                                    })}
                                    key={key}
                                    onClick={() =>
                                        setProkeySelected(value.prokey)
                                    }
                                >
                                    <Box mb={1.25} width="100%">
                                        <LspTypography
                                            variant="body1"
                                            color="black"
                                        >
                                            {value.title.toUpperCase()}
                                        </LspTypography>
                                    </Box>
                                    <LspTypography variant="body2" color="grey">
                                        {value.desc}
                                    </LspTypography>
                                </ButtonBase>
                            ))}
                        </div>
                        <Box mt={3}>
                            <Hidden smDown>
                                <LspButton
                                    type="submit"
                                    fullWidth
                                    onClick={() => setStep(STEP.CONFIRM)}
                                >
                                    {t("wealth:SI00012")}
                                </LspButton>
                            </Hidden>

                            <Hidden mdUp>
                                <LspButton
                                    fullWidth
                                    onClick={() => setStep(STEP.INTRO)}
                                    type="submit"
                                >
                                    {t("lb_next")}
                                </LspButton>
                            </Hidden>
                        </Box>
                    </>
                )}

                {step === STEP.INTRO && (
                    <LifeLongDocument
                        leftDetail={leftDetail}
                        prokeySelected={prokeySelected}
                        step={step}
                        setStep={setStep}
                        isShowBtnSubmit
                    />
                )}

                {step === STEP.SUCCESS && (
                    <LspCongratulation
                        icon="like"
                        title={t("wealth:SI00032")}
                        contents={`${t("wealth:SI00033")}</br></br>${t(
                            "wealth:SI00034"
                        )}`}
                        button={{
                            label: t("lb_done"),
                            onClick: () => onCompleteHandler(),
                        }}
                    />
                )}
            </div>
        </Content>
    );
};

export default memo(LifeLongProduct);
