import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6),
    },
    formContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    modalForm: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[2],
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 450,
        },
    },
    modalFormContent: {
        textAlign: "center",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(5.5),
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
    },
    submitButton: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    extraActions: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    extraActionItem: {
        textAlign: "center",
        fontWeight: "600",
        marginTop: theme.spacing(4),
        fontSize: theme.typography.pxToRem(16),
    },
    colorBar: {
        marginTop: theme.spacing(5.5),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: "600",
        textAlign: "center",
        textTransform: "uppercase",
        whiteSpace: "pre-line",
    },
    desc: {
        color: theme.palette.grey[600],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "center",
        maxWidth: 260,
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
        },
    },
    succeedMsg: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "600",
        marginTop: theme.spacing(4.5),
        marginBottom: theme.spacing(4.5),
        color: theme.palette.grey[600],
        marginLeft: "auto",
        marginRight: "auto",
    },
    errorMsg: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(2.5),
    },
    language: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        "& .active": {
            fontWeight: 600,
        },
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
    },
    hasLanguage: {
        paddingTop: theme.spacing(3),
    },
    bottomSpace: {
        marginBottom: theme.spacing(2),
    },
    customColorBar: {
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    modalFormMobile: {
        [theme.breakpoints.down("xs")]: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    modalFormContentMobile: {
        [theme.breakpoints.down("xs")]: {
            flex: 1,
            justifyContent: "center",
            display: "inline-flex",
            flexDirection: "column",
        },
    },
}));
