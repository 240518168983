import DoubleButtons from "@components/DoubleButtons";
import LspButtonIconLink from "@components/LspButtonIconLink";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import {
    Box,
    FormControl,
    FormControlLabel,
    Icon,
    makeStyles,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import Interweave from "interweave";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    confirm: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(2),
    },
    optionList: {
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        "& > label": {
            width: "50%",
            marginLeft: 0,
            marginRight: 0,
        },
        "& > label:last-child": {
            paddingLeft: theme.spacing(3.5),
        },
    },
    optionWrapper: {
        position: "relative",
        width: "100%",
    },
    orLabel: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    footer: {
        "& button": {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
}));

const GoalSaveDetailConfirmDelete = ({
    onClose,
    onConfirm,
    planName,
    amount,
    goalHasMoney,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const buttons = [
        {
            label: t("lb_no"),
            onClick: onClose,
        },
        {
            label: t("lb_yes"),
            onClick: onConfirm,
        },
    ];

    const goalHasMoneyContent = useMemo(() => {
        return t("gs_question_before_delete_goal_2")
            .replace("%a@", formatNumber(amount))
            .replace("%n@", planName);
    }, [t, amount, planName, formatNumber]);

    const destinationList = useMemo(() => {
        return [
            {
                id: "spend",
                label: t("transaction_list_lb_spend_account"),
            },
            {
                id: "piggy",
                label: t("goal_save_account_piggy_title"),
            },
        ];
    }, [t]);

    const [selectedDestination, setSelectedDestination] = useState("");

    const onChangeDestination = (e) => {
        setSelectedDestination(e.target.value);
    };

    return (
        <>
            {!goalHasMoney && (
                <Box className={classes.confirm}>
                    <Box paddingY={2}>
                        <LspTypography>
                            <Interweave
                                content={`${t(
                                    "gs_question_before_delete_goal"
                                ).replace("%@", planName)}`}
                            />
                        </LspTypography>
                    </Box>
                    <DoubleButtons
                        primaryButton={buttons[0]}
                        secondaryButton={buttons[1]}
                    />
                </Box>
            )}

            {goalHasMoney && (
                <Box className={classes.confirm}>
                    <LspTypography>
                        <Interweave content={goalHasMoneyContent} />
                    </LspTypography>
                    <Box marginY={2} className={classes.optionWrapper}>
                        <Box className={classes.orLabel}>{t("lb_or")}</Box>
                        <FormControl fullWidth>
                            <RadioGroup
                                row
                                aria-label="destination"
                                name="destination"
                                className={classes.optionList}
                            >
                                {destinationList?.map((item) => {
                                    return (
                                        <FormControlLabel
                                            key={item.id}
                                            value={item.id}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    checked={
                                                        selectedDestination ===
                                                        item?.id
                                                    }
                                                    onChange={
                                                        onChangeDestination
                                                    }
                                                />
                                            }
                                            label={item.label}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box className={classes.footer}>
                        <LspButtonIconLink
                            startIcon={<Icon className="font-icon icon-ok" />}
                            onClick={() => onConfirm(selectedDestination)}
                            color="success"
                        >
                            {t("gs_lb_withdraw_and_delete")}
                        </LspButtonIconLink>
                        <LspButtonIconLink
                            startIcon={
                                <Icon className="font-icon icon-close" />
                            }
                            onClick={onClose}
                            color="error"
                        >
                            {t("lb_cancel")}
                        </LspButtonIconLink>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default memo(GoalSaveDetailConfirmDelete);
