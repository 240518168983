/* eslint-disable react/no-array-index-key */
import { Icon, makeStyles, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { memo, useMemo } from "react";
import { connect } from "react-redux";
import LspTypography from "@components/LspTypography";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import LspButton from "@components/LspButton";
import useNumber from "@helpers/useNumber";
import clsx from "clsx";
import PageHeaderBack from "@components/PageHeaderBack";

const useStyles = makeStyles((theme) => ({
    accountInfo: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(3),
        "& > div": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.down("xs")]: {
                paddingBottom: theme.spacing(1),
            },
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
        },
    },
    contact: {
        width: "100%",
        textAlign: "center",
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.neutral.grey3}`,
    },

    transactionBox: {
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
    },
    headerLine: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2),
        background: theme.palette.neutral.grey3,
    },
    title: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    body: {
        minHeight: 67,
        display: "flex",
        flexWrap: "wrap",
        "& > div": {
            width: "30%",
            paddingBottom: theme.spacing(1.25),
            [theme.breakpoints.down("xs")]: {
                width: "auto",
            },
        },
    },
    statusLine: {
        width: "100%",
        background: theme.palette.primary.main,
        textTransform: "uppercase",
        fontSize: 14,
        color: theme.palette.neutral.white,
        padding: theme.spacing(0.75, 2),
        fontWeight: 600,
    },
    seeMore: {
        textDecoration: "underline",
        textTransform: "uppercase",
        padding: theme.spacing(1.25, 2),
    },
    note: {
        fontStyle: "italic",
        padding: theme.spacing(2, 3),
    },
    button: {
        display: "flex",
        borderTop: `1px solid ${theme.palette.neutral.grey3}`,
        padding: theme.spacing(1),
        alignItems: "center",
        " & > div": {
            width: "33%",

            "&:last-child": {
                textAlign: "right",
                color: theme.palette.status.success,
                cursor: "pointer",
            },
        },
    },
    status: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.status.success,
    },
    down: {
        color: theme.palette.error.main,
    },
    readMore: {
        " & > a": {
            color: theme.palette.status.success,
            fontSize: theme.typography.pxToRem(14),
            cursor: "pointer",
            textTransform: "underline",
        },
        textAlign: "right",
    },
}));

const AccountOverview = ({ productDetail, onBack, onNext }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const contact = t("wealth:vc3_acc_contact_support").replace(
        "%@",
        t("wealth:vc3_vinacapital_hotline")
    );
    const { formatNumber } = useNumber();

    const loginMio = () => {
        const path = t("wealth:vc3_login_mio_link");
        window.open(path, "_blank");
    };

    const note2 = useMemo(() => {
        const noteValue = t(`wealth:vc3_note_${productDetail.productKey}`);
        const content = productDetail.navDate
            ? noteValue.replace("%@", productDetail.navDate)
            : noteValue;

        return content;
    }, [t, productDetail]);
    return (
        <>
            <PageHeaderBack onBack={onBack}>
                {productDetail.FETitle}
            </PageHeaderBack>
            <div className={classes.readMore}>
                <a onClick={() => onNext(VINA_WEALTH_STEPS.DETAIL)}>
                    {t("wealth:vinawealth2_acc_readmore_btn")}
                </a>
            </div>

            <Paper className={classes.transactionBox}>
                <div className={classes.headerLine}>
                    <div className={classes.title}>
                        <Icon
                            className={`font-icon icon-vcaccount ${classes.icon}`}
                        />
                        <LspTypography variant="heading3" color="primary">
                            {t("wealth:vc3_acc_overview")}
                        </LspTypography>
                    </div>
                    <div />
                </div>
                <div className={`${classes.body} ${classes.accountInfo}`}>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_balance")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.FECurrentBalance}
                        </LspTypography>
                    </div>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_units")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.FEFundUnits}
                        </LspTypography>
                    </div>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_lastest_nav")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.nav
                                ? formatNumber(productDetail.nav)
                                : t("wealth:vc3_lb_na")}
                        </LspTypography>
                        {productDetail.percent && (
                            <span
                                className={clsx(
                                    classes.status,
                                    classes[productDetail.FEStatusUnit]
                                )}
                            >
                                {productDetail.percent}%
                            </span>
                        )}
                    </div>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_ori_cost")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.originalCost
                                ? formatNumber(productDetail.originalCost)
                                : t("wealth:vc3_lb_na")}
                        </LspTypography>
                    </div>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_unreallized_gain_loss")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.balanceUnrealizedGainLoss
                                ? formatNumber(
                                      productDetail.balanceUnrealizedGainLoss
                                  )
                                : t("wealth:vc3_lb_na")}
                        </LspTypography>
                    </div>
                    <div>
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:vc3_acc_reallized_gain_loss")}
                        </LspTypography>
                        <LspTypography>
                            {productDetail.realizedGainLoss
                                ? formatNumber(productDetail.realizedGainLoss)
                                : t("wealth:vc3_lb_na")}
                        </LspTypography>
                    </div>
                </div>
                <div className={classes.note}>
                    <LspTypography variant="body2" color="grey">
                        {t("wealth:vc3_note1")}
                    </LspTypography>
                    <LspTypography variant="body2" color="grey">
                        {note2}
                    </LspTypography>
                </div>
                <div className={classes.button}>
                    <div />
                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={productDetail.FEBtnFunc}
                    >
                        {t("wealth:vc3_contribute_btn")}
                    </LspButton>
                    <div onClick={loginMio}>
                        <span> {t("wealth:vc3_login_mio_btn")}</span>
                    </div>
                </div>

                <div className={classes.contact}>
                    <LspTypography variant="body2">{contact}</LspTypography>
                </div>
            </Paper>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    productDetail: state.vinaWealth.productDetail,
});

export default memo(connect(mapState)(AccountOverview));
