/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, Paper, Box } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.neutral.white,

        fontSize: theme.typography.pxToRem(35),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },
    iconBack: {
        cursor: "pointer",
    },

    list: {
        listStyleType: "none",
        padding: "0",
    },
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    boxTitle: {
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        background: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },
    boxBody: {
        padding: theme.spacing(3.75, 2.5, 3.75, 5.5),
        "& > div": {
            padding: theme.spacing(0, 0, 2.5, 3),
            position: "relative",
            "&::before": {
                position: "absolute",
                content: "''",
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: theme.palette.blue.main,
                left: 0,
                top: 7,
            },
        },
    },
    boxBodyItem: {
        marginTop: theme.spacing(1.25),
        position: "relative",
        "&::before": {
            position: "absolute",
            content: "''",
            width: 5,
            height: 5,
            borderRadius: "50%",
            backgroundColor: theme.palette.neutral.black,
            left: -13,
            top: 7,
        },
    },
}));

const STEP = {
    CONFIRM: "CONFIRM",
    SUCCESS: "SUCCESS",
};

const LifeLongDocument = ({
    leftDetail,
    prokeySelected,
    step,
    setStep,
    isShowBtnSubmit,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const goBack = () => {
        if (step) {
            setStep("");
        } else {
            history.push("products");
        }
    };

    return (
        <Content>
            <div>
                {step !== STEP.SUCCESS && (
                    <Icon
                        onClick={goBack}
                        className={`font-icon icon-longarrowleft ${classes.iconBack}`}
                    />
                )}

                <div>
                    <ul className={classes.list}>
                        {leftDetail?.map((row, index) => {
                            return (
                                prokeySelected === row.prokey && (
                                    <Paper key={index}>
                                        <li
                                            key={`row-${index}`}
                                            className={classes.item}
                                        >
                                            <div className={classes.boxTitle}>
                                                <Icon
                                                    className={`font-icon icon-${row.iconHeader} ${classes.icon}`}
                                                />
                                                <div>
                                                    <LspTypography
                                                        variant="heading1"
                                                        color="white"
                                                    >
                                                        {row.titleHeader}
                                                    </LspTypography>

                                                    <LspTypography
                                                        variant="body2"
                                                        color="white"
                                                    >
                                                        {row.descHeader}
                                                    </LspTypography>
                                                </div>
                                            </div>
                                            <div className={classes.boxBody}>
                                                {row?.content?.map(
                                                    (item, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <Box pb={1.25}>
                                                                    <LspTypography
                                                                        key={`item-${i}`}
                                                                        variant="body1"
                                                                        color="grey"
                                                                    >
                                                                        {
                                                                            item[0]
                                                                        }
                                                                    </LspTypography>
                                                                </Box>

                                                                {item.map(
                                                                    (v, k) =>
                                                                        k !==
                                                                            0 && (
                                                                            <Box
                                                                                className={
                                                                                    classes.boxBodyItem
                                                                                }
                                                                                key={
                                                                                    k
                                                                                }
                                                                            >
                                                                                {
                                                                                    v
                                                                                }
                                                                            </Box>
                                                                        )
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </li>
                                    </Paper>
                                )
                            );
                        })}
                    </ul>
                    {isShowBtnSubmit && (
                        <LspButton
                            type="submit"
                            fullWidth
                            onClick={() => setStep(STEP.CONFIRM)}
                        >
                            {t("wealth:SI00012")}
                        </LspButton>
                    )}
                </div>
            </div>
        </Content>
    );
};

export default memo(LifeLongDocument);
