/* eslint-disable import/no-named-default */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import api from "@config/api";
import { RESPONSE_CODE } from "@config/constants";
import { default as en } from "./resources/en.json";
import { default as vn } from "./resources/vn.json";
import { default as enHangout } from "./resources/hangout_en.json";
import { default as vnHangout } from "./resources/hangout_vn.json";
import { default as enMaster } from "./resources/master_en.json";
import { default as vnMaster } from "./resources/master_vn.json";
import { default as enEkyc } from "./resources/ekyc_en.json";
import { default as vnEkyc } from "./resources/ekyc_vn.json";
import { default as enTxnapptype } from "./resources/txnapptype_en.json";
import { default as vnTxnapptype } from "./resources/txnapptype_vn.json";
import { default as enLixi } from "./resources/lixi_en.json";
import { default as vnLixi } from "./resources/lixi_vn.json";
import { default as enPayAnyone } from "./resources/payanyone_en.json";
import { default as vnPayAnyone } from "./resources/payanyone_vn.json";
import { default as enCreditCard } from "./resources/prc000009_en.json";
import { default as vnCreditCard } from "./resources/prc000009_vn.json";
import { default as enWealth } from "./resources/w_prc000069_en.json";
import { default as vnWealth } from "./resources/w_prc000069_vn.json";
import { default as enInsurance } from "./resources/prc000010_en.json";
import { default as vnInsurance } from "./resources/prc000010_vn.json";

import { default as enLoans } from "./resources/prc000006_en.json";
import { default as vnLoans } from "./resources/prc000006_vn.json";

import { default as enLifeInsurance } from "./resources/prc000008_en.json";
import { default as vnLifeInsurance } from "./resources/prc000008_vn.json";

import { default as enWealthProduct } from "./resources/wealthproduct_en.json";
import { default as vnWealthProduct } from "./resources/wealthproduct_vn.json";
import { default as enMemberPlan } from "./resources/memberplan_en.json";
import { default as vnMemberPlan } from "./resources/memberplan_vn.json";
import { default as enPendingTask } from "./resources/pendingtask_en.json";
import { default as vnPendingTask } from "./resources/pendingtask_vn.json";

import { default as enRegulation } from "./resources/contentBase_en.json";
import { default as vnRegulation } from "./resources/contentBase_vn.json";

import { default as sysParamContent } from "./resources/systemParams.json";
import { default as enVinaWealth } from "./resources/prc_vinawealth_en.json";
import { default as vnVinaWealth } from "./resources/prc_vinawealth_vn.json";

import { default as enWealthCreditCard } from "./resources/mastercard_en.json";
import { default as vnWealthCreditCard } from "./resources/mastercard_vn.json";
import { default as enWealthCreditCardV3 } from "./resources/mastercardv3_en.json";
import { default as vnWealthCreditCardV3 } from "./resources/mastercardv3_vn.json";

import { default as enLibertyNationality } from "./resources/liberty_nationalities_en.json";
import { default as vnLibertyNationality } from "./resources/liberty_nationalities_vn.json";

import { default as enFAQ } from "./resources/faqs_000070_en.json";
import { default as vnFAQ } from "./resources/faqs_000070_vn.json";
import { default as enDashboard } from "./resources/dashboard_en.json";
import { default as vnDashboard } from "./resources/dashboard_vn.json";
import { default as enSplitBill } from "./resources/splitbill_en.json";
import { default as vnSplitBill } from "./resources/splitbill_vn.json";

import { default as enDebitCard } from "./resources/debitcard_en.json";
import { default as vnDebitCard } from "./resources/debitcard_vn.json";

import { default as enIBFT } from "./resources/IBFT_en.json";
import { default as vnIBFT } from "./resources/IBFT_vn.json";

import { default as enVirtual } from "./resources/virtualcard_en.json";
import { default as vnVirtual } from "./resources/virtualcard_vn.json";
import { default as enTXNList } from "./resources/txnlist_en.json";
import { default as vnTXNList } from "./resources/txnlist_vn.json";

import { default as enTD } from "./resources/TD_en.json";
import { default as vnTD } from "./resources/TD_vn.json";
import { default as enOverdraft } from "./resources/overdraft_en.json";
import { default as vnOverdraft } from "./resources/overdraft_vn.json";
import { default as enMoneypot } from "./resources/moneypot_en.json";
import { default as vnMoneypot } from "./resources/moneypot_vn.json";
import { default as vnGGAuthen } from "./resources/gg_authen_vn.json";
import { default as enGGAuthen } from "./resources/gg_authen_en.json";

const resources = {
    en: {
        translation: en,
        hangout: enHangout,
        sysParamContent,
        master: enMaster,
        ekyc: enEkyc,
        txnapptype: enTxnapptype,
        lixi: enLixi,
        payAnyone: enPayAnyone,
        creditCard: enCreditCard,
        wealth: {
            ...enWealth,
            ...enWealthProduct,
            ...enLoans,
            ...enVinaWealth,
            ...enWealthCreditCard,
            ...enWealthCreditCardV3,
            ...enLifeInsurance,
            ...enInsurance,
            ...enLibertyNationality,
        },
        member: enMemberPlan,
        regulation: enRegulation,
        pendingTask: enPendingTask,
        faq: enFAQ,
        dashboard: enDashboard,
        debitCard: enDebitCard,
        splitBill: enSplitBill,
        IBFT: enIBFT,
        virtual: enVirtual,
        txnList: enTXNList,
        TD: enTD,
        overdraft: enOverdraft,
        pot: enMoneypot,
        gg_authen: enGGAuthen,
    },
    vn: {
        translation: vn,
        hangout: vnHangout,
        sysParamContent,
        master: vnMaster,
        ekyc: vnEkyc,
        txnapptype: vnTxnapptype,
        lixi: vnLixi,
        payAnyone: vnPayAnyone,
        creditCard: vnCreditCard,
        wealth: {
            ...vnWealth,
            ...vnWealthProduct,
            ...vnLoans,
            ...vnVinaWealth,
            ...vnWealthCreditCard,
            ...vnWealthCreditCardV3,
            ...vnLifeInsurance,
            ...vnInsurance,
            ...vnLibertyNationality,
        },
        member: vnMemberPlan,
        regulation: vnRegulation,
        pendingTask: vnPendingTask,
        faq: vnFAQ,
        dashboard: vnDashboard,
        debitCard: vnDebitCard,
        splitBill: vnSplitBill,
        IBFT: vnIBFT,
        virtual: vnVirtual,
        txnList: vnTXNList,
        TD: vnTD,
        overdraft: vnOverdraft,
        pot: vnMoneypot,
        gg_authen: vnGGAuthen,
    },
};

// Handler for language from url
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const languageFromUrl = urlParams.get("language");
let lng = "vn";
if (languageFromUrl) {
    lng = languageFromUrl;
} else if (localStorage.getItem("lang")) {
    lng = localStorage.getItem("lang");
}

localStorage.setItem("lang", lng.toLowerCase());

const openRequest = indexedDB.open("timoTranslate");

const checkContentApis = "user/public/contents";
const contentApis = "public/contents";

const TRANSLATE_VN_KEYS = [
    "hangout_vn",
    "faqs_000070_vn",
    "migration_vn",
    "memberplan_vn",
    "ekyc_vn",
    "dashboard_vn",
    "gg_authen_vn",
];

const TRANSLATE_EN_KEYS = [
    "hangout_en",
    "faqs_000070_en",
    "migration_en",
    "memberplan_en",
    "ekyc_en",
    "dashboard_en",
    "gg_authen_vn",
];

openRequest.onupgradeneeded = async () => {
    // triggers if the client had no database
    // ...perform initialization...
    const db = openRequest.result;

    if (!db.objectStoreNames.contains("translatePrivate")) {
        // if there's no "translatePrivate" store
        db.createObjectStore("translatePrivate", {
            keyPath: "keys",
            autoIncrement: true,
        });
    }

    if (!db.objectStoreNames.contains("translatePrivateContent")) {
        // if there's no "translatePrivateContent" store
        db.createObjectStore("translatePrivateContent", {
            keyPath: "keys",
            autoIncrement: true,
        });
    }

    if (!db.objectStoreNames.contains("translate")) {
        // if there's no "translate" store
        db.createObjectStore("translate", {
            keyPath: "keys",
            autoIncrement: true,
        });
    }

    if (!db.objectStoreNames.contains("translateContent")) {
        // if there's no "translateContent" store
        db.createObjectStore("translateContent", {
            keyPath: "keys",
            autoIncrement: true,
        });
    }
};

const insertResourceTranslate = (lang, key, data) => {
    i18n.addResourceBundle(lang, key, data);
};

const getContentTranslate = async (key) => {
    const db = openRequest.result;

    const requestApis = await api.get(`${contentApis}/${key}`);

    if (requestApis.status === RESPONSE_CODE.SUCCESS) {
        const { data } = requestApis;

        const transactionContent = db.transaction(
            "translateContent",
            "readwrite"
        );
        const translateKeysContentData = transactionContent.objectStore(
            "translateContent"
        );

        // save content data
        if (data) {
            translateKeysContentData.put({ keys: key, value: data });
            if (TRANSLATE_VN_KEYS.includes(key)) {
                insertResourceTranslate("vn", key.replace("_vn", ""), data);
            }

            if (TRANSLATE_EN_KEYS.includes(key)) {
                insertResourceTranslate("en", key.replace("_en", ""), data);
            }
        }
    }
};

const getTranslateKeys = async () => {
    const db = openRequest.result;

    const requestApis = await api.get(`${checkContentApis}/check`);

    if (requestApis.status === RESPONSE_CODE.SUCCESS) {
        const { data } = requestApis.data;

        const transaction = db.transaction("translate", "readwrite");
        const translateKeysData = transaction.objectStore("translate");

        if (data) {
            Object.keys(data).forEach((item) => {
                const isTranslateVnExist = TRANSLATE_VN_KEYS.includes(item);
                const isTranslateENExist = TRANSLATE_EN_KEYS.includes(item);

                if (isTranslateVnExist || isTranslateENExist) {
                    const checkExist = translateKeysData.get(item);

                    // on check success
                    checkExist.onsuccess = (event) => {
                        const { result } = event.target;
                        if (!result || data[item] !== result.value) {
                            // console.log(`The translate with ${item} not found`);
                            const translateKey = {
                                value: data[item],
                                keys: item,
                            };
                            getContentTranslate(item);
                            translateKeysData.put(translateKey);
                        } else {
                            // console.log(
                            //     `The translate with key ${item} existed`
                            // );
                        }
                    };
                }
            });
        }

        transaction.oncomplete = () => {
            const transactionContent = db.transaction(
                "translateContent",
                "readwrite"
            );
            const translateKeysContentData = transactionContent.objectStore(
                "translateContent"
            );

            // update content for vn
            TRANSLATE_VN_KEYS.forEach((item) => {
                const contentData = translateKeysContentData.get(item);
                contentData.onsuccess = (event) => {
                    const { result } = event.target;
                    if (result) {
                        if (TRANSLATE_VN_KEYS.includes(result.keys)) {
                            insertResourceTranslate(
                                "vn",
                                result.keys.replace("_vn", ""),
                                result.value
                            );
                        }
                    }
                };
            });

            // update content for en
            TRANSLATE_EN_KEYS.forEach((item) => {
                const contentData = translateKeysContentData.get(item);
                contentData.onsuccess = (event) => {
                    const { result } = event.target;
                    if (result) {
                        if (TRANSLATE_EN_KEYS.includes(result.keys)) {
                            insertResourceTranslate(
                                "en",
                                result.keys.replace("_en", ""),
                                result.value
                            );
                        }
                    }
                };
            });
        };
    }
};

openRequest.onsuccess = () => {
    getTranslateKeys();
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lng.toLowerCase(), // If set to 'cimode' the output text will be the key
        fallbackLng: "vn",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
