import produce from "immer";
import { OTPDialogActionType } from "../actions/otp-dialog";

/* ------------- Initial State ------------- */
/**
 * `refNo`, `submitFunc`, `resendFunc` - these props are used for the in component handler (without going through saga and OTP component internal handlers)
 */
const initialState = {
    opened: false,
    type: null,
    id: null,
    refNo: null,
    submitFunc: null,
    resendFunc: null,
    loading: false,
    dialogTitle: null,
    dialogSubtitle: null,
    dialogDescription: null,
    dialogHideResendButton: false,
    dialogOnCancel: null,
    otpError: null,
};

/* ------------- Reducer ------------- */
const otpDialogReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case OTPDialogActionType.Open:
                const {
                    type,
                    refNo,
                    submitFunc,
                    resendFunc,
                    dialogTitle,
                    dialogSubtitle,
                    dialogDescription,
                    dialogHideResendButton,
                    dialogOnCancel,
                } = action.payload;

                draft.opened = true;
                draft.type = type;
                draft.id = new Date().getTime();
                draft.refNo = refNo;
                draft.submitFunc = submitFunc;
                draft.resendFunc = resendFunc;
                draft.loading = false;
                draft.dialogTitle = dialogTitle;
                draft.dialogSubtitle = dialogSubtitle;
                draft.dialogDescription = dialogDescription;
                draft.dialogHideResendButton = dialogHideResendButton || false;
                draft.dialogOnCancel = dialogOnCancel || null;
                draft.otpError = null;
                break;
            case OTPDialogActionType.SetError:
                draft.otpError = action.payload;
                break;
            case OTPDialogActionType.Close:
                draft.opened = false;
                draft.type = null;
                draft.refNo = null;
                draft.submitFunc = null;
                draft.resendFunc = null;
                draft.loading = false;
                draft.dialogTitle = null;
                draft.dialogSubtitle = null;
                draft.dialogDescription = null;
                draft.dialogHideResendButton = false;
                draft.otpError = null;
                draft.dialogOnCancel = null;
                break;
            case OTPDialogActionType.SetLoading:
                draft.loading = action.payload;
                break;
            default:
                break;
        }
    });

export default otpDialogReducer;
