/* eslint-disable no-console */
import { SECRET_KEY } from "@config/constants";
import CryptoJS from "crypto-js";

const decrypt = (userKey, userId, cipherText) => {
    if (cipherText) {
        const data = userKey + userId + SECRET_KEY.SALT;

        const key = CryptoJS.MD5(data).toString();

        const keyHex = CryptoJS.enc.Utf8.parse(key);

        const decrypted = CryptoJS.TripleDES.decrypt(cipherText, keyHex, {
            mode: CryptoJS.mode.ECB,
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }
};

export default decrypt;
