import Content from "@components/Content";
import LspButton from "@components/LspButton";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";

import { makeStyles } from "@material-ui/core";
import contentService from "@services/content";
import { useMemo, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    iframe: {
        "& iframe": {
            border: "none",
            overflowX: "hidden !important",
            background: "white",
            minHeight: "50vh",
        },
        "& html, body": {
            maxWidth: "100%",
            overflowX: "hidden !important",
        },
    },
}));
const TermConditionDialog = ({ header, url, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [content, setContent] = useState("");

    const isPdf = useMemo(() => {
        const extension = url?.slice(-4);
        return extension === ".pdf";
    }, [url]);

    useEffect(() => {
        if (url) {
            let finalUrl = url;
            let isFullLink = url.indexOf("http") > -1;

            if (!isFullLink) {
                finalUrl = `${process.env.REACT_APP_API_URL}${finalUrl}`;
                isFullLink = true;
            }

            contentService
                .getContent({ url: finalUrl, isFullLink })
                .then((result) => {
                    setContent(result.data);
                });
        }
    }, [url]);

    return (
        <Content>
            <LspDialogTitle>
                <div>{header}</div>
            </LspDialogTitle>
            <LspDialogContent>
                <div className={classes.iframe}>
                    {!isPdf && (
                        <iframe
                            title="test"
                            srcDoc={content}
                            width="100%"
                            height={300}
                        />
                    )}

                    {isPdf && (
                        <iframe
                            title="test"
                            src={url}
                            width="100%"
                            height={300}
                        />
                    )}

                    {/* <Interweave content={content} /> */}
                </div>
            </LspDialogContent>
            <LspDialogActions>
                <LspButton type="submit" fullWidth onClick={onClose}>
                    {t("lb_close")}
                </LspButton>
            </LspDialogActions>
        </Content>
    );
};

export default TermConditionDialog;
