import { makeStyles, Radio } from "@material-ui/core";
import { isFunction } from "lodash";
import { memo } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    radioItem: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
    },
    radioItemLeft: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2),
    },
    radioCircle: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        border: `1.5px solid ${theme.palette.magicalGrey[300]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    radioCircleActive: {
        border: `1.5px solid rgba(16, 24, 32, 0.1);`,
        backgroundColor: theme.palette.green.main,
    },
    radioCircleChild: {
        borderRadius: "50%",
        width: 12,
        height: 12,
        backgroundColor: theme.palette.neutral.white,
    },
    radioItemRight: {
        display: "inline-flex",
        flex: 1,
        flexDirection: "column",
        textAlign: "left",
    },
    radioItemTitle: {
        color: theme.palette.white.contrastText,
        marginBottom: theme.spacing(0.25),
    },
    radioItemText: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: "18px",
        color: theme.palette.magicalGrey[400],
    },
    radioItemTextChange: {
        color: theme.palette.green.main,
        textDecoration: "underline",
        fontWeight: 700,
        cursor: "pointer",
    },
    disabled: {
        color: theme.palette.magicalGrey[300],
    },
    customRadioButton: {
        "& .MuiSvgIcon-root": {
            fontSize: 24,
        },
        padding: theme.spacing(1, 0),
    },
}));

const LspRadioButton = ({
    isSelected,
    title,
    description,
    isActiveChangeAction,
    onItemSelected,
    onItemChangeAddress,
    cfgKey,
    disabled,
    loading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleOnSelected = () => {
        if (isFunction(onItemSelected) && !disabled) {
            onItemSelected({ title, description, cfgKey });
        }
    };

    const handleItemChange = (event) => {
        event.stopPropagation();
        if (isFunction(onItemChangeAddress)) {
            onItemChangeAddress();
        }
    };

    return (
        <>
            {loading && (
                <div className={classes.radioItem}>
                    <div className={classes.radioItemLeft}>
                        <Skeleton variant="circle" width={24} height={24} />
                    </div>
                    <div className={classes.radioItemRight}>
                        <LspTypography
                            className={`${classes.radioItemTitle} ${
                                disabled ? classes.disabled : ""
                            }`}
                            variant="heading3"
                        >
                            <Skeleton variant="text" width={150} />
                        </LspTypography>
                        <LspTypography
                            className={`${classes.radioItemText} ${
                                disabled ? classes.disabled : ""
                            }`}
                            variant="body3"
                        >
                            <Skeleton variant="text" width="100%" />
                        </LspTypography>
                    </div>
                </div>
            )}
            {!loading && (
                <div className={classes.radioItem} onClick={handleOnSelected}>
                    <div className={classes.radioItemLeft}>
                        <Radio
                            className={classes.customRadioButton}
                            checkedIcon={
                                <div
                                    className={`${classes.radioCircle} ${classes.radioCircleActive}`}
                                >
                                    <div className={classes.radioCircleChild} />
                                </div>
                            }
                            disabled={disabled}
                            checked={isSelected}
                        />
                    </div>
                    <div className={classes.radioItemRight}>
                        <LspTypography
                            className={`${classes.radioItemTitle} ${
                                disabled ? classes.disabled : ""
                            }`}
                            variant="heading3"
                        >
                            {title}
                        </LspTypography>
                        <LspTypography
                            className={`${classes.radioItemText} ${
                                disabled ? classes.disabled : ""
                            }`}
                            variant="body3"
                        >
                            {description} &nbsp;
                            {isActiveChangeAction && (
                                <span
                                    className={classes.radioItemTextChange}
                                    onClick={handleItemChange}
                                >
                                    {t(
                                        "debitCard:reissuecard_receive_card_change_add"
                                    )}
                                </span>
                            )}
                        </LspTypography>
                    </div>
                </div>
            )}
        </>
    );
};

LspRadioButton.propTypes = {
    isSelected: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    isActiveChangeAction: PropTypes.bool,
    cfgKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
};

LspRadioButton.defaultProps = {
    isSelected: false,
    title: "",
    description: "",
    isActiveChangeAction: false,
    cfgKey: "",
    disabled: false,
    loading: false,
};

export default memo(LspRadioButton);
