import { AppActionType } from "@config/constants";

/* ------------- Actions Definition ------------- */
const start = () => {
    return {
        type: AppActionType.Start,
    };
};

const stop = () => {
    return {
        type: AppActionType.Stop,
    };
};

const switchPaletteType = () => {
    return {
        type: AppActionType.SwitchPaletteType,
    };
};
const switchThemeName = (payload) => {
    return {
        type: AppActionType.SwitchThemeName,
        payload,
    };
};

const agreeTermCondition = (payload) => {
    return {
        type: AppActionType.AgreeTermCondition,
        payload,
    };
};

const appAction = {
    start,
    stop,
    switchPaletteType,
    switchThemeName,
    agreeTermCondition,
};

export default appAction;
