import LspButton from "@components/LspButton";
import { Grid, Icon, makeStyles } from "@material-ui/core";
import { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import { DELIVERY_ADDRESS } from "@containers/Booking/constant";
import useAddress from "@helpers/useAddress";
import LspRadioButton from "@components/LspRadioButton";

const useStyles = makeStyles((theme) => ({
    deliveryCard: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
    },
    deliveryCardHeader: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(23),
        lineHeight: "34px",
        letterSpacing: "0.3px",
        color: theme.palette.white.contrastText,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1.5),
    },
    deliveryCardBody: {
        marginBottom: theme.spacing(8.5),
    },
    deliveryCardBodyCarouselCard: {
        marginBottom: theme.spacing(3),
    },
    deliveryBox: {
        boxShadow:
            "0px 3px 4px rgba(0, 0, 0, 0.04), 0px 3px 1px rgba(0, 0, 0, 0.02), 0px 1px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
    },
    deliveryBoxItem: {
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
    deliveryBoxAction: {
        padding: theme.spacing(2),
    },
    deliveryBoxActionIcon: {
        width: 12,
        height: 12,
        marginRight: theme.spacing(),
        color: theme.palette.purple.main,
    },
    deliveryTitle: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: "22px",
        color: theme.palette.white.contrastText,
        marginBottom: theme.spacing(0.5),
    },
    deliveryTo: {
        marginBottom: theme.spacing(3),
    },
    deliveryPickup: {},
    deliveryLine: {
        borderBottom: `2px solid ${theme.palette.background.grey}`,
        lineHeight: "0.1em",
        textAlign: "center",
        margin: "10px 0 20px",
        marginBottom: theme.spacing(3),
    },
    deliveryLineText: {
        background: theme.palette.background.paper,
        padding: `0 ${theme.spacing(0.5)}px`,
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.magicalGrey[400],
    },
    disabled: {
        color: theme.palette.magicalGrey[300],
    },
}));

const DeliveryOptions = ({
    onDeliveryOptionsClose,
    onDeliveryOptionsSubmit,
    onHangOutSubmitChange,
    address,
    newAddress,
    onAddNewAddressOpen,
    hangout,
    onNewAddressChange,
    disableUserAddress,
    selectAddress,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { joinAddress } = useAddress();

    const [currentSelect, setCurrentSelect] = useState({
        currentAddress: true,
        permanentAddress: false,
        newAddress: false,
        hangOut: false,
    });

    const [currentAddress, setCurrentAddress] = useState({});
    const [permanentAddress, setPermanentAddress] = useState({});
    const [newAddressData, setNewAddressData] = useState({});
    const [hangOutPickUp, setHangOutPickUp] = useState({});

    useEffect(() => {
        if (selectAddress) {
            setCurrentSelect(() => ({
                currentAddress: false,
                permanentAddress: false,
                newAddress: false,
                hangOut: false,
                ...selectAddress,
            }));
        }
    }, [selectAddress]);

    useEffect(() => {
        if (address) {
            const mapAddress = joinAddress({
                address: address.addressName,
                ward: address.wardName,
                district: address.distName,
                province: address.cityName,
            });

            const mapPermanent = joinAddress({
                address: address.permanentAddress,
                ward: address.permanentWardName,
                district: address.permanentDistName,
                province: address.permanentCityName,
            });

            const current = {
                addressName: address.addressName,
                wardId: address.wardId,
                wardName: address.wardName,
                distId: address.distId,
                distName: address.distName,
                cityId: address.cityId,
                cityName: address.cityName,
                address: mapAddress,
            };
            const permanent = {
                addressName: address.permanentAddress,
                wardId: address.permanentWardId,
                wardName: address.permanentWardName,
                distId: address.permanentDistId,
                distName: address.permanentDistName,
                cityId: address.permanentCityId,
                cityName: address.permanentCityName,
                address: mapPermanent,
            };

            setPermanentAddress(permanent);
            setCurrentAddress(current);
        }
    }, [address, joinAddress]);

    useEffect(() => {
        if (
            disableUserAddress?.currentAddress &&
            disableUserAddress?.permanentAddress &&
            disableUserAddress?.newAddress
        ) {
            setCurrentSelect(() => ({
                currentAddress: false,
                permanentAddress: false,
                newAddress: false,
                hangOut: true,
            }));
        }
    }, [disableUserAddress]);

    useEffect(() => {
        if (newAddress != null) {
            const mapNewAddress = {
                ...newAddress,
                address: `${newAddress.addressName}, ${t("lb_ward")} ${
                    newAddress.wardName
                }, ${t("lb_district")} ${newAddress.distName}, ${
                    newAddress.cityName
                }`,
            };

            setNewAddressData(mapNewAddress);
        }
    }, [newAddress, t]);

    useEffect(() => {
        if (hangout) {
            setHangOutPickUp(hangout);
        }
    }, [hangout]);

    const handleAddressSelected = (key) => {
        switch (key) {
            case "currentAddress":
                setCurrentSelect(() => ({
                    currentAddress: true,
                    permanentAddress: false,
                    newAddress: false,
                    hangOut: false,
                }));
                break;
            case "permanentAddress":
                setCurrentSelect(() => ({
                    currentAddress: false,
                    permanentAddress: true,
                    newAddress: false,
                    hangOut: false,
                }));
                break;
            case "newAddress":
                setCurrentSelect(() => ({
                    currentAddress: false,
                    permanentAddress: false,
                    newAddress: true,
                    hangOut: false,
                }));
                break;
            case "hangOut":
                setCurrentSelect(() => ({
                    currentAddress: false,
                    permanentAddress: false,
                    newAddress: false,
                    hangOut: true,
                }));
                break;
            default:
                break;
        }
    };

    const handleAddNewAddress = () => {
        if (isFunction(onAddNewAddressOpen)) {
            onAddNewAddressOpen();
        }
    };

    const onHangOutSubmit = () => {
        if (isFunction(onHangOutSubmitChange)) {
            onHangOutSubmitChange();
        }
    };

    const handleNewAddressChange = () => {
        if (isFunction(onNewAddressChange)) {
            onNewAddressChange();
        }
    };

    const handleDeliverySubmit = () => {
        if (isFunction(onDeliveryOptionsSubmit)) {
            const currentSelectedDelivery = {};
            switch (true) {
                case currentSelect.currentAddress:
                    currentSelectedDelivery.delivery =
                        DELIVERY_ADDRESS.CURRENT_ADDRESS;
                    currentSelectedDelivery.data = currentAddress;
                    break;
                case currentSelect.newAddress:
                    currentSelectedDelivery.delivery =
                        DELIVERY_ADDRESS.OTHER_ADDRESS;
                    currentSelectedDelivery.data = newAddressData;

                    break;
                case currentSelect.permanentAddress:
                    currentSelectedDelivery.delivery =
                        DELIVERY_ADDRESS.PERMANENT_ADDRESS;
                    currentSelectedDelivery.data = permanentAddress;

                    break;
                case currentSelect.hangOut:
                    currentSelectedDelivery.delivery = DELIVERY_ADDRESS.HANGOUT;
                    currentSelectedDelivery.data = hangOutPickUp;
                    break;
                default:
                    break;
            }
            onDeliveryOptionsSubmit(currentSelectedDelivery);
        }
    };

    const handleDeliveryCancel = () => {
        if (isFunction(onDeliveryOptionsClose)) {
            onDeliveryOptionsClose();
        }
    };

    return (
        <>
            <div className={classes.deliveryCard}>
                <div className={classes.deliveryCardHeader}>
                    {t("debitCard:reissuecard_receive_card_method_header")}
                </div>
                <div className={classes.deliveryCardBody}>
                    <div className={classes.deliveryTo}>
                        <div className={classes.deliveryTitle}>
                            {t(
                                "debitCard:reissuecard_receive_card_method_delivery"
                            )}
                        </div>
                        <div className={classes.deliveryBox}>
                            <div className={classes.deliveryBoxItem}>
                                <LspRadioButton
                                    disabled={
                                        disableUserAddress?.currentAddress
                                    }
                                    title={t(
                                        "debitCard:reissuecard_receive_card_current_add"
                                    )}
                                    isSelected={currentSelect.currentAddress}
                                    description={currentAddress?.address}
                                    onItemSelected={() =>
                                        handleAddressSelected("currentAddress")
                                    }
                                />
                            </div>

                            {!disableUserAddress?.hidePermanentAddress && (
                                <div className={classes.deliveryBoxItem}>
                                    <LspRadioButton
                                        disabled={
                                            disableUserAddress?.permanentAddress
                                        }
                                        title={t(
                                            "debitCard:reissuecard_receive_card_permanent_add"
                                        )}
                                        isSelected={
                                            currentSelect.permanentAddress
                                        }
                                        description={permanentAddress?.address}
                                        onItemSelected={() =>
                                            handleAddressSelected(
                                                "permanentAddress"
                                            )
                                        }
                                    />
                                </div>
                            )}

                            {!isEmpty(newAddressData) && (
                                <div className={classes.deliveryBoxItem}>
                                    <LspRadioButton
                                        disabled={
                                            disableUserAddress?.newAddress
                                        }
                                        title={t(
                                            "debitCard:reissuecard_receive_card_CTA_new_add"
                                        )}
                                        isSelected={currentSelect.newAddress}
                                        description={newAddressData?.address}
                                        isActiveChangeAction
                                        onItemChangeAddress={
                                            handleNewAddressChange
                                        }
                                        onItemSelected={() =>
                                            handleAddressSelected("newAddress")
                                        }
                                    />{" "}
                                </div>
                            )}
                            {isEmpty(newAddressData) && (
                                <div className={classes.deliveryBoxAction}>
                                    <LspButton
                                        disabled={
                                            disableUserAddress?.newAddress
                                        }
                                        variant="tertiary"
                                        fullWidth
                                        onClick={handleAddNewAddress}
                                    >
                                        <Icon
                                            className={`font-icon icon-plus ${classes.deliveryBoxActionIcon}`}
                                        />
                                        {t(
                                            "debitCard:reissuecard_receive_card_CTA_new_add"
                                        )}
                                    </LspButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.deliveryLine}>
                        <span className={classes.deliveryLineText}>OR</span>
                    </div>
                    <div className={classes.deliveryPickup}>
                        <div className={classes.deliveryTitle}>
                            {t(
                                "debitCard:reissuecard_receive_card_method_pickup"
                            )}
                        </div>
                        <div className={classes.deliveryBox}>
                            <div className={classes.deliveryBoxItem}>
                                <LspRadioButton
                                    isSelected={currentSelect.hangOut}
                                    onItemSelected={() =>
                                        handleAddressSelected("hangOut")
                                    }
                                    title={hangOutPickUp?.title}
                                    description={hangOutPickUp?.description}
                                    isActiveChangeAction={
                                        !disableUserAddress?.hangOut
                                    }
                                    onItemChangeAddress={onHangOutSubmit}
                                    disabled={disableUserAddress?.hangOut}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.deliveryCardFooter}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="secondary"
                                onClick={handleDeliveryCancel}
                            >
                                {t("lb_back")}
                            </LspButton>
                        </Grid>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="primary"
                                onClick={handleDeliverySubmit}
                            >
                                {t("lb_next")}
                            </LspButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default memo(DeliveryOptions);
