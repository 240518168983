import Content from "@components/Content";
import { memo, useMemo } from "react";
import { Route, Switch } from "react-router-dom";

import {
    SpendAccountNavigationKey,
    BillNavigationKey,
} from "@config/constants";
import usePathname from "@helpers/usePathname";
import NewBill from "@containers/SpendAccount/BillPayment/NewBill";
import BillList from "./BillList";

const DefaultRoutes = memo(() => <BillList />);

const parentName = `spend-account/${SpendAccountNavigationKey.BillPayment}`;

const BillPayment = () => {
    const { generateChildPathname } = usePathname();

    const childRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    BillNavigationKey.BillList
                ),
                component: BillList,
            },
            {
                path: generateChildPathname(
                    parentName,
                    BillNavigationKey.NewBill
                ),
                component: NewBill,
            },
        ],
        [generateChildPathname]
    );

    return (
        <Content size="md">
            <Switch>
                {childRoutes.map((r) => (
                    <Route key={r.path} path={r.path} component={r.component} />
                ))}
                <Route component={DefaultRoutes} />
            </Switch>
        </Content>
    );
};
export default BillPayment;
