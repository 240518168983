import { call, put } from "redux-saga/effects";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import systemParamsService from "@services/system-params";
import systemParamsAction from "../actions/system-params";
// import authAction from "../actions/auth";

export function* getSystemParamsSaga() {
    const response = yield call(systemParamsService.getSystemParams);

    // if (response.ok) {
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(systemParamsAction.getSystemParamsSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(systemParamsAction.getSystemParamsError());
            // yield put(authAction.logoutSuccess());
            break;
    }
    // } else {
    //     yield put(systemParamsAction.getSystemParamsError());
    // }
}

export function* getPhoneConfigsSysParamSaga() {
    const response = yield call(systemParamsService.getPhoneConfigs);
    const { code, data } = response.data;

    // if (response.ok) {
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(systemParamsAction.getPhoneConfigsSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(systemParamsAction.getPhoneConfigsError(data));
            break;
    }
    // } else {
    //     yield put(systemParamsAction.getPhoneConfigsError(data));
    // }
}
