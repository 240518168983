import { memo, useEffect, useMemo, useRef, useState } from "react";
import useNumber from "@helpers/useNumber";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import LspAvatar from "@components/LspAvatar";
import paymentRequestService from "@services/payment-request";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import PaymentSend from "@containers/PaymentRequest/PaymentSend";
import PaymentReceive from "@containers/PaymentRequest/PaymentReceive";
import { useTranslation } from "react-i18next";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import Loading from "@components/Loading";
import { format, parse } from "date-fns";
import useDate from "@helpers/useDate";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";
    return {
        tdItem: {
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            "&.inactive": {
                backgroundColor: isDark
                    ? theme.palette.background.paper
                    : theme.palette.grey[200],
            },
            fontSize: theme.typography.pxToRem(16),
            "&:hover": {
                boxShadow: theme.shadows[2],
            },
            borderBottom: `1px solid ${theme.palette.divider}`,
            "&:first-child": {
                borderTop: `1px solid ${theme.palette.divider}`,
            },
        },
        centerTxt: {
            textAlign: "center",
        },
        subTxt: {
            textAlign: "center",
            fontSize: theme.typography.pxToRem(12),
        },
        clickableArea: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: theme.spacing(2.75, 2),
        },
        tdName: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        tdShortDesc: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.neutral.grey,
        },
        tdAmount: {
            fontSize: theme.typography.pxToRem(24),
            fontWeight: 400,
            color: theme.palette.orange.main,
            textAlign: "right",
            "&.inactive": {
                color: theme.palette.text.amount,
            },
        },
        badge: {
            padding: theme.spacing(0, 2, 0, 2),
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.neutral.white,
            textTransform: "uppercase",
            fontSize: theme.typography.pxToRem(12),
            marginLeft: theme.spacing(1),
        },
        green: {
            background: theme.palette.status.success,
        },
        gold: {
            background: theme.palette.gold.main,
        },
        blue: {
            background: theme.palette.blue.main,
        },
        orange: {
            background: theme.palette.gold.main,
        },
        red: {
            background: theme.palette.orange.main,
        },
    };
});

const PaymentItem = ({ item }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const isOpenStatus = useMemo(() => item.status === "OPEN", [item.status]);
    const [fetchingData, setFetchingData] = useState(false);
    const unmounted = useRef(false);
    const { isToday } = useDate();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const showPaymentDetail = async () => {
        setFetchingData(true);
        const idPay = item.isSent ? item.paymentId : item.payeePaymentId;
        const params = {
            id: idPay,
        };
        const { data } = await paymentRequestService.getPaymentDetail(
            item.isSent,
            params
        );

        if (unmounted.current) return;

        setFetchingData(false);

        if (item.isSent) {
            switch (data.code) {
                case RESPONSE_CODE.CREATED:
                    GlobalDialogController.show({
                        component: () => (
                            <PaymentSend
                                paymentSendInfo={data.data}
                                isSent={item.isSent}
                            />
                        ),
                    });
                    break;
                case RESPONSE_CODE.PAYMENT_UNAVAILABLE:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Warning",
                            header: t("cm_popup_payment_request_tittle"),
                            content: "cm_popup_payment_request_content",
                            button: t("lb_ok"),
                        },
                    });
                    break;

                default:
                    break;
            }
        } else {
            switch (data.code) {
                case RESPONSE_CODE.CREATED:
                    GlobalDialogController.show({
                        component: () => (
                            <PaymentReceive
                                paymentSendInfo={data.data}
                                isSent={item.isSent}
                            />
                        ),
                    });
                    break;
                case RESPONSE_CODE.PAYMENT_UNAVAILABLE:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Warning",
                            header: t("cm_popup_payment_request_tittle"),
                            content: "cm_popup_payment_request_content",
                            button: t("lb_ok"),
                        },
                    });
                    break;

                default:
                    break;
            }
        }
    };

    const showReceipt = () => {
        const txnId = !item.isSent ? item.transId : item.payeeList[0].transId;
        LspReceiptController.dialog(txnId);
    };

    const onClickHandler = () => {
        if (isOpenStatus) {
            showPaymentDetail();
        } else {
            showReceipt();
        }
    };

    const onDate = useMemo(() => {
        let date = "";
        if (item?.isSent && item?.payeeList[0]) {
            date = item?.payeeList[0].modifyDate;
        } else {
            date = item?.modifyDate;
        }

        if (date) {
            const dateFormatted = format(
                parse(date, "dd/MM/yyyy HH:mm:ss", new Date()),
                "dd/MM/yyyy"
            );

            if (isToday(dateFormatted, "dd/MM/yyyy")) {
                return t("gs_transaction_lb_today");
            }
            return `${t("lb_on")} ${dateFormatted}`;
        }
    }, [t, item, isToday]);

    return (
        <div className={classes.tdItem}>
            <div
                className={classes.clickableArea}
                onClick={onClickHandler}
                datatestid={item?.isSent ? "sent-record" : "received-record"}
            >
                <Box pr={2} position="relative">
                    <LspAvatar avatar={item.avatar} size={40} />
                </Box>
                <Box>
                    <div>
                        {`${t(item.titleContent)} `}
                        <span className={classes.tdName}>
                            {item.nameDisplayCustom}
                        </span>
                    </div>
                    <div className={classes.tdShortDesc}>
                        {onDate}

                        {isOpenStatus &&
                            item.labelStatusDisplay?.text &&
                            !item.isRead && (
                                <span
                                    className={clsx(
                                        classes.badge,
                                        classes[item.labelStatusDisplay.color]
                                    )}
                                >
                                    {`${t(item.labelStatusDisplay.text)}`}
                                </span>
                            )}
                    </div>
                </Box>
                <Box flex={1}>
                    <div
                        className={clsx(classes.tdAmount, {
                            inactive: item.isSent,
                        })}
                    >
                        {!isOpenStatus && (item.isSent ? "+" : "-")}
                        {formatNumber(item.amount)}
                    </div>
                </Box>
                <Box style={{ marginLeft: 8 }}>
                    {fetchingData && <Loading />}
                </Box>
            </div>
        </div>
    );
};

export default memo(PaymentItem);
