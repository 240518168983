import { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, IconButton, makeStyles, Paper } from "@material-ui/core";
import InfoTooltip from "@components/InfoTooltip";
import LspTypography from "@components/LspTypography";
import { VisibilityOffIcon, VisibilityOnIcon } from "@components/LspIcon";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => {
    return {
        itemName: {
            color: theme.palette.white.contrastText,
        },
        gold: {
            color: theme.palette.gold.main,
        },
        blue: {
            color: theme.palette.blue.main,
        },
        orange: {
            color: theme.palette.orange.main,
        },
        red: {
            color: theme.palette.orange.main,
        },
        primary: {
            color: theme.palette.primary.main,
        },
        accountSummaryWrapper: {
            padding: theme.spacing(2),
        },
        hint: {
            justifyContent: "center",
        },
        eyeIcon: {
            position: "absolute",
            right: theme.spacing(1.5),
            top: 0,
            "& svg": {
                width: 18,
                height: 18,
            },
        },
        subItem: {
            display: "flex",
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(1),
        },
    };
});

const defaultHiddenValue = "******";

const AccountSummaryBoxOverdraft = ({ items, hiddenBalanceConfig }) => {
    const classes = useStyles();

    const {
        enableHideBalance,
        balanceOption,
        toggleBalanceFunc,
    } = hiddenBalanceConfig;

    const displayValue = useCallback(
        (value) => {
            if (enableHideBalance && balanceOption) return defaultHiddenValue;

            return value;
        },
        [enableHideBalance, balanceOption]
    );

    const hiddenBalanceToggle = useCallback(() => {
        if (isFunction(toggleBalanceFunc)) {
            toggleBalanceFunc();
        }
    }, [toggleBalanceFunc]);

    const primaryItem = useMemo(() => items.filter((el) => el.primary)[0], [
        items,
    ]);
    const subItems = useMemo(() => items.filter((ele, i) => i > 0), [items]);

    return (
        <Paper component={Box} className={classes.accountSummaryWrapper}>
            <Box textAlign="center" position="relative">
                <LspTypography
                    className={classes.itemName}
                    component="div"
                    variant="body3"
                >
                    {primaryItem?.tooltipText && (
                        <InfoTooltip
                            color="black"
                            wrapperClassName={classes.hint}
                            icon="InfoIconV2"
                            title={primaryItem?.tooltipText}
                            placement="bottom"
                        >
                            {primaryItem?.name}
                        </InfoTooltip>
                    )}
                    {!primaryItem?.tooltipText && primaryItem?.name}
                    {enableHideBalance && (
                        <IconButton
                            size="small"
                            onClick={hiddenBalanceToggle}
                            className={classes.eyeIcon}
                        >
                            {balanceOption ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityOnIcon />
                            )}
                        </IconButton>
                    )}
                </LspTypography>
                <LspTypography
                    className={clsx(classes[primaryItem?.valueColor])}
                    datatestid={primaryItem?.id}
                    variant="title2"
                >
                    {displayValue(primaryItem?.value)}
                </LspTypography>
            </Box>

            {subItems?.map((item) => {
                return (
                    <Box className={classes.subItem} key={item?.id}>
                        <LspTypography variant="body3">
                            {`${item?.name} `}
                        </LspTypography>
                        &nbsp;
                        <LspTypography
                            className={clsx(classes[item?.valueColor])}
                            datatestid={item?.id}
                            variant="subheading4"
                            component="div"
                        >
                            {!item?.tooltipText && (
                                <>{displayValue(item?.value)}</>
                            )}
                            {item?.tooltipText && (
                                <InfoTooltip
                                    color="black"
                                    wrapperClassName={classes.hint}
                                    title={item?.tooltipText}
                                    placement="bottom"
                                    icon="InfoIconV2"
                                >
                                    {displayValue(item?.value)}
                                </InfoTooltip>
                            )}
                        </LspTypography>
                    </Box>
                );
            })}
        </Paper>
    );
};

AccountSummaryBoxOverdraft.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            tooltipText: PropTypes.string,
            valueColor: PropTypes.oneOf([
                "black",
                "blue",
                "orange",
                "gold",
                "primary",
            ]),
        })
    ),
    hiddenBalanceConfig: PropTypes.shape({
        enableHideBalance: false,
        balanceOption: false,
        toggleBalanceFunc: null,
    }),
};

AccountSummaryBoxOverdraft.defaultProps = {
    items: [],
    hiddenBalanceConfig: {},
};

export default memo(AccountSummaryBoxOverdraft);
