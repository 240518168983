import { useState, useEffect, useRef, useCallback } from "react";
import {
    makeStyles,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import sha512 from "crypto-js/sha512";
import { useHistory } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import userService from "@services/user";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import authAction from "@redux/actions/auth";
import useOTP from "@helpers/useOTP";
import moveMoneyService from "@services/move-money";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import LspButton from "./LspButton";
import LspTextField from "./LspTextField";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: 450,
        margin: "auto",
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[3],
    },
    header: {
        textAlign: "center",
        padding: theme.spacing(3, 2, 2, 2),
    },
    body: {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: theme.spacing(3),
    },
    footer: {
        padding: theme.spacing(0, 3, 3, 3),
    },
}));

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAPK);

const UpdatePasswordDialog = ({ logout, user }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const [isPasswordVisible, setIsPasswordVisible] = useState({
        confirmNewPassword: false,
        newPassword: false,
        currentPassword: false,
    });

    const handlePasswordVisibility = useCallback((data) => {
        setIsPasswordVisible((prev) => ({
            ...prev,
            [data]: !prev[data],
        }));
    }, []);

    const { register, handleSubmit, errors, getValues, setError } = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: null,
            confirmNewPassword: null,
        },
    });
    const [fetching, setFetching] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const commitOtp = async (dataToPost) => {
        setLoading(true);

        const response = await userService.commitOtpUpdatePassword(dataToPost);

        if (unmounted.current) {
            return;
        }
        setLoading(false);
        const { code } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            closeOTPDialog();
            window.location.href = "/home";
        } else {
            GlobalDialogController.showError({
                errorCode: code,
                onClose: () => {
                    window.location.reload();
                },
            });
        }
    };

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const updatePasswordHandler = (response) => {
        setFetching(false);
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                // go dash board
                // history.push("/home");
                window.location.href = "/home";
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.refNo,
                    dialogTitle: t("otp_popup_title"),
                    dialogDescription: ``,
                    dialogSubtitle: t("otp_popup_sms_description"),
                    dialogHideResendButton: true,
                    submitFunc: commitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.INCORRECT_VALUE:
                setError("currentPassword", {
                    type: "manual",
                    message: t("quickcode_msg_incorrect_password"),
                });
                break;
            case RESPONSE_CODE.MISMATCH_VALUE:
                AlertController.show(
                    t("user_profile_new_password_un_match"),
                    AlertType.Error
                );
                break;
            case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
                // force logout
                logout();
                break;
            case RESPONSE_CODE.PASSWORD_POLICIES_VIOLATED:
                AlertController.show(t("password_rule_msg"), AlertType.Error);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const updatePassword = async (data) => {
        const { currentPassword, newPassword, confirmNewPassword } = data;
        if (
            fetching ||
            !currentPassword ||
            !newPassword ||
            !confirmNewPassword
        ) {
            return;
        }
        setFetching(true);

        const encryptedCurrentPassword = sha512(currentPassword).toString();
        const encryptedNewPassword = crypt.encrypt(newPassword);
        // const encryptedConfirmNewPassword = crypt.encrypt(confirmNewPassword);

        const response = await userService.updatePassword({
            currentPassword: encryptedCurrentPassword,
            newPassword: encryptedNewPassword,
            confirmNewPassword: encryptedNewPassword,
        });

        if (unmounted.current) {
            return;
        }

        updatePasswordHandler(response);
    };

    const passwordValidation = (isCallUpdatePassword) => {
        const currentPassword = getValues("currentPassword");
        const newPassword = getValues("newPassword");
        const confirmNewPassword = getValues("confirmNewPassword");

        if (currentPassword === newPassword) {
            return t("join_msg_new_password_same_old_password");
        }
        if (!!confirmNewPassword && newPassword !== confirmNewPassword) {
            return t("user_profile_new_password_un_match");
        }
        if (isCallUpdatePassword) {
            updatePassword({
                currentPassword,
                newPassword,
                confirmNewPassword,
            });
        }
    };

    return (
        <div className={classes?.wrapper}>
            <form
                className={classes.updatePasswordForm}
                onSubmit={handleSubmit(() => passwordValidation(true))}
            >
                <DialogTitle classes={{ root: classes?.header }}>
                    <LspTypography variant="title2">
                        {t("lb_expired_password_title")}
                    </LspTypography>
                </DialogTitle>
                <DialogContent classes={{ root: classes?.body }}>
                    <Box mb={2}>
                        <LspTextField
                            type={
                                isPasswordVisible.currentPassword
                                    ? "text"
                                    : "password"
                            }
                            label={t("user_profile_current_password_lbl")}
                            error={!!errors.currentPassword}
                            name="currentPassword"
                            helperText={errors.currentPassword?.message || " "}
                            inputProps={{
                                ref: register({
                                    required: t("msg_we_need_this"),
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD,
                                        message: t("join_msg_password_invalid"),
                                    },
                                }),
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handlePasswordVisibility(
                                                    "currentPassword"
                                                )
                                            }
                                        >
                                            {isPasswordVisible?.currentPassword ? (
                                                <VisibilityOffOutlined />
                                            ) : (
                                                <VisibilityOutlined />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <LspTypography variant="body3" color="grey">
                            {t("dashboard:update_pw_hint_change_web")}
                        </LspTypography>
                    </Box>
                    <LspTextField
                        type={
                            isPasswordVisible.newPassword ? "text" : "password"
                        }
                        label={t("user_profile_new_password_lbl")}
                        error={!!errors.newPassword}
                        name="newPassword"
                        helperText={errors.newPassword?.message || " "}
                        inputProps={{
                            ref: register({
                                required: t("msg_we_need_this"),
                                pattern: {
                                    value: REGEX_PATTERN.PASSWORD,
                                    message: t("join_msg_password_invalid"),
                                },
                                validate: () => passwordValidation(),
                            }),
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handlePasswordVisibility(
                                                "newPassword"
                                            )
                                        }
                                    >
                                        {isPasswordVisible?.newPassword ? (
                                            <VisibilityOffOutlined />
                                        ) : (
                                            <VisibilityOutlined />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <LspTextField
                        type={
                            isPasswordVisible.confirmNewPassword
                                ? "text"
                                : "password"
                        }
                        label={t("user_profile_reenter_password_lbl")}
                        error={!!errors.confirmNewPassword}
                        name="confirmNewPassword"
                        helperText={errors.confirmNewPassword?.message || " "}
                        inputProps={{
                            ref: register({
                                required: t("msg_we_need_this"),
                                pattern: {
                                    value: REGEX_PATTERN.PASSWORD,
                                    message: t("join_msg_password_invalid"),
                                },
                                validate: () => passwordValidation(),
                            }),
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            handlePasswordVisibility(
                                                "confirmNewPassword"
                                            )
                                        }
                                    >
                                        {isPasswordVisible?.confirmNewPassword ? (
                                            <VisibilityOffOutlined />
                                        ) : (
                                            <VisibilityOutlined />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes?.footer }}>
                    <LspButton type="submit" fullWidth progressing={fetching}>
                        {t("lb_expired_password_btn")}
                    </LspButton>
                </DialogActions>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.info,
});
const mapDispatch = (dispatch) => ({
    logout: () => dispatch(authAction.logoutRequest()),
});

export default connect(mapStateToProps, mapDispatch)(UpdatePasswordDialog);
