import CardActivation from "@containers/CardManagement/CardActivation";
import { CARD_TYPES } from "@containers/CardManagement/constant";

const PendingTaskCardActivation = ({ onDone, onClose }) => {
    return (
        <CardActivation
            cardType={CARD_TYPES.DEBIT_CARD}
            onDone={onDone}
            onClose={onClose}
            type="dialog"
        />
    );
};

export default PendingTaskCardActivation;
