import { Skeleton } from "@material-ui/lab";

const ReviewPayeeLoading = ({ className }) => {
    return (
        <div>
            <div>
                <Skeleton
                    className={className.avatar}
                    variant="circle"
                    width={60}
                    height={60}
                />
            </div>
            <div className={className.name}>
                <Skeleton width={250} />
            </div>
            <div className={className.box}>
                <div className={className.icon}>
                    <Skeleton variant="circle" width={40} height={40} />
                </div>
                <div className={className.info}>
                    <Skeleton width={470} />
                    <Skeleton width={470} />
                    <Skeleton width={470} />
                </div>
            </div>
        </div>
    );
};

export default ReviewPayeeLoading;
