import api from "@config/api";

const getTermDeposit = async ({ tdId }) => {
    return await api.get(`/user/term-deposit/v2/tdDetail/${tdId}`);
};

const setAutoRolloverTermDeposit = async (data) => {
    return await api.post("/user/term-deposit/v2/convert", data);
};

const getTermDepositRates = async () => {
    return await api.get("/user/term-deposit/v2/interestRate");
};

const getEarlyRedemptionData = async (data) => {
    return await api.post("/user/term-deposit/v2/calculate-redeem", data);
};

const redeemTermDeposit = async (data) => {
    return await api.post("/user/term-deposit/v2/redeem", data);
};

const getOpeningSuggestion = async (data) => {
    return await api.post("/user/term-deposit/v2/suggest", data);
};

const getRedemptionPrediction = async ({ amount, termId }) => {
    return await api.post("/user/term-deposit/v2/predict", { amount, termId });
};

const openTermDeposit = async (data) => {
    return await api.post("user/term-deposit/v2/open", data);
};

const openTermDepositCheck = async (data) => {
    return await api.post("user/term-deposit/v2/open/check", data);
};

const validationSpecialRate = async (payload) => {
    return await api.post(`/user/term-deposit/v2/validateSpecialRate`, payload);
};

const previewSpecialRate = async (payload) => {
    return await api.post(
        `/user/term-deposit/v2/calculatePartialWithDrawTd`,
        payload
    );
};

const partialWithDrawTd = async (payload) => {
    return await api.post("/user/term-deposit/v2/partialWithDrawTd", payload);
};

const checkAutoDeposit = async (data) => {
    return await api.get("user/term-deposit/recurring/v1/check", data);
};

const openAutoDeposit = async (data) => {
    return await api.post("user/term-deposit/recurring/v1/create", data);
};
const commitOpenAutoDeposit = async (data) => {
    return await api.post(
        "user/term-deposit/recurring/v1/commitCreateOtp",
        data
    );
};

const getAutoDeposit = async () => {
    return await api.get("user/term-deposit/recurring/v1/list");
};

const deleteAuToDeposit = async (data) => {
    return await api.post("user/term-deposit/recurring/v1/delete", data);
};

const commitDeleteAuToDeposit = async (data) => {
    return await api.post(
        "/user/term-deposit/recurring/v1/commitDeleteOtp",
        data
    );
};

const termDepositService = {
    getTermDeposit,
    setAutoRolloverTermDeposit,
    getTermDepositRates,
    getEarlyRedemptionData,
    redeemTermDeposit,
    getOpeningSuggestion,
    getRedemptionPrediction,
    openTermDeposit,
    validationSpecialRate,
    previewSpecialRate,
    partialWithDrawTd,
    checkAutoDeposit,
    openAutoDeposit,
    commitOpenAutoDeposit,
    getAutoDeposit,
    deleteAuToDeposit,
    commitDeleteAuToDeposit,
    openTermDepositCheck,
};

export default termDepositService;
