import LspButtonIconLink from "@components/LspButtonIconLink";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { Box, Icon, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(2),
    },
    actionButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > span": {
            fontSize: 14,
        },
    },
    line: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
        "& > p": {
            width: "50%",
        },
        "& > p:first-child": {
            marginRight: theme.spacing(1.5),
            width: "50%",
            textAlign: "right",
        },

        "&:last-child": {
            borderBottom: "none",
        },
    },
    note: {
        textAlign: "center",
        marginTop: theme.spacing(1),
        fontStyle: "italic",
    },
}));

const GoalSaveDetailBody = ({ detail, toggleEdit }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <Box className={classes.body}>
            <Box display="flex" justifyContent="flex-end">
                <LspButtonIconLink
                    startIcon={<Icon className="font-icon icon-edit" />}
                    onClick={toggleEdit}
                    buttonProps={{
                        datatestid: "editGoalBtn",
                    }}
                >
                    {t("move_money_biller_edit")}
                </LspButtonIconLink>

                {/* <ButtonBase className={classes.actionButton}>
                    <Icon className="font-icon icon-edit" />
                    <LspTypography variant="body3" color="grey">
                        {t("move_money_biller_edit")}
                    </LspTypography>
                </ButtonBase> */}
            </Box>
            <Box className={classes.line}>
                <LspTypography variant="heading3">
                    {`${t("goalSave_lb_goal_name")}: `}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {detail?.planName}
                </LspTypography>
            </Box>
            <Box className={classes.line}>
                <LspTypography variant="heading3">
                    {`${t("goalSave_lb_goal_created_date")}: `}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {detail?.createAt}
                </LspTypography>
            </Box>
            <Box className={classes.line}>
                <LspTypography variant="heading3">
                    {`${t("goalSave_lb_target_date")}: `}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {detail?.targetDate}
                </LspTypography>
            </Box>
            <Box className={classes.line}>
                <LspTypography variant="heading3">
                    {`${t("goalSave_lb_target_amount")}: `}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {formatNumber(detail?.targetAmount)}
                </LspTypography>
            </Box>
            {detail?.recurringAmount && (
                <Box className={classes.line}>
                    <LspTypography variant="heading3">
                        {`${t("gs_label_recurring_amount")}: `}
                    </LspTypography>
                    <LspTypography variant="body2" color="grey">
                        {formatNumber(detail?.recurringAmount)}
                    </LspTypography>
                </Box>
            )}

            {detail?.FERecurringType && (
                <Box className={classes.line}>
                    <LspTypography variant="heading3">
                        {`${t("gs_label_frequency")}: `}
                    </LspTypography>
                    <LspTypography variant="body2" color="grey">
                        {detail?.FERecurringType}
                    </LspTypography>
                </Box>
            )}
            {detail?.nextDateContribute && (
                <Box className={classes.note}>
                    <LspTypography variant="body2" color="grey">
                        {`${t("gs_label_next_contribute")} ${
                            detail?.nextDateContribute
                        }`}
                    </LspTypography>
                </Box>
            )}
        </Box>
    );
};

export default memo(GoalSaveDetailBody);
