import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";

import LspCongratulation from "@components/LspCongratulation";
import {
    WEALTH_CREDIT_CARD_STEPS,
    WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import LspTypography from "@components/LspTypography";
import Interweave from "interweave";
import useSharedClasses from "@containers/Wealth/wealthSharedClasses";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 176,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    contentLine: {
        // marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    hangoutBooking: {
        textAlign: "center",
        fontSize: 14,
    },
    label: {
        marginTop: theme.spacing(1),
    },
}));

const SCREENS = {
    FOREIGNER: "FOREIGNER",
    NORMAL: "NORMAL",
    NO_BOOKING: "NO_BOOKING",
    DELIVERY: "DELIVERY",
};

const WealthCreditCardApplySuccessfully = ({
    onNext,
    bookingInfo,
    deliveryInfo,
    isForeigner,
    fetching,
    applyMethod,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const sharedClasses = useSharedClasses();

    // case apply credit card v2: only pickup card at hangout
    // case apply credit card v3:
    // + pick up card at hangout & delivery to home
    // + if user change personal info > must come hangout to pickup card
    const screen = useMemo(() => {
        if (!bookingInfo) {
            return SCREENS.NO_BOOKING;
        }

        if (deliveryInfo?.address && !deliveryInfo?.isChangeInfo) {
            return SCREENS.DELIVERY;
        }

        if (isForeigner) {
            return SCREENS.FOREIGNER;
        }

        return SCREENS.NORMAL;
    }, [isForeigner, bookingInfo, deliveryInfo]);

    const normalContent = useMemo(() => {
        switch (applyMethod) {
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED:
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED_v3:
                return t("wealth:mc_v3_confirm_application_form");
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD:
                return `
                    ${t("wealth:mc_v3_complete_application_form")}\n\n${t(
                    "wealth:mc_v3_successful_msg2"
                ).replace("%@", t("wealth:mc_v3_timocare_no"))}
                `;
            case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD:
                return `
                    ${t("wealth:mc_v3_complete_application_form")}\n\n${t(
                    "wealth:mc_v3_successful_msg3"
                ).replace("%@", t("wealth:mc_v3_timocare_no"))}
                `;
            default:
                return t("wealth:mc_v3_successful_msg1").replace(
                    "%@",
                    t("wealth:mc_v3_timocare_no")
                );
        }
    }, [applyMethod, t]);

    const deliveryContent = useMemo(() => {
        if (
            applyMethod === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED ||
            applyMethod === WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.PRE_QUALIFIED_V3
        ) {
            return {
                title: t("wealth:mc_v3_submit_application"),
                content: `${t("wealth:mc_v3_submit_application_detail1")}\n
                ${t("wealth:mc_v3_submit_application_detail2")}\n\n`,
            };
        }
        return {
            title: t("wealth:mc_v3_successful_title"),
            content: t("wealth:mc_v3_failure_booking_msg").replace(
                "%@",
                t("wealth:mc_v3_timocare_no")
            ),
        };
    }, [applyMethod, t]);

    return (
        <>
            {!fetching && (
                <>
                    {screen === SCREENS.FOREIGNER && (
                        <LspCongratulation
                            title={t("wealth:mc_v3_successful_title")}
                            contents={t(
                                "wealth:mc_v3_successful_booking_details"
                            )}
                            contentWithAction={{
                                label: t("wealth:mc_v3_link_about_mc"),
                                onClick: () => {
                                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                                },
                                className: [sharedClasses.aboutButton],
                            }}
                            component
                        >
                            <div className={classes.hangoutBooking}>
                                <LspTypography
                                    className={classes.label}
                                    color="grey"
                                    variant="body3"
                                >
                                    {t(
                                        "wealth:mc_v3_successful_booking_location"
                                    )}
                                </LspTypography>
                                <LspTypography variant="heading3">
                                    {bookingInfo?.hangoutLabel}
                                </LspTypography>

                                <LspTypography variant="body2">
                                    {bookingInfo?.hangoutAddress}
                                </LspTypography>
                                <LspTypography
                                    className={classes.label}
                                    color="grey"
                                    variant="body3"
                                >
                                    {t("wealth:mc_v3_cussessful_booking_time")}
                                </LspTypography>
                                <LspTypography variant="heading3">
                                    {bookingInfo?.bookingDate}
                                </LspTypography>
                            </div>

                            <div className={classes.contentLine}>
                                <Interweave
                                    content={`
                       ${t("wealth:mc_v3_complete_application_form")} \n\n ${t(
                                        "wealth:mc_v3_successful_foreigner"
                                    )}`}
                                />
                            </div>
                        </LspCongratulation>
                    )}
                    {screen === SCREENS.NORMAL && (
                        <LspCongratulation
                            title={t("wealth:mc_v3_successful_title")}
                            contents={t(
                                "wealth:mc_v3_successful_booking_details"
                            )}
                            contentWithAction={{
                                label: t("wealth:mc_v3_link_about_mc"),
                                onClick: () => {
                                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                                },
                                className: [sharedClasses.aboutButton],
                            }}
                            component
                        >
                            <div className={classes.hangoutBooking}>
                                <LspTypography
                                    className={classes.label}
                                    color="grey"
                                    variant="body3"
                                >
                                    {t(
                                        "wealth:mc_v3_successful_booking_location"
                                    )}
                                </LspTypography>
                                <LspTypography variant="heading3">
                                    {bookingInfo?.hangoutLabel}
                                </LspTypography>

                                <LspTypography variant="body2">
                                    {bookingInfo?.hangoutAddress}
                                </LspTypography>
                                <LspTypography
                                    className={classes.label}
                                    color="grey"
                                    variant="body3"
                                >
                                    {t("wealth:mc_v3_cussessful_booking_time")}
                                </LspTypography>
                                <LspTypography variant="heading3">
                                    {bookingInfo?.bookingDate}
                                </LspTypography>
                            </div>

                            <div
                                className={classes.contentLine}
                                style={{ marginTop: 16 }}
                            >
                                <Interweave content={normalContent} />
                            </div>
                        </LspCongratulation>
                    )}
                    {screen === SCREENS.NO_BOOKING && (
                        <LspCongratulation
                            title={t("wealth:mc_v3_successful_title")}
                            contents={t(
                                "wealth:mc_v3_failure_booking_msg"
                            ).replace("%@", t("wealth:mc_v3_timocare_no"))}
                            contentWithAction={{
                                label: t("wealth:mc_v3_link_about_mc"),
                                onClick: () => {
                                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                                },
                                className: [sharedClasses.aboutButton],
                            }}
                            component
                        />
                    )}
                    {screen === SCREENS.DELIVERY && (
                        <LspCongratulation
                            title={deliveryContent?.title}
                            contentWithAction={{
                                label: t("wealth:mc_v3_link_about_mc"),
                                onClick: () => {
                                    onNext(WEALTH_CREDIT_CARD_STEPS.INTRO);
                                },
                                className: [sharedClasses.aboutButton],
                            }}
                            component
                        >
                            <div className={classes.contentLine}>
                                <Interweave
                                    content={deliveryContent?.content}
                                />
                            </div>
                            <div className={classes.hangoutBooking}>
                                <LspTypography
                                    className={classes.label}
                                    color="grey"
                                    variant="body3"
                                >
                                    {t(
                                        "wealth:mc_v3_successful_booking_location"
                                    )}
                                </LspTypography>
                                <LspTypography variant="heading3">
                                    {deliveryInfo?.address}
                                </LspTypography>
                            </div>
                            <div
                                className={classes.contentLine}
                                style={{ marginTop: 16 }}
                            >
                                {t("wealth:mc_v3_submit_application_detail3")}
                            </div>
                        </LspCongratulation>
                    )}
                </>
            )}
        </>
    );
};

const mapState = (state) => ({
    isForeigner: state.user.info?.isForeigner,
});

export default memo(connect(mapState)(WealthCreditCardApplySuccessfully));
