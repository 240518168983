/* ------------- Types Definition ------------- */
export const AuthActionType = Object.freeze({
    SetIsAuthenticated: "AuthActionType/SetIsAuthenticated",
    LoginRequest: "AuthActionType/LoginRequest",
    LoginSuccess: "AuthActionType/LoginSuccess",
    LoginError: "AuthActionType/LoginError",
    LoginWithOTP: "AuthActionType/LoginWithOTP",
    LoginWithOTPError: "AuthActionType/LoginWithOTPError",
    LoginWithTwoFactor: "AuthActionType/LoginWithTwoFactor",
    LoginWithTwoFactorError: "AuthActionType/LoginWithTwoFactorError",
    LogoutRequest: "AuthActionType/LogoutRequest",
    LogoutSuccess: "AuthActionType/LogoutSuccess",
    LogoutError: "AuthActionType/LogoutError",
    ForceLogout: "AuthActionType/ForceLogout",
    SubmitDeviceAuthenticationOTPRequest:
        "AuthActionType/SubmitDeviceAuthenticationOTPRequest",
    SubmitDeviceAuthenticationOTPSuccess:
        "AuthActionType/SubmitDeviceAuthenticationOTPSuccess",
    SubmitDeviceAuthenticationOTPError:
        "AuthActionType/SubmitDeviceAuthenticationOTPError",
    SubmitTwoFactorOTPRequest: "AuthActionType/SubmitTwoFactorOTPRequest",
    SubmitTwoFactorOTPSuccess: "AuthActionType/SubmitTwoFactorOTPSuccess",
    SubmitTwoFactorOTPError: "AuthActionType/SubmitTwoFactorOTPError",
    Reset: "AuthActionType/Reset",
    SubmitGGTwoFactorOTPRequest: "AuthActionType/SubmitGGTwoFactorOTPRequest",
    SubmitGGTwoFactorOTPSuccess: "AuthActionType/SubmitGGTwoFactorOTPSuccess",
    SubmitGGTwoFactorOTPError: "AuthActionType/SubmitGGTwoFactorOTPError",

    ClearLoginTextField: "AuthActionType/ClearLoginTextField",
});

/* ------------- Actions Definition ------------- */
const setIsAuthenticated = (payload) => {
    return {
        type: AuthActionType.SetIsAuthenticated,
        payload,
    };
};

const loginRequest = (payload) => {
    return {
        type: AuthActionType.LoginRequest,
        payload,
    };
};
const loginSuccess = (payload) => {
    return {
        type: AuthActionType.LoginSuccess,
        payload,
    };
};
const loginError = () => {
    return {
        type: AuthActionType.LoginError,
    };
};
const loginWithOTP = (payload) => {
    return {
        type: AuthActionType.LoginWithOTP,
        payload,
    };
};
const loginWithOTPError = (payload) => {
    return {
        type: AuthActionType.LoginWithOTPError,
        payload,
    };
};
const loginWithTwoFactor = (payload) => {
    return {
        type: AuthActionType.LoginWithTwoFactor,
        payload,
    };
};
const loginWithTwoFactorError = (payload) => {
    return {
        type: AuthActionType.LoginWithTwoFactorError,
        payload,
    };
};

const logoutRequest = (payload) => {
    return {
        type: AuthActionType.LogoutRequest,
        payload,
    };
};
const logoutSuccess = () => {
    return {
        type: AuthActionType.LogoutSuccess,
    };
};
const logoutError = () => {
    return {
        type: AuthActionType.LogoutError,
    };
};
const forceLogout = () => {
    return {
        type: AuthActionType.ForceLogout,
    };
};

const submitDeviceAuthenticationOTPRequest = (payload) => {
    return {
        type: AuthActionType.SubmitDeviceAuthenticationOTPRequest,
        payload,
    };
};

const submitDeviceAuthenticationOTPSuccess = (payload) => {
    return {
        type: AuthActionType.SubmitDeviceAuthenticationOTPSuccess,
        payload,
    };
};

const submitDeviceAuthenticationOTPError = () => {
    return {
        type: AuthActionType.SubmitDeviceAuthenticationOTPError,
    };
};

const submitTwoFactorOTPRequest = (payload) => {
    return {
        type: AuthActionType.SubmitTwoFactorOTPRequest,
        payload,
    };
};

const submitTwoFactorOTPSuccess = (payload) => {
    return {
        type: AuthActionType.SubmitTwoFactorOTPSuccess,
        payload,
    };
};

const submitTwoFactorOTPError = () => {
    return {
        type: AuthActionType.SubmitTwoFactorOTPError,
    };
};

const reset = () => {
    return {
        type: AuthActionType.Reset,
    };
};

const submitGGTwoFactorOTPRequest = (payload) => {
    return {
        type: AuthActionType.SubmitGGTwoFactorOTPRequest,
        payload,
    };
};

const submitGGTwoFactorOTPSuccess = (payload) => {
    return {
        type: AuthActionType.SubmitGGTwoFactorOTPSuccess,
        payload,
    };
};

const submitGGTwoFactorOTPError = () => {
    return {
        type: AuthActionType.SubmitGGTwoFactorOTPError,
    };
};
const updateClearLoginTextfield = (payload) => {
    return {
        type: AuthActionType.ClearLoginTextField,
        payload,
    };
};

const authAction = {
    setIsAuthenticated,
    loginRequest,
    loginSuccess,
    loginError,
    loginWithOTP,
    loginWithOTPError,
    loginWithTwoFactor,
    loginWithTwoFactorError,
    logoutRequest,
    logoutSuccess,
    logoutError,
    forceLogout,
    submitDeviceAuthenticationOTPRequest,
    submitDeviceAuthenticationOTPSuccess,
    submitDeviceAuthenticationOTPError,
    submitTwoFactorOTPRequest,
    submitTwoFactorOTPSuccess,
    submitTwoFactorOTPError,
    reset,
    submitGGTwoFactorOTPRequest,
    submitGGTwoFactorOTPSuccess,
    submitGGTwoFactorOTPError,
    updateClearLoginTextfield,
};

export default authAction;
