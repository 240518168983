/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LspCardList from "@components/LspCardList";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import PageHeaderBack from "@components/PageHeaderBack";

const INSURANCE_AREA_KEY = {
    ASEAN: "asean",
    ASIA: "asia",
    WORLD: "world",
};

const WealthTravelAreaSelection = ({ setDestination, onNext }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const areaList = useMemo(() => {
        const contentAsean = t("wealth:lbi_travel_dest_opt", {
            returnObjects: true,
        })[0];
        const contentAsia = t("wealth:lbi_travel_dest_opt", {
            returnObjects: true,
        })[1];
        const contentWorld = t("wealth:lbi_travel_dest_opt", {
            returnObjects: true,
        })[2];
        return [
            {
                id: INSURANCE_AREA_KEY.ASEAN,
                title: contentAsean[1],
                description: contentAsean[2],
            },
            {
                id: INSURANCE_AREA_KEY.ASIA,
                title: contentAsia[1],
                description: contentAsia[2],
            },
            {
                id: INSURANCE_AREA_KEY.WORLD,
                title: contentWorld[1],
                description: contentWorld[2],
            },
        ];
    }, [t]);

    const onBackHandler = () => {
        history.goBack();
    };

    const onClickItemHandler = (item) => {
        setDestination(item);
        onNext();
    };

    return (
        <>
            <PageHeaderBack onBack={onBackHandler}>
                <Icon className="font-icon icon-travelinsurance" />
                <LspTypography>
                    {t("wealth:lbi_travel_destination_title")}
                </LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <LspCardList
                    list={areaList}
                    onClickItem={onClickItemHandler}
                    variant="primary"
                />
            </Content>
        </>
    );
};

const mapDispatch = (dispatch) => ({
    setDestination: (payload) =>
        dispatch(wealthInsuranceAction.setDestination(payload)),
});

export default memo(connect(null, mapDispatch)(WealthTravelAreaSelection));
