import LspTypography from "@components/LspTypography";
import { Box, Grow, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: "uppercase",
        fontSize: theme.typography.pxToRem(30),
        fontWeight: "100",
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.pxToRem(16),
        },
    },

    uppercase: {
        textTransform: "uppercase",
    },

    whyTimo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    logo: {
        width: "auto",
        height: "482px",
    },
    whyTimoTxt: {
        maxWidth: "470px",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },

    whyTimoTxt2: {
        maxWidth: "470px",
        textAlign: "left",
        "& > img": {
            width: "100%",
            margin: theme.spacing(2, 0),
        },
        "& > p:first-child": {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },

    slideBtn: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(3),
        "& > div": {
            width: "50px",
            height: "5px",
            background: "#563D82",
            opacity: 0.5,
            borderRadius: "8px",
            margin: "6px",
            cursor: "pointer",
            "&.active": {
                opacity: 1,
            },
        },
    },
}));

const InstallmentIntroduce = () => {
    const { t } = useTranslation("translation", "creditCard");
    const classes = useStyles();
    const [view, setView] = useState("1");

    return (
        <>
            <Box textAlign="center" mb={4} mt={2}>
                <LspTypography
                    variant="title2"
                    color="primary"
                    className={classes.uppercase}
                >
                    {t("creditCard:cc_installment_header")}
                </LspTypography>
            </Box>

            {view === "1" && (
                <Grow in>
                    <div className={classes.whyTimo}>
                        <div>
                            <img
                                src={t("creditCard:img_cc_highlight")}
                                alt=""
                                className={classes.logo}
                            />
                        </div>

                        <div className={classes.whyTimoTxt}>
                            <LspTypography
                                variant="body2"
                                color="primary"
                                className={classes.title}
                            >
                                {t("creditCard:IN00002")}
                            </LspTypography>

                            <LspTypography variant="body2" color="black">
                                {t("creditCard:IN00003")}
                            </LspTypography>
                        </div>
                    </div>
                </Grow>
            )}

            {view === "2" && (
                <Grow in>
                    <div className={classes.whyTimo}>
                        <div>
                            <img
                                src={t("creditCard:img_cc_highlight")}
                                alt=""
                                className={classes.logo}
                            />
                        </div>

                        <div className={classes.whyTimoTxt2}>
                            <LspTypography
                                variant="title2"
                                color="primary"
                                className={classes.title}
                            >
                                {t("creditCard:IN00004")}
                            </LspTypography>

                            <LspTypography variant="body2" color="black">
                                {
                                    t("creditCard:IN00005", {
                                        returnObjects: true,
                                    })[0]
                                }
                            </LspTypography>
                            <img src={t("creditCard:img_ins_txn")} alt="" />
                            <LspTypography variant="body2" color="black">
                                {t("creditCard:IN00006")}
                            </LspTypography>
                            <img src={t("creditCard:img_ins_term")} alt="" />
                        </div>
                    </div>
                </Grow>
            )}

            <div className={classes.slideBtn}>
                <div
                    className={view === "1" ? "active" : ""}
                    onClick={() => setView("1")}
                />
                <div
                    className={view === "2" ? "active" : ""}
                    onClick={() => setView("2")}
                />
            </div>
        </>
    );
};

export default InstallmentIntroduce;
