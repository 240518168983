import { Divider, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import { useCallback, useState, useEffect, useRef } from "react";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import useAccounts from "@helpers/useAccounts";
import { HintIcon } from "@components/LspIcon";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import accountService from "@services/account";
import moveMoneyService from "@services/move-money";
import useOTP from "@helpers/useOTP";
import InstallmentDetailLoading from "./InstallmentDetailLoading";

const useStyles = makeStyles((theme) => ({
    hint: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        color: theme.palette.primary.main,
        "& p": {
            marginLeft: theme.spacing(1),
        },
    },
}));

const InstallmentEarlyInfo = ({
    className,
    title,
    txnId,
    stepForward,
    stepBack,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { spendAccount, creditCard } = useAccounts();
    const [detail, setDetail] = useState({});
    const [infoLoading, setInfoLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const unmounted = useRef(false);
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const getInfoBeforeCloseEarly = useCallback(
        async (id) => {
            const content = t("installment_early_warning_non_working_hours")
                .replace("%@", t("sysParamContent:WorkingHourInstallment"))
                .replace("#@", t("sysParamContent:WorkingDayInstallment"));
            setInfoLoading(true);
            const infoResponse = await accountService.getCloseEarlyInstallmentInfo(
                {
                    txnId: id,
                }
            );

            if (unmounted.current) {
                return;
            }

            const { code, data } = infoResponse.data;

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setDetail(data);
                    break;
                case RESPONSE_CODE.CANNOT_PAYMENT_INSTALLMENT:
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Error",
                            header: t("fc_lb_attention"),
                            translatedContent: content,
                            button: t("lb_ok"),
                        },
                    });
                    stepBack();
                    break;
                case RESPONSE_CODE.INSTALLMENT_LAST_MONTH_STATEMENT:
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Warning",
                            header: t("hm_lb_notice"),
                            content: "creditCard:ins_close_early_last_payment",
                            button: t("lb_ok"),
                        },
                    });
                    stepBack();
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
            setInfoLoading(false);
        },
        [t, closeDialog, stepBack]
    );

    useEffect(() => getInfoBeforeCloseEarly(txnId), [
        getInfoBeforeCloseEarly,
        txnId,
    ]);

    const closeEarlyErrorHandler = useCallback(
        ({ code, data }) => {
            setActionLoading(false);
            closeOTPDialog();
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    stepForward(data);
                    break;
                case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Error",
                            header: "oops",
                            content:
                                "installment_pay_early_daily_limit_exceeded",
                            button: "lb_ok",
                        },
                    });
                    break;
                case RESPONSE_CODE.NOT_ALLOWED:
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Error",
                            header: "oops",
                            content: "installment_no_enough_money_to_pay",
                            button: "lb_ok",
                        },
                    });
                    break;
                case RESPONSE_CODE.CANNOT_PAYMENT_INSTALLMENT:
                    const content = t(
                        "installment_early_warning_non_working_hours"
                    )
                        .replace(
                            "%@",
                            t("sysParamContent:WorkingHourInstallment")
                        )
                        .replace(
                            "#@",
                            t("sysParamContent:WorkingDayInstallment")
                        );
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Error",
                            header: t("fc_lb_attention"),
                            translatedContent: content,
                            button: t("lb_ok"),
                        },
                    });
                    break;
                case RESPONSE_CODE.INSTALLMENT_LAST_MONTH_STATEMENT:
                    GlobalDialogController.showCustomDialog({
                        onClose: closeDialog,
                        dialogInfo: {
                            iconImage: "Warning",
                            header: t("hm_lb_notice"),
                            content: "creditCard:ins_close_early_last_payment",
                            button: t("lb_ok"),
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [closeDialog, t, stepForward, closeOTPDialog]
    );

    const submitOtp = useCallback(
        async (dataToPost) => {
            setLoading(true);

            const payload = {
                txnId,
                ...dataToPost,
            };

            // const submitResponse = {
            //     data: {
            //         code: 200,
            //         data: {
            //             version: 2,
            //             txnAppType: "100",
            //             txnTimestamp: 1624588524942,
            //             txnId: "TF210625141962359",
            //             txnAmount: -1000,
            //             txnFrom: {
            //                 accName: "NGUYEN THI HEO QUAY",
            //                 bankName: "Timo",
            //                 accNo: "9997041000857",
            //             },
            //             txnTo: {
            //                 accName: "QUY VAC-XIN PHONG COVID-19",
            //                 bankName: "BIDV",
            //                 accNo: "21110009116868",
            //             },
            //             txnDesc: "Timo Transfer",
            //             canShare: true,
            //             receiptTile: "Move money receipt",
            //             payeeCheck: {
            //                 status: 3,
            //                 destination: {
            //                     desType: 1,
            //                     branchId: "5001",
            //                     provinceId: "1",
            //                     province: "Hà Nội",
            //                     provinceName: "Hà Nội",
            //                     accountName: "QUY VAC-XIN PHONG COVID-19",
            //                     accountNumber: "21110009116868",
            //                     bankShortName: "Agribank",
            //                     bankId: "8",
            //                     bankName: "BIDV",
            //                 },
            //             },
            //         },
            //     },
            // };

            const submitResponse = await moveMoneyService.commitOtpCloseEarlyInstallment(
                payload
            );

            if (unmounted.current) {
                return;
            }

            closeEarlyErrorHandler(submitResponse.data);
        },
        [txnId, closeEarlyErrorHandler, setLoading]
    );

    const onCloseEarly = async () => {
        setActionLoading(true);
        const payload = {
            txnId,
            earlyFee: detail.earlyFee, // earlyFee
            earlyInterest: detail.earlyInterest.toString(), // earlyInterest
            amount: detail.earlyTotal, // earlyTotal
            payeeId: parseInt(creditCard.payeeId),
        };
        const response = await moveMoneyService.closeEarlyInstallment(payload);

        if (unmounted.current) {
            return;
        }

        const { code, data } = response.data;
        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: submitOtp,
            });
        } else {
            closeEarlyErrorHandler(response.data);
        }

        setActionLoading(false);
    };

    return (
        <>
            {!infoLoading && (
                <>
                    <div className={className.box}>
                        <div className={className.headerWrapper}>
                            <div
                                className={`${className.headerStatus} bg-active`}
                            >
                                <LspTypography variant="heading2" color="white">
                                    {`${t("available_to_spend")} ${formatNumber(
                                        spendAccount?.availableAmount
                                    )}`}
                                </LspTypography>
                            </div>
                            <div className={className.headerInfo}>
                                <LspTypography
                                    variant="title2"
                                    className={className.headerTitle}
                                >
                                    {title || t("tr_lb_not_available")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    className={className.label}
                                >
                                    {t("creditCard:IN00015")}
                                </LspTypography>
                                <LspTypography variant="title1">
                                    {formatNumber(
                                        detail.currentOutstandingAmount
                                    ) || t("tr_lb_not_available")}
                                </LspTypography>
                            </div>
                        </div>
                        <div className={className.bodyWrapper}>
                            <div className={className.infoLine}>
                                <LspTypography
                                    variant="body2"
                                    className={className.label}
                                >
                                    {t("creditCard:ins_detail_interest_amount")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    className={className.value}
                                >
                                    {formatNumber(detail.interestAmount) ||
                                        t("tr_lb_not_available")}
                                </LspTypography>
                            </div>
                            <div className={className.infoLine}>
                                <LspTypography
                                    variant="body2"
                                    className={className.label}
                                >
                                    {t("creditCard:IN00016")}
                                </LspTypography>
                                <LspTypography
                                    variant="body2"
                                    className={className.value}
                                >
                                    {formatNumber(detail.earlyClosureFee) ||
                                        t("tr_lb_not_available")}
                                </LspTypography>
                            </div>
                            <Divider className={className.divider} />
                            <div className={className.infoLine}>
                                <LspTypography
                                    variant="body2"
                                    className={className.label}
                                >
                                    {t(
                                        "creditCard:ins_pay_early_total_payment_to_pay_off"
                                    )}
                                </LspTypography>
                                <LspTypography variant="title2" color="error">
                                    {formatNumber(detail.totalAmountPayEarly) ||
                                        t("tr_lb_not_available")}
                                </LspTypography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.hint}>
                        <HintIcon vb={24} />
                        <LspTypography variant="body2">
                            {t("creditCard:ins_close_early_note")}
                        </LspTypography>
                    </div>
                    <div className={className.footer}>
                        <LspButton
                            variant="primary"
                            onClick={onCloseEarly}
                            fullWidth
                            progressing={actionLoading}
                        >
                            {t("creditCard:ins_pay_early_btn")}
                        </LspButton>
                    </div>
                </>
            )}
            {infoLoading && <InstallmentDetailLoading className={className} />}
        </>
    );
};

export default InstallmentEarlyInfo;
