export const BillActionTypes = Object.freeze({
    GetBillList: "BillActionTypes/GetBillList",
    GetBillListSuccess: "BillActionTypes/GetBillListSuccess",
    GetBillListError: "BillActionTypes/GetBillListError",
    Reset: "BillActionTypes/Reset",
    SetSelectedBill: "BillActionTypes/SetSelectedBill",
    GetSplitBill: "BillActionTypes/GetSplitBill",
    GetSplitBillSuccess: "BillActionTypes/GetSplitBillSuccess",
    GetSplitBillError: "BillActionTypes/GetSplitBillError",
    GetSplitBillPayment: "BillActionTypes/GetSplitBillPayment",
    GetSplitBillPaymentSuccess: "BillActionTypes/GetSplitBillPaymentSuccess",
    GetSplitBillPaymentError: "BillActionTypes/GetSplitBillPaymentError",
});

const getSplitBillPayment = (payload) => {
    return {
        type: BillActionTypes.GetSplitBillPayment,
        payload,
    };
};

const getSplitBillPaymentSuccess = (payload) => {
    return {
        type: BillActionTypes.GetSplitBillPaymentSuccess,
        payload,
    };
};

const getSplitBillPaymentError = () => {
    return {
        type: BillActionTypes.GetSplitBillPaymentError,
    };
};

const getSplitBill = (payload) => {
    return {
        type: BillActionTypes.GetSplitBill,
        payload,
    };
};

const getSplitBillSuccess = (payload) => {
    return {
        type: BillActionTypes.GetSplitBillSuccess,
        payload,
    };
};

const getSplitBillError = () => {
    return {
        type: BillActionTypes.GetSplitBillError,
    };
};

const getBillList = () => {
    return {
        type: BillActionTypes.GetBillList,
    };
};

const getBillListSuccess = (payload) => {
    return {
        type: BillActionTypes.GetBillListSuccess,
        payload,
    };
};

const getBillListError = () => {
    return {
        type: BillActionTypes.GetBillListError,
    };
};

const reset = (payload) => {
    return {
        type: BillActionTypes.Reset,
        payload,
    };
};
const setSelectedBill = (payload) => {
    return {
        type: BillActionTypes.SetSelectedBill,
        payload,
    };
};

const billActions = {
    getBillList,
    getBillListSuccess,
    getBillListError,
    reset,
    setSelectedBill,
    getSplitBill,
    getSplitBillSuccess,
    getSplitBillError,
    getSplitBillPayment,
    getSplitBillPaymentSuccess,
    getSplitBillPaymentError,
};

export default billActions;
