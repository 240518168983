import api from "@config/api";

const initMetrics = async () => {
    return await api.get("user/join/metric/init");
};

const joinMetric = async (payload) => {
    return await api.post(
        "user/join/metric",
        { events: payload.data },
        { headers: { token: payload.token } }
    );
};

const metric = async (payload) => {
    return await api.post(
        "user/metric",
        { events: payload.data },
        { headers: { token: payload.token } }
    );
};
const metricService = {
    initMetrics,
    joinMetric,
    metric,
};

export default metricService;
