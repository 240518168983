import { makeStyles } from "@material-ui/core";

import autoDeposit from "@assets/images/auto_deposit_icon.png";
import LspTypography from "@components/LspTypography";
import { ArrowRightIcon } from "@components/LspIcon";
import { isFunction } from "lodash";
import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        alignItems: "center",
        cursor: "pointer",
        boxShadow: theme.shadows[29],
    },
    column: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        minHeight: 180,
    },
    icon: {
        width: 56,
        height: 56,
        marginRight: theme.spacing(1),
    },
    iconColumn: {
        width: 64,
        height: 64,
        marginBottom: theme.spacing(2),
    },
    content: {
        flex: 1,
    },
    action: {
        color: theme.palette.magicalGrey[400],
    },
}));
const AutoDepositBanner = ({ onClick, type }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit = () => {
        if (isFunction(onClick)) {
            onClick();
        }
    };

    const isColumn = useMemo(() => type === "column", [type]);

    return (
        <>
            <div
                className={clsx(
                    classes.container,
                    isColumn ? classes.column : ""
                )}
                onClick={() => handleSubmit()}
            >
                <img
                    className={clsx(
                        isColumn ? classes.iconColumn : classes.icon
                    )}
                    src={autoDeposit}
                    alt={t("TD:td_recurring_setup_new_label")}
                />
                {!isColumn && (
                    <>
                        <div className={classes.content}>
                            <LspTypography variant="subheading2">
                                {t("TD:td_recurring_empty_title")}
                            </LspTypography>
                            <LspTypography variant="body1">
                                {t("TD:td_recurring_empty_subtitle")}
                            </LspTypography>
                        </div>
                        <ArrowRightIcon className={classes.action} />
                    </>
                )}
                {isColumn && (
                    <LspTypography variant="button1" color="primary">
                        {t("TD:td_recurring_setup_new_label")}
                    </LspTypography>
                )}
            </div>
        </>
    );
};
export default AutoDepositBanner;
