import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useCallCenter = () => {
    const { t } = useTranslation();

    const getCallCenterContent = useCallback(
        (value) => {
            const content = value?.replace(
                "%@",
                `<a href="tel: ${t("hangout:callcenter_number")}">${t(
                    "hangout:callcenter_number"
                )}</a>`
            );

            return content;
        },
        [t]
    );

    return {
        getCallCenterContent,
    };
};

export default useCallCenter;
