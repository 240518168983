import LspAddress from "@components/LspAddress";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import { Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isFunction } from "lodash";
import { memo, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    deliveryCard: {
        width: "100%",
        borderRadius: 8,
        minHeight: 600,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
    },
    deliveryCardHeader: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(23),
        lineHeight: "34px",
        letterSpacing: "0.3px",
        color: theme.palette.white.contrastText,
        padding: theme.spacing(3),
    },
    deliveryCardBody: {
        padding: theme.spacing(3),
        flex: 1,
    },
    deliveryCardBodyCarouselCard: {
        marginBottom: theme.spacing(3),
    },
    deliveryBoxItem: {
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
    deliveryCardFooter: {
        padding: theme.spacing(3),
    },
    deliveryAlert: {
        marginBottom: theme.spacing(2),
        "& .MuiAlert-message": {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: "22px",
        },
    },
}));

const defaultErrors = {
    province: "",
    district: "",
    ward: "",
    address: "",
};

const AddDeliveryAddress = ({
    selected,
    onAddDeliveryAddressClose,
    onAddDeliveryAddressSubmit,
}) => {
    const classes = useStyles();

    const [addressName, setAddressName] = useState(selected?.addressName || "");
    const [errors, setErrors] = useState(defaultErrors);
    const { t } = useTranslation();

    const [selectedProvince, setSelectedProvince] = useState({
        cfgKey: selected?.cityId,
        cfgValue: selected?.cityName,
    });
    const [selectedDistrict, setSelectedDistrict] = useState({
        cfgKey: selected?.distId,
        cfgValue: selected?.distName,
    });
    const [selectedWard, setSelectedWard] = useState({
        cfgKey: selected?.wardId,
        cfgValue: selected?.wardName,
    });

    const onChangeWard = (ward) => {
        setErrors((prev) => ({
            ...prev,
            ward: "",
        }));
        setSelectedWard(ward);
    };

    const onChangeDistrict = (district) => {
        setErrors((prev) => ({
            ...prev,
            district: "",
        }));
        setSelectedDistrict(district);
    };

    const onChangeProvince = (province) => {
        setErrors((prev) => ({
            ...prev,
            province: "",
        }));
        setSelectedProvince(province);
    };

    const onAddressChange = (e) => {
        const { value } = e.target;
        setAddressName(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                addressName: t("msg_we_need_this"),
            }));
        } else {
            setErrors((prev) => ({
                ...prev,
                addressName: "",
            }));
        }
    };

    const handleClose = () => {
        if (isFunction(onAddDeliveryAddressClose)) {
            onAddDeliveryAddressClose();
        }
    };

    const handleSubmit = () => {
        if (addressName === undefined || addressName === "") {
            setErrors((prev) => ({
                ...prev,
                addressName: t("msg_we_need_this"),
            }));
        }
        if (selectedProvince.cfgValue === undefined) {
            setErrors((prev) => ({
                ...prev,
                province: t("msg_we_need_this"),
            }));
        }

        if (selectedDistrict.cfgValue === undefined) {
            setErrors((prev) => ({
                ...prev,
                district: t("msg_we_need_this"),
            }));
        }

        if (selectedWard.cfgValue === undefined) {
            setErrors((prev) => ({
                ...prev,
                ward: t("msg_we_need_this"),
            }));
        }
        if (
            addressName &&
            selectedWard.cfgValue != null &&
            selectedDistrict.cfgValue != null &&
            selectedProvince.cfgValue != null
        ) {
            onAddDeliveryAddressSubmit({
                addressName,
                cityId: selectedProvince.cfgKey,
                cityName: selectedProvince.cfgValue,
                distId: selectedDistrict.cfgKey,
                distName: selectedDistrict.cfgValue,
                wardId: selectedWard.cfgKey,
                wardName: selectedWard.cfgValue,
            });
        }
    };
    return (
        <>
            <div className={classes.deliveryCard}>
                <div className={classes.deliveryCardHeader}>
                    {t("debitCard:reissuecard_receive_card_CTA_new_add")}
                </div>
                <div className={classes.deliveryCardBody}>
                    <div className={classes.deliveryAlert}>
                        <Alert variant="filled" severity="warning">
                            {t("booking_add_location_notice")}
                        </Alert>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LspTextField
                                disableHelperText
                                autoFocus
                                name="address"
                                label="Address"
                                onChange={onAddressChange}
                                value={addressName}
                                error={!!errors.addressName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LspAddress
                                selectedProvince={selectedProvince}
                                onChangeProvince={onChangeProvince}
                                selectedDistrict={selectedDistrict}
                                onChangeDistrict={onChangeDistrict}
                                selectedWard={selectedWard}
                                onChangeWard={onChangeWard}
                                errors={errors}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.deliveryCardFooter}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="secondary"
                                onClick={handleClose}
                            >
                                {t("lb_back")}
                            </LspButton>
                        </Grid>
                        <Grid item xs={6}>
                            <LspButton
                                fullWidth
                                variant="primary"
                                onClick={handleSubmit}
                            >
                                {t("lb_done")}
                            </LspButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

AddDeliveryAddress.propTypes = {
    selected: PropTypes.shape({
        cityId: PropTypes.string,
        cityName: PropTypes.string,
        distId: PropTypes.string,
        distName: PropTypes.string,
        wardId: PropTypes.string,
        wardName: PropTypes.string,
        addressName: PropTypes.string,
    }),
};

AddDeliveryAddress.defaultProps = {
    selected: {},
};
export default memo(AddDeliveryAddress);
