import { makeStyles } from "@material-ui/core";
import { memo } from "react";
import BillBreakLine from "./billBreakLine";
import BillHeader from "./billHeader";
import BillMember from "./billMember";

const useStyles = makeStyles((theme) => ({
    bill: {
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.neutral.white,
    },
}));

const Bill = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.bill}>
                <BillHeader />
                <BillBreakLine />
                <BillMember />
            </div>
        </>
    );
};

export default memo(Bill);
