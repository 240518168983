import Content from "@components/Content";
import LspReceipt from "@components/LspReceipt";
import { RESPONSE_CODE, SpendAccountNavigationKey } from "@config/constants";
import BillDetail from "@containers/SpendAccount/BillPayment/BillDetail";
import NewBillCustomerInput from "@containers/SpendAccount/BillPayment/NewBill/NewBillCustomerInput";
import NewBillHeader from "@containers/SpendAccount/BillPayment/NewBill/NewBillHeader";
import NewBillProviders from "@containers/SpendAccount/BillPayment/NewBill/NewBillProviders";
import NewBillSunLifeForm from "@containers/SpendAccount/BillPayment/NewBill/NewBillSunLifeForm";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useStepNavigation from "@helpers/useStepNavigation";
import billServices from "@services/bill";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const NEW_BILL_STEPS = {
    PROVIDER_LIST: 0,
    PROVIDER_CHILD_LIST: 1,
    INPUT_CUSTOMER_ID: 2,
    BILL_DETAIL: 3,
    BILL_RECEIPT: 4,
    BILL_SUN_LIFE_FORM: 5,
};

const NewBill = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const unmounted = useRef(false);
    const { step, stepBack, stepForward } = useStepNavigation({
        initialStep: NEW_BILL_STEPS.PROVIDER_LIST,
    });

    const [providerList, setProviderList] = useState([]);
    const [providerSubList, setProviderSubList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [billFormData, setBillFormData] = useState([]);

    const [fetchingDetail, setFetchingDetail] = useState(false);
    const [billDetail, setBillDetail] = useState({});
    const [errors, setErrors] = useState(
        {
            customerId: "",
            extraCode: "",
        },
        []
    );

    const [receipt, setReceipt] = useState({});

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getProviderList = useCallback(async (supplierId) => {
        setFetching(true);
        const payload = {
            supplierId,
        };

        const response = await billServices.getBillProvider(payload);
        if (unmounted.current) return;

        const { code, data } = response.data;

        setFetching(false);

        if (code === RESPONSE_CODE.SUCCESS) {
            const providers = data?.map((item) => {
                const name = item?.providerName || item?.serviceName;
                return {
                    ...item,
                    providerName: name,
                };
            });

            if (supplierId === 0) {
                setProviderList(providers);
            } else {
                setProviderSubList(providers);
            }

            return;
        }

        GlobalDialogController.showError({ errorCode: code });
    }, []);

    const backToBillList = () => {
        history.push(`/spend-account/${SpendAccountNavigationKey.BillPayment}`);
    };

    const onNextChildProviders = (provider) => {
        setSelectedProvider(provider);
        getProviderList(provider?.providerId);
        stepForward(NEW_BILL_STEPS.PROVIDER_CHILD_LIST);
    };

    const getBillFormErrorHandler = () => {
        stepBack();
        GlobalDialogController.hide();
    };

    const getBillForm = async (supplyId) => {
        setFetching(true);
        const payload = {
            supplyId,
        };

        const response = await billServices.getBillForm(payload);
        if (unmounted.current) return;

        const { code, data } = response.data;

        setFetching(false);
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setBillFormData(data);
                break;
            case RESPONSE_CODE.NOT_ACCEPTABLE:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "serverunavailable",
                        header: "lb_we_sorry",
                        content: "gb_ms_unsupport_supplier",
                        button: "lb_ok",
                    },
                    onClose: getBillFormErrorHandler,
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const onNextCustomerForm = (provider) => {
        setSelectedProvider(provider);
        getBillForm(provider?.providerId);

        if (provider?.serviceCode === "LIFE_INSURANCE") {
            stepForward(NEW_BILL_STEPS.BILL_SUN_LIFE_FORM);
            return;
        }

        stepForward(NEW_BILL_STEPS.INPUT_CUSTOMER_ID);
    };

    const getBillDetail = async (dataToPost) => {
        setFetchingDetail(true);
        const payload = {
            serviceCode: selectedProvider?.serviceCode,
            providerCode: selectedProvider?.providerCode,
            saveBill: dataToPost?.saveBill,
            providerId: selectedProvider?.providerId,
            formData: dataToPost?.formData,
            formId: billFormData?.formId,
        };
        const response = await billServices.getBillDetail(payload);

        if (unmounted.current) return;

        setFetchingDetail(false);

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.NO_RECORD:
                setBillDetail(data);
                stepForward(NEW_BILL_STEPS.BILL_DETAIL);
                break;
            case RESPONSE_CODE.NOT_ACCEPTABLE:
                setErrors((prev) => ({
                    ...prev,
                    customerId: t("gb_ms_unsupport_supplier"),
                }));

                break;
            case RESPONSE_CODE.WRONG_INFO:
                setErrors((prev) => ({
                    ...prev,
                    customerId: t("gb_ms_cannot_found_customer_id"),
                }));
                break;
            default:
                setErrors((prev) => ({
                    ...prev,
                    customerId: t("bill_customerId_invalid"),
                }));
                break;
        }
    };

    useEffect(() => {
        getProviderList(0);
    }, [getProviderList]);

    const onPayBillSuccessfully = (receiptInfo) => {
        setReceipt(receiptInfo);
        stepForward(NEW_BILL_STEPS.BILL_RECEIPT);
    };

    return (
        <>
            <NewBillHeader
                step={step.current}
                stepList={NEW_BILL_STEPS}
                providerName={selectedProvider?.providerName}
                stepBack={stepBack}
            />
            {step.current === NEW_BILL_STEPS.PROVIDER_LIST && (
                <NewBillProviders
                    providerList={providerList}
                    onCancel={backToBillList}
                    fetching={fetching}
                    onNext={onNextChildProviders}
                />
            )}
            {step.current === NEW_BILL_STEPS.PROVIDER_CHILD_LIST && (
                <NewBillProviders
                    providerList={providerSubList}
                    onCancel={backToBillList}
                    fetching={fetching}
                    onNext={onNextCustomerForm}
                />
            )}
            {step.current === NEW_BILL_STEPS.INPUT_CUSTOMER_ID && (
                <NewBillCustomerInput
                    onCancel={backToBillList}
                    fetching={fetching}
                    onNext={getBillDetail}
                    formData={billFormData?.formData}
                    fetchingDetail={fetchingDetail}
                    errors={errors}
                    setErrors={setErrors}
                />
            )}
            {step.current === NEW_BILL_STEPS.BILL_DETAIL && (
                <Content size="sm">
                    <BillDetail
                        selectedBill={selectedProvider}
                        detail={billDetail}
                        onCancel={backToBillList}
                        onNext={onPayBillSuccessfully}
                    />
                </Content>
            )}
            {step.current === NEW_BILL_STEPS.BILL_RECEIPT && (
                <Content size="sm">
                    <LspReceipt onClose={backToBillList} rawReceipt={receipt} />
                </Content>
            )}
            {step.current === NEW_BILL_STEPS.BILL_SUN_LIFE_FORM && (
                <NewBillSunLifeForm
                    onCancel={backToBillList}
                    onNext={onPayBillSuccessfully}
                />
            )}
        </>
    );
};

export default memo(NewBill);
