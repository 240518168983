import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import userAction from "@redux/actions/user";
import ProfileBanner from "@containers/Profile/ProfileBanner";
import ProfileAccountInfo from "@containers/Profile/ProfileAccountInfo/ProfileAccountInfo";
import ProfileAccountDetail from "@containers/Profile/ProfileAccountDetail";
import ProfileAccountPersonalInfo from "@containers/Profile/ProfileAccountPersonalInfo";
import ProfileFetching from "@containers/Profile/ProfileFetching";

const Profile = ({ user, fetching, getProfile, systemParam }) => {
    const unmounted = useRef(false);

    const firstLoaded = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    // ===== End Upload avatar =====

    useEffect(() => {
        if (unmounted.current) return;
        getProfile();
        firstLoaded.current = true;
    }, [getProfile]);

    return (
        <>
            <ProfileBanner
                avatar={user.avatar}
                fullName={user.fullName}
                getProfile={getProfile}
            />
            <ContentWrapper>
                <Content>
                    {fetching && <ProfileFetching />}
                    {!fetching && firstLoaded.current && (
                        <>
                            <ProfileAccountInfo
                                user={user}
                                systemParam={systemParam}
                            />
                            <ProfileAccountDetail user={user} />
                            <ProfileAccountPersonalInfo user={user} />
                        </>
                    )}
                </Content>
            </ContentWrapper>
        </>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    fetching: state.user.fetching,
    systemParam: state.systemParams.sysparams,
});

const mapDispatch = (dispatch) => ({
    getProfile: () => dispatch(userAction.getProfileRequest()),
});

export default connect(mapState, mapDispatch)(Profile);
