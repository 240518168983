import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspTextField from "@components/LspTextField";
import { RESPONSE_CODE } from "@config/constants";
import NewBillSunLifeReview from "@containers/SpendAccount/BillPayment/NewBill/NewBillSunLifeReview";
import useTransactionValidation from "@containers/SpendAccount/useTransactionValidation";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import useOTP from "@helpers/useOTP";
import { Box, makeStyles } from "@material-ui/core";
import moveMoneyService from "@services/move-money";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    column: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& > div": {
            width: `calc(50% - ${theme.spacing(1)}px)`,
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
    },
}));

const NewBillSunLifeForm = ({ onCancel, onNext, sunLifeAccount }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [submitting, setSubmitting] = useState(false);
    const [isReviewStep, setIsReviewStep] = useState(false);

    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const { validateAmount } = useTransactionValidation();
    const { spendAccount } = useAccounts();

    const [amount, setAmount] = useState("");
    const [policyNumber, setPolicyNumber] = useState("");
    const [errors, setErrors] = useState({
        amount: "",
        policyNumber: "",
    });
    const [validatingAmount, setValidatingAmount] = useState(false);

    const unmounted = useRef(false);
    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const sunLifeInfo = useMemo(() => {
        return `${sunLifeAccount.accountName} | ${sunLifeAccount?.bankName}`;
    }, [sunLifeAccount]);

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (!value) {
                setErrors((prev) => ({
                    ...prev,
                    amount: t("msg_we_need_this"),
                }));
            } else if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value: +value });
        },
        [errors.amount, t]
    );

    const onChangePolicyNumber = (e) => {
        const { value } = e.target;
        setPolicyNumber(value);

        if (value === "") {
            setErrors((prev) => ({
                ...prev,
                policyNumber: t("msg_we_need_this"),
            }));
        } else if (errors?.policyNumber !== "") {
            setErrors((prev) => ({
                ...prev,
                policyNumber: "",
            }));
        }
    };

    const toggleReviewScreen = useCallback(() => {
        setIsReviewStep((prev) => !prev);
    }, []);

    const onFormSubmit = useCallback(
        (e) => {
            e.preventDefault();

            if (errors?.amount || errors?.policyNumber) return;

            validateAmount({
                amount: amount.value,
                transferType: "member",
                beforeRequest: () => setValidatingAmount(true),
                isCancelled: unmounted.current,
                afterResponse: () => setValidatingAmount(false),
                handleBalanceLessThanMinAmount: (minAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: `${t("spend_msg_for_least_amount").replace(
                            "%@",
                            formatNumber(minAmount)
                        )}`,
                    })),
                handleAmountOutOfRange: (minAmount, maxAmount) => {
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_amount_invalid")
                            .replace("@", formatNumber(minAmount))
                            .replace("%@", formatNumber(maxAmount)),
                    }));
                },
                handleAmountOverDailyLimit: () =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_daily_limit"),
                    })),
                handleAmountValid: () => {
                    toggleReviewScreen();
                },
            });
        },
        [
            validateAmount,
            amount.value,
            t,
            errors,
            toggleReviewScreen,
            formatNumber,
        ]
    );

    const makePaymentResponseHandler = useCallback(
        (code, data) => {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    onNext(data);
                    break;
                case RESPONSE_CODE.NO_ENOUGH_MONEY_3:
                    AlertController.show(
                        t("topup_msg_invalid_amount"),
                        AlertType.Error
                    );
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [onNext, t]
    );

    const commitOtp = useCallback(
        async (payload) => {
            setLoading(true);

            const { data } = await moveMoneyService.submitOTP(payload);
            setLoading(false);
            closeOTPDialog();
            makePaymentResponseHandler(data.code, data.data);
        },
        [makePaymentResponseHandler, closeOTPDialog, setLoading]
    );

    const onMakePayment = useCallback(async () => {
        setSubmitting(true);
        const payload = {
            source: {
                accountNumber: spendAccount?.no,
            },
            target: {
                accountNumber: sunLifeAccount?.accountNumber,
                description: `${t(
                    "wealth_life_insurance_label_policy_number"
                )} ${policyNumber}`,
                amount: parseFloat(+amount.value),
                bankName: sunLifeAccount?.bankName,
                accountName: sunLifeAccount?.accountName,
                desType: "bankaccount",
            },
            notification: "sms",
        };

        const response = await moveMoneyService.transferToAccountNumber(
            payload
        );

        if (unmounted.current) return;
        setSubmitting(false);

        const { code, data } = response.data;

        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: commitOtp,
            });
            return;
        }
        makePaymentResponseHandler(code, data);
    }, [
        policyNumber,
        sunLifeAccount,
        spendAccount,
        t,
        amount.value,
        commitOtp,
        makePaymentResponseHandler,
        openOTPDialog,
    ]);

    const buttons = useMemo(
        () => [
            {
                label: t("lb_next"),
                onClick: onFormSubmit,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
            {
                label: t("mm_label_move_money_now"),
                onClick: onMakePayment,
            },
        ],
        [onFormSubmit, onCancel, t, onMakePayment]
    );

    return (
        <Content size="sm">
            {/* SUN LIFE FORM */}
            {!isReviewStep && (
                <form onSubmit={onFormSubmit}>
                    <Box className={classes.column}>
                        <LspTextField
                            name="accountNumber"
                            label={t("lb_account_number")}
                            helperText={sunLifeInfo}
                            inputProps={{
                                type: "text",
                                maxLength: 23,
                            }}
                            value={sunLifeAccount?.accountNumber}
                            disabled
                        />
                        <NumberFormat
                            value={amount.formattedValue}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            onValueChange={onAmountChange}
                            label={t("payee_card_amount_label")}
                            error={!!errors.amount}
                            helperText={errors.amount || " "}
                            customInput={LspTextField}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            autoFocus
                        />
                    </Box>
                    <LspTextField
                        fullWidth
                        error={!!errors?.policyNumber}
                        helperText={errors?.policyNumber}
                        name="policyNumber"
                        label={t("wealth_life_insurance_policy_number")}
                        inputProps={{
                            type: "text",
                        }}
                        onChange={onChangePolicyNumber}
                        value={policyNumber}
                    />
                    <Box mt={3}>
                        <DoubleButtons
                            primaryButton={buttons[0]}
                            secondaryButton={buttons[1]}
                            progressing={validatingAmount}
                        />
                    </Box>
                </form>
            )}

            {isReviewStep && (
                <>
                    <NewBillSunLifeReview
                        sunLifeAccount={sunLifeAccount}
                        policyNumber={policyNumber}
                        amount={amount}
                    />

                    <Box mt={3}>
                        <DoubleButtons
                            primaryButton={buttons[2]}
                            secondaryButton={buttons[1]}
                            progressing={submitting}
                        />
                    </Box>
                </>
            )}
        </Content>
    );
};

const stateProps = (state) => ({
    sunLifeAccount: {
        accountNumber:
            state.systemParams.info.sysparams["1.0"]?.SunlifeAccountNumber,
        accountName:
            state.systemParams.info.sysparams["1.0"]?.SunlifeAccountName,
        bankName: state.systemParams.info.sysparams["1.0"]?.SunlifeBankName,
        bankId: state.systemParams.info.sysparams["1.0"]?.SunlifeBankId,
    },
});

export default memo(connect(stateProps)(NewBillSunLifeForm));
