/* ------------- Types Definition ------------- */
export const LixiActionTypes = Object.freeze({
    GetDashboard: "LixiActionTypes/GetDashboard",
    GetDashboardSuccess: "LixiActionTypes/GetDashboardSuccess",
    GetDashboardError: "LixiActionTypes/GetDashboardError",
    GetUnreadLixiList: "LixiActionTypes/GetUnreadLixiList",
    GetUnreadLixiListSuccess: "LixiActionTypes/GetUnreadLixiListSuccess",
    GetUnreadLixiListError: "LixiActionTypes/GetUnreadLixiListError",
    GetReadLixiList: "LixiActionTypes/GetReadLixiList",
    GetReadLixiListSuccess: "LixiActionTypes/GetReadLixiListSuccess",
    GetReadLixiListError: "LixiActionTypes/GetReadLixiListError",
    Reset: "LixiActionTypes/Reset",
    GetLixiDetail: "LixiActionTypes/GetLixiDetail",
    GetLixiDetailSuccess: "LixiActionTypes/GetLixiDetailSuccess",
    GetLixiDetailError: "LixiActionTypes/GetLixiDetailError",
    SetPayeeDetail: "LixiActionTypes/SetPayeeDetail",
    SetSubmitInfo: "LixiActionTypes/SetSubmitInfo",
    ResetSubmitInfo: "LixiActionTypes/ResetSubmitInfo",
});

/* ------------- Actions Definition ------------- */

const getUnreadLixiList = (payload) => {
    return {
        type: LixiActionTypes.GetUnreadLixiList,
        payload,
    };
};

const getUnreadLixiListSuccess = (payload) => {
    return {
        type: LixiActionTypes.GetUnreadLixiListSuccess,
        payload,
    };
};

const getUnreadLixiListError = () => {
    return {
        type: LixiActionTypes.GetUnreadLixiListError,
    };
};

const getReadLixiList = (payload) => {
    return {
        type: LixiActionTypes.GetReadLixiList,
        payload,
    };
};

const getReadLixiListSuccess = (payload) => {
    return {
        type: LixiActionTypes.GetReadLixiListSuccess,
        payload,
    };
};

const getReadLixiListError = () => {
    return {
        type: LixiActionTypes.GetReadLixiListError,
    };
};

const getDashboard = () => {
    return {
        type: LixiActionTypes.GetDashboard,
    };
};

const getDashboardSuccess = (payload) => {
    return {
        type: LixiActionTypes.GetDashboardSuccess,
        payload,
    };
};

const getDashboardError = () => {
    return {
        type: LixiActionTypes.GetDashboardError,
    };
};

const reset = () => {
    return {
        type: LixiActionTypes.Reset,
    };
};

//* ------------- Get Lixi Detail ------------- */

const getLixiDetail = (payload) => {
    return {
        type: LixiActionTypes.GetLixiDetail,
        payload,
    };
};

const getLixiDetailSuccess = (payload) => {
    return {
        type: LixiActionTypes.GetLixiDetailSuccess,
        payload,
    };
};

const getLixiDetailError = () => {
    return {
        type: LixiActionTypes.GetLixiDetailError,
    };
};

//* ------------- Send lixi flow ------------- */

const setPayeeDetail = (payload) => {
    return {
        type: LixiActionTypes.SetPayeeDetail,
        payload,
    };
};

const setSubmitInfo = (payload) => {
    return {
        type: LixiActionTypes.SetSubmitInfo,
        payload,
    };
};

const resetSubmitInfo = () => {
    return {
        type: LixiActionTypes.ResetSubmitInfo,
    };
};

const lixiActions = {
    getDashboard,
    getDashboardSuccess,
    getDashboardError,
    getUnreadLixiList,
    getUnreadLixiListSuccess,
    getUnreadLixiListError,
    getReadLixiList,
    getReadLixiListSuccess,
    getReadLixiListError,
    reset,

    getLixiDetail,
    getLixiDetailSuccess,
    getLixiDetailError,

    setPayeeDetail,
    setSubmitInfo,
    resetSubmitInfo,
};

export default lixiActions;
