import { useCallback, useMemo } from "react";
import capitalize from "lodash/capitalize";

const useCapitalize = () => {
    const capitalizeWord = useCallback((string = "") => {
        return capitalize(string);
    }, []);

    const capitalizeSentence = useCallback(
        (sentence = "") => {
            const wordArray = sentence.split(" ");
            const formattedWordArray = wordArray.map((word) => {
                return capitalizeWord(word);
            });
            return formattedWordArray.join(" ");
        },
        [capitalizeWord]
    );

    const capitalizeFormat = useMemo(
        () => ({
            capitalizeWord,
            capitalizeSentence,
        }),
        [capitalizeWord, capitalizeSentence]
    );

    return capitalizeFormat;
};

export default useCapitalize;
