import { useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import format from "date-fns/format";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
import {
    CreditCardNavigationKey,
    TransactionGroupType,
} from "@config/constants";
import accountAction from "@redux/actions/account";
import TransactionListLoading from "@components/TransactionList/TransactionListLoading";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import Content from "@components/Content";
import { useHistory } from "react-router-dom";
import useAccounts from "@helpers/useAccounts";
import PageHeader from "@components/PageHeader";

const InstallmentAvailable = ({
    getTransactionList,
    transactionListFetching,
    transactionList,
    txnDateIndex,
    xidIndex,
    hasMore,
    serverTime,
    serverTimeFetching,
}) => {
    const history = useHistory();
    const { t } = useTranslation("translation", "creditCard");

    const { creditCard } = useAccounts();

    const firstLoaded = useRef(false);

    const getTransactionHistory = useCallback(
        ({ pagingData } = {}) => {
            if (!creditCard || !serverTime) {
                return;
            }

            const payload = {
                accountNo: creditCard.no,
                accountType: creditCard.accountType,
                fromDate: "01/01/2015",
                toDate: format(serverTime, "dd/MM/yyyy"),
                listType: "installment",
            };
            if (pagingData?.txnDateIndex && pagingData.xidIndex) {
                payload.txnDateIndex = pagingData.txnDateIndex;
                payload.xidIndex = pagingData.xidIndex;
            }
            getTransactionList(payload);
        },
        [serverTime, creditCard, getTransactionList]
    );

    const backInstallmentList = useCallback(() => {
        history.push(CreditCardNavigationKey.InstallmentList);
    }, [history]);

    useEffect(() => {
        if (!serverTimeFetching && !firstLoaded.current) {
            firstLoaded.current = true;
            getTransactionHistory();
        }
    }, [serverTimeFetching, getTransactionHistory]);

    const loadMore = () => {
        if (
            !hasMore ||
            transactionListFetching ||
            serverTimeFetching ||
            !firstLoaded.current
        ) {
            return;
        }
        getTransactionHistory({
            pagingData: {
                txnDateIndex,
                xidIndex,
            },
        });
    };

    const infiniteRef = useInfiniteScroll({
        loading: transactionListFetching,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const isEmptyList =
        !hasMore && transactionList?.length === 0 && !transactionListFetching;

    return (
        <Content>
            <PageHeader backFunc={backInstallmentList}>
                {t("creditCard:convertable_installment_header")}
            </PageHeader>
            {isEmptyList ? (
                <Box paddingY={4} textAlign="center">
                    <Typography variant="body2">
                        {t("spend_dont_have_transactions_yet")}
                    </Typography>
                </Box>
            ) : (
                <>
                    <div ref={infiniteRef}>
                        {transactionList?.map((txnGroup) => {
                            const key = `${txnGroup.dispDate}-${txnGroup.item.length}`;
                            return (
                                <TransactionGroup
                                    key={key}
                                    header={txnGroup.dispDate}
                                    items={txnGroup.item}
                                    onItemClick={null}
                                    type={TransactionGroupType.CreditCard}
                                />
                            );
                        })}
                    </div>
                    {hasMore && <TransactionListLoading />}
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    transactionList: state.account.creditCard.transactionList,
    transactionListFetching: state.account.creditCard.fetching,
    txnDateIndex: state.account.creditCard.txnDateIndex,
    xidIndex: state.account.creditCard.xidIndex,
    hasMore: state.account.creditCard.hasMore,
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
});

const mapDispatch = (dispatch) => ({
    getTransactionList: (payload) =>
        dispatch(accountAction.getCreditCardTransactionListRequest(payload)),
});

export default connect(mapState, mapDispatch)(InstallmentAvailable);
