import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import {
    Box,
    ButtonBase,
    Icon,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    suggestionBox: {
        boxShadow: theme.shadows[3],
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.neutral.grey2}`,
        borderRadius: theme.shape.radiusLevels[0],
        padding: theme.spacing(1),
        "& button": {
            display: "inline-block",
            marginLeft: theme.spacing(0.25),
            marginRight: theme.spacing(0.25),
        },
        marginBottom: theme.spacing(2),
        position: "relative",
    },
    closeIcon: {
        textAlign: "right",
        position: "absolute",
        right: 0,
        top: 0,
        "& span": {
            fontSize: 12,
        },
    },
    beatIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& p": {
            fontStyle: "italic",
        },
    },
}));

const GoalSaveDetailBoxSuggestion = ({
    onClose,
    suggestingData,
    updateSuggestField,
    fieldName,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <Box className={classes.suggestionBox}>
            <IconButton onClick={onClose} className={classes.closeIcon}>
                <Icon className="font-icon icon-close" />
            </IconButton>
            <Box className={classes.title}>
                <Icon className={`font-icon icon-beat ${classes.beatIcon}`} />
                <LspTypography variant="heading4" color="primary">
                    {t("gs_label_timo_suggest")}
                </LspTypography>
            </Box>
            {fieldName === "endDate" && (
                <Box>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_1")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "suggestAmount",
                                suggestingData?.suggestAmount
                            )
                        }
                        datatestid="updateSuggestAmount"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {formatNumber(suggestingData?.suggestAmount)}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_2")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "targetMoney",
                                suggestingData?.targetMoney
                            )
                        }
                        datatestid="updateTargetMoney"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {formatNumber(suggestingData?.targetMoney)}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_3")}
                    </LspTypography>
                </Box>
            )}
            {fieldName === "targetMoney" && (
                <Box>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_1")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "suggestAmount",
                                suggestingData?.suggestAmount
                            )
                        }
                        datatestid="updateSuggestAmount"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {formatNumber(suggestingData?.suggestAmount)}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_target_date")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "endDate",
                                suggestingData?.endDate
                            )
                        }
                        datatestid="updateEndDate"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {suggestingData?.endDate}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_3")}
                    </LspTypography>
                </Box>
            )}
            {fieldName === "suggestAmount" && (
                <Box>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_1")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "suggestAmount",
                                suggestingData?.suggestAmount
                            )
                        }
                        datatestid="updateSuggestAmount"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {formatNumber(suggestingData?.suggestAmount)}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_target_date")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "endDate",
                                suggestingData?.endDate
                            )
                        }
                        datatestid="updateEndDate"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {suggestingData?.endDate}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_3")}
                    </LspTypography>
                </Box>
            )}
            {fieldName === "recurringType" && (
                <Box>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_1")}
                    </LspTypography>
                    <ButtonBase
                        onClick={() =>
                            updateSuggestField(
                                "suggestAmount",
                                suggestingData?.suggestAmount
                            )
                        }
                        datatestid="updateSuggestAmount"
                    >
                        <LspTypography variant="hyperlink2" color="success">
                            {formatNumber(suggestingData?.suggestAmount)}
                        </LspTypography>
                    </ButtonBase>
                    <LspTypography component="span" variant="body3">
                        {t("gs_edit_suggest_3")}
                    </LspTypography>
                </Box>
            )}
        </Box>
    );
};

export default memo(GoalSaveDetailBoxSuggestion);
