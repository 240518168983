import useNumber from "@helpers/useNumber";
import { memo } from "react";
import { format, parse } from "date-fns";

const BillHistoryItem = ({ item, classes }) => {
    const { formatNumber } = useNumber();

    const payDate = format(
        parse(item?.PayTime, "dd-MM-yyyy", new Date()),
        "dd/MM/yyyy"
    );
    const billPeriod = format(
        parse(item?.PayTime, "dd-MM-yyyy", new Date()),
        "MM/yyyy"
    );

    return (
        <div className={`${classes.historiesColumn} ${classes.historiesInfo}`}>
            <div>{payDate}</div>
            <div className={classes.amount}>
                {formatNumber(item?.Billamount)}
            </div>
            <div>{item?.Billid}</div>
            <div>{billPeriod}</div>
        </div>
    );
};

export default memo(BillHistoryItem);
