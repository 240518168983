import ContentWrapper from "@components/ContentWrapper";
import { OVERDRAFT_KEY, OVERDRAFT_STATUS } from "@config/constants";
import usePathname from "@helpers/usePathname";
import { useMemo, memo, useEffect, useCallback, useState, useRef } from "react";

import { Switch, Route, useHistory } from "react-router-dom";
import overdraftService from "@services/overdraft";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OverdraftIntroDialog from "@components/OverdraftIntroDialog";

import Banner from "@components/Banner";
import { useTranslation } from "react-i18next";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import tommyEmpty from "@assets/images/tommy_empty.png";
import { Skeleton } from "@material-ui/lab";
import Content from "@components/Content";
import useAccounts from "@helpers/useAccounts";
import OverdraftCreate from "./Component/OverdraftCreate";
import OverdraftPreview from "./Component/OverdraftPreview";
import OverdraftDetails from "./Component/OverdraftDetails";
import OverdraftClose from "./Component/OverdraftClose";

const parentName = "overdraft";

const Overdraft = () => {
    const { generateChildPathname } = usePathname();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { isOverDraftUser } = useAccounts();
    const unmounted = useRef(false);
    const firstLoad = useRef(false);

    const history = useHistory();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const handleOnClose = () => {
        GlobalDialogController.hide();
    };

    const handleCreateOverdraft = useCallback(
        (overdraftId) => {
            history.push(
                overdraftId
                    ? `/${parentName}/${OVERDRAFT_KEY.CREATE}/${overdraftId}`
                    : `/${parentName}/${OVERDRAFT_KEY.CREATE}`
            );
            handleOnClose();
        },
        [history]
    );

    const handlePreviewOverdraft = useCallback(
        (id) => {
            history.push(
                `/${parentName}/${OVERDRAFT_KEY.PREVIEW}/${id}?odStatus=${OVERDRAFT_STATUS.PROCESSING}`
            );
        },
        [history]
    );

    const openTD = useCallback(() => {
        handleOnClose();
        history.push("/term-deposit/open");
    }, [history]);

    const notFoundTD = useCallback(() => {
        const contents = {
            banner: tommyEmpty,
            title: t(
                "overdraft:overdraft_status_NOT_FOUND_TD_ELIGIBLE_popup_title"
            ),
            description: t(
                "overdraft:overdraft_status_NOT_FOUND_TD_ELIGIBLE_popup_desc"
            ),
            buttons: [
                {
                    variant: "primary",
                    label: t(
                        "overdraft:overdraft_status_NOT_FOUND_TD_ELIGIBLE_popup_cta"
                    ),
                    onClick: () => openTD(),
                },
                {
                    variant: "plain",
                    label: t("lb_close"),
                    onClick: () => {
                        handleOnClose();
                        history.push(`/home`);
                    },
                },
            ],
        };

        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={contents} centerImg />
            ),
        });
    }, [history, openTD, t]);

    const foreignDialog = useCallback(() => {
        const contents = {
            banner: tommyEmpty,
            title: t("overdraft:overdraft_status_FOREIGN_popup_title"),
            description: t("overdraft:overdraft_status_FOREIGN_popup_desc"),
            buttons: [
                {
                    variant: "primary",
                    label: t("lb_close"),
                    onClick: () => {
                        handleOnClose();
                        history.push(`/home`);
                    },
                },
            ],
        };

        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={contents} centerImg />
            ),
        });
    }, [history, t]);

    const openOverdraftIntro = useCallback(
        (termId) => {
            GlobalDialogController.show({
                component: () => (
                    <OverdraftIntroDialog
                        onNext={() => {
                            handleCreateOverdraft(termId);
                        }}
                        onClose={() => {
                            handleOnClose();
                            history.push(`/home`);
                        }}
                    />
                ),
            });
        },
        [history, handleCreateOverdraft]
    );

    const handleOverDraftStatus = useCallback(
        (status, overdraftId, termId) => {
            if (unmounted.current) return;

            switch (status) {
                case OVERDRAFT_STATUS.AVAILABLE:
                    openOverdraftIntro(termId);
                    break;
                case OVERDRAFT_STATUS.PROCESSING:
                case OVERDRAFT_STATUS.PROCESS_CLOSING:
                    handlePreviewOverdraft(overdraftId);
                    break;
                case OVERDRAFT_STATUS.OVERDRAFTED:
                    history.push(`/overdraft/details`);
                    break;
                case OVERDRAFT_STATUS.NOT_FOUND_TD_ELIGIBLE:
                    notFoundTD();
                    break;
                case OVERDRAFT_STATUS.OTP_WAITING:
                    handleCreateOverdraft(termId);
                    break;
                case OVERDRAFT_STATUS.INELIGIBLE:
                case OVERDRAFT_STATUS.FOREIGN:
                    foreignDialog();
                    break;
                default:
                    break;
            }
            handleOnClose();
        },
        [
            foreignDialog,
            handleCreateOverdraft,
            handlePreviewOverdraft,
            history,
            notFoundTD,
            openOverdraftIntro,
        ]
    );

    const handleCheckOverDraft = useCallback(async () => {
        setLoading(true);
        const overdraftStatus = await overdraftService.checkOverdraftStatus();

        if (unmounted.current) return;

        const { data } = overdraftStatus.data;
        setLoading(false);
        handleOverDraftStatus(
            data.result,
            data?.overdraftId || "",
            data?.termId || ""
        );
    }, [handleOverDraftStatus]);

    useEffect(() => {
        setLoading(false);

        if (firstLoad.current) return;

        handleCheckOverDraft();
        firstLoad.current = true;
    }, [history, isOverDraftUser, handleCheckOverDraft]);

    const handleHelpChange = () => {
        GlobalDialogController.show({
            component: () => (
                <OverdraftIntroDialog onClose={() => handleOnClose()} />
            ),
        });
    };

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(parentName, OVERDRAFT_KEY.CREATE),
                component: () => <OverdraftCreate />,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    OVERDRAFT_KEY.CREATE,
                    "termId"
                ),
                component: () => <OverdraftCreate />,
            },
            {
                path: generateChildPathname(
                    parentName,
                    OVERDRAFT_KEY.PREVIEW,
                    "overdraftId"
                ),
                component: OverdraftPreview,
            },
            {
                path: generateChildPathname(parentName, OVERDRAFT_KEY.DETAILS),
                component: OverdraftDetails,
            },
            {
                path: generateChildPathname(parentName, OVERDRAFT_KEY.EARLY),
                component: OverdraftClose,
            },
        ],
        [generateChildPathname]
    );

    return (
        <>
            <Banner
                title={
                    isOverDraftUser
                        ? t("overdraft:overdraft_dashboard_title")
                        : t("overdraft:overdraft_register_title")
                }
                helper
                helperTitle={t("overdraft:overdraft_review_faq")}
                onHelperChange={handleHelpChange}
            />
            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                                exact
                            />
                        );
                    })}
                </Switch>
            </ContentWrapper>
            <Content>
                {loading && (
                    <>
                        <Skeleton
                            height={100}
                            variant="rect"
                            style={{ marginBottom: 32 }}
                        />
                        <Skeleton height={250} variant="rect" />
                    </>
                )}
            </Content>
        </>
    );
};

export default memo(Overdraft);
