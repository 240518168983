import {
    Box,
    Checkbox,
    FormControlLabel,
    Icon,
    makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useNumber from "@helpers/useNumber";
import { useCallback, useEffect, useRef, useState } from "react";
import { RESPONSE_CODE } from "@config/constants";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import paymentRequestService from "@services/payment-request";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import moveMoneyService from "@services/move-money";
import useAccounts from "@helpers/useAccounts";
import useOTP from "@helpers/useOTP";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import UsingOverdraftWarningDialog from "@components/UsingOverdraftWarningDialog";
import useTransactionValidation from "@containers/SpendAccount/useTransactionValidation";
import OTPController from "@helpers/controllers/OTPController";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import LspTranslation from "@components/LspTranslation";

const useStyles = makeStyles((theme) => ({
    titlePayNow: {
        display: "flex",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.neutral.white,
        background: theme.palette.primary.main,
        width: "100%",
        justifyContent: "center",
        "& > span": {
            color: theme.palette.primary.main,
            borderRadius: theme.shape.radiusLevels[2],
            background: theme.palette.neutral.white,
            marginRight: theme.spacing(2),
        },
        height: "150px",
    },
    info: {
        width: "100%",
        padding: theme.spacing(3.75, 5),
        background: theme.palette.background.paper,
        maxWidth: "480px",
        textAlign: "left",
        margin: "0 auto",
        transform: "translateY(-30px)",
    },
    uppercase: {
        textTransform: "uppercase",
    },
    amountPayNow: {
        fontSize: theme.typography.pxToRem(30),
        padding: theme.spacing(2.75, 7),
        borderBottom: `1px solid ${theme.palette.divider}`,
        fontWeight: 400,
    },
    payNowBlock: {
        width: "100%",
        background: theme.palette.background.default,
    },
    moveMoneyBtn: {
        paddingBottom: theme.spacing(3),
        margin: "0 auto",
        width: "100%",
        maxWidth: "480px",
    },
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        fontSize: theme.typography.pxToRem(14),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
        "@media print": {
            display: "flex !important",
        },
        "& hr": {
            background: "transparent",
            borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
        },
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        padding: theme.spacing(3),
        fontSize: theme.typography.pxToRem(16),
        marginTop: theme.spacing(4),
    },
}));

const PAYNOW_PAGES = {
    PAYNOW: "PAYNOW",
    OVERDRAFT_WARNING: "OVERDRAFT_WARNING",
};

const PayNow = ({
    paymentSendInfo,
    maxAmountLimitPerTransfer,
    isPendingTask,
    onPaySuccessHandler,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { formatNumber } = useNumber();
    const { spendAccount } = useAccounts();
    const { isUsingOverdraftMoney } = useTransactionValidation();

    const [ckSavePayeeReceive, setCkSavePayeeReceive] = useState(false);
    const [paymentReceiveError, setPaymentReceiveError] = useState("");
    const [fetchingData, setFetchingData] = useState(false);
    const [isPayeeExist, setIsPayeeExist] = useState(false);
    const [payeeExists, setPayeeExists] = useState(false);
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [page, setPage] = useState(() => {
        const amount = paymentSendInfo?.amount;

        if (isUsingOverdraftMoney({ canUseOverdraft: true, amount })) {
            return PAYNOW_PAGES.OVERDRAFT_WARNING;
        }

        return PAYNOW_PAGES.PAYNOW;
    }, [isUsingOverdraftMoney]);

    const unmounted = useRef(false);
    const firstLoad = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const paySuccessfully = (transactionId) => {
        LspReceiptController.dialog(transactionId, () => {
            GlobalDialogController.hide();
        });
        onPaySuccessHandler();
    };

    const commitOtp = async (otp) => {
        const payload = {
            ...otp,
            id: paymentSendInfo.paymentRequestId,
            receiveId: paymentSendInfo.payeeRequestId,
        };
        setLoading(true);
        const { data } = await paymentRequestService.commitOTP(payload);
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.CREATED:
                closeOTPDialog();
                paySuccessfully(data.data?.txnId);
                break;
            case RESPONSE_CODE.INCORRECT_VALUE:
            case RESPONSE_CODE.MISMATCH_VALUE:
                AlertController.show(
                    <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                    AlertType.Error
                );
                break;
            default:
                closeOTPDialog();
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const fnCheckDestinationOfDefaultBank = useCallback(async () => {
        const dataToPost = {
            targetInfo: paymentSendInfo.accountNumberCreator,
            bankAccount: spendAccount.no,
            checkType: "timo",
        };
        const { data } = await paymentRequestService.checkCardNumber(
            dataToPost
        );
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setPayeeExists(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [paymentSendInfo.accountNumberCreator, spendAccount.no]);

    const onPayNow = useCallback(async () => {
        const dataToPost = {
            accountNumber: paymentSendInfo.accountNumberCreator,
        };
        const { data } = await moveMoneyService.checkPayeeDestinationExistence(
            dataToPost
        );

        switch (data.code) {
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_CURRENT_PAYEE: // 4101
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO: // 4102
                // This Payee already exists & has a Timo destination!
                setIsPayeeExist(true);
                setPayeeExists(true);
                break;
            case RESPONSE_CODE.DESTINATION_NOT_EXISTING: // 4100: account not found
                break;
            case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST: // 4103
                // This Payee already exists & not have Timo destination!
                setPayeeExists(true);
                fnCheckDestinationOfDefaultBank();
                break;
            case RESPONSE_CODE.SUCCESS: // available to add || Not have Timo Payee or Destination
                setIsPayeeExist(false);
                setPayeeExists(false);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });

                break;
        }
    }, [paymentSendInfo.accountNumberCreator, fnCheckDestinationOfDefaultBank]);

    const onMoveMoney = async () => {
        setFetchingData(true);
        const dataToPost = {
            id: paymentSendInfo.paymentRequestId,
            receiveId: paymentSendInfo.payeeRequestId,
            payNow: {
                isTimo: true,
                notification: "sms",
            },
        };

        if (ckSavePayeeReceive) {
            dataToPost.payNow.saveNewPayee = !payeeExists;
        } else {
            dataToPost.payNow.saveNewPayee = false;
        }
        const {
            data,
        } = await paymentRequestService.deleteReceivedPaymentRequest(
            dataToPost
        );

        if (unmounted.current) return;
        setFetchingData(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
            case RESPONSE_CODE.CREATED:
                paySuccessfully(data?.data?.transactionId);
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: commitOtp,
                });
                break;
            case RESPONSE_CODE.NOT_ALLOWED:
            case RESPONSE_CODE.UNDER_MIN_TRANSFER:
                setIsPayeeExist(true);
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: "payment_content_paynow_not_enough_money",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.TRANSFER_TO_YOURSELF:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: "payee_card_number_invalid_payee_is_payer",
                        button: t("lb_ok"),
                    },
                });
                break;

            case RESPONSE_CODE.OVER_ALLOWED_MAX_AMOUNT:
                setIsPayeeExist(true);
                setPaymentReceiveError(
                    t(
                        "spend_other_bank_amount_transfer_over_limit_msg_bankFlow"
                    ).replace("%@", formatNumber(maxAmountLimitPerTransfer))
                );
                break;
            case RESPONSE_CODE.FAST_CASH_REACH_LIMIT:
                setIsPayeeExist(true);
                setPaymentReceiveError(
                    t("payee_card_amount_submitted_invalid")
                );
                break;
            case RESPONSE_CODE.CRYPTO_RELATED_TRANSACTION:
                setIsPayeeExist(true);
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("lb_we_sorry"),
                        content: "crypto_related_msg",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                setIsPayeeExist(true);
                setPaymentReceiveError(t("ms_lb_transfer_daily_limit"));
                break;
            case RESPONSE_CODE.CREATOR_CANCELED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: "payment_reuquest_has_been_canceled",
                        button: t("lb_ok"),
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    // Init check on first time
    useEffect(() => {
        if (!firstLoad.current) {
            onPayNow();
            firstLoad.current = true;
        }
    }, [onPayNow]);

    useEffect(() => {
        if (isPendingTask) onPayNow();
    }, [isPendingTask, onPayNow]);

    const onCloseOverdraftWarning = () => {
        setPage(PAYNOW_PAGES.PAYNOW);
    };

    return (
        <>
            {page === PAYNOW_PAGES.PAYNOW && (
                <Box className={classes.wrapper}>
                    <Box p={4} pb={2} className={classes.titlePayNow}>
                        <Icon className="font-icon icon-move-money" />
                        <div>{t("payment_title_receive_confirm_paynow")}</div>
                    </Box>
                    <Box className={classes.payNowBlock}>
                        <Box className={classes.info}>
                            <Box
                                className={classes.amountPayNow}
                                textAlign="center"
                            >
                                {formatNumber(paymentSendInfo.amount)}
                            </Box>
                            <Box mt={2}>
                                <LspTypography
                                    variant="subheading4"
                                    color="black"
                                    className={classes.uppercase}
                                >
                                    {t("lb_to")}
                                </LspTypography>
                                <LspTypography
                                    variant="subheading4"
                                    color="grey"
                                >
                                    {paymentSendInfo.userSentName}
                                </LspTypography>
                            </Box>
                            <Box mt={2}>
                                <LspTypography
                                    variant="subheading4"
                                    color="black"
                                    className={classes.uppercase}
                                >
                                    {t("payee_bank_name_label")}
                                </LspTypography>
                                <LspTypography
                                    variant="subheading4"
                                    color="grey"
                                >
                                    {t("mm_label_bank_name_timo")}
                                </LspTypography>
                            </Box>
                            <Box mt={2}>
                                <LspTypography
                                    variant="subheading4"
                                    color="black"
                                    className={classes.uppercase}
                                >
                                    {t("lb_account_number")}
                                </LspTypography>
                                <LspTypography
                                    variant="subheading4"
                                    color="grey"
                                >
                                    {paymentSendInfo.accountNumberCreator}
                                </LspTypography>
                            </Box>
                            <Box mt={2}>
                                <LspTypography
                                    variant="subheading4"
                                    color="black"
                                    className={classes.uppercase}
                                >
                                    {t("payee_card_description_label")}
                                </LspTypography>
                                <LspTypography
                                    variant="subheading4"
                                    color="grey"
                                >
                                    {paymentSendInfo.reason ||
                                        t("global_move_money_default_desc")}
                                </LspTypography>
                            </Box>
                        </Box>
                        <Box style={{ minHeight: "60px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={ckSavePayeeReceive}
                                        onChange={(e) =>
                                            setCkSavePayeeReceive(
                                                e.target.checked
                                            )
                                        }
                                        color="secondary"
                                        inputProps={{
                                            datatestid:
                                                "payment-request-add-payee-checkbox",
                                        }}
                                        disabled={isPayeeExist}
                                    />
                                }
                                label={
                                    <LspTypography
                                        variant="heading3"
                                        disabled={isPayeeExist}
                                    >
                                        {t("payment_lb_save_payee_receive")}
                                    </LspTypography>
                                }
                            />
                        </Box>

                        <Box className={classes.moveMoneyBtn}>
                            {paymentReceiveError && (
                                <Box className={classes.error} mb={3}>
                                    {paymentReceiveError}
                                </Box>
                            )}
                            <LspButton
                                variant="primary"
                                progressing={fetchingData}
                                fullWidth
                                onClick={onMoveMoney}
                                buttonProps={{
                                    datatestid:
                                        "payment-request-move-money-btn",
                                }}
                            >
                                {t("lb_move_money")}
                            </LspButton>
                        </Box>
                    </Box>
                </Box>
            )}
            {page === PAYNOW_PAGES.OVERDRAFT_WARNING && (
                <UsingOverdraftWarningDialog
                    onClose={onCloseOverdraftWarning}
                />
            )}
        </>
    );
};

const mapState = (state) => ({
    maxAmountLimitPerTransfer:
        state.systemParams.info.conf.MaxAmountLimitPerTransfer,
});

export default connect(mapState)(PayNow);
