import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
    LspDialogNoHeaderContent,
    LspDialogNoHeaderTitle,
} from "@components/LspNoHeaderDialog";
import { LspDialogActions } from "@components/LspDialog";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    link: {
        marginTop: theme.spacing(2),
    },
    image: {
        width: 190,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

const PendingTaskChipCardInfo = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const cardImg = `${process.env.REACT_APP_API_URL}/${t(
        "sysParamContent:DefaultCardUrl"
    )}`;

    const moreInfo = () => {
        window.open(t("master:learn_more_link"), "_blank");
    };

    return (
        <Content>
            <LspDialogNoHeaderTitle>
                <div>{t("master:about_chip_card_title")}</div>
            </LspDialogNoHeaderTitle>
            <LspDialogNoHeaderContent>
                <img
                    className={classes.image}
                    src={cardImg}
                    alt="credit card"
                />
                <LspTypography variant="body2">
                    {t("master:about_chip_card_subtitle")}
                </LspTypography>
                <ButtonBase onClick={moreInfo} className={classes.link}>
                    <LspTypography variant="hyperlink1" color="success">
                        {t("master:lb_learn_more")}
                    </LspTypography>
                </ButtonBase>
            </LspDialogNoHeaderContent>
            <LspDialogActions>
                <LspButton type="submit" fullWidth onClick={onClose}>
                    {t("mc_installment_introduce_footer")}
                </LspButton>
            </LspDialogActions>
        </Content>
    );
};

export default memo(PendingTaskChipCardInfo);
