import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moveMoneyService from "@services/move-money";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useAccounts from "@helpers/useAccounts";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import AlertController from "@helpers/controllers/AlertController";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { Skeleton } from "@material-ui/lab";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "./LspDialog";
import { RESPONSE_CODE } from "../config/constants";
import DoubleButtons from "./DoubleButtons";
import LspTextField from "./LspTextField";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "auto",
    },
    fetchingInput: {
        borderRadius: theme.shape.borderRadius,
    },
    fetchingText: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

/**
 * @param {Object} props
 * @param {string} [props.accountNumber]
 * @param {function} [props.onCancel]
 * @param {function} [props.onSuccess]
 */

const AddPayee = ({ accountNumber, onCancel, onSuccess }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [fetching, setFetching] = useState(true);
    const { spendAccount } = useAccounts();
    const [payeeName, setPayeeName] = useState("");
    const [payeeInfo, setPayeeInfo] = useState(null);
    const [error, setError] = useState("");

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const addDestination = useCallback(
        async ({ payeeId, nickName }) => {
            const payload = {
                payeeId: parseInt(payeeId),
                destinations: [
                    {
                        desType: 1,
                        accountName: payeeInfo?.accountName,
                        accountNumber: payeeInfo?.accountNumber,
                        bankId: payeeInfo?.bankId,
                        bankShortName: payeeInfo?.bankShortName,
                        bankName: payeeInfo?.bankName,
                        description: payeeInfo?.bankShortName,
                    },
                ],
            };

            const response = await moveMoneyService.addDestination(payload);
            if (unmounted.current) {
                return;
            }

            const { code } = response.data;
            setSubmitting(false);

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                case RESPONSE_CODE.CREATED:
                    AlertController.show(
                        t("payee_msg_add_payee_to_list_success").replace(
                            "%@",
                            nickName
                        )
                    );
                    if (isFunction(onSuccess)) {
                        onSuccess();
                    }
                    break;
                case RESPONSE_CODE.DUPLICATED_PAYEE:
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [payeeInfo, t, onSuccess]
    );

    const addPayee = useCallback(
        async ({ accountName, nickName }) => {
            const payload = {
                nickName,
                fullName: accountName,
                avatar: "",
                company: "",
                phoneNos: [],
                emails: [],
            };
            const response = await moveMoneyService.addPayee(payload);
            if (unmounted.current) {
                return;
            }

            const { code, data } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    addDestination({
                        payeeId: data.payeeId,
                        nickName,
                    });
                    break;
                case RESPONSE_CODE.DUPLICATED_PAYEE:
                default:
                    setSubmitting(false);
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [addDestination]
    );

    const checkExistingPayee = useCallback(
        async (e) => {
            e.preventDefault();
            setSubmitting(true);

            const payload = {
                accountNumber: payeeInfo?.accountNumber,
                payeeName: escapeAccentVietnamese(payeeName),
            };

            const response = await moveMoneyService.checkPayeeDestinationExistence(
                payload
            );
            if (unmounted.current) {
                return;
            }

            const { code } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    addPayee({
                        accountName: payeeInfo?.accountName,
                        accountNumber: payeeInfo?.accountNumber,
                        nickName: escapeAccentVietnamese(payeeName),
                        bankId: payeeInfo?.bankId,
                    });
                    break;
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_CURRENT_PAYEE:
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST_WITH_TIMO:
                case RESPONSE_CODE.DESTINATION_EXISTING_IN_PAYEE_LIST:
                    setSubmitting(false);
                    setError(t("payee_msg_payee_name_exist"));
                    break;
                case RESPONSE_CODE.DESTINATION_NOT_EXISTING: // account not found, it will be bank transfer
                default:
                    setSubmitting(false);
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [payeeInfo, payeeName, t, addPayee]
    );

    const getPayeeInfo = useCallback(
        async (accNumber) => {
            const payload = {
                bankAccount: spendAccount.no,
                checkType: "timo",
                targetInfo: accNumber,
            };
            const response = await moveMoneyService.getInternalAccountDetail(
                payload
            );

            if (unmounted.current) {
                return;
            }

            setFetching(true);

            const { code, data } = response.data;

            setFetching(false);

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setPayeeInfo(data?.bank);
                    setPayeeName(data?.bank?.accountName);
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [spendAccount.no]
    );

    useEffect(() => {
        getPayeeInfo(accountNumber);
    }, [accountNumber, getPayeeInfo]);

    const payeeNameOnChange = (e) => {
        const { value } = e.target;
        setPayeeName(value);
        if (!value) {
            setError(t("msg_we_need_this"));
        } else {
            setError("");
        }
    };

    const payeeFetchingRender = () => {
        return (
            <>
                <Skeleton
                    variant="rect"
                    height={50}
                    className={classes.fetchingInput}
                />
                <Skeleton width={250} className={classes.fetchingText} />
                <Skeleton
                    variant="rect"
                    height={50}
                    className={classes.fetchingInput}
                />
                <Skeleton width={200} className={classes.fetchingText} />
            </>
        );
    };

    const payeeRender = () => {
        return (
            <>
                <LspTextField
                    autoFocus
                    name="accountNumber"
                    label={t("lb_account_number")}
                    helperText={`${payeeInfo?.accountName || ""} | Timo`}
                    value={accountNumber}
                    inputProps={{
                        readOnly: true,
                    }}
                />
                <LspTextField
                    autoFocus
                    error={!!error}
                    name="payeeName"
                    value={payeeName}
                    label={t("spend_move_money_hint_enterName_save_money_list")}
                    helperText={
                        error || t("payee_txt_tap_to_change_payee_name")
                    }
                    inputProps={{
                        maxLength: 70,
                    }}
                    onChange={payeeNameOnChange}
                />
            </>
        );
    };

    return (
        <form className={classes?.wrapper}>
            <LspDialogTitle>
                <div>
                    <Icon className="font-icon icon-savepayee" />
                </div>
                <div>{t("payee_title_save_payee_to_list")}</div>
            </LspDialogTitle>
            <LspDialogContent>
                {fetching && payeeFetchingRender()}
                {!fetching && payeeRender()}
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    disabled={!payeeName || fetching}
                    progressing={submitting}
                    primaryButton={{
                        label: t("payee_lb_add_payee"),
                        onClick: checkExistingPayee,
                    }}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: onCancel,
                    }}
                />
            </LspDialogActions>
        </form>
    );
};

AddPayee.propTypes = {
    accountNumber: PropTypes.string,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
};

AddPayee.defaultProps = {
    accountNumber: "",
    onSuccess: undefined,
    onCancel: undefined,
};

export default AddPayee;
