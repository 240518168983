import { memo, useEffect, useCallback, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import wealthService from "@services/wealth";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import wealthCreditCardAction from "@redux/actions/wealth-credit-card";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import { WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS } from "../../wealthConstants";
import WealthCreditCardMethodSelectionsItem from "./WealthCreditCardMethodSelectionsItem";
import WealthCreditCardMethodSelectionsFetching from "./WealthCreditCardMethodSelectionsFetching";

const WealthCreditCardMethodSelections = ({
    onBack,
    onNext,
    subProducts,
    setSubProducts,
}) => {
    const { t } = useTranslation();
    const [content, setContent] = useState([]);
    const [fetching, setFetching] = useState(false);

    const unmounted = useRef(false);
    const firstLoad = useRef(true);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getSubProductList = useCallback(async () => {
        setFetching(true);
        const response = await wealthService.getSubProducts(
            WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.NON_QUALIFIED
        );

        if (unmounted.current) return;

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setSubProducts(data.subProducts);
                setFetching(false);
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: code,
                });
                break;
        }
    }, [setSubProducts]);

    useEffect(() => {
        if (!subProducts && firstLoad.current) {
            getSubProductList();
            firstLoad.current = false;
        }
    }, [getSubProductList, subProducts]);

    useEffect(() => {
        if (subProducts) {
            const contents = [];

            for (let product = 0; product < subProducts.length; product++) {
                const proKey = subProducts[product].productKey;
                switch (proKey) {
                    case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.MINIMUM_BALANCE:
                        contents.push({
                            icon: "spendaccounticon",
                            title: t("wealth:mc_v3_how_method1_title"),
                            content: t("wealth:mc_v3_how_method1_msg"),
                            proKey,
                        });
                        break;
                    case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.SECURE_CARD:
                        contents.push({
                            icon: "open-newtd",
                            title: t("wealth:mc_v3_how_method2_title"),
                            content: t("wealth:mc_v3_how_method2_msg"),
                            proKey,
                        });
                        break;
                    case WEALTH_CREDIT_CARD_SUB_PRODUCT_KEYS.ONE_FOR_ONE_CARD:
                        contents.push({
                            icon: "for1",
                            title: t("wealth:mc_v3_how_method3_title"),
                            content: t("wealth:mc_v3_how_method3_msg"),
                            proKey,
                        });
                        break;
                    default:
                        break;
                }
            }
            setContent(contents);
        }
    }, [subProducts, t]);

    return (
        <>
            <PageHeaderBack onBack={onBack}>
                {t("wealth:mc_v3_how_title")}
            </PageHeaderBack>
            {fetching && <WealthCreditCardMethodSelectionsFetching />}
            {!fetching && (
                <div>
                    {content?.map((item) => {
                        return (
                            <WealthCreditCardMethodSelectionsItem
                                key={item.proKey}
                                item={item}
                                onClick={onNext}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

const mapState = (state) => ({
    subProducts: state.wealthCreditCard.subProductList,
});

const mapDispatch = (dispatch) => ({
    setSubProducts: (payload) =>
        dispatch(wealthCreditCardAction.setSubProductList(payload)),
});

export default memo(
    connect(mapState, mapDispatch)(WealthCreditCardMethodSelections)
);
