import LspDataDropdown from "@components/LspDataDropdown";
import { DATA_DROPDOWN_TYPES } from "@config/constants";
import { memo } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";

/**
 * @param {object} props
 * @param {object} props.selectedProvince
 * @param {func} props.onChangeProvince*
 * @param {object} props.selectedDistrict
 * @param {func} props.onChangeDistrict *
 * @param {object} props.selectedWard
 * @param {func} props.onChangeWard
 * @param {object} props.errors
 */

const defaultValue = {
    province: {
        cfgValue: "",
        cfgKey: "",
    },
    district: {
        cfgValue: "",
        cfgKey: "",
    },
    ward: {
        cfgValue: "",
        cfgKey: "",
    },
};

const useStyles = makeStyles(
    (theme) => ({
        wrapper: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                justifyContent: "space-between",
            },
            "& .MuiBox-root": {
                [theme.breakpoints.up("sm")]: {
                    marginRight: theme.spacing(2),
                    "&:last-child": {
                        marginRight: 0,
                    },
                },
            },
        },
        fullWidthWrapper: {
            flexDirection: "column",
        },
        halfWidthWrapper: {
            flexWrap: "wrap",
            "& .MuiBox-root": {
                [theme.breakpoints.up("sm")]: {
                    "&:first-child": {
                        marginRight: 0,
                    },
                },
            },
        },
        halfWidth: {
            width: "calc(50% - 8px)",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        fullWidth: {
            width: "100%",
        },
    }),
    []
);

const LspAddress = ({
    selectedProvince,
    onChangeProvince,
    selectedDistrict,
    onChangeDistrict,
    selectedWard,
    onChangeWard,
    errors,
    submitting,
    fullWidth,
    isHideWard,
    disabledAll,
    halfWidth,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onChangeWardHandler = (ward) => {
        if (!isHideWard) {
            onChangeWard(ward);
        }
    };

    const onChangeDistrictHandler = (district) => {
        onChangeWardHandler(defaultValue.ward);
        onChangeDistrict(district);
    };
    const onChangeProvinceHandler = (province) => {
        onChangeDistrictHandler(defaultValue.district);
        onChangeProvince(province);
    };

    return (
        <Box
            className={clsx(classes.wrapper, {
                [classes.fullWidthWrapper]: fullWidth,
                [classes.halfWidthWrapper]: halfWidth,
            })}
        >
            <Box className={classes.fullWidth}>
                <LspDataDropdown
                    selectedData={selectedProvince || defaultValue.province}
                    onChangeData={onChangeProvinceHandler}
                    typeData={DATA_DROPDOWN_TYPES.PROVINCE}
                    labelData={t("province_city_lb")}
                    error={errors.province}
                    disabled={submitting || disabledAll}
                />
            </Box>

            <Box
                className={clsx(null, {
                    [classes.halfWidth]: halfWidth,
                    [classes.fullWidth]: !halfWidth,
                })}
            >
                <LspDataDropdown
                    selectedData={selectedDistrict || defaultValue.district}
                    onChangeData={onChangeDistrictHandler}
                    typeData={DATA_DROPDOWN_TYPES.DISTRICT}
                    labelData={t("lb_district")}
                    parentId={selectedProvince?.cfgKey}
                    disabled={
                        !selectedProvince?.cfgKey || submitting || disabledAll
                    }
                    pendingLoad={!selectedProvince?.cfgKey}
                    error={errors.district}
                />
            </Box>

            {!isHideWard && (
                <Box
                    className={clsx(null, {
                        [classes.halfWidth]: halfWidth,
                        [classes.fullWidth]: !halfWidth,
                    })}
                >
                    <LspDataDropdown
                        selectedData={selectedWard || defaultValue.ward}
                        onChangeData={onChangeWardHandler}
                        typeData={DATA_DROPDOWN_TYPES.WARD}
                        labelData={t("lb_ward")}
                        parentId={selectedDistrict?.cfgKey}
                        disabled={
                            !selectedDistrict?.cfgKey ||
                            submitting ||
                            disabledAll
                        }
                        pendingLoad={!selectedDistrict?.cfgKey}
                        error={errors.ward}
                    />
                </Box>
            )}
        </Box>
    );
};

LspDataDropdown.propTypes = {
    selectedProvince: PropTypes.shape({
        cfgKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cfgValue: PropTypes.string,
    }),
    onChangeProvince: PropTypes.func,
    selectedDistrict: PropTypes.shape({
        cfgKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cfgValue: PropTypes.string,
    }),
    onChangeDistrict: PropTypes.func,
    selectedWard: PropTypes.shape({
        cfgKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cfgValue: PropTypes.string,
    }),
    onChangeWard: PropTypes.func,
    errors: PropTypes.shape({
        province: PropTypes.string,
        district: PropTypes.string,
        ward: PropTypes.string,
    }),
    fullWidth: PropTypes.bool,
    isHideWard: PropTypes.bool,
    disabledAll: PropTypes.bool,
    halfWidth: PropTypes.bool,
};

LspDataDropdown.defaultProps = {
    selectedProvince: defaultValue.province,
    selectedDistrict: defaultValue.district,
    selectedWard: defaultValue.ward,
    errors: {
        province: "",
        district: "",
        ward: "",
    },
    fullWidth: false,
    isHideWard: false,
    disabledAll: false,
    halfWidth: false,
};

export default memo(LspAddress);
