import api from "@config/api";
import axios from "axios";

const getContent = async ({ url, isFullLink }) => {
    if (isFullLink) {
        return axios.get(url);
    }
    return await api.get(url, null);
};

const getAttachment = async ({ url, isFullLink, token }) => {
    if (isFullLink) {
        return await axios.get(url, {
            headers: { token },
        });
    }
    return await api.get(url, null);
};

const contentService = {
    getContent,
    getAttachment,
};

export default contentService;
