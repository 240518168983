import { REGULATION_TYPE } from "@config/constants";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import GlobalDialogController from "./controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    wrapperTD: {
        "& ul": {
            paddingLeft: theme.spacing(2),
        },
        "& > div:nth-child(2)": {
            textAlign: "justify",
        },
    },
}));

const useForeignerDialog = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getDialogInfo = useCallback(
        (key, isNotMarkdownContent) => {
            const info = {
                iconImage: "Warning",
                header: t(`regulation:${key}title`),
                button: t(`regulation:${key}btn`) || "",
            };
            if (isNotMarkdownContent) {
                info.content = t(`regulation:${key}content`);
            } else {
                info.markdownContent = t(`regulation:${key}content`);
            }

            return info;
        },
        [t]
    );

    const reminderForGSPage = useCallback(() => {
        const popupInfo = t(`regulation:gs_notice_popup`, {
            returnObjects: true,
            defaultValue: "",
        });
        const info = {
            iconImage: "Warning",
            header: popupInfo[0] || "",
            markdownContent: popupInfo[1] || "",
            button: popupInfo[2] || "",
        };
        // return info;
        return { ...info, wrapperClasses: classes.wrapperTD };
    }, [t, classes]);

    const gsWarningForCannotContribute = useCallback(() => {
        const key = "gs_error_";
        const info = getDialogInfo(key, true);
        info.linkContent = {
            label: t("regulation:foreigner_warning_read_more"),
            link: t("regulation:foreigner_warning_read_more_link"),
        };
        return info;
    }, [t, getDialogInfo]);

    const tdReminderForeigner = useCallback(() => {
        const key = "td_reg_change_";
        const info = getDialogInfo(key, false);
        return { ...info, wrapperClasses: classes.wrapperTD };
    }, [getDialogInfo, classes]);

    const tdErrorForCannotOpenTD = useCallback(() => {
        const info = {
            iconImage: "Warning",
            header: t("regulation:gs_error_title"),
            content: t("regulation:td_error_content"),
            button: "regulation:gs_error_btn",
            linkContent: {
                label: t("regulation:foreigner_warning_read_more"),
                link: t("regulation:foreigner_warning_read_more_link"),
            },
        };
        return info;
    }, [t]);

    const dialog = useCallback(
        (type) => {
            let info = null;
            switch (type) {
                case REGULATION_TYPE.GS_PAGE:
                    info = reminderForGSPage();
                    break;
                case REGULATION_TYPE.GS_CANNOT_CONTRIBUTE:
                    info = gsWarningForCannotContribute();
                    break;
                case REGULATION_TYPE.TD_PAGE:
                    info = tdReminderForeigner();
                    break;
                case REGULATION_TYPE.TD_CANNOT_OPEN:
                    info = tdErrorForCannotOpenTD();
                    break;
                default:
                    break;
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: info,
            });
        },
        [
            reminderForGSPage,
            gsWarningForCannotContribute,
            tdErrorForCannotOpenTD,
            tdReminderForeigner,
        ]
    );

    return { dialog };
};

export default useForeignerDialog;
