/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-typos */
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Interweave from "interweave";
import { memo, useCallback, useEffect, useState } from "react";
import { TERM_CONDITION_TYPES } from "@config/constants";
import { isArray } from "lodash";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import TermConditionDialog from "./TermConditionDialog";

const useStyles = makeStyles((theme) => ({
    wrapperTermCondition: {
        width: "100%",
        "& a": {
            color: theme.palette.status.success,
            fontWeight: 600,
            cursor: "pointer",
        },
    },
}));

/**
 * @param {object} props
 * @param {string} props.content
 * @param {array} props.keyList
 * @param {string} props.type
 */

const TermCondition = ({
    content,
    keyList,
    type = TERM_CONDITION_TYPES.DIALOG,
}) => {
    const classes = useStyles();
    const [termContent, setTermContent] = useState("");

    const openPopup = useCallback((header, urlContent) => {
        GlobalDialogController.show({
            size: "md",
            component: () => (
                <TermConditionDialog
                    header={header}
                    url={urlContent}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    }, []);

    const mapContent = useCallback(
        ({ key, label, url, baseContent }) => {
            let tmpUrl = "";

            if (type === TERM_CONDITION_TYPES.DIALOG) {
                tmpUrl = `<a id="term-dialog-${key}" class="term-dialog" >${label}</a>`;
            } else {
                const href =
                    url.indexOf("http") > -1
                        ? url
                        : process.env.REACT_APP_API_URL + url;
                tmpUrl = `<a href=" ${href} " target="_blank" >${label}</a>`;
            }

            return baseContent.replace(key, tmpUrl);
        },
        [type]
    );

    const processContent = useCallback(() => {
        if (!content || !keyList) {
            console.log(
                "May be you miss [content] or [keyList] params, please check it again!!!"
            );
            return;
        }

        if (isArray(keyList)) {
            let isHaveOneItem = true;

            // Multiple keys
            if (isArray(keyList[0])) {
                isHaveOneItem = false;
            }
            let finalText = "";

            if (isHaveOneItem) {
                finalText = mapContent({
                    key: keyList[0],
                    label: keyList[1],
                    url: keyList[2],
                    baseContent: content,
                });
            } else {
                for (let i = 0; i < keyList.length; i++) {
                    const item = keyList[i];
                    finalText = mapContent({
                        key: item[0],
                        label: item[1],
                        url: item[2],
                        baseContent: finalText || content,
                    });
                }
            }
            setTermContent(finalText);
        } else {
            console.log(
                "[keyList] is wrong format, it should be array with [keyName, label, url]"
            );
        }
    }, [content, keyList, mapContent]);

    const onClickTermConditionHandler = useCallback(
        (e) => {
            e.preventDefault();
            const part = e.target.id.split("-");
            const key = part[part.length - 1];
            const dialogInfo = keyList.find((item) => item[0] === key);
            if (dialogInfo) {
                openPopup(dialogInfo[1], dialogInfo[2]);
            }
        },
        [keyList, openPopup]
    );

    useEffect(() => {
        processContent();
    }, [processContent]);

    useEffect(() => {
        if (!termContent) return;
        const elems = document.querySelectorAll(".term-dialog");

        for (let i = elems.length; i--; ) {
            elems[i].addEventListener(
                "click",
                onClickTermConditionHandler,
                false
            );
        }

        return () => {
            for (let i = elems.length; i--; ) {
                elems[i].removeEventListener(
                    "click",
                    onClickTermConditionHandler
                );
            }
        };
    }, [termContent, onClickTermConditionHandler]);

    return (
        <div className={classes.wrapperTermCondition}>
            <Interweave content={termContent} />
        </div>
    );
};

TermCondition.propTypes = {
    content: PropTypes.string.isRequired,
    keyList: PropTypes.array.isRequired,
    type: PropTypes.oneOf([
        TERM_CONDITION_TYPES.DIALOG,
        TERM_CONDITION_TYPES.NEW_TAB,
    ]),
};

TermCondition.defaultProps = {
    // content: null,
    // keyList: null,
    type: TERM_CONDITION_TYPES.DIALOG,
};

// How to use
// const termContent = {
//     content:
//         "Bạn cần đọc, nghiên cứu kỹ và xác nhận đồng ý với $KEY.43.02 Chương trình trả góp cho giao dịch bằng Thẻ tín dụng tại $KEY.43.01 về phát hành và sử dụng Thẻ tín dụng Timo Life.",
//     keyList: [
//         "$KEY.43.02",
//         "Điều khoản và Điều kiện",
//         "/cxpassets/contents/tnc/T&C_installment.html",
//     ],
//     keyList: [
//         [
//             "$KEY.43.01",
//             "Điều kiện giao dịch chung",
//             "https://timo.vn/tnc/Installment_VN.html",
//         ],
//         [
//             "$KEY.43.02",
//             "Điều khoản và Điều kiện",
//             "/cxpassets/contents/tnc/T&C_installment.html",
//         ],
//     ],
// };

// <TermCondition
// content={termContent.content}
// keyList={termContent.keyList}
// type="NEW_TAB"
// />

export default memo(TermCondition);
