import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import {
    LspDialogNoHeaderContent,
    LspDialogNoHeaderTitle,
} from "@components/LspNoHeaderDialog";
import { LspDialogActions } from "@components/LspDialog";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
    },
}));

const EditReferralCode = ({
    referralCode,
    onClose,
    onConfirmSaveReferralCode,
    error,
    onChangeRefCode,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const refCodeMaxLength = 20;

    const onConfirm = (e) => {
        e.preventDefault();
        onConfirmSaveReferralCode();
    };

    return (
        <form onSubmit={onConfirm} className={classes.root}>
            <LspDialogNoHeaderTitle>
                <LspTypography variant="heading1">
                    {t("lb_edit_referral_code")}
                </LspTypography>
            </LspDialogNoHeaderTitle>
            <LspDialogNoHeaderContent>
                <LspTextField
                    autoFocus
                    error={!!error}
                    name="referralCode"
                    label={t("master:referral_code")}
                    helperText={
                        error ||
                        t("creditCard:character_remain").replace(
                            "%@",
                            refCodeMaxLength - referralCode.length
                        )
                    }
                    inputProps={{
                        maxLength: refCodeMaxLength,
                    }}
                    onChange={onChangeRefCode}
                    value={referralCode}
                />
            </LspDialogNoHeaderContent>
            <LspDialogActions>
                <LspButton variant="secondary" onClick={onClose} fullWidth>
                    {t("lb_cancel")}
                </LspButton>
                <LspButton
                    variant="primary"
                    fullWidth
                    type="submit"
                    disabled={!referralCode}
                >
                    {t("master:lb_save_code")}
                </LspButton>
            </LspDialogActions>
        </form>
    );
};

export default EditReferralCode;
