/* eslint-disable prefer-destructuring */
import { LANGUAGE_OPTIONS } from "@config/constants";
import i18n from "@i18n/";
import { format } from "date-fns";
import { groupBy } from "lodash";

const groupNotificationByDate = (list) => {
    if (!Array.isArray(list) || list.length === 0) {
        return list;
    }
    const groupedList = groupBy(list, "createdDate");
    const mappedList = [];

    const loopList = Object.entries(groupedList);

    for (let group = 0; group < loopList.length; group++) {
        const currentGroup = loopList[group];
        let date;
        let FEDate;
        let childList;

        for (let item = 0; item < currentGroup.length; item++) {
            date = currentGroup[0];
            FEDate =
                i18n.language === LANGUAGE_OPTIONS.EN
                    ? format(new Date(date), "dd/MM/yyyy")
                    : date;
            childList = currentGroup[1];
        }

        mappedList.push({
            id: FEDate.split("/").join(""),
            date: FEDate,
            originalDate: date,
            list: childList,
        });
    }

    return mappedList;
};

export default groupNotificationByDate;
