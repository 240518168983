import { memo, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import { SpendAccountNavigationKey } from "@config/constants";
import PendingPaymentList from "@containers/SpendAccount/PendingPayment/PendingPaymentList";
import PaymentDetailPage from "@containers/SpendAccount/PendingPayment/PaymentDetailPage";

const parentName = `spend-account/${SpendAccountNavigationKey.PendingPayment}`;

const PendingPayment = () => {
    const { generateChildPathname } = usePathname();

    const childRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    "spend-account",
                    SpendAccountNavigationKey.PendingPayment
                ),
                component: PendingPaymentList,
            },
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.PaymentDetail,
                    "txnId"
                ),
                component: PaymentDetailPage,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoute = useMemo(() => childRoutes[0], [childRoutes]);

    return (
        <Switch>
            {childRoutes.map((r) => (
                <Route
                    exact
                    key={r.path}
                    path={r.path}
                    component={r.component}
                />
            ))}
            <Route>
                <Redirect to={defaultRoute.path} />
            </Route>
        </Switch>
    );
};

export default memo(PendingPayment);
