/* eslint-disable react/no-array-index-key */
import { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Banner from "@components/Banner";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    Box,
    Icon,
    makeStyles,
    Paper,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import LspButton from "@components/LspButton";
import userService from "@services/user";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import Loading from "@components/Loading";
import CardItem from "./CardItem";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    leftSide: {
        marginTop: theme.spacing(4),
        width: "60%",
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginBottom: theme.spacing(4),
        },
    },
    rightSide: {
        flex: 1,
        minHeight: "calc(100vh - (160px))",
        "& > div": {
            height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 4),
        },
    },
    title: {
        background: theme.palette.primary.main,
        textAlign: "center",
        padding: theme.spacing(2, 0),
    },
    body: {
        padding: theme.spacing(2, 4),
    },
    top: {
        background: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "200px",
        "& > span": {
            color: theme.palette.black.main,
            width: 40,
            height: 40,
            borderRadius: "50%",
            background: theme.palette.neutral.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: theme.spacing(2),
        },
    },
    list: {
        maxWidth: 400,
        margin: "0 auto",
        position: "relative",
        top: -40,
        padding: theme.spacing(0, 1.5),
    },
    card: {
        border: `1px solid ${theme.palette.neutral.grey3}`,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
        textAlign: "center",
        "& > span": {
            display: "block",
            margin: theme.spacing(2, 0),
        },
    },
    italic: {
        fontStyle: "italic",
    },
    loading: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Reward = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [rewardList, setRewardList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingVoucherId, setLoadingVoucherId] = useState("");
    const theme = useTheme();

    const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getRewardsList = useCallback(async () => {
        setLoading(true);
        const { data } = await userService.getListReward();
        if (unmounted.current) {
            return;
        }
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                // data.data = [
                //     {
                //         campaignId: 51,
                //         campaignFrom: "27/02/2019",
                //         campaignTo: "27/04/2019",
                //         campaignCover:
                //             "https://app.timo.vn/cxpassets/frontend/images/Shopee_100K_reward.png",
                //         voucherId: 1237,
                //         voucherCode: "https://duybeo.com",
                //         status: "picked",
                //         redeemTo: "20/05/2019",
                //         sendEmail: 0,
                //     },
                //     {
                //         campaignId: 52,
                //         campaignFrom: "27/02/2019",
                //         campaignTo: "27/04/2019",
                //         campaignCover:
                //             "https://app.timo.vn/cxpassets/frontend/images/Shopee_100K_reward.png",
                //         voucherId: 1238,
                //         voucherCode: "https://duybeo.com",
                //         status: "picked",
                //         visitSite: "https://shopee.vn/search?keyword=shoppe",
                //         redeemTo: "20/05/2019",
                //         sendEmail: 1,
                //     },
                //     {
                //         campaignId: 542,
                //         campaignFrom: "27/02/2019",
                //         campaignTo: "27/04/2019",
                //         campaignCover:
                //             "https://app.timo.vn/cxpassets/frontend/images/Shopee_100K_reward.png",
                //         voucherId: 12348,
                //         voucherCode: "https://duybeo.com",
                //         status: "picked",
                //         visitSite: "https://shopee.vn/search?keyword=shoppe",
                //         redeemTo: "20/05/2019",
                //         sendEmail: 1,
                //     },
                //     {
                //         campaignId: 52,
                //         campaignFrom: "27/02/2019",
                //         campaignTo: "27/04/2019",
                //         campaignCover:
                //             "https://app.timo.vn/cxpassets/frontend/images/Shopee_100K_reward.png",
                //         voucherId: 1238,
                //         voucherCode: "https://duybeo.com",
                //         status: "used",
                //         visitSite: "https://shopee.vn/search?keyword=shoppe",
                //         redeemTo: "20/05/2019",
                //         sendEmail: 2,
                //     },
                // ];
                setRewardList(data.data);
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    }, []);

    const trackUserGetVoucher = async (voucherId) => {
        setLoadingVoucherId(voucherId);
        const { data } = await userService.trackUserGetVoucher(voucherId);
        if (unmounted.current) {
            return;
        }
        setLoadingVoucherId("");
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                getRewardsList();
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const sendVoucherToEmail = async (voucherId) => {
        setLoadingVoucherId(voucherId);
        const { data } = await userService.sendVoucherCodeByEmail(voucherId);
        if (unmounted.current) {
            return;
        }
        setLoadingVoucherId("");
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                getRewardsList();
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const goToPrivileges = useCallback(() => {
        const url = t("sysParamContent:DealUrl");
        window.open(url, "_blank");
    }, [t]);

    useEffect(() => {
        getRewardsList();
    }, [getRewardsList]);

    return (
        <>
            <Banner title={t("wealth:OV00031").toUpperCase()} />
            <div className={classes.container}>
                <div className={classes.leftSide}>
                    <Content size={isDownSm ? "xl" : "md"}>
                        <Paper>
                            <div className={classes.title}>
                                <LspTypography variant="heading1" color="white">
                                    {t("wealth:OV00034")}
                                </LspTypography>
                            </div>
                            <div className={classes.body}>
                                <LspTypography variant="body1" color="black">
                                    {t("wealth:OV00035")}
                                </LspTypography>
                                <Box my={2}>
                                    <LspTypography
                                        variant="heading2"
                                        color="purple"
                                    >
                                        {t("wealth:OV00036")}
                                    </LspTypography>
                                </Box>
                                <LspTypography
                                    variant="body1"
                                    color="blue"
                                    component="a"
                                    href={t("wealth:OV00050")}
                                    target="_blank"
                                    className={classes.italic}
                                >
                                    {t("wealth:OV00037")}
                                </LspTypography>
                            </div>
                        </Paper>
                    </Content>
                </div>
                <div className={classes.rightSide}>
                    <Paper>
                        {loading ? (
                            <div className={classes.loading}>
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <div className={classes.top}>
                                    <Icon className="font-icon icon-deal" />
                                    <LspTypography
                                        variant="body1"
                                        color="white"
                                    >
                                        {t("wealth:OV00032")}
                                    </LspTypography>
                                </div>
                                <div className={classes.list}>
                                    {rewardList.length > 0 ? (
                                        rewardList?.map((item, key) => (
                                            <CardItem
                                                item={item}
                                                key={key}
                                                trackUserGetVoucher={
                                                    trackUserGetVoucher
                                                }
                                                sendVoucherToEmail={
                                                    sendVoucherToEmail
                                                }
                                                loadingVoucherId={
                                                    loadingVoucherId
                                                }
                                            />
                                        ))
                                    ) : (
                                        <Paper className={classes.card}>
                                            <LspTypography
                                                variant="heading2"
                                                color="purple"
                                            >
                                                {t("wealth:OV00038")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="black"
                                                component="span"
                                            >
                                                {t("wealth:OV00039")}
                                            </LspTypography>
                                            <LspButton
                                                fullWidth
                                                onClick={goToPrivileges}
                                            >
                                                {t("wealth:OV00040")}
                                            </LspButton>
                                        </Paper>
                                    )}
                                </div>
                            </>
                        )}
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default Reward;
