import { FetchStatus } from "@config/constants";
import { VinaWealthActionType } from "@redux/actions/vina-wealth";
import produce from "immer";

/* ------------- Initial State ------------- */
const initialState = {
    productDetail: null,
    amount: "",
    infoReceipt: null,
    passportInfo: null,
    congratulationFailed: false,
    pendingTransaction: {
        fetching: false,
        status: null,
        list: [],
    },
    completedTransaction: {
        fetching: false,
        status: null,
        list: [],
        lastId: -1,
        isLoadMore: true,
    },
    isEmptyTransaction: false,
};

/* ------------- Reducer ------------- */
const vinaWealthReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case VinaWealthActionType.SetProductDetail:
                draft.productDetail = action.payload;
                break;
            case VinaWealthActionType.ClearProductDetail:
                draft.productDetail = null;
                break;
            case VinaWealthActionType.SetAmountToMove:
                draft.amount = action.payload;
                break;
            case VinaWealthActionType.SetInfoReceipt:
                draft.infoReceipt = action.payload;
                break;
            case VinaWealthActionType.SetPassportInfo:
                draft.passportInfo = action.payload;
                break;
            case VinaWealthActionType.SetCongratulationFailed:
                draft.congratulationFailed = action.payload;
                break;
            case VinaWealthActionType.GetPendingTransaction:
                draft.pendingTransaction.fetching = true;
                draft.pendingTransaction.status = FetchStatus.Pending;
                break;
            case VinaWealthActionType.GetPendingTransactionSuccess:
                draft.pendingTransaction.list = action.payload;
                draft.pendingTransaction.fetching = false;
                draft.pendingTransaction.status = FetchStatus.Success;
                break;
            case VinaWealthActionType.GetPendingTransactionError:
                draft.pendingTransaction.fetching = false;
                draft.pendingTransaction.status = FetchStatus.Error;
                break;
            case VinaWealthActionType.GetCompletedTransaction:
                draft.completedTransaction.fetching = true;
                draft.completedTransaction.status = FetchStatus.Pending;
                break;
            case VinaWealthActionType.GetCompletedTransactionSuccess:
                const newList = action.payload;
                const fullList = [
                    ...draft.completedTransaction.list,
                    ...newList,
                ];
                const lastItem = newList ? newList[newList.length - 1] : null;

                draft.completedTransaction.list = fullList;
                draft.completedTransaction.fetching = false;
                draft.completedTransaction.status = FetchStatus.Success;
                draft.completedTransaction.lastId = lastItem?.id || "";
                draft.completedTransaction.isLoadMore =
                    action.payload && action.payload.length > 0;

                draft.isEmptyTransaction =
                    fullList?.length <= 0 &&
                    draft.pendingTransaction.list?.length <= 0;
                break;
            case VinaWealthActionType.GetCompletedTransactionError:
                draft.completedTransaction.fetching = false;
                draft.completedTransaction.status = FetchStatus.Error;
                break;
            case VinaWealthActionType.Reset: {
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            }
            default:
                break;
        }
    });

export default vinaWealthReducer;
