import { call, put } from "redux-saga/effects";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import lixiService from "@services/lixi";
import lixiActions from "../actions/lixi";

export function* getUnreadLixiListSaga(action) {
    const { payload } = action;
    const response = yield call(lixiService.getLixiList, payload);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(lixiActions.getUnreadLixiListSuccess(data.paoanyoneList));
            break;
        default:
            yield put(lixiActions.getUnreadLixiListError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* getReadLixiListSaga(action) {
    const { payload } = action;
    const response = yield call(lixiService.getLixiList, payload);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(lixiActions.getReadLixiListSuccess(data.paoanyoneList));
            break;
        default:
            yield put(lixiActions.getReadLixiListError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* getDashboardSaga() {
    const response = yield call(lixiService.getLixiDashboard);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            const info = data.paoanyoneList;
            yield put(lixiActions.getDashboardSuccess(info));
            break;
        default:
            yield put(lixiActions.getDashboardError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* getLixiDetailSaga(action) {
    const response = yield call(lixiService.getLixiDetailList, action.payload);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            const info = data.detail;
            yield put(lixiActions.getLixiDetailSuccess(info));
            break;
        default:
            yield put(lixiActions.getLixiDetailListError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}
