import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import { Skeleton } from "@material-ui/lab";

const MAX_LENGTH_TAX_NUMBER = 13;
const MIN_LENGTH_TAX_NUMBER = 10;

const ProfileTaxForm = ({
    successMessage,
    isUpdating,
    onSubmitForm,
    errorMessage,
    isEdit,
    toggleEdit,
    currentValue,
}) => {
    const classes = useProfileSharedClasses();

    const { t } = useTranslation();
    const [tax, setTax] = useState(currentValue);

    const [error, setError] = useState(errorMessage || "");
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const toggleEditHandler = (e) => {
        e.preventDefault();
        toggleEdit(PROFILE_FORM_TYPES.TAX);
    };

    const onCancelEdit = (e) => {
        setError("");
        setTax(currentValue);
        toggleEditHandler(e);
    };

    const onChangeHandler = (e) => {
        setError("");
        const { value } = e.target;
        const taxValue = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: value,
        });

        setTax(taxValue);

        if (!taxValue) {
            setError(t("msg_we_need_this"));
        } else if (
            taxValue?.length < MIN_LENGTH_TAX_NUMBER ||
            taxValue?.length > MAX_LENGTH_TAX_NUMBER
        ) {
            setError(t("ekyc:tax_identification_number_exceed_error"));
        }
    };

    const onSubmitHandler = useCallback(
        (e) => {
            e.preventDefault();
            if (error) return;

            onSubmitForm(tax);
        },
        [tax, onSubmitForm, error]
    );

    return (
        <form className={classes.formWrapper} onSubmit={onSubmitHandler}>
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-jobtitle ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("ekyc:tax_identification_number_header")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {!isUpdating &&
                            (successMessage === ""
                                ? currentValue ||
                                  t("profile_edit_job_title_notupdate")
                                : tax)}
                        {isUpdating && (
                            <Skeleton
                                width={170}
                                style={{ display: "inline-block" }}
                            />
                        )}
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    <ProfileEditComponent
                        isEdit={isEdit}
                        onEdit={toggleEditHandler}
                        onCancel={onCancelEdit}
                        updating={isUpdating}
                    />
                </div>
            </div>

            {isEdit && (
                <div className={classes.formDetail}>
                    <LspTextField
                        style={{ width: "100%" }}
                        autoFocus
                        name="tax"
                        error={!!error || !!errorMessage}
                        label={t("ekyc:tax_identification_number_placeholder")}
                        helperText={errorMessage || error || " "}
                        inputProps={{
                            maxLength: MAX_LENGTH_TAX_NUMBER,
                        }}
                        onChange={onChangeHandler}
                        disabled={isUpdating}
                        value={tax || ""}
                    />
                </div>
            )}
        </form>
    );
};

export default memo(ProfileTaxForm);
