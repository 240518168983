import { memo, useEffect, useMemo } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useMediaQuery, Slide, makeStyles } from "@material-ui/core";
import AlertController from "@helpers/controllers/AlertController";

const autoHideDuration = 4500;

const ControllerInitialization = memo(() => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        AlertController.setOpen(enqueueSnackbar);
    }, [enqueueSnackbar]);

    useEffect(() => {
        AlertController.setClose(closeSnackbar);
    }, [closeSnackbar]);

    return null;
});

const useStyles = makeStyles((theme) => ({
    snackbar: {
        maxWidth: 400,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "none",
        },
    },
    success: {
        backgroundColor: `${theme.palette.success.main} !important`,
    },
    error: {
        backgroundColor: `${theme.palette.error.main} !important`,
    },
}));

const LspSnackbarProvider = ({ children }) => {
    const classes = useStyles();

    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down("sm")
    );

    const anchorOrigin = useMemo(() => {
        if (isSmallScreen) {
            return { horizontal: "center", vertical: "top" };
        }
        return { horizontal: "right", vertical: "top" };
    }, [isSmallScreen]);

    const SlideTransition = (props) => {
        if (isSmallScreen) {
            return <Slide {...props} direction="down" />;
        }
        return <Slide {...props} direction="left" />;
    };

    return (
        <SnackbarProvider
            maxSnack={4}
            anchorOrigin={anchorOrigin}
            autoHideDuration={autoHideDuration}
            TransitionComponent={SlideTransition}
            disableWindowBlurListener
            className={classes.snackbar}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
            }}
        >
            <>
                <ControllerInitialization />
                {children}
            </>
        </SnackbarProvider>
    );
};

export default memo(LspSnackbarProvider);
