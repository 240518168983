import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import { RESPONSE_CODE } from "@config/constants";
import PageHeaderBack from "@components/PageHeaderBack";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import useOTP from "@helpers/useOTP";
import { Box, makeStyles } from "@material-ui/core";
import vinaWealthAction from "@redux/actions/vina-wealth";
import moveMoneyService from "@services/move-money";
import wealthService from "@services/wealth";
import { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            padding: theme.spacing(0, 2),
        },
        contentIntro: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            padding: theme.spacing(2.5),
            textAlign: "center",
            borderRadius: theme.shape.radiusLevels[1],
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        introBtn: {
            margin: theme.spacing(3, 0),
        },

        whyVC: {
            display: "flex",
            alignItems: "flex-start",
            background: theme.palette.background.whiteBoxTransparent,
            padding: theme.spacing(2),
            border: "1px solid #f1f1f1",
            marginTop: theme.spacing(2.5),
            borderRadius: theme.shape.radiusLevels[1],
            color: theme.palette.white.contrastText,
            " & > span": {
                fontSize: theme.typography.pxToRem(16),
                fontWeight: "bold",
                "& > i": {
                    color: theme.palette.blue.main,
                    marginRight: theme.spacing(1),
                    fontSize: theme.typography.pxToRem(25),
                },
            },
        },
    };
});

const VinaWealthInputAmount = ({
    productDetail,
    onBack,
    onNext,
    setAmountToStore,
    setInfoReceipt,
    setCongratulationFailed,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const [amount, setAmount] = useState("");
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const hintAmount = useMemo(() => {
        let content = "";
        if (!productDetail.FEIsContributeAccount) {
            content = t("wealth:vinawealth2_transfer_note");
        } else {
            content = "";
        }

        return content;
    }, [t, productDetail]);

    const paymentBtn = useMemo(() => {
        let content = "";
        if (!productDetail.FEIsContributeAccount) {
            content = t("wealth:vinawealth2_payment_btn");
        } else {
            content = t("wealth:vinawealth2_payment_contribute_btn");
        }
        return content;
    }, [t, productDetail]);

    const investAmount = useMemo(() => {
        let content = "";
        const minInvest = t(
            `wealth:vinawealth2_min_${productDetail.productKey}`
        );
        content = t("wealth:vinawealth2_payment_min").replace(
            "%@",
            formatNumber(+minInvest)
        );
        setAmount(+minInvest);
        return content;
    }, [t, productDetail, formatNumber]);

    const submitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            productKey: productDetail.productKey,
            ...dataToPost,
        };

        const { data } = await wealthService.commitOTPTopUp(payload);

        if (unmounted.current) return;

        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setInfoReceipt(data.data);
                onNext(VINA_WEALTH_STEPS.RESULT);
                closeOTPDialog();
                break;

            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const topupAmount = async () => {
        const dataToPost = {
            amount: +amount,
            productKey: productDetail.productKey,
        };
        const { data } = await wealthService.topupWealthSubProducts(dataToPost);

        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setCongratulationFailed(false);
                setInfoReceipt(data.data);
                onNext(VINA_WEALTH_STEPS.RESULT);
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: submitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.OPEN_VINA_WEALTH_SUCCESS_TOP_UP_FAILURE:
                setCongratulationFailed(true);
                onNext(VINA_WEALTH_STEPS.RESULT);
                break;
            case RESPONSE_CODE.NOT_ALLOWED: // BANK_ACCOUNT_NOT_ENOUGH_MONEY
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: "global_title_insuficient_funds",
                        content: "installment_no_enough_money_to_pay",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: "global_title_daily_limit_exceed",
                        content: "ms_lb_transfer_daily_limit",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.LINK_ACCOUNT_EXISTED:
                const content = t(
                    "wealth:vc3_popup_existing_error_msg"
                ).replace("%@", t("wealth:vc3_vinacapital_hotline"));
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: "wealth:vc3_popup_existing_error_title",
                        translatedContent: content,
                        doubleButton: [
                            {
                                label: t("wealth:vc3_link_account_btn"),
                                onClick: () => {
                                    onNext(VINA_WEALTH_STEPS.LINK_ACCOUNT);
                                    GlobalDialogController.hide();
                                },
                            },
                            {
                                label: t("wealth:vc3_cancel_btn"),
                                onClick: () => GlobalDialogController.hide(),
                            },
                        ],
                    },
                });
                break;
            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const moveMoneyToVina = () => {
        if (productDetail.FEIsContributeAccount) {
            topupAmount(productDetail.productKey);
        } else {
            onNext(VINA_WEALTH_STEPS.TERM_CONDITION);
            setAmountToStore(amount);
        }
    };

    const onAmountChange = useCallback(({ value }) => {
        if (value.length === 14) return;
        setAmount(value);
    }, []);
    return (
        <Content size="xs">
            <PageHeaderBack onBack={onBack}>
                {t("wealth:vinawealth2_contribute_title")}
            </PageHeaderBack>

            <Box className={classes.wrapper}>
                <Box className={classes.contentIntro}>
                    <Box mb={1.25}>{t("wealth:vinawealth2_payment_title")}</Box>
                    <NumberFormat
                        value={amount}
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        onValueChange={onAmountChange}
                        inputProps={{
                            maxLength: 19,
                            type: "text",
                        }}
                        // error={!!errors.amount}
                        helperText={investAmount}
                        customInput={LspTextField}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        allowedDecimalSeparators={false}
                    />
                </Box>
                {hintAmount && (
                    <Box className={classes.whyVC}>
                        <span>
                            <i className="font-icon icon-hint" />
                        </span>
                        <div>{hintAmount}</div>
                    </Box>
                )}

                <Box className={classes.introBtn}>
                    <LspButton
                        type="submit"
                        disabled={
                            +t(
                                `wealth:vinawealth2_min_${productDetail.productKey}`
                            ) > amount
                        }
                        fullWidth
                        onClick={moveMoneyToVina}
                    >
                        {paymentBtn}
                    </LspButton>
                </Box>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    productDetail: state.vinaWealth.productDetail,
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    clearProductDetailStore: () =>
        dispatch(vinaWealthAction.clearProductDetail()),
    setAmountToStore: (payload) =>
        dispatch(vinaWealthAction.setAmountToMove(payload)),
    setInfoReceipt: (payload) =>
        dispatch(vinaWealthAction.setInfoReceipt(payload)),
    setCongratulationFailed: (payload) =>
        dispatch(vinaWealthAction.setCongratulationFailed(payload)),
});

export default connect(mapState, mapDispatch)(VinaWealthInputAmount);
