import { memo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import LspAvatar from "@components/LspAvatar";
import iconDefault from "@assets/images/icon_default.png";

const useStyles = makeStyles((theme) => {
    return {
        tdItem: {
            display: "flex",
            padding: theme.spacing(2.75, 2),
            backgroundColor: theme.palette.background.paper,
            borderTop: `1px solid ${theme.palette.neutral.grey3}`,
        },
    };
});

const PaymentRequestListLoading = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.tdItem}>
                <Box pr={2} position="relative">
                    <LspAvatar avatar={iconDefault} size={40} />
                </Box>
                <Box>
                    <Skeleton width={300} />
                    <Skeleton width={150} />
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                    <Skeleton
                        width={100}
                        style={{ display: "block", textAlign: "right" }}
                    />
                </Box>
            </div>
            <div className={classes.tdItem}>
                <Box pr={2} position="relative">
                    <LspAvatar avatar={iconDefault} size={40} />
                </Box>
                <Box>
                    <Skeleton width={300} />
                    <Skeleton width={150} />
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                    <Skeleton
                        width={100}
                        style={{ display: "block", textAlign: "right" }}
                    />
                </Box>
            </div>
        </>
    );
};

export default memo(PaymentRequestListLoading);
