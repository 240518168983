import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { Box, makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import lspReceiptActions from "@redux/actions/lsp-receipt";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import useNumber from "@helpers/useNumber";
import { useTranslation } from "react-i18next";
import GroupPaymentItem from "./GroupPaymentItem";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[3],
        padding: theme.spacing(2),
    },
    container: {
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.radiusLevels[3],
    },
    header: {
        borderTopLeftRadius: theme.shape.radiusLevels[3],
        borderTopRightRadius: theme.shape.radiusLevels[3],
        background: theme.palette.blue.main,
        color: theme.palette.black.contrastText,
        padding: theme.spacing(2),
        textAlign: "center",
    },
    groupItem: {},
}));
const PaymentDetail = ({
    onBack,
    paymentDetail,
    isLoading,
    getPaymentDetail,
    bankXID,
}) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const { t } = useTranslation();

    const [groupDetail, setGroupDetail] = useState([{}, {}, {}]);

    useEffect(() => {
        getPaymentDetail({ bankXID });
    }, [bankXID, getPaymentDetail]);

    useEffect(() => {
        if (paymentDetail) {
            setGroupDetail(paymentDetail?.groupDetail);
        }
    }, [paymentDetail, paymentDetail?.groupDetail]);

    return (
        <Box>
            <div className={classes.wrapper}>
                <PageHeaderBack onBack={onBack}>
                    <LspTypography variant="title2" capitalize>
                        {t("txnapptype:payment_detail_header")}
                    </LspTypography>
                    <LspTypography variant="body3" capitalize color="grey">
                        {`${t("txnapptype:default_title")}: ${formatNumber(
                            paymentDetail?.txnGroupAmount || ""
                        )} ${paymentDetail?.txnCurrency || ""}`}
                    </LspTypography>
                </PageHeaderBack>

                <div className={classes.container}>
                    <LspTypography
                        className={classes.header}
                        variant="subheading2"
                        component="div"
                    >
                        {t("txnapptype:payment_detail_title")}
                    </LspTypography>

                    <div className={classes.groupItem}>
                        {groupDetail.map((item, index, list) => (
                            <GroupPaymentItem
                                key={Math.random()}
                                txnDesc={item?.txnDesc}
                                txnAmount={item?.txnAmount}
                                txnCurrency={paymentDetail?.txnCurrency}
                                txnTimestamp={item?.txnTimestamp}
                                txnAppType={item?.txnAppType}
                                noBreakLine={list.length - 1 === index}
                                isLoading={isLoading}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Box>
    );
};

const stateProps = (state) => ({
    isLoading: state.receipt.paymentDetail?.fetching,
    paymentDetail: state.receipt.paymentDetail?.data,
});

const dispatchProps = (dispatch) => ({
    getPaymentDetail: (payload) =>
        dispatch(lspReceiptActions.getPaymentDetailRequest(payload)),
});

export default connect(stateProps, dispatchProps)(PaymentDetail);
