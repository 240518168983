import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import querystring from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import sha512 from "crypto-js/sha512";

import { Box, Icon, Paper } from "@material-ui/core";
import Content from "@components/Content";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import PageHeaderBack from "@components/PageHeaderBack";
import LspTextField from "@components/LspTextField";
import LspButton from "@components/LspButton";

import { REGEX_PATTERN } from "@config/constants";
import CardManagementActions from "@redux/actions/card-management";
import { CardManagementNavigation } from "./constant";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAC);

const cryptMambu = new JSEncrypt();
cryptMambu.setPublicKey(process.env.REACT_APP_RSAC_CORE);

const CardResetPin = ({ resetPinLoading, resetPin }) => {
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const { t } = useTranslation();
    const history = useHistory();
    const maxLengthPin = 6;
    const [pinRules, setPinRules] = useState(null);

    const location = useLocation();
    const params = querystring.parse(location.search);
    const { cardId } = params;

    const { register, handleSubmit, errors, getValues, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            password: "",
            newPin: "",
            verifyNewPin: "",
        },
    });

    const getPinRules = useCallback(() => {
        const pinCheck = t("sysParamContent:PinCheck").split(",");
        setPinRules(
            pinCheck.map((r) => {
                return {
                    name: r,
                    rule: t(`sysParamContent:${r}`),
                    error: t(`ekyc:${r}_Match_Error`),
                };
            })
        );
    }, [t]);

    useEffect(() => {
        if (!cardId) {
            history.push(CardManagementNavigation.Debit);
        } else {
            getPinRules();
        }
    }, [getPinRules, cardId, history]);

    const resetPinHandler = (data) => {
        if (!data.password || !data.newPin || !data.verifyNewPin) {
            return;
        }

        const encryptedPassword = sha512(data.password).toString();
        const encryptedPin = crypt.encrypt(data.newPin);
        const encryptedPinMambu = cryptMambu.encrypt(data.newPin);

        const dataToPost = {
            cardId,
            newPIN: encryptedPin,
            newPINMambu: encryptedPinMambu,
            password: encryptedPassword,
        };
        resetPin(dataToPost);
    };

    const pinValidation = () => {
        const newPin = getValues("newPin");

        for (let i = 0; i < pinRules.length; i++) {
            const currentPinRule = pinRules[i];
            const regexString = currentPinRule.rule.replace("\\\\", "\\");
            const regex = new RegExp(regexString, "g");
            const isInValid = regex.test(newPin);
            if (isInValid) {
                return currentPinRule.error;
            }
        }
    };

    const verifyPinValidation = () => {
        const newPin = getValues("newPin");
        const verifyNewPin = getValues("verifyNewPin");
        if (newPin !== verifyNewPin) {
            return t("mc_lb_enter_pin_error");
        }
    };

    const onChangePassword = (e) => {
        const password = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.PASSWORD_REPLACEMENT,
            text: e.target.value,
        });
        setValue("password", password);
    };
    const onChangeNewPin = (e) => {
        const newPin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });
        setValue("newPin", newPin);
    };
    const onChangeVerifyNewPin = (e) => {
        const verifyNewPin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });

        setValue("verifyNewPin", verifyNewPin);
    };

    const onBack = () => {
        history.goBack();
    };

    return (
        <Content size="sm">
            <Paper>
                <Box p={3}>
                    <form onSubmit={handleSubmit(resetPinHandler)}>
                        {/* INPUT PASSWORD */}
                        <PageHeaderBack onBack={onBack}>
                            <Icon className="font-icon icon-Timo-Plus-Phone" />
                            <div>{t("master:enter_login_password_web")}</div>
                        </PageHeaderBack>
                        <div>
                            <LspTextField
                                error={!!errors.password}
                                name="password"
                                label={t("lb_password")}
                                helperText={errors.password?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                    }),
                                    type: "password",
                                }}
                                onChange={onChangePassword}
                            />
                            <LspTextField
                                error={!!errors.newPin}
                                name="newPin"
                                label={t("card_txt_placeholder_new_pin")}
                                helperText={errors.newPin?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: () => pinValidation(),
                                    }),
                                    maxLength: maxLengthPin,
                                    type: "password",
                                }}
                                onChange={onChangeNewPin}
                            />

                            <LspTextField
                                error={!!errors.verifyNewPin}
                                name="verifyNewPin"
                                label={t("card_txt_placeholder_new_pin")}
                                helperText={errors.verifyNewPin?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: () => verifyPinValidation(),
                                    }),
                                    maxLength: maxLengthPin,
                                    type: "password",
                                }}
                                onChange={onChangeVerifyNewPin}
                            />
                        </div>
                        <div>
                            <LspButton
                                progressing={resetPinLoading}
                                fullWidth
                                type="submit"
                            >
                                {t("lb_next")}
                            </LspButton>
                        </div>
                    </form>
                </Box>
            </Paper>
        </Content>
    );
};

const stateProps = (state) => ({
    resetPinLoading: state.cardInfo.resetPinLoading,
});

const dispatchProps = (dispatch) => ({
    resetPin: (data) => dispatch(CardManagementActions.resetPin(data)),
});

export default connect(stateProps, dispatchProps)(CardResetPin);
