import ColorBar from "@components/ColorBar";
import ContentModal from "@components/ContentModal";
import { LANGUAGE_OPTIONS, RESPONSE_CODE } from "@config/constants";
import useSharedClasses from "@helpers/styleContentModal";
import activationService from "@services/activation";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import querystring from "query-string";
import i18n from "@i18n/";
import useLanguage from "@helpers/useLanguage";
import { makeStyles } from "@material-ui/core";
import ActivationForm from "./ActivationForm";
import ActivationFormLoading from "./ActivationFormLoading";

const SUBMITTED_STATUES = {
    TOKEN_EXPIRED: "TOKEN_EXPIRED",
    SUCCESS: "SUCCESS",
    INIT: "INIT",
};
const useStyles = makeStyles((theme) => ({
    customColorBar: {
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    modalForm: {
        [theme.breakpoints.down("xs")]: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    modalFormContent: {
        [theme.breakpoints.down("xs")]: {
            flex: 1,
            justifyContent: "center",
            display: "inline-flex",
            flexDirection: "column",
        },
    },
}));

const Activation = () => {
    const sharedClasses = useSharedClasses();
    const classes = useStyles();

    const { t } = useTranslation();
    const { switchLanguage } = useLanguage();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { sk: token } = params;

    const currentLanguage = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? LANGUAGE_OPTIONS.EN.toUpperCase()
            : LANGUAGE_OPTIONS.VN.toUpperCase();
    }, []);

    const [result, setResult] = useState({
        status: SUBMITTED_STATUES.INIT,
        message: null,
    });

    const [verifying, setVerifying] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const title = useMemo(() => {
        if (result?.status === SUBMITTED_STATUES.SUCCESS) {
            return t("join_title_congratulation");
        }

        if (result?.status === SUBMITTED_STATUES.TOKEN_EXPIRED) {
            return t("new_join_txt_title_expired_token");
        }

        return t("new_join_activate_title");
    }, [result?.status, t]);

    const checkTokenIsValid = useCallback(
        async (currentToken) => {
            if (!currentToken) return;
            setVerifying(true);

            const response = await activationService.checkTokenIsValid({
                activationToken: currentToken,
            });

            if (unmounted.current) return;

            const { code } = response.data;
            setVerifying(false);
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    break;
                case RESPONSE_CODE.TOKEN_EXPIRED:
                    setResult({
                        status: SUBMITTED_STATUES.TOKEN_EXPIRED,
                        message: t("new_join_txt_subtitle_expired_token"),
                    });
                    break;
                default:
                    GlobalDialogController.showError({ errorCode: code });
                    break;
            }
        },
        [t]
    );

    const resultHandler = (data) => {
        setResult(data);
    };

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const languageContent = useMemo(() => {
        const languages = Object.keys(LANGUAGE_OPTIONS).filter(
            (item) => item !== LANGUAGE_OPTIONS.VI.toUpperCase()
        );
        return languages
            .sort((a, b) => b.localeCompare(a))
            .map((item, index) => (
                <span key={Math.random()}>
                    <span
                        className={`${
                            currentLanguage === item ? "active" : ""
                        }`}
                        onClick={
                            currentLanguage !== item ? changeLanguage : null
                        }
                    >
                        {item}
                    </span>
                    {index !== languages?.length - 1 && <span>|</span>}
                </span>
            ));
    }, [currentLanguage, changeLanguage]);

    useEffect(() => {
        checkTokenIsValid(token);
    }, [checkTokenIsValid, token]);

    return (
        <ContentModal supportMobile>
            <div className={`${sharedClasses.modalForm} ${classes.modalForm}`}>
                <div className={sharedClasses.language}>{languageContent}</div>
                <div
                    className={`${sharedClasses.modalFormContent} ${sharedClasses.hasLanguage} ${classes.modalFormContent}`}
                >
                    <div className={sharedClasses.title}>{title}</div>

                    {/* Before submit - activation form */}
                    {result?.status === SUBMITTED_STATUES.INIT && (
                        <>
                            {!verifying && (
                                <ActivationForm
                                    token={token}
                                    statusList={SUBMITTED_STATUES}
                                    resultHandler={resultHandler}
                                />
                            )}
                            {verifying && <ActivationFormLoading />}
                        </>
                    )}

                    {/* Handler for after submit form (submit successfully) or token expire */}

                    {result?.status !== SUBMITTED_STATUES.INIT && (
                        <div
                            className={clsx(sharedClasses.succeedMsg, {
                                [sharedClasses.errorMsg]:
                                    result?.status ===
                                    SUBMITTED_STATUES.TOKEN_EXPIRED,
                            })}
                        >
                            <span>{result?.message}</span>
                        </div>
                    )}
                </div>
                <ColorBar
                    className={`${sharedClasses.colorBar} ${classes.customColorBar}`}
                />
            </div>
        </ContentModal>
    );
};

export default Activation;
