import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";
import LspTypography from "@components/LspTypography";

const InstallmentCompletedStatusBody = ({ detail, classes }) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00009")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {detail.createdDate || t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00010")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {detail.endDate || t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:ins_detail_original_amount")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.originalAmount) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:lb_total_interest")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.convertFeeAmount) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00016")}
                </LspTypography>
                <LspTypography variant="body2" className={classes.value}>
                    {formatNumber(detail.earlyClosureFee) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.infoLine}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("creditCard:IN00017")}
                </LspTypography>
                <LspTypography variant="title2" color="grey">
                    {formatNumber(detail.totalAmountPayEarly) ||
                        t("tr_lb_not_available")}
                </LspTypography>
            </div>
        </>
    );
};

export default InstallmentCompletedStatusBody;
