import LspTypography from "@components/LspTypography";
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import Interweave from "interweave";
import { INVITATION_TIERS } from "@containers/Invitation/constant";
import { useCallback, useMemo } from "react";
import InvitationPendingList from "../InvitationPendingList";
import InvitationAvatar from "../InvitationAvatar";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        flex: 1,
        "& img": {
            width: "100%",
            maxWidth: theme.spacing(4),
            marginRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
                maxWidth: theme.spacing(12.25),
                marginRight: theme.spacing(2),
            },
        },
    },
    description: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: "rgba(255,255,255, 0.5)",
    },
}));

const InvitationLevel = ({ detail, tierList }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const getCurrentTierInfo = useCallback(
        (currentTier) => {
            const tier = tierList.filter(
                (item) => item.className === currentTier
            );
            return tier.length > 0 ? tier[0] : {};
        },
        [tierList]
    );

    const description = useMemo(() => {
        if (!detail?.currentClass) return "";

        let desc = "";

        if (detail?.currentClass === INVITATION_TIERS.SUPER_STAR) {
            desc = t("member:timostarclub_lb_congra_super_star");
        } else {
            const currentTierInfo = getCurrentTierInfo(detail?.currentClass);
            const remainPeople =
                currentTierInfo.toPoint + 1 - detail?.currentPoint;

            desc = t("member:timostarclub_lb_require");
            desc = desc.replace("%@", remainPeople);

            switch (detail?.currentClass) {
                case INVITATION_TIERS.BEGINNER:
                    desc = desc.replace(
                        "%@",
                        t("member:timostarclub_lb_rising_star")
                    );
                    break;
                case INVITATION_TIERS.RISING_STAR:
                    desc = desc.replace(
                        "%@",
                        t("member:timostarclub_lb_shining_star")
                    );
                    break;
                case INVITATION_TIERS.SHINING_STAR:
                    desc = desc.replace(
                        "%@",
                        t("member:timostarclub_lb_super_star")
                    );
                    break;
                case INVITATION_TIERS.SUPER_STAR:
                default:
                    break;
            }
        }
        return desc;
    }, [detail, t, getCurrentTierInfo]);

    const viewPendingList = (e) => {
        e.preventDefault();
        GlobalDialogController.show({
            component: () => (
                <InvitationPendingList
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    return (
        <div className={classes.wrapper}>
            {isMobile && (
                <div>
                    <LspTypography color="white" variant="body3">
                        {t("member:timostarclub_lb_your_lv")}
                    </LspTypography>

                    <Box display="flex" alignItems="center" mt={1} mb={1}>
                        <InvitationAvatar type={detail?.currentClass} />
                        <LspTypography color="white" variant="heading1">
                            {detail?.currentClass}
                        </LspTypography>
                    </Box>
                    <LspTypography
                        color="white"
                        variant="body3"
                        className={classes.description}
                    >
                        <Interweave content={description} />
                    </LspTypography>
                    <a href="#" onClick={viewPendingList}>
                        <LspTypography
                            color="success"
                            variant="hyperlink2"
                            component="span"
                        >
                            {t("member:timostarclub_btn_view_pending_list")}
                        </LspTypography>
                    </a>
                </div>
            )}
            {!isMobile && (
                <div>
                    <Box display="flex">
                        <div>
                            <InvitationAvatar type={detail?.currentClass} />
                        </div>
                        <div>
                            <LspTypography
                                color="white"
                                variant="body3"
                                style={{ marginBottom: "8px" }}
                            >
                                {t("member:timostarclub_lb_your_lv")}
                            </LspTypography>
                            <LspTypography color="white" variant="title2">
                                {detail?.currentClass}
                            </LspTypography>
                            <LspTypography
                                color="white"
                                variant="body3"
                                className={classes.description}
                            >
                                <Interweave content={description} />
                            </LspTypography>
                            <a href="#" onClick={viewPendingList}>
                                <LspTypography
                                    color="success"
                                    variant="hyperlink2"
                                    component="span"
                                >
                                    {t(
                                        "member:timostarclub_btn_view_pending_list"
                                    )}
                                </LspTypography>
                            </a>
                        </div>
                    </Box>
                </div>
            )}
        </div>
    );
};

export default InvitationLevel;
