import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import { create, CancelToken } from "apisauce";
import ApiCancelToken from "@helpers/controllers/ApiCancelToken";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { RESPONSE_CODE } from "./constants";

const cancelToken = CancelToken.source();

ApiCancelToken.setCancelToken(cancelToken);

const api = create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: +process.env.REACT_APP_API_TIMEOUT,
    headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json, text/plain",
    },
    cancelToken: cancelToken.token,
});

api.addRequestTransform((config) => {
    if (QuickCodeDialogController.isPreventRequestApi(config.url)) {
        // console.log("config.url", config.url);
        return;
    }
    return config;
});

const REMAIN_CHANGE_PASSWORD_RES_CODES = [
    RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_14_DAY,
    RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_07_DAY,
    RESPONSE_CODE.REMAIN_CHANGE_PASSWORD_03_DAY,
];

const isPasswordExpire = (response) => {
    if (response.ok) {
        const { code, data } = response?.data;

        const isRemainChangePass = REMAIN_CHANGE_PASSWORD_RES_CODES.find(
            (i) => i === code
        );

        if (isRemainChangePass) {
            return {
                code,
                data,
                result: true,
            };
        }
    }

    return {
        result: false,
    };
};

api.addResponseTransform((response) => {
    // if (axios.isCancel(response.originalError)) {
    //     // console.log("axios request cancelled", response.originalError.message);
    //     response.data = {
    //         code: RESPONSE_CODE.FE_CANCEL_TOKEN,
    //         data: {},
    //     };
    //     return;
    // }

    // console.log("addResponseTransform response", response);

    if (response.problem === "CANCEL_ERROR") {
        // console.log("axios request cancelled", response.originalError.message);
        response.data = {
            code: RESPONSE_CODE.FE_CANCEL_TOKEN,
            data: {},
        };
        return;
    }

    const needChangePass = isPasswordExpire(response);
    if (needChangePass.result) {
        GlobalDialogController.showError({
            errorCode: needChangePass.code,
            data: needChangePass.data,
        });
        return;
    }

    if (response.config?.bypassErrorHandler) {
        return;
    }

    // response = {
    //     ...response,
    //     status: 503,
    //     ok: false,
    //     data: {
    //         code: 3500,
    //         data: {
    //             message: "`message`",
    //             fromTime: "10/09/2022 04:00",
    //             toTime: "11/09/2022 04:00",
    //         },
    //     },
    // };

    // eslint-disable-next-line no-unreachable
    if (!response.ok) {
        switch (response.status) {
            case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
            case RESPONSE_CODE.UNAUTHORIZED:
                if (!response.config?.bypassForceLogout) {
                    ApiCancelToken.forceLogout();
                    GlobalDialogController.showError({
                        errorCode: response.status,
                    });
                }
                response.data = {
                    code: response.status,
                    data: {},
                };
                break;
            case RESPONSE_CODE.INVALID: // for wrong quick code
            case RESPONSE_CODE.NOT_ACCEPTABLE: // for wrong quick code
                response.data = {
                    code: response.status,
                    data: {},
                };
                break;
            case RESPONSE_CODE.SERVER_UNAVAILABLE:
                if (response?.data?.code === RESPONSE_CODE.UNDER_MAINTENANCE) {
                    localStorage.setItem(
                        "termObject",
                        JSON.stringify(response?.data?.data)
                    );
                    window.location.href = "/maintenance";
                    // window.open("/maintenance", "_blank");

                    response.data = {
                        code: response.status,
                        data: {},
                    };
                }
                break;
            default:
                response.data = {
                    code: -900,
                    data: {},
                };
                break;
        }
    } else {
        const { code, data } = response.data;

        switch (code) {
            // case RESPONSE_CODE.BAD_REQUEST:
            // case RESPONSE_CODE.FEATURE_UNAVAILABLE:
            // case RESPONSE_CODE.UNSUPPORTED_OPERATION:
            // case RESPONSE_CODE.SERVER_UNAVAILABLE:
            // case RESPONSE_CODE.TECHNICAL_DIFFICULT:
            // case RESPONSE_CODE.REQUEST_TIMEOUT:
            // case RESPONSE_CODE.REQUEST_TIMEOUT_V2:
            // case RESPONSE_CODE.OPERATION_TIMEOUT:
            // case RESPONSE_CODE.IOTP_REQUIRED:
            //     GlobalDialogController.showError({
            //         errorCode: code,
            //     });
            //     break;
            case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
            case RESPONSE_CODE.UNAUTHORIZED:
                if (!response.config?.bypassForceLogout) {
                    ApiCancelToken.forceLogout();
                    GlobalDialogController.showError({
                        errorCode: code,
                    });
                }
                break;

            case RESPONSE_CODE.ERROR_DYNAMIC_CONTENT:
                GlobalDialogController.showError({
                    errorCode: code,
                    data,
                });
                break;
            case RESPONSE_CODE.EKYC_REACH_LIMIT:
            case RESPONSE_CODE.MONTHLY_LIMIT_FOREIGNER:
                GlobalDialogController.showError({
                    errorCode: code,
                    data,
                    type: GlobalDialogTypes.FinancialError,
                });
                break;
            default:
                break;
        }
    }
});

export default api;
