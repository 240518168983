import { Box, makeStyles } from "@material-ui/core";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        padding: theme.spacing(3),
    },
}));

const LspBox = ({ children, className, ...props }) => {
    const classes = useStyles();
    return (
        <Box className={`${classes.wrapper} ${className}`} {...props}>
            {children}
        </Box>
    );
};

export default memo(LspBox);
