import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Fragment, memo } from "react";

const useStyles = makeStyles((theme) => ({
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const NotificationLoading = () => {
    const classes = useStyles();
    return (
        <>
            {[1, 2, 3].map((value, idx, arr) => (
                <Fragment key={value}>
                    <Box px={2} py={1}>
                        <Typography>
                            <Skeleton variant="text" width="50%" />
                        </Typography>
                        <Typography variant="caption">
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="70%" />
                            <Skeleton variant="text" width="30%" />
                        </Typography>
                    </Box>
                    {idx < arr.length - 1 && (
                        <Divider className={classes.divider} />
                    )}
                </Fragment>
            ))}
        </>
    );
};

export default memo(NotificationLoading);
