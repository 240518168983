import LspCard from "@components/LspCard";
import LspCheckbox from "@components/LspCheckbox";
import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { isFunction } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(3, 1),
        alignItems: "center",
        cursor: "pointer",
    },
    checkbox: {
        marginRight: theme.spacing(2),
    },
    content: {},
}));

const AutoDepositSetting = ({ checked, onClick }) => {
    const classes = useStyles();
    const [checkbox, setCheckbox] = useState(checked);
    const { t } = useTranslation();

    const handleOnChange = () => {
        setCheckbox(!checkbox);

        if (isFunction(onClick)) {
            onClick();
        }
    };

    useEffect(() => {
        setCheckbox(checked);
    }, [checked]);

    return (
        <>
            <LspCard
                defaultTitleColor
                title={
                    <LspTypography variant="subheading3">
                        {t("TD:td_recurring_setting_advance_section")}
                    </LspTypography>
                }
                color="grey"
                noBreakLine
            >
                <div
                    className={classes.container}
                    onClick={() => handleOnChange()}
                >
                    <div className={classes.checkbox}>
                        <LspCheckbox checked={checkbox} />
                    </div>
                    <div className={classes.content}>
                        <LspTypography variant="subheading2">
                            {t("TD:td_recurring_setting_auto_recurring_title")}
                        </LspTypography>
                        <LspTypography variant="body2" color="grey">
                            {t("TD:td_recurring_setting_auto_recurring_desc")}
                        </LspTypography>
                    </div>
                </div>
            </LspCard>
        </>
    );
};

AutoDepositSetting.propTypes = {
    checked: PropTypes.bool,
};

AutoDepositSetting.defaultProps = {
    checked: false,
};

export default AutoDepositSetting;
