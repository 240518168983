import {
    ButtonBase,
    Icon,
    IconButton,
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ClearIcon from "@material-ui/icons/Clear";
import LspTextField from "../../components/LspTextField";

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        width: theme.typography.pxToRem(50),
        height: theme.typography.pxToRem(50),
        position: "absolute",
        right: 0,
        top: 0,
        transition: "width 0.5s",
    },
    searchIcon: {
        width: theme.typography.pxToRem(50),
        height: theme.typography.pxToRem(50),
        boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
        color: theme.palette.primary.main,
        display: "block",
    },
    expandSearchContainer: {
        width: "100%",
    },
    searchContent: {
        display: "flex",
        justifyContent: "space-between",
    },
    searchInput: {
        "& .MuiFormHelperText-root": {
            display: "none",
        },
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    show: {
        display: "block",
    },
    clearIcon: {
        fontSize: theme.typography.pxToRem(14),
        cursor: "pointer",
    },
    buttons: {
        marginRight: theme.spacing(9),
        textAlign: "right",
        marginTop: theme.typography.pxToRem(5),
    },
}));

const SearchButton = ({ children, filter }) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const [expandSearch, setExpandSearch] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const expandSearchClick = () => {
        setExpandSearch(true);
    };

    const clearInputSearch = () => {
        setSearchInput("");
    };

    const closeSearchClick = () => {
        clearInputSearch();
        setExpandSearch(false);
    };

    const onChangeSearchInput = (searchContent) => {
        setSearchInput(searchContent);
    };

    const debounceSearch = useRef();

    useEffect(() => {
        clearTimeout(debounceSearch.current);
        debounceSearch.current = setTimeout(() => {
            filter(searchInput);
        }, 300);
        return () => clearTimeout(debounceSearch.current);
    }, [searchInput, filter]);

    return (
        <div style={{ position: "relative" }}>
            <div className={classes.buttons}>{children}</div>
            <div
                className={clsx(classes.searchContainer, {
                    [classes.expandSearchContainer]: !!expandSearch,
                })}
            >
                <div
                    className={clsx(classes.searchContent, {
                        [classes.hide]: !expandSearch,
                    })}
                >
                    <LspTextField
                        autoFocus
                        name="searchInput"
                        label="lb_search"
                        onChange={(e) => onChangeSearchInput(e.target.value)}
                        value={searchInput}
                        className={clsx(classes.searchInput, {
                            [classes.show]: !!expandSearch,
                            [classes.hide]: !expandSearch,
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={clearInputSearch}
                                        size="small"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <ButtonBase
                        className={clsx({
                            [classes.cancelBtn]: !!expandSearch,
                            [classes.hide]: !expandSearch,
                        })}
                        onClick={closeSearchClick}
                    >
                        {t("lb_cancel")}
                    </ButtonBase>
                </div>

                <ButtonBase
                    className={clsx(classes.searchIcon, {
                        [classes.hide]: !!expandSearch,
                    })}
                    onClick={expandSearchClick}
                >
                    <Icon className="font-icon icon-Lixi---search" />
                </ButtonBase>
            </div>
        </div>
    );
};
export default SearchButton;
