import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar-edit";

const dragBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderWidth: 2,
    borderRadius: 2,
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    maxWidth: 400,
    cursor: "pointer",
};

const activeStyle = {
    borderStyle: "dashed",
    borderColor: "#2196f3",
};

const rejectStyle = {
    borderStyle: "dashed",
    borderColor: "#E65365",
};

const useStyles = makeStyles((theme) => ({
    orLabel: {
        textTransform: "uppercase",
        textAlign: "center",
    },
    iconUpload: {
        fontSize: theme.typography.pxToRem(100),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const ImageUploader = ({
    acceptedFileExtensions,
    avatarMaxSize,
    onError,
    onUpload,
    files,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const previewUrl = useMemo(() => files?.preview, [files]);

    const isLargeFiles = useCallback(
        (fileSize) => fileSize / (1024 * 1024) >= avatarMaxSize,
        [avatarMaxSize]
    );

    const onDrop = useCallback(
        (acceptFile, rejectFile) => {
            const isLargeFile = acceptFile.some((item) =>
                isLargeFiles(item?.size)
            );
            if (Object.keys(rejectFile).length !== 0) {
                onError(t("upload_avatar_msg_wrong_extension"));
            } else if (isLargeFile) {
                onError(t("upload_payee_avatar_limit_size"));
            } else {
                onError("");
                const file = acceptFile[0];
                onUpload({ ...file, preview: URL.createObjectURL(file) });
            }
        },
        [isLargeFiles, onError, t, onUpload]
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept: acceptedFileExtensions,
        onDrop,
        noClick: true,
    });

    const style = useMemo(
        () => ({
            ...dragBox,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    const onClose = () => {
        onUpload(null);
    };

    const onCrop = (previewInfo) => {
        onUpload((prev) => ({ ...prev, preview: previewInfo }));
    };

    return (
        <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {!files && (
                <>
                    <LspTypography className={classes.orLabel} color="primary">
                        {t("lb_or")}
                    </LspTypography>
                    <Icon
                        className={`font-icon icon-avatarchange ${classes.iconUpload}`}
                    />
                    <LspTypography color="primary" variant="body1">
                        {t("profile_txt_button_drag_photo")}
                    </LspTypography>
                </>
            )}
            {files && (
                <Avatar
                    width={340}
                    imageWidth={340}
                    onCrop={onCrop}
                    onClose={onClose}
                    src={previewUrl}
                    exportAsSquare
                />
            )}
        </Box>
    );
};

export default memo(ImageUploader);
