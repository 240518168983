import { Checkbox, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { memo } from "react";
import { isFunction } from "lodash";
import { CheckApartIcon, CheckIcon, UnCheckedIcon } from "./LspIcon";

const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "white",
    },
    success: {
        "&.Mui-checked": {
            color: theme.palette.status.success,
        },
    },
    primary: {
        "&.Mui-checked": {
            color: theme.palette.primary.main,
        },
    },
    secondary: {
        "&.Mui-checked": {
            color: theme.palette.secondary.main,
        },
    },
}));

/**
 * @typedef LspCheckboxProps
 * @type {object}
 * @property {{"success"|"primary"|"secondary"}} color
 * @property {string} className
 * @property {boolean} indeterminate
 */

/**
 * @param {LspCheckboxProps} props
 */

const LspCheckbox = ({
    color,
    className,
    indeterminate,
    onClick,
    ...props
}) => {
    const classes = useStyles();

    const handleOnClick = ($event) => {
        if (isFunction(onClick)) {
            onClick($event);
        }
    };

    return (
        <Checkbox
            checked
            icon={<UnCheckedIcon />}
            checkedIcon={indeterminate ? <CheckApartIcon /> : <CheckIcon />}
            onClick={handleOnClick}
            className={clsx(classes.checkbox, {
                [className]: className,
                [classes.success]: color === "success",
                [classes.primary]: color === "primary",
                [classes.secondary]: color === "secondary",
            })}
            {...props}
        />
    );
};

LspCheckbox.propTypes = {
    color: PropTypes.oneOf(["success", "primary", "secondary"]),
    className: PropTypes.string,
    indeterminate: PropTypes.bool,
};

LspCheckbox.defaultProps = {
    color: "success",
    className: "",
    indeterminate: false,
};

export default memo(LspCheckbox);
