import {
    makeStyles,
    Button,
    Divider,
    CircularProgress,
    Box,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { memo } from "react";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        position: "relative",
        flex: "0 0 auto",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "flex-end",
        borderRadius: theme.shape.borderRadius,
    },
    button: {
        transition: theme.transitions.create("opacity", {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
        }),
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    primaryButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    secondaryButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    dividerContainer: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
        }),
        backgroundColor: theme.palette.primary.main,
        "&.disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    divider: {
        backgroundColor: theme.palette.background.paper,
    },
    progress: {
        position: "absolute",
        top: "50%",
        left: "50%",
    },
}));

const defaultButtonProps = {
    variant: "contained",
    size: "large",
    color: "primary",
    fullWidth: true,
    disableRipple: true,
};

/**
 * @param {object} props
 * @param {number} [props.ml]
 * @param {number} [props.mr]
 * @param {number} [props.mt]
 * @param {number} [props.mb]
 * @param {boolean} props.progressing
 * @param {object} props.primaryButton
 * @param {string} props.primaryButton.label
 * @param {function} [props.primaryButton.onClick]
 * @param {object} props.secondaryButton
 * @param {string} props.secondaryButton.label
 * @param {function} [props.secondaryButton.onClick]
 * @param {boolean} props.disabled
 * @param {object} props.buttonProps
 */

const DoubleButtons = ({
    ml,
    mr,
    mt,
    mb,
    progressing,
    primaryButton,
    secondaryButton,
    disabled,
    buttonProps = {},
}) => {
    const classes = useStyles();

    return (
        <Box ml={ml} mr={mr} mb={mb} mt={mt} className={classes.container}>
            {progressing && (
                <CircularProgress
                    className={classes.progress}
                    style={{
                        marginTop: -18 / 2,
                        marginLeft: -18 / 2,
                    }}
                    size={18}
                />
            )}
            <Button
                {...defaultButtonProps}
                {...buttonProps}
                className={clsx(
                    classes.button,
                    classes.secondaryButton,
                    buttonProps.className
                )}
                disabled={progressing || disabled}
                onClick={secondaryButton?.onClick}
                id="backBtn"
            >
                <LspTypography variant="button1" color="white">
                    {secondaryButton?.label}
                </LspTypography>
            </Button>
            <div
                className={clsx(classes.dividerContainer, {
                    disabled: progressing || disabled,
                })}
            >
                {!primaryButton.disable && (
                    <Divider
                        orientation="vertical"
                        className={classes.divider}
                    />
                )}
            </div>
            <Button
                {...defaultButtonProps}
                {...buttonProps}
                className={clsx(
                    classes.button,
                    classes.primaryButton,
                    buttonProps.className
                )}
                disabled={progressing || disabled || primaryButton.disable}
                type="submit"
                onClick={primaryButton?.onClick}
                id="nextBtn"
            >
                <LspTypography variant="button1" color="white">
                    {primaryButton?.label}
                </LspTypography>
            </Button>
        </Box>
    );
};

DoubleButtons.propTypes = {
    ml: PropTypes.number,
    mr: PropTypes.number,
    mt: PropTypes.number,
    mb: PropTypes.number,
    progressing: PropTypes.bool,
    primaryButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    }).isRequired,
    secondaryButton: PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
    }),
    disabled: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    buttonProps: PropTypes.object,
};

DoubleButtons.defaultProps = {
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
    progressing: false,
    secondaryButton: undefined,
    disabled: false,
    buttonProps: undefined,
};

export default memo(DoubleButtons);
