import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    loading: {
        "& p": {
            width: "100%",
        },
    },
    avatar: {
        textAlign: "center",
        width: 80,
        height: 80,
        margin: "auto",
        borderRadius: theme.shape.radiusLevels[1],
        marginBottom: theme.spacing(2),
    },
}));
const InvitationTierListLoading = ({ classes }) => {
    const className = useStyles();

    return (
        <div className={className.loading}>
            <div className={classes.tierListHeader}>
                <LspTypography variant="subheading1" color="black">
                    <Skeleton width={150} />
                </LspTypography>
            </div>

            <div className={classes.tierList}>
                {[...new Array(4)].map((_, index) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className={classes.tierItem}>
                            <LspTypography>
                                <Skeleton
                                    variant="rect"
                                    classes={{ root: className.avatar }}
                                />
                            </LspTypography>
                            <LspTypography>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </LspTypography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default InvitationTierListLoading;
