import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    boxWrapper: {
        padding: theme.spacing(3, 2, 2, 2),
        marginBottom: theme.spacing(3),
        "&.highlight": {
            borderTop: `4px solid ${theme.palette.primary.main}`,
        },
    },
    contentList: {
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
        "& > li": {
            marginBottom: theme.spacing(1),
            "&::marker": {
                color: theme.palette.primary.main,
                fontSize: "1.15em",
            },
            "& > span": {
                whiteSpace: "break-spaces",
            },
        },
    },
    listLine: {
        background: theme.palette.background.paper,
        padding: theme.spacing(3, 2, 3, 3),
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        cursor: "pointer",
        width: "100%",
        "& > div": {
            flexGrow: 2,
        },
    },
    iconList: {
        color: theme.palette.primary.main,
        fontSize: 16,
        marginTop: theme.spacing(0.75),
        marginRight: theme.spacing(1),
        alignSelf: "flex-start",
    },
    formHeader: {
        background: theme.palette.background.default,
        padding: theme.spacing(1, 2),
        width: "100%",
    },
    subtitle: {
        textTransform: "uppercase",
        marginBottom: theme.spacing(2),
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    multipleFormWrapper: {
        padding: theme.spacing(3, 2, 1, 2),
    },
    boxFormWrapper: {
        padding: theme.spacing(3, 2, 1),
    },
    button: {
        marginTop: theme.spacing(3),
    },
    radioWrapper: {
        padding: theme.spacing(1, 2, 1),
    },
    monthWrapper: {
        position: "relative",
    },
    monthLabel: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2.25),
        color: theme.palette.neutral.grey1,
    },
}));
