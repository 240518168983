import { makeStyles, Icon } from "@material-ui/core";

import { LspDialog } from "@components/LspDialog";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import LspChecker from "@components/LspChecker";
import LspButton from "@components/LspButton";
import { isFunction } from "lodash";
import tommyIcon from "@assets/images/tommy_finding.png";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 4),
        background: theme.palette.background.paper,
    },
    header: {
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    headerImg: {
        width: 160,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    headerTitle: {
        marginBottom: theme.spacing(2),
    },
    content: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(5),
        boxShadow: theme.shadows[29],
        "& .font-icon": {
            color: theme.palette.magicalGrey[400],
        },
    },
    borderBottom: {
        borderBottom: `1.5px solid ${theme.palette.neutral.grey3}`,
        margin: theme.spacing(2, 0),
    },
}));
const OverdraftPreviewEarly = ({ onClose, amount }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const handleClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const earlyOverdraft = useMemo(() => {
        return (
            t("overdraft:overdraft_early_closure_notes", {
                returnObjects: true,
            }) || []
        );
    }, [t]);

    return (
        <>
            <LspDialog open>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <LspTypography
                            variant="title2"
                            textCenter
                            className={classes.headerTitle}
                        >
                            {t("overdraft:overdraft_early_closure_title")}
                        </LspTypography>
                        <div className={classes.headerImg}>
                            <img src={tommyIcon} alt="Tomi Looking" />
                        </div>
                    </div>
                    <div className={classes.content}>
                        <LspTypography
                            variant="heading3"
                            textCenter
                            color="grey"
                        >
                            {t("overdraft:overdraft_early_closure_fee")}
                        </LspTypography>
                        <LspTypography variant="heading1" textCenter>
                            {formatNumber(amount, false, true)}
                        </LspTypography>
                        <div className={classes.borderBottom} />
                        {earlyOverdraft.map((item, index) => (
                            <LspChecker
                                // eslint-disable-next-line react/no-array-index-key
                                key={`checkoption-${index}`}
                                label={
                                    <LspTypography color="grey" variant="body2">
                                        {item}
                                    </LspTypography>
                                }
                                emptyIcon={
                                    <Icon
                                        className="font-icon icon-info-v2"
                                        fontSize="small"
                                    />
                                }
                            />
                        ))}
                    </div>
                    <LspButton fullWidth onClick={handleClose}>
                        {t("overdraft:overdraft_explore_cta")}
                    </LspButton>
                </div>
            </LspDialog>
        </>
    );
};

export default OverdraftPreviewEarly;
