import { memo, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import PayAnyone from "src/containers/SpendAccount/MoveMoney/PayAnyone";
import usePathname from "@helpers/usePathname";
import { MoveMoneyType, SpendAccountNavigationKey } from "@config/constants";
import Content from "@components/Content";
import PayeeList from "./PayeeList";
import MoveMoneyChannelSelect from "./MoveMoneyChannelSelect";
import InternalTransfer from "./InternalTransfer";
import DebitCardTransfer from "./DebitCardTransfer";
import BankAccountTransfer from "./BankAccountTransfer";
import CreditCardTransfer from "./CreditCardTransfer";

const DefaultRoute = memo(() => (
    <>
        <MoveMoneyChannelSelect />
        <PayeeList />
    </>
));

const parentName = `spend-account/${SpendAccountNavigationKey.MoveMoney}`;

const MoveMoney = () => {
    const { generateChildPathname } = usePathname();

    const childRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(parentName, MoveMoneyType.Internal),
                component: InternalTransfer,
            },
            {
                path: generateChildPathname(
                    parentName,
                    MoveMoneyType.DebitCard
                ),
                component: DebitCardTransfer,
            },
            {
                path: generateChildPathname(
                    parentName,
                    MoveMoneyType.BankAccount
                ),
                component: BankAccountTransfer,
            },
            {
                path: generateChildPathname(
                    parentName,
                    MoveMoneyType.PayAnyone
                ),
                component: PayAnyone,
            },
            {
                path: generateChildPathname(parentName, MoveMoneyType.Credit),
                component: CreditCardTransfer,
            },
        ],
        [generateChildPathname]
    );

    return (
        <Content>
            <Switch>
                {childRoutes.map((r) => (
                    <Route key={r.path} path={r.path} component={r.component} />
                ))}
                <Route component={DefaultRoute} />
            </Switch>
        </Content>
    );
};

export default memo(MoveMoney);
