import { memo, useEffect, useMemo, useState, useRef } from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import systemParamsAction from "@redux/actions/system-params";
import accountAction from "@redux/actions/account";
import LoadingScreen from "@components/LoadingScreen";
import userAction from "@redux/actions/user";
import { AccountStatus, AppActionType, FetchStatus } from "@config/constants";
import quickCodeActions from "@redux/actions/quick-code";
import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import Maintenance from "@containers/Maintenance";
import Translate from "@i18n/translate";
import { isEmpty } from "lodash";
import bank from "@redux/actions/bank";
import overdraftAction from "@redux/actions/overdraft";
import useAccounts from "@helpers/useAccounts";

import useStatus from "@helpers/useStatus";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AgreeTnc from "@components/AgreeTnc";
import UserRoutes from "./UserRoutes";
import GuestRoutes from "./GuestRoutes";

export const history = createBrowserHistory();

const Initialization = ({
    appStatus,
    appTriggered,
    clientAuth,
    isAuthenticated,
    getSystemParams,
    systemParams,
    systemParamsFetchStatus,
    getAccountStatus,
    accountStatus,
    getAccountList,
    user,
    userFetchStatus,
    getUserProfile,
    accountList,
    accountListFetchStatus,
    checkQuickCodeExistence,
    quickCodeExistenceCheckStatus,
    quickCodeExisting,
    getPhoneConfigs,
    getServerTime,
    getFeatureState,
    bankList,
    provinceList,
    branchList,
    bankListData,
    provinceListData,
    branchListData,
    getOverdraftDetail,
    branchListDataStatus,
    provinceListDataStatus,
    bankListDataStatus,
    accountStatusFetchStatus,
}) => {
    const [initializedVariables, setInitializedVariables] = useState([]);
    const { agreeTNCStatus } = useStatus();
    const tncShown = useRef(false);

    const clientAuthenticated = useMemo(
        () => clientAuth === "token.x-timo-devicekey",
        [clientAuth]
    );

    const appReady = useMemo(
        () => appStatus === AppActionType.Start && appTriggered,
        [appStatus, appTriggered]
    );

    const accessible = useMemo(
        () =>
            appStatus !== AppActionType.Stop &&
            clientAuthenticated &&
            [AccountStatus.TimoIn, AccountStatus.TimoOut].includes(
                accountStatus
            ) &&
            quickCodeExisting,
        [appStatus, clientAuthenticated, accountStatus, quickCodeExisting]
    );

    const showLoadingScreen = useMemo(
        () =>
            appStatus !== AppActionType.Stop &&
            initializedVariables.length < 5 &&
            clientAuthenticated,
        [appStatus, initializedVariables, clientAuthenticated]
    );

    const overdraftDetailLoaded = useRef(false);
    const { isLockedOverDraft } = useAccounts();

    useEffect(() => {
        return () => {
            tncShown.current = false;
        };
    }, []);

    useEffect(() => {
        if (
            !initializedVariables.includes("accountStatus") &&
            [AccountStatus.TimoIn, AccountStatus.TimoOut].includes(
                accountStatus
            )
        ) {
            setInitializedVariables(["accountStatus"]);
        }
    }, [initializedVariables, accountStatus]);

    useEffect(() => {
        if (!initializedVariables.includes("quickCodeExisting")) {
            if (quickCodeExistenceCheckStatus === FetchStatus.Success) {
                if (quickCodeExisting) {
                    setInitializedVariables([
                        ...initializedVariables,
                        "quickCodeExisting",
                    ]);
                } else {
                    // Setup quick code flow
                    QuickCodeDialogController.create();
                }
            }
        }
    }, [
        initializedVariables,
        quickCodeExisting,
        quickCodeExistenceCheckStatus,
    ]);

    useEffect(() => {
        if (
            !initializedVariables.includes("systemParams") &&
            !!systemParams &&
            systemParamsFetchStatus === FetchStatus.Success
        ) {
            setInitializedVariables([...initializedVariables, "systemParams"]);
        }
    }, [initializedVariables, systemParams, systemParamsFetchStatus]);

    useEffect(() => {
        if (
            !initializedVariables.includes("user") &&
            !!user &&
            userFetchStatus === FetchStatus.Success
        ) {
            setInitializedVariables([...initializedVariables, "user"]);
        }
    }, [initializedVariables, user, userFetchStatus]);

    useEffect(() => {
        if (
            !initializedVariables.includes("accountList") &&
            !!accountList &&
            accountListFetchStatus === FetchStatus.Success
        ) {
            setInitializedVariables([...initializedVariables, "accountList"]);
        }
    }, [initializedVariables, accountList, accountListFetchStatus]);

    // First init
    useEffect(() => {
        if (
            appReady &&
            clientAuthenticated &&
            agreeTNCStatus &&
            !accountStatusFetchStatus
        ) {
            getAccountStatus();
            Translate.initTranslate();
        }
    }, [
        appReady,
        clientAuthenticated,
        getAccountStatus,
        agreeTNCStatus,
        accountStatusFetchStatus,
    ]);

    const resetTncShown = () => {
        tncShown.current = false;
    };

    useEffect(() => {
        if (!agreeTNCStatus && !tncShown.current && clientAuthenticated) {
            GlobalDialogController.show({
                component: () => <AgreeTnc onReset={resetTncShown} />,
            });
            tncShown.current = true;
        }
    }, [agreeTNCStatus, clientAuthenticated]);

    // get account status loaded ==> this code will be affected
    useEffect(() => {
        if (appReady && isAuthenticated && agreeTNCStatus) {
            checkQuickCodeExistence();
        }
    }, [appReady, isAuthenticated, checkQuickCodeExistence, agreeTNCStatus]);

    // get lock type if overdraft status was locked
    useEffect(() => {
        if (isLockedOverDraft && !overdraftDetailLoaded.current) {
            overdraftDetailLoaded.current = true;
            getOverdraftDetail();
        }
    }, [isLockedOverDraft, getOverdraftDetail]);

    useEffect(() => {
        if (appReady && accessible) {
            getSystemParams();
            getAccountList();
            getUserProfile();
            getPhoneConfigs();
            getServerTime();
            getFeatureState();
        }
    }, [
        appReady,
        accessible,
        getSystemParams,
        getAccountList,
        getUserProfile,
        getPhoneConfigs,
        getServerTime,
        getFeatureState,
    ]);

    useEffect(() => {
        if (
            !quickCodeExistenceCheckStatus &&
            !systemParams &&
            !user &&
            !accountList &&
            initializedVariables.length > 0
        ) {
            setInitializedVariables([]);
        }
    }, [
        quickCodeExistenceCheckStatus,
        systemParams,
        user,
        accountList,
        initializedVariables,
    ]);

    useEffect(() => {
        if (appReady && accessible) {
            if (isEmpty(bankListData) && branchListDataStatus === null) {
                bankList();
            }
        }
    }, [accessible, appReady, bankList, bankListData, branchListDataStatus]);

    useEffect(() => {
        if (appReady && accessible) {
            if (isEmpty(provinceListData) && provinceListDataStatus === null) {
                provinceList();
            }
        }
    }, [
        accessible,
        appReady,
        provinceList,
        provinceListData,
        provinceListDataStatus,
    ]);

    useEffect(() => {
        if (appReady && accessible) {
            if (isEmpty(branchListData) && bankListDataStatus === null) {
                branchList();
            }
        }
    }, [accessible, appReady, bankListDataStatus, branchList, branchListData]);

    return (
        <>
            <LoadingScreen open={showLoadingScreen} />
            {!showLoadingScreen && (
                <AppRouter authenticated={accessible} user={user} />
            )}
        </>
    );
};

const AppRouter = memo(({ authenticated }) => {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/maintenance" component={Maintenance} />
                {authenticated ? <UserRoutes /> : <GuestRoutes />}
            </Switch>
        </Router>
    );
});

const mapState = (state) => ({
    appStatus: state.app.status,
    appTriggered: state.app.triggered,
    isAuthenticated: state.auth.isAuthenticated,
    systemParams: state.systemParams.info,
    systemParamsFetchStatus: state.systemParams.status,
    accountStatus: state.account.accountStatus,
    accountStatusFetchStatus: state.account.accountStatusFetchStatus,
    user: state.user.info,
    userFetchStatus: state.user.status,
    accountList: state.account.list,
    accountListFetchStatus: state.account.status,
    quickCodeExistenceCheckStatus: state.quickCodeInfo.existenceCheckStatus,
    quickCodeExisting: state.quickCodeInfo.existing,
    bankListData: state.bank.bankList.data,
    bankListDataStatus: state.bank.bankList.status,
    provinceListData: state.bank.provinceList.data,
    provinceListDataStatus: state.bank.provinceList.status,
    branchListData: state.bank.branchList.data,
    branchListDataStatus: state.bank.branchList.status,
});

const mapDispatch = (dispatch) => ({
    getAccountStatus: () => dispatch(accountAction.getAccountStatusRequest()),
    checkQuickCodeExistence: () =>
        dispatch(quickCodeActions.checkQuickCodeExistenceRequest()),
    getSystemParams: () =>
        dispatch(systemParamsAction.getSystemParamsRequest()),
    getAccountList: () => dispatch(accountAction.getAccountListRequest()),
    getUserProfile: () => dispatch(userAction.getProfileRequest()),
    getPhoneConfigs: () => dispatch(systemParamsAction.getPhoneConfigs()),
    getServerTime: () => dispatch(userAction.getServerTimeRequest()),
    getFeatureState: () => dispatch(userAction.getFeatureStateRequest()),
    bankList: () => dispatch(bank.getBankList()),
    provinceList: () => dispatch(bank.getProvinceList()),
    branchList: () => dispatch(bank.getBranchList()),
    getOverdraftDetail: () => dispatch(overdraftAction.getOverdraftDetail()),
});

export default connect(mapState, mapDispatch)(Initialization);
