import produce from "immer";
import { isArray } from "lodash";
import { WealthInsuranceActionType } from "../actions/wealth-insurance";

/* ------------- Initial State ------------- */
const initialState = {
    destination: null,
    travelDate: {
        fromDate: null,
        toDate: null,
    },
    attendant: {
        type: null,
        adult: 1,
        child: 1,
    },
    packageList: null,
    selectedPackage: null,
    ownerPolicy: null,
    dependentPolicy: null,
    formDependentPolicy: null,
};

/* ------------- Reducer ------------- */
const wealthInsuranceReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case WealthInsuranceActionType.SetDestination:
                draft.destination = action.payload;
                break;
            case WealthInsuranceActionType.SetTravelDate:
                draft.travelDate = action.payload;
                break;
            case WealthInsuranceActionType.SetAttendantInfo:
                draft.attendant = action.payload;
                break;
            case WealthInsuranceActionType.SetPackageList:
                draft.packageList = action.payload;
                break;
            case WealthInsuranceActionType.SetSelectedPackage:
                draft.selectedPackage = action.payload;
                break;
            case WealthInsuranceActionType.SetOwnerPolicy:
                draft.ownerPolicy = action.payload;
                break;
            case WealthInsuranceActionType.SetDependentPolicy:
                draft.dependentPolicy = action.payload;
                break;
            case WealthInsuranceActionType.SetDependentPolicyForm:
                draft.formDependentPolicy = action.payload;
                break;
            case WealthInsuranceActionType.Reset: {
                const params = action.payload;
                if (params && isArray(params)) {
                    for (let i = 0; i < params.length; i++) {
                        draft[params[i]] = initialState[params[i]];
                    }
                } else {
                    const stateNames = Object.keys(initialState);
                    stateNames.forEach((name) => {
                        draft[name] = initialState[name];
                    });
                }
                break;
            }
            default:
                break;
        }
    });

export default wealthInsuranceReducer;
