import LspTypography from "@components/LspTypography";
import { Box, Divider, Icon } from "@material-ui/core";
import { memo } from "react";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import LspBox from "@components/LspBox";
import ProfileFetchingForm from "@containers/Profile/ProfileFetchingForm";
import { useTranslation } from "react-i18next";

const ProfileFetching = () => {
    const { t } = useTranslation();
    const classes = useProfileSharedClasses();

    return (
        <>
            <LspBox className={classes.wrapperBox}>
                <Box className={classes.headerWrapper}>
                    <Icon
                        className={`font-icon icon-accountinfo ${classes.headerIcon}`}
                    />
                    <LspTypography variant="title2" className={classes.header}>
                        {t("user_profile_account_info_lb")}
                    </LspTypography>
                </Box>

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />
            </LspBox>
            <LspBox className={classes.wrapperBox}>
                <Box className={classes.headerWrapper}>
                    <Icon
                        className={`font-icon icon-accountdetail ${classes.headerIcon}`}
                    />
                    <LspTypography variant="title2" className={classes.header}>
                        {t("profile_lb_account_detail")}
                    </LspTypography>
                </Box>

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />
            </LspBox>
            <LspBox className={classes.wrapperBox}>
                <Box className={classes.headerWrapper}>
                    <Icon
                        className={`font-icon icon-personalinfo ${classes.headerIcon}`}
                    />
                    <LspTypography variant="title2" className={classes.header}>
                        {t("user_profile_personal_info_lb")}
                    </LspTypography>
                </Box>

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />

                <Divider className={classes.divider} />

                <ProfileFetchingForm />
            </LspBox>
        </>
    );
};
export default memo(ProfileFetching);
