import { memo, useMemo } from "react";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Paper, makeStyles } from "@material-ui/core";
import banner from "@assets/images/rocket_banner.png";
import useAccounts from "@helpers/useAccounts";
import LspButton from "./LspButton";
import LspTypography from "./LspTypography";
import Content from "./Content";
import { CloseIcon } from "./LspIcon";

const useStyles = makeStyles((theme) => ({
    banner: {
        width: "100%",
    },
    wrapper: {
        position: "relative",
        padding: theme.spacing(3),
        height: "calc(100vh - 32px)",
        overflow: "hidden",
        margin: theme.spacing(2, 0),
        display: "flex",
        flexDirection: "column",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
    },
    desc: {
        whiteSpace: "pre-line",
        marginTop: theme.spacing(1),
    },
    nextButton: {},
    content: {
        flex: 1,
        overflow: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
}));

const OverdraftIntroDialog = ({ onClose, onNext }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isOverDraftUser } = useAccounts();

    const onCloseHandler = () => {
        if (onClose && isFunction(onClose)) {
            onClose();
        }
    };

    const onNextHandler = () => {
        if (onNext && isFunction(onNext)) {
            onNext();
        }
    };

    const bodyContent = useMemo(() => {
        return (
            t("overdraft:overdraft_intro_faqs", { returnObjects: true }) || []
        );
    }, [t]);

    return (
        <Content size="sm">
            <Paper className={classes.wrapper}>
                <IconButton
                    className={classes.closeButton}
                    onClick={onCloseHandler}
                >
                    <CloseIcon />
                </IconButton>
                <div className={classes.content}>
                    <img src={banner} alt="banner" className={classes.banner} />
                    <LspTypography variant="title1" textCenter>
                        {t("overdraft:overdraft_intro_header")}
                    </LspTypography>
                    <Box marginBottom={3}>
                        {bodyContent?.map((line, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box key={index} mt={2.5}>
                                    <LspTypography variant="heading1">
                                        {line?.title}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body2"
                                        className={classes.desc}
                                        color="grey"
                                    >
                                        {line?.description}
                                    </LspTypography>
                                </Box>
                            );
                        })}
                    </Box>
                    <LspTypography variant="body2">
                        {t("overdraft:overdraft_intro_note")}
                    </LspTypography>
                </div>
                <div className={classes.nextButton}>
                    {onNext && (
                        <LspButton fullWidth mt={3} onClick={onNextHandler}>
                            {isOverDraftUser
                                ? t("overdraft:overdraft_threshold_notice_cta")
                                : t("overdraft:overdraft_intro_cta")}
                        </LspButton>
                    )}
                </div>
            </Paper>
        </Content>
    );
};

export default memo(OverdraftIntroDialog);
