import { Icon, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import lightLogo from "@assets/images/logo-light.png";
import paoLogo from "@assets/images/logo-pao.png";
import whiteLogo from "@assets/images/white_logo.png";
import { BrandingIcon } from "./LspIcon";
/**
 * @param {Object} props
 * @param {("default"|"light"|"pao"|"white")} props.variant
 * @param {string} props.className
 * @param {function} props.onClick
 */
const LogoComponent = ({ variant, className, onClick }) => {
    const { t } = useTranslation();
    const mainLogo = useMemo(() => t("ekyc:timo_logo"), [t]);

    const src = useMemo(() => {
        switch (variant) {
            case "light":
                return lightLogo;
            case "pao":
                return paoLogo;
            case "white":
                return whiteLogo;
            case "default":
            default:
                return mainLogo;
        }
    }, [variant, mainLogo]);

    return (
        <img
            alt="logo"
            src={src}
            className={className}
            onClick={onClick}
            style={onClick ? { cursor: "pointer" } : {}}
        />
    );
};

LogoComponent.propTypes = {
    variant: PropTypes.oneOf(["default", "light", "pao", "white"]),
    className: PropTypes.string,
    onClick: PropTypes.func,
};

LogoComponent.defaultProps = {
    variant: "default",
    className: "",
    onClick: undefined,
};

const useStyles = makeStyles((theme) => ({
    logoMarkRoot: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "50%",
    },
}));

/**
 * @param {Object} props
 * @param {("outlined"|"filled")} props.variant
 * @param {string} props.className
 * @param {function} props.onClick
 */
const LogoMarkComponent = ({ variant, className, onClick }) => {
    const classes = useStyles();

    return (
        <>
            {variant === "filled" && (
                <Icon
                    className={clsx(
                        className,
                        "font-icon icon-Logo-Payee",
                        classes.logoMarkRoot
                    )}
                    onClick={onClick}
                />
            )}

            {variant !== "filled" && (
                <BrandingIcon
                    className={clsx(
                        className,
                        "font-icon",
                        classes.logoMarkRoot
                    )}
                    onClick={onClick}
                />
            )}
        </>
    );
};

LogoMarkComponent.propTypes = {
    variant: PropTypes.oneOf(["outlined", "filled"]),
    className: PropTypes.string,
    onClick: PropTypes.func,
};

LogoMarkComponent.defaultProps = {
    variant: "outlined",
    className: "",
    onClick: undefined,
};

export const Logo = memo(LogoComponent);
export const LogoMark = memo(LogoMarkComponent);
