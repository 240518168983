import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const BillHistoryItemLoading = ({ classes }) => {
    return (
        <div className={`${classes.historiesColumn} ${classes.historiesInfo}`}>
            <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
        </div>
    );
};

export default memo(BillHistoryItemLoading);
