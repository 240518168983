import Banner from "@components/Banner";
import Content from "@components/Content";
import ContentWrapper from "@components/ContentWrapper";
import { Box } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import BankQRCodeSection from "./BankQRCodeSection";

const BankQRCode = ({ featureState }) => {
    const { t } = useTranslation();

    return (
        <>
            <Banner
                title={t("global_share_bank_detail_label")}
                subtitle={t("global_share_bank_detail_desc_label")}
            />
            <ContentWrapper>
                <Content size="sm">
                    <Box display="flex" justifyContent="flex-start">
                        <BankQRCodeSection
                            enableSelectAccount={
                                featureState?.enableAliasPhase2
                            }
                            hiddenUserInfo={featureState?.enableAliasPhase2}
                            enableFullName={featureState?.enableAliasPhase2}
                        />
                    </Box>
                </Content>
            </ContentWrapper>
        </>
    );
};
const stateProps = (state) => ({
    featureState: state.user.featureState.data,
});

const dispatchProps = () => ({});
export default memo(connect(stateProps, dispatchProps)(BankQRCode));
