import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";
import LspAddress from "@components/LspAddress";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { REGEX_PATTERN } from "@config/constants";
import { BOOKING_STEPS } from "@containers/Booking/constant";
import useAddress from "@helpers/useAddress";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { Box } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { useState, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const defaultErrors = {
    province: "",
    district: "",
    ward: "",
    address: "",
};
const CreateBookingOtherAddress = ({
    onCancel,
    onBack,
    onNext,
    setBookingAddress,
    selectedAddress,
}) => {
    const { t } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const { joinAddress } = useAddress();

    const [errors, setErrors] = useState(defaultErrors);
    const [addressName, setAddressName] = useState(
        selectedAddress?.address || ""
    );
    const [selectedProvince, setSelectedProvince] = useState({
        cfgKey: selectedAddress?.province?.cfgKey,
        cfgValue: selectedAddress?.province?.cfgValue,
    });
    const [selectedDistrict, setSelectedDistrict] = useState({
        cfgKey: selectedAddress?.district?.cfgKey,
        cfgValue: selectedAddress?.district?.cfgValue,
    });
    const [selectedWard, setSelectedWard] = useState({
        cfgKey: selectedAddress?.ward?.cfgKey,
        cfgValue: selectedAddress?.ward?.cfgValue,
    });

    const onChangeWard = (ward) => {
        setErrors((prev) => ({
            ...prev,
            ward: "",
        }));
        setSelectedWard(ward);
    };

    const onChangeDistrict = (district) => {
        setErrors((prev) => ({
            ...prev,
            district: "",
        }));
        setSelectedDistrict(district);
    };

    const onChangeProvince = (province) => {
        setErrors((prev) => ({
            ...prev,
            province: "",
        }));
        setSelectedProvince(province);
    };

    const onChangeAddress = (e) => {
        setErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressName(address);
        if (!address) {
            setErrors((prev) => ({
                ...prev,
                address: t("msg_we_need_this"),
            }));
        }
    };

    const onBackHandler = () => {
        setBookingAddress({});
        onBack();
    };

    const onSubmitHandler = useCallback(
        (e) => {
            e.preventDefault();
            if (
                !addressName ||
                !selectedProvince.cfgKey ||
                !selectedDistrict.cfgKey ||
                !selectedWard.cfgKey
            ) {
                setErrors({
                    address: !addressName ? t("msg_we_need_this") : "",
                    province: !selectedProvince.cfgKey
                        ? t("msg_we_need_this")
                        : "",
                    district: !selectedDistrict.cfgKey
                        ? t("msg_we_need_this")
                        : "",
                    ward: !selectedWard.cfgKey ? t("msg_we_need_this") : "",
                });
                return;
            }

            setBookingAddress({
                fullAddress: joinAddress({
                    address: addressName,
                    province: selectedProvince?.cfgValue,
                    district: selectedDistrict?.cfgValue,
                    ward: selectedWard?.cfgValue,
                }),
                province: selectedProvince,
                district: selectedDistrict,
                ward: selectedWard,
                address: addressName,
            });
            onNext(BOOKING_STEPS.DELIVERY_CONFIRMATION);
        },
        [
            addressName,
            selectedProvince,
            selectedDistrict,
            selectedWard,
            joinAddress,
            setBookingAddress,
            onNext,
            t,
        ]
    );

    const buttons = useMemo(() => {
        return [
            {
                label: t("lb_next"),
                onClick: onSubmitHandler,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ];
    }, [t, onCancel, onSubmitHandler]);

    return (
        <Content size="sm">
            <form onSubmit={onSubmitHandler}>
                <PageHeaderBack onBack={onBackHandler}>
                    {t("booking_add_location_title")}
                </PageHeaderBack>
                <Box>
                    <LspTextField
                        autoFocus
                        name="addressName"
                        error={!!errors?.address}
                        label={t("bill_lb_address")}
                        helperText={errors?.address || " "}
                        inputProps={{
                            maxLength: 35,
                        }}
                        onChange={onChangeAddress}
                        value={addressName}
                    />
                    <LspAddress
                        selectedProvince={selectedProvince}
                        onChangeProvince={onChangeProvince}
                        selectedDistrict={selectedDistrict}
                        onChangeDistrict={onChangeDistrict}
                        selectedWard={selectedWard}
                        onChangeWard={onChangeWard}
                        errors={errors}
                        halfWidth
                    />
                </Box>
                <Box textAlign="center">
                    <LspTypography>
                        {t("booking_add_location_notice")}
                    </LspTypography>
                </Box>
                <Box mt={3}>
                    <DoubleButtons
                        primaryButton={buttons[0]}
                        secondaryButton={buttons[1]}
                    />
                </Box>
            </form>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    serviceName: state.booking.serviceName,
    selectedAddress: state.booking.address,
});

const mapDispatch = (dispatch) => ({
    setBookingAddress: (payload) =>
        dispatch(bookingActions.setBookingAddress(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingOtherAddress);
