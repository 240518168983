import { makeStyles } from "@material-ui/core";

import bill from "@assets/images/bill.png";
import done from "@assets/images/done_double_check.png";
import { memo } from "react";
import billActions from "@redux/actions/bill";
import { connect } from "react-redux";
import { SPLIT_BILL_STATUS } from "@config/constants";
import LspTypography from "@components/LspTypography";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    subSplitBillHeader: {
        minHeight: 112,
        display: "flex",
        flexDirection: "row",
        padding: "16px 16px 52px 16px",
    },
    paid: {
        backgroundColor: theme.palette.success.main,
    },
    unPaid: {
        backgroundColor: theme.palette.gold.main,
    },
    subSplitBillHeaderRight: {
        display: "inline-flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
    },
    subSplitBillHeaderLeft: {},
    subSplitBillHeaderIcon: {
        borderRadius: "50%",
        width: 42,
        height: 42,
        backgroundColor: theme.palette.neutral.white,
        display: "flex",
        justifyContent: "center",
        marginRight: theme.spacing(2.5),
    },
    imgIcon: {
        padding: theme.spacing(1.5),
        width: "100%",
        height: "100%",
    },
    subHeaderText: {
        textAlign: "left",
        color: theme.palette.neutral.white,
    },
    subHeaderName: {
        fontWeight: 600,
    },
    subHeaderDescription: {
        fontWeight: 400,
    },
    uppercase: {
        textTransform: "uppercase",
    },
    skeletonColor: {
        background: theme.palette.neutral.white,
    },
}));

const SubSplitBillHeader = ({ splitBill, fetching }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isCompleted = () => splitBill?.status === SPLIT_BILL_STATUS.COMPLETED;

    const paymentStatus = () => {
        if (isCompleted()) {
            return t("splitBill:sb_web_msg_request_completed").replace(
                "%@",
                splitBill?.senderName
            );
        }
        return t("splitBill:sb_web_msg_request_from_host").replace(
            "%@",
            splitBill?.senderName
        );
    };
    return (
        <>
            <div
                className={`${classes.subSplitBillHeader} ${
                    isCompleted() ? classes.paid : classes.unPaid
                }`}
            >
                {fetching && (
                    <>
                        <div className={classes.subSplitBillHeaderLeft}>
                            <div className={classes.subSplitBillHeaderIcon}>
                                <Skeleton
                                    animation="wave"
                                    variant="circle"
                                    width={42}
                                    height={42}
                                />
                            </div>
                        </div>
                        <div className={classes.subSplitBillHeaderRight}>
                            <Skeleton
                                animation="wave"
                                height={15}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                                animation="wave"
                                height={15}
                                width="100%"
                                style={{ marginBottom: 6 }}
                            />
                        </div>
                    </>
                )}
                {!fetching && (
                    <>
                        <div className={classes.subSplitBillHeaderLeft}>
                            <div className={classes.subSplitBillHeaderIcon}>
                                {!isCompleted() && (
                                    <img
                                        className={classes.imgIcon}
                                        src={bill}
                                        alt="Bill Icon"
                                    />
                                )}
                                {isCompleted() && (
                                    <img
                                        className={classes.imgIcon}
                                        src={done}
                                        alt="Done Icon"
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.subSplitBillHeaderRight}>
                            <LspTypography
                                className={`${classes.subHeaderText} ${classes.subHeaderName}`}
                                variant="subheading3"
                                color="black"
                            >
                                {paymentStatus()}
                            </LspTypography>

                            {splitBill?.description && (
                                <LspTypography
                                    className={`${classes.subHeaderText} ${classes.subHeaderDescription}`}
                                    variant="body2"
                                    color="black"
                                >
                                    {splitBill?.description}
                                </LspTypography>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const stateProps = (state) => ({
    splitBill: state.bill.splitBill.bill,
    fetching: state.bill.splitBill.fetching,
});

const dispatchProps = (dispatch) => ({
    getSplitBill: () => dispatch(billActions.getSplitBill),
});

export default connect(stateProps, dispatchProps)(memo(SubSplitBillHeader));
