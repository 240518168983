import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import AccountSummaryBoxOverdraft from "@components/Account/AccountSummaryBoxOverdraft";
import Content from "@components/Content";
import TransactionGroup from "@components/TransactionList/TransactionGroup";
import {
    FetchStatus,
    SpendAccountNavigationKey,
    TransactionGroupType,
} from "@config/constants";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import accountAction from "@redux/actions/account";
import { groupBy } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const PendingPaymentList = ({
    getPayAnyoneDashboard,
    totalBalance,
    totalAmountPending,
    getPayAnyoneList,
    payAnyoneListFetchStatus,
    payAnyoneList,
    featureState,
    updateHideBalanceOption,
    hiddenBalanceOption,
}) => {
    const { t } = useTranslation("translation", "payAnyone");
    const { formatNumber } = useNumber();
    const lastId = useRef(null);
    const history = useHistory();

    const {
        isBalanceHidden,
        getCurrentBalance,
        getAvailableToSpend,
    } = useAccounts();

    const availableToSpend = getAvailableToSpend(true);

    const updatedHiddenOption = useRef(false);

    const isEnableOverdraft = useMemo(() => featureState?.enableOverDraft, [
        featureState?.enableOverDraft,
    ]);

    const currentBalance = getCurrentBalance(true);

    const subItems = useMemo(() => {
        // eslint-disable-next-line prefer-const
        let items = [
            {
                id: "currentBalance",
                name: t("overdraft:overdraft_current_balance"),
                value: formatNumber(currentBalance),
                valueColor: "primary",
                primary: true,
            },
            {
                id: "availableToSpend",
                name: t("overdraft:overdraft_available_to_spend"),
                value: formatNumber(availableToSpend),
                tooltipText: t(
                    "overdraft:overdraft_available_to_spend_tooltip"
                ),
            },
        ];

        if (totalAmountPending && totalAmountPending > 0) {
            items.push({
                id: "pendingAmount",
                name: t("payAnyone:dashboard_pending_payment"),
                value: formatNumber(totalAmountPending),
            });
        }
        return items;
    }, [currentBalance, t, availableToSpend, totalAmountPending, formatNumber]);

    // reset state for balance option when unmounted
    useEffect(() => {
        if (!updatedHiddenOption.current) {
            updateHideBalanceOption(isBalanceHidden);
            updatedHiddenOption.current = true;
        }

        return () => {
            updateHideBalanceOption(isBalanceHidden);
            updatedHiddenOption.current = false;
        };
    }, [isBalanceHidden, updateHideBalanceOption]);

    const hiddenBalanceConfig = useMemo(() => {
        return {
            enableHideBalance: true,
            balanceOption: hiddenBalanceOption,
            toggleBalanceFunc: updateHideBalanceOption,
        };
    }, [updateHideBalanceOption, hiddenBalanceOption]);

    useEffect(() => {
        getPayAnyoneDashboard();
    }, [getPayAnyoneDashboard]);

    useEffect(() => {
        getPayAnyoneList({ lastId });
    }, [getPayAnyoneList]);

    useEffect(() => {
        if (payAnyoneListFetchStatus === FetchStatus.Success) {
            const newLastId = payAnyoneList[payAnyoneList.length]?.paId;
            if (newLastId !== lastId.current) {
                lastId.current = newLastId;
            }
        }
    }, [payAnyoneListFetchStatus, payAnyoneList]);

    const groupedList = useMemo(() => {
        return groupBy(payAnyoneList, "paDate");
    }, [payAnyoneList]);
    const groupNames = useMemo(() => Object.keys(groupedList), [groupedList]);

    const onItemClick = (detail) => {
        history.push(
            `/spend-account/${SpendAccountNavigationKey.PendingPayment}/${SpendAccountNavigationKey.PaymentDetail}/${detail.paId}`
        );
    };

    return (
        <Content>
            {!isEnableOverdraft && (
                <AccountSummaryBox
                    leftItemName={t("payAnyone:total_balance")}
                    rightItemName={t("payAnyone:dashboard_pending_payment")}
                    rightItemColor="blue"
                    leftItemValue={formatNumber(totalBalance)}
                    rightItemValue={formatNumber(totalAmountPending)}
                    leftItemId="availableBalance"
                    rightItemId="pendingAmount"
                    hiddenBalanceConfig={hiddenBalanceConfig}
                />
            )}

            {isEnableOverdraft && (
                <AccountSummaryBoxOverdraft
                    hiddenBalanceConfig={hiddenBalanceConfig}
                    items={subItems}
                />
            )}

            {groupNames.map((gn) => (
                <TransactionGroup
                    key={gn}
                    header={gn.split(" ")[0]}
                    items={groupedList[gn]}
                    type={TransactionGroupType.PayAnyone}
                    onItemClick={onItemClick}
                    hiddenRemainBalanceOption={hiddenBalanceOption}
                />
            ))}
        </Content>
    );
};

const mapState = (state) => ({
    totalBalance: state.account.payAnyoneDashboard.totalBalance,
    totalAmountPending: state.account.payAnyoneDashboard.totalAmountPending,
    payAnyoneListFetchStatus: state.account.payAnyoneList.status,
    payAnyoneList: state.account.payAnyoneList.items,
    featureState: state.user.featureState.data,
    hiddenBalanceOption: state.account.spendAccount.hiddenBalance,
});

const mapDispatch = (dispatch) => ({
    getPayAnyoneDashboard: () =>
        dispatch(accountAction.getPayAnyoneDashboardRequest()),
    getPayAnyoneList: () => dispatch(accountAction.getPayAnyoneListRequest()),
    updateHideBalanceOption: (payload) =>
        dispatch(accountAction.updateHideBalanceOption(payload)),
});

export default connect(mapState, mapDispatch)(PendingPaymentList);
