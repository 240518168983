import { call, put, select } from "redux-saga/effects";
import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import topUpService from "@services/top-up";
import LspTranslation from "@components/LspTranslation";
import accountAction from "@redux/actions/account";
import topUpAction from "../actions/top-up";

const getDialogOpened = (state) => state.otpDialog.opened;
const getMyPhoneNumber = (state) => state.user.info.phoneNumber;

export function* getContactListSaga(action) {
    const { payload } = action;
    const response = yield call(topUpService.getContacts, payload);
    const { code, data } = response.data;

    // if (response.ok) {
    const myPhoneNumber = yield select(getMyPhoneNumber);

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.NOT_FOUND_IN_DATABASE:
            const myPhoneNo = {
                indexPhoneNo: "",
                phoneNo: myPhoneNumber,
                isMyPhoneNo: true,
            };

            const contacts = [myPhoneNo, ...data.results];

            yield put(topUpAction.getContactListSuccess(contacts));
            yield put(topUpAction.updatePhoneNumber(myPhoneNumber));
            yield put(topUpAction.updateSelectedPhoneObject(contacts[0]));
            break;
        default:
            yield put(topUpAction.getContactListError());
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
    // } else {
    //     GlobalDialogController.showError({
    //         errorCode: response.status,
    //     });
    //     yield put(topUpAction.GetContactListError());
    // }
}

export function* getPhoneConfigsSaga() {
    const response = yield call(topUpService.getPhoneConfigs);
    const { code, data } = response.data;

    // if (response.ok) {
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(topUpAction.getPhoneConfigsSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
    // } else {
    //     GlobalDialogController.showError({
    //         errorCode: response.status,
    //     });
    // }
}

export function* topUpSaga(action) {
    const { payload } = action;
    const response = yield call(topUpService.topUpRequest, payload);
    const { code, data } = response.data;

    // if (response.ok) {
    const opened = yield select(getDialogOpened);

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            // Show receipt
            yield put(accountAction.getAccountListRequest());
            break;
        case RESPONSE_CODE.OTP_REQUIRED:
            yield put(topUpAction.topUpWithOTP(data));
            if (opened) {
                OTPController.hide();
            } else {
                OTPController.show(OTPDialogType.TopUp);
            }
            break;
        case RESPONSE_CODE.DUPLICATE_FIELD:
            AlertController.show(
                <LspTranslation i18nKey="topup_msg_existing_recipient_name" />,
                AlertType.Error
            );
            yield put(topUpAction.topUpError());
            break;
        case RESPONSE_CODE.NO_ENOUGH_MONEY:
        case RESPONSE_CODE.NOT_ALLOWED:
            AlertController.show(
                <LspTranslation i18nKey="topup_msg_invalid_amount" />,
                AlertType.Error
            );
            yield put(topUpAction.topUpError());
            break;
        default:
            GlobalDialogController.showError({
                errorCode: code,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(topUpAction.topUpError());
            break;
    }
    // } else {
    //     GlobalDialogController.showFinancialError({
    //         errorCode: response.status,
    //     });
    //     yield put(topUpAction.topUpError());
    // }
}

export function* topUpOtpCommitSaga(action) {
    const { payload } = action;
    const response = yield call(topUpService.otpCommit, payload);
    const { code, data } = response.data;

    // if (response.ok) {
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            OTPController.hide();
            yield put(topUpAction.topUpSuccess(data));
            yield put(accountAction.getAccountListRequest());
            break;
        default:
            GlobalDialogController.showError({
                errorCode: code,
                type: GlobalDialogTypes.FinancialError,
            });
            yield put(topUpAction.topUpError());
            break;
    }
    // } else {
    //     GlobalDialogController.showFinancialError({
    //         errorCode: response.status,
    //     });
    //     yield put(topUpAction.topUpError());
    // }
}

export function* deletePhoneNumberSaga(action) {
    const { payload } = action;
    const response = yield call(topUpService.deletePhone, payload);
    const { code } = response.data;

    // if (response.ok) {
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(topUpAction.deletePhoneSuccess());
            yield put(topUpAction.getContactList());
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(topUpAction.deletePhoneError());
            break;
    }
    // } else {
    //     GlobalDialogController.showError({
    //         errorCode: response.status,
    //     });
    //     yield put(topUpAction.deletePhoneError());
    // }
}
