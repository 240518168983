import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    item: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        width: "33.3%",
        alignItems: "space-between",
        justifyContent: "center",
        "& img": {
            height: 42,
        },
    },
}));

const NewBillProviders = ({ fetching, providerList, onCancel, onNext }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const fetchingLayer = useMemo(() => {
        return (
            <>
                {[...new Array(6)]?.map((_, index) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <ButtonBase className={classes.item} key={index}>
                            <Box>
                                <Skeleton
                                    variant="rect"
                                    width={42}
                                    height={42}
                                />
                            </Box>
                            <Box>
                                <LspTypography variant="body3">
                                    <Skeleton width={100} />
                                </LspTypography>
                            </Box>
                        </ButtonBase>
                    );
                })}
            </>
        );
    }, [classes]);

    return (
        <Content size="sm">
            <Box className={classes.wrapper}>
                {fetching && fetchingLayer}
                {!fetching &&
                    providerList?.map((item) => {
                        return (
                            <ButtonBase
                                key={item?.providerId}
                                className={classes.item}
                                onClick={() => onNext(item)}
                            >
                                <Box>
                                    <img
                                        src={item?.imageLink}
                                        alt={`icon ${item?.providerName}`}
                                    />
                                </Box>
                                <Box>
                                    <LspTypography variant="body3">
                                        {item?.providerName}
                                    </LspTypography>
                                </Box>
                            </ButtonBase>
                        );
                    })}
            </Box>

            {onCancel && (
                <Box mt={3}>
                    <LspButton fullWidth onClick={onCancel}>
                        {t("lb_cancel")}
                    </LspButton>
                </Box>
            )}
        </Content>
    );
};

export default memo(NewBillProviders);
