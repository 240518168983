import LspTranslation from "@components/LspTranslation";
import { RESPONSE_CODE } from "@config/constants";
import { TDSpecialRate } from "@containers/TermDeposit/PartialWithdraw/tdHelper";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import termDepositAction from "@redux/actions/term-deposit";
import termDepositService from "@services/term-deposit";
import { call, put } from "redux-saga/effects";

export function* getTernDepositRates(action) {
    const { payload } = action;

    const response = yield call(
        termDepositService.getTermDepositRates,
        payload
    );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(termDepositAction.getTermDepositRatesSuccess(data));
            break;
        default:
            yield put(termDepositAction.getTermDepositRatesErrors());
            break;
    }
}

export function* getTernDepositDetail(action) {
    const { payload } = action;

    const response = yield call(termDepositService.getTermDeposit, payload);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                termDepositAction.getTermDepositDetailSuccess(data?.detail)
            );
            break;
        default:
            yield put(termDepositAction.getTermDepositDetailErrors());
            break;
    }
}

export function* validateSpecialRate(action) {
    const { payload } = action;

    const response = yield call(
        termDepositService.validationSpecialRate,
        payload
    );

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.VALIDATION_SPECIAL_RATE:
            yield put(termDepositAction.validateSpecialRateError());
            TDSpecialRate();
            break;
        case RESPONSE_CODE.SUCCESS:
            yield put(
                termDepositAction.validateSpecialRateSuccess({
                    tdId: payload?.termDepositId,
                })
            );
            break;
        default:
            yield put(termDepositAction.validateSpecialRateError());
            break;
    }
}

export function* previewPartialRate(action) {
    const { payload } = action;

    const response = yield call(termDepositService.previewSpecialRate, payload);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(termDepositAction.previewPartialTdSuccess(data));
            break;
        default:
            yield put(termDepositAction.previewPartialTdError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* partialTD(action) {
    const { payload } = action;

    const response = yield call(termDepositService.partialWithDrawTd, payload);

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.TD_ERROR:
            AlertController.show(
                <LspTranslation i18nKey="msg_error_500" />,
                AlertType.Error
            );
            yield put(termDepositAction.partialTDErrors());
            break;
        case RESPONSE_CODE.SUCCESS:
            yield put(termDepositAction.partialTDSuccess(data));
            break;
        default:
            yield put(termDepositAction.partialTDErrors());
            GlobalDialogController.showFinancialError({
                errorCode: code,
            });
            break;
    }
}

export function* earlyRedeemTD(action) {
    const { payload } = action;

    const response = yield call(
        termDepositService.getEarlyRedemptionData,
        payload
    );

    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(termDepositAction.earlyRedeemTDSuccess(data));
            break;
        default:
            yield put(termDepositAction.earlyRedeemTDErrors());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* checkAutoDeposit(action) {
    const response = yield call(termDepositService.checkAutoDeposit);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                termDepositAction.checkAutoDepositSuccess(data?.isExisted)
            );
            break;
        default:
            yield put(termDepositAction.checkAutoDepositError());
            break;
    }
}

export function* getAutoDeposit(action) {
    const { payload } = action;

    const response = yield call(termDepositService.getAutoDeposit, payload);

    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(
                termDepositAction.getAutoDepositListSuccess(
                    data?.recurringList || []
                )
            );
            break;
        default:
            yield put(termDepositAction.getAutoDepositListError());
            break;
    }
}
