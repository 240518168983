import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import produce from "immer";
import { FetchStatus } from "../../config/constants";
import { QuickCodeActionTypes } from "../actions/quick-code";

export const quickCodeInitialize = {
    opened: false, // for dialog quick code required
    submitting: false,
    quickCode: "",
    password: "",
    activeStepTmp: null,
    existenceCheckStatus: null,
    existing: false,
    successMsg: null,
    isActionAfterClose: false,
    type: "",
    loaded: false,
};

const quickCodeReducer = (state = quickCodeInitialize, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            /* #region ------------- SetActiveStep ------------- */
            case QuickCodeActionTypes.SetActiveStep:
                draft.activeStepTmp = action.payload;
                break;
            case QuickCodeActionTypes.SetActiveStepSuccess:
                draft.activeStepTmp = null;
                break;

            /* #endregion ---------- END SetActiveStep ---------- */

            /* #region ------------- OpenQuickCodeDialog ------------- */
            case QuickCodeActionTypes.OpenQuickCodeDialog:
                draft.opened = true;
                draft.type = action.payload;
                break;
            case QuickCodeActionTypes.CloseQuickCodeDialog:
                draft.type = quickCodeInitialize.type;
                draft.opened = quickCodeInitialize.opened;
                draft.isActionAfterClose =
                    quickCodeInitialize.isActionAfterClose;
                draft.loaded = quickCodeInitialize.loaded;

                draft.submitting = quickCodeInitialize.submitting;
                draft.quickCode = quickCodeInitialize.quickCode;
                draft.password = quickCodeInitialize.password;
                draft.successMsg = quickCodeInitialize.successMsg;
                draft.existenceCheckStatus =
                    quickCodeInitialize.existenceCheckStatus;
                // draft.existing = quickCodeInitialize.existing;
                QuickCodeDialogController.resetStatusInfo();
                break;
            /* #endregion ---------- END OpenQuickCodeDialog ---------- */

            /* #region ------------- CreateNewQuickCode ------------- */
            case QuickCodeActionTypes.CreateNewQuickCode:
                draft.submitting = true;
                break;
            case QuickCodeActionTypes.CreateNewQuickCodeSuccess:
                draft.submitting = false;
                draft.existing = true;
                break;
            case QuickCodeActionTypes.CreateNewQuickCodeError:
                draft.submitting = false;
                break;
            /* #endregion ---------- END CreateNewQuickCode ---------- */

            /* #region ------------- CheckValidQuickCode ------------- */
            case QuickCodeActionTypes.CheckValidQuickCode:
                draft.submitting = true;
                break;
            case QuickCodeActionTypes.CheckValidQuickCodeSuccess:
                draft.submitting = false;
                draft.isActionAfterClose = true;
                QuickCodeDialogController.resetStatusInfo();
                draft.loaded = quickCodeInitialize.loaded;
                draft.opened = quickCodeInitialize.opened;
                break;
            case QuickCodeActionTypes.CheckValidQuickCodeError:
                draft.submitting = false;
                draft.quickCode = "";
                break;
            /* #endregion ---------- END CheckValidQuickCode ---------- */

            /* #region ------------- ForgotQuickCode ------------- */
            case QuickCodeActionTypes.ForgotQuickCode:
                draft.submitting = true;
                break;
            case QuickCodeActionTypes.ForgotQuickCodeSuccess:
                draft.submitting = false;
                break;
            case QuickCodeActionTypes.ForgotQuickCodeError:
                draft.submitting = false;
                break;
            /* #endregion ---------- END ForgotQuickCode ---------- */

            /** *** CheckQuickCodeExistence **** */
            case QuickCodeActionTypes.CheckQuickCodeExistence:
                draft.existenceCheckStatus = FetchStatus.Pending;
                draft.existing = false;
                break;
            case QuickCodeActionTypes.CheckQuickCodeExistenceSuccess:
                draft.existenceCheckStatus = FetchStatus.Success;
                draft.existing = action.payload;
                break;
            case QuickCodeActionTypes.CheckQuickCodeExistenceError:
                draft.existenceCheckStatus = FetchStatus.Error;
                break;
            case QuickCodeActionTypes.SetPassword:
                draft.password = action.payload;
                break;
            default:
                break;
        }
    });
export default quickCodeReducer;
