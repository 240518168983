import api from "@config/api";

const getContacts = async () => {
    return await api.get("/user/topup/contacts", {});
};

const getPhoneConfigs = async () => {
    return await api.get("/user/topup/config/amountList", {});
};

const deletePhone = async (payload) => {
    return await api.delete("/user/topup/contacts", null, { data: payload });
};

const topUpRequest = async (payload) => {
    return await api.post("/user/topup/request", payload);
};

const otpCommit = async (payload) => {
    return await api.post("/user/topup/complete", payload);
};

const topUpService = {
    getContacts,
    getPhoneConfigs,
    deletePhone,
    topUpRequest,
    otpCommit,
};

export default topUpService;
