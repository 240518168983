import api from "@config/api";

const initToken = async (token) => {
    return await api.post(
        "/login/reset/password/verify",
        {},
        {
            headers: {
                token,
            },
        }
    );
};

const setNewPassword = async (newPassword, token) => {
    return await api.post(
        "/login/reset/password/submit",
        { newPassword },
        {
            headers: {
                token,
            },
        }
    );
};

const commitOTP = async (payload) => {
    return await api.post("/login/reset/password/verifyOTP", payload);
};
const forgotPassordService = {
    initToken,
    setNewPassword,
    commitOTP,
};

export default forgotPassordService;
