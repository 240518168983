import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Grid, Icon } from "@material-ui/core";
import userService from "@services/user";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JSEncrypt } from "jsencrypt/bin/jsencrypt";
import sha512 from "crypto-js/sha512";
import useOTP from "@helpers/useOTP";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";

const defaultValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAPK);

const ProfilePasswordForm = ({ systemParam }) => {
    const { t } = useTranslation();
    const classes = useProfileSharedClasses();

    const [successMessage, setSuccessMessage] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const unmounted = useRef(false);

    const { register, handleSubmit, errors, getValues, setError } = useForm({
        defaultValues,
    });

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const toggleEditHandler = (e) => {
        e.preventDefault();
        setIsEdit((prev) => !prev);
    };

    const isDisabledEditPassword = useMemo(() => {
        return (
            systemParam?.disablePasswordUpdate === true ||
            systemParam?.disablePasswordUpdate === "true" ||
            systemParam?.disablePasswordUpdate === 1 ||
            systemParam?.disablePasswordUpdate === "1"
        );
    }, [systemParam?.disablePasswordUpdate]);

    const commitOtp = async (dataToPost) => {
        setLoading(true);

        const response = await userService.commitOtpUpdatePassword(dataToPost);

        if (unmounted.current) {
            return;
        }
        setLoading(false);
        const { code } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            closeOTPDialog();
            setIsEdit(false);
            setSuccessMessage(t("profile_msg_update"));
        } else {
            GlobalDialogController.showError({ errorCode: code });
        }
    };

    const onSubmitForm = async () => {
        if (errors.length > 0) {
            return;
        }
        setIsUpdating(true);

        const currentPassword = getValues("currentPassword");
        const newPassword = getValues("newPassword");

        const encryptedCurrentPassword = sha512(currentPassword).toString();
        const encryptedNewPassword = crypt.encrypt(newPassword);

        const response = await userService.updatePassword({
            currentPassword: encryptedCurrentPassword,
            newPassword: encryptedNewPassword,
            confirmNewPassword: encryptedNewPassword,
        });
        if (unmounted.current) return;

        setIsUpdating(false);

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.refNo,
                    submitFunc: commitOtp,
                });
                break;
            case RESPONSE_CODE.INCORRECT_VALUE:
                setError("currentPassword", {
                    type: "manual",
                    message: t("wrong_password"),
                });
                break;
            case RESPONSE_CODE.MISMATCH_VALUE:
                setError("confirmNewPassword", {
                    type: "manual",
                    message: t("user_profile_new_password_un_match"),
                });
                break;
            case RESPONSE_CODE.PASSWORD_POLICIES_VIOLATED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Error",
                        header: "popup_title_security",
                        content: "password_rule_msg",
                        button: "lb_ok",
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const newPasswordValidation = () => {
        const currentPassword = getValues("currentPassword");
        const newPassword = getValues("newPassword");
        if (currentPassword === newPassword) {
            return t("join_msg_new_password_same_old_password");
        }
    };

    const confirmNewPasswordValidation = () => {
        const newPassword = getValues("newPassword");
        const confirmNewPassword = getValues("confirmNewPassword");
        if (!!confirmNewPassword && newPassword !== confirmNewPassword) {
            return t("user_profile_new_password_un_match");
        }
    };

    return (
        <form
            className={classes.formWrapper}
            onSubmit={handleSubmit(() => onSubmitForm())}
        >
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-password ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("lb_password")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        ******
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    {!isDisabledEditPassword && (
                        <ProfileEditComponent
                            isEdit={isEdit}
                            onEdit={toggleEditHandler}
                            onCancel={toggleEditHandler}
                            updating={isUpdating}
                        />
                    )}
                </div>
            </div>

            {isEdit && (
                <Grid container spacing={2} style={{ marginTop: "16px" }}>
                    <Grid item sm={4} xs={12}>
                        <LspTextField
                            autoFocus
                            name="currentPassword"
                            error={!!errors.currentPassword}
                            label={t("user_profile_current_password_lbl")}
                            helperText={errors.currentPassword?.message || " "}
                            inputProps={{
                                maxLength: 32,
                                type: "password",
                                ref: register({
                                    required: t("msg_we_need_this"),
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD,
                                        message: t(
                                            "user_profile_password_invalid"
                                        ),
                                    },
                                    minLength: {
                                        value: 8,
                                        message: t("min_length_character"),
                                    },
                                }),
                            }}
                            disabled={isUpdating}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <LspTextField
                            name="newPassword"
                            error={!!errors.newPassword}
                            label={t("user_profile_new_password_lbl")}
                            helperText={errors.newPassword?.message || " "}
                            inputProps={{
                                maxLength: 32,
                                type: "password",
                                ref: register({
                                    required: t("msg_we_need_this"),
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD,
                                        message: t("global_msg_password_error"),
                                    },
                                    minLength: {
                                        value: 8,
                                        message: t("join_msg_password_invalid"),
                                    },
                                    maxLength: {
                                        value: 32,
                                        message: t("join_msg_password_invalid"),
                                    },
                                    validate: () => newPasswordValidation(),
                                }),
                            }}
                            disabled={isUpdating}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <LspTextField
                            name="confirmNewPassword"
                            error={!!errors.confirmNewPassword}
                            label={t("user_profile_reenter_password_lbl")}
                            helperText={
                                errors.confirmNewPassword?.message || " "
                            }
                            inputProps={{
                                maxLength: 32,
                                type: "password",
                                ref: register({
                                    required: t("msg_we_need_this"),
                                    pattern: {
                                        value: REGEX_PATTERN.PASSWORD,
                                        message: t("join_msg_password_invalid"),
                                    },
                                    minLength: {
                                        value: 8,
                                        message: t("global_msg_password_error"),
                                    },
                                    maxLength: {
                                        value: 32,
                                        message: t("global_msg_password_error"),
                                    },
                                    validate: () =>
                                        confirmNewPasswordValidation(),
                                }),
                            }}
                            disabled={isUpdating}
                        />
                    </Grid>
                </Grid>
            )}
        </form>
    );
};

export default memo(ProfilePasswordForm);
