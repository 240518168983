import querystring from "query-string";
import Loading from "@components/Loading";
import {
    DOCUMENT_ERROR_TYPE,
    FetchStatus,
    RESPONSE_CODE,
} from "@config/constants";
import useTermDeposit from "@containers/TermDeposit/OpenTermDeposit/useTermDeposit";
import useNumber from "@helpers/useNumber";
import {
    Box,
    makeStyles,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import termDepositService from "@services/term-deposit";
import clsx from "clsx";
import { addMonths, differenceInDays, parse } from "date-fns";
import { useCallback, useEffect, useRef, useState, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import termDepositAction from "@redux/actions/term-deposit";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => ({
    tableHeadCell: {
        textTransform: "uppercase",
        fontWeight: 600,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        "&:first-child": {
            paddingLeft: theme.spacing(2),
        },
        "&:last-child": {
            paddingRight: 0,
        },
    },
    tableCell: {
        padding: theme.spacing(0, 1),
        "&:last-child": {
            paddingRight: theme.spacing(0.5),
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(0, 2),
            "&:last-child": {
                paddingRight: theme.spacing(1),
            },
        },
    },
    tableRow: {
        cursor: "pointer",
        "&:hover": {
            opacity: theme.palette.opacity.hover,
        },
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
        "&.disabled": {
            opacity: theme.palette.opacity.disabled,
            cursor: "not-allowed",
        },
    },
}));

const TermSelection = ({
    user,
    serverTime,
    amount,
    selectedTerm,
    setSelectedTerm,
    termDepositRate,
    getTermRates,
    termDepositRateStatus,
    termDepositRateFetch,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { termId: termIdAutofill } = params;
    const { t } = useTranslation();
    const { parseNumber, formatNumber } = useNumber();
    const [rates, setRates] = useState([]);

    const [specialRates, setSpecialRates] = useState(0);

    const { checkVisaIsValid } = useTermDeposit();

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const isMathSpecialRate = useMemo(() => {
        return (
            termDepositRate?.isAvailableTdRate &&
            parseNumber(amount.value) >= specialRates
        );
    }, [
        amount.value,
        parseNumber,
        specialRates,
        termDepositRate?.isAvailableTdRate,
    ]);

    const checkVisaValidity = useCallback(() => {
        if (!user.isForeigner) {
            return { valid: true };
        }

        const visaToDate = user?.visaDateTo
            ? parse(user?.visaDateTo, "yyyy-MM-dd", new Date())
            : null;

        const result = checkVisaIsValid(visaToDate, serverTime);

        return {
            valid: result?.type === DOCUMENT_ERROR_TYPE.VISA_DATE_VALID,
            error: result?.type,
            monthCountUntilExpiry: result?.remainingMonths,
        };

        // const visaDateFrom = parse(user.visaDateFrom, "yyyy-MM-dd", new Date());
        // const visaDateTo = parse(user.visaDateTo, "yyyy-MM-dd", new Date());
        // const minValidMonth = 6;
        // const visaValidMonth = differenceInMonths(visaDateTo, visaDateFrom);

        // if (visaValidMonth < minValidMonth) {
        //     return {
        //         valid: false,
        //         error: "visa life time is less than 6 months",
        //     };
        // }

        // const dayCountUntilExpiry = Math.ceil(
        //     differenceInHours(visaDateTo, serverTime) / 24
        // );

        // if (dayCountUntilExpiry >= 30) {
        //     return {
        //         valid: true,
        //         monthCountUntilExpiry: dayCountUntilExpiry / 30,
        //     };
        // }

        // if (dayCountUntilExpiry > 0) {
        //     return {
        //         valid: false,
        //         error: "visa remaining life time is less than a month",
        //     };
        // }

        // return { valid: false, error: "visa is expired" };
    }, [user, serverTime, checkVisaIsValid]);

    const checkTermAvailability = useCallback(
        ({ month }) => {
            const visa = checkVisaValidity();
            if (!visa.valid) {
                return false;
            }
            if (visa.valid && !visa.monthCountUntilExpiry) {
                return true;
            }
            if (month <= visa.monthCountUntilExpiry) {
                return true;
            }
            return false;
        },
        [checkVisaValidity]
    );

    const getFinalAmount = useCallback(
        ({ month, interestRate, interestRateSpecial }) => {
            if (!amount.value) {
                return "-";
            }
            const redemptionDate = addMonths(serverTime, month);
            const remainingDays = differenceInDays(redemptionDate, serverTime);
            const parsedAmount = parseNumber(amount.formattedValue);
            const currentRates = isMathSpecialRate
                ? interestRateSpecial || interestRate
                : interestRate;
            const decimalRate = currentRates / 100;
            const profit = parsedAmount * decimalRate * (remainingDays / 365);
            const finalAmount = Math.round(parsedAmount + profit);

            return formatNumber(finalAmount);
        },
        [
            amount.value,
            amount.formattedValue,
            serverTime,
            parseNumber,
            isMathSpecialRate,
            formatNumber,
        ]
    );

    const autofillSelectedTerm = useCallback(
        (rateList, termId) => {
            const foundItem = rateList.find(
                (item) => item.termId.toString() === termId
            );

            if (foundItem) {
                const availableTerm = checkTermAvailability(foundItem);
                if (availableTerm) {
                    setSelectedTerm(foundItem);
                }
            }
        },
        [setSelectedTerm, checkTermAvailability]
    );

    useEffect(() => {
        if (isEmpty(termDepositRate)) {
            getTermRates();
        }
    }, [getTermRates, termDepositRate]);

    useEffect(() => {
        if (
            !isEmpty(termDepositRate) &&
            termDepositRateStatus === FetchStatus.Success
        ) {
            setRates(termDepositRate.items);
            setSpecialRates(+termDepositRate.value || 0);
            if (termIdAutofill) {
                autofillSelectedTerm(termDepositRate.items, termIdAutofill);
            }
        }
    }, [
        autofillSelectedTerm,
        termDepositRate,
        termDepositRateStatus,
        termIdAutofill,
    ]);

    if (termDepositRateFetch) {
        return (
            <Box mb={3}>
                <Loading />
            </Box>
        );
    }

    return (
        <Box component={Paper} overflow="hidden" mb={3}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                classes={{
                                    root: classes.tableHeadCell,
                                }}
                                align="center"
                            >
                                {t("termDeposit_lb_months")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.tableHeadCell,
                                }}
                                align="center"
                            >
                                {t("termDeposit_lb_annual_interest_rate")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.tableHeadCell,
                                }}
                                align="center"
                            >
                                {t("termDeposit_lb_balance_redeem_date")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.tableHeadCell,
                                }}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rates || []).map((term) => {
                            const isDisabled = !checkTermAvailability(term);
                            const selectTerm = () => {
                                if (isDisabled) {
                                    return;
                                }
                                setSelectedTerm(term);
                            };
                            return (
                                <TableRow
                                    key={term.termId}
                                    onClick={selectTerm}
                                    className={clsx(classes.tableRow, {
                                        disabled: isDisabled,
                                    })}
                                >
                                    <TableCell
                                        classes={{
                                            root: classes.tableCell,
                                        }}
                                        align="center"
                                    >
                                        {term.month}
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCell,
                                        }}
                                        align="center"
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color={
                                                isMathSpecialRate &&
                                                term.interestRateSpecial
                                                    ? "success"
                                                    : "black"
                                            }
                                        >
                                            {isMathSpecialRate
                                                ? formatNumber(
                                                      term.interestRateSpecial ||
                                                          term.interestRate
                                                  )
                                                : formatNumber(
                                                      term.interestRate
                                                  )}
                                            %
                                        </LspTypography>
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCell,
                                        }}
                                        align="center"
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color={
                                                isMathSpecialRate &&
                                                term.interestRateSpecial
                                                    ? "success"
                                                    : "black"
                                            }
                                        >
                                            {getFinalAmount(term)}
                                        </LspTypography>
                                    </TableCell>
                                    <TableCell
                                        classes={{
                                            root: classes.tableCell,
                                        }}
                                    >
                                        <Radio
                                            size="small"
                                            color="primary"
                                            disabled={isDisabled}
                                            checked={
                                                selectedTerm === term.termId
                                            }
                                            onChange={selectTerm}
                                            value={term.termId}
                                            datatestid={`termMonth${term.month}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    serverTime: state.user.serverTime.value,
    termDepositRate: state.termDeposit.termRates.data,
    termDepositRateStatus: state.termDeposit.termRates.status,
    termDepositRateFetch: state.termDeposit.termRates.fetching,
});

const dispatchProps = (dispatch) => ({
    getTermRates: () => dispatch(termDepositAction.getTermDepositRates()),
});
export default connect(mapState, dispatchProps)(memo(TermSelection));
