/* eslint-disable react/no-array-index-key */
import { OVERDRAFT_LOCK_TYPES } from "@config/constants";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon, makeStyles } from "@material-ui/core";
import LspChecker from "@components/LspChecker";
import { isArray, isFunction } from "lodash";
import OverdraftStatus from "@containers/Overdraft/Component/OverdraftStatus";
import LspTypography from "@components/LspTypography";
import GlobalDialogController from "./controllers/GlobalDialogController";
import useNumber from "./useNumber";

const useStyles = makeStyles((theme) => ({
    lockStatus: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    lockStatusContent: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
    },
    wrapperContent: {
        padding: 0,
        marginTop: `-${theme.spacing(2)}px`,
        "& > div": {
            paddingTop: 0,
            paddingBottom: theme.spacing(1),
        },
    },
    checkerWrapper: {
        alignItems: "flex-start",
        marginTop: theme.spacing(1),
    },
    iconClass: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        "& span": {
            fontSize: "1em",
        },
    },
}));

const useOverdraft = () => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const overdraftDetails = useSelector((state) => state.overdraft.detail);

    const lockOverdraftDetail = ({
        title,
        subtitle,
        desc,
        button,
        onSubmit,
    }) => {
        const hostUrl = process.env.REACT_APP_API_URL;

        console.log("title", title);
        console.log("subtitle", subtitle);
        console.log("desc", desc);
        console.log("button", button);
        console.log("onSubmit", onSubmit);

        const content = desc?.map((item, index) => {
            return (
                <LspChecker
                    label={item}
                    emptyIcon={<Icon fontSize="small">👉</Icon>}
                    key={index}
                    wrapperClass={classes.checkerWrapper}
                    iconClass={classes.iconClass}
                />
            );
        });

        const onSubmitHandler = () => {
            if (onSubmit && isFunction(onSubmit)) {
                onSubmit();
            }
            GlobalDialogController.hide();
        };

        const overdraftStatus = (
            <div className={classes.lockStatus}>
                <OverdraftStatus
                    wrapperContentClass={classes.wrapperContent}
                    src={`${hostUrl}/cxpassets/frontend/images/pfm/tomi/cry_a_river.png`}
                    title={
                        <>
                            <LspTypography
                                variant="title2"
                                textCenter
                                style={{ marginBottom: 8 }}
                            >
                                {title}
                            </LspTypography>
                            <LspTypography
                                variant="body2"
                                color="grey"
                                textCenter
                            >
                                {subtitle}
                            </LspTypography>
                        </>
                    }
                    content={
                        <div className={classes.lockStatusContent}>
                            {content}
                        </div>
                    }
                    action
                    actionTitle={button}
                    onSubmit={onSubmitHandler}
                />
            </div>
        );
        GlobalDialogController.show({
            component: () => overdraftStatus,
            size: "xs",
        });
    };

    const getLockStatusContent = useCallback(
        (lockType) => {
            const mapLockType =
                lockType === OVERDRAFT_LOCK_TYPES.SWITCH_DEBT_GROUP
                    ? OVERDRAFT_LOCK_TYPES.A_SWITCH_DEBT_GROUP
                    : lockType;

            const title = t(
                `overdraft:overdraft_lock_status_${mapLockType}_title`
            );
            const desc = t(
                `overdraft:overdraft_lock_status_${mapLockType}_description`
            );

            const hyperlink = t("overdraft:overdraft_lock_status_cta");

            const dialogContent = {
                title: t(
                    `overdraft:overdraft_lock_status_${mapLockType}_reason_title`
                ),
                subtitle: t(
                    `overdraft:overdraft_lock_status_${mapLockType}_reason_description`
                ),
                desc:
                    t(
                        `overdraft:overdraft_lock_status_${mapLockType}_reason_notes`,
                        { returnObjects: true }
                    ) || [],
                button: t("overdraft:overdraft_lock_status_got_it_btn"),
                onSubmit: () => GlobalDialogController.hide(),
            };
            switch (mapLockType) {
                case OVERDRAFT_LOCK_TYPES.A_NO_FUND_IN:
                    let bodyContent = [];
                    const outstandingAmount =
                        overdraftDetails?.outstandingAmount;

                    if (isArray(dialogContent?.desc)) {
                        bodyContent = dialogContent?.desc?.map((i) =>
                            i?.replace(
                                "%@_outstanding",
                                formatNumber(outstandingAmount)
                            )
                        );
                    }

                    return {
                        title,
                        desc,
                        hyperlink,
                        dialog: {
                            ...dialogContent,
                            desc: bodyContent,
                        },
                    };
                case OVERDRAFT_LOCK_TYPES.A_SWITCH_DEBT_GROUP:
                case OVERDRAFT_LOCK_TYPES.COPS_LOCK:
                case OVERDRAFT_LOCK_TYPES.A_EXPIRATION:
                case OVERDRAFT_LOCK_TYPES.LOCK_CLOSURE:
                case OVERDRAFT_LOCK_TYPES.FAIL_CLOSURE:
                    return {
                        title,
                        desc,
                        hyperlink,
                        dialog: dialogContent,
                    };
                default:
                    return null;
            }
        },
        [t, overdraftDetails, formatNumber]
    );

    return {
        getLockStatusContent,
        lockOverdraftDetail,
    };
};

export default useOverdraft;
