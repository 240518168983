import { useEffect, useMemo } from "react";

import "@i18n";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import viLocale from "date-fns/locale/vi";

import { connect } from "react-redux";
import ApiCancelToken from "@helpers/controllers/ApiCancelToken";
import LspSnackbarProvider from "@components/LspSnackbarProvider";
import initCreateMuiTheme from "@config/theme";
import useInitialization from "@helpers/useInitialization";
import OTPDialog from "@components/OTPDialog";
import GlobalDialog from "@components/GlobalDialog";
import appAction from "@redux/actions/app";
import { AppActionType, LANGUAGE_OPTIONS } from "@config/constants";
import authAction from "@redux/actions/auth";
import NetworkDetector from "@components/NetworkDetector";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Helmet } from "react-helmet";
import Metrics from "@config/metrics";
import { UAParser } from "ua-parser-js";
import bank from "@redux/actions/bank";
import { isEmpty } from "lodash";
import Initialization from "./Initialization";

const App = ({
    appStatus,
    appTriggered,
    startApp,
    stopApp,
    forceLogout,
    isDarkMode,
    appTheme,
}) => {
    const { i18n } = useTranslation();
    const clientAuth = useInitialization();
    const theme = initCreateMuiTheme({
        themeName: appTheme,
        darkMode: isDarkMode,
    });
    const { os } = UAParser();

    useEffect(() => {
        if (appStatus === AppActionType.Stop) {
            stopApp();
        } else if (appStatus === AppActionType.Start && !appTriggered) {
            startApp();
        }
    }, [appStatus, appTriggered, startApp, stopApp]);

    useEffect(() => {
        ApiCancelToken.setForceLogout(forceLogout);
    }, [forceLogout]);

    const pickerLocale = useMemo(() => {
        if (i18n.language === LANGUAGE_OPTIONS.EN) {
            return enLocale;
        }
        return viLocale;
    }, [i18n]);

    const isMobile = useMemo(() => {
        return os.name === "Android" || os.name === "iOS";
    }, [os]);

    return (
        <>
            <Helmet
                title={process.env.REACT_APP_NAME}
                meta={[
                    {
                        name: `theme-color`,
                        content: theme.palette.primary.main,
                    },
                ]}
            />
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Metrics />
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={pickerLocale}
                >
                    <LspSnackbarProvider>
                        <OTPDialog />
                        <GlobalDialog />
                        <NetworkDetector />
                        {clientAuth && (
                            <>
                                {isMobile && (
                                    <DndProvider backend={TouchBackend}>
                                        <Initialization
                                            clientAuth={clientAuth}
                                        />
                                    </DndProvider>
                                )}
                                {!isMobile && (
                                    <DndProvider backend={HTML5Backend}>
                                        <Initialization
                                            clientAuth={clientAuth}
                                        />
                                    </DndProvider>
                                )}
                            </>
                        )}
                    </LspSnackbarProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </>
    );
};

const mapState = (state) => ({
    appStatus: state.app.status,
    appTriggered: state.app.triggered,
    isDarkMode: state.app.isDarkMode,
    appTheme: state.app.themeName,
});

const mapDispatch = (dispatch) => ({
    startApp: () => dispatch(appAction.start()),
    stopApp: () => dispatch(appAction.stop()),
    forceLogout: () => dispatch(authAction.forceLogout()),
});

export default connect(mapState, mapDispatch)(App);
