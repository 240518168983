import { call, put } from "redux-saga/effects";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import overdraftService from "@services/overdraft";
import overdraftAction from "@redux/actions/overdraft";

export function* getOverdraftDetailSaga() {
    const response = yield call(overdraftService.manageOverdraft);
    const { code, data } = response.data;

    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(overdraftAction.getOverdraftDetailSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(overdraftAction.getOverdraftDetailError());
            break;
    }
}
