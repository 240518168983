import { useState, useEffect } from "react";
import Fingerprint2 from "fingerprintjs2";

const generateFingerprint = (components) => {
    const values = components.map((component) => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);
    return fingerprint;
};

const useFingerprint = () => {
    const [fingerprint, setFingerprint] = useState(null);

    useEffect(() => {
        if (window.requestIdleCallback) {
            requestIdleCallback(() => {
                Fingerprint2.get((components) => {
                    const fgp = generateFingerprint(components);
                    setFingerprint(fgp);
                });
            });
        } else {
            setTimeout(() => {
                Fingerprint2.get((components) => {
                    const fgp = generateFingerprint(components);
                    setFingerprint(fgp);
                });
            }, 500);
        }
    }, []);

    return fingerprint;
};

export default useFingerprint;
