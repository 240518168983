import api from "@config/api";

const getDocumentList = async (lang) => {
    return await api.get(`/document/request/${lang}`, null);
};

const getDocumentTemplate = async (lang, id) => {
    return await api.get(`/document/template/request/${lang}`, {
        docId: id,
    });
};

const getInfoTransactionID = async (transactionID) => {
    return await api.get(`/user/account/transaction/${transactionID}`, null);
};

const getDocumentOptionList = async (id, lang) => {
    return await api.get(`/document/receive/${lang}`, {
        docId: id,
    });
};

const submitFeeDocument = async (data) => {
    return await api.post(`/document/request`, data);
};

const commitOTP = async (data) => {
    return await api.post(`/document/request/commit`, data);
};

const getDocumentAccount = async (lang, id) => {
    return await api.get(`/document/detail/request/${lang}`, {
        docId: id,
    });
};

const requestDocumentService = {
    getDocumentList,
    getDocumentTemplate,
    getInfoTransactionID,
    getDocumentOptionList,
    submitFeeDocument,
    commitOTP,
    getDocumentAccount,
};

export default requestDocumentService;
