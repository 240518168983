/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Paper, Icon } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import { format, parse } from "date-fns";
import { vi, enUS } from "date-fns/locale";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(1.5),
        cursor: "pointer",
        "& > div": {
            display: "flex",
            justifyContent: "space-between",
        },
        "& > div:first-child": {
            marginBottom: theme.spacing(1.5),
        },

        "& > div:not(:first-child)": {
            marginTop: theme.spacing(1.5),
        },
    },
    line: {
        width: "100%",
        backgroundColor: theme.palette.grey[200],
        height: 1,
    },
    icon: {
        " & > i": {
            display: "block",
        },
    },
    month: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 2),
    },
    period: {
        "&::first-letter": {
            textTransform: "uppercase",
        },
    },
}));
const LastStatementItem = ({ item, showDetail }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { formatNumber } = useNumber();
    const formatDate = (value) => {
        return format(parse(value, "yyyyMM", new Date()), "MMMM", {
            locale: i18n.language === "vn" ? vi : enUS,
        });
    };

    return (
        <Box mb={2}>
            <LspTypography
                variant="subheading1"
                color="black"
                className={classes.month}
            >
                {item.year}
            </LspTypography>
            {item.statements.map((value, i) => (
                <Paper
                    key={i}
                    className={classes.item}
                    onClick={() => showDetail(value)}
                >
                    <div>
                        <LspTypography
                            variant="subheading2"
                            color="black"
                            className={classes.period}
                        >
                            {formatDate(value.id)}
                        </LspTypography>
                        <Icon className={classes.icon}>
                            <i className="font-icon icon-right-open-big" />
                        </Icon>
                    </div>
                    <div className={classes.line} />
                    <div>
                        <LspTypography variant="body1" color="grey">
                            {t("creditCard:stm_closing_balance")}
                        </LspTypography>

                        <LspTypography variant="body1" color="grey">
                            {formatNumber(value.closingBalance)}
                        </LspTypography>
                    </div>
                    <div>
                        <LspTypography variant="body1" color="grey">
                            {t("creditCard:stm_min_payment")}
                        </LspTypography>

                        <LspTypography variant="body1" color="grey">
                            {formatNumber(value.minimumPayment)}
                        </LspTypography>
                    </div>
                </Paper>
            ))}
        </Box>
    );
};

export default LastStatementItem;
