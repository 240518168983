import api from "../config/api";

const getReceipt = async (payload) => {
    return await api.post("/user/account/transaction/receipt", payload);
};

const submitNotes = async (payload) => {
    return await api.post("/user/account/transaction/note", payload);
};

const modifyNotes = async (payload) => {
    const { fileManageId, ...restProps } = payload;
    return await api.put(
        `/user/account/transaction/note/${fileManageId}`,
        restProps
    );
};

const paymentDetail = async (payload) => {
    return await api.post(
        "/user/account/transaction/receipt/groupFee",
        payload
    );
};

const submitAttachment = async (payload) => {
    return await api.post("/user/account/transaction/attachment", payload, {
        headers: { "content-type": "multipart/form-data" },
    });
};

const modifyAttachment = async (payload) => {
    const { fileManageId, ...restProps } = payload;
    return await api.put(
        `/user/account/transaction/attachment/${fileManageId}`,
        restProps.file,
        { headers: { "content-type": "multipart/form-data" } }
    );
};

const deleteAttachment = async (payload) => {
    const { fileManageId, ...restProps } = payload;
    return await api.delete(
        `/user/account/transaction/attachment/${fileManageId}`,
        {},
        { data: restProps }
    );
};

const lspReceiptService = {
    getReceipt,
    submitNotes,
    paymentDetail,
    modifyNotes,
    submitAttachment,
    deleteAttachment,
    modifyAttachment,
};

export default lspReceiptService;
