import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { memo, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 8,
        backgroundColor: theme.palette.magicalGrey[100],
    },
    bar: {
        height: 8,
        backgroundColor: theme.palette.primary.main,
    },
    inactive: {
        backgroundColor: theme.palette.grey[600],
    },
}));

const TermDepositProgressBar = ({ value, maxValue, inactive }) => {
    const classes = useStyles();

    const barWidth = useMemo(() => {
        if (!maxValue) return "0%";

        if (inactive || value >= maxValue || value <= 0) {
            return "100%";
        }

        const percent = (1 - value / maxValue) * 100;
        return `${percent}%`;
    }, [inactive, value, maxValue]);

    return (
        <div className={classes.root}>
            <div
                className={clsx(classes.bar, { [classes.inactive]: inactive })}
                style={{ width: barWidth }}
            />
        </div>
    );
};

export default memo(TermDepositProgressBar);
