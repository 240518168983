import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "@assets/images/logo.png";
import LspTypography from "@components/LspTypography";
import DownloadApp from "@components/DownloadApp";

const useStyles = makeStyles((theme) => ({
    join: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: theme.palette.grey.A400,
        textAlign: "center",
    },
    colorBar: {
        height: theme.spacing(0.8),
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
        "& div": {
            width: "25%",
        },
        "& div:nth-child(1)": { background: theme.palette.primary.main },
        "& div:nth-child(2)": { background: theme.palette.blue.main },
        "& div:nth-child(3)": { background: theme.palette.orange.main },
        "& div:nth-child(4)": { background: theme.palette.gold.main },
    },
    title: {
        marginTop: "8px",
        marginBottom: "8px",
    },
    logo: {
        height: theme.spacing(6),
        marginTop: "8px",
        marginBottom: "8px",
    },
}));

const ClaimMoneyJoinTimo = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.join}>
            <div className={classes.colorBar}>
                <div />
                <div />
                <div />
                <div />
            </div>
            <LspTypography variant="title2" className={classes.title}>
                {t("payAnyone:pao_download_app1")}
            </LspTypography>
            <img className={classes.logo} src={logo} alt="logo" />
            <LspTypography variant="title2" className={classes.title}>
                {t("payAnyone:pao_download_app2")}
            </LspTypography>
            <Box marginTop={2}>
                <DownloadApp size="small" />
            </Box>
        </div>
    );
};

export default ClaimMoneyJoinTimo;
