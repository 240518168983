/* eslint-disable camelcase */
import { memo, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import CardChangePin from "@containers/CardManagement/CardChangePin";
import { connect } from "react-redux";
import CardManagementActions from "@redux/actions/card-management";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

import useAccounts from "@helpers/useAccounts";
import tommy from "@assets/images/tommy_no_hope.png";
import useCard from "@helpers/useCard";
import {
    CardManagementNavigation,
    CARD_MANAGEMENT_SUB_NAV,
    CARD_TYPES,
} from "./constant";
import CardResetPin from "./CardResetPin";
import CardDetail from "./CardDetail";
import CardReIssue from "./CardReIssue";
import CardActivation from "./CardActivation";
import CardLimit from "./CardLimit";

const useStyles = makeStyles(() => ({
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const CardManagement = ({
    resetGetCardListStatus,
    cardList,
    cardListStatus,
    user,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { search } = useLocation();
    const { isCardFeatureNotAvailableForeigner } = useAccounts();

    const { isCreditCard } = useCard();

    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    // const directed = useRef(false);

    const isActivateVirtualVisaDebitPage = useCallback((cardType, pathName) => {
        return (
            (cardType ===
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD ||
                CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP) &&
            pathName === CardManagementNavigation.ActivateCard
        );
    }, []);

    const isDebitPage = useCallback((cardType, pathName) => {
        return (
            (cardType === CARD_MANAGEMENT_SUB_NAV.DEBIT || cardType === null) &&
            (pathName === CardManagementNavigation.Debit ||
                pathName === CardManagementNavigation.ResetPin ||
                pathName === CardManagementNavigation.ReIssueCard ||
                pathName === CardManagementNavigation.ChangePin)
        );
    }, []);

    const isEnableDebitCardTab = useCallback(
        (cardType) => {
            return (
                isActivateVirtualVisaDebitPage(cardType, location.pathname) ||
                isDebitPage(cardType, location.pathname)
            );
        },
        [location.pathname, isActivateVirtualVisaDebitPage, isDebitPage]
    );

    const activeItem = useCallback(
        (type) => {
            const cardQueryType = queryParams.get("type");

            if (type === CardManagementNavigation.Credit) {
                return (
                    location.pathname === CardManagementNavigation.Credit ||
                    cardQueryType === CARD_MANAGEMENT_SUB_NAV.CREDIT
                );
            }

            return isEnableDebitCardTab(cardQueryType);
        },
        [isEnableDebitCardTab, location.pathname, queryParams]
    );

    const navbarItems = useMemo(
        () => [
            {
                icon: "Debitcard",
                title: "mc_lb_debit_card",
                path: CardManagementNavigation.Debit,
                selected: activeItem(CardManagementNavigation.Debit),
            },
            {
                icon: "creditcard",
                title: "mc_lb_master_card",
                path: CardManagementNavigation.Credit,
                selected: activeItem(CardManagementNavigation.Credit),
            },
        ],
        [activeItem]
    );

    const DebitCardDetail = () => <CardDetail type="debit" />;
    const CreditCardDetail = () => <CardDetail type="credit" />;

    const onDoneActivateCard = useCallback(
        (cardType) => {
            const url = isCreditCard(cardType)
                ? CardManagementNavigation.Credit
                : CardManagementNavigation.Debit;
            history.push(url);
        },
        [history, isCreditCard]
    );

    useEffect(() => {
        return () => {
            resetGetCardListStatus();
        };
    }, [resetGetCardListStatus]);

    const isForeignerWarning = useMemo(() => {
        return (
            isCardFeatureNotAvailableForeigner &&
            cardListStatus === "success" &&
            (!cardList || cardList?.length <= 0)
        );
    }, [isCardFeatureNotAvailableForeigner, cardList, cardListStatus]);

    // User re-issue card and waiting for new card
    const isWarningReIssueCard = useMemo(() => {
        return (
            user?.currentOnboardingType === "NormalKYC" &&
            cardListStatus === "success" &&
            (!cardList || cardList?.length <= 0)
        );
    }, [user, cardList, cardListStatus]);

    // Handler for case foreigner with field thoi han cu tru lien tuc > 360 (sbs-1341)
    // Set default active tab for current active card
    // useEffect(() => {
    //     if (
    //         directed.current ||
    //         !isCardFeatureNotAvailableForeigner ||
    //         cardListStatus !== "success" ||
    //         cardList?.length === 0
    //     ) {
    //         return;
    //     }

    //     const isExistDebit = cardList?.findIndex(
    //         (item) =>
    //             item.cardType === CARD_TYPES.DEBIT_CARD ||
    //             item.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD
    //     );

    //     if (isExistDebit > 0) {
    //         return;
    //     }
    //     directed.current = true;
    //     history.push("/card-management/credit");
    // }, [history, cardListStatus, isCardFeatureNotAvailableForeigner, cardList]);

    // sbs-1341
    useEffect(() => {
        if (isForeignerWarning) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconCustom: tommy,
                    header: "regulation:manage_card_pop_up_unqualified_title",
                    content: "regulation:manage_card_pop_up_unqualified_desc",
                    button: "regulation:manage_card_pop_up_unqualified_CTA",
                    navigatePathForButton: "/home",
                },
                onClose: GlobalDialogController.hide(),
            });
            return;
        }

        if (isWarningReIssueCard) {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    header: "ekyc:sorry_title",
                    content: "mc_ms_cannot_find_cif_number",
                    button: "lb_ok",
                    navigatePathForButton: "/home",
                },
                onClose: GlobalDialogController.hide(),
            });
        }
    }, [isForeignerWarning, isWarningReIssueCard]);

    const customRoutes = useMemo(() => {
        return [
            {
                path: CardManagementNavigation.Debit,
                component: DebitCardDetail,
                default: true,
            },
            {
                path: CardManagementNavigation.Credit,
                component: CreditCardDetail,
            },
            {
                path: CardManagementNavigation.ResetPin,
                component: CardResetPin,
            },
            {
                path: CardManagementNavigation.ChangePin,
                component: CardChangePin,
            },
            {
                path: CardManagementNavigation.ReIssueCard,
                component: CardReIssue,
            },
            {
                path: CardManagementNavigation.CardLimit,
                component: CardLimit,
            },
            {
                path: CardManagementNavigation.ActivateCard,
                component: () => {
                    const cardQueryType = queryParams.get("type");
                    let cardType = cardQueryType;

                    switch (cardQueryType) {
                        case CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD:
                        case CARD_MANAGEMENT_SUB_NAV.PHYSICAL_VISA_DEBIT_VIRTUAL_CARD_MP:
                            break;
                        case CARD_MANAGEMENT_SUB_NAV.DEBIT:
                            cardType = CARD_TYPES.DEBIT_CARD;
                            break;
                        case CARD_MANAGEMENT_SUB_NAV.CREDIT:
                        default:
                            cardType = CARD_TYPES.VISA_CARD;
                            break;
                    }
                    return (
                        <CardActivation
                            cardType={cardType}
                            onDone={onDoneActivateCard}
                        />
                    );
                },
            },
        ];
    }, [onDoneActivateCard, queryParams]);
    const defaultRoutes = useMemo(() => customRoutes.find((r) => r.default), [
        customRoutes,
    ]);

    return (
        <>
            <Banner
                title={t("card_activation_title")}
                navbarItems={navbarItems}
            />
            <ContentWrapper className={classes.contentWrapper}>
                <Content size="xl">
                    <Switch>
                        {isForeignerWarning || isWarningReIssueCard ? (
                            <Redirect to={defaultRoutes.path} />
                        ) : (
                            <>
                                {customRoutes.map((r) => {
                                    return (
                                        <Route
                                            key={r.path}
                                            path={r.path}
                                            component={r.component}
                                        />
                                    );
                                })}
                            </>
                        )}

                        {!!defaultRoutes && (
                            <Route
                                component={() => (
                                    <Redirect to={defaultRoutes.path} />
                                )}
                            />
                        )}
                    </Switch>
                </Content>
            </ContentWrapper>
        </>
    );
};
const mapStateToProps = (state) => ({
    cardList: state.cardInfo.cardList,
    cardListStatus: state.cardInfo.cardListStatus,
    user: state.user.info,
});

const mapDispatchToProps = (dispatch) => ({
    resetGetCardListStatus: () =>
        dispatch(CardManagementActions.resetGetCardListStatus()),
});

export default memo(
    connect(mapStateToProps, mapDispatchToProps)(CardManagement)
);
