/* eslint-disable react/no-array-index-key */
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Interweave from "interweave";
import LspTypography from "@components/LspTypography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import TermConditionDialog from "@components/TermCondition/TermConditionDialog";

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetailsInstant = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3.75),
        display: "block",
        "& > ul:first-child": {
            listStyleType: "none",
            padding: 0,
            " & > li": {
                margin: theme.spacing(1.25, 0),
                "& > span:first-child": {
                    borderRadius: "50%",
                    background: theme.palette.primary.main,
                    color: theme.palette.neutral.white,
                    display: "inline-block",
                    textAlign: "center",
                    marginRight: theme.spacing(1),
                    padding: "1px 7px",
                },
            },
        },
    },
}))(MuiAccordionDetails);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3.75),
        display: "block",
        "& > ul:first-child": {
            padding: 0,
            " & > li": {
                display: "flex",
                justifyContent: "space-between",
                margin: theme.spacing(1.25, 0),
                "& > p > span > b": {
                    fontSize: theme.typography.pxToRem(18),
                },
            },
        },
        "& > ul:last-child": {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
            paddingLeft: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            listStyleType: "none",
            " & > li": {
                marginBottom: theme.spacing(0.75),
            },
        },
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    uppercase: {
        textTransform: "uppercase",
    },
    beneficiaryInfo: {
        display: "flex",
        flexWrap: "wrap",
        " & > div": {
            width: "50%",
            marginBottom: theme.spacing(1.25),
        },
    },
    footer: {
        padding: theme.spacing(3, 3.75),
        backgroundColor: theme.palette.grey[300],
    },
}));

export default function CustomizedAccordions({
    leftDetailPackage,
    leftContentTitle,
    leftDetailPackage2,
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    const openTermCondition = (item) => {
        GlobalDialogController.show({
            component: () => (
                <TermConditionDialog
                    header={item.panelBody[1]}
                    url={item.panelFooter[1]}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    return (
        <div>
            {leftDetailPackage?.length > 0 &&
                leftDetailPackage.map((item, index) => {
                    if (index === 0)
                        return (
                            index === 0 && (
                                <div key={index}>
                                    <Accordion square expanded>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <LspTypography
                                                variant="body2"
                                                color="grey"
                                            >
                                                {item.panelHeader}
                                            </LspTypography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                                {item?.panelBody?.map(
                                                    (value, key) => (
                                                        <li key={key}>
                                                            <LspTypography
                                                                variant="body2"
                                                                color="grey"
                                                            >
                                                                <Interweave
                                                                    content={
                                                                        value[0]
                                                                    }
                                                                />
                                                            </LspTypography>
                                                            <LspTypography
                                                                variant="body2"
                                                                color="grey"
                                                            >
                                                                <Interweave
                                                                    content={
                                                                        value[1]
                                                                    }
                                                                />
                                                            </LspTypography>
                                                        </li>
                                                    )
                                                )}
                                            </ul>

                                            <ul className="annotate-list">
                                                {item?.panelFooter?.map(
                                                    (value, key) => (
                                                        <li key={key}>
                                                            <LspTypography
                                                                variant="body3"
                                                                component="i"
                                                                color="grey"
                                                            >
                                                                <Interweave
                                                                    content={
                                                                        value[0]
                                                                    }
                                                                />
                                                            </LspTypography>
                                                            <LspTypography
                                                                variant="body3"
                                                                component="i"
                                                                color="grey"
                                                            >
                                                                <Interweave
                                                                    content={
                                                                        value[1]
                                                                    }
                                                                />
                                                            </LspTypography>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                        );

                    if (index === 1) {
                        return (
                            <Accordion square key={index}>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <LspTypography variant="body2" color="grey">
                                        {item.panelHeader}
                                    </LspTypography>
                                </AccordionSummary>
                                <AccordionDetailsInstant>
                                    <ul>
                                        {item.panelBody.map((value, k) => (
                                            <li key={k}>
                                                <span>{k + 1}</span>
                                                <Interweave content={value} />
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetailsInstant>
                            </Accordion>
                        );
                    }

                    return (
                        <Accordion square key={index}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <LspTypography variant="body2" color="grey">
                                    {item.panelHeader}
                                </LspTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LspTypography
                                    variant="hyperlink2"
                                    component="a"
                                    color="grey"
                                    onClick={() => openTermCondition(item)}
                                >
                                    <Interweave content={item.panelFooter[0]} />
                                </LspTypography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            {leftContentTitle && (
                <Accordion square>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <LspTypography variant="body2" color="grey">
                            {t("wealth:SI00093")}
                        </LspTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.beneficiaryInfo}>
                            <div>
                                <LspTypography
                                    variant="heading4"
                                    color="grey"
                                    className={classes.uppercase}
                                >
                                    {t("sunlife_placeholder_bene_name")}
                                </LspTypography>
                                <LspTypography variant="body1" color="grey">
                                    {leftContentTitle.beneficiary?.name}
                                </LspTypography>
                            </div>
                            <div>
                                <LspTypography
                                    variant="heading4"
                                    color="grey"
                                    className={classes.uppercase}
                                >
                                    {t("lb_gender")}
                                </LspTypography>
                                <LspTypography variant="body1" color="grey">
                                    {leftContentTitle.beneficiary?.gender}
                                </LspTypography>
                            </div>
                            <div>
                                <LspTypography
                                    variant="heading4"
                                    color="grey"
                                    className={classes.uppercase}
                                >
                                    {t("sunlife_placeholder_date_birth")}
                                </LspTypography>
                                <LspTypography variant="body1" color="grey">
                                    {leftContentTitle.beneficiary?.dateOfBirth}
                                </LspTypography>
                            </div>
                            <div>
                                <LspTypography
                                    variant="heading4"
                                    color="grey"
                                    className={classes.uppercase}
                                >
                                    {t("sunlife_placeholder_relationship")}
                                </LspTypography>
                                <LspTypography variant="body1" color="grey">
                                    {leftContentTitle.beneficiary?.relationship}
                                </LspTypography>
                            </div>
                            <div>
                                <LspTypography
                                    variant="heading4"
                                    color="grey"
                                    className={classes.uppercase}
                                >
                                    {t("sunlife_lb_id_passport")}
                                </LspTypography>
                                <LspTypography variant="body2" color="grey">
                                    {leftContentTitle.beneficiary?.legalId}
                                </LspTypography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
            {leftDetailPackage2?.length > 0 &&
                leftDetailPackage2.map((item, key) => (
                    <div key={key}>
                        <Accordion square>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <LspTypography variant="body2" color="grey">
                                    {item.panelHeader}
                                </LspTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LspTypography
                                    variant="hyperlink2"
                                    component="a"
                                    color="grey"
                                    onClick={() => openTermCondition(item)}
                                >
                                    <Interweave content={item.panelFooter[0]} />
                                </LspTypography>
                            </AccordionDetails>
                        </Accordion>
                        <div className={classes.footer}>
                            <LspTypography variant="body1" color="grey">
                                {t("wealth:SI00067")}
                            </LspTypography>
                        </div>
                    </div>
                ))}
        </div>
    );
}
