import { useCallback, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import LspButton from "@components/LspButton";
import {
    LANGUAGE_OPTIONS,
    REGEX_PATTERN,
    RESPONSE_CODE,
} from "@config/constants";
import LspTextField from "@components/LspTextField";
import ColorBar from "@components/ColorBar";
import clsx from "clsx";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import resetPasswordService from "@services/reset-password";
import ContentModal from "@components/ContentModal";
import useSharedClasses from "@helpers/styleContentModal";
import useLanguage from "@helpers/useLanguage";

import querystring from "query-string";

const useStyles = makeStyles((theme) => ({
    customColorBar: {
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(1),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    modalForm: {
        [theme.breakpoints.down("xs")]: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    modalFormContent: {
        [theme.breakpoints.down("xs")]: {
            flex: 1,
            justifyContent: "center",
            display: "inline-flex",
            flexDirection: "column",
        },
    },
}));

const ResetPassword = () => {
    const sharedClasses = useSharedClasses();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { switchLanguage } = useLanguage();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { language } = params;
    const history = useHistory();
    const [msgAfterSubmitted, setMsgAfterSubmitted] = useState("");
    const [statusAfterSubmitted, setStatusAfterSubmitted] = useState("");

    const goToLoginPage = useCallback(() => {
        history.push("/login");
    }, [history]);

    const defaultValues = {
        legalId: "",
        email: "",
    };

    const { register, handleSubmit, errors, formState } = useForm({
        defaultValues,
    });

    const currentLanguage = useMemo(() => {
        return i18n.language === LANGUAGE_OPTIONS.EN
            ? LANGUAGE_OPTIONS.EN.toUpperCase()
            : LANGUAGE_OPTIONS.VN.toUpperCase();
    }, [i18n.language]);

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const languageContent = useMemo(() => {
        const languages = Object.keys(LANGUAGE_OPTIONS).filter(
            (item) => item !== LANGUAGE_OPTIONS.VI.toUpperCase()
        );

        return languages
            .sort((a, b) => b.localeCompare(a))
            .map((item, index) => (
                <span key={Math.random()}>
                    <span
                        className={`${
                            currentLanguage === item ? "active" : ""
                        }`}
                        onClick={
                            currentLanguage !== item ? changeLanguage : null
                        }
                    >
                        {item}
                    </span>
                    {index !== languages?.length - 1 && <span>|</span>}
                </span>
            ));
    }, [currentLanguage, changeLanguage]);

    const retrieveClick = async (dataToPost) => {
        const { data } = await resetPasswordService.resetPasswordCheck(
            dataToPost
        );
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setStatusAfterSubmitted("success");
                setMsgAfterSubmitted(t("resetpassword_msg_success"));
                break;
            case RESPONSE_CODE.INVALID:
                GlobalDialogController.showError({
                    errorCode: RESPONSE_CODE.FE_USER_BLOCKED,
                });
                break;
            case RESPONSE_CODE.CODE_USER_NOT_DONE_KYC:
                setStatusAfterSubmitted("warning");
                setMsgAfterSubmitted(t("reset_password_user_not_done_kyc"));
                break;
            case RESPONSE_CODE.MISMATCH_VALUE:
                setStatusAfterSubmitted("error");
                setMsgAfterSubmitted(
                    t("fp_ms_error_on_reset_password_combine_invalid")
                );
                break;
            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    };

    return (
        <ContentModal supportMobile>
            <form
                className={`${sharedClasses.modalForm} ${classes.modalForm}`}
                onSubmit={handleSubmit(retrieveClick)}
            >
                <div className={sharedClasses.language}>{languageContent}</div>

                <div
                    className={`${sharedClasses.modalFormContent} ${classes.modalFormContent}`}
                >
                    <div className={sharedClasses.title}>
                        {t("resetpassword_title")}
                    </div>
                    {statusAfterSubmitted !== "success" &&
                        statusAfterSubmitted !== "warning" && (
                            <>
                                <div className={sharedClasses.desc}>
                                    {t("login_reset_password_desc")}
                                </div>
                                <LspTextField
                                    autoFocus
                                    name="legalId"
                                    error={!!errors.legalId}
                                    label={t("global_lb_id_passport")}
                                    helperText={errors.legalId?.message || " "}
                                    inputProps={{
                                        ref: register({
                                            required: t("msg_we_need_this"),
                                            pattern: {
                                                value:
                                                    REGEX_PATTERN.PASSPORT_IDNUMBER,
                                                message: t(
                                                    "ms_lb_national_id_invalid"
                                                ),
                                            },
                                            minLength: {
                                                value: 4,
                                                message: t(
                                                    "ms_lb_national_id_invalid"
                                                ),
                                            },
                                            maxLength: {
                                                value: 15,
                                                message: t(
                                                    "ms_lb_national_id_invalid"
                                                ),
                                            },
                                        }),
                                    }}
                                />
                                <LspTextField
                                    name="email"
                                    error={!!errors.email}
                                    label={t("lb_hint_email")}
                                    helperText={errors.email?.message || " "}
                                    inputProps={{
                                        ref: register({
                                            required: t("msg_we_need_this"),
                                            pattern: {
                                                value: REGEX_PATTERN.EMAIL,
                                                message: t(
                                                    "ms_lb_email_invalid"
                                                ),
                                            },
                                        }),
                                    }}
                                />
                            </>
                        )}

                    {statusAfterSubmitted !== "" && (
                        <div
                            className={clsx(sharedClasses.succeedMsg, {
                                [sharedClasses.errorMsg]:
                                    statusAfterSubmitted === "error" ||
                                    statusAfterSubmitted === "warning",
                            })}
                        >
                            <span>{msgAfterSubmitted}</span>
                        </div>
                    )}

                    {statusAfterSubmitted !== "success" && (
                        <>
                            <LspButton
                                type="submit"
                                progressing={formState.isSubmitting}
                                className={sharedClasses.submitButton}
                                fullWidth
                            >
                                {t("lb_next")}
                            </LspButton>
                            <div className={sharedClasses.extraActions}>
                                <Link
                                    onClick={goToLoginPage}
                                    color="secondary"
                                    className={sharedClasses.extraActionItem}
                                >
                                    {t("login_reset_password_back_btn")}
                                </Link>
                            </div>
                        </>
                    )}

                    {statusAfterSubmitted === "success" && (
                        <LspButton
                            onClick={goToLoginPage}
                            type="submit"
                            className={sharedClasses.submitButton}
                            fullWidth
                        >
                            {t("login_reset_password_back_btn")}
                        </LspButton>
                    )}
                </div>
                <ColorBar
                    className={`${sharedClasses.colorBar} ${classes.customColorBar}`}
                />
            </form>
        </ContentModal>
    );
};

export default ResetPassword;
