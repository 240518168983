/* eslint-disable react/no-array-index-key */
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { useMemo, useCallback, useEffect, useState } from "react";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspTextField from "@components/LspTextField";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import { DatePicker } from "@material-ui/pickers";
import userAction from "@redux/actions/user";
import { addDays, addYears } from "date-fns";
import { connect } from "react-redux";
import FooterControlActions from "@components/FooterControlActions";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(3, 3),
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },

    footer: {
        padding: theme.spacing(0, 3, 3, 3),
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    formGroup: {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            width: "48%",
        },

        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            "& > div": {
                width: "100%",
            },
        },
    },
}));

const GoalSaveDetail = ({
    stepBack,
    getServerTime,
    serverTime,
    onNextDetail,
    infoGoalSave,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [amountError, setAmountError] = useState(null);
    const [goalAmount, setGoalAmount] = useState(infoGoalSave.goalAmount || "");
    const [planName, setPlanName] = useState(infoGoalSave.planName || "");
    const { thousandSeparator, decimalSeparator } = useNumber();
    const currentDate = useMemo(() => {
        if (serverTime.value) {
            const dateFormatted = addDays(new Date(serverTime.value), 1);
            return dateFormatted;
        }
    }, [serverTime]);
    const [targetDate, setTargetDate] = useState(
        infoGoalSave?.targetDate || currentDate
    );

    useEffect(() => {
        getServerTime();
    }, [getServerTime]);

    const maxDate = useMemo(() => {
        if (serverTime.value) {
            const dateFormatted = addYears(new Date(serverTime.value), 3);
            return dateFormatted;
        }
    }, [serverTime]);

    const onToDateChange = useCallback((d) => {
        if (!d) {
            setTargetDate(" ");
            return;
        }
        setTargetDate(new Date(d));
    }, []);

    const onNext = () => {
        if (goalAmount < 100000) {
            setAmountError(t("gs_ms_goal_amount_is_invalid"));
            return;
        }
        if (goalAmount > 100000000000) {
            setAmountError(t("gs_ms_goal_amount_does_not_exceed"));
            return;
        }
        const info = {
            goalAmount,
            planName,
            targetDate,
        };
        onNextDetail(info);
    };

    const onAmountChange = (e) => {
        if (amountError) {
            setAmountError("");
        }
        setGoalAmount(e.floatValue);
    };

    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const buttons = [
        {
            onClick: stepBack,
            label: t("lb_back"),
        },

        {
            onClick: onNext,
            label: t("lb_next"),
        },

        {
            onClick: () => GlobalDialogController.hide(),
            label: t("lb_cancel"),
        },
    ];

    return (
        <>
            <div className={classes.body}>
                <LspTextField
                    autoFocus
                    label={t("goalSave_lb_goal_name")}
                    onChange={(e) => {
                        setPlanName(
                            replaceSpecialCharacter({
                                pattern: REGEX_PATTERN.DESCRIPTION_REPLACEMENT,
                                text: e.target.value,
                            })
                        );
                    }}
                    fullWidth
                    value={planName}
                    inputProps={{
                        maxLength: 25,
                        datatestid: "planName",
                    }}
                />

                <div className={classes.formGroup}>
                    <NumberFormat
                        customInput={LspTextField}
                        autoComplete="off"
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        label={t("goalSave_lb_target_amount")}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        allowedDecimalSeparators={false}
                        inputProps={{
                            maxLength: 19,
                            datatestid: "goalTargetAmount",
                        }}
                        error={!!amountError}
                        helperText={amountError}
                        onValueChange={onAmountChange}
                        onKeyDown={(e) => {
                            if (e.key === ".") {
                                e.preventDefault();
                            }
                        }}
                        value={goalAmount}
                    />
                    <DatePicker
                        TextFieldComponent={LspTextField}
                        disableHelperText
                        label={t("goalSave_lb_target_date")}
                        disableToolbar
                        autoOk
                        openTo="year"
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        invalidDateMessage=" "
                        maxDateMessage=" "
                        value={targetDate || ""}
                        onChange={onToDateChange}
                        maxDate={maxDate}
                        minDate={currentDate}
                        inputProps={{
                            datatestid: "goalTargetDate",
                        }}
                    />
                </div>
            </div>
            <FooterControlActions
                leftButton={buttons[0]}
                centerButton={buttons[1]}
                rightButton={buttons[2]}
                disabled={!planName || !goalAmount}
                wrapperClasses={classes.footer}
            />
        </>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime,
});

const mapDispatch = (dispatch) => ({
    getServerTime: () => dispatch(userAction.getServerTimeRequest()),
});

export default connect(mapState, mapDispatch)(GoalSaveDetail);
