import AccountSummaryBox from "@components/Account/AccountSummaryBox";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTextField from "@components/LspTextField";
import useTransactionValidation from "@containers/SpendAccount/useTransactionValidation";
import { WEALTH_PAYMENT_METHODS } from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useAccounts from "@helpers/useAccounts";
import useDescription from "@helpers/useDescription";
import useNumber from "@helpers/useNumber";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PaymentAmountSuggestion from "./PaymentAmountSuggestion";
import PaymentAmountSuggestionLoading from "./PaymentAmountSuggestionLoading";

const useStyles = makeStyles((theme) => ({
    item: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        "&:last-child": {
            borderBottom: "none",
        },
    },
    itemLeft: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2),
        "& .MuiRadio-root": {
            padding: 0,
        },
    },
    itemCircle: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        border: `1.5px solid ${theme.palette.magicalGrey[300]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    itemCircleActive: {
        border: `1.5px solid rgba(16, 24, 32, 0.1);`,
        backgroundColor: theme.palette.green.main,
    },
    itemCircleChild: {
        borderRadius: "50%",
        width: 12,
        height: 12,
        backgroundColor: theme.palette.neutral.white,
    },
    itemRight: {
        display: "inline-flex",
        flex: 1,
        flexDirection: "column",
    },
    itemTitle: {
        color: theme.palette.black.main,
        marginBottom: theme.spacing(0.25),
    },
    itemText: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: "18px",
        color: theme.palette.magicalGrey[400],
    },
    customRadioButton: {
        "& .MuiSvgIcon-root": {
            fontSize: 24,
        },
    },
    oneline: {
        flexDirection: "row",
        alignItems: "center",
    },
    amountInput: {
        width: "60%",
        "& .MuiTextField-root": {
            width: "100%",
        },
        "& .MuiInput-input": {
            textAlign: "right",
        },
        "& .MuiInputLabel-root": {
            left: "auto",
            right: 0,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            right: theme.spacing(-1.25),
        },
        "& .MuiInputLabel-root.MuiFormLabel-filled": {
            right: theme.spacing(-1.25),
        },
    },
    disabled: {
        opacity: 0.5,
    },
}));

const PaymentAmount = ({
    user,
    next,
    amount,
    setAmount,
    description,
    setDescription,
    defaultDesc,
    selectedAmountOption,
    onAmountOptionChange,
    amountSuggestionList,
    amountSuggestionLoading,
    suggest,
}) => {
    const { formatNumber } = useNumber();
    const { t } = useTranslation();
    const { validateAmount } = useTransactionValidation();
    const { getStandardDescription } = useDescription();
    const classes = useStyles();
    const { creditCard, getCurrentBalance } = useAccounts();

    const currentBalance = getCurrentBalance(false);

    const unmounted = useRef(false);

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const cardNumberHelperText = useMemo(() => {
        return `${user.fullName} | ${t("user_bank_name")}`;
    }, [user, t]);

    const [amountError, setAmountError] = useState(null);

    const [loading, setLoading] = useState(false);

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (amountError) {
                setAmountError(null);
            }
            setAmount({ formattedValue, value: +value });
        },
        [setAmount, amountError]
    );

    const validateFormData = useCallback(() => {
        if (
            !amount.value &&
            selectedAmountOption?.id === WEALTH_PAYMENT_METHODS.MANUAL
        ) {
            setAmountError(t("msg_we_need_this"));
            return false;
        }
        return true;
    }, [t, amount, selectedAmountOption?.id]);

    const errorAmountHandler = useCallback(
        (error) => {
            if (selectedAmountOption?.id === WEALTH_PAYMENT_METHODS.MANUAL) {
                setAmountError(error);
                return;
            }
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Warning",
                    content: error,
                    button: "lb_close",
                },
            });
        },
        [selectedAmountOption, setAmountError]
    );

    const submit = useCallback(
        (e) => {
            e.preventDefault();
            if (!validateFormData()) {
                return;
            }

            console.log("777777");

            const comparedAmount =
                selectedAmountOption?.id === WEALTH_PAYMENT_METHODS.MANUAL
                    ? amount?.value
                    : selectedAmountOption?.amount;
            validateAmount({
                amount: comparedAmount,
                transferType: "bank",
                isCancelled: unmounted.current,
                beforeRequest: () => setLoading(true),
                afterResponse: () => setLoading(false),
                handleBalanceLessThanMinAmount: (minAmount) =>
                    errorAmountHandler(
                        `${t("spend_msg_for_least_amount").replace(
                            "%@",
                            formatNumber(minAmount)
                        )}`
                    ),
                handleAmountOutOfRange: (minAmount, maxAmount) =>
                    errorAmountHandler(
                        `${t("payee_card_amount_from")} ${formatNumber(
                            minAmount
                        )} ${t("moveMoney_label_amount_and")} ${formatNumber(
                            maxAmount
                        )}`
                    ),
                handleAmountOverDailyLimit: () =>
                    errorAmountHandler(t("ms_lb_transfer_daily_limit")),
                handleAmountValid: async () => {
                    const desc = await getStandardDescription({
                        defaultDescription: defaultDesc,
                        description,
                    });

                    setDescription(desc?.standardDescription);
                    next();
                },
            });
        },
        [
            validateFormData,
            validateAmount,
            amount,
            formatNumber,
            t,
            getStandardDescription,
            description,
            defaultDesc,
            setDescription,
            next,
            errorAmountHandler,
            selectedAmountOption,
        ]
    );
    return (
        <Content>
            <AccountSummaryBox
                leftItemName={t("payAnyone:total_balance")}
                rightItemName={t("ah_lb_current_balance")}
                rightItemColor="blue"
                leftItemValue={formatNumber(currentBalance)}
                rightItemValue={formatNumber(creditCard?.availableAmount)}
            />

            <Paper component={Box} p={3} my={2}>
                <form onSubmit={submit}>
                    <LspTextField
                        disabled
                        label={t("mc_card_number")}
                        value={creditCard.no}
                        helperText={cardNumberHelperText}
                    />
                    {amountSuggestionLoading && (
                        <PaymentAmountSuggestionLoading classes={classes} />
                    )}
                    {!amountSuggestionLoading && (
                        <PaymentAmountSuggestion
                            classes={classes}
                            amount={amount}
                            selectedAmountOption={selectedAmountOption}
                            onAmountOptionChange={onAmountOptionChange}
                            onAmountChange={onAmountChange}
                            list={amountSuggestionList}
                            error={amountError}
                            suggest={suggest}
                        />
                    )}

                    <LspButton
                        type="submit"
                        fullWidth
                        progressing={loading}
                        disabled={!selectedAmountOption}
                    >
                        {t("lb_move_money")}
                    </LspButton>
                </form>
            </Paper>
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    systemParams: state.systemParams.info,
});

export default connect(mapState)(PaymentAmount);
