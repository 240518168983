import produce from "immer";
import {
    LIXI_ACTIONS,
    PAY_ANYONE_STATUSES,
} from "../../containers/Lixi/constant";
import mapLixiList from "../../helpers/mapLixiList";
import { LixiActionTypes } from "../actions/lixi";

/* ------------- Initial State ------------- */
const initialState = {
    dashboardLoading: false,
    dashboardInfo: null,
    listLoading: false,
    unreadList: [],
    readList: [],
    lastId: -1,
    hasMore: true,
    typeList: null,
    hasEmptyList: false,
    detailLoading: false,
    detailInfo: null,
    payee: null,
    theme: {},
    amount: "",
    message: "",
};

/* ------------- Reducer ------------- */
const lixiReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LixiActionTypes.GetDashboard:
                draft.dashboardLoading = true;
                break;
            case LixiActionTypes.GetDashboardSuccess:
                draft.dashboardLoading = false;
                draft.dashboardInfo = action.payload;
                break;
            case LixiActionTypes.GetDashboardError:
                draft.dashboardLoading = false;
                break;
            case LixiActionTypes.GetUnreadLixiList:
                draft.typeList = action.payload.status;
                draft.listLoading = true;
                break;
            case LixiActionTypes.GetUnreadLixiListSuccess:
                draft.listLoading = false;
                draft.unreadList = mapLixiList(
                    draft.unreadList,
                    action.payload
                );
                if (action.payload.length > 0) {
                    const lastItem = action.payload[action.payload.length - 1];
                    draft.lastId = lastItem.giftId;
                } else {
                    draft.typeList = PAY_ANYONE_STATUSES.READ;
                    draft.lastId = -1;
                }
                break;
            case LixiActionTypes.GetUnreadLixiListError:
                draft.listLoading = false;
                draft.hasMore = false;
                break;
            case LixiActionTypes.GetReadLixiList:
                draft.typeList = action.payload.status;
                draft.listLoading = true;
                break;
            case LixiActionTypes.GetReadLixiListSuccess:
                draft.readList = mapLixiList(
                    draft.readList,
                    action.payload,
                    true
                );
                if (action.payload.length === 0) {
                    draft.hasMore = false;
                    draft.lastId = -1;
                } else {
                    const lastItem = action.payload[action.payload.length - 1];
                    draft.lastId = lastItem.giftId;
                }

                if (draft.type === LIXI_ACTIONS.RECEIVE) {
                    if (
                        draft.unreadList.length === 0 &&
                        draft.readList.length === 0
                    ) {
                        draft.hasEmptyList = true;
                    }
                } else if (draft.readList.length === 0) {
                    draft.hasEmptyList = true;
                }
                draft.listLoading = false;
                break;
            case LixiActionTypes.GetReadLixiListError:
                draft.listLoading = false;
                draft.hasMore = false;
                break;

            case LixiActionTypes.GetLixiDetail:
                draft.detailLoading = true;
                break;
            case LixiActionTypes.GetLixiDetailSuccess:
                draft.detailLoading = false;
                draft.detailInfo = action.payload;
                break;
            case LixiActionTypes.GetLixiDetailError:
                draft.detailLoading = false;
                break;
            case LixiActionTypes.SetPayeeDetail:
                draft.payee = action.payload;
                break;
            case LixiActionTypes.SetSubmitInfo:
                const { key, value } = action.payload;
                draft[key] = value;
                break;
            case LixiActionTypes.ResetSubmitInfo:
                const params = action.payload || "";
                if (Array.isArray(params)) {
                    params.forEach((p) => {
                        draft[p] = initialState[p];
                    });
                } else if (params !== "") {
                    draft[params] = initialState[params];
                } else {
                    draft.payee = initialState.payee;
                    draft.theme = initialState.theme;
                    draft.amount = initialState.amount;
                    draft.message = initialState.message;
                }
                break;
            case LixiActionTypes.Reset:
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    if (name !== "dashboardInfo") {
                        draft[name] = initialState[name];
                    }
                });
                break;
            default:
                break;
        }
    });

export default lixiReducer;
