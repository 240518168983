import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { isFunction } from "lodash";
import GlobalDialogController from "../helpers/controllers/GlobalDialogController";
import { LspDialogActions } from "./LspDialog";
import {
    LspDialogNoHeaderContent,
    LspDialogNoHeaderTitle,
} from "./LspNoHeaderDialog";
import LspButton from "./LspButton";

const useStyles = makeStyles(() => ({
    wrapper: {
        margin: "auto",
    },
}));

const MarketingDialog = ({ detail, onCloseFunc }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const closePopup = () => {
        if (isFunction(onCloseFunc)) {
            onCloseFunc();
        }
        GlobalDialogController.hide();
    };

    return (
        <div className={classes?.wrapper}>
            <LspDialogNoHeaderTitle>
                <div>{detail?.title}</div>
            </LspDialogNoHeaderTitle>
            <LspDialogNoHeaderContent>
                <ReactMarkdown>{detail?.contentData}</ReactMarkdown>
            </LspDialogNoHeaderContent>
            <LspDialogActions>
                <LspButton type="submit" fullWidth onClick={closePopup}>
                    {t("lb_close")}
                </LspButton>
            </LspDialogActions>
        </div>
    );
};

export default MarketingDialog;
