import { useCallback, useEffect, useMemo } from "react";
import { Box, Link, makeStyles, Popover } from "@material-ui/core";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import notificationAction from "@redux/actions/notification";
import take from "lodash/take";
import { useHistory } from "react-router-dom";
import groupNotificationByDate from "@helpers/groupNotificationByDate";
import LspTypography from "@components/LspTypography";
import useNotifyNavigation from "@helpers/useNotifyNavigation";
import useDate from "@helpers/useDate";
import NotificationPopoverItem from "./NotificationPopoverItem";
import NotificationPopoverLoading from "./NotificationPopoverLoading";
import NotificationPopoverEmpty from "./NotificationPopoverEmpty";

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        width: 300,
        maxHeight: "70vh",

        [theme.breakpoints.down("xs")]: {
            width: "100vw",
            height: "calc(100vh - 48px)",
            position: "fixed",
            top: theme.spacing(6),
            left: 0,
            maxHeight: "100%",
            paddingBottom: theme.spacing(6),
        },
    },
    header: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
    },
    content: {
        overflow: "auto",
    },
    footerControl: {
        color: theme.palette.primary.main,
        "&:active": {
            opacity: theme.palette.opacity.active,
        },

        [theme.breakpoints.down("xs")]: {
            width: "100%",
            position: "absolute",
            bottom: 0,
        },
    },
    timeline: {
        padding: theme.spacing(1, 2),
        background: theme.palette.background.default,
    },
}));

const maxListLength = 5;

const NotificationPopover = ({
    anchorEl,
    onClose,
    getNotificationList,
    notificationList,
    notificationListFetching,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const { getLabelByDate } = useDate();

    const isOpening = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const { direction } = useNotifyNavigation();

    useEffect(() => {
        if (isOpening) {
            getNotificationList();
        }
    }, [isOpening, getNotificationList]);

    const shortenList = useMemo(() => {
        if (!Array.isArray(notificationList)) {
            return [];
        }

        if (notificationList.length > maxListLength) {
            const list = take(notificationList, 5);
            return groupNotificationByDate(list);
        }
        return groupNotificationByDate(notificationList);
    }, [notificationList]);

    const showLoading = useMemo(
        () => shortenList.length === 0 && notificationListFetching,
        [shortenList, notificationListFetching]
    );

    const onShowMoreClick = useCallback(() => {
        history.push("/notification-center");
        onClose();
    }, [history, onClose]);

    const getDateGroup = useCallback(
        (item) => getLabelByDate(item?.date, "dd/MM/yyyy", item?.originalDate),
        [getLabelByDate]
    );

    const onClickNotifyItem = (item) => {
        direction(item);
        onClose();
    };

    return (
        <Popover
            open={isOpening}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    marginTop: 16,
                },
            }}
        >
            <div className={classes.root}>
                <Box
                    px={2}
                    py={1.5}
                    textAlign="center"
                    className={classes.header}
                >
                    <LspTypography variant="subheading1">
                        {t("notification_label_notification_center")}
                    </LspTypography>
                </Box>
                <Box overflow="auto">
                    {showLoading && <NotificationPopoverLoading />}
                    {shortenList.length === 0 && !showLoading && (
                        <NotificationPopoverEmpty />
                    )}
                    {shortenList.length > 0 &&
                        shortenList?.map((group) => {
                            return (
                                <div key={group.id}>
                                    <LspTypography
                                        variant="subheading3"
                                        className={classes.timeline}
                                    >
                                        {getDateGroup(group)}
                                    </LspTypography>
                                    {group.list.map(
                                        (notification, idx, arr) => (
                                            <NotificationPopoverItem
                                                key={notification.iD}
                                                {...notification}
                                                lastItem={
                                                    idx === arr.length - 1
                                                }
                                                onClick={onClickNotifyItem}
                                            />
                                        )
                                    )}
                                </div>
                            );
                        })}
                </Box>
                {/* <Divider className={classes.divider} /> */}
                {shortenList.length > 0 && (
                    <Box
                        px={2}
                        py={1.5}
                        textAlign="center"
                        className={classes.footerControl}
                    >
                        <Link onClick={onShowMoreClick}>
                            <LspTypography variant="button2">
                                {t("payee_lb_payment_history_showmore")}
                            </LspTypography>
                        </Link>
                    </Box>
                )}
            </div>
        </Popover>
    );
};

const mapState = (state) => ({
    notificationListFetching: state.notification.fetching,
    notificationList: state.notification.list,
});

const mapDispatch = (dispatch) => ({
    getNotificationList: () =>
        dispatch(notificationAction.getNotificationListRequest()),
});

export default connect(mapState, mapDispatch)(NotificationPopover);
