import LspButton from "@components/LspButton";
import { CallIcon, CardIcon, SettingBankIcon } from "@components/LspIcon";
import { Box, Fade, Icon, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useMemo, useEffect, useState, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import useCard from "@helpers/useCard";
import GlobalDialogController from "../../helpers/controllers/GlobalDialogController";
import { BANK_CARD_STATUS, CARD_STATUSES, CARD_TYPES } from "./constant";

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        marginBottom: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
    },
    oneButton: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    actionBtn: {
        background: theme.palette.background.paper,
    },
    actionBtnIcon: {
        color: theme.palette.primary.main,
    },
    item: {
        width: 300,
        margin: theme.spacing(1.25, 1),
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 16px)",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    customButton: {
        "& button": {
            padding: theme.spacing(2),
        },
        "& p": {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 400,
            lineHeight: 22 / 14,
            textTransform: "capitalize",
        },
    },
}));

const CardButtons = ({
    card,
    resetPin,
    reissueCard,
    changePin,
    cardLimit,
    enableCardChangePin,
    enableCardResetPin,
    enableCreditCardResetPin,
}) => {
    const classes = useStyles();
    const { t } = useTranslation(["translation", "hangout"]);
    const [isResetPinBtn, setIsResetPinBtn] = useState(false);
    const [isChangePinBtn, setIsChangePinBtn] = useState(false);
    const [isReissuePinBtn, setIsReissuePinBtn] = useState(false);
    const [isCardLimit, setCardLimit] = useState(false);

    const {
        isPhysicalVirtualDebitVisa,
        isRequestedPhysicalVirtualDebitVisa,
        isVirtualCard,
        isDebitCard,
    } = useCard();

    const enableChangePin = useMemo(() => {
        return enableCardChangePin === "true" || enableCardChangePin === true;
    }, [enableCardChangePin]);

    const isTimoCoreCard = useMemo(() => card?.isAllowSetCardLimit, [
        card?.isAllowSetCardLimit,
    ]);

    const enableResetPin = useMemo(() => {
        return enableCardResetPin === "true" || enableCardResetPin === true;
    }, [enableCardResetPin]);

    const enableCreditResetPin = useMemo(() => {
        return (
            enableCreditCardResetPin === "true" ||
            enableCreditCardResetPin === true
        );
    }, [enableCreditCardResetPin]);

    const callSupport = () => {
        const phone = t("hangout:callcenter_number");
        const content = t("web_contact_to_report").replace(
            "%@",
            `<a href="tel: ${phone}">${phone}</a>`
        );

        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                header: "manage_card_report",
                translatedContent: content,
                button: "lb_ok",
            },
        });
    };

    const isVirtual = useMemo(() => {
        return card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD;
    }, [card?.cardType]);

    const isVisa = useMemo(() => {
        return (
            card?.cardType === CARD_TYPES.VISA_CARD ||
            card?.cardType === CARD_TYPES.MASTER_CARD ||
            card?.cardType === CARD_TYPES.FE_VISA_CARD ||
            card?.cardType === CARD_TYPES.FE_CREDIT_PARAM
        );
    }, [card?.cardType]);

    const isShowSupportButton = useMemo(() => {
        return (
            isRequestedPhysicalVirtualDebitVisa(
                card?.cardType,
                card?.physicalVirtualVDCardStatus
            ) || !isVirtualCard(card?.cardType)
        );
    }, [isVirtualCard, card, isRequestedPhysicalVirtualDebitVisa]);

    useEffect(() => {
        if (!card) return;

        const { cardStatus } = card;

        // complete get physical successfully
        if (
            isPhysicalVirtualDebitVisa(
                card?.cardType,
                card?.physicalVirtualVDCardStatus
            )
        ) {
            switch (cardStatus) {
                case CARD_STATUSES.ACTIVE:
                    setIsResetPinBtn(enableResetPin);
                    break;
                case CARD_STATUSES.CHANGE_PIN_1ST:
                case CARD_STATUSES.BLOCKED:
                default:
                    break;
            }

            return;
        }

        if (
            isRequestedPhysicalVirtualDebitVisa(
                card?.cardType,
                card?.physicalVirtualVDCardStatus
            )
        )
            return;

        if (isVirtual) {
            switch (cardStatus) {
                case CARD_STATUSES.ACTIVE:
                    setIsReissuePinBtn(true);
                    setCardLimit(true);
                    break;
                case CARD_STATUSES.CHANGE_PIN_1ST:
                    setIsReissuePinBtn(true);
                    break;
                case CARD_STATUSES.BLOCKED:
                    const isReIssueStatus =
                        card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                    if (!isReIssueStatus && card.unLockable) {
                        setIsReissuePinBtn(true);
                    }
                    break;
                default:
                    break;
            }

            return;
        }

        if (isDebitCard(card?.cardType)) {
            switch (cardStatus) {
                case CARD_STATUSES.ACTIVE:
                    setCardLimit(true);

                    setIsResetPinBtn(enableResetPin);
                    setIsChangePinBtn(enableChangePin);
                    setIsReissuePinBtn(true);
                    break;
                case CARD_STATUSES.CHANGE_PIN_1ST:
                    setCardLimit(true);
                    setIsReissuePinBtn(true);
                    setIsChangePinBtn(enableChangePin);
                    break;
                case CARD_STATUSES.BLOCKED:
                    const isReIssueStatus =
                        card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                    if (!isReIssueStatus && card.unLockable) {
                        setIsReissuePinBtn(true);
                    }
                    break;
                default:
                    break;
            }

            return;
        }

        if (isVisa) {
            // CREDIT CARD
            switch (cardStatus) {
                case CARD_STATUSES.ACTIVE:
                    setCardLimit(true);
                    setIsResetPinBtn(enableCreditResetPin);
                    break;
                default:
                    break;
            }
        }
    }, [
        card,
        enableChangePin,
        enableResetPin,
        enableCreditResetPin,
        isVirtual,
        isDebitCard,
        isVisa,
        isPhysicalVirtualDebitVisa,
        isRequestedPhysicalVirtualDebitVisa,
    ]);

    return (
        <Fade in>
            <Box
                className={clsx(classes.buttonWrapper, {
                    [classes.oneButton]:
                        !isResetPinBtn && !isReissuePinBtn && !isChangePinBtn,
                })}
            >
                {isResetPinBtn && (
                    <Box className={classes.item}>
                        <LspButton
                            variant="secondary"
                            fullWidth
                            className={classes.customButton}
                            buttonProps={{
                                startIcon: (
                                    <Icon
                                        className={`font-icon icon-locked ${classes.actionBtnIcon}`}
                                    />
                                ),
                            }}
                            onClick={() => resetPin(card)}
                        >
                            {t("card_activation_reset_pin")}
                        </LspButton>
                    </Box>
                )}
                {isChangePinBtn && (
                    <Box className={classes.item}>
                        <LspButton
                            variant="secondary"
                            fullWidth
                            className={classes.customButton}
                            buttonProps={{
                                startIcon: (
                                    <Icon
                                        className={`font-icon icon-locked ${classes.actionBtnIcon}`}
                                    />
                                ),
                            }}
                            onClick={() => changePin(card)}
                        >
                            {t("mc_lb_title_change_pin")}
                        </LspButton>
                    </Box>
                )}

                {isReissuePinBtn && (
                    <Box className={classes.item}>
                        <LspButton
                            variant="secondary"
                            fullWidth
                            buttonProps={{
                                startIcon: <CardIcon />,
                            }}
                            className={classes.customButton}
                            onClick={() => reissueCard(card)}
                        >
                            {isVirtual
                                ? t("reissue_virtual_card_manage")
                                : t(
                                      "card_activation_request_new_card_pin_button_label"
                                  )}
                        </LspButton>
                    </Box>
                )}

                {isCardLimit && isTimoCoreCard && (
                    <Box className={classes.item}>
                        <LspButton
                            variant="secondary"
                            fullWidth
                            buttonProps={{
                                startIcon: <SettingBankIcon />,
                            }}
                            className={classes.customButton}
                            onClick={() => cardLimit(card)}
                        >
                            {t("manage_card_set_limit_entry_point")}
                        </LspButton>
                    </Box>
                )}

                {isShowSupportButton && (
                    <Box className={classes.item}>
                        <LspButton
                            variant="secondary"
                            onClick={callSupport}
                            fullWidth
                            className={classes.customButton}
                            buttonProps={{
                                startIcon: <CallIcon />,
                            }}
                        >
                            {t("manage_card_report")}
                        </LspButton>
                    </Box>
                )}
            </Box>
        </Fade>
    );
};

const stateProps = (state) => ({
    enableCardChangePin:
        state.systemParams.info.sysparams["1.0"].enableCardChangePin || false,
    enableCardResetPin:
        state.systemParams.info.sysparams["1.0"].enableCardResetPin || false,
    enableCreditCardResetPin:
        state.systemParams.info.sysparams["1.0"].enableCreditCardResetPin ||
        false,
});
export default connect(stateProps)(CardButtons);
