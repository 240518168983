import Content from "@components/Content";
import LspButton from "@components/LspButton";
import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { useState, memo } from "react";
import PropTypes from "prop-types";
import RolloverMethodGroup from "./RolloverMethodGroup";

const useStyles = makeStyles((theme) => ({
    dialogBody: {
        padding: theme.spacing(4, 2),
    },
}));

const RolloverMethodDialog = ({
    onClose,
    rollType,
    onSubmit,
    showRollover,
}) => {
    const classes = useStyles();
    const [type, seType] = useState("");

    const { t } = useTranslation();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({ rollType: type });
        }
    };

    const handleSelect = ({ id }) => {
        seType(id);
    };
    return (
        <LspDialog open maxWidth="sm" scroll="body">
            <LspDialogTitle>{t("TD:td_rollover_method")}</LspDialogTitle>
            <LspDialogContent className={classes.dialogBody}>
                <RolloverMethodGroup
                    selected={rollType}
                    onSelected={handleSelect}
                    showRollover={showRollover}
                />
            </LspDialogContent>
            <LspDialogActions>
                <LspButton variant="secondary" fullWidth onClick={handleClose}>
                    {t("lb_close")}
                </LspButton>
                <LspButton
                    type="submit"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={type === "" || rollType === type}
                >
                    {t("loan_vpbank_button_confirm")}
                </LspButton>
            </LspDialogActions>
        </LspDialog>
    );
};

RolloverMethodDialog.propTypes = {
    showRollover: PropTypes.bool,
};

RolloverMethodDialog.defaultProps = {
    showRollover: true,
};

export default memo(RolloverMethodDialog);
