import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DoubleButtons from "@components/DoubleButtons";
import Interweave from "interweave";
import { INSTALLMENT_ACTIONS } from "./constant";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.neutral.white,
        borderRadius: theme.shape.borderRadius,
    },
    description: {
        color: theme.palette.neutral.black,
        whiteSpace: "pre-wrap",
    },
}));

const InstallmentConfirmationDialog = ({ onCancel, type, onConfirm }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [content, setContent] = useState({});

    useEffect(() => {
        switch (type) {
            case INSTALLMENT_ACTIONS.CANCEL:
                setContent({
                    title: t("creditCard:cancel_ins_popup_title"),
                    description: t("creditCard:cancel_ins_popup_content"),
                });
                break;
            case INSTALLMENT_ACTIONS.DELETE_FOR_COMPLETE:
            case INSTALLMENT_ACTIONS.DELETE_FOR_DECLINE:
                setContent({
                    title: t("creditCard:delete_record_popup_title"),
                    description: t("creditCard:delete_record_popup_content"),
                });
                break;
            default:
                break;
        }
    }, [type, t]);

    return (
        <Content size="sm">
            <div className={classes.wrapper}>
                <LspDialogTitle>{content.title}</LspDialogTitle>
                <LspDialogContent>
                    <div className={classes.description}>
                        <LspTypography variant="body2">
                            <Interweave content={content.description} />
                        </LspTypography>
                    </div>
                </LspDialogContent>
                <LspDialogActions>
                    <DoubleButtons
                        primaryButton={{
                            label: t("loan_vpbank_button_confirm"),
                            onClick: () => {
                                onConfirm(type);
                                onCancel(); // close dialog
                            },
                        }}
                        secondaryButton={{
                            label: t("lb_cancel"),
                            onClick: onCancel,
                        }}
                    />
                </LspDialogActions>
            </div>
        </Content>
    );
};

export default InstallmentConfirmationDialog;
