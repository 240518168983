import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Content from "@components/Content";
import { InfoIcon } from "@components/LspIcon";
import { useCallback, useEffect, useRef, useState, Fragment } from "react";
import invitationService from "@services/invitation";
import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import LspButton from "@components/LspButton";
import clsx from "clsx";
import InvitationPendingItem from "./InvitationPendingItem";
import InvitationPendingListLoading from "./InvitationPendingListLoading";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderRadius: theme.shape.radiusLevels[1],
        background: theme.palette.background.paper,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(3),
        },
        "& a": {
            textDecoration: "none",
            marginTop: theme.spacing(1),
            display: "block",
        },
    },
    header: {
        marginBottom: theme.spacing(3),
        textAlign: "center",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
    },
    note: {
        display: "flex",
        background: theme.palette.blue.main,
        borderRadius: theme.shape.radiusLevels[1],
        color: theme.palette.neutral.white,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        "& > div": {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
    },
    pendingList: {
        marginTop: theme.spacing(2),
        height: 297,
        overflow: "auto",
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
    },
    emptyList: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    pendingItem: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        borderTop: `1px solid ${theme.palette.neutral.grey3}`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:last-child": {
            borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    info: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    footer: {
        paddingTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
    },
}));

const InvitationPendingList = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const unmounted = useRef(false);
    const [loading, setLoading] = useState(true);
    const [isShowNote, setIsShowNote] = useState(false);
    const [pendingList, setPendingList] = useState(null);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getPendingList = useCallback(async () => {
        const response = await invitationService.getInvitationPendingList();

        if (unmounted.current) return;

        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                setIsShowNote(data.hint);
                setPendingList(data.pendingList);
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
        setLoading(false);
    }, []);

    const turnOffNoteOption = async (e) => {
        e.preventDefault();

        await invitationService.toggleNoteOption({
            enable: false,
        });
        setIsShowNote(false);
    };

    useEffect(() => {
        getPendingList();
    }, [getPendingList]);

    return (
        <>
            {loading && <InvitationPendingListLoading classes={classes} />}
            {!loading && (
                <Content size="sm" boxProps={{ className: classes.wrapper }}>
                    <div className={classes.header}>
                        <LspTypography variant="heading1">
                            {t("member:timostarclub_title_pending_invitation")}
                        </LspTypography>
                    </div>
                    <div className={classes.body}>
                        {isShowNote && (
                            <div className={classes.note}>
                                <InfoIcon />
                                <div>
                                    <LspTypography
                                        variant="subheading3"
                                        color="white"
                                    >
                                        {t(
                                            "member:timostarclub_lb_pending_invitation_info"
                                        )}
                                    </LspTypography>
                                    <a href="#" onClick={turnOffNoteOption}>
                                        <LspTypography
                                            variant="button2"
                                            color="white"
                                        >
                                            {t(
                                                "member:timostarclub_btn_pending_invitation_gotit"
                                            )}
                                        </LspTypography>
                                    </a>
                                </div>
                            </div>
                        )}

                        <div
                            className={clsx(classes.pendingList, {
                                [classes.emptyList]: pendingList?.length <= 0,
                            })}
                        >
                            {pendingList?.length <= 0 && (
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "member:timostarclub_lb_pending_invitation_empty_list"
                                    )}
                                </LspTypography>
                            )}

                            {pendingList?.map((item) => {
                                return (
                                    <Fragment key={item.phoneNumber}>
                                        <InvitationPendingItem
                                            detail={item}
                                            classes={classes}
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>

                        <div className={classes.footer}>
                            <LspButton
                                variant="secondary"
                                onClick={onClose}
                                fullWidth
                            >
                                {t("lb_close")}
                            </LspButton>
                        </div>
                    </div>
                </Content>
            )}
        </>
    );
};

export default InvitationPendingList;
