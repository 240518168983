import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { call, put } from "redux-saga/effects";
import OTPController from "@helpers/controllers/OTPController";
import forgotPassordService from "@services/forgot-password";
import ForgotPasswordActions from "@redux/actions/forgor-password";

export function* forgotPasswordSaga(action) {
    const inforOTP = action.payload;
    const response = yield call(forgotPassordService.commitOTP, inforOTP);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
        case RESPONSE_CODE.SUCCESS_V2:
            OTPController.hide();
            yield put(ForgotPasswordActions.otpCommitSuccess());
            break;
        case RESPONSE_CODE.FAST_CASH_REACH_LIMIT:
            OTPController.hide();
            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.FAST_CASH_LIMIT,
            });
            break;

        default:
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
}
