import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import Content from "@components/Content";
import LspButtonIconLink from "@components/LspButtonIconLink";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    body: {
        background: theme.palette.neutral.white,
        textAlign: "center",
    },
    top: {
        background: theme.palette.primary.main,
        padding: theme.spacing(4, 4),
        "& > span": {
            fontSize: 43,
            color: theme.palette.neutral.white,
        },
    },
}));

const PiggyPopup = ({ amount }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Content size="xs">
            <div className={classes.body}>
                <div className={classes.top}>
                    <Icon className="font-icon icon-piggy" />
                    <LspTypography variant="body2" color="white">
                        {t("gs_lb_balance_piggy_bank")}
                    </LspTypography>
                    <LspTypography variant="title2" color="white">
                        {amount}
                    </LspTypography>
                </div>
                <Box p={4}>
                    <LspTypography variant="body2" color="grey">
                        {t("gs_info_piggy_bank")}
                    </LspTypography>

                    <LspButtonIconLink
                        mt={4}
                        startIcon={<Icon className="font-icon icon-close" />}
                        onClick={() => GlobalDialogController.hide()}
                        color="default"
                    >
                        {t("lb_close")}
                    </LspButtonIconLink>
                </Box>
            </div>
        </Content>
    );
};

export default PiggyPopup;
