import api from "../config/api";

const getAccountStatus = async () => {
    return await api.get("login/userAccountStatus", null);
};

const getAccountList = async () => {
    return await api.get("user/accountPreview", null);
};

const getPayAnyoneDashboard = async () => {
    return await api.get("user/payanyone/dashboard");
};

const getSpendAccountTransactionList = async (data) => {
    return await api.post("user/account/transaction/list", data);
};

const getPayeeTransactionHistories = async (data) => {
    return await api.post("user/account/transaction/list2", data);
};

const exportSpendAccountTransactionList = async (data) => {
    return await api.post("user/account/transactions/spend/export", data, {
        responseType: "blob",
    });
};

const getCreditCardTransactionList = async (data) => {
    return await api.post("user/creditcard/trans", data);
};

const commitOTP = async ({ txnId, ...payload }) => {
    return await api.post(
        `/user/creditcard/installment/${txnId}/commit`,
        payload,
        {
            bypassErrorHandler: true,
        }
    );
};

const getPayAnyoneList = async (data) => {
    return await api.post("user/payanyone/PA/list", {
        ...data,
        paoType: "PA",
    });
};

const getTermDepositList = async ({
    createDateIndex,
    statusIndex,
    listStatus,
}) => {
    return await api.get("/user/term-deposit/v2/getList/0", {
        createDateIndex,
        statusIndex,
        listStatus,
    });
};

const getInstallmentData = async (data) => {
    return await api.post("user/creditcard/installment/trans", data);
};

const getInstallmentById = async ({ id }) => {
    return await api.get(`user/creditcard/installment/${id}`);
    // Mockup data
    // return await apiMock.get(`user/creditcard/installment`, {
    //     params: { type: "actived" },
    // });
    // return await apiMock.get(`user/creditcard/installment`, {
    //     params: { type: "pending" },
    // });
    // return await apiMock.get(`user/creditcard/installment`, {
    //     params: { type: "completed" },
    // });
};

const getPaymentDetailById = async ({ paId }) => {
    return await api.post(`/user/payanyone/PA/detail/${paId}`);
};

const updatePaymentStatus = async ({ paId, ...others }) => {
    return await api.post(`/user/payanyone/PA/update/${paId}`, others);
};

const cancelInstallment = async ({ txnId }) => {
    return await api.post(`user/creditcard/installment/${txnId}/cancel`, {
        txnId,
    });
};

const deleteInstallment = async ({ txnId }) => {
    return await api.delete(`user/creditcard/installment/${txnId}`, null, {
        data: { txnId },
    });
};

const getCloseEarlyInstallmentInfo = async ({ txnId }) => {
    return await api.get(`user/creditcard/installment/pay/${txnId}/check`);
};

const checkTransactionInstallment = async ({ txnId }) => {
    return await api.get(`user/creditcard/installment/${txnId}/check`);
};

const applyInstallment = async (data) => {
    return await api.post(
        `user/creditcard/installment/${data.txnId}/apply`,
        data
    );
};

const getListCategories = async (data) => {
    return await api.post("user/creditcard/discount/categories", data);
};

const getTagList = async () => {
    return await api.get("user/creditcard/discount/tag/list");
};

const getLastStatementList = async (data) => {
    return await api.post("/user/creditcard/statement/list", data);
};

const getLastStatementDetail = async (data) => {
    return await api.post("/user/creditcard/statement/detail", data);
};

const getCurrentStatementList = async (data) => {
    return await api.post("/user/creditcard/statement/current", data);
};

const getGoalSaveList = async (data) => {
    return await api.post("/user/account/goalSave/list", data);
};

const contributeGoalSave = async (data) => {
    return await api.post("/user/account/goalSave/contribute", data);
};
const deleteGoalSave = async (data) => {
    return await api.post("/user/account/goalSave/delete", data);
};
const updateGoalSave = async ({ planId, ...payload }) => {
    return await api.put(`/user/account/goalSave/list/${planId}`, payload);
};
const updateBackgroundGoalSave = async (payload) => {
    return await api.post(`/user/account/goalSave/uploadImage`, payload);
};
const suggestionGoalInfo = async (payload) => {
    return await api.post(`/user/account/goalSave/suggest`, payload);
};

const accountLimit = async (payload) => {
    return await api.get(`/user/account/limit`, payload);
};

const modifyAccountLimit = async (payload) => {
    return await api.put(`/user/account/limit/amount`, payload);
};

const changePasswordLater = async (payload) => {
    return await api.post(`/user/password/latter`, payload);
};

const accountService = {
    getAccountStatus,
    getAccountList,
    getPayAnyoneDashboard,
    getSpendAccountTransactionList,
    exportSpendAccountTransactionList,
    getCreditCardTransactionList,
    getPayAnyoneList,
    getTermDepositList,
    getInstallmentData,
    getInstallmentById,
    getPaymentDetailById,
    updatePaymentStatus,
    cancelInstallment,
    deleteInstallment,
    getCloseEarlyInstallmentInfo,
    getListCategories,
    getTagList,
    getLastStatementList,
    getLastStatementDetail,
    getCurrentStatementList,
    checkTransactionInstallment,
    applyInstallment,
    commitOTP,
    getGoalSaveList,
    contributeGoalSave,
    deleteGoalSave,
    updateGoalSave,
    updateBackgroundGoalSave,
    suggestionGoalInfo,
    getPayeeTransactionHistories,

    // account limit
    accountLimit,
    modifyAccountLimit,

    changePasswordLater,
};

export default accountService;
