import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import ReIssuedCard from "@containers/CardManagement/ReIssuedCard";
import userAction from "@redux/actions/user";
import CardManagementActions from "@redux/actions/card-management";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import userService from "@services/user";
import LspTranslation from "@components/LspTranslation";
import useCard from "@helpers/useCard";
import CardFetching from "./CardFetching";
import CardRender from "./CardRender";
import {
    CARD_TYPES,
    CARD_ACTIONS,
    E_COMMERCE_STATUSES,
    CardManagementNavigation,
    CARD_MANAGEMENT_SUB_NAV,
    REISSUE_CARD_VERSION,
} from "./constant";

const useStyles = makeStyles((theme) => ({
    cardInfo: {
        position: "relative",
        padding: theme.spacing(6, 3, 3, 3),
    },
    content: {
        margin: "auto",
        width: "100%",
    },
}));

const CardDetail = ({
    type,
    cardList,
    fetchingCardList,
    getCardList,
    cardListStatus,
    cardStatusUpdating,
    updateCardStatus,
    eCommerceSUpdating,
    updateECommerce,
    systemParams,
    enableReIssuedCard,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const cardNav =
        type === CARD_TYPES.FE_DEBIT_PARAM
            ? CARD_TYPES.DEBIT_CARD
            : CARD_TYPES.VISA_CARD;
    const history = useHistory();
    const { isVirtualCard } = useCard();

    useEffect(() => {
        if (!cardListStatus) {
            getCardList();
        }
    }, [cardListStatus, getCardList]);

    const isVirtualDebit = (cardType) => {
        return (
            cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
            cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
        );
    };

    const updateCard = useCallback(
        (card, isLockCard) => {
            if (!isLockCard && !card.unLockable) {
                const phone = t("hangout:callcenter_number");
                const content = t(
                    "master:manage_card_unlock_popup_desc"
                ).replace("%@", `<a href="tel: ${phone}">${phone}</a>`);

                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("master:manage_card_unlock_popup_title"),
                        translatedContent: content,
                        button: t("lb_btn_ok_got_it"),
                    },
                });
                return;
            }

            updateCardStatus({
                cardId: card.cardId,
                cardStatus: isLockCard
                    ? CARD_ACTIONS.LOCK_CARD
                    : CARD_ACTIONS.UNLOCK_CARD,
                cardType: card.cardType,
            });
        },
        [updateCardStatus, t]
    );

    const lockCardConfirmation = useCallback(
        (card) => {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "problem",
                    header: "fc_lb_attention",
                    content: isVirtualDebit(card?.cardType)
                        ? "master:manage_card_visa_virtual_popup_lock_desc"
                        : "card_lock_card_confirmation_desc",
                    doubleButton: [
                        {
                            label: t("lb_continue"),
                            onClick: () => {
                                updateCard(card, true);
                                GlobalDialogController.hide();
                            },
                        },
                        {
                            label: t("lb_cancel"),
                            onClick: () => {
                                GlobalDialogController.hide();
                            },
                        },
                    ],
                },
            });
        },
        [updateCard, t]
    );

    const eCommerceConfirm = useCallback(
        (card) => {
            const eComStatus = card.ecomStatus;

            const isEnable = eComStatus === E_COMMERCE_STATUSES.ENABLED;
            const content = isEnable
                ? "card_txt_disable_ecommerce_confirmation"
                : "mc_lb_on_ecommerce_warning";

            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    icon: "card2",
                    header: "card_title_disable_ecommerce_confirmation",
                    content,
                    doubleButton: [
                        {
                            label: t("lb_continue"),
                            onClick: () => {
                                updateECommerce({
                                    cardId: card.cardId,
                                    cardStatus: isEnable
                                        ? CARD_ACTIONS.DISABLE_ECOMMERCE
                                        : CARD_ACTIONS.ENABLE_ECOMMERCE,
                                    cardType: card.cardType,
                                });
                                GlobalDialogController.hide();
                            },
                        },
                        {
                            label: t("lb_cancel"),
                            onClick: () => {
                                GlobalDialogController.hide();
                            },
                        },
                    ],
                },
            });
        },
        [t, updateECommerce]
    );

    const resetPin = useCallback(
        (card) => {
            history.push(
                `${CardManagementNavigation.ResetPin}?cardId=${card.cardId}&type=${type}`
            );
        },
        [history, type]
    );
    const changePin = useCallback(
        (card) => {
            if (card?.wrongPin) {
                const maxWrongPin =
                    systemParams?.maxWrongChangePinAttemptsForCard || 3;
                if (card?.wrongPin >= maxWrongPin) {
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            icon: "problem",
                            header: "mc_lb_title_change_pin_not_success",
                            content: "mc_lb_content_forgot_pin_warning",
                            button: "lb_ok",
                        },
                    });
                    return;
                }
            }

            history.push(
                `${CardManagementNavigation.ChangePin}?cardId=${card.cardId}&type=${type}`
            );
        },
        [history, systemParams?.maxWrongChangePinAttemptsForCard, type]
    );

    const reIssueCardNewFlow = useCallback(
        async (card, isVirtual) => {
            const { cardId, cardImageUrl } = card;
            const response = await userService.reissueCheck();
            const isValidIDDocument =
                response?.data?.data?.isValidIDDocument || false;

            const header = isVirtual
                ? t("virtualcard:reissued_virtual_error_ID_invalid_title")
                : t("debitCard:reissuecard_error_expired_id_title");

            const tel = `<a href='tel: ${t("hangout:callcenter_number")}'>${t(
                "hangout:callcenter_number"
            )}</a>`;

            const desc = t(
                "virtualcard:reissued_virtual_error_ID_invalid_desc"
            ).replace("%@", tel);

            const content = isVirtual
                ? desc
                : t("debitCard:reissuecard_error_expired_id_desc");

            if (!isValidIDDocument) {
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header,
                        translatedContent: content,
                        button: t(
                            "debitCard:reissuecard_error_expired_id_CTA_ok"
                        ),
                    },
                    onClose: () => {
                        if (isVirtual) {
                            return;
                        }
                        GlobalDialogController.show({
                            size: "sm",
                            component: () => (
                                <ReIssuedCard
                                    isValidIDDocument={isValidIDDocument}
                                    cardId={cardId}
                                    reissueVersion={
                                        REISSUE_CARD_VERSION.CARD_V2
                                    }
                                />
                            ),
                        });
                    },
                });
                return;
            }

            const reissueVersion = isVirtual
                ? REISSUE_CARD_VERSION.VIRTUAL
                : REISSUE_CARD_VERSION.CARD_V2;

            GlobalDialogController.show({
                size: "sm",
                component: () => (
                    <ReIssuedCard
                        cardId={cardId}
                        cardImageUrl={cardImageUrl}
                        isValidIDDocument={isValidIDDocument}
                        reissueVersion={reissueVersion}
                    />
                ),
            });
        },
        [t]
    );

    const reissueCard = useCallback(
        (card) => {
            const { cardType } = card;
            const isVirtual = isVirtualCard(cardType);

            if (enableReIssuedCard || isVirtual) {
                reIssueCardNewFlow(card, isVirtual);
                return;
            }

            history.push(
                `${CardManagementNavigation.ReIssueCard}?cardId=${card.cardId}`
            );
        },
        [enableReIssuedCard, history, reIssueCardNewFlow, isVirtualCard]
    );

    const cardLimit = useCallback(
        (card) => {
            if (
                (type === CARD_MANAGEMENT_SUB_NAV.DEBIT &&
                    card.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD) ||
                card.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
            ) {
                history.push(
                    `${CardManagementNavigation.CardLimit}?cardId=${card.cardId}&type=${type}&cardType=${card.cardType}`
                );
                return;
            }
            history.push(
                `${CardManagementNavigation.CardLimit}?cardId=${card.cardId}&type=${type}`
            );
        },
        [history, type]
    );

    const cardContent = useCallback(() => {
        if (fetchingCardList) {
            return <CardFetching />;
        }
        return (
            <CardRender
                cardList={cardList}
                cardNav={cardNav}
                lockCardConfirmation={lockCardConfirmation}
                unlockCard={updateCard}
                cardStatusUpdating={cardStatusUpdating}
                className={classes.cardInfo}
                onChangeECommerceStatus={eCommerceConfirm}
                eCommerceSUpdating={eCommerceSUpdating}
                resetPin={resetPin}
                reissueCard={reissueCard}
                changePin={changePin}
                cardLimit={cardLimit}
            />
        );
    }, [
        fetchingCardList,
        cardList,
        cardNav,
        lockCardConfirmation,
        updateCard,
        cardStatusUpdating,
        classes.cardInfo,
        eCommerceConfirm,
        eCommerceSUpdating,
        resetPin,
        reissueCard,
        changePin,
        cardLimit,
    ]);

    return <div className={classes.content}>{cardContent()}</div>;
};

const mapStateToProps = (state) => ({
    cardList: state.cardInfo.cardList,
    cardListStatus: state.cardInfo.cardListStatus,
    fetchingCardList: state.cardInfo.fetchingCardList,
    cardStatusUpdating: state.cardInfo.cardStatusUpdating,
    eCommerceSUpdating: state.cardInfo.eCommerceSUpdating,
    systemParams: state.systemParams.info.sysparams["1.0"],
    enableReIssuedCard:
        state.user.featureState?.data?.enableReIssuedCard || false,
});

const mapDispatchToProps = (dispatch) => ({
    getCardList: () => dispatch(CardManagementActions.getCardList()),
    updateCardStatus: (payload) =>
        dispatch(CardManagementActions.updateCardStatus(payload)),
    updateECommerce: (payload) =>
        dispatch(CardManagementActions.updateECommerce(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardDetail);
