import { isArray } from "lodash";
import { useCallback } from "react";

const usePathname = () => {
    const generateChildPathname = useCallback(
        (parentName = "", childName = "", urlParams) => {
            let path = "";
            if (parentName !== "") {
                path = `/${path}${parentName}`;
            }
            if (childName !== "") {
                path = `${path}/${childName}`;
            }
            if (urlParams && typeof urlParams === "string") {
                path += `/:${urlParams}`;
            } else if (Array.isArray(urlParams)) {
                urlParams.forEach((p) => {
                    path += `/:${p}`;
                });
            }
            return path;
        },
        []
    );

    const checkSelectedMenu = useCallback((currentPath, pathList) => {
        if (!currentPath || !pathList) return false;

        if (isArray(pathList)) {
            for (let i = 0; i < pathList.length; i++) {
                if (currentPath.includes(pathList[i])) {
                    return true;
                }
            }
            return false;
        }

        return currentPath.includes(pathList);
    }, []);

    const getQueryParams = useCallback((name, url = window.location.href) => {
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }, []);

    return { generateChildPathname, checkSelectedMenu, getQueryParams };
};

export default usePathname;
