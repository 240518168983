import { call, put, select } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import notificationService from "@services/notification";
import { RESPONSE_CODE } from "@config/constants";
import notificationAction from "../actions/notification";

const getIdIndex = (state) => state.notification.idIndex;

export function* checkNotificationSaga() {
    const response = yield call(notificationService.checkNotification);
    // if (response.ok) {
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(notificationAction.checkNotificationSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(notificationAction.checkNotificationError());
            break;
    }
    // } else {
    //     yield put(notificationAction.checkNotificationError());
    // }
}

export function* getNotificationListSaga(action) {
    const { payload } = action;
    const params = {};
    if (payload.loadMore) {
        const idIndex = yield select(getIdIndex);
        params.idIndex = idIndex;
    }
    const response = yield call(
        notificationService.getNotificationList,
        params
    );
    // if (response.ok) {
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(notificationAction.getNotificationListSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(notificationAction.getNotificationListError());
            break;
    }
    // } else {
    //     yield put(notificationAction.getNotificationListError());
    // }
}
