import LspTypography from "@components/LspTypography";
import { APP_CONTENT } from "@config/constants";
import useNumber from "@helpers/useNumber";
import {
    Box,
    Icon,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: theme.spacing(1, 2),
        border: `1px solid ${theme.palette.neutral.grey3}`,
        marginTop: theme.spacing(2),
    },
    table: {
        "& .MuiTableCell-sizeSmall": {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        "& .MuiTypography-colorPrimary": {
            fontWeight: 600,
        },
        "& .MuiTableRow-root:last-child": {
            "& .MuiTableCell-root": {
                borderBottom: "none",
            },
        },
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
}));

const NewBillSunLifeReview = ({ sunLifeAccount, policyNumber, amount }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { thousandSeparator, decimalSeparator } = useNumber();

    return (
        <Box textAlign="center">
            <LspTypography>
                {t("wealth_life_insurance_title_review")}
            </LspTypography>
            <TableContainer component={Box} className={classes.tableContainer}>
                <Table size="small" className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right">
                                <LspTypography variant="body2" color="grey">
                                    {t("lb_to")}
                                </LspTypography>
                            </TableCell>
                            <TableCell>
                                <Box display="flex" alignItems="center">
                                    <Icon
                                        className={`${APP_CONTENT.LOGO_FONT_ICON_MINI} ${classes.icon}`}
                                    />
                                    <Box>
                                        {sunLifeAccount?.accountNumber && (
                                            <LspTypography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {sunLifeAccount?.accountNumber}
                                            </LspTypography>
                                        )}
                                        {sunLifeAccount?.bankName && (
                                            <LspTypography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {sunLifeAccount?.bankName}
                                            </LspTypography>
                                        )}
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">
                                <LspTypography variant="body2" color="grey">
                                    {t("lb_amount_web")}
                                </LspTypography>
                            </TableCell>
                            <TableCell>
                                <LspTypography variant="body2" color="primary">
                                    <NumberFormat
                                        value={amount?.value}
                                        displayType="text"
                                        thousandSeparator={thousandSeparator}
                                        decimalSeparator={decimalSeparator}
                                    />
                                </LspTypography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">
                                <LspTypography variant="body2" color="grey">
                                    {t(
                                        "wealth_life_insurance_label_policy_number"
                                    )}
                                </LspTypography>
                            </TableCell>
                            <TableCell>
                                <LspTypography variant="body2" color="primary">
                                    {policyNumber}
                                </LspTypography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default memo(NewBillSunLifeReview);
