import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import { RequestDocumentKey } from "@config/constants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import DocumentList from "./DocumentList";
import DocumentInfo from "./DocumentInfo";

const parentName = "request-document";

const RequestDocument = () => {
    const { t } = useTranslation();
    const { generateChildPathname } = usePathname();
    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(parentName, "list"),
                component: DocumentList,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    RequestDocumentKey.documentInfo
                ),
                component: DocumentInfo,
            },
        ],
        [generateChildPathname]
    );

    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    return (
        <>
            <Banner
                title={t("request_document_label")}
                subtitle={t("rq_document_header")}
            />
            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};

export default RequestDocument;
