import { Avatar, makeStyles } from "@material-ui/core";

import timoCircleLogo from "@assets/images/circle-logo.png";
import contactIcon from "@assets/images/contact_icon.png";
import timoDefaultAvatar from "@assets/images/timo_default_avatar.png";

import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { memo } from "react";
import useNumber from "@helpers/useNumber";
import { ADJ_DIRECT, METRIC_NAME, METRIC_SCREEN } from "@config/constants";
import { useTranslation } from "react-i18next";
import metricActions from "@redux/actions/metric";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
    billMember: {
        display: "flex",
        flexDirection: "column",
        "&:last-child": {
            marginBottom: 0,
        },
    },
    billMemberItem: {
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    billMemberItemIcon: {
        borderRadius: "50%",
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.magicalGrey[300],
    },
    billMemberItemIconAvatar: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: "24px",
        letterSpacing: "0.3px",
        "& .MuiAvatar-img": {
            width: "100%",
            height: "100%",
        },
    },
    billShortName: {
        color: theme.palette.neutral.white,

        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    timoMember: {
        width: 24,
        height: 24,
        position: "absolute",
        bottom: -6,
        right: -8,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    defaultAvatar: {
        padding: theme.spacing(1.5),
    },
    timoMemberImg: {
        width: "100%",
        height: "100%",
    },
    billMemberItemBody: {
        flex: 1,
        display: "inline-flex",
        alignItems: "center",
    },
    billMemberItemBodyLeft: {
        flex: 1,
    },
    billMemberText: {
        fontWeight: 700,
        textAlign: "left",
    },
    billMemberHost: {
        fontWeight: 400,
        textAlign: "left",
        textTransform: "uppercase",
        color: theme.palette.purple.main,
    },
    billMemberSender: {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: "18px",
        letterSpacing: "0.3px",
        textTransform: "lowercase",
        color: theme.palette.magicalGrey[300],
    },
    billMemberItemBodyRight: {},
    billMemberAmount: {
        fontWeight: 600,
        textAlign: "right",
    },
    billMemberPayment: {
        fontWeight: 400,
        textAlign: "right",
    },
    billMemberItemAction: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        "&:last-child": {
            marginBottom: 0,
        },
        "&:empty": {
            paddingBottom: 0,
        },
    },
    noBackgroundColor: {
        backgroundColor: "unset",
    },
}));

const SPLIT_BILL_STATUS = {
    COMPLETED: "COMPLETED",
    PENDING: "PENDING",
};

const BillMemberItem = ({ memberItem, metricRequest, metricConfig }) => {
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const { t } = useTranslation();

    const handleShortenName = (name) => {
        const splitFullName = (name || "Timo").split(" ");
        const { length } = splitFullName;
        const firstName = (length > 0 && splitFullName[0]) || "";
        const lastName =
            (length > 0 && length > 1 && splitFullName[length - 1]) || "";
        return firstName.charAt(0) + lastName.charAt(0);
    };

    const isPaid = () => {
        return memberItem?.status === SPLIT_BILL_STATUS.COMPLETED;
    };

    const activeDefaultAvatar = () => {
        return memberItem?.payeeName && !memberItem?.timoUser;
    };

    const handlePayment = (metricRes) => {
        const endpoint = window.location.origin;

        const id = memberItem?.refUuid || memberItem?.id;

        const params = new URLSearchParams({
            appSessionId: metricConfig.appSessionId || "",
            previousEventId: metricRes.data.event_id || "",
            previousScreenName: metricRes.data.screen_name || "",
            previousMetricName: metricRes.data.metric_name || "",
        });

        const splitBillLink = `${endpoint}/payment/${id}?${params.toString()}`;
        const splitBillDeepLink = `${ADJ_DIRECT.SPLIT_BILL}?id=${
            memberItem?.id || ""
        }`;

        const time = new Date().getTime();

        const metricData = {
            data: {
                detail_data: {
                    app_session_id: metricConfig.appSessionId || "",
                    screen_session_id: metricConfig.screenSessionId,
                    event_id: uuidv4() || "",
                    previous_event_id: "",
                },
                error_code: "",
                error_message: "",
                flow_name: "",
                metric_location: "WEB",
                previous_event_name: "",
                previous_screen_name: "",
                screen_name: METRIC_SCREEN.SPLIT_BILL,
                metric_name: METRIC_NAME.END,
                time_stamp_number: time || "",
            },
            force: true,
        };

        metricRequest(metricData);

        if (memberItem?.timoUser) {
            const decodeURISplitBillLink = encodeURIComponent(splitBillLink);
            const adj = (ADJ_DIRECT.ADJ_LINK || "")
                .split("__HOME_PAGE__")
                .join(splitBillDeepLink)
                .split("__DECODE_URI__")
                .join(decodeURISplitBillLink);
            window.open(adj, "_blank");
            return;
        }

        window.open(splitBillLink, "_blank");
    };

    return (
        <>
            <div className={classes.billMember}>
                <div className={classes.billMemberItem}>
                    <div
                        className={`${classes.billMemberItemIcon} ${
                            memberItem?.timoUser
                                ? classes.noBackgroundColor
                                : ""
                        }`}
                    >
                        <div className={classes.billMemberItemIconAvatar}>
                            {activeDefaultAvatar() && (
                                <Avatar
                                    alt="Default Timo Logo"
                                    className={classes.billShortName}
                                >
                                    {handleShortenName(memberItem?.payeeName)}
                                </Avatar>
                            )}
                            {memberItem?.timoUser && (
                                <Avatar
                                    src={timoDefaultAvatar}
                                    alt="Profile Avatar"
                                    className={classes.billShortName}
                                />
                            )}
                            {memberItem?.phoneNumber &&
                                !memberItem?.payeeName &&
                                !memberItem?.timoUser && (
                                    <Avatar
                                        src={contactIcon}
                                        alt="Timo Circle Avatar"
                                        className={`${classes.billShortName} ${classes.defaultAvatar}`}
                                    />
                                )}
                        </div>

                        {memberItem?.timoUser && (
                            <div className={classes.timoMember}>
                                <img src={timoCircleLogo} alt="Timo" />
                            </div>
                        )}
                    </div>

                    <div className={classes.billMemberItemBody}>
                        <div className={classes.billMemberItemBodyLeft}>
                            <LspTypography
                                className={classes.billMemberText}
                                variant="heading3"
                                color="black"
                            >
                                {memberItem?.payeeName
                                    ? memberItem?.payeeName
                                    : memberItem?.phoneNumber}
                            </LspTypography>
                            {memberItem?.sender && (
                                <LspTypography
                                    className={classes.billMemberHost}
                                    variant="body3"
                                    color="purple"
                                >
                                    {" "}
                                    {t(
                                        "splitBill:sb_split_bill_sub_title_host"
                                    )}{" "}
                                    &nbsp;
                                    <span className={classes.billMemberSender}>
                                        {t(
                                            "splitBill:sb_split_bill_sub_title_host_me"
                                        )}
                                    </span>
                                </LspTypography>
                            )}
                        </div>
                        <div className={classes.billMemberItemBodyRight}>
                            <LspTypography
                                className={classes.billMemberAmount}
                                variant="heading3"
                                color="black"
                            >
                                {formatNumber(memberItem?.amount)}
                            </LspTypography>

                            <div className={classes.billMemberPayment}>
                                {isPaid() && !memberItem?.sender && (
                                    <LspTypography
                                        variant="body3"
                                        color="success"
                                    >
                                        {t(
                                            "splitBill:sb_split_bill_detail_status_paid"
                                        )}
                                    </LspTypography>
                                )}
                                {!isPaid() && !memberItem?.sender && (
                                    <LspTypography variant="body3" color="gold">
                                        {t(
                                            "splitBill:sb_split_bill_detail_status_unpaid"
                                        )}
                                    </LspTypography>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.billMemberItemAction}>
                    {!isPaid() && (
                        <LspButton
                            fullWidth
                            // onClick={handlePayment}
                            enableMetric
                            forceRequest
                            onMetricSubmit={handlePayment}
                            metricData={{
                                screen_name: METRIC_SCREEN.SPLIT_BILL,
                                metric_name: METRIC_NAME.TAP_PAYMENT,
                                screen_session_id: metricConfig.screenSessionId,
                                detail_data: {
                                    host_id: memberItem?.id,
                                    received_type: memberItem?.timoUser
                                        ? "timo"
                                        : "non_timo",
                                },
                            }}
                        >
                            {t("splitBill:sb_web_btn_pay_now")}
                        </LspButton>
                    )}
                </div>
            </div>
        </>
    );
};

const stateProps = (state) => ({
    metricConfig: state.metrics?.metricConfig,
});

const dispatchProps = (dispatch) => ({
    metricRequest: (payload) =>
        dispatch(metricActions.metricRequestUpdate(payload)),
});

export default memo(connect(stateProps, dispatchProps)(BillMemberItem));
