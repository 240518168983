/* eslint-disable react/no-array-index-key */
import { makeStyles, Paper } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import useSharedClasses from "@containers/Wealth/WealthCreditCard/wealthCreditCardSharedClasses";
import PageHeaderBack from "@components/PageHeaderBack";
import LspButton from "@components/LspButton";
import format from "date-fns/format";
import { parse } from "date-fns";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(2, 2, 3, 2),
    },
    label: {
        marginTop: theme.spacing(2),
    },
}));

const WealthCreditCardBookingConfirmation = ({
    onBack,
    onSubmit,
    submitting,
    hangout,
    time,
}) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const classes = useStyles();

    return (
        <>
            <PageHeaderBack onBack={onBack} />
            <Paper>
                <div className={sharedClasses.formHeader}>
                    <LspTypography variant="heading3" color="grey">
                        {t("wealth:mc_v3_confirm_booking")}
                    </LspTypography>
                </div>
                <div className={classes.body}>
                    <LspTypography
                        variant="heading4"
                        className={classes.label}
                        color="grey"
                    >
                        {t("wealth:mc_v3_successful_booking_location")}
                    </LspTypography>
                    <LspTypography variant="heading2" color="primary">
                        {`${hangout.city} ${t("lb_hangout")}`}
                    </LspTypography>
                    <LspTypography variant="body3">
                        {hangout.address}
                    </LspTypography>
                    <LspTypography
                        variant="heading4"
                        className={classes.label}
                        color="grey"
                    >
                        {t("wealth:mc_v3_cussessful_booking_time")}
                    </LspTypography>
                    <LspTypography variant="heading2" color="primary">
                        {format(
                            parse(
                                time.fromTime,
                                "yyyy-MM-dd HH:mm",
                                new Date()
                            ),
                            "HH:mm EEEE, dd/MM/yyyy"
                        )}
                    </LspTypography>
                </div>
            </Paper>
            <LspButton
                onClick={onSubmit}
                progressing={submitting}
                fullWidth
                className={sharedClasses.button}
            >
                {t("wealth:mc_v3_btn_confirm2")}
            </LspButton>
        </>
    );
};

export default memo(WealthCreditCardBookingConfirmation);
