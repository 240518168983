import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

const useStepNavigation = ({ initialStep }) => {
    const history = useHistory();

    const [step, setStep] = useState({
        previous: [],
        current: initialStep,
    });

    const stepForward = useCallback(
        (stepName) => {
            setStep({
                previous: [...step.previous, step.current],
                current: stepName,
            });
        },
        [step]
    );

    const stepBack = useCallback(() => {
        if (step.previous.length <= 0) {
            history.goBack();
            return;
        }
        const prevStep = step.previous[step.previous.length - 1];
        setStep({
            previous: step.previous.slice(0, step.previous.length - 1),
            current: prevStep,
        });
    }, [history, step]);

    return {
        step,
        stepForward,
        stepBack,
    };
};

export default useStepNavigation;
