import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    LspDialog,
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import { RESPONSE_CODE } from "@config/constants";
import Loading from "@components/Loading";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import useNumber from "@helpers/useNumber";
import DoubleButtons from "@components/DoubleButtons";
import termDepositService from "@services/term-deposit";
import LspTypography from "@components/LspTypography";
import AlertController from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    contentBox: {
        color: theme.palette.black.main,
        border: `1px solid ${theme.palette.grey[300]}`,
        display: "flex",
    },
    contentBoxColumn: {
        padding: theme.spacing(3, 2),
        flex: 1,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        "&.lastColumn": {
            borderRight: `1px solid transparent`,
        },
        "& div:nth-child(2), & div:nth-child(4)": {
            marginTop: theme.spacing(1),
        },
    },
    vsLabel: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        height: theme.spacing(5),
        width: theme.spacing(5),
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

const EarlyRedemptionDialog = ({ tdId, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [redemption, setRedemption] = useState();
    const { formatNumber } = useNumber();

    const unmounted = useRef(false);
    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getDetails = useCallback(async () => {
        if (!tdId) {
            return;
        }
        setLoading(true);
        const response = await termDepositService.getEarlyRedemptionData({
            termDepositId: tdId,
        });
        if (unmounted.current) {
            return;
        }
        if (response.ok) {
            const { code, data } = response.data;
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    setRedemption(data);
                    break;
                default:
                    break;
            }
        }
        setLoading(false);
    }, [tdId]);

    useEffect(() => {
        getDetails();
    }, [getDetails]);

    const redeem = useCallback(async () => {
        if (!tdId) {
            return;
        }
        setLoading(true);
        const response = await termDepositService.redeemTermDeposit({
            termDepositId: tdId,
        });
        if (unmounted.current) {
            return;
        }
        setLoading(false);

        const { code } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                onClose({ reload: true });

                const message = `${t(
                    "td_title_redeem_sucessfully"
                )} ${formatNumber(redemption?.redeemAmountNow)} ${t(
                    "td_msg_redeem_success"
                )}`;
                AlertController.show(message);
                break;
            case RESPONSE_CODE.CANNOT_REDEEM_TERM_DEPOSIT:
                onClose();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "problem",
                        header: t("td_ms_redeem_now_error_title"),
                        translatedContent: t("td_msg_redeem_failed"),
                        button: t("lb_done"),
                    },
                    onClose: () => {
                        GlobalDialogController.hide();
                    },
                });
                break;
            case RESPONSE_CODE.LOCKED_TERM_DEPOSIT:
                onClose();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "problem",
                        header: t("td_ms_redeem_now_error_title"),
                        content: t("termDeposit_msg_desc_lockedTD").replace(
                            "%@",
                            redemption?.redeemDate
                        ),
                        button: t("lb_done"),
                    },
                    onClose: () => {
                        GlobalDialogController.hide();
                    },
                });
                break;
            case RESPONSE_CODE.END_OF_DAY_JOB_RUNNING:
                onClose();
                GlobalDialogController.showFinancialError({
                    errorCode: code,
                });
                break;
            case RESPONSE_CODE.AUTH_BIOS:
            case RESPONSE_CODE.AUTH:
            case RESPONSE_CODE.BIOS_NEW_DEVICE:
            case RESPONSE_CODE.FOREIGNER:
            case RESPONSE_CODE.RE_ENROLL:
            case RESPONSE_CODE.REQUIRED_ENROLL:
            case RESPONSE_CODE.RE_ENROLL_ERROR:
                onClose();
                GlobalDialogController.showError({ errorCode: code });

                break;
            default:
                onClose();
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "problem",
                        header: t("td_lb_error_sorry"),
                        content: t("td_cannot_redeem_popup"),
                        button: t("lb_done"),
                    },
                    onClose: () => {
                        GlobalDialogController.hide();
                    },
                });
                break;
        }
    }, [tdId, onClose, t, redemption, formatNumber]);

    return (
        <LspDialog open={!!tdId} maxWidth="sm" scroll="body" onClose={onClose}>
            <LspDialogTitle>{t("td_tv_header_infor_redeem")}</LspDialogTitle>
            <LspDialogContent>
                {loading && !redemption ? (
                    <Loading />
                ) : (
                    <Box
                        position="relative"
                        display="flex"
                        minHeight={100}
                        className={classes.contentBox}
                    >
                        <Box className={classes.contentBoxColumn}>
                            <LspTypography>
                                {t("td_label_redeem_now")}
                            </LspTypography>
                            <LspTypography variant="body3" color="grey">
                                {t("termDeposit_lb_rate")}
                            </LspTypography>
                            <LspTypography
                                variant="subheading1"
                                color="primary"
                            >
                                {redemption?.annualInterestRateNow}%
                            </LspTypography>
                            <LspTypography variant="body3" color="grey">
                                {t("lb_amount_web")}
                            </LspTypography>
                            <LspTypography
                                variant="subheading1"
                                color="primary"
                            >
                                {formatNumber(redemption?.redeemAmountNow)}
                            </LspTypography>
                        </Box>
                        <Box
                            className={clsx(
                                classes.contentBoxColumn,
                                "lastColumn"
                            )}
                        >
                            <LspTypography>{`${t("td_label_redeem_on")} ${
                                redemption?.redeemDate
                            }`}</LspTypography>
                            <LspTypography variant="body3" color="grey">
                                {t("termDeposit_lb_rate")}
                            </LspTypography>
                            <LspTypography
                                variant="subheading1"
                                color="primary"
                            >
                                {redemption?.annualInterestRateOnDate}%
                            </LspTypography>
                            <LspTypography variant="body3" color="grey">
                                {t("lb_amount_web")}
                            </LspTypography>
                            <LspTypography
                                variant="subheading1"
                                color="primary"
                            >
                                {formatNumber(redemption?.redeemAmountOnDate)}
                            </LspTypography>
                        </Box>
                        <div className={classes.vsLabel}>VS</div>
                    </Box>
                )}
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    progressing={redemption && loading}
                    disabled={loading}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: onClose,
                    }}
                    primaryButton={{
                        label: t("td_yes_redeem_now"),
                        onClick: redeem,
                        disable:
                            !redemption?.redeemAmountOnDate ||
                            !redemption?.redeemAmountNow,
                    }}
                />
            </LspDialogActions>
        </LspDialog>
    );
};

export default memo(EarlyRedemptionDialog);
