import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import LspTypography from "@components/LspTypography";

import LspButton from "@components/LspButton";
import Content from "@components/Content";
import { memo, useEffect, useMemo } from "react";
import Interweave from "interweave";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";

const useStyles = makeStyles((theme) => ({
    banner: {
        width: "100%",
        paddingTop: theme.spacing(0.5),
    },
    wrapper: {
        textAlign: "center",
        position: "relative",
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    centerImg: {
        width: 160,
        marginTop: theme.spacing(4),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1),
        cursor: "pointer",
    },
    desc: {
        "& a": {
            color: theme.palette.success.main,
        },
    },
}));

const ConditionNotificationDialog = ({ content, centerImg }) => {
    const classes = useStyles();
    const { banner, title, description, buttons } = content;
    const { t } = useTranslation();

    const isString = useMemo(() => typeof description === "string", [
        description,
    ]);

    const handleCloseDialog = () => {
        GlobalDialogController.hide();
    };

    return (
        <Content size="xs">
            <Paper className={classes.wrapper}>
                {content?.closeButton && (
                    <Icon
                        className={clsx(
                            "font-icon icon-close close-custom-icon",
                            classes.closeButton
                        )}
                        onClick={handleCloseDialog}
                    />
                )}
                {banner && (
                    <img
                        src={banner}
                        alt="banner"
                        className={`${classes.banner} ${
                            centerImg ? classes.centerImg : ""
                        }`}
                    />
                )}
                <Box p={2}>
                    <LspTypography variant="heading1" className={classes.title}>
                        {title}
                    </LspTypography>
                    <LspTypography
                        variant="body2"
                        color="grey"
                        className={classes.desc}
                    >
                        {isString && <Interweave content={description} />}
                        {!isString && description}
                    </LspTypography>
                    <Box pt={2}>
                        {buttons?.map((item, _index) => {
                            return (
                                <LspButton
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={_index}
                                    fullWidth
                                    onClick={item?.onClick}
                                    variant={item?.variant}
                                    mt={1}
                                    mb={1}
                                >
                                    {item?.label}
                                </LspButton>
                            );
                        })}
                    </Box>
                </Box>
            </Paper>
        </Content>
    );
};
export default memo(ConditionNotificationDialog);
