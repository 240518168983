import { CircularProgress, Switch, withStyles } from "@material-ui/core";
import clsx from "clsx";

const SwitchButton = withStyles((theme) => ({
    root: {
        width: 51,
        height: 30,
        padding: 0,
        "&.sizeSmall": {
            width: 45,
            height: 24,
        },
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(21px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.palette.success.main,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: theme.palette.success.main,
            border: "6px solid #fff",
        },
    },
    thumb: {
        // boxShadow: theme.shadows[4],
        width: 28,
        height: 28,
        "&.sizeSmall": {
            width: 22,
            height: 22,
        },
    },
    track: {
        borderRadius: theme.spacing(4) / 2,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
    wrapper: {
        width: 51,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&.sizeSmall": {
            width: 45,
            height: 24,
        },
    },
}))(({ fetching, classes, size, ...props }) => {
    return (
        <div className={clsx(classes.wrapper, { sizeSmall: size === "small" })}>
            {fetching && <CircularProgress size={18} />}
            {!fetching && (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: clsx(classes.root, {
                            sizeSmall: size === "small",
                        }),
                        switchBase: classes.switchBase,
                        thumb: clsx(classes.thumb, {
                            sizeSmall: size === "small",
                        }),
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            )}
        </div>
    );
});

export default SwitchButton;
