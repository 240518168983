import { useEffect, useState, useRef, useCallback } from "react";
import sha256 from "crypto-js/sha256";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import api from "../config/api";
import useFingerprint from "./useFingerprint";
import getClientId from "./getClientId";
import { HeaderKey } from "../config/constants";

const appVersion = process.env.REACT_APP_VERSION;

const uuidKey = sha256("uuidKeyT1m0@412NTMK").toString().substring(0, 16);

const useInitialization = () => {
    const [clientAuth, setClientAuth] = useState(null);
    const clientAuthRef = useRef(null);
    const fingerprint = useFingerprint();

    const token = useSelector((state) => state.auth.token);
    const timoDeviceId = useSelector((state) => state.auth.timoDeviceId);

    const updateClientAuth = (value) => {
        if (clientAuthRef.current !== value) {
            clientAuthRef.current = value;
            setClientAuth(value);
        }
    };

    const initialHeader = useCallback(
        (uuid) => {
            const clientId = getClientId();
            const gofsContext = sha256(`WEB.${uuid}.${appVersion}`).toString();
            const gofsContextId = `${gofsContext}.${uuidv4()}`;

            if (token) {
                if (timoDeviceId) {
                    const deviceKey = `${timoDeviceId || ""}${clientId}`;
                    const headers = {
                        [HeaderKey.Token]: token,
                        [HeaderKey.DeviceKey]: deviceKey,
                        [HeaderKey.ContextId]: gofsContextId,
                    };
                    api.setHeaders(headers);
                    api.deleteHeader(HeaderKey.DeviceReg);

                    updateClientAuth(
                        `${HeaderKey.Token}.${HeaderKey.DeviceKey}`
                    );
                } else {
                    const deviceReg = `${uuid}${clientId}`;
                    const headers = {
                        [HeaderKey.Token]: token,
                        [HeaderKey.DeviceReg]: deviceReg,
                        [HeaderKey.ContextId]: gofsContextId,
                    };
                    api.setHeaders(headers);
                    api.deleteHeader(HeaderKey.DeviceKey);

                    updateClientAuth(
                        `${HeaderKey.Token}.${HeaderKey.DeviceReg}`
                    );
                }
            } else {
                const deviceReg = `${uuid}${clientId}`;
                const headers = {
                    [HeaderKey.DeviceReg]: deviceReg,
                    [HeaderKey.ContextId]: gofsContextId,
                };
                api.setHeaders(headers);
                api.deleteHeader(HeaderKey.Token);
                api.deleteHeader(HeaderKey.DeviceKey);

                updateClientAuth(HeaderKey.DeviceReg);
            }
        },
        [token, timoDeviceId]
    );

    const encryptString = useCallback((text) => {
        // Encrypt the message using AES encryption and the secret key
        const encryptedText = CryptoJS.AES.encrypt(text, uuidKey).toString();
        return encryptedText;
    }, []);
    const decryptString = useCallback((cipherText) => {
        if (cipherText) {
            const bytes = CryptoJS.AES.decrypt(cipherText, uuidKey);
            const plaintext = bytes.toString(CryptoJS.enc.Utf8);
            return plaintext;
        }
    }, []);

    useEffect(() => {
        const storedEncryptedFingerprint = localStorage.getItem(uuidKey);

        if (
            storedEncryptedFingerprint &&
            (storedEncryptedFingerprint !== "" ||
                storedEncryptedFingerprint !== "false")
        ) {
            const decryptedFingerprint = decryptString(
                storedEncryptedFingerprint
            );

            initialHeader(decryptedFingerprint);
        } else if (fingerprint) {
            const encryptedFingerprint = encryptString(fingerprint);

            localStorage.setItem(uuidKey, encryptedFingerprint);
            initialHeader(fingerprint);
        } else {
            updateClientAuth(null);
        }
    }, [fingerprint, initialHeader, encryptString, decryptString]);

    return clientAuth;
};

export default useInitialization;
