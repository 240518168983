import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import lixiActions from "../../redux/actions/lixi";
import { LIXI_ACTIONS, LIXI_NAV, LIXI_STEPS } from "./constant";
import LixiTheme from "./LixiTheme/LixiTheme";
import MemberList from "./MemberList";
import ReviewPayee from "./ReviewPayee/ReviewPayee";

const LixiSender = ({
    type,
    resetState,
    // resetSubmitData
}) => {
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [steps, setSteps] = useState(() => Object.values(LIXI_STEPS));
    const [historySteps, setHistorySteps] = useState([]);
    const [currentStep, setCurrentStep] = useState([]);
    const [payee, setPayee] = useState({});

    const changeStep = useCallback(
        (step) => {
            setCurrentStep(step || steps[0]);
        },
        [steps]
    );

    const nextStep = useCallback(
        (step) => {
            const histories = historySteps;
            histories.push(step || steps[0]);
            setHistorySteps(histories);
            changeStep(step || steps[0]);
        },
        [changeStep, historySteps, steps]
    );

    // // Handler for back to previous step
    const backPreviousStep = () => {
        const histories = historySteps;
        if (histories.length >= 2) {
            const previousStep = histories[histories.length - 2];
            histories.pop();
            setHistorySteps(histories);
            changeStep(previousStep);
        } else {
            const path =
                type === LIXI_ACTIONS.RECEIVE
                    ? LIXI_NAV.RECEIVE_LIST
                    : LIXI_NAV.SEND_LIST;
            history.push(path);
        }
    };

    // const handleDataForBack = (step) => {
    //     switch (step) {
    //         case LIXI_STEPS.PAYEE_LIST:
    //             resetSubmitData();
    //             break;
    //         case LIXI_STEPS.REVIEW_PAYEE:
    //             resetSubmitData(['message', 'amount']);
    //             break;
    //         case LIXI_STEPS.THEME:
    //             resetSubmitData('message');
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const onClickPayeeDetail = () => {
        setPayee(payee);
        nextStep(LIXI_STEPS.REVIEW_PAYEE);
    };

    const reviewPayeeNextClick = () => {
        nextStep(LIXI_STEPS.THEME);
    };

    // init function
    useEffect(() => {
        nextStep();
    }, [nextStep]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <div>
            {currentStep === LIXI_STEPS.PAYEE_LIST && (
                <MemberList onClickPayee={onClickPayeeDetail} />
            )}
            {currentStep === LIXI_STEPS.REVIEW_PAYEE && (
                <ReviewPayee
                    payee={payee}
                    cancelFunc={backPreviousStep}
                    nextFunc={reviewPayeeNextClick}
                />
            )}
            {currentStep === LIXI_STEPS.THEME && (
                <LixiTheme backFunc={backPreviousStep} />
            )}
        </div>
    );
};

const stateProps = () => ({});

const dispatchProps = (dispatch) => ({
    resetState: () => dispatch(lixiActions.reset()),
    // resetSubmitData: (payload) =>
    //     dispatch(lixiActions.resetSubmitInfo(payload)),
});

export default connect(stateProps, dispatchProps)(LixiSender);
