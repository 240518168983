import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspChecker from "@components/LspChecker";
import LspHintInfo from "@components/LspHintInfo";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { FetchStatus, RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import { Box, Paper, makeStyles } from "@material-ui/core";
import termDepositAction from "@redux/actions/term-deposit";
import termDepositService from "@services/term-deposit";
import { useCallback, useEffect, memo, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import accountAction from "@redux/actions/account";
import useTermDepositList from "../useTermDepositList";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    content: {
        marginBottom: theme.spacing(3),
    },
    checker: {
        marginLeft: theme.spacing(3),
    },
}));

const MINIMUM_PARTIAL_TD = 100000;

const PartialWithdraw = ({
    tdDetail,
    getTermDetail,
    previewPartial,
    previewTdFetching,
    previewTdStatus,
    clearValidationTD,
    earlyRedeemTD,
    getEarlyRedeemTD,
    earlyRedeemTDId,
    earlyRedeemTDStatus,
    totalActiveTermDepositAmount,
    setTotalActiveTermDepositAmount,
}) => {
    const classes = useStyles();
    const { tdId } = useParams();
    const { t } = useTranslation();
    const [amount, setAmount] = useState("");
    const { getActiveTermDepositList } = useTermDepositList();

    const history = useHistory();

    const onBack = () => {
        history.goBack();
    };

    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();

    useEffect(() => {
        getTermDetail({ tdId });
    }, [getTermDetail, tdId]);

    useEffect(() => {
        const convertTdIdNumber = parseFloat(tdId);
        if (earlyRedeemTDId !== convertTdIdNumber) {
            getEarlyRedeemTD({ termDepositId: convertTdIdNumber });
        }
    }, [earlyRedeemTDId, earlyRedeemTDStatus, getEarlyRedeemTD, tdId]);

    const onAmountChange = (e) => {
        setAmount(e.floatValue);
    };

    const checkMultiple = (num1, num2) => {
        return num1 % num2 === 0;
    };

    const minimumTDThanBalance = useMemo(
        () => tdDetail?.termDepositBalance - amount >= MINIMUM_PARTIAL_TD,
        [amount, tdDetail?.termDepositBalance]
    );

    const minimumWithdraw = useMemo(
        () =>
            amount >= MINIMUM_PARTIAL_TD &&
            checkMultiple(amount, MINIMUM_PARTIAL_TD),
        [amount]
    );

    const maxWithdraw = useMemo(() => amount <= tdDetail?.termDepositBalance, [
        amount,
        tdDetail?.termDepositBalance,
    ]);

    const isValid = useMemo(
        () =>
            amount !== "" &&
            minimumTDThanBalance &&
            minimumWithdraw &&
            maxWithdraw,
        [amount, minimumTDThanBalance, minimumWithdraw, maxWithdraw]
    );

    const handleCheck = () => {
        previewPartial({ termDepositId: parseFloat(tdId), amount });
    };

    useEffect(() => {
        if (previewTdStatus === FetchStatus.Success) {
            const convertAmount = btoa(amount);
            history.push(
                `/term-deposit/preview-withdraw/${tdId}?amount=${convertAmount}`
            );
            clearValidationTD();
        }
    }, [amount, clearValidationTD, history, previewTdStatus, tdId]);

    const requestToGetList = useCallback(async () => {
        if (totalActiveTermDepositAmount == null) {
            const response = await getActiveTermDepositList({});

            if (response) {
                setTotalActiveTermDepositAmount(response?.totalBalance);
            }
        }
    }, [
        getActiveTermDepositList,
        setTotalActiveTermDepositAmount,
        totalActiveTermDepositAmount,
    ]);

    useEffect(() => {
        requestToGetList();
    }, [requestToGetList]);

    return (
        <Content size="sm">
            <Box component={Paper} p={3}>
                <PageHeaderBack onBack={onBack}>
                    <LspTypography variant="heading1" color="primary">
                        {t("TD:td_early_redeem_partial_withdraw")}
                    </LspTypography>
                    <LspTypography variant="body2" color="grey" inherit>
                        {t(
                            "TD:td_early_redeem_partial_withdraw_start_description"
                        ).replace(
                            "%@",
                            earlyRedeemTD?.annualInterestRateNow || null
                        )}
                    </LspTypography>
                </PageHeaderBack>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <NumberFormat
                            label={t(
                                "TD:td_early_redeem_partial_withdraw_amount_withdraw"
                            )}
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            customInput={LspTextField}
                            onValueChange={onAmountChange}
                            value={amount}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            helperText={t(
                                "TD:td_early_redeem_partial_withdraw_TD_amount"
                            ).replace(
                                "%@",
                                formatNumber(
                                    tdDetail?.termDepositBalance,
                                    false,
                                    true
                                )
                            )}
                            className={classes.input}
                        />
                        <LspHintInfo
                            content={t(
                                "TD:td_early_redeem_partial_withdraw_condition_title"
                            )}
                        />
                        <div className={classes.checker}>
                            <LspChecker
                                label={t(
                                    "TD:td_early_redeem_partial_withdraw_condition_1"
                                )}
                                type="boolean"
                                input={amount}
                                checked={minimumTDThanBalance}
                            />
                            <LspChecker
                                label={t(
                                    "TD:td_early_redeem_partial_withdraw_condition_2"
                                )}
                                regExp=""
                                type="boolean"
                                input={amount}
                                checked={minimumWithdraw}
                            />
                            <LspChecker
                                label={t(
                                    "TD:td_early_redeem_partial_withdraw_condition_3"
                                )}
                                regExp=""
                                type="boolean"
                                input={amount}
                                checked={maxWithdraw}
                            />
                        </div>
                    </div>
                    <LspButton
                        disabled={!isValid}
                        fullWidth
                        onClick={handleCheck}
                        progressing={previewTdFetching}
                    >
                        {t("TD:td_early_redeem_partial_withdraw_review")}
                    </LspButton>
                </div>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    tdDetail: state.termDeposit.tdDetail.data,
    previewTd: state.termDeposit.previewTD.data,
    previewTdStatus: state.termDeposit.previewTD.status,
    previewTdFetching: state.termDeposit.previewTD.fetching,
    earlyRedeemTD: state.termDeposit.earlyRedemptionTD?.data,
    earlyRedeemTDId: state.termDeposit.earlyRedemptionTD?.tdId,
    earlyRedeemTDStatus: state.termDeposit.earlyRedemptionTD?.status,
    totalActiveTermDepositAmount:
        state.account.termDeposit.totalActiveTermDepositAmount,
});

const dispatchProps = (dispatch) => ({
    getTermDetail: (payload) =>
        dispatch(termDepositAction.getTermDepositDetail(payload)),
    previewPartial: (payload) =>
        dispatch(termDepositAction.previewPartialTd(payload)),
    clearValidationTD: () => dispatch(termDepositAction.clearValidateRate()),
    getEarlyRedeemTD: (payload) =>
        dispatch(termDepositAction.earlyRedeemTDRequest(payload)),
    setTotalActiveTermDepositAmount: (amount) =>
        dispatch(accountAction.setTotalActiveTermDepositAmount(amount)),
});

export default connect(mapState, dispatchProps)(memo(PartialWithdraw));
