import LspButton from "@components/LspButton";
import {
    TransferIcon,
    PaymentIcon,
    MobilePhoneIcon,
    ReceiptIcon,
    CreditCardIcon,
} from "@components/LspIcon";
import useAccounts from "@helpers/useAccounts";
import { Grid, Icon } from "@material-ui/core";
import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const QuickActions = ({ featureState }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isOverDraftUser } = useAccounts();
    const enablePaymentRequest = useMemo(() => {
        return !featureState?.unavailableFeatures?.find(
            (i) => i === "PaymentRequest"
        );
    }, [featureState?.unavailableFeatures]);

    const isEnableOverdraft = useMemo(() => featureState?.enableOverDraft, [
        featureState?.enableOverDraft,
    ]);

    const quickActions = useMemo(() => {
        const actionList = [
            {
                id: "movemoneybtn",
                label: "lb_move_money",
                icon: <TransferIcon />,
                path: "/spend-account/move-money",
            },
            {
                id: "topupbtn",
                label: "lb_top_up",
                icon: <MobilePhoneIcon />,
                path: "/spend-account/top-up",
            },

            {
                id: "billpaymentbtn",
                label: "home_label_bill_pay",
                icon: <ReceiptIcon />,
                path: "/spend-account/bill-payment",
            },
        ];

        if (enablePaymentRequest) {
            actionList.push({
                id: "paymentrequestbtn",
                label: "payment_title",
                icon: <PaymentIcon />,
                path: "/payment-request",
            });
        } else {
            actionList.push({
                id: "creditpaymentbtn",
                label: "move_money_lb_credit_card",
                icon: <CreditCardIcon />,
                path: "/spend-account/move-money/credit",
            });
        }

        if (isEnableOverdraft && !isOverDraftUser) {
            actionList.push({
                id: "overdraftbtn",
                label: t("overdraft:overdraft_intro_header"),
                icon: <Icon className="font-icon icon-fasttransfer" />,
                path: "/overdraft",
                fullWidth: true,
            });
        }

        if (isEnableOverdraft && isOverDraftUser) {
            actionList.push({
                id: "overdraft",
                label: t("overdraft:overdraft_dashboard_title"),
                icon: <Icon className="font-icon icon-fasttransfer" />,
                path: "/overdraft",
                fullWidth: true,
            });
        }

        return actionList;
    }, [enablePaymentRequest, isEnableOverdraft, isOverDraftUser, t]);

    return (
        <Grid container spacing={1}>
            {quickActions.map((action) => (
                <Grid key={action.label} item xs={action?.fullWidth ? 12 : 6}>
                    <LspButton
                        variant="secondary"
                        fullWidth
                        buttonProps={{
                            startIcon: action.icon,
                            id: `${action.id}`,
                        }}
                        onClick={() => history.push(action.path)}
                    >
                        {t(action.label)}
                    </LspButton>
                </Grid>
            ))}
        </Grid>
    );
};

const stateProps = (state) => ({
    featureState: state.user.featureState.data,
});

export default memo(connect(stateProps)(QuickActions));
