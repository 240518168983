import { useState, memo } from "react";
import { useTranslation } from "react-i18next";

import { Box, makeStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        wrapperScreenSpam: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            fontSize: theme.typography.pxToRem(14),
            background: theme.palette.background.paper,
            borderRadius: theme.shape.radiusLevels[0],
            overflow: "hidden",
            "@media print": {
                display: "flex !important",
            },
            "& hr": {
                background: "transparent",
                borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
            },
            color: theme.palette.primary.main,
        },
        spamScreenHeader: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 600,
            paddingBottom: theme.spacing(4),
            background: theme.palette.primary.main,
            color: theme.palette.neutral.white,

            width: "100%",
            "& > div": {
                width: "70%",
                margin: "0 auto",
            },
            textAlign: "center",
        },
        duplicateTxt: {
            width: "70%",
            margin: "0 auto",
            marginTop: theme.spacing(5),
            color: theme.palette.primary.main,
        },
    };
});
const PaymentReceiverSpamScreen = ({
    progressing,
    deleteRequest,
    userAlreadyBlocked,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [blockUser, setBlockUser] = useState(false);

    return (
        <Box className={classes.wrapperScreenSpam}>
            <Box p={4} pb={2} className={classes.spamScreenHeader}>
                {userAlreadyBlocked && (
                    <div>{t("payment_lb_user_already_blocked")}</div>
                )}
                {!userAlreadyBlocked && (
                    <div>{t("payment_lb_confirm_block_sender")}</div>
                )}
            </Box>
            <Box className={classes.duplicateTxt}>
                <div>{t("payment_content_param1_user_already_blocked")}</div>
                <div>{t("payment_content_param2_user_already_blocked")}</div>
            </Box>
            {!userAlreadyBlocked && (
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={blockUser}
                                onChange={(e) => setBlockUser(e.target.checked)}
                                color="secondary"
                            />
                        }
                        label={
                            <LspTypography variant="heading3">
                                {t("payment_lb_block_person")}
                            </LspTypography>
                        }
                    />
                    {blockUser && (
                        <LspTypography color="error" variant="body2">
                            {t("payment_lb_cannot_be_undone")}
                        </LspTypography>
                    )}
                </Box>
            )}

            <Box mt={4} width="70%" pb={4}>
                <LspButton
                    variant="primary"
                    fullWidth
                    progressing={progressing}
                    onClick={() => deleteRequest(null, blockUser)}
                >
                    {t("lb_continue")}
                </LspButton>
            </Box>
        </Box>
    );
};

export default memo(PaymentReceiverSpamScreen);
