import LspTypography from "@components/LspTypography";
import Interweave from "interweave";
import InvitationAvatar from "../InvitationAvatar";

const InvitationTier = ({ classes, detail }) => {
    return (
        <>
            <div className={classes.tierItem} key={detail?.id}>
                <InvitationAvatar type={detail?.className} />
                <LspTypography
                    color="black"
                    variant="title2"
                    className={classes.titleItem}
                >
                    {detail?.className}
                </LspTypography>
                {detail?.description.map((desc, index) => {
                    return (
                        <LspTypography
                            variant="body2"
                            className={classes.description}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                        >
                            <Interweave content={desc} />
                        </LspTypography>
                    );
                })}
            </div>
        </>
    );
};

export default InvitationTier;
