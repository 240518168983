/* eslint-disable react/no-array-index-key */
import LspTypography from "@components/LspTypography";
import { makeStyles, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(2, 2, 10),
    },
}));
const WealthCreditCardFetching = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.wrapper}>
            <LspTypography variant="title2">
                <Skeleton />
            </LspTypography>
            <LspTypography variant="title2">
                <Skeleton width="40%" />
            </LspTypography>
            {[...new Array(5)]?.map((_, index) => (
                <LspTypography variant="body2" key={index}>
                    <Skeleton />
                </LspTypography>
            ))}
            <LspTypography variant="title2">
                <Skeleton />
            </LspTypography>
            <LspTypography variant="title2">
                <Skeleton width="40%" />
            </LspTypography>
            {[...new Array(7)]?.map((_, index) => (
                <LspTypography variant="body2" key={index}>
                    <Skeleton />
                </LspTypography>
            ))}
        </Paper>
    );
};

export default memo(WealthCreditCardFetching);
