import PaymentDetail from "@containers/SpendAccount/PendingPayment/PaymentDetail";
import { useParams } from "react-router-dom";

const PaymentDetailPage = () => {
    const { txnId } = useParams();

    return <PaymentDetail txnId={txnId} />;
};

export default PaymentDetailPage;
