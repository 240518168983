import LspTypography from "@components/LspTypography";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
    line: {
        background: theme.palette.background.default,
        padding: theme.spacing(1, 2),
    },
}));

const NotificationListFetching = ({ type }) => {
    const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column">
            <Box>
                <LspTypography
                    variant="heading3"
                    color="black"
                    className={classes.line}
                >
                    <Skeleton width="30%" />
                </LspTypography>
            </Box>

            <Box display="flex" flexDirection="column" p={2}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Box>
            <Divider />

            {type !== "loadMore" && (
                <>
                    <Box>
                        <LspTypography
                            variant="heading3"
                            color="black"
                            className={classes.line}
                        >
                            <Skeleton width="30%" />
                        </LspTypography>
                    </Box>

                    <Box display="flex" flexDirection="column" p={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>
                    <Divider />

                    <Box>
                        <LspTypography
                            variant="heading3"
                            color="black"
                            className={classes.line}
                        >
                            <Skeleton width="30%" />
                        </LspTypography>
                    </Box>

                    <Box display="flex" flexDirection="column" p={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default memo(NotificationListFetching);
