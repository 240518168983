/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import {
    Icon,
    makeStyles,
    Paper,
    Box,
    withStyles,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { useMemo, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { connect } from "react-redux";
import TermConditionDialog from "@components/TermCondition/TermConditionDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { GENDER_LIST, REGEX_PATTERN } from "@config/constants";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import LspAddress from "@components/LspAddress";
import LspTextField from "@components/LspTextField";
import { INSTANT_COVERAGE_STEPS } from "@containers/Wealth/wealthConstants";
import { useHistory } from "react-router-dom";

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3.75),
        display: "block",
        "& > ul:first-child": {
            paddingLeft: theme.spacing(1.25),
            " & > li": {
                margin: theme.spacing(1.25, 0),
            },
        },
    },
}))(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.neutral.white,
        fontSize: theme.typography.pxToRem(35),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },

    iconBack: {
        cursor: "pointer",
    },

    list: {
        listStyleType: "none",
        padding: "0",
    },
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    boxTitle: {
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        background: "linear-gradient(99deg, #c86dd7, #3023ae)",
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },

    boxBody: {
        padding: theme.spacing(3.75, 2.5, 3.75, 5.5),
        "& > div": {
            padding: theme.spacing(0, 0, 2.5, 3),
            position: "relative",
            "&::before": {
                position: "absolute",
                content: "''",
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: theme.palette.blue.main,
                left: 0,
                top: 7,
            },
        },
    },

    panelHeader: {
        " & > p:last-child": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8,
        },
        marginBottom: theme.spacing(3.75),
    },

    beneficiaryInfo: {
        display: "flex",
        flexWrap: "wrap",
        " & > div": {
            width: "50%",
            marginBottom: theme.spacing(1.25),
        },
    },
    boxTitleCongra: {
        background: theme.palette.status.success,
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },
    footer: {
        padding: theme.spacing(3, 3.75),
        backgroundColor: theme.palette.grey[100],
    },
    uppercase: {
        textTransform: "uppercase",
    },
}));

const LeftPageReview = ({
    prokeySelected,
    packageDetail,
    formInfo,
    user,
    step,
    setSelectedDistrict,
    setSelectedProvince,
    setAddressName,
    addressName,
    selectedProvince,
    selectedDistrict,
    errors,
    setErrors,
    addressOption,
    setAddressOption,
    stepBack,
    showBtnMobile,
    stepForward,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const goBack = () => {
        if (step.current === INSTANT_COVERAGE_STEPS.CONGRATULATION) {
            history.push("products");
            return;
        }
        stepBack();
    };

    useEffect(() => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                icon: "apply",
                header: t("wealth:sl_consent_title"),
                content: t("wealth:sl_consent_msg"),
                button: t("lb_ok"),
            },
        });
    }, [t]);

    const onChangeDistrict = (district) => {
        setErrors((prev) => ({
            ...prev,
            district: "",
        }));
        setSelectedDistrict(district);
    };

    const onChangeProvince = (province) => {
        setErrors((prev) => ({
            ...prev,
            province: "",
        }));
        setSelectedProvince(province);
    };

    const onChangeAddress = (e) => {
        setErrors((prev) => ({
            ...prev,
            address: "",
        }));
        const { value } = e.target;
        const address = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.ADDRESS,
            text: value,
        });
        setAddressName(address);
        if (!address) {
            setErrors((prev) => ({
                ...prev,
                address: t("msg_we_need_this"),
            }));
        }
    };

    const fullAddress = useMemo(() => {
        const addressList = user.address;
        let strAddress = "";
        if (addressList.addressName) strAddress = addressList.addressName;
        if (addressList.wardName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_ward_prefix") +
                    addressList.wardName;
            }
        }
        if (addressList.distName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_district_prefix") +
                    addressList.distName;
            }
        }
        if (addressList.cityName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress += addressList.cityName;
            }
        }

        return strAddress;
    }, [user, t]);

    const policyTerm = useMemo(() => {
        const content = {};
        content.panelHeader = t("wealth:SI00055", {
            returnObjects: true,
            defaultValue: "",
        });
        content.panelBody = t("wealth:SI00055", {
            returnObjects: true,
            defaultValue: "",
        });
        content.panelFooter = t("wealth:SI00056", {
            returnObjects: true,
            defaultValue: "",
        });
        return content;
    }, [t]);

    const openTermCondition = () => {
        GlobalDialogController.show({
            component: () => (
                <TermConditionDialog
                    header={policyTerm.panelBody[1]}
                    url={policyTerm.panelFooter[1]}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    return (
        <Content>
            <div>
                <Icon
                    onClick={goBack}
                    className={`font-icon icon-longarrowleft ${classes.iconBack}`}
                />
                <div>
                    <div className={classes.list}>
                        <Paper>
                            {step.current !==
                                INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                                <div className={classes.boxTitle}>
                                    <Icon
                                        className={`font-icon icon-reviewpolicy ${classes.icon}`}
                                    />
                                    <div>
                                        <LspTypography
                                            variant="heading1"
                                            color="white"
                                        >
                                            {t("wealth:SI00095")}
                                        </LspTypography>
                                    </div>
                                </div>
                            )}

                            {step.current ===
                                INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                                <div className={classes.boxTitleCongra}>
                                    <Icon
                                        className={`font-icon icon-like ${classes.icon}`}
                                    />
                                    <div>
                                        <LspTypography
                                            variant="heading1"
                                            color="white"
                                        >
                                            {t("wealth:SI00036")}
                                        </LspTypography>
                                    </div>
                                </div>
                            )}

                            <div className={classes.boxBody}>
                                <LspTypography variant="heading4">
                                    {t("sunlife_lb_product_name")}
                                </LspTypography>
                                <LspTypography variant="body1" color="primary">
                                    {t(`wealth:SI00058_${prokeySelected}`)}
                                </LspTypography>
                                <LspTypography variant="heading4">
                                    {t("sunlife_lb_coverage_duration")}
                                </LspTypography>
                                <LspTypography variant="body1" color="primary">
                                    {packageDetail?.effectiveDate}
                                </LspTypography>
                            </div>
                            {step.current !==
                                INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                                <Accordion square expanded>
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <LspTypography
                                            variant="body2"
                                            color="grey"
                                        >
                                            {t("wealth:SI00061")}
                                        </LspTypography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        checked={
                                                            !!addressOption.defaultAddress
                                                        }
                                                        onChange={() =>
                                                            setAddressOption({
                                                                defaultAddress: true,
                                                                newAddress: false,
                                                            })
                                                        }
                                                        color="secondary"
                                                    />
                                                }
                                                label={
                                                    <LspTypography
                                                        variant="heading3"
                                                        color="grey"
                                                    >
                                                        {t("wealth:SI00062")}
                                                    </LspTypography>
                                                }
                                            />
                                            <div>{fullAddress}</div>
                                        </div>

                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        checked={
                                                            !!addressOption.newAddress
                                                        }
                                                        onChange={() =>
                                                            setAddressOption({
                                                                defaultAddress: false,
                                                                newAddress: true,
                                                            })
                                                        }
                                                        color="secondary"
                                                    />
                                                }
                                                label={
                                                    <LspTypography
                                                        variant="heading3"
                                                        color="grey"
                                                    >
                                                        {t("wealth:SI00063")}
                                                    </LspTypography>
                                                }
                                            />
                                            {addressOption.newAddress && (
                                                <div>
                                                    <LspTextField
                                                        autoFocus
                                                        name="addressName"
                                                        label={t(
                                                            "bill_lb_address"
                                                        )}
                                                        error={
                                                            !!errors?.address
                                                        }
                                                        helperText={
                                                            errors?.address ||
                                                            " "
                                                        }
                                                        value={addressName}
                                                        inputProps={{
                                                            maxLength: 35,
                                                        }}
                                                        onChange={
                                                            onChangeAddress
                                                        }
                                                    />
                                                    <LspAddress
                                                        onChangeProvince={
                                                            onChangeProvince
                                                        }
                                                        selectedProvince={
                                                            selectedProvince
                                                        }
                                                        selectedDistrict={
                                                            selectedDistrict
                                                        }
                                                        onChangeDistrict={
                                                            onChangeDistrict
                                                        }
                                                        errors={errors}
                                                        isHideWard
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )}

                            <Accordion square expanded>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <LspTypography variant="body2" color="grey">
                                        {t("wealth:SI00093")}
                                    </LspTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.beneficiaryInfo}>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t(
                                                    "sunlife_placeholder_bene_name"
                                                )}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {formInfo?.beneficiaryName}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("lb_gender")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {formInfo?.genderName ===
                                                GENDER_LIST[0].cfgKey
                                                    ? t(GENDER_LIST[0].cfgValue)
                                                    : t(
                                                          GENDER_LIST[1]
                                                              .cfgValue
                                                      )}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t(
                                                    "sunlife_placeholder_date_birth"
                                                )}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {formInfo?.dateOfBirth}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t(
                                                    "sunlife_placeholder_relationship"
                                                )}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {formInfo?.relationship}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("sunlife_lb_id_passport")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {formInfo?.legalId ||
                                                    t("wealth:SI00090")}
                                            </LspTypography>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion square expanded>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <LspTypography variant="body2" color="grey">
                                        {t("wealth:SI00096")}
                                    </LspTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.beneficiaryInfo}>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("rq_lb_full_name")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.fullName}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("sunlife_lb_id_passport")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.nationalId}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("lb_date_of_birth")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.displayDOB}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("lb_gender")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.gender === 1
                                                    ? t("lb_male")
                                                    : t("lb_female")}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("sunlife_lb_phone")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.phoneNumber}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("lb_hint_email")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {user.userEmail}
                                            </LspTypography>
                                        </div>
                                        <div>
                                            <LspTypography
                                                variant="heading3"
                                                color="grey"
                                                className={classes.uppercase}
                                            >
                                                {t("bill_lb_address")}
                                            </LspTypography>
                                            <LspTypography
                                                variant="body1"
                                                color="grey"
                                            >
                                                {fullAddress}
                                            </LspTypography>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            {step.current !==
                                INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                                <>
                                    {t("wealth:SI00049", {
                                        returnObjects: true,
                                        defaultValue: "",
                                    }).map((item, key) => {
                                        return (
                                            <Accordion
                                                square
                                                expanded
                                                key={key}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header"
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                >
                                                    <LspTypography
                                                        variant="body2"
                                                        color="grey"
                                                    >
                                                        {item[0]}
                                                    </LspTypography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <ul>
                                                        {item.map(
                                                            (value, k) => (
                                                                <li key={k}>
                                                                    <Interweave
                                                                        content={
                                                                            value
                                                                        }
                                                                    />
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}

                                    <Accordion square expanded>
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <LspTypography
                                                variant="body2"
                                                color="grey"
                                            >
                                                {policyTerm.panelHeader[0]}
                                            </LspTypography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <LspTypography
                                                variant="hyperlink2"
                                                component="a"
                                                color="grey"
                                                onClick={openTermCondition}
                                            >
                                                {policyTerm.panelFooter[0]}
                                            </LspTypography>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )}

                            {step.current ===
                                INSTANT_COVERAGE_STEPS.CONGRATULATION && (
                                <div className={classes.footer}>
                                    <LspTypography variant="body1" color="grey">
                                        {t("wealth:SI00067")}
                                    </LspTypography>
                                </div>
                            )}
                        </Paper>
                    </div>
                </div>
                {showBtnMobile && (
                    <Box mt={3}>
                        <LspButton
                            type="submit"
                            fullWidth
                            onClick={() =>
                                stepForward(
                                    INSTANT_COVERAGE_STEPS.REVIEW_POLICY
                                )
                            }
                        >
                            {t("lb_next")}
                        </LspButton>
                    </Box>
                )}
            </div>
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
});

export default connect(mapState, null)(LeftPageReview);
