import { ButtonBase, makeStyles, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { grey, purple } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: theme.spacing(35),
        maxWidth: "40%",
        minWidth: 232,
        background: theme.palette.background.paper,
        padding: theme.spacing(4),
        borderTopRightRadius: theme.shape.radiusLevels[0],
        borderBottomRightRadius: theme.shape.radiusLevels[0],
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up("sm")]: {
            marginBottom: 0,
        },
    },
    circleContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: theme.spacing(2),
        "& div": {
            borderRadius: "50%",
            width: 15,
            height: 15,
            background: purple[200],
        },
        "& div.circleActive": {
            background: theme.palette.primary.main,
        },
    },
    numberKeyWrapper: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        color: theme.palette.primary.main,
    },
    numberKey: {
        width: 48,
        height: 48,
        background: theme.palette.background.paper,
        margin: theme.spacing(0.5),
        borderRadius: theme.shape.radiusLevels[0],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(24),
        boxShadow: theme.shadows[2],
    },
    doubleBtn: {
        width: "104px !important",
        fontSize: theme.typography.pxToRem(16),
        "& span": {
            marginRight: theme.spacing(1),
        },
    },
    loadingItem: {
        margin: theme.spacing(0.5),
        borderRadius: theme.shape.radiusLevels[0],
    },
    closeIcon: {
        fontSize: theme.typography.pxToRem(20),
        color: grey[400],
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        cursor: "pointer",
    },
}));

const circleNodes = ["1", "2", "3", "4"];
const numberKeys = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0"];

const QuickCodeKeyBoard = ({ nextHandler, loading, onClose, isCloseIcon }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [keyboardString, setKeyboardString] = useState("");
    // loading = true;

    useEffect(() => {
        if (keyboardString && keyboardString.length === 4) {
            nextHandler(keyboardString);
            setKeyboardString("");
        }
    }, [keyboardString, nextHandler]);

    const numberClick = (key) => {
        if (key === "del") {
            if (!keyboardString || keyboardString.length === 0) return;
            const keyboardStringAfterDel = keyboardString.substring(
                0,
                keyboardString.length - 1
            );
            setKeyboardString(keyboardStringAfterDel);
        } else {
            setKeyboardString(keyboardString ? keyboardString + key : key);
        }
    };

    const keyboardView = (node) => {
        const parsedNode = +node;
        return (
            <div
                className={`${
                    keyboardString && keyboardString.length >= parsedNode
                        ? "circleActive"
                        : ""
                }`}
                key={node}
            />
        );
    };

    return (
        <>
            <div className={classes.wrapper}>
                {isCloseIcon && (
                    <Icon
                        className={`font-icon icon-close ${classes.closeIcon}`}
                        onClick={onClose}
                    />
                )}
                <div className={classes.circleContainer}>
                    {circleNodes.map((node) => keyboardView(node))}
                </div>
                <div className={classes.numberKeyWrapper}>
                    {loading && (
                        <>
                            {" "}
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={48}
                                height={48}
                            />
                            <Skeleton
                                classes={{ root: classes.loadingItem }}
                                variant="rect"
                                width={104}
                                height={48}
                            />
                        </>
                    )}
                    {!loading &&
                        numberKeys.map((key) => (
                            <ButtonBase
                                className={classes.numberKey}
                                onClick={() => numberClick(key)}
                                key={key}
                            >
                                {key}
                            </ButtonBase>
                        ))}
                    {!loading && (
                        <ButtonBase
                            className={`${classes.doubleBtn} ${classes.numberKey}`}
                            onClick={() => numberClick("del")}
                        >
                            <span>{t("del")}</span>
                            <Icon className="font-icon icon-del" />
                        </ButtonBase>
                    )}
                </div>
            </div>
        </>
    );
};

export default QuickCodeKeyBoard;
