import Content from "@components/Content";
import LspButton from "@components/LspButton";
import TermCondition from "@components/TermCondition";
import PageHeaderBack from "@components/PageHeaderBack";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import { Box, makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => {
    return {
        contentIntro: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            padding: theme.spacing(2.5),
            textAlign: "center",
            borderRadius: theme.shape.radiusLevels[1],
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        introBtn: {
            margin: theme.spacing(3, 0),
        },
        contentItem: {
            marginTop: theme.spacing(1.25),
        },
    };
});

const VinaWealthTermCondition = ({ productDetail, onBack, onNext }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Content size="xs">
            <PageHeaderBack onBack={onBack}>
                {t("wealth:vc3_tc_title")}
            </PageHeaderBack>
            <Box>
                <Box className={classes.contentIntro}>
                    <Box>
                        <TermCondition
                            content={t(
                                `wealth:vc3_tc_detail_${productDetail.productKey}`
                            )}
                            keyList={t(
                                `wealth:vc3_tc_link_${productDetail.productKey}`,
                                {
                                    returnObjects: true,
                                }
                            )}
                            type="NEW_TAB"
                        />
                    </Box>
                </Box>
                <Box className={classes.introBtn}>
                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={() => onNext(VINA_WEALTH_STEPS.REVIEW_INFO)}
                    >
                        {t("wealth:vc3_agree_btn")}
                    </LspButton>
                </Box>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    productDetail: state.vinaWealth.productDetail,
});

export default connect(mapState, null)(VinaWealthTermCondition);
