import LspTypography from "@components/LspTypography";
import { Icon } from "@material-ui/core";

import useNumber from "@helpers/useNumber";

const SpendItem = ({ item }) => {
    const { formatNumber } = useNumber();

    return (
        <>
            <Icon className="font-icon icon-spendaccounticon" />
            <LspTypography variant="heading2" color="white">
                {formatNumber(item?.accountAmount)}
            </LspTypography>
            <LspTypography variant="body2" color="white">
                {item?.accountName}
            </LspTypography>
        </>
    );
};

export default SpendItem;
