import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTranslation } from "react-i18next";
import { CircularProgress, Divider, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    group: {
        boxShadow: theme.shadows[1],
        height: theme.spacing(4),
        padding: theme.spacing(1),
        position: "relative",
        background: theme.palette.background.paper,
    },
    root: {
        padding: theme.spacing(0.5, 1),
        border: "none",
        minWidth: 50,
        "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0)",
            fontWeight: 600,
            color: theme.palette.success.main,
            "&.red": {
                color: theme.palette.error.main,
            },
            "&:hover": {
                backgroundColor: theme.palette.background.paper,
            },
        },
    },
    loading: {
        position: "absolute",
        top: "calc(50% - 9px)",
        left: "calc(50% - 9px)",
    },
}));

const Toggle = ({ props, status, onChangeHandler, loading, disabled }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleAlignment = (event, newStatus) => {
        onChangeHandler(newStatus);
    };

    return (
        <>
            <ToggleButtonGroup
                value={status}
                exclusive
                onChange={handleAlignment}
                className={classes.group}
            >
                {loading && (
                    <CircularProgress size={18} className={classes.loading} />
                )}
                <ToggleButton
                    value={false}
                    className={clsx(classes.root, {
                        red: !status,
                    })}
                    {...props}
                    disabled={loading || disabled}
                >
                    {t("lb_toggle_off")}
                </ToggleButton>
                <Divider
                    variant="middle"
                    orientation="vertical"
                    style={{ marginRight: 0 }}
                />
                <ToggleButton
                    value
                    className={classes.root}
                    {...props}
                    disabled={loading || disabled}
                >
                    {t("lb_toggle_on")}
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
};

export default Toggle;
