import api from "@config/api";

const checkTokenIsValid = async (payload) => {
    return await api.post("/v2/user/registration/verify", payload);
};

const requestCreateAuthentication = async (payload) => {
    return await api.post("/v2/user/registration/activate", payload);
};

const verifyMailMobileJoin = async (payload) => {
    return await api.post("/v3/user/join/email/verify", payload);
};

const activationService = {
    checkTokenIsValid,
    requestCreateAuthentication,
    verifyMailMobileJoin,
};

export default activationService;
