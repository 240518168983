import DoubleButtons from "@components/DoubleButtons";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    line: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: `1px solid ${theme.palette.magicalGrey[200]}`,
        borderBottom: `1px solid ${theme.palette.magicalGrey[200]}`,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    cell: {
        width: "50%",
        "&:first-child": {
            borderRight: `1px solid ${theme.palette.magicalGrey[200]}`,
        },
    },
}));

const WarningExceedOutStandingBalance = ({
    onCancel,
    onYes,
    amount,
    outStandingBalance,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const classes = useStyles();

    return (
        <Paper>
            <LspDialogTitle>
                <div>
                    <Icon className="font-icon icon-balance" />
                </div>
                <div>
                    {t("master_card_enter_amount_greater_outstanding_balance")}
                </div>
            </LspDialogTitle>
            <LspDialogContent>
                <Box className={classes.line}>
                    <Box className={classes.cell}>
                        <LspTypography
                            color="black"
                            uppercase
                            variant="heading4"
                        >
                            {t("mastercard_outstanding_balance")}
                        </LspTypography>
                        <LspTypography color="purple" variant="subheading1">
                            {outStandingBalance > 0
                                ? formatNumber(outStandingBalance)
                                : outStandingBalance}
                        </LspTypography>
                    </Box>
                    <Box className={classes.cell}>
                        <LspTypography
                            color="black"
                            uppercase
                            variant="heading4"
                        >
                            {t("master_card_txt_entered_amount")}
                        </LspTypography>
                        <LspTypography color="purple" variant="subheading1">
                            {formatNumber(amount)}
                        </LspTypography>
                    </Box>
                </Box>
                <LspTypography color="grey">
                    {t("master_card_txt_sure_proceed")}
                </LspTypography>
            </LspDialogContent>
            <LspDialogActions>
                <DoubleButtons
                    primaryButton={{
                        label: t("lb_yes"),
                        onClick: onYes,
                    }}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: onCancel,
                    }}
                />
            </LspDialogActions>
        </Paper>
    );
};

export default memo(WarningExceedOutStandingBalance);
