import { Box, Fade, Icon, makeStyles } from "@material-ui/core";
import { useEffect, useState, isValidElement } from "react";
import PropTypes from "prop-types";

import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(3),
        marginTop: 0,
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    cardHeader: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(2, 3),
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.magicalGrey[100]}`,
        [theme.breakpoints.up("sm")]: {
            transition: theme.transitions.create("background-color", {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    noBreakLine: {
        borderBottom: "none",
    },
    cardHeaderIcon: {
        marginRight: theme.spacing(1.5),
        display: "flex",
        "& .MuiIcon-root, .MuiSvgIcon-root": {
            fontSize: theme.typography.pxToRem(24),
        },
        color: theme.palette.primary.main,
        "&:last-child": {
            marginRight: 0,
        },
    },
    cardHeaderText: {
        flex: 1,
        textAlign: "left",
    },
    cardHeaderAction: {
        cursor: "pointer",
        color: theme.palette.magicalGrey[300],
        fontSize: theme.typography.pxToRem(16),
        "& .MuiIcon-root, .MuiSvgIcon-root": {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    cardHeaderActionIcon: {
        fontSize: theme.typography.pxToRem(16),
    },
    cardItem: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 3),
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
    },
    defaultTitleColor: {
        color: theme.palette.black.main,
    },
    grey: {
        backgroundColor: theme.palette.magicalGrey[200],
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    purple: {
        backgroundColor: theme.palette.purple.main,
    },
    blue: {
        backgroundColor: theme.palette.blue.main,
    },
    orange: {
        backgroundColor: theme.palette.orange.main,
    },
    gold: {
        backgroundColor: theme.palette.gold.main,
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
}));
const LspCard = ({
    children,
    title,
    headerIcon,
    enableCollapse,
    defaultOpenCollapse,
    noBreakLine,
    defaultTitleColor,
    fetching,
    color,
}) => {
    const classes = useStyles();
    const [isCollapsible, setCollapsible] = useState(false);

    const handleCollapsible = () => {
        setCollapsible((pre) => !pre);
    };

    useEffect(() => {
        if (defaultOpenCollapse || !enableCollapse) {
            setCollapsible(true);
        }
    }, [defaultOpenCollapse, enableCollapse]);

    const fetchingSkeleton = () => {
        return (
            <>
                <div className={classes.cardHeader}>
                    <Skeleton variant="rect" width="100%" />
                </div>
                <div>
                    <Skeleton variant="rect" width="100%" height={150} />
                </div>
            </>
        );
    };

    return (
        <>
            <Box mt={3} className={classes.cardContent}>
                {fetching && fetchingSkeleton()}
                {!fetching && (
                    <>
                        <div
                            className={clsx(
                                classes[color],
                                classes.cardHeader,
                                noBreakLine ? classes.noBreakLine : ""
                            )}
                        >
                            {headerIcon && (
                                <div className={classes.cardHeaderIcon}>
                                    {headerIcon}
                                </div>
                            )}
                            <div className={classes.cardHeaderText}>
                                <LspTypography
                                    variant="heading2"
                                    color={
                                        defaultTitleColor ? "black" : "primary"
                                    }
                                >
                                    {title}
                                </LspTypography>
                            </div>
                            {enableCollapse && (
                                <div
                                    className={classes.cardHeaderAction}
                                    onClick={handleCollapsible}
                                >
                                    {!isCollapsible && (
                                        <Icon
                                            className={`font-icon icon-down-open-big ${classes.cardHeaderActionIcon}`}
                                        />
                                    )}
                                    {isCollapsible && (
                                        <Icon
                                            className={`font-icon icon-up-open-big ${classes.cardHeaderActionIcon}`}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <Fade in={isCollapsible} unmountOnExit>
                            <div className={classes.cardItem}>{children}</div>
                        </Fade>
                    </>
                )}
            </Box>
        </>
    );
};
LspCard.propTypes = {
    enableCollapse: PropTypes.bool,
    defaultOpenCollapse: PropTypes.bool,
    noBreakLine: PropTypes.bool,
    fetching: PropTypes.bool,
    color: PropTypes.string,
};

LspCard.defaultProps = {
    enableCollapse: false,
    defaultOpenCollapse: false,
    noBreakLine: false,
    fetching: false,
    color: "",
};

export default LspCard;
