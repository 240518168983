/* ------------- Types and Action Definition ------------- */
export const TERM_DEPOSIT = Object.freeze({
    GetTermDepositRates: "TERM_DEPOSIT/GetTermDepositRates",
    GetTermDepositRatesSuccess: "TERM_DEPOSIT/GetTermDepositRatesSuccess",
    GetTermDepositRatesErrors: "TERM_DEPOSIT/GetTermDepositRatesErrors",

    // GET TD DETAIL
    GetTermDepositDetail: "TERM_DEPOSIT/GetTermDepositDetail",
    GetTermDepositDetailSuccess: "TERM_DEPOSIT/GetTermDepositDetailSuccess",
    GetTermDepositDetailErrors: "TERM_DEPOSIT/GetTermDepositDetailErrors",

    // validate special rate
    ValidateSpecialRateRequest: "TERM_DEPOSIT/ValidateSpecialRateRequest",
    ValidateSpecialRateSuccess: "TERM_DEPOSIT/ValidateSpecialRateSuccess",
    ValidateSpecialRateError: "TERM_DEPOSIT/ValidateSpecialRateError",
    ClearValidateSpecialRateError: "TERM_DEPOSIT/ClearValidateSpecialRateError",

    // preview partial rate
    PreviewSpecialRateRequest: "TERM_DEPOSIT/PreviewSpecialRateRequest",
    PreviewSpecialRateSuccess: "TERM_DEPOSIT/PreviewSpecialRateSuccess",
    PreviewSpecialRateError: "TERM_DEPOSIT/PreviewSpecialRateError",

    // partial td
    PartialTDRequest: "TERM_DEPOSIT/PartialTDRequest",
    PartialTDSuccess: "TERM_DEPOSIT/PartialTDSuccess",
    PartialTDErrors: "TERM_DEPOSIT/PartialTDErrors",

    // early redeem td
    EarlyRedeemTDRequest: "TERM_DEPOSIT/EarlyRedeemTDRequest",
    EarlyRedeemTDSuccess: "TERM_DEPOSIT/EarlyRedeemTDSuccess",
    EarlyRedeemTDErrors: "TERM_DEPOSIT/EarlyRedeemTDErrors",

    // check auto deposit
    CheckAutoDeposit: "TERM_DEPOSIT/CheckAutoDeposit",
    CheckAutoDepositSuccess: "TERM_DEPOSIT/CheckAutoDepositSuccess",
    CheckAutoDepositError: "TERM_DEPOSIT/CheckAutoDepositError",

    // auto deposit
    GetAutoDepositList: "TERM_DEPOSIT/GetAutoDepositList",
    GetAutoDepositListSuccess: "TERM_DEPOSIT/GetAutoDepositListSuccess",
    GetAutoDepositListError: "TERM_DEPOSIT/GetAutoDepositListError",
});
/* ------------- Actions Definition ------------- */

const getTermDepositRates = () => {
    return {
        type: TERM_DEPOSIT.GetTermDepositRates,
    };
};

const getTermDepositRatesSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.GetTermDepositRatesSuccess,
        payload,
    };
};

const getTermDepositRatesErrors = () => {
    return {
        type: TERM_DEPOSIT.GetTermDepositRatesErrors,
    };
};

const getTermDepositDetail = (payload) => {
    return {
        type: TERM_DEPOSIT.GetTermDepositDetail,
        payload,
    };
};

const getTermDepositDetailSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.GetTermDepositDetailSuccess,
        payload,
    };
};

const getTermDepositDetailErrors = () => {
    return {
        type: TERM_DEPOSIT.GetTermDepositDetailErrors,
    };
};

// validation special rate

const validateSpecialRateRequest = (payload) => {
    return {
        type: TERM_DEPOSIT.ValidateSpecialRateRequest,
        payload,
    };
};

const validateSpecialRateSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.ValidateSpecialRateSuccess,
        payload,
    };
};

const validateSpecialRateError = () => {
    return {
        type: TERM_DEPOSIT.ValidateSpecialRateError,
    };
};

const clearValidateRate = () => {
    return {
        type: TERM_DEPOSIT.ClearValidateSpecialRateError,
    };
};

// preview partial td

const previewPartialTd = (payload) => {
    return {
        type: TERM_DEPOSIT.PreviewSpecialRateRequest,
        payload,
    };
};
const checkAutoDeposit = () => {
    return {
        type: TERM_DEPOSIT.CheckAutoDeposit,
    };
};

const checkAutoDepositSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.CheckAutoDepositSuccess,
        payload,
    };
};

const previewPartialTdSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.PreviewSpecialRateSuccess,
        payload,
    };
};

const previewPartialTdError = () => {
    return {
        type: TERM_DEPOSIT.PreviewSpecialRateError,
    };
};

//  partial td
const partialTDRequest = (payload) => {
    return {
        type: TERM_DEPOSIT.PartialTDRequest,
        payload,
    };
};

const partialTDSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.PartialTDSuccess,
        payload,
    };
};

const partialTDErrors = () => {
    return {
        type: TERM_DEPOSIT.PartialTDErrors,
    };
};

//  early redeem td
const earlyRedeemTDRequest = (payload) => {
    return {
        type: TERM_DEPOSIT.EarlyRedeemTDRequest,
        payload,
    };
};

const earlyRedeemTDSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.EarlyRedeemTDSuccess,
        payload,
    };
};

const checkAutoDepositError = () => {
    return {
        type: TERM_DEPOSIT.CheckAutoDepositError,
    };
};

const getAutoDepositList = () => {
    return {
        type: TERM_DEPOSIT.GetAutoDepositList,
    };
};

const getAutoDepositListSuccess = (payload) => {
    return {
        type: TERM_DEPOSIT.GetAutoDepositListSuccess,
        payload,
    };
};

const earlyRedeemTDErrors = () => {
    return {
        type: TERM_DEPOSIT.EarlyRedeemTDErrors,
    };
};

const getAutoDepositListError = () => {
    return {
        type: TERM_DEPOSIT.GetAutoDepositListError,
    };
};

const termDepositAction = {
    getTermDepositRates,
    getTermDepositRatesSuccess,
    getTermDepositRatesErrors,

    // td detail
    getTermDepositDetail,
    getTermDepositDetailSuccess,
    getTermDepositDetailErrors,

    // validate special
    validateSpecialRateRequest,
    validateSpecialRateSuccess,
    validateSpecialRateError,

    clearValidateRate,

    previewPartialTd,
    previewPartialTdSuccess,
    previewPartialTdError,

    partialTDRequest,
    partialTDSuccess,
    partialTDErrors,

    earlyRedeemTDRequest,
    earlyRedeemTDSuccess,
    earlyRedeemTDErrors,

    // check auto deposit
    checkAutoDeposit,
    checkAutoDepositSuccess,
    checkAutoDepositError,

    getAutoDepositList,
    getAutoDepositListSuccess,
    getAutoDepositListError,
};

export default termDepositAction;
