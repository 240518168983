import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
    Dialog,
    makeStyles,
    Typography,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grow,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import RefreshIcon from "@material-ui/icons/Refresh";

import OTPController, {
    OTPDialogType,
} from "@helpers/controllers/OTPController";
import otpDialogAction from "@redux/actions/otp-dialog";
import authAction from "@redux/actions/auth";
import authService from "@services/auth";
import moveMoneyAction from "@redux/actions/move-money";
import moveMoneyService from "@services/move-money";
import CardManagementActions from "@redux/actions/card-management";
import topUpAction from "@redux/actions/top-up";
import { isFunction } from "lodash";
import ForgotPasswordActions from "@redux/actions/forgor-password";
import userAction from "@redux/actions/user";
import Interweave from "interweave";
import DoubleButtons from "./DoubleButtons";
import LspTextField from "./LspTextField";
import { CARD_TYPES } from "../containers/CardManagement/constant";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "transparent",
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(4),
        color: theme.palette.primary.contrastText,
        textAlign: "center",
    },
    title: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: "600",
        fontFamily: "Open Sans",
    },
    subtitle: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "Open Sans",
        fontStyle: "italic",
    },
    body: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        "&:first-child": {
            paddingTop: theme.spacing(4),
        },
    },
    legend: {
        color: grey[700],
    },
    resendBtn: {
        whiteSpace: "nowrap",
    },
    actions: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        paddingTop: theme.spacing(2),
        display: "block",
    },
    form: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const OTPDialog = ({
    open,
    otpError,
    close,
    opened,
    refNo,
    submitFunc,
    resendFunc,
    loading,
    dialogTitle,
    dialogSubtitle,
    dialogDescription,
    dialogHideResendButton,
    type,
    authRefNo,
    moveMoneyRefNo,
    forgotPasswordRefNo,
    user,
    // onClose,
    submitDeviceAuthenticationOTP,
    submitTwoFactorOTP,
    submitMoveMoneyOTP,
    authFetching,
    moveMoneyFetching,
    resetAuth,
    resetMoveMoney,
    cancelOTPCardManagement,
    submitUpdateCardStatusOTP,
    submitResetPinOTP,
    submitReIssueCardOTP,
    cardRefNo,
    cardId,
    cardAction,
    cardType,
    otpCardFetching,
    submitCardActivationOTP,
    topUpFetching,
    resetTopUp,
    topUpRefNo,
    otpCommitTopUp,
    resetMoveMoneyByPhone,
    commitOtpTransferByPhone,
    otpCommitCloseInstallmentEarly,
    submitForgotPasswordOTP,
    forgotPasswordPhoneNumber,
    version,
    submitMaskOTP,
    otpMaskFetching,
    cancelMaskCardRequest,
    createAliasOTP,
    aliasRefNo,
    featureState,
    dialogOnCancel,
    submitGGTwoFactorOTPRequest,
    clearLoginTextField,
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const otpDefaultValue = window.isLocalhost ? "5555" : "";

    const { register, handleSubmit, errors, reset } = useForm({
        defaultValues: { otp: otpDefaultValue },
    });
    const resendEnabledTimer = useRef(null);
    const [resendEnabled, setResendEnabled] = useState(false);

    const [content, setContent] = useState({});

    // useEffect(() => {
    //     const message = otpError ? t([otpError]) : "";
    //     console.log("message: ", message);
    //     setError("otp", {
    //         type: "manual",
    //         message,
    //     });
    // }, [otpError, setError, t]);

    useEffect(() => {
        OTPController.setOpen(open);
    }, [open]);

    useEffect(() => {
        OTPController.setClose(close);
    }, [close]);

    // reset the form to init state when dialog open
    useEffect(() => {
        if (opened) {
            reset();
        }
    }, [opened, reset]);

    const submitting = useMemo(() => {
        switch (type) {
            case OTPDialogType.DeviceAuthorization:
            case OTPDialogType.GGTwoFactor:
            case OTPDialogType.TwoFactor:
                return authFetching;
            case OTPDialogType.UpdateCardStatus:
            case OTPDialogType.ResetPin:
            case OTPDialogType.ReIssueCard:
            case OTPDialogType.CardActivation:
                return otpCardFetching;
            case OTPDialogType.MaskRequest:
                return otpMaskFetching;
            case OTPDialogType.TopUp:
                return topUpFetching;
            case OTPDialogType.MoveMoney:
            case OTPDialogType.MoveMoneyByPhone:
            case OTPDialogType.CloseInstallmentEarly:
                return moveMoneyFetching;
            default:
                return loading;
        }
    }, [
        type,
        authFetching,
        otpMaskFetching,
        topUpFetching,
        moveMoneyFetching,
        loading,
        otpCardFetching,
    ]);

    useEffect(() => {
        if (!opened) {
            return;
        }
        switch (type) {
            case OTPDialogType.GGTwoFactor:
                setContent({
                    title: (
                        <Interweave
                            content={t("gg_authen:gg_authen_enter_code_title")}
                        />
                    ),
                    subtitle: t("gg_authen:gg_authen_enter_code_desc"),
                    description: t("gg_authen:gg_authen_enter_code_note"),
                    dataTestId: "device-gg-two-factor",
                });
                break;
            case OTPDialogType.DeviceAuthorization:
                setContent({
                    title: t("dv_lb_authorize_device"),
                    subtitle: t("otp_popup_email_description"),
                    description: `${t("lg_lb_new_device_subtitle_1")} ${t(
                        "lg_lb_new_device_subtitle_2"
                    )}`,
                    dataTestId: "device-authorization",
                });
                break;
            case OTPDialogType.TwoFactor:
                setContent({
                    title: t("otp_lb_2factor_title"),
                    subtitle: t("otp_popup_sms_description"),
                    description: t("two_factor_popup_sms_sent_sentence"),
                    dataTestId: "two-factor-input",
                });
                break;
            case OTPDialogType.ForgotPassword:
                setContent({
                    title: t("otp_popup_title"),
                    subtitle: t("otp_popup_sms_description"),
                    description: `${t(
                        "otp_popup_sms_sent_sentence"
                    )} ${forgotPasswordPhoneNumber}`,
                });
                break;
            case OTPDialogType.MoveMoney:
            case OTPDialogType.UpdateCardStatus:
            case OTPDialogType.ResetPin:
            case OTPDialogType.ReIssueCard:
            case OTPDialogType.CardActivation:
            case OTPDialogType.MoveMoneyByPhone:
            case OTPDialogType.CloseInstallmentEarly:
            case OTPDialogType.TopUp:
            case OTPDialogType.CreateAlias:
            default:
                setContent({
                    title: dialogTitle ?? t("otp_popup_title"),
                    subtitle: dialogSubtitle ?? t("otp_popup_sms_description"),
                    description:
                        dialogDescription ??
                        `${t(
                            "otp_popup_sms_sent_sentence"
                        )} ******${user?.phoneNumber.slice(6)}`,
                });
                break;
        }
    }, [
        opened,
        type,
        t,
        user,
        dialogTitle,
        dialogSubtitle,
        dialogDescription,
        forgotPasswordPhoneNumber,
    ]);

    useEffect(() => {
        if (resendEnabled || !opened) {
            return;
        }
        clearTimeout(resendEnabledTimer.current);
        resendEnabledTimer.current = setTimeout(() => {
            setResendEnabled(true);
        }, 30000);
    }, [opened, resendEnabled]);

    useEffect(() => {
        if (!opened) {
            clearTimeout(resendEnabledTimer.current);
            setResendEnabled(false);
        }
    }, [opened]);

    const handleClose = useCallback(() => {
        // if (onClose) {
        //     onClose();
        // }
        if (dialogOnCancel && isFunction(dialogOnCancel)) {
            dialogOnCancel();
        }
        switch (type) {
            case OTPDialogType.DeviceAuthorization:
                resetAuth();
                clearLoginTextField(true);
                break;
            case OTPDialogType.TwoFactor:
                resetAuth();
                break;
            case OTPDialogType.MoveMoney:
            case OTPDialogType.CloseInstallmentEarly:
                resetMoveMoney();
                break;
            case OTPDialogType.UpdateCardStatus:
            case OTPDialogType.ResetPin:
            case OTPDialogType.ReIssueCard:
            case OTPDialogType.CardActivation:
                cancelOTPCardManagement();
                break;
            case OTPDialogType.TopUp:
                resetTopUp();
                break;
            case OTPDialogType.MoveMoneyByPhone:
                resetMoveMoneyByPhone("CANCEL_OTP");
                break;
            case OTPDialogType.MaskRequest:
                cancelMaskCardRequest();
                break;
            default:
                break;
        }
        // close();
        OTPController.hide();
    }, [
        cancelOTPCardManagement,
        resetAuth,
        resetMoveMoney,
        resetMoveMoneyByPhone,
        resetTopUp,
        type,
        cancelMaskCardRequest,
        dialogOnCancel,
    ]);

    const submit = useCallback(
        (data) => {
            const { otp } = data;

            switch (type) {
                case OTPDialogType.GGTwoFactor:
                    submitGGTwoFactorOTPRequest({
                        lang: i18n.language,
                        refNo: authRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        smsOTP: otp,
                    });
                    break;
                case OTPDialogType.DeviceAuthorization:
                    submitDeviceAuthenticationOTP({
                        lang: i18n.language,
                        refNo: authRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.TwoFactor:
                    submitTwoFactorOTP({
                        lang: i18n.language,
                        refNo: authRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        smsOTP: otp,
                    });
                    break;
                case OTPDialogType.MoveMoney:
                    submitMoveMoneyOTP({
                        refNo: moveMoneyRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.UpdateCardStatus:
                    submitUpdateCardStatusOTP({
                        refNo: cardRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        cardId,
                        cardAction,
                        cardType,
                    });
                    break;
                case OTPDialogType.ResetPin:
                    submitResetPinOTP({
                        refNo: cardRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        cardId,
                    });
                    break;
                case OTPDialogType.ReIssueCard:
                    submitReIssueCardOTP({
                        refNo: cardRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        cardId,
                        version,
                    });
                    break;
                case OTPDialogType.CardActivation:
                    submitCardActivationOTP({
                        refNo: cardRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        CC: !(cardType === CARD_TYPES.DEBIT_CARD),
                        enableImmediatelyUseCCW4:
                            featureState?.enableImmediatelyUseCCW4,
                    });
                    break;
                case OTPDialogType.TopUp:
                    otpCommitTopUp({
                        refNo: topUpRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.MoveMoneyByPhone:
                    commitOtpTransferByPhone({
                        refNo: moveMoneyRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.CloseInstallmentEarly:
                    otpCommitCloseInstallmentEarly({
                        refNo: moveMoneyRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.ForgotPassword:
                    submitForgotPasswordOTP({
                        refNo: forgotPasswordRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                case OTPDialogType.MaskRequest:
                    submitMaskOTP({
                        refNo: cardRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                        cardId,
                    });
                    break;
                case OTPDialogType.CreateAlias:
                    createAliasOTP({
                        refNo: aliasRefNo,
                        otp,
                        securityChallenge: otp,
                        securityCode: otp,
                    });
                    break;
                default:
                    if (isFunction(submitFunc)) {
                        submitFunc({
                            refNo,
                            otp,
                            securityChallenge: otp,
                            securityCode: otp,
                        });
                    }
                    break;
            }
        },
        [
            type,
            submitGGTwoFactorOTPRequest,
            i18n.language,
            authRefNo,
            submitDeviceAuthenticationOTP,
            submitTwoFactorOTP,
            submitMoveMoneyOTP,
            moveMoneyRefNo,
            submitUpdateCardStatusOTP,
            cardRefNo,
            cardId,
            cardAction,
            cardType,
            submitResetPinOTP,
            submitReIssueCardOTP,
            version,
            submitCardActivationOTP,
            featureState?.enableImmediatelyUseCCW4,
            otpCommitTopUp,
            topUpRefNo,
            commitOtpTransferByPhone,
            otpCommitCloseInstallmentEarly,
            submitForgotPasswordOTP,
            forgotPasswordRefNo,
            submitMaskOTP,
            createAliasOTP,
            aliasRefNo,
            submitFunc,
            refNo,
        ]
    );

    const defaultResend = useCallback((otpRefNo) => {
        moveMoneyService.resendOTP({
            refNo: otpRefNo,
            notification: "sms",
        });
    }, []);

    const resend = useCallback(() => {
        if (!resendEnabled) {
            return;
        }
        setResendEnabled(false);
        switch (type) {
            case OTPDialogType.DeviceAuthorization:
                authService.resendDeviceAuthenticationOTP({
                    refNo: authRefNo,
                    notification: "email",
                });
                break;
            case OTPDialogType.TwoFactor:
                authService.resendTwoFactorOTP({
                    refNo: authRefNo,
                    notification: "sms",
                });
                break;
            case OTPDialogType.MoveMoney:
            case OTPDialogType.MoveMoneyByPhone:
            case OTPDialogType.CloseInstallmentEarly:
                defaultResend(moveMoneyRefNo);
                break;
            case OTPDialogType.UpdateCardStatus:
            case OTPDialogType.ResetPin:
            case OTPDialogType.ReIssueCard:
            case OTPDialogType.CardActivation:
                defaultResend(cardRefNo);
                break;
            case OTPDialogType.TopUp:
                defaultResend(topUpRefNo);
                break;
            default:
                if (isFunction(resendFunc)) {
                    resendFunc({
                        refNo,
                    });
                }
                break;
        }
    }, [
        authRefNo,
        cardRefNo,
        defaultResend,
        moveMoneyRefNo,
        refNo,
        resendEnabled,
        resendFunc,
        topUpRefNo,
        type,
    ]);

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={opened}
            onClose={handleClose}
            scroll="body"
            disableBackdropClick
            PaperProps={{ className: classes.paper }}
            TransitionComponent={Grow}
            id="lspDialog"
        >
            <DialogTitle className={classes.header}>
                <SmartphoneIcon fontSize="large" />
                <div className={classes.title}>{content.title}</div>
                <div className={classes.subtitle}>{content.subtitle}</div>
            </DialogTitle>
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
                <DialogContent classes={{ root: classes.body }}>
                    <LspTextField
                        name="otp"
                        label={t("spend_lb_enter_otp")}
                        autoFocus
                        error={!!errors.otp?.message || !!otpError}
                        helperText={errors.otp?.message || t(otpError) || " "}
                        inputProps={{
                            ref: register({
                                required: t("msg_we_need_this"),
                                // minLength: {
                                //     value: 4,
                                //     message: t('sc_ms_input_otp_wrong_one_time'),
                                // },
                            }),
                            datatestid: content?.dataTestId,
                        }}
                    />
                    <Box display="flex" alignItems="center" paddingTop={0.5}>
                        <Box flexGrow={1}>
                            <Typography
                                variant="body2"
                                className={classes.legend}
                            >
                                {content.description}
                            </Typography>
                        </Box>
                        <Box>
                            {!dialogHideResendButton && (
                                <Button
                                    onClick={resend}
                                    disabled={!resendEnabled}
                                    color="secondary"
                                    startIcon={<RefreshIcon />}
                                    className={classes.resendBtn}
                                >
                                    {t("lb_resend")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <DoubleButtons
                        progressing={submitting}
                        secondaryButton={{
                            label: t("lb_cancel"),
                            onClick: handleClose,
                        }}
                        primaryButton={{ label: t("lb_submit") }}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    opened: state.otpDialog.opened,
    otpError: state.otpDialog.otpError,
    type: state.otpDialog.type,
    refNo: state.otpDialog.refNo,
    submitFunc: state.otpDialog.submitFunc,
    resendFunc: state.otpDialog.resendFunc,
    loading: state.otpDialog.loading,
    dialogTitle: state.otpDialog.dialogTitle,
    dialogSubtitle: state.otpDialog.dialogSubtitle,
    dialogDescription: state.otpDialog.dialogDescription,
    dialogHideResendButton: state.otpDialog.dialogHideResendButton,
    dialogOnCancel: state.otpDialog.dialogOnCancel,
    authFetching: state.auth.fetching,
    moveMoneyFetching: state.moveMoney.fetching,
    authRefNo: state.auth.refNo,
    moveMoneyRefNo: state.moveMoney.refNo,
    user: state.user.info,
    otpCardFetching: state.cardInfo.otpFetching,
    otpMaskFetching: state.cardInfo.masks.otpFetching,
    cardRefNo: state.cardInfo.refNo,
    cardId: state.cardInfo.cardId,
    cardAction: state.cardInfo.cardAction,
    cardType: state.cardInfo.cardType,
    topUpFetching: state.topUp.submitting,
    topUpRefNo: state.topUp.refNo,
    forgotPasswordRefNo: state.forgotPassword.refNo,
    forgotPasswordPhoneNumber: state.forgotPassword.phoneNumber,
    version: state.cardInfo.version,
    aliasRefNo: state.user?.submitAlias.data.refNo,
    featureState: state.user.featureState.data,
});

const mapDispatchToProps = (dispatch) => ({
    open: (payload) => dispatch(otpDialogAction.open(payload)),
    close: () => dispatch(otpDialogAction.close()),
    submitDeviceAuthenticationOTP: (payload) =>
        dispatch(authAction.submitDeviceAuthenticationOTPRequest(payload)),
    submitTwoFactorOTP: (payload) =>
        dispatch(authAction.submitTwoFactorOTPRequest(payload)),
    submitMoveMoneyOTP: (payload) =>
        dispatch(moveMoneyAction.submitOTP(payload)),
    submitUpdateCardStatusOTP: (payload) =>
        dispatch(CardManagementActions.otpCommit(payload)),
    submitResetPinOTP: (payload) =>
        dispatch(CardManagementActions.otpCommitResetPin(payload)),
    submitReIssueCardOTP: (payload) =>
        dispatch(CardManagementActions.otpCommitReIssueCard(payload)),
    resetAuth: () => dispatch(authAction.reset()),
    resetMoveMoney: () => dispatch(moveMoneyAction.reset()),
    cancelOTPCardManagement: () => dispatch(CardManagementActions.otpCancel()),
    submitCardActivationOTP: (payload) =>
        dispatch(CardManagementActions.otpCommitCardActivation(payload)),
    resetTopUp: () => dispatch(topUpAction.OTPCancel()),
    otpCommitTopUp: (payload) => dispatch(topUpAction.OTPCommit(payload)),
    resetMoveMoneyByPhone: (payload) =>
        dispatch(moveMoneyAction.resetPayAnyoneData(payload)),
    commitOtpTransferByPhone: (payload) =>
        dispatch(moveMoneyAction.commitOTPTransferByPhone(payload)),
    otpCommitCloseInstallmentEarly: (payload) =>
        dispatch(moveMoneyAction.closeInstallmentEarlyCommitOTP(payload)),
    submitForgotPasswordOTP: (payload) =>
        dispatch(ForgotPasswordActions.otpCommit(payload)),
    submitMaskOTP: (payload) =>
        dispatch(CardManagementActions.getMaskOTPRequest(payload)),
    cancelMaskCardRequest: () =>
        dispatch(CardManagementActions.cancelGetMaskOTP()),
    createAliasOTP: (payload) =>
        dispatch(userAction.submitOTPNicknameRequest(payload)),
    submitGGTwoFactorOTPRequest: (payload) =>
        dispatch(authAction.submitGGTwoFactorOTPRequest(payload)),
    clearLoginTextField: (payload) =>
        dispatch(authAction.updateClearLoginTextfield(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPDialog);
