import tomiBaoTri from "@assets/images/tomi_bao_tri.png";
import LspTranslation from "@components/LspTranslation";
import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import OTPController from "@helpers/controllers/OTPController";

const hostUrl = process.env.REACT_APP_API_URL;

const handleCloseDialog = () => {
    GlobalDialogController.hide();
    OTPController.hide();
};

export const twoFactorInit = (onSubmit) => {
    const contentError = {
        banner: `${hostUrl}/cxpassets/frontend/images/money-pot/general/mp_top_up_success.png`,
        title: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_confirm_title" />
        ),
        description: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_confirm_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="gg_authen:gg_authen_popup_confirm_btn_ok" />
                ),
                onClick: () => onSubmit(),
            },
            {
                variant: "plain",
                label: (
                    <LspTranslation i18nKey="gg_authen:gg_authen_popup_confirm_btn_cancel" />
                ),
                onClick: () => handleCloseDialog(),
            },
        ],
    };

    GlobalDialogController.show({
        component: () => (
            <ConditionNotificationDialog content={contentError} centerImg />
        ),
    });
};

export const twoFactorsWarningDialog = () => {
    const contentError = {
        banner: tomiBaoTri,
        title: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_verify_title" />
        ),
        description: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_verify_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_verify_btn_back" />
                ),
                onClick: () => handleCloseDialog(),
            },
        ],
    };

    GlobalDialogController.show({
        component: () => (
            <ConditionNotificationDialog content={contentError} centerImg />
        ),
    });
};

export const twoFactorsWarningSetupDialog = (onSubmit) => {
    const contentError = {
        banner: tomiBaoTri,
        title: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_enable_title" />
        ),
        description: (
            <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_enable_desc" />
        ),
        buttons: [
            {
                variant: "primary",
                label: (
                    <LspTranslation i18nKey="gg_authen:gg_authen_popup_fail_enable_btn_try_again" />
                ),
                onClick: () => onSubmit(),
            },
        ],
    };

    GlobalDialogController.show({
        component: () => (
            <ConditionNotificationDialog content={contentError} centerImg />
        ),
    });
};
