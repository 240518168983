import LspTypography from "@components/LspTypography";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    splitBillCard: {},
    splitBillCardHeader: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
        alignItems: "center",
    },
    splitBillCardHeaderIcon: {
        marginRight: theme.spacing(1.25),
        display: "inline-flex",
        alignItems: "center",
        width: 18,
        height: 18,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
}));

const SplitBillCard = ({ icon, title, children, loading }) => {
    const classes = useStyles();

    const fetchingLayer = useMemo(
        () => (
            <>
                <div className={classes.splitBillCardHeaderIcon}>
                    <Skeleton
                        animation="wave"
                        variant="rect"
                        width={18}
                        height={18}
                    />
                </div>
                <Skeleton animation="wave" height={25} width="100%" />
            </>
        ),
        [classes.splitBillCardHeaderIcon]
    );

    return (
        <>
            <div className={classes.splitBillCard}>
                <div className={classes.splitBillCardHeader}>
                    {loading && fetchingLayer}
                    {!loading && (
                        <>
                            <div className={classes.splitBillCardHeaderIcon}>
                                {icon}
                            </div>
                            <LspTypography
                                className={classes.splitBillCardHeaderText}
                                variant="subheading1"
                                color="gold"
                            >
                                {title}
                            </LspTypography>
                        </>
                    )}
                </div>
                <div className={classes.splitBillCardHeaderBody}>
                    {children}
                </div>
            </div>
        </>
    );
};

SplitBillCard.propTypes = {
    loading: PropTypes.bool,
};

SplitBillCard.defaultProps = {
    loading: false,
};

export default memo(SplitBillCard);
