import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import clsx from "clsx";
import Toggle from "@components/Toggle";
import useCard from "@helpers/useCard";
import {
    BANK_CARD_STATUS,
    CARD_STATUSES,
    CARD_TYPES,
    E_COMMERCE_STATUSES,
} from "./constant";

const useStyles = makeStyles((theme) => ({
    eCommerceOptions: {
        padding: theme.spacing(3),
        color: theme.palette.grey[700],
    },
    disabled: {
        cursor: "not-allowed",
        pointerEvents: "none",
        opacity: 0.5,
    },
}));

const CardECommerce = ({
    card,
    onChangeECommerceStatus,
    systemParams,
    eCommerceSUpdating,
}) => {
    const classes = useStyles();
    const [eCom, setECom] = useState(true);
    const [isShowECom, setIsShowECom] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { t } = useTranslation();
    const { isPhysicalVirtualDebitVisa } = useCard();

    const isPhysicalVirDebitVisa = useMemo(
        () =>
            isPhysicalVirtualDebitVisa(
                card?.cardType,
                card?.physicalVirtualVDCardStatus
            ),
        [card, isPhysicalVirtualDebitVisa]
    );

    const allowEcom = useMemo(
        () =>
            (systemParams.AllowDebitEcommerce &&
                card.cardType !== CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP &&
                card.cardType !== CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD) ||
            false,
        [card, systemParams.AllowDebitEcommerce]
    );

    const checkECommercePermission = useCallback(() => {
        const eComStatus = card.ecomStatus;
        const haveECom = isPhysicalVirDebitVisa || allowEcom;

        setIsShowECom(haveECom);
        if (eComStatus === E_COMMERCE_STATUSES.ENABLED) {
            setECom(true);
        } else {
            setECom(false);
        }
    }, [card, allowEcom, isPhysicalVirDebitVisa]);

    const isShowECommerceOption = useCallback(() => {
        const { cardStatus } = card;
        switch (cardStatus) {
            case CARD_STATUSES.ACTIVE:
                checkECommercePermission();
                break;
            case CARD_STATUSES.BLOCKED:
                // if (card?.cardType === CARD_TYPES.VISA_CARD) {
                //     return false;
                // }
                setIsDisabled(true);
                const isReIssueStatus =
                    card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                if (!isReIssueStatus) {
                    checkECommercePermission();
                }
                break;
            default:
                break;
        }
    }, [card, checkECommercePermission]);

    useEffect(() => {
        isShowECommerceOption();
    }, [isShowECommerceOption]);

    return (
        <>
            {isShowECom && (
                <>
                    {isPhysicalVirDebitVisa && (
                        <Box paddingX={3}>
                            <Divider />
                        </Box>
                    )}
                    <div
                        className={clsx(classes.eCommerceOptions, {
                            [classes.disabled]: isDisabled,
                        })}
                    >
                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={8}>
                                {t("mc_ecommerce")}
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                <Toggle
                                    status={eCom}
                                    onChangeHandler={() =>
                                        onChangeECommerceStatus(card)
                                    }
                                    loading={eCommerceSUpdating}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
        </>
    );
};

const mapState = (state) => ({
    systemParams: state.systemParams.info.sysparams["1.0"],
});

export default connect(mapState)(CardECommerce);
