import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const BillListFetching = ({ parentClasses }) => {
    return (
        <>
            {[...new Array(3)].map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={parentClasses.billItem} key={index}>
                    <Box
                        p={1}
                        height={83}
                        width="100%"
                        display="flex"
                        alignItems="center"
                    >
                        <Box px={1}>
                            <Skeleton
                                animation="pulse"
                                variant="circle"
                                width={50}
                                height={50}
                            />
                        </Box>
                        <Box px={1}>
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={90}
                                />
                            </Typography>
                            <Typography>
                                <Skeleton
                                    animation="pulse"
                                    variant="text"
                                    width={150}
                                />
                            </Typography>
                        </Box>
                    </Box>
                </div>
            ))}
        </>
    );
};

export default BillListFetching;
