import { Box, Icon, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AlertController from "@helpers/controllers/AlertController";
import Toggle from "@components/Toggle";
import PasswordGeneratorFetching from "@containers/SpendAccount/MoveMoney/PayAnyone/PasswordGeneratorFetching";

const useStyles = makeStyles((theme) => ({
    passwordGenerator: {
        background: theme.palette.background.default,
        display: "flex",
        justifyContent: "space-between",
        "& div": {
            width: "100%",
        },
        "& #secretCodeHidden": {
            height: 0,
            opacity: 0,
            padding: 0,
            margin: 0,
            overflow: "hidden",
        },
    },
    passwordFetching: {},
    info: {
        marginRight: theme.spacing(2),
    },
    toggle: {
        flex: 1,
    },
    title: {
        color: theme.palette.blue.main,
        fontWeight: 600,
    },
    description: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
    },
    disabledOption: {
        cursor: "not-allowed",
        pointerEvents: "none",
        opacity: 0.5,
    },
    icon: {
        cursor: "pointer",
    },
}));

const PasswordGenerator = ({
    password,
    option,
    isDisabled,
    fetching,
    onChangePasswordOption,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "payAnyone");

    const copyHandler = () => {
        AlertController.show(t("payAnyone:password_copy_success_banner"));
    };

    return (
        <Box
            className={`${classes.passwordGenerator} ${
                isDisabled && "disabledOption"
            }`}
            component={Paper}
            p={2}
            mt={3}
            mb={4}
        >
            {fetching && <PasswordGeneratorFetching className={classes} />}
            {!fetching && (
                <>
                    <div className={classes.info}>
                        <Typography className={classes.title}>
                            {t("payAnyone:payment_password_title")}
                        </Typography>

                        {option && (
                            <div>
                                <Typography>
                                    {`${password} `}
                                    <CopyToClipboard
                                        text={password}
                                        onCopy={copyHandler}
                                    >
                                        <Icon
                                            className={`font-icon icon-copytoclipboard  ${classes.icon}`}
                                        />
                                    </CopyToClipboard>
                                </Typography>
                            </div>
                        )}

                        <div>
                            {!option && (
                                <Typography className={classes.description}>
                                    {t("payAnyone:payment_password_off")}
                                </Typography>
                            )}
                            {option && (
                                <Typography className={classes.description}>
                                    {t("payAnyone:payment_password_on")}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <div className={classes.toggle}>
                        <Toggle
                            status={option}
                            onChangeHandler={onChangePasswordOption}
                            loading={fetching}
                            disabled={isDisabled}
                        />
                    </div>
                </>
            )}
        </Box>
    );
};

export default PasswordGenerator;
