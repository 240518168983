/* ------------- Types Definition ------------- */
export const LspReceiptActionType = Object.freeze({
    GetReceipt: "LspReceiptActionType/GetReceipt",
    GetReceiptSuccess: "LspReceiptActionType/GetReceiptSuccess",
    GetReceiptError: "LspReceiptActionType/GetReceiptError",
    Reset: "LspReceiptActionType/Reset",
    ResetNotes: "LspReceiptActionType/ResetNotes",
    SubmitNotes: "LspReceiptActionType/SubmitNotes",
    SubmitNotesSuccess: "LspReceiptActionType/SubmitNotesSuccess",
    SubmitNotesError: "LspReceiptActionType/SubmitNotesError",

    GetPaymentDetailRequest: "LspReceiptActionType/GetPaymentDetailRequest",
    GetPaymentDetailSuccess: "LspReceiptActionType/GetPaymentDetailSuccess",
    GetPaymentDetailError: "LspReceiptActionType/GetPaymentDetailError",
});

/* ------------- Actions Definition ------------- */

const getReceipt = (payload) => {
    return {
        type: LspReceiptActionType.GetReceipt,
        payload,
    };
};

const getReceiptSuccess = (payload) => {
    return {
        type: LspReceiptActionType.GetReceiptSuccess,
        payload,
    };
};

const getReceiptError = () => {
    return {
        type: LspReceiptActionType.GetReceiptError,
    };
};

const submitNotes = (payload) => {
    return {
        type: LspReceiptActionType.SubmitNotes,
        payload,
    };
};

const submitNotesSuccess = (payload) => {
    return {
        type: LspReceiptActionType.SubmitNotesSuccess,
        payload,
    };
};

const submitNotesError = () => {
    return {
        type: LspReceiptActionType.SubmitNotesError,
    };
};

const reset = () => {
    return {
        type: LspReceiptActionType.Reset,
    };
};

const resetNote = () => {
    return {
        type: LspReceiptActionType.ResetNotes,
    };
};

const getPaymentDetailRequest = (payload) => {
    return {
        type: LspReceiptActionType.GetPaymentDetailRequest,
        payload,
    };
};

const getPaymentDetailSuccess = (payload) => {
    return {
        type: LspReceiptActionType.GetPaymentDetailSuccess,
        payload,
    };
};

const getPaymentDetailError = () => {
    return {
        type: LspReceiptActionType.GetPaymentDetailError,
    };
};

const lspReceiptActions = {
    getReceipt,
    getReceiptSuccess,
    getReceiptError,
    reset,
    submitNotes,
    submitNotesSuccess,
    submitNotesError,
    resetNote,
    getPaymentDetailRequest,
    getPaymentDetailSuccess,
    getPaymentDetailError,
};

export default lspReceiptActions;
