import sessionStorage from "redux-persist/lib/storage/session";
import localStorage from "redux-persist/lib/storage/index";

// const authReconciler = (inboundState, originalState, reducedState) => {
//     if (!(inboundState.token && inboundState.isAuthenticated)) {
//         return originalState;
//     }
//     return inboundState;
// };

export const authPersistConfig = {
    key: "auth",
    storage: sessionStorage,
    whitelist: ["token", "timoDeviceId", "userId"],
    // stateReconciler: authReconciler,
};

export const appPersistConfig = {
    key: "app",
    storage: sessionStorage,
    whitelist: ["status", "themeName", "isDarkMode"],
};

export const quickCodePersistConfig = {
    key: "quickCode",
    storage: sessionStorage,
    whitelist: ["opened"],
};

export const metricsConfig = {
    key: "metrics",
    storage: localStorage,
    whitelist: ["metric", "metricList", "metricConfig"],
};

export const notificationPersistConfig = {
    key: "notification",
    storage: localStorage,
    whitelist: ["showAliasBankNameDialog"],
};
