import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { InfoIcon } from "@components/LspIcon";
import LspReceiptPreview from "@components/LspReceipt/LspReceiptPreview";
import LspTypography from "@components/LspTypography";
import OverdraftIntroDialog from "@components/OverdraftIntroDialog";
import { DefaultOption, FetchStatus, RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useAccounts from "@helpers/useAccounts";
import { Box, Paper, makeStyles } from "@material-ui/core";
import moveMoneyAction from "@redux/actions/move-money";
import payeeService from "@services/payee";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    overdraftText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(3.5),
    },
    hintIcon: {
        color: theme.palette.grey[400],
        marginRight: theme.spacing(0.5),
        width: 16,
        height: 16,
    },
}));

const PaymentConfirmation = ({
    user,
    transferring,
    transferStatus,
    next,
    amount,
    description,
    defaultDescription,
    transferToCreditCard,
}) => {
    const { t } = useTranslation();
    const { spendAccount, creditCard, isOverDraftUser } = useAccounts();
    const classes = useStyles();

    useEffect(() => {
        if (transferStatus === FetchStatus.Success) {
            next();
        }
    }, [transferStatus, next]);

    const getCreditCardPayee = useCallback(async () => {
        if (!creditCard) {
            return null;
        }

        const response = await payeeService.getPayee({
            payeeId: creditCard.payeeId,
            payeeType: "mastercard",
        });
        const { code, data } = response.data;

        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                return `${data.cards[0].cardId}`;
            default:
                GlobalDialogController.showError({
                    errorCode: code,
                });
                return null;
        }
    }, [creditCard]);

    const receiptInfo = useMemo(
        () => ({
            txnAmount: amount.value,
            txnTo: {
                accName: user.fullName,
                bankName: DefaultOption.Bank,
                accNo: creditCard.no,
            },
            txnDesc: description || defaultDescription,
        }),
        [
            amount.value,
            creditCard.no,
            description,
            defaultDescription,
            user.fullName,
        ]
    );

    const onConfirmClick = useCallback(async () => {
        const destinationId = await getCreditCardPayee();

        if (!destinationId) {
            return;
        }

        const payload = {
            notification: "sms",
            source: {
                accountNumber: spendAccount.no,
            },
            target: {
                amount: amount.value,
                bankName: t("mm_label_bank_name_timo"),
                cardName: user.fullName,
                cardNumber: creditCard.no,
                description,
                destinationId,
                payeeId: creditCard.payeeId,
            },
        };
        transferToCreditCard(payload);
    }, [
        getCreditCardPayee,
        t,
        amount,
        creditCard,
        spendAccount,
        description,
        user,
        transferToCreditCard,
    ]);

    const onCloseOverdraftViewMore = () => {
        GlobalDialogController.hide();
    };

    const viewMoreOverdraft = () => {
        GlobalDialogController.show({
            component: () => (
                <OverdraftIntroDialog onClose={onCloseOverdraftViewMore} />
            ),
        });
    };

    return (
        <Content size="sm">
            <Box component={Paper} overflow="hidden" elevation={4}>
                <LspReceiptPreview {...receiptInfo} noMarginTop />
            </Box>
            <LspButton
                mt={3}
                fullWidth
                onClick={onConfirmClick}
                progressing={transferring}
            >
                {t("txt_pay")}
            </LspButton>
            {isOverDraftUser && (
                <Box className={classes.overdraftText}>
                    <InfoIcon className={classes.hintIcon} />
                    <LspTypography color="grey" variant="tiny">
                        {t("overdraft:overdraft_not_apply")}
                    </LspTypography>
                    &nbsp;
                    <LspTypography
                        color="grey"
                        variant="hyperlink3"
                        onClick={viewMoreOverdraft}
                    >
                        {t("wealth:vc3_seemore_btn")}
                    </LspTypography>
                </Box>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    transferring: state.moveMoney.fetching,
    transferStatus: state.moveMoney.status,
});

const mapDispatch = (dispatch) => ({
    transferToCreditCard: (payload) =>
        dispatch(moveMoneyAction.transferToCreditCardRequest(payload)),
});

export default connect(mapState, mapDispatch)(PaymentConfirmation);
