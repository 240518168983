/* eslint-disable react/no-array-index-key */
import { memo, useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import { makeStyles } from "@material-ui/core";
import requestDocumentService from "@services/request-document";
import LspButton from "@components/LspButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import RequestDocumentActions from "@redux/actions/request-document";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import Interweave from "interweave";
import { format } from "date-fns";
import useOTP from "@helpers/useOTP";
import moveMoneyService from "@services/move-money";
import LspCongratulation from "@components/LspCongratulation";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import { REQUEST_DOC_FORM_TYPE } from "./constant";

const useStyles = makeStyles((theme) => ({
    content: {
        textAlign: "center",
        "& > span": {
            display: "block",
            margin: theme.spacing(2, 0),
        },
    },
    nextBtn: {
        marginTop: theme.spacing(2),
        width: "100%",
        "& > button": {
            width: "50%",
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    },
}));

const OTP_STATUS = {
    SUCCESS: "1",
    ERROR: "2",
};

const PaymentDocument = ({ onBack, documentInfo, serverTime }) => {
    const { t } = useTranslation();
    const [result, setResult] = useState("");
    const [txnId, setTxnId] = useState("");
    const [fetching, setFetching] = useState(false);
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const history = useHistory();

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const title = useMemo(() => {
        const content = documentInfo.confirm_msg
            .replace("%@doc", `<b>${documentInfo.desc}</b>`)
            .replace(
                "%@address",
                `<b> ${documentInfo.selected_doc.receiveDetail}</b>`
            );
        return content;
    }, [documentInfo]);

    const serviceFee = useMemo(() => {
        const content = t("rq_document_confirm_service_fee").replace(
            "%@",
            `<span> ${formatNumber(documentInfo.selected_doc.amount)} </span>`
        );
        return content;
    }, [documentInfo, t, formatNumber]);

    const returnMessageResult = (data) => {
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setResult(OTP_STATUS.SUCCESS);
                setTxnId(data.data.txnId);
                closeOTPDialog();
                break;
            case RESPONSE_CODE.CANT_TRANSFER_TO_DESTINATION:
                setResult(OTP_STATUS.ERROR);
                closeOTPDialog();
                break;

            case RESPONSE_CODE.NOT_ALLOWED: // 405 no enough money to transfer
                const content = `${t("spend_msg_for_least_amount").replace(
                    "%@",
                    formatNumber(documentInfo.selected_doc.amount)
                )}<br><br><i>${t("rq_document_please_contribute")}</i>`;
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "moneyerror",
                        header: "global_title_insuficient_funds",
                        content,
                        button: t("lb_ok"),
                    },
                });
                closeOTPDialog();
                break;
            case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED: // 409
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER: // 4208
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: "global_title_daily_limit_exceed",
                        content: "ms_lb_transfer_daily_limit",
                        button: t("lb_ok"),
                    },
                });
                closeOTPDialog();
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const commitOtp = async (dataToPost) => {
        setLoading(true);
        const { data } = await requestDocumentService.commitOTP(dataToPost);
        setLoading(false);
        returnMessageResult(data);
    };

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const submitRequestDocument = async () => {
        setFetching(true);
        const dataToPost = { ...documentInfo.data_to_post };
        if (documentInfo.view !== REQUEST_DOC_FORM_TYPE.oneFieldDate) {
            // two fields or three fields
            if (documentInfo.view === REQUEST_DOC_FORM_TYPE.twoFieldsDate) {
                dataToPost.fromDate = documentInfo.fromDate;
                dataToPost.toDate = documentInfo.toDate;
            } else if (
                documentInfo.view === REQUEST_DOC_FORM_TYPE.threeFields &&
                documentInfo.cycleType !== ""
            ) {
                dataToPost.cycle = documentInfo.cycleType.toString();
                if (documentInfo.cycleType === 0) {
                    // customize
                    dataToPost.fromDate = documentInfo.fromDate;
                    dataToPost.toDate = documentInfo.toDate;
                }
            } else {
                dataToPost.transactionId = documentInfo.transactionId;
            }

            dataToPost.requestedDate = format(
                serverTime.value,
                "dd/MM/yyyy HH:mm:ss"
            );
        } else {
            dataToPost.requestedDate = format(
                serverTime.value,
                "dd/MM/yyyy HH:mm:ss"
            );
            dataToPost.selectedAccounts = [];
            const childAccountIds = [];
            const { accountList } = documentInfo;
            if (accountList != null) {
                const selectedAccount = accountList;
                for (let i = 0; i < selectedAccount.length; i++) {
                    if (!selectedAccount[i].childAccounts) {
                        dataToPost.selectedAccounts.push({
                            type: selectedAccount[i].type,
                        });
                    } else if (selectedAccount[i].childAccounts) {
                        for (
                            let iii = 0;
                            iii < selectedAccount[i].childAccounts.length;
                            iii++
                        ) {
                            childAccountIds.push(
                                selectedAccount[i].childAccounts[iii].id
                            );
                        }
                        if (childAccountIds.length > 0) {
                            dataToPost.selectedAccounts.push({
                                type: selectedAccount[i].type,
                                childAccountIds,
                            });
                        }
                    }
                }
            } else {
                // Remittance Confirmation with Stamp
                dataToPost.requestedDate = format(
                    serverTime.value,
                    "dd/MM/yyyy"
                );
            }
        }

        dataToPost.provinceId = parseInt(dataToPost.provinceId);
        const { data } = await requestDocumentService.submitFeeDocument(
            dataToPost
        );
        setFetching(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setResult(OTP_STATUS.SUCCESS);
                setTxnId(data.data.txnId);
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: commitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED: // 409
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER: // 4208
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: "global_title_daily_limit_exceed",
                        content: "ms_lb_transfer_daily_limit",
                        button: t("lb_ok"),
                    },
                });
                closeOTPDialog();
                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    const showReceipt = useCallback(() => {
        LspReceiptController.dialog(txnId);
    }, [txnId]);

    return (
        <Content>
            {result ? (
                <LspCongratulation
                    icon={result === OTP_STATUS.SUCCESS ? "ok" : "close"}
                    type={result === OTP_STATUS.SUCCESS ? "success" : "error"}
                    title={
                        result === OTP_STATUS.SUCCESS
                            ? t("lb_thank_you")
                            : t("gs_contribute_error_title")
                    }
                    contents={
                        result === OTP_STATUS.SUCCESS
                            ? `${t("rq_document_success_title")}<br/>` +
                              `<i>${t("rq_document_success_msg")}</i>`
                            : t("nt_ms_error_internal_bank_transfer")
                    }
                    contentWithAction={
                        result === OTP_STATUS.SUCCESS && {
                            label: t("investigation_btn_view_receipt"),
                            onClick: showReceipt,
                        }
                    }
                    button={{
                        label: t("lb_done"),
                        onClick: () => history.push("list"),
                    }}
                />
            ) : (
                <>
                    <PageHeaderBack onBack={onBack}>
                        {documentInfo.desc}
                    </PageHeaderBack>
                    <div className={classes.content}>
                        <Interweave content={title} />
                        <Interweave content={serviceFee} />
                    </div>
                    <LspButton
                        className={classes.nextBtn}
                        onClick={submitRequestDocument}
                        progressing={fetching}
                    >
                        {t("rq_document_confirm_and_pay")}
                    </LspButton>
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    documentInfo: state.requestDocument,
    serverTime: state.user.serverTime,
});

const mapDispatch = (dispatch) => ({
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
});

export default memo(connect(mapState, mapDispatch)(PaymentDocument));
