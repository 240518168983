/* eslint-disable react/no-array-index-key */
import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import {
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import { ArrowDownIcon } from "@components/LspIcon";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";

const useStyles = makeStyles((theme) => ({
    selected: {
        background: theme.palette.primary.main,
        "& > div > p": {
            color: theme.palette.neutral.white,
        },
    },
    heading: {
        fontSize: 26,
        color: theme.palette.primary.main,
        margin: theme.spacing(2, 0),
    },
}));

export const AccordionContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 4),
        display: "block",
        textAlign: "left",
    },
}))(AccordionDetails);

const FAQ = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();
    const location = useLocation();
    const queryParams = querystring.parse(location.search);

    const openFirstTime = useRef(false);
    const [selectedItem, setSelectedItem] = useState("");
    const content = useMemo(() => {
        const faqContent = t(`faq:OV00003`, {
            returnObjects: true,
            defaultValue: "",
        })[0];

        const mappedList = faqContent?.groups?.map((item) => {
            let id = replaceSpecialCharacter({
                pattern: REGEX_PATTERN.NICK_NAME,
                text: item?.title,
                hasEscapeAccentVietnamese: true,
            });
            id = id.split(" ").join("-");
            return { ...item, id };
        });

        return {
            ...faqContent,
            groups: mappedList,
        };
    }, [t, replaceSpecialCharacter]);

    const itemClick = (item) => {
        if (item.id === selectedItem) {
            setSelectedItem("");
            return;
        }
        setSelectedItem(item.id);
    };

    useEffect(() => {
        if (queryParams?.open && !openFirstTime.current) {
            let title = "";
            switch (queryParams?.open) {
                case "credit":
                    title = content?.groups[3]?.id;
                    break;
                default:
                    break;
            }
            setSelectedItem(title);
            openFirstTime.current = true;
        }
    }, [queryParams?.open, content]);

    return (
        <>
            <Banner title={t("faq:OV00001").toUpperCase()} />
            <ContentWrapper>
                <Content size="md">
                    <p className={classes.heading}>{content.groupTitle}</p>
                    {content?.groups.map((item, index) => (
                        <Accordion
                            expanded={selectedItem === item.id}
                            key={index}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowDownIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={clsx({
                                    [classes.selected]:
                                        selectedItem === item.id,
                                })}
                                onClick={() => itemClick(item)}
                            >
                                <LspTypography color="black" variant="heading3">
                                    {item.title}
                                </LspTypography>
                            </AccordionSummary>
                            <AccordionContent>
                                {item.questions.map((value, key) => (
                                    <Accordion key={key}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <LspTypography
                                                color="black"
                                                variant="heading4"
                                            >
                                                {value.name}
                                            </LspTypography>
                                        </AccordionSummary>
                                        <AccordionContent>
                                            {value.answer.map((elm, k) => (
                                                <LspTypography
                                                    color="black"
                                                    variant="body3"
                                                    key={k}
                                                >
                                                    {elm}
                                                </LspTypography>
                                            ))}
                                        </AccordionContent>
                                    </Accordion>
                                ))}
                            </AccordionContent>
                        </Accordion>
                    ))}
                </Content>
            </ContentWrapper>
        </>
    );
};

export default FAQ;
