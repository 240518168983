import Loading from "@components/Loading";
import { EditIcon } from "@components/LspIcon";
import LspTypography from "@components/LspTypography";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    actionButton: {
        textTransform: "capitalize",
        display: "flex",
        fontSize: theme.typography.pxToRem(16),
        marginLeft: theme.spacing(2),
        textDecoration: "none",
    },
    actionIcon: {
        marginLeft: theme.spacing(0.25),
        color: theme.palette.neutral.grey1,
    },
}));
const ProfileEditComponent = ({ isEdit, onEdit, onCancel, updating }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            {!isEdit && (
                <ButtonBase className={classes.actionButton} onClick={onEdit}>
                    <LspTypography color="grey">
                        {t("user_profile_edit_btn")}
                    </LspTypography>
                    <EditIcon className={classes.actionIcon} fontSize="small" />
                </ButtonBase>
            )}
            {isEdit && (
                <>
                    <ButtonBase
                        className={classes.actionButton}
                        onClick={onCancel}
                        disabled={updating}
                    >
                        <LspTypography color="error">
                            {t("lb_cancel")}
                        </LspTypography>
                    </ButtonBase>
                    <ButtonBase
                        className={classes.actionButton}
                        type="submit"
                        disabled={updating}
                    >
                        <LspTypography color="success">
                            {t("lb_next")}
                        </LspTypography>
                        {updating && (
                            <div style={{ marginLeft: 8 }}>
                                <Loading />
                            </div>
                        )}
                    </ButtonBase>
                </>
            )}
        </>
    );
};

export default memo(ProfileEditComponent);
