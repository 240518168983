import { connect } from "react-redux";

import { Box, InputAdornment, CircularProgress } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";
import { REGEX_PATTERN, RESPONSE_CODE } from "@config/constants";
import DoubleButtons from "@components/DoubleButtons";
import useAccounts from "@helpers/useAccounts";
import moveMoneyService from "@services/move-money";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";

const AddMemberDestination = ({ onCancel, onSave, ownerEmail, loading }) => {
    const { t } = useTranslation();
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const [memberDetail, setMemberDetail] = useState("");
    const [error, setError] = useState("");
    const { spendAccount } = useAccounts();
    const [destination, setDestination] = useState(null);
    const destinationDescription = useMemo(() => {
        if (destination) {
            return `${destination.bank.accountName} | ${destination.bank.bankShortName}`;
        }
        return " ";
    }, [destination]);
    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const onChangeMemberDetail = (event) => {
        const value = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.MEMBER_DETAIL,
            text: event.target.value,
        });
        setMemberDetail(value);
        if (!value) {
            setError(t("msg_we_need_this"));
        } else {
            setError("");
        }
    };

    const getTargetAccount = useCallback(
        async (target, callbackFunc) => {
            if (ownerEmail === target) {
                setError(t("payee_card_number_invalid_payee_is_payer"));
                return;
            }
            if (!spendAccount || !target || fetchingTargetAccount) {
                return;
            }

            setFetchingTargetAccount(true);

            const payload = {
                targetInfo: target,
                bankAccount: spendAccount.no,
            };

            const response = await moveMoneyService.getInternalAccountDetail(
                payload
            );

            if (!unmounted.current) {
                setFetchingTargetAccount(false);
                const { code, data } = response.data;
                switch (code) {
                    case RESPONSE_CODE.SUCCESS:
                        if (data.bank.accountNumber === spendAccount.no) {
                            setDestination(null);
                            setError(
                                t("payee_card_number_invalid_payee_is_payer")
                            );
                        } else {
                            setDestination({ ...data, targetInfo: target });
                            if (callbackFunc) {
                                callbackFunc({ ...data, targetInfo: target });
                            }
                        }
                        break;
                    case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                        setDestination(null);
                        setError(t("spend_lb_invalid_timo_destination"));
                        break;
                    default:
                        break;
                }
            }
        },
        [t, spendAccount, ownerEmail, fetchingTargetAccount]
    );

    const onSaveHandler = useCallback(
        (target, destinationInfo) => {
            if (!target) {
                setError(t("msg_we_need_this"));
                return;
            }
            if (!destinationInfo) {
                getTargetAccount(target, (info) => {
                    onSave(info);
                });
            } else {
                onSave(destinationInfo);
            }
        },
        [getTargetAccount, onSave, t]
    );

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                onSaveHandler(memberDetail, destination);
            }}
        >
            <Box>
                <LspTextField
                    autoFocus
                    error={!!error}
                    name="memberDetail"
                    label={t("spend_move_money_hint_username_member_detail")}
                    helperText={error || destinationDescription}
                    inputProps={{
                        maxLength: 35,
                    }}
                    onChange={onChangeMemberDetail}
                    value={memberDetail}
                    InputProps={{
                        endAdornment: fetchingTargetAccount ? (
                            <InputAdornment position="end">
                                <CircularProgress size={16} />
                            </InputAdornment>
                        ) : null,
                        type: "search",
                    }}
                    onBlur={() => getTargetAccount(memberDetail)}
                />
            </Box>
            <DoubleButtons
                primaryButton={{
                    label: t("payee_btn_add_destination"),
                    onClick: (e) => {
                        e.preventDefault();
                        onSaveHandler(memberDetail, destination);
                    },
                }}
                secondaryButton={{
                    label: t("lb_cancel"),
                    onClick: onCancel,
                }}
                disabled={loading}
                progressing={loading}
            />
        </form>
    );
};

const stateProps = (state) => ({
    ownerEmail: state.user?.info?.userEmail,
});

export default connect(stateProps)(AddMemberDestination);
