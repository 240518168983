import { connect } from "react-redux";

import {
    Icon,
    InputAdornment,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";

import { useTranslation } from "react-i18next";
import LspTextField from "@components/LspTextField";

import { provinceList } from "@i18n/resources/provinceList.json";
import { branchList } from "@i18n/resources/branchList.json";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { filter, uniqBy } from "lodash";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { BANK_INFO, RESPONSE_CODE } from "@config/constants";
import moveMoneyService from "@services/move-money";
import useAccounts from "@helpers/useAccounts";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import DoubleButtons from "@components/DoubleButtons";

const useStyles = makeStyles((theme) => ({
    fastTransferIcon: {
        color: "#4990e2",
        fontSize: theme.typography.pxToRem(14),
        marginRight: theme.spacing(1),
        opacity: 0,
        "&.visible": {
            opacity: 1,
        },
    },
}));

const AddBankAccountDestination = ({
    onCancel,
    onSave,
    loading,
    sysParam,
    bankList,
}) => {
    const { t } = useTranslation();

    const classes = useStyles();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const initErrors = useMemo(
        () => ({
            selectedBank: null,
            province: null,
            branch: null,
            targetInfo: null,
            ownerName: null,
        }),
        []
    );

    const { spendAccount } = useAccounts();

    const [errors, setErrors] = useState(initErrors);

    const clearErrors = useCallback(() => setErrors(initErrors), [initErrors]);

    const [destination, setDestination] = useState(null);
    const [normalTransferMessage, setNormalTransferMessage] = useState("");

    const [targetInfo, setTargetInfo] = useState("");
    const [selectedBank, setSelectedBank] = useState(null);
    const selectedBankBranchList = useRef([]);
    const [
        selectedBankProvinceBranchList,
        setSelectedBankProvinceBranchList,
    ] = useState([]);
    const [selectedBankProvinceList, setSelectedBankProvinceList] = useState(
        []
    );
    const [branch, setBranch] = useState(null);
    const [province, setProvince] = useState(null);
    const [ownerName, setOwnerName] = useState("");
    const [isOwnerNameDisabled, setIsOwnerNameDisabled] = useState(false);

    const [checkingFastTransfer, setCheckingFastTransfer] = useState(false);
    const [isFastTransferAvailable, setIsFastTransferAvailable] = useState(
        false
    );

    // set this state to anything to call function getTargetAccount
    const [isAutoFilled, setIsAutoFilled] = useState(false);
    const [isAutoFilledTargetChecked, setIsAutoFilledTargetChecked] = useState(
        false
    );

    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);

    const checkFastTransferStatus = useCallback(async (bankId) => {
        setCheckingFastTransfer(true);
        const response = await moveMoneyService.checkFastTransferStatus({
            bankId,
        });

        if (!unmounted.current) {
            setCheckingFastTransfer(false);
            const {
                code,
                data: { fastTransferSupported },
            } = response.data;
            if (code === RESPONSE_CODE.SUCCESS) {
                return fastTransferSupported;
            }
            return false;
        }
    }, []);

    const getTargetAccount = useCallback(async () => {
        if (
            !spendAccount ||
            !selectedBank ||
            !targetInfo ||
            fetchingTargetAccount
        ) {
            return;
        }

        setErrors((prev) => ({
            ...prev,
            targetInfo: null,
            ownerName: null,
        }));
        setDestination({
            account: {
                accountNumber: targetInfo,
                bankName: selectedBank.bankName,
                bankId: selectedBank.bankId,
            },
        });

        if (!isFastTransferAvailable) {
            return;
        }

        setIsOwnerNameDisabled(true);
        setFetchingTargetAccount(true);

        const payload = {
            bankId: selectedBank.bankId,
            targetInfo,
            bankAccount: spendAccount.no,
        };

        const response = await moveMoneyService.getAccountDetailByAccountNumber(
            payload
        );

        if (!unmounted.current) {
            setFetchingTargetAccount(false);

            const { code, data } = response.data;

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    if (data.bank.accountNumber === spendAccount.no) {
                        setDestination(null);
                        setErrors((prev) => ({
                            ...prev,
                            targetInfo: t(
                                "payee_card_number_invalid_payee_is_payer"
                            ),
                        }));
                    } else {
                        setIsOwnerNameDisabled(true);
                        setIsFastTransferAvailable(true);
                        setOwnerName(
                            data.account?.cardName ||
                                data.bank?.accountName ||
                                data.card?.accountName
                        );
                        setDestination({
                            account: {
                                accountNumber:
                                    data.bank?.accountNumber ||
                                    data.account?.cardNumber,
                                bankId:
                                    data.bank?.bankId || data.account?.bankId,
                                bankName:
                                    data.bank?.bankName ||
                                    data.account?.bankName,
                            },
                        });
                    }
                    break;
                case RESPONSE_CODE.WRONG_INFO:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            iconImage: "Warning",
                            header: "gb_lb_encountered_problem",
                            content: "txt_can_not_retrieve_bank_detail",
                            doubleButton: [
                                {
                                    label: t("lb_btn_enter_manually"),
                                    onClick: () => {
                                        GlobalDialogController.hide();
                                        setIsOwnerNameDisabled(false);
                                        setIsFastTransferAvailable(false);

                                        setNormalTransferMessage(
                                            t("spend_msg_using_normal_transfer")
                                        );
                                        // The bank you have chosen does not support Fast Transfer. Your funds will arrive in the next working day.
                                    },
                                },
                                {
                                    label: t("payment_request_btn_again"),
                                    onClick: () => {
                                        setDestination(null);
                                        setTargetInfo("");
                                        GlobalDialogController.hide();
                                    },
                                },
                            ],
                        },
                    });
                    break;
                default:
                    setIsOwnerNameDisabled(false);
                    setIsFastTransferAvailable(false);
                    setErrors((prev) => ({
                        ...prev,
                        targetInfo: t("payee_account_number_submitted_invalid"),
                    }));
                    break;
            }
        }
    }, [
        t,
        targetInfo,
        fetchingTargetAccount,
        selectedBank,
        isFastTransferAvailable,
        spendAccount,
    ]);

    useEffect(() => {
        if (isAutoFilled && !isAutoFilledTargetChecked) {
            setIsAutoFilledTargetChecked(true);
            getTargetAccount();
        }
    }, [
        getTargetAccount,
        isAutoFilled,
        isAutoFilledTargetChecked,
        isFastTransferAvailable,
    ]);

    const onBankChange = useCallback(
        async ({ value, autofill }) => {
            setNormalTransferMessage("");

            if (checkingFastTransfer) {
                return;
            }

            let isFastTransferAvailableFromServer = false;

            if (!!value && value.realTimeTransfer === "Y") {
                isFastTransferAvailableFromServer = await checkFastTransferStatus(
                    value.bankId
                );
            } else {
                isFastTransferAvailableFromServer = false;
            }
            if (unmounted.current) {
                return;
            }
            const filteredBranchList = value
                ? branchList.filter((b) => b.bankId === value.bankId)
                : [];

            const filteredProvinceIdList = uniqBy(
                filteredBranchList,
                "provinceId"
            ).map((p) => p.provinceId);

            const filteredProvinceList = filter(provinceList, (p) =>
                filteredProvinceIdList.includes(p.provinceId)
            );

            clearErrors();

            selectedBankBranchList.current = filteredBranchList;
            setIsFastTransferAvailable(isFastTransferAvailableFromServer);
            setSelectedBankProvinceList(filteredProvinceList);
            setSelectedBank(value);
            setProvince(
                autofill?.provinceId
                    ? provinceList.find(
                          (p) => p.provinceId === autofill.provinceId
                      )
                    : null
            );
            setBranch(
                autofill?.branchId
                    ? branchList.find((b) => b.branchId === autofill.branchId)
                    : null
            );
            setTargetInfo(
                autofill?.accountNumber ? autofill?.accountNumber : ""
            );
            setOwnerName(autofill?.accountName ? autofill?.accountName : "");
            setIsOwnerNameDisabled(isFastTransferAvailableFromServer);

            if (autofill) {
                setIsAutoFilled(true);
            }
        },
        [checkingFastTransfer, clearErrors, checkFastTransferStatus]
    );

    const onProvinceChange = useCallback((e, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            province: null,
            branch: null,
        }));
        setBranch(null);
        setProvince(value);
    }, []);

    const onBranchSelectionFocus = useCallback(() => {
        const filteredBranchList = province
            ? selectedBankBranchList.current.filter(
                  (b) => b.provinceId === province.provinceId
              )
            : [];
        setSelectedBankProvinceBranchList(filteredBranchList);
    }, [province]);

    const onBranchChange = useCallback((e, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            branch: null,
        }));
        setBranch(value);
    }, []);

    const specialCharacterAccountNumber = useMemo(() => {
        let special = `[^A-Za-z0-9${sysParam?.AllowAccountCitadSpecialCharacter.replace(
            "[",
            "\\["
        )
            .replace("]", "\\]")
            .replace("-", "\\-")}]`;
        special = new RegExp(special, "g");
        return special;
    }, [sysParam]);

    const onAccountNumberChange = useCallback(
        (e) => {
            if (errors.targetInfo) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    targetInfo: null,
                }));
            }
            setDestination(null);

            const value = e.target.value.replace(
                specialCharacterAccountNumber,
                ""
            );

            setTargetInfo(value);
        },
        [errors.targetInfo, specialCharacterAccountNumber]
    );

    const onSaveHandler = useCallback(() => {
        let isValidInfo = true;

        if (!selectedBank) {
            setErrors((prev) => ({
                ...prev,
                selectedBank: t("msg_we_need_this"),
            }));
            isValidInfo = false;
        }

        if (!targetInfo) {
            setErrors((prev) => ({
                ...prev,
                targetInfo: t("msg_we_need_this"),
            }));
            isValidInfo = false;
        }

        if (!ownerName) {
            setErrors((prev) => ({
                ...prev,
                ownerName: t("msg_we_need_this"),
            }));
            isValidInfo = false;
        }

        if (!isFastTransferAvailable) {
            if (!province) {
                setErrors((prev) => ({
                    ...prev,
                    province: t("msg_we_need_this"),
                }));
                isValidInfo = false;
            }
            if (!branch) {
                setErrors((prev) => ({
                    ...prev,
                    branch: t("msg_we_need_this"),
                }));
                isValidInfo = false;
            }
        } else {
            getTargetAccount();
        }

        if (!isValidInfo || !destination) {
            return;
        }

        const { accountNumber, bankId, bankName } = destination.account;
        const info = {
            accountNumber,
            bankId,
            bankName,
            accountName: ownerName,
        };

        if (branch) {
            info.branchId = branch.branchId;
            info.branchName = branch.branchName;
        }

        if (province) {
            info.provinceId = province.provinceId;
            info.provinceName = province.provinceName;
        }

        onSave(info);
    }, [
        onSave,
        branch,
        province,
        destination,
        ownerName,
        isFastTransferAvailable,
        selectedBank,
        t,
        targetInfo,
        getTargetAccount,
    ]);

    return (
        <form>
            <Autocomplete
                options={bankList}
                getOptionLabel={(option) => option?.bankName || ""}
                renderOption={(option) => (
                    <>
                        {!option?.isRemove && (
                            <>
                                <Icon
                                    className={clsx(
                                        "font-icon icon-fasttransfer",
                                        classes.fastTransferIcon,
                                        {
                                            visible:
                                                option.realTimeTransfer === "Y",
                                        }
                                    )}
                                />
                                {option.bankName}
                            </>
                        )}
                    </>
                )}
                disabled={checkingFastTransfer}
                value={selectedBank}
                onChange={(_, value) => onBankChange({ value })}
                renderInput={(params) => {
                    const inputProps = checkingFastTransfer
                        ? {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <CircularProgress size={16} />
                                  </InputAdornment>
                              ),
                          }
                        : params.InputProps;
                    return (
                        <LspTextField
                            {...params}
                            label={t("payee_bank_name_label")}
                            error={!!errors.selectedBank}
                            helperText={
                                errors.selectedBank ||
                                normalTransferMessage ||
                                " "
                            }
                            helperTextColor={normalTransferMessage ? "red" : ""}
                            InputProps={inputProps}
                        />
                    );
                }}
            />

            <LspTextField
                label={t("lb_account_number")}
                error={!!errors.targetInfo}
                helperText={errors.targetInfo || " "}
                disabled={!selectedBank}
                onBlur={getTargetAccount}
                onChange={onAccountNumberChange}
                value={targetInfo}
                InputProps={{
                    endAdornment: fetchingTargetAccount ? (
                        <InputAdornment position="end">
                            <CircularProgress size={16} />
                        </InputAdornment>
                    ) : null,
                }}
                inputProps={{
                    maxLength: 32,
                }}
            />

            {selectedBank &&
                !isFastTransferAvailable &&
                selectedBank?.bankId !==
                    BANK_INFO.DEFAULT_BANK_2_ID.toString() && (
                    <>
                        <Autocomplete
                            options={selectedBankProvinceList}
                            getOptionLabel={(option) =>
                                option?.provinceName || ""
                            }
                            value={province}
                            onChange={onProvinceChange}
                            renderInput={(params) => (
                                <LspTextField
                                    {...params}
                                    label={t("payee_bank_province_label")}
                                    error={!!errors.province}
                                    helperText={errors.province || " "}
                                />
                            )}
                        />

                        <Autocomplete
                            options={selectedBankProvinceBranchList}
                            getOptionLabel={(option) =>
                                option?.branchName || ""
                            }
                            value={branch}
                            onChange={onBranchChange}
                            onFocus={onBranchSelectionFocus}
                            renderInput={(params) => (
                                <LspTextField
                                    {...params}
                                    label={t("payee_bank_branch_label")}
                                    error={!!errors.branch}
                                    helperText={errors.branch || " "}
                                />
                            )}
                        />
                    </>
                )}
            <LspTextField
                label={t("payee_account_owner_name_label")}
                error={!!errors.ownerName}
                disabled={isOwnerNameDisabled}
                helperText={errors.ownerName || " "}
                onChange={(e) => {
                    setErrors((prev) => ({
                        ...prev,
                        ownerName: null,
                    }));
                    setOwnerName(escapeAccentVietnamese(e.target.value));
                }}
                value={ownerName}
            />
            <DoubleButtons
                primaryButton={{
                    label: t("payee_btn_add_destination"),
                    onClick: (e) => {
                        e.preventDefault();
                        onSaveHandler();
                    },
                }}
                secondaryButton={{
                    label: t("lb_cancel"),
                    onClick: onCancel,
                }}
                disabled={loading}
                progressing={loading}
            />
        </form>
    );
};

const stateProps = (state) => ({
    sysParam: state.systemParams?.info?.sysparams["1.0"],
    bankList: state.bank.bankList.data,
});

export default connect(stateProps)(AddBankAccountDestination);
