import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import {
    Box,
    Icon,
    IconButton,
    InputAdornment,
    Paper,
    makeStyles,
} from "@material-ui/core";
import Content from "@components/Content";

import PageHeaderBack from "@components/PageHeaderBack";
import LspTypography from "@components/LspTypography";

import LspTextField from "@components/LspTextField";
import { EditIcon } from "@components/LspIcon";
import { memo, useState, useEffect, useMemo, useCallback } from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom";
import LspButton from "@components/LspButton";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import useNumber from "@helpers/useNumber";
import { connect } from "react-redux";
import CardManagementActions from "@redux/actions/card-management";
import { isEmpty } from "lodash";
import { FetchStatus } from "@config/constants";
import Interweave from "interweave";
import tommySuccess from "@assets/images/tommy_success.png";

import ConditionNotificationDialog from "@components/NotificationPopover/ConditionNotificationDialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { CARD_TYPES, CARD_MANAGEMENT_SUB_NAV } from "./constant";

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: theme.spacing(4),
    },
    headerIcon: {
        marginBottom: theme.spacing(1),
        "& .font-icon": {
            fontSize: theme.typography.pxToRem(32),
        },
    },
    headerText: {
        color: theme.palette.primary.main,
        fontSize: 20,
        lineHeight: "27px",
        fontWeight: 400,
    },
    body: {
        marginBottom: theme.spacing(4),
    },
    footer: {
        textAlign: "center",
    },
    footerText: {
        marginBottom: theme.spacing(2),
    },
}));

const CARD_LIMIT_TYPES = {
    POS_PAY: "POS",
    ATM_CASH: "ATM",
    E_COM_PAY: "ECOM",
};

const CardLimit = ({
    userId,
    cardLimit,
    cardLimitStatus,
    getCardLimit,
    adjustCardLimit,
    modifyCardLimitStatus,
    modifyCardLimitFetch,
    cardLimitFetch,
    resetCardLimit,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const {
        thousandSeparator,
        decimalSeparator,
        formatNumber,
        parseNumber,
    } = useNumber();

    const [ATM, setATMCash] = useState(false);
    const [POS, setPosPayment] = useState(false);
    const [ECOM, setOnlinePayment] = useState(false);
    const [isModified, setModified] = useState(false);

    const [errors, setErrors] = useState({});
    const [cardLimitData, setCardLimitData] = useState({});
    const [cardLimitDefault, setCardLimitDefaultData] = useState({});

    const [disableEdit, setDisableEdit] = useState(true);

    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();
    const cardType = useMemo(() => query.get("cardType"), [query]);
    const cardId = useMemo(() => query.get("cardId"), [query]);
    const type = useMemo(() => query.get("type"), [query]);

    useEffect(() => {
        if (isEmpty(cardLimit) && cardLimitStatus !== FetchStatus.Pending) {
            getCardLimit({ userId, cardId });
        }
    }, [
        cardId,
        cardLimit,
        cardLimitStatus,
        getCardLimit,
        modifyCardLimitStatus,
        query,
        userId,
    ]);

    const openDialog = useCallback((content) => {
        GlobalDialogController.show({
            component: () => (
                <ConditionNotificationDialog content={content} centerImg />
            ),
        });
    }, []);

    const closeDialog = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    useEffect(() => {
        if (modifyCardLimitStatus === FetchStatus.Success) {
            setModified(false);
            const contents = {
                banner: tommySuccess,
                title: t("master:manage_card_set_limit_success_title"),
                description: t("master:manage_card_set_limit_success_desc"),
                buttons: [
                    {
                        variant: "primary",
                        label: t("master:manage_card_set_limit_success_CTA"),
                        onClick: () => closeDialog(),
                    },
                ],
            };
            openDialog(contents);
            setDisableEdit(false);
            getCardLimit({ userId, cardId });
        }

        if (modifyCardLimitStatus === FetchStatus.Error) {
            getCardLimit({ userId, cardId });
            setDisableEdit(false);
        }
    }, [
        cardId,
        closeDialog,
        getCardLimit,
        modifyCardLimitStatus,
        openDialog,
        t,
        userId,
    ]);

    const modifyCardLimitData = (amount, key) => {
        if (
            amount === cardLimitDefault[key].cardLimit &&
            cardLimitDefault[key].cardLimitUpdate < amount
        ) {
            setModified(true);
        } else {
            setModified(amount !== cardLimitDefault[key].cardLimit);
        }
    };

    const handleCardLimitInit = useCallback((cardMethod, data) => {
        switch (cardMethod) {
            case CARD_LIMIT_TYPES.ATM_CASH:
                setCardLimitDefaultData((pre) => ({
                    ...pre,
                    ATM: data,
                }));
                setCardLimitData((pre) => ({
                    ...pre,
                    ATM:
                        data?.cardLimitUpdate != null
                            ? data?.cardLimitUpdate
                            : data?.cardLimit,
                }));
                break;
            case CARD_LIMIT_TYPES.POS_PAY:
                setCardLimitDefaultData((pre) => ({
                    ...pre,
                    POS: data,
                }));
                setCardLimitData((pre) => ({
                    ...pre,
                    POS:
                        data?.cardLimitUpdate != null
                            ? data?.cardLimitUpdate
                            : data?.cardLimit,
                }));
                break;
            case CARD_LIMIT_TYPES.E_COM_PAY:
                setCardLimitDefaultData((pre) => ({
                    ...pre,
                    ECOM: data,
                }));
                setCardLimitData((pre) => ({
                    ...pre,
                    ECOM:
                        data?.cardLimitUpdate != null
                            ? data?.cardLimitUpdate
                            : data?.cardLimit,
                }));
                break;

            default:
                break;
        }
    }, []);

    const handleCardData = useCallback(
        (cardList) => {
            // for type atm
            const isExistATMMethod = cardList.find(
                (item) => item.cardKey === CARD_LIMIT_TYPES.ATM_CASH
            );
            if (isExistATMMethod) {
                handleCardLimitInit(
                    CARD_LIMIT_TYPES.ATM_CASH,
                    isExistATMMethod
                );
            }

            // for type atm
            const isExistPOSMethod = cardList.find(
                (item) => item.cardKey === CARD_LIMIT_TYPES.POS_PAY
            );
            if (isExistPOSMethod) {
                handleCardLimitInit(CARD_LIMIT_TYPES.POS_PAY, isExistPOSMethod);
            }

            // for type ecom
            const isExistECOMEMethod = cardList.find(
                (item) => item.cardKey === CARD_LIMIT_TYPES.E_COM_PAY
            );
            if (isExistPOSMethod) {
                handleCardLimitInit(
                    CARD_LIMIT_TYPES.E_COM_PAY,
                    isExistECOMEMethod
                );
            }
        },
        [handleCardLimitInit]
    );

    useEffect(() => {
        if (cardLimit) {
            setDisableEdit(false);
            if (
                cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
            ) {
                const defaultData = cardLimit.find(
                    (item) =>
                        item.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                        item.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
                );
                if (defaultData) {
                    setCardLimitDefaultData((pre) => ({
                        ...pre,
                        ECOM: defaultData,
                    }));
                    setCardLimitData((pre) => ({
                        ...pre,
                        ECOM:
                            defaultData?.cardLimitUpdate != null
                                ? defaultData?.cardLimitUpdate
                                : defaultData?.cardLimit,
                    }));
                }
                return;
            }

            if (type === CARD_MANAGEMENT_SUB_NAV.CREDIT) {
                const visaCardList = cardLimit.filter(
                    (item) => item.cardType === CARD_TYPES.VISA_CARD
                );
                handleCardData(visaCardList);
                return;
            }

            if (type === CARD_MANAGEMENT_SUB_NAV.DEBIT) {
                const visaCardList = cardLimit.filter(
                    (item) => item.cardType === CARD_TYPES.DEBIT_CARD
                );
                handleCardData(visaCardList);
            }
        }
    }, [cardLimit, cardType, handleCardData, type]);

    useEffect(() => {
        switch (type) {
            case "credit":
                setATMCash(true);
                setPosPayment(true);
                setOnlinePayment(true);
                break;
            case "debit":
                if (
                    cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD ||
                    cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP
                ) {
                    setOnlinePayment(true);
                    break;
                }
                setATMCash(true);
                setPosPayment(true);
                setOnlinePayment(true);
                break;

            default:
                break;
        }
    }, [cardType, query, type]);

    const onBack = () => {
        history.goBack();
    };

    const onCardLimitChange = (data, typeData) => {
        const { value } = data?.target;
        const amount = parseNumber(value);
        if (ATM && typeData === CARD_LIMIT_TYPES.ATM_CASH) {
            setCardLimitData((prev) => ({
                ...prev,
                ATM: amount,
            }));
            setErrors((pre) => ({
                ...pre,
                ATM: amount > cardLimitDefault?.ATM?.cardLimit,
            }));
            modifyCardLimitData(amount, CARD_LIMIT_TYPES.ATM_CASH);
        }

        if (POS && typeData === CARD_LIMIT_TYPES.POS_PAY) {
            setCardLimitData((prev) => ({
                ...prev,
                POS: amount,
            }));
            setErrors((pre) => ({
                ...pre,
                POS: amount > cardLimitDefault?.POS?.cardLimit,
            }));
            modifyCardLimitData(amount, CARD_LIMIT_TYPES.POS_PAY);
        }

        if (ECOM && typeData === CARD_LIMIT_TYPES.E_COM_PAY) {
            setErrors((pre) => ({
                ...pre,
                ECOM: amount > cardLimitDefault?.ECOM?.cardLimit,
            }));
            setCardLimitData((prev) => ({
                ...prev,
                ECOM: amount,
            }));

            modifyCardLimitData(amount, CARD_LIMIT_TYPES.E_COM_PAY);
        }
    };

    const isAvailableSubmit = (details, amount) => {
        if (
            details &&
            details?.cardLimitUpdate == null &&
            amount !== details?.cardLimitUpdate
        ) {
            return true;
        }
        return (
            details &&
            details?.cardLimitUpdate != null &&
            details?.cardLimitUpdate !== amount
        );
    };

    const isAvailableApply = useMemo(() => {
        return Object.values(errors)?.every((item) => !item);
    }, [errors]);

    const onSubmitHandler = () => {
        if (isAvailableApply) {
            setDisableEdit(true);
            const paramsData = {
                userId,
                typeListCards: [],
            };

            if (
                ECOM &&
                isAvailableSubmit(cardLimitDefault?.ECOM, cardLimitData?.ECOM)
            ) {
                const eComData = {
                    cardCode: cardLimitDefault?.ECOM?.cardCode,
                    cardLimit: cardLimitData?.ECOM,
                    cardId,
                };
                paramsData.typeListCards.push(eComData);
            }

            if (
                POS &&
                isAvailableSubmit(cardLimitDefault?.POS, cardLimitData?.POS)
            ) {
                const POSData = {
                    cardCode: cardLimitDefault?.POS?.cardCode,
                    cardLimit: cardLimitData?.POS,
                    cardId,
                };
                paramsData.typeListCards.push(POSData);
            }

            if (
                ATM &&
                isAvailableSubmit(cardLimitDefault?.ATM, cardLimitData?.ATM)
            ) {
                const ATMData = {
                    cardCode: cardLimitDefault?.ATM?.cardCode,
                    cardLimit: cardLimitData?.ATM,
                    cardId,
                };
                paramsData.typeListCards.push(ATMData);
            }

            adjustCardLimit(paramsData);
        }
    };

    useEffect(() => {
        return () => {
            resetCardLimit();
        };
    }, [resetCardLimit]);
    return (
        <Content size="sm">
            <Paper>
                <Box p={3}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmitHandler();
                        }}
                    >
                        <div className={classes.header}>
                            <PageHeaderBack onBack={onBack}>
                                <div className={classes.headerIcon}>
                                    <Icon className="font-icon icon-Debitcard" />
                                </div>
                                <LspTypography className={classes.headerText}>
                                    {t("master:manage_card_set_limit_header")}
                                </LspTypography>
                            </PageHeaderBack>
                        </div>
                        <div className={classes.body}>
                            {ATM && (
                                <NumberFormat
                                    disabled={disableEdit}
                                    name="ATM"
                                    label={t(
                                        "master:manage_card_set_limit_ATM"
                                    )}
                                    helperText={
                                        errors?.ATM
                                            ? t(
                                                  "master:manage_card_set_limit_error"
                                              )
                                            : `${t(
                                                  "master:manage_card_set_limit_max_day"
                                              )} <b>${formatNumber(
                                                  cardLimitDefault?.ATM
                                                      ?.cardLimit
                                              )} vnd</b>`
                                    }
                                    error={errors?.ATM}
                                    value={cardLimitData?.ATM}
                                    onChange={(data) =>
                                        onCardLimitChange(
                                            data,
                                            CARD_LIMIT_TYPES.ATM_CASH
                                        )
                                    }
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    customInput={LspTextField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}

                            {POS && (
                                <NumberFormat
                                    disabled={disableEdit}
                                    name="POS"
                                    label={t(
                                        "master:manage_card_set_limit_POS"
                                    )}
                                    helperText={
                                        errors?.POS
                                            ? t(
                                                  "master:manage_card_set_limit_error"
                                              )
                                            : `${t(
                                                  "master:manage_card_set_limit_max_day"
                                              )}  <b>${formatNumber(
                                                  cardLimitDefault?.POS
                                                      ?.cardLimit
                                              )} vnd </b>`
                                    }
                                    error={errors?.POS}
                                    value={cardLimitData?.POS}
                                    onChange={(data) =>
                                        onCardLimitChange(
                                            data,
                                            CARD_LIMIT_TYPES.POS_PAY
                                        )
                                    }
                                    customInput={LspTextField}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            {ECOM && (
                                <NumberFormat
                                    disabled={disableEdit}
                                    name="ECOM"
                                    label={t(
                                        "master:manage_card_set_limit_online_payment"
                                    )}
                                    helperText={
                                        errors?.ECOM
                                            ? t(
                                                  "master:manage_card_set_limit_error"
                                              )
                                            : `${t(
                                                  "master:manage_card_set_limit_max_day"
                                              )} <b>${formatNumber(
                                                  cardLimitDefault?.ECOM
                                                      ?.cardLimit
                                              )} vnd</b>`
                                    }
                                    value={cardLimitData?.ECOM}
                                    onChange={(data) =>
                                        onCardLimitChange(
                                            data,
                                            CARD_LIMIT_TYPES.E_COM_PAY
                                        )
                                    }
                                    error={errors?.ECOM}
                                    allowNegative={false}
                                    customInput={LspTextField}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    disabled
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </div>
                        <div className={classes.footer}>
                            <LspTypography
                                color="grey"
                                variant="body3"
                                className={classes.footerText}
                            >
                                <Interweave
                                    content={t(
                                        "master:manage_card_set_limit_confirm"
                                    )}
                                />
                            </LspTypography>
                            <LspButton
                                disabled={!isModified || !isAvailableApply}
                                type="submit"
                                fullWidth
                                variant="primary"
                                progressing={
                                    disableEdit ||
                                    modifyCardLimitFetch ||
                                    cardLimitFetch
                                }
                            >
                                {t("master:manage_card_set_limit_CTA_apply")}
                            </LspButton>
                        </div>
                    </form>
                </Box>
            </Paper>
        </Content>
    );
};

CardLimit.propTypes = {
    cardLimitFetch: PropTypes.bool,
    modifyCardLimitFetch: PropTypes.bool,
};

CardLimit.defaultProps = {
    cardLimitFetch: false,
    modifyCardLimitFetch: false,
};

const stateProps = (state) => ({
    cardLimit: state.cardInfo.cardLimitRequest.data,
    cardLimitStatus: state.cardInfo.cardLimitRequest.status,
    modifyCardLimitStatus: state.cardInfo.cardLimit.status,
    modifyCardLimitFetch: state.cardInfo.cardLimit.fetching,
    cardLimitFetch: state.cardInfo.cardLimitRequest.fetching,
    userId: state.user.info?.userId,
});

const dispatchProps = (dispatch) => ({
    getCardLimit: (payload) =>
        dispatch(CardManagementActions.getCardLimitRequest(payload)),
    adjustCardLimit: (payload) =>
        dispatch(CardManagementActions.cardLimitRequest(payload)),
    resetCardLimit: () => dispatch(CardManagementActions.resetCardLimit()),
});

export default connect(stateProps, dispatchProps)(memo(CardLimit));
