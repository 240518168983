/* eslint-disable react/no-array-index-key */
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RequestDocumentKey, RESPONSE_CODE } from "@config/constants";
import useNumber from "@helpers/useNumber";
import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, makeStyles, Radio } from "@material-ui/core";
import requestDocumentService from "@services/request-document";
import LspButton from "@components/LspButton";
import Loading from "@components/Loading";
import useAccounts from "@helpers/useAccounts";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import RequestDocumentActions from "@redux/actions/request-document";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    document: {
        position: "relative",
        padding: theme.spacing(1.5, 0, 1.25, 0),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderWidth: "1px 0 0 0",
        "&:last-child": {
            borderWidth: "1px 0 1px 0",
        },
        "& > p > span": {
            marginLeft: theme.spacing(1),
            fontStyle: "italic",
        },
        "& > p": {
            [theme.breakpoints.down("sm")]: {
                flex: 1,
            },
        },
    },
    hint: {
        background: theme.palette.background.header,
        padding: theme.spacing(2, 1.5),
    },
    requestBtn: {
        position: "absolute",
        right: "-100px",
        display: "inline-block",
        [theme.breakpoints.down("sm")]: {
            position: "unset",
            right: "0",
        },
        "& > button > span > p": {
            textTransform: "lowercase",
            "&::first-letter": {
                textTransform: "uppercase",
            },
        },
        "& > button": {
            padding: theme.spacing(1, 2),
        },
    },
}));

const DocumentList = ({ setDocumentInfo, resetDocumentInfo }) => {
    const { t, i18n } = useTranslation();
    const [documentList, setDocumentList] = useState([]);
    const [requestIdSelected, setRequestIdSelected] = useState("");
    const [fetching, setFetching] = useState(true);
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const { spendAccount } = useAccounts();
    const history = useHistory();

    const getDocumentList = useCallback(async () => {
        const { data } = await requestDocumentService.getDocumentList(
            i18n.language
        );
        setFetching(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setDocumentList(data.data.available);
                break;

            default:
                break;
        }
    }, [i18n]);

    const onCheckBox = (item) => {
        setRequestIdSelected(item.requestId);
    };

    const onClickRequest = (item) => {
        resetDocumentInfo();
        if (item.amount > 0) {
            if (spendAccount.availableAmount < item.amount) {
                const content = `${t("spend_msg_for_least_amount").replace(
                    "%@",
                    formatNumber(item.amount)
                )}<br><br><i>${t("rq_document_please_contribute")}</i>`;
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "moneyerror",
                        header: "global_title_insuficient_funds",
                        content,
                        button: t("lb_ok"),
                    },
                });
                return;
            }
        }

        const data = {
            desc: item.content,
            feeCharge: item.amount,
            requestId: item.requestId,
        };
        setDocumentInfo(data);

        history.push(RequestDocumentKey.documentInfo);
    };

    useEffect(() => {
        getDocumentList();
    }, [getDocumentList]);
    return (
        <Content>
            {fetching && <Loading />}
            {!fetching && (
                <>
                    <Box textAlign="center">
                        <LspTypography variant="subheading1" color="grey">
                            {t("request_document_main_page_title")}
                        </LspTypography>
                    </Box>
                    <Box paddingY={3.75}>
                        {documentList.map((item, key) => (
                            <div className={classes.document} key={key}>
                                <LspTypography variant="body2" color="grey">
                                    {item.content}
                                    <LspTypography
                                        variant="body2"
                                        color="error"
                                        component="span"
                                    >
                                        {`(${formatNumber(item.amount)} VND)`}
                                    </LspTypography>
                                </LspTypography>

                                <Radio
                                    color="primary"
                                    checked={
                                        item.requestId === requestIdSelected
                                    }
                                    onChange={() => onCheckBox(item)}
                                />

                                {item.requestId === requestIdSelected && (
                                    <LspButton
                                        onClick={() => onClickRequest(item)}
                                        className={classes.requestBtn}
                                        size="small"
                                    >
                                        {t("rq_document_request_btn")}
                                    </LspButton>
                                )}
                            </div>
                        ))}
                    </Box>
                    <LspTypography
                        variant="body2"
                        color="grey"
                        className={classes.hint}
                    >
                        {t("rq_document_request_receive_time")}
                    </LspTypography>
                </>
            )}
        </Content>
    );
};

const mapState = (state) => ({
    paymentStatus: state.paymentRequest.status,
});

const mapDispatch = (dispatch) => ({
    setDocumentInfo: (payload) =>
        dispatch(RequestDocumentActions.setDocumentInfo(payload)),
    resetDocumentInfo: () =>
        dispatch(RequestDocumentActions.resetDocumentInfo()),
});

export default memo(connect(mapState, mapDispatch)(DocumentList));
