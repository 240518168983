import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles } from "@material-ui/core";
import LspButton from "@components/LspButton";
import Interweave from "interweave";
import LspCheckbox from "@components/LspCheckbox";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";

const useStyles = makeStyles((theme) => ({
    card: {
        border: `1px solid ${theme.palette.neutral.grey3}`,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(4),
    },
    italic: {
        fontStyle: "italic",
    },
}));

const CardItem = ({
    item,
    trackUserGetVoucher,
    sendVoucherToEmail,
    loadingVoucherId,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [confirmTerm, setConfirmTerm] = useState(false);
    const linkTerm = useMemo(() => {
        const toTemplate = t(`wealth:OV00052`, {
            returnObjects: true,
            defaultValue: "",
        });
        const tag = `<a target="_blank" href="${toTemplate[1]}">${toTemplate[0]}</a>`;
        return t("wealth:OV00051").replace("%@", tag);
    }, [t]);

    return (
        <div className={classes.card}>
            <div
                style={{
                    backgroundImage: `url(${item.campaignCover})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "130px",
                }}
            />
            <Box p={2}>
                {item.sendEmail === 0 && (
                    <>
                        <Box display="flex" alignItems="flex-start">
                            <LspCheckbox
                                onChange={() => setConfirmTerm((prev) => !prev)}
                                checked={confirmTerm}
                            />
                            <LspTypography
                                variant="body1"
                                color="grey"
                                component="i"
                            >
                                <Interweave content={linkTerm} />
                            </LspTypography>
                        </Box>

                        <LspButton
                            onClick={() => sendVoucherToEmail(item.voucherId)}
                            disabled={!confirmTerm}
                            progressing={loadingVoucherId === item.voucherId}
                            fullWidth
                            mt={2}
                            buttonProps={{
                                endIcon: (
                                    <Icon className="font-icon icon-longarrowright" />
                                ),
                            }}
                        >
                            {t("wealth:OV00044")}
                        </LspButton>
                    </>
                )}

                {item.sendEmail !== 0 && (
                    <>
                        {item.status === "picked" && (
                            <>
                                <LspTypography variant="body1" color="black">
                                    {t("wealth:OV00048")}
                                    <LspTypography
                                        variant="heading2"
                                        color="black"
                                        component="span"
                                    >
                                        ***************
                                    </LspTypography>
                                </LspTypography>
                                <Box my={2}>
                                    <LspTypography
                                        variant="body1"
                                        color="black"
                                    >
                                        {t("wealth:OV00049")}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body1"
                                        color="blue"
                                        component="a"
                                        href={item.visitSite}
                                        target="_blank"
                                        className={classes.italic}
                                    >
                                        {item.visitSite}
                                    </LspTypography>
                                </Box>

                                <LspButton
                                    onClick={() =>
                                        trackUserGetVoucher(item.voucherId)
                                    }
                                    progressing={
                                        loadingVoucherId === item.voucherId
                                    }
                                    fullWidth
                                >
                                    {t("wealth:OV00046")}
                                </LspButton>
                            </>
                        )}

                        {item.status === "used" && (
                            <>
                                <LspTypography variant="body1" color="black">
                                    {t("wealth:OV00048")}
                                    <LspTypography
                                        variant="heading2"
                                        color="black"
                                        component="span"
                                    >
                                        {item.voucherCode}
                                    </LspTypography>
                                </LspTypography>
                                <Box my={2}>
                                    <LspTypography
                                        variant="body1"
                                        color="black"
                                    >
                                        {t("wealth:OV00049")}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body1"
                                        color="blue"
                                        component="a"
                                        href={item.visitSite}
                                        target="_blank"
                                        className={classes.italic}
                                    >
                                        {item.visitSite}
                                    </LspTypography>
                                </Box>
                                <LspButton
                                    onClick={() => {
                                        AlertController.show(
                                            t("if_btn_click_copy_clipboard"),
                                            AlertType.Success
                                        );
                                        navigator.clipboard.writeText(
                                            item.voucherCode
                                        );
                                    }}
                                    fullWidth
                                >
                                    {t(
                                        "profile_btn_copy_popover_referral_code"
                                    )}
                                </LspButton>
                            </>
                        )}
                    </>
                )}
            </Box>
        </div>
    );
};

export default CardItem;
