import api from "@config/api";

const retrieveUserName = async (payload) => {
    return await api.post("/login/username/retrieve", payload);
};

const retrieveUsernameService = {
    retrieveUserName,
};

export default retrieveUsernameService;
