/* ------------- Types and Action Definition ------------- */
export const GlobalDialogActionTypes = Object.freeze({
    Open: "GlobalDialogActionTypes/Open",
    Close: "GlobalDialogActionTypes/Close",
    SetLoading: "GlobalDialogActionTypes/SetLoading",
});

export const GlobalDialogTypes = Object.freeze({
    Component: "GlobalDialogTypes/Component",
    Custom: "GlobalDialogTypes/Custom",
    GeneralError: "GlobalDialogTypes/GeneralError",
    FinancialError: "GlobalDialogTypes/FinancialError",
});

/* ------------- Actions Definition ------------- */
const open = (payload) => {
    return {
        type: GlobalDialogActionTypes.Open,
        payload,
    };
};
const close = () => {
    return {
        type: GlobalDialogActionTypes.Close,
    };
};

const setLoading = (payload) => {
    return {
        type: GlobalDialogActionTypes.SetLoading,
        payload,
    };
};

const GlobalDialogActions = {
    open,
    close,
    setLoading,
};

export default GlobalDialogActions;
