export const QuickCodeActionTypes = Object.freeze({
    // Reset: "QuickCodeActionTypes/Reset",

    SetActiveStep: "QuickCodeActionTypes/SetActiveStep",
    SetActiveStepSuccess: "QuickCodeActionTypes/SetActiveStepSuccess",

    OpenQuickCodeDialog: "QuickCodeActionTypes/OpenQuickCodeDialog",
    CloseQuickCodeDialog: "QuickCodeActionTypes/CloseQuickCodeDialog",

    CreateNewQuickCode: "QuickCodeActionTypes/CreateNewQuickCode",
    CreateNewQuickCodeSuccess: "QuickCodeActionTypes/CreateNewQuickCodeSuccess",
    CreateNewQuickCodeError: "QuickCodeActionTypes/CreateNewQuickCodeError",

    CheckValidQuickCode: "QuickCodeActionTypes/CheckValidQuickCode",
    CheckValidQuickCodeSuccess:
        "QuickCodeActionTypes/CheckValidQuickCodeSuccess",
    CheckValidQuickCodeError: "QuickCodeActionTypes/CheckValidQuickCodeError",

    ForgotQuickCode: "QuickCodeActionTypes/ForgotQuickCode",
    ForgotQuickCodeSuccess: "QuickCodeActionTypes/ForgotQuickCodeSuccess",
    ForgotQuickCodeError: "QuickCodeActionTypes/ForgotQuickCodeError",

    CheckQuickCodeExistence: "QuickCodeActionTypes/CheckQuickCodeExistence",
    CheckQuickCodeExistenceSuccess:
        "QuickCodeActionTypes/CheckQuickCodeExistenceSuccess",
    CheckQuickCodeExistenceError:
        "QuickCodeActionTypes/CheckQuickCodeExistenceError",

    SetPassword: "QuickCodeActionTypes/SetPassword",
});

export const QuickCodeScreens = Object.freeze({
    INTRO: "INTRO",
    INPUT_NEW_QUICK_CODE: "INPUT_NEW_QUICK_CODE",
    CONFIRM_NEW_QUICK_CODE: "CONFIRM_NEW_QUICK_CODE",
    INPUT_PASSWORD: "INPUT_PASSWORD",
    INPUT_CURRENT_QUICK_CODE: "INPUT_CURRENT_QUICK_CODE",
    INPUT_NEW_QUICK_CODE_FOR_CHANGE: "INPUT_NEW_QUICK_CODE_FOR_CHANGE",
});

export const QuickCodeFlows = Object.freeze({
    CREATE: "CREATE",
    CHANGE: "CHANGE",
    VERIFY: "VERIFY",
});

// const reset = () => {
//     return {
//         type: QuickCodeActionTypes.Reset,
//     };
// };

const setActiveStep = (payload) => {
    return {
        type: QuickCodeActionTypes.SetActiveStep,
        payload,
    };
};

const setActiveStepSuccess = () => {
    return {
        type: QuickCodeActionTypes.SetActiveStepSuccess,
    };
};

const openQuickCodeDialog = (payload) => {
    return {
        type: QuickCodeActionTypes.OpenQuickCodeDialog,
        payload,
    };
};

const closeQuickCodeDialog = () => {
    return {
        type: QuickCodeActionTypes.CloseQuickCodeDialog,
    };
};

const createNewQuickCode = (payload) => {
    return {
        type: QuickCodeActionTypes.CreateNewQuickCode,
        payload,
    };
};

const createNewQuickCodeSuccess = () => {
    return {
        type: QuickCodeActionTypes.CreateNewQuickCodeSuccess,
    };
};

const createNewQuickCodeError = () => {
    return {
        type: QuickCodeActionTypes.CreateNewQuickCodeError,
    };
};

const checkValidQuickCode = (payload) => {
    return {
        type: QuickCodeActionTypes.CheckValidQuickCode,
        payload,
    };
};

const checkValidQuickCodeSuccess = () => {
    return {
        type: QuickCodeActionTypes.CheckValidQuickCodeSuccess,
    };
};

const checkValidQuickCodeError = () => {
    return {
        type: QuickCodeActionTypes.CheckValidQuickCodeError,
    };
};

const forgotQuickCode = () => {
    return {
        type: QuickCodeActionTypes.ForgotQuickCode,
    };
};

const forgotQuickCodeSuccess = () => {
    return {
        type: QuickCodeActionTypes.ForgotQuickCodeSuccess,
    };
};

const forgotQuickCodeError = () => {
    return {
        type: QuickCodeActionTypes.ForgotQuickCodeError,
    };
};

const checkQuickCodeExistenceRequest = () => {
    return {
        type: QuickCodeActionTypes.CheckQuickCodeExistence,
    };
};

const checkQuickCodeExistenceSuccess = (payload) => {
    return {
        type: QuickCodeActionTypes.CheckQuickCodeExistenceSuccess,
        payload,
    };
};

const checkQuickCodeExistenceError = () => {
    return {
        type: QuickCodeActionTypes.CheckQuickCodeExistenceError,
    };
};

const setPassword = (payload) => {
    return {
        type: QuickCodeActionTypes.SetPassword,
        payload,
    };
};

const quickCodeActions = {
    // reset,
    setActiveStep,
    setActiveStepSuccess,

    openQuickCodeDialog,
    closeQuickCodeDialog,

    createNewQuickCode,
    createNewQuickCodeSuccess,
    createNewQuickCodeError,

    checkValidQuickCode,
    checkValidQuickCodeSuccess,
    checkValidQuickCodeError,

    forgotQuickCode,
    forgotQuickCodeSuccess,
    forgotQuickCodeError,

    checkQuickCodeExistenceRequest,
    checkQuickCodeExistenceSuccess,
    checkQuickCodeExistenceError,

    setPassword,
};

export default quickCodeActions;
