import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { base, neutral } from "@config/theme/colors";
import { isFunction } from "lodash";
import { connect } from "react-redux";
import metricActions from "@redux/actions/metric";
import { v4 as uuidv4 } from "uuid";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
        },
        buttonWrapper: {
            position: "relative",
            display: "inline-flex",
        },
        fullWidth: {
            width: "100%",
        },
        buttonRoot: {
            transition: theme.transitions.create("opacity", {
                duration: theme.transitions.duration.short,
                easing: theme.transitions.easing.easeInOut,
            }),
            "&:hover": {
                boxShadow: theme.shadows[1],
            },
            "&:active": {
                opacity: theme.palette.opacity.active,
            },
        },
        label: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        primary: {
            boxShadow: theme.shadows[1],
            "&.disabled": {
                backgroundColor: theme.palette.primary.main,
                boxShadow: theme.shadows[1],
                opacity: theme.palette.opacity.disabled,
                "& .MuiButton-startIcon": {
                    color: theme.palette.primary.contrastText,
                    opacity: theme.palette.opacity.disabled,
                },
            },
        },
        success: {
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.success.main,
            "&.disabled": {
                backgroundColor: theme.palette.success.main,
                boxShadow: theme.shadows[1],
                opacity: theme.palette.opacity.disabled,
                "& .MuiButton-startIcon": {
                    color: theme.palette.success.contrastText,
                    opacity: theme.palette.opacity.disabled,
                },
            },
            "&:hover": {
                backgroundColor: theme.palette.success.dark,
            },
        },
        error: {
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.error.main,
            "&.disabled": {
                backgroundColor: theme.palette.error.main,
                boxShadow: theme.shadows[1],
                opacity: theme.palette.opacity.disabled,
                "& .MuiButton-startIcon": {
                    color: theme.palette.primary.contrastText,
                    opacity: theme.palette.opacity.disabled,
                },
            },
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            },
        },
        secondary: {
            "&.disabled": {
                backgroundColor: neutral.white,
                boxShadow: theme.shadows[1],
                "& .MuiButton-startIcon": {
                    // color: theme.palette.primary.main,
                    opacity: theme.palette.opacity.disabled,
                },
            },
            color: theme.palette.primary.main,
            boxShadow: theme.shadows[29],
        },
        tertiary: {
            borderStyle: "dashed",
            borderColor: theme.palette.magicalGrey[300],
            boxShadow: "none",
            "&:hover": {
                borderStyle: "dashed",
                borderColor: theme.palette.magicalGrey[300],
                backgroundColor: neutral.grey3,
                boxShadow: "none",
            },
            "&.disabled": {
                borderStyle: "dashed",
                borderColor: theme.palette.magicalGrey[300],
                backgroundColor: theme.palette.background.paper,
                boxShadow: "none",
            },
        },
        plain: {
            borderStyle: "none",
            boxShadow: "none",
            "&:hover": {
                backgroundColor: base.grey[200],
                borderStyle: "none",
            },
            "&.disabled": {
                opacity: 0.5,
                borderStyle: "none",
            },
        },
    };
});

const useCircularProps = (buttonSize) => {
    let size = 18;
    if (buttonSize === "large") {
        size = 20;
    } else if (buttonSize === "small") {
        size = 16;
    }
    const inlineStyles = {
        marginTop: -size / 2,
        marginLeft: -size / 2,
    };
    return [size, inlineStyles];
};

/**
 * @param {Object} props
 * @param {number} props.ml
 * @param {number} props.mr
 * @param {number} props.mt
 * @param {number} props.mb
 * @param {("submit"|"button")} props.type
 * @param {boolean} props.progressing
 * @param {boolean} props.disabled
 * @param {string} props.className
 * @param {object} [props.buttonProps]
 * @param {React.ReactNode} [props.buttonProps.startIcon]
 * @param {React.ReactNode} [props.buttonProps.endIcon]
 * @param {object} [props.buttonProps.classes]
 * @param {object} [props.circularProps]
 * @param {function} props.onClick
 * @param {boolean} props.fullWidth
 * @param {("large"|"small")} props.size
 * @param {("primary"|"secondary"|"tertiary"|"plain"|"success")} props.variant
 * @param {boolean} props.enableMetric
 * @param {Object} props.metricData
 * @param {boolean} props.forceRequest
 * @param {boolean} props.wrapText
 */

const LspButton = ({
    ml,
    mr,
    mt,
    mb,
    type,
    progressing,
    disabled,
    className,
    children,
    buttonProps,
    circularProps,
    onClick,
    fullWidth,
    size,
    variant,
    enableMetric,
    metricData,
    metricRequest,
    forceRequest,
    onMetricSubmit,
    wrapText,
}) => {
    const classes = useStyles();
    const [circularSize, circularInlineStyles] = useCircularProps(size);

    const muiColor = useMemo(() => {
        switch (variant) {
            case "secondary":
                return "default";
            case "plain":
            case "tertiary":
            case "primary":
            case "error":
            case "success":
            default:
                return "primary";
        }
    }, [variant]);

    const muiVariant = useMemo(() => {
        switch (variant) {
            case "plain":
            case "tertiary":
                return "outlined";
            case "secondary":
            case "success":
            case "primary":
            default:
                return "contained";
        }
    }, [variant]);

    const typoColor = useMemo(() => {
        switch (variant) {
            case "tertiary":
                return "black";
            case "plain":
            case "secondary":
                return "primary";
            case "primary":
            default:
                return "white";
        }
    }, [variant]);

    const typoVariant = useMemo(() => {
        switch (size) {
            case "small":
                return "button3";
            case "large":
            default:
                return "button1";
        }
    }, [size]);

    const handleOnClick = (event) => {
        if (enableMetric) {
            const time = new Date().getTime();
            const metric = {
                error_code: "",
                error_message: "",
                event_id: uuidv4() || "",
                flow_name: "",
                metric_location: "WEB",
                previous_event_id: "",
                previous_event_name: "",
                previous_screen_name: "",
                screen_name: "",
                metric_name: "",
                time_stamp_number: time || "",
                ...metricData,
            };
            const metricRequestData = { data: metric, force: forceRequest };

            metricRequest(metricRequestData);

            if (isFunction(onMetricSubmit)) {
                onMetricSubmit(metricRequestData);
            }
        }

        if (isFunction(onClick)) {
            onClick(event);
        }
    };

    return (
        <Box
            ml={ml}
            mr={mr}
            mb={mb}
            mt={mt}
            className={clsx(classes.buttonWrapper, {
                [classes.fullWidth]: fullWidth || !!buttonProps?.fullWidth,
                [className]: !!className,
            })}
        >
            <Button
                variant={muiVariant}
                {...buttonProps}
                disableRipple
                type={type}
                fullWidth={fullWidth || !!buttonProps?.fullWidth}
                disabled={progressing || disabled}
                onClick={(event) => handleOnClick(event)}
                color={muiColor}
                size={size}
                classes={{
                    contained: classes.buttonRoot,
                    ...buttonProps?.classes,
                }}
                className={clsx(buttonProps?.className, {
                    [classes.primary]: variant === "primary",
                    [classes.secondary]: variant === "secondary",
                    [classes.tertiary]: variant === "tertiary",
                    [classes.plain]: variant === "plain",
                    [classes.error]: variant === "error",
                    [classes.success]: variant === "success",
                    disabled: progressing || disabled,
                })}
            >
                <LspTypography
                    className={`${wrapText ? "" : classes.label}`}
                    variant={typoVariant}
                    color={typoColor}
                    disabled={progressing || disabled}
                >
                    {children}
                </LspTypography>
            </Button>
            {progressing && (
                <CircularProgress
                    {...circularProps}
                    className={clsx(classes.buttonProgress, {
                        [circularProps?.className]: !!circularProps?.className,
                    })}
                    style={circularInlineStyles}
                    size={circularSize}
                />
            )}
        </Box>
    );
};

LspButton.propTypes = {
    ml: PropTypes.number,
    mr: PropTypes.number,
    mt: PropTypes.number,
    mb: PropTypes.number,
    type: PropTypes.oneOf(["submit", "button"]),
    progressing: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(["large", "small"]),
    variant: PropTypes.oneOf([
        "primary",
        "secondary",
        "tertiary",
        "plain",
        "error",
        "success",
    ]),
    enableMetric: PropTypes.bool,
    forceRequest: PropTypes.bool,
    onMetricSubmit: PropTypes.func,
    wrapText: PropTypes.bool,
};

LspButton.defaultProps = {
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
    type: "button",
    progressing: false,
    disabled: false,
    className: "",
    onClick: undefined,
    fullWidth: false,
    size: "large",
    variant: "primary",
    enableMetric: false,
    forceRequest: false,
    onMetricSubmit: undefined,
    wrapText: false,
};

const dispatchProps = (dispatch) => ({
    metricRequest: (payload) =>
        dispatch(metricActions.metricRequestUpdate(payload)),
});

export default memo(connect(null, dispatchProps)(LspButton));
