import {
    Box,
    ButtonBase,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { memo, useCallback, useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MoveMoneyType, SpendAccountNavigationKey } from "@config/constants";
import clsx from "clsx";
import {
    BankIcon,
    BrandingIcon,
    CardIcon,
    CreditCardIcon,
} from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
    },
    button: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        borderRadius: theme.shape.radiusLevels[0],
        flex: 1,
        "& svg": {
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(1),
        },
    },
    disabled: {
        opacity: theme.palette.action.disabledOpacity,
    },
    description: {
        background: theme.palette.neutral.grey4,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        borderBottomLeftRadius: theme.shape.radiusLevels[0],
        borderBottomRightRadius: theme.shape.radiusLevels[0],
    },
}));

const MoveMoneyChannelSelect = ({
    onSelectFunc,
    isDisabledMember = false,
    title,
    description,
    hidePAO = false,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const channels = useMemo(() => {
        return [
            {
                id: MoveMoneyType.Internal,
                name: "move_money_lb_timo_member",
                icon: <BrandingIcon />,
                hide: false,
            },
            {
                id: MoveMoneyType.DebitCard,
                name: "move_money_lb_debit_card",
                icon: <CardIcon />,
                hide: false,
            },
            {
                id: MoveMoneyType.BankAccount,
                name: "move_money_lb_bank_account",
                icon: <BankIcon />,
                hide: false,
            },
            {
                id: MoveMoneyType.Credit,
                name: "move_money_lb_credit_card",
                icon: <CreditCardIcon />,
                hide: false,
            },
            // {
            //     id: MoveMoneyType.PayAnyone,
            //     name: "lixi:send_anyone",
            //     icon: "PAO-Phone-number",
            //     // hide: hidePAO,
            //     hide: true,
            // },
        ];
    }, []);

    const onSelect = useCallback(
        (channel) => {
            if (onSelectFunc) {
                onSelectFunc(channel);
            } else {
                history.push(
                    `/spend-account/${SpendAccountNavigationKey.MoveMoney}/${channel}`
                );
            }
        },
        [history, onSelectFunc]
    );

    return (
        <Paper className={classes.root}>
            <Box paddingX={2} paddingTop={2} paddingBottom={2}>
                <Typography variant="body1">
                    {title || t("payee_title_mke_transfer")}
                </Typography>
            </Box>
            {description && (
                <Box className={classes.description}>
                    <Typography variant="body2">{description}</Typography>
                </Box>
            )}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
                alignItems="flex-start"
                paddingBottom={1}
                paddingX={1}
            >
                {channels.map((ch) => (
                    <Fragment key={ch.id}>
                        {!ch?.hide && (
                            <ButtonBase
                                className={clsx(classes.button, {
                                    [classes.disabled]:
                                        isDisabledMember === "true" &&
                                        ch.id === MoveMoneyType.Internal,
                                })}
                                onClick={() => {
                                    onSelect(ch.id);
                                }}
                                disabled={
                                    isDisabledMember === "true" &&
                                    ch.id === MoveMoneyType.Internal
                                }
                                id={`button-${ch.id}`}
                            >
                                {/* <Icon className={`font-icon icon-${ch.icon}`} /> */}
                                {ch?.icon}
                                <Typography variant="body1">
                                    {t(ch.name)}
                                </Typography>
                            </ButtonBase>
                        )}
                    </Fragment>
                ))}
            </Box>
        </Paper>
    );
};

export default memo(MoveMoneyChannelSelect);
