import api from "../config/api";

const getTotalPendingTasks = async () => {
    return await api.get("user/tasks/count", {
        status: "pending",
    });
};

const getPendingTask = async (payload) => {
    let url = "user/tasks?status=pending";
    if (payload?.lastTaskId && payload?.modifiedAtIndex) {
        url += `&lastTaskId=${payload.lastTaskId}&modifiedAtIndex=${payload.modifiedAtIndex}`;
    }
    return await api.get(url);
};

const deletePendingTask = async ({ taskId }) => {
    return await api.delete(`user/tasks?taskId=${taskId}`);
};

const pendingTaskService = {
    getTotalPendingTasks,
    getPendingTask,
    deletePendingTask,
};

export default pendingTaskService;
