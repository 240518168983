import { Icon, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import topUpAction from "@redux/actions/top-up";
import { connect } from "react-redux";
import PageHeader from "@components/PageHeader";
import useNumber from "@helpers/useNumber";
import { useEffect } from "react";
import { FetchStatus } from "@config/constants";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import Content from "@components/Content";
import DoubleButtons from "@components/DoubleButtons";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.grey[500],
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(3),
        borderRadius: theme.shape.radiusLevels[1],
    },
    line: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    labelText: {
        minWidth: theme.typography.pxToRem(100),
    },
    valueText: {
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        textTransform: "capitalize",
    },
    wrapper: {
        width: "80%",
        margin: "auto",
    },
    btn: {
        marginTop: theme.spacing(2),
    },
}));

const TopUpReview = ({
    parentClasses,
    backPrevious,
    nextStep,
    submitting,
    phoneNumber,
    payeeName,
    amount,
    selectedPhoneObject,
    topUpRequest,
    status,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { thousandSeparator, decimalSeparator } = useNumber();

    const topUpExecute = () => {
        if (submitting) return;

        const indexPhoneNo = selectedPhoneObject?.indexPhoneNo || "";

        let fullName;

        if (selectedPhoneObject?.isMyPhoneNo || !payeeName) {
            // top up to my phone or top up to new phone without save payee
            fullName = "";
        } else if (payeeName) {
            // top up to new phone with save payee
            fullName = escapeAccentVietnamese(payeeName);
        } else {
            // top up to phone in contactList
            fullName = selectedPhoneObject?.fullName;
        }

        const dataToPost = {
            notification: "sms",
            target: {
                indexPhoneNo,
                phoneNo: phoneNumber,
                fullName,
                amount: parseInt(amount),
            },
        };

        topUpRequest(dataToPost);
    };

    useEffect(() => {
        if (status === FetchStatus.Success) {
            nextStep();
        }
    }, [status, nextStep]);

    const backStep = () => {
        if (submitting) return;
        backPrevious();
    };

    return (
        <>
            <PageHeader>
                <div className={parentClasses.headerWrapper}>
                    <div>{t("topup_lb_title_ready_topup")} </div>
                    <div className={parentClasses.iconWrapper}>
                        <Icon
                            className={`font-icon icon-phone2 ${parentClasses.headerIcon}`}
                        />
                    </div>
                </div>
            </PageHeader>
            <Content size="sm">
                <div className={classes.body}>
                    <div className={classes.wrapper}>
                        <div className={classes.line}>
                            <div className={classes.labelText}>
                                {t("lb_top_up")}
                            </div>
                            <div className={classes.valueText}>
                                <Typography>
                                    <NumberFormat
                                        value={phoneNumber}
                                        displayType="text"
                                        format="### #######"
                                    />
                                </Typography>
                            </div>
                        </div>
                        {payeeName && (
                            <div className={classes.line}>
                                <div className={classes.labelText}>
                                    {t("payee_name_label")}
                                </div>
                                <div className={classes.valueText}>
                                    {escapeAccentVietnamese(payeeName)}
                                </div>
                            </div>
                        )}

                        <div className={classes.line}>
                            <div className={classes.labelText}>
                                {t("topup_lb_amount")}
                            </div>
                            <div className={classes.valueText}>
                                <Typography>
                                    <NumberFormat
                                        value={amount}
                                        displayType="text"
                                        thousandSeparator={thousandSeparator}
                                        decimalSeparator={decimalSeparator}
                                    />
                                    {` VND`}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.btn}>
                    <DoubleButtons
                        primaryButton={{
                            onClick: topUpExecute,
                            label: t("lb_submit"),
                        }}
                        secondaryButton={{
                            onClick: backStep,
                            label: t("lb_back"),
                        }}
                        progressing={submitting}
                    />
                </div>
            </Content>
        </>
    );
};

const mapState = (state) => ({
    payeeName: state.topUp.payeeName,
    phoneNumber: state.topUp.phoneNumber,
    amount: state.topUp.amount,
    selectedPhoneObject: state.topUp.selectedPhoneObject,
    submitting: state.topUp.submitting,
    receipt: state.topUp.info,
    status: state.topUp.status,
});

const dispatchProps = (dispatch) => ({
    topUpRequest: (payload) => dispatch(topUpAction.topUpRequest(payload)),
});

export default connect(mapState, dispatchProps)(TopUpReview);
