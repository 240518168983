import { Box, makeStyles } from "@material-ui/core";
import { memo, useState, useEffect } from "react";
import { isFunction } from "lodash";
import { connect } from "react-redux";
import lspReceiptActions from "@redux/actions/lsp-receipt";
import { FetchStatus } from "@config/constants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LspButton from "./LspButton";
import LspTextField from "./LspTextField";
import LspTypography from "./LspTypography";

const useStyles = makeStyles((theme) => {
    return {
        header: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",

            "& > div:nth-child(2)": {
                margin: theme.spacing(1, 0),
            },
        },
        headerTitle: {
            flex: 1,
            textAlign: "center",
        },
        headerAction: {
            position: "absolute",
            right: theme.spacing(2),
        },
    };
});
const LspNotes = ({
    onClose,
    onSuccess,
    submitNotes,
    refNo,
    note,
    status,
    reset,
    loading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [notes, setNotes] = useState(note || "");

    const handleClickCloseAddNote = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const handleSaveNotes = () => {
        if (isFunction(onSuccess)) {
            submitNotes({ refNo, note: notes });
        }
    };

    useEffect(() => {
        if (status === FetchStatus.Success) {
            if (isFunction(onSuccess)) {
                onSuccess();
            }
            reset();
        }
    }, [onSuccess, reset, status]);

    return (
        <>
            <Box p={3} pb={4} className={classes.header}>
                <LspTypography
                    className={classes.headerTitle}
                    component="div"
                    variant="subheading1"
                >
                    {t("txnapptype:receipt_btn_add_note")}
                </LspTypography>
                <LspButton
                    className={classes.headerAction}
                    type="button"
                    onClick={() => handleClickCloseAddNote()}
                    variant="plain"
                    size="small"
                >
                    {t("lb_close")}
                </LspButton>
            </Box>
            <Box pt={0} p={3} pb={8}>
                <LspTextField
                    autoFocus
                    label={t("master:receipt_add_note_placeholder")}
                    disableHelperText
                    onChange={(e) => {
                        setNotes(e.target.value);
                    }}
                    value={notes}
                    multiline
                    rows={3}
                    enableCountValue
                    inputProps={{
                        maxLength: 128,
                    }}
                />
            </Box>
            <Box p={3} pt={0}>
                <LspButton
                    fullWidth
                    type="button"
                    onClick={() => handleSaveNotes()}
                    variant="primary"
                    progressing={loading}
                >
                    {t("manage_device_text_save")}
                </LspButton>
            </Box>
        </>
    );
};

LspNotes.propTypes = {
    status: PropTypes.string,
    loading: PropTypes.bool,
    refNo: PropTypes.string,
    note: PropTypes.string,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
};

LspNotes.defaultProps = {
    status: null,
    loading: false,
    refNo: "",
    note: "",
    onSuccess: null,
    onClose: null,
};

const mapStateToProps = (state) => ({
    status: state.receipt.notes.status,
    loading: state.receipt.notes.fetching,
});

const mapDispatchToProps = (dispatch) => ({
    submitNotes: (payload) => dispatch(lspReceiptActions.submitNotes(payload)),
    reset: () => dispatch(lspReceiptActions.resetNote()),
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(LspNotes));
