import { call, put } from "redux-saga/effects";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import payeeService from "@services/payee";
import payeeAction from "../actions/payee";

const BaseResponseCode = {
    Success: 200,
    Unauthorized: 401,
    InternalError: 500,
};

const ResponseCode = {
    ...BaseResponseCode,
    PayeeNotFound: 406,
};

export function* getPayeeDetailSaga(action) {
    const { payload } = action;
    const response = yield call(payeeService.getPayee, payload);
    const { code, data } = response.data;
    // if (response.ok) {
    switch (code) {
        case ResponseCode.Success:
            yield put(payeeAction.getPayeeDetailSuccess(data));
            break;
        case ResponseCode.PayeeNotFound:
            // payee not found
            yield put(payeeAction.getPayeeDetailError());
            break;
        default:
            yield put(payeeAction.getPayeeDetailError());
            GlobalDialogController.showError({ errorCode: code });
            break;
    }
    // } else {
    //     yield put(payeeAction.getPayeeDetailError());
    //     GlobalDialogController.showError({
    //         errorCode: response.status,
    //     });
    // }
}
