import { AccountType, CreditCardNavigationKey } from "@config/constants";
import useWealth from "@containers/Wealth/useWealth";
import {
    WEALTH_NAVIGATION_KEYS,
    WEALTH_PRODUCT_KEYS,
} from "@containers/Wealth/wealthConstants";
import useAccounts from "@helpers/useAccounts";
import {
    Box,
    ButtonBase,
    Icon,
    makeStyles,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        justifyContent: "flex-start",
        transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
        }),
        "&:hover": {
            backgroundColor:
                theme.palette.type === "light"
                    ? theme.palette.grey.A100
                    : theme.palette.grey[700],
        },
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(28),
        marginRight: theme.spacing(2),
    },
    title: {
        fontWeight: 600,
    },
    subtitle: {},
}));

const rootPath = WEALTH_NAVIGATION_KEYS.ROOT;

const WealthProductListItem = ({ productKey, accountList }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { getWealthIcon } = useWealth();
    const history = useHistory();
    const { allowAccessCredit } = useAccounts();
    const creditArrays = useMemo(
        () => [AccountType.CreditCardOnline, AccountType.CreditCard],
        []
    );

    const isExistCreditCard = useMemo(() => {
        const isCreditCard = (accountList || []).find((item) =>
            creditArrays.includes(item.accountType)
        );

        return isCreditCard && allowAccessCredit;
    }, [accountList, allowAccessCredit, creditArrays]);

    const onClickHandler = () => {
        switch (productKey) {
            case WEALTH_PRODUCT_KEYS.CARD:
            case WEALTH_PRODUCT_KEYS.MASTERCARD:
            case WEALTH_PRODUCT_KEYS.MASTERCARD3:
            case WEALTH_PRODUCT_KEYS.CREDIT_CARD_V4:
                if (isExistCreditCard) {
                    history.push(CreditCardNavigationKey.TransactionList);
                    return;
                }

                history.push(
                    `/${rootPath}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`
                );
                break;
            case WEALTH_PRODUCT_KEYS.LIFE_INSURANCE2:
                history.push(
                    `/${rootPath}/${WEALTH_NAVIGATION_KEYS.LIFE_INSURANCE}`
                );
                break;
            case WEALTH_PRODUCT_KEYS.OVERDRAFT:
                break;
            case WEALTH_PRODUCT_KEYS.GENERAL_INSURANCE:
                history.push(
                    `/${rootPath}/${WEALTH_NAVIGATION_KEYS.GENERAL_INSURANCE}/${WEALTH_NAVIGATION_KEYS.DASHBOARD}`
                );
                break;
            case WEALTH_PRODUCT_KEYS.VINA_WEALTH_V2:
            case WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3:
                history.push(
                    `/${rootPath}/${WEALTH_NAVIGATION_KEYS.VINA_WEALTH}`
                );
                break;
            case WEALTH_PRODUCT_KEYS.LOANS:
                history.push(`/${rootPath}/${WEALTH_NAVIGATION_KEYS.LOANS}`);
                break;
            case WEALTH_PRODUCT_KEYS.OVERSEAS:
                break;
            default:
                break;
        }
    };

    return (
        <ButtonBase
            key={productKey}
            className={classes.button}
            disableRipple
            onClick={onClickHandler}
        >
            <Icon
                className={clsx(
                    `font-icon icon-${getWealthIcon(productKey)}`,
                    classes.icon
                )}
            />
            <Box flex={1} textAlign="left">
                <Typography className={classes.title}>
                    {t(`wealth:${productKey}_name`)}
                </Typography>
                <Typography variant="body2" className={classes.subtitle}>
                    {t(`wealth:${productKey}_subtitle`)}
                </Typography>
            </Box>
        </ButtonBase>
    );
};

const mapState = (state) => ({
    accountList: state.account.list,
});

export default connect(mapState)(WealthProductListItem);
