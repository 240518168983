import LspBreakLine from "@components/LspBreakLine";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => {
    return {
        groupPayment: {
            padding: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
    };
});

const GroupPaymentItem = ({
    noBreakLine,
    txnAppType,
    txnTimestamp,
    txnAmount,
    txnDesc,
    txnCurrency,
    isLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <>
            {isLoading && (
                <>
                    <div className={classes.groupPayment}>
                        <LspTypography variant="heading1" textCenter>
                            <Skeleton variant="text" width={200} height={32} />
                        </LspTypography>
                        <LspTypography variant="body3" textCenter color="grey">
                            <Skeleton variant="text" width={150} height={20} />
                        </LspTypography>
                        <LspTypography variant="heading1" textCenter>
                            <Skeleton variant="text" width={200} height={32} />
                        </LspTypography>
                        <LspTypography
                            variant="subheading3"
                            textCenter
                            color="grey"
                        >
                            <Skeleton variant="text" width={300} height={20} />
                        </LspTypography>
                    </div>
                    {!noBreakLine && <LspBreakLine />}
                </>
            )}
            {!isLoading && (
                <>
                    <div className={classes.groupPayment}>
                        <LspTypography variant="heading1" textCenter>
                            {t(`txnapptype:${txnAppType}_title`)}
                        </LspTypography>
                        <LspTypography variant="body3" textCenter color="grey">
                            {txnTimestamp &&
                                format(txnTimestamp, "dd/MM/yyyy - HH:mm")}
                        </LspTypography>
                        <LspTypography variant="heading1" textCenter>
                            {formatNumber(txnAmount)} {txnCurrency}
                        </LspTypography>
                        <LspTypography
                            variant="subheading3"
                            textCenter
                            color="grey"
                        >
                            {txnDesc}
                        </LspTypography>
                    </div>
                    {!noBreakLine && <LspBreakLine />}
                </>
            )}
        </>
    );
};

GroupPaymentItem.propTypes = {
    noBreakLine: PropTypes.bool,
    txnAppType: PropTypes.string,
    txnTimestamp: PropTypes.number,
    txnAmount: PropTypes.number,
    txnDesc: PropTypes.string,
    txnCurrency: PropTypes.string,
    isLoading: PropTypes.bool,
};

GroupPaymentItem.defaultProps = {
    noBreakLine: false,
    txnAppType: "",
    txnTimestamp: null,
    txnAmount: 0,
    txnDesc: "",
    txnCurrency: "",
    isLoading: false,
};

export default GroupPaymentItem;
