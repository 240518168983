/* ------------- Types Definition ------------- */
export const MoveMoneyActionType = Object.freeze({
    TransferToDebitCardRequest:
        "MoveMoneyActionType/TransferToDebitCardRequest",
    TransferToAccountNumberRequest:
        "MoveMoneyActionType/TransferToAccountNumberRequest",
    TransferToCreditCardRequest:
        "MoveMoneyActionType/TransferToCreditCardRequest",
    TransferSuccess: "MoveMoneyActionType/TransferSuccess",
    TransferError: "MoveMoneyActionType/TransferError",
    TransferErrorWithAmount: "MoveMoneyActionType/TransferErrorWithAmount",
    TransferWithOTP: "MoveMoneyActionType/TransferWithOTP",
    RemoveExistingPayee: "MoveMoneyActionType/RemoveExistingPayee",
    GetExistingPayeeSuccess: "MoveMoneyActionType/GetExistingPayeeSuccess",
    SubmitOTP: "MoveMoneyActionType/SubmitOTP",
    SubmitOTPError: "MoveMoneyActionType/SubmitOTPError",
    Reset: "MoveMoneyActionType/Reset",

    // Pay anyone
    UpdatePasswordPayAnyone: "MoveMoneyActionType/UpdatePasswordPayAnyone",
    UpdatePasswordOptionPayAnyone:
        "MoveMoneyActionType/UpdatePasswordOptionPayAnyone",
    UpdateDisabledPasswordPayAnyone:
        "MoveMoneyActionType/UpdateDisabledPasswordPayAnyone",
    ResetPayAnyoneData: "MoveMoneyActionType/ResetPayAnyoneData",
    TransferByPhone: "MoveMoneyActionType/TransferByPhone",
    CommitOTPTransferByPhone: "MoveMoneyActionType/CommitOTPTransferByPhone",
    SetExternalData: "MoveMoneyActionType/SetExternalData",
});

/* ------------- Actions Definition ------------- */

const transferToDebitCardRequest = (payload) => {
    return {
        type: MoveMoneyActionType.TransferToDebitCardRequest,
        payload,
    };
};

const transferToAccountNumberRequest = (payload) => {
    return {
        type: MoveMoneyActionType.TransferToAccountNumberRequest,
        payload,
    };
};

const transferToCreditCardRequest = (payload) => {
    return {
        type: MoveMoneyActionType.TransferToCreditCardRequest,
        payload,
    };
};

const transferSuccess = (payload) => {
    return {
        type: MoveMoneyActionType.TransferSuccess,
        payload,
    };
};
const transferError = () => {
    return {
        type: MoveMoneyActionType.TransferError,
    };
};

const transferErrorWithAmount = (payload) => {
    return {
        type: MoveMoneyActionType.TransferErrorWithAmount,
        payload,
    };
};

const transferWithOTP = (payload) => {
    return {
        type: MoveMoneyActionType.TransferWithOTP,
        payload,
    };
};

const removeExistingPayee = () => {
    return {
        type: MoveMoneyActionType.RemoveExistingPayee,
    };
};

const getExistingPayeeSuccess = (payload) => {
    return {
        type: MoveMoneyActionType.GetExistingPayeeSuccess,
        payload,
    };
};

const submitOTP = (payload) => {
    return {
        type: MoveMoneyActionType.SubmitOTP,
        payload,
    };
};

const submitOTPError = (payload) => {
    return {
        type: MoveMoneyActionType.SubmitOTPError,
        payload,
    };
};

const updatePasswordPayAnyone = (payload) => {
    return {
        type: MoveMoneyActionType.UpdatePasswordPayAnyone,
        payload,
    };
};

const updatePasswordOptionPayAnyone = (payload) => {
    return {
        type: MoveMoneyActionType.UpdatePasswordOptionPayAnyone,
        payload,
    };
};

const updateDisabledPasswordPayAnyone = (payload) => {
    return {
        type: MoveMoneyActionType.UpdateDisabledPasswordPayAnyone,
        payload,
    };
};

const reset = () => {
    return {
        type: MoveMoneyActionType.Reset,
    };
};

const resetPayAnyoneData = (payload) => {
    return {
        type: MoveMoneyActionType.ResetPayAnyoneData,
        payload,
    };
};

const transferByPhone = (payload) => {
    return {
        type: MoveMoneyActionType.TransferByPhone,
        payload,
    };
};

const commitOTPTransferByPhone = (payload) => {
    return {
        type: MoveMoneyActionType.CommitOTPTransferByPhone,
        payload,
    };
};

const setExternalData = (payload) => {
    return {
        type: MoveMoneyActionType.SetExternalData,
        payload,
    };
};

const moveMoneyAction = {
    transferToDebitCardRequest,
    transferToAccountNumberRequest,
    transferToCreditCardRequest,
    transferSuccess,
    transferError,
    transferWithOTP,
    removeExistingPayee,
    getExistingPayeeSuccess,
    submitOTP,
    submitOTPError,
    reset,
    transferErrorWithAmount,
    updatePasswordPayAnyone,
    updatePasswordOptionPayAnyone,
    updateDisabledPasswordPayAnyone,
    resetPayAnyoneData,
    transferByPhone,
    commitOTPTransferByPhone,
    setExternalData,
};

export default moveMoneyAction;
