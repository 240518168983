import { Box, Icon, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AlertController from "@helpers/controllers/AlertController";
import { useEffect } from "react";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(4),
        },
    },
    header: {
        padding: theme.spacing(1, 4),
        textAlign: "center",
        color: theme.palette.blue.contrastText,
        borderTopLeftRadius: theme.shape.radiusLevels[0],
        borderTopRightRadius: theme.shape.radiusLevels[0],
        textTransform: "uppercase",
        "&.active": {
            background: theme.palette.blue.main,
        },
        "&.expired": {
            background: theme.palette.gold.main,
        },
        "&.failed": {
            background: theme.palette.orange.main,
        },
        "&.cancelled": {
            background: theme.palette.magicalGrey[400],
        },
    },
    body: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        "&:not(:last-child)": {
            borderBottom: `1px solid ${theme.palette.magicalGrey[200]}`,
        },
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    rowItem: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "&:not(:last-child)": {
            borderRight: `1px solid ${theme.palette.magicalGrey[200]}`,
        },
        color: theme.palette.black.main,
    },
    itemName: {
        color: theme.palette.purple.main,
    },
    itemValue: {},
    hint: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.magicalGrey[400],
    },
    icon: {
        cursor: "pointer",
        marginLeft: theme.spacing(0.5),
    },
}));

const PaymentBoxInfo = ({ detail, hasSecretCode, status }) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "payAnyone");
    const { formatNumber } = useNumber();

    const copyHandler = () => {
        AlertController.show(t("payAnyone:password_copy_success_banner"));
    };

    useEffect(() => {
        return () => {};
    });

    return (
        <>
            <Box component={Paper} className={classes.box}>
                <Typography className={`${classes.header} ${status}`}>
                    {status}
                </Typography>
                <div className={classes.body}>
                    <div className={classes.row}>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemName}>
                                {t("lb_amount_web")}
                            </Typography>
                            <Typography className={classes.itemValue}>
                                <b>{formatNumber(detail?.amount)}</b>
                            </Typography>
                        </div>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemName}>
                                {t("lb_to")}
                            </Typography>
                            <Typography className={classes.itemValue}>
                                <b> {detail?.phoneNumber}</b>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.rowItem}>
                            <Typography className={classes.itemName}>
                                {t("payee_card_description_label")}
                            </Typography>
                            <Typography className={classes.itemValue}>
                                {detail?.desc}
                            </Typography>
                        </div>
                    </div>

                    {hasSecretCode && (
                        <div className={classes.row}>
                            <div className={classes.rowItem}>
                                <Typography className={classes.subtitle}>
                                    {t("payAnyone:password_for_this_payment")}
                                </Typography>
                                <Typography className={classes.hint}>
                                    {t("payAnyone:tap_to_copy")}
                                </Typography>
                                <Typography className={classes.itemValue}>
                                    {detail?.secretCode}
                                    <CopyToClipboard
                                        text={detail?.secretCode}
                                        onCopy={copyHandler}
                                    >
                                        <Icon
                                            className={`font-icon icon-copytoclipboard  ${classes.icon}`}
                                        />
                                    </CopyToClipboard>
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </Box>
        </>
    );
};

export default PaymentBoxInfo;
