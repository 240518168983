import Content from "@components/Content";
import LspButton from "@components/LspButton";

import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { FetchStatus } from "@config/constants";
import useNumber from "@helpers/useNumber";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import termDepositAction from "@redux/actions/term-deposit";
import { isEmpty } from "lodash";

import { useMemo, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import accountAction from "@redux/actions/account";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    header: {
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        marginBottom: theme.spacing(2),
    },
    text: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
}));

const PreviewWithdraw = ({
    previewTd,
    partialWithDraw,
    partialTDFetching,
    partialTDStatus,
    clearValidationTD,
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const history = useHistory();

    const { tdId } = useParams();
    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();
    const amount = useMemo(() => {
        const amountBase64 = query.get("amount");
        console.log("amountBase64: ", atob(amountBase64));
        return atob(amountBase64);
    }, [query]);

    useEffect(() => {
        if (isEmpty(previewTd)) {
            history.push("/");
        }
    }, [history, previewTd]);

    const onBack = () => {
        history.goBack();
    };

    const handleTdWithDraw = () => {
        partialWithDraw({
            termDepositId: parseFloat(tdId),
            amount: parseFloat(amount),
        });
    };

    useEffect(() => {
        if (partialTDStatus === FetchStatus.Success) {
            AlertController.show(
                t("TD:td_early_redeem_partial_withdraw_successful_body"),
                AlertType.Success
            );
            history.push(`/term-deposit/active/${tdId}`);
            clearValidationTD();
        }
    }, [clearValidationTD, history, partialTDStatus, t, tdId]);

    return (
        <Content size="sm">
            <Box component={Paper} p={3}>
                <PageHeaderBack onBack={onBack}>
                    <LspTypography variant="heading1" color="primary">
                        {t("TD:td_early_redeem_partial_withdraw_review")}
                    </LspTypography>
                </PageHeaderBack>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <LspTypography variant="body1" color="grey" textCenter>
                            {t(
                                "TD:td_early_redeem_partial_withdraw_amount_withdraw"
                            )}
                        </LspTypography>
                        <LspTypography variant="title1" textCenter>
                            {formatNumber(amount, false, true)}
                        </LspTypography>
                    </div>
                    <div className={classes.content}>
                        {/* ========================================= */}

                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <LspTypography
                                    color="grey"
                                    variant="subheading3"
                                    className={classes.text}
                                >
                                    {t(
                                        "TD:td_early_redeem_partial_withdraw_review_method"
                                    )}
                                </LspTypography>
                            </Grid>
                            <Grid item xs={7}>
                                <LspTypography variant="heading3">
                                    {t("TD:td_early_redeem_partial_withdraw")}
                                </LspTypography>
                            </Grid>
                        </Grid>

                        {/* ========================================= */}
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <LspTypography
                                    color="grey"
                                    variant="subheading3"
                                    className={classes.text}
                                >
                                    {t(
                                        "TD:td_early_redeem_partial_withdraw_review_interest_rate"
                                    )}
                                </LspTypography>
                            </Grid>
                            <Grid item xs={7}>
                                <LspTypography variant="heading3">
                                    {t(
                                        "TD:td_early_redeem_partial_withdraw_review_interest_rate_non_term"
                                    ).replace(
                                        "%@",
                                        previewTd?.annualInterestRateNow
                                    )}
                                </LspTypography>
                            </Grid>
                        </Grid>
                        {/* ========================================= */}
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <LspTypography
                                    color="grey"
                                    variant="subheading3"
                                    className={classes.text}
                                >
                                    {t(
                                        "TD:td_early_redeem_partial_withdraw_review_interest_amount"
                                    )}
                                </LspTypography>
                            </Grid>
                            <Grid item xs={7}>
                                <LspTypography variant="heading3">
                                    {formatNumber(
                                        previewTd?.totalInterestNow,
                                        false,
                                        true
                                    )}
                                </LspTypography>
                            </Grid>
                        </Grid>

                        {/* ========================================= */}
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <LspTypography
                                    color="grey"
                                    variant="subheading3"
                                    className={classes.text}
                                >
                                    {t(
                                        "TD:td_early_redeem_partial_withdraw_review_remaining"
                                    )}
                                </LspTypography>
                            </Grid>
                            <Grid item xs={7}>
                                <LspTypography variant="heading3">
                                    {formatNumber(
                                        previewTd?.termDepositAmount,
                                        false,
                                        true
                                    )}
                                </LspTypography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <LspButton
                    fullWidth
                    onClick={() => handleTdWithDraw()}
                    progressing={partialTDFetching}
                >
                    {t("loan_vpbank_button_confirm")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    previewTd: state.termDeposit.previewTD.data,
    partialTDFetching: state.termDeposit.partialTD.fetching,
    partialTDStatus: state.termDeposit.partialTD.status,
});

const dispatchProps = (dispatch) => ({
    partialWithDraw: (payload) =>
        dispatch(termDepositAction.partialTDRequest(payload)),
    clearValidationTD: () => dispatch(termDepositAction.clearValidateRate()),
});

export default connect(mapState, dispatchProps)(PreviewWithdraw);
