import LspTypography from "@components/LspTypography";
import SwitchButton from "@components/SwitchButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import userAction from "@redux/actions/user";
import { isEmpty, isFunction } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import tommyNoHope from "@assets/images/no_hope_with_status.png";
import useOTP from "@helpers/useOTP";
import userService from "@services/user";
import { ALIAS_TYPE, RESPONSE_CODE } from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    accountSettingContainer: {
        display: "flex",
        flexDirection: "row",
    },
    accountSettingLeft: {
        flex: 1,
        display: "flex",
        marginRight: theme.spacing(3),
        "&:last-child": {
            marginRight: 0,
        },
    },
    accountSettingRight: {},
    accountSettingIcon: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2),
        color: theme.palette.grey[300],
    },
    accountSettingContent: {},
    accountSettingHeader: {},
    accountSettingText: {},
}));
const AccountSettingItems = ({
    icon,
    checked,
    onAccountSettingChange,
    title,
    description,
    toggleAlias,
    toggleAliasRequest,
    item,
    fetching,
    fetchAliasData,
    getBankInfoRequest,
}) => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { openOTPDialog, closeOTPDialog } = useOTP();

    const handleCommitOTP = useCallback(
        async (otp) => {
            const commitOTP = await userService.commitiOTPAlias(otp);
            const { code, data } = commitOTP.data;
            setLoading(false);
            closeOTPDialog();

            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    AlertController.show(
                        item?.aliasType === ALIAS_TYPE.ALIAS_NICKNAME
                            ? t("IBFT:IBFT_alias_setting_nickname_push_on")
                            : t("IBFT:IBFT_alias_setting_push_on"),
                        AlertType.Success
                    );
                    fetchAliasData();
                    getBankInfoRequest();
                    break;

                default:
                    AlertController.show(
                        item?.aliasType === ALIAS_TYPE.ALIAS_NICKNAME
                            ? t("IBFT:IBFT_alias_setting_nickname_push_error")
                            : t("IBFT:IBFT_alias_setting_push_error"),
                        AlertType.Error
                    );
                    break;
            }
        },
        [closeOTPDialog, fetchAliasData, getBankInfoRequest, item?.aliasType, t]
    );

    const handleSubmitOTP = useCallback(
        async (aliasId, type, duplicate) => {
            const submitOTPResult = await userService.submitiOTPAlias({
                aliasId,
                aliasType: type,
                duplicate,
            });

            const { code, data } = submitOTPResult.data;
            setLoading(false);

            switch (code) {
                case RESPONSE_CODE.OTP_REQUIRED:
                    openOTPDialog({
                        refNo: data?.refNo,
                        submitFunc: handleCommitOTP,
                    });
                    break;

                default:
                    GlobalDialogController.showError({
                        errorCode: code,
                        data,
                    });

                    break;
            }
        },
        [handleCommitOTP, openOTPDialog]
    );

    const handleTogglePhoneAlias = useCallback(
        (aliasId, enable, type, duplicate) => {
            if (!duplicate) {
                handleSubmitOTP(aliasId, type, duplicate);
                return;
            }

            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconCustom: tommyNoHope,
                    header: t(
                        "IBFT:IBFT_phone_number_popup_not_activate_title"
                    ),
                    content: t(
                        "IBFT:IBFT_phone_number_new_popup_not_activate_desc"
                    ),
                    doubleButton: [
                        {
                            label: t(
                                "IBFT:IBFT_phone_number_new_popup_not_activate_btn_change"
                            ),
                            onClick: () => {
                                GlobalDialogController.hide();
                                handleSubmitOTP(aliasId, type, duplicate);
                            },
                        },

                        {
                            label: t(
                                "IBFT:IBFT_phone_number_new_popup_not_activate_btn_keep"
                            ),
                            onClick: () => {
                                GlobalDialogController.hide();
                                setLoading(false);
                            },
                        },
                    ],
                },
            });
        },
        [handleSubmitOTP, t]
    );

    const handleToggleAlias = useCallback(
        (aliasData, toggleValue) => {
            if (
                aliasData.aliasType === ALIAS_TYPE.PHONE_NUMBER &&
                toggleValue
            ) {
                setLoading(true);
                handleTogglePhoneAlias(
                    aliasData?.id,
                    toggleValue,
                    aliasData.aliasType,
                    aliasData.duplicate
                );
                return;
            }
            if (
                aliasData.aliasType === ALIAS_TYPE.ALIAS_NICKNAME &&
                toggleValue
            ) {
                setLoading(true);
                handleTogglePhoneAlias(
                    aliasData?.id,
                    toggleValue,
                    aliasData.aliasType,
                    aliasData.duplicate
                );
                return;
            }

            const aliasModel = {
                aliasId: aliasData?.id,
                enable: toggleValue,
                type: aliasData.aliasType,
            };
            toggleAliasRequest(aliasModel);
            setLoading(true);
        },
        [handleTogglePhoneAlias, toggleAliasRequest]
    );

    const handleSwitchChange = ($event) => {
        const isChecked = $event.target.checked;
        if (isFunction(onAccountSettingChange)) {
            onAccountSettingChange(isChecked);
        }
        handleToggleAlias(item, isChecked);
    };
    useEffect(() => {
        if (!toggleAlias) {
            setLoading(toggleAlias);
        }
    }, [toggleAlias]);

    const fetchingSkeleton = () => {
        return (
            <>
                <Skeleton
                    variant="rect"
                    width="100%"
                    style={{ marginBottom: 6 }}
                    height={80}
                />
            </>
        );
    };

    return (
        <>
            {fetching && fetchingSkeleton()}
            {!fetching && (
                <div className={classes.accountSettingContainer}>
                    <>
                        <div className={classes.accountSettingLeft}>
                            {icon && (
                                <div className={classes.accountSettingIcon}>
                                    {icon}
                                </div>
                            )}
                            <div className={classes.accountSettingContent}>
                                <div className={classes.accountSettingHeader}>
                                    <LspTypography
                                        variant="heading3"
                                        component="div"
                                    >
                                        {title}
                                    </LspTypography>
                                </div>
                                <LspTypography
                                    className={classes.accountSettingText}
                                    variant="body1"
                                    component="div"
                                >
                                    {description}
                                </LspTypography>
                            </div>
                        </div>
                        <div className={classes.accountSettingRight}>
                            <SwitchButton
                                checked={checked}
                                onChange={($event) =>
                                    handleSwitchChange($event)
                                }
                                fetching={isLoading}
                            />
                        </div>
                    </>
                </div>
            )}
        </>
    );
};

AccountSettingItems.propTypes = {
    checked: PropTypes.bool,
    item: PropTypes.shape({
        aliasType: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    fetching: PropTypes.bool,
};

AccountSettingItems.defaultProps = {
    checked: false,
    item: {
        aliasType: "",
    },
    title: "",
    description: "",
    fetching: false,
};

const stateProps = (state) => ({
    toggleAlias: state.user.toggleAlias.fetching,
});

const dispatchProps = (dispatch) => ({
    fetchAliasData: () => dispatch(userAction.getAliasRequest()),
    toggleAliasRequest: (payload) =>
        dispatch(userAction.toggleAliasRequest(payload)),
    getBankInfoRequest: () => dispatch(userAction.getBankInfoRequest()),
});
export default connect(stateProps, dispatchProps)(AccountSettingItems);
