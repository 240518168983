import { memo, useCallback, useMemo } from "react";
import { makeStyles, Box, Icon } from "@material-ui/core";
import clsx from "clsx";
import isFunction from "lodash/isFunction";
import useNumber from "@helpers/useNumber";
import {
    CreditCardNavigationKey,
    InstallmentStatus,
    PayAnyoneStatus,
    TransactionGroupType,
} from "@config/constants";
import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import LspButton from "@components/LspButton";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AddPayee from "@components/AddPayee";
import { useHistory } from "react-router-dom";
import { INSTALLMENT_STATUSES } from "@containers/CreditCard/Installment/constant";

const useStyles = makeStyles((theme) => ({
    txnRoot: {
        cursor: "pointer",
        paddingBottom: theme.spacing(1),
        "&:hover": {
            boxShadow: theme.shadows[3],
            borderRadius: "4px",
        },
        "&:active": {
            opacity: theme.palette.opacity.active,
        },
    },
    txnInfo: {
        flex: 1,
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            paddingRight: theme.spacing(2),
        },
    },
    txtNotes: {
        margin: theme.spacing(0, 2),
        paddingLeft: theme.spacing(1),
        borderLeft: `2px solid ${theme.palette.magicalGrey[400]}`,
    },

    txnBalance: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    statusLabel: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.grey[600],
        borderRadius: theme.shape.radiusLevels[3],
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.primary.contrastText,
    },
    action: {
        backgroundColor: theme.palette.neutral.white,
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[2],
        padding: theme.spacing(0.5, 2),
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        "& > span": {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(16),
        },
    },
    bgBlue: {
        backgroundColor: theme.palette.blue.main,
    },
    bgGold: {
        backgroundColor: theme.palette.gold.main,
    },
    bgOrange: {
        backgroundColor: theme.palette.orange.main,
    },
    bgGrey: {
        backgroundColor: theme.palette.grey[600],
    },
    textGreen: {
        color: theme.palette.secondary.main,
    },
    textBlue: {
        color: theme.palette.blue.main,
    },
    textGold: {
        color: theme.palette.gold.main,
    },
    textOrange: {
        color: theme.palette.orange.main,
    },
    textGrey: {
        color: theme.palette.grey[600],
    },
}));

const defaultHiddenValue = "******";

const TransactionItem = ({
    type, // Type of transaction item
    timoAppType,
    txnTitle,
    txnTime,
    txnAmount,
    txnAmountMC,
    remainingAmount,
    refNo,
    txnDesc,
    cur,
    timoDesc1,
    timoDesc2,
    onClick,
    title, // Installment
    createdDate, // Installment
    installStatus, // Installment action
    txnId,
    phoneNumber, // Pay anyone
    receiverName, // Pay anyone
    desc, // Pay anyone
    amount, // Pay anyone
    status, // Pay anyone
    txnType,
    payeeId,
    creditAccount,
    txnTimeDisplay,
    goalSavePlanName,
    enableAddPayee,
    note,
    showRemainBalance,
    hiddenRemainBalanceOption,
}) => {
    const classes = useStyles();
    const { t } = useTranslation("translation", "creditCard", "payAnyone");
    const { formatNumber } = useNumber();
    const history = useHistory();

    // Use for spend transaction
    const addPayeeAvailable = useMemo(
        () =>
            timoAppType === "100" &&
            txnType === "IncomingTransfer" &&
            !!creditAccount &&
            !payeeId &&
            enableAddPayee,
        [timoAppType, txnType, creditAccount, payeeId, enableAddPayee]
    );

    const displayTitle = useMemo(() => {
        switch (type) {
            case TransactionGroupType.CreditCard:
                if (timoAppType === "150" && timoDesc2 !== "") {
                    return timoDesc2;
                }

                if (timoAppType) {
                    return timoDesc1;
                }
                return txnDesc;
            case TransactionGroupType.Installment:
                return title;
            case TransactionGroupType.PayAnyone:
                return desc || t("payAnyone:pao_title_pending_txn");
            case TransactionGroupType.GoalSave:
                return (
                    <>
                        {goalSavePlanName}
                        <LspTypography variant="body3" component="span">
                            {` (${txnTitle})`}
                        </LspTypography>
                    </>
                );
            case TransactionGroupType.SpendAccount:
            default:
                return txnTitle;
        }
    }, [
        type,
        title,
        txnTitle,
        timoAppType,
        timoDesc1,
        timoDesc2,
        desc,
        t,
        goalSavePlanName,
        txnDesc,
    ]);

    const displaySubtitle = useMemo(() => {
        switch (type) {
            case TransactionGroupType.CreditCard:
                return txnTimeDisplay;
            case TransactionGroupType.Installment:
                return createdDate;
            case TransactionGroupType.PayAnyone:
                return `${phoneNumber}${
                    receiverName ? ` - ${receiverName}` : ""
                }`;
            case TransactionGroupType.GoalSave:
                return txnTime;
            case TransactionGroupType.SpendAccount:
            default:
                return txnDesc;
        }
    }, [
        type,
        createdDate,
        txnDesc,
        phoneNumber,
        receiverName,
        txnTimeDisplay,
        txnTime,
    ]);

    const txnValue = useMemo(() => {
        switch (type) {
            case TransactionGroupType.CreditCard:
                return txnAmountMC;
            case TransactionGroupType.PayAnyone:
                return amount;
            case TransactionGroupType.SpendAccount:
            default:
                return txnAmount;
        }
    }, [type, txnAmount, txnAmountMC, amount]);

    const displayValue = useMemo(() => {
        switch (type) {
            case TransactionGroupType.PayAnyone: {
                let color = "";
                switch (status) {
                    case PayAnyoneStatus.Active:
                        color = "blue";
                        break;
                    case PayAnyoneStatus.Expired:
                        color = "gold";
                        break;
                    case PayAnyoneStatus.Failed:
                        color = "orange";
                        break;
                    case PayAnyoneStatus.Cancelled:
                        color = "grey";
                        break;
                    default:
                        break;
                }

                return { text: `-${formatNumber(txnValue)}`, color };
            }
            case TransactionGroupType.CreditCard:
            case TransactionGroupType.GoalSave:
                return {
                    text:
                        txnValue > 0
                            ? `+${formatNumber(txnValue)}`
                            : formatNumber(txnValue),
                    color: txnValue < 0 ? "error" : null,
                };
            case TransactionGroupType.SpendAccount:
                return {
                    text: formatNumber(txnValue),
                    color: txnValue > 0 ? "success" : null,
                };
            case TransactionGroupType.Installment:
                return {
                    text: formatNumber(txnValue),
                };
            default:
                return {};
        }
    }, [type, formatNumber, txnValue, status]);

    const txnSubValue = useMemo(() => {
        switch (type) {
            case TransactionGroupType.CreditCard:
                if (cur && cur !== "VND") {
                    return cur;
                }
                return null;
            case TransactionGroupType.Installment:
            case TransactionGroupType.SpendAccount:
            case TransactionGroupType.GoalSave:
                return null;
            default:
                return formatNumber(remainingAmount);
        }
    }, [type, cur, formatNumber, remainingAmount]);

    const isEmphasizedTitle = useMemo(() => {
        if (txnValue <= 0) {
            return false;
        }
        switch (type) {
            case TransactionGroupType.Installment:
            case TransactionGroupType.PayAnyone:
            case TransactionGroupType.GoalSave:
                return false;
            default:
                return true;
        }
    }, [type, txnValue]);

    const hasAction = useMemo(() => {
        if (installStatus === INSTALLMENT_STATUSES.AVAILABLE) {
            return true;
        }
    }, [installStatus]);

    const statusLabel = useMemo(() => {
        if (type === TransactionGroupType.Installment) {
            switch (installStatus) {
                case InstallmentStatus.Active:
                    return {
                        text: t(`creditCard:lb_active`),
                        className: classes.bgBlue,
                    };
                case InstallmentStatus.Pending:
                    return {
                        text: t(`creditCard:lb_pending`),
                        className: classes.bgGold,
                    };
                case InstallmentStatus.Declined:
                    return {
                        text: t(`creditCard:lb_decline`),
                        className: classes.bgOrange,
                    };
                case InstallmentStatus.Completed:
                    return {
                        text: t(`creditCard:lb_close`),
                        className: classes.bgGrey,
                    };
                default:
                    return null;
            }
        }
        if (type === TransactionGroupType.PayAnyone) {
            switch (status) {
                case PayAnyoneStatus.Active:
                    return {
                        text: t(`payAnyone:status_active`),
                        className: classes.bgBlue,
                    };
                case PayAnyoneStatus.Expired:
                    return {
                        text: t(`payAnyone:status_expired`),
                        className: classes.bgGold,
                    };
                case PayAnyoneStatus.Failed:
                    return {
                        text: t(`payAnyone:status_failed`),
                        className: classes.bgOrange,
                    };
                case PayAnyoneStatus.Cancelled:
                    return {
                        text: t(`payAnyone:status_cancelled`),
                        className: classes.bgGrey,
                    };
                default:
                    return null;
            }
        }
    }, [type, t, installStatus, status, classes]);

    const handleOnClickEvent = useCallback(() => {
        if (!isFunction(onClick)) {
            return;
        }
        switch (type) {
            case TransactionGroupType.Installment:
                onClick(txnId);
                break;
            case TransactionGroupType.GoalSave:
                return;
            case TransactionGroupType.CreditCard:
            case TransactionGroupType.SpendAccount:
            default:
                onClick(refNo);
                break;
        }
    }, [type, txnId, refNo, onClick]);

    const onCancelAddPayee = useCallback(() => {
        GlobalDialogController.hide();
    }, []);

    const onSuccessAddPayee = useCallback(() => {
        GlobalDialogController.hide();
        history.push("spend-account/transactions");
    }, [history]);

    const addNewPayee = useCallback(() => {
        GlobalDialogController.show({
            component: () => (
                <AddPayee
                    accountNumber={creditAccount}
                    onCancel={onCancelAddPayee}
                    onSuccess={onSuccessAddPayee}
                />
            ),
        });
    }, [creditAccount, onCancelAddPayee, onSuccessAddPayee]);

    const createConversion = useCallback(
        ($event) => {
            $event.stopPropagation();
            history.push(
                `/${CreditCardNavigationKey.InstallConversion}/${txnId}`
            );
        },
        [history, txnId]
    );

    const isHasNote = useMemo(() => note && note.length > 0, [note]);

    return (
        <div className={classes.txnRoot}>
            <Box
                px={2}
                py={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                onClick={handleOnClickEvent}
            >
                <div className={classes.txnInfo}>
                    <LspTypography
                        variant="subheading2"
                        color={isEmphasizedTitle ? "success" : "black"}
                    >
                        {displayTitle}
                    </LspTypography>
                    <LspTypography variant="body3" className={classes.subTitle}>
                        {displaySubtitle}
                    </LspTypography>
                </div>
                <div className={classes.txnBalance}>
                    <LspTypography
                        variant="subheading1"
                        color={displayValue.color}
                    >
                        {displayValue.text}
                    </LspTypography>
                    {txnSubValue && (
                        <LspTypography variant="body2">
                            {txnSubValue}
                        </LspTypography>
                    )}
                    {statusLabel && (
                        <div
                            className={clsx(
                                classes.statusLabel,
                                statusLabel.className
                            )}
                        >
                            {statusLabel.text}
                        </div>
                    )}

                    {showRemainBalance && remainingAmount && (
                        <LspTypography variant="body2" color="grey">
                            {hiddenRemainBalanceOption
                                ? defaultHiddenValue
                                : formatNumber(remainingAmount)}
                        </LspTypography>
                    )}

                    {hasAction && (
                        <LspTypography
                            variant="body3"
                            color="primary"
                            component="span"
                            className={classes.action}
                            onClick={createConversion}
                        >
                            <Icon className="font-icon icon-plus" />
                            {t("mc_menu_label_installment")}
                        </LspTypography>
                    )}
                </div>
            </Box>
            <Box className={classes.txtNotes} onClick={handleOnClickEvent}>
                {isHasNote && (
                    <LspTypography variant="body1" color="grey">
                        {note}
                    </LspTypography>
                )}
            </Box>
            {addPayeeAvailable && (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    px={2}
                    paddingBottom={1}
                >
                    <LspButton size="small" onClick={addNewPayee}>
                        {t("payee_lb_add_payee")}
                    </LspButton>
                </Box>
            )}
        </div>
    );
};

export default memo(TransactionItem);
