import Content from "@components/Content";
import LspButton from "@components/LspButton";
import ViewBookingDelivery from "@containers/Booking/ViewBooking/ViewBookingDelivery";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import bookingActions from "@redux/actions/booking";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DELIVERY_METHODS } from "../constant";
import ViewBookingHangout from "./ViewBookingHangout";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
}));

const ViewBooking = ({ detail, onCancel, fetching, onChangeBooking }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const fetchingLayer = useMemo(() => {
        return (
            <>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Skeleton
                        width={100}
                        height={100}
                        variant="rect"
                        style={{ borderRadius: 16 }}
                    />
                    <br />
                    <Skeleton width={120} />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Skeleton width={100} />
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Skeleton width={100} />
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Skeleton width="80%" />
                    <Skeleton width={200} />
                    <Skeleton width={130} />
                </Box>
            </>
        );
    }, []);

    const isHangoutMethod = useMemo(
        () => detail?.deliveryMethod === DELIVERY_METHODS.HANGOUT,
        [detail?.deliveryMethod]
    );
    return (
        <Content size="sm">
            <Paper className={classes.body}>
                {fetching && fetchingLayer}
                {!fetching && isHangoutMethod && (
                    <ViewBookingHangout onChangeBooking={onChangeBooking} />
                )}
                {!fetching && !isHangoutMethod && <ViewBookingDelivery />}
            </Paper>

            <LspButton fullWidth onClick={onCancel}>
                {t("lb_btn_ok_got_it")}
            </LspButton>
        </Content>
    );
};

const mapState = (state) => ({
    serviceName: state.booking.serviceName,
    detail: state.booking.detail,
    fetching: state.booking.fetchingDetail,
    onCancel: state.booking.onCancel,
});
const mapDispatch = (dispatch) => ({
    reset: () => dispatch(bookingActions.reset()),
});

export default connect(mapState, mapDispatch)(ViewBooking);
