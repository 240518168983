import { PayAnyoneStatus, RESPONSE_CODE } from "@config/constants";
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import SuccessIcon from "@assets/images/Success1.png";
import ErrorIcon from "@assets/images/Error1.png";
import LspTypography from "@components/LspTypography";
import Interweave from "interweave";
import useNumber from "@helpers/useNumber";
import { LogoMark } from "@components/LspBranding";
import clsx from "clsx";
import { ArrowRightIcon } from "@components/LspIcon";
import moveMoneyService from "@services/move-money";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    result: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    subtitle: {
        marginTop: theme.spacing(1),
    },
    benefitWrapper: {
        padding: theme.spacing(3, 2, 2, 2),
        backgroundColor: theme.palette.neutral.grey4,
        margin: theme.spacing(2, 0, 1, 0),
        borderRadius: theme.shape.radiusLevels[1],
        textAlign: "left",
        width: "100%",
    },
    benefitContent: {
        listStyleType: "none",
        paddingLeft: 0,
        "& .branchName": {
            fontWeight: 700,
            "& span:nth-child(1)": {
                color: theme.palette.purple.main,
            },
            "& span:nth-child(2)": {
                color: theme.palette.blue.main,
            },
            "& span:nth-child(3)": {
                color: theme.palette.orange.main,
            },
            "& span:nth-child(4)": {
                color: theme.palette.gold.main,
            },
        },
        "& > li": {
            position: "relative",
            paddingLeft: 10,
            marginBottom: 8,
            "&:before": {
                position: "absolute",
                content: "",
                width: 3,
                height: 3,
                borderRadius: "50%",
                backgroundColor: theme.palette.neutral.black,
                left: 0,
                top: 7,
            },
        },
    },
}));

const ClaimMoneyResult = memo(
    ({ parentClasses, status, onDownloadApp, verifyCode }) => {
        const { t } = useTranslation();
        const [content, setContent] = useState(null);
        const styles = useStyles();
        const { formatNumber } = useNumber();
        const [bodyContent, setBodyContent] = useState(null);

        const unmounted = useRef(false);

        useEffect(() => {
            return () => {
                unmounted.current = true;
            };
        }, []);

        const getContent = useCallback(
            (paymentStatus) => {
                switch (paymentStatus) {
                    case PayAnyoneStatus.Expired:
                        setContent({
                            title: t("payAnyone:pao_payment_expired_title"),
                            subtitle: t(
                                "payAnyone:pao_payment_expired_content"
                            ),
                            isFailure: true,
                        });
                        break;
                    case PayAnyoneStatus.Cancelled:
                        setContent({
                            title: t("payAnyone:pao_payment_cancel_title"),
                            subtitle: t("payAnyone:pao_payment_cancel_content"),
                            isFailure: true,
                        });
                        break;
                    case PayAnyoneStatus.Done: // have been already claimed money
                        setContent({
                            title: t("payAnyone:pao_payment_completed_title"),
                            isFailure: false,
                        });
                        break;
                    case PayAnyoneStatus.FE_CLAIM_MONEY_SUCCESS:
                        setContent({
                            title: t("payAnyone:pao_payment_completed_title"),
                            subtitle: t(
                                "payAnyone:pao_payment_completed_content"
                            ),
                            isFailure: false,
                        });
                        break;
                    case PayAnyoneStatus.FE_WRONG_SECURITY_MANY_TIMES:
                        setContent({
                            title: t("payAnyone:pao_payment_failed_title"),
                            subtitle: t("payAnyone:pao_payment_failed_content"),
                            isFailure: true,
                        });
                        break;
                    case PayAnyoneStatus.Failed:
                    default:
                        setContent({
                            title: t("payAnyone:pao_payment_error_title"),
                            subtitle: t("payAnyone:pao_payment_error_content"),
                            isFailure: true,
                        });
                        break;
                }
            },
            [t]
        );

        useEffect(() => {
            getContent(status);
        }, [getContent, status]);

        const branchName = useCallback(() => {
            return t("payAnyone:pao_payment_benefit_brand_name")
                .split("")
                .map((item) => `<span>${item}</span>`)
                .reduce((acc, item) => acc.concat(item), "");
        }, [t]);

        const getBodyContent = useCallback(async () => {
            const arrayContent = t("payAnyone:pao_payment_benefit_content", {
                returnObjects: true,
            });
            const contents = [];

            const response = await moveMoneyService.getAdsContentPayAnyone({
                verifyCode,
            });

            if (unmounted.current) {
                return;
            }

            const { code, data } = response.data;

            if (code === RESPONSE_CODE.SUCCESS) {
                for (let i = 0; i < arrayContent.length; i++) {
                    const themeObj = t(`payAnyone:${arrayContent[i]}`, {
                        returnObjects: true,
                    });
                    if (themeObj) {
                        contents.push(
                            themeObj
                                .replace(
                                    "%@maxAmount",
                                    formatNumber(data?.amountLimitPerMonth)
                                )
                                .replace("%@interest", data?.interestRate)
                                .replace(
                                    "%@minAmount",
                                    formatNumber(
                                        data?.minAmountVinaWealthInvestment
                                    )
                                )
                        );
                    }
                }
            }
            setBodyContent(contents);
        }, [t, formatNumber, verifyCode]);

        useEffect(() => {
            getBodyContent();
        }, [getBodyContent]);

        return (
            <div className={styles.result}>
                <img
                    alt="icon"
                    src={content?.isFailure ? ErrorIcon : SuccessIcon}
                />
                <div className={parentClasses.title}>
                    <LspTypography variant="title2" color="black">
                        {content?.title}
                    </LspTypography>
                </div>
                {!!content?.subtitle && (
                    <div
                        className={`${styles.subtitle} ${parentClasses.subtitle}`}
                    >
                        <LspTypography variant="body2" color="grey">
                            {content.subtitle}
                        </LspTypography>
                    </div>
                )}
                <div className={styles.benefitWrapper}>
                    <div className={styles.benefitContent}>
                        <li>
                            <Interweave
                                content={t(
                                    "payAnyone:pao_payment_benefit_title"
                                ).replace(
                                    "%@",
                                    `<span class="branchName">
                                    ${branchName()}
                                </span>`
                                )}
                            />
                        </li>
                        {bodyContent && (
                            <ul>
                                {bodyContent?.map((item) => (
                                    <li key={Math.random()}>
                                        <Box paddingTop={1}>
                                            <Interweave content={item} />
                                        </Box>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {!bodyContent && (
                            <>
                                {[...new Array(15)]?.map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Skeleton key={index} />
                                ))}
                            </>
                        )}
                    </div>
                </div>

                <div className={parentClasses.methods}>
                    <ButtonBase
                        className={`${parentClasses.methodsOption} ${parentClasses.colorFullBottom}`}
                        onClick={onDownloadApp}
                    >
                        <div className={parentClasses.optionContent}>
                            <LogoMark
                                className={clsx(
                                    parentClasses.optionIcon,
                                    parentClasses.memberIcon
                                )}
                            />
                            <LspTypography
                                variant="heading3"
                                className={parentClasses.optionName}
                            >
                                {t("payAnyone:pao_lb_join_timo_now")}
                            </LspTypography>
                            <LspTypography
                                variant="tiny"
                                className={parentClasses.optionDesc}
                            >
                                {t("payAnyone:lb_free")}
                            </LspTypography>
                            <ArrowRightIcon
                                className={parentClasses.navigationIcon}
                            />
                        </div>
                        <div className={parentClasses.colorFullBottomBar}>
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </ButtonBase>
                </div>
            </div>
        );
    }
);

export default ClaimMoneyResult;
