import { useState, memo, useMemo, useEffect } from "react";

import { ATTACHMENT_MODE } from "@config/constants";
import { isFunction } from "lodash";
import LspUploadAttachment from "./LspUploadAttachment";

import LspViewAttachment from "./LspViewAttachment";

const VIEW_MODE = {
    ATTACHMENT: 0,
    VIEW_ATTACHMENT: 1,
};

const MAX_ATTACHMENT = 3;
const AttachmentController = ({
    receipt,
    refNo,
    viewData,
    onBack,
    onUpload,
    mode,
    onDelete,
    onModify,
}) => {
    const [viewMode, setViewMode] = useState(VIEW_MODE.VIEW_ATTACHMENT);
    const [maxAttachment, setMaxAttachment] = useState(0);
    const [attachmentView, setAttachmentView] = useState(null);

    const [attachmentConfig, setAttachmentConfig] = useState({
        type: ATTACHMENT_MODE.CREATE,
    });

    useEffect(() => {
        switch (mode) {
            case "create":
                setViewMode(VIEW_MODE.ATTACHMENT);
                setAttachmentView(null);
                break;

            default:
                setViewMode(VIEW_MODE.VIEW_ATTACHMENT);
                setAttachmentView(viewData);

                break;
        }
    }, [mode, viewData]);

    const handleBack = () => {
        if (isFunction(onBack)) {
            onBack();
        }
    };

    const handleSaveAttachment = (data) => {
        if (attachmentConfig?.fileManageId && isFunction(onModify)) {
            onModify({ fileManageId: attachmentConfig?.fileManageId });
        }
        if (isFunction(onUpload)) {
            onUpload(data);
        }
    };

    const handleUploadSuccess = () => {
        setViewMode(VIEW_MODE.RECEIPT);
    };

    const handleCancelModify = () => {
        setAttachmentConfig({
            type: ATTACHMENT_MODE.CREATE,
        });
        const attachmentFiles = receipt?.urlAttachments;

        setMaxAttachment(
            MAX_ATTACHMENT - ((attachmentFiles && attachmentFiles.length) || 0)
        );
        handleBack();
    };

    const handleChangeAttachment = ({ fileManageId }) => {
        setViewMode(VIEW_MODE.ATTACHMENT);
        setMaxAttachment(1);
        setAttachmentConfig({
            type: ATTACHMENT_MODE.MODIFY,
            fileManageId,
        });
    };

    const handleDelete = () => {
        if (isFunction(onDelete)) {
            onDelete();
        }
    };

    useEffect(() => {
        const attachmentFiles = receipt?.urlAttachments;

        setMaxAttachment(
            MAX_ATTACHMENT - ((attachmentFiles && attachmentFiles.length) || 0)
        );
    }, [receipt?.urlAttachments]);

    return (
        <>
            {viewMode === VIEW_MODE.ATTACHMENT && (
                <LspUploadAttachment
                    refNo={refNo}
                    onSuccess={(data) => handleSaveAttachment(data)}
                    onCancel={() => handleCancelModify()}
                    max={maxAttachment}
                    fileManageId={attachmentConfig?.fileManageId}
                />
            )}

            {viewMode === VIEW_MODE.VIEW_ATTACHMENT && (
                <LspViewAttachment
                    refNo={refNo}
                    url={attachmentView?.url}
                    fileType={attachmentView?.fileType}
                    fileManageId={attachmentView?.fileManageId}
                    urlDownLoad={attachmentView?.urlDownLoad}
                    onClose={() => {
                        handleBack();
                    }}
                    onDelete={({ fileManageId }) => handleDelete(fileManageId)}
                    extension={attachmentView?.extension}
                    onChangeAttachment={(data) => handleChangeAttachment(data)}
                />
            )}
        </>
    );
};

export default memo(AttachmentController);
