import { format, isEqual, parse, sub } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const useDate = () => {
    const { t } = useTranslation();
    const serverTime = useSelector(
        (state) => state.user.serverTime.value,
        shallowEqual
    );

    const reformatDateString = useCallback(
        /**
         * Change formatted date string to another format
         * @param {string} inputDate
         * @param {string} inputDateFormat - input date format (https://date-fns.org/v2.21.3/docs/format)
         * @param {string} outputDateFormat - output date format (https://date-fns.org/v2.21.3/docs/format)
         * @returns {string} formatted date string as definition of `outputDateFormat`
         */
        (inputDate, inputDateFormat, outputDateFormat) => {
            const dateObj = parse(inputDate, inputDateFormat, new Date());
            const outputDate = format(dateObj, outputDateFormat);
            return outputDate;
        },
        []
    );

    const isToday = useCallback(
        /**
         * Check isToday
         * @param {string} date
         * @param {string} dateFormat - input date format (https://date-fns.org/v2.21.3/docs/format)
         * @returns {boolean} date is Today
         */
        (date, dateFormat = "dd/MM/yyyy HH:mm:ss") => {
            if (!date) return "";
            if (!serverTime) return date;

            const dateFormatted = parse(date, dateFormat, new Date());
            const formatServerTime = parse(
                format(serverTime, "dd/MM/yyyy"),
                "dd/MM/yyyy",
                new Date()
            );

            return isEqual(dateFormatted, formatServerTime);
        },
        [serverTime]
    );
    const isYesterday = useCallback(
        /**
         * Check isYesterday
         * @param {string} date
         * @param {string} dateFormat - input date format (https://date-fns.org/v2.21.3/docs/format)
         * @returns {boolean} date is Yesterday
         */
        (date, dateFormat = "dd/MM/yyyy HH:mm:ss") => {
            if (!date) return "";
            if (!serverTime) return date;

            const dateFormatted = parse(date, dateFormat, new Date());
            const formatServerTime = format(serverTime, "dd/MM/yyyy");

            const yesterday = sub(
                parse(formatServerTime, "dd/MM/yyyy", new Date()),
                {
                    days: 1,
                }
            );
            return isEqual(dateFormatted, yesterday);
        },
        [serverTime]
    );

    const getLabelByDate = useCallback(
        /**
         * Check getLabelByDate
         * @param {string} date
         * @param {string} dateFormat - input date format (https://date-fns.org/v2.21.3/docs/format)
         * @param {string} defaultDate - input date format (https://date-fns.org/v2.21.3/docs/format)
         * @returns {string} label date. Ex: Today, Yesterday, 12/12/2022,...
         */
        (date, dateFormat, defaultDate = "") => {
            if (isToday(date, dateFormat)) {
                return t("noti_center_today");
            }

            if (isYesterday(date, dateFormat)) {
                return t("noti_center_yesterday");
            }
            return defaultDate;
        },
        [t, isToday, isYesterday]
    );

    return { reformatDateString, isToday, isYesterday, getLabelByDate };
};

export default useDate;
