import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import GlobalDialogActions, {
    GlobalDialogTypes,
} from "@redux/actions/global-dialog";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Backdrop } from "@material-ui/core";
import otpDialogAction from "@redux/actions/otp-dialog";
import ErrorHandler from "./ErrorHandler";
import { LspDialog } from "../LspDialog";

const GlobalDialog = ({
    opened,
    component: Component,
    dialogInfo,
    open,
    close,
    type,
    errorCode,
    onClose,
    loading,
    data,
    size,
    setOtpError,
}) => {
    useEffect(() => {
        GlobalDialogController.setOpen(open);
    }, [open]);

    useEffect(() => {
        GlobalDialogController.setClose(close);
    }, [close]);

    useEffect(() => {
        GlobalDialogController.setOtpError(setOtpError);
    }, [setOtpError]);

    const closeHandlerFunc = useCallback(() => {
        if (onClose) {
            close();
            onClose();
        } else {
            close();
        }
    }, [close, onClose]);

    return (
        <LspDialog
            open={opened}
            maxWidth={size}
            // onEscapeKeyDown={closeHandlerFunc}
            scroll="body"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            id="lspDialog"
        >
            {type === GlobalDialogTypes.Component && <Component />}
            {!!type && type !== GlobalDialogTypes.Component && (
                <ErrorHandler
                    data={data}
                    errorCode={errorCode}
                    close={closeHandlerFunc}
                    type={type}
                    dialogContent={dialogInfo}
                    isOpen={opened}
                    loading={loading}
                />
            )}
        </LspDialog>
    );
};

const mapState = (state) => ({
    opened: state.globalDialog.opened,
    component: state.globalDialog.component,
    dialogInfo: state.globalDialog.dialogInfo,
    type: state.globalDialog.type,
    errorCode: state.globalDialog.errorCode,
    onClose: state.globalDialog.onClose,
    loading: state.globalDialog.loading,
    data: state.globalDialog.data,
    size: state.globalDialog.size,
});

const mapDispatch = (dispatch) => ({
    open: (payload) => dispatch(GlobalDialogActions.open(payload)),
    close: () => dispatch(GlobalDialogActions.close()),
    setOtpError: (error) => dispatch(otpDialogAction.setError(error)),
});

export default connect(mapState, mapDispatch)(GlobalDialog);
