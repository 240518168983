import { memo } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import { InfoIcon, InfoV2Icon, QuestionMarkIcon } from "./LspIcon";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
    },
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(2),
    },
    content: {
        marginRight: theme.spacing(0.5),
    },
    icon: {
        color: theme.palette.grey[400],
        cursor: "pointer",
        backgroundColor: "transparent",
        display: "inline-block",
        lineHeight: 0,
    },
    white: {
        backgroundColor: theme.palette.neutral.white,
        color: "rgba(0, 0, 0, 0.87)",
    },
    whiteArrow: {
        color: theme.palette.neutral.white,
    },
    black: {
        color: theme.palette.neutral.white,
        backgroundColor: "rgba(0, 0, 0, 0.80)",
    },
    blackArrow: {
        color: "rgba(0, 0, 0, 0.87)",
    },
}));

const InfoTooltip = ({
    children,
    color,
    placement,
    className,
    wrapperClassName,
    icon,
    ...props
}) => {
    const classCustom = useStyles();

    return (
        <div
            className={clsx(classCustom.wrapper, {
                [wrapperClassName]: !!wrapperClassName,
            })}
        >
            <div className={classCustom.content}>{children}</div>
            <Tooltip
                {...props}
                placement={placement}
                classes={{
                    tooltip: clsx(classCustom.tooltip, classCustom[color]),
                    arrow: classCustom[`${color}Arrow`],
                }}
            >
                <span className={`${classCustom.icon} ${className}`}>
                    {icon === "QuestionMarkIcon" && <QuestionMarkIcon />}
                    {icon === "InfoIcon" && <InfoIcon />}
                    {icon === "InfoIconV2" && <InfoV2Icon />}
                </span>
            </Tooltip>
        </div>
    );
};

InfoTooltip.propTypes = {
    color: PropTypes.oneOf(["black", "white"]),
    arrow: PropTypes.bool,
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    icon: PropTypes.oneOf(["InfoIcon", "QuestionMarkIcon", "InfoIconV2"]),
};

InfoTooltip.defaultProps = {
    color: "white",
    arrow: true,
    placement: "top",
    className: "",
    wrapperClassName: "",
    icon: "InfoIcon",
};

export default memo(InfoTooltip);
