/* ------------- Types Definition ------------- */
export const VinaWealthActionType = Object.freeze({
    SetProductDetail: "WealthActionType/SetProductDetail",
    ClearProductDetail: "WealthActionType/ClearProductDetail",
    SetAmountToMove: "WealthActionType/SetAmountToMove",
    SetInfoReceipt: "WealthActionType/SetInfoReceipt",
    SetPassportInfo: "WealthActionType/SetPassportInfo",
    SetCongratulationFailed: "WealthActionType/SetCongratulationFailed",
    Reset: "WealthActionType/Reset",
    GetPendingTransaction: "WealthActionType/GetPendingTransaction",
    GetPendingTransactionSuccess:
        "WealthActionType/GetPendingTransactionSuccess",
    GetPendingTransactionError: "WealthActionType/GetPendingTransactionError",
    GetCompletedTransaction: "WealthActionType/GetCompletedTransaction",
    GetCompletedTransactionSuccess:
        "WealthActionType/GetCompletedTransactionSuccess",
    GetCompletedTransactionError:
        "WealthActionType/GetCompletedTransactionError",
});

/* ------------- Actions Definition ------------- */

const setProductDetail = (payload) => {
    return {
        type: VinaWealthActionType.SetProductDetail,
        payload,
    };
};

const clearProductDetail = () => {
    return {
        type: VinaWealthActionType.ClearProductDetail,
    };
};

const setAmountToMove = (payload) => {
    return {
        type: VinaWealthActionType.SetAmountToMove,
        payload,
    };
};

const setInfoReceipt = (payload) => {
    return {
        type: VinaWealthActionType.SetInfoReceipt,
        payload,
    };
};

const setPassportInfo = (payload) => {
    return {
        type: VinaWealthActionType.SetPassportInfo,
        payload,
    };
};

const setCongratulationFailed = (payload) => {
    return {
        type: VinaWealthActionType.SetCongratulationFailed,
        payload,
    };
};

const reset = (payload) => {
    return {
        type: VinaWealthActionType.Reset,
        payload,
    };
};

const getPendingTransaction = (payload) => {
    return {
        type: VinaWealthActionType.GetPendingTransaction,
        payload,
    };
};

const getPendingTransactionSuccess = (payload) => {
    return {
        type: VinaWealthActionType.GetPendingTransactionSuccess,
        payload,
    };
};

const getPendingTransactionError = () => {
    return {
        type: VinaWealthActionType.GetPendingTransactionError,
    };
};

const getCompletedTransaction = (payload) => {
    return {
        type: VinaWealthActionType.GetCompletedTransaction,
        payload,
    };
};

const getCompletedTransactionSuccess = (payload) => {
    return {
        type: VinaWealthActionType.GetCompletedTransactionSuccess,
        payload,
    };
};

const getCompletedTransactionError = () => {
    return {
        type: VinaWealthActionType.GetCompletedTransactionError,
    };
};

const vinaWealthAction = {
    setProductDetail,
    clearProductDetail,
    setAmountToMove,
    setInfoReceipt,
    setPassportInfo,
    setCongratulationFailed,
    reset,
    getPendingTransaction,
    getPendingTransactionSuccess,
    getPendingTransactionError,
    getCompletedTransaction,
    getCompletedTransactionSuccess,
    getCompletedTransactionError,
};

export default vinaWealthAction;
