import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import { SpendAccountNavigationKey } from "@config/constants";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import useNumber from "@helpers/useNumber";
import useAccounts from "@helpers/useAccounts";
import BillPayment from "@containers/SpendAccount/BillPayment";
import PaymentList from "./PaymentList";

const parentName = "payment-request";

const PaymentRequest = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { formatNumber } = useNumber();
    const { spendAccount } = useAccounts();
    const { generateChildPathname } = usePathname();
    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.BillPayment
                ),
                component: BillPayment,
            },
            {
                path: generateChildPathname(parentName, "list"),
                component: PaymentList,
                default: true,
            },
        ],
        [generateChildPathname]
    );

    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);
    const navbarItems = useMemo(
        () => [
            {
                title: "payment_title",
                icon: "requestpayment",
                path: generateChildPathname(parentName, "list"),
                selected: location.pathname.includes(
                    generateChildPathname(parentName, "list")
                ),
            },
            {
                title: "home_label_bill_pay",
                icon: "paybill",
                path: generateChildPathname(
                    parentName,
                    SpendAccountNavigationKey.BillPayment
                ),
                selected: location.pathname.includes(
                    generateChildPathname(
                        parentName,
                        SpendAccountNavigationKey.BillPayment
                    )
                ),
            },
        ],
        [location, generateChildPathname]
    );

    const bannerSubtitle = useMemo(
        () => (
            <>
                {t("available_to_spend")}
                <b>{formatNumber(spendAccount?.workingBalance)}</b>
            </>
        ),
        [t, formatNumber, spendAccount]
    );

    return (
        <>
            <Banner
                title={t("payment_title")}
                subtitle={bannerSubtitle}
                navbarItems={navbarItems}
            />
            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};

export default memo(PaymentRequest);
