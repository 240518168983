/* eslint-disable react/no-array-index-key */
import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    footerDirection: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttonControl: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey2,
    },
    item: {
        width: 8,
        height: 8,
        background: theme.palette.neutral.grey3,
        margin: theme.spacing(0.5),
        borderRadius: "50%",
        cursor: "pointer",
    },
    active: {
        background: theme.palette.primary.main,
    },
}));

const DependentButtonControls = ({
    setCurrentScreen,
    totalPage,
    pages,
    currentScreen,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const controlNextClick = () => {
        setCurrentScreen((prev) =>
            prev + 1 <= totalPage - 1 ? prev + 1 : prev
        );
    };

    const controlBackClick = () => {
        setCurrentScreen((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
    };

    return (
        <Box className={classes.footerDirection}>
            <ButtonBase
                className={classes.buttonControl}
                onClick={controlBackClick}
            >
                {t("lp_btn_previous")}
            </ButtonBase>
            <div>
                {pages?.map((_, index) => {
                    return (
                        <ButtonBase
                            className={clsx(classes.item, {
                                [classes.active]: currentScreen === index,
                            })}
                            onClick={() => setCurrentScreen(index)}
                            key={`button-${index}`}
                        />
                    );
                })}
            </div>
            <ButtonBase
                className={classes.buttonControl}
                onClick={controlNextClick}
            >
                {t("lb_next")}
            </ButtonBase>
        </Box>
    );
};

export default memo(DependentButtonControls);
