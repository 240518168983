import Content from "@components/Content";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { useEffect, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import termDepositAction from "@redux/actions/term-deposit";
import AutoDepositBanner from "./AutoDepositBanner";
import AutoDepositCard from "./AutoDepositCard";

const AutoDeposit = ({ autoDepositList, getAutoDepositList }) => {
    const history = useHistory();

    const { t } = useTranslation();

    const handleBannerClick = () => {
        history.push("/term-deposit/open");
    };

    useEffect(() => {
        getAutoDepositList();
    }, [getAutoDepositList]);

    const isNotExistedAutoRoll = useMemo(
        () => autoDepositList && autoDepositList.length > 0,
        [autoDepositList]
    );

    return (
        <Content>
            {!isNotExistedAutoRoll && (
                <AutoDepositBanner onClick={() => handleBannerClick()} />
            )}
            {isNotExistedAutoRoll && (
                <Grid container spacing={2}>
                    {autoDepositList.map((recurring, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item sm={6} xs={12} key={index}>
                            <AutoDepositCard {...recurring} />
                        </Grid>
                    ))}
                    <Grid item sm={6} xs={12}>
                        <AutoDepositBanner
                            type="column"
                            onClick={() => handleBannerClick()}
                        />
                    </Grid>
                </Grid>
            )}
        </Content>
    );
};
const mapState = (state) => ({
    autoDepositList: state.termDeposit.autoDepositList.data,
});

const dispatchProps = (dispatch) => ({
    getAutoDepositList: () => dispatch(termDepositAction.getAutoDepositList()),
});
export default connect(mapState, dispatchProps)(memo(AutoDeposit));
