import {
    Box,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    loadingWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    headerList: {
        width: "100%",
        background: "#f1f1f1",
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(2),
    },
    itemWrapper: {
        height: "65vw",
        maxHeight: "400px",
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(4),
        },
    },
    item: {
        height: "100%",
        borderRadius: "12px",
    },
}));

const LixiListLoading = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box className={classes.loadingWrapper}>
            <div className={classes.headerList}>
                <Skeleton width={200} />
            </div>
            <div className={classes.bodyList}>
                <Grid container spacing={isSmall ? 2 : 4}>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.itemWrapper}
                    >
                        <Skeleton variant="rect" className={classes.item} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.itemWrapper}
                    >
                        <Skeleton variant="rect" className={classes.item} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.itemWrapper}
                    >
                        <Skeleton variant="rect" className={classes.item} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.itemWrapper}
                    >
                        <Skeleton variant="rect" className={classes.item} />
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
};

export default LixiListLoading;
