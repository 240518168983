/* eslint-disable jsx-a11y/label-has-associated-control */
import LspAvatar from "@components/LspAvatar";
import { makeStyles } from "@material-ui/core";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    avatar: {
        cursor: "pointer",
    },
    input: {
        display: "none",
    },
}));

const avatarMaxSizeDefault = 1000000;
const acceptedFileExtensionsDefault =
    "image/jpg, image/gif, image/jpeg, image/png";

/**
 * @param {object} props
 * @param {string} props.acceptedFileExtensions
 * @param {number} props.avatarMaxSize*
 * @param {func} props.onError
 * @param {func} props.onUpload *
 * @param {string} props.avatar
 * @param {bool} props.hasTimoMem
 * @param {number} props.avatarSize
 * @param {bool} props.isBase64
 */

const AvatarButtonUploader = ({
    acceptedFileExtensions = acceptedFileExtensionsDefault,
    avatarMaxSize = avatarMaxSizeDefault,
    onError,
    onUpload,
    avatar,
    hasTimoMem,
    avatarSize,
    isBase64,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onDrop = useCallback(
        (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);

            if (!acceptedFileExtensions.includes(file.type)) {
                onError(t("upload_avatar_msg_wrong_extension"));
                return;
            }

            if (file.size > avatarMaxSize) {
                onError(t("upload_payee_avatar_limit_size"));
                return;
            }

            reader.onloadend = () => {
                onUpload(reader.result.toString());
            };
        },
        [t, onError, avatarMaxSize, onUpload, acceptedFileExtensions]
    );

    return (
        <form>
            <input
                accept="image/*"
                id="upload-avatar"
                multiple
                type="file"
                onChange={onDrop}
                className={classes.input}
            />
            <label htmlFor="upload-avatar" className={classes.avatar}>
                <LspAvatar
                    avatar={avatar}
                    hasIcon={hasTimoMem}
                    size={avatarSize}
                    isBase64={isBase64}
                />
            </label>
        </form>
    );
};

AvatarButtonUploader.propTypes = {
    acceptedFileExtensions: PropTypes.string,
    avatarMaxSize: PropTypes.number,
    onError: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    avatar: PropTypes.string,
    hasTimoMem: PropTypes.bool,
    avatarSize: PropTypes.number,
    isBase64: PropTypes.bool,
};

AvatarButtonUploader.defaultProps = {
    acceptedFileExtensions: acceptedFileExtensionsDefault,
    avatarMaxSize: avatarMaxSizeDefault,
    hasTimoMem: false,
    avatarSize: 50,
    avatar: null,
    isBase64: false,
};

export default memo(AvatarButtonUploader);
