import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, IconButton, makeStyles, Paper } from "@material-ui/core";
import InfoTooltip from "@components/InfoTooltip";
import LspTypography from "@components/LspTypography";
import { VisibilityOffIcon, VisibilityOnIcon } from "@components/LspIcon";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => {
    return {
        divider: {
            width: 2,
            backgroundColor: theme.palette.divider,
            [theme.breakpoints.down("xs")]: {
                width: "94%",
                height: 2,
                margin: theme.spacing(2, "auto"),
            },
        },
        itemName: {
            color: theme.palette.white.contrastText,
        },
        itemValue: {
            color: theme.palette.white.contrastText,
            fontSize: theme.typography.pxToRem(24),
            fontWeight: 600,
        },
        gold: {
            color: theme.palette.gold.main,
        },
        blue: {
            color: theme.palette.blue.main,
        },
        orange: {
            color: theme.palette.orange.main,
        },
        red: {
            color: theme.palette.orange.main,
        },
        accountSummaryWrapper: {
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },
        hint: {
            justifyContent: "center",
        },
        eyeIcon: {
            position: "absolute",
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(1),
            "& svg": {
                width: 18,
                height: 18,
            },
        },
    };
});

const defaultHiddenValue = "******";

const AccountSummaryBox = ({
    leftItemId,
    leftItemName,
    rightItemId,
    rightItemName,
    leftItemValue,
    rightItemValue,
    leftItemColor,
    rightItemColor,
    totalToPay,
    totalToPayValue,
    leftItemTooltip,
    rightItemTooltip,
    hiddenBalanceConfig,
}) => {
    const classes = useStyles();

    const {
        enableHideBalance,
        balanceOption,
        toggleBalanceFunc,
    } = hiddenBalanceConfig;

    const displayValue = useCallback(
        (value) => {
            if (enableHideBalance && balanceOption) return defaultHiddenValue;

            return value;
        },
        [enableHideBalance, balanceOption]
    );

    const hiddenBalanceToggle = useCallback(() => {
        if (isFunction(toggleBalanceFunc)) {
            toggleBalanceFunc();
        }
    }, [toggleBalanceFunc]);

    return (
        <Paper component={Box} className={classes.accountSummaryWrapper}>
            <Box flex={1} textAlign="center">
                <LspTypography className={classes.itemName} component="div">
                    {leftItemTooltip && (
                        <InfoTooltip
                            color="black"
                            wrapperClassName={classes.hint}
                            icon="InfoIcon"
                            title={leftItemTooltip}
                            placement="bottom"
                        >
                            {leftItemName}
                        </InfoTooltip>
                    )}
                    {!leftItemTooltip && leftItemName}
                    {enableHideBalance && (
                        <IconButton
                            size="small"
                            onClick={hiddenBalanceToggle}
                            className={classes.eyeIcon}
                        >
                            {balanceOption ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityOnIcon />
                            )}
                        </IconButton>
                    )}
                </LspTypography>
                <LspTypography
                    className={clsx(classes.itemValue, classes[leftItemColor])}
                    datatestid={leftItemId}
                >
                    {displayValue(leftItemValue)}
                </LspTypography>
            </Box>
            <div className={classes.divider} />
            <Box flex={1} textAlign="center">
                <LspTypography className={classes.itemName} component="div">
                    {rightItemTooltip && (
                        <InfoTooltip
                            color="black"
                            wrapperClassName={classes.hint}
                            icon="InfoIcon"
                            title={rightItemTooltip}
                            placement="bottom"
                        >
                            {rightItemName}
                        </InfoTooltip>
                    )}
                    {!rightItemTooltip && rightItemName}
                </LspTypography>
                <LspTypography
                    className={clsx(classes.itemValue, classes[rightItemColor])}
                    datatestid={rightItemId}
                >
                    {displayValue(rightItemValue)}
                </LspTypography>
            </Box>
            {totalToPay && (
                <>
                    <div className={classes.divider} />
                    <Box flex={1} textAlign="center">
                        <LspTypography className={classes.itemName}>
                            {totalToPay}
                        </LspTypography>
                        <LspTypography
                            className={clsx(classes.itemValue, classes.red)}
                        >
                            {displayValue(totalToPayValue)}
                        </LspTypography>
                    </Box>
                </>
            )}
        </Paper>
    );
};

AccountSummaryBox.propTypes = {
    leftItemName: PropTypes.string.isRequired,
    rightItemName: PropTypes.string.isRequired,
    leftItemValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    rightItemValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    leftItemColor: PropTypes.oneOf(["black", "blue", "orange", "gold"]),
    rightItemColor: PropTypes.oneOf(["black", "blue", "orange", "gold"]),
    leftItemTooltip: PropTypes.string,
    rightItemTooltip: PropTypes.string,
    leftItemId: PropTypes.string,
    rightItemId: PropTypes.string,
    hiddenBalanceConfig: PropTypes.shape({
        enableHideBalance: false,
        balanceOption: false,
        toggleBalanceFunc: null,
    }),
};

AccountSummaryBox.defaultProps = {
    leftItemColor: "black",
    rightItemColor: "black",
    leftItemTooltip: "",
    rightItemTooltip: "",
    rightItemId: "",
    leftItemId: "",
    hiddenBalanceConfig: {},
};

export default memo(AccountSummaryBox);
