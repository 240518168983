import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import PageHeaderBack from "@components/PageHeaderBack";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";
import { connect } from "react-redux";

import useNumber from "@helpers/useNumber";
import { useEffect, useCallback } from "react";
import { RESPONSE_CODE } from "@config/constants";
import { useHistory } from "react-router-dom";
import userAction from "@redux/actions/user";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import TermConditionDialog from "@components/TermCondition/TermConditionDialog";
import LspHintInfo from "@components/LspHintInfo";

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    header: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    breakLine: {
        border: `1px solid ${theme.palette.magicalGrey[200]}`,
        marginBottom: theme.spacing(2),
    },
    tips: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
}));

const PreviewNickname = ({
    nickname,
    onBack,
    aliasFree,
    onPreviewSubmit,
    submitFetching,
    aliasNicknameCode,
    resetSubmitOTP,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const { formatNumber } = useNumber();

    const onSubmitHandler = () => {
        if (isFunction(onPreviewSubmit)) {
            onPreviewSubmit();
        }
    };

    const handleBack = useCallback(() => {
        if (isFunction(onBack)) {
            onBack();
        }
    }, [onBack]);

    useEffect(() => {
        switch (aliasNicknameCode) {
            case RESPONSE_CODE.FEE_SUCCESS_ERROR_CREATE:
                history.push("/bank-information/bank-details");
                resetSubmitOTP();
                break;
            case RESPONSE_CODE.CREATE_ALIAS_FAIL:
                handleBack();
                resetSubmitOTP();
                break;
            default:
                break;
        }
    }, [aliasNicknameCode, handleBack, history, onBack, resetSubmitOTP]);

    const openTermCondition = () => {
        const link = t("IBFT:IBFT_nickname_review_link_tnc");

        const url = process.env.REACT_APP_API_URL + link;
        GlobalDialogController.show({
            component: () => (
                <TermConditionDialog
                    header={t("global_label_term_and_condition")}
                    url={url}
                    onClose={() => GlobalDialogController.hide()}
                />
            ),
        });
    };

    return (
        <Content size="sm">
            <Paper>
                <Box p={3}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmitHandler();
                        }}
                    >
                        <div className={classes.header}>
                            <PageHeaderBack onBack={handleBack}>
                                <LspTypography
                                    variant="heading1"
                                    color="primary"
                                >
                                    {t("IBFT:IBFT_nickname_review_header")}
                                </LspTypography>
                            </PageHeaderBack>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <LspTypography variant="body1" color="grey">
                                    {t("IBFT:IBFT_nickname_intro_fee")}
                                </LspTypography>
                                <LspTypography variant="title1" color="black">
                                    {formatNumber(aliasFree?.feeAmount)}
                                </LspTypography>
                                <LspTypography variant="body1" color="grey">
                                    {t("IBFT:IBFT_nickname_intro_fee_other")}
                                </LspTypography>
                            </div>
                            <div className={classes.breakLine} />
                            <div className={classes.body}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <LspTypography
                                            variant="subheading3"
                                            color="grey"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_name"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LspTypography
                                            variant="heading3"
                                            color="black"
                                        >
                                            {nickname}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <LspTypography
                                            variant="subheading3"
                                            color="grey"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_features"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LspTypography
                                            variant="heading3"
                                            color="black"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_features_details"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <LspTypography
                                            variant="subheading3"
                                            color="grey"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_scope"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LspTypography
                                            variant="heading3"
                                            color="black"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_scope_details"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <LspTypography
                                            variant="subheading3"
                                            color="grey"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_noted"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LspTypography
                                            variant="heading3"
                                            color="black"
                                        >
                                            {t(
                                                "IBFT:IBFT_nickname_review_noted_details"
                                            )}
                                        </LspTypography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <div className={classes.tips}>
                                <LspHintInfo
                                    onDirect={openTermCondition}
                                    content={t(
                                        "IBFT:IBFT_nickname_review_confirm"
                                    ).replace(
                                        "%@",
                                        `<a>${t(
                                            "IBFT:IBFT_nickname_review_tnc"
                                        )}</a>`
                                    )}
                                />
                            </div>

                            <LspButton
                                fullWidth
                                type="submit"
                                progressing={submitFetching}
                            >
                                {t("IBFT:IBFT_nickname_review_btn_confirm")}
                            </LspButton>
                        </div>
                    </form>
                </Box>
            </Paper>
        </Content>
    );
};

const mapState = (state) => ({
    aliasFree: state.user.aliasFree?.data,
    aliasFreeStatus: state.user.aliasFree?.status,
    submitFetching: state.user.submitAlias?.fetching,
    aliasNicknameCode: state.user.aliasNickname?.code,
});

const mapDispatch = (dispatch) => ({
    resetSubmitOTP: () => dispatch(userAction.submitOTPNicknameReset()),
});

export default connect(mapState, mapDispatch)(PreviewNickname);
