/* ------------- Types Definition ------------- */
export const BookingActionTypes = Object.freeze({
    Reset: "BookingActionTypes/Reset",
    GetBookingList: "BookingActionTypes/GetBookingList",
    GetBookingListSuccess: "BookingActionTypes/GetBookingListSuccess",
    GetBookingListError: "BookingActionTypes/GetBookingListError",
    SetSelectedDate: "BookingActionTypes/SetSelectedDate",
    SetSelectedTime: "BookingActionTypes/SetSelectedTime",
    SetBookingList: "BookingActionTypes/SetBookingList",
    SetHangoutList: "BookingActionTypes/SetHangoutList",
    SetSelectedHangout: "BookingActionTypes/SetSelectedHangout",
    SetBookingConfigHandler: "BookingActionTypes/SetBookingConfigHandler",

    GetBookingDetail: "BookingActionTypes/GetBookingDetail",
    GetBookingDetailSuccess: "BookingActionTypes/GetBookingDetailSuccess",
    GetBookingDetailError: "BookingActionTypes/GetBookingDetailError",

    SetBookingMethod: "BookingActionTypes/SetBookingMethod",
    SetBookingHangoutProvince: "BookingActionTypes/SetBookingHangoutProvince",
    SetBookingAddressType: "BookingActionTypes/SetBookingAddressType",
    SetBookingAddress: "BookingActionTypes/SetBookingAddress",
});

/* ------------- Actions Definition ------------- */

const reset = () => {
    return {
        type: BookingActionTypes.Reset,
    };
};

const getBookingList = (payload) => {
    return {
        type: BookingActionTypes.GetBookingList,
        payload,
    };
};
const getBookingListSuccess = (payload) => {
    return {
        type: BookingActionTypes.GetBookingListSuccess,
        payload,
    };
};
const getBookingListError = () => {
    return {
        type: BookingActionTypes.GetBookingListError,
    };
};

const setSelectedDate = (payload) => {
    return {
        type: BookingActionTypes.SetSelectedDate,
        payload,
    };
};
const setSelectedTime = (payload) => {
    return {
        type: BookingActionTypes.SetSelectedTime,
        payload,
    };
};

const setBookingList = (payload) => {
    return {
        type: BookingActionTypes.SetBookingList,
        payload,
    };
};

const setHangoutList = (payload) => {
    return {
        type: BookingActionTypes.SetHangoutList,
        payload,
    };
};

const setSelectedHangout = (payload) => {
    return {
        type: BookingActionTypes.SetSelectedHangout,
        payload,
    };
};

const setBookingConfigHandler = (payload) => {
    return {
        type: BookingActionTypes.SetBookingConfigHandler,
        payload,
    };
};

const getBookingDetail = (payload) => {
    return {
        type: BookingActionTypes.GetBookingDetail,
        payload,
    };
};
const getBookingDetailSuccess = (payload) => {
    return {
        type: BookingActionTypes.GetBookingDetailSuccess,
        payload,
    };
};
const getBookingDetailError = () => {
    return {
        type: BookingActionTypes.GetBookingDetailError,
    };
};

const setBookingMethod = (payload) => {
    return {
        type: BookingActionTypes.SetBookingMethod,
        payload,
    };
};
const setBookingHangoutProvince = (payload) => {
    return {
        type: BookingActionTypes.SetBookingHangoutProvince,
        payload,
    };
};
const setBookingAddressType = (payload) => {
    return {
        type: BookingActionTypes.SetBookingAddressType,
        payload,
    };
};
const setBookingAddress = (payload) => {
    return {
        type: BookingActionTypes.SetBookingAddress,
        payload,
    };
};

const bookingActions = {
    reset,
    getBookingList,
    getBookingListSuccess,
    getBookingListError,
    setSelectedDate,
    setSelectedTime,
    setBookingList,
    setHangoutList,
    setSelectedHangout,

    setBookingConfigHandler,
    getBookingDetail,
    getBookingDetailSuccess,
    getBookingDetailError,

    setBookingMethod,
    setBookingHangoutProvince,
    setBookingAddressType,
    setBookingAddress,
};

export default bookingActions;
