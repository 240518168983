import { useTranslation } from "react-i18next";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles, useTheme } from "@material-ui/core";
import useNumber from "@helpers/useNumber";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";
import { ThemeName } from "@config/constants";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    item: {
        background: "transparent",
        boxShadow: theme.shadows[2],
        border: "none",
        borderRadius: theme.shape.radiusLevels[0],
        overflow: "hidden",
        padding: 0,
        cursor: "pointer",
        width: "100%",
        height: "202px",
        display: "block",
        textAlign: "center",
        "&:hover ": {
            "& $italicTxt": {
                visibility: "visible",
                opacity: 1,
            },
        },
    },
    content: {
        height: "142px",
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        position: "relative",
        textAlign: "center",
    },
    icon: {
        fontSize: theme.typography.pxToRem(100),
        color: theme.palette.neutral.white,
        opacity: 0.4,
        display: "inline-block",
        paddingTop: theme.spacing(3),
        height: "100%",
    },
    onTopImg: {
        position: "absolute",
        width: "100%",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "0 auto",
        "& > svg": {
            width: "60px",
        },
        padding: theme.spacing(2),
    },
    italicTxt: {
        "& > span": {
            fontStyle: "initial",
        },
        opacity: 0,
        visibility: "hidden",
        transition: "opacity 600ms, visibility 600ms",
    },
    title: {
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottomLeftRadius: theme.shape.radiusLevels[0],
        borderBottomRightRadius: theme.shape.radiusLevels[0],
        backgroundColor: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    iconWrapper: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: theme.palette.success.main,
        border: `1px solid ${theme.palette.primary.success}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        color: theme.palette.neutral.white,
        marginBottom: theme.spacing(3),
    },

    iconSuccess: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.success}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        color: theme.palette.success.main,
        marginTop: theme.spacing(3),
    },
    successCard: {
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.success.main,
        height: "100%",
    },
}));

const GoalSaveItem = ({
    goalSaveItem,
    addNewSuccess,
    index,
    onItemClick,
    isTransfer,
    themeName,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { formatNumber } = useNumber();

    const itemNotAchieved = (item) => {
        if (item) {
            return (
                item.contributedAmount < item.targetAmount &&
                item.targetAmount > 0 &&
                item.FEPercent < 100
            );
        }
    };

    const itemAchieved = (item) => {
        if (item) {
            return (
                item.FEPercent > 0 &&
                item.targetAmount > 0 &&
                item.contributedAmount >= item.targetAmount
            );
        }
    };

    const iconGoal = (item) => {
        const listIcon = {
            PHONE: "phone",
            ELECTRONICS: "laptop",
            VEHICLE: "vehicle",
            TRAVEL: "travel",
            JEWELRY: "diamond",
            WEDDING: "ring",
            BABY: "baby",
            STUDY: "study",
            OTHER: "other",
        };
        return (
            <Icon
                className={`font-icon icon-${listIcon[item]} ${classes.icon}`}
            />
        );
    };

    const itemClick = (item) => {
        if (isTransfer) {
            return;
        }
        onItemClick(item);
    };

    const contributedAmountColor = useMemo(() => {
        if (goalSaveItem.isOnTrack) {
            return "success";
        }

        if (themeName === ThemeName.Gold) {
            return "white";
        }

        return "gold";
    }, [themeName, goalSaveItem.isOnTrack]);

    return (
        <>
            <div
                className={classes.item}
                onClick={() => itemClick(goalSaveItem)}
                datatestid={`goalItem${index}`}
            >
                {addNewSuccess && index === 0 ? (
                    <div className={classes.successCard}>
                        <LspTypography variant="body1" color="white">
                            {t("gs_msg_create_successfully_1")}
                        </LspTypography>
                        <LspTypography variant="body1" color="white">
                            {t("gs_msg_create_successfully_2")}
                        </LspTypography>
                        <div className={`${classes.iconSuccess}`}>
                            <Icon className={`font-icon icon-ok `} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={classes.content}>
                            {goalSaveItem.linkImage ? (
                                <div
                                    style={{
                                        backgroundImage: `url(${goalSaveItem.linkImage})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        height: "142px",
                                        opacity: 0.4,
                                    }}
                                />
                            ) : (
                                iconGoal(goalSaveItem.goalCategory)
                            )}

                            {itemNotAchieved(goalSaveItem) && (
                                <div className={classes.onTopImg}>
                                    <LspTypography
                                        variant="tiny"
                                        color="white"
                                        className={classes.italicTxt}
                                    >
                                        {t("goal_save_lb_tartget")}
                                        <LspTypography
                                            variant="body2"
                                            color="white"
                                            component="span"
                                        >
                                            {` ${formatNumber(
                                                goalSaveItem.targetAmount
                                            )}`}
                                        </LspTypography>
                                    </LspTypography>

                                    <CircularProgressbar
                                        value={goalSaveItem.FEPercent}
                                        text={`${goalSaveItem.FEPercent}%`}
                                        styles={buildStyles({
                                            textColor:
                                                theme.palette.neutral.white,
                                            pathColor: theme.palette.gold.main,
                                            width: "50px",
                                        })}
                                    />
                                    <LspTypography
                                        variant="body1"
                                        color={contributedAmountColor}
                                    >
                                        {formatNumber(
                                            goalSaveItem.contributedAmount
                                        )}
                                    </LspTypography>
                                    {goalSaveItem.recurringAmount && (
                                        <LspTypography
                                            variant="body2"
                                            color="white"
                                            className={classes.italicTxt}
                                        >
                                            {formatNumber(
                                                goalSaveItem.recurringAmount
                                            )}

                                            <LspTypography
                                                variant="body2"
                                                color="white"
                                                component="i"
                                            >
                                                / {goalSaveItem.FERecurringType}
                                            </LspTypography>
                                        </LspTypography>
                                    )}
                                </div>
                            )}
                            {itemAchieved(goalSaveItem) && (
                                <div className={classes.onTopImg}>
                                    <LspTypography
                                        variant="body2"
                                        color="success"
                                        className={classes.italicTxt}
                                    >
                                        {t("goal_save_lb_achieved")}
                                    </LspTypography>
                                    <div className={`${classes.iconWrapper}`}>
                                        <Icon
                                            className={`font-icon icon-ok `}
                                        />
                                    </div>

                                    <LspTypography
                                        variant="body1"
                                        color="success"
                                    >
                                        {formatNumber(
                                            goalSaveItem.contributedAmount
                                        )}
                                    </LspTypography>

                                    {goalSaveItem.recurringAmount && (
                                        <LspTypography
                                            variant="body2"
                                            color="white"
                                            className={classes.italicTxt}
                                        >
                                            {formatNumber(
                                                goalSaveItem.recurringAmount
                                            )}

                                            <LspTypography
                                                variant="body2"
                                                color="white"
                                                component="i"
                                            >
                                                / {goalSaveItem.FERecurringType}
                                            </LspTypography>
                                        </LspTypography>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className={classes.title}>
                            <div>
                                <LspTypography variant="body2" color="grey">
                                    {goalSaveItem.planName}
                                </LspTypography>
                                <LspTypography
                                    variant="body3"
                                    color="grey"
                                    className={classes.italicTxt}
                                >
                                    {`${t("gm_lb_by")} ${
                                        goalSaveItem.targetDate
                                    }`}
                                </LspTypography>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const stateProps = (state) => ({
    themeName: state.app.themeName,
});

export default connect(stateProps)(GoalSaveItem);
