import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.radiusLevels[1],
        padding: theme.spacing(3),
    },
    item: {
        width: "calc(50% - 8px)",
    },
    box: {
        background: theme.palette.neutral.grey3,
        borderRadius: theme.shape.radiusLevels[1],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const InvitationDetailLoading = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Box display="flex" justifyContent="space-between">
                <div
                    style={{ width: "65%", marginRight: 16, paddingBottom: 20 }}
                >
                    <Box display="flex" justifyContent="space-between">
                        <div>
                            <Skeleton
                                variant="rect"
                                width={80}
                                height={80}
                                style={{ borderRadius: 8 }}
                            />
                        </div>
                        <div style={{ flex: 1, marginLeft: 16 }}>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </Box>
                </div>
                <div style={{ flex: 1 }}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            </Box>
            <Box paddingX={15} paddingY={7} mt={2} className={classes.box}>
                <Skeleton width={120} />
                <Skeleton width={320} />
                <Skeleton width={170} />
            </Box>
        </div>
    );
};

export default InvitationDetailLoading;
