import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    // Handler to call on window resize
    const handleResize = useCallback(() => {
        setWindowSize({
            width: window.outerWidth,
            height: window.outerHeight,
        });
    }, []);

    useEffect(() => {
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);
    return windowSize;
}

const ReactiveViewport = () => {
    const windowSize = useWindowSize();
    const scale = useMemo(() => {
        if (windowSize.width > 600) {
            return "1.0";
        }
        return "0.85";
    }, [windowSize.width]);

    return (
        <Helmet>
            <meta
                name="viewport"
                content={`width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale} user-scalable=no`}
            />
        </Helmet>
    );
};

export default memo(ReactiveViewport);
