/* eslint-disable react/no-array-index-key */
import { ButtonBase, Paper } from "@material-ui/core";
import { memo } from "react";

import { useTranslation } from "react-i18next";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { connect } from "react-redux";
import PageHeaderBack from "@components/PageHeaderBack";
import { useHistory } from "react-router-dom";
import useSharedClasses from "./wealthCreditCardSharedClasses";

const WealthCreditCardIntro = ({ isApplied, onNext, onBack }) => {
    const { t } = useTranslation();
    const sharedClasses = useSharedClasses();
    const history = useHistory();
    const contentIntro = t("wealth:mc_v3_intro_answers", {
        returnObjects: true,
    });

    const whyIntro = t("wealth:mc_v3_reasons_answers", { returnObjects: true });

    const openFaq = () => {
        history.push("/faq?open=credit");
    };

    return (
        <>
            {isApplied && <PageHeaderBack onBack={onBack} />}
            <Paper className={`highlight ${sharedClasses.boxWrapper}`}>
                <LspTypography variant="title2" color="primary">
                    {t("wealth:mc_v3_intro_question")}
                </LspTypography>
                <ul className={sharedClasses.contentList}>
                    {contentIntro?.map((content, index) => {
                        return <li key={index}>{content}</li>;
                    })}
                </ul>
                <LspTypography variant="title2" color="primary">
                    {t("wealth:mc_v3_reasons_question")}
                </LspTypography>
                <ul className={sharedClasses.contentList}>
                    {whyIntro?.map((content, index) => {
                        return <li key={index}>{content}</li>;
                    })}
                </ul>

                <ButtonBase
                    style={{
                        marginBottom: 18,
                        width: "100%",
                    }}
                    onClick={openFaq}
                >
                    <LspTypography variant="hyperlink1" color="success">
                        {t("wealth:mc_v3_more_questions")}
                    </LspTypography>
                </ButtonBase>
            </Paper>
            <LspButton fullWidth onClick={onNext}>
                {isApplied
                    ? t("wealth:mc_v3_btn_how_apply")
                    : t("wealth:mc_v3_btn_apply")}
            </LspButton>
        </>
    );
};

const mapState = (state) => ({
    isApplied: state.wealthCreditCard.isApplied,
});

export default memo(connect(mapState)(WealthCreditCardIntro));
