/* eslint-disable react/no-array-index-key */
import { Box, makeStyles } from "@material-ui/core";
import { memo, useMemo } from "react";
import LspTypography from "@components/LspTypography";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import useNumber from "@helpers/useNumber";

const useStyles = makeStyles((theme) => ({
    itemLine: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.neutral.grey3}`,
    },
    noDivider: {
        borderBottom: "none",
    },
    itemStatus: {
        textAlign: "center",
        position: "absolute",
        top: "50%",
        left: "-50%",
        right: 0,
        width: "100%",
        marginLeft: "11px",
        marginTop: "-11px",
        transform: "rotate(-90deg)",
        color: "white",
        fontSize: 11,
        padding: theme.spacing(0.5, 1),
    },
    cancelled: {
        background: theme.palette.status.error,
    },
    pending: {
        background: theme.palette.status.pending,
    },
    itemInfo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1.75, 2),
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& > div": {
            padding: theme.spacing(0, 2),
            "&:last-child": {
                marginBottom: 0,
            },
            [theme.breakpoints.down("xs")]: {
                marginBottom: theme.spacing(1),
            },
        },
    },
}));

const VINA_WEALTH_ORDER_TYPES = {
    CONTRIBUTION: "1",
    REDEEMED: "2",
    SWITCH_IN: "3",
    SWITCH_OUT: "4",
};

const VINA_WEALTH_TRANSACTION_TYPES = {
    PENDING: "1",
    COMPLETE: "2",
    USER_CANCEL: "3",
    MIO_CANCEL: "4",
};
const VinaWealthTransactionItem = ({ item, individual = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const status = useMemo(() => {
        switch (item?.transactionType) {
            case VINA_WEALTH_TRANSACTION_TYPES.PENDING:
                return {
                    label: t("wealth:vc3_status_pending"),
                    status: individual ? "pending" : "",
                };
            case VINA_WEALTH_TRANSACTION_TYPES.USER_CANCEL:
                return {
                    label: t("wealth:vc3_status_canceled"),
                    status: "cancelled",
                };
            case VINA_WEALTH_TRANSACTION_TYPES.MIO_CANCEL:
                return {
                    label: t("wealth:vc3_status_canceled"),
                    status: "cancelled",
                };
            case VINA_WEALTH_TRANSACTION_TYPES.COMPLETE:
            default:
                return {
                    label: "",
                    status: "",
                };
        }
    }, [item?.transactionType, t, individual]);

    const name = useMemo(() => {
        const prefixLabel = item?.timoTransaction
            ? t("wealth:vc3_transaction_timo")
            : "";
        const value = t(`wealth:vc3_transaction_title_${item?.productKey}`);
        switch (item?.orderType) {
            case VINA_WEALTH_ORDER_TYPES.CONTRIBUTION:
                return {
                    label: `${prefixLabel} ${t(
                        "wealth:vc3_transaction_contribution"
                    )}`,
                    value,
                };
            case VINA_WEALTH_ORDER_TYPES.REDEEMED:
                return {
                    label: `${prefixLabel} ${t(
                        "wealth:vc3_transaction_redeemed"
                    )}`,
                    value,
                };
            case VINA_WEALTH_ORDER_TYPES.SWITCH_IN:
                return {
                    label: `${prefixLabel} ${t(
                        "wealth:vc3_transaction_switch_in"
                    )}`,
                    value,
                };
            case VINA_WEALTH_ORDER_TYPES.SWITCH_OUT:
                return {
                    label: `${prefixLabel} ${t(
                        "wealth:vc3_transaction_switch_out"
                    )}`,
                    value,
                };
            default:
                break;
        }
    }, [item, t]);

    return (
        <div
            className={clsx(classes.itemLine, {
                [classes.noDivider]: !!individual,
            })}
        >
            <div className={`${classes.itemStatus} ${classes[status?.status]}`}>
                {status?.label}
            </div>
            <Box className={classes.itemInfo}>
                <Box style={{ flex: 2 }}>
                    <LspTypography variant="body3">{name?.label}</LspTypography>
                    <LspTypography variant="body2">{name?.value}</LspTypography>
                </Box>
                <Box>
                    <LspTypography variant="body3" color="grey">
                        {t("wealth:vc3_trans_date_lb")}
                    </LspTypography>
                    <LspTypography variant="body2">
                        {item?.priceDate || t("wealth:vc3_lb_na")}
                    </LspTypography>
                </Box>
                <Box>
                    <LspTypography variant="body3" color="grey">
                        {t("wealth:vinawealth3_trans_list_amount_lb")}
                    </LspTypography>
                    <LspTypography variant="body2">
                        {formatNumber(item?.grossAmount) || 0}
                    </LspTypography>
                </Box>
            </Box>
        </div>
    );
};

export default memo(VinaWealthTransactionItem);
