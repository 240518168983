import DoubleButtons from "@components/DoubleButtons";
import LspTypography from "@components/LspTypography";
import { Box, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isFunction } from "lodash";
import ButtonUploader from "@components/AvatarUploader/ButtonUploader";
import ImageUploader from "@components/AvatarUploader/ImageUploader";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.neutral.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(4),
        },
    },

    orLabel: {
        textTransform: "uppercase",
        textAlign: "center",
    },
    iconUpload: {
        fontSize: theme.typography.pxToRem(100),
        color: theme.palette.primary.main,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
    },
    errorMessage: {
        color: theme.palette.status.error,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttons: {
        maxWidth: 400,
        width: "100%",
        "& button": {
            marginTop: 0,
        },
    },
}));

const avatarMaxSize = 1;
const acceptedFileExtensions = "image/jpg, image/gif, image/jpeg, image/png";

const AvatarUploader = ({ onCancel, onSubmit }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [files, setFiles] = useState(null);
    const [warningMessage, setWarningMessage] = useState("");

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(files?.preview);
        },
        [files]
    );

    const onCancelHandler = () => {
        if (isFunction(onCancel)) {
            onCancel();
        }
    };

    const onSubmitHandler = useCallback(() => {
        if (isFunction(onSubmit)) {
            onSubmit(files);
        }
    }, [files, onSubmit]);

    const onUploadHandler = (file) => {
        setFiles(file);
    };

    return (
        <Box className={classes.wrapper}>
            <LspTypography variant="heading2" color="grey">
                {t("profile_title_modal_upload_photo")}
            </LspTypography>
            <ButtonUploader
                onError={setWarningMessage}
                onUpload={onUploadHandler}
                avatarMaxSize={avatarMaxSize}
                acceptedFileExtensions={acceptedFileExtensions}
            />

            <ImageUploader
                onError={setWarningMessage}
                onUpload={onUploadHandler}
                avatarMaxSize={avatarMaxSize}
                acceptedFileExtensions={acceptedFileExtensions}
                files={files}
            />
            <LspTypography color="error" className={classes.errorMessage}>
                {warningMessage}
            </LspTypography>

            <div className={classes.buttons}>
                <DoubleButtons
                    primaryButton={{
                        label: t("manage_device_text_save"),
                        onClick: onSubmitHandler,
                        disable: files === null,
                    }}
                    secondaryButton={{
                        label: t("lb_cancel"),
                        onClick: onCancelHandler,
                    }}
                />
            </div>
        </Box>
    );
};

export default AvatarUploader;
