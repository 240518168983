/* eslint-disable react/forbid-prop-types */
import { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        minHeight: "101vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(2),
        maxWidth: 450,
        paddingBottom: theme.spacing(6),
    },
    formContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    rootMobile: {
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            minHeight: "unset",
        },
    },
    containerMobile: {
        [theme.breakpoints.down("xs")]: {
            flex: 1,
            padding: 0,
            maxWidth: "none",
        },
    },
    formContainerMobile: {
        [theme.breakpoints.down("xs")]: {
            height: "100vh",
            padding: 0,
        },
    },
}));

const ContentModal = ({ children, supportMobile }) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.root} ${supportMobile && classes.rootMobile}`}
        >
            <Grow in>
                <div
                    className={`${classes.container} ${
                        supportMobile && classes.containerMobile
                    }`}
                >
                    <div
                        className={`${classes.formContainer} ${
                            supportMobile && classes.formContainerMobile
                        }`}
                    >
                        {children}
                    </div>
                </div>
            </Grow>
        </div>
    );
};

ContentModal.propTypes = {
    children: PropTypes.node,
    supportMobile: PropTypes.bool,
};

ContentModal.defaultProps = {
    children: <></>,
    supportMobile: false,
};

export default memo(ContentModal);
