import { memo, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { GENDER_LIST } from "@config/constants";
import LspTypography from "@components/LspTypography";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    label: {
        textTransform: "uppercase",
        marginTop: theme.spacing(1.5),
    },
}));

const OwnerInfoReview = ({ ownerPolicy, parentClasses }) => {
    const { t } = useTranslation();
    const sharedClasses = useClassesShared();
    const classes = useStyles();

    const gender = useMemo(() => {
        return ownerPolicy?.gender === GENDER_LIST[0].cfgKey
            ? t(GENDER_LIST[0].cfgValue)
            : t(GENDER_LIST[1].cfgValue);
    }, [ownerPolicy?.gender, t]);

    return (
        <>
            <Box
                className={clsx(sharedClasses.formHeader, {
                    [parentClasses?.header]: true,
                })}
            >
                <LspTypography variant="body2">{`${t(
                    "wealth_label_person"
                )} 1`}</LspTypography>
                <LspTypography variant="body2" color="grey">
                    {t("wealth:GE00098")}
                </LspTypography>
            </Box>
            <Box className={sharedClasses.formBody}>
                <LspTypography variant="body2" className={classes.label}>
                    {t("wealth:GE00011")}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {ownerPolicy?.fullName}
                </LspTypography>

                <LspTypography variant="body2" className={classes.label}>
                    {t("lb_gender")}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {gender}
                </LspTypography>

                <LspTypography variant="body2" className={classes.label}>
                    {t("wealth:GE00074")}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {ownerPolicy?.dateOfBirth}
                </LspTypography>

                <LspTypography variant="body2" className={classes.label}>
                    {t("wealth:GE00075")}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {ownerPolicy?.legalId}
                </LspTypography>

                <LspTypography variant="body2" className={classes.label}>
                    {t("wealth:GE00076")}
                </LspTypography>
                <LspTypography variant="body2" color="grey">
                    {ownerPolicy?.nationality?.value}
                </LspTypography>
            </Box>
        </>
    );
};

const mapState = (state) => ({
    ownerPolicy: state.insurance.ownerPolicy,
});

export default memo(connect(mapState)(OwnerInfoReview));
