import PropTypes from "prop-types";

import { Box, Icon, IconButton, makeStyles } from "@material-ui/core";
import { memo, useState } from "react";
import clsx from "clsx";
import LspTypography from "@components/LspTypography";
import { ArrowDownIcon } from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    notificationBox: {
        borderRadius: theme.shape.radiusLevels[1],
        overflow: "hidden",
        boxShadow: theme.shadows[1],
    },
    warning: {
        background: theme.palette.status.error,
    },
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        padding: theme.spacing(1, 2),
    },
    closeIcon: {
        color: theme.palette.neutral.white,
        fontSize: 18,
        position: "absolute",
        right: 0,
    },
    icon: {
        color: theme.palette.neutral.white,
        fontSize: 20,
        marginRight: theme.spacing(1),
    },
    body: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2, 4),
        transition: "all 0.3s",
    },
    collapse: {
        height: 0,
        padding: 0,
        "& a, p": {
            opacity: 0,
        },
    },
}));

/**
 * @typedef NotificationBoxProps
 * @type {object}
 * @property {{"warning"|"success"}} color
 * @property {string} className
 * @property {string} children
 * @property {string} title
 * @property {string} icon
 */

/**
 * @param {NotificationBoxProps} props
 */

const NotificationBox = ({ color, className, children, title, icon }) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(true);

    const toggleCollapse = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Box
            className={clsx(classes.notificationBox, {
                [classes.className]: !!className,
            })}
        >
            <Box
                className={clsx(classes.header, {
                    [classes.warning]: color === "warning",
                })}
            >
                <Icon className={`font-icon icon-${icon} ${classes.icon}`} />
                <LspTypography color="white">{title}</LspTypography>

                {expanded && (
                    <IconButton
                        className={`font-icon icon-X  ${classes.closeIcon}`}
                        onClick={toggleCollapse}
                    />
                )}
                {!expanded && (
                    <IconButton
                        onClick={toggleCollapse}
                        className={`${classes.closeIcon}`}
                    >
                        <ArrowDownIcon />
                    </IconButton>
                )}
            </Box>
            <Box
                className={clsx(classes.body, {
                    [classes.collapse]: !expanded,
                })}
            >
                {children}
            </Box>
        </Box>
    );
};

NotificationBox.propTypes = {
    color: PropTypes.oneOf(["warning", "success"]),
    title: PropTypes.string,
    icon: PropTypes.string,
};

NotificationBox.defaultProps = {
    color: "warning",
    title: "",
    icon: "notice",
};

export default memo(NotificationBox);
