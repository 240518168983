import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import querystring from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import JSEncrypt from "jsencrypt";

import { Box, Paper } from "@material-ui/core";
import Content from "@components/Content";
import cardManagementService from "@services/card-management";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import LspTranslation from "@components/LspTranslation";
import useOTP from "@helpers/useOTP";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import PageHeaderBack from "@components/PageHeaderBack";
import LspTextField from "../../components/LspTextField";
import LspButton from "../../components/LspButton";

import { REGEX_PATTERN, RESPONSE_CODE } from "../../config/constants";
import { CardManagementNavigation } from "./constant";

const crypt = new JSEncrypt();
crypt.setPublicKey(process.env.REACT_APP_RSAC);

const cryptMambu = new JSEncrypt();
cryptMambu.setPublicKey(process.env.REACT_APP_RSAC_CORE);

const CardChangePin = () => {
    const { replaceSpecialCharacter } = useInputSpecialCharacter();

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const params = querystring.parse(location.search);
    const { cardId } = params;

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [submitting, setSubmitting] = useState(false);
    const maxLengthPin = 6;
    const [pinRules, setPinRules] = useState(null);
    const unmounted = useRef(false);

    const { register, handleSubmit, errors, getValues, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            currentPin: "",
            newPin: "",
            verifyNewPin: "",
        },
    });

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getPinRules = useCallback(() => {
        const pinCheck = t("sysParamContent:PinCheck").split(",");
        setPinRules(
            pinCheck.map((r) => {
                return {
                    name: r,
                    rule: t(`sysParamContent:${r}`),
                    error: t(`ekyc:${r}_Match_Error`),
                };
            })
        );
    }, [t]);

    useEffect(() => {
        if (!cardId) {
            history.push(CardManagementNavigation.Debit);
        } else {
            getPinRules();
        }
    }, [getPinRules, cardId, history]);

    const changePinResponseHandler = (code) => {
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                history.push(CardManagementNavigation.Debit);
                AlertController.show(
                    <LspTranslation i18nKey="mc_lb_change_pin_success" />,
                    AlertType.Success
                );
                break;
            case RESPONSE_CODE.PIN_FAILED_NOT_BLOCK:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "problem",
                        header: "mc_lb_title_warning",
                        content: "mc_lb_content_warning",
                        button: "lb_ok",
                    },
                });
                break;
            case RESPONSE_CODE.PIN_FAILED_BLOCK:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        icon: "problem",
                        header: "mc_lb_title_change_pin_not_success",
                        content: "mc_lb_content_forgot_pin_warning",
                        button: "lb_btn_ok_got_it",
                    },
                    onClose: () => {
                        history.push(CardManagementNavigation.Debit);
                    },
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const commitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            cardId,
            ...dataToPost,
        };
        const { data } = await cardManagementService.commitOtpChangePin(
            payload
        );
        setLoading(false);
        closeOTPDialog();
        changePinResponseHandler(data.code);
    };

    const changePinHandler = async (formData) => {
        if (
            !formData.currentPin ||
            !formData.newPin ||
            !formData.verifyNewPin
        ) {
            return;
        }

        setSubmitting(true);

        const encryptedCurrentPin = crypt.encrypt(formData.currentPin);
        const encryptedCurrentPinMambu = cryptMambu.encrypt(
            formData.currentPin
        );
        const encryptedPin = crypt.encrypt(formData.newPin);
        const encryptedPinMambu = cryptMambu.encrypt(formData.newPin);

        const dataToPost = {
            cardId,
            newPIN: encryptedPin,
            newPINMambu: encryptedPinMambu,
            currentPIN: encryptedCurrentPin,
            currentPINMambu: encryptedCurrentPinMambu,
        };

        const response = await cardManagementService.changePin(dataToPost);

        if (unmounted.current) return;

        setSubmitting(false);

        const { code, data } = response.data;

        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: commitOtp,
            });
            return;
        }

        changePinResponseHandler(code);
    };

    const pinValidation = () => {
        const newPin = getValues("newPin");

        for (let i = 0; i < pinRules.length; i++) {
            const currentPinRule = pinRules[i];
            const regexString = currentPinRule.rule.replace("\\\\", "\\");
            const regex = new RegExp(regexString, "g");
            const isInValid = regex.test(newPin);
            if (isInValid) {
                return currentPinRule.error;
            }
        }
    };

    const verifyPinValidation = () => {
        const newPin = getValues("newPin");
        const verifyNewPin = getValues("verifyNewPin");
        if (newPin !== verifyNewPin) {
            return t("mc_lb_enter_pin_error");
        }
    };

    const onChangeCurrentPin = (e) => {
        const currentPin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });
        setValue("currentPin", currentPin);
    };

    const onChangeNewPin = (e) => {
        const newPin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });
        setValue("newPin", newPin);
    };

    const onChangeVerifyNewPin = (e) => {
        const verifyNewPin = replaceSpecialCharacter({
            pattern: REGEX_PATTERN.NUMBER_REPLACEMENT,
            text: e.target.value,
        });

        setValue("verifyNewPin", verifyNewPin);
    };

    const onBack = () => {
        history.goBack();
    };

    return (
        <Content size="sm">
            <Paper>
                <Box p={3}>
                    <form onSubmit={handleSubmit(changePinHandler)}>
                        <PageHeaderBack onBack={onBack}>
                            <div>{t("mc_lb_title_change_pin")}</div>
                        </PageHeaderBack>
                        <div>
                            <LspTextField
                                error={!!errors.currentPin}
                                name="currentPin"
                                label={t("card_txt_placeholder_current_pin")}
                                helperText={errors.currentPin?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                    }),
                                    maxLength: maxLengthPin,
                                    type: "password",
                                }}
                                onChange={onChangeCurrentPin}
                            />
                            <LspTextField
                                error={!!errors.newPin}
                                name="newPin"
                                label={t("card_txt_placeholder_new_pin")}
                                helperText={errors.newPin?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: () => pinValidation(),
                                    }),
                                    maxLength: maxLengthPin,
                                    type: "password",
                                }}
                                onChange={onChangeNewPin}
                            />

                            <LspTextField
                                error={!!errors.verifyNewPin}
                                name="verifyNewPin"
                                label={t("card_txt_placeholder_new_pin")}
                                helperText={errors.verifyNewPin?.message || " "}
                                inputProps={{
                                    ref: register({
                                        required: t("msg_we_need_this"),
                                        validate: () => verifyPinValidation(),
                                    }),
                                    maxLength: maxLengthPin,
                                    type: "password",
                                }}
                                onChange={onChangeVerifyNewPin}
                            />
                        </div>
                        <div>
                            <LspButton
                                progressing={submitting}
                                fullWidth
                                type="submit"
                            >
                                {t("lb_next")}
                            </LspButton>
                        </div>
                    </form>
                </Box>
            </Paper>
        </Content>
    );
};

export default CardChangePin;
