import LspTypography from "@components/LspTypography";
import {
    Box,
    Divider,
    Icon,
    IconButton,
    makeStyles,
    Popover,
} from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import useAccounts from "@helpers/useAccounts";
import { useTranslation } from "react-i18next";
import LspBox from "@components/LspBox";
import Interweave from "interweave";
import LspButton from "@components/LspButton";
import AlertController from "@helpers/controllers/AlertController";
import CopyToClipboard from "react-copy-to-clipboard";
import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    refCode: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: theme.palette.background.default,
        padding: theme.spacing(0.25, 1, 0.25, 2),
        borderRadius: theme.shape.radiusLevels[0],
        color: theme.palette.primary.main,
    },
    refIcon: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    shareAccount: {
        fontSize: 12,
        cursor: "pointer",
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            right: 0,
            top: theme.spacing(1.25),
        },
    },
    shareIcon: {
        marginLeft: theme.spacing(1),
        color: theme.palette.status.success,
        fontSize: 12,
    },
    refNoDialogWrapper: {
        width: "100%",
        maxWidth: 480,
    },
    refNoDialogHeader: {
        margin: theme.spacing(1),
        textTransform: "uppercase",
    },
    refNoDialogContent: {
        padding: theme.spacing(2),
        textAlign: "center",
    },
    refNoDialogFooter: {
        background: theme.palette.primary.main,
        padding: theme.spacing(2, 4),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    copyButton: {
        display: "inline",
    },
}));

const ProfileAccountInfo = ({ user }) => {
    const classes = useStyles();
    const className = useProfileSharedClasses();
    const history = useHistory();

    const { t } = useTranslation();
    const { spendAccount } = useAccounts();
    const [refNoDialogEl, setRefNoDialogEl] = useState(null);

    const shareBankAccount = () => {
        history.push("/bank-information");
    };

    const toggleRefCodeDialog = useCallback((event) => {
        setRefNoDialogEl((el) => (el ? null : event.currentTarget));
    }, []);

    const copyHandler = () => {
        AlertController.show(t("if_btn_click_copy_clipboard"));
    };

    return (
        <LspBox className={className.wrapperBox}>
            <Box className={className.headerWrapper}>
                <Icon
                    className={`font-icon icon-accountinfo ${className.headerIcon}`}
                />
                <LspTypography variant="title2" className={className.header}>
                    {t("user_profile_account_info_lb")}
                </LspTypography>

                <LspTypography
                    color="success"
                    className={classes.shareAccount}
                    onClick={shareBankAccount}
                >
                    {t("profile_share_bank_detail_label")}
                    <Icon
                        className={`font-icon icon-sharebankinfo ${classes.shareIcon} `}
                    />
                </LspTypography>
            </Box>

            <Divider className={className.divider} />

            <div className={className.line}>
                <div>
                    <Icon
                        className={`font-icon icon-bank ${className.leftIcon}`}
                    />
                    <LspTypography
                        variant="heading2"
                        component="span"
                        color="grey"
                    >
                        {t("lb_account_number")}
                    </LspTypography>
                    {": "}
                    <LspTypography
                        variant="body1"
                        component="span"
                        color="grey"
                    >
                        {spendAccount?.no}
                    </LspTypography>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}
                >
                    <div className={classes.refCode}>
                        <LspTypography component="span" color="primary">
                            {t("profile_label_personal_referral_code")}
                        </LspTypography>
                        : &nbsp;
                        <LspTypography
                            component="span"
                            color="primary"
                            variant="heading2"
                        >
                            {user?.referralCode}
                        </LspTypography>
                        <div>
                            <IconButton onClick={toggleRefCodeDialog}>
                                <Icon
                                    className={`font-icon icon-help ${classes.refIcon}`}
                                />
                            </IconButton>

                            <Popover
                                open={Boolean(refNoDialogEl)}
                                anchorEl={refNoDialogEl}
                                onClose={toggleRefCodeDialog}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <div className={classes.refNoDialogWrapper}>
                                    <div className={classes.refNoDialogContent}>
                                        <LspTypography
                                            variant="heading1"
                                            color="primary"
                                            className={
                                                classes.refNoDialogHeader
                                            }
                                        >
                                            {t(
                                                "profile_lb_popover_refferral_code"
                                            )}
                                        </LspTypography>
                                        <LspTypography color="grey">
                                            <Interweave
                                                content={t(
                                                    "profile_content_popover_referral_code"
                                                )}
                                            />
                                        </LspTypography>
                                    </div>
                                    <div className={classes.refNoDialogFooter}>
                                        <LspTypography color="white">
                                            {user?.invite?.inviteLink}
                                        </LspTypography>

                                        <CopyToClipboard
                                            text={user?.invite?.inviteLink}
                                            onCopy={copyHandler}
                                        >
                                            <LspButton
                                                variant="secondary"
                                                size="small"
                                                className={classes.copyButton}
                                            >
                                                {t(
                                                    "profile_btn_copy_popover_referral_code"
                                                )}
                                            </LspButton>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>

            <Divider className={className.divider} />

            <div className={className.line}>
                <div>
                    <Icon
                        className={`font-icon icon-phone ${className.leftIcon}`}
                    />

                    <LspTypography
                        variant="heading2"
                        component="span"
                        color="grey"
                    >
                        {t("payee_bank_name_label")}
                    </LspTypography>
                    {": "}
                    <LspTypography
                        variant="body1"
                        component="span"
                        color="grey"
                    >
                        {t("mm_label_bank_name_timo")}
                    </LspTypography>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                    }}
                >
                    <LspTypography component="span" color="grey">
                        {t("profile_label_swift_code")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {user?.swiftCode}
                    </LspTypography>
                </div>
            </div>
        </LspBox>
    );
};

export default memo(ProfileAccountInfo);
