import { Box, Paper, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { memo } from "react";
import RolloverMethodGroup from "./RolloverMethodGroup";

const useStyles = makeStyles((theme) => ({
    headerCell: {
        color: theme.palette.white.contrastText,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.background.header,
    },
    bodyCell: {},
}));

const RolloverMethod = ({ onSelected, selected, showRollover, type }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Box component={Paper} overflow="hidden" mb={3}>
                <div className={classes.headerCell}>
                    {t("TD:td_rollover_method")}
                </div>
                <div className={classes.bodyCell}>
                    <RolloverMethodGroup
                        onSelected={onSelected}
                        selected={selected}
                        showRollover={showRollover}
                    />
                </div>
            </Box>
        </>
    );
};

RolloverMethod.propTypes = {
    showRollover: PropTypes.bool,
    selected: PropTypes.string,
};

RolloverMethod.defaultProps = {
    showRollover: true,
    selected: "",
};

export default memo(RolloverMethod);
