import React, { useMemo } from "react";
import { Dialog, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import logo from "@assets/images/logo-light.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    logo: {
        width: 150,
        animation: `$loading 1800ms infinite ${theme.transitions.easing.easeInOut}`,
        [theme.breakpoints.up("sm")]: {
            width: 200,
        },
    },
    bgGradient: {
        background: `linear-gradient(to right, ${theme.palette.purple.main} 0%, ${theme.palette.purple.main} 25%, ${theme.palette.blue.main} 25%, ${theme.palette.blue.main} 50%, ${theme.palette.orange.main} 50%, ${theme.palette.orange.main} 75%, ${theme.palette.gold.main} 75%, ${theme.palette.gold.main} 100%)`,
    },
    bgPurple: {
        background: theme.palette.purple.main,
    },
    bgBlue: {
        background: theme.palette.blue.main,
    },
    bgOrange: {
        background: theme.palette.orange.main,
    },
    bgGold: {
        background: theme.palette.gold.main,
    },
    "@keyframes loading": {
        "0%": {
            transform: "translateY(-20%)",
        },
        "60%": {
            transform: "translateY(10%)",
        },
        "100%": {
            transform: "translateY(-20%)",
        },
    },
    wrapper: {
        zIndex: `1299 !important`,
    },
}));

const bgClassNames = ["bgPurple", "bgBlue", "bgOrange", "bgGold", "bgGradient"];

const LoadingScreen = ({ open }) => {
    const classes = useStyles();
    const theme = useTheme();
    const bgClassName = useMemo(() => {
        const idx = Math.floor(Math.random() * 5);
        return bgClassNames[idx];
    }, []);

    const bgClassColor = {
        bgPurple: theme.palette.purple.main,
        bgBlue: theme.palette.blue.main,
        bgOrange: theme.palette.orange.main,
        bgGold: theme.palette.gold.main,
        bgGradient: "",
    };

    return (
        <Dialog
            fullScreen
            open={open}
            classes={{
                root: classes.wrapper,
            }}
        >
            <Helmet
                title={process.env.REACT_APP_NAME}
                meta={[
                    {
                        name: `theme-color`,
                        content: bgClassName ? bgClassColor[bgClassName] : "",
                    },
                ]}
            />
            <div className={clsx(classes.root, classes[bgClassName])}>
                <img className={classes.logo} src={logo} alt="logo" />
            </div>
        </Dialog>
    );
};

export default React.memo(LoadingScreen);
