import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from "react-router-dom";
import usePathname from "@helpers/usePathname";
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import { Icon, makeStyles, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import querystring from "query-string";
import TermDepositForeignerBoxWarning from "@containers/TermDeposit/TermDepositForeignerBoxWarning";
import { TermDepositNavigationKey } from "@config/constants";
import ContentWrapper from "@components/ContentWrapper";

import LspTypography from "@components/LspTypography";
import Rocket from "@assets/images/rocket.png";
import { CloseIcon } from "@components/LspIcon";
import ActiveList from "./ActiveList";
import HistoryList from "./HistoryList";
import TermDepositBanner from "./TermDepositBanner";
import InterestRatesDialog from "./InterestRatesDialog";
import OpenTermDeposit from "./OpenTermDeposit";
import PartialWithdraw from "./PartialWithdraw/PartialWithdraw";
import PreviewWithdraw from "./PartialWithdraw/PreviewWithdraw";

const parentName = "term-deposit";
const useStyles = makeStyles((theme) => ({
    overdraftBox: {
        borderRadius: theme.shape.borderRadius,
        background: "rgba(86, 61, 130, 0.10)",
        padding: theme.spacing(3),
        position: "relative",
        cursor: "pointer",
    },
    overdraftBoxTitle: {
        marginBottom: theme.spacing(1),
    },
    overdraftBoxIMG: {
        position: "absolute",
        bottom: -4,
        right: 16,
        width: 150,
        zIndex: -1,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    overdraftBoxClose: {
        top: 0,
        position: "absolute",
        right: 0,
        cursor: "pointer",
    },
}));

const TermDeposit = () => {
    const classes = useStyles();

    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [ratesDialog, setRatesDialog] = useState(false);
    const { generateChildPathname } = usePathname();

    const location = useLocation();
    const params = querystring.parse(location.search);
    const isOpenRate = params?.open === "rate";

    const openTermDepositPath = useMemo(() => {
        return generateChildPathname(
            parentName,
            TermDepositNavigationKey.OpenTermDeposit
        );
    }, [generateChildPathname]);

    const showActions = useMemo(() => {
        if (openTermDepositPath === pathname) {
            return false;
        }
        return true;
    }, [openTermDepositPath, pathname]);

    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(
                    parentName,
                    TermDepositNavigationKey.Active
                ),
                component: ActiveList,
                default: true,
            },
            {
                path: generateChildPathname(
                    parentName,
                    TermDepositNavigationKey.Active,
                    "tdId"
                ),
                component: ActiveList,
            },
            {
                path: generateChildPathname(
                    parentName,
                    TermDepositNavigationKey.History
                ),
                component: HistoryList,
            },
            {
                path: openTermDepositPath,
                component: OpenTermDeposit,
            },
            {
                path: generateChildPathname(
                    parentName,
                    TermDepositNavigationKey.PartialWithdraw,
                    "tdId"
                ),
                component: PartialWithdraw,
            },
            {
                path: generateChildPathname(
                    parentName,
                    TermDepositNavigationKey.PreviewWithdraw,
                    "tdId"
                ),
                component: PreviewWithdraw,
            },
        ],
        [generateChildPathname, openTermDepositPath]
    );

    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);

    const openTermDeposit = useCallback(() => {
        history.push(openTermDepositPath);
    }, [history, openTermDepositPath]);

    const openRatesDialog = useCallback(() => {
        setRatesDialog(true);
    }, [setRatesDialog]);

    const closeRatesDialog = useCallback(() => {
        if (isOpenRate) {
            history.replace({
                search: "",
            });
        }
        setRatesDialog(false);
    }, [setRatesDialog, isOpenRate, history]);

    useEffect(() => {
        if (isOpenRate) {
            openRatesDialog();
        }
    }, [isOpenRate, openRatesDialog, history]);

    const onCloseHandler = () => {};

    return (
        <>
            <TermDepositBanner />
            <TermDepositForeignerBoxWarning />
            <ContentWrapper>
                {showActions && (
                    <Content
                        boxProps={{
                            mb: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <LspButton
                            mr={1}
                            variant="secondary"
                            buttonProps={{
                                startIcon: (
                                    <Icon className="font-icon icon-createTD" />
                                ),
                                datatestid: "openTDBtn",
                            }}
                            onClick={openTermDeposit}
                        >
                            {t("td_btn_open")}
                        </LspButton>
                        <LspButton
                            ml={1}
                            variant="secondary"
                            buttonProps={{
                                startIcon: (
                                    <Icon className="font-icon icon-Percentage" />
                                ),
                                datatestid: "interestRateBtn",
                            }}
                            onClick={openRatesDialog}
                        >
                            {t("hm_btn_lb_interest_rate")}
                        </LspButton>
                    </Content>
                )}
                {/* Hold */}
                {/* <Content>
                    <div className={classes.overdraftBox}>
                        <LspTypography
                            variant="heading2"
                            className={classes.overdraftBoxTitle}
                        >
                            {t(
                                "overdraft:overdraft_result_success_description"
                            )}
                        </LspTypography>
                        <LspTypography variant="hyperlink2" color="primary">
                            {t("view_details")}
                        </LspTypography>
                        <div className={classes.overdraftBoxIMG}>
                            <img src={Rocket} alt="Rocket" />
                        </div>
                        <IconButton
                            className={classes.overdraftBoxClose}
                            onClick={onCloseHandler}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Content> */}
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                                exact
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
                {ratesDialog && (
                    <InterestRatesDialog
                        open={ratesDialog}
                        onClose={closeRatesDialog}
                    />
                )}
            </ContentWrapper>
        </>
    );
};

export default memo(TermDeposit);
