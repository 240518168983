import { RESPONSE_CODE } from "@config/constants";

import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import OTPController from "./OTPController";

export default class GlobalDialogController {
    static open;

    static close;

    static otpError;

    static directLinkFunc;

    static setOpen(open) {
        this.open = open;
    }

    static setClose(close) {
        this.close = close;
    }

    static setOtpError(error) {
        this.otpError = error;
    }

    static setDirectLink(directLinkFunc) {
        this.directLinkFunc = directLinkFunc;
    }

    static directTo(link) {
        this.directLinkFunc(link);
    }

    // Handler for quick code
    static randomId = "jex8zk";

    static cachedName = `lspStatus_${this.randomId}`;

    static isRequiredQuickCode() {
        const status = JSON.parse(localStorage.getItem(this.cachedName));
        // console.log("status: ", status?.required);
        // console.log("this.opening: ", this.opening);
        return status?.required;
    }

    /**
     * @param {{component: component, onClose: function, size: string}} dialogOptions
     */

    static show({ component, onClose, size }) {
        setTimeout(() => {
            this.open({
                component,
                onClose,
                type: GlobalDialogTypes.Component,
                size,
            });
        }, 500);
    }

    /**
     * @param {{errorCode: number|string onClose: function, type: GlobalDialogTypes, data}} dialogOptions
     */
    static showError({
        errorCode,
        onClose,
        data,
        type = GlobalDialogTypes.GeneralError,
    }) {
        // prevent show error while quick code is opening
        if (
            this.isRequiredQuickCode() &&
            errorCode !== RESPONSE_CODE.UNAUTHORIZED
        ) {
            return;
        }

        if (type === GlobalDialogTypes.FinancialError && !data) {
            // eslint-disable-next-line no-console
            console.warn(
                `'data' is required with type === GlobalDialogTypes.FinancialError`
            );
        }

        if (errorCode === RESPONSE_CODE.FE_CANCEL_TOKEN) {
            return;
        }

        if (OTPController.opening) {
            if (
                errorCode === RESPONSE_CODE.INCORRECT_VALUE ||
                errorCode === RESPONSE_CODE.MISMATCH_VALUE
            ) {
                this.otpError("sc_ms_input_otp_wrong_one_time");
                return;
            }

            if (errorCode === RESPONSE_CODE.OTP_EXPIRED) {
                this.otpError("sc_ms_otp_expired");
                return;
            }
        }

        this.open({
            errorCode,
            type,
            onClose,
            data,
        });
    }

    /**
     * @param {{errorCode: number|string onClose: function, data}} dialogOptions
     */
    static showFinancialError({ errorCode, onClose, data }) {
        // prevent show error while quick code is opening
        if (
            this.isRequiredQuickCode() &&
            errorCode !== RESPONSE_CODE.UNAUTHORIZED
        ) {
            return;
        }

        switch (errorCode) {
            case RESPONSE_CODE.INCORRECT_VALUE:
            case RESPONSE_CODE.MISMATCH_VALUE:
                this.otpError("sc_ms_input_otp_wrong_one_time");
                break;
            case RESPONSE_CODE.OTP_EXPIRED:
                this.otpError("sc_ms_otp_expired");
                break;
            default:
                this.open({
                    errorCode,
                    type: GlobalDialogTypes.FinancialError,
                    onClose,
                    data,
                });
                break;
        }
    }

    /**
     *
     * @typedef DoubleButton
     * @type {object}
     * @property {string} label
     * @property {function} onClick
     */

    /**
     * @typedef DialogInfo
     * @type {object}
     * @property {string} iconImage - use for Error.svg, Warning.sv, Success.svg icon.
     * @property {string} icon - icon name.
     * @property {string} [header] - header translation key.
     * @property {string} [content] - content translation key.
     * @property {string} [translatedContent] - translatedContent key.
     * @property {string} [highlight]
     * @property {string} [contact]
     * @property {string} button
     * @property {Array.<DoubleButton>} [doubleButton]
     */

    /**
     * @param {{onClose: function, dialogInfo: DialogInfo}} dialogOptions
     */
    static showCustomDialog({ onClose, dialogInfo }) {
        this.open({ type: GlobalDialogTypes.Custom, onClose, dialogInfo });
    }

    static hide() {
        this.close();
    }
}
