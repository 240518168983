import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { Box, Icon, makeStyles, Paper } from "@material-ui/core";
import { format, parse } from "date-fns";
import Interweave from "interweave";
import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DELIVERY_METHODS } from "../constant";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 22,
        margin: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(3),
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 100,
        color: theme.palette.primary.main,
        display: "block",
    },
    label: {
        textTransform: "uppercase",
        color: theme.palette.neutral.grey1,
        fontSize: 16,
    },
    body: {
        padding: theme.spacing(2),
        textAlign: "center",
        marginBottom: theme.spacing(3),
    },
}));

const CreateBookingSuccess = ({
    method,
    selectedHangout,
    selectedTime,
    onComplete,
    selectedAddress,
    selectedDate,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const content = useMemo(() => {
        if (method === DELIVERY_METHODS.HANGOUT) {
            const parsedTime =
                parse(selectedTime?.fromTime, "yyyy-MM-dd HH:mm", new Date()) ||
                "";
            return {
                title: t("booking_thank_for_book"),
                subtitle: t("booking_pickup_will_reserve_card"),
                address: {
                    label: t("lb_location"),
                    name: selectedHangout?.name,
                    value: selectedHangout?.address,
                },
                time: {
                    label: t("lb_time"),
                    value: format(parsedTime, "HH:mm EEEE, dd/MM/yyyy"),
                },
                note: t("booking_pickup_please_bring_doc"),
            };
        }

        const deliveryContent = {
            title: t("booking_delivery_confirmation"),
            subtitle: t("master:card_delivery_address"),
            address: {
                name: t("booking_delivery_new_address"),
                value: selectedAddress?.fullAddress,
            },
            time: {},
            note: `${t("master:time_card_delivery")} <br /> ${t(
                "ekyc:login_contact_for_support"
            ).replace("%@", `<b>${t("hangout:callcenter_number")}</b>`)}`,
        };

        if (method === DELIVERY_METHODS.MOTORBIKE) {
            const parsedDate = parse(
                selectedDate?.day,
                "yyyy-MM-dd",
                new Date()
            );

            deliveryContent.time.label = t("lb_time");

            deliveryContent.time.value = `${selectedTime?.name} ${format(
                parsedDate,
                "dd/MM/yyyy"
            )}`;
        }

        // Delivery
        return deliveryContent;
    }, [
        t,
        selectedHangout,
        method,
        selectedAddress,
        selectedDate,
        selectedTime,
    ]);
    return (
        <Content size="sm">
            <Paper className={classes.body}>
                <Icon
                    className={`font-icon icon-Plus-SUCCESS ${classes.icon}`}
                />
                <LspTypography className={classes.title} color="grey">
                    {content?.title}
                </LspTypography>

                <Box mb={2}>
                    <LspTypography>{content?.subtitle}</LspTypography>
                </Box>

                <Box>
                    <div className={classes.label} htmlFor="address">
                        {content?.address?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.address?.name}
                    </LspTypography>
                    <LspTypography variant="body1">
                        {content?.address?.value}
                    </LspTypography>
                </Box>

                <Box mt={2}>
                    <div className={classes.label} htmlFor="address">
                        {content?.time?.label}
                    </div>
                    <LspTypography variant="heading1" color="primary">
                        {content?.time?.value}
                    </LspTypography>
                </Box>

                <Box mt={2} mb={1}>
                    <LspTypography>
                        <Interweave content={content?.note} />
                    </LspTypography>
                </Box>
            </Paper>

            <LspButton fullWidth onClick={onComplete}>
                {t("lb_btn_ok_got_it")}
            </LspButton>
        </Content>
    );
};

const mapState = (state) => ({
    onComplete: state.booking.onComplete,
    method: state.booking.method,
    selectedHangout: state.booking.selectedHangout,
    selectedTime: state.booking.selectedTime,
    selectedDate: state.booking.selectedDate,
    selectedAddress: state.booking.address,
});
export default connect(mapState)(CreateBookingSuccess);
