import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const Loading = ({ size, ...restProp }) => {
    return (
        <Box
            {...restProp}
            paddingY={1}
            key={0}
            display="flex"
            justifyContent="center"
        >
            <CircularProgress size={size} />
        </Box>
    );
};

Loading.propTypes = {
    size: PropTypes.number,
};

Loading.defaultProps = {
    size: 20,
};

export default Loading;
