import api from "../config/api";

const getSystemParams = async () => {
    return await api.get("system/params", null);
};

const getPhoneConfigs = async () => {
    return await api.get("/user/topup/config/amountList", {});
};

const systemParamsService = {
    getSystemParams,
    getPhoneConfigs,
};

export default systemParamsService;
