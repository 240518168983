import LspButton from "@components/LspButton";
import { makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
    orLabel: {
        textTransform: "uppercase",
        textAlign: "center",
    },
    buttonWrapper: {
        "&:focus": {
            outline: "none",
        },
    },
    wrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
}));

const avatarMaxSizeDefault = 10;
const acceptedFileExtensionsDefault =
    "image/jpg, image/jpeg, image/png, application/pdf";

const ButtonUploader = ({
    acceptedFileExtensions = acceptedFileExtensionsDefault,
    avatarMaxSize = avatarMaxSizeDefault,
    onError,
    onUpload,
    type,
    maxFiles = 3,
    modifyMode,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isLargeFiles = useCallback(
        (fileSize) => fileSize / (1024 * 1024) >= avatarMaxSize,
        [avatarMaxSize]
    );

    const onDrop = useCallback(
        (acceptFile, rejectFile) => {
            const isLargeFile = acceptFile.some((item) =>
                isLargeFiles(item?.size)
            );

            if (!isEmpty(rejectFile)) {
                if (rejectFile.length > maxFiles) {
                    onError(
                        modifyMode
                            ? t(
                                  "txnapptype:receipt_attach_file_error_number_file_change_exceed_desc"
                              )
                            : t(
                                  "txnapptype:receipt_attach_file_error_number_file_exceed_desc"
                              )
                    );
                    return;
                }
                switch (type) {
                    case "multiple":
                        onError(
                            t(
                                "txnapptype:receipt_attach_file_error_format_unsuport_desc"
                            )
                        );
                        break;

                    default:
                        onError(t("upload_avatar_msg_wrong_extension"));
                        break;
                }
            } else if (isLargeFile) {
                switch (type) {
                    case "multiple":
                        onError(
                            t(
                                "txnapptype:receipt_attach_file_error_size_exceed_desc"
                            )
                        );
                        break;

                    default:
                        onError(t("upload_payee_avatar_limit_size"));
                        break;
                }
            } else {
                onError("");
                if (type === "multiple") {
                    const files = acceptFile.map((item) => ({
                        file: item,
                        preview: URL.createObjectURL(item),
                    }));
                    onUpload({
                        files,
                    });
                    return;
                }
                const file = acceptFile[0];
                onUpload({ ...file, preview: URL.createObjectURL(file) });
            }
        },
        [isLargeFiles, maxFiles, type, onError, modifyMode, t, onUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFileExtensions,
        onDrop,
        maxFiles,
    });

    return (
        <div className={classes.wrapper}>
            <div className={classes.buttonWrapper} {...getRootProps()}>
                <input {...getInputProps()} />
                <LspButton variant="secondary" className={classes.uploadButton}>
                    {t("profile_txt_button_modal_upload_photo")}
                </LspButton>
            </div>
        </div>
    );
};

ButtonUploader.propTypes = {
    maxFiles: PropTypes.number,
    type: PropTypes.string,
    modifyMode: PropTypes.bool,
};

ButtonUploader.defaultProps = {
    maxFiles: 3,
    type: "default",
    modifyMode: false,
};

export default memo(ButtonUploader);
