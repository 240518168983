import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useLanguage from "@helpers/useLanguage";
import { useCallback } from "react";

import logo from "@assets/images/timo_logo_horizontal.png";

const useStyles = makeStyles((theme) => ({
    splitBillHeader: {
        backgroundColor: theme.palette.background.default,
        minHeight: 69,
        padding: "16px 16px 22px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(14),
    },
    headerLeft: {
        maxWidth: 150,
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    headerRight: {},
    bold: {
        fontWeight: "bold",
    },
}));

const SplitBillNav = () => {
    const classes = useStyles();

    const { i18n } = useTranslation();
    const { switchLanguage } = useLanguage();

    const changeLanguage = useCallback(() => {
        window.onbeforeunload = null;
        switchLanguage();
    }, [switchLanguage]);

    const isVI = () => i18n.language === "vn";

    return (
        <>
            <div className={classes.splitBillHeader}>
                <div className={classes.headerLeft}>
                    <img src={logo} alt="Timo" />
                </div>
                <div className={classes.headerRight} onClick={changeLanguage}>
                    <span className={isVI() ? classes.bold : ""}>VN</span> |{" "}
                    <span className={isVI() ? "" : classes.bold}>EN</span>
                </div>
            </div>
        </>
    );
};

export default SplitBillNav;
