import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import useNumber from "@helpers/useNumber";
import { Box, ButtonBase, Divider, Icon, makeStyles } from "@material-ui/core";
import {
    memo,
    useMemo,
    Fragment,
    useState,
    useRef,
    useEffect,
    useCallback,
} from "react";

import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import BillHistory from "@containers/SpendAccount/BillPayment/BillHistory";
import useAccounts from "@helpers/useAccounts";
import useOTP from "@helpers/useOTP";
import moveMoneyService from "@services/move-money";
import { RESPONSE_CODE } from "@config/constants";
import billServices from "@services/bill";
import billActions from "@redux/actions/bill";
import { connect } from "react-redux";
import DoubleButtons from "@components/DoubleButtons";
import clsx from "clsx";
import { isFunction } from "lodash";
import LspReceiptController from "@helpers/controllers/LspReceiptController";
import useTransactionValidation from "../useTransactionValidation";

const useStyles = makeStyles((theme) => ({
    infoWrapper: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    billDetailWrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.radiusLevels[2],
        paddingBottom: theme.spacing(2),
    },
    border: {
        border: `1px solid ${theme.palette.background.header}`,
    },
    label: {
        width: "calc(40% - 16px)",
        display: "inline-block",
        marginRight: theme.spacing(2),
    },
    value: {
        width: "60%",
        color: theme.palette.primary.main,
        fontWeight: 600,
        display: "inline-block",
    },
    center: {
        "& > span:first-child": {
            width: `calc(50% - ${theme.spacing(2)}px)`,
            textAlign: "right",
            marginRight: theme.spacing(2),
        },
        "& > span:last-child": {
            width: "50%",
        },
        [theme.breakpoints.down("xs")]: {
            "& > span:first-child": {
                width: "100%",
                textAlign: "center",
            },
            "& > span:last-child": {
                width: "100%",
                textAlign: "center",
                marginTop: theme.spacing(0.5),
                marginBottom: theme.spacing(1),
            },
        },
    },
    detail: {
        border: `1px solid ${theme.palette.background.header}`,
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    headerDetail: {
        background: theme.palette.background.default,
        color: theme.palette.white.contrastText,
        padding: theme.spacing(1, 2),
        textAlign: "center",
    },
    divider: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2.5),
    },
    totalBox: {
        border: `1px solid ${theme.palette.background.header}`,
        padding: theme.spacing(2),
        width: "100%",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    footer: {
        padding: theme.spacing(1, 4, 3, 4),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        "& > div:nth-child(1)": {
            width: "30%",
        },
        "& > div:nth-child(2)": {
            width: "40%",
            textAlign: "center",
        },
        "& > div:nth-child(3)": {
            width: "30%",
            textAlign: "right",
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            flexDirection: "column",
            "& > div:nth-child(2)": {
                width: "100%",
                "& > div": {
                    width: "100%",
                },
                "& button": {
                    width: "100%",
                },
            },
            "& > div:nth-child(3)": {
                textAlign: "center",
                width: "100%",
                marginTop: theme.spacing(2),
            },
        },
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    fetching: {
        border: "none",
    },
}));
const BillDetail = ({
    selectedBill,
    detail,
    fetching,
    getBillList,
    showHistoryButton = false,
    onCancel,
    onNext,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { formatNumber } = useNumber();
    const { isOverDraftUser, spendAccount, availableAmount } = useAccounts();
    const {
        warningUsingOverdraftMoneyDialog,
        isUsingOverdraftMoney,
    } = useTransactionValidation();

    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [submitting, setSubmitting] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const title = useMemo(() => {
        const totalBill = detail?.bills?.length || 0;

        if (totalBill <= 0) {
            return t("payee_bill_no_unpaid_bill");
        }

        if (totalBill === 1) {
            return `${totalBill} ${t("move_money_unpaid_bill")}`;
        }

        return `${totalBill} ${t("move_money_unpaid_bills")}`;
    }, [detail, t]);

    const fetchingLayer = useMemo(() => {
        return (
            <Box p={2} className={`${classes.infoWrapper} ${classes.fetching}`}>
                <Box>
                    <span className={classes.label}>
                        <Skeleton />
                    </span>
                    <span className={classes.value}>
                        <Skeleton />
                    </span>
                </Box>

                <Box mt={0.5}>
                    <span className={classes.label}>
                        <Skeleton />
                    </span>
                    <span className={classes.value}>
                        <Skeleton />
                    </span>
                </Box>
                <Box mt={0.5}>
                    <span className={classes.label}>
                        <Skeleton />
                    </span>
                    <span className={classes.value}>
                        <Skeleton />
                    </span>
                </Box>
                <Box mt={0.5}>
                    <span className={classes.label}>
                        <Skeleton />
                    </span>
                    <span className={classes.value}>
                        <Skeleton />
                    </span>
                </Box>
            </Box>
        );
    }, [classes]);

    const onCloseHistory = () => {
        GlobalDialogController.hide();
    };

    const openBillHistory = () => {
        GlobalDialogController.show({
            component: () => (
                <BillHistory
                    selectedBill={selectedBill}
                    onClose={onCloseHistory}
                />
            ),
        });
    };

    const payBillResponseHandler = useCallback(
        (code, data) => {
            switch (code) {
                case RESPONSE_CODE.SUCCESS:
                    // Pay bill by new bill
                    if (onNext && isFunction(onNext)) {
                        onNext(data);
                        return;
                    }

                    // Pay bill on bill list
                    LspReceiptController.dialog(null, getBillList, data);
                    break;
                case RESPONSE_CODE.WRONG_PASSWORD_MANY_TIMES:
                case RESPONSE_CODE.NO_ENOUGH_MONEY_2:
                    GlobalDialogController.showCustomDialog({
                        dialogInfo: {
                            icon: "problem",
                            header: "gs_contribute_error_title",
                            content: "topup_msg_invalid_amount",
                            button: "lb_ok",
                        },
                    });
                    break;
                default:
                    GlobalDialogController.showFinancialError({
                        errorCode: code,
                    });
                    break;
            }
        },
        [getBillList, onNext]
    );

    const payBillCommitOtp = useCallback(
        async (payload) => {
            setLoading(true);

            const { data } = await moveMoneyService.submitOTP(payload);
            setLoading(false);
            closeOTPDialog();
            payBillResponseHandler(data.code, data.data);
        },
        [payBillResponseHandler, closeOTPDialog, setLoading]
    );

    const payBillRequest = useCallback(async () => {
        setSubmitting(true);
        const payload = {
            source: {
                debitAccount: spendAccount?.no,
            },
            target: {
                serviceCode: selectedBill?.serviceCode,
                providerCode: selectedBill?.providerCode,
                billCustomerId: detail?.customerId,
                billAmount: parseFloat(detail?.totalBillAmount),
                payeeId: selectedBill?.payeeId || detail?.payeeId,
            },
            notification: "sms",
        };
        const response = await billServices.payBillRequest(payload);

        if (unmounted.current) return;

        setSubmitting(false);

        const { code, data } = response.data;

        if (code === RESPONSE_CODE.OTP_REQUIRED) {
            openOTPDialog({
                refNo: data.refNo,
                submitFunc: payBillCommitOtp,
            });
            return;
        }

        payBillResponseHandler(code, data);
    }, [
        payBillResponseHandler,
        payBillCommitOtp,
        selectedBill,
        detail,
        openOTPDialog,
        spendAccount?.no,
    ]);

    const validation = useCallback(() => {
        const amount = detail?.totalBillAmount || 0;

        if (isUsingOverdraftMoney({ canUseOverdraft: true, amount })) {
            warningUsingOverdraftMoneyDialog(payBillRequest);
            return;
        }

        payBillRequest();
    }, [
        payBillRequest,
        warningUsingOverdraftMoneyDialog,
        detail?.totalBillAmount,
        isUsingOverdraftMoney,
    ]);

    const buttons = useMemo(
        () => [
            {
                label: t("bill_otp_btn"),
                onClick: validation,
            },
            {
                label: t("lb_cancel"),
                onClick: onCancel,
            },
        ],
        [validation, onCancel, t]
    );

    return (
        <Box>
            {fetching && fetchingLayer}

            {!fetching && (
                <>
                    <Box
                        className={
                            !showHistoryButton ? classes.billDetailWrapper : ""
                        }
                    >
                        <Box
                            p={2}
                            className={clsx(classes.infoWrapper, {
                                [classes.border]: showHistoryButton,
                            })}
                        >
                            {detail?.customerId && (
                                <Box>
                                    <span className={classes.label}>
                                        {`${t("bill_lb_customer_id")}: `}
                                    </span>
                                    <span className={classes.value}>
                                        {detail?.customerId}
                                    </span>
                                </Box>
                            )}

                            {detail?.customerName && (
                                <Box mt={0.5}>
                                    <span className={classes.label}>{`${t(
                                        "lb_name"
                                    )}: `}</span>
                                    <span className={classes.value}>
                                        {detail?.customerName}
                                    </span>
                                </Box>
                            )}

                            {detail?.customerAddress && (
                                <Box mt={0.5}>
                                    <span className={classes.label}>{`${t(
                                        "bill_lb_address"
                                    )}: `}</span>
                                    <span className={classes.value}>
                                        {detail?.customerAddress}
                                    </span>
                                </Box>
                            )}

                            {selectedBill?.providerName && (
                                <Box mt={0.5}>
                                    <span className={classes.label}>{`${t(
                                        "bill_lb_supplier"
                                    )}: `}</span>
                                    <span className={classes.value}>
                                        {selectedBill?.providerName}
                                    </span>
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.detail}>
                            <Box>
                                <LspTypography className={classes.headerDetail}>
                                    {title}
                                </LspTypography>
                            </Box>
                            <Box p={2}>
                                {detail?.totalBillAmount <= 0 && (
                                    <LspTypography
                                        variant="heading1"
                                        color="primary"
                                        style={{ textAlign: "center" }}
                                    >
                                        {t("bill_lb_none_unpaid")}
                                    </LspTypography>
                                )}
                                {detail?.bills?.map((item, index) => {
                                    return (
                                        <Fragment key={item?.billId}>
                                            {index > 0 && (
                                                <Divider
                                                    className={classes.divider}
                                                />
                                            )}
                                            {item?.billId && (
                                                <Box className={classes.center}>
                                                    <span
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        {`${t(
                                                            "bill_lb_bill_number"
                                                        )}: `}
                                                    </span>
                                                    <span
                                                        className={
                                                            classes.value
                                                        }
                                                    >
                                                        {item?.billId}
                                                    </span>
                                                </Box>
                                            )}
                                            {item?.amount && (
                                                <Box className={classes.center}>
                                                    <span
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        {`${t(
                                                            "bill_amount"
                                                        )}: `}
                                                    </span>
                                                    <span
                                                        className={
                                                            classes.value
                                                        }
                                                    >
                                                        {formatNumber(
                                                            item?.amount
                                                        )}
                                                    </span>
                                                </Box>
                                            )}
                                        </Fragment>
                                    );
                                })}

                                {detail?.totalBillAmount > 0 && (
                                    <Box className={classes.totalBox}>
                                        <Box className={classes.center}>
                                            <span className={classes.label}>
                                                {`${t(
                                                    "bill_lb_total_unpaid_balance"
                                                )}: `}
                                            </span>
                                            <span className={classes.value}>
                                                {formatNumber(
                                                    detail?.totalBillAmount
                                                )}
                                            </span>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {showHistoryButton && (
                            <Box className={classes.footer}>
                                <Box />
                                <Box>
                                    {detail?.totalBillAmount > 0 && (
                                        <LspButton
                                            onClick={validation}
                                            progressing={submitting}
                                        >
                                            {t("lb_move_money")}
                                        </LspButton>
                                    )}
                                </Box>
                                <Box>
                                    <ButtonBase onClick={openBillHistory}>
                                        <Icon
                                            className={`font-icon icon-history ${classes.icon}`}
                                        />
                                        <LspTypography color="primary">
                                            {t("bill_butt_bill_pay_history")}
                                        </LspTypography>
                                    </ButtonBase>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {!showHistoryButton && detail?.totalBillAmount > 0 && (
                        <Box mt={3}>
                            <DoubleButtons
                                primaryButton={buttons[0]}
                                secondaryButton={buttons[1]}
                                progressing={submitting}
                                disabled={fetching}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

const dispatchProps = (dispatch) => ({
    getBillList: () => dispatch(billActions.getBillList()),
});

export default memo(connect(null, dispatchProps)(BillDetail));
