import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { connect } from "react-redux";
import Content from "@components/Content";
import accountAction from "@redux/actions/account";
import TermDepositItem from "./TermDepositItem";
import TermDepositListLoading from "./TermDepositListLoading";
import useTermDepositList from "./useTermDepositList";

const TermDepositList = ({
    type = "active",
    setTotalActiveTermDepositAmount,
    setTotalRedeemedTermDepositAmount,
}) => {
    const unmounted = useRef(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [createDateIndex, setCreateDateIndex] = useState("");
    const [statusIndex, setStatusIndex] = useState("");
    const [isAllowRollover, setIsAllowRollover] = useState(false);
    const {
        getActiveTermDepositList,
        getInactiveTermDepositList,
    } = useTermDepositList();

    useEffect(
        () => () => {
            unmounted.current = true;
        },
        []
    );

    const getList = useMemo(() => {
        if (type === "history") {
            return getInactiveTermDepositList;
        }
        return getActiveTermDepositList;
    }, [type, getInactiveTermDepositList, getActiveTermDepositList]);

    const setTotalAmount = useMemo(() => {
        if (type === "history") {
            return setTotalRedeemedTermDepositAmount;
        }
        return setTotalActiveTermDepositAmount;
    }, [
        type,
        setTotalRedeemedTermDepositAmount,
        setTotalActiveTermDepositAmount,
    ]);

    const requestToGetList = useCallback(
        async (reload = false) => {
            setLoading(true);
            const payload = {};
            if (!reload) {
                payload.createDateIndex = createDateIndex;
                payload.statusIndex = statusIndex;
            }

            const response = await getList(payload);
            if (unmounted.current) {
                return;
            }
            setLoading(false);
            if (Array.isArray(response?.tdList)) {
                if (reload) {
                    setList(response?.tdList);
                } else {
                    setList((prevList) => [...prevList, ...response?.tdList]);
                }
            }
            setTotalAmount(response?.totalBalance);
            setCreateDateIndex(response?.createDateIndex);
            setStatusIndex(response?.statusIndex);
            setIsAllowRollover(response?.enableRollingOnlyPrinciple);
        },
        [getList, setTotalAmount, createDateIndex, statusIndex]
    );

    const reloadList = useCallback(() => {
        requestToGetList(true);
    }, [requestToGetList]);

    useEffect(() => {
        requestToGetList();
    }, [requestToGetList]);

    const hasMore = useMemo(() => !createDateIndex && !statusIndex, [
        createDateIndex,
        statusIndex,
    ]);

    const loadMore = () => {
        // if (!hasMore || loading) {
        // }
    };

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: hasMore,
        onLoadMore: loadMore,
        scrollContainer: "window",
    });

    const handleScrollBottom = () => {
        const {
            scrollHeight,
            offsetHeight,
            clientHeight,
        } = infiniteRef.current;
        const height = Math.max(scrollHeight, offsetHeight, clientHeight);

        const timeout = setTimeout(() => {
            window.scrollTo({ top: height, behavior: "smooth" });
            clearTimeout(timeout);
        }, 200);
    };

    return (
        <Content>
            <div ref={infiniteRef}>
                {list?.map((td, index) => {
                    return (
                        <TermDepositItem
                            key={td.termId}
                            {...td}
                            reloadList={() => reloadList()}
                            enableRollingOnlyPrinciple={isAllowRollover}
                            total={list.length}
                            currentIndex={index}
                            scrollBottom={handleScrollBottom}
                        />
                    );
                })}
            </div>
            {loading && <TermDepositListLoading />}
        </Content>
    );
};

const mapDispatch = (dispatch) => ({
    setTotalActiveTermDepositAmount: (amount) =>
        dispatch(accountAction.setTotalActiveTermDepositAmount(amount)),
    setTotalRedeemedTermDepositAmount: (amount) =>
        dispatch(accountAction.setTotalRedeemedTermDepositAmount(amount)),
});

export default connect(null, mapDispatch)(TermDepositList);
