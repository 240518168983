import { makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import querystring from "query-string";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import {
    useLocation,
    Switch,
    Route,
    Redirect,
    useHistory,
} from "react-router-dom";
import Banner from "../../components/Banner";
import ContentWrapper from "../../components/ContentWrapper";

import LixiList from "./LixiList";
import LixiDetail from "./LixiDetail";
import { LIXI_ACTIONS, LIXI_NAV, PAY_ANYONE_TYPES } from "./constant";
import lixiActions from "../../redux/actions/lixi";
import useNumber from "../../helpers/useNumber";
import LixiSender from "./LixiSender";

const useStyles = makeStyles(() => ({
    wrapper: {
        margin: "auto",
        width: "100%",
        maxWidth: "1140px",
    },
}));

const Lixi = ({ dashboard, getDashboard }) => {
    const { formatNumber } = useNumber();
    const classes = useStyles();
    const { t } = useTranslation("translation", "lixi");
    const location = useLocation();
    const history = useHistory();
    const params = querystring.parse(location.search);
    const [subtitle, setSubtitle] = useState(null);

    const activeItem = useCallback(
        (type) => {
            return location.pathname === type;
        },
        [location.pathname]
    );

    const navbarItems = useMemo(
        () => [
            {
                icon: "Lixi---Received",
                title: t("lixi:received_navbar"),
                path: LIXI_NAV.RECEIVE_LIST,
                selected: activeItem(LIXI_NAV.RECEIVE_LIST),
            },
            {
                icon: "Lixi---Sent",
                title: t("lixi:sent_navbar"),
                path: LIXI_NAV.SEND_LIST,
                selected: activeItem(LIXI_NAV.SEND_LIST),
            },
        ],
        [activeItem, t]
    );

    const sendBtnClick = useCallback(() => {
        history.push(LIXI_NAV.SEND_LIXI);
    }, [history]);

    // Receiver want to send lixi to sender
    const sendBackLixi = (detail) => {
        console.log(
            "🚀 ~ file: index.js ~ line 114 ~ sendBackLixi ~ detail",
            detail
        );
    };

    const detailStep = useCallback(
        (giftId) => {
            const type = location.pathname.split("/")[2];
            history.push(`${LIXI_NAV.DETAIL}?type=${type}&id=${giftId}`);
        },
        [history, location]
    );

    useEffect(() => {
        getDashboard();
        // nextStep();
    }, [getDashboard]);

    useEffect(() => {
        if (dashboard) {
            const lixiInfo = dashboard.find(
                (item) => item.paoType === PAY_ANYONE_TYPES.LIXI
            );
            let content = "";
            if (params.type === LIXI_NAV.RECEIVE) {
                content = `${t("lixi:total_lixi_received")} ${formatNumber(
                    lixiInfo.totalAmountReceive
                )}`;
            } else {
                content = `${t("lixi:total_lixi_sent")} ${formatNumber(
                    lixiInfo.totalAmountSend
                )}`;
            }
            setSubtitle(content);
        }
    }, [dashboard, params, t, formatNumber]);

    const customRoutes = useMemo(
        () => [
            {
                path: LIXI_NAV.RECEIVE_LIST,
                component: () => {
                    return (
                        <LixiList
                            sendBtnClick={sendBtnClick}
                            type={LIXI_ACTIONS.RECEIVE}
                            onClickLixiItem={detailStep}
                        />
                    );
                },
                default: true,
            },
            {
                path: LIXI_NAV.SEND_LIST,
                component: () => {
                    return (
                        <LixiList
                            sendBtnClick={sendBtnClick}
                            type={LIXI_ACTIONS.SEND}
                            onClickLixiItem={detailStep}
                        />
                    );
                },
            },
            {
                path: LIXI_NAV.DETAIL,
                component: () => {
                    return (
                        <LixiDetail
                            giftId={params.id}
                            type={params.type}
                            onDone={(detail) => sendBackLixi(detail)}
                            isReview="true"
                        />
                    );
                },
            },
            {
                path: LIXI_NAV.SEND_LIXI,
                component: () => {
                    return <LixiSender />;
                },
            },
        ],
        [detailStep, params.id, params.type, sendBtnClick]
    );
    const defaultRoutes = useMemo(() => customRoutes.find((r) => r.default), [
        customRoutes,
    ]);

    return (
        <>
            <Banner
                title={t("lixi:lixi_title")}
                subtitle={subtitle}
                navbarItems={navbarItems}
            />
            <ContentWrapper>
                <div className={classes.wrapper}>
                    <Switch>
                        {customRoutes.map((r) => {
                            return (
                                <Route
                                    key={r.path}
                                    path={r.path}
                                    component={r.component}
                                />
                            );
                        })}
                        {!!defaultRoutes && (
                            <Route
                                component={() => (
                                    <Redirect to={defaultRoutes.path} />
                                )}
                            />
                        )}
                    </Switch>
                </div>
            </ContentWrapper>
        </>
    );
};

const stateProps = (state) => ({
    dashboard: state.lixi.dashboardInfo,
});

const dispatchProps = (dispatch) => ({
    getDashboard: () => dispatch(lixiActions.getDashboard()),
});

export default connect(stateProps, dispatchProps)(Lixi);
