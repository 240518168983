import { call, put } from "redux-saga/effects";
import quickCodeService from "@services/quick-code";
import { RESPONSE_CODE } from "@config/constants";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import QuickCodeDialogController from "@helpers/controllers/QuickCodeDialogController";
import LspTranslation from "@components/LspTranslation";
import { CancelToken } from "apisauce";
import ApiCancelToken from "@helpers/controllers/ApiCancelToken";
import api from "@config/api";
import { SHA512 } from "crypto-js";
import quickCodeActions, { QuickCodeScreens } from "../actions/quick-code";
import authAction from "../actions/auth";
import GlobalDialogActions from "../actions/global-dialog";

export function* checkQuickCodeExistenceSaga() {
    const response = yield call(quickCodeService.checkExistingQuickCode);
    if (response.ok) {
        const { code } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                yield put(
                    quickCodeActions.checkQuickCodeExistenceSuccess(true)
                );
                break;
            case RESPONSE_CODE.INVALID:
                yield put(
                    quickCodeActions.checkQuickCodeExistenceSuccess(false)
                );
                break;
            default:
                break;
        }
    } else if (response.status === RESPONSE_CODE.UNAUTHORIZED) {
        yield put(quickCodeActions.checkQuickCodeExistenceError());
    } else if (response.status === RESPONSE_CODE.INVALID) {
        yield put(quickCodeActions.checkQuickCodeExistenceSuccess(false));
    } else {
        yield put(quickCodeActions.checkQuickCodeExistenceError());
    }
}

export function* createNewQuickCodeSaga(action) {
    const { password, quickCode, successMsg } = action.payload;
    const data = {
        quickCode,
        password,
    };

    const response = yield call(quickCodeService.createNewQuickCode, data);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
            QuickCodeDialogController.hide();
            yield put(quickCodeActions.createNewQuickCodeSuccess());
            QuickCodeDialogController.notifyMessage(successMsg);
            yield put(quickCodeActions.setPassword(""));
            yield put(quickCodeActions.checkQuickCodeExistenceSuccess(true));
            break;
        case RESPONSE_CODE.UNAUTHORIZED:
        case RESPONSE_CODE.INVALID:
            AlertController.show(
                <LspTranslation i18nKey="quickcode_msg_incorrect_password" />,
                AlertType.Error
            );
            yield put(quickCodeActions.createNewQuickCodeError());
            break;
        case RESPONSE_CODE.QUICK_CODE_INVALID:
            AlertController.show(
                <LspTranslation i18nKey="quickcode_warning_choose_harder_qc" />,
                AlertType.Error
            );
            yield put(quickCodeActions.createNewQuickCodeError());
            if (successMsg?.isCreateFlow) {
                yield put(
                    quickCodeActions.setActiveStep(
                        QuickCodeScreens.INPUT_NEW_QUICK_CODE
                    )
                );
            } else {
                yield put(
                    quickCodeActions.setActiveStep(
                        QuickCodeScreens.INPUT_NEW_QUICK_CODE_FOR_CHANGE
                    )
                );
            }

            break;
        case RESPONSE_CODE.MAX_FAIL_ATTEMPTS_REACHED:
            // blocked user and force logout
            QuickCodeDialogController.hide();
            GlobalDialogController.showError({
                errorCode: RESPONSE_CODE.FE_USER_BLOCKED,
            });
            yield put(quickCodeActions.createNewQuickCodeError());
            yield put(authAction.logoutSuccess());
            break;
        default:
            yield put(quickCodeActions.createNewQuickCodeError());
            GlobalDialogController.showError({
                errorCode: code,
            });
            break;
    }
}

export function* checkValidQuickCodeSaga(action) {
    const cancelToken = CancelToken.source();
    ApiCancelToken.setCancelToken(cancelToken);
    api.axiosInstance.defaults.cancelToken = cancelToken.token;

    const { payload } = action;
    const { quickCode, token } = payload;

    const response = yield call(quickCodeService.checkValidQuickCode, {
        quickCode,
    });
    const { code, message } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
            const hardCodeHash = "VzNMQHZlVGltQDIwMjI=";
            const hashCompareFrontend = SHA512(
                token + quickCode + hardCodeHash
            ).toString();

            if (hashCompareFrontend === message) {
                yield put(quickCodeActions.checkValidQuickCodeSuccess());
                return;
            }

            AlertController.show(
                <LspTranslation i18nKey="quick_code_error_no_match" />,
                AlertType.Error
            );
            yield put(quickCodeActions.checkValidQuickCodeError());

            break;
        case RESPONSE_CODE.INVALID:
            QuickCodeDialogController.hide();
            AlertController.show(
                <LspTranslation i18nKey="quickcode_content_msg_error_5_times_wrong_qc" />,
                AlertType.Error
            );
            yield put(quickCodeActions.checkValidQuickCodeError());
            yield put(authAction.logoutSuccess());
            // force logout
            break;
        case RESPONSE_CODE.NOT_ACCEPTABLE:
            AlertController.show(
                <LspTranslation i18nKey="quick_code_error_no_match" />,
                AlertType.Error
            );
            yield put(quickCodeActions.checkValidQuickCodeError());
            break;
        default:
            yield put(quickCodeActions.checkValidQuickCodeError());
            break;
    }
}

export function* forgotQuickCodeSaga() {
    const cancelToken = CancelToken.source();
    ApiCancelToken.setCancelToken(cancelToken);
    api.axiosInstance.defaults.cancelToken = cancelToken.token;

    yield put(GlobalDialogActions.setLoading(true));
    const response = yield call(quickCodeService.forgotQuickCode);
    const { code } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
        case RESPONSE_CODE.CREATED:
            QuickCodeDialogController.hide();
            AlertController.show(
                <LspTranslation i18nKey="global_session_timeout" />,
                AlertType.Error
            );
            yield put(quickCodeActions.forgotQuickCodeSuccess());
            yield put(authAction.logoutSuccess());
            // force logout with a message
            break;
        default:
            yield put(quickCodeActions.forgotQuickCodeError());
            break;
    }
}
