import { useTranslation } from "react-i18next";
import clsx from "clsx";
import LspAvatar from "../../components/LspAvatar";

const Payee = ({ payee, onClickPayee, className }) => {
    const { t } = useTranslation("translation", "lixi");

    return (
        <>
            {payee?.hasTimoMem && (
                <div
                    className={clsx(className.payee, {
                        [className.invisible]: payee.invisible,
                    })}
                    onClick={() => onClickPayee()}
                >
                    <div className={className.avatar}>
                        <LspAvatar avatar={payee?.avatar} hasIcon />
                    </div>
                    <div className={className.detail}>
                        <div>{payee?.nickName}</div>
                        {payee?.addtionalInfo?.date && (
                            <div className={className.date}>
                                {t("move_money_status_label_1")}{" "}
                                {payee?.addtionalInfo?.date}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Payee;
