import LspTypography from "@components/LspTypography";
import { memo } from "react";
import { Skeleton } from "@material-ui/lab";

import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";

const ProfileFetchingForm = () => {
    const classes = useProfileSharedClasses();

    return (
        <div className={classes.line}>
            <div>
                <LspTypography component="span" color="grey" variant="heading2">
                    <Skeleton width={170} style={{ display: "inline-block" }} />
                </LspTypography>
            </div>
            <div>
                <Skeleton width={50} />
            </div>
        </div>
    );
};

export default memo(ProfileFetchingForm);
