/* eslint-disable camelcase */
import { Box, Button, Grid, Icon, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useState, useMemo, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import LspButton from "@components/LspButton";
import { WEALTH_NAVIGATION_KEYS } from "@containers/Wealth/wealthConstants";
import warningImg from "@assets/images/warning.png";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useDate from "@helpers/useDate";
import { isFunction } from "lodash";

import { connect } from "react-redux";
import CardManagementActions from "@redux/actions/card-management";
import Loading from "@components/Loading";
import decrypt from "@helpers/decryptSecretKey";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import isEmpty from "lodash/isEmpty";
import AlertController, {
    AlertType,
} from "@helpers/controllers/AlertController";

import useCallCenter from "@helpers/useCallCenter";
import LspTypography from "@components/LspTypography";
import cardManagementService from "@services/card-management";
import { RESPONSE_CODE } from "@config/constants";
import useOTP from "@helpers/useOTP";
import tommySuccess2 from "@assets/images/tommy_success_status_2.png";
import creditCard from "@assets/images/credit_card.png";
import tomiCry from "@assets/images/tommy_cry_status.png";

import LspTranslation from "@components/LspTranslation";
import useCard from "@helpers/useCard";
import {
    BANK_CARD_STATUS,
    CardManagementNavigation,
    CARD_ACTIONS,
    CARD_STATUSES,
    CARD_TYPES,
    CORE_CARD,
} from "./constant";
import PhysicalCardStatus from "./physicalCard/PhysicalCardStatus";

const useStyles = makeStyles((theme) => ({
    cardInfoItem: {
        marginBottom: theme.spacing(2),
    },
    cardImage: {
        maxWidth: "100%",
    },
    cardInfoItemLabel: {},
    cardInfoItemValue: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.neutral.black,
        display: "flex",
        alignItems: "center",
        "& .font-icon": {
            fontSize: theme.typography.pxToRem(12),
            cursor: "pointer",
            width: 24,
        },
    },

    cardInfoItemEye: {
        marginLeft: theme.spacing(1.5),
    },

    eCommerceOptions: {
        padding: theme.spacing(3, 2),
        color: theme.palette.grey[700],
    },
    warningBox: {
        background: theme.palette.background.default,
        borderRadius: theme.shape.radiusLevels[0],
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
    },
    warningImg: {
        width: 48,
        height: 48,
        position: "absolute",
        top: -theme.spacing(1),
        left: theme.spacing(3),
    },
    warningContent: {
        padding: theme.spacing(6, 3, 3, 3),
        minHeight: 162,
    },
    btn: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(0.75),
    },
    containerInfo: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: theme.spacing(6),
        width: "100%",
    },
    leftSide: {
        boxShadow: theme.shadows[2],
        flex: 1,
        padding: theme.spacing(3, 2),
        borderRadius: theme.shape.borderRadius,

        // [theme.breakpoints.down("sm")]: {
        //     marginRight: theme.spacing(2),
        // },
    },
    rightSide: {
        position: "absolute",
        right: 24,
        width: 160,
        height: 250,
        boxShadow: theme.shadows[2],
        top: 28,
        borderRadius: theme.shape.borderRadius,
    },
    activeWarningBox: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        "& $leftSide": {
            boxShadow: "none",
            borderRadius: "unset",
            padding: theme.spacing(0, 2),
            paddingLeft: 0,
        },
        "& $rightSide": {
            boxShadow: "none",
            borderRadius: "unset",
            position: "unset",
        },
        // "& $containerInfo": {
        //     alignItems: "center",
        // },
        padding: theme.spacing(3),
    },
    cardDot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    dot: {
        width: 8,
        height: 8,
        marginBottom: theme.spacing(1),
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        cursor: "pointer",
        "&:last-child": {
            marginRight: 0,
        },
    },
    dotDeActive: {
        backgroundColor: theme.palette.magicalGrey[200],
    },

    copyIcon: {
        cursor: "pointer",
        color: theme.palette.grey[400],
    },
    infoBoxText: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        color: theme.palette.magicalGrey[400],
        textAlign: "left",
    },
    physicalCardBox: {
        backgroundColor: theme.palette.magicalGrey[100],
        borderRadius: theme.shape.radiusLevels[2],
        position: "relative",
        padding: theme.spacing(3, 2),
    },
    physicalCardBoxImg: {
        position: "absolute",
        width: 28,
        height: 28,
        left: theme.spacing(2),
        top: -theme.spacing(1.5),
    },
}));
const CardInfo = ({
    className,
    card,
    hideCardInfo,
    cardList,
    onChangeActiveCard,
    getMaskRequest,
    masks,
    otpFetching,
    clearMaskData,
    cancelOTPRequest,
    userKey,
    user,
    updateCardStatus,
    activatingVirtualCard,
    featureState,
    getCardList,
}) => {
    const classes = useStyles();
    const { reformatDateString } = useDate();
    const loadedCurrentCard = useRef(false);
    const { getCallCenterContent } = useCallCenter();
    const { isVirtualCard, isDebitCard } = useCard();

    const [isShowCardInfo, setIsShowCardInfo] = useState(false);
    const [isShowWarningBox, setIsShowWarningBox] = useState(false);
    const [isShowChangePinBtn, setIsShowChangePinBtn] = useState(false);
    const [isShowContactBtn, setIsShowContactBtn] = useState(false);
    const [isShowActivateBtn, setIsShowActivateBtn] = useState(false);
    const [isShowApplyNowBtn, setIsShowApplyNowBtn] = useState(false);
    const [isShowPhysicalCardBox, setShowPhysicalCardBox] = useState(false);

    const [desc, setDesc] = useState(null);
    const [isShowEye, setShowEye] = useState(false);
    const isHyperlink = card?.cardStatus === CARD_STATUSES.FOREIGNER_WARNING;
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();

    const [cardNumber, setCardNumber] = useState(null);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const { t } = useTranslation(["translation", "hangout"]);
    const history = useHistory();

    const enableImmediatelyUseCCW4 = useMemo(
        () => featureState?.enableImmediatelyUseCCW4,
        [featureState?.enableImmediatelyUseCCW4]
    );

    const closeDialog = () => {
        GlobalDialogController.hide();
    };

    useEffect(() => {
        return () => {
            clearMaskData({ cardId: card?.cardId });
        };
    }, [card?.cardId, clearMaskData]);

    const isDebit = useMemo(() => {
        return isDebitCard(card.cardType);
    }, [card, isDebitCard]);

    const isVirtualVisaDebitCard = useMemo(() => {
        return isVirtualCard(card.cardType);
    }, [card, isVirtualCard]);

    const isVisaCard = useMemo(() => {
        return isVirtualVisaDebitCard || card.cardType === CARD_TYPES.VISA_CARD;
    }, [card, isVirtualVisaDebitCard]);

    const isBVBCreditCard = useMemo(
        () =>
            card.cardType === CARD_TYPES.VISA_CARD &&
            !card?.isAllowSetCardLimit,
        [card.cardType, card?.isAllowSetCardLimit]
    );

    useEffect(() => {
        if (cancelOTPRequest) {
            setShowEye(false);
        }
    }, [cancelOTPRequest]);

    useEffect(() => {
        return () => {
            loadedCurrentCard.current = false;
        };
    }, []);

    useEffect(() => {
        const cardIdParams = query.get("cardId");
        const findActiveCard = (cardList || []).find(
            (item) => item?.cardId.toString() === cardIdParams
        );

        if (loadedCurrentCard.current) return;

        if (!isEmpty(findActiveCard)) {
            loadedCurrentCard.current = true;
            if (isFunction(onChangeActiveCard)) {
                onChangeActiveCard(findActiveCard);
            }
        }
    }, [cardList, onChangeActiveCard, query]);

    const warningForPotentialRisk = () => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                icon: "sorryface",
                header: "td_lb_error_sorry",
                content: "mc_lb_potential_risk_locked_card_content",
                button: "lb_ok",
            },
        });
    };

    const mapDebitCard = useCallback(() => {
        switch (card.cardStatus) {
            case CARD_STATUSES.ACTIVE:
                setIsShowCardInfo(true);
                break;
            case CARD_STATUSES.CHANGE_PIN_1ST:
                setIsShowChangePinBtn(true);
                setIsShowWarningBox(true);
                setDesc(["changing_pin_suggestion"]);
                break;
            case CARD_STATUSES.INACTIVE:
                setIsShowActivateBtn(true);
                setIsShowWarningBox(true);
                setDesc(["mc_lb_card_inactive_info_web"]);
                break;
            case CARD_STATUSES.BLOCKED:
                const isReIssueStatus =
                    card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;
                if (isReIssueStatus) {
                    setIsShowWarningBox(true);
                    setIsShowContactBtn(true);
                    setDesc(["mc_lb_notice_card_reissued"]);
                } else {
                    setIsShowCardInfo(true);
                }
                break;
            case CARD_STATUSES.LOCKED:
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                setDesc([
                    "debit_card_no_longer_using_light_text_1",
                    "debit_card_no_longer_using_light_text_2",
                ]);
                break;
            case CARD_STATUSES.INACTIVE_2:
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                setDesc([
                    "not_unlockable_debit_card_bold_text",
                    "not_unlockable_debit_card_light_text",
                ]);
                break;
            case CARD_STATUSES.CLOSED:
                setIsShowWarningBox(true);
                setDesc(["card_activation_closed_debit_card_description"]);
                break;
            case CARD_STATUSES.POTENTIAL_RISK:
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                setDesc(["mc_lb_potential_risk_locked_card_content"]);
                warningForPotentialRisk();
                break;
            case CARD_STATUSES.FOREIGNER_WARNING:
                const content = getCallCenterContent(
                    t("regulation:manage_card_debit_card_notice")
                );
                setIsShowWarningBox(true);
                setDesc([content]);
                break;
            case CARD_STATUSES.NO_CARD:
            default:
                setIsShowActivateBtn(true);
                setIsShowWarningBox(true);
                setDesc(["mc_lb_card_inactive_info_web"]);
                break;
        }
    }, [card, getCallCenterContent, t]);

    const mapCreditCard = useCallback(() => {
        switch (card.cardStatus) {
            case CARD_STATUSES.ACTIVE:
                setIsShowCardInfo(true);
                break;

            case CARD_STATUSES.INACTIVE:
                setIsShowActivateBtn(true);
                setIsShowWarningBox(true);
                let descText = "";
                switch (card?.cardType) {
                    case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD:
                    case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP:
                        descText = "master:manage_card_visa_virtual_inactive";
                        break;
                    case CARD_TYPES.VISA_CARD:
                        descText = "wealth:mc_v2_status_temp_lock";
                        break;
                    default:
                        if (enableImmediatelyUseCCW4) {
                            descText =
                                "wealth:mc_v2_activate_online_feature_message";
                        } else {
                            descText = "mc_lb_card_inactive_info_web";
                        }
                        break;
                }

                setDesc([descText]);
                break;
            case CARD_STATUSES.CLOSED:
                setDesc(["wealth:mc_v2_status_closed"]);
                setIsShowWarningBox(true);
                setIsShowContactBtn(true);
                break;
            case CARD_STATUSES.BLOCKED:
                const isReIssueStatus =
                    card.bankCardStatus === BANK_CARD_STATUS.PICK_UP_L41;

                if (isReIssueStatus) {
                    setIsShowWarningBox(true);
                    setIsShowContactBtn(true);
                } else {
                    setIsShowCardInfo(true);
                }

                const status = isReIssueStatus
                    ? "wealth:mc_v2_status_pickup_l41"
                    : "wealth:mc_v2_status_temp_lock";
                setDesc([status]);
                break;
            case CARD_STATUSES.EXPIRED:
                setDesc(["wealth:mc_v2_status_expired"]);
                setIsShowWarningBox(true);
                setIsShowContactBtn(true);

                break;
            case CARD_STATUSES.POTENTIAL_RISK:
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                setDesc(["wealth:mc_v2_status_potential_risk"]);
                warningForPotentialRisk();
                break;
            case CARD_STATUSES.FOREIGNER_WARNING:
                const contentCredit = getCallCenterContent(
                    t("regulation:manage_card_credit_card_notice")
                );
                setIsShowWarningBox(true);
                setDesc([contentCredit]);
                break;
            case CARD_STATUSES.UNDEFINE:
                setDesc(["wealth:mc_v2_status_request_to_close"]);
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                break;
            case CARD_STATUSES.REQUEST_TO_CLOSED:
                setDesc(["wealth:mc_v2_status_request_to_close"]);
                setIsShowContactBtn(true);
                setIsShowWarningBox(true);
                break;
            case CARD_STATUSES.NO_CARD:
            default:
                setIsShowApplyNowBtn(true);
                setIsShowWarningBox(true);
                setDesc(["mc_lb_notice_open_credit_card"]);
                break;
        }
    }, [
        card.bankCardStatus,
        card.cardStatus,
        card?.cardType,
        getCallCenterContent,
        enableImmediatelyUseCCW4,
        t,
    ]);

    useEffect(() => {
        if (!card) return;

        if (isDebit || isVirtualVisaDebitCard) {
            mapDebitCard();
        } else {
            mapCreditCard();
        }
    }, [mapDebitCard, mapCreditCard, isDebit, card, isVirtualVisaDebitCard]);

    const contactUsClick = () => {
        const phone = t("hangout:callcenter_number");
        const content = t("ra_lb_content_register_success4").replace(
            "%@",
            `<a href="tel: ${phone}">${phone}</a>`
        );
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                header: "mc_contact_us",
                translatedContent: content,
                button: "lb_ok",
            },
        });
    };

    const applyCreditCard = () => {
        history.push(
            `/${WEALTH_NAVIGATION_KEYS.ROOT}/${WEALTH_NAVIGATION_KEYS.CREDIT_CARD}`
        );
    };

    const virtualCardActivate = () => {
        updateCardStatus({
            cardId: card.cardId,
            cardStatus: CARD_ACTIONS.ACTIVATE_VIRTUAL_CARD,
            cardType: card.cardType,
        });
    };

    const physicalCardSuccess = () => {
        GlobalDialogController.show({
            component: () => (
                <PhysicalCardStatus
                    imgUrl={tommySuccess2}
                    title={t(
                        "wealth:mc_v2_activate_online_feature_successful_title"
                    )}
                    description={t(
                        "wealth:mc_v2_activate_online_feature_successful_desc"
                    )}
                    note={
                        <>
                            <LspTypography className={classes.infoBoxText}>
                                {t(
                                    "wealth:mc_v2_activate_online_feature_successful_note"
                                )}
                            </LspTypography>
                            <img src={creditCard} alt="Credit Card" />
                        </>
                    }
                    onClose={() => {
                        closeDialog();
                        window.location.reload();
                    }}
                />
            ),
        });
    };

    const physicalCardErrors = () => {
        GlobalDialogController.show({
            component: () => (
                <PhysicalCardStatus
                    imgUrl={tomiCry}
                    title={t("wealth:mc_v2_activate_online_feature_fail_title")}
                    description={t(
                        "wealth:mc_v2_activate_online_feature_fail_desc"
                    )}
                    onClose={closeDialog}
                />
            ),
        });
    };

    useEffect(() => {
        if (
            card?.cardType === CARD_TYPES.VISA_CARD &&
            card?.isActivePhysicalStatus &&
            enableImmediatelyUseCCW4
        ) {
            setShowPhysicalCardBox(true);
        }
    }, [
        card?.cardType,
        card?.isActivePhysicalStatus,
        card.physicalStatus,
        enableImmediatelyUseCCW4,
    ]);

    const handleActivePhysicalCardCommitOTP = async (payload) => {
        const response = await cardManagementService.commitActiveCard({
            ...payload,
            cardId: card?.cardId,
        });

        const { code, data } = response.data;

        closeOTPDialog();
        switch (code) {
            case RESPONSE_CODE.INCORRECT_VALUE:
            case RESPONSE_CODE.MISMATCH_VALUE:
                AlertController.show(
                    <LspTranslation i18nKey="sc_ms_input_otp_wrong_one_time" />,
                    AlertType.Error
                );
                break;
            case RESPONSE_CODE.ACTIVATE_CARD_FAILURE:
                physicalCardErrors();
                break;
            case RESPONSE_CODE.ACTIVE_OK_OFF_PHYSICAL_FALSE:
            case RESPONSE_CODE.SUCCESS:
                physicalCardSuccess();
                getCardList();
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const handleActivePhysicalCard = async () => {
        const response = await cardManagementService.activeCard({
            cardId: card?.cardId,
        });
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.refNo,
                    submitFunc: handleActivePhysicalCardCommitOTP,
                });
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                break;
        }
    };

    const isAvailableActivatePhysicalCard = useMemo(
        () =>
            card.cardType === CARD_TYPES.VISA_CARD &&
            enableImmediatelyUseCCW4 &&
            !card?.isActivePhysicalStatus &&
            card?.coreCard !== CORE_CARD.BVB,
        [card, enableImmediatelyUseCCW4]
    );

    const cardActivation = () => {
        if (isVirtualVisaDebitCard) {
            virtualCardActivate();
            return;
        }

        if (isAvailableActivatePhysicalCard) {
            handleActivePhysicalCard();
            return;
        }

        const cardType =
            card.cardType === CARD_TYPES.DEBIT_CARD
                ? CARD_TYPES.FE_DEBIT_PARAM
                : CARD_TYPES.FE_CREDIT_PARAM;

        history.push({
            pathname: CardManagementNavigation.ActivateCard,
            search: `?type=${cardType}&coreCard=${card?.coreCard}`,
        });
    };

    // const timoIssueDate = useMemo(() => {
    //     if (!card?.timoIssueDate) return "";
    //     return reformatDateString(
    //         card?.timoIssueDate,
    //         "yyyy-MM-dd",
    //         "dd/MM/yyyy"
    //     );
    // }, [card?.timoIssueDate, reformatDateString]);

    const expiredDate = useMemo(() => {
        if (!card?.expiredDate) return "";
        if (card?.cardType === CARD_TYPES.VISA_CARD) {
            return card?.validTo;
        }
        return reformatDateString(card?.expiredDate, "yyyyMM", "MM/yyyy");
    }, [card?.cardType, card?.expiredDate, card?.validTo, reformatDateString]);

    const cardInfo = useMemo(() => masks[card.cardId], [masks, card]);

    const handleToggleEycIcon = useCallback(
        (cardId) => {
            if (!isShowEye) {
                if (cardInfo?.cardNumber) {
                    setShowEye(true);
                    return;
                }
                getMaskRequest({ cardId });
                setShowEye(true);
                return;
            }
            clearMaskData({ cardId });
            setShowEye(false);
        },
        [cardInfo?.cardNumber, clearMaskData, getMaskRequest, isShowEye]
    );

    const handleSwitchClick = (item) => {
        if (isFunction(onChangeActiveCard)) {
            onChangeActiveCard(item);
            const params = new URLSearchParams({
                cardId: item?.cardId,
            });
            history.replace({
                search: params.toString(),
            });
        }
    };

    const handleDecrypt = useCallback(
        (data, type) => {
            if (type !== "date") {
                return decrypt(userKey, user?.userId, data);
            }
            return data;
        },
        [user?.userId, userKey]
    );

    const handleCardInfo = useMemo(() => {
        if (hideCardInfo && cardInfo?.cardNumber && isShowEye) {
            const decryptCardNumber = handleDecrypt(cardInfo?.cardNumber);
            setCardNumber(decryptCardNumber);
            return (
                <NumberFormat
                    value={decryptCardNumber}
                    displayType="text"
                    format="#### #### #### ####"
                />
            );
        }

        if (!hideCardInfo) {
            const dummyCard = (card?.cardNumber || "").match(/.{1,4}/g);
            setCardNumber(card?.cardNumber);

            return (dummyCard || []).join(" ");
        }
        return "**** **** **** ****";
    }, [
        card?.cardNumber,
        cardInfo?.cardNumber,
        handleDecrypt,
        hideCardInfo,
        isShowEye,
    ]);

    const handleEye = useCallback(() => {
        return isShowEye ? (
            <>
                {otpFetching ? (
                    <Loading className={classes.cardInfoItemEye} size={12} />
                ) : (
                    <Icon
                        className={`${classes.cardInfoItemEye} font-icon icon-show-eye`}
                        onClick={() => handleToggleEycIcon(card.cardId)}
                    />
                )}
            </>
        ) : (
            <Icon
                className={`${classes.cardInfoItemEye} font-icon icon-hide-eye`}
                onClick={() => handleToggleEycIcon(card.cardId)}
            />
        );
    }, [
        card.cardId,
        classes.cardInfoItemEye,
        handleToggleEycIcon,
        isShowEye,
        otpFetching,
    ]);

    const handleMaskData = useCallback(
        (data, type) => {
            let mask = "";

            if (hideCardInfo) {
                switch (type) {
                    case "cvv":
                        mask = "***";
                        break;
                    case "date":
                        mask = "**/****";
                        break;
                    default:
                        break;
                }
                if (isShowEye) {
                    return otpFetching ? mask : handleDecrypt(data, type);
                }
                return mask;
            }
            return data;
        },
        [handleDecrypt, hideCardInfo, isShowEye, otpFetching]
    );

    const defaultImgURL = useMemo(() => {
        switch (card?.cardType) {
            case CARD_TYPES.DEBIT_CARD:
                return (
                    process.env.REACT_APP_API_URL +
                    t("sysParamContent:DefaultCardUrl")
                );
            case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD:
            case CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP:
                return (
                    process.env.REACT_APP_API_URL +
                    t("sysParamContent:DefaultVisaDebitVirtualUrl")
                );
            default:
                return (
                    process.env.REACT_APP_API_URL +
                    t("sysParamContent:DefaultCreditCardUrl")
                );
        }
    }, [card?.cardType, t]);

    const handleCopy = (data) => {
        navigator.clipboard.writeText(data);
        AlertController.show(t("member:timostarclub_msg_copy_successful"));
    };

    const showCopyCardNumberIcon = useMemo(() => {
        return (
            cardNumber &&
            (card?.cardType === CARD_TYPES.DEBIT_CARD ||
                (isShowEye &&
                    card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD) ||
                card?.cardType === CARD_TYPES.VISA_DEBIT_VIRTUAL_CARD_MP)
        );
    }, [card?.cardType, cardNumber, isShowEye]);

    const renderCardInfo = () => {
        if (isDebit) {
            return (
                <Grid container spacing={1} className={classes.cardInfoItem}>
                    <Grid item sm={3}>
                        <LspTypography
                            className={classes.cardInfoItemLabel}
                            variant="body2"
                            color="grey"
                        >
                            {t("mc_valid_from")}
                        </LspTypography>
                        <div className={classes.cardInfoItemValue}>
                            {card?.validFrom}
                        </div>
                    </Grid>
                    <Grid item sm={3}>
                        <LspTypography
                            className={classes.cardInfoItemLabel}
                            variant="body2"
                            color="grey"
                        >
                            {t("mc_valid_to")}
                        </LspTypography>
                        <div className={classes.cardInfoItemValue}>
                            {card?.validTo}
                        </div>
                    </Grid>
                </Grid>
            );
        }
        if (isBVBCreditCard) {
            return (
                <div className={classes.cardInfoItem}>
                    <LspTypography
                        className={classes.cardInfoItemLabel}
                        variant="body2"
                        color="grey"
                    >
                        {t("mc_expiry_date")}
                    </LspTypography>
                    <div className={classes.cardInfoItemValue}>
                        {card?.expiredDate}
                    </div>
                </div>
            );
        }

        if (isVisaCard) {
            return (
                <Grid container spacing={1} className={classes.cardInfoItem}>
                    <Grid item sm={3}>
                        <LspTypography
                            className={classes.cardInfoItemLabel}
                            variant="body2"
                            color="grey"
                        >
                            {t("card_activation_CVV")}:
                        </LspTypography>
                        <div className={classes.cardInfoItemValue}>
                            {handleMaskData(cardInfo?.cvv, "cvv")}
                        </div>
                    </Grid>
                    <Grid item sm={3}>
                        <LspTypography
                            className={classes.cardInfoItemLabel}
                            variant="body2"
                            color="grey"
                        >
                            {t("mc_valid_to")}
                        </LspTypography>
                        <div className={classes.cardInfoItemValue}>
                            {handleMaskData(expiredDate, "date")}
                        </div>
                    </Grid>
                </Grid>
            );
        }
    };

    return (
        <div
            className={`${className} ${
                isShowWarningBox ? classes.activeWarningBox : ""
            }`}
        >
            <div className={classes.containerInfo}>
                <div className={classes.leftSide}>
                    {isVirtualVisaDebitCard && (
                        <div className={classes.cardInfoItem}>
                            <LspTypography
                                className={classes.cardInfoItemLabel}
                                variant="body2"
                                color="grey"
                            >
                                {t("pot:mp_visavirtualcard_manage_source")}:
                            </LspTypography>
                            <div className={classes.cardInfoItemValue}>
                                {card?.virtualAccountName ||
                                    t("home_lb_spend_account")}
                            </div>
                        </div>
                    )}
                    {isShowCardInfo && (
                        <>
                            <div className={classes.cardInfoItem}>
                                <LspTypography
                                    className={classes.cardInfoItemLabel}
                                    variant="body2"
                                    color="grey"
                                >
                                    {t("mc_card_number")}
                                    {showCopyCardNumberIcon && (
                                        <span
                                            onClick={() =>
                                                handleCopy(cardNumber)
                                            }
                                            className={`icon-copy ${classes.copyIcon}`}
                                        />
                                    )}
                                </LspTypography>
                                <div className={classes.cardInfoItemValue}>
                                    {handleCardInfo}
                                    {hideCardInfo && handleEye()}
                                </div>
                            </div>

                            {renderCardInfo()}
                            <div
                                className={classes.cardInfoItem}
                                style={{ marginBottom: 0 }}
                            >
                                <LspTypography
                                    className={classes.cardInfoItemLabel}
                                    variant="body2"
                                    color="grey"
                                >
                                    {t("mc_cardholder_name")}
                                </LspTypography>
                                <div className={classes.cardInfoItemValue}>
                                    {card?.nameOnCard ||
                                        card?.cardName ||
                                        card?.cardEmbossingName}
                                </div>
                            </div>
                        </>
                    )}

                    {isShowWarningBox && (
                        <div>
                            <div className={classes.warningBox}>
                                <img
                                    src={warningImg}
                                    alt="warning"
                                    className={classes.warningImg}
                                />
                                <div className={classes.warningContent}>
                                    {desc.map((d) => (
                                        <div key={card.cardId}>
                                            <Interweave
                                                content={isHyperlink ? d : t(d)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {isShowChangePinBtn && (
                                <Button className={classes.btn}>
                                    {t("mc_lb_title_change_pin")}
                                </Button>
                            )}

                            {isShowContactBtn && (
                                <LspButton
                                    fullWidth
                                    className={classes.btn}
                                    onClick={contactUsClick}
                                >
                                    {t("mc_contact_us")}
                                </LspButton>
                            )}

                            {isShowActivateBtn && (
                                <LspButton
                                    fullWidth
                                    className={classes.btn}
                                    disabled={card.isDisabled}
                                    onClick={cardActivation}
                                    progressing={activatingVirtualCard}
                                >
                                    {t("mc_lb_btn_active_now")}
                                </LspButton>
                            )}

                            {isShowApplyNowBtn && (
                                <LspButton
                                    fullWidth
                                    onClick={applyCreditCard}
                                    className={classes.btn}
                                >
                                    {t("mc_lb_btn_apply_now")}
                                </LspButton>
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.rightSide}>
                    <img
                        src={card?.cardImageUrl || defaultImgURL}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultImgURL;
                        }}
                        alt="img"
                        className={classes.cardImage}
                    />
                </div>
            </div>
            {isShowPhysicalCardBox && (
                <Box>
                    <div className={classes.physicalCardBox}>
                        <img
                            src={warningImg}
                            alt="warning"
                            className={classes.physicalCardBoxImg}
                        />
                        <LspTypography variant="body3" color="grey">
                            {t(
                                "wealth:mc_v2_activate_online_feature_successful_note"
                            )}
                        </LspTypography>
                    </div>

                    <LspButton fullWidth mt={2} onClick={cardActivation}>
                        {t("wealth:mc_v2_activate_offline_feature_CTA")}
                    </LspButton>
                </Box>
            )}
            {cardList && cardList.length > 1 && (
                <div className={classes.cardDot}>
                    {cardList &&
                        cardList.map((item) => (
                            <span
                                key={item.cardId}
                                className={`${classes.dot} ${
                                    card.cardId !== item.cardId
                                        ? classes.dotDeActive
                                        : ""
                                }`}
                                onClick={() => handleSwitchClick(item)}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    masks: state.cardInfo.masks?.data,
    otpFetching: state.cardInfo?.masks?.fetching,
    cancelOTPRequest: state.cardInfo?.masks?.cancelOTPRequest,
    userKey: state.user?.featureState?.data?.userKey,
    user: state.user.info,
    activatingVirtualCard: state.cardInfo?.cardStatusUpdating,
    featureState: state.user.featureState.data,
});

const mapDispatchToProps = (dispatch) => ({
    getMaskRequest: (payload) =>
        dispatch(CardManagementActions.getMaskRequest(payload)),
    clearMaskData: (payload) =>
        dispatch(CardManagementActions.clearMaskData(payload)),
    updateCardStatus: (payload) =>
        dispatch(CardManagementActions.updateCardStatus(payload)),
    getCardList: () => dispatch(CardManagementActions.getCardList()),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(CardInfo));
