import { RESPONSE_CODE } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { call, put } from "redux-saga/effects";
import billActions from "@redux/actions/bill";
import billServices from "@services/bill";

export function* getBillListSaga() {
    const response = yield call(billServices.getBillList);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(billActions.getBillListSuccess(data.results));
            break;
        case RESPONSE_CODE.NOT_ACCEPTABLE:
            yield put(billActions.getBillListSuccess([]));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(billActions.getBillListError());
            break;
    }
}

export function* getSplitBillSaga(action) {
    const { payload } = action;
    const response = yield call(billServices.getSplitBill, payload);
    if (response) {
        const { code, data } = response.data;
        switch (code) {
            case RESPONSE_CODE.SUCCESS:
                yield put(billActions.getSplitBillSuccess(data.splitBill));
                break;
            default:
                GlobalDialogController.showError({ errorCode: code });
                yield put(billActions.getSplitBillError());
                break;
        }
    }
}

export function* getSplitBillPaymentSaga(action) {
    const { payload } = action;
    const response = yield call(billServices.getSplitBillPayment, payload);
    const { code, data } = response.data;
    switch (code) {
        case RESPONSE_CODE.SUCCESS:
            yield put(billActions.getSplitBillPaymentSuccess(data));
            break;
        default:
            GlobalDialogController.showError({ errorCode: code });
            yield put(billActions.getSplitBillPaymentError());
            break;
    }
}
