import Content from "@components/Content";
import LspButton from "@components/LspButton";
import PageHeaderBack from "@components/PageHeaderBack";
import { VINA_WEALTH_STEPS } from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import { Box, makeStyles } from "@material-ui/core";
import wealthService from "@services/wealth";
import Interweave from "interweave";
import { useMemo, useRef, useEffect, useCallback, useState } from "react";
import logo from "@assets/images/vinacapital@2x.png";
import useOTP from "@helpers/useOTP";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RESPONSE_CODE } from "@config/constants";
import { GlobalDialogTypes } from "@redux/actions/global-dialog";
import moveMoneyService from "@services/move-money";
import vinaWealthAction from "@redux/actions/vina-wealth";

const useStyles = makeStyles((theme) => {
    return {
        contentIntro: {
            boxShadow: theme.shadows[2],
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            fontSize: theme.typography.pxToRem(16),
            padding: theme.spacing(2.5, 0),
            textAlign: "center",
            borderRadius: theme.shape.radiusLevels[1],
        },
        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
        introBtn: {
            margin: theme.spacing(3, 0),
        },
        contentItem: {
            marginTop: theme.spacing(1.25),
        },
        logo: {
            width: 160,
            paddingBottom: theme.spacing(1.5),
        },
        investAmount: {
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(2.5, 0, 0.75, 0),
            "& > div": {
                fontWeight: "bold",
            },
            "& > span": {
                fontSize: theme.typography.pxToRem(24),
                color: theme.palette.blue.main,
            },
        },
        investInfo: {
            padding: theme.spacing(1.25),
            marginTop: theme.spacing(2),
            fontSize: theme.typography.pxToRem(15),
            background: theme.palette.background.header,
        },
        desc: {
            marginBottom: theme.spacing(1.5),
        },
        title: {
            fontSize: theme.typography.pxToRem(13),
            color: theme.palette.grey[400],
        },
    };
});

const VinaWealthReviewInfo = ({
    amount,
    onBack,
    onNext,
    productDetail,
    user,
    setInfoReceipt,
    passportInfo,
    setCongratulationFailed,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    const { setLoading, openOTPDialog, closeOTPDialog } = useOTP();
    const unmounted = useRef(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    const fullAddress = useMemo(() => {
        const infoAcc = `${user.fullName}<br/>${user.phoneNumber}`;

        const addressList = user.address;
        let strAddress = "";
        if (addressList.addressName) strAddress = addressList.addressName;
        if (addressList.wardName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_ward_prefix") +
                    addressList.wardName;
            }
        }
        if (addressList.distName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress =
                    strAddress +
                    t("user_profile_district_prefix") +
                    addressList.distName;
            }
        }
        if (addressList.cityName) {
            if (strAddress) {
                strAddress += ", ";
                strAddress += addressList.cityName;
            }
        }

        return `${infoAcc}<br />${strAddress}`;
    }, [user, t]);

    const submitOtp = async (dataToPost) => {
        setLoading(true);
        const payload = {
            productKey: productDetail.productKey,
            ...dataToPost,
        };

        const { data } = await wealthService.commitOTP(payload);
        if (unmounted.current) return;

        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setInfoReceipt(data.data);
                onNext(VINA_WEALTH_STEPS.RESULT);
                closeOTPDialog();
                break;

            default:
                GlobalDialogController.showFinancialError({
                    errorCode: data.code,
                });
                break;
        }
    };

    const resendOTP = useCallback(({ refNo }) => {
        moveMoneyService.resendOTP({
            refNo,
            notification: "sms",
        });
    }, []);

    const applyProduct = async () => {
        setSubmitting(true);
        const dataToPost = {
            amount: +amount,
            productKey: productDetail.productKey,
        };
        if (passportInfo) {
            if (passportInfo.placeIdNumber) {
                dataToPost.docIssuedPlace = passportInfo.placeIdNumber;
            }

            if (passportInfo.dateIdNumber) {
                dataToPost.docIssuedDate = passportInfo.dateIdNumber;
            }
        }

        const { data } = await wealthService.applyProduct(dataToPost);
        if (unmounted.current) return;
        setSubmitting(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setCongratulationFailed(false);
                setInfoReceipt(data.data);
                onNext(VINA_WEALTH_STEPS.RESULT);
                break;
            case RESPONSE_CODE.OTP_REQUIRED:
                openOTPDialog({
                    refNo: data.data.refNo,
                    submitFunc: submitOtp,
                    resendFunc: resendOTP,
                });
                break;
            case RESPONSE_CODE.OPEN_VINA_WEALTH_SUCCESS_TOP_UP_FAILURE:
                setCongratulationFailed(true);
                onNext(VINA_WEALTH_STEPS.RESULT);
                break;
            case RESPONSE_CODE.NOT_ALLOWED:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("global_title_insuficient_funds"),
                        content: "installment_no_enough_money_to_pay",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.DAILY_LIMITED_EXCEEDED:
            case RESPONSE_CODE.DAILY_LIMIT_TRANSFER:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("global_title_daily_limit_exceed"),
                        content: "ms_lb_transfer_daily_limit",
                        button: t("lb_ok"),
                    },
                });
                break;
            case RESPONSE_CODE.IOTP_REQUIRED:
                GlobalDialogController.showError({
                    errorCode: data.code,
                    type: GlobalDialogTypes.FinancialError,
                });
                break;
            case RESPONSE_CODE.EKYC_REACH_LIMIT:
            case RESPONSE_CODE.MONTHLY_LIMIT_FOREIGNER:
                const limitAmount = data.data.remainingLimit || 0;
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("mc_lb_title_notice"),
                        content: t("mm_over_daily_limit").replace(
                            "%@",
                            limitAmount
                        ),
                        button: t("lb_btn_ok_got_it"),
                    },
                });
                break;

            case RESPONSE_CODE.FAST_CASH_NOT_ALLOW:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("gs_contribute_error_title"),
                        content: t("payee_card_amount_submitted_invalid"),
                        button: t("lb_ok"),
                    },
                });
                break;

            case RESPONSE_CODE.FOREIGNER:
            case RESPONSE_CODE.RE_ENROLL:
            case RESPONSE_CODE.BIOS_NEW_DEVICE:
            case RESPONSE_CODE.REQUIRED_ENROLL:
                GlobalDialogController.showError({ errorCode: data.code });
                break;

            default:
                GlobalDialogController.showCustomDialog({
                    dialogInfo: {
                        iconImage: "Warning",
                        header: t("wealth:vc3_final_case1_title"),
                        content: t("wealth:vc3_final_case1_msg"),
                        doubleButton: [
                            {
                                label: t("wealth:vc3_try_again_btn"),
                                onClick: () => {
                                    GlobalDialogController.hide();
                                    applyProduct();
                                },
                            },
                            {
                                label: t("wealth:vc3_cancel_btn"),
                                onClick: () => GlobalDialogController.hide(),
                            },
                        ],
                    },
                });

                break;
        }
    };
    return (
        <Content size="xs">
            <PageHeaderBack onBack={onBack}>
                {t("wealth:vc3_confirmation_title")}
            </PageHeaderBack>
            <Box>
                <Box className={classes.contentIntro}>
                    <div>
                        <img src={logo} alt="" className={classes.logo} />
                    </div>
                    <div className={classes.investAmount}>
                        <div>{t("wealth:vinawealth2_investedamount_lb")}</div>
                        <span>{formatNumber(amount)}</span>
                    </div>
                    <div className={classes.investInfo}>
                        {t("wealth:vc3_acc_details_title")}
                    </div>
                    <Box p={2.5}>
                        <div className={classes.title}>
                            {t("wealth:vinawealth2_payment_info_type")}
                        </div>
                        <div className={classes.desc}>
                            {t(
                                `wealth:vinawealth2_investment_type_${productDetail.productKey}`
                            )}
                        </div>
                        <div className={classes.title}>
                            {t("wealth:vinawealth2_payment_info_contact")}
                        </div>
                        <div className={classes.desc}>
                            <Interweave content={fullAddress} />
                        </div>
                    </Box>
                </Box>
                <Box className={classes.introBtn}>
                    <LspButton
                        type="submit"
                        fullWidth
                        onClick={applyProduct}
                        progressing={submitting}
                    >
                        {t("wealth:vinawealth2_movemoney_btn")}
                    </LspButton>
                </Box>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    user: state.user.info,
    amount: state.vinaWealth.amount,
    productDetail: state.vinaWealth.productDetail,
    passportInfo: state.vinaWealth.passportInfo,
});
const mapDispatch = (dispatch) => ({
    setInfoReceipt: (payload) =>
        dispatch(vinaWealthAction.setInfoReceipt(payload)),
    setCongratulationFailed: (payload) =>
        dispatch(vinaWealthAction.setCongratulationFailed(payload)),
});

export default connect(mapState, mapDispatch)(VinaWealthReviewInfo);
