import { makeStyles } from "@material-ui/core";
import { memo, useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    lspBreak: {
        position: "relative",
    },
    lspBreakLine: {
        opacity: "0.18",
        border: `2px dashed ${theme.palette.magicalGrey[300]}`,
    },
    lspBreakCircle: {
        width: 18,
        height: 18,
        position: "absolute",
        borderRadius: "50%",

        "&:before": {
            borderRadius: "0 150px 150px 0",
        },
        "&:after": {
            borderRadius: "150px 0 0 150px",
        },
    },
    lspBreakCircleRight: {
        bottom: -6,
        right: -10,
    },
    lspBreakCircleLeft: {
        left: -10,
        bottom: -6,
    },
    default: {
        backgroundColor: theme.palette.background.default,
    },
    grey: {
        backgroundColor: theme.palette.background.grey,
    },
}));
const LspBreakLine = ({ color }) => {
    const classes = useStyles();

    const styleColor = useMemo(() => {
        switch (color) {
            case "grey":
                return classes.grey;

            default:
                return classes.default;
        }
    }, [classes.default, classes.grey, color]);

    return (
        <>
            <div className={classes.lspBreak}>
                <div className={classes.lspBreakLine} />
                <div
                    className={`${classes.lspBreakCircle} ${classes.lspBreakCircleRight} ${styleColor}`}
                />
                <div
                    className={`${classes.lspBreakCircle} ${classes.lspBreakCircleLeft} ${styleColor}`}
                />
            </div>
        </>
    );
};
export default memo(LspBreakLine);
