import { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isFunction } from "lodash";

const useStyles = makeStyles((theme) => ({
    title1: {
        fontSize: theme.typography.pxToRem(28),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 42 / 28,
    },
    title2: {
        fontSize: theme.typography.pxToRem(24),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 34 / 24,
    },
    heading1: {
        fontSize: theme.typography.pxToRem(18),
        fontFamily: "Open Sans Bold",
        lineHeight: 28 / 18,
    },
    heading2: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "Open Sans Bold",
        lineHeight: 24 / 16,
    },
    heading3: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans Bold",
        lineHeight: 22 / 14,
    },
    heading4: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans Bold",
        lineHeight: 18 / 12,
    },
    subheading1: {
        fontSize: theme.typography.pxToRem(18),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 28 / 18,
    },
    subheading2: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 24 / 16,
    },
    subheading3: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 22 / 14,
    },
    subheading4: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 18 / 12,
    },
    body1: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "Open Sans",
        lineHeight: 24 / 16,
    },
    body2: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans",
        lineHeight: 22 / 14,
    },
    body3: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans",
        lineHeight: 18 / 12,
    },
    tiny: {
        fontSize: theme.typography.pxToRem(10),
        fontFamily: "Open Sans",
        lineHeight: 16 / 10,
    },
    button1: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "Open Sans Semi Bold",
        textTransform: "uppercase",
        lineHeight: 24 / 16,
    },
    button2: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans Semi Bold",
        textTransform: "uppercase",
        lineHeight: 22 / 14,
    },
    button3: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans Semi Bold",
        textTransform: "uppercase",
        lineHeight: 18 / 12,
    },
    hyperlink1: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans Bold",
        textDecoration: "underline",
        lineHeight: 22 / 14,
        cursor: "pointer",
    },
    hyperlink2: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans Bold",
        textDecoration: "underline",
        lineHeight: 18 / 12,
        cursor: "pointer",
    },
    hyperlink3: {
        fontSize: theme.typography.pxToRem(10),
        fontFamily: "Open Sans Bold",
        lineHeight: 16 / 10,
        textDecoration: "underline",
        cursor: "pointer",
    },
    status1: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 22 / 14,
    },
    status2: {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Open Sans Semi Bold",
        lineHeight: 18 / 12,
    },
    black: {
        color: theme.palette.white.contrastText,
    },
    white: {
        color: theme.palette.black.contrastText,
    },
    grey: {
        color: theme.palette.magicalGrey[400],
    },
    primary: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
    purple: {
        color: theme.palette.purple.main,
    },
    blue: {
        color: theme.palette.blue.main,
    },
    orange: {
        color: theme.palette.orange.main,
    },
    gold: {
        color: theme.palette.gold.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    disabled: {
        opacity: theme.palette.opacity.disabled,
        cursor: "default",
    },
    link: {
        cursor: "pointer",
    },
    uppercase: {
        textTransform: "uppercase",
    },
    capitalize: {
        textTransform: "capitalize",
    },
    inherit: {
        textTransform: "none",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));

/**
 * @typedef LspTypographyProps
 * @type {object}
 * @property {("title1"|"title2"|"heading1"|"heading2"|"heading3"|"heading4"|"subheading1"|"subheading2"|"subheading3"|"subheading4"|"body1"|"body2"|"body3"|"tiny"|"button1"|"button2"|"button3"|"hyperlink1"|"hyperlink2"|"status1"|"status2")} variant
 * @property {("black"|"white"|"grey"|"primary"|"secondary"|"purple"|"blue"|"orange"|"gold"|"success"|"error")} color - color of the text
 * @property {boolean} disabled
 * @property {("p"|"span"|"div"|"a")} component
 * @property {boolean} uppercase
 */

/**
 * @param {LspTypographyProps} props
 */
const LspTypography = ({
    children,
    className,
    variant,
    color,
    disabled,
    uppercase,
    inherit,
    component,
    onClick,
    capitalize,
    textCenter,
    textRight,
    ...restProps
}) => {
    const classes = useStyles();

    const handleOnClick = ($event) => {
        if (!disabled && isFunction(onClick)) {
            onClick($event);
        }
    };

    return (
        <Typography
            {...restProps}
            variant="body1"
            component={component}
            className={clsx(classes[variant], classes[color], className, {
                [classes.link]: component === "a",
                [classes.disabled]: disabled,
                [classes.uppercase]: uppercase,
                [classes.inherit]: inherit,
                [classes.capitalize]: capitalize,
                [classes.textCenter]: textCenter,
                [classes.textRight]: textRight,
            })}
            onClick={handleOnClick}
        >
            {children}
        </Typography>
    );
};

LspTypography.propTypes = {
    inherit: PropTypes.bool,
    uppercase: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.oneOf([
        "title1",
        "title2",
        "heading1",
        "heading2",
        "heading3",
        "heading4",
        "subheading1",
        "subheading2",
        "subheading3",
        "subheading4",
        "body1",
        "body2",
        "body3",
        "tiny",
        "button1",
        "button2",
        "button3",
        "hyperlink1",
        "hyperlink2",
        "hyperlink3",
        "status1",
        "status2",
    ]),
    color: PropTypes.oneOf([
        "black",
        "white",
        "grey",
        "primary",
        "secondary",
        "purple",
        "blue",
        "orange",
        "gold",
        "success",
        "error",
    ]),
    disabled: PropTypes.bool,
    component: PropTypes.oneOfType([
        PropTypes.oneOf(["p", "span", "div", "a", "i"]),
        PropTypes.node,
    ]),
    capitalize: PropTypes.bool,
    textCenter: PropTypes.bool,
    textRight: PropTypes.bool,
};

LspTypography.defaultProps = {
    inherit: false,
    uppercase: false,
    capitalize: false,
    className: "",
    variant: "body1",
    color: "black",
    disabled: false,
    component: "p",
    textCenter: false,
    textRight: false,
};

export default memo(LspTypography);
