import { UAParser } from "ua-parser-js";

const getClientId = () => {
    const { browser, device } = UAParser();
    const deviceType = device.type || "desktop";
    return `:WEB:WEB:${
        process.env.REACT_APP_VERSION
    }:WEB:${deviceType}:${browser.name.toLowerCase()}`;
};

export default getClientId;
