import { FetchStatus } from "@config/constants";
import { BookingActionTypes } from "@redux/actions/booking";
import produce from "immer";

/* ------------- Initial State ------------- */
const initialState = {
    selectedDate: {},
    selectedTime: {},
    bookingList: null,
    hangoutList: null,
    fetching: false,
    status: null,
    selectedHangout: {},

    onComplete: null,
    onCancel: null,
    id: null,
    type: null,
    serviceName: null,
    detail: {},
    fetchingDetail: false,
    method: null,
    hangoutProvince: {},

    addressType: null,
    address: {},
};

/* ------------- Reducer ------------- */
const bookingReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case BookingActionTypes.Reset:
                const stateNames = Object.keys(initialState);
                stateNames.forEach((name) => {
                    draft[name] = initialState[name];
                });
                break;
            case BookingActionTypes.GetBookingList:
                draft.bookingList = initialState.bookingList;
                draft.fetching = true;
                draft.status = FetchStatus.Pending;
                break;
            case BookingActionTypes.GetBookingListSuccess:
                draft.bookingList = action.payload;
                draft.fetching = false;
                draft.status = FetchStatus.Success;
                break;
            case BookingActionTypes.GetBookingListError:
                draft.bookingList = initialState.bookingList;
                draft.fetching = false;
                draft.status = FetchStatus.Error;
                break;
            case BookingActionTypes.SetSelectedDate:
                draft.selectedDate = action.payload;
                break;
            case BookingActionTypes.SetSelectedTime:
                draft.selectedTime = action.payload;
                break;
            case BookingActionTypes.SetTimeList:
                draft.bookingList = action.payload;
                break;
            case BookingActionTypes.SetHangoutList:
                draft.hangoutList = action.payload;
                break;
            case BookingActionTypes.SetSelectedHangout:
                draft.selectedHangout = action.payload;
                break;
            case BookingActionTypes.SetBookingConfigHandler:
                draft.id = action.payload?.id;
                draft.type = action.payload?.type;
                draft.serviceName = action.payload?.serviceName;
                draft.onComplete = action.payload?.onComplete;
                draft.onCancel = action.payload?.onCancel;
                break;
            case BookingActionTypes.GetBookingDetail:
                draft.bookingList = initialState.detail;
                draft.fetchingDetail = true;
                draft.status = FetchStatus.Pending;
                break;
            case BookingActionTypes.GetBookingDetailSuccess:
                draft.detail = action.payload;
                draft.fetchingDetail = false;
                draft.status = FetchStatus.Success;
                break;
            case BookingActionTypes.GetBookingDetailError:
                draft.detail = initialState.detail;
                draft.fetchingDetail = false;
                draft.status = FetchStatus.Error;
                break;
            case BookingActionTypes.SetBookingMethod:
                draft.method = action.payload;
                break;
            case BookingActionTypes.SetBookingHangoutProvince:
                draft.hangoutProvince = action.payload;
                break;
            case BookingActionTypes.SetBookingAddressType:
                draft.addressType = action.payload;
                break;
            case BookingActionTypes.SetBookingAddress:
                draft.address = action.payload;
                break;
            default:
                break;
        }
    });

export default bookingReducer;
