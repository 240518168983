import Content from "@components/Content";
import LspReceipt from "@components/LspReceipt";
import { CreditCardNavigationKey } from "@config/constants";
import { Box, Paper } from "@material-ui/core";
import { useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const PaymentSuccess = ({ transferInfo }) => {
    const history = useHistory();

    const onClose = useCallback(() => {
        history.push(CreditCardNavigationKey.TransactionList);
    }, [history]);

    return (
        <Content size="sm">
            <Box component={Paper} overflow="hidden" elevation={4}>
                <LspReceipt
                    onClose={onClose}
                    rawReceipt={transferInfo}
                    noMarginTop
                />
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    transferInfo: state.moveMoney.info,
});

export default connect(mapState)(PaymentSuccess);
