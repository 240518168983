import { REGEX_PATTERN } from "@config/constants";
import { useCallback, useMemo } from "react";
import escapeAccentVietnamese from "./escapeAccentVietnamese";

const useInputSpecialCharacter = () => {
    /**
     * return string without special characters
     * @param   {any} pattern  Regex pattern
     * @param   {string} text Value need to be replaced special characters
     * @param   {bool} hasEscapeAccentVietnamese
     * @return  {string} string without special characters
     */
    const replaceSpecialCharacter = useCallback(
        ({ pattern, text, hasEscapeAccentVietnamese }) => {
            let input = text;

            if (hasEscapeAccentVietnamese) {
                input = escapeAccentVietnamese(input);
            }

            switch (pattern) {
                case REGEX_PATTERN.NICK_NAME:
                    input = escapeAccentVietnamese(input);
                    break;
                default:
                    break;
            }

            return input.replace(pattern, "");
        },
        []
    );

    const characterValidation = useCallback((value, regex) => {
        const condition = new RegExp(regex);
        return condition.test(value);
    }, []);

    const specialCharacter = useMemo(
        () => ({
            replaceSpecialCharacter,
            characterValidation,
        }),
        [replaceSpecialCharacter, characterValidation]
    );

    return specialCharacter;
};

export default useInputSpecialCharacter;
