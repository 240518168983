import { RESPONSE_CODE } from "@config/constants";
import PageHeaderBack from "@components/PageHeaderBack";

import {
    WEALTH_VINA_TYPES,
    WEALTH_PRODUCT_KEYS,
    WEALTH_VINA_ACCOUNT_STATUS,
    VINA_WEALTH_STEPS,
    VINA_WEALTH_HISTORY_TRANSACTION_TYPES,
} from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import { Box, makeStyles } from "@material-ui/core";
import vinaWealthAction from "@redux/actions/vina-wealth";
import wealthService from "@services/wealth";
import { useState, useRef, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import VinaWealthTransaction from "../VinaWealthTransaction";
import VinaWealthItem from "./VinaWealthItem";

const useStyles = makeStyles((theme) => {
    return {
        tdList: {
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "space-between",
        },

        titleContent: {
            fontSize: theme.typography.pxToRem(20),
            color: theme.palette.primary.main,
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: theme.spacing(3.75),
            fontWeight: "bold",
        },
    };
});

const STATUS_UNIT = {
    DOWN: "down",
    UP: "up",
};

const VinaWealthDashboard = ({
    notExistedAccount,
    onClickItem,
    setProductDetailStore,
    onBack,
    onMoreTransaction,
    user,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const unmounted = useRef(false);
    const [subProducts, setSubProduct] = useState(null);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const { formatNumber } = useNumber();
    const learnMoreFunc = useCallback(
        (product) => {
            setProductDetailStore(product);
            onClickItem(VINA_WEALTH_STEPS.DETAIL);
        },
        [setProductDetailStore, onClickItem]
    );

    const getOverviewAccount = useCallback(
        (product) => {
            setProductDetailStore(product);

            onClickItem(VINA_WEALTH_STEPS.ACCOUNT_OVERVIEW);
        },
        [onClickItem, setProductDetailStore]
    );

    const getBtnForExistingAccount = useCallback(
        (product) => {
            product.FEIsContributeAccount = true;
            product.FEBtnText = t("wealth:vc3_contribute_btn");
            product.FEBtnFunc = () => {
                setProductDetailStore(product);
                onClickItem(VINA_WEALTH_STEPS.INPUT_AMOUNT);
            };
            product.FELearnMoreText = t("wealth:vc3_seemore_btn");
            product.FELearnMoreFunc = () => {
                getOverviewAccount(product);
            };
            return product;
        },
        [t, getOverviewAccount, onClickItem, setProductDetailStore]
    );

    const checkValidDocumentInfo = useCallback(() => {
        let documentIsValid = true;
        if (user) {
            const documentId = user.legalId;
            const documentIssueDate = user.docIssueDate;
            const documentIssuePlace = user.docIssuePlace;

            // if (documentId) {
            //     $scope.legalId = documentId;
            // }

            if (!documentId || !documentIssueDate || !documentIssuePlace) {
                onClickItem(VINA_WEALTH_STEPS.PASSPORT_INFO);
                documentIsValid = false;
            }
        }
        return documentIsValid;
    }, [onClickItem, user]);

    const createAccountClick = useCallback(
        (product) => {
            setProductDetailStore(product);
            if (notExistedAccount) {
                if (checkValidDocumentInfo) {
                    onClickItem(VINA_WEALTH_STEPS.INPUT_AMOUNT);
                } else {
                    onClickItem(VINA_WEALTH_STEPS.PASSPORT_INFO);
                }
            } else {
                onClickItem(VINA_WEALTH_STEPS.INPUT_AMOUNT);
            }
        },
        [
            onClickItem,
            setProductDetailStore,
            checkValidDocumentInfo,
            notExistedAccount,
        ]
    );

    const getBtnForNewAccount = useCallback(
        (product) => {
            product.FEIsContributeAccount = false;
            product.FEBtnText = t("wealth:vc3_create_invest_btn");
            product.FEBtnFunc = () => {
                createAccountClick(product);
            };
            product.FELearnMoreFunc = () => {
                learnMoreFunc(product);
            };
            return product;
        },
        [t, createAccountClick, learnMoreFunc]
    );

    const getCommonContentDashBoard = useCallback(
        (product) => {
            product.pricing = t(`wealth:vinawealth2_min_${product.productKey}`);
            product.pricingCurrency = formatNumber(
                t(`wealth:vinawealth2_min_${product.productKey}`)
            );
            product.FETitle = t(`wealth:vc3_title_${product.productKey}`);
            product.FESubTitle = t(
                `wealth:vc3_sub_title_${product.productKey}`
            );
            product.FEDesc = t(
                `wealth:vc3_main_description_${product.productKey}`
            );
            product.FESubdesc = t(
                `wealth:vc3_sub_description_${product.productKey}`
            );
            product.FEOverview = t(
                `wealth:vc3_vinaflex_overview_${product.productKey}`,
                {
                    returnObjects: true,
                }
            );

            return product;
        },
        [t, formatNumber]
    );

    const getContentForNoExistingAccount = useCallback(
        (products) => {
            for (let i = 0; i < products.length; i++) {
                let currentProduct = products[i];
                currentProduct = getBtnForNewAccount(currentProduct);
                currentProduct = getCommonContentDashBoard(currentProduct);
            }
            setSubProduct(products);
        },
        [getBtnForNewAccount, getCommonContentDashBoard]
    );
    const mapInfoForExistingAccount = useCallback(
        (products) => {
            let accNumber = "";

            for (let i = 0; i < products.length; i++) {
                let currentProduct = products[i];

                if (
                    currentProduct.accountStatus ===
                    WEALTH_VINA_ACCOUNT_STATUS.NEW_ACCOUNT
                ) {
                    currentProduct = getBtnForNewAccount(currentProduct);
                } else {
                    currentProduct = getBtnForExistingAccount(currentProduct);

                    if (currentProduct.currentBalance === undefined) {
                        currentProduct.FECurrentBalance = t("wealth:vc3_lb_na");
                    } else {
                        currentProduct.FECurrentBalance = formatNumber(
                            currentProduct.currentBalance
                        );
                    }

                    if (
                        currentProduct.balanceUnrealizedGainLoss === undefined
                    ) {
                        currentProduct.FEBalanceUnrealizedGainLoss = t(
                            "wealth:vc3_lb_na"
                        );
                    } else {
                        currentProduct.FEBalanceUnrealizedGainLoss = formatNumber(
                            currentProduct.balanceUnrealizedGainLoss
                        );
                    }

                    if (currentProduct.fundUnits === undefined) {
                        currentProduct.FEFundUnits = t("wealth:vc3_lb_na");
                    } else {
                        currentProduct.FEFundUnits = formatNumber(
                            currentProduct.fundUnits
                        );
                    }
                    currentProduct.FENAV = currentProduct.nav
                        ? formatNumber(currentProduct.nav)
                        : t("wealth:vc3_lb_na");
                    currentProduct.FEStatusUnit =
                        STATUS_UNIT.DOWN === currentProduct.status.toLowerCase()
                            ? "down"
                            : "";
                }

                if (currentProduct.accountNumber) {
                    if (accNumber !== currentProduct.accountNumber) {
                        accNumber = currentProduct.accountNumber
                            ? currentProduct.accountNumber
                            : "";
                    }
                }

                currentProduct = getCommonContentDashBoard(currentProduct);
            }
            setSubProduct(products);
        },
        [
            t,
            getCommonContentDashBoard,
            formatNumber,
            getBtnForExistingAccount,
            getBtnForNewAccount,
        ]
    );

    const getDashBoardInfo = useCallback(async () => {
        const dataToPost = {
            productKey: WEALTH_PRODUCT_KEYS.VINA_WEALTH_V3,
            type: WEALTH_VINA_TYPES.DASHBOARD,
        };
        const { data } = await wealthService.actionWealthSubProducts(
            dataToPost
        );
        if (unmounted.current) return;

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                if (data.data.products) {
                    const { products } = data.data;
                    if (notExistedAccount) {
                        getContentForNoExistingAccount(products);
                        return;
                    }
                    mapInfoForExistingAccount(products);
                }
                break;

            default:
                GlobalDialogController.showError({
                    errorCode: data.code,
                });
                break;
        }
    }, [
        getContentForNoExistingAccount,
        mapInfoForExistingAccount,
        notExistedAccount,
    ]);

    useEffect(() => {
        getDashBoardInfo();
    }, [getDashBoardInfo]);

    return (
        <Box pb={2}>
            <Box className={classes.titleContent}>
                <PageHeaderBack onBack={notExistedAccount ? onBack : null}>
                    {notExistedAccount
                        ? t("wealth:vc3_start_investing_title")
                        : t("wealth:vc3_manage_title")}
                </PageHeaderBack>
            </Box>

            <Box className={classes.tdList}>
                {subProducts?.map((product) => (
                    <VinaWealthItem key={product.fundType} product={product} />
                ))}
            </Box>
            {!notExistedAccount && (
                <Box>
                    <VinaWealthTransaction
                        products={subProducts}
                        type={
                            VINA_WEALTH_HISTORY_TRANSACTION_TYPES.ACCOUNT_INFO
                        }
                        onMoreTransaction={onMoreTransaction}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapState = (state) => ({
    serverTime: state.user.serverTime.value,
    serverTimeFetching: state.user.serverTime.fetching,
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    setProductDetailStore: (payload) =>
        dispatch(vinaWealthAction.setProductDetail(payload)),
});

export default connect(mapState, mapDispatch)(VinaWealthDashboard);
