import { Box, ButtonBase, Grow, makeStyles } from "@material-ui/core";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    BANK_INFO,
    MoveMoneyType,
    SpendAccountNavigationKey,
} from "@config/constants";
import useNumber from "@helpers/useNumber";
import { bankList } from "@i18n/resources/bankList.json";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { LogoMark } from "@components/LspBranding";
import { BankIcon, CardIcon } from "@components/LspIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        padding: theme.spacing(1.5, 2),
        fontSize: theme.typography.pxToRem(14),
        boxShadow: theme.shadows[2],
        fontFamily: theme.typography.fontFamily,
    },
    icon: {
        color: theme.palette.grey[500],
        marginRight: theme.spacing(1),
    },
    deletedLabel: {
        color: theme.palette.orange.main,
    },
    brandingIcon: {
        color: theme.palette.primary.main,
    },
    destinationIcon: {
        border: `1px solid ${theme.palette.neutral.white}`,
        backgroundColor: theme.palette.neutral.white,
        boxShadow: theme.shadows[2],
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: theme.spacing(1),
    },
}));

const PayeeDestination = ({ destination, disabled, isDeleted }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const { formatCardNumber } = useNumber();

    const checkDestinationWasRemoved = useCallback((bankId) => {
        const bankInfo = bankList.find((b) => b.bankId === bankId);
        const isRemove = bankInfo?.isRemove || false;
        return isRemove;
    }, []);

    const destinationNotAvailableLongerWarning = useCallback(
        (bankShortName) => {
            GlobalDialogController.showCustomDialog({
                dialogInfo: {
                    iconImage: "Error",
                    header: t("payee_list_title_invalid_ben_bank").replace(
                        "%@",
                        bankShortName
                    ),
                    content: t(
                        "payee_list_notification_invalid_ben_bank"
                    ).replace("%@", bankShortName),
                    button: t("payee_list_btn_ben_bank_got_it"),
                },
            });
        },
        [t]
    );

    const onClick = useCallback(() => {
        let channel = null;
        if (
            destination.bankId &&
            checkDestinationWasRemoved(destination.bankId)
        ) {
            destinationNotAvailableLongerWarning(destination.bankShortName);
            return;
        }

        if (destination.cardNumber) {
            channel = MoveMoneyType.DebitCard;
        } else if (destination.bankId) {
            if (destination.bankId === "999") {
                channel = MoveMoneyType.Internal;
            } else {
                channel = MoveMoneyType.BankAccount;
            }
        }

        localStorage.setItem(
            "transferDestination",
            JSON.stringify(destination)
        );
        const path = `/spend-account/${SpendAccountNavigationKey.MoveMoney}/${channel}?autofill=1`;

        history.push(path);
    }, [
        destination,
        history,
        checkDestinationWasRemoved,
        destinationNotAvailableLongerWarning,
    ]);

    const label = useMemo(() => {
        let num = "";
        if (destination.accountNumber) {
            num = destination.accountNumber;
        } else {
            num = formatCardNumber(destination.cardNumber);
        }
        return `${num} | ${destination.bankShortName || destination.bankName}`;
    }, [destination, formatCardNumber]);

    const DestinationTypeIcon = useMemo(() => {
        if (
            destination.bankId === BANK_INFO.DEFAULT_BANK_1_ID ||
            destination.bankName.toLowerCase() === BANK_INFO.DEFAULT_BANK_1
        ) {
            return () => (
                <div className={classes.destinationIcon}>
                    <LogoMark
                        variant="filled"
                        className={classes.brandingIcon}
                    />
                </div>
            );
        }
        if (destination.accountNumber) {
            return BankIcon;
        }
        return CardIcon;
    }, [destination, classes.brandingIcon, classes.destinationIcon]);

    return (
        <Box
            component={ButtonBase}
            onClick={onClick}
            disabled={disabled}
            justifyContent="flex-start"
            alignItems="center"
            marginY={0.5}
            className={classes.root}
        >
            <DestinationTypeIcon className={classes.icon} />
            <Box flex={1} textAlign="left">
                {label}
            </Box>
            {isDeleted && (
                <Grow in={isDeleted}>
                    <Box className={classes.deletedLabel}>
                        {t("payee_destination_text_deleted")}
                    </Box>
                </Grow>
            )}
        </Box>
    );
};

export default memo(PayeeDestination);
