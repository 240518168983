import cloneDeep from "lodash/cloneDeep";
import { format, parse } from "date-fns";
import groupBy from "lodash/groupBy";

const mapLixiList = (currentList, newList, isGroupList) => {
    const mapBasicInfo = (list) => {
        const mapList = list?.map((item) => {
            const parsedDate = item.sendDate
                ? parse(item.sendDate, "d/M/yyyy HH:mm:ss xxxx", new Date())
                : "";
            const formattedDate = parsedDate
                ? format(parsedDate, "d/MM/yyyy")
                : parsedDate;
            return {
                ...item,
                FEDate: formattedDate,
            };
        });

        return mapList;
    };

    const groupList = (list) => {
        const groupedList = groupBy(list, "FEDate");
        const finalList = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item in groupedList) {
            if (Object.prototype.hasOwnProperty.call(groupedList, item)) {
                finalList.push({
                    date: item,
                    list: groupedList[item],
                });
            }
        }

        return finalList;
    };

    if (!Array.isArray(currentList) || currentList.length === 0) {
        const list = mapBasicInfo(newList);

        if (!isGroupList) {
            return list;
        }
        return groupList(list);
    }
    if (!Array.isArray(newList) || newList.length === 0) {
        return currentList;
    }

    if (!isGroupList) {
        return [...currentList, ...newList];
    }

    const currentLastDate = currentList[currentList.length - 1].date;
    const mapedNewList = mapBasicInfo(newList);
    const groupedNewList = groupList(mapedNewList);
    const newFirstDate = groupedNewList[0].date;

    if (currentLastDate !== newFirstDate) {
        return [...currentList, ...groupedNewList];
    }

    const clonedCurrentList = cloneDeep(currentList);

    clonedCurrentList[clonedCurrentList.length - 1].list = [
        ...clonedCurrentList[clonedCurrentList.length - 1].list,
        ...groupedNewList[0].list,
    ];

    return [
        ...clonedCurrentList,
        ...groupedNewList.filter((_, index) => index !== 0),
    ];
};

export default mapLixiList;
