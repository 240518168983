import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { ALIAS_TYPE, BankDetails, FetchStatus } from "@config/constants";
import IntroNickname from "@containers/Nickname/dialog/IntroNickname";
import AlertController from "@helpers/controllers/AlertController";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { CircularProgress, Icon } from "@material-ui/core";
import userAction from "@redux/actions/user";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const NicknameLine = ({
    featureState,
    bankInfo,
    classes,
    aliasFeeFetching,
    getAliasFree,
    aliasFeeStatus,
}) => {
    const [nicknameAlias, setNicknameAlias] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const history = useHistory();
    const { t } = useTranslation();

    const handleDirect = useCallback(() => {
        history.push(BankDetails.Nickname);
    }, [history]);

    const enableAliasNickName = useMemo(() => {
        return featureState?.enableAliasNickName;
    }, [featureState?.enableAliasNickName]);

    const isActiveNickname = useMemo(() => {
        if (!isEmpty(bankInfo?.aliasList)) {
            const nickname = bankInfo?.aliasList.find(
                (aliasItem) => aliasItem.aliasType === ALIAS_TYPE.ALIAS_NICKNAME
            );

            if (nickname) {
                setNicknameAlias(nickname);
                return nickname.status;
            }
            return false;
        }
    }, [bankInfo]);

    const handleCreateNickname = () => {
        getAliasFree();
        setOpen(true);
    };

    useEffect(() => {
        if (aliasFeeStatus === FetchStatus.Success && isOpen) {
            GlobalDialogController.show({
                component: () => <IntroNickname onDirect={handleDirect} />,
            });
            setOpen(false);
        }
    }, [aliasFeeStatus, handleDirect, isOpen]);

    return (
        <>
            {enableAliasNickName && isEmpty(nicknameAlias) && (
                <>
                    <LspTypography
                        className={classes.boxInformationTitle}
                        variant="body2"
                    >
                        {t("IBFT:IBFT_bankdetails_fast_nickname")}
                    </LspTypography>
                    <div className={classes.boxInformationNickname}>
                        <LspTypography
                            variant={
                                isActiveNickname ? "heading2" : "subheading2"
                            }
                            component="span"
                            color={isActiveNickname ? "black" : "grey"}
                        >
                            {isActiveNickname
                                ? nicknameAlias?.alias
                                : t(
                                      "IBFT:IBFT_bankdetails_fast_nickname_unavailable"
                                  )}
                            {/* --translate */}
                        </LspTypography>
                        <LspButton
                            type="button"
                            variant="secondary"
                            size="small"
                            onClick={handleCreateNickname}
                            disabled={aliasFeeFetching}
                            buttonProps={{
                                startIcon: aliasFeeFetching ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <Icon className="font-icon icon-plus" />
                                ),
                            }}
                        >
                            {t(
                                "IBFT:IBFT_bankdetails_fast_btn_create_nickname"
                            )}
                        </LspButton>
                    </div>
                </>
            )}
            {enableAliasNickName &&
                !isEmpty(nicknameAlias) &&
                isActiveNickname && (
                    <>
                        <LspTypography
                            className={classes.boxInformationTitle}
                            variant="body2"
                        >
                            {t("IBFT:IBFT_bankdetails_fast_nickname")}
                        </LspTypography>
                        <div className={classes.boxInformationNickname}>
                            <LspTypography
                                variant={
                                    isActiveNickname
                                        ? "heading2"
                                        : "subheading2"
                                }
                                component="span"
                                color={isActiveNickname ? "black" : "grey"}
                            >
                                {isActiveNickname
                                    ? nicknameAlias?.alias
                                    : t(
                                          "IBFT:IBFT_bankdetails_fast_nickname_unavailable"
                                      )}
                            </LspTypography>
                            <LspButton
                                size="small"
                                variant="secondary"
                                type="button"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        nicknameAlias?.alias
                                    );
                                    AlertController.show(
                                        t(
                                            "member:timostarclub_msg_copy_successful"
                                        )
                                    );
                                }}
                            >
                                {t("IBFT:IBFT_bankdetails_fast_btn_copy")}
                            </LspButton>
                        </div>
                    </>
                )}
        </>
    );
};

const stateProps = (state) => ({
    featureState: state.user.featureState.data,
    user: state.user.info || {},
    aliasFeeFetching: state.user.aliasFree?.fetching,
    aliasFeeStatus: state.user.aliasFree?.status,
});

const mapDispatch = (dispatch) => ({
    getAliasFree: (payload) =>
        dispatch(userAction.getNicknameFeeRequest(payload)),
});

export default connect(stateProps, mapDispatch)(NicknameLine);
