/* eslint-disable no-console */
import ReactDOM from "react-dom";
import "./assets/fonts/timo-icon/style.css";
import "./assets/fonts/open-sans/index.css";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactiveViewport from "@components/ReactiveViewport";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";

import createStore from "./config/redux";

window.environment = process.env.REACT_APP_ENVIRONMENT.toString();
window.isProduction = process.env.REACT_APP_ENVIRONMENT.toString() === "prod";
window.isLocalhost = window.location.hostname === "localhost";

const store = createStore();
window.persistor = persistStore(store);

if (window.isProduction) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={window.persistor}>
            <ReactiveViewport />
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
