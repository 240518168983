import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    contentWrapperRoot: {
        flexGrow: 1,
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&.noPaddingX": {
            paddingLeft: 0,
            paddingRight: 0,
        },
        "&.noPaddingY": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(2) + theme.spacing(8) + 1,
            paddingRight: theme.spacing(2) + theme.spacing(8) + 1,
            "&.noPaddingLeft": {
                paddingLeft: 0,
            },
            "&.noPaddingX": {
                // paddingLeft: theme.spacing(1) + theme.spacing(8) + 1,
                // paddingRight: theme.spacing(1) + theme.spacing(8) + 1,
                paddingLeft: 0,
                paddingRight: theme.spacing(8) + 1,
            },
            "&.noPaddingY": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
    withSideMenu: {
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        "&.noPaddingX": {
            paddingLeft: 0,
            paddingRight: 0,
        },
        "&.noPaddingY": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2) + theme.drawerWidth,
            "&.noPaddingX": {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1) + theme.drawerWidth,
            },
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: theme.spacing(2) + theme.drawerWidth,
            paddingRight: theme.spacing(2) + theme.drawerWidth,
            "&.noPaddingX": {
                paddingLeft: theme.spacing(1) + theme.drawerWidth,
                paddingRight: theme.spacing(1) + theme.drawerWidth,
            },
        },
    },
}));

const ContentWrapper = ({
    children,
    opened,
    className,
    noPaddingX,
    noPaddingY,
    style,
}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.contentWrapperRoot, {
                [classes.withSideMenu]: opened,
                noPaddingX: !!noPaddingX,
                noPaddingY: !!noPaddingY,
                [className]: !!className,
            })}
            style={style}
        >
            {children}
        </div>
    );
};

const mapState = (state) => ({
    opened: state.layout.sideMenuOpened,
});

export default connect(mapState)(ContentWrapper);
