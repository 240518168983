import { makeStyles } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useEffect, useState, useMemo, memo, useCallback } from "react";
import PropTypes from "prop-types";

import vietQR from "@assets/images/viet-qr.svg";
import { Skeleton } from "@material-ui/lab";
import convertVietnameseCharacterToUnicode from "@helpers/convertVietnameseCharacterToUnicode";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

/* eslint-disable no-bitwise */
const convertentry = (input, order) => {
    let len = 0;
    let actchar;
    let count = 0;
    const polynom = [0, 0, 0, 0, 0, 0, 0, 0];
    const brk = [-1, 0, 0, 0, 0, 0, 0, 0];

    // convert crc value into byte sequence
    len = input.length;
    for (let i = 0; i < len; i++) {
        actchar = parseInt(input.charAt(i), 16);
        if (Number.isNaN(actchar) === true) return brk;
        actchar &= 15;

        for (let j = 0; j < 7; j++)
            polynom[j] = ((polynom[j] << 4) | (polynom[j + 1] >> 4)) & 255;
        polynom[7] = ((polynom[7] << 4) | actchar) & 255;
    }

    // compute and check crc order
    count = 64;
    let b = 0;
    for (let i = 0; i < 8; i++) {
        for (let j = 0x80; j; j >>= 1) {
            b = j;
            if (polynom[i] & j) break;
            count--;
        }
        if (polynom[i] & b) break;
    }

    if (count > order) return brk;

    return polynom;
};

const calculationCRC = (crcString) => {
    let i;
    let j;
    let k;
    let bit;
    let datalen = "";
    let len;
    let actchar;
    let flag;
    let counter;
    let c;
    let crc = new Array(8 + 1);
    const mask = new Array(8);
    const hexnum = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
    ];

    let data = "";
    const order = 16;
    let polynom = new Array(8);
    let init = new Array(8);
    let xor = new Array(8);
    let result = "";

    counter = order;
    for (i = 7; i >= 0; i--) {
        if (counter >= 8) mask[i] = 255;
        else mask[i] = (1 << counter) - 1;
        counter -= 8;
        if (counter < 0) counter = 0;
    }
    init = convertentry("FFFF", order);
    polynom = convertentry("1021", order);
    xor = convertentry(0, order);
    crc = init;
    data = crcString;
    datalen = data.length;
    len = 0; // number of data bytes

    crc[8] = 0;

    // main loop, algorithm is fast bit by bit type
    for (i = 0; i < datalen; i++) {
        c = data.charCodeAt(i);
        for (j = 0; j < 8; j++) {
            bit = 0;
            if (crc[7 - ((order - 1) >> 3)] & (1 << ((order - 1) & 7))) bit = 1;
            if (c & 0x80) bit ^= 1;
            c <<= 1;
            for (
                k = 0;
                k < 8;
                k++ // rotate all (max.8) crc bytes
            ) {
                crc[k] = ((crc[k] << 1) | (crc[k + 1] >> 7)) & mask[k];
                if (bit) crc[k] ^= polynom[k];
            }
        }
        len++;
    }

    // perform xor value
    for (i = 0; i < 8; i++) crc[i] ^= xor[i];

    flag = 0;
    for (i = 0; i < 8; i++) {
        actchar = crc[i] >> 4;
        if (flag || actchar) {
            result += hexnum[actchar];
            flag = 1;
        }

        actchar = crc[i] & 15;
        if (flag || actchar || i === 7) {
            result += hexnum[actchar];
            flag = 1;
        }
    }

    return result;
};

const useStyles = makeStyles((theme) => ({
    bodyQrCode: {
        position: "relative",
        marginBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
    },
    bodyQRIcon: {
        position: "absolute",
        width: "100%",
        height: 10,
    },
}));

const VIET_QR_CODE = Object.freeze({
    PAYLOAD_FORMAT_INDICATOR: "000201",
    POINT_OF_INITIATION_METHOD: "010212",
    STATIC_POINT_OF_INITIATION_METHOD: "010211",
    BANK_VIET_BANK_CODE: "970454",
    COUNTRY_CODE: "5802VN",
    TRANSACTION_CURRENCY_CODE: "5303704",
    ADDITIONAL_DATA_FIELD_TEMPLATE: "62",
    AID: "0010A00000072701",
    NAPAS_FAST_TRANSFER: "0208QRIBFTTA",
    CRC: "6304",
});

const VietQRCode = ({
    accountNumber,
    amount,
    description,
    activeAmount,
    size,
    iconSize,
    level,
    loading,
    featureState,
    enableIBFT,
}) => {
    const classes = useStyles();

    const [bankCode, setBankCode] = useState(null);
    const { t } = useTranslation();

    const enableIBFTFNewUser = useMemo(() => {
        return enableIBFT;
    }, [enableIBFT]);

    const generationVietQRCode = useCallback(
        (accNumber) => {
            const payloadFormatIndicator =
                VIET_QR_CODE.PAYLOAD_FORMAT_INDICATOR; // Payload Format Indicator, The Payload Format Indicator shall contain a value of "01". All other values are Reserved for Future Use (RFU)
            const pointOfInitiationMethod =
                VIET_QR_CODE.STATIC_POINT_OF_INITIATION_METHOD; // Point of Initiation Method, 11: Static QR Code, 12: dynamic QR Code. All other values are RFU.
            const account = accNumber || 0;
            const accountNumberLength =
                account.length < 10 ? `0${account.length}` : account.length;
            const napasBankCode =
                featureState?.enableIBFTGW || enableIBFTFNewUser
                    ? t("IBFT:IBFT_bankcode")
                    : VIET_QR_CODE.BANK_VIET_BANK_CODE; // beneficiary bank code https://www.indovinabank.com.vn/sites/default/files/0%20MARKETING_p1/EBANKING%20forms/Các%20ngân%20hàng%20trong%20liên%20minh%20NAPAS_vn.pdf
            const bankInformation = `0006${napasBankCode}01${accountNumberLength}${account}`;
            const beneficiaryInformation = `${VIET_QR_CODE.AID}${bankInformation.length}${bankInformation}${VIET_QR_CODE.NAPAS_FAST_TRANSFER}`; // Beneficiary account information
            const consumerAccountInformation = `38${beneficiaryInformation.length}${beneficiaryInformation}`; // Merchant Account Information
            const transactionCurrency = VIET_QR_CODE.TRANSACTION_CURRENCY_CODE; // Transaction Currency Code https://en.wikipedia.org/wiki/ISO_4217
            const countryCode = `${VIET_QR_CODE.COUNTRY_CODE}${VIET_QR_CODE.CRC}`; // Country Code https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2

            const code =
                payloadFormatIndicator +
                pointOfInitiationMethod +
                consumerAccountInformation +
                transactionCurrency +
                countryCode;

            const crcCode = calculationCRC(code); // Cyclic redundancy check (CRC): Checksum calculated over all the data objects included in the QR Code. [ISO/IEC 13239] https://www.lammertbies.nl/comm/info/crc-calculation

            return `${code}${crcCode.length < 4 ? `0${crcCode}` : crcCode}`;
        },
        [featureState?.enableIBFTGW, t, enableIBFTFNewUser]
    );

    const generationQRCodeWithAmount = useCallback(
        (accNumber = "", amountWithAmount = 0, descriptionWithAmount = "") => {
            const payloadFormatIndicator =
                VIET_QR_CODE.PAYLOAD_FORMAT_INDICATOR; // Payload Format Indicator, The Payload Format Indicator shall contain a value of "01". All other values are Reserved for Future Use (RFU)
            const pointOfInitiationMethod =
                VIET_QR_CODE.POINT_OF_INITIATION_METHOD; // Point of Initiation Method, 11: Static QR Code, 12: dynamic QR Code. All other values are RFU.
            const account = accNumber;
            const accountNumberLength =
                account.length < 10 ? `0${account.length}` : account.length;
            const napasBankCode =
                featureState?.enableIBFTGW || enableIBFTFNewUser
                    ? t("IBFT:IBFT_bankcode")
                    : VIET_QR_CODE.BANK_VIET_BANK_CODE; // beneficiary bank code https://www.indovinabank.com.vn/sites/default/files/0%20MARKETING_p1/EBANKING%20forms/Các%20ngân%20hàng%20trong%20liên%20minh%20NAPAS_vn.pdf
            const bankInformation = `0006${napasBankCode}01${accountNumberLength}${account}`;
            const beneficiaryInformation = `${VIET_QR_CODE.AID}${bankInformation.length}${bankInformation}${VIET_QR_CODE.NAPAS_FAST_TRANSFER}`; // Beneficiary account information
            const consumerAccountInformation = `38${beneficiaryInformation.length}${beneficiaryInformation}`; // Merchant Account Information
            const transactionCurrency = VIET_QR_CODE.TRANSACTION_CURRENCY_CODE; // Transaction Currency Code https://en.wikipedia.org/wiki/ISO_4217
            const transactionAmountKey = "54"; // Transaction Amount
            const amountNumber = amountWithAmount || 0;
            const transactionAmount = parseInt(amountNumber).toString(); // convert account number to string
            const transactionAmountLength =
                transactionAmount.length < 10
                    ? `0${transactionAmount.length}`
                    : transactionAmount.length;
            const transactionDescriptionKey =
                VIET_QR_CODE.ADDITIONAL_DATA_FIELD_TEMPLATE; // Additional Data Field Template
            const transactionDescription = descriptionWithAmount || "";
            const convertToUnicode = convertVietnameseCharacterToUnicode(
                transactionDescription
            ); // replace all vietnamese character to unicode
            const replaceAllSpecialCharacterDescription = convertToUnicode.replace(
                /[^a-zA-Z0-9 ]/g,
                ""
            ); // remove all special character
            const transactionDescriptionLength =
                replaceAllSpecialCharacterDescription.length + 4 < 10
                    ? `0${replaceAllSpecialCharacterDescription.length + 4}`
                    : replaceAllSpecialCharacterDescription.length + 4;
            const countryCode = VIET_QR_CODE.COUNTRY_CODE; // Country Code https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
            const endCode = VIET_QR_CODE.CRC; // CRC (Cyclic Redundancy Check)

            let code = `${
                payloadFormatIndicator +
                pointOfInitiationMethod +
                consumerAccountInformation +
                transactionCurrency +
                transactionAmountKey +
                transactionAmountLength +
                transactionAmount + // for amount
                countryCode
            }`;

            if (replaceAllSpecialCharacterDescription.length !== 0) {
                code = `${
                    code +
                    transactionDescriptionKey +
                    transactionDescriptionLength
                }08${
                    replaceAllSpecialCharacterDescription.length < 10
                        ? `0${replaceAllSpecialCharacterDescription.length}`
                        : replaceAllSpecialCharacterDescription.length
                }${
                    replaceAllSpecialCharacterDescription // for description
                }${endCode}`;
            } else {
                code = `${code + endCode}`;
            }

            const crcCode = calculationCRC(code); // Cyclic redundancy check (CRC): Checksum calculated over all the data objects included in the QR Code. [ISO/IEC 13239] https://www.lammertbies.nl/comm/info/crc-calculation
            return `${code}${crcCode.length < 4 ? `0${crcCode}` : crcCode}`;
        },
        [featureState?.enableIBFTGW, t, enableIBFTFNewUser]
    );

    useEffect(() => {
        if (accountNumber && activeAmount) {
            const vietQRCode = generationQRCodeWithAmount(
                accountNumber,
                amount,
                description
            );
            setBankCode(vietQRCode);
        } else if (accountNumber) {
            const vietQRCode = generationVietQRCode(accountNumber);
            setBankCode(vietQRCode);
        }
    }, [
        enableIBFTFNewUser,
        accountNumber,
        activeAmount,
        amount,
        description,
        generationQRCodeWithAmount,
        generationVietQRCode,
    ]);

    const fetchingLayer = useMemo(
        () => (
            <div className={classes.bodyQrCode}>
                <Skeleton width={size} height={size} variant="rect" />
            </div>
        ),
        [classes.bodyQrCode, size]
    );

    return (
        <>
            {loading && fetchingLayer}
            {bankCode && !loading && (
                <div className={classes.bodyQrCode}>
                    <div
                        className={classes.bodyQRIcon}
                        style={{ width: size + 20 }}
                    />
                    <QRCode
                        renderAs="canvas"
                        value={bankCode}
                        size={size}
                        level={level}
                        imageSettings={{
                            src: vietQR,
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                </div>
            )}
        </>
    );
};

VietQRCode.propTypes = {
    size: PropTypes.number,
    iconSize: PropTypes.number,
    activeAmount: PropTypes.bool,
    level: PropTypes.oneOf(["L", "M", "Q", "H"]),
    loading: PropTypes.bool,
    accountNumber: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.number,
    enableIBFT: PropTypes.bool,
};

VietQRCode.defaultProps = {
    size: 200,
    activeAmount: false,
    iconSize: 65,
    level: "M",
    loading: false,
    accountNumber: "",
    description: "",
    amount: 0,
    enableIBFT: false,
};

const stateProps = (state) => ({
    featureState: state.user.featureState.data,
});

const dispatchProps = () => ({});

export default memo(connect(stateProps, dispatchProps)(VietQRCode));
