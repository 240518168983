import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Banner from "@components/Banner";
import ContentWrapper from "@components/ContentWrapper";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import "react-circular-progressbar/dist/styles.css";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import usePathname from "@helpers/usePathname";
import GoalSaveGallery from "./GoalSaveGallery";
import GoalSaveTransaction from "./GoalSaveTransaction";

const parentName = "goal-save";

const GoalSave = () => {
    const { t } = useTranslation();
    const { generateChildPathname } = usePathname();
    const location = useLocation();
    const { goalSave } = useAccounts();
    const { formatNumber } = useNumber();
    const contentRoutes = useMemo(
        () => [
            {
                path: generateChildPathname(parentName, "goalsave-gallery"),
                component: GoalSaveGallery,
                default: true,
            },
            {
                path: generateChildPathname(parentName, "goalsave-transaction"),
                component: GoalSaveTransaction,
            },
        ],
        [generateChildPathname]
    );
    const defaultRoutes = useMemo(() => contentRoutes.find((r) => r.default), [
        contentRoutes,
    ]);
    const navbarItems = useMemo(
        () => [
            {
                title: "gm_lb_goal_transaction",
                icon: "transactionlist",
                path: generateChildPathname(parentName, "goalsave-transaction"),
                selected: location.pathname.includes(
                    generateChildPathname(parentName, "goalsave-transaction")
                ),
            },
            {
                title: "gm_lb_goal_management",
                icon: "goalgallery",

                path: generateChildPathname(parentName, "goalsave-gallery"),
                selected: location.pathname.includes(
                    generateChildPathname(parentName, "goalsave-gallery")
                ),
            },
        ],
        [location, generateChildPathname]
    );
    const bannerSubtitle = useMemo(
        () => (
            <>
                {`${t("goal_save_account_des")} : `}
                <b>{formatNumber(goalSave?.workingBalance)}</b>
            </>
        ),
        [t, formatNumber, goalSave]
    );

    return (
        <>
            <Banner
                title={t("home_lb_goal_account").toUpperCase()}
                subtitle={bannerSubtitle}
                navbarItems={navbarItems}
            />

            <ContentWrapper>
                <Switch>
                    {contentRoutes.map((r) => {
                        return (
                            <Route
                                key={r.path}
                                path={r.path}
                                component={r.component}
                            />
                        );
                    })}
                    {defaultRoutes && (
                        <Route
                            component={() => (
                                <Redirect to={defaultRoutes.path} />
                            )}
                        />
                    )}
                </Switch>
            </ContentWrapper>
        </>
    );
};

export default GoalSave;
