export const LIXI_ACTIONS = {
    RECEIVE: "receive",
    SEND: "send",
};

export const PAY_ANYONE_TYPES = {
    LIXI: "LIXI",
};

export const PAY_ANYONE_STATUSES = {
    READ: "read",
    UNREAD: "unread",
};
export const LIXI_STEPS = {
    PAYEE_LIST: "PAYEE_LIST",
    THEME: "THEME",
    MESSAGE: "MESSAGE",
    REVIEW_PAYEE: "REVIEW_PAYEE",
    REVIEW: "REVIEW",
    REVIEW_RECEIPT: "REVIEW_RECEIPT",
    RECEIPT: "RECEIPT",
    NEW_PAYEE: "NEW_PAYEE",
};

export const LIXI_NAV = {
    RECEIVE_LIST: "/lixi/receive",
    SEND_LIST: "/lixi/send",
    DETAIL: "/lixi/detail",
    SEND_LIXI: "/lixi/send-lixi",
};
