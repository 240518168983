/* eslint-disable react/no-array-index-key */
import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspTypography from "@components/LspTypography";
import { RESPONSE_CODE } from "@config/constants";
import { MANAGE_INSTANT_STEPS } from "@containers/Wealth/wealthConstants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import useNumber from "@helpers/useNumber";
import { Icon, makeStyles, Paper } from "@material-ui/core";
import wealthService from "@services/wealth";
import { useEffect, useRef, useState, useCallback, memo } from "react";

import { useTranslation } from "react-i18next";
import CustomizedAccordions from "../Accordion";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.neutral.white,

        fontSize: theme.typography.pxToRem(35),
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
    },
    iconBack: {
        cursor: "pointer",
    },

    list: {
        listStyleType: "none",
        padding: "0",
    },
    item: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    boxTitle: {
        padding: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        background: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            "& > span ": {
                marginRight: theme.spacing(2),
            },
        },
    },
    boxTitleAmount: {
        flex: 1,
        textAlign: "right",
    },

    boxBody: {
        padding: theme.spacing(3.75, 2.5, 3.75, 5.5),
        "& > div": {
            padding: theme.spacing(0, 0, 2.5, 3),
            position: "relative",
            "&::before": {
                position: "absolute",
                content: "''",
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: theme.palette.blue.main,
                left: 0,
                top: 7,
            },
        },
    },
}));

const LeftPage = ({
    stepForward,
    isShowBtnSubmit,
    leftContentTitle,
    leftDetailPackage,
    leftDetailPackage2,
    typeAction,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { formatNumber } = useNumber();
    const [loading, setLoading] = useState(false);
    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const callAPIAction = useCallback(async () => {
        setLoading(true);

        const { data } = await wealthService.actionWealthSubProducts(
            typeAction
        );
        if (unmounted.current) {
            return;
        }
        setLoading(false);

        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                stepForward(MANAGE_INSTANT_STEPS.CONGRATULATION);
                break;
            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, [typeAction, stepForward]);

    return (
        <Content>
            <div>
                <div>
                    <ul className={classes.list}>
                        <Paper>
                            <li className={classes.item}>
                                <div className={classes.boxTitle}>
                                    <Icon
                                        className={`font-icon icon-managepolicy ${classes.icon}`}
                                    />
                                    <div>
                                        <LspTypography
                                            variant="heading1"
                                            color="white"
                                        >
                                            {leftContentTitle.txtHeader}
                                        </LspTypography>
                                    </div>
                                    <div className={classes.boxTitleAmount}>
                                        <LspTypography
                                            variant="heading2"
                                            color="white"
                                        >
                                            {leftContentTitle.txtYouPayHeader}
                                        </LspTypography>
                                        <LspTypography
                                            variant="heading2"
                                            color="white"
                                        >
                                            {formatNumber(
                                                leftContentTitle.valYouPayHeader
                                            )}
                                        </LspTypography>
                                    </div>
                                </div>
                                <div className={classes.boxBody}>
                                    <LspTypography variant="heading4">
                                        {t("sunlife_lb_product_name")}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body1"
                                        color="primary"
                                    >
                                        {leftContentTitle.productName}
                                    </LspTypography>
                                    <LspTypography variant="heading4">
                                        {t("sunlife_lb_coverage_duration")}
                                    </LspTypography>
                                    <LspTypography
                                        variant="body1"
                                        color="primary"
                                    >
                                        {leftContentTitle.coverageDuration}
                                    </LspTypography>
                                </div>
                            </li>

                            <CustomizedAccordions
                                leftDetailPackage={leftDetailPackage}
                                leftContentTitle={leftContentTitle}
                                leftDetailPackage2={leftDetailPackage2}
                            />
                        </Paper>
                    </ul>
                    {isShowBtnSubmit && (
                        <LspButton
                            type="submit"
                            fullWidth
                            onClick={callAPIAction}
                            progressing={loading}
                        >
                            {t("lb_next")}
                        </LspButton>
                    )}
                </div>
            </div>
        </Content>
    );
};

export default memo(LeftPage);
