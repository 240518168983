import LspButton from "@components/LspButton";
import useNumber from "@helpers/useNumber";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useMemo, memo } from "react";

import { useTranslation } from "react-i18next";

import { isUndefined } from "lodash";
import { REISSUE_CARD_VERSION } from "@containers/CardManagement/constant";
import LspTypography from "@components/LspTypography";
import lightIcon from "../../assets/images/light.png";

const useStyles = makeStyles((theme) => ({
    deliveryCard: {
        width: "100%",
        borderRadius: 8,
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    deliveryCardHeader: {
        textAlign: "center",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(23),
        lineHeight: "34px",
        letterSpacing: "0.3px",
        color: theme.palette.white.contrastText,
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    deliveryCardBody: {
        marginBottom: theme.spacing(4),
    },
    deliveryBox: {
        boxShadow:
            "0px 3px 4px rgba(0, 0, 0, 0.04), 0px 3px 1px rgba(0, 0, 0, 0.02), 0px 1px 8px rgba(0, 0, 0, 0.1)",

        borderRadius: 8,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
    },
    deliveryBoxHeader: {
        paddingBottom: theme.spacing(2),
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        textAlign: "center",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    deliveryBoxBody: {
        paddingTop: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
    },
    deliveryBoxBodyLeft: {
        marginRight: theme.spacing(3),
        width: 130,
    },
    deliveryBoxBodyLeftImg: {
        width: "100%",
    },
    deliveryBoxBodyRight: {
        flex: 1,
    },
    deliveryBoxBodyRightItem: {
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        "&:last-child": {
            marginBottom: 0,
        },
    },
    deliveryBoxBodyRightHeader: {
        width: 120,
    },
    deliveryBoxBodyRightContentText: {
        color: theme.palette.white.contrastText,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: "18px",
        letterSpacing: "0.3px",
        textAlign: "left",
        flex: 1,
    },
    deliveryBoxBodyRightHeaderText: {
        color: theme.palette.magicalGrey[400],
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: "18px",
        letterSpacing: "0.3px",
        textAlign: "left",
    },
    deliveryTips: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    deliveryTipsIcon: {
        marginRight: theme.spacing(1),
    },
    deliveryTipsText: {
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: "18px",
        letterSpacing: "0.3px",
        color: theme.palette.magicalGrey[400],
    },
}));

const DeliveryReview = ({
    reviews,
    onReviewCancel,
    onReviewSubmit,
    loading,
    deliveryVersion,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { formatNumber } = useNumber();

    const deliveryCostText = useMemo(() => {
        if (
            !reviews?.reIssueFree &&
            !reviews?.deliveryFee &&
            !reviews?.annualFee
        ) {
            return t("master:reissue_virtual_free_charge");
        }

        const deliveryCost =
            (reviews?.reIssueFree || 0) +
            (reviews?.deliveryFee || 0) +
            (reviews?.annualFee || 0) +
            (reviews?.issuanceFee || 0);

        return t("debitCard:reissuecard_review_app_service_charge_amt").replace(
            "%@",
            `${formatNumber(deliveryCost)}`
        );
    }, [t, reviews, formatNumber]);

    const defaultImgURL = useMemo(() => {
        let imagePath = "";
        switch (deliveryVersion) {
            case REISSUE_CARD_VERSION.VIRTUAL:
                imagePath = t("sysParamContent:DefaultVisaDebitVirtualUrl");
                break;
            case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
                imagePath = t("sysParamContent:DefaultVisaDebitVirtualUrl");
                break;
            case REISSUE_CARD_VERSION.CARD_V2:
            default:
                imagePath = t("sysParamContent:DefaultCardUrl");
                break;
        }

        return `${process.env.REACT_APP_API_URL}${imagePath}`;
    }, [t, deliveryVersion]);

    const hintContent = useMemo(() => {
        switch (deliveryVersion) {
            case REISSUE_CARD_VERSION.VIRTUAL:
                return t("master:request_virtual_card_review_hint");
            case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
            case REISSUE_CARD_VERSION.CARD_V2:
            default:
                return t("debitCard:reissuecard_review_app_hint");
        }
    }, [deliveryVersion, t]);

    const confirmButtonText = useMemo(() => {
        switch (deliveryVersion) {
            case REISSUE_CARD_VERSION.VIRTUAL:
                return t("master:request_virtual_card_review_CTA_cfm");
            case REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD:
            case REISSUE_CARD_VERSION.CARD_V2:
            default:
                return t("debitCard:reissuecard_review_app_CTA_cfm");
        }
    }, [deliveryVersion, t]);

    const header = useMemo(() => {
        if (deliveryVersion !== REISSUE_CARD_VERSION.VIRTUAL_TO_PHYSICAL_CARD) {
            return (
                <div className={classes.deliveryBoxHeader}>
                    <LspTypography variant="body2" color="grey">
                        {t("debitCard:reissuecard_review_app_service_charge")}
                    </LspTypography>
                    <LspTypography variant="heading1">
                        {deliveryCostText}
                    </LspTypography>
                </div>
            );
        }

        return (
            <div className={classes.deliveryBoxHeader}>
                <LspTypography variant="body2" color="grey">
                    {t("debitCard:phys_visa_review_cardname")}
                </LspTypography>
                <LspTypography variant="heading1">
                    {deliveryCostText}
                </LspTypography>

                {reviews?.virtualAccountName && (
                    <>
                        <LspTypography variant="body2" color="grey">
                            {t("debitCard:phys_visa_review_source_of_fund")}
                        </LspTypography>
                        <Box marginTop={0.75}>
                            <LspTypography variant="heading3">
                                {reviews?.virtualAccountName}
                            </LspTypography>
                        </Box>
                    </>
                )}
            </div>
        );
    }, [deliveryVersion, classes, deliveryCostText, t, reviews]);

    return (
        <>
            <div className={classes.deliveryCard}>
                <div className={classes.deliveryCardHeader}>
                    {t("debitCard:reissuecard_review_app_header")}
                </div>
                <div className={classes.deliveryCardBody}>
                    <div className={classes.deliveryBox}>
                        {header}

                        <div className={classes.deliveryBoxBody}>
                            <div className={classes.deliveryBoxBodyLeft}>
                                <img
                                    className={classes.deliveryBoxBodyLeftImg}
                                    src={reviews?.cardImageUrl || defaultImgURL}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = defaultImgURL;
                                    }}
                                    alt="Timo card"
                                />
                            </div>
                            <div className={classes.deliveryBoxBodyRight}>
                                {/* ========================================== */}
                                <div
                                    className={classes.deliveryBoxBodyRightItem}
                                >
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightHeader
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeaderText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_fullname"
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightContentText
                                        }
                                    >
                                        {reviews?.fullName}
                                    </div>
                                </div>
                                {/* ========================================== */}
                                <div
                                    className={classes.deliveryBoxBodyRightItem}
                                >
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightHeader
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeaderText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_phone"
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightContentText
                                        }
                                    >
                                        {reviews?.phoneNumber}
                                    </div>
                                </div>
                                {/* ========================================== */}
                                <div
                                    className={classes.deliveryBoxBodyRightItem}
                                >
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightHeader
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeaderText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_id"
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightContentText
                                        }
                                    >
                                        {reviews?.legalId}
                                    </div>
                                </div>

                                {/* ========================================== */}
                                {!isUndefined(reviews?.reIssueFree) && (
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightItem
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeader
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightHeaderText
                                                }
                                            >
                                                {t(
                                                    "debitCard:reissuecard_review_app_reissue_fee"
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightContentText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_service_charge_amt"
                                            ).replace(
                                                "%@",
                                                `${formatNumber(
                                                    reviews?.reIssueFree
                                                )}`
                                            )}
                                        </div>
                                    </div>
                                )}
                                {/* ========================================== */}
                                {!isUndefined(reviews?.issuanceFee) && (
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightItem
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeader
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightHeaderText
                                                }
                                            >
                                                {t(
                                                    "debitCard:phys_visa_review_issuance_fee"
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightContentText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_service_charge_amt"
                                            ).replace(
                                                "%@",
                                                `${formatNumber(
                                                    reviews?.issuanceFee
                                                )}`
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* ========================================== */}
                                {!isUndefined(reviews?.annualFee) && (
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightItem
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeader
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightHeaderText
                                                }
                                            >
                                                {t(
                                                    "debitCard:phys_visa_review_annual_fee"
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightContentText
                                            }
                                        >
                                            {t(
                                                "debitCard:reissuecard_review_app_service_charge_amt"
                                            ).replace(
                                                "%@",
                                                `${formatNumber(
                                                    reviews?.annualFee
                                                )}`
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* ========================================== */}

                                {!isUndefined(reviews?.deliveryFee) &&
                                    !reviews?.isHangout && (
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightItem
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightHeader
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.deliveryBoxBodyRightHeaderText
                                                    }
                                                >
                                                    {t(
                                                        "debitCard:reissuecard_review_app_delivery_fee"
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightContentText
                                                }
                                            >
                                                {t(
                                                    "debitCard:reissuecard_review_app_service_charge_amt"
                                                ).replace(
                                                    "%@",
                                                    `${formatNumber(
                                                        reviews?.deliveryFee
                                                    )}`
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {/* ========================================== */}
                                {reviews?.receiveDetail && (
                                    <div
                                        className={
                                            classes.deliveryBoxBodyRightItem
                                        }
                                    >
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightHeader
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.deliveryBoxBodyRightHeaderText
                                                }
                                            >
                                                {reviews?.isHangout
                                                    ? t(
                                                          "debitCard:reissuecard_review_app_pickup_HO"
                                                      )
                                                    : t(
                                                          "debitCard:reissuecard_review_app_delivery_add"
                                                      )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.deliveryBoxBodyRightContentText
                                            }
                                        >
                                            {reviews?.receiveDetail}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.deliveryTips}>
                        <div className={classes.deliveryTipsIcon}>
                            <img src={lightIcon} alt="Tip Icon" />
                        </div>
                        <div className={classes.deliveryTipsText}>
                            {hintContent}
                        </div>
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LspButton
                            fullWidth
                            variant="secondary"
                            disabled={loading}
                            onClick={onReviewCancel}
                        >
                            {t("lb_back")}
                        </LspButton>
                    </Grid>
                    <Grid item xs={6}>
                        <LspButton
                            progressing={loading}
                            fullWidth
                            variant="primary"
                            onClick={onReviewSubmit}
                        >
                            {confirmButtonText}
                        </LspButton>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default memo(DeliveryReview);
