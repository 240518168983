import ContentWrapper from "@components/ContentWrapper";
import LspTypography from "@components/LspTypography";
import { Icon, makeStyles } from "@material-ui/core";
import { memo, useEffect, useRef, useState } from "react";
import LspAvatar from "@components/LspAvatar";
import useAccounts from "@helpers/useAccounts";
import useNumber from "@helpers/useNumber";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import userService from "@services/user";
import { RESPONSE_CODE } from "@config/constants";
import AvatarUploader from "@components/AvatarUploader";

const useStyles = makeStyles((theme) => {
    const isDark = theme.palette.type === "dark";

    return {
        banner: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
            zIndex: theme.zIndex.appBar - 1,
            height: theme.spacing(15),
            borderBottom: `1px solid ${
                isDark
                    ? theme.palette.magicalGrey[500]
                    : theme.palette.magicalGrey[100]
            }`,
        },
        bannerTitle: {
            fontSize: theme.typography.pxToRem(24),
            fontWeight: "600",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
        },
        bannerSubtitle: {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.main,
        },
        avatarWrapper: {
            cursor: "pointer",
            borderRadius: "50%",
            position: "relative",
            boxShadow: theme.shadows[3],
            padding: theme.spacing(0.25),
        },
        iconUpload: {
            fontSize: 16,
        },
        iconUploadWrapper: {
            position: "absolute",
            top: theme.spacing(0.25),
            right: theme.spacing(0.25),
            color: theme.palette.primary.main,
            background: theme.palette.neutral.white,
            padding: theme.spacing(0.25, 0.5),
            borderRadius: "50%",
        },
        fullName: {
            textTransform: "uppercase",
        },
        info: {
            marginLeft: theme.spacing(2),
        },
    };
});

const ProfileBanner = ({ avatar, fullName, getProfile }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { spendAccount } = useAccounts();
    const { formatNumber } = useNumber();
    const [fetchingAvatar, setFetchingAvatar] = useState(false);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    // ===== Begin Upload avatar =====
    const uploadAvatarRequest = async (file) => {
        GlobalDialogController.hide();
        setFetchingAvatar(true);

        const response = await userService.uploadAvatar({
            avatar: file?.preview,
        });

        if (unmounted.current) return;

        setFetchingAvatar(false);

        const { code } = response.data;

        if (code === RESPONSE_CODE.SUCCESS) {
            getProfile();
        } else {
            GlobalDialogController.showError({ errorCode: code });
        }
    };

    const openUploadAvatarDialog = () => {
        GlobalDialogController.show({
            component: () => (
                <AvatarUploader
                    onSubmit={uploadAvatarRequest}
                    onCancel={() => GlobalDialogController.hide()}
                />
            ),
            onClose: () => {
                GlobalDialogController.hide();
            },
        });
    };

    return (
        <ContentWrapper className={classes.banner}>
            <div
                className={classes.avatarWrapper}
                onClick={openUploadAvatarDialog}
            >
                {fetchingAvatar && (
                    <Skeleton variant="circle" width={80} height={80} />
                )}

                {!fetchingAvatar && (
                    <>
                        <LspAvatar avatar={avatar} variant="circle" size={80} />
                        <div className={classes.iconUploadWrapper}>
                            <Icon
                                className={`font-icon icon-avatarchange ${classes.iconUpload}`}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className={classes.info}>
                <LspTypography
                    variant="title1"
                    color="primary"
                    className={classes.fullName}
                >
                    {fullName}
                </LspTypography>
                <div>
                    <LspTypography
                        variant="body1"
                        color="primary"
                        component="span"
                    >
                        {t("available_to_spend")}
                    </LspTypography>
                    &nbsp;
                    <LspTypography
                        variant="heading2"
                        color="primary"
                        component="span"
                    >
                        {formatNumber(spendAccount?.availableAmount)}
                    </LspTypography>
                </div>
            </div>
        </ContentWrapper>
    );
};
export default memo(ProfileBanner);
