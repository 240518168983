import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    CircularProgress,
    InputAdornment,
    makeStyles,
    Paper,
    Step,
    StepLabel,
    Stepper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    BANK_INFO,
    CardNumberValidLengthValues,
    FetchStatus,
    INPUT_RULES,
    MoveMoneyType,
    RESPONSE_CODE,
    SpendAccountNavigationKey,
    TRANSFER_DESTINATION_TYPES,
} from "@config/constants";
import DoubleButtons from "@components/DoubleButtons";
import moveMoneyService from "@services/move-money";
import useNumber from "@helpers/useNumber";
import moveMoneyAction from "@redux/actions/move-money";
import LspTextField from "@components/LspTextField";
import LspReceipt from "@components/LspReceipt";
import useAccounts from "@helpers/useAccounts";
import useCapitalize from "@helpers/useCapitalize";
import useDescription from "@helpers/useDescription";
import LspSuggestAmount from "@components/LspSuggestAmount";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { Autocomplete } from "@material-ui/lab";
// import { provinceList } from "@i18n/resources/provinceList.json";
// import { branchList } from "@i18n/resources/branchList.json";
import { filter, uniqBy } from "lodash";
import escapeAccentVietnamese from "@helpers/escapeAccentVietnamese";
import LspTypography from "@components/LspTypography";
import { WarningIcon } from "@components/LspIcon";
import LspBankList from "@components/LspBankList";
import useTransactionValidation from "../useTransactionValidation";
import TransferConfirmation from "./TransferConfirmation";

const useStyles = makeStyles((theme) => ({
    warningMessage: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        alignItems: "center",
    },
}));

const CreditCardTransfer = ({
    transferToDebitCard,
    transferring,
    transferStatus,
    transferInfo,
    resetTransfer,
    errorCodeAmount,
    fullName,
    featureState,
    systemParams,
    transferToAccountNumber,
    addingPayee,
    onCancel,
    onSave,
    addPayeeLoading,
    provinceList,
    branchList,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const {
        formatNumber,
        parseNumber,
        formatCardNumber,
        thousandSeparator,
        decimalSeparator,
    } = useNumber();
    const {
        validateAmount,
        getErrorByResponseCode,
    } = useTransactionValidation();
    const { spendAccount } = useAccounts();
    const { capitalizeSentence } = useCapitalize();
    const { getStandardDescription } = useDescription();

    const defaultDescription = useMemo(() => {
        return t("mm_description_default").replace(
            "%@",
            capitalizeSentence(fullName)
        );
    }, [fullName, t, capitalizeSentence]);

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    useEffect(() => {
        return () => {
            resetTransfer();
        };
    }, [resetTransfer]);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (transferStatus === FetchStatus.Success) {
            setActiveStep(2);
        }
    }, [transferStatus]);

    const steps = useMemo(
        () => [
            {
                id: 0,
                label: t("payee_bank_title"),
            },
            {
                id: 1,
                label: t("payee_transfer_review_title"),
            },
            {
                id: 2,
                label: t("transaction_btn_receipt"),
            },
        ],
        [t]
    );

    const [destination, setDestination] = useState(null);
    const [cardNumber, setCardNumber] = useState("");
    const [amount, setAmount] = useState({});
    const [description, setDescription] = useState("");
    const [standardDescription, setStandardDescription] = useState(null);
    const [isExtraField, setIsExtraField] = useState(false);

    const [selectedBank, setSelectedBank] = useState(null);
    const selectedBankBranchList = useRef([]);
    const [
        selectedBankProvinceBranchList,
        setSelectedBankProvinceBranchList,
    ] = useState([]);
    const [selectedBankProvinceList, setSelectedBankProvinceList] = useState(
        []
    );
    const [branch, setBranch] = useState(null);
    const [province, setProvince] = useState(null);
    const [ownerName, setOwnerName] = useState("");

    const initErrors = useMemo(
        () => ({
            cardNumber: null,
            selectedBank: null,
            province: null,
            branch: null,
            ownerName: null,
            amount: null,
            description: null,
        }),
        []
    );

    const [fetchingTargetAccount, setFetchingTargetAccount] = useState(false);
    const [validatingAmount, setValidatingAmount] = useState(false);

    const [errors, setErrors] = useState(initErrors);

    const isInValidIBFTNapas = useMemo(() => {
        return description.length > INPUT_RULES.MAXIMUM_CHARACTER;
    }, [description]);

    const destinationDescription = useMemo(() => {
        if (destination) {
            const name =
                destination?.card?.cardName ||
                destination?.account?.accountName;
            const bankName =
                destination?.card?.bankShortName ||
                destination?.account?.bankName;
            return `${name} | ${bankName}`;
        }
    }, [destination]);

    const clearErrors = useCallback(() => setErrors(initErrors), [initErrors]);

    const back = useCallback(() => {
        if (activeStep === 0) {
            return;
        }
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const next = useCallback(() => {
        if (activeStep === steps.length - 1) {
            return;
        }
        setActiveStep(activeStep + 1);
    }, [activeStep, steps]);

    const cancel = useCallback(() => {
        history.push(`/spend-account/${SpendAccountNavigationKey.MoveMoney}`);
    }, [history]);

    const onCardNumberChange = useCallback(
        (e) => {
            if (errors.cardNumber) {
                setErrors((prev) => ({
                    ...prev,
                    cardNumber: null,
                }));
            }
            if (destination) {
                setDestination(null);
            }
            setCardNumber(formatCardNumber(e.target.value));
        },
        [formatCardNumber, errors.cardNumber, destination]
    );

    const onAmountChange = useCallback(
        ({ formattedValue, value }) => {
            if (errors.amount) {
                setErrors((prev) => ({
                    ...prev,
                    amount: null,
                }));
            }
            setAmount({ formattedValue, value: +value });
        },
        [errors.amount]
    );

    const resetExtraFields = useCallback(() => {
        clearErrors();
        setSelectedBank(null);
        setBranch(null);
        setProvince(null);
        setOwnerName("");
    }, [clearErrors]);

    const addExtraField = useCallback(() => {
        GlobalDialogController.hide();
        resetExtraFields();
        setIsExtraField(true);
    }, [resetExtraFields]);

    const warningContinueWithCitad = useCallback(() => {
        GlobalDialogController.showCustomDialog({
            dialogInfo: {
                iconImage: "Warning",
                header: "li_lb_tittle_service_unavailable",
                content: "mb_del_destination_create_new",
                doubleButton: [
                    {
                        label: t("lb_continue"),
                        onClick: addExtraField,
                    },
                    {
                        label: t("lb_cancel"),
                        onClick: () => GlobalDialogController.hide(),
                    },
                ],
            },
        });
    }, [t, addExtraField]);

    const getTargetAccount = useCallback(
        async (target) => {
            if (!spendAccount || !target || fetchingTargetAccount) {
                return;
            }

            const parsedCardNumber = parseNumber(target, false);

            if (
                !CardNumberValidLengthValues.includes(parsedCardNumber.length)
            ) {
                setDestination(null);
                setErrors((prev) => ({
                    ...prev,
                    cardNumber: t("card_number_hint"),
                }));
                return;
            }

            setFetchingTargetAccount(true);

            const payload = {
                targetInfo: parsedCardNumber,
                bankAccount: spendAccount.no,
            };

            const response = await moveMoneyService.getAccountDetailByCreditCardNumber(
                payload
            );

            if (!unmounted.current) {
                setFetchingTargetAccount(false);
                if (response.ok) {
                    const { code, data } = response.data;
                    switch (code) {
                        case RESPONSE_CODE.SUCCESS:
                            setDestination(data);
                            setErrors((prev) => ({
                                ...prev,
                                cardNumber: null,
                            }));
                            setIsExtraField(false);
                            break;
                        case RESPONSE_CODE.CODE_USER_NOT_DONE_KYC: // invalid card num
                            setErrors((prev) => ({
                                ...prev,
                                cardNumber: t("spend_lb_invalid_card_number"),
                            }));
                            setIsExtraField(false);
                            setDestination(null);
                            break;
                        case RESPONSE_CODE.NO_EXISTING_MEMBER_ACCOUNT:
                        case RESPONSE_CODE.WRONG_INFO:
                        default:
                            setDestination(null);
                            warningContinueWithCitad();
                            break;
                    }
                } else {
                    setDestination(null);
                    warningContinueWithCitad();
                }
            }
        },
        [
            t,
            spendAccount,
            fetchingTargetAccount,
            parseNumber,
            warningContinueWithCitad,
        ]
    );

    const mapExtraFields = useCallback(() => {
        const payload = {
            account: {
                accountNumber: cardNumber,
                bankName: selectedBank?.bankName,
                bankId: selectedBank?.bankId,
                bankShortName: selectedBank?.bankShortName,
                accountName: ownerName,
                branchId: branch?.branchId,
                provinceId: province?.provinceId,
            },
        };
        return payload;
    }, [
        cardNumber,
        selectedBank,
        ownerName,
        branch?.branchId,
        province?.provinceId,
    ]);

    const onNextHandler = useCallback(async () => {
        if (isExtraField) {
            const payload = mapExtraFields();
            setDestination(payload);
        }

        const desc = await getStandardDescription({
            defaultDescription,
            description,
        });

        setStandardDescription(desc?.standardDescription);
        if (description === "") {
            setDescription(desc?.description);
        }
        next();
    }, [
        next,
        description,
        defaultDescription,
        getStandardDescription,
        isExtraField,
        mapExtraFields,
    ]);

    const validateFormData = useCallback(() => {
        const formErrors = { ...errors };

        if (!cardNumber) {
            formErrors.cardNumber = t("msg_we_need_this");
        }

        if (!amount?.value && !addingPayee) {
            formErrors.amount = t("msg_we_need_this");
        }

        if (isExtraField) {
            if (!selectedBank) {
                formErrors.selectedBank = t("msg_we_need_this");
            }

            if (!province) {
                formErrors.province = t("msg_we_need_this");
            }
            if (!branch) {
                formErrors.branch = t("msg_we_need_this");
            }
            if (!ownerName) {
                formErrors.ownerName = t("msg_we_need_this");
            }
        }

        setErrors(formErrors);

        const isValid = Object.values(formErrors)?.every(
            (item) => item === null
        );

        return isValid;
    }, [
        t,
        cardNumber,
        amount,
        errors,
        isExtraField,
        branch,
        ownerName,
        province,
        selectedBank,
        addingPayee,
    ]);

    const submitForm = useCallback(
        async (event) => {
            event.preventDefault();

            const isValid = validateFormData();

            if (!spendAccount || fetchingTargetAccount || !isValid) {
                return;
            }

            validateAmount({
                amount: amount.value,
                transferType: isExtraField ? "bank" : "card",
                beforeRequest: () => setValidatingAmount(true),
                isCancelled: unmounted.current,
                afterResponse: () => setValidatingAmount(false),
                handleBalanceLessThanMinAmount: (minAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: `${t("spend_msg_for_least_amount").replace(
                            "%@",
                            formatNumber(minAmount)
                        )}`,
                    })),
                handleAmountOutOfRange: (minAmount, maxAmount) =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_amount_invalid")
                            .replace("@", formatNumber(minAmount))
                            .replace("%@", formatNumber(maxAmount)),
                    })),
                handleAmountOverDailyLimit: () =>
                    setErrors((prev) => ({
                        ...prev,
                        amount: t("ms_lb_transfer_daily_limit"),
                    })),
                handleAmountValid: onNextHandler,
            });
        },
        [
            validateFormData,
            validateAmount,
            spendAccount,
            fetchingTargetAccount,
            t,
            amount,
            formatNumber,
            onNextHandler,
            isExtraField,
        ]
    );

    const confirmTransfer = useCallback(
        (saveAsPayee) => {
            const notification = "sms";

            const source = {
                accountNumber: spendAccount.no,
            };

            // eslint-disable-next-line prefer-const
            let target = {
                amount: +amount.value,
                bankName:
                    destination?.card?.bankName ||
                    destination?.account?.bankName,
                desType: destination?.type,
                description: standardDescription,
                originalFullDescription: description,
            };

            if (isExtraField) {
                target.accountNumber = destination?.account?.accountNumber;
                target.accountName = destination?.account?.accountName;
                target.bankId = destination?.account?.bankId;
                target.branchId = destination?.account?.branchId;
                target.provinceId = destination?.account?.provinceId;
            } else {
                target.cardNumber = destination?.card?.cardNumber;
                target.cardName = destination?.card?.cardName;
                target.destinationType = TRANSFER_DESTINATION_TYPES.CREDIT;
            }

            if (saveAsPayee) {
                target.saveAsPayee = saveAsPayee;
            }
            const payload = {
                notification,
                source,
                target,
            };

            if (isExtraField) {
                transferToAccountNumber(payload);
                return;
            }

            transferToDebitCard(payload);
        },
        [
            spendAccount,
            destination,
            description,
            amount,
            transferToDebitCard,
            standardDescription,
            isExtraField,
            transferToAccountNumber,
        ]
    );

    useEffect(() => {
        if (errorCodeAmount) {
            const error = getErrorByResponseCode(errorCodeAmount);
            setErrors((prev) => ({
                ...prev,
                amount: error,
            }));
            setActiveStep(0); // back to input info amount
        }
    }, [errorCodeAmount, getErrorByResponseCode]);

    const isShowNapasLogo = useMemo(() => {
        if (isExtraField) return false;

        const bankId = destination?.card?.bankId;

        if (bankId === BANK_INFO.DEFAULT_BANK_1_ID.toString()) {
            return false;
        }

        const maxAmountSML = parseFloat(systemParams?.conf?.MaxAmountSML);
        const amountPreview = amount.value;
        const enableNapas247Logo = featureState?.enableNapas247Logo;

        return enableNapas247Logo && maxAmountSML >= amountPreview;
    }, [
        amount.value,
        featureState?.enableNapas247Logo,
        systemParams?.conf?.MaxAmountSML,
        destination,
        isExtraField,
    ]);

    // Extra field handler

    const onBankChange = useCallback(
        async ({ value }) => {
            const filteredBranchList = value
                ? branchList.filter((b) => b.bankId === value.bankId)
                : [];

            const filteredProvinceIdList = uniqBy(
                filteredBranchList,
                "provinceId"
            ).map((p) => p.provinceId);

            const filteredProvinceList = filter(provinceList, (p) =>
                filteredProvinceIdList.includes(p.provinceId)
            );

            clearErrors();

            selectedBankBranchList.current = filteredBranchList;

            setSelectedBankProvinceList(filteredProvinceList);
            setSelectedBank(value);
            setProvince(null);
            setBranch(null);
        },
        [clearErrors, branchList, provinceList]
    );

    const onProvinceChange = useCallback((e, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            province: null,
            branch: null,
        }));
        setBranch(null);
        setProvince(value);
    }, []);

    const onBranchSelectionFocus = useCallback(() => {
        const filteredBranchList = province
            ? selectedBankBranchList.current.filter(
                  (b) => b.provinceId === province.provinceId
              )
            : [];
        setSelectedBankProvinceBranchList(filteredBranchList);
    }, [province]);

    const onBranchChange = useCallback((e, value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            branch: null,
        }));
        setBranch(value);
    }, []);

    const onOwnerNameChange = useCallback(
        (e) => {
            if (errors.ownerName) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    ownerName: null,
                }));
            }
            setOwnerName(escapeAccentVietnamese(e.target.value));
        },
        [errors.ownerName]
    );

    const onSaveHandler = useCallback(
        (event) => {
            event.preventDefault();

            const isValid = validateFormData();

            if (fetchingTargetAccount || !isValid) {
                return;
            }

            if (isExtraField) {
                const payload = mapExtraFields();
                onSave(payload?.account, MoveMoneyType.BankAccount);
            } else {
                onSave(destination, MoveMoneyType.DebitCard);
            }
        },
        [
            destination,
            onSave,
            isExtraField,
            mapExtraFields,
            fetchingTargetAccount,
            validateFormData,
        ]
    );

    return (
        <Paper component={Box} overflow="hidden">
            {!addingPayee && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((s) => (
                        <Step key={s.id}>
                            <StepLabel>{s.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}
            {activeStep === 0 && (
                <Box paddingTop={addingPayee ? 2 : 0}>
                    <form onSubmit={submitForm}>
                        {isExtraField && (
                            <Box className={classes.warningMessage}>
                                <WarningIcon vb={20} />
                                <LspTypography variant="body3" color="error">
                                    {t(
                                        "master:move_money_warning_citad_for_credit_transfer_message"
                                    )}
                                </LspTypography>
                            </Box>
                        )}

                        <Box p={3} paddingTop={addingPayee ? 1 : 0}>
                            <LspTextField
                                label={t("payee_card_number_label")}
                                error={!!errors.cardNumber}
                                helperText={
                                    errors.cardNumber || destinationDescription
                                }
                                onBlur={() => getTargetAccount(cardNumber)}
                                onChange={onCardNumberChange}
                                value={cardNumber}
                                InputProps={{
                                    endAdornment: fetchingTargetAccount ? (
                                        <InputAdornment position="end">
                                            <CircularProgress size={16} />
                                        </InputAdornment>
                                    ) : null,
                                }}
                            />
                            {isExtraField && (
                                <>
                                    <LspBankList
                                        value={selectedBank}
                                        onChange={(_, value) =>
                                            onBankChange({ value })
                                        }
                                        disableFastTransfer
                                        renderInput={(params) => {
                                            const inputProps =
                                                params.InputProps;
                                            return (
                                                <LspTextField
                                                    {...params}
                                                    label={t(
                                                        "payee_bank_name_label"
                                                    )}
                                                    error={
                                                        !!errors.selectedBank
                                                    }
                                                    helperText={
                                                        errors.selectedBank ||
                                                        " "
                                                    }
                                                    InputProps={inputProps}
                                                />
                                            );
                                        }}
                                    />
                                    <Autocomplete
                                        options={selectedBankProvinceList}
                                        getOptionLabel={(option) =>
                                            option?.provinceName || ""
                                        }
                                        value={province}
                                        onChange={onProvinceChange}
                                        renderInput={(params) => (
                                            <LspTextField
                                                {...params}
                                                label={t(
                                                    "payee_bank_province_label"
                                                )}
                                                error={!!errors.province}
                                                helperText={
                                                    errors.province || " "
                                                }
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        options={selectedBankProvinceBranchList}
                                        getOptionLabel={(option) =>
                                            option?.branchName || ""
                                        }
                                        value={branch}
                                        onChange={onBranchChange}
                                        onFocus={onBranchSelectionFocus}
                                        renderInput={(params) => (
                                            <LspTextField
                                                {...params}
                                                label={t(
                                                    "payee_bank_branch_label"
                                                )}
                                                error={!!errors.branch}
                                                helperText={
                                                    errors.branch || " "
                                                }
                                            />
                                        )}
                                    />

                                    <LspTextField
                                        label={t(
                                            "payee_account_owner_name_label"
                                        )}
                                        error={!!errors.ownerName}
                                        helperText={errors.ownerName || " "}
                                        onChange={onOwnerNameChange}
                                        value={ownerName}
                                    />
                                </>
                            )}

                            {!addingPayee && (
                                <>
                                    <LspSuggestAmount
                                        amount={amount.formattedValue}
                                        thousandSeparator={thousandSeparator}
                                        decimalSeparator={decimalSeparator}
                                        onValueChange={onAmountChange}
                                        label={t("payee_card_amount_label")}
                                        error={!!errors.amount}
                                        helperText={errors.amount || " "}
                                        customInput={LspTextField}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        allowedDecimalSeparators={false}
                                        numberValue={amount.value}
                                        onChange={onAmountChange}
                                        inputProps={{
                                            id: "amountbox",
                                        }}
                                    />
                                    <LspTextField
                                        label={t(
                                            "payee_card_description_label"
                                        )}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        value={description}
                                        multiline
                                        error={isInValidIBFTNapas}
                                        helperText={
                                            isInValidIBFTNapas
                                                ? t(
                                                      "master:move_money_desc_over_characters"
                                                  )
                                                : ""
                                        }
                                        inputProps={{
                                            maxLength: 254,
                                            id: "descbox",
                                        }}
                                    />
                                    <Box marginTop={1.5}>
                                        <DoubleButtons
                                            progressing={validatingAmount}
                                            primaryButton={{
                                                label: t("lb_next"),
                                                disable: isInValidIBFTNapas,
                                            }}
                                            secondaryButton={{
                                                label: t("lb_cancel"),
                                                onClick: cancel,
                                            }}
                                        />
                                    </Box>
                                </>
                            )}

                            {addingPayee && (
                                <Box marginTop={1.5}>
                                    <DoubleButtons
                                        primaryButton={{
                                            label: t(
                                                "payee_btn_add_destination"
                                            ),
                                            onClick: onSaveHandler,
                                        }}
                                        secondaryButton={{
                                            label: t("lb_cancel"),
                                            onClick: onCancel,
                                        }}
                                        disabled={addPayeeLoading}
                                        progressing={addPayeeLoading}
                                    />
                                </Box>
                            )}
                        </Box>
                    </form>
                </Box>
            )}
            {activeStep === 1 && (
                <TransferConfirmation
                    isFastTransfer={isShowNapasLogo}
                    onBack={back}
                    onConfirm={confirmTransfer}
                    destination={destination}
                    amount={amount.value}
                    description={description}
                    progressing={transferring}
                />
            )}
            {activeStep === 2 && (
                <LspReceipt onClose={cancel} rawReceipt={transferInfo} />
            )}
        </Paper>
    );
};

const mapState = (state) => ({
    transferring: state.moveMoney.fetching,
    transferStatus: state.moveMoney.status,
    transferInfo: state.moveMoney.info,
    errorCodeAmount: state.moveMoney.errorCodeAmount,
    fullName: state.user.info.fullName,
    featureState: state.user.featureState.data,
    systemParams: state.systemParams.info,
    bankList: state.bank.bankList.data,
    provinceList: state.bank.provinceList.data,
    branchList: state.bank.branchList.data,
});

const mapDispatch = (dispatch) => ({
    transferToDebitCard: (payload) =>
        dispatch(moveMoneyAction.transferToDebitCardRequest(payload)),
    transferToAccountNumber: (payload) =>
        dispatch(moveMoneyAction.transferToAccountNumberRequest(payload)),
    resetTransfer: () => dispatch(moveMoneyAction.reset()),
});

export default connect(mapState, mapDispatch)(CreditCardTransfer);
