import { ButtonBase, Icon, Input, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, memo } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        border: `1px solid ${theme.palette.neutral.grey3}`,
        width: 102,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: theme.palette.neutral.grey1,
        borderRadius: theme.shape.radiusLevels[0],
        "& .MuiInput-underline:before": {
            border: "none",
        },
    },
    input: {
        flex: 1,
        borderLeft: `1px solid ${theme.palette.neutral.grey3}`,
        borderRight: `1px solid ${theme.palette.neutral.grey3}`,
        "& input": {
            textAlign: "center",
            color: theme.palette.primary.main,
        },
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 32,
        height: 32,
        cursor: "pointer",
    },
    disabled: {
        opacity: 0.7,
        "& input": {
            opacity: 0.7,
        },
    },
}));

/**
 * @typedef LspNumberCounterProps
 * @type {object}
 * @property {number} [props.min]
 * @property {number} [props.max]
 * @property {string} [props.parentClass]
 * @property {number} [props.value]
 * @property {function} props.onChange
 * @property {boolean} props.disabled
 */

/**
 * @param {LspNumberCounterProps} props
 */

const LspNumberCounter = ({
    min,
    max,
    parentClass,
    value,
    onChange,
    disabled,
    ...props
}) => {
    const classes = useStyles();

    const onClickHandler = useCallback(
        (isPlus) => {
            if (value < min) {
                onChange(min);
            } else if (isPlus && value + 1 <= max) {
                onChange(value + 1);
            } else if (!isPlus && value - 1 >= min) {
                onChange(value - 1);
            }
        },
        [onChange, min, max, value]
    );

    const onChangeHandler = (e) => {
        onChange(e.target.value);
    };

    return (
        <div
            className={clsx(classes.wrapper, {
                [parentClass]: !!parentClass,
            })}
        >
            <ButtonBase
                className={clsx(classes.button, {
                    [classes.disabled]: disabled,
                })}
                disabled={disabled}
                onClick={() => onClickHandler(false)}
            >
                <Icon className="font-icon icon-minus" />
            </ButtonBase>
            <Input
                value={value}
                className={clsx(classes.input, {
                    [classes.disabled]: disabled,
                })}
                onChange={onChangeHandler}
                disabled
                {...props}
            />
            <ButtonBase
                className={clsx(classes.button, {
                    [classes.disabled]: disabled,
                })}
                disabled={disabled}
                onClick={() => onClickHandler(true)}
            >
                <Icon className="font-icon icon-plus" />
            </ButtonBase>
        </div>
    );
};

LspNumberCounter.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    parentClass: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

LspNumberCounter.defaultProps = {
    min: 0,
    max: 100,
    parentClass: "",
    value: 0,
    onChange: null,
    disabled: false,
};

export default memo(LspNumberCounter);
