import LspButton from "@components/LspButton";
import {
    LspDialogActions,
    LspDialogContent,
    LspDialogTitle,
} from "@components/LspDialog";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.neutral.white,
        borderRadius: theme.shape.borderRadius,
        "& ul": {
            paddingLeft: 17,
        },
    },
    description: {
        textAlign: "left",
        color: theme.palette.neutral.black,
    },
    uppercase: {
        textTransform: "uppercase",
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

const InstallmentDeclineInfo = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const description1 = t("creditCard:IN00025", {
        returnObjects: true,
    });
    const description2 = t("creditCard:IN00027", {
        returnObjects: true,
    });

    return (
        <Content size="sm">
            <div className={classes.wrapper}>
                <LspDialogTitle>
                    {t("creditCard:declined_ins_header")}
                </LspDialogTitle>
                <LspDialogContent>
                    <LspTypography
                        variant="heading1"
                        color="primary"
                        className={classes.marginBottom}
                    >
                        {t("creditCard:declined_ins_question")}
                    </LspTypography>

                    <div className={classes.description}>
                        <LspTypography variant="body2">
                            {t("creditCard:IN00024")}
                        </LspTypography>
                        <ul style={{ marginTop: 0 }}>
                            {description1.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    </div>

                    <LspTypography
                        variant="heading1"
                        color="primary"
                        className={classes.uppercase}
                    >
                        {t("creditCard:IN00026")}
                    </LspTypography>

                    <div className={classes.description}>
                        <ul style={{ marginBottom: 0 }}>
                            {description2.map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    </div>
                </LspDialogContent>
                <LspDialogActions>
                    <LspButton variant="primary" fullWidth onClick={onClose}>
                        {t("lb_btn_ok_got_it")}
                    </LspButton>
                </LspDialogActions>
            </div>
        </Content>
    );
};

export default InstallmentDeclineInfo;
