import { FetchStatus } from "@config/constants";

const { BillActionTypes } = require("@redux/actions/bill");
const { default: produce } = require("immer");

const initialState = {
    bills: [],
    billsFetching: false,
    selectedBill: {},
    splitBill: {
        fetching: false,
        bill: {},
        status: false,
    },
    splitBillPayment: {
        fetching: false,
        payment: {},
        status: false,
    },
};

const billReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case BillActionTypes.GetBillList:
                draft.billsFetching = true;
                break;
            case BillActionTypes.GetBillListSuccess:
                draft.billsFetching = false;
                draft.bills = action.payload;
                break;
            case BillActionTypes.GetBillListError:
                draft.billsFetching = false;
                break;
            case BillActionTypes.SetSelectedBill:
                draft.selectedBill = action.payload;
                break;
            case BillActionTypes.Reset:
                const param = action.payload;
                if (!!param && param !== "") {
                    draft[param] = initialState[param];
                } else {
                    const stateNames = Object.freeze(initialState);
                    stateNames.forEach((name) => {
                        draft[name] = initialState[name];
                    });
                }
                break;

            case BillActionTypes.GetSplitBill:
                draft.splitBill.fetching = true;
                draft.splitBill.status = FetchStatus.Pending;
                break;
            case BillActionTypes.GetSplitBillSuccess:
                draft.splitBill.fetching = false;
                draft.splitBill.bill = action.payload;
                draft.splitBill.status = FetchStatus.Success;
                break;
            case BillActionTypes.GetSplitBillError:
                draft.splitBill.fetching = false;
                draft.splitBill.status = FetchStatus.Error;
                break;
            case BillActionTypes.GetSplitBillPayment:
                draft.splitBillPayment.fetching = true;
                draft.splitBillPayment.status = FetchStatus.Pending;
                break;
            case BillActionTypes.GetSplitBillPaymentSuccess:
                draft.splitBillPayment.fetching = false;
                draft.splitBillPayment.payment = action.payload;
                draft.splitBillPayment.status = FetchStatus.Success;
                break;
            case BillActionTypes.GetSplitBillPaymentError:
                draft.splitBillPayment.fetching = false;
                draft.splitBillPayment.status = FetchStatus.Error;
                break;
            default:
                break;
        }
    });

export default billReducer;
