import Content from "@components/Content";
import LspButton from "@components/LspButton";
import LspCardList from "@components/LspCardList";
import PageHeaderBack from "@components/PageHeaderBack";
import {
    BOOKING_SERVICES,
    BOOKING_STEPS,
    DELIVERY_METHODS,
} from "@containers/Booking/constant";
import { Box } from "@material-ui/core";
import bookingActions from "@redux/actions/booking";
import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const CreateBookingPickupMethod = ({
    serviceName,
    onCancel,
    onNext,
    setBookingMethod,
    availableMethod,
}) => {
    const { t } = useTranslation();

    const content = useMemo(() => {
        let methods = [];
        switch (serviceName) {
            case BOOKING_SERVICES.EKYC_BOOKING:
                methods = [
                    {
                        id: DELIVERY_METHODS.MAIL,
                        title: t("booking_by_mail"),
                        description: t("booking_by_mail_description"),
                        isAvailable: availableMethod?.isMail,
                    },
                    {
                        id: DELIVERY_METHODS.MOTORBIKE,
                        title: t("booking_by_motobike"),
                        description: t("booking_by_motobike_description"),
                        isAvailable: availableMethod?.isMotorbike,
                    },
                ];
                break;
            case BOOKING_SERVICES.REPLACE_CHIP_BOOKING:
            default:
                methods = [
                    {
                        id: DELIVERY_METHODS.MAIL,
                        title: t("booking_by_mail"),
                        description: t("booking_by_mail_description"),
                        isAvailable: availableMethod?.isMail,
                    },
                    {
                        id: DELIVERY_METHODS.MOTORBIKE,
                        title: t("booking_by_motobike"),
                        description: t("booking_by_motobike_description"),
                        isAvailable: availableMethod?.isMotorbike,
                    },
                ];
                break;
        }

        return {
            title: t("master:choose_way_to_receive_card"),
            methods,
        };
    }, [serviceName, t, availableMethod]);

    const onSelectMethod = useCallback(
        (method) => {
            setBookingMethod(method?.id);
            onNext(BOOKING_STEPS.DELIVERY_ADDRESS_SELECTION);
        },
        [onNext, setBookingMethod]
    );

    return (
        <Content size="sm">
            <PageHeaderBack>{content?.title}</PageHeaderBack>
            <LspCardList
                list={content?.methods}
                onClickItem={onSelectMethod}
                variant="primary"
                hideStyle
            />
            <Box mt={3}>
                <LspButton fullWidth onClick={onCancel}>
                    {t("lb_cancel")}
                </LspButton>
            </Box>
        </Content>
    );
};

const mapState = (state) => ({
    onCancel: state.booking.onCancel,
    detail: state.booking.detail,
    serviceName: state.booking.serviceName,
});

const mapDispatch = (dispatch) => ({
    setBookingMethod: (payload) =>
        dispatch(bookingActions.setBookingMethod(payload)),
});
export default connect(mapState, mapDispatch)(CreateBookingPickupMethod);
