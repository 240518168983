import LspTypography from "@components/LspTypography";
import { WEALTH_PAYMENT_METHODS } from "@containers/Wealth/wealthConstants";
import useNumber from "@helpers/useNumber";
import { Box, Paper, Radio, TextField } from "@material-ui/core";
import clsx from "clsx";
import { format, parse } from "date-fns";
import { useState, memo, useMemo } from "react";

import { enUS, vi } from "date-fns/locale";

import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const PaymentAmountSuggestion = ({
    classes,
    amount,
    selectedAmountOption,
    onAmountOptionChange,
    list,
    onAmountChange,
    error,
    suggest,
}) => {
    const { formatNumber, thousandSeparator, decimalSeparator } = useNumber();
    const { t, i18n } = useTranslation("translation", "creditCard");

    const [period, setPeriod] = useState(null);

    const disabledRadioOption = (currentAmount) => {
        return currentAmount === 0;
    };

    const convertPeriod = useMemo(() => {
        if (suggest && suggest.period) {
            const periodData = format(
                parse(suggest.period, "MMyyyy", new Date()),
                "MMMM yyyy",
                {
                    locale: i18n.language === "vn" ? vi : enUS,
                }
            );
            setPeriod(periodData);
            return ` ${periodData} (${suggest?.from} - ${suggest?.to})`;
        }
        return "";
    }, [i18n.language, suggest]);

    return (
        <Paper component={Box} p={2} marginTop={1} marginBottom={3}>
            <LspTypography variant="subheading3" color="grey">
                {t("mastercardv3:make_payment_statement").replace(
                    "%@",
                    convertPeriod
                )}
            </LspTypography>

            {list?.map((item) => {
                return (
                    <div className={classes.item} key={item?.id}>
                        <div className={classes.itemLeft}>
                            <Radio
                                className={classes.customRadioButton}
                                checkedIcon={
                                    <div
                                        className={`${classes.itemCircle} ${classes.itemCircleActive}`}
                                    >
                                        <div
                                            className={classes.itemCircleChild}
                                        />
                                    </div>
                                }
                                checked={selectedAmountOption?.id === item?.id}
                                value={item}
                                onClick={() => onAmountOptionChange(item)}
                                disabled={disabledRadioOption(item?.amount)}
                            />
                        </div>
                        <div
                            className={clsx(classes.itemRight, {
                                [classes.oneline]: !item?.subtitle,
                                [classes.disabled]: disabledRadioOption(
                                    item?.amount
                                ),
                            })}
                        >
                            <LspTypography
                                className={`${classes.itemTitle}`}
                                variant="subheading3"
                            >
                                {item?.title}
                            </LspTypography>
                            {item?.subtitle && (
                                <LspTypography
                                    className={`${classes.itemText} `}
                                    variant="body3"
                                >
                                    {item?.subtitle}
                                </LspTypography>
                            )}
                        </div>
                        {item?.id !== WEALTH_PAYMENT_METHODS.MANUAL && (
                            <div
                                className={
                                    disabledRadioOption(item?.amount)
                                        ? classes.disabled
                                        : ""
                                }
                            >
                                <LspTypography
                                    variant="subheading3"
                                    color="black"
                                >
                                    {item?.amount
                                        ? formatNumber(item?.amount)
                                        : item?.amount}
                                </LspTypography>
                            </div>
                        )}
                        {item?.id === WEALTH_PAYMENT_METHODS.MANUAL && (
                            <Box
                                className={clsx(classes.amountInput, {
                                    [classes.hideLabel]: amount?.value !== "",
                                })}
                            >
                                <NumberFormat
                                    value={amount.formattedValue}
                                    thousandSeparator={thousandSeparator}
                                    decimalSeparator={decimalSeparator}
                                    onValueChange={onAmountChange}
                                    label={t("lb_amount_web")}
                                    error={!!error}
                                    helperText={error}
                                    customInput={TextField}
                                    allowLeadingZeros={false}
                                    allowNegative={false}
                                    allowedDecimalSeparators={false}
                                />
                            </Box>
                        )}
                    </div>
                );
            })}
        </Paper>
    );
};

export default memo(PaymentAmountSuggestion);
