import produce from "immer";
import { GlobalDialogActionTypes } from "../actions/global-dialog";

/* ------------- Initial State ------------- */
const initialState = {
    opened: false,
    component: null,
    dialogInfo: null,
    type: null,
    errorCode: null,
    onClose: null,
    loading: false,
    data: null,
    size: "sm",
};

/* ------------- Reducer ------------- */
const globalDialogReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GlobalDialogActionTypes.Open:
                const {
                    component,
                    dialogInfo,
                    type,
                    errorCode,
                    onClose,
                    data,
                    size,
                } = action.payload;
                draft.opened = true;
                draft.type = type;
                if (component) {
                    draft.component = component;
                }
                if (dialogInfo) {
                    draft.dialogInfo = dialogInfo;
                }
                if (errorCode) {
                    draft.errorCode = errorCode;
                }
                if (onClose) {
                    draft.onClose = onClose;
                }
                if (data) {
                    draft.data = data;
                }
                if (size) {
                    draft.size = size;
                }
                break;
            case GlobalDialogActionTypes.Close:
                draft.opened = initialState.opened;
                draft.dialogInfo = initialState.dialogInfo;
                draft.component = initialState.component;
                draft.type = initialState.type;
                draft.errorCode = initialState.errorCode;
                draft.onClose = initialState.onClose;
                draft.loading = initialState.loading;
                draft.data = initialState.data;
                draft.size = "sm";
                break;

            case GlobalDialogActionTypes.SetLoading:
                draft.loading = action.payload;
                break;
            default:
                break;
        }
    });

export default globalDialogReducer;
