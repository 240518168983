import LspTypography from "@components/LspTypography";
import {
    FormControl,
    FormControlLabel,
    Icon,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileEditComponent from "@containers/Profile/ProfileEditComponent";
import { Skeleton } from "@material-ui/lab";
import { PROFILE_FORM_TYPES } from "@containers/Profile/ProfileAccountPersonalInfo/constants";

import useProfileSharedClasses from "@containers/Profile/profileSharedClasses";

const ProfileMaritalStatusForm = ({
    successMessage,
    isUpdating,
    onSubmitForm,
    selected,
    errorMessage,
    isEdit,
    toggleEdit,
    list,
}) => {
    const { t } = useTranslation();
    const classes = useProfileSharedClasses();
    const [marital, setMarital] = useState(selected);
    const [error, setError] = useState("");
    const maritalName = useMemo(() => {
        const currentMarital = list?.filter((item) => item.cfgKey === selected);
        return currentMarital[0]?.cfgValue;
    }, [list, selected]);

    const toggleEditHandler = (e) => {
        e.preventDefault();
        setMarital(selected);
        toggleEdit(PROFILE_FORM_TYPES.MARITAL_STATUS);
    };

    const onSubmitHandler = useCallback(
        (e) => {
            e.preventDefault();
            if (!marital) {
                setError(t("msg_we_need_this"));
                return;
            }
            onSubmitForm(marital);
        },
        [marital, onSubmitForm, t]
    );

    const onChangeHandler = (e) => {
        const { value } = e.target;
        setError("");
        setMarital(value);
    };

    return (
        <form className={classes.formWrapper} onSubmit={onSubmitHandler}>
            <div className={`${classes.line}`}>
                <div>
                    <Icon
                        className={`font-icon icon-maritalstatus ${classes.leftIcon}`}
                    />
                    <LspTypography
                        component="span"
                        color="grey"
                        variant="heading2"
                    >
                        {t("user_profile_marital_status_txt")}
                    </LspTypography>
                    : &nbsp;
                    <LspTypography component="span" color="grey">
                        {!isUpdating && maritalName}
                        {isUpdating && (
                            <Skeleton
                                width={170}
                                style={{ display: "inline-block" }}
                            />
                        )}
                    </LspTypography>
                    {successMessage && (
                        <LspTypography
                            color="success"
                            variant="body1"
                            className={classes.successMessage}
                        >
                            {successMessage}
                        </LspTypography>
                    )}
                </div>
                <div>
                    <ProfileEditComponent
                        isEdit={isEdit}
                        onEdit={toggleEditHandler}
                        onCancel={toggleEditHandler}
                        updating={isUpdating}
                    />
                </div>
            </div>

            {isEdit && (
                <div className={classes.formDetail}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="bottom"
                        >
                            {list?.map((item) => {
                                return (
                                    <FormControlLabel
                                        key={item.cfgKey}
                                        value={item.cfgKey}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={
                                                    marital === item?.cfgKey
                                                }
                                                onChange={onChangeHandler}
                                                disabled={isUpdating}
                                            />
                                        }
                                        label={item.cfgValue}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    {(errorMessage || error) && (
                        <LspTypography color="error" variant="body3">
                            {errorMessage || error}
                        </LspTypography>
                    )}
                </div>
            )}
        </form>
    );
};

export default memo(ProfileMaritalStatusForm);
