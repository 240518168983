import Content from "@components/Content";
import LspTypography from "@components/LspTypography";
import { Box, Icon, Paper } from "@material-ui/core";
import { memo, useState, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import wealthInsuranceAction from "@redux/actions/wealth-insurance";
import LspTextField from "@components/LspTextField";
import useClassesShared from "@containers/Wealth/wealthSharedClasses";
import LspButton from "@components/LspButton";

import PageHeaderBack from "@components/PageHeaderBack";

import { Autocomplete } from "@material-ui/lab";
import useInputSpecialCharacter from "@helpers/useInputSpecialCharacter";
import { REGEX_PATTERN } from "@config/constants";
import parse from "date-fns/parse";
import format from "date-fns/format";
import OwnerPolicyReview from "./OwnerPolicyReview";

const WealthTravelOwnerPolicy = ({
    onBack,
    onNext,
    ownerPolicy,
    setOwnerPolicy,
    user,
}) => {
    const sharedClasses = useClassesShared();
    const { t } = useTranslation();

    const { characterValidation } = useInputSpecialCharacter();

    const [errors, setErrors] = useState({
        passportId: null,
        nationality: null,
    });

    const birthDay = useMemo(() => {
        return user?.dateOfBirth
            ? parse(user?.dateOfBirth, "yyyy-MM-dd", new Date())
            : "";
    }, [user?.dateOfBirth]);

    const province = useMemo(() => {
        return {
            cfgKey: user?.address?.cityId,
            cfgValue: user?.address?.cityName,
        };
    }, [user?.address]);

    const district = useMemo(() => {
        return {
            cfgKey: user?.address?.distId,
            cfgValue: user?.address?.distName,
        };
    }, [user?.address]);

    const nationalityList = useMemo(() => {
        return t("wealth:nationalities", { returnObjects: true });
    }, [t]);

    const [passportId, setPassportId] = useState(ownerPolicy?.legalId || "");
    const [nationality, setNationality] = useState(
        ownerPolicy?.nationality || null
    );

    const onChangePassport = (e) => {
        const { value } = e.target;
        setPassportId(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                passportId: t("msg_we_need_this"),
            }));
            return;
        }

        if (!characterValidation(value, REGEX_PATTERN.PASSPORT_IDNUMBER)) {
            setErrors((prev) => ({
                ...prev,
                passportId: t("wealth_travel_error_passport_invalid"),
            }));
            return;
        }

        setErrors((prev) => ({
            ...prev,
            passportId: "",
        }));
    };
    const onChangeNationality = ({ value }) => {
        setNationality(value);
        if (!value) {
            setErrors((prev) => ({
                ...prev,
                nationality: t("msg_we_need_this"),
            }));
            return;
        }

        setErrors((prev) => ({
            ...prev,
            nationality: "",
        }));
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        if (errors?.passportId || errors?.nationality) return;

        setOwnerPolicy({
            legalId: passportId,
            nationality,
            fullName: user?.fullName,
            phoneNumber: user?.phoneNumber,
            dateOfBirth: format(birthDay, "dd/MM/yyyy"),
            gender: user?.gender,
            email: user?.userEmail,
            address: user?.address?.addressName,
            city: province,
            district,
        });
        onNext();
    };

    return (
        <form onSubmit={onSubmitForm}>
            <PageHeaderBack onBack={onBack}>
                <Icon className="font-icon icon-insuranceinformation" />
                <LspTypography>{t("wealth:GE00098")}</LspTypography>
            </PageHeaderBack>
            <Content size="xs">
                <Paper style={{ overflow: "hidden" }}>
                    <Box className={sharedClasses.formHeader}>
                        <LspTypography>
                            {t("wealth:lbi_travel_policyholder_passport_lb")}
                        </LspTypography>
                    </Box>
                    <Box className={sharedClasses.formBody}>
                        <LspTextField
                            autoFocus
                            error={!!errors?.passportId}
                            name="passportId"
                            value={passportId}
                            label={t("wealth:GE00075")}
                            helperText={errors?.passportId || ""}
                            inputProps={{
                                maxLength: 15,
                            }}
                            onChange={onChangePassport}
                        />
                        <Autocomplete
                            options={nationalityList}
                            getOptionLabel={(option) => option?.value || ""}
                            renderOption={(option) => <>{option.value}</>}
                            onChange={(_, value) =>
                                onChangeNationality({ value })
                            }
                            value={nationality}
                            renderInput={(params) => {
                                return (
                                    <LspTextField
                                        {...params}
                                        label={t("wealth:GE00076")}
                                        error={!!errors?.nationality}
                                        helperText={errors?.nationality || " "}
                                    />
                                );
                            }}
                        />
                    </Box>
                    <Box className={sharedClasses.formHeader}>
                        <LspTypography>
                            {t("wealth:lbi_travel_policyholder_personal_lb")}
                        </LspTypography>
                    </Box>
                    <Box className={sharedClasses.formBody}>
                        <OwnerPolicyReview
                            province={province}
                            district={district}
                            user={user}
                            birthDay={birthDay}
                        />
                    </Box>
                </Paper>
                <LspButton
                    type="submit"
                    fullWidth
                    className={sharedClasses.button}
                    disabled={
                        !!errors?.passportId ||
                        !!errors?.nationality ||
                        !passportId ||
                        !nationality
                    }
                >
                    {t("lb_next")}
                </LspButton>
            </Content>
        </form>
    );
};

const mapState = (state) => ({
    ownerPolicy: state.insurance.ownerPolicy,
    user: state.user.info,
});

const mapDispatch = (dispatch) => ({
    setOwnerPolicy: (payload) =>
        dispatch(wealthInsuranceAction.setOwnerPolicy(payload)),
});

export default memo(connect(mapState, mapDispatch)(WealthTravelOwnerPolicy));
