/* eslint-disable react/no-array-index-key */
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LspTextField from "@components/LspTextField";
import LspTypography from "@components/LspTypography";
import cloneDeep from "lodash/cloneDeep";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    tagList: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: theme.spacing(1.5),
    },
    uppercase: {
        textTransform: "uppercase",
    },
    tagItem: {
        borderRadius: theme.shape.radiusLevels[0],
        margin: theme.spacing(0.5),
        padding: theme.spacing(1),
        cursor: "pointer",
        textAlign: "center",
        "&.selected": {
            background: theme.palette.primary.main,
            " & > p": {
                color: theme.palette.neutral.white,
            },
        },
    },
}));
const SearchDiscount = ({ tagList, setCategoriesFilter, categoriesFilter }) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();

    const changTagFilter = (item) => {
        let cloneTag = cloneDeep(categoriesFilter.tags);
        // when filter with tag is All
        if (item.tag === "All") {
            cloneTag = [];
            setCategoriesFilter((prev) => ({
                ...prev,
                lastId: -1,
                tags: [],
                keyword: searchTerm,
            }));
            return;
        }

        // Another case
        const index = cloneTag.indexOf(item.tag);
        if (index > -1) {
            cloneTag.splice(index, 1);
        } else {
            cloneTag.push(item.tag);
        }
        setCategoriesFilter((prev) => ({
            ...prev,
            lastId: -1,
            tags: cloneTag,
            keyword: searchTerm,
        }));
    };

    const onChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSearchTerm = () => {
        setCategoriesFilter((prev) => ({
            ...prev,
            lastId: -1,
            keyword: searchTerm,
        }));
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setCategoriesFilter((prev) => ({
                ...prev,
                lastId: -1,
                keyword: searchTerm,
            }));
        }
    };

    return (
        <div className={classes.wrapper}>
            <LspTextField
                disableHelperText
                label={t("investigation_lb_search")}
                value={searchTerm}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon
                                onClick={onSearchTerm}
                                cursor="pointer"
                            />
                        </InputAdornment>
                    ),
                }}
            />
            <div className={classes.tagList}>
                {tagList.map((item, key) => (
                    <div
                        className={clsx(classes.tagItem, {
                            selected:
                                categoriesFilter.tags?.includes(item.tag) ||
                                (item.tag === "All" &&
                                    !categoriesFilter.tags.length),
                        })}
                        onClick={() => changTagFilter(item)}
                        key={key}
                    >
                        <LspTypography
                            variant="body1"
                            color="grey"
                            className={classes.uppercase}
                        >
                            {item.name}
                        </LspTypography>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(SearchDiscount);
