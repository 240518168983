import { ImageIcon, PDFIcon } from "@components/LspIcon";
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { isFunction } from "lodash";
import { useCallback, useEffect, useState } from "react";
import pdfIcon from "@assets/images/pdf_icon.png";

import { connect } from "react-redux";
import { base } from "@config/theme/colors";

const useStyles = makeStyles((theme) => ({
    attachmentFile: {
        height: 60,
        width: 60,

        marginRight: theme.spacing(2),

        "& embed": {
            overflow: "hidden !important",
        },
        cursor: "pointer",
    },
    previewPdf: {
        height: 60,
        width: 60,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontSize: theme.typography.pxToRem(32),
        color: theme.palette.magicalGrey[400],
        position: "relative",
    },
    previewPdfImg: {
        padding: theme.spacing(0.8, 1.5),
        width: 60,
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: base.lightPurple1,
        borderRadius: theme.shape.borderRadius,
        "& img": {
            width: "100%",
        },
    },
    previewPdfImgIcon: {
        width: 20,
        height: 20,
    },
}));
const LspAttachmentItem = ({ item, onClick, loading }) => {
    const classes = useStyles();

    const [attachment, setAttachmentSrc] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [extension, setExtension] = useState(null);

    const handleLoadImg = useCallback(async () => {
        if (item?.url) {
            const extensionFile = item?.contentType;
            const isPDF = extensionFile === "pdf";

            const file = isPDF ? "application/pdf" : "image/*";

            setExtension(extensionFile);
            setAttachmentSrc(item?.url);
            setFileType(file);
        }
    }, [item?.contentType, item?.url]);

    useEffect(() => {
        handleLoadImg();
    }, [handleLoadImg]);

    const handleOpenFile = () => {
        if (isFunction(onClick)) {
            onClick({
                url: attachment,
                fileType,
                fileManageId: item.fileManageId,
                extension,
                urlDownLoad: item?.urlDownLoad,
            });
        }
    };

    const handleShowAttachment = () => {
        switch (fileType) {
            case "application/pdf":
                return (
                    <div className={classes.previewPdfImg}>
                        <div className={classes.previewPdfImgIcon}>
                            <PDFIcon />
                        </div>
                    </div>
                );

            default:
                return (
                    <div className={classes.previewPdfImg}>
                        <div className={classes.previewPdfImgIcon}>
                            <ImageIcon />
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={classes.attachmentBox}>
            <div className={classes.attachmentFile} onClick={handleOpenFile}>
                {!loading && handleShowAttachment()}
                {loading && <Skeleton variant="rect" width={60} height={60} />}
            </div>
        </div>
    );
};

const stateToProps = (state) => ({
    token: state.auth.token,
});

export default connect(stateToProps, null)(LspAttachmentItem);
