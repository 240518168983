/* eslint-disable react/no-array-index-key */
import { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import LspTypography from "@components/LspTypography";
import Content from "@components/Content";
import accountService from "@services/account";
import { RESPONSE_CODE, CreditCardNavigationKey } from "@config/constants";
import GlobalDialogController from "@helpers/controllers/GlobalDialogController";
import { format, parse } from "date-fns";
import { vi, enUS } from "date-fns/locale";
import PageHeader from "@components/PageHeader";
import { useHistory } from "react-router-dom";
import Loading from "@components/Loading";
import ChartDetail from "./ChartDetail";
import LastStatementItem from "./LastStatementItem";

const useStyles = makeStyles(() => ({
    period: {
        "&::first-letter": {
            textTransform: "uppercase",
        },
    },
}));

const LastStatement = () => {
    const [statements, setStatements] = useState([]);
    const [statementDetail, setStatementDetail] = useState({});
    const [showDetailPage, setShowDetailPage] = useState(false);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const unmounted = useRef(false);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    const getLastStatementList = useCallback(async () => {
        setLoading(true);
        const { data } = await accountService.getLastStatementList({});
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                setStatements(data.data.items);
                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    }, []);

    const mappingData = (data) => {
        if (data.period) {
            data.period = format(
                parse(data.period, "yyyyMM", new Date()),
                "MMMM yyyy",
                {
                    locale: i18n.language === "vn" ? vi : enUS,
                }
            );
        }
        data.chartData = Object.values(data.summary || {});
        data.chartLabels = Object.keys(data.summary || {}).map(
            (summaryItem) => {
                switch (summaryItem) {
                    case "food":
                        return t("creditCard:stm_expenses_fb");
                    case "shopping":
                        return t("creditCard:stm_expenses_ecom");
                    case "entertainments":
                        return t("creditCard:stm_expenses_lifestyle");
                    case "others":
                    default:
                        return t("creditCard:stm_expenses_other");
                }
            }
        );
        setStatementDetail(data);
    };

    const showDetail = async (detail) => {
        setLoading(true);
        const dataToPost = {
            id: detail.id,
        };
        const { data } = await accountService.getLastStatementDetail(
            dataToPost
        );
        if (unmounted.current) {
            return;
        }
        setLoading(false);
        switch (data.code) {
            case RESPONSE_CODE.SUCCESS:
                mappingData(data.data);
                setShowDetailPage(true);
                break;

            default:
                GlobalDialogController.showError({ errorCode: data.code });
                break;
        }
    };

    useEffect(() => {
        getLastStatementList();
    }, [getLastStatementList]);

    return (
        <Content>
            {loading && <Loading />}
            {!loading && (
                <>
                    {!showDetailPage ? (
                        <>
                            <PageHeader
                                backFunc={() =>
                                    history.push(
                                        CreditCardNavigationKey.CurrentStatement
                                    )
                                }
                            >
                                {t("creditCard:stm_past_stm")}
                            </PageHeader>
                            {statements.map((item, key) => (
                                <LastStatementItem
                                    item={item}
                                    key={key}
                                    showDetail={showDetail}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            <PageHeader
                                backFunc={() => setShowDetailPage(false)}
                            >
                                {t("creditCard:stm_details_header")}
                            </PageHeader>
                            <Box mb={4} mt={4} pl={2}>
                                <LspTypography
                                    variant="subheading2"
                                    color="black"
                                    className={classes.period}
                                >
                                    {statementDetail.period}
                                </LspTypography>
                            </Box>
                            <ChartDetail currentData={statementDetail} />
                        </>
                    )}
                </>
            )}
        </Content>
    );
};

export default LastStatement;
